import { Injectable, EventEmitter } from "@angular/core";
import { GeneralSetting } from "./generalSetting.service";

@Injectable({
  providedIn: "root"
})
export class CurrencyService {

  currencyEmitter: EventEmitter<boolean> = new EventEmitter();

  public _currencySymbol: string = "$";
  private _isPrefix!: boolean;

  get CurrencySymbol() {
    return GeneralSetting.getCurrencySymbol();//this._currencySymbol;
  }

  get IsPrefix() {
    return this._isPrefix;
  }

  setCurrencySymbol(symbol: any) {
    this._currencySymbol = symbol;
  }

  setIsPrefix(isPrefix: boolean) {
    this._isPrefix = isPrefix;
    this.currencyEmitter.emit(this._isPrefix);
  }

  getCurrencyInfo(): boolean {
    return GeneralSetting.getCurrencyPrefix() == "True";
  }

}
