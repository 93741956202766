import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Category } from 'src/app/models/category';
import { ComboGroupV2, ItemV2, ModifierV2 } from 'src/app/models/item';
import { Variation, VariationOption } from 'src/app/models/Variations';
import { DatabaseService } from 'src/app/services/database.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { ItemTransferService } from 'src/app/services/item-transfer.service';
import { environment } from 'src/environments/environment';
import { BoxBuilderItemFooterComponent } from './box-builder-item-footer/box-builder-item-footer.component';
import { BoxBuilderItemDetail } from './box-builder-item-detail/box-builder-item-detail.component';
import { ChooseDonutCustomizePopupComponent } from '../dialogs/choose-donut-customize-popup/choose-donut-customize-popup.component';
import { CartService } from 'src/app/services/cart.service';
import { setTokenSourceMapRange } from 'typescript';


@Component({
  selector: 'app-box-builder-item',
  templateUrl: './box-builder-item.component.html',
  styleUrls: ['./box-builder-item.component.css'],
})
export class BoxBuilderItemComponent implements OnInit, OnDestroy {

  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  constructor(
    private readonly itemTransferService: ItemTransferService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly db: DatabaseService,
    private readonly modalService: NgbModal,
    private readonly isKioskService: IsKioskService,
    private readonly cartService: CartService
  ) {
    this.item = null;
    this.variations = [] as Variation[];
    this.modifiers = new Array<ModifierV2>();
    this.ItemID = this.route.snapshot.queryParams['id'];
    this.CategoryID = this.route.snapshot.queryParams['category'];
    this.guid = this.route.snapshot.queryParams['guid'];
    this.isOrderReviewEdit = this.route.snapshot.queryParams['reviewEdit'];
    this.isLoyalty = this.route.snapshot.queryParams['loyalty'];
    this.concessionaireId = route.snapshot.queryParams['concessionaireId'];
  }

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  modifiers: ModifierV2[] = [] as ModifierV2[];

  variations: Variation[] = [] as Variation[];

  comboGroup: ComboGroupV2[] = [] as ComboGroupV2[];


  concessionaireId: string = "";

  modalReference: any;

  ItemID: string;

  CategoryID: string;

  isReady: boolean = false;

  item: ItemV2 | null;

  // For Edit Item Feature
  guid: string = '';

  isOrderReviewEdit = false;
  // For Edit Item Feature end
  isLoyalty: string;

  isComboItem = false;

  category: Category = {} as Category;

  quantity: number = 1;

  cartQuantity: number = 0;

  branchLogo: string | null = '';

  selectedCount: number = 0;
  itemImageList: any[] = [];

  // For selected item with modifiers
  _selectedModifiers: ModifierV2[] = [];

  _selectedVariations: VariationOption[] = [];

  _selectedComboGroup: ComboGroupV2[] = [];

  isModifiersValid!: boolean;
  isVariationsValid!: boolean;
  isComboGroupValid: boolean = false;

  totalComboGroups: number = 0;

  isFromEdit: boolean = false;
  numInvisibleGroups: any;

  @ViewChild(BoxBuilderItemDetail) boxBuilderItem!: BoxBuilderItemDetail;

  @ViewChild(BoxBuilderItemFooterComponent)
  boxBuilderItemFooter!: BoxBuilderItemFooterComponent;

  @ViewChild(ChooseDonutCustomizePopupComponent, { static: true })
  chooseDonutCustomizePopup!: ChooseDonutCustomizePopupComponent;

  currentSelectedModifierIndex: number = 0;

  comboItemFlags: any[] = [];
  ngOnInit(): void {
    this.branchLogo = GeneralSetting.getBranchLogo();
    this.initializePage();
  }

  private initializePage() {
    if (!this.db.hasCategory()) {
      this.db.getPersistentCategoriesAsync(true);
    }

    if (this.isComboItem) {
      if (this.item) {
        this.setDetailByItem(this.item);
        if (this.item.ComboGroup) {
          this.numInvisibleGroups = this.item.ComboGroup.filter(
            (x) => x.isHidden
          ).length;
        }
      }

      // this.checkIsReady();
      return;
    }

    this.allSubsCription.push(
      this.db.categorySubject.subscribe((categories) => {
        if (!(categories && categories.length > 0)) return;

        const category = categories.find(
          (x) => Number(x.CategoryID) == Number(this.CategoryID)
        );

        this.category = category ?? ({} as Category);

        if (this.guid) {
          this.setEditItemDetail();
          if (this.item) {
            if (this.item.ComboGroup) {
              this.numInvisibleGroups = this.item.ComboGroup.filter(
                (x) => x.isHidden
              ).length;
            }
          }
          return;
        }

        if (this.isLoyalty != 'true') {
          const item = category?.associatedItems?.find(
            (x) => x.ItemID == this.ItemID
          );
          if (item) {
            this.totalComboGroups = item?.ComboGroup.length!;
            this.setDetailByItem(item);
            if (this.item) {
              if (this.item.ComboGroup) {
                this.numInvisibleGroups = this.item.ComboGroup.filter(
                  (x) => x.isHidden
                ).length;
              }
            }
          }
        } else {
          const item = this.itemTransferService.getItemV2();
          if (item) {
            this.setDetailByItem(item);
            if (this.item) {
              if (this.item.ComboGroup) {
                this.numInvisibleGroups = this.item.ComboGroup.filter(
                  (x) => x.isHidden
                ).length;
              }
            }
          }
        }
        this.checkIsReady();
      }));
  }

  private setEditItemDetail() {
    const cartItem = this.cartService.getCartItems().find(
      (x: any) => x.guid == this.guid
    );

    if (cartItem && cartItem != null) {
      const item = JSON.parse(JSON.stringify(cartItem)) as ItemV2;

      if (item != undefined && item.Modifiers.length > 0) {
        this.modifiers = item.Modifiers;
      }

      if (item != undefined && item.Variations.length > 0) {
        this.variations = item.Variations;
      }

      if (item != undefined && item.IsCombo) {
        if (item && item.ComboGroup.length > 0) {
          this.comboGroup = item.ComboGroup;
        }
      }

      this.item = item;
      this.totalComboGroups = item?.ComboGroup.length!;
      if (!this.item || this.item == null) return;

      this.itemTransferService.snapshotItemV2(this.item);

      this.cartQuantity = parseInt(
        (this.item ? Number(this.item.Quantity) : 1).toString()
      );

      this.quantity = this.item ? Number(this.item.Quantity) : 1;

      const cartItems = this.cartService.getCartItems().filter(
        (x) => x.ItemID == item.ItemID
      );

      if (cartItems && cartItems.length > 0) {
        const cartItemsQuantity = Number(
          cartItems.map((x) => Number(x.Quantity)).reduce((a, b) => a + b, 0)
        );

        this.cartQuantity = cartItemsQuantity;
      }

      this.cartQuantity = this.quantity - this.cartQuantity;
    }

    this.checkIsReady();
    return;
  }
  // on getting item from category subscription set all detail in component
  private setDetailByItem(categoryItem: ItemV2) {
    if (!categoryItem) return;

    const item = JSON.parse(JSON.stringify(categoryItem)) as ItemV2;

    if (!item) return;

    if (item.Modifiers && item.Modifiers.length > 0) {
      this.modifiers = item.Modifiers;
    }

    if (item.Variations && item.Variations.length > 0) {
      this.variations = item.Variations;
    }

    if (item.IsCombo && item.ComboGroup && item.ComboGroup.length > 0) {
      this.comboGroup = item.ComboGroup;
    }

    if (
      this.itemTransferService.itemV2?.OldPrice &&
      this.itemTransferService.itemV2?.OldPrice != undefined
    ) {
      item.Price = this.itemTransferService.itemV2?.Price;
      item.OldPrice = this.itemTransferService.itemV2?.OldPrice;
    }

    this.quantity = item?.OrderMinimumQuantity || 1;

    this.itemTransferService.snapshotItemV2(item);

    this.item = item;
  }

  open(
    item: any,
    isCombo: boolean,
    isComboItem: boolean,
    itemImageList: any[],
    totalComboGroups: number,
    selectedCount: number,
    numInvisibleGroups: number,
    isEdit: boolean = false
  ) {
    //const boxBuilderCom = new BoxBuilderComponent(this.itemTransferService,this.router,this.route,this.db,this.modalService,this.isKioskService);

    this.modalReference = this.modalService.open(BoxBuilderItemComponent, {
      centered: true,
      size: 'xl',
      windowClass: 'BoxBuilderPopup',
      modalDialogClass: 'BoxBuilderPopup_modelDialog',
    });

    this.modalReference.componentInstance.item = item;
    this.modalReference.componentInstance.item.IsCombo = isCombo; // sending to builder item
    // this.modalReference.componentInstance.modifiers = item.Modifiers; // Sending to builder item
    this.modalReference.componentInstance.isComboItem = isComboItem;
    this.modalReference.componentInstance.itemImageList = itemImageList;
    this.modalReference.componentInstance.totalComboGroups = totalComboGroups;
    this.modalReference.componentInstance.selectedCount = selectedCount;
    this.modalReference.componentInstance.numInvisibleGroups =
      numInvisibleGroups;
    this.modalReference.componentInstance.modalReference = this.modalReference;

    if (isEdit) {
      this.modalReference.componentInstance.isFromEdit = isEdit;
    } else {
      this.modalReference.componentInstance.item.ItemConsolidateQuantity = 1;
    }

    return this.modalReference;
  }

  close() {
    this.modalReference.close();
  }

  getSelectedCount(selectedCount: number) {
    this.selectedCount = selectedCount;
  }

  getItemImageList(itemImageList: any[]) {
    this.itemImageList = itemImageList;
  }

  isComboStateChanged(value: boolean) {
    this.item!.IsCombo = value;
  }

  closeModel(data: ItemV2 | null) {
    this.modalReference.close(data);
  }

  setSelectedModifiers(values: {
    inputModifier: ModifierV2[];
    isValid: boolean;
    modifierSelectedIndex: number;
  }) {
    this._selectedModifiers = Array.from(values.inputModifier);
    this.isModifiersValid = values.isValid;

    this.currentSelectedModifierIndex = values.modifierSelectedIndex;
    this.checkIsReady();
  }

  private checkIsReady() {
    let isValid = false;

    if (
      this.item &&
      (!this.item.ComboGroup || this.item.ComboGroup.length <= 0) &&
      (!this.item.Modifiers || this.item.Modifiers.length <= 0) &&
      (!this.item.Variations || this.item.Variations.length <= 0)
    ) {
      isValid = true;
    } else {
      if (this.comboGroup && this.comboGroup.length > 0) {
        isValid = this.isComboGroupValid;
      } else {
        if (this.modifiers) {
          if (this.isModifiersValid == undefined) {
            isValid = true;
          } else {
            isValid = this.isModifiersValid;
          }
        }
      }
    }

    // just to avoid having expressing change error in log
    // setTimeout(() => {
    this.isReady = isValid;
    // }, 500);
  }

  getComboItemFlags(comboItemFlags: any[]) {
    this.comboItemFlags = comboItemFlags;
  }

  cancel() {
    this.router.navigate(['/menu'], {
      queryParams: { category: this.CategoryID, concessionaireId: this.concessionaireId },
    });
  }

  // updateComboGroup(comboGroupList: ComboGroupV2[]) {
  //   // this.comboGroup = comboGroupList;
  //   this.boxBuilderItem.comboGroup = comboGroupList;
  //   if (this.boxBuilderItem) {
  //     this.boxBuilderItem.setComboItems(true);
  //   }
  // }

  reviewScreenOpen(result: boolean) {
    // this.boxBuilderItemFooter.reviewYourBox();
  }
}
