import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getLoyaltyCustomerResponse, PaytronixCustomerResponse } from 'src/app/models/loyaltyModal';
import { environment } from 'src/environments/environment';
import { GeneralSetting } from '../generalSetting.service';
import { IHttpHeaders } from '../http.service';
import { APIResponse, APIResponses, OLOPaytronixCustomerResponse, ReferrizerCustomerAPIResponse, ReferrizerCustomerResponse } from './Models';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseApiUrl: string = environment.apiUrl;
  private companyId = '';
  private branchId = '';
  constructor(
    private readonly http: HttpClient,
  ) {
  }
  defaultHandler(err: any): Promise<APIResponse> {
    return new Promise<APIResponse>((resolve, reject) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status == 401) {
          reject({ Code: APIResponses.UnAuthorized, res: err });
        } else if (err.status == 0 && err.ok == false) {
          resolve({ Code: APIResponses.Offline, res: err });
        } else {
          reject({ Code: 0, res: err });
        }
      }
    });
  }
  retry(
    fn: Function,
    fnArgs: any[],
    err: Function = (a: any) => {
      return new Promise<void>((resolve) => {
        resolve(a);
      });
    },
    errArgs: any[] = [],
    attempts: number = 4,
    delay: number = 2,
    errorArr: any[] = []
  ) {
    return new Promise((resolve, reject) => {
      let errArr: any[] = errorArr;
      let args: any = errArgs;
      fn(...fnArgs)
        .then((data: any) => {
          resolve({ data, errArr });
        })
        .catch((error: any) => {
          err(error, ...args).then(
            (data: any) => {
              errArr.push(data);
              if (attempts === 0) {
                reject(errArr);
                return;
              }
              setTimeout(() => {
                this.retry(
                  fn,
                  fnArgs,
                  err,
                  args,
                  attempts - 1,
                  delay * 2,
                  errArr
                ).then(
                  (data: any) => {
                    resolve(data);
                  },
                  (error: any) => {
                    reject(error);
                  }
                );
              }, delay * 1000);
            },
            (data: any) => {
              errArr.push(data);
              //logs the reason for error being thrown
              //console.log(APIResponses[data.Code])
              reject(errArr);
              return;
            }
          );
        });
    });
  }
   getOLOPaytronixCustomer(username: string, password: string) {
    let getOLOPaytronixCustomerErrorHandler = (a: any) => {
      return new Promise<APIResponse>((resolve, reject) => {
        this.defaultHandler(a).then(
          (data: APIResponse) => {
            resolve(data);
          },
          (data: APIResponse) => {
            reject(data);
          }
        );
      });
    };
    return new Promise<OLOPaytronixCustomerResponse>((resolve, reject) => {
      this.retry(
        this.getOLOPaytronixCustomerAPI.bind(this),
        [username,password],
        getOLOPaytronixCustomerErrorHandler
        ).then(
          (data: any) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    })    
  }
  /**
   * @description should not be called directly
   * @description fetches Paytronix customer with entered data. No other sign in calls used, paytronix does not use phone number
   * @param username username of the user to be fetched
   * @param password password of the user to be fetched
   */
  private getOLOPaytronixCustomerAPI(username: string, password: string) {
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();
    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId);
    let body = {
      username: username,
      password: password,
    };

    return this.http.post<PaytronixCustomerResponse>(
      this.baseApiUrl + 'OLOPaytronixAuthenticateGuest',
      body,
      { headers }
    ).toPromise();
  }
  getLevelUpCustomerByPhone(phone: string, qr: boolean = false){
    let getLevelUpCustomerByPhoneErrorHandler = (a: any) => {
      return new Promise<APIResponse>((resolve, reject) => {
        this.defaultHandler(a).then(
          (data: APIResponse) => {
            resolve(data);
          },
          (data: APIResponse) => {
            reject(data);
          }
        );
      });
    };
    return new Promise<any>((resolve, reject) => {
      this.retry(
        this.getLevelUpCustomerByPhoneAPI.bind(this),
        [phone,qr],
        getLevelUpCustomerByPhoneErrorHandler
        ).then(
          (data: any) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    })
  }
  private getLevelUpCustomerByPhoneAPI(phone: string, qr: boolean){
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();
    const headers = new HttpHeaders()
      .set('Version', '1.0')
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('DeviceID', 'sdfsd15145151515');
    let body = {
      customer: {
        payment_token_data: phone,
        isMobile: !qr,
      },
    };
    return this.http.post<getLoyaltyCustomerResponse>(
      this.baseApiUrl + 'LevelUpCustomerDetailsSK',
      body,
      { headers }
    ).toPromise()
  }
  getReferrizerCustomerByPhone(phone: string) {
    let getReferrizerCustomerByPhoneErrorHandler = (a: any) => {
      return new Promise<APIResponse>((resolve, reject) => {
        this.defaultHandler(a).then(
          (data: APIResponse) => {
            resolve(data);
          },
          (data: APIResponse) => {
            reject(data);
          }
        );
      });
    };
    return new Promise<ReferrizerCustomerResponse>((resolve, reject) => {
      this.retry(
        this.getReferrizerCustomerByPhoneAPI.bind(this),
        [phone],
        getReferrizerCustomerByPhoneErrorHandler
        ).then(
          (data: any) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    })


  }
  private getReferrizerCustomerByPhoneAPI(phone: string) {
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();

    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId);

    let modal = {
      phone: phone,
    };

    return this.http.post<ReferrizerCustomerAPIResponse>(
      this.baseApiUrl + 'GetLoyaltyCustomerV3',
      modal,
      { headers }
    ).toPromise()
  }
  oloPunchhQRLogin(qr:string){
    let oloPunchhQRLoginErrorHandler = (a: any) => {
      return new Promise<APIResponse>((resolve, reject) => {
        this.defaultHandler(a).then(
          (data: APIResponse) => {
            resolve(data);
          },
          (data: APIResponse) => {
            reject(data);
          }
        );
      });
    };
    return new Promise<any>((resolve, reject) => {
      this.retry(
        this.oloPunchhQRLoginAPI.bind(this),
        [qr],
        oloPunchhQRLoginErrorHandler
        ).then(
          (data: any) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    })

  }
  private oloPunchhQRLoginAPI(qr: string) {
    let basket = {
      QRCode: qr,
    };
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();
    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2');
    return this.http
      .post<any>(this.baseApiUrl + 'OLO_SigninwithQRCodeV2SK', basket, {
        headers,
      })
      .toPromise();
  }
  getPunchhCustomer(phone: string) {
    let getPunchhCustomerErrorHandler = (a: any) => {
      return new Promise<APIResponse>((resolve, reject) => {
        this.defaultHandler(a).then(
          (data: APIResponse) => {
            resolve(data);
          },
          (data: APIResponse) => {
            reject(data);
          }
        );
      });
    };
    return new Promise<any>((resolve, reject) => {
      this.retry(
        this.getPunchhCustomerAPI.bind(this),
        [phone],
        getPunchhCustomerErrorHandler
        ).then(
          (data: any) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    })
  }
  private getPunchhCustomerAPI(phone: string) {
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();

    const headers = new HttpHeaders()

      .set('Version', '2')
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId);

    let modal = {
      Phonenumber: phone,
    };
    return this.http.post<getLoyaltyCustomerResponse>(
      this.baseApiUrl + 'OLO_SigninwithPhoneV2SK',
      modal,
      { headers }
    ).toPromise()
  }

  getPunchhCustomerExternal(email: string, password: string, phone: string) {
    let getPunchhCustomerExternalErrorHandler = (a: any) => {
      return new Promise<APIResponse>((resolve, reject) => {
        this.defaultHandler(a).then(
          (data: APIResponse) => {
            resolve(data);
          },
          (data: APIResponse) => {
            reject(data);
          }
        );
      });
    };
    return new Promise<any>((resolve, reject) => {
      this.retry(
        this.getPunchhCustomerExternalAPI.bind(this),
        [email,password,phone],
        getPunchhCustomerExternalErrorHandler
        ).then(
          (data: any) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    })
  }
  private getPunchhCustomerExternalAPI(email: string, password: string, phone: string) {
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();
    const headers = new HttpHeaders()
      .set('Version', '2')
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId);
    let modal = {
      email: email,
      password: password,
      phone: phone,
    };

    return this.http.post<getLoyaltyCustomerResponse>(
      this.baseApiUrl + 'SSO_SigninV2SK',
      modal,
      { headers }
    ).toPromise()
  }
}
