import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemV2 } from 'src/app/models/item';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { ModifierV2 } from '../../../models/item';

@Component({
  selector: 'app-sold-out',
  templateUrl: './sold-out.component.html',
  styleUrls: ['./sold-out.component.css'],
})
export class SoldOutComponent implements OnInit {

  imageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  topText: string = '';

  bottomText: string = '';

  item: ModifierV2[] = new Array<ModifierV2>();

  modalReference: any;

  parent: ItemV2 = new ItemV2();

  constructor(
    private modalService: NgbModal,
    private isKioskService: IsKioskService,
    private language:LanguageService
  ) { }

  ngOnInit(): void { }

  formatText(item: ModifierV2[]) {
    //future implementation
    //language specific resource files
    //will require custom formatting

    // Making changes as per General kiosk - Rekha
    // if (item.length === 1) {
    //   this.bottomText = item[0].Name;
    //   this.topText = this.bottomText + ' is ';
    // } else if (item.length === 2) {
    //   this.bottomText = item[0].Name + ' and ' + item[1].Name;
    //   this.topText = this.bottomText + ' are ';
    // } else {
    //   for (let i = 0; i <= item.length - 1; i++) {
    //     this.bottomText += item[i].Name;
    //     if (i < item.length - 2) {
    //       this.bottomText += ', ';
    //     } else if (i < item.length - 1) {
    //       this.bottomText += ' and ';
    //     }
    //   }
    //   this.topText = this.bottomText + ' are ';
    // }

    if (item.length > 0 ) {
      this.bottomText = "'"+this.getName(item[0])+"'";
      this.topText = this.bottomText + ' is ';
    }
  }

  public static open(modalService: NgbModal, item: ModifierV2[], parent: ItemV2) {
    function getName(item: ModifierV2): string {
      const displayName = item.DisplayName;
      const categoryName = item.Category;
      return (displayName && displayName != '') ? displayName : ((categoryName && categoryName != '') ? categoryName : '')
    }

    let bottomText = "";
    let topText = "";

    if (item.length > 0) {
      bottomText = "'" + getName(item[0]) + "'";
      topText = bottomText + ' is ';
    }

    let modalReference = modalService.open(SoldOutComponent, {
      centered: true,
      size: 'lg',
    });

    modalReference.componentInstance.item = item;
    modalReference.componentInstance.parent = parent;
    modalReference.componentInstance.topText = topText;
    modalReference.componentInstance.bottomText = bottomText;
    modalReference.componentInstance.modalReference = modalReference;

    return modalReference;
  }

  open(item: ModifierV2[], parent: ItemV2) {
    this.formatText(item);

    this.item = item;
    this.parent = parent;

    let modalReference = this.modalService.open(SoldOutComponent, {
      centered: true,
      size: 'lg',
    });
    modalReference.componentInstance.item = this.item;
    modalReference.componentInstance.parent = this.parent;
    modalReference.componentInstance.topText = this.topText;
    modalReference.componentInstance.bottomText = this.bottomText;
    modalReference.componentInstance.modalReference = modalReference;
    this.modalReference = modalReference;
  }

  continue() {
    this.modalReference.close('continue');
  }

  backToMenu() {
    this.modalReference.close('back');
  }
  
  private getName(item : ModifierV2) : string{
    const displayName = item.DisplayName;
    const categoryName = item.Category;
    return (displayName && displayName !='') ?  displayName : ((categoryName && categoryName !='') ?  categoryName : '')
  }
}
