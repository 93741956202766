<!-- Payment Modal -->
<section>
  <div id="payment-popup" role="document">
    <div class="payment-modal">
      <!-- <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="canceled()"
      >
        <span aria-hidden="true">&times;</span>
      </button> -->
      <h1 class="text-center">{{ checkoutText }}</h1>
      <h2 class="text-center" [ngStyle]="{ color: priceColor }">
        {{
          isOLO
            | functionCall
              : getTotal
              : oloValidationRes
              : total
              : isOracle
              : oracleCalcualtion
            | number: "1.2-2"
            | CurrencyPipe
        }}
      </h2>
      <h4 class="text-center">{{totalDueText}}</h4>
      <h3 class="text-center" style="width: 100%; padding: 0 5rem 0 5rem">
        {{ paymentMethodText }}
      </h3>
      <div
        class="col-md-12 col-xs-12 {{
          isOLO || isParBrink || isOracle ? 'p-2' : 'p-0'
        }} total-amount"
        *ngIf="isOLO || isParBrink || isOracle"
      >
        <div class="row">
          <div class="col-md-6 col-xs-6">{{ subTotalText }}:</div>
          <div class="col-md-6 col-xs-6 text-right subTotal">
            {{
              isOLO
                | functionCall
                  : getSubTotal
                  : oloValidationRes
                  : subtotal
                  : isOracle
                  : oracleCalcualtion
                  : isOracleMicross
                  : reward
                | number: "1.2-2"
                | CurrencyPipe
            }}
          </div>
        </div>
        <div class="row" *ngIf="oloFee">
          <div class="col-md-6 col-xs-6">{{serviceFeeText}}</div>
          <div class="col-md-6 col-xs-6 text-right">
            {{ oloFee | number: "1.2-2" | CurrencyPipe }}
          </div>
        </div>
        <div class="row" *ngIf="discount > 0">
          <div class="col-md-6 col-xs-6">{{ discountText }}</div>
          <div
            class="col-md-6 col-xs-6 text-right taxTotal"
            [ngStyle]="{ color: positiveButton }"
          >
            -{{ discount | number: "1.2-2" | CurrencyPipe }}
          </div>
        </div>
        <div class="row" *ngIf="reward > 0">
          <div class="col-md-6 col-xs-6">{{ rewardText }}</div>
          <div
            class="col-md-6 col-xs-6 text-right taxTotal"
            [ngStyle]="{ color: positiveButton }"
          >
            -{{ reward | number: "1.2-2" | CurrencyPipe }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-6">
            {{ taxType == "2" ? vatText : taxesAndFees + ':' }}
          </div>
          <div class="col-md-6 col-xs-6 text-right taxTotal">
            {{
              isOLO
                | functionCall
                  : getTax
                  : oloValidationRes
                  : tax
                  : isOracle
                  : oracleCalcualtion
                | number: "1.2-2"
                | CurrencyPipe
            }}
          </div>
        </div>
        <div class="row" *ngIf="tip > 0">
          <div class="col-md-6 col-xs-6">{{tipText}}:</div>
          <div class="col-md-6 col-xs-6 text-right taxTotal">
            {{ tip | number: "1.2-2" | CurrencyPipe }}
          </div>
        </div>
        <div class="row total">
          <div class="col-md-6 col-xs-6">{{ totalText }}</div>
          <div
            class="col-md-6 col-xs-6 text-right totalAmmount"
            [ngStyle]="{ color: priceColor }"
          >
            {{
              isOLO
                | functionCall
                  : getTotal
                  : oloValidationRes
                  : total
                  : isOracle
                  : oracleCalcualtion
                | number: "1.2-2"
                | CurrencyPipe
            }}
          </div>
        </div>
      </div>
      <!-- <div
        class="promo-section"
        *ngIf="isOLO && !(discount > 0) && !hasLoyaltyReward"
      >
        <p>{{applyDiscountText}}</p>
        <div class="d-flex">
          <input
            KeyboardCustom
            customLength="350"
            readonly
            name="discountCode"
            [(ngModel)]="promoInput"
            class="promo-input"
            type="text"
            placeholder="Enter discount code here"
            id="promoCode"
            maxlength="50"
            [ngClass]="{ disabled: isDiscountApplied }"
            [ngStyle]="{ color: positiveButton }"
          />

          <button
            (click)="applyOrRemoveOloDiscount()"
            class="apply"
            [ngStyle]="{
              'background-color': positiveButton,
              color: positiveButtonText
            }"
          >
            <span>{{
              isDiscountApplied ? removeText : (applyText | uppercase)
            }}</span>
          </button>
        </div>
        <span class="err-msg" *ngIf="discountErrorMessage != ''">
          <i class="fas fa-exclamation-circle"></i>
          {{ discountErrorMessage }}
        </span>
      </div> -->

      <div class="pay-with-container">
        <div
          *ngFor="let type of paymentTypes"
          class="col-md-12 col-xs-12 row pay-with"
          matRipple
          (click)="selectedPayment(type)"
        >
          <div class="col-md-3 col-xs-3">
            <img
              [src]="
                type.PaymentTypeID != '22'
                  ? 'assets/images/' + type.PaymentTypeID + '.png'
                  : 'assets/images/pay-with-loyalty.png'
              " class="img-responsive"
            />
          </div>
          <div class="col-md-9 col-xs-9">
            <h3>
              {{ type.PaymentDisplayName }}
              <span
                *ngIf="
                  type.PaymentTypeID === '2' &&
                  this.usePercentageCardKIOSK === 'True'
                "
                >*</span
              >
            </h3>
            <h4
              class="totalAmmount"
              [ngStyle]="{ color: priceColor }"
              *ngIf="type.PaymentTypeID == '2'; else showTotal"
            >
              {{ cardTotal | number: "1.2-2" | CurrencyPipe }}
            </h4>
            <ng-template #showTotal>
              <h4 class="totalAmmount" [ngStyle]="{ color: priceColor }">
                {{
                  isOLO
                    | functionCall
                      : getTotal
                      : oloValidationRes
                      : total
                      : isOracle
                      : oracleCalcualtion
                    | number: "1.2-2"
                    | CurrencyPipe
                }}
              </h4>
            </ng-template>
          </div>
        </div>
      </div>
      <h5>{{ nonCashFeeText }}</h5>
      <div
        matRipple
        *ngIf="showTipDialog == 'True'"
        class="edit-tip"
        (click)="editTip()"
      >
        <button (click)="editTip()">{{ editTipText | uppercase }}</button>
      </div>
      <p class="cancel-button text-center" (click)="canceled()">
        {{ cancelText }} / {{ backToCartText }}
      </p>
    </div>
  </div>
</section>
