<div class="promo-box">
  <p class="header-text">{{enterPromoCodeText}}</p>
  <form [formGroup]="promoform" (ngSubmit)="apply()" novalidate>
  <div class="form-group">
    <label for="promoCode">{{promoCodeText}}</label>
    <input
    KeyboardCustom
    [readonly]="isKiosk"
    [ngStyle]="{ borderColor: submitted && f.promoCode.errors ? 'red' : '' }"
      [(ngModel)]="promoInput"
      class="promo-input"
      type="text"
      formControlName="promoCode"
      id="promoCode"
      maxlength="50"
    />
    <div *ngIf="submitted && f.promoCode.errors">
      <div *ngIf="f.promoCode.errors.required" class="lmargin10 font-size3">
        <p class='font-size5 red'>{{enterPromoCodeText}}</p>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button
      (click)="apply()"
      class="apply background-positive-button-color border-positive-button-color positive-button-text-color"
    >
      {{applyCodeText}}
    </button>
    <button
      (click)="cancel()"
      class="cancel negative-button-text-color border-negative-button-color background-negative-button-color"
    >
      {{cancelText}}
    </button>
  </div>
</form>

</div>
