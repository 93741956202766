<div id="tabletent-popup" role="dialog">
  <div class="tabletent-popup-modal">
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="canceled()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <h1 class="text-center text-uppercase">{{ windowHeaderText }}</h1>
    <div class="content">
      <h2>{{ windowTitle }}</h2>
      <h4 class="text-center">{{ windowText }}</h4>
      <div class="tent-img" [ngStyle]="{'background-image': 'url(' + tentImage +')'}">
        <h6 *ngIf="!isCustomImage">{{tentNumberText}}</h6>
        <strong class="text-center" *ngIf="!isCustomImage">{{ tentNumber }}</strong>
      </div>
      <div class="input-group mb-3">
        <input KeyboardCustom customLength="3" layout="numeric" [readonly]="isKiosk" mask="000" #tentControl="ngModel"
          id="ttp" required [(ngModel)]="tentNumber" />
        <div class="input-group-append">
          <button (click)="confirmTent()" class="
              btn
              green-btn
              border-positive-button-color
              positive-button-text-color
              background-positive-button-color
            " type="button">
            <i class="fa fa-check"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>