import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-olo-apply-remove-discount-popup',
  templateUrl: './olo-apply-remove-discount-popup.component.html',
  styleUrls: ['./olo-apply-remove-discount-popup.component.css']
})
export class OloApplyRemoveDiscountPopupComponent implements OnInit,OnDestroy {

  modalReference: any;
  languageSub!: Subscription;

  constructor(private readonly modalService: NgbModal,private language:LanguageService) { }

  isApplyingDiscount: boolean = false;

  checkoutText:string=''
  applyingDiscountText:string=''
  removingDiscountText:string=''
  pleaseBePatientText:string=''
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText(){
    this.checkoutText=this.language.getTextElement('lbl_checkout')
    this.applyingDiscountText=this.language.getTextElement('applying_discount')
    this.removingDiscountText=this.language.getTextElement('removing_discount')
    this.pleaseBePatientText=this.language.getTextElement('please_be_patient_for_a_moment_while_we_calculate_your_order_discount')
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  public static open(modalService:NgbModal,isApplyingDiscount: boolean = true) {
    const modalReference =modalService.open(OloApplyRemoveDiscountPopupComponent, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });
    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.isApplyingDiscount = isApplyingDiscount;

    return modalReference;
  }

  open(isApplyingDiscount: boolean = true) {
    const modalReference = this.modalService.open(OloApplyRemoveDiscountPopupComponent, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });
    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.isApplyingDiscount = isApplyingDiscount;
    this.modalReference = modalReference;
  }

  clear() {
    this.modalReference.close();
  }
}
