<div class="container">
  <div *ngIf="case == 1">
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="clear(0)"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="header">
      <span class="top-text">{{ checkoutText }}</span>
    </div>      
    <div class="body">
      <div class="column">
        <div *ngIf="showPayment">
          <app-payment-total-row
            [total]="total"
            [subtotal]="subtotal"
            [discount]="discount"
            [tax]="tax"
            [shipping]="shipping"
            [reward]="reward"
          ></app-payment-total-row>
          <span class="top-text">{{ totalDueText }}</span>
        </div>
      </div>
      <div>
        <p class="top-text-body">
          {{ scanQRCodeText }}
        </p>
      </div>
      <img class="scan-img" src="assets/images/samsung-kiosk-scan.gif" />
    </div>
  </div>
  <div *ngIf="case == 2">
    <div class="body">
      <div class="column">

        <img src="assets/images/error.png" class="image" />

      </div>
      <div class="header">
        <div class="column just-contents-center">
          <span class="declined-text">{{ paymentDeclinedText }}</span>
        </div>
      </div>
      

      <button
        class="root-round3 even-margin2 buttons even-pad3 positive-button-text-color background-positive-button-color"
        (click)="clear(1)"
      >
        {{tryAgainText|uppercase}}
      </button>
      <button
        class="root-round3 even-margin2 buttons border-negative-button-color even-pad3 negative-button-text-color "
        (click)="clear(2)"
      >
        {{tryAnotherMethodText|uppercase}}
      </button>
    </div>
  </div>
</div>
