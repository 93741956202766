import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BowlmodeSelectionComponent } from './bowlmode-selection/bowlmode-selection.component';

@Component({
  selector: 'app-item-bowl-mode',
  templateUrl: './item-bowl-mode.component.html',
  styleUrls: ['./item-bowl-mode.component.css']
})
export class ItemBowlModeComponent implements OnInit {

  constructor(private modalRef : NgbModal) { }

  ngOnInit(): void {
  }

  open(){
    let selection = new BowlmodeSelectionComponent(this.modalRef);

    let modelRefrence = selection.open();

    modelRefrence.result.then((result: any) => {
      if (result == "cancel") {
        
      } else {
        
      }
    });
  }
}
