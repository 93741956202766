<!--<i (click)="back()" class="fas fa-arrow-left"></i> -->
<div class="col-md-12 col-12 header-report">
  <h2><span>{{grossSalesText}}</span></h2>
  <h4>
    <input
    style="width: 12rem; font-weight: 700; margin-left: 1rem;margin-right: 1rem"
      matInput
      readonly
      [matDatepicker]="datePicker"
      [ngModel]="dt"
      (ngModelChange)="setGrossDate($event)"
      id="datePicker"
    />
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker touchUi #datePicker></mat-datepicker>
  </h4>
</div>
<br /><br />
<div class="row mb-3rem">
  <table class="table table-borderless border-white border-radius-10">
    <thead>
      <tr>
        <th scope="col">{{tokenNoText}}</th>
        <th scope="col">{{refOrderNoText}}</th>
        <th scope="col">{{invoiceNoText}}</th>
        <th scope="col">{{subtotalText}}</th>
        <th scope="col">{{taxText}}</th>
        <th scope="col">{{tipText}}</th>
        <th scope="col">{{upchargeText}}</th>
        <th scope="col">{{discountText}}</th>
        <th scope="col">{{totalText}}</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let orders of grossSalesOrders">
        <td>{{ orders.T_TokenCount }}</td>
        <td>{{ orders.InvoiceRefNo }}</td>
        <td>{{ orders.OrderInvoiceNo }}</td>
        <td>{{ orders.Subtotal | number: "1.2-2" | CurrencyPipe }}</td>
        <td>{{ orders.OrderTax | number: "1.2-2" | CurrencyPipe }}</td>
        <td>{{ orders.OrderTip | number: "1.2-2" | CurrencyPipe }}</td>
        <td
          *ngIf="
            (orders.UpChargeAmountCard | numberConvert) == 0.0;
            else templateUpChargeAmountCard
          "
        >
          -{{naText|uppercase}}-
        </td>
        <ng-template #templateUpChargeAmountCard>
          <td>{{ orders.UpChargeAmountCard | number: "1.2-2" | CurrencyPipe }}</td>
        </ng-template>
        <td
          *ngIf="
            (orders.OrderDiscount | numberConvert) == 0.0;
            else templateDiscount
          "
        >
          -{{naText|uppercase}}-
        </td>
        <ng-template #templateDiscount>
          <td>{{ orders.OrderDiscount | number: "1.2-2" | CurrencyPipe }}</td>
        </ng-template>
        <td>{{ orders.OrderTotal | number: "1.2-2" | CurrencyPipe }}</td>
      </tr>
    </tbody>
  </table>
</div>
<br />
<br />
