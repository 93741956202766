import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FlexPairingService } from 'src/app/services/flex-pairing.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoaderService } from 'src/app/services/loader.service';
import { PaymentGatwayService } from 'src/app/services/payment-gatway.service';
import { PaymentTypesService } from 'src/app/services/payment-types.service';
import { Snackbar } from '../../../services/common';
import { DatabaseService } from '../../../services/database.service';
import { GeneralSetting } from '../../../services/generalSetting.service';
import { VerifoneService } from '../../../services/verifone.service';

@Component({
  selector: 'app-settings-payment',
  templateUrl: './settings-payment.component.html',
  styleUrls: ['./settings-payment.component.css'],
})
export class SettingsPaymentComponent implements OnInit,OnDestroy {
  TaxSchema!: Map<string, Array<any>>;
  boxchecked1: boolean = GeneralSetting.getAllowCash() === 'true';
  boxchecked2: boolean = GeneralSetting.getRoundUpTotal() === 'true';

  showVerifoneButton: boolean = false;
  cardPayment: string = '';
  hsn: string = ''
  merchantId = '';
  languageSub!: Subscription;
  paymentGatewayText: string = '';
  tcpIPText: string = '';
  roundUpTotalText: string = '';
  registerVerifoneText: string = '';
  allowCashText: string = '';
  pairFlexText: string = '';
  connectionTypeText: string = ''
  paymentSettingsText: string = ''
  deviceHsnText: string = ''
  merchantIdText: string = ''
  constructor(
    private verifoneService: VerifoneService,
    private paymentTypeService: PaymentTypesService,
    private readonly db: DatabaseService,
    private language: LanguageService,
    private loader:LoaderService,
    private paymentGatewayService: PaymentGatwayService,
    private flexPair:FlexPairingService
  ) {}

  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  pairFlex(){
    this.flexPair.checkPairing()
  }
  loadText() {
    this.paymentGatewayText=this.language.getTextElement('text_payment_gateway')
    this.tcpIPText=this.language.getTextElement('txt_tcp_ip')
    this.roundUpTotalText=this.language.getTextElement('text_round_up_total')
    this.registerVerifoneText=this.language.getTextElement('register_verifone')
    this.allowCashText=this.language.getTextElement('allow_cash')
    this.connectionTypeText=this.language.getTextElement('connection_type')
    this.paymentSettingsText=this.language.getTextElement('payment_settings')
    this.pairFlexText=this.language.getTextElement('pair_flex')
    this.deviceHsnText=this.language.getTextElement('lbl_device_hsn')
    this.merchantIdText=this.language.getTextElement('lbl_merchant_id')
  }

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.TaxSchema = new Map<string, Array<any>>();

    this.hsn = GeneralSetting.getDeviceHSN();
    this.merchantId = GeneralSetting.getMerchantId();

    this.db.gatherAllTaxesFromSql().then((taxGroups: any) => {
      taxGroups.forEach((taxGroup: any) => {
        if (this.TaxSchema.get(taxGroup.TaxGroupName)) {
          this.TaxSchema.get(taxGroup.TaxGroupName)?.push([
            taxGroup.TaxName,
            taxGroup.Percentage,
          ]);
        } else {
          this.TaxSchema.set(taxGroup.TaxGroupName, [
            [taxGroup.TaxName, taxGroup.Percentage],
          ]);
        }
      });
    });

    this.paymentTypeService.getCardPayment().then((data) => {
      if (data.length > 0) {
        let thirdPartyPaymentTypeIds = data.map((x) => x.ThirdPartyPaymentID);

        if (thirdPartyPaymentTypeIds.includes('17')) {
          this.showVerifoneButton = true;
        }

        this.cardPayment = data[0].PaymentTypeName;
      }
    });
  }

  onChecked(e: any, boxNumber: number) {
    var checkbox = e.target.checked;
    if (checkbox) {
      if (boxNumber === 1) {
        this.boxchecked1 = true;
        GeneralSetting.setAllowCash('true');
      }

      if (boxNumber === 2) {
        this.boxchecked2 = true;
        GeneralSetting.setRoundUpTotal('true');
      }
    } else {
      if (boxNumber === 1) {
        this.boxchecked1 = false;
        GeneralSetting.setAllowCash('false');
      }

      if (boxNumber === 2) {
        this.boxchecked2 = false;
        GeneralSetting.setRoundUpTotal('false');
      }
    }
  }

  async onVerifoneRegisterClick() {
    this.loader.openLoader();

    let registerRes = await this.paymentGatewayService.doConfigurePaymentDevice(true);

    this.loader.closeLoader();

    if (registerRes) {
      let message=this.language.getTextElement('registered_verifone_device')
      Snackbar.show(message, 2500);
    }
    else{
      let err=this.language.getTextElement('error_unable_register_device')
      Snackbar.show(err, 2500);
    }
  }



}
