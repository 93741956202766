import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from 'src/app/services/language.service';
import { PaymentErrorComponent } from './payment-error.component';

@Injectable({
  providedIn: 'root'
})
export class PaymentErrorDialogService {

  constructor(private modalService:NgbModal,
      private language: LanguageService) 
    { }


  public openPaymentErrorDialog(isVerifone: boolean, errorMessage: string) {
    return new Promise<void>((resolve, reject) => {
      let pec = PaymentErrorComponent.open(
        this.modalService,
        this.language,
        isVerifone,
        errorMessage
      );
      pec.result.then((data: any) => {
        resolve(data);
      });
    });
  }

  
}
