<div (click)="checkModifierValid()" class="modifier-container">
  <div id="{{ modifier.ModifierID }}"
       class="html-objects"
       *ngFor="let modifier of inputModifier;index as i;">
    <app-modifier (SelectedItem)="checkModifierValid()"
                  [modifierIndex]="i"
                  [modifier]="modifier"
                  (modifierChange)="modifierChange($event)"
                  [ItemType]="ItemType"
                  [ItemName]="ItemName"
                  [ingredients]="modifier.Ingredients && modifier.Ingredients.length > 0 ? modifier.Ingredients : []"
                  [ItemDescription]="ItemDescription"
                  >
    </app-modifier>
  </div>
</div>
