import { Injectable } from '@angular/core';
import { LogService } from 'src/app/services/log.service';
import { DatabaseService } from './database.service';
import { DatabaseHandler } from '../DatabaseHandler';

@Injectable({
  providedIn: 'root',
})
export class SMSService {
  _hasfetched: boolean = false;
  defaultMessage: string = 'Hi #. Your Order Number # is in progress.';
  SMSmessage: string = this.defaultMessage;
  canSMS: string = 'False';

  constructor(
    private logger: LogService,
    private readonly databaseService: DatabaseService
  ) {
    this.getSMSSetting();
  }
  getSMSSetting() {
    return new Promise<any>((resolve) => {

        this.getSMSsettingFromSql().then((element: any) => {
          this._hasfetched = true;
          this.canSMS = element.BranchIsSMS;
          this.SMSmessage =
            element.SMSText !== '' ? element.SMSText : this.defaultMessage;
          resolve('');
        });

    });
  }
  // grab SMS setting from local DB
  getSMSsettingFromSql(): Promise<any> {
    return new Promise((resolve) => {
      const ErrorCallBack = (tx: any, result: any) => {
        console.log('sms Service, Error getSMSsettingFromSql :- ', result);
      };

      const DataCallBack = (tx: any, result: any) => {
        resolve(result.rows[0]);
      };

      // find SMS setting for kiosk
      var sqlString =
        "SELECT * FROM NotificationSettings WHERE ModuleName = 'KIOSK'";
      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        DataCallBack,
        ErrorCallBack
      );
    });
  }
}
