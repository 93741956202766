import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { Snackbar } from 'src/app/services/common';
import { DataService } from 'src/app/services/data.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-item-weight',
  templateUrl: './item-weight.component.html',
  styleUrls: ['./item-weight.component.css'],
})
export class ItemWeightComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  modalReference: any;
  isgettingData = false;
  positiveButton: string | null = '';

  loadingText: string = '';
  continueText: string = '';
  placeOnScaleText: string = '';
  placeOnScaleByKioskText:string=''
  constructor(
    private modalservice: NgbModal,
    private dataService: DataService,
    private language: LanguageService
  ) {
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }

  loadText() {
    this.loadingText = this.language.getTextElement('txt_loading');
    this.continueText = this.language.getTextElement('lbl_continue');
    this.placeOnScaleText =this.language.getTextElement('place_your_bowl_on_the_scale')
    this.placeOnScaleByKioskText =this.language.getTextElement('place_your_bowl_on_the_scale_next_to_the_kiosk')
  }
  ngOnInit(): void {
    this.positiveButton = GeneralSetting.getPositiveColor();
  }

  public static open(modalservice: NgbModal) {
    var modalReference = modalservice.open(ItemWeightComponent, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });

    modalReference.componentInstance.modalReference = modalReference;

    return modalReference;
  }

  open() {
    this.modalReference = this.modalservice.open(ItemWeightComponent, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });
    this.modalReference.componentInstance.modalReference = this.modalReference;
  }

  continue() {
    this.getWeightOfItem();
  }

  getWeightOfItem() {
    this.isgettingData = true;

    this.allSubsCription.push(
      this.dataService
        .getWeight('W', GeneralSetting.getRaspberryPiIPAddress())
        .pipe(timeout(10000))
        .subscribe(
          (response: any) => {
            if (response && response != '') {
              this.modalReference.close(response);
              this.isgettingData = false;
            } else {
              this.isgettingData = false;
              this.modalReference.close('error');
            }
          },
          (error) => {
            this.isgettingData = false;
            this.modalReference.close('error');
          }
        )
    );
  }

  getAccentColor() {
    return GeneralSetting.getAccentColor();
  }

  cancel() {
    this.modalReference.close('cancel');
  }
}
