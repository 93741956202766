<button class="close-icon" (click)="!isBoth ? dismiss() :onClose.emit('back');">
    <svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78">
      <g id="x-circle" transform="translate(-0.551 -1)">
        <circle id="Ellipse_3" data-name="Ellipse 3" cx="38" cy="38" r="38" transform="translate(1.551 2)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <line id="Line_12" data-name="Line 12" x1="23" y2="23" transform="translate(28.551 28)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <line id="Line_13" data-name="Line 13" x2="23" y2="23" transform="translate(28.551 28)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </g>
    </svg>
    
  </button>
    <h1>{{termsAndConditionsText}}</h1>
    <iframe *ngIf="value" class="mt-3 i-frame" width="100%" height="100%" frameBorder="0" [src]="value"></iframe>
    <!-- <div class="row">
        <div class="col-6">
            <button class="btn back-btn" (click)="!isBoth ? dismiss() :onClose.emit('back');">Back</button>
        </div>
        <div class="col-6">
            <button class="btn green-btn" (click)="!isBoth ? dismiss() :onClose.emit('done');">Done</button>
        </div>
    </div> -->

