import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonFunctions } from './common';
import { GeneralSetting } from './generalSetting.service';

@Injectable({
  providedIn: 'root'
})
export class CancelPaymentService {

  constructor(
    private readonly http: HttpClient
  ) {}

  public doCancelPayment() {
    this.doCancelOrder()
      .toPromise()
      .then(async (data: any) => {
        if (!data.RESPONSE || data.RESPONSE.RESULT_CODE != '-1') {
          console.log('Cancel Response :- ', data);
        }
      }, error => {
        console.log('Cancel Payment Error :- ', error);
      });
  }

  doCancelOrder() {
    const currentCounter = CommonFunctions.increaseVerifonePaymentCounter();;

    const body = {
      message: GeneralSetting.getVerifoneDecryptedMessage(),
      counter: currentCounter,
      macLable: GeneralSetting.getVerifoneMacLabel(),
    };

    return this.http
      .post<any>(environment.NodeUrl + 'doCancelOrder', body);
  }

}
