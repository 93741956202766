<!-- <div class="titles grid-container">
    <p [style.color]="bkgdColor" class="grid-item">ITEM</p>
    <p [style.color]="bkgdColor" class="grid-item">QTY</p>
    <p [style.color]="bkgdColor" class="grid-item"> PRICE</p>
</div> -->
<div @fadeInGrow id="cartList" *ngIf="cartItems && cartItems.length > 0" #cartitemContainerDiv (scroll)="onScroll($event)">
  <div class="scroll-btn" *ngIf="checkScrollCheck"
  style="bottom :{{ hasPromos | functionCall : setScrollArrowPosition : loyaltyPromos : hasPromosCoupon : isLoyalty}}">
    <span class="material-icons" *ngIf="!isBottom" (click)="scrollDown()">
        keyboard_double_arrow_down
    </span>
    <span class="material-icons" *ngIf="isBottom" (click)="scrollTop()">
        keyboard_double_arrow_up
        </span>
  </div>

  <ng-container *ngIf="isConcessionaire">
    <div *ngFor="let con of uniqueConcessionaire">
      <!-- <div class="concessionaireName" [style.color]="con.ConcessionaireColorCode"> -->
      <div class="concessionaireName">
        <span>{{ con.ConcessionaireName }}</span>
      </div>

      <app-orderreviewitem-list [ConcessionaireId]="con.ConcessionaireId"
                                [cartItems]="cartItems"
                                [sibling]="sibling"
                                [promo]="promo"
                                [smbdiscount]="smbdiscount"
                                (setScrollArrow)="onScroll($event)">
      </app-orderreviewitem-list>

    </div>
  </ng-container>

  <ng-container *ngIf="!isConcessionaire">
    <app-orderreviewitem-list
                              [ConcessionaireId]="''"
                              [cartItems]="cartItems"
                              [sibling]="sibling"
                              [promo]="promo"
                              [smbdiscount]="smbdiscount"
                              >
    </app-orderreviewitem-list>
  </ng-container>
</div>


