<div class="modal-content"  id="OLOTotalLoader">
  <div class="modal-body">
    <h1 class="text-center"
    style="margin-bottom: 4rem;
    font-size: 4rem;
    font-weight: 700;">{{headerText}}</h1>
    <div class="content">
      <h1 *ngIf="!isOrderUp&&!isRewardCheck" class="text-center" style="font-size: 50px;"  ><b>{{calculatingTotalText}}</b></h1>
      <h1 *ngIf="!isOrderUp&&!isRewardCheck" class="text-center">{{taxAndTotalText}}</h1>
      <h1 *ngIf="isRewardCheck" class="text-center">{{availableRewardText}}</h1>
      <h1 *ngIf="isLevelUpPayment" class="text-center">{{processingPaymentText}}</h1>

      <img src="../../../../assets/images/loading/olo_animation.gif" onerror="this.onerror=null; this.src='assets/images/Image_21.png'" class="col-md-6 offset-md-3 col-6 col-offset-3" alt=""/>
    </div>
  </div>
</div>