import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ItemV2 } from 'src/app/models/item';
import { Snackbar } from 'src/app/services/common';
import { DataService } from 'src/app/services/data.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-choose-donut-customize-popup',
  templateUrl: './choose-donut-customize-popup.component.html',
  styleUrls: ['../../box-builder/box-builder.component.css'],
})
export class ChooseDonutCustomizePopupComponent implements OnInit {
  ngOnDestroy() {
    if (this.allSubscription.length > 0) {
      for (let i = 0; i < this.allSubscription.length; i++) {
        this.allSubscription[i].unsubscribe();
      }
    }
  }

  ref: any;

  allSubscription: Subscription[] = [];

  totalQuantity = [] as any[];

  selectedQuantity: number = -1;

  totalSelectedCount: number = 0;

  comboItem: ItemV2 = new ItemV2();

  imageBaseUrl: string = '';

  isEdit: boolean = false;

  itemQuantity: number = 0;

  howManyText: string = '';

  placeholderImage : string = '';
  
  addToBoxText: string = '';

  constructor(
    private readonly modalService: NgbModal,
    private language: LanguageService,
    private readonly dataService: DataService
  ) {}

  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.allSubscription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }

  loadText() {
    this.howManyText = this.language.getTextElement('how_many_of_these');
    this.addToBoxText = this.language.getTextElement('add_to_donut_box');
  }

  public static open(
    modalService: NgbModal,
    isEdit: boolean,
    imagebaseUrl: string,
    comboItem: ItemV2,
    comboGroupLength: number,
    selectedCount: number
  ) {
    var ref = modalService.open(ChooseDonutCustomizePopupComponent, {
      centered: true,
      modalDialogClass: 'cd-pop',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.totalQuantity = Array.from(
      Array(comboGroupLength).keys()
    );
    ref.componentInstance.totalSelectedCount = selectedCount;
    ref.componentInstance.comboItem = comboItem;
    ref.componentInstance.imageBaseUrl = imagebaseUrl;
    ref.componentInstance.isEdit = isEdit;
    ref.componentInstance.itemQuantity = comboItem.ItemConsolidateQuantity;
    if (isEdit) {
      ref.componentInstance.selectedQuantity =
        comboItem.ItemConsolidateQuantity - 1;
    } else {
      ref.componentInstance.selectedQuantity = 0;
    }
    return ref;
  }

  open(
    isEdit: boolean,
    imagebaseUrl: string,
    comboItem: ItemV2,
    comboGroupLength: number,
    selectedCount: number
  ) {
    var ref = this.modalService.open(ChooseDonutCustomizePopupComponent, {
      centered: true,
      modalDialogClass: 'cd-pop',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.totalQuantity = Array.from(
      Array(comboGroupLength).keys()
    );
    ref.componentInstance.totalSelectedCount = selectedCount;
    ref.componentInstance.comboItem = comboItem;
    ref.componentInstance.imageBaseUrl = imagebaseUrl;
    ref.componentInstance.isEdit = isEdit;
    ref.componentInstance.itemQuantity = comboItem.ItemConsolidateQuantity;
    if (isEdit) {
      ref.componentInstance.selectedQuantity =
        comboItem.ItemConsolidateQuantity - 1;
    } else {
      ref.componentInstance.selectedQuantity = 0;
    }
    this.ref = ref;
  }

  getIsActive(pos: number): boolean {
    let editableQuantity =
      this.totalQuantity.length -
      this.totalSelectedCount +
      (this.isEdit ? this.itemQuantity : 0);
    return pos < editableQuantity;
  }

  onAddToBox() {
    if (this.selectedQuantity >= 0) {
      var result = { status: true, Quantity: +this.selectedQuantity + 1 };
      this.ref.close(result);
    } else {
      let error:string=this.language.getTextElement('select_quantity')
      Snackbar.show(error, 2000);
    }
  }

  onClick(event: any, pos: number) {
    this.selectedQuantity = pos;
  }

  deleteItem() {
    if (this.isEdit) {
      this.ref.close('delete');
    }
  }

  close() {
    this.ref.close('close');
  }

  getAccentColor(): string {
    return GeneralSetting.getAccentColor();
  }
}
