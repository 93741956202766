<ng-container *ngIf="item">
  <app-box-builder-header [Item]="item" [isCombo]="item.IsCombo" [selectedTotalCount]="selectedCount"
                          [numInvisibleGroups]="numInvisibleGroups" [itemImageList]="itemImageList" (itemModalClose)="closeModel($event)"
                          [totalComboGroups]="totalComboGroups">
  </app-box-builder-header>

  <ng-container *ngIf="item.IsCombo && comboGroup">
    <app-box-builder-combo [comboGroups]="comboGroup" [modifiers]="modifiers" [isCombo]="item.IsCombo"
                           [numInvisibleGroups]="numInvisibleGroups" [totalComboGroups]="totalComboGroups"
                           [selectedTotalCount]="selectedCount" (isComboChanged)="isComboStateChanged($event)"
                           (selectedCountEmitter)="getSelectedCount($event)" (itemImageListEmitter)="getItemImageList($event)"
                           (modifierEmitter)="setSelectedModifiers($event)" [inputItem]="item"
                           (openReviewScreenEmitter)="reviewScreenOpen($event)" (itemModalClose)="closeModel($event)"
                           (comboItemFlagsEmitter)="getComboItemFlags($event)"></app-box-builder-combo>
  </ng-container>

  <ng-container *ngIf="item.IsCombo && comboGroup">
    <app-box-builder-footer [categoryID]="category.CategoryID" [isCombo]="item.IsCombo" class="footer"
                            [isReady]="isReady" [isEdit]="isFromEdit" [itemImageList]="itemImageList" [isComboItem]="isComboItem"
                            [modifiers]="_selectedModifiers" [variations]="_selectedVariations" [comboGroup]="_selectedComboGroup"
                            [itemID]="item.ItemID" [comboGroups]="comboGroup" [categoryID]="category.CategoryID"
                            [isModifiersValid]="isModifiersValid" [isVariationsValid]="isVariationsValid"
                            [isComboGroupValid]="isComboGroupValid" [inputItem]="item" [boxBuilderItemDetailModal]="boxBuilderItem"
                            [quantityWhenAdd]="quantity" [cartQuantity]="cartQuantity" (itemModalClose)="closeModel($event)"
                            [currentSelectedModifierIndex]="currentSelectedModifierIndex" [selectedTotalCount]="selectedCount"
                            (selectedCountEmitter)="getSelectedCount($event)" [totalComboGroups]="totalComboGroups"
                            [comboItemFlags]="comboItemFlags" [numInvisibleGroups]="numInvisibleGroups"
                            (itemImageListEmitter)="getItemImageList($event)" (comboSelectionEmitter)="updateComboGroup($event)">
    </app-box-builder-footer>
  </ng-container>

</ng-container>


<div style="display:none;">
  <app-timeout style="display: none" [hidden]="true"></app-timeout>
</div>
