import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ItemV2, ComboGroupV2, ModifierV2 } from 'src/app/models/item';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { CategorySalesType, CommonFunctions } from 'src/app/services/common';
import { ItemSwitchWarningComponent } from 'src/app/components/dialogs/item-switch-warning/item-switch-warning.component';
import { ComboItemPopupComponent } from 'src/app/components/combo-item-popup/combo-item-popup.component';
import { AlcoholicOver21DialogComponent } from 'src/app/components/dialogs/alcoholic-over21-dialog/alcoholic-over21-dialog.component';
import { AlcoholicItemsExceedMaxComponent } from 'src/app/components/dialogs/alcoholic-items-exceed-max/alcoholic-items-exceed-max.component';
import { OutOfStockComponent } from 'src/app/components/out-of-stock/out-of-stock.component';
import { SoldOutComponent } from 'src/app/components/dialogs/sold-out/sold-out.component';
import { LanguageService } from 'src/app/services/language.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css'],
})
export class ItemsComponent implements OnInit {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  @Input() item: ItemV2 = {} as ItemV2;

  @Input() itemIndex: number = 0;

  @Input() itemType: number = 1;
  @Input() isSubCategory: boolean = false;

  @Input() parent: ComboGroupV2 = {} as ComboGroupV2;

  @Input() parentQuantity: number = 1;

  @Output() parentChange = new EventEmitter<ComboGroupV2>();

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  primaryColor: string = '';

  image: string = '';

  isSoldOut: boolean = false;

  itemPrice: string = '';

  quantity: number = 1;

  Price: string = '';

  hasModifier: boolean = true;
  showCalories:boolean=GeneralSetting.getShowCalorieCounts()=="True"
  isBuilderMode: boolean = false;
  priceColor: string | null = '';
  imageRadius: string = '';
  bordersTypeItem: boolean = false;
  placeholderImage : string = '';

  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();

  customizeText: string = '';
  calorieText: string = '';
  soldOutText: string = '';
  constructor(
    private readonly isKioskService: IsKioskService,
    private readonly modalService: NgbModal,
    private readonly router: Router,
    private language: LanguageService,
    private readonly dataService : DataService
  ) {
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }
  loadText() {
    this.calorieText = this.language.getTextElement('calorie_format_text');
    this.customizeText = this.language.getTextElement('txt_customize');
    this.soldOutText = this.language.getTextElement('sold_out');
  }
  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.isBuilderMode = this.router.url.startsWith('/ItemBuilderMode');
    this.image = this.getFormattedURL(this.item);
    this.primaryColor = GeneralSetting.getPrimaryColor();
    this.priceColor = GeneralSetting.getPriceColor();
    this.imageRadius = GeneralSetting.getBorderPercentage();
    this.bordersTypeItem =
      GeneralSetting.getBorderTypeItems().toLowerCase() == 'true';

    if (
      this.item.IsItem86 == 'False' ||
      (this.item.CurrentStock != null && Number(this.item.CurrentStock) <= 0)
    )
      this.isSoldOut = true;

    if (this.item) {
      this.setItemQuantity();
      this.setItemPrice();

      if (
        (this.item.ComboGroup && this.item.ComboGroup.length > 0) ||
        (this.item.AttributeCount && this.item.AttributeCount > 0) ||
        (this.item.ModifierCount && this.item.ModifierCount > 0)
      ) {
        this.hasModifier = true;
      } else {
        this.hasModifier = false;
      }
    }
  }

  private setItemQuantity() {
    if (this.item.Quantity) {
      this.quantity = Number(this.item.Quantity);
    }
  }

  private selectItemLogic(parent: ComboGroupV2, itemIndex: number): any {
    const item = parent.Items[itemIndex];

    if (!item) {
      return parent;
    }

    this.parent.count = CommonFunctions.getParentCountItem(this.parent);

    if (parent.count < parent.max) {
      parent.count++;

      item.isSelected = true;

      parent.IsSelected = true;
    } else if (parent.max === 1) {
      // if only 1 selectable switch selection to newly selected object
      parent.count = 1;

      for (const item of parent.Items) {
        item.isSelected = false;
      }

      item.isSelected = true;
      parent.IsSelected = true;
    }

    if (parent.count <= parent.max && parent.count >= parent.min) {
      parent.isValid = true;
    }

    this.parent.count = CommonFunctions.getParentCountItem(this.parent);
    return parent;
  }

  private openItemSwitchWarning(item: ItemV2) {
    ItemSwitchWarningComponent.open(this.modalService).result.then(
      (result: any) => {
        if (result == 'start') {
          if (!item.isSelected) {
            if (this.parent.count >= this.parent.max && this.parent.max == 1) {
              for (let i = 0; i < this.parent.Items.length; i++) {
                if (
                  this.parent.Items[i].isSelected &&
                  this.parent.Items[i].ItemCategorySalesTypeID ===
                    CategorySalesType.Alcohol.toString()
                ) {
                  const currentAlcohol = Number(
                    GeneralSetting.getCurrentCountofAlkol()
                  );
                  if (currentAlcohol > 0)
                    GeneralSetting.setCurrentCountofAlkol(
                      Number(
                        currentAlcohol -
                          Number(this.parentQuantity) * Number(this.quantity)
                      ).toString()
                    );
                }
                this.parent.Items[i].isSelected = false;
              }
            }
            this.openItemAddToCart(item);
          } else {
            if (
              item.ItemCategorySalesTypeID ===
              CategorySalesType.Alcohol.toString()
            ) {
              const currentAlcohol = Number(
                GeneralSetting.getCurrentCountofAlkol()
              );
              if (currentAlcohol > 0)
                GeneralSetting.setCurrentCountofAlkol(
                  Number(
                    currentAlcohol -
                      Number(this.parentQuantity) * Number(this.quantity)
                  ).toString()
                );
            }
            item.isSelected = false;
            this.parentChange.emit(this.parent);
          }
        }
      }
    );
  }

  private updateAlcoholCount(item: ItemV2) {
    const currentAlcohol = Number(GeneralSetting.getCurrentCountofAlkol());
    if (item.isSelected) {
      if (
        item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString()
      ) {
        this.AlcoholicItemChecks().then((data: string) => {
          if (data == 'over21') {
            GeneralSetting.setCurrentCountofAlkol(
              Number(
                currentAlcohol +
                  Number(this.parentQuantity) * Number(this.quantity)
              ).toString()
            );
          } else if (data == 'alcoholicExceed') {
            item.isSelected = false;
          } else {
            item.isSelected = false;
          }

          return;
        });
        this.parent.Items[this.itemIndex] = item;
        this.parentChange.emit(this.parent);
      } else {
        this.parent.Items[this.itemIndex] = item;
        this.parentChange.emit(this.parent);
        return;
      }
    } else {
      // alert('hii-2');
      if (
        item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString()
      ) {
        GeneralSetting.setCurrentCountofAlkol(
          Number(
            currentAlcohol - Number(this.parentQuantity) * Number(this.quantity)
          ).toString()
        );
      }
      this.parent.Items[this.itemIndex] = item;
      this.parentChange.emit(this.parent);
      return;
    }
  }

  private openItemAddToCart(item: ItemV2) {
    if (
      (!item.ComboGroup || item.ComboGroup.length <= 0) &&
      (!item.ModifierCount || item.ModifierCount <= 0) &&
      (!item.AttributeCount || item.AttributeCount <= 0)
    ) {
      item.isSelected = !item.isSelected;
      item.Quantity = '1';
      this.updateAlcoholCount(item);
    } else {
      if (!item.isSelected) {
        //alert('hii-2'); --dont touch
        CommonFunctions.checkForSoldOutV2(item).then((data: ModifierV2[]) => {
          if (data.length > 0) {
            if (GeneralSetting.getIsmDoNotAllowAddToCartKiosk() == 'True') {
              OutOfStockComponent.open(this.modalService,this.language, 1);
            } else {
              this.soldOutPath(data, item);
            }
          } else {
            this.redirectItem(item);
          }
        });
      } else {
        this.openItem(item);
      }
    }
  }

  /**
   * @implements hard-coded age of majority, to be changed in future versions
   * @description checks if the user has exceeded current amount ofalcoholic items allowed per order, and checks user age
   * @returns {Promise<string>} string data detailing whether user age is over/under legal drinking age. Currently hard-coded 21, soon to be localized
   */
  AlcoholicItemChecks(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let max: number = Number(
        GeneralSetting.getMaxAlcoholicItemsPerOrder() || 0
      );
      if (max > 0) {
        const current: number = Number(GeneralSetting.getCurrentCountofAlkol());
        let qtyvalue =
          Number(this.parentQuantity) * Number(this.quantity) + current;
        if (current === max || qtyvalue > max) {
          AlcoholicItemsExceedMaxComponent.open(this.modalService);
          resolve('alcoholicExceed');
        } else {
          let o21dm = AlcoholicOver21DialogComponent.open(this.modalService);
          o21dm.result.then((data: any) => {
            if (data === 'over') {
              resolve('over21');
            } else {
              resolve('under21');
            }
          });
        }
      }
    });
  }

  private checkQtyOfAlcohol() {
    const alcoholMaxQuantity =
      GeneralSetting.getMaxAlcoholicItemsPerOrder() == null ||
      GeneralSetting.getMaxAlcoholicItemsPerOrder() == ''
        ? 9999
        : Number(GeneralSetting.getMaxAlcoholicItemsPerOrder());

    if (!this.item) return;
    if (alcoholMaxQuantity <= 0) return;
    this.increaseQuantity();
  }

  private increaseQuantity() {
    const alkolMax: number = Number(
      GeneralSetting.getMaxAlcoholicItemsPerOrder()
    );
    this.parent.count = CommonFunctions.getParentCountItem(this.parent);
    let currentAlcoholCount = CommonFunctions.getLatestAlcoholicCount(
      this.parentQuantity,
      this.quantity,
      true
    );

    if (currentAlcoholCount > alkolMax) {
      AlcoholicItemsExceedMaxComponent.open(this.modalService);
    } else {
      this.quantity += 1;

      const item = this.parent.Items[this.itemIndex];

      item.Quantity = this.quantity.toString();

      this.setItemPrice();

      this.parent.count = CommonFunctions.getParentCountItem(this.parent);

      if (
        item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString()
      ) {
        GeneralSetting.setCurrentCountofAlkol(currentAlcoholCount.toString());
      }
    }

    this.parentChange.emit(this.parent);
  }

  private getFormattedURL(item: ItemV2): string {
    return (
      (item.ImageUrl &&
        (item.ImageUrl.startsWith(this.baseImageUrl)
          ? item.ImageUrl
          : this.baseImageUrl + item.ImageUrl)) ||
          (this.placeholderImage !=''? this.baseImageUrl+this.placeholderImage : '')
    );
  }

  private openItem(item: ItemV2) {
    //setting max qty that can be selected on item
    const maxParent = this.parent.max == 0 ? 9999 : this.parent.max;
    const currentSelected = this.parent.Items.filter(
      (x) => x.isSelected
    ).length;
    const itemMaxQty = maxParent - currentSelected;

    item.OrderMaximumQuantity = itemMaxQty;
    item.OrderMinimumQuantity = 1;

    const modalReference = ComboItemPopupComponent.open(
      this.modalService,
      item,
      this.itemType,
      this.isSubCategory
    );

    modalReference.result.then((result: any) => {
      if (result == 'cancel') {
        const item = this.parent.Items[this.itemIndex];

        //Todo check functionality in kiosk
        if (!item.isSelected) {
          item.isSelected = false;
        }

        this.parent.count = CommonFunctions.getParentCountItem(this.parent);

        this.parentChange.emit(this.parent);
      } else {
        const item = result as ItemV2;

        this.parent.Items[this.itemIndex] = item;

        this.parent = this.selectItemLogic(this.parent, this.itemIndex);

        this.parent.count = CommonFunctions.getParentCountItem(this.parent);
        if (item.isSelected) {
          if (
            item.ItemCategorySalesTypeID ===
            CategorySalesType.Alcohol.toString()
          ) {
            const currentAlcohol = Number(
              GeneralSetting.getCurrentCountofAlkol()
            );
            GeneralSetting.setCurrentCountofAlkol(
              Number(
                currentAlcohol +
                  Number(this.parentQuantity) * Number(this.quantity)
              ).toString()
            );
          }
          this.parentChange.emit(this.parent);
        }
      }
    });
  }

  private setItemPrice() {
    let price = Number(this.item.Price);

    price = price * this.quantity;

    if (price > 0) {
      this.itemPrice = CommonFunctions.roundDigit(price, 2).toFixed(2);
    } else {
      this.itemPrice = '';
    }
  }

  itemClicked() {
    const item = this.parent.Items[this.itemIndex];
    if (this.isSoldOut) return;
    if (!item) return;

    //Todo adding item86 check for inventory
    //if (this.parent.IsModifier86 == "False" || modifier.IsModifier86 == "False") return;

    this.parent.count = CommonFunctions.getParentCountItem(this.parent);

    if (item.isSelected) {
      this.openItemAddToCart(item);
      return;
    }

    if (this.parent.count >= this.parent.max && this.parent.max == 1) {
      this.openItemSwitchWarning(item);
      return;
    } else if (this.parent.count >= this.parent.max) {
      return;
    }
    this.openItemAddToCart(item);
    return;
  }

  onCloseClick(e: any) {
    e.preventDefault();
    e.stopPropagation();

    const item = this.parent.Items[this.itemIndex];

    if (!item) return false;
    if (this.hasModifier) {
      this.openItemSwitchWarning(item);
      return false;
    }

    item.isSelected = false;
    this.quantity = 1;
    if (item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString()) {
      let currentAlcoholCount = CommonFunctions.getLatestAlcoholicCount(
        this.parentQuantity,
        Number(item.Quantity),
        false
      );
      GeneralSetting.setCurrentCountofAlkol(currentAlcoholCount.toString());
    }
    this.parentChange.emit(this.parent);

    return false;
  }

  onQuantityIncrease(e: any) {
    e.preventDefault();
    e.stopPropagation();

    const max = this.parent.max;
    const count = CommonFunctions.getParentCountItem(this.parent);
    const alkolMax = GeneralSetting.getMaxAlcoholicItemsPerOrder();
    if (count == max) {
      return;
    }
    if (
      alkolMax !== null &&
      Number(alkolMax) > 0 &&
      this.item.ItemCategorySalesTypeID == CategorySalesType.Alcohol.toString()
    ) {
      this.checkQtyOfAlcohol();
    } else {
      this.quantity += 1;

      const item = this.parent.Items[this.itemIndex];

      item.Quantity = this.quantity.toString();

      this.setItemPrice();

      this.parent.count = CommonFunctions.getParentCountItem(this.parent);

      this.parentChange.emit(this.parent);
    }
  }

  onQuantityDecrease(e: any) {
    e.preventDefault();
    e.stopPropagation();

    if (
      this.item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString()
    ) {
      let currentAlcoholCount = CommonFunctions.getLatestAlcoholicCount(
        this.parentQuantity,
        this.quantity,
        false
      );
      GeneralSetting.setCurrentCountofAlkol(currentAlcoholCount.toString());
    }

    if (this.quantity == 1) {
      this.item.isSelected = false;

      this.item.Quantity = '1';

      this.parent.Items[this.itemIndex] = this.item;

      this.parent.count = CommonFunctions.getParentCountItem(this.parent);

      this.parentChange.emit(this.parent);
      return;
    }

    this.quantity -= 1;

    const item = this.parent.Items[this.itemIndex];
    item.Quantity = this.quantity.toString();

    this.parent.count = CommonFunctions.getParentCountItem(this.parent);

    this.setItemPrice();

    this.parentChange.emit(this.parent);
  }

  // Show dialog of 'Allow Add item to cart'
  private soldOutPath(data: ModifierV2[], item: ItemV2) {
    SoldOutComponent.open(this.modalService, data, item).result.then(
      (data: string) => {
        if (data == 'continue') {
          this.redirectItem(item);
        }
        //else{}
        //no else block
        //if reached, user selected back to menu
      }
    );
  }

  private redirectItem(item: ItemV2) {
    if (item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString()) {
      this.AlcoholicItemChecks().then((data: string) => {
        if (data == 'over21') {
          this.openItem(item);
        }
      });
    } else {
      this.openItem(item);
    }
  }
}
