import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { VariationComponent } from '../variation.component';
import { VariationOption } from '../../../../models/Variations';
import { environment } from '../../../../../environments/environment';
import { DataService } from 'src/app/services/data.service';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-variation-option',
  templateUrl: './variation-option.component.html',
  styleUrls: ['./variation-option.component.css'],
})
export class VariationOptionComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
      if(this.languageSub){
        this.languageSub.unsubscribe()
      }
  }
  @Input() variationOption!: VariationOption;
  @Input() varitaion: VariationComponent | undefined;
  @Input() isSelected: boolean = false;
  @Input() index!: number;
  @Input() isBuilderMode: boolean = false;
  primaryColor: string | null = '';
  priceColor: string | null = "";
  imageRadius:string = "";
  languageSub!:Subscription
  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  showCalories:boolean=GeneralSetting.getShowCalorieCounts()=="True"
  bordersTypeVariation:boolean = false;
  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();
  placeholderImage : string = '';
  calorieText:string=''
  constructor(private isKioskService: IsKioskService, private readonly dataService: DataService,private language:LanguageService) {}
  loadText(){
    this.calorieText=this.language.getTextElement('calorie_format_text')
  }
  ngOnInit(): void {
    this.languageSub=this.language.localeCommunicator.subscribe((val)=>{
      this.loadText()
    })
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.primaryColor = GeneralSetting.getPrimaryColor();
    this.priceColor = GeneralSetting.getPriceColor();
    this.imageRadius = GeneralSetting.getBorderPercentage();
    this.bordersTypeVariation = GeneralSetting.getBorderTypeVariations().toLowerCase() == "true";
  }

  Select(): void {
    //console.log(this.varitaion)
    this.varitaion?.setSelectedVariation(this.variationOption, this.index);
  }
  checkPrice(price: any): boolean {
    if (Number(price) > 0) {
      return true;
    } else {
      return false;
    }
  }
}