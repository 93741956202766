import { NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { RouterModule, Routes } from '@angular/router';
import { ConcessionaireSelectComponent } from './concessionaire-select/concessionaire-select.component';

export const routes: Routes = [
  {
    path: '',
    component: ConcessionaireSelectComponent,
    data: { animation: 'branchLogin' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }
  ],
})
export class SharedRoutingModule { }
