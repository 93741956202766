<div role="tabpanel" class="tab-pane" id="settings">
  <h2>
    {{printerSettingsText}}
    <button class="kiosk-setting-button" (click)="refreshKitchenPrintersWrapper()"
      >{{refreshText|uppercase}}</button>
  </h2>
  <div class="col-md-12 col-12 mb-3rem"><strong>{{kitchenPrintersText}}</strong></div>

  <div class="row mb-3rem" *ngIf="hasRefreshed; else loadingPrintersBlock">
    <table class="table table-borderless border-white border-radius-10">
      <thead>
        <tr>
          <th scope="col">{{printerNameText}}</th>
          <th scope="col">{{ipAddressText}}</th>
          <th scope="col">{{printerModelText}}</th>
          <th scope="col">{{statusText}}</th>
        </tr>
      </thead>
      <tbody *ngIf="KitchenPrinters.length > 0; else noPrintersBlock">
          <!--show printers-->
              <tr *ngFor="let printer of KitchenPrinters">
                <td scope="row">{{ printer.Name }}</td>
              <td scope="row" >{{ printer.IpAddress }}</td>
                <td scope="row">
                  {{ printer.ModelName }}
                  <!-- TM-88VI -->
                </td>
                <td scope="row" *ngIf="printer.BrandName == 'Star' || printer.BrandName == '8' || printer.BrandName == 'Seiko'; else readyOrNot">
                  <a class="btn-test-print" (click)="testStarPrint(printer)">{{testPrintText|uppercase}}</a>
                </td>
                <ng-template #readyOrNot>
                <td scope="row" *ngIf="printer.isConnected != 'true'; else readyBlock">
                 <p class="failure">{{notReadyText|uppercase}}</p>
                </td>
                <ng-template #readyBlock>
                  <td scope="row">
                   <p class="success">{{readyText|uppercase}}</p>
                  </td>
                </ng-template>
                </ng-template>
              </tr>

      </tbody>
      <ng-template #noPrintersBlock>
        <tr>
          <td scope="row">{{noPrintersText}}</td>
        </tr>
      </ng-template>
    </table>
  </div>

  <div class="col-md-12 col-12 mb-3rem">
    <strong> {{invoiceMerchantText}}</strong>
  </div>

  <div class="row mb-3rem" *ngIf="hasRefreshed; else loadingPrintersBlock">
    <table class="table table-borderless border-white border-radius-10">
      <thead>
        <tr>
          <th scope="col">{{printerNameText}}</th>
          <th scope="col">{{ipAddressText}}</th>
          <th scope="col">{{printerModelText}}</th>
          <th scope="col">{{statusText}}</th>
        </tr>
      </thead>
      <tbody *ngIf="InvoicePrinters.length > 0; else noPrintersBlock">
          <!--show printers-->
              <tr *ngFor="let printer of InvoicePrinters">
                <td scope="row">{{ printer.Name }}</td>
                <td scope="row" >{{ printer.IpAddress }}</td>
                <td scope="row">
                  {{ printer.ModelName }}
                  <!-- TM-88VI -->
                </td>
                <td scope="row" *ngIf="printer.BrandName == 'Star' || printer.BrandName == 'Seiko'; else readyOrNot">
                  <a class="btn-test-print" (click)="testStarPrint(printer)">{{testPrintText|uppercase}}</a>
                </td>
                <ng-template #readyOrNot>
                  <td scope="row" *ngIf="printer.isConnected != 'true'; else readyBlock">
                    <p class="failure">{{notReadyText|uppercase}}</p>
                  </td>
                  <ng-template #readyBlock>
                    <td scope="row">
                      <p class="success">{{readyText|uppercase}}</p>
                    </td>
                  </ng-template>
                </ng-template>
              </tr>

      </tbody>
      <ng-template #noPrintersBlock>
        <tr>
          <td scope="row">{{noPrintersText}}</td>
        </tr>
      </ng-template>
    </table>
  </div>
  <ng-template #loadingPrintersBlock>
        <div  style="display: flex; justify-content: center;">
          <div class="loading-overlay">
            <img src="/assets/images/loading_gif.gif" style="height:15rem;width:15rem; background:url('/assets/images/loading_gif.gif')" />
        </div>
     </div>
  </ng-template>

  <div class="row mb-3rem">
    <div class="col-md-6 col-6">{{baudRateText}}</div>
    <div class="col-md-6 col-6 text-left baudrate">
      9600
      <span class="switch_box">
        <input
          type="checkbox"
          class="switch_1"
          [checked]="baudRate"
          (change)="setBaudRate($event)"
        />
      </span>
      115200
    </div>
  </div>
  <!-- <div class=" col-md-12 col-12 mb-3rem">
    <strong>   Token Printer :</strong>
    </div>
    <div class=" row mb-3rem">
        <table class="table table-borderless border-white border-radius-10">
            <thead>
              <tr>
                <th scope="col">Printer Name</th>
                <th scope="col">IP Address</th>
                <th scope="col">Printer Modal</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">QA Kitchen</td>
                <td>192.168.1.165</td>
                <td>Epson TM-T88 &nbsp;&nbsp;<i class="fa fa-pencil"></i></td>
              </tr>
              <tr>
                <td colspan="3"><span class="success">Printer is Ready</span></td>
              </tr>

            </tbody>
          </table>
    </div> -->
  <div class="row mb-3rem">
    <div class="col-md-6 col-6">{{kioskText}}</div>
    <div class="col-md-6 col-6 text-right">{{ orderPrefix }}</div>
  </div>
  <div class="row mb-3rem">
    <div class="col-md-6 col-6">{{thankYouText}}</div>
    <div class="col-md-6 col-6 text-right" id="thankYouMessage">{{ printerThankyouMessage }}</div>
  </div>
  <div>
  </div>
</div>
