import { ItemAttributeOption, Ingredient } from './BranchLoginModels';
import { Variation, VariationOption } from '../models/Variations';
import { TaxPojo } from 'src/app/models/tax-pojo';
export enum ItemType {
  ClassicMode = 1,
  BuilderMode = 2,
  BowlMode = 3,
}

export interface Common {
  associatedOptions?: any[]; //all possible objects (Modifier | Ingredient | combo item)
  variations?: any; // pretty sure this is not actually used
  isSelected?: any;
  parentObject?: any; // reference to parent mostly for validation purposes see ***setDefaultsRecursive*** function
  count?: number; // count of selected options
  isValid?: boolean; // is within min and max
  ItemID?: string;
  select?: (arg: any) => any; // select function
  deselect?: () => void;
  getObjects?: () => any[]; // not used anymore to be removed
  default?: any; // i dont think this is used, to be removed
  selectedIngrediants?: any; // array of only selected ingredients
  ingrediants?: any; // not used
  associatedVariations?: any; // array of variations
  height?: number; // height is important for displaying nested mods, see ***flattenMods*** function
  ItemTags?: string[];
}

//Should be used for any type of item in the application
export interface IItemV2 {
  AppliedPromoName?: string;
  AttributeCount: number;
  Calorie?: string;
  MaxCalories?: string;
  cartId: string;
  CategoryID: string;
  CategoryName: string;
  ComboGroupID: string;
  DiscountAmount: number;
  DiscountID: number;
  DiscountPer: number;
  DiscountTypeID: number;
  EndTimes?: string[];
  FullDescription: string | undefined;
  GuestName: string;
  guid: string;
  IconImages: string[];
  ImageUrl: string | undefined;
  isAlcoholic?: boolean;
  IsAskForName?: string;
  IsBuilderMode: string;
  IsCombo: boolean;
  IsDiscount: boolean;
  IsUpSellItem: boolean;
  ItemCategorySalesTypeID: string;
  ItemID: string;
  ItemImage: string | null | undefined;
  ItemName: string | undefined;
  Name: string | undefined;
  OldPrice?: string | undefined;
  OrderMaximumQuantity: number;
  OrderMinimumQuantity: number;
  Price: string;
  Quantity: string;
  specialRequest: string;
  StartTimes?: string[];
  TaxGroupId: number;
  TaxPer: number;
  TaxPercentage: number;
  TotalTax: number;
  UpSellItemID: string;
  UpSellItemGuid: string;
  UpSellItemPrice: number;

  RefItemId?: string;
  ModifierCount: number;

  CommonModifierCount: number;
  totalPrice?: number;
  Kitchens?: string[];
  TimeActives?: string[];

  //Todo if any change needed check
  flattenedMods: any;

  Modifiers: ModifierV2[];
  Variations: any[];
  ComboGroup: ComboGroupV2[];

  AllowQuantitySelection: string;

  IsItemDetailScreenShowKiosk: string;

  CurrentStock: string;
  LowThreshold: string;
  SoldOut: boolean;
  isSelected: boolean;
  IsLoyaltyDiscountItem: boolean;
  IsLoyaltyItem?: boolean;
  taxDetail: TaxPojo[];

  VariationOption?: VariationOption[];
  ExtraPrice: string;
  DiscountName: string;
  BackgroundImage: string;
  IsCategory: boolean;

  ConcessionaireId: string;
  ConcessionaireName: string;
  ConcessionaireColorCode: string;
  nameAddedtoLast5: boolean;
  checkedInLast5: boolean;
  ShowasClassicModifierGroup?: string;
  LabelforItemSelection?: string;
  AskForTentNumber?: string;
  TableTentNumber?: string;
  UpsellDiscount?: string;
  totalUpsellDiscount?: string;
}

export class ItemV2 implements IItemV2 {
  AllowQuantitySelection: string = 'True';
  AppliedPromoName?: string;
  AttributeCount: number = 0;
  Calorie?: string = '';
  MaxCalories?: string = '';
  cartId: string = '';
  CategoryID: string = '';
  CategoryName: string = '';
  ComboGroupID: string = '';
  ComboInstanceID?: string = '';
  CommonModifierCount: number = 0;
  CustomMessage?: string = '';
  DiscountAmount: number = 0;
  DiscountAmountForCalulation: number = 0;
  DiscountID: number = 0;
  DiscountPer: number = 0;
  DiscountTypeID: number = 0;
  DisplayOrder?: string = '';
  EndTimes?: string[];
  flattenedMods: any = '';
  FullDescription: string = '';
  GuestName: string = '';
  guid: string = '';
  IconImages: string[] = [];
  ImageUrl: string | undefined = '';
  isAlcoholic?: boolean = false;
  IsAllowCustomize?: boolean = false;
  IsAskForName?: string = '';
  IsBuilderMode: string = '';
  IsCombo: boolean = false;
  IsDiscount: boolean = false;
  IsDefault?: 'True' | 'False' = 'False';
  IsItemDetailScreenShowKiosk: string = 'True';
  IsLoyaltyDiscountItem: boolean = false;
  IsLoyaltyItem?: boolean;
  isSelected: boolean = false;
  IsOpenTenderReward: boolean = false;
  RefModifierId?: string = '';
  RefIngredientId?: string = '';
  //IsSelected?: boolean = false;
  IsUpSellItem: boolean = false;
  ItemCategorySalesTypeID: string = '';
  ItemID: string = '';
  ItemImage: string | null | undefined = '';
  ItemName: string = '';
  Kitchens?: string[] = [] as string[];
  last5modsName?: string;
  ModifierCount: number = 0;
  Name: string = '';
  OldPrice?: string | undefined = undefined;
  openItem?: boolean = false
  OrderMaximumQuantity: number = 9999;
  OrderMinimumQuantity: number = 0;
  Price: string = '';
  Quantity: string = '0';
  RefItemId?: string = '';
  RefComboGroupItemID?: string = '';
  SelectionPrice?: number;
  Sku: string = ''
  specialRequest: string = '';
  StartTimes?: string[];

  TaxGroupId: number = 0;
  TaxPer: number = 0;
  TaxPercentage: number = 0;
  TimeActives?: string[];
  totalPrice?: number = 0;
  TotalTax: number = 0;
  UpSellItemGuid: string = '';
  UpSellItemID: string = "0";
  UpSellItemPrice: number = 0;

  Modifiers: ModifierV2[] = [] as ModifierV2[];
  Variations: Variation[] = [] as Variation[];
  ComboGroup: ComboGroupV2[] = [] as ComboGroupV2[];
  taxDetail: TaxPojo[] = [] as TaxPojo[];
  CurrentStock: string = '';
  LowThreshold: string = '';
  SoldOut: boolean = false;

  //Todo remove VariationOption With variation
  VariationOption?: VariationOption[] = [] as VariationOption[];
  ExtraPrice: string = '';
  DiscountName: string = "";
  KOTDisplayName: string = '';
  BackgroundImage: string = '';

  // orders only
  OrderItemID?: string; // these are used for order items
  ItemAttributeOptionID?: string;
  Ingredients?: ModifierIngredientV2[]; // this is also used for order items
  ComboID?: string;
  // For bogo discount
  PurchaseOrGet: string = '';
  GroupCategoryID: string = '';
  BogoPurchaseQuantity: string = '';
  BogoGetQuantity = '';
  BogoAppliedQty = '';
  RemainAppliedQty = "0";
  IsBOGO: string = "False";
  IsDiscountedItem: string = "False";
  IsPercentage: string = 'False';

  //Box Builder Mode;
  IsBoxBuilderMode: string = '';
  ItemConsolidateQuantity: number = 0;
  IsActive?: 'True' | 'False' = 'True';
  BoxBuilderItemGUID?: string = '';
  IsInvisibleItem: string = 'False';
  ShortDescription: string = '';
  IsCategory: boolean = false;

  ConcessionaireId: string = "";
  ConcessionaireName: string = "";
  ConcessionaireColorCode: string = "";
  nameAddedtoLast5: boolean = false;
  checkedInLast5: boolean = false;

  //Weight
  Weight: number = 0;
  WeightUnitID: string = '';
  EnablePricebyWeight: string = 'False';
  ShowasClassicModifierGroup?: string;
  LabelforItemSelection?: string;

  OrderReviewDisplayName: string = '';

  ReceiptItemGroupID: string = '0';
  AskForTentNumber: string = "false";
  TableTentNumber: string = "";
  IsItem86?: 'True' | 'False' = 'True';
  TaxAmount: number = 0.00;
  TaxAmountWithDiscount: number = 0.00;
}

export interface IModifierV2 {
  ModifierID: string;
  IsModifier86: string;
  IsForced: string;
  CountOption: string;
  Quantity: string;
  MinNoOfSelection: string;
  Category: string;
  Name: string;
  Price: string;
  DisplayOrder: string;
  DisplayName: string;
  Description: string;
  IngredientID: string;
  RefModifierId?: string;
  ParentID: string; // if this is not 0 we know its a nested mod
  ItemID: string;
  NestedModifer?: ModifierV2;
  Ingredients: (ModifierIngredientV2 | ModifierV2)[];
  ParentModifierID: string;
  //todo Need to check where is used
  count: number;
  max: number; // max and min set from CountOption and MinNoOfSelection
  min: number;
  rule: string; // text displayed for describing max and min EX. (Choose exactly 1)
  isReady: boolean;
  ImageUrl: string;
  isValid: boolean;
  IsSelected: boolean;
  IsSubGroup: boolean;
  SelectedIngredients: (ModifierIngredientV2 | ModifierV2)[];
  IsIngredient: boolean;
  IsModifier: boolean;
  IsSelection: boolean;
  KOTDisplayOrder: string;
  KOTDisplayName: string;
  isOloOptionGroup: string;
}

export class ModifierV2 implements IModifierV2 {
  Category: string = '';
  CountOption: string = '';
  Description: string = '';
  DeselectedImage: string = '';
  DisplayName: string = '';
  DisplayOrder: string = '';
  ImageUrl: string = '';
  IngredientID: string = '';
  Ingredients: (ModifierIngredientV2 | ModifierV2)[] = [] as (
    | ModifierIngredientV2
    | ModifierV2
  )[];
  IsForced: string = '';
  IsIngredient: boolean = false;
  IsIngredientModifier: boolean = false;
  IsModifier = true;
  IsModifier86: string = '';
  IsSelected: boolean = false;
  ItemID: string = '';
  MinNoOfSelection: string = '';
  ModifierID: string = '';
  Name: string = '';
  ParentID: string = ''; // if this is not 0 we know its a nested mod
  ParentModifierID: string = '';
  Price: string = '';
  Quantity: string = '1';
  SelectedImage: string = '';
  SelectedIngredients: (ModifierIngredientV2 | ModifierV2)[] =
    [] as ModifierIngredientV2[];
  count: number = 0;
  isReady: boolean = false;
  isValid: boolean = false;
  max: number = 99; // max and min set from CountOption and MinNoOfSelection
  min: number = 0;
  rule: string = ''; // text displayed for describing max and min EX. (Choose exactly 1)
  RefModifierId?: string = '';
  NestedModifer?: ModifierV2;
  IsSelection: boolean = false;
  KOTDisplayOrder: string = '';
  KOTDisplayName: string = '';
  RefIngredientId?: string = '';
  //Added for nested ModifierSenario only
  IsSubGroup: boolean = false;
  isOloOptionGroup: string = '';
  //used for orders
  SelectionParentID?: string;
  IsStartinCollapsedMode: string = '';
  MinAggregateQty: number = 0;
  MaxAggregateQty: number = 0;
  MinChoiceQty: number = 0;
  MaxChoiceQty: number = 0;
  ChoiceQtyIncrement: number = 0;
  UseAdvancedModifierQuantitySettings: string = '';
  ruleAggregate: string = ''; // text displayed for describing maxAggregate. (Select total of 10)
  countAggregate: number = 0;
}

export interface IModifierIngredient {
  ModifierID: string;
  ItemID: string;
  IsForced: string;
  Quantity: string;
  Countable: string;
  MaxQty: string;
  PortionCount: string;
  hasChildren: string;
  DisplayOrder: string;
  IsPremium: string;
  IsDefault: string;
  IngredientID: string;
  Name: string;
  DisplayName: string;
  ImageUrl: string;
  DeselectImageUrl: string;
  IsAllergic: string;
  Calorie: string;
  MaxCalories: string;
  ModifierDetailId: number;
  ItemTags: string[];
  TagNames: string[];
  isNegativeModifier: boolean;
  RefModifierID?: string;
  IsIngredient86: string;
  RefIngredientId?: string;
  CurrentStock: string;
  LowThreshold: string;
  ParentID: string;
  IsIngredient: boolean;
  IsModifier: boolean;
  NestedModifer?: ModifierV2[];
  //For selection and deselection logic
  deselect?: any;
  select?: any;

  count: number;
  IsSelection: boolean;
  KOTDisplayOrder: string;
  KOTDisplayName: string;
  IsItem: string;
}

export class ModifierIngredientV2 implements IModifierIngredient {
  public ModifierID: string = '';
  public ItemID: string = '';
  public IsForced: string = '';
  public ExtraPrice: string = '';
  public Quantity: string = '1';
  public Countable: string = '';
  public MaxQty: string = '';
  public PortionCount: string = '';
  public hasChildren: string = '';
  public DisplayOrder: string = '';
  public IsPremium: string = '';
  public IsDefault: string = '';
  public IngredientID: string = '';
  public ModifierDetailID: string = '';
  public Name: string = '';
  public DisplayName: string = '';
  public ImageUrl: string = '';
  public DeselectImageUrl: string = '';
  public IsAllergic: string = '';
  public Calorie: string = '';
  public MaxCalories: string = '';
  public ModifierDetailId: number = 0;
  public ItemTags: string[] = [];
  public TagNames: string[] = [];
  public isNegativeModifier: boolean = false;
  public RefModifierID?: string = '';
  public IsIngredient86: string = '';
  public RefIngredientId?: string = '';
  public CurrentStock: string = '';
  public LowThreshold: string = '';
  public ParentID: string = '';
  public NestedModifer?: ModifierV2[];
  public IsModifier86?: string = '';

  public IsIngredient = true;
  public IsModifier = false;

  public IsSelected = false;
  public count: number = 0;
  public height: number = 0;

  // used for orders
  public SelectionParentID = '';
  public IsNegativeModifier = '';

  public IsSelection: boolean = false;
  KOTDisplayOrder: string = '';
  KOTDisplayName: string = '';
  isSendtoPOS: string = 'False';
  IsItem: string = '';
  IsSendNegativeModifierstoPOS: string = 'False';
}

export interface ComboGroupV2 extends Common {
  ComboGroupID: string;
  GroupName: string;
  CountOption: string;
  GroupNameLang: string;
  MinNoOfSelection: string;
  DisplayOrder: string;
  count: number;
  max: number;
  min: number;
  rule: string;
  IsForced: string;
  isReady: boolean;
  Items: ItemV2[];
  Variations: Variation[];
  IsSelected: boolean;
  isHidden: boolean;
  HasInvisibleItem: boolean;
  checkedInLast5?: boolean
}

export interface customSubGroup {
  MCSCMappingID: number;
  ModifierID: string;
  SubgroupModifierID: string;
  IngredientID: string;
  ModifierDetailID: string;
  DisplayOrder: string;
}
