import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { LanguageService } from 'src/app/services/language.service';
import { SMBDiscount } from 'src/app/services/smb-discount.service';

@Component({
  selector: 'app-smb-discounts-code',
  templateUrl: './smb-discounts-code.component.html',
  styleUrls: ['./smb-discounts-code.component.css'],
})
export class SMBDiscountsCodeComponent implements OnInit, OnDestroy {
  isKiosk = this.isKioskService.isKiosk();
  discountCode: string = '';
  allSubscription: Subscription[] = [];
  discount: SMBDiscount = {} as SMBDiscount;
  processing: boolean = false;
  correct: boolean = false;
  modalReference: NgbModalRef | undefined;
  languageSub!:Subscription
  continueText:string=''
  discountAppliedText:string=''
  applyDiscountText:string=''
  enterDiscountText:string=''

  constructor(
    private isKioskService: IsKioskService,
    private KeyboardService: KeyboardService,
    private language:LanguageService
  ) {}

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText(){
this.continueText=this.language.getTextElement('lbl_continue')
this.discountAppliedText=this.language.getTextElement('discount_successful')
this.applyDiscountText=this.language.getTextElement('apply_discount')
this.enterDiscountText=this.language.getTextElement('hint_enter_coupon_code')
  }
  dismiss() {
    if (this.modalReference) {
      this.KeyboardService.manual = false;
      this.KeyboardService.newBoardSubject.next();
      this.KeyboardService.KeyboardStatus = false;
      this.modalReference.dismiss();
    }
  }
  checkCode() {
    this.processing = true;
    this.KeyboardService.manual = false;
    this.KeyboardService.newBoardSubject.next();
    this.KeyboardService.KeyboardStatus = false;
    setTimeout(() => {
      if (this.discountCode.toLocaleLowerCase() == this.discount.CouponCode.toLocaleLowerCase()) {
        this.correct = true;
      } else {
        this.close(true);
      }
    }, 1000);
  }
  close(badCode: boolean = false) {
    if (this.modalReference) {
      this.modalReference.close(badCode);
    }
  }
  closeIter3(code: string) {
    if (this.modalReference) {
      this.KeyboardService.manual = false;
      this.KeyboardService.newBoardSubject.next();
      this.KeyboardService.KeyboardStatus = false;
      this.modalReference.close(code);
    }
  }
  public static open(
    modalService: any,
    KeyboardService: KeyboardService,
    discount: SMBDiscount,
    discountCode: string
  ) {
    let modalReference = modalService.open(SMBDiscountsCodeComponent, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });
    modalReference.componentInstance.discount = discount;
    modalReference.componentInstance.discountCode = discountCode;
    let subarr: Subscription[] = [];
    modalReference.componentInstance.allSubscription = subarr.push(
      KeyboardService.stringSub.subscribe((x) => {
        if (modalReference && modalReference.componentInstance)
          modalReference.componentInstance.discountCode = x;
      })
    );
    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }
  public static openIter3(
    modalService: any,
    KeyboardService: KeyboardService
  ) {
    let modalReference = modalService.open(SMBDiscountsCodeComponent, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });
    modalReference.componentInstance.discountCode = '';
    let subarr: Subscription[] = [];
    modalReference.componentInstance.allSubscription = subarr.push(
      KeyboardService.stringSub.subscribe((x) => {
        if (modalReference && modalReference.componentInstance)
          modalReference.componentInstance.discountCode = x;
      })
    );
    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }
}
