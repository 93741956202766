<div id="textmessage">
  <div class="textmessage-modal">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="canceled()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h1 class="text-center">{{checkoutText}}</h1>

    <div class="content">
      <h2 class="text-center">{{ tipHeader }}</h2>
      <h4 class="text-center">{{ tipDescription }}</h4>
      <br />
      <div class="row tips">
        <button *ngFor="let tip of tipsPercent" class="col text-center tip" [ngClass]="{ active: tip == selectedTip }"
          (click)="onClick($event)">
          <span id="{{ tip.TipID }}">{{ calculatePercent(tip.PercentageOrAmount) }}%</span>
          <div class="divider"></div>
          <span class="success dividedText" [ngStyle]="{ color: priceColor }">{{
            calculatePrice(tip.PercentageOrAmount) | CurrencyPipe
            }}</span>
        </button>
        <br />
        <button *ngFor="let tip of tipsFixed" class="col text-center tip" [ngClass]="{ active: tip == selectedTip }"
          (click)="onClick($event)">
          <span id="{{ tip.TipID }}">{{ tip.PercentageOrAmount | CurrencyPipe }}</span>
          <!-- <div class="divider"></div>
          <span class="success dividedText" [ngStyle]="{ color: priceColor }">{{ tip.PercentageOrAmount | CurrencyPipe
            }}</span> -->
        </button>

        <button class="col text-center tip" [ngClass]="{ active: noTip == 'true' }" (click)="onClick($event)">
          <span><i class="fa fa-ban"></i></span>
          <div class="divider"></div>
          <span id="0">{{noTipText}}</span>
        </button>
      </div>

      <mat-slider [thumbLabel]="true" [displayWith]="labelFn" min="0" max="100" step="1" #v (input)="setTipVal(v.value)"
        value="{{ matSliderPercent || 0 }}" [(ngModel)]="matSliderPercent"></mat-slider>

      <h3 class="text-center">{{totalText}} <span [ngStyle]="{ color: priceColor }">{{ calculatedTotal | CurrencyPipe
          }}</span></h3>
      <h5 class="text-center">
        {{tipAmountText}} {{ calculatePrice(selectedTip) | CurrencyPipe }}
      </h5>
      <div class="slidecontainer"></div>
      <br />
      <div class="col-12 col-md-12 popup-footer text-center">
        <a class="green-btn white text-uppercase bold" matRipple [style.background-color]="bkgdColor"
          [style.border]="bkgdColor" (click)="addTip(calculatePrice(selectedTip))">{{this.currentPercent > 0 ? addTipText : noTipText}}</a>
      </div>
    </div>
  </div>
</div>