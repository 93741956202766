<div class="body">
  <p class="header">{{refundAlertText}}</p>
  <p class="question">{{confirmText}}</p>
  <div class="buttons">
    <button class="cancel" (click)="back()">{{cancelText|uppercase}}</button
    ><button class="yes positive-button-text-color background-positive-button-color" (click)="refund()">
      {{yesText|uppercase}}
    </button>
  </div>
</div>
