import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { StoreTime } from 'src/app/models/storeTime';
import { Subscription } from 'rxjs';
import { TimeService } from 'src/app/services/store-time.service';
import { formatDate } from '@angular/common';
import { GeneralSetting } from './generalSetting.service';
@Injectable({
  providedIn: 'root',
})
export class TimeSyncService implements OnDestroy {
  reorderedWeek: any;
  closingTime: any;
  openingTime: any;
  storetime: StoreTime[] = [];
  scSub!: Subscription;
  mtSub!: Subscription;
  timeService: TimeService;
  storeIsClosed: boolean = false;
  currentTime = formatDate(Date.now(), 'HH:mm', 'en-US');
  dayOfWeek: number = new Date().getDay();
  constructor(TimeService: TimeService) {
    this.timeService = TimeService;
  }

  ngOnDestroy() {
    if (this.scSub) {
      this.scSub.unsubscribe();
    }
  }

  storeClosed(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.timeService.storeTime.length < 1) {
        this.scSub = this.timeService.getStoreTimes().subscribe((time: any) => {
          this.storetime = time;
          resolve(this.checkStoreTiming());
        });
        
      } else {
        this.storetime = this.timeService.storeTime;
        resolve(this.checkStoreTiming());
      }
    });
  }

  // Changes done as per multi scheduling timing
  checkStoreTiming(){
    return new Promise<boolean>((resolve, reject) => {
      if(this.storetime.length > 0){
        this.dayOfWeek = new Date().getDay();
        if (this.dayOfWeek == 0) {
          this.dayOfWeek = 7;
        }
        
        let currentStoreTime = this.storetime.filter((x) => (x.dayId == this.dayOfWeek));    
        
        currentStoreTime = currentStoreTime.filter((item, pos, self) => self.findIndex(v => v.start === item.start) === pos);

        currentStoreTime = currentStoreTime.sort((a: any, b: any) =>  (a.start < b.start) ? -1 : 1);

        this.currentTime = formatDate(Date.now(), 'HH:mm:ss', 'en-US');

        if(currentStoreTime && currentStoreTime != undefined && currentStoreTime.length >0){
          for(var i = 0; i < currentStoreTime.length ; i++){
            this.closingTime = currentStoreTime[i].end;
            this.openingTime = currentStoreTime[i].start;
            if (this.openingTime <= this.currentTime && this.closingTime >= this.currentTime) {
              this.storeIsClosed = false;
              break;
            } else {
              this.storeIsClosed = true;
            }
          }
        }else {
          this.storeIsClosed = true;
        }
      }else {
        this.storeIsClosed = false;
      }
      resolve(this.storeIsClosed);
    });
  }
}
