<!-- Modal -->
    <div class="customize-donut">
            <a type="button" data-bs-dismiss="modal" aria-label="Close" class="close-btn" (click)="close()">
                <img src="../../../../assets/images/box-builder/close-icon.svg" class="img-fluid" /></a>
            <div class="modal-body">
                <h2 style="margin-top: 40px; font-weight: 500;">
                    {{alreadySelectedText}}<br />{{selectedCount}} {{donutsText}} 
                </h2>
            </div>
            <div class="modal-footer">
                <button class="btn btn-add active" id="btn-box" (click)="openReviewScreen()" >{{reviewBoxText}}</button>
            </div>
    </div>