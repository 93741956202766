<!-- DO NOT REMOVE, THIS IS REQUIRED TO START THE APP-->
<div id="kioskAppIdDiv"></div>
<!-- END DO NOT REMOVE -->
<div [@routeAnimations]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</div>

<div id="snackbarV2">This category is required</div>
<app-soft-keyboard></app-soft-keyboard>
<div id="blank_overlay" style='display:none'>This category is required</div>
