import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberConvert'
})
export class NumberConvertPipe implements PipeTransform {

  transform(value:any): number {
      return Number(value);
  }

}
