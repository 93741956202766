import { Injectable } from '@angular/core';
import { GeneralSetting } from './generalSetting.service';
import { OLOService } from './olo.service';


export enum Integration {
  'None' = 0,
  'Parbrink' = 1,
  'Clover' = 2,
  'Symphony' = 3,
  'OLO' = 4,
  'Micros3700' = 5
}
@Injectable({
  providedIn: 'root'
})
export class IntegrationService {
  integration: number = Integration.None;

  constructor(private olo:OLOService){
    if (GeneralSetting.getIntegrationId() != '') {
      this.integration = Number(Integration[GeneralSetting.getIntegrationId()]);
    }
  }
  setNone() {
    this.integration = Integration.None;
    GeneralSetting.setIntegrationId(Integration[0]);
  }
  setParbrink() {
    this.integration = Integration.Parbrink;
    GeneralSetting.setIntegrationId(Integration[1]);
  }
  // setClover(){
  //   this.integration=2;
  //   GeneralSetting.setIntegrationId(Integration[2]);
  // }
  setSymphony() {
    this.integration = Integration.Symphony;
    GeneralSetting.setIntegrationId(Integration[3]);
  }
  setOLO() {
    this.integration = Integration.OLO;
    GeneralSetting.setIntegrationId(Integration[4]);
  }
  setMicros3700() {
    this.integration = Integration.Micros3700;
    GeneralSetting.setIntegrationId(Integration[5])
  }

  async sendErrorEmail(req: {
    apiFailed: boolean,
    resData: any,
    Error: string,
    orderInvoiceNo: string,
    isValidation: boolean,
  }) {
    switch (this.integration) {
      case Integration.Parbrink:

        break;
      case Integration.Clover:
        break;
      case Integration.Symphony:
        break;
      case Integration.OLO:
        this.olo.sendErrorEmail(req);
        break;
      case Integration.Micros3700:
        break;
      default:
        break;
    }
  }

}
