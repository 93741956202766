import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bowlmode-selection',
  templateUrl: './bowlmode-selection.component.html',
  styleUrls: ['./bowlmode-selection.component.css']
})
export class BowlmodeSelectionComponent implements OnInit {

  modalReference:any;

  constructor(private modalService:NgbModal) { }

  ngOnInit(): void {
  }

  open(){
    const modalReference = this.modalService.open(BowlmodeSelectionComponent, {
      backdrop: "static",
      windowClass: "itemModal",
      modalDialogClass: "itemModal",
      centered: true,
      size: "xl",
    });

    this.modalReference.componentInstance.modalReference = this.modalReference;
    return this.modalReference;
  }

  close(){
    this.modalReference.close();
  }

}
