<div role="tabpanel" class="tab-pane" id="store-timing">
        <h2 class="text-center mb-3rem">Store Hours</h2>
        <div class="row">
        <table class="store-timing-table table" style="width:100%">
          <thead>
            <tr>
            <th>{{daysText}}</th>
            <th>{{startTimeText}}</th>
            <th>{{endTimeText}}</th>
            <th></th>
              </tr>
            </thead>
          <tbody>
            <tr class="monday">
                <td id="1">{{mondayText}}</td>
                 <td>{{ storeTime && storeTime[0] ? storeTime[0].start : ''}}</td>
            <td>{{ storeTime && storeTime[0] ? storeTime[0].end : ''}}</td>
            <!-- <td  class="text-center switch_box">
              <input type="checkbox" class="switch_1" id="time1" checked>
              </td> -->
             <!-- <td  class="text-center"><input type="checkbox" class="styled-checkbox" id="time1" name="time1" value="check" checked>   <label for="time1"></label>       </td>
             --> </tr>

            <tr>
                <td id="2">{{tuesdayText}}</td>
                    <td>{{ storeTime && storeTime[1] ? storeTime[1].start : '' }}</td>
            <td>{{ storeTime && storeTime[1] ? storeTime[1].end : '' }}</td>
            <!-- <td  class="text-center switch_box">
              <input type="checkbox" class="switch_1" id="time2" checked>
              </td> -->
              <!--
              <td  class="text-center"><input type="checkbox" class="styled-checkbox" id="time2" name="time2" value="check" checked>   <label for="time2"></label>       </td>
              -->
            </tr>

            <tr>
                <td id="3">{{wednesdayText}}</td>
                       <td>{{storeTime && storeTime[2] ? storeTime[2].start : ''}}</td>
            <td>{{storeTime && storeTime[2] ? storeTime[2].end : ''}}</td><!--
              <td  class="text-center"><input type="checkbox" class="styled-checkbox" id="time3" name="time3" value="check" checked>   <label for="time3"></label>       </td>
            -->
             <!-- <td  class="text-center switch_box">
                <input type="checkbox" class="switch_1" id="time3" checked>
                </td> -->
            </tr>

            <tr>
                <td id="4">{{thursdayText}}</td>
                  <td>{{storeTime && storeTime[3] ? storeTime[3].start : ''}}</td>
            <td>{{storeTime && storeTime[3] ? storeTime[3].end : ''}}</td>
             <!-- <td  class="text-center"><input type="checkbox" class="styled-checkbox" id="time4" name="time4" value="check" checked>   <label for="time4"></label>       </td>
             -->
             <!-- <td  class="text-center switch_box">
              <input type="checkbox" class="switch_1" id="time4" checked>
              </td> -->
            </tr>

            <tr>
                <td id="5">{{fridayText}}</td>
                       <td>{{storeTime && storeTime[4] ? storeTime[4].start : ''}}</td>
            <td>{{storeTime && storeTime[4] ? storeTime[4].end : ''}}</td>
             <!-- <td  class="text-center"><input type="checkbox" class="styled-checkbox" id="time5" name="time5" value="check" checked>   <label for="time5"></label>       </td>
            -->
            <!-- <td  class="text-center switch_box">
              <input type="checkbox" class="switch_1" id="time5" checked>
              </td> -->
            </tr>

            <tr>
                <td id="6">{{saturdayText}}</td>
                    <td>{{storeTime && storeTime[5] ? storeTime[5].start : ''}}</td>
            <td>{{storeTime && storeTime[5] ? storeTime[5].end : ''}}</td>
             <!-- <td  class="text-center"><input type="checkbox" class="styled-checkbox" id="time6" name="time6" value="check" checked>   <label for="time6"></label>       </td>
             -->
             <!-- <td  class="text-center switch_box">
              <input type="checkbox" class="switch_1" id="time6" checked>
              </td> -->
            </tr>

            <tr>
                <td id="7">{{sundayText}}</td>
                   <td>{{storeTime && storeTime[6] ? storeTime[6].start : ''}}</td>
            <td>{{storeTime && storeTime[6] ? storeTime[6].end : ''}}</td>
<!--<td  class="text-center"><input type="checkbox" class="styled-checkbox" id="time7" name="time7" value="check" checked>   <label for="time7"></label>       </td>
            -->
            <!-- <td  class="text-center switch_box">
              <input type="checkbox" class="switch_1" id="time7" checked>
              </td> -->
            </tr>
          </tbody>
        </table>
        </div>
</div>
