import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IsKioskService {
  isKiosk(): boolean {
    try {
      return window.navigator.userAgent.includes("Tizen");
    } catch (e) {
      return true;
    }
  }

  constructor() { }
}
