import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LogService, loggingData } from 'src/app/services/log.service';
import { OrderReviewListComponent } from 'src/app/components/order-review/order-review-list/order-review-list.component';
import { DatabaseService } from 'src/app/services/database.service';
import { DiscountItemMapping, DiscountMaster } from '../../models/DiscountMaster';
import { CartService } from '../../services/cart.service';
import { Subscription } from 'rxjs';
import { OrderReviewPromotionsComponent } from 'src/app/components/order-review/order-review-promotions/order-review-promotions.component';
import { PusherService } from 'src/app/services/pusher.service';
import { OrderReviewFooterComponent } from 'src/app/components/order-review/order-review-footer/order-review-footer.component';
import { GeneralSetting } from '../../services/generalSetting.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { CustomerLoginPopupComponent } from '../dialogs/customer-login-popup/customer-login-popup.component';
import { LanguageService } from 'src/app/services/language.service';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { CustomerWelcomeModalComponent } from '../dialogs/customer-welcome-modal/customer-welcome-modal.component';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { environment } from 'src/environments/environment';
import { LevelUpObject, LoyaltyType } from 'src/app/models/loyaltyModal';
import { CustomerLoginModalService } from '../dialogs/customer-login-popup/customer-login-popup.service';
import { SMBDiscountsComponent } from './smb-discounts/smb-discounts.component';
import { PreloaderService } from 'src/app/services/preloader.service';

enum BogoDiscountType {
  FreeItem = '1',
  Percentage = '2',
}

enum BogoFlatOrPercentage {
  Percentage = '1',
  Flat = '2',
}

@Component({
  selector: 'app-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.css'],
})
export class OrderReviewComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
    this.modelService.dismissAll();
  }

  private allSubsCription: Subscription[] = [];

  variableUpSell!: boolean;

  modifiableUpSell!: boolean;

  bkgdColor: string = '';

  textColor: string = '';

  // @ViewChild(OrderLevelUpsellDialogComponent)
  // upsellModal!: OrderLevelUpsellDialogComponent;
  @ViewChild(OrderReviewFooterComponent)
  orderReviewFooterChild!: OrderReviewFooterComponent;
  @ViewChild(CustomerWelcomeModalComponent)
  welcome!: CustomerWelcomeModalComponent;
  @ViewChild(OrderReviewListComponent) cart!: OrderReviewListComponent;

  @ViewChild(OrderReviewPromotionsComponent)
  promo!: OrderReviewPromotionsComponent;

  @ViewChild(SMBDiscountsComponent)
  smbdiscount!: SMBDiscountsComponent;

  subtotalOutput: number = 0.0;

  totalOutput: number = 0.0;

  taxOutput: number = 0.0;

  discountOutput: number = 0.0;

  listDiscount = [] as DiscountMaster[];

  listDiscountItem = [] as DiscountItemMapping[];

  hasPromos: boolean = false;

  hasPromosCoupon: boolean = false;

  loyaltyPromos: boolean = false;

  isLoyalty: boolean = false;
  useLoyalty: boolean = false;
  removed: boolean = false;

  orderReviewFooter!: OrderReviewFooterComponent;

  @ViewChild(CustomerLoginPopupComponent)
  customerLoginPopup!: CustomerLoginPopupComponent;

  private min = (a: any, f: any) =>
    a.reduce((m: any, x: any) => (m[f] < x[f] ? m : x));
  private max = (a: any, f: any) =>
    a.reduce((m: any, x: any) => (m[f] > x[f] ? m : x));

  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();
  languageSub!: Subscription
  reviewOrderText: string = ''

  concessionaireHeaderImage: string = "";

  imageBaseUrl = this.isKioskService.isKiosk() ? environment.imageBaseUrl : environment.imageBaseUrlRemote;

  isShowBranchLogo: boolean = false;

  constructor(
    private readonly databaseService: DatabaseService,
    private readonly cartService: CartService,
    private readonly pusherService: PusherService,
    private modelService: NgbModal,
    private userService: UserService,
    private router: Router,
    private readonly loyaltyService: LoyaltyService,
    private language: LanguageService,
    private logger: LogService,
    private isKioskService: IsKioskService,
    private customerLoginService: CustomerLoginModalService,
    private preloader: PreloaderService,
    private cdr: ChangeDetectorRef

  ) {
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    )
  }
  loadText() {
    this.reviewOrderText = this.language.getTextElement('lbl_review_order')
  }
  ngOnInit(): void {

    this.bkgdColor = GeneralSetting.getPrimaryColor();

    this.textColor = GeneralSetting.getTextColor();

    this.Initialize();

    this.setCalculation();
  }

  private Initialize() {
    const items = this.cartService.getCartItems();
    this.isLoyalty = this.loyaltyService.isLoyalty;
    this.useLoyalty = GeneralSetting.getUseLoyaltyKiosk() == "True"
    const itemIds = items.map((item: any) => item.ItemID);
    this.concessionaireHeaderImage = GeneralSetting.getConcessionaireHeaderImage();

    this.allSubsCription.push(
      this.databaseService.getDiscountItems(itemIds).subscribe((discountItems) => {
        this.listDiscountItem = discountItems;

        const discountIds = this.listDiscountItem.map(dis => dis.DiscountID);

        this.allSubsCription.push(
          this.databaseService.getDiscountByBogoItem(discountIds).subscribe((discount) => {
            this.listDiscount = discount;
            // this.bogoDiscountCheck();
          })
        );
      })
    );
  }

  getUserName() {
    if (this.orderReviewFooterChild && this.orderReviewFooterChild.awaitingLevelUpProposal) {
      return
    }
    if (this.userService.isUserLoggedIn) {
      return this.userService.name ?? 'Guest';
    } else {
      return 'Sign In';
    }
  }
  private setCalculation() {
    (async () => {
      const allCalc = await this.cartService.calculateAll();
      this.subtotalOutput = allCalc[0];
      if (GeneralSetting.getParBrinkIntegrationID() != '') {
        this.taxOutput = allCalc[1];
      }
      this.totalOutput = allCalc[3];
      this.cartService.sub_subtotal.next(allCalc[0]);
      this.cartService.sub_tax.next(allCalc[1]);
      this.cartService.sub_discount.next(allCalc[2]);
      this.cartService.sub_total.next(allCalc[3]);
      this.cartService.sub_shipping.next(allCalc[4])
      if (allCalc[5]) {
        this.cartService.sub_reward.next(allCalc[5])
      }

      if (this.totalOutput < 0 || isNaN(this.subtotalOutput)) {
        this.orderReviewFooterChild.removeDiscount();
        let items = this.cartService.getCartItems()
        for (let i = 0; i < items.length; i++) {
          if (items[i].IsLoyaltyDiscountItem) {
            this.cartService.removeFromCart(items[i])
          }
        }
      }
    })();
  }
  onUserClick() {
    if (this.orderReviewFooterChild && this.orderReviewFooterChild.paymentTaken) {
      return
    }
    this.customerLoginService.onUserClick(this.loyaltyService.loyaltyType).then((data: number) => {
      if (data) {
        if (data == 1) {
          this.router.navigateByUrl('/lastfiveorder');
          this.orderReviewFooterChild.removeDiscount();
        } else if (data == 2) {
          this.getUserName();
          if (this.loyaltyService.loyaltyType == LoyaltyType.LevelUp) {
            this.orderReviewFooterChild.ngOnInit()
          }
          this.promo.ngOnInit()
        } else if (data == 3) {
          this.promo.ngOnInit()
        } else {
          //this block should not be reached
        }
      }
      //no else block for top-level if-con
      //where data is falsey, a zero is returned to end execution flow without hanging promise
    }
      //no rejection handler
      //promise resolves 0 on all reject cases
    );

  }
  bogoDiscountCheck() {
    let items = this.cartService.getCartItems();
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      let discountItem = this.listDiscountItem.find(
        (x) => x.ItemID == item.ItemID && x.PurchaseOrGet == '1'
      );
      if (discountItem == null && discountItem == undefined) continue;
      let discountMaster = this.listDiscount.find(
        (x) =>
          x.DiscountID ==
          (discountItem != undefined ? discountItem.DiscountID : '')
      );
      if (discountMaster == null && discountMaster == undefined) continue;
      if (Number(item.Quantity) < Number(discountMaster.BogoPurchaseQuantity))
        continue;
      let freeItemsDiscount = this.listDiscountItem.filter(
        (x) =>
          x.DiscountID ==
          (discountMaster != undefined ? discountMaster.DiscountID : '') &&
          x.PurchaseOrGet == '2'
      );
      let freeItems = [] as any[];
      for (var j = 0; j < freeItemsDiscount.length; j++) {
        let freeItem = items.filter(
          (x: any) => x.ItemID == freeItemsDiscount[j].ItemID
        );
        if (freeItem.length > 0) {
          freeItem.forEach((x: any) => {
            freeItems.push(x);
          });
        }
      }
      const itemQty = Number(item.Quantity);
      const bogoPurchaseQty = Number(discountMaster.BogoPurchaseQuantity);
      let costItem =
        discountMaster.BogoAppliesTo == '1'
          ? this.min(freeItems, 'Price')
          : this.max(freeItems, 'Price');
      const setItem = items.find((x: any) => x.ItemID == costItem.ItemID);
      const bogoDiscountQty = Number(discountMaster.BogoGetQuantity);
      const bogoDiscountAmount = Number(discountMaster.BOGODiscountAmount);
      const bogoDiscountPer = Number(discountMaster.BogoFlatOrPercentage);
      if (setItem == undefined || setItem == null) continue;

      const perItemPrice = this.cartService.getItemPrice(setItem, true);

      setItem.DiscountTypeID = Number(discountMaster.DiscountTypeID);
      setItem.DiscountID = Number(discountMaster.DiscountID);
      setItem.IsDiscount = true;
      if (discountMaster.BogoDiscountType == BogoDiscountType.FreeItem) {
        let totalDiscountQty = Math.floor(
          (itemQty / bogoPurchaseQty) * bogoDiscountQty
        );
        if (totalDiscountQty > Number(setItem.Quantity))
          totalDiscountQty = Number(setItem.Quantity);
        setItem.DiscountAmount = perItemPrice * totalDiscountQty;
        setItem.DiscountPer =
          (setItem.DiscountAmount * 100) / (perItemPrice * itemQty);
      } else {
        if (
          discountMaster.BogoFlatOrPercentage == BogoFlatOrPercentage.Percentage
        ) {
          let totalDiscountAmount =
            (perItemPrice * itemQty * bogoDiscountPer) / 100;
          setItem.DiscountAmount = totalDiscountAmount;
          setItem.DiscountPer = bogoDiscountPer;
        } else {
          if (bogoDiscountAmount <= perItemPrice * itemQty) {
            setItem.DiscountAmount = bogoDiscountAmount;
            setItem.DiscountPer =
              (setItem.DiscountAmount * 100) / (perItemPrice * itemQty);
          }
        }
      }
    }

    this.setCalculation();
  }
  updatePrices() {
    this.setCalculation();
  }
  updateSubtotal(event: any) {
    this.setCalculation();
  }
  updateTotal(event: any) {
    this.setCalculation();
  }

  updateTax(event: any) {
    this.setCalculation();
  }

  getSubtotal(event: number) {
    this.setCalculation();
  }

  getTotal(event: any) {
    this.setCalculation();
  }

  getTax(event: any) {
    this.setCalculation();
  }

  getPromotion(event: any) {
    this.setCalculation();
  }

  checkPromos(event: any) {
    this.hasPromos = event;
    this.cdr.detectChanges();
  }

  checkHasPromoCoupon(event: any) {
    this.hasPromosCoupon = event;
    this.cdr.detectChanges();
  }

  checkPromosLoyalty(event: any) {
    this.loyaltyPromos = event;
    this.cdr.detectChanges();
  }

  removePromo(event: any) {
    this.removed = event;
    this.cdr.detectChanges();
  }



  getHeightOfList(hasPromos: boolean, loyaltyPromos: any, isLoyalty: boolean, hasPromosCoupon: boolean, isRemovePrice: boolean) {
    if (isRemovePrice) {
      return '79.58vh';
    } else {
      if (hasPromos && loyaltyPromos) {
        return '52.4vh';
      }
      else if (hasPromos && hasPromosCoupon && !loyaltyPromos) {
        return '63.58vh';
      }
      else if (hasPromos && !loyaltyPromos) {
        return GeneralSetting.getIsConcessionaire().toLowerCase() == 'true' ? '63vh' : (isLoyalty ? '63.58vh' : '68.58vh');
      }
      else if (!hasPromos && loyaltyPromos) {
        return '65.15vh';
      } else if (hasPromosCoupon && !loyaltyPromos) {
        return '74.35vh';
      } else if (isLoyalty) {
        return '76.35vh';
      } else {
        return '81.35vh';
      }


    }
  }


  /**
   * Sets branch header image
   * @param headerImageUrl 
   * @param imageBaseUrl 
   * @returns 
   */
  setHeaderImage(headerImageUrl: string, imageBaseUrl: string) {
    return headerImageUrl && headerImageUrl != '' ? imageBaseUrl + headerImageUrl : "";
  }

  ngAfterViewInit() {
    this.preloader.hide();
  }
}
