import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-olo-error-popup',
  templateUrl: './olo-error-popup.component.html',
  styleUrls: ['./olo-error-popup.component.css']
})
export class OloErrorPopupComponent implements OnInit,OnDestroy {

  ngOnDestroy() {
    if(this.timeOut) clearTimeout(this.timeOut);
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  languageSub!: Subscription;

  oloErrorMsg: string = "";

  oloConfirmation : string = "";

  oloBtnMsg : string = "";

  modalReference: any;

  isTimeOut:boolean= false;

  private timeOut:any = null;

  uhOhText: string = "";

  retryText: string = "";

  defaultConfirmationMessage: string = ""

  constructor(public modalService: NgbModal, private language: LanguageService) { }
  
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    if (this.isTimeOut) {
     this.timeOut = setTimeout(() => {
        this.close();
      },5000);
    }

  }

  loadText() {
    this.uhOhText = this.language.getTextElement('lbl_uh_oh');
    this.retryText = this.language.getTextElement('lbl_retry');
    this.defaultConfirmationMessage = this.language.getTextElement('do_you_want_to_send_the_order_again');

    if(this.oloBtnMsg == "") {
      this.oloBtnMsg = this.retryText;
    }

    if(this.oloConfirmation == "") {
      this.oloConfirmation = this.defaultConfirmationMessage;
    }
    if(this.oloConfirmation=='0'){
      this.oloConfirmation = ""
    }
  }

  public static open(modalService: NgbModal,oloErrorMsg: any, oloConfirmation: string = "", oloBtnMsg: string = "", isTimeOut: boolean = false) {
    var modalReference = modalService.open(OloErrorPopupComponent, {
      windowClass: "oloErrorModel",
      modalDialogClass: "oloErrorModel",
      backdrop: 'static',
      size: 'lg',
      centered: true
    });

    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.oloErrorMsg = oloErrorMsg;
    modalReference.componentInstance.oloConfirmation = oloConfirmation;
    modalReference.componentInstance.oloBtnMsg = oloBtnMsg;
    modalReference.componentInstance.isTimeOut = isTimeOut;

    return modalReference;
  }

  close() {
    this.modalReference.close('close');

    if(this.timeOut) clearTimeout(this.timeOut);
  }

  canceled() {
    this.modalReference.close('canceled');

    if(this.timeOut) clearTimeout(this.timeOut);
  }
}
