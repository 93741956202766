import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Variation, VariationOption } from '../../models/Variations';
import { Subscription } from 'rxjs';
import {ItemTransferService} from '../../services/item-transfer.service'
@Component({
  selector: 'app-variation-container',
  templateUrl: './variation-container.component.html',
  styleUrls: ['./variation-container.component.css'],
})
export class VariationContainerComponent implements OnInit, OnDestroy {

  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  ItemID:string;
  //@Input() ItemID: string = '';

  @Output() totalAdditionlPrice: string = '';

  @Output() variation: string = '';

  @Output() collapsed: boolean = false;

  variations: Variation[] = [];

  alreadySelected: boolean = false;

  @Input() inputVariations: any;

  variationOptionsSelected: VariationOption[] = [];

  @Output() variationOptionSelectedEmiter: EventEmitter<VariationOption[]> = new EventEmitter<VariationOption[]>();

  @Output() isValidEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private itemTransferService: ItemTransferService) {
    this.ItemID=this.itemTransferService.itemV2.ItemID;
  }

  ngOnInit(): void {
    this.isValidEmitter.emit(true);

    if (this.inputVariations && this.inputVariations.length > 0) {
      for (let item of this.inputVariations[0].variationOptions) {
        if (item.isSelected) {
          this.alreadySelected = true;
          this.variationOptionSelectedEmiter.emit([item]);
          break;
        }
      }
      if (!this.alreadySelected) {
        this.inputVariations[0].variationOptions[0].isSelected = true;
        this.variationOptionSelectedEmiter.emit([
          this.inputVariations[0].variationOptions[0]
        ]);
      }
      return;
    }
  }

  printThis() {}

  setVariationOptionSelected(
    selectedVariation: VariationOption,
    index: number
  ) {
    this.variationOptionsSelected[index] = selectedVariation;
    this.variationOptionSelectedEmiter.emit(
      this.variationOptionsSelected.slice()
    );
    //slice so that it does not lose any data, but ALSO returns a NEW array object that triggers the ngOnChange on the cart compoent
    this.isValidEmitter.emit(true);
  }
}
