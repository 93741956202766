import { Injectable } from '@angular/core';
import { start } from 'repl';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  todayBetweenDates(startDate: Date, endDate: Date): boolean {
    let today = new Date().getTime();
    if (today <= endDate.getTime() && today >= startDate.getTime()) {
      return true;
    }
    return false;
  }

  convertTime12to24 = (time12h: string) => {
    var [month, day, year, time] = time12h.split(' ');

    if (!time && !year) {
      time = day;
    }

    const modifier = time.slice(0, -2);
    time = time.slice(0, time.length - 2);
    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = (parseInt(hours, 10) + 12).toString();
    }

    if (!year) {
      var d = new Date(time12h);
      month = '' + (d.getMonth() + 1);
      day = '' + d.getDate();
      year = ''+ d.getFullYear();
    }

    return `${month} ${day} ${year} ${hours}:${minutes}`;
  };
}
