import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CommonFunctions } from 'src/app/services/common';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';
import { PaymentTotalRowComponent } from '../../payment-total-row/payment-total-row.component';
import { CancelPaymentService } from 'src/app/services/cancel-payment.service';


@Component({
  selector: 'app-payment-insert',
  templateUrl: './payment-insert.component.html',
  styleUrls: ['./payment-insert.component.css'],
})
export class PaymentInsertComponent implements OnInit, OnDestroy {
  @ViewChild(PaymentTotalRowComponent) paymentRow!: PaymentTotalRowComponent
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  total: number = 0;
  subtotal: number = 0;
  tax: number = 0;
  discount: number = 0;
  reward: number = 0;
  shipping: number = 0;
  tip: number = 0;

  modalReference!: NgbModalRef;

  languageSub!: Subscription;

  isVerifone!: boolean;

  isClover: boolean = false;

  isRefund: boolean = false;

  isCancel: boolean = true;

  payWithCardText: string = '';

  checkoutText: string = '';

  cancelText: string = '';

  refundText: string = '';

  refundCardText: string = '';

  followInstructionsText: string = ''

  showPayment: boolean = false

  constructor(
    private modalService: NgbModal,
    private cancelPayment:CancelPaymentService,
    private language: LanguageService
  ) { }

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  private loadText() {
    if (this.payWithCardText.trim() == "") {
      this.payWithCardText = this.language.getTextElement('pay_with_card');
    }

    this.checkoutText = this.language.getTextElement('lbl_checkout');
    this.cancelText = this.language.getTextElement('lbl_button_cancel');
    this.refundText = this.language.getTextElement('refund');
    this.refundCardText = this.language.getTextElement('refund_card');
  }

  public static open(
    modalService: NgbModal,
    language: LanguageService,
    isVerifone: boolean,
    isRefund: boolean = false,
    total: number = 0,
    subtotal: number = 0,
    tax: number = 0,
    tip: number = 0,
    shipping: number = 0,
    discount: number = 0,
    reward: number = 0,
    showPayment: boolean = false,
    isClover?: boolean,
    payWithCardText: string = ""
  ) {
    let modalReference = modalService.open(PaymentInsertComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });
    modalReference.componentInstance.total = total;
    modalReference.componentInstance.subtotal = subtotal;
    modalReference.componentInstance.tax = tax;
    modalReference.componentInstance.tip = tip;
    modalReference.componentInstance.shipping = shipping;
    modalReference.componentInstance.discount = discount;
    modalReference.componentInstance.reward = reward;
    modalReference.componentInstance.showPayment = showPayment
    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.payWithCardText = payWithCardText;

    modalReference.componentInstance.isVerifone = isVerifone;

    if (isClover) {
      modalReference.componentInstance.isClover = isClover;
    }

    modalReference.componentInstance.isRefund = isRefund;
    let text = language.getTextElement('follow_payment_instructions')
    modalReference.componentInstance.followInstructionsText = text
    modalReference.componentInstance.removeCancel();

    return modalReference;
  }

  open(isVerifone: boolean, isRefund: boolean = false, isClover?: boolean) {
    this.modalReference = this.modalService.open(PaymentInsertComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    this.modalReference.componentInstance.modalReference = this.modalReference;
    this.modalReference.componentInstance.isVerifone = isVerifone;
    if (isClover) {
      this.modalReference.componentInstance.isClover = isClover;
    }
    this.modalReference.componentInstance.isRefund = isRefund;
    this.modalReference.componentInstance.removeCancel();
  }

  async removeCancel() {
    await CommonFunctions.delay(10000);
    this.isCancel = false;
  }

  close() {
    if (this.modalReference) this.modalReference.close();
  }

  //#region Cancel Payment
  private isCancelClick = false;
  private async setCancelClick() {
    this.isCancelClick = true;

    await CommonFunctions.delay(3000);

    this.isCancelClick = false;
  }

  onCancelClick() {

    if (this.isCancelClick) return;

    this.setCancelClick();

    this.cancelPayment.doCancelPayment();
  }

  //#endregion

}
