import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { Snackbar } from 'src/app/services/common';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-item-tent-popup',
  templateUrl: './item-tent-popup.component.html',
  styleUrls: ['./item-tent-popup.component.css'],
})
export class ItemTentPopupComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  headerText: string = '';

  bodyText: string = '';

  modalReference: any;

  tentNumber: any = null;

  isKiosk: boolean = false;
  
  validItemTentText: string = '';
  
  defaultBodyText: string = '';
  
  defaultHeaderText: string = '';
  
  languageSub!: Subscription;
  
  constructor(
    public modalService: NgbModal,
    private KeyboardService: KeyboardService,
    isKioskService: IsKioskService,
    private language: LanguageService
  ) {
    this.isKiosk = isKioskService.isKiosk();
  }

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });

    if (GeneralSetting.getTableTentNumber() != '') {
      this.tentNumber = GeneralSetting.getTableTentNumber();
    }

    this.headerText =
      GeneralSetting.getItemTableTentHeaderText() == ''
        ? this.defaultHeaderText
        : GeneralSetting.getItemTableTentHeaderText();

    this.bodyText =
      GeneralSetting.getItemTableTentBodyText() == ''
        ? this.defaultBodyText
        : GeneralSetting.getItemTableTentBodyText();

    this.KeyboardPopup();
  }
  // add click event on first input field @jay
  KeyboardPopup(){
    let itemTentEle: HTMLElement = document.getElementById('itemTent') as HTMLElement;
    itemTentEle.click();
  }
  
  loadText() {
    this.validItemTentText = this.language.getTextElement( 'please_enter_a_valid_item_tent_number' );
    this.defaultBodyText = this.language.getTextElement( 'enter_a_tent_number_and_we_will_bring_this_item_to_your_table' );
    this.defaultHeaderText = this.language.getTextElement( 'enter_tent_number_for_this_item' );
  }

  public static open(modalService: NgbModal) {
    let modalReference = modalService.open(ItemTentPopupComponent, {
      //backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.tentNumber = '';
    return modalReference;
  }

  open() {
    let modalReference = this.modalService.open(ItemTentPopupComponent, {
      //backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.tentNumber = '';
    this.modalReference = modalReference;
  }

  

  confirmTent() {
    if ( this.tentNumber && this.tentNumber.length && this.tentNumber.length <= 3 && this.tentNumber != 0 ) {
      GeneralSetting.setIsItemTableTentAdded(true);
      this.KeyboardService.KeyboardStatus = false;

      let res = {
        tentNumber: this.tentNumber,
        status: 'ok',
      };

      this.modalReference.close(res);
    } else {
      Snackbar.show(this.validItemTentText, 2000);
    }
  }
}
