import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';
import { loggingData, LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css'],
})
export class PaymentSuccessComponent implements OnInit {

  ngOnDestroy() {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  languageSub!: Subscription;

  modalReference: any;

  isVerifone!: boolean;

  paymentApprovedText: string = '';

  constructor(
    private modalService: NgbModal,
    private logger: LogService,
    private language: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => [
      this.loadText(),
    ]);
  }

  loadText() {
    this.paymentApprovedText = this.language.getTextElement('payment_approved');
  }

  public static open(modalService: NgbModal, isVerifone: boolean = false) {
    let modalReference = modalService.open(PaymentSuccessComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.isVerifone = isVerifone;

    return modalReference;
  }

  open(isVerifone: boolean) {
    this.modalReference = this.modalService.open(PaymentSuccessComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    this.modalReference.componentInstance.modalReference = this.modalReference;
    this.modalReference.componentInstance.isVerifone = isVerifone;
  }

  close() {
    var log = new loggingData(
      'Payment Success',
      'Successful Payment Response Received',
      'Confirmation',
      `Succesfully Received Payment Response`,
      true
    );
    this.logger.sendLogToServer(log);
    this.modalReference.close('close');
  }

}
