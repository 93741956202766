import { Injectable } from '@angular/core';
import { Guid } from '../models/Guid';
import { ItemV2 } from '../models/item';
import { CommonFunctions } from './common';
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root',
})

export class ScannerHybridModeServiceService {

  constructor(private database: DatabaseService) { }

  stopBarcodeScanner(){
    console.log("Reached for stop scanner!!");
    var messageType = 'stopScanner';
    var message = {
      messageType: messageType,
    };
    window.top!.postMessage(message, '*');
  }

  startItemScanner(){
    console.log("Reached for start scanner!!");
    var messageType = 'startScanner';
    var message = {
      messageType: messageType,
    };
    window.top!.postMessage(message, '*');
  }

  async createCartItem(inputItem: ItemV2,database : DatabaseService ) {
    const cartItem = JSON.parse(JSON.stringify(inputItem));
    cartItem.guid = (inputItem.guid && inputItem.guid !='') ? inputItem.guid : Guid.newGuid();
    cartItem.AllowQuantitySelection = 'True';
    cartItem.GuestName = inputItem.GuestName;
    cartItem.Quantity = '1';
    cartItem.totalPrice = CommonFunctions.getItemPrice(cartItem) * Number(cartItem.Quantity);

    if (cartItem.Variations && cartItem.Variations.length <= 0) {
      await database.getPersistentVariationsAsync(cartItem.ItemID).then(variations => {
        if (cartItem) {
          cartItem.Variations = JSON.parse(JSON.stringify(variations));
          if (cartItem.Variations && cartItem.Variations.length > 0) {
            for (let i = 0; i < cartItem.Variations.length; i++) {
              let variation = cartItem.Variations[i];
              for (let j = 0; j < variation.variationOptions.length; j++) {
                if (variation.variationOptions && variation.variationOptions != undefined && variation.variationOptions.length > 0 && 
                   cartItem.VariationOption && cartItem.VariationOption != undefined && cartItem.VariationOption.length > 0) {
                  if (variation.variationOptions[j].ItemAttributeOptionID == cartItem.VariationOption[0].ItemAttributeOptionID) {
                   variation.IsSelected = true;
                    variation.variationOptions[j].isSelected = true;
                  }
                  else {
                    variation.variationOptions[j].isSelected = false;
                  }
                }
              }
            }
          }
        }
      });
    }
    return cartItem;
  }
}
