import { Injectable } from '@angular/core';
import {
  OrderMasterRes,
  OrderItemMasterRes,
  OrderItemModifierMasterRes,
  CustomerMasterRes,
  OrderUpRes,
} from '../models/OrderUpRes';
import { DatabaseHandler } from '../DatabaseHandler';
import { OrderMasterReq } from '../models/OrderMasterReq';
import { loggingData, LogService } from 'src/app/services/log.service';
import {
  OrderItemMasterReq,
  OrderItemModifierMasterReq,
} from '../models/OrderItemMasterReq';
import { CustomerListReq } from '../models/CustomerListReq';
import { CurbsideDetailMaster } from '../models/CurbsideDetailMaster';
import { ExternalOrderDetail } from '../models/ExternalOrderDetail';
import { ItemInventoryReq } from '../models/ItemInventoryReq';
import { OnlineCustomerMasterReq } from '../models/OnlineCustomerMasterReq';
import { OrderOtherChargeReq } from '../models/OrderOtherChargeReq';
import { OrderPartialPaymentMasterReq } from '../models/OrderPartialPaymentMasterReq';
import { OrderRefundPaymentMasterReq } from '../models/OrderRefundPaymentMasterReq';
import { OrderSplitBillReq } from '../models/OrderSplitBillReq';
import { OrderSuccessPaymentMasterReq } from '../models/OrderSuccessPaymentMasterReq';
import { DataService } from './data.service';
import { OrderListReq } from '../models/OrderListReq';
import { VerifoneService } from 'src/app/services/verifone.service';
import { IVerifoneQueryRes } from 'src/app/models/VerifoneResponse';
import { PaymentTypesService } from './payment-types.service';
import { timeout } from 'rxjs/operators';
import { ConcessioniareOrderSplitBill } from '../models/ConcessioniareOrderSplitBill';
import { OrderHistoryService } from './order-history.service';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root',
})
export class OfflineOrderService {
  orderMasterData = [] as OrderMasterRes[];

  orderItemData = [] as OrderItemMasterRes[];

  orderModifierMasterData = [] as OrderItemModifierMasterRes[];

  orderCustomerData = [] as CustomerMasterRes[];

  constructor(
    private readonly dataService: DataService,
    private readonly paymentTypeService: PaymentTypesService,
    private logger: LogService,
    private verifone: VerifoneService,
    private orderHistory: OrderHistoryService,
    private loader:LoaderService
  ) {}

  sendOfflineOrderToBackend() {
    this.dataService
      .IsOnlineCheckSK()
      .pipe(timeout(50000))
      .toPromise()
      .then(
        (data) => {
          this.paymentTypeService.getCardPayment().then((data) => {
            if (data.length > 0) {
              let thirdPartyPaymentTypeIds = data.map(
                (x) => x.ThirdPartyPaymentID
              );

              if (thirdPartyPaymentTypeIds.includes('17')) {

                this.logger.sendLogToServer(new loggingData(
                  'Offline Order Up For Verifone Order',
                  'Offline Order Up For Verifone Order',
                  'Offline Order Up For Verifone Order Started',
                  ``,
                  true
                ));

                this.connetAndSendVerifoneOrderToBackend();

                this.sendOnlineErrorOrderToBackend(true);
              } else {
                this.logger.sendLogToServer(new loggingData(
                  'Offline Order Up For Failed Order',
                  'Offline Order Up For Failed Order',
                  'Offline Order Up For Failed Order Started',
                  ``,
                  true
                ));
                this.sendOnlineErrorOrderToBackend();
              }
            }
            else{
              this.loader.closeLoader();
            }
          });
        },
        async (error) => {
          this.loader.closeLoader();
        }
      );
  }

  private async sendOnlineErrorOrderToBackend(
    isNotVerifoneOrder: boolean = false
  ) {
    let AllOrders = await this.getUnSendOrderData(isNotVerifoneOrder);

    if (AllOrders.length <= 0) return;

    for (var i = 0; i < AllOrders.length; i++) {
      try{
        await this.sendOrderToBackend(AllOrders[i], AllOrders[i].TransactionID);
      }
      catch(e){

      }
    }
    this.loader.closeLoader
  }

  //Getting  Data from local db
  private getUnSendOrderData(isNotVerifoneOrder: boolean = false) {
    let getOrders = `select * from OrderMasters where IsOrderFailed='true' `;

    if (isNotVerifoneOrder) {
      getOrders += " and ThirdPartyPaymentID != '17'";
    }

    return DatabaseHandler.getDataFromQuery<OrderMasterRes[]>(
      getOrders
    ).toPromise<OrderMasterRes[]>();
  }

  //Convert order res data to req data end

  // Remove Current Detail From db
  private removeOfflineOrderDetail(listAppRefId: string[]) {
    this.removeOrderDataFromDb(listAppRefId);
    this.removeItemDataFromDb(listAppRefId);
    this.removeModifierDataFromDb(listAppRefId);
    this.removeCustomerDataFromDb(listAppRefId);
  }

  private removeOrderDataFromDb(listAppRefId: string[]) {
    return new Promise<OrderMasterRes[]>((resolve: any) => {
      let deleteOrders = `delete from OrderMasters where appRefId in (`;

      for (let i = 0; i < listAppRefId.length; i++) {
        if (i == 0) {
          deleteOrders += "'" + listAppRefId[i] + "'";
        } else {
          deleteOrders += `,\'${listAppRefId[i]}\'`;
        }
      }
      deleteOrders += ')';

      const errorCallback = (statement: any, error: any) => {
        var log = new loggingData(
          'Offline Order Error',
          'Encountered Error on Removing Order Data From LocalDB',
          `Offline Order Error`,
          `Encountered Error Removing Data ${listAppRefId}: ${error}`,
          true
        );
        this.logger.sendLogToServer(log);
      };

      const setOrders = () => {
        resolve(null);
      };

      DatabaseHandler.executeSqlStatement(
        deleteOrders,
        [],
        setOrders,
        errorCallback
      );
    });
  }

  private removeItemDataFromDb(listAppRefId: string[]) {
    return new Promise<OrderMasterRes[]>((resolve: any) => {
      let deleteOrders = `delete from OrderItemMasters where AppRefIDOrder in (`;

      for (let i = 0; i < listAppRefId.length; i++) {
        if (i == 0) {
          deleteOrders += "'" + listAppRefId[i] + "'";
        } else {
          deleteOrders += `,\'${listAppRefId[i]}\'`;
        }
      }
      deleteOrders += ')';

      const errorCallback = (statement: any, error: any) => {
        var log = new loggingData(
          'Offline Order Error',
          'Encountered Error on Removing Item Data From LocalDB',
          `Offline Order Error`,
          `Encountered Error Removing Data ${listAppRefId}: ${error}`,
          true
        );
        this.logger.sendLogToServer(log);
      };

      const setOrders = () => {
        resolve(null);
      };

      DatabaseHandler.executeSqlStatement(
        deleteOrders,
        [],
        setOrders,
        errorCallback
      );
    });
  }

  private removeModifierDataFromDb(listAppRefId: string[]) {
    return new Promise<OrderMasterRes[]>((resolve: any) => {
      let deleteOrders = `delete from OrderItemModifierMasters where AppRefIDOrder in (`;

      for (let i = 0; i < listAppRefId.length; i++) {
        if (i == 0) {
          deleteOrders += "'" + listAppRefId[i] + "'";
        } else {
          deleteOrders += `,\'${listAppRefId[i]}\'`;
        }
      }
      deleteOrders += ')';

      const errorCallback = (statement: any, error: any) => {
        var log = new loggingData(
          'Offline Order Error',
          'Encountered Error on Removing Modifier Data From LocalDB',
          `Offline Order Error`,
          `Encountered Error Removing Data ${listAppRefId}: ${error}`,
          true
        );
        this.logger.sendLogToServer(log);
      };

      const setOrders = () => {
        resolve(null);
      };

      DatabaseHandler.executeSqlStatement(
        deleteOrders,
        [],
        setOrders,
        errorCallback
      );
    });
  }

  private removeCustomerDataFromDb(listAppRefId: string[]) {
    return new Promise<OrderMasterRes[]>((resolve: any) => {
      let deleteOrders = `delete from CustomerMasters where AppRefID in (`;

      for (let i = 0; i < listAppRefId.length; i++) {
        if (i == 0) {
          deleteOrders += "'" + listAppRefId[i] + "'";
        } else {
          deleteOrders += `,\'${listAppRefId[i]}\'`;
        }
      }
      deleteOrders += ')';

      const errorCallback = (statement: any, error: any) => {
        var log = new loggingData(
          'Offline Order Error',
          'Encountered Error on Removing Customer Data From LocalDB',
          `Offline Order Error`,
          `Encountered Error Removing Data ${listAppRefId}: ${error}`,
          true
        );
        this.logger.sendLogToServer(log);
      };

      const setOrders = () => {
        resolve(null);
      };

      DatabaseHandler.executeSqlStatement(
        deleteOrders,
        [],
        setOrders,
        errorCallback
      );
    });
  }
  // Remove Current Detail From db End

  private async submitOfflineOrderToBackend(orderData: OrderListReq) {
    this.dataService
      .submitOrder(orderData)
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            var resData = data as OrderUpRes;

            const listAppRefID = resData.OrderMasters.map(
              (order) => order.AppRefID
            );

            this.setOrderFailedFalse(listAppRefID,orderData.OrderMaster[0].TransactionID);
          });
        } else {
        }
      })
      .catch((error) => {
        var log = new loggingData(
          'Offline Order Error',
          'Encountered Error on Sending Data to Backend',
          `Offline Order Error`,
          `Encountered Error Sending Data: ${error}`,
          true
        );
        this.logger.sendLogToServer(log);
      });
  }

  private setOrderFailedFalse(listAppRefId: string[], transactionId: string) {

    let updateOrder = `update OrderMasters set IsOrderFailed='false',TransactionID='${transactionId}' where appRefId in (`;

    for (let i = 0; i < listAppRefId.length; i++) {
      if (i == 0) {
        updateOrder += "'" + listAppRefId[i] + "'";
      } else {
        updateOrder += `,\'${listAppRefId[i]}\'`;
      }
    }
    updateOrder += ')';

    const errorCallback = (statement: any, error: any) => {
      var log = new loggingData(
        'Offline Order Error',
        'Encountered Error on Setting Order Data From LocalDB',
        `Offline Order Error`,
        `Encountered Error Setting OrderFailed to false Data ${listAppRefId}: ${error}`,
        true
      );
      this.logger.sendLogToServer(log);
    };

    const setOrders = () => {};

    DatabaseHandler.executeSqlStatement(
      updateOrder,
      [],
      setOrders,
      errorCallback
    );
  }

  async connetAndSendVerifoneOrderToBackend() {
    this.verifone
      .doConnectVerifoneDevice()
      .toPromise()
      .then(async (data) => {
        this.logger.sendLogToServer(
          new loggingData(
            'Verifone Offline Order',
            'Verifone Offline Order Device connected',
            `Verifone Offline Order Processing Device Connected`,
            '',
            true
          )
        );

        this.getAndSendVerifoneOrderToBackend();
      },error => {
        this.logger.sendLogToServer(
          new loggingData(
            'Not Able to Connect Verifone',
            'Verifone Offline Order Processing Device Not able to connect',
            `Verifone Offline Order Processing Device Connection Error`,
            '',
            true
          )
        );

        this.loader.closeLoader();
      });
  }

  async getAndSendVerifoneOrderToBackend() {
    this.verifone
      .getQueryVerifoneOfflineTransaction()
      .toPromise()
        .then(async (data: IVerifoneQueryRes) => {
        if (
          data &&
          data.RESPONSE &&
          data.RESPONSE.RECORDS &&
          data.RESPONSE.RESULT_CODE == '-1'
        ) {

          var Records = data.RESPONSE.RECORDS.RECORD;

          let oldTransaction = [];

          if(Array.isArray(Records)){
            oldTransaction = Records;
          }
          else{
            oldTransaction = [Records];
          }

          if (!oldTransaction || oldTransaction.length <= 0) {

            this.logger.sendLogToServer(
              new loggingData(
                'Verifone Offline order',
                'Verifone Offline Order Not Procesed',
                `Verifone Offline Orders Not Procesed`,
                JSON.stringify(oldTransaction),
                true
              )
            );

            this.loader.closeLoader();
            return;
          }

          this.logger.sendLogToServer(
            new loggingData(
              'Verifone Offline order',
              'Verifone Offline Order All Processed Order',
              `Verifone Offline Orders Processed on Device/Pos`,
              JSON.stringify(oldTransaction),
              true
            )
          );

          for (let i = 0; i < oldTransaction.length; i++) {
            const tran = oldTransaction[i];

            const invoiceNo = tran.INVOICE;
            const transId = tran.CTROUTD;

            const orderData = await this.getOfflineOrderByInvoiceNo(invoiceNo);

            if (orderData && orderData.length > 0) {

              this.logger.sendLogToServer(
                new loggingData(
                  'Verifone Offline Order Pos Log',
                  'Offline Invoice no :- ' + invoiceNo,
                  `Verifone Pos log`,
                  JSON.stringify(tran),
                  true
                )
              );

              const oldOrder = orderData[0] as OrderMasterRes;

              oldOrder.TransactionID = transId + "-" + invoiceNo;

              const appRefIdOrder = oldOrder.AppRefID;

              const orderItem = await this.getOrderItemByAppRefId(
                appRefIdOrder
              );

              let orderModifier = [] as OrderItemModifierMasterRes[];

              if (orderItem && orderItem.length > 0) {
                orderModifier = await this.getOrderModifierByAppRedIdOrder(
                  appRefIdOrder
                );
              }

              const orderCustomerData =
                await this.getOrderCustomerDataByAppRefID(appRefIdOrder);

              const conSplitBillData = await this.orderHistory.getConcesisonaireOrderSplitBillByInvoiceNoFromSql(oldOrder.OrderID, oldOrder.OrderInvoiceNo);

              var paymentData = null;

              try {
                paymentData = await this.getOrderSuccessPaymentByInvoiceNo(
                  oldOrder.OrderInvoiceNo
                );

                if(paymentData && paymentData.length > 0){
                  let newPaymentData = JSON.parse(JSON.stringify(paymentData[0]));

                  let respText = JSON.parse(paymentData[0].resptext);
                  respText.ts = transId;

                  newPaymentData.resptext = JSON.stringify(respText);

                  paymentData = [newPaymentData];
                }
              } catch (e) {}

              const orderMasterData = this.getOrderMasterFromResData(
                oldOrder,
                orderItem,
                orderModifier,
                orderCustomerData,
                paymentData,
                conSplitBillData
              );

              const objOrderReq = {
                CloverCustomerID: '',
                OrderMaster: [orderMasterData],
              } as OrderListReq;

              this.logger.sendLogToServer(
                new loggingData(
                  'Send Verifone Offline Order Backend',
                  'Offline Invoice no :- ' + invoiceNo,
                  `Verifone Offline Order`,
                  JSON.stringify(objOrderReq),
                  true
                )
              );

              this.submitOfflineOrderToBackend(objOrderReq);
            }
          }
        }
        else{
          this.logger.sendLogToServer(
            new loggingData(
              'Verifone Offline Has not processed any order',
              'Verifone Offline Has not processed any order',
              `Verifone Offline Has not processed any order`,
              '',
              true
            )
          );
        }
        this.loader.closeLoader();
      },
      error => {
        this.loader.closeLoader();
      });
  }

  private async sendOrderToBackend(oldOrder: OrderMasterRes, transId: string) {
    oldOrder.TransactionID = transId;

    const appRefIdOrder = oldOrder.AppRefID;

    const orderItem = await this.getOrderItemByAppRefId(appRefIdOrder);

    let orderModifier = [] as OrderItemModifierMasterRes[];

    if (orderItem && orderItem.length > 0) {
      orderModifier = await this.getOrderModifierByAppRedIdOrder(appRefIdOrder);
    }

    const orderCustomerData = await this.getOrderCustomerDataByAppRefID(
      appRefIdOrder
    );
    var paymentData = null;

    const conSplitBillData = await this.orderHistory.getConcesisonaireOrderSplitBillByInvoiceNoFromSql(oldOrder.OrderID, oldOrder.OrderInvoiceNo);

    try {
      paymentData =await this.getOrderSuccessPaymentByInvoiceNo(
        oldOrder.OrderInvoiceNo
      );
    } catch (e) {}
    const orderMasterData = this.getOrderMasterFromResData(
      oldOrder,
      orderItem,
      orderModifier,
      orderCustomerData,
      paymentData,
      conSplitBillData
    );

    const objOrderReq = {
      CloverCustomerID: '',
      OrderMaster: [orderMasterData],
    } as OrderListReq;

    this.submitOfflineOrderToBackend(objOrderReq);
  }

  private getOfflineOrderByInvoiceNo(invoiceNo: string) {
    return new Promise<OrderMasterRes[]>((resolve: any) => {
      let invoice = invoiceNo.trim();
      const getOrders = `select * from OrderMasters where OrderInvoiceNo like '%${invoice}%' and IsOrderFailed = 'true'`;

      const errorCallback = (statement: any, error: any) => {
        resolve([] as OrderMasterRes[]);
      };

      const setOrders = (tx: string, results: any) => {
        var data: OrderMasterRes[] = Array.from(results.rows);

        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getOrders,
        [],
        setOrders,
        errorCallback
      );
    });
  }

  getOrderByInvoiceNo(invoiceNo: string) {
    return new Promise<OrderMasterRes[]>((resolve: any) => {

      let invoice = invoiceNo.trim();
      const getOrders = `select * from OrderMasters where OrderInvoiceNo = '${invoice}'`;

      const errorCallback = (statement: any, error: any) => {
        resolve([] as OrderMasterRes[]);
      };

      const setOrders = (tx: string, results: any) => {
        var data: OrderMasterRes[] = Array.from(results.rows);

        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getOrders,
        [],
        setOrders,
        errorCallback
      );
    });
  }


  public getOrderItemByAppRefId(AppRefIDOrder: string) {
    return new Promise<OrderItemMasterRes[]>((resolve: any) => {
      const getOrderItems = `select * from OrderItemMasters where AppRefIDOrder= '${AppRefIDOrder}'`;

      const errorCallback = (statement: any, error: any) => {
        resolve([] as OrderItemMasterRes[]);
      };

      const setOrders = (tx: string, results: any) => {
        var data: OrderItemMasterRes[] = Array.from(results.rows);

        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getOrderItems,
        [],
        setOrders,
        errorCallback
      );
    });
  }

  private getOrderModifierByAppRedIdOrder(AppRefIDOrder: string) {
    return new Promise<OrderItemModifierMasterRes[]>((resolve: any) => {
      const getOrderItems = `select * from OrderItemModifierMasters where AppRefIDOrder='${AppRefIDOrder}'`;

      const errorCallback = (statement: any, error: any) => {
        resolve([] as OrderItemModifierMasterRes[]);
      };

      const setOrders = (tx: string, results: any) => {
        var data: OrderItemModifierMasterRes[] = Array.from(results.rows);

        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getOrderItems,
        [],
        setOrders,
        errorCallback
      );
    });
  }

  private getOrderCustomerDataByAppRefID(AppRefID: string) {
    return new Promise<CustomerMasterRes[]>((resolve: any) => {
      const getOrderItems = `select * from CustomerMasters where AppRefID='${AppRefID}'`;

      const errorCallback = (statement: any, error: any) => {
        resolve([] as CustomerMasterRes[]);
      };

      const setOrders = (tx: string, results: any) => {
        var data: CustomerMasterRes[] = Array.from(results.rows);

        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getOrderItems,
        [],
        setOrders,
        errorCallback
      );
    });
  }

  private getOrderSuccessPaymentByInvoiceNo(invoiceNo: string) {
    let getOrders = `select * from OrderSuccessPaymentMaster where OrderInvoiceNumber='${invoiceNo}'`;

    return DatabaseHandler.getDataFromQuery<OrderSuccessPaymentMasterReq[]>(
      getOrders
    ).toPromise<OrderSuccessPaymentMasterReq[]>();
  }

  private getOrderMasterFromResData(
    orderMasterData: OrderMasterRes,
    orderItemMasterRes: OrderItemMasterRes[],
    orderItemModifierMasterRes: OrderItemModifierMasterRes[],
    orderCustomerData: CustomerMasterRes[],
    paymentData: any,
    orderConSplitBillData: ConcessioniareOrderSplitBill[]
  ) {
    const orderMaster = orderMasterData;

    const orderItemData = this.getOrderItemDataFromResData(
      orderItemMasterRes,
      orderItemModifierMasterRes
    );

    let objCustomerData = {} as CustomerListReq;

    if (orderCustomerData.length > 0) {
      objCustomerData = this.getCustomerMasterFromResData(orderCustomerData[0]);
    }

    let customerID = orderMaster.CustomerID;

    const objOrderMasterReq = {
      OrderID: 0,
      OrderInvoiceNo: orderMaster.OrderInvoiceNo,
      TableID: orderMaster.TableID ? Number(orderMaster.TableID) : 0,
      KitchenID: 0,
      SectionID: 0,
      ModuleID: 2,
      StaffModuleID: orderMaster.StaffModuleID
        ? Number(orderMaster.StaffModuleID)
        : 0,
      StaffID: 0,
      CompanyID: orderMaster.CompanyID,
      BranchID: orderMaster.BranchID,
      CustomerID: customerID,
      OrderStatusID: Number(orderMaster.OrderStatusID),
      Subtotal: Number(orderMaster.Subtotal),
      OrderTax: Number(orderMaster.OrderTax),
      ExtraCharge: Number(orderMaster.ExtraCharge),
      ExtraComment: '',
      IsDiscount: orderMaster.IsDiscount == 'True',
      OrderDiscount: Number(orderMaster.OrderDiscount),
      DiscountComment: '',
      OrderTip: Number(orderMaster.OrderTip),
      OrderTotal: Number(orderMaster.OrderTotal),
      IsRefunded: false,
      RefundedAmount: 0.0,
      RefundedComment: '',
      OrderTypeID: orderMaster.OrderTypeID,
      DineTypeID: 0,
      CustomerIP: '',
      PaidDateUtc: orderMaster.CreatedDate,
      CreatedBy: 0,
      IsDeleted: false,
      CreatedDate: orderMaster.CreatedDate,
      CustomOrderNumber: '',
      OrderComment: '',
      IsCancel: false,
      CancelComment: '',
      CancelAmount: 0.0,
      PaymentTypeID: orderMaster.PaymentTypeID,
      CardTypeID: 0,
      CardNetworkID: orderMaster.CardNetworkID
        ? Number(orderMaster.CardNetworkID)
        : 0,
      OrderPlaceID: orderMaster.OrderPlaceID
        ? Number(orderMaster.OrderPlaceID)
        : 0,
      TenorAmount: 0.0,
      ChangeAmount: 0.0,
      OrderSize: 0,
      AppRefID: orderMaster.AppRefID,
      CustomerAppRefID: orderMaster.CustomerAppRefID,
      TokenCount: orderMaster.TokenCount ? orderMaster.TokenCount : '',
      T_TokenCount: orderMaster.T_TokenCount
        ? Number(orderMaster.T_TokenCount)
        : 0,
      D_TokenCount: orderMaster.D_TokenCount
        ? Number(orderMaster.D_TokenCount)
        : 0,
      OtherCharges: 0.0,
      StaffLocationID: 0,
      BranchSectionConcessionaireID: orderMaster.BranchSectionConcessionaireID
        ? Number(orderMaster.BranchSectionConcessionaireID)
        : 0,
      MergeTableID: 0,
      IsMergeTable: false,
      DiscountTypeOnID: 0,
      ThirdPartyPaymentID: orderMaster.ThirdPartyPaymentID
        ? Number(orderMaster.ThirdPartyPaymentID)
        : 0,
      TransactionID: orderMaster.TransactionID ? orderMaster.TransactionID : '',
      AuthorizationID: orderMaster.AuthorizationID
        ? orderMaster.AuthorizationID
        : '',
      SplitbillType: '',
      EvenallySize: '',
      ItemwiseSize: '',
      IsSplitBill: false,
      AttandanceAppRefID: '',
      OrderCashDiscount: 0.0,
      RefundedDate: '',
      IsUpChargeCard: false,
      IsPercentageCard: false,
      PercentageOrAmounCard: 0.0,
      UpChargeAmountCard: 0.0,
      IsUpChargeCash: orderMaster.IsUpChargeCash
        ? orderMaster.IsUpChargeCash == 'True'
        : false,
      IsPercentageCash: orderMaster.IsPercentageCash
        ? orderMaster.IsPercentageCash == 'True'
        : false,
      PercentageOrAmounCash: orderMaster.PercentageOrAmounCash
        ? Number(orderMaster.PercentageOrAmounCash)
        : 0,
      UpChargeAmountCash: orderMaster.UpChargeAmountCard
        ? Number(orderMaster.UpChargeAmountCard)
        : 0,
      PercentageOrFlatRateVal: '',
      PercentageOrFlatRate: '',
      OrderDiscountTypeID: orderMaster.OrderDiscountTypeID,
      DiscountSelectedTypeID: '',
      CustomerGroupID: 0,
      CustomerName: orderMaster.CustomerName ? orderMaster.CustomerName : '',
      AutoGratuityTip: 0.0,
      AutoGratuityPercentage: 0.0,
      TenderTypeID: 0,
      RefEarnedPoints: 0,
      RefDiscRedeemedPoints: 0,
      RefRedeemedPoints: 0,
      RefTotalPoints: 0,
      TaxableAmount: Number(orderMaster.TaxableAmount),
      CFDID: '',
      PickupDate: '',
      DeliveryDate: '',
      IsMergeOrder: false,
      MergeOrderInvoiceNo: '',
      TPOrderID: '',
      OraclePriceDifferent: 0.0,
      CloverOrderId: '',
      IsFireOrder: false,
      RevenueCenterID: 0,
      levelupuuid: 0,
      ShippingTypeID: 0,
      ShippingAmount: 0,
      CustomerMaster: objCustomerData,
      CurbsideDetail: {} as CurbsideDetailMaster,
      ExternalOrderDetails: [] as ExternalOrderDetail[],
      ItemInventory: [] as ItemInventoryReq[],
      OnlineCustomerMaster: [] as OnlineCustomerMasterReq[],
      OrderItemMaster: orderItemData,
      OrderOtherCharge: [] as OrderOtherChargeReq[],
      OrderPartialPaymentMaster: [] as OrderPartialPaymentMasterReq[],
      OrderRefundPaymentMaster: [] as OrderRefundPaymentMasterReq[],
      OrderSplitBill: orderConSplitBillData,
      ConcessioniareOrderSplitBill: orderConSplitBillData,
      OrderSuccessPaymentMaster: paymentData
        ? paymentData
        : ([] as OrderSuccessPaymentMasterReq[]),
    } as OrderMasterReq;

    return objOrderMasterReq;
  }

  private getOrderItemDataFromResData(
    listOrderItemRes: OrderItemMasterRes[],
    orderItemModifierMasterRes: OrderItemModifierMasterRes[]
  ) {
    const listObjOrderItem = [] as OrderItemMasterReq[];

    for (let index = 0; index < listOrderItemRes.length; index++) {
      const objOrderItem = listOrderItemRes[index];

      const appRefIdOrderItem = objOrderItem.AppRefID;

      const modifierList = this.getItemModifierFormResData(
        orderItemModifierMasterRes
      );

      const objOrderItemReq = {
        AppRefID: objOrderItem.AppRefID,
        AppRefIDOrder: objOrderItem.AppRefIDOrder,
        BranchID: objOrderItem.BranchID,
        CompanyID: objOrderItem.CompanyID,
        CreatedDate: objOrderItem.CreatedDate,
        CategoryID: objOrderItem.CategoryID,
        ItemAttributeID: objOrderItem.ItemAttributeID,
        ItemAttributeOptionID: objOrderItem.ItemAttributeOptionID,
        ItemID: objOrderItem.ItemID,
        ItemName: objOrderItem.ItemName,
        ItemPrice: Number(objOrderItem.ItemPrice),
        ItemQuantity: Number(objOrderItem.ItemQuantity),
        ItemTax: Number(objOrderItem.ItemTax),
        TaxPer: Number(objOrderItem.TaxPer),
        ItemTotal: Number(objOrderItem.ItemTotal),
        OriginalProductCost: Number(objOrderItem.OriginalProductCost),
        OrderItemModifierMaster: modifierList,
        StartDate: objOrderItem.CreatedDate,
        TaxGroupID: Number(objOrderItem.TaxGroupID),
        EndDate: objOrderItem.CreatedDate,
        DiscountAmount: Number(objOrderItem.DiscountAmount),
        DiscountPercentage: Number(objOrderItem.DiscountPercentage),
        DiscountComment: '',
        IsDiscount: objOrderItem.IsDiscount == 'True',
        DiscountID: Number(objOrderItem.DiscountID),
        OrderItemCashDiscount: Number(objOrderItem.OrderItemCashDiscount),
        DiscountTypeID: Number(objOrderItem.DiscountTypeID),
        OrderItemComment: objOrderItem.OrderItemComment,
        UpSellItemID: objOrderItem.UpSellItemID,
        UpSellType: 0,
        UpsellItemPrice: Number(objOrderItem.UpsellItemPrice),
        OrderItemModifierGroupMaster: [],
        IsCombo: objOrderItem.IsCombo == 'True',
        ComboID:objOrderItem.ComboID,
        CustomerID: '0',
        CreatedBy: 1,
        GuestName: objOrderItem.GuestName,
        BranchSectionConcessionaireID: 0,
        CancelComment: '',
        CancelledAmount: 0,
        ChefID: 0,
        ComplimentaryAmount: 0,
        ComplimentaryComment: '',
        CourseID: 0,
        EvenallyPaidno: '',
        EvenalyChanged: '',
        EvenalyPayment: '',
        EvenalyPaymenttype: '',
        EvenalyTendered: '',
        HasModifiedPrice: false,
        HoldItem: false,
        IsCancel: false,
        IsComplimentary: false,
        IsInventoryTracked: false,
        IsLoyaltyItem: false,
        IsPercentage: false,
        IsRefunded: false,
        IsSchedulePromotionAvailable: false,
        IsSpoiled: false,
        ItemCategorySalesTypeID: 0,
        ItemwisePageNo: '',
        KitchenID: 0,
        KotTokenNo: 0,
        OrderID: 0,
        OrderItemID: 0,
        RedeemPoints: 0,
        RefundedAmount: 0,
        RefundedComment: '',
        SchedulePromotionID: 0,
        SplitbillChanged: '',
        SplitbillPayement: '',
        SplitbillPayementType: '',
        SplitbillTendered: '',
        SpoiledAmount: 0,
        SpoiledComment: '',
        StaffLocationID: 0,
        TableID: 0,
        TableSeatNo: 0,
        TaxLocationID: 0,
        OrderReviewDisplayName: objOrderItem.OrderReviewDisplayName,
      } as OrderItemMasterReq;

      listObjOrderItem.push(objOrderItemReq);
    }

    return listObjOrderItem;
  }

  private getItemModifierFormResData(
    listObjOrderModifierRes: OrderItemModifierMasterRes[]
  ) {
    const listObjOrderModifierReq = [] as OrderItemModifierMasterReq[];

    for (let index = 0; index < listObjOrderModifierRes.length; index++) {
      const objModifier = listObjOrderModifierRes[index];

      const modifier = {
        OrderItemModifierID: objModifier.OrderItemModifierID,
        OrderID: 0,
        OrderItemID: '0',
        ModifierDetailID: objModifier.ModifierDetailID,
        ModifierID: objModifier.ModifierID,
        SelectionParentID: objModifier.SelectionParentID,
        ModifierName: '',
        IngredientID: objModifier.IngredientID,
        IngredientName: '',
        UnitTypeID: Number(objModifier.UnitTypeID),
        Quantity: Number(objModifier.Quantity),
        ExtraPrice: Number(objModifier.ExtraPrice),
        TotalPrice: Number(objModifier.TotalPrice),
        Countable: objModifier.Countable == 'True',
        IsDefault: objModifier.IsDefault == 'True',
        LogicID: Number(objModifier.LogicID),
        AppRefIDOrder: objModifier.AppRefIDOrder,
        AppRefIDOrderItem: objModifier.AppRefIDOrderItem,
        AppRefID: objModifier.AppRefID,
        IsPaid: Number(objModifier.IsPaid),
        IsNegativeModifier: objModifier.IsNegativeModifier == 'True',
        Price: Number(objModifier.Price),
      } as OrderItemModifierMasterReq;

      listObjOrderModifierReq.push(modifier);
    }

    return listObjOrderModifierReq;
  }

  private getCustomerMasterFromResData(customerData: CustomerMasterRes) {
    return {
      AccountNo: '',
      AppRefID: customerData.AppRefID,
      BAddress1: customerData.BAddress1,
      BAddress2: customerData.BAddress2,
      BCityID: Number(customerData.BCityID),
      BCityName: '',
      BCountryID: Number(customerData.BCountryID),
      BCountryName: '',
      BStateID: Number(customerData.BStateID),
      BStateName: '',
      BZipCode: customerData.BZipCode,
      BankedRewards: customerData.BankedRewards,
      BranchID: customerData.BranchID,
      CanOrderOnDIYDevices: '',
      CompanyID: customerData.CompanyID,
      CompanyName: customerData.CompanyName,
      ConsumerPan: customerData.ConsumerPan,
      CreatedBy: Number(customerData.CreatedBy),
      CreatedDate: customerData.CreatedDate,
      CustomerID: customerData.CustomerID,
      CustomerNo: customerData.MobileNo,
      CustomerSignupTypeID: Number(customerData.CustomerSignupTypeID),
      CustomerTypeID: Number(customerData.CustomerTypeID),
      DOA: customerData.DOA,
      DOB: customerData.DOB,
      DisplayName: customerData.DisplayName,
      EmailID: customerData.EmailID,
      EmailVerified: customerData.EmailVerified == 'True',
      Facebook: customerData.Facebook,
      FullName: customerData.FullName,
      Gender: Number(customerData.Gender),
      IPAddress: '',
      ImageUrl: '',
      IndustryID: Number(customerData.IndustryID),
      Instagram: customerData.Instagram,
      IsActive: 'true',
      IsDeleted: 'false',
      IsGuest: 'true',
      Linkedin: customerData.Linkedin,
      LoyaltyId: customerData.LoyaltyId,
      MembershipLevel: customerData.MembershipLevel,
      MembershipLevelId: customerData.MembershipLevelId,
      MobileCode: customerData.MobileCode,
      MobileNo: customerData.MobileNo,
      NetBalance: customerData.NetBalance,
      NetDebits: customerData.NetDebits,
      NoOfGuest: Number(customerData.NoOfGuest),
      Notes: customerData.Notes,
      Password: customerData.Password,
      PendingPoints: customerData.PendingPoints,
      PointsBalance: customerData.PointsBalance,
      RefLoyaltyCustomerID: customerData.RefLoyaltyCustomerID,
      RefUserID: '0',
      SAddress1: customerData.SAddress1,
      SAddress2: customerData.SAddress2,
      SCityID: Number(customerData.SCityID),
      SCityName: '',
      SCountryID: Number(customerData.SCountryID),
      SCountryName: '',
      SStateID: Number(customerData.SStateID),
      SStateName: '',
      SZipCode: customerData.SZipCode,
      SignupAnniversaryDay: customerData.SignupAnniversaryDay,
      Skype: customerData.Skype,
      TaxNo: customerData.TaxNo,
      TaxTypeID: Number(customerData.TaxTypeID),
      TotalCredits: customerData.TotalCredits,
      TotalDebits: customerData.TotalDebits,
      TotalPointCredits: customerData.TotalPointCredits,
      Twitter: customerData.Twitter,
      UpdatedBy: Number(customerData.UpdatedBy),
      UpdatedDate: customerData.UpdatedDate,
      Website: customerData.Website,
      RefCloverCustomerID: '0',
      TitleID: Number(customerData.TitleID),
      MemberID: '0',
      IsAllowForMarketingCommunication : customerData.IsAllowForMarketingCommunication
    } as CustomerListReq;
  }



}
