<div id="areyousure">
    <div class="modal-body areyousure-modal">
        <h1 class="text-center header-text">{{cancelOrderText}}</h1>
        <div class="content">
            <h4 class="text-center sub-text">{{confirmText}}</h4>

            <div class="footer">
                <div class="col-12 col-md-12 popup-footer text-center">
                    <img src="{{ cancelOrderImage }}"
                        onerror="this.onerror=null; this.src='assets/images/Image_21.png'" />

                    <button matRipple class="green-btn text-uppercase" type="submit" name="submits" (click)="close()"
                        [style.background]="positiveColor" [style.border]="positiveColor"
                        [style.color]="positiveColorText">{{noText|uppercase}}</button>
                    <br />
                    <button matRipple class="white-btn warn text-uppercase" (click)="cancel()"
                        [style.background]="negativeColor" [style.color]="negativeColorText">{{yesText|uppercase}}</button>
                </div>
            </div>

        </div>
    </div>
</div>