export enum LoyaltyType {
  None = 0,
  OLOPunchh = 1,
  Referrizer = 2,
  LevelUp = 3,
  OLOPaytronix = 4,
}
export interface LoyaltyInfo {
  IsActive:string;
  Name:string;
  ThirdPartyLoyaltyID:string;
}
export interface RefLoyaltyCustomerList {
  loyaltyCustomers: RefLoyaltyCustomer[];
}
export interface LevelUpCustomer {
  CustomerID: string;
  loyalty_id: string;
  name: string;
  orders_count: number;
  discount?: string;
}
export interface PaytronixCustomer extends PaytronixCustomerResponse {
  membershipid?: string;
}
export interface ReturningCustomer {
  AccountNo: string;
  AppRefID: string;
  BAddress1: string;
  BAddress2: string;
  BCityName: string;
  BCountryName: string;
  BStateName: string;
  BZipCode: string;
  BranchID: string;
  CompanyID: string;
  ConsumerPan: string;
  CreatedDate: string;
  CustomerID: string;
  CustomerNo: string;
  DOA: string;
  DOB: string;
  EmailID: string;
  FullName: string;
  IsAllowForMarketingCommunication: string;
  IsGuest: string;
  MobileCode: string;
  MobileNo: string;
  NoOfGuest: string;
  Password: string;
  RefUserID: string;
}
export interface PunchhLoyaltyCustomer {
  Firstname: string;
  LastName: string;
  MembershipID: string;
  Phone: string;
  authtoken: string;
  basketID?: string;
}
export interface OLOPaytronixLoyaltyRewardDetail {
  applied: boolean;
  availableoffline: boolean;
  availableonline: boolean;
  categories: any;
  description: any;
  expirationdate: string;
  externalreference: any;
  fineprint: any;
  imageurl: any;
  items: any;
  label: string;
  membershipid: number;
  quantityapplied: number;
  quantityavailable: number;
  reference: string;
  rewardid: any;
  type: string;
  validminutes: any;
  value: number;
  vendorrefs: any;
}
export interface PunchhLoyaltyRewardDetail {
  applied: boolean;
  availableoffline: boolean;
  availableonline: boolean;
  categories: string | null;
  description: string;
  expirationdate: string;
  externalreference: string;
  fineprint: string;
  imageurl: string;
  items: any;
  label: string;
  membershipid: number;
  quantityapplied: number;
  quantityavailable: number;
  reference: string;
  rewardid: string | null;
  type: string;
  validminutes: number;
  value: number;
  vendorrefs: string | null;
}
export interface RefLoyaltyCustomer {
  id: string;
  userId: string;
  customerID: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  birthDate: string;
  registrationDate: string;
  firstVisitDate: string;
  lastVisitDate: string;
  visitsCount: number;
  loyaltyPointsCount: number;
  type: string;
  description: string;
  refRegistrationDate: string;
  refFirstVisitDate: string;
  refLastVisitDate: string;
  labels: RefLoyaltyLabels[];
  shouldSendNotification: boolean;
  pointEarnedLasttime?: string;
}
export interface RefLoyaltyLabels {
  id: string;
  name: string;
  color: string;
}

export interface RefCustomerError {
  message: string;
  code: string;
  errorCode: string;
  path: string;
  timestamp: string;
}

export interface RefCustomerResponse {
  custErrorResponse: RefCustomerError;
  custSuccessResponse: RefLoyaltyCustomer;
}
export interface PaytronixCustomerResponse {
  result?: string;
  authorizationcode: string|null;
  authtoken: string;
  basketid: string|null;
  contactnumber: string|null;
  emailaddress: string|null;
  expiresin: string|null;
  firstname: string|null;
  lastname: string|null;
  provider: string;
  providertoken: string;
  provideruserid: string;
  refreshtoken: string|null;
}
export interface getLoyaltyCustomerResponse {
  status: string;
  statusCode: string;
  data: RefLoyaltyCustomer;
  message: string;
}
export interface LevelUpItem {
  item: {
    charged_price_amount: number;
    description: string;
    name: string;
    quantity: number;
    sku: string;
    category: string;
    standard_price_amount: number;
    children: LevelUpModifier[];
    upc?: string;
  };
}
export interface LevelUpModifier {
  item: {
    charged_price_amount: number;
    description: string;
    name: string;
    quantity: number;
    sku?: string;
    category?: string;
    standard_price_amount?: string;
  };
}
export interface LevelUpCompletedOrder {
  levelUpCompletedOrder: {
    completed_order: {};
  };
}
export interface LevelUpProposalResponse {
  currency: string;
  discount_amount: number;
  rewards: any[];
  uuid: string;
}
export interface LevelUpObject {
  /** * @param order body used to propose order to levelup */ 
  order: LevelUpProposal|undefined;
  /** * @param proposal proposal response from levelup */ 
  proposal: LevelUpProposalResponse|undefined;
  /** * @param completedOrder body used to complete order to levelup */ 
  completedOrder: LevelUpCompletedOrder;
  /** * @param completion complete order response from levelup */ 
  completion: any;
  /** * @param fail flag used to bypass levelup features if experiencing network issues */ 
  fail: boolean;
  /** * @param qr sent as payment token for order completion, needed to pay with levelup */ 
  qr?: string;
  /** * @param originalQr backup of qr used in case of multiple scans*/ 
  originalQr?: string;
  /** * @param uuid only used after successful order redemption, token to identify order */ 
  uuid?: string;
  /** * @param visits number of logged in orders made at this location */ 
  visits?: any;
}
export interface LevelUpProposal {
  proposed_order: {
    payment_token_data: string;
    cashier?: string;
    discount_only: boolean;
    receipt_message_html: null;
    exemption_amount: number
    proposed_order_uuid?: string;
    register?: string;
    tax_amount: number;
    tip_amount: number;
    identifier_from_merchant: string;
    partial_authorization_allowed: boolean;
    location_id: number;
    spend_amount: number;
    items: LevelUpItem[];
  };
}

export interface ReferrizerLoyaltyRewardDetail {
  id: string;
  title: string;
  points: string;
  expires: boolean;
  startDate: string;
  expiredDate: string;
  type: string;
  value: string;
  ItemID: string;
  MultiItemIDs?: string[];
  Items?: any[];
  totalQuantity: string;
  perContactQuantity: string;
  totalAvailableQuantity: string;
  perContactAvailableQuantity: string;
}

export interface customerVisitRes {
  id: string;
  date: string;
  points: number;
  contactId: string;
  userId: string;
  checkinSource: string;
  amount: string;
  message: string;
  LoyaltyID: number;
  punchCheckinReq?: any;
}
