<div class="position-relative" #selectionComponent>
  <section class="">
    <div class="combopack-selection">
      <div
        [ngClass]="((modifiers.length != 0 && modifiers.length <= 4) || (comboGroups.length != 0 && comboGroups.length <= 4)) ? 'd-flex justify-content-center' : ''"
        class="combopack-category">
        <ng-container *ngIf="variations && variations.length > 0">
          <div class="col-sm-auto mx-4">
            <div class="combpart align-items-center d-flex" #target>
              <input type="radio" id="{{ ItemName + '_' + selectedVariation.ItemAttributeID }}" name="combopack"
                class="d-none" (click)="onVariationSelect()" [checked]="isVariationSelected" />
              <label matRipple class="text-center selection-card position-relative"
                for="{{ ItemName + '_' + selectedVariation.ItemAttributeID }}" [ngStyle]="{
                  backgroundColor: selectedVariation ? primaryColor : '',
                  borderColor: selectedVariation ? primaryColor : '',
                  color:  selectedVariation ? '#ffffff' : ''
                }">
                <div class="card-required">
                  <p class="bold">{{requiredText|uppercase}}</p>
                </div>

                <div class="add">
                  <img class="add-image" src="../../../../assets/images/plus.svg"
                    onerror="this.onerror=null; this.src='../../../../assets/images/Image_21.png';"
                    *ngIf="isObjectEmpty(selectedVariation)" />

                  <div *ngIf="!isObjectEmpty(selectedVariation)" class="container image-container">
                    <div class="rounded-circle image-row">
                      <div class="row remove-gutter">
                        <img alt=""
                          src="{{ selectedVariation.ImageUrl && selectedVariation.ImageUrl !='' ? imageUrl + selectedVariation.ImageUrl : placeholderImage !='' ? imageUrl+placeholderImage : 'assets/images/Image_21.png'}}"
                          onerror="this.onerror=null; this.src='assets/images/Image_21.png';"
                          class="restrict-height selected-images" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="cholder">
                  <div class="card-text translate-middle-x start-50">
                    <span>{{ 1 }} {{ofText}} {{ modifiers.length + 1 }}</span>
                    <span>
                      {{ variations[0].Name }}
                    </span>
                  </div>
                </div>
              </label>
              <i *ngIf="0 < modifiers.length - 1" class="fas fa-caret-right card-icon"></i>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="modifiers && modifiers.length > 0">
          <div class="col-sm-auto mx-4" *ngFor="let modifier of modifiers; let i = index">
            <div class="combpart align-items-center d-flex" #target>
              <input type="radio" id="{{
                  ItemId +
                    '_' +
                    modifier.ModifierID +
                    '_' +
                    i +
                    '_' +
                    modifierStartingIndex
                }}" name="combopack" class="d-none" (click)="onModifierSelect(i)" [checked]="
                  !isVariationSelected &&
                  isModifierSelected &&
                  i == modifierSelectedIndex
                " />
              <label matRipple class="text-center selection-card position-relative" for="{{
                  ItemId +
                    '_' +
                    modifier.ModifierID +
                    '_' +
                    i +
                    '_' +
                    modifierStartingIndex
                }}" [ngStyle]="{
                  backgroundColor:
                    !isVariationSelected &&
                    isModifierSelected &&
                    modifierSelectedIndex == i
                      ? primaryColor
                      : '',
                  borderColor:
                    !isVariationSelected &&
                    isModifierSelected &&
                    modifierSelectedIndex == i
                      ? primaryColor
                      : '',
                  color:
                    !isVariationSelected &&
                    isModifierSelected &&
                    modifierSelectedIndex == i
                      ? '#ffffff'
                      : ''
                }">
                <div class="card-required">
                  <p *ngIf="modifier.min > 0" class="bold">{{requiredText|uppercase}}</p>
                </div>

                <div class="add">
                  <img class="add-image" src="../../../assets/images/plus.svg"
                    onerror="this.onerror = null;this.src = 'assets/images/Image_21.png'" *ngIf="
                      getSelectedIngredientArrayByModifier(modifier).length == 0
                    " alt="" />

                  <div *ngIf="
                      getSelectedIngredientArrayByModifier(modifier).length != 0
                    " class="container image-container">

                    <div class="rounded-circle image-row" *ngFor="

                          let ingredient of getSelectedIngredientArrayByModifier(
                            modifier
                          );
                          let j = index
                        ">
                      <img
                        src="{{ ingredient.ImageUrl && ingredient.ImageUrl !='' ? imageUrl + ingredient.ImageUrl : placeholderImage !='' ? imageUrl+placeholderImage : 'assets/images/Image_21.png'}}"
                        alt="" onerror="this.onerror = null;this.src = 'assets/images/Image_21.png';"
                        class="restrict-height selected-images" *ngIf="j < 6" />

                    </div>
                  </div>
                </div>

                <div class="cholder">
                  <div class="card-text translate-middle-x start-50">
                    <span>
                      {{ i + modifierStartingIndex + 1 }} {{ofText}}
                      {{ modifiers.length + modifierStartingIndex }}
                    </span>
                    <span>
                      {{ modifier.Category }}
                    </span>
                  </div>
                </div>
              </label>
              <i *ngIf="i < modifiers.length - 1" class="fas fa-caret-right card-icon"></i>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="comboGroups && comboGroups.length > 0">
          <div class="col-sm-auto mx-4" *ngFor="let comboGroup of comboGroups; let i = index">
            <div *ngIf="!comboGroup.isHidden">
              <div class="combpart align-items-center d-flex" #target>
                <input type="radio" id="{{ ItemId + '_' + comboGroup.ComboGroupID + '_' + i }}" name="combopack"
                  class="d-none" (click)="onItemSelect(i)" [checked]="
                  !IsComboGroupSelected && i == comboGroupSelectedIndex
                " />
                <label matRipple class="text-center selection-card position-relative"
                  for="{{ ItemId + '_' + comboGroup.ComboGroupID + '_' + i }}" [ngStyle]="{
                  backgroundColor:
                    IsComboGroupSelected && comboGroupSelectedIndex == i
                      ? primaryColor
                      : '',
                  borderColor:
                    IsComboGroupSelected && comboGroupSelectedIndex == i
                      ? primaryColor
                      : '',
                   color:  IsComboGroupSelected && comboGroupSelectedIndex == i ? '#ffffff' : ''
                }">

                  <div class="card-required">
                    <p *ngIf="comboGroup.min > 0" class="bold">{{requiredText|uppercase}}</p>
                  </div>

                  <div class="add">
                    <img class="add-image" src="../../../assets/images/plus.svg"
                      onerror="this.onerror = null;this.src = 'assets/images/Image_21.png'" *ngIf="
                      getSelectedItemArrayByComboGroup(comboGroup).length == 0
                    " alt="" />

                    <div *ngIf="
                      getSelectedItemArrayByComboGroup(comboGroup).length != 0
                    " class="container image-container">
                      <div class="rounded-circle image-row" *ngFor="
                        let item of getSelectedItemArrayByComboGroup(
                          comboGroup
                        );
                        let j = index
                      ">
                        <img
                          src="{{ item.ImageUrl && item.ImageUrl !='' ? imageUrl + item.ImageUrl : placeholderImage !='' ? imageUrl+placeholderImage : 'assets/images/Image_21.png'}}"
                          alt="" onerror="this.onerror = null;this.src = 'assets/images/Image_21.png';"
                          class="restrict-height selected-images" *ngIf="j < 6" />
                      </div>
                    </div>
                  </div>
                  <div class="cholder">
                    <div class="card-text translate-middle-x start-50">
                      <span>{{ i + 1 }} {{ofText}} {{ comboGroups.length-numInvisibleGroups }}</span>
                      <span>
                        {{ comboGroup.GroupName }}
                      </span>
                    </div>
                  </div>

                </label>
                <i *ngIf="i < comboGroups.length-(numInvisibleGroups+1)" class="fas fa-caret-right card-icon"></i>
              </div>

            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>

  <ng-container *ngIf="variations && variations.length > 0">
    <div class="modifier-description text-center py-4" *ngIf="isVariationSelected">
      <h1>1. {{ variations[0].Name | slice: 0:32 }}</h1>
      <p>
        <span class="required-text" [style.color]="isVariationSelected ? 'green' : 'red'">{{requiredText}}</span>
        {{chooseOneText}}
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="modifiers.length > 0">
    <div class="modifier-description text-center py-4" *ngIf="isModifierSelected">
      <h1>
        {{ modifierStartingIndex + modifierSelectedIndex + 1 }}.
        {{
        modifiers[modifierSelectedIndex].Category.toUpperCase() | slice: 0:32
        }}
      </h1>
      <p>
        <span *ngIf="!isAggregate && modifiers[modifierSelectedIndex].min > 0" class="required-text" [style.color]="
            modifiers[modifierSelectedIndex].isValid ? 'green' : 'red'
          ">{{requiredText}}</span>
        <span *ngIf="isRequired" class="material-icons text-danger">error</span>
        <span *ngIf="isCompleted" class="material-icons text-success">done</span>

        <span [ngStyle]="{color: isCompleted ? 'green': isRequired ? 'red' : null}">
          {{rules}}</span>

        <span *ngIf="showAggregateRule">
          {{modifiers[modifierSelectedIndex].ruleAggregate}}
        </span>


        <!-- {{ modifiers[modifierSelectedIndex].rule }} -->
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="comboGroups.length > 0">
    <div class="modifier-description text-center py-4" *ngIf="IsComboGroupSelected">
      <h1>
        {{ comboGroupSelectedIndex + 1 }}.
        {{
        comboGroups[comboGroupSelectedIndex].GroupName.toUpperCase()
        | slice: 0:32
        }}
      </h1>
      <p>
        <span *ngIf="comboGroups[comboGroupSelectedIndex].min > 0" class="required-text" [style.color]="
            comboGroups[comboGroupSelectedIndex].isValid ? 'green' : 'red'
          ">{{requiredText}}</span>

        {{ comboGroups[comboGroupSelectedIndex].rule }}
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="
      (isModifierSelected && modifiers.length > 0) ||
      (isVariationSelected && variations.length > 0) ||
      (IsComboGroupSelected && comboGroups.length > 0)
    ">
    <div id="selections" [ngStyle]="{
        height: modifiers.length == 1 || comboGroups.length == 1 ? '49vh' : ''
      }" class="modifier-list">
      <div [@.disabled]="noAnimation" id="scrollToTop">
        <div *ngIf="isModifierSelected" class="row overflow-scroll ingrediant-box" style="margin-bottom: 20px;">
          <ng-container *ngIf="modifiers[modifierSelectedIndex]">
            <ng-container *ngFor="
                let ingredient of modifiers[modifierSelectedIndex].Ingredients;
                let i = index
              ">
              <ng-container *ngIf="selectedModifierSubGroup == null || selectedModifierSubGroup != ingredient">
                <app-ingredient class="col-md-3" [parent]="modifiers[modifierSelectedIndex]" [ingredientIndex]="i"
                  (parentChange)="parentChangeIngredient($event)" [ingredient]="ingredient" [ItemType]="2"
                  [ItemName]="ItemName" [ItemDescription]="ItemDescription">
                </app-ingredient>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="selectedModifierSubGroup != null && hasSubCategory">
            <ng-container>
              <div class="modifier-description text-center py-4">
                <h1>
                  {{ (hasSecondLevelSubgroup ? selectedModifierSubGroupLevel2.Category :
                  selectedModifierSubGroup.Category) | slice: 0:32 }}
                </h1>
                <p>
                  <span
                    *ngIf="(hasSecondLevelSubgroup ? selectedModifierSubGroupLevel2.min : selectedModifierSubGroup.min) > 0"
                    class="required-text"
                    [style.color]="(hasSecondLevelSubgroup ? selectedModifierSubGroupLevel2.isValid : selectedModifierSubGroup.isValid) ? 'green' : 'red' ">Required.</span>

                  {{ hasSecondLevelSubgroup ? selectedModifierSubGroupLevel2.rule : selectedModifierSubGroup.rule }}
                </p>
              </div>
            </ng-container>

            <ng-container
              *ngFor="let ingredient of (hasSecondLevelSubgroup ? selectedModifierSubGroupLevel2.Ingredients : selectedModifierSubGroup.Ingredients);let i = index">
              <app-ingredient class="col-md-3"
                [parent]="hasSecondLevelSubgroup ? selectedModifierSubGroupLevel2 : selectedModifierSubGroup"
                [ingredientIndex]="i" (parentChange)="subGroupIngredientChange($event)" [ingredient]="ingredient"
                [ItemType]="2" [ItemName]="ItemName" [ItemDescription]="ItemDescription"
                [IsDisableSubgroup]="modifiers[modifierSelectedIndex].count >= modifiers[modifierSelectedIndex].max && (hasSecondLevelSubgroup ? !selectedModifierSubGroupLevel2.IsSelected : !selectedModifierSubGroup.IsSelected)">
              </app-ingredient>
            </ng-container>
          </ng-container>
        </div>

        <ng-container *ngIf="isVariationSelected && variations.length > 0">
          <div class="row overflow-scroll ingrediant-box" id="ingrediant-box">
            <app-variation [isBuilderMode]="true" [collapsed]="false" [variation]="variations[0]" [variationIndex]="0"
              (SelectedVariationEmiter)="setVariationOptionSelected($event)"></app-variation>
          </div>
        </ng-container>

        <div *ngIf="IsComboGroupSelected" class="row overflow-scroll ingrediant-box">
          <ng-container *ngIf="comboGroups[comboGroupSelectedIndex]">
            <ng-container *ngFor="
                let item of comboGroups[comboGroupSelectedIndex].Items;
                let i = index
              ">
              <app-items class="col-md-3" [parent]="comboGroups[comboGroupSelectedIndex]" [itemIndex]="i"
                (parentChange)="parentChangeItem($event)" [item]="item" [itemType]="2"
                [parentQuantity]="parentQuantity">
              </app-items>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="">
    <div *ngIf="modifiers.length != 0 || comboGroups.length != 0"
      class="navigation-combo position-fixed start-50 translate-middle-x w-100">
      <div class="row justify-content-between align-items-center my-3">
        <div class="col-4 text-center">
          <button *ngIf="
              !this.isVariationSelected &&
              ((this.isModifierSelected &&
                ((this.variations && this.variations.length > 0) ||
                  this.modifierSelectedIndex > 0)) ||
                (this.IsComboGroupSelected &&
                  ((this.variations && this.variations.length > 0) ||
                    this.comboGroupSelectedIndex > 0)))
            " (click)="onPreviousClick()" class="btn btn-lg" [ngStyle]="{
              backgroundColor: primaryColor,
              color: primaryTextColor
            }">
            {{backText|uppercase}}
          </button>
        </div>

        <div *ngIf="
            isVariationSelected ||
            (isModifierSelected &&
              modifierSelectedIndex != modifiers.length - 1) ||
            (IsComboGroupSelected &&
              comboGroupSelectedIndex != comboGroups.length-(numInvisibleGroups+1))
          " class="col-4 text-center">
          <button class="btn btn-lg btn-secondary" [ngStyle]="{
              backgroundColor:
                (isVariationSelected && isVariationValid) ||
                (modifiers &&
                  modifiers.length > 0 &&
                  modifiers[modifierSelectedIndex].isValid) ||
                (comboGroups &&
                  comboGroups.length > 0 &&
                  comboGroups[comboGroupSelectedIndex].isValid)
                  ? primaryColor
                  : '',
              color:
                (isVariationSelected && isVariationValid) ||
                (modifiers &&
                  modifiers.length > 0 &&
                  modifiers[modifierSelectedIndex].isValid) ||
                (comboGroups &&
                  comboGroups.length > 0 &&
                  comboGroups[comboGroupSelectedIndex].isValid)
                  ? primaryTextColor
                  : ''
            }" (click)="onNextClick()">
            {{nextText|uppercase}}
          </button>
        </div>

        <div *ngIf="showFixItemButton" class="col-4 text-center">
          <button matRipple class="btn btn-lg" [ngStyle]="{ backgroundColor: 'red', color: primaryTextColor }"
            [ngClass]="'animated pulse infinite'" (click)="onFixItemsClick()">
            {{fixItemsText}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>