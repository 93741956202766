<div class="modal-body customMsgModal" id="customeOrderMessage">
  <button type="button" class="close" (click)="canceled()">
    <span>&times;</span>
  </button>
  <h1>
    {{ title }}
  </h1>
  <div class="content baseContent">
    <h2 class="text-center beforeAfterText" *ngIf="beforeImageText != ''">{{ beforeImageText }}</h2>
    <div class="img-wrap">
      <img *ngIf="ImageUrl && ImageUrl != ''" [src]="ImageUrl && ImageUrl !='' ? baseImageUrl + ImageUrl : placeholderImage !='' ? baseImageUrl+placeholderImage : 'assets/images/Image_21.png'"
        onerror="this.onerror=null; this.src='assets/images/Image_21.png'" class="img-fluid" alt="image" />
    </div>
    <h2 class="text-center beforeAfterText" *ngIf="afterImageText != ''">{{ afterImageText }}</h2>
  </div>

  <div class="footer">
    <button type="submit"
      class="background-primary-color positive-button-text-color bold green-btn border-primary-color"
      (click)="canceled()">
      Ok
    </button>
  </div>
</div>