<main (click)="documentClick($event)" [ngStyle]="{ backgroundColor: isHidden ? 'rgba(0, 0, 0, 0.6)' : '' }"
  class="home">
  <div *ngIf="showSeparateImage === 'False' || !isHidden">
    <video *ngIf="showSlide === false" autoplay muted loop id="myVideo" onCanPlay="this.play()">
      <source src="{{ baseImageUrl + singleVideo }}" type="video/mp4" />
    </video>

    <ngb-carousel *ngIf="showSlide" #carousel activeId="{{ activeID }}" (slid)="onSlide($event)">
      <ng-template ngbSlide *ngFor="let content of screenArray" id="{{ content.ScreenSaverID }}">
        <video *ngIf="activeID == content.ScreenSaverID && content.video" #videoPlayer class="myVideoarr"
          (canplay)="onCanPlay($event)" muted (play)="videoPlay($event)" (ended)="videoEnded($event)"
          onended="currentTime = 0">
          <source autostart="0" src="{{ baseImageUrl + content.VideoPath }}" type="video/mp4" />
        </video>
        {{ defaultLanguage }}
        <img *ngIf="!content.video" class="active carousel-item" src="{{
            content && content.ImagePath
              ? baseImageUrl + content.ImagePath
              : defaultLanguage === 'en'
              ? '../../../assets/images/default-screen-saver-en.jpg'
              : '../../../assets/images/default-screen-saver-es.jpg'
          }}" onerror="this.onerror = null;this.src = 'assets/images/Image_21.png';" alt="" />
      </ng-template>
    </ngb-carousel>
  </div>
  <div class="container-fluid overlay" id="eat-options" *ngIf="!storeIsClosed">
    <img *ngIf="showSeparateImage === 'True'" class="backgroundimg"
      src="{{ baseImageUrl + separateHomeScreenImagePath }}"
      onerror="this.onerror = null;this.src = 'assets/images/Image_21.png';" alt="" />
    <img *ngIf="showSeparateImage !== 'True'" class="backgroundimg" src="{{
        defaultLanguage === 'en'
          ? '../../../assets/images/default-screen-saver-en.jpg'
          : defaultLanguage === 'es'
          ? '../../../assets/images/default-screen-saver-es.jpg'
          : '../../../assets/images/default-screen-saver-fr.jpg'
      }}" onerror="this.onerror = null;this.src = 'assets/images/Image_21.png';" alt="" />
    <div class="row">
      <div class="col-sm-12 text-center">
        <div *ngIf="branchLogo && branchLogo != 'null'; else defaultLogo" class="logo-box">
          <img [ngClass]="{ opacity0: !isShowHomeScreenLogo }" src="{{ baseImageUrl + branchLogo }}"
            onerror="this.onerror = null;this.src = 'assets/images/Image_21.png';" class="logo-img"
            (click)="enterSettings($event)" id="logoImg" alt="" />
        </div>
        <ng-template #defaultLogo>
          <div class="logo-box">
            <img [ngClass]="{ opacity0: !isShowHomeScreenLogo }" src="{{
                defaultLanguage === 'en'
                  ? 'assets/images/your-logo-goes-here-en.jpg'
                  : defaultLanguage === 'es'
                  ? 'assets/images/your-logo-goes-here.jpg'
                  : 'assets/images/your-logo-goes-here-fr.jpg'
              }}" onerror="this.onerror = null;this.src = 'assets/images/Image_21.png';" class="logo-img"
              (click)="enterSettings($event)" id="logoImg" alt="" />
          </div>
        </ng-template>
      </div>
    </div>
    <div class="row" *ngIf="isHidden">
      <div class="col-md-12" style="display: flex">
        <div class="options-group" *ngIf="!storeIsClosed">
          <ul>
            <li *ngFor="let option of options">
              <a matRipple id="{{ option.OrderTypeID }}" class="location-title"
                (click)="selectLocation($event.target, option)" (mouseover)="styleHoverOption($event.currentTarget)"
                (mouseleave)="styleUnHoverOption($event.currentTarget)" [ngStyle]="{
                  color: optionBorderColor || textColor,
                  'background-color': optionColor,
                  border: optionBorderColor && '5px solid',
                  'border-color': optionBorderColor
                }">{{ option.DisplayLabel }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="reward-button" *ngIf="
        !storeIsClosed &&
        hasLoyaltyProgram &&
        disableScreenSaver === 'True' &&
        !isRemovePricingAndPayments
      ">
      <a (click)="onRewardButtonClick($event)" (mouseover)="hoverReward = true" (mouseleave)="hoverReward = false"
        [ngStyle]="{
          color: hoverReward ? accentColor : textColor,
          'background-color': hoverReward ? textColor : accentColor
        }" style="position: fixed; bottom: 0; width: 100%">
        {{ rewardsAccountSignInText }}
      </a>
    </div>
  </div>
  <div *ngIf="
      !storeIsClosed &&
      showLanguagePicker &&
      isHidden &&
      languageData.length > 1
    " class="language">
    <div class="globe-row">
      <img src="assets/images/globe.png" class="globe-img" id="language" />
      <p class="globe-text">{{ selectLanguageText }}</p>
    </div>
    <div class="flag-row">
      <ng-container *ngFor="let lan of languageData; let i = index">
        <button id="language{{ i + 1 }}" class="flag-button1" [ngStyle]="
            defaultLanguage == lan.Code
              ? {
                  color: optionBorderColor || textColor,
                  'background-color': optionColor,
                  border: optionBorderColor && '5px solid',
                  'border-color': optionBorderColor
                }
              : null
          " (click)="swapLanguage(lan.Code)">
          <!-- <div id="language1" class="flag">
            <img id="language1" src="assets/images/{{ lan.Code }}Img.svg" />
          </div> -->
          {{ languageKeyValue[lan.Code] }}
        </button>
      </ng-container>
    </div>
  </div>
  <div *ngIf="isShowOverlay" class="overlay-language">
    <div *ngIf="!isHidden && !storeIsClosed && false" class="language">
      <div class="globe-row">
        <img src="assets/images/globe.png" (click)="showOverlay()" class="globe-img" id="language" />
      </div>
      <div *ngIf="isShowOverlay" class="flag-row">
        <button id="language1" class="flag-button1" (click)="swapLanguage('en')">
          English
        </button>
        <button id="language2" class="flag-button2" (click)="swapLanguage('es')">
          Español
        </button>
        <button id="language3" class="flag-button3" (click)="swapLanguage('fr')">
          Français
        </button>
      </div>
    </div>
  </div>
  <div class="home" *ngIf="disableScreenSaver === 'False' && isShowSplashScreenMessage">
    <div class="container-fluid" id="home" *ngIf="!isHidden">
      <div class="row">
        <div class="col-sm-12 text-center">
          <div class="home-content">
            <h1 *ngIf="
                !storeIsClosed &&
                (splashScreenMessage != '' || splashScreenMessage != null) &&
                isShowSplashScreenMessage
              ">
              {{ splashScreenMessage }}
            </h1>
            <div *ngIf="storeIsClosed" class="store-closed">
              <h1>{{ storeClosedTimeMessage }}</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <div id="tabButtonDiv" class="touch-button" *ngIf="!storeIsClosed && message != ''">
          <a matRipple (mouseover)="hoverStart = true" (mouseleave)="hoverStart = false" [ngStyle]="{
              color: hoverStart ? bkgdColor : textColor,
              'background-color': hoverStart ? textColor : bkgdColor
            }">
            {{ message }}
          </a>
        </div>

        <div class="reward-button" *ngIf="!storeIsClosed && showLoyaltyButton">
          <a matRipple (mouseover)="hoverReward = true" (click)="onRewardButtonClick($event)"
            (mouseleave)="hoverReward = false" [ngStyle]="{
              color: hoverReward ? accentColor : textColor,
              'background-color': hoverReward ? textColor : accentColor
            }">
            <img src="../../../assets/images/reward.png"
              onerror="this.onerror=null; this.src='assets/images/Image_21.png';" />
            {{ rewardsAccountSignInText }}
          </a>
        </div>
      </div>
    </div>
  </div>

</main>
<div style="display: none">
  <app-manager-pin-dialog [hidden]="true"></app-manager-pin-dialog>
  <app-customer-login-popup [hidden]="true"></app-customer-login-popup>
  <app-order-type-warning></app-order-type-warning>
  <app-returning-customer-dialog [hidden]="true"></app-returning-customer-dialog>
  <app-update-menu></app-update-menu>

  <app-customer-welcome-modal [hidden]="true"></app-customer-welcome-modal>
  <app-timeout [hidden]="true"></app-timeout>
  <app-customer-welcome-modal [hidden]="true"></app-customer-welcome-modal>
</div>