<div class="col-md-12 col-12 header-report">
  <h2><span>{{zReportText}}</span></h2>
  <h4>
    <input style="width: 13rem; font-weight: 700; margin-left: 1rem;text-align: end;" matInput readonly
      [matDatepicker]="datePicker" [ngModel]="dt" (ngModelChange)="setDate($event)" id="datePicker" />
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker touchUi #datePicker></mat-datepicker>
  </h4>
</div>
<br /><br />

<!-- <input type="date" [ngModel] ="dt2 | date:'yyyy-MM-dd'" (ngModelChange)="dt2 = $event"/> -->
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{grossSalesText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ grossSalesDisplay | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{discountsLoyaltyText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ discounts | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>

<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{refundsText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ refunds | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{netSalesText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ netSales | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{feesText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ fees | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{taxesText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ taxes | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{tipsText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ tips | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{amountCollectedText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ amountCollect | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{openOrdersText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ openOrders | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="col-md-12 col-12 mb-3rem">
  <strong> {{paymentTypesText}} </strong>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalOrdersText}}</div>
  <div class="col-md-6 col-6 text-right">{{ totalOrders }}</div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">
    {{totalCashText}} <span class="middleText">{{ totalCashOrders }}</span>
  </div>
  <div class="col-md-6 col-6 text-right">
    {{ totalCash | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">
    {{totalCreditText}} <span class="middleText">{{ totalCreditOrders }}</span>
  </div>
  <div class="col-md-6 col-6 text-right">
    {{ totalCredit | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>

<br />
<div class="col-md-12 col-12 mb-3rem">
  <strong> {{channelSalesText}} </strong>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{kioskSalesText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ kioskSales | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>

<div class="col-md-12 col-12 mb-3rem" *ngIf="creditCardsSales && creditCardsSales.length > 0">
  <strong> {{creditCardSalesText}} </strong>
</div>
<div class="row mb-3rem" *ngFor="let ccSales of creditCardsSales">
  <div class="col-md-6 col-6"> {{ ccSales.NetworkName }}:</div>
  <div class="col-md-6 col-6 text-right">
    <span> {{ ccSales.OrderTotal | number: "1.2-2" | CurrencyPipe }}</span>
  </div>
</div>

<br />
<div class="col-md-12 col-12 mb-3rem">
  <strong> {{otherPaymentSummaryText}} </strong>
</div>

<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalTenderOrdersText}}</div>
  <div class="col-md-6 col-6 text-right">{{ totalTenderOrders }}</div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalTenderNetSalesText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalTenderNetSales | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalTenderOrderTaxesText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalTenderOrderTaxes | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalTenderOrderTipsText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalTenderOrderTips | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalTenderOrderFeesText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalTenderOrderFees | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalTenderAmountCollectedText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalTenderAmountCollected | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>

<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalHouseOrdersText}}</div>
  <div class="col-md-6 col-6 text-right">{{ totalHouseOrders }}</div>
</div>

<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalHouseNetSalesText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalHouseNetSales | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalHouseOrderTaxesText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalHouseOrderTaxes | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalHouseOrderTipsText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalHouseOrderTips | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalHouseOrderFeesText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalHouseOrderFees | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalHouseAmountCollectedText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalHouseAmountCollected | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalNetOtherOrdersText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalNetOtherOrders | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="col-md-12 col-12 mb-3rem">
  <strong> {{feesSummaryText}} </strong>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalNonCashFeesText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalNonCashFees | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="col-md-12 col-12 mb-3rem">
  <strong> {{discountLoyaltySummaryText}}</strong>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{orderDiscountsText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ orderDiscounts | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{loyaltyDiscountsText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ loyaltyDiscounts | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalDiscountsText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalDiscounts | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalGiftRedemptionsText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalGiftCardRedemptions | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{complimentaryText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ complimentary | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>

<!-- <div *ngIf="categories.length > 0" class="col-md-12 col-12 mb-3rem">
  <strong> Sales By Category</strong>
</div> -->

<div class="col-md-12 col-12 mb-3rem">
  <strong> {{salesByCategoryText}} </strong>
</div>
<div class="row mb-3rem" *ngFor="let category of categories">
  <div class="col-md-6 col-6"> {{ category.name }}:</div>
  <div class="col-md-6 col-6 text-right">
    <span>{{ category.quantity | number: "1.0" }} </span>
    <span> ({{ category.sales | number: "1.2-2" | CurrencyPipe }})</span>
  </div>
</div>

<div class="row mb-3rem" *ngIf="totalCategoryQty">
  <div class="col-md-6 col-6"> {{totalText}} </div>
  <div class="col-md-6 col-6 text-right">
    {{ totalCategoryQty | number: "1.0" }}
  </div>
</div>

<div class="row mb-3rem" *ngIf="totalCategorySales">
  <div class="col-md-6 col-6"> {{totalSalesText}}</div>
  <div class="col-md-6 col-6 text-right">
    ({{
    (totalCategorySales ? totalCategorySales : "")
    | number: "1.2-2"
    | CurrencyPipe
    }})
  </div>
</div>

<br />

<div class="col-md-12 col-12 mb-3rem">
  <strong> {{canceledOrdersSummaryText}}</strong>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalRefundsText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalRefunds | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ canceledOrdersTotal | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="col-md-12 col-12 mb-3rem">
  <strong> {{tipSummaryText}}</strong>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{cashTipsText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ cashTips | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{creditCardTipsText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ creditCardTips | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{tenderTipsText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ tenderTips | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{houseTipsText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ houseTips | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>
<div class="row mb-3rem">
  <div class="col-md-6 col-6">{{totalTipsText}}</div>
  <div class="col-md-6 col-6 text-right">
    {{ totalTips | number: "1.2-2" | CurrencyPipe }}
  </div>
</div>

<button (click)="print()">{{printText}}</button>
<br />
<br />