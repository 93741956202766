//a container carousel to hold any type of object
import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ComboGroupV2 } from "../../models/item";
import { CommonFunctions } from '../../services/common';

@Component({
  selector: 'app-combo-group-container',
  templateUrl: './combo-group-container.component.html',
  styleUrls: ['./combo-group-container.component.css']
})
export class ComboGroupContainerComponent implements OnInit {

  //get modifiers --> get ingredients
  //get item --> modifiers --> get ingredients

  // input from item with modifiers container or anywhere else using this component
  @Input() inputComboGroup: ComboGroupV2[] = [] as ComboGroupV2[];

  @Input() ItemType:number = 1;
  @Input() parentQuantity: number = 1;

  @Output() isValidEmitter: EventEmitter<boolean> = new EventEmitter();

  @Output() comboGroupEmitter: EventEmitter<{ inputComboGroup: ComboGroupV2[], isValid: boolean }> = new EventEmitter();

  @Input() IsCategory: boolean = false;
  isValid: boolean = true;

  ngOnInit(): void {
    console.log("IsCategory", this.IsCategory);

  }

  ngAfterViewInit(): void {
    this.checkComboGroupValid();
  }

  comboGroupChange(obj: { comboGroup: ComboGroupV2, index: number }) {
    if (this.inputComboGroup && this.inputComboGroup.length > 0) {

      let cg = this.inputComboGroup[obj.index];

      cg = obj.comboGroup;
    }

    this.checkComboGroupValid();
  }

  // check if things are valid / selected
  checkComboGroupValid() {
    // is valid by default
    this.isValid = true;

    if (this.inputComboGroup && this.inputComboGroup.length > 0) {
      for (let i = 0; i < this.inputComboGroup.length; i++) {
        const comboGroup = this.inputComboGroup[i];

        const groupSelectedItem =
          CommonFunctions.getParentCountItem(comboGroup);

        // min and max data can be not correct to fix them and check
        if (comboGroup.min != 0 && comboGroup.min < 0) {
          comboGroup.min = 0;
        }

        if (comboGroup.max <= 0) {
          comboGroup.max = 99999;
        }

        if (comboGroup.isSelected) {
          if (comboGroup.Items.length > 0) {
            if (
              groupSelectedItem >= comboGroup.min &&
              groupSelectedItem <= comboGroup.max
            ) {
              comboGroup.isValid = true;
            } else {
              this.isValid = false;
              comboGroup.isValid = false;
            }
          }
        } else {
          if (
            groupSelectedItem >= comboGroup.min &&
            groupSelectedItem <= comboGroup.max
          ) {
            comboGroup.isValid = true;
            comboGroup.isSelected = true;
          } else {
            comboGroup.isSelected = false;
            this.isValid = false;
          }
        }

      }

      this.comboGroupEmitter.emit({ inputComboGroup: this.inputComboGroup, isValid: this.isValid });
    }
  }

  // scroll to invalid comboGroup when user tries to press add to cart / update
  comboGroupScroll() {
    let i: number = 0;

    if (this.inputComboGroup.length <= 0) return;

    // find index of invalid modifier or combo
    if (!this.inputComboGroup) return;

    for (let [index, item] of this.inputComboGroup.entries()) {
      if (!item.isValid) {
        i = index;
        break;
      }
    }

    // find the corresponding html element of the invalid modifier set by modifier/combo id
    const htmlMod = document.getElementById(this.inputComboGroup[i].ComboGroupID);

    if (htmlMod) {
      // smooth scrolling doesn't actually work on tyzen see menu-item scroll features if you need to make this smooth
      htmlMod.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      if (htmlMod.className.includes(" animated shake")) return;
      htmlMod.className += " animated shake";
      setTimeout(() => {
        if (htmlMod) htmlMod.className = "html-objects";
      }, 1000);
    }
  }

}
