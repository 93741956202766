<div [ngStyle]="{
    border: ingredient.IsSelected ? 'solid 3px' : '',
    borderColor: ingredient.IsSelected ? primaryColor : '',
    position: 'relative'
  }" style="animation: fadeInSide {{
    ingredientIndex / 9
  }}s ease-in-out 0s forwards;" class="object item-ingredient-animation" [ngClass]="{
    sold:
      parent.IsModifier86 == 'False' || ingredient.IsIngredient86 == 'False',
    doDisabled:
      (!ingredient.IsSelected &&
      parent.isValid &&
      !(parent.count < parent.max) &&
      parent.max > 1) || IsDisableSubgroup
  }" (click)="itemClicked()" *ngIf="!ingredient.IsSubGroup">
  <div class="close" *ngIf="(ingredient.IsSelected && !ingredient.IsIngredient && (parent.max > 1 || ingredient.IsModifier)) && !(isSoldOut ||
    parent.IsModifier86 == 'False' ||
    ingredient.IsIngredient86 == 'False' ||
    ingredient.IsModifier86 == 'False')" (click)="onCloseClick($event)">
    <i class="fa fa-close" style="font-size: 32px"></i>
  </div>
  <span class="row itemTagContainer" style="height: 2.25rem">
    <div *ngFor="let image of ingredient.ItemTags" class="col itemTagDiv">
      <img *ngIf="image != null && image != ''"
        [ngStyle]="{ width: '2.25rem', height: '2.25rem', 'border-radius': bordersTypeModifire ? imageRadius : '0%'}"
        src="{{ image && image !='' ? baseImageUrl + image : placeholderImage !='' ? baseImageUrl+placeholderImage : 'assets/images/Image_21.png'}}"
        onerror="this.onerror=null; this.src='assets/images/Image_21.png'" class="col-md-3" />
    </div>
  </span>
  <div class="image-div" style="">
    <img class="" [src]="
        ingredient.IsModifier
          ? ingredient.IsSelected
            ? image != ''
              ? image
              : placeholderImage !='' 
                ? baseImageUrl+placeholderImage 
                : 'assets/images/Image_21.png'
            : deselectedImage != ''
            ? deselectedImage
            :  placeholderImage !='' 
              ? baseImageUrl+placeholderImage 
              : 'assets/images/Image_21.png'
          : image != ''
          ? image
          :  placeholderImage !='' 
            ? baseImageUrl+placeholderImage 
            : 'assets/images/Image_21.png'
      " onerror="this.onerror=null; this.src='assets/images/Image_21.png';" alt="" [ngClass]="{ soldOut: isSoldOut }"
      [ngStyle]="{'border-radius': bordersTypeModifire ? imageRadius : '0%'}" />
  </div>
  <div class="name-div">
    <div class="name">{{ ingredient.Name || ingredient.Category }}</div>
  </div>
  <div class="sold-out" *ngIf="
      isSoldOut ||
      parent.IsModifier86 == 'False' ||
      ingredient.IsIngredient86 == 'False' ||
      ingredient.IsModifier86 == 'False'
    ">
    <em>{{soldOutText}}</em>
  </div>
  <div class="text-center">
    <span class="ing-price" [ngStyle]="{ color: priceColor }" [ngClass]="{ soldOut: isSoldOut }"
      [style.color]="Price == 'No Charge' ? 'Gray' : ''" 
      [style.marginBottom]="ingredient.Ingredients?.length > 0 && ingredient.IsSelected ? '0rem' : ''" 
      *ngIf=" ((ingredient.IsModifier && Price != 'No Charge') ||
        ingredient.IsIngredient) && !isRemovePricingAndPayments ">
      <b>{{ Price == noChargeText ? (isShowNoCharge ? '+' + Price : "") : '+' + Price }}</b>
    </span>  
    <span *ngIf="( (((ingredient.IsModifier && Price != 'No Charge') ||
      ingredient.IsIngredient) && !isRemovePricingAndPayments  ) 
      && ( showCalories&&ingredient.Calorie>0) )">
      {{ Price == noChargeText ? (isShowNoCharge ? '|' : "") : '|' }}
    </span>
    <span *ngIf="showCalories&&ingredient.Calorie>0" class="calorie-div price"
      [ngClass]="{ soldOut: isSoldOut }">
      {{ ingredient.Calorie ? ingredient.Calorie + calorieText : "" }}
    </span>
  </div>

  <div class="number-counter">
    <div class="number-content">
      <span class="input-number-decrement JS_ItemDecrement" data-itemId="1" *ngIf="
          (parent.max > 1 &&
          ingredient.IsSelected &&
          ((ingredient.IsModifier && !isOlo) || ingredient.IsIngredient)) && !(isSoldOut ||
          parent.IsModifier86 == 'False' ||
          ingredient.IsIngredient86 == 'False' ||
          ingredient.IsModifier86 == 'False') &&  (parent.UseAdvancedModifierQuantitySettings === 'True' || maxQty>1) 
        " (click)="onQuantityDecrease($event)">
        <img src="../../../../../assets/images/minus.svg"
          onerror="this.onerror = null;this.src = 'assets/images/Image_21.png';" alt="" />
      </span>
      <span>
        <input class="input-number-2 JS_ItemQuantity" disabled="disabled" data-itemId="1" id="1" type="text"
          value="{{ quantity }}" min="0" max="" [(ngModel)]="quantity" *ngIf="
            (parent.max > 1 &&
            ingredient.IsSelected &&
            ((ingredient.IsModifier && !isOlo) || ingredient.IsIngredient )) && !(isSoldOut ||
            parent.IsModifier86 == 'False' ||
            ingredient.IsIngredient86 == 'False' ||
            ingredient.IsModifier86 == 'False') && (parent.UseAdvancedModifierQuantitySettings === 'True' || maxQty>1) 
          " />
      </span>
      <span class="input-number-increment JS_ItemIncrement" data-itemId="1" *ngIf="
          (parent.max > 1 &&
          ingredient.IsSelected &&
          ((ingredient.IsModifier && !isOlo) || ingredient.IsIngredient )) && !(isSoldOut ||
          parent.IsModifier86 == 'False' ||
          ingredient.IsIngredient86 == 'False' ||
          ingredient.IsModifier86 == 'False') &&  (parent.UseAdvancedModifierQuantitySettings === 'True' || maxQty>1) 
        " (click)="onQuantityIncrease($event)">
        <img src="../../../../../assets/images/plus.svg"
          onerror="this.onerror=null; this.src='assets/images/Image_21.png'" />
      </span>
    </div>
  </div>

  <div *ngIf="ingredient.Ingredients?.length > 0 || ingredient.IsModifier" class="custom-footer"
    [ngClass]="{ soldOut: isSoldOut }">
    <p *ngIf="ingredient.IsModifier" class="custom font-size4 medium" [ngClass]="{ soldOut: isSoldOut }">
      {{customizeText|uppercase}}
    </p>
  </div>
</div>