import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';
import { TermsConditionQrUrlBothComponent } from '../dialogs/terms-condition-qr-url-both/terms-condition-qr-url-both.component';
import { TermsConditionsPdfComponent } from '../dialogs/terms-conditions-pdf/terms-conditions-pdf.component';
import { TermsConditionsQrComponent } from '../dialogs/terms-conditions-qr/terms-conditions-qr.component';
import { TermsConditionsUrlComponent } from '../dialogs/terms-conditions-url/terms-conditions-url.component';

@Component({
  selector: 'app-optin-marketing',
  templateUrl: './optin-marketing.component.html',
  styleUrls: ['./optin-marketing.component.css']
})
export class OptinMarketingComponent implements OnInit {

  allowCustomerOptInForMarketing = GeneralSetting.getAllowCustomerOptInForMarketing();
  marketingOptInText = GeneralSetting.getMarketingOptInText();
  addTermsConditions = GeneralSetting.getAddTermsConditions();

  @Output() onTermsAndConditionsChange = new EventEmitter<any>()
  termsAndConditionsText:string = "";
  constructor(private language:LanguageService,private modalService:NgbModal,private sanitizer:DomSanitizer) { }
 // intialize the component
  ngOnInit(): void {
    this.termsAndConditionsText = this.language.getTextElement('lbl_terms_and_conditions');
    if (this.checkLines(this.marketingOptInText)) { 
      this.marketingOptInText = this.marketingOptInText.substring(0, 100);
      this.marketingOptInText = this.marketingOptInText + "..." + this.language.getTextElement('read_more');
      // this.termsConditionReadMoreComponent.open(termsAndConditionsUrl);
    }
    // console.log("LINES", this.checkLines(this.marketingOptInText));
    
  }
/**
 * @author  Rajat Soni
 * @param txt // txt for check lines
 * @description This functions is used to check lines
 * @returns boolean
 */
  checkLines(txt : string) {
  if (txt && txt != null) {
    return (txt.length > 114) ? true : false;
  } else return false;
};
/**
 * @author  Rajat Soni
 * @param txt // txt for check lines
 * @description This functions is used to check lines
 * @returns 
 */
  fieldsChange(values: any): void {
    if (values.currentTarget.checked) {
      GeneralSetting.setIsAllowForMarketingCommunication('True');
    } else {
      GeneralSetting.setIsAllowForMarketingCommunication('False');
    }
    this.onTermsAndConditionsChange.emit(values.currentTarget.checked);
  }
  /**
   * @author Rajat Soni
   * @description This functions is used to open popup for terms and conditions on based on condition
   * @returns void
   */
  onTermsAndConditions() { 
    
    let viewTermsAndConditions = GeneralSetting.getViewTermsAndCondition();
    let termsAndConditionsUrl = GeneralSetting.getTermsConditionsURL();
    let termsAndConditionsFile = GeneralSetting.getTermsConditionsFile();
    if (viewTermsAndConditions == "0") { //URL
      let howToView = GeneralSetting.getHowToViewURLBasedTermsAndCondition()
      // In a popup: 1
      // Show QR Code: 2
      // Both: 3
      if (howToView == "1") {
        TermsConditionsUrlComponent.open(termsAndConditionsUrl,this.modalService,this.sanitizer);
      } else if (howToView == "2") {
         TermsConditionsQrComponent.open(termsAndConditionsUrl,this.modalService);
      } else { 
        TermsConditionQrUrlBothComponent.open(termsAndConditionsUrl,this.modalService);
      }
    } else if (viewTermsAndConditions == "1") { //Pdf 
      TermsConditionsPdfComponent.open(termsAndConditionsFile,this.modalService,this.sanitizer);

    }
  }
}
