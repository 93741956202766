<div id="custominfo-popup" role="dialog">
    <div class="header">
      <h2 class="text-center font-size9"><b>{{uhOhText}}</b></h2>
      <h4 class="text-center"><strong>{{changeIncompatibleText}}</strong></h4>
      <h3 class="text-center">{{makeChangeAnywaysText}} </h3>
    </div>
    <div class="popup-footer">
      <button type="submit" class="text-center green-btn background-positive-button-color" (click)="close('cancel')">
        {{cancelText}}
      </button>
      <button type="submit" class="text-center green-btn background-negative-button-color" (click)="close('okay')">
       {{okayText}}
      </button>
    </div>
  </div>
  