<ng-container *ngIf="Item">
  <section class="donut-box-section">
    <div class="dbs-header">
      <a class="logo-image"><img src="{{ baseImageUrl + branchLogo }}"
          onerror="this.onerror=null; this.src='assets/images/Image_21.png'" class="img-fluid" alt="branch-logo" /></a>

      <a class="close-btn" (click)="cancel()">

        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="none">
          <rect x="1" y="1" width="68" height="68" rx="19" fill="#fff" [ngStyle]="{stroke:getAccentColor()}"
            stroke-width="2" />
          <path
            d="m36.312 36.105 8.478 8.478-.207.207-8.478-8.478L35 35.207l-1.105 1.105-8.478 8.478-.207-.207 8.478-8.478L34.793 35l-1.105-1.105-8.478-8.478.207-.207 8.478 8.478L35 34.793l1.105-1.105 8.478-8.478.207.207-8.478 8.478L35.207 35l1.105 1.105z"
            [ngStyle]="{stroke:getAccentColor()}" stroke-width="3.125" />
        </svg>
        <!-- <img src="assets/images/box-builder/close icon.png" alt="X" /> -->
      </a>
    </div>
    <div class="dbs-body">
      <div class="row">
        <div class="col-6">
          <h1 class="box-item-heading" >{{ Item!.Name }}</h1>
          <p class="description">{{ Item!.FullDescription }}</p>
          <span class="funky">
            REMAINING DONUTS
            <b id="count">{{
              totalComboGroups - (numInvisibleGroups + selectedTotalCount)
              }}</b>
            <span class="funky-normal"> OF </span><b id="total">{{ totalComboGroups - numInvisibleGroups }}</b>
          </span>
        </div>
        <div class="col-6">
          <!-- <img src="assets/images/box-builder/Donut-box.png" class="img-fluid" /> -->
          <div class="donut-selection">
            <!--Remove below and rewrite the code for donut selection-->
            <div class="movie-container d-none">
              <!--Hidden code-->
              <!--THis is for donut selection-->
              <label>{{pickDonutText}}</label>
              <!-- <select id="movie">
                            <option value="10">Strawberry ($10)</option>
                        </select> -->
            </div>
            <!--End waste code-->
            <div [ngClass]="(totalComboGroups - numInvisibleGroups) > 6 ? 'donut-box' : 'donut-box six'">
              <ng-container *ngFor="let itemImage of itemImageList">
                <!-- <div class="seat" style="background-image: url({{itemImage.image}});">
                                </div> -->
                <img class="seat" src="{{ itemImage.image != ''
                                            ? itemImage.image 
                                            : placeholderImage != '' 
                                              ? baseImageUrl+placeholderImage 
                                              : 'assets/images/Image_21.png'}}"
                  onerror="this.onerror=null; this.src='assets/images/Image_21.png'" />
                <!-- <div class="seat"
                                style="background-image: url(https://staging.grubbrr.com/uploads/ItemImage/42c0a981-32cd-4ce4-ba72-e412ac7ba88d_Boo-Berry.png);">
                            </div> -->
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>