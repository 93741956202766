import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RefundSuccessComponent } from './refund-success.component';

@Injectable({
  providedIn: 'root',
})
export class RefundSuccessDialogService {
  constructor(private modalService: NgbModal) {}

  public async openRefundSuccessDialog() {
    return new Promise<void>((resolve, reject) => {
      let ref = RefundSuccessComponent.open(this.modalService);
      ref.result.then((data: any) => {
        resolve(data);
      });
    });
  }
  
}
