<ng-container *ngIf="!isHybrid">
  <div [@cartSlideIn] *ngFor="let cartItem of cartItems; let i = index" class="cart-item"
    [ngClass]="{ 'mb-5': isCartOpen, '': !isCartOpen }" id="1">
    <div class="closed-images" *ngIf="!isCartOpen">
      <img (click)="editItem(cartItem)" src="{{ getFormattedURL(cartItem) }}"
        onerror="this.onerror=null; this.src='assets/images/Image_21.png'" class="closed-image img-responsive"
        [ngStyle]="{ 'border-radius': bordersTypeItem ? imageRadius : '0%' }" />
    </div>
    <div class="vertical-center" id="{{ cartItem.guid }}">
      <div class="img-part" [ngClass]="{ open: !isCartOpen }">
        <img *ngIf="isCartOpen" (click)="editItem(cartItem)" src="{{ getFormattedURL(cartItem) }}"
          onerror="this.onerror=null; this.src='assets/images/Image_21.png'" class="img-responsive"
          [ngStyle]="{ 'border-radius': bordersTypeItem ? imageRadius : '0%' }" />
      </div>
      <div class="footercart" *ngIf="isCartOpen">
        <img src="../../../assets/images/delete.svg" class="delete-icon" (click)="removeFromCart(cartItem)"
          onerror="this.onerror=null; this.src='assets/images/Image_21.png'" />

        <div class="info" (click)="editItem(cartItem)">
          <h5>{{ cartItem.CategoryName | slice: 0:16 }}</h5>
          <p>{{ cartItem.Name }}</p>
        </div>

        <div class="number-counter">
          <div class="number-content" *ngIf="
                  !(
                    cartItem.EnablePricebyWeight &&
                    cartItem.EnablePricebyWeight == 'True'
                  );
                  else weightLayout
                ">
            <span [ngStyle]="{
                    visibility:
                      cartItem.AllowQuantitySelection == 'True'
                        ? 'visible'
                        : 'hidden'
                  }" id="{{ cartItem.guid }}" class="input-number-decrement JS_ItemDecrement" data-itemId="1"
              (click)="decreaseAmount(cartItem, i)">
              <img src="../../../assets/images/minus.svg"
                onerror="this.onerror=null; this.src='assets/images/Image_21.png'" />
            </span>
            <input id="{{ cartItem.guid }}" class="input-number-2 JS_ItemQuantity" disabled="disabled" data-itemId="1"
              id="1" type="text" value="{{ cartItem.Quantity }}" min="0" max="" />
            <span [ngStyle]="{
                    visibility:
                      cartItem.AllowQuantitySelection == 'True'
                        ? 'visible'
                        : 'hidden'
                  }" id="{{ cartItem.guid }}" class="input-number-increment JS_ItemIncrement" data-itemId="1"
              (click)="increaseAmount(cartItem)">
              <img src="../../../assets/images/plus.svg"
                onerror="this.onerror=null; this.src='assets/images/Image_21.png'" />
            </span>
          </div>
          <ng-template #weightLayout>
            <div class="weightlayout" [ngStyle]="{ color: priceColor }">
              {{ cartItem.Weight }}
              <p [ngSwitch]="cartItem.WeightUnitID">
                <span *ngSwitchCase="0">oz.</span>
                <span *ngSwitchCase="1">lb.</span>
                <span *ngSwitchCase="2">g.</span>
                <span *ngSwitchCase="3">kg.</span>
                <span *ngSwitchDefault></span>
              </p>
            </div>
          </ng-template>

          <p class="text-danger no-margin font-size4 lh10" *ngIf="cartItem.IsLoyaltyDiscountItem">
            {{rewardItemText}}
          </p>
          <p class="text-danger no-margin font-size4 lh10" *ngIf="
              !cartItem.IsLoyaltyDiscountItem &&
              !(cartItem.AllowQuantitySelection == 'True')
            ">
            {{upsellItemText}}
          </p>
        </div>

        <div class="row">
          <div (click)="editItem(cartItem)" [style.color]="textColor" class="col-md-12 col-12"
            [ngStyle]="{ color: priceColor }">
            <!-- <strong *ngIf="!isRemovePricingAndPayments">{{ cartItem.totalPrice | number: "1.2-2" | CurrencyPipe }}</strong> -->
            <div class="item-container" *ngIf="!isRemovePricingAndPayments">
              <div class="item-price" [ngStyle]="{ color: priceColor }">
                <div class="price-discount-container">
                  <p [style.textDecoration]="cartItem.DiscountAmount > 0 ? 'line-through' : ''"
                    [style.color]="textColor" class="price-text">
                    ({{ (cartItem.Price ? cartItem.Price : 0) | number: "1.2-2" | currency
                    }})
                  </p>
                  <p class="item-discount" *ngIf="cartItem.DiscountAmount > 0">
                    {{
                    (((cartItem.totalPrice ? cartItem.totalPrice : 0.00)) -
                    (cartItem.DiscountAmount>0?cartItem.DiscountAmount:0.00)) | numberConvert
                    | number: "1.2-2"
                    | CurrencyPipe
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>