import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  HttpClient,
} from "@angular/common/http";
import { GeneralSetting } from "./generalSetting.service";
import { IHttpHeaders, HttpConnection } from './http.service';
import { IsKioskService } from './is-kiosk.service';

@Injectable({
  providedIn: "root",
})
export class LogService {

  private baseUrl: string = environment.apiUrl;

  private appLogUrl: string = "AppLogSK";
  private devicestatus: string ="DeviceStatusUpdateSK";
  WaitingReports: any[] = [];

  constructor(private readonly http: HttpClient, private readonly isKioskService: IsKioskService) {
    this.init();
  }
  init() {
    setInterval(() => {
      if (this.WaitingReports.length) {
        //console.log(`There ${this.WaitingReports.length===1?'is':'are'} ${this.WaitingReports.length} report${this.WaitingReports.length===1?'':'s'} waiting`);
        try {
          let a = this.WaitingReports.shift();
          this.catchUp(a).then((data: boolean) => {
            if (!data) {
              this.WaitingReports.push(a);
            }
          });
        } catch (e: any) { }
      }
    }, 2 * 60 * 1000);
  }
  catchUp(priorLog: any) {
    return new Promise<boolean>((resolve, reject) => {
      var headers = [] as IHttpHeaders[];
      headers.push({
        key: 'Content-Type',
        value: 'application/Json',
      } as IHttpHeaders);
      HttpConnection.Connection(this.http, this.isKioskService.isKiosk())
        .addUrl(this.baseUrl + this.appLogUrl)
        .addMethod('POST')
        .addHeader(headers)
        .addBody(priorLog)
        .post()
        .toPromise()
        .then(
          (data: any) => {
            console.log(data);
            console.log('App Log sent to server');
            resolve(true);
          },
          (err: any) => {
            console.log('Error sending App Log to server: ', err);
            resolve(false);
          }
        );
    });
  }
  /**
   * send log to server
   * @param data
   * @type loggingData
   * @returns
   */
  sendLogToServer(data: loggingData, offline: boolean = false) {
    try {
      if (data.logWithDate) {
        const time = formatDate(Date.now(), "MMM dd yyyy h:mm:ssa", "en-US");
        data.dataToSend = time + data.dataToSend;
      }

      const headers = [] as IHttpHeaders[];
      headers.push({ key: "Content-Type", value: "application/Json" } as IHttpHeaders);

      const body = {
        StaffModuleID: data.staffModuleId,
        Title: data.title,
        Description: data.description,
        Tags: environment.Version + "  " + data.tag  ,
        FilePath: btoa(this.branchDetailLog() + data.dataToSend),
        RawFilePath: this.branchDetailLog() + data.dataToSend,
        CompanyID: data.companyId,
        BranchID: data.branchId,
        CreatedDate: data.createdDate,
        CreatedDateInMillisecond: data.createdDateInMillisecond,
        DeviceName: "Samsung Kiosk",
        AppID: GeneralSetting.getSerialNo(),
        MID: GeneralSetting.getMerchantId(),
        IsEmailSend: data.isSendMail ? "True" : "False"
      };
      if (offline) {
        this.WaitingReports.push(body);
      } else {
        HttpConnection.Connection(this.http, this.isKioskService.isKiosk())
          .addUrl(this.baseUrl + this.appLogUrl)
          .addMethod("POST")
          .addHeader(headers)
          .addBody(body)
          .post()
          .toPromise()
          .then(() => {
            // console.log("App Log sent to server");
          });
      }
    }
    catch (e) { }
  }

  private branchDetailLog(): string {
    const appId = "AppID: " + GeneralSetting.getSerialNo() + "\n";
    const appVersion = "App Version: " + environment.Version + "\n";
    const deviceName = "Device Name: " + GeneralSetting.getDeviceName() + "\n";
    const deviceModel = "Device Model And OS: " + GeneralSetting.getBranchName() + " " + GeneralSetting.getDeviceModel() + " " + GeneralSetting.getOsversion() + "\n";
    const ip = "IP: " + GeneralSetting.getIpAddress() + "\n";
    const dateTime = "Date Time " + new Date() + "\n";
    const branchCompany = "Branch & Company: " + GeneralSetting.getBranchId() + "(" + GeneralSetting.getCompanyId() + ")" + "\n";
    const data = "Data: ";

    const branchDetail = appId +
      appVersion +
      deviceName +
      deviceModel +
      ip +
      dateTime +
      branchCompany +
      data;

    return branchDetail;
  }

  
  public async updateDeviceStatus(statusId : string) {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json; charset=UTF-8');
    myHeaders.append('CompanyID', GeneralSetting.getCompanyId());
    myHeaders.append('BranchID', GeneralSetting.getBranchId());
    myHeaders.append('DeviceID', GeneralSetting.getSerialNo());

    let currentTime = new Date().toLocaleString('en-US');

    let map = [
        {
        StaffModuleID: GeneralSetting.getStaffModuleID(),
        StatusId: statusId,
        StatusDate: currentTime,
        },
    ];

    var raw = JSON.stringify(map);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    };

    var response = await fetch(this.baseUrl + this.devicestatus,
        requestOptions as any
    );
  }

}

/**
 * logging data class, including different parameters needed for a single log
 */
export class loggingData {
  title: string = "";
  description: string = "";
  tag: string = "";
  dataToSend: string = "";
  logWithDate: boolean = true;
  branchId: string = "";
  companyId: string = "";
  createdDate: string = "";
  createdDateInMillisecond: number = 0
  staffModuleId: string = "";
  isSendMail: boolean = false;

  constructor(
    title: string,
    description: string,
    tag: string,
    dataToSend: any,
    logWithDate: boolean,
    isSendMail: boolean = false
  ) {
    this.title = title;
    this.description = description;
    this.tag = tag;
    this.dataToSend = dataToSend;
    this.logWithDate = logWithDate;
    this.isSendMail = isSendMail;

    //get other data from session storage
    let createdDate = Date.now();
    this.companyId = GeneralSetting.getCompanyId();
    this.branchId = GeneralSetting.getBranchId();
    this.staffModuleId = GeneralSetting.getStaffModuleID();
    this.createdDate = formatDate(createdDate, "MMM dd yyyy h:mm:ssa", "en-US");
    this.createdDateInMillisecond = createdDate;
  }
}
