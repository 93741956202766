import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { DatabaseService } from 'src/app/services/database.service';
import { LanguageService } from 'src/app/services/language.service';
import { GeneralSetting } from '../../../services/generalSetting.service';

@Component({
  selector: 'app-alcoholic-items-dialog',
  templateUrl: './alcoholic-items-dialog.component.html',
  styleUrls: ['./alcoholic-items-dialog.component.css'],
})
export class AlcoholicItemsDialogComponent implements OnInit {
  
  ngOnDestroy() {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  constructor(private readonly dataService: DataService,
    private readonly databaseService: DatabaseService,
    private language: LanguageService
  ) {}

  ref: any;

  primaryColor: string | null = '';

  titleText = '';

  loadingText: string = '';

  languageSub!: Subscription;

  subTitleText = '';

  positiveButtonText = '';

  negativeButtonText = '';

  model_status: number = 1;

  @Output() closeModal = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    
    this.primaryColor = GeneralSetting.getPrimaryColor();
  }

  loadText() {
    this.loadingText = this.language.getTextElement('txt_loading');
    this.setLabels()
  }

  public static open(modalService: NgbModal) {
    var ref = modalService.open(AlcoholicItemsDialogComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });
    ref.componentInstance.ref = ref;
    return ref;
  }

  setLabels() {
    if (this.model_status == 2) {
      this.titleText = GeneralSetting.getWaitingStaffText(); //'Please wait for the verification...';
      this.subTitleText = this.language.getTextElement('alcoholic_staff_check');    
      this.positiveButtonText = this.language.getTextElement('check_customer_id').toUpperCase();
      this.negativeButtonText = this.language.getTextElement('lbl_button_cancel').toUpperCase();
    } else if (this.model_status == 1) {
      this.titleText  = this.language.getTextElement('alcoholic_item');
      this.subTitleText = this.language.getTextElement('alcoholic_staff_check');
      this.positiveButtonText = this.language.getTextElement('verify_id').toUpperCase();
      this.negativeButtonText = this.language.getTextElement('lbl_button_cancel').toUpperCase();
    } else if (this.model_status == 3) {
      this.titleText = this.language.getTextElement('alcoholic_item');
      this.subTitleText =this.language.getTextElement('verify_id_item_removal').replace('%d', GeneralSetting.getAgeOfMajority())
      this.positiveButtonText = this.language.getTextElement('customer_over_age').replace('%d',GeneralSetting.getAgeOfMajority()).toUpperCase();
      this.negativeButtonText = this.language.getTextElement('customer_under_age').replace('%d',GeneralSetting.getAgeOfMajority()).toUpperCase();
    }
  }

  onVerifyClick = async () => {
    if (this.model_status == 1) {
      let deviceName = await this.databaseService.getDeviceNameByDeviceID(
        GeneralSetting.getSerialNo()
      );
      this.dataService
        .sendAlcoholicItemNotification(deviceName)
        .toPromise()
        .then(
          (data) => {
            console.log('Send notification', data);
          },
          (error) => {}
        );
      this.model_status = 2;
    } else if (this.model_status == 2) {
      this.model_status = 3;
    } else if (this.model_status == 3) {
      this.ref.close('over21');
    }
    this.setLabels();
  };

  cancelModal = () => {
    if (this.model_status == 3) {
      this.ref.close('under21');
    } else {
      this.ref.close('cancel');
    }
  };

}
