import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';
import { loggingData, LogService } from 'src/app/services/log.service';
@Component({
  selector: 'app-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.css'],
})
export class PaymentErrorComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  modalReference!: NgbModalRef;

  languageSub!: Subscription;

  isVerifone!: boolean;

  isRefund: boolean = false;

  isClover: boolean = false;

  isCloverRefund: boolean = false;

  isCloverConnectFailed: boolean = false;

  checkoutText: string = '';

  refundText: string = ''

  notEnoughFundsText: string = ''

  paymentText: string = ''

  error: string = '';

  constructor(
    private modalService: NgbModal,
    private logger: LogService,
    private language: LanguageService
  ) {}


  loadText() {
    this.checkoutText = this.language.getTextElement('lbl_checkout');
    this.refundText = this.language.getTextElement('refund');
    this.notEnoughFundsText=this.language.getTextElement('not_enough_funds')
    this.paymentText=this.language.getTextElement('payment')

  }

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  public static open(
    modalService:any,
    language:LanguageService,
    isVerifone: boolean,
    error: string,
    isRefund: boolean = false,
    isClover?: boolean,
    isCloverRefund?: boolean,
    isCloverConnectFailed?: boolean
  ) {
    let modalReference = modalService.open(PaymentErrorComponent, {
      size: 'lg',
      centered: true,
    });
    modalReference.componentInstance.error = error;
    if (isClover) {
      let error = language.getTextElement('insufficient_funds_refund_issued')
      modalReference.componentInstance.error = error;
    }
    if (isCloverConnectFailed) {
      modalReference.componentInstance.error = error;
    }

    modalReference.componentInstance.isRefund = isRefund;
    if (isCloverRefund) {
      modalReference.componentInstance.isCloverRefund = isCloverRefund;
    }
    modalReference.componentInstance.isVerifone = isVerifone;
    if (isClover) {
      modalReference.componentInstance.isClover = isClover;
    }

    modalReference.componentInstance.modalReference = modalReference;

    return modalReference;
  }

  close() {
    var log = new loggingData(
      'Payment Error',
      'Encountered Payment Error',
      'Payment Error',
      `Recieved Error: ${
        this.error
      } During Payment Type ${GeneralSetting.getPaymentTypeID()}`,
      false
    );
    this.logger.sendLogToServer(log);
    this.modalReference.close('closed');
  }

}
