import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrderHistoryService } from 'src/app/services/order-history.service';
import { DatePipe } from '@angular/common';
import { order } from 'src/app/models/order';
import { ItemwiseSalesService } from 'src/app/services/itemwise-sales.service';
import { itemwise } from 'src/app/models/itemwise';
import { Subscription } from 'rxjs';
import { loggingData, LogService } from 'src/app/services/log.service';
import { CategoryReport } from '../../../models/categoryReport';
import { DataService } from '../../../services/data.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-settings-reports',
  templateUrl: './settings-reports.component.html',
  styleUrls: ['./settings-reports.component.css'],
})
export class SettingsReportsComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  none: boolean = true;
  itemwise: boolean = false;
  orderhistory: boolean = false;
  grossSales: boolean = false;
  zreport: boolean = false;

  categories: CategoryReport[] = [];
  totalCategorySales: string = '';
  totalCategoryQty: string = '';
  dataService: DataService;

  orderHistoryService: OrderHistoryService;
  orders: order[] = [];
  grossSalesOrders: order[] = [];

  itemwiseService: ItemwiseSalesService;
  itemwiseOrders: itemwise[] = [];

  dt: Date = new Date();
  dt2: Date = new Date();
  dt3: Date = new Date();
  dt4: Date = new Date();

  selectedDate: Date = new Date();
  dateDisplay: string = '';
  grossSalesDisplay: string = '';
  discounts: string = '';
  refunds: string = '';
  netSales: string = '';
  fees: string = '';
  taxes: string = '';
  tips: string = '';
  amountCollect: string = '';
  openOrders: string = '';
  totalOrders: string = '';
  totalCashOrders: string = '';
  totalCreditOrders: string = '';
  totalCash: string = '';
  totalCredit: string = '';
  kioskSales: string = '';
  totalTenderOrders: string = '';
  totalTenderNetSales: string = '';
  totalTenderOrderTaxes: string = '';
  totalTenderOrderTips: string = '';
  totalTenderOrderFees: string = '';
  totalTenderAmountCollected: string = '';
  totalHouseOrders: string = '';
  totalHouseNetSales: string = '';
  totalHouseOrderTaxes: string = '';
  totalHouseOrderTips: string = '';
  totalHouseOrderFees: string = '';
  totalHouseAmountCollected: string = '';
  totalNetOtherOrders: string = '';
  totalNonCashFees: string = '';
  orderDiscounts: string = '';
  loyaltyDiscounts: string = '';
  totalDiscounts: string = '';
  totalGiftCardRedemptions: string = '';
  complimentary: string = '';
  categoryTotal: string = '';
  totalRefunds: string = '';
  canceledOrdersTotal: string = '';
  cashTips: string = '';
  creditCardTips: string = '';
  tenderTips: string = '';
  houseTips: string = '';
  totalTips: string = '';

  languageSub!: Subscription;
  reportsText: string = ''; 
  zReportsText: string = ''; 
  grossSalesText: string = ''; 
  itemWiseSalesText: string = ''; 
  orderHistoryText: string = ''; 
  constructor(
    DataService: DataService,
    OrderHistoryService: OrderHistoryService,
    ItemwiseSalesService: ItemwiseSalesService,
    private datePipe: DatePipe,
    private logger: LogService,
    private language: LanguageService
  ) {
    this.dataService = DataService;
    this.orderHistoryService = OrderHistoryService;
    this.itemwiseService = ItemwiseSalesService;
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    )
  }
  loadText() {
    this.reportsText=this.language.getTextElement('title_activity_reports')
    this.zReportsText=this.language.getTextElement('lbl_reports_z')
    this.grossSalesText=this.language.getTextElement('lbl_reports_gross_sales')
    this.itemWiseSalesText=this.language.getTextElement('lbl_reports_item_wise_sales')
    this.orderHistoryText=this.language.getTextElement('lbl_reports_order_history')
  }
  ngOnInit(): void {
    const input = document.querySelector("input[type='date']");
    if (input != null) (<HTMLInputElement>input).valueAsDate = new Date();
  }

  back() {
    this.none = true;
    this.itemwise = false;
    this.orderhistory = false;
    this.grossSales = false;
    this.zreport = false;
  }

  zReport() {
    this.none = false;
    this.itemwise = false;
    this.orderhistory = false;
    this.grossSales = false;
    this.zreport = true;

    // set current date as display date
    this.dateDisplay = new Date().toDateString();
    // do post request with current date
    this.allSubsCription.push(this.dataService
      .getZreport(this.dateDisplay, this.dateDisplay)
      .subscribe(
        (val) => {
          this.parseData(JSON.parse(JSON.stringify(val)));
          var log = new loggingData(
            'Z Report',
            'Success',
            'General',
            'Z report from cloud are successful',
            false
          );

          this.logger.sendLogToServer(log);
        },
        (response) => {
          var log = new loggingData(
            'POS Z report',
            'POS Z report Api Failed',
            'POS Z report',
            response,
            true
          );

          this.logger.sendLogToServer(log);
        },
        () => { }
      )
    );
  }
  itemwiseReport() {
    this.none = false;
    this.itemwise = true;
    this.orderhistory = false;
    this.grossSales = false;

    const date = this.datePipe.transform(new Date(), 'MMM d YYYY');
    // this.itemwiseService.getTotalItems(date!.toString()).subscribe((item) => {

    //   count = Number(item);
    // })

    this.allSubsCription.push(
      this.itemwiseService
        .getItemReport(date!.toString())
        .subscribe((item) => {
          this.itemwiseOrders = item;
        })
    );
  }
  computePrice(str: string, no: number): number {
    return Number(str) * no;
  }

  setItemwiseDate(event: any) {
    this.dt = event;
    const date = this.datePipe.transform(this.dt, 'MMM d YYYY');
    this.allSubsCription.push(
      this.itemwiseService
        .getItemReport(date!.toString())
        .subscribe((item) => {
          this.itemwiseOrders = item;
        })
    );
  }

  grossSalesReport() {
    this.none = false;
    this.itemwise = false;
    this.orderhistory = false;
    this.grossSales = true;
    this.zreport = false;

    const date = this.datePipe.transform(new Date(), 'MMM d YYYY');

    this.allSubsCription.push(
      this.orderHistoryService
        .getOrderHistory(date!.toString())
        .subscribe((item) => {
          this.grossSalesOrders = item;
        })
    );
  }

  setGrossDate(event: any) {
    this.dt2 = event;
    const date = this.datePipe.transform(this.dt2, 'MMM d YYYY');

    this.allSubsCription.push(
      this.orderHistoryService
        .getOrderHistory(date!.toString())
        .subscribe((item) => {
          this.grossSalesOrders = item;
        })
    );
  }

  orderHistoryReport() {
    this.none = false;
    this.itemwise = false;
    this.orderhistory = true;
    this.grossSales = false;
    this.zreport = false;

    // const date = new Date().getUTCMonth().toString()
    const date = this.datePipe.transform(new Date(), 'MMM d YYYY');

    this.allSubsCription.push(
      this.orderHistoryService
        .getOrderHistory(date!.toString())
        .subscribe((item) => {
          this.orders = item;
        })
    );
  }

  determineStatus(): string {
    return this.language.getTextElement('text_success');
  }
  setHistoryDate(event: any) {
    this.dt3 = event;
    const date = this.datePipe.transform(this.dt3, 'MMM d YYYY');

    this.allSubsCription.push(
      this.orderHistoryService
        .getOrderHistory(date!.toString())
        .subscribe((item) => {
          this.orders = item;
        })
    );
  }
  setDate(e: any) {
    this.dateDisplay = e.target.value;

    // on date change reset categories array
    this.categories = [];

    this.allSubsCription.push(
      this.dataService
        .getZreport(this.dateDisplay, this.dateDisplay)
        .subscribe(
          (val) => {
            this.parseData(JSON.parse(JSON.stringify(val)));
            var log = new loggingData(
              'Z Report',
              'Success',
              'General',
              'Z report from cloud are successful',
              false
            );

            this.logger.sendLogToServer(log);
          },
          (response) => {
            var log = new loggingData(
              'POS Z report',
              'POS Z report Api Failed',
              'POS Z report',
              response,
              true
            );

            this.logger.sendLogToServer(log);
          },
          () => { }
        )
    );

    var date = Date.parse(this.dateDisplay);
    this.selectedDate = new Date(date);
    // i have to to this because dates have weird time zone errors???
    this.selectedDate.setTime(
      this.selectedDate.getTime() + new Date().getTimezoneOffset() * 60 * 1000
    );

    this.dateDisplay = this.selectedDate.toDateString();
  }

  dateTime() { }

  parseData(data: any): void {
    var zData = data.data.NewZReport.ZReport;

    var len: Number = zData.SalesByCategoryList
      ? zData.SalesByCategoryList.length
      : 0;
    for (var i = 0; i < len; i++) {
      var categoryData = zData.SalesByCategoryList[i];
      var category: CategoryReport = {
        name: categoryData.CategoryName,
        quantity: categoryData.TotalItemQty,
        sales: categoryData.TotalItemTotal,
      };
      this.categories.push(category);
    }

    this.totalCategoryQty = zData.SalesByCategoryList
      ? zData.SalesByCategoryList[0].TotalSalesByCategoryQty
      : 0;
    this.totalCategorySales = zData.SalesByCategoryList
      ? zData.SalesByCategoryList[0].TotalSalesByCategoryAmount
      : 0;

    this.grossSalesDisplay = zData.TotalGrossSales;
    this.discounts = zData.TotalDiscounts;
    this.refunds = zData.TotalRefunds;
    this.netSales = zData.TotalNetSales;
    this.fees = zData.TotalFees;
    this.taxes = zData.TotalTaxes;
    this.tips = zData.TotalTips;
    this.amountCollect = zData.AmmountCollected;
    this.openOrders = zData.OpenOrders;
    this.totalOrders = zData.TotalOrders;
    this.totalCashOrders = zData.TotalCashOrders;
    this.totalCreditOrders = zData.TotalCreditOrders;
    this.totalCash = zData.TotalCash;
    this.totalCredit = zData.TotalCredit;
    this.kioskSales = zData.KioskSales;
    this.totalTenderOrders = zData.TotalTenderOrders;
    this.totalTenderNetSales = zData.TotalTenderNetSales;
    this.totalTenderOrderTaxes = zData.TotalTenderOrderTaxes;
    this.totalTenderOrderTips = zData.TotalTenderOrderTips;
    this.totalTenderOrderFees = zData.TotalTenderOrderFees;
    this.totalTenderAmountCollected = zData.TotalTenderGrossSales;
    this.totalHouseOrders = zData.TotalHouseOrders;
    this.totalHouseNetSales = zData.TotalHouseNetSales;
    this.totalHouseOrderTaxes = zData.TotalHouseOrderTaxes;
    this.totalHouseOrderTips = zData.TotalHouseOrderTips;
    this.totalHouseOrderFees = zData.TotalHouseOrderFees;
    this.totalHouseAmountCollected = zData.TotalHouseGrossSales;
    this.totalNetOtherOrders = zData.TotalNetOtherOrders;
    this.totalNonCashFees = zData.TotalNonCashFees;
    this.orderDiscounts = zData.OrderDiscounts;
    this.loyaltyDiscounts = zData.LoyaltyDiscounts;
    this.totalDiscounts = zData.TotalDiscounts;
    this.totalGiftCardRedemptions = zData.TotalGiftCardRedemptions;
    this.complimentary = zData.Complimentary;
    this.totalRefunds = zData.TotalRefunds;
    this.cashTips = zData.CashTips;
    this.creditCardTips = zData.CreditCardTips;
    this.tenderTips = zData.TenderTips;
    this.houseTips = zData.HouseTips;
    this.totalTips = zData.TotalTips;
    this.canceledOrdersTotal = zData.TotalCanceledOrdersSummary;
  }
}
