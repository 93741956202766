<div class="page-container">
  <div class="page-header" [ngClass]="{'title': offline}">YOU'RE {{online?"ONLINE":"OFFLINE"}}!</div>
  <div *ngIf="!online" class="flex"><img src="assets/offline.png"  [ngClass]="{'title': offline}" class="imgtag" alt="" /></div>
  <div *ngIf="online" class="flex"><img src="assets/online.png" class="imgtag" alt="" /></div>
  <div *ngIf="!online" class="page-description">
    Please connect to Wi-Fi or Ethernet, then click the button below to check
    connection and return to login screen <br/>
    Wi-Fi can be setup from the Samsung Home screen 
    (found by holding bottom-left corner of Kiosk screen and entering pin)

  </div>
  <div class="flex">
    <button *ngIf="!online" matRipple class="connection-button" (click)="testNetwork()">
      Check Connection
    </button>
    <div *ngIf="online">
        <img src="assets/loading.png" class="loader" alt="" />
    </div>
  </div>
</div>
