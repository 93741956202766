  <footer class="review-cart">
  <div class="row">
    <div class="col-md-6 col-xs-6 cart-list">
      <div class="col-md-12 col-xs-12 footer-review-list {{
          isMenuIntegration ? 'oloRowChange' : ''
        }}" *ngIf="!isRemovePricingAndPayments">
        <div class="row">
          <div class="col-md-6 col-xs-6 sub-total">{{subtotalText}}</div>
          <div class="col-md-6 col-xs-6">
            <span class="right subTotal" [style.color]="'#000000'">{{
              subtotal | number: "1.2-2" | CurrencyPipe
              }}</span>
          </div>
        </div>

        <div *ngIf="discount != 0 && !isOlo" class="row">
          <div class="col-md-6 col-xs-6 sub-total">{{discountText}}:</div>
          <div class="col-md-6 col-xs-6">
            <span class="right subTotal" [style.color]="'#000000'">-{{ discount | number: "1.2-2" | CurrencyPipe
              }}<i (click)="removeDiscount()" class="far fa-trash-alt del"></i></span>
          </div>
        </div>

        <div class="row sub-total total-tax" *ngIf="!isMenuIntegration">
          <div class="col-md-5 col-xs-5 sub-total total-tax">
            {{ taxType == "2" ? totalVatText : totalTaxText }}
            <img (click)="openTaxInfoModel()" src="../../../assets/images/information.png"
                 onerror="this.onerror=null; this.src='assets/images/Image_21.png'" />
          </div>

          <div class="col-md-7 col-xs-7 taxIncludedLabel" *ngIf="isTaxIncludedInSubtotal">
            <span class="right" >
              {{subtotalIncludedText}}
            </span>
          </div>

          <div class="col-md-7 col-xs-7" *ngIf="!isTaxIncludedInSubtotal">
            <span class="right taxTotal" [style.color]="'#000000'">{{ tax && tax != null ? (tax | number: "1.2-2" |
              CurrencyPipe) : (0 | number: "1.2-2" | CurrencyPipe) }}</span>
          </div>
        </div>
        <div class="row" *ngIf="shippingFee!=0">
          <div class="col-md-6 col-xs-6">{{shippingFeeText}}</div>
          <div class="col-md-6 col-xs-6">
            <span class="right subTotal" [ngStyle]="{ color: priceColor }">{{
              shippingFee | number: "1.2-2" | CurrencyPipe
              }}</span>
          </div>
        </div>
        <div class="row" *ngIf="!isMenuIntegration">
          <div class="col-md-6 col-xs-6"><b class="total-final">{{totalText}}</b></div>
          <div class="col-md-6 col-xs-6">
            <strong class="right totalAmount" [style.color]="'#000000'">{{
              total && total != null&&total > 0
              ? (total | number: "1.2-2" | CurrencyPipe)
              : (0 | number: "1.2-2" | CurrencyPipe)
              }}</strong>
          </div>
        </div>

        <div class="row oloBottonMsg" *ngIf="isMenuIntegration">
          <div>
            <span class="oloBottonMsgTax">{{totalCalculationText}}</span>
          </div>
          <div class="oloBottonMsgPay">
            <strong class="oloBottonMsgPay">{{touchPayText}}</strong>
          </div>
        </div>

        <div>
          <p *ngIf="showcalorie === 'True'" class="cal-text">
            {{calorieCountText}}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-6 p-0">
      <div class="row" style="align-items: center;">
        <div class="col-md-6 col-xs-6 lh-10rem ">
          <button matRipple (click)="backToMenu()" class="
              gray-btn
              positive-button-color
              border-positive-button-color
              text-uppercase
              bg-none
            ">
            {{backToMenuText|uppercase}}
          </button>
          <button (click)="cancelOrderPopup()" matRipple class="plain-btn text-uppercase" [ngStyle]="{
              'background-color': negativeButton,
              color: negativeButtonText
            }">
            {{cancelOrderText|uppercase}}
          </button>
        </div>
        <div class="flex col-md-5 col-xs-5 text-center pay">
          <button appDebounceClick type="button" class="m-0
              height80
              col-md-12 col-xs-12
              green-btn
              animated
              pulse
              infinite
              bmargin6
              {{ isRemovePricingAndPayments ? 'none' : 'text-uppercase' }}
            " id="pay" mat-raised-button matRipple (debounceClick)="startPaymentAsync()" [debounceTime]="200"
            [ngStyle]="{
              'background-color': positiveButton,
              color: positiveButtonText,
              'font-size': getFontSize()
            }"
          > {{ isRemovePricingAndPayments ? submitOrderText : payText|uppercase }}
          </button>
          <!-- <button type="button" class="col-md-12 col-xs-12 green-btn text-uppercase"
                mat-raised-button (click)="printerSetup()">
                    printerSetup
                </button>
                <button type="button" class="col-md-12 col-xs-12 green-btn text-uppercase"
                mat-raised-button (click)="KOT()">
                    KOT
                </button> -->
        </div>
      </div>
    </div>
  </div>
</footer>
<div style="display: none">
  
  <app-level-up-modal [hidden]="true"></app-level-up-modal>

  <app-timeout [hidden]="true"></app-timeout>

  <app-par-brink-error-pop-up [hidden]="true"></app-par-brink-error-pop-up>

</div>
