import { Component, OnDestroy, OnInit } from '@angular/core';
import { defaults } from 'lodash';
import { Subscription } from 'rxjs';
import Keyboard from 'simple-keyboard';
import { Guid } from 'src/app/models/Guid';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-soft-keyboard',
  templateUrl: './soft-keyboard.component.html',
  styleUrls: ['./soft-keyboard.component.css'],
})
export class SoftKeyboardComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  value = '';
  keyboard: Keyboard | undefined;
  selectedInput: any;
  currentLayout = 'default';
  lockUpperCase = '';

  layoutFilters: any = {
    default: function (str: any): String {
      return str;
    },
    shift: function (str: any): String {
      return str;
    },
    defSpan: function (str: any): String {
      return str;
    },
    defSpanAcc: function (str: any): String {
      return str;
    },
    grubbrrLogin: function (str: any): String {
      return str;
    },
    numeric: function (str: any): String {
      return str.replace(/\D+/g, '');
    },
    alpha: function (str: any): String {
      return str;
    },
    alphaNumeric: function (str: any): String {
      return str;
    },
  };
  setInputCaretPosition(elem:any, pos:any){
    if (elem.setSelectionRange) {
      elem.focus();
      elem.setSelectionRange(pos, pos);
      /**
       * Scrolling when caret is at the end of input
       */
      if (pos === elem.value.length) elem.scrollLeft = elem.scrollWidth;
    }
  };

  ngAfterViewInit(): void {
    this.keyboard = new Keyboard({
      onChange: (input) =>
        this.KeyboardService.manual
          ? this.onChange2(input)
          : this.onChange(input),
      onKeyPress: (button: any) => this.onKeyPress(button),
      disableCaretPositioning: true,
      preventMouseDownDefault: true,
      newLineOnEnter: false,
      layoutName: this.currentLayout,
      maxLength: 10,
      buttonTheme: [
        {
          class: 'fas fa-backspace',
          buttons: '{bksp}',
        },
        {
          class: 'empty-class-to-avoid-console-warnings',
          buttons: '{enter}',
        },
        {
          class: 'max100 pad4',
          buttons: `, .`,
        },
        {
          class: 'max100 pad5',
          buttons: `@ , .`,
        },
        {
          class: 'max100 pad3',
          buttons: `\\ " ' ; : = % * ? ! < > - _ * $ & + - ( ) # [ ]`,
        },
        {
          class: 'max150 pad7',
          buttons: '.com',
        },
        {
          class: 'max130',
          buttons: '{alt}',
        },
        {
          class: 'pad3',
          buttons: '{alt2}',
        },
        {
          class: 'max150 pad7',
          buttons: '{default}',
        },
        {
          class: 'max100 fas fa-arrow-up',
          buttons: `{shift}`,
        },
        {
          class: 'max100 fas fa-arrow-up',
          buttons: `{alphaShift}`,
        },
      ],
      layout: {
        numeric: ['1 2 3', '4 5 6', '7 8 9', '{enter} 0 {bksp}'],
        defSpan: [
          'q w e r t y u i o p {bksp}',
          'a s d f g h j k l {enter}',
          '{shift} z x c v b n m , .',
          '{alt} {acc} {space} _ - @ .com',
        ],
        defSpanAcc: [
          'q w é r t y ú í ó p {bksp}',
          'á s d f g h j k l {enter}',
          '{shift} z x c v b ñ m , .',
          '{alt} {acc2} {space} _ - @ .com',
        ],
        defSpanAccShift: [
          'Q W É R T Y Ú Í Ó P {bksp}',
          'Á S D F G H J K L {enter}',
          '{shift} Z X C V B Ñ M , .',
          '{alt} {acc2} {space} _ - @ .com',
        ],
        defSpanShift: [
          'Q W E R T Y U I O P {bksp}',
          'A S D F G H J K L {enter}',
          '{shift} Z X C V B N M , .',
          '{alt} {acc2} {space} _ - @ .com',
        ],
        default: this.language.locale == 'fr' ? [
          'é à è ì ò ù â ê î ô û ç ë ï ü',
          'q w e r t y u i o p {bksp}',
          'a s d f g h j k l {enter}',
          '{shift} z x c v b n m , .',
          '{alt} {space} _ - @ .com',
        ] : [
          'q w e r t y u i o p {bksp}',
          'a s d f g h j k l {enter}',
          '{shift} z x c v b n m , .',
          this.language.locale == 'es'
            ? '{alt} {acc} {space} _ - @ .com'
            : '{alt} {space} _ - @ .com',
        ],
        shift: this.language.locale == 'fr' ? [
          'É À È Ì Ò Ù Â Ê Î Ô Û Ç Ë Ï Ü',
          'Q W E R T Y U I O P {bksp}',
          'A S D F G H J K L {enter}',
          '{shift} Z X C V B N M , .',
          '{alt} {space} _ - @ .com',
        ] : [
          'Q W E R T Y U I O P {bksp}',
          'A S D F G H J K L {enter}',
          '{shift} Z X C V B N M , .',
          this.language.locale == 'es'
            ? '{alt} {acc} {space} _ - @ .com'
            : '{alt} {space} _ - @ .com',
        ],
        alphaShift: [
          'Q W E R T Y U I O P {bksp}',
          'A S D F G H J K L {enter}',
          '{alphaShift} Z X C V B N M {space}',
        ],
        alpha: [
          'q w e r t y u i o p {bksp}',
          'a s d f g h j k l {enter}',
          '{alphaShift} z x c v b n m {space}',
        ],
        alt: [
          '1 2 3 4 5 6 7 8 9 0 {bksp}',
          `@ # $ & - + ( ) {enter}`,
          `[ ] \\ = * " ' : ; ! ?`,
          '{default} , {space} . {default}',
        ],
        // alt2: [
        //   "~ ` | • √ π ÷ × ¶ ∆ {bksp}",
        //   `£ ¢ € ¥ ^ ° = { } {enter}`,
        //   `{altBack} \\ © ® ™ ℅ [ ] ¡ ¿ {altBack}`,
        //   "{default} , < {space} > . {default}"
        // ],
        grubbrrLogin: [
          '1 2 3 4 5 6 7 8 9 0',
          'q w e r t y u i o p {bksp}',
          'a s d f g h j k l {enter}',
          'z x c v b n m ? @ .com',
          '@grubbrr.com {space} .',
        ],
        altAlpha: [
          '1 2 3 4 5 6 7 8 9 0 {bksp}',
          `@ # $ & - + ( ) {enter}`,
          `[ ] \\ = * " ' : ; ! ?`,
          '{alphaNumeric} , {space} . {default}',
        ],
        alphaNumeric: [
          '1 2 3 4 5 6 7 8 9 0',
          'q w e r t y u i o p {bksp}',
          'a s d f g h j k l {enter}',
          '{shift} z x c v b n m , .',
          '{altAlpha} {space} _ - @ .com',
        ],
      },
      display: {
        '{acc}': 'áéíóñú',
        '{acc2}': 'aeionu',
        '{bksp}': ' ',
        '{enter}': 'ENTER',
        '{shift}': ' ',
        '{alphaShift}': ' ',
        '{alt}': '?123',
        '{altBack}': '?123',
        '{alt2}': '~[<',
        '{default}': 'ABC',
        '{space}': 'SPACE',
        '{clear}': 'CLEAR',
        '@': '@',
        '?': '?',
        '.com': '.com',
        '{altAlpha}': '?123',
        '{alphaNumeric}': 'ABC',
      },
    });

    this.allSubsCription.push(
      this.KeyboardService.ObserveInputChange.subscribe((input: any) => {
        let layoutName = input[1];
        let inputName = input[0].id;
        let inputValue = input[0].value;
        if (this.KeyboardService.manual) {
          this.keyboard?.setOptions({
            inputName: inputName,
            //layoutName: layoutName,
            maxLength: parseInt(input[2]),
            theme: 'hg-theme-default defaultOver',
          });
        } else {
          this.keyboard?.setOptions({
            inputName: inputName,
            layoutName: layoutName,
            maxLength: parseInt(input[2]),
            theme: 'hg-theme-default defaultOver',
          });
        }
        if (input[0]) {
          inputValue = this.layoutFilters[layoutName](inputValue);
        }
        this.keyboard?.setInput(inputValue, inputName);
        this.value = inputValue;
        this.selectedInput = input[0];
        this.currentLayout = layoutName;
        this.lockUpperCase = '';
        if (!this.KeyboardService.manual) {
          this.currentLayout = layoutName;
        }
      })
    );
  }
  valoo: string = '';
  onChange2 = (input: string) => {
    if(this.KeyboardService.maxLength){
      if(input.length>=this.KeyboardService.maxLength){
        input=input.slice(0,this.KeyboardService.maxLength)
      }
    }
    let caretPosition = this.keyboard?.caretPosition;
    if (caretPosition === null) caretPosition = input.length;
    this.setInputCaretPosition(this.selectedInput, caretPosition);
    this.keyboard?.setInput(input)
    this.valoo = input;

    this.KeyboardService.stringSub.next(this.valoo);
  };
  onChange = (input: string) => {
    input = this.layoutFilters[this.currentLayout](input);
    this.value = input;
    this.selectedInput.value = this.value;
    let caretPosition = this.keyboard?.caretPosition;
    if (caretPosition === null) caretPosition = input.length;
    this.setInputCaretPosition(this.selectedInput, caretPosition);
    this.selectedInput.dispatchEvent(new Event('input'), { bubbles: true });
    this.keyboard?.setInput(input);
    if (this.value == "" || (this.value).trim().slice(-1) == ".") {
      this.handleUpperCase();
    } else {
      if (!this.lockUpperCase) {
       this.handleLowerCase();
      }
    }
    
  };

  clearAll() {
    this.keyboard?.clearInput();
  }

  onKeyPress = (button: string) => {
    // console.log("Button pressed", button);
    this.KeyboardService.buttonclicked.next(button);
    this.KeyboardService.handleupperlowercase.subscribe((val)=>{
      if(val){
        this.handleUpperCase();
      }else{
        this.handleLowerCase();
      }
    })
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === '{shift}' || button === '{lock}') {
      this.handleShift();
    }
    if (button === '{alt}') {
      this.handleAlt();
    }
    if (button === '{alt2}') {
      this.handleAlt2();
    }
    if (button === '{altBack}') {
      this.handleAltBack();
    }
    if (button === '{alphaShift}') {
      this.handleAlphaShift();
    }
    if (button === '{default}') {
      this.handleDefault();
    }
    if (button === '{alphaNumeric}') {
      this.handleAlphaNumeric();
    }
    if (button === '{altAlpha}') {
      this.handleAltAlpha();
    }
    if (button === '{clear}') {
      this.clearAll();
    }
    if (button === '{acc}') {
      this.handleAcc();
    }
    if (button === '{acc2}') {
      this.handleAcc();
    }
    if (button === '{enter}') {
      this.KeyboardService.KeyboardStatus = false;
    }
  };

  onInputChange = (event: any) => {
    this.keyboard?.setInput(event.target.value);
  };

  handleUpperCase() {
    let currentLayout = this.keyboard?.options.layoutName;
    let altToggle = currentLayout;
    if (currentLayout === 'default') {
      altToggle = 'shift'
    }
    this.keyboard?.setOptions({
      layoutName: altToggle,
    });
  }

  handleLowerCase() {
    let currentLayout = this.keyboard?.options.layoutName;
    let altToggle = currentLayout;
    if (currentLayout === 'shift') {
      altToggle = 'default'
    }
    this.keyboard?.setOptions({
      layoutName: altToggle,
    });
  }




  handleAltBack() {
    let currentLayout = this.keyboard?.options.layoutName;
    let altToggle = currentLayout === 'alt2' ? 'alt' : 'alt2';

    this.keyboard?.setOptions({
      layoutName: altToggle,
    });
  }
  handleAlphaShift() {
    let currentLayout = this.keyboard?.options.layoutName;
    let altToggle = currentLayout === 'alpha' ? 'alphaShift' : 'alpha';

    this.keyboard?.setOptions({
      layoutName: altToggle,
    });
  }
  handleAlt2() {
    let currentLayout = this.keyboard?.options.layoutName;
    let altToggle = currentLayout === 'alt' ? 'alt2' : 'alt';

    this.keyboard?.setOptions({
      layoutName: altToggle,
    });
  }

  handleAlt() {
    let currentLayout = this.keyboard?.options.layoutName;
    let altToggle = currentLayout === 'default' ? 'alt' : 'alt';

    this.keyboard?.setOptions({
      layoutName: altToggle,
    });
  }

  handleShift = () => {
    let currentLayout = this.keyboard?.options.layoutName;
    let shiftToggle = 
      currentLayout === 'default' 
        ? 'shift' 
        : currentLayout=='defSpanAcc' 
          ? 'defSpanAccShift'
          :  currentLayout=='defSpanAccShift' 
            ? 'defSpanAcc' 
            :currentLayout=='defSpan' 
              ?'defSpanShift'
              :currentLayout=='defSpanShift'
                ?'defSpan' 
                :'default';


    this.lockUpperCase = shiftToggle === 'shift' ? 'lockUpperCase' : '';
    this.keyboard?.setOptions({
      layoutName: shiftToggle,
    });
  };
  handleDefault = () => {
    let currentLayout = this.keyboard?.options.layoutName;
    let defaultToggle = currentLayout === 'alt' ? 'default' : 'shift';

    this.keyboard?.setOptions({
      layoutName: defaultToggle,
    });
  };

  handleAlphaNumeric = () => {
    let currentLayout = this.keyboard?.options.layoutName;
    let defaultToggle = currentLayout === 'altAlpha' ? 'alphaNumeric' : 'shift';

    this.keyboard?.setOptions({
      layoutName: defaultToggle,
    });
  };
  handleAcc() {
    let currentLayout = this.keyboard?.options.layoutName;
    let accToggle =( currentLayout === 'defSpan' || currentLayout ==='default') ? 'defSpanAcc' : (currentLayout=='defSpanAccShift')?'defSpanShift':'defSpan';
    this.keyboard?.setOptions({
      layoutName: accToggle,
    });
  }
  handleAltAlpha() {
    let currentLayout = this.keyboard?.options.layoutName;
    let altToggle = currentLayout === 'alphaNumeric' ? 'altAlpha' : 'altAlpha';

    this.keyboard?.setOptions({
      layoutName: altToggle,
    });
  }
  constructor(
    public KeyboardService: KeyboardService,
    private language: LanguageService
  ) {}

  ngOnInit(): void {
    this.KeyboardService.newBoardSubject.subscribe((val) => {
      if(val){
        this.currentLayout = val;
      }
      //this.ngOnDestroy();
      this.keyboard?.destroy();         
      this.ngAfterViewInit();
      this.keyboard?.setOptions({inputName:Guid.newGuid()})
    });
    this.allSubsCription.push(
      this.KeyboardService.stringSub.subscribe((val) => {
        this.valoo = val;
      })
    );
    this.allSubsCription.push(
      this.KeyboardService.clearSub.subscribe(() => {
        this.keyboard?.replaceInput({})
      })
    );
  }
}
