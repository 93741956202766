import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { DatabaseHandler } from 'src/app/DatabaseHandler';
import { HeaderImage } from 'src/app/models/headerImage';
import { environment } from 'src/environments/environment';
import { loggingData, LogService } from 'src/app/services/log.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { CustomerLoginPopupComponent } from 'src/app/components/dialogs/customer-login-popup/customer-login-popup.component';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { CartComponent } from 'src/app/components/cart/cart.component';
import { Category } from 'src/app/models/category';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { DatabaseService } from 'src/app/services/database.service';
import { Subscription } from 'rxjs';
import {
  ItemV2 as Item,
  ItemV2,
  ModifierV2 as Modifier,
} from 'src/app/models/item';
import {
  CategorySalesType,
  CommonFunctions,
  Snackbar,
} from 'src/app/services/common';
import { OutOfStockComponent } from 'src/app/components/out-of-stock/out-of-stock.component';
import { SoldOutComponent } from 'src/app/components/dialogs/sold-out/sold-out.component';
import { AlcoholicOver21DialogComponent } from 'src/app/components/dialogs/alcoholic-over21-dialog/alcoholic-over21-dialog.component';
import { AlcoholicItemsExceedMaxComponent } from 'src/app/components/dialogs/alcoholic-items-exceed-max/alcoholic-items-exceed-max.component';

import { ItemTransferService } from '../../services/item-transfer.service';
import { Guid } from '../../models/Guid';
import { Concessionaire } from '../../models/Concessionaire';

import { PusherService } from '../../services/pusher.service';
import { ItemWeightComponent } from '../dialogs/item-weight/item-weight.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpSellDialogComponent } from '../dialogs/up-sell-dialog/up-sell-dialog.component';

import { ItemSelectionComponent } from '../dialogs/item-selection/item-selection.component';
import { ScannedItemAddedComponent } from '../dialogs/scanned-item-added/scanned-item-added.component';
import { OloErrorPopupComponent } from '../dialogs/olo-error-popup/olo-error-popup.component';
import { VariationOption } from 'src/app/models/Variations';
import { ScannerHybridModeServiceService } from 'src/app/services/scanner-hybrid-mode-service.service';
import { IntegrationService } from 'src/app/services/integration.service';
import { GeneralAlertComponent } from '../dialogs/general-alert/general-alert.component';
import { usConnectCreds } from 'src/app/models/usconnectCreds';
import { LanguageChoice, LanguageService } from 'src/app/services/language.service';
import { FilterConcessionaireComponent } from '../dialogs/filter-concessionaire/filter-concessionaire.component';
import { ConcessionaireFilterTypes } from 'src/app/models/concessionaireTypes';
import { ConcessionaireService } from './conc-service/concessionaire.service';
import { BrandFilterComponent } from '../dialogs/brand-filter/brand-filter.component';
import { TimeoutComponent } from '../timeout/timeout.component';
import { Subject } from 'rxjs';
import { SchedulePricingService } from 'src/app/services/schedule-pricing.service';
import { DataService } from 'src/app/services/data.service';
import { FixedItemDiscount } from 'src/app/models/discounts';
import { DiscountService } from 'src/app/services/discount.service';
import { CustomerLoginModalService } from '../dialogs/customer-login-popup/customer-login-popup.service';
import { PreloaderService } from 'src/app/services/preloader.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }

    if (this.isHybridMode) {
      this.scannerService.stopBarcodeScanner();
    }
  }

  private allSubsCription: Subscription[] = [];

  @ViewChild(CustomerLoginPopupComponent)
  customerLoginPopup!: CustomerLoginPopupComponent;

  @ViewChild(CartComponent) cart!: CartComponent;

  @ViewChild(TimeoutComponent) timeOutComponent!: TimeoutComponent;

  @ViewChild('itemContainerDiv') itemContainerDiv!: ElementRef;

  @ViewChild('categoryContainerDiv') categoryContainerDiv!: ElementRef;

  @ViewChild('itemsgrid') itemsgrid!: ElementRef;

  itemSelectionmodal!: ItemSelectionComponent;

  scannedItemAddedComponent!: ScannedItemAddedComponent;

  imageBaseUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  branchLogo: string = '';

  queryCategoryId: string = '';

  categoryTitle: string = '';

  isLoyalty: boolean = false;
  useLoyalty: boolean = false;
  userName: string = '';

  openCart: boolean = false;

  categoryColor: string = '';

  primaryColor: string = '';

  accentColor: string = '';

  textColor: string = '';

  priceColor: string = '';

  backgroundColor: string = '';

  isHidden: boolean = false;

  headerImages: HeaderImage[] = [];

  headerImage: HeaderImage = {
    imageURL: '',
    ID: '',
    bgColor: '',
  };

  concessionaireBaseUrl: string = '/uploads/ConcessionaireLogo/';

  hideHeaderCategoryText: string = 'false';

  allCategory: Category[] = [] as Category[];

  selectedCategory: Category | null = null;

  parentCategoryStack = [] as Category[];

  currentCategoryToShow: Category[] = [] as Category[];

  currentCategoryToShowSub: Subject<Category[]> = new Subject();

  currentItems: Item[] = [] as Item[];
  currentItemsSub: Subject<Item[]> = new Subject();

  defaultCatBackground: string = '';

  backgroundImageCategory: string = '';

  showCalorie: string = '';

  hideStartAt: string = '';

  minimizeSpace!: any;

  checkScrollCheck: boolean = false;

  isTop: boolean = true;

  isBottom: boolean = false;

  removeAlcohol: boolean = false;

  //For Concessionaire
  isConcessionaire: boolean = false;

  allConcessionaire: Concessionaire[] = [] as Concessionaire[];

  allConcessionaireSub: Subject<Concessionaire[]> = new Subject();

  selectedConcessionaire!: Concessionaire;

  queryConcessionaireId: string = '';

  private itemOffSet = 0;

  private isInbotton = false;


  isHybridMode = GeneralSetting.getUseBarcodeScanner() === 'True' ? true : false;

  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();

  IsHideBarcodeItems = GeneralSetting.getIsHideBarcodeItems();

  usConnectCreds: usConnectCreds = {
    AuthToken: '',
    DeviceSerialNumber: '',
    UCMID: '',
    URL: '',
  };

  integrationID: number;

  isMiniMizeSpacing: boolean = false;

  timeOutCloseCart!: any;

  backText: string = '';

  startsAtText: string = '';

  regularPriceText: string = '';

  calorieText: string = '';

  signInText: string = '';

  viewMoreText: string = '';

  soldOutText: string = '';

  noResultsText: string = '';

  tryDifferentFilterText: string = '';

  foodFiltersText: string = '';

  scrollForMoreText: string = '';

  scrollForMoreLBText: string = '';

  isFilterApplied: string = '';

  isItemTagsSelected: string = '';

  selConcessionaireFilters: ConcessionaireFilterTypes[] =
    [] as ConcessionaireFilterTypes[];

  concessionaireHeaderImage: string = '';

  isShowBranchLogo: boolean = false;

  positiveBtnColor: string = '';

  positiveTextColor: string = '';

  conSize!: number;

  isHideFilter: boolean = false;

  secondaryColor: string = '';

  isFilter: boolean = false;

  categoryDescription: string = '';

  placeholderImage: string = '';

  fixedItemDisc: FixedItemDiscount[] = [] as FixedItemDiscount[];

  closeCartDelay: any;

  showOverlay: boolean = false;

  showScroll: boolean = false;

  isScrollArrowUp: boolean = false;

  isSelectedLangFrench: boolean = false;

  constructor(
    private db: DatabaseService,
    private isKioskService: IsKioskService,
    private userService: UserService,
    private logger: LogService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private loyaltyService: LoyaltyService,
    private itemTransferService: ItemTransferService,
    private cartService: CartService,
    private readonly pusherService: PusherService,
    public modalService: NgbModal,
    private scannerService: ScannerHybridModeServiceService,
    private language: LanguageService,
    private readonly conService: ConcessionaireService,
    integration: IntegrationService,
    private schedulePricing: SchedulePricingService,
    private readonly dataService: DataService,
    private discountService: DiscountService,
    private customerLoginService: CustomerLoginModalService,
    private preloader: PreloaderService,
    private cdr:ChangeDetectorRef
  ) {
    this.integrationID = integration.integration;
    this.queryCategoryId = this.route.snapshot.queryParams['category'];
    this.queryConcessionaireId =
      this.route.snapshot.queryParams['concessionaireId'];
    this.setOffSet(0);
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
    if (this.language.locale == 'fr') {
      this.isSelectedLangFrench = true;
    } else {
      this.isSelectedLangFrench = false;
    }
  }

  bordersTypeCategory: boolean = false;
  bordersTypeItem: boolean = false;
  imageRadius: string = '';
  isHideZeroPrice: boolean = false;

  loadText() {
    this.backText = this.language.getTextElement('back');
    this.startsAtText = this.language.getTextElement('txt_starts_at');
    this.regularPriceText = this.language.getTextElement('txt_regular_price');
    this.calorieText = this.language.getTextElement('calorie_format_text');
    this.soldOutText = this.language.getTextElement('sold_out');
    this.signInText = this.language.getTextElement('action_sign_in');
    this.foodFiltersText = this.language.getTextElement('food_filters');
    this.viewMoreText = this.language.getTextElement('view_more');
    this.noResultsText = this.language.getTextElement('no_results');
    this.scrollForMoreText = this.language.getTextElement("scroll_for_more_overlay");
    this.scrollForMoreLBText = this.language.getTextElement("lbl_scroll_for_more");
    this.tryDifferentFilterText = this.language.getTextElement(
      'try_different_filter'
    );
  }

  async ngOnInit(): Promise<void> {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.branchLogo =
      GeneralSetting.getBranchLogo() != ''
        ? this.imageBaseUrl + GeneralSetting.getBranchLogo()
        : 'assets/images/Image_21.png';

    this.isLoyalty = this.loyaltyService.isLoyalty;
    this.useLoyalty = GeneralSetting.getUseLoyaltyKiosk() == 'True';
    this.categoryColor = GeneralSetting.getCategoryColor();

    this.primaryColor = GeneralSetting.getPrimaryColor();

    this.textColor = GeneralSetting.getTextColor();

    this.accentColor = GeneralSetting.getAccentColor();

    this.defaultCatBackground = GeneralSetting.getKioskBackgroundImage();

    this.minimizeSpace = GeneralSetting.getIsMinimizeSpacing();

    this.showCalorie = GeneralSetting.getShowCalorieCounts();

    this.hideStartAt = GeneralSetting.getHideStartsAtForVariablePrice();

    this.priceColor = GeneralSetting.getPriceColor();

    this.isConcessionaire =
      GeneralSetting.getIsConcessionaire().toLowerCase() == 'true';

    this.bordersTypeCategory =
      GeneralSetting.getBorderTypeCategories().toLowerCase() == 'true';

    this.bordersTypeItem =
      GeneralSetting.getBorderTypeItems().toLowerCase() == 'true';

    this.imageRadius = GeneralSetting.getBorderPercentage();

    this.getCategoryHeaderDetail();

    this.setOffSet(0);

    this.isHideZeroPrice =
      GeneralSetting.getHidePriceIfBasePriceZero() &&
        GeneralSetting.getHidePriceIfBasePriceZero() == 'True'
        ? true
        : false;

    if (this.isConcessionaire) {
      this.concessionaireHeaderImage =
        GeneralSetting.getConcessionaireHeaderImage();

      this.isShowBranchLogo = GeneralSetting.getHideLogo();

      this.getSelConcessionaireTypes();

      this.getAndSetAllConcessionaire();
    } else {
      this.getAndSetAllCategory();
    }

    this.userLoginCheck();

    this.pusherBind();

    // if (this.isHybridMode) {
    //   //this.showItemSelectionPopup();
    //   this.startBarcodeScanner();
    // }

    this.positiveBtnColor = GeneralSetting.getPositiveColor();

    this.positiveTextColor = GeneralSetting.getPositiveColorText();

    this.secondaryColor = GeneralSetting.getSecondaryColor();

    this.fixedItemDisc = await this.discountService.getFixedItemDisc();
  }

  async ngAfterViewInit() {


    this.showOverlay = GeneralSetting.getIsOverlayScreenShown() == "false" ? true : false;
    this.cdr.detectChanges();
    if (this.showOverlay) {
      setTimeout(() => {
        this.showOverlay = false;
        this.cdr.detectChanges();
        GeneralSetting.setIsOverlayScreenShown(true);
      }, 1500);
    }
    this.checkforScroll();


    setTimeout(() => {
      this.checkScrollCheck = this.checkScroll();
    }, 1500);

    this.preloader.hide();
  }

  checkforScroll() {
    if (this.currentCategoryToShow.length && this.currentCategoryToShow.length > 5) {
      this.showScroll = true;
    } else {
      this.showScroll = false;
    }
  }

  private pusherBind() {
    this.allSubsCription.push(
      this.pusherService.Item86.subscribe((x) => {
        if (x && Object.keys(x).length > 0) {
          setTimeout(() => {
            this.onSideCategoryClick(this.selectedCategory);
          }, 1000);
        }
      })
    );

    this.allSubsCription.push(
      this.pusherService.ItemPrice.subscribe((x) => {
        this.onSideCategoryClick(this.selectedCategory);
      })
    );


    this.allSubsCription.push(
      this.pusherService.ItemStatus.subscribe((x) => {
        if (x && Object.keys(x).length > 0) {
          setTimeout(() => {
            this.onSideCategoryClick(this.selectedCategory);
          }, 1000);
        }
      })
    );

    this.allSubsCription.push(
      this.pusherService.ItemIsActive.subscribe((x) => {
        if (x && Object.keys(x).length > 0) {
          setTimeout(() => {
            this.onSideCategoryClick(this.selectedCategory);
          }, 1000);
        }
      })
    );

    this.allSubsCription.push(
      this.loyaltyService.updateRewardSub.subscribe((points: number) => {
        let availablePoints = this.userService.referrizerLoyaltyUser?.loyaltyPointsCount || 0;;
        availablePoints = Number(availablePoints) + Number(points);
        this.userService.referrizerLoyaltyUser.loyaltyPointsCount = availablePoints;
      })
    );
  }

  private SetAllConcessioaniteValues(con: Concessionaire[]) {
    this.allConcessionaire = con;
    this.conSize = this.allConcessionaire.length;

    if (this.allConcessionaire.length > 0) {
      let filterCon =
        this.conSize > (this.isFilter ? 7 : 6)
          ? this.allConcessionaire.slice(0, this.isFilter ? 6 : 5)
          : this.allConcessionaire;

      //this.allConcessionaireSub = new BehaviorSubject(filterCon).asObservable();
      this.allConcessionaireSub.next(filterCon);
    } else {
      this.allConcessionaireSub.next([]);
    }
  }

  private SetShowingCategoryValues(cat: Category[]) {
    this.currentCategoryToShow = cat;
    this.currentCategoryToShowSub.next(this.currentCategoryToShow);
  }

  private SetShowingItemValues(item: Item[]) {
    this.schedulePricing.findAndApplySchedulePricing(item).then((data) => {
      this.currentItems = item;
      if (this.currentItems.length > 0) {
        this.currentItemsSub.next(this.currentItems);
      } else {
        this.currentItemsSub.next([]);
      }
    });
  }

  private getAndSetAllConcessionaire() {
    //let conPer = window.performance.now();
    //console.log("start Concessionaire Performance :- " + conPer);

    this.db
      .getAllConcessionaireFromSql(
        this.isFilterApplied,
        this.isItemTagsSelected
      )
      .then((conList: Concessionaire[]) => {
        this.SetAllConcessioaniteValues(conList.filter((x) => !x.IsClosed));
        this.setDefaultConcessionaire();
      });
  }

  private findDefaultConcessionaireAndSet() {
    if (this.allConcessionaire.length > 0) {
      const defaultCon = this.allConcessionaire.find(
        (x) => x.IsDefaultConcessionaire == true
      );

      if (defaultCon) {
        this.selectedConcessionaire = defaultCon;
      } else {
        this.selectedConcessionaire = this.allConcessionaire[0];
      }

      this.queryConcessionaireId = this.selectedConcessionaire.ConcessionaireID;

      this.getAndSetCategoryDetailOnConClick(this.selectedConcessionaire);
    }
  }

  private async setDefaultConcessionaire() {
    if (this.allConcessionaire.length > 0) {
      if (this.queryConcessionaireId && this.queryConcessionaireId != '') {
        const con = this.allConcessionaire.find(
          (x) => x.ConcessionaireID == this.queryConcessionaireId
        );
        if (con) {
          this.onConcessionaireClick(con);
        } else {
          this.findDefaultConcessionaireAndSet();
        }
      } else {
        this.findDefaultConcessionaireAndSet();
      }
    } else {
      this.selectedConcessionaire = {} as Concessionaire;
      this.allCategory = [];
      this.SetShowingItemValues([] as Item[]);
      this.setSelectedCategoryWithItem(this.allCategory[0], []);
    }

    this.scrollToConcessionaire();
  }

  private scrollToConcessionaire() {
    setTimeout(() => {
      if (this.selectedConcessionaire) {
        const conRef = document.getElementById(
          this.selectedConcessionaire.ConcessionaireID
        );

        if (conRef) {
          conRef.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }
      }
    }, 500);
  }

  private getAndSetAllCategory() {
    this.db.getAllCategoryFromSql('', false).then(async (categories) => {
      if (!(categories && categories.length > 0)) return;

      this.allCategory = this.setChildCategories(categories);

      if (this.allCategory && this.allCategory.length > 0) {
        this.setDefaultCategory();
      }
    });
  }

  private setChildCategories(categories: Category[]) {
    const childrenCats = categories.filter(
      (cat) => cat.ParentCategoryID != '0'
    );

    childrenCats.forEach((child) => {
      var parent = categories.find(
        (cat) => cat.CategoryID.toString() == child.ParentCategoryID
      );
      if (parent && parent.associatedItems) {
        if (
          parent.associatedItems.find(
            (cat) => cat.CategoryID == child.CategoryID
          ) == undefined
        ) {
          child.isSub = true;
          parent.associatedItems?.push(child);
          child.parent = parent;
        }
      }
    });

    return categories;
  }

  private async setDefaultCategory() {
    var setDefault = async () => {
      if (this.allCategory && this.allCategory.length > 0) {
        this.SetShowingCategoryValues(
          this.allCategory.filter((cat) => cat.ParentCategoryID == '0')
        );

        if (this.currentCategoryToShow.length > 0) {
          let items = await this.db.getItemsByCategoryIdFromSql(
            this.currentCategoryToShow[0].CategoryID,
            this.isConcessionaire,
            this.selectedConcessionaire?.ConcessionaireID,
            false,
            '',
            this.itemOffSet,
            0,
            '',
            this.isItemTagsSelected,
            this.IsHideBarcodeItems
          );
          this.setSelectedCategoryWithItem(
            this.currentCategoryToShow[0],
            items
          );
        }
      }
    };

    if (this.queryCategoryId && this.queryCategoryId != '') {
      for (var i = 0; i < this.allCategory.length; i++) {
        if (this.allCategory[i].CategoryID == this.queryCategoryId) {
          let category = this.allCategory[i];

          if (category.parent && Object.entries(category.parent).length > 0) {
            if (category.ShowasClassicModifierGroup == 'True') {
              this.onSideCategoryClick(category.parent, true);
            } else {
              this.onSideCategoryClick(category, true);
            }
          } else {
            this.onSideCategoryClick(category, false);
          }

          this.scrollToCategory();

          return;
        }
      }

      setDefault();
    } else {
      setDefault();
    }
  }

  private userLoginCheck() {
    this.allSubsCription.push(
      this.userService.userSubject.subscribe((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          this.userName =
            this.userService.name ?? this.language.getTextElement('txt_guest');
        } else {
          this.userName = this.language.getTextElement('action_sign_in');
        }
      })
    );
  }

  private setSelectedCategoryWithItem(category: Category, items: Item[]) {
    this.selectedCategory = category;

    if (category) {
      let childCategory = category.associatedItems;

      let categoryItems = this.convertCategoryToItem(
        childCategory as Category[]
      );
      items.push(...categoryItems);

      this.SetShowingItemValues(items);

      this.backgroundImageCategory =
        category.BackgroundImage != ''
          ? this.imageBaseUrl + category.BackgroundImage
          : this.defaultCatBackground != '' && category.ColorCode == ''
            ? this.imageBaseUrl + this.defaultCatBackground
            : '';

      this.backgroundColor = category.ColorCode
        ? category.ColorCode
        : GeneralSetting.getCategoryBackgroundColor();

      this.categoryTitle = category.Name;

      if (category.HideHeaderText) {
        this.hideHeaderCategoryText =
          category.HideHeaderText.toString().toLowerCase();
      }

      this.categoryDescription = category.Description ?? '';

      //this.checkScrollCheck = this.checkScroll();

      //this.headerImage.bgColor = category.ColorCode;
      this.changeHeaderDetail(category);

      this.scrollItemGridToTop();
      //this.scrollToCategory();

      this.checkforScroll();
    }
  }

  private scrollToCategory() {
    setTimeout(() => {
      if (this.selectedCategory) {
        let categoryRef = document.getElementById(
          this.selectedCategory.CategoryID
        );

        if (categoryRef) {
          categoryRef.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
        }
      }
    }, 50);
  }

  private changeHeaderDetail(category: Category) {
    let i = 0;
    for (i = 0; i < this.headerImages.length; i++) {
      if (this.headerImages[i].ID === category.CategoryID) {
        this.headerImage.imageURL = this.headerImages[i].imageURL;
        break;
      }
    }
    if (i === this.headerImages.length) {
      this.headerImage.imageURL = '';
      this.headerImage.bgColor = GeneralSetting.getPrimaryColor();
    } else {
      this.headerImage.bgColor = '';
    }
  }

  private getCategoryHeaderDetail() {
    const CallBack = (tx: any, result: any): void => {
      for (let i = 0; i < result.rows.length; i++) {
        if (result.rows[i].HeaderImage !== '') {
          const image: HeaderImage = {
            imageURL:
              result.rows && result.rows[i]
                ? this.imageBaseUrl + result.rows[i].HeaderImage
                : '',
            ID: result.rows[i].CategoryID,
            bgColor: '',
          };
          this.headerImages.push(image);
        }
      }

      this.cartService.setPromotion(0, true, false, []);
    };

    const ErrorCallBack = (tx: any, result: any): void => {
      console.log('Category Header Image Get Error :- ', result);
    };

    var sqlString = 'SELECT HeaderImage, CategoryID FROM CategoryMasters';
    DatabaseHandler.executeSqlStatement(sqlString, [], CallBack, ErrorCallBack);
  }

  private convertCategoryToItem(categories: Category[]) {
    let items = [] as Item[];

    for (var i = 0; i < categories.length; i++) {
      let cat = categories[i];

      let item = new Item();

      item.Name = cat.Name;
      item.IsCategory = true;
      item.AttributeCount = 0;
      item.ModifierCount = 0;
      item.Price = '0';
      item.OldPrice = '0';
      item.ImageUrl = cat.ImageUrl;
      item.BackgroundImage = cat.BackgroundImage ?? '';
      item.DisplayOrder = cat.DisplayOrder;
      item.CategoryID = cat.CategoryID;
      item.ShowasClassicModifierGroup = cat.ShowasClassicModifierGroup;
      item.LabelforItemSelection = cat.LabelforItemSelection;
      item.Quantity = '1';
      items.push(item);
    }

    return items;
  }

  private async getItemByCategory(
    categoryID: string,
    concessionaireId: string = '',
    isConcessionaire: boolean = false
  ) {
    let itemPer = window.performance.now();

    //console.log("start Item Performance :- " + itemPer);

    var categoryItems = await this.db.getItemsByCategoryIdFromSql(
      categoryID,
      this.isConcessionaire,
      this.selectedConcessionaire?.ConcessionaireID,
      false,
      '',
      this.itemOffSet,
      0,
      '',
      '',
      this.IsHideBarcodeItems
    );

    let itemPerEnd = window.performance.now();

    //console.log("End Item Performance :- " + itemPerEnd);

    //console.log("item Per Sec:- " + ((itemPerEnd - itemPer) / 1000));

    //console.log("Category Items Length :- " + categoryItems.length);

    return categoryItems;
  }

  private navigateToItemPage(item: Item, direction: string) {
    this.itemTransferService.snapshotItemV2(item);
    this.router.navigate([`/${direction}`], {
      queryParams: {
        id: item.ItemID,
        category: item.CategoryID,
        concessionaireId: this.isConcessionaire
          ? this.selectedConcessionaire?.ConcessionaireID
          : '',
      },
    });
  }

  /**
   * }
   * @implements hard-coded age of majority, to be changed in future versions
   * @description checks if the user has exceeded current amount of alcoholic items allowed per order, and checks user age
   * @returns {Promise<string>} string data detailing whether user age is over/under legal drinking age. Currently hard-coded 21, soon to be localized
   */
  private AlcoholicItemChecks(item: Item, direction: string) {
    let max: number = Number(
      GeneralSetting.getMaxAlcoholicItemsPerOrder() || 0
    );
    if (max > 0) {
      const current: number = Number(GeneralSetting.getCurrentCountofAlkol());
      if (current === max) {
        AlcoholicItemsExceedMaxComponent.open(this.modalService);
      } else {
        let co21 = AlcoholicOver21DialogComponent.open(this.modalService);

        co21.result.then((data: any) => {
          if (data === 'over') {
            var soldOutMode = this.checkForSoldOutModifier(item);

            if (soldOutMode.length > 0) {
              this.openSoldOutModel(data, item, direction);
            } else if (direction == 'menu') {
              this.normalItemAddToCart(item);
              //this.navigateToItemPage(item, direction);
            } else {
              this.navigateToItemPage(item, direction);
            }
          } else {
            this.removeAlcohol = true;
            //this.isUnder21.emit(true);
            //resolve('under21');
          }
        });
      }
    }
  }

  /**
   * @param {Modifier[]} soldOutModifier An array of the selected item's Modifier elements which are both default and 86
   * @param {ItemV2} item Data related to the selected item object
   * @param {string} direction Dynamic URL path of the selected item's desired screen
   */
  private openSoldOutModel(
    soldOutModifier: Modifier[],
    item: Item,
    direction: string
  ) {
    let som = SoldOutComponent.open(this.modalService, soldOutModifier, item);

    som.result.then((data: string) => {
      if (data == 'continue') {
        this.navigateToItemPage(item, direction);
      }
    });
  }

  private checkForSoldOutModifier(item: Item): Modifier[] {
    const soldOutMod: Modifier[] = [];
    // Set flow as per General kiosk i.e. 86 based on modifer group not on ingredient
    // const soldOutCheck = async (item: any) => {
    //   if (
    //     item.IsDefault &&
    //     item.IsDefault == 'True' &&
    //     item.IsIngredient86 &&
    //     item.IsIngredient86 == 'False'
    //   ) {
    //     soldOutMod.push(item);
    //   }
    // };

    function modifierCheck(modifier: Modifier) {
      if (modifier && modifier.IsModifier86 == 'False') {
        soldOutMod.push(modifier);
        return modifier;
      }

      return null;
      // Set flow as per General kiosk i.e. 86 based on modifer group not on ingredient
      // if (modifier.Ingredients && modifier.Ingredients.length > 0) {
      //   await CommonFunctions.forEach(modifier.Ingredients, soldOutCheck);
      // }
    }

    if (item.Modifiers) {
      for (var i = 0; i < item.Modifiers.length; i++) {
        var mod = modifierCheck(item.Modifiers[i]);
        if (mod) soldOutMod.push(mod);
      }
    }

    return soldOutMod;
  }

  private async normalItemAddToCart(item: Item) {
    const objCartItem = this.createCartItem(item);

    let upSellItems = await this.getUpSellItems(item.ItemID);
    let itemUpsell = this.cartService.cartItems.find((cartItem) => cartItem.IsUpSellItem && upSellItems.find((upSellItem) => upSellItem.ItemID == cartItem.ItemID));
    if (!itemUpsell && upSellItems.length > 0) {
      this.openUpSellModel(objCartItem);
    } else {
      await this.cartService.addToCartAsync(objCartItem);
      this.openCartOnAddItem();
    }
  }

  private createCartItem(inputItem: Item) {
    const cartItem = JSON.parse(JSON.stringify(inputItem)) as Item;
    cartItem.guid = inputItem.guid ? inputItem.guid : Guid.newGuid();
    cartItem.Modifiers = [];
    cartItem.VariationOption = [];
    cartItem.ComboGroup = [];
    cartItem.AllowQuantitySelection =
      cartItem.AllowQuantitySelection == ''
        ? 'True'
        : cartItem.AllowQuantitySelection;
    cartItem.GuestName = inputItem.GuestName;
    cartItem.Quantity =
      inputItem.Quantity && inputItem.Quantity != undefined
        ? inputItem.Quantity
        : '1';
    cartItem.totalPrice =
      CommonFunctions.getItemPrice(cartItem) * Number(cartItem.Quantity);

    if (
      this.isConcessionaire &&
      this.selectedConcessionaire &&
      Object.entries(this.selectedConcessionaire).length > 0
    ) {
      cartItem.ConcessionaireId = this.selectedConcessionaire.ConcessionaireID;
      cartItem.ConcessionaireName = this.selectedConcessionaire.Name;
      cartItem.ConcessionaireColorCode = this.selectedConcessionaire.ColorCode;
    }

    return cartItem;
  }

  private setOffSet(offSet: number, isAdd: boolean = false) {
    if (isAdd) {
      this.itemOffSet = this.itemOffSet + 50;
    } else {
      this.itemOffSet = offSet;
    }
  }

  changeOpenCart(event: any) {
    this.openCart = event;
  }

  onUserClick() {
    this.customerLoginService.onUserClick(this.loyaltyService.loyaltyType).then((data: number) => {
      if (data) {
        if (data == 1) {
          this.router.navigateByUrl('/lastfiveorder');
        } else if (data == 2) {
          this.getUserName();
        } else {
          //this block should not be reached
        }
      }
      //no else block for top-level if-con
      //where data is falsey, a zero is returned to end execution flow without hanging promise
    }
      //no rejection handler
      //promise resolves 0 on all reject cases
    );
  }
  /**
   * Gets user name
   * @returns
   */
  getUserName() {
    if (this.userService.isUserLoggedIn) {
      this.userName = this.userService.name;
    } else {
      this.userName = this.signInText;
    }
  }

  async onConcessionaireClick(con: Concessionaire) {
    if (
      this.selectedConcessionaire &&
      this.selectedConcessionaire.ConcessionaireID == con.ConcessionaireID
    )
      return;

    if (this.isConClickStoped) return;
    this.onConStop();

    this.selectedConcessionaire = con;

    // To move the list values on based on selection
    const selConList = this.allConcessionaire;
    let index = this.allConcessionaire.length - 1;
    let newindex = this.allConcessionaire.length;
    for (var i = index; i >= 0; i--) {
      if (
        this.allConcessionaire[i].ConcessionaireID !==
        this.selectedConcessionaire.ConcessionaireID
      ) {
        selConList[--newindex] = this.allConcessionaire[i];
      }
    }

    selConList[0] = this.selectedConcessionaire;

    this.allCategory = [];
    this.SetShowingCategoryValues([]);
    this.SetShowingItemValues([]);

    this.allConcessionaire = selConList;

    this.getSelConcessionaireTypes();
    this.getAndSetCategoryDetailOnConClick(this.selectedConcessionaire);
  }

  private async getAndSetCategoryDetailOnConClick(con: Concessionaire) {
    let categories = await this.db.getAllCategoryByConcessionaireId(
      this.selectedConcessionaire.ConcessionaireID,
      this.isItemTagsSelected
    );

    this.allCategory = this.setChildCategories(categories);

    this.setDefaultCategory();

    this.queryConcessionaireId = con.ConcessionaireID;
  }

  private async onPageEndGetRemainingItems() {
    if (
      this.selectedCategory &&
      this.currentItems &&
      this.currentItems.length > 0 &&
      this.currentItems.length % 50 == 0 &&
      !this.isInbotton
    ) {
      this.isInbotton = true;
      this.setOffSet(0, true);

      let items = await this.getItemByCategory(
        this.selectedCategory?.CategoryID ?? '',
        this.selectedConcessionaire?.ConcessionaireID,
        this.isConcessionaire
      );

      this.isInbotton = false;

      this.SetShowingItemValues(this.currentItems.concat(items));
    }
  }

  async onSideCategoryClick(
    category: Category | null | undefined,
    IsSub: boolean = false
  ) {

    setTimeout(() => {
      this.checkScrollCheck = this.checkScroll();
    }, 500);

    if (!category) return;

    this.setOffSet(0);

    //if (IsSub) {
    for (let i = 0; i < this.allCategory.length; i++) {
      let cat = this.allCategory[i];

      if (cat.CategoryID == category.CategoryID) {
        if (!cat.parent || Object.keys(cat.parent).length == 0) {
          this.SetShowingCategoryValues(
            this.allCategory.filter((cat) => cat.ParentCategoryID == '0')
          );

          let items = await this.db.getItemsByCategoryIdFromSql(
            cat.CategoryID,
            this.isConcessionaire,
            this.selectedConcessionaire?.ConcessionaireID,
            false,
            '',
            this.itemOffSet,
            0,
            '',
            this.isItemTagsSelected,
            this.IsHideBarcodeItems
          );

          this.setSelectedCategoryWithItem(cat, items);
        } else {
          this.SetShowingCategoryValues([cat] as Category[]);

          let items = await this.db.getItemsByCategoryIdFromSql(
            cat.CategoryID,
            this.isConcessionaire,
            this.selectedConcessionaire?.ConcessionaireID,
            false,
            '',
            this.itemOffSet,
            0,
            '',
            this.isItemTagsSelected,
            this.IsHideBarcodeItems
          );

          this.setSelectedCategoryWithItem(cat, items);
        }

        break;
      }
    }
    //}
    //else {
    //  let items = await this.db.getItemsByCategoryIdFromSql(category.CategoryID, this.isConcessionaire, this.selectedConcessionaire?.ConcessionaireID);

    //  this.setSelectedCategoryWithItem(category, items);
    //}
  }

  onItemClick(item: Item, event: any) {
    // Checks fixed item quantity limit
    if (this.fixedItemDisc != undefined && this.fixedItemDisc.length > 0) {
      var isFixedItemAvail = this.discountService.isFixedItemAvail(
        this.fixedItemDisc[0],
        item
      );

      if (isFixedItemAvail) {
        return;
      }
    }

    if (item.IsCategory) {
      if (
        !item.ShowasClassicModifierGroup ||
        item.ShowasClassicModifierGroup == 'False'
      ) {
        let category = {} as Category;
        category.CategoryID = item.CategoryID;

        this.onSideCategoryClick(category, true);
        return;
      } else {

        this.navigateToItemPage(item, 'ItemNormalMode');
        return;
      }
    }

    if (GeneralSetting.getParBrinkIntegrationID() != '') {
      if (item.RefItemId == '') {
        let error = this.language
          .getTextElement('missing_ref_item_id')
          .replace('%d', item.Name);
        Snackbar.show(error, 5000);
        return;
      }
    }

    if (item.SoldOut) {
      OutOfStockComponent.open(this.modalService, this.language, 0);
      return;
    }

    this.itemTransferService.snapshotItem(item);
    //Check for is item by weight
    if (item.EnablePricebyWeight && item.EnablePricebyWeight == 'True') {
      if (GeneralSetting.getRaspberryPiIPAddress() == '') {
        let errorModel = new GeneralAlertComponent(
          this.modalService,
          this.language
        );

        let title = this.language.getTextElement('titile_uh_oh');
        let subTitle = this.language.getTextElement('rasperry_pi_not_setup');
        errorModel.open(title, subTitle);
        this.manageScannerInWeightScale(errorModel);
      } else {
        this.scannerService.stopBarcodeScanner();

        let iwm = ItemWeightComponent.open(this.modalService);

        iwm.result.then(
          (weight: string) => {
            if (weight == 'error') {
              let errorModel = new GeneralAlertComponent(
                this.modalService,
                this.language
              );
              let title = this.language.getTextElement('titile_uh_oh');
              let subTitle = this.language.getTextElement(
                'server_communiation_error'
              );
              errorModel.open(title, subTitle);
              this.manageScannerInWeightScale(errorModel);
            } else if (weight == 'cancel') {
              this.startBarcodeScanner();
            } else {
              try {
                this.setWeightAndNavigate(item, weight);
              } catch (e: any) {
                Snackbar.show(e, 3000);
              }
            }
          },
          () => {
            //dismissed instead of close
          }
        );
      }
    } else {
      const direction = CommonFunctions.getItemType(item);

      if (
        item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString()
      ) {
        //path of functions to be called when item is alcoholic
        this.AlcoholicItemChecks(item, direction);
      } else if (direction == 'menu') {
        // Only Normal Item is added directly to cart
        this.normalItemAddToCart(item);
        this.navigateToItemPage(item, direction);
      } else if (direction != 'itemDescription') {
        let soldOutMode = this.checkForSoldOutModifier(item);

        if (soldOutMode.length > 0) {
          if (GeneralSetting.getIsmDoNotAllowAddToCartKiosk() == 'True') {
            OutOfStockComponent.open(this.modalService, this.language, 1);
          } else {
            this.openSoldOutModel(soldOutMode, item, direction);
          }
        } else {
          this.navigateToItemPage(item, direction);
        }
      } else if (direction == 'itemDescription') {
        let resDuplicateItem = this.cartService.getItemById(
          item.ItemID
        ) as ItemV2;
        if (resDuplicateItem.guid) {
          item = resDuplicateItem;
        }
        this.navigateToItemPage(item, direction);
      }
    }
  }

  private openUpSellModel(objItem: ItemV2) {
    const itemTypeStr = CommonFunctions.getItemTypeV2(objItem);
    let itemType: number = 1;

    if (itemTypeStr == 'ItemBuilderMode') {
      itemType = 2;
    }

    let usdm = UpSellDialogComponent.open(
      this.modalService,
      this.language,
      objItem.ItemID,
      1,
      itemType,
      objItem
    );

    usdm.result.then((result: any) => {
      if (result != 'cancel') {
        this.upSellModelResponse(result, objItem);
      } else {
        this.addToCart(objItem);
      }
    });
  }

  private upSellModelResponse(resUpsellItems: ItemV2[], objItem: ItemV2) {
    const upSellItems: ItemV2[] = [];

    for (let upSellItem of resUpsellItems) {
      upSellItem.IsUpSellItem = true;
      upSellItem.UpSellItemID = objItem.ItemID;
      upSellItem.UpSellItemGuid = objItem.guid;
      upSellItem.UpSellItemPrice = CommonFunctions.getItemPrice(objItem);
      upSellItem.totalPrice = CommonFunctions.getItemPrice(upSellItem);

      if (this.isConcessionaire) {
        upSellItem.ConcessionaireId = objItem.ConcessionaireId;
        upSellItem.ConcessionaireName = objItem.ConcessionaireName;
        upSellItem.ConcessionaireColorCode = objItem.ConcessionaireColorCode;
      }

      upSellItems.push(upSellItem);
    }

    this.addToCart(objItem, upSellItems);
  }

  private async addToCart(objItem: ItemV2, upSellItems: ItemV2[] = []) {
    objItem.totalPrice =
      CommonFunctions.getItemPrice(objItem) * Number(objItem.Quantity);

    const resAddToCart = await this.cartService.addToCartAsync(objItem);

    if (!resAddToCart.status) {
      if (resAddToCart.error == 'alcohol') {
        let error = this.language.getTextElement('max_alcohol_limit');
        Snackbar.show(error, 3000);
      } else {
        let error = this.language
          .getTextElement('item_has_max_quantity')
          .replace('%d', objItem.OrderMaximumQuantity.toLocaleString());
        Snackbar.show(error, 2500);
      }

      return;
    } else {
      if (upSellItems.length > 0) {
        for (let i = 0; i < upSellItems.length; i++) {
          upSellItems[i].UpSellItemGuid = objItem.guid;
          this.cartService.addToCartAsync(upSellItems[i]);
        }
      }
      this.openCartOnAddItem();
    }

    return;
  }

  private async getUpSellItems(itemId: string) {
    let items = await this.db.getUpSellItemsByItemId(itemId);

    return items;
  }

  onCategoryScroll() {

    let element = document.getElementById("category-container") as HTMLElement;
    //  this.categoryContainerDiv.nativeElement;

    let scrollTop = element?.scrollTop;
    let clientHeight = element?.clientHeight;
    // console.log(scrollTop, clientHeight);
    if (scrollTop < 51) {

      let scrollCheck = scrollTop - clientHeight;
      if (scrollCheck < 0) {
        this.isScrollArrowUp = false;
        return;
      }
    }

    this.isScrollArrowUp = true;
  }

  checkScroll(): boolean {
    if (!this.itemContainerDiv) {
      return false;
    }

    const element = this.itemContainerDiv.nativeElement;
    if (element) {
      if (
        element.scrollTop >=
        (element.scrollHeight - element.clientHeight) * 0.8
      ) {
        this.isTop = false;
        this.isBottom = true;
      } else if (
        element.scrollTop <=
        (element.scrollHeight - element.clientHeight) * 0.1
      ) {
        this.isTop = true;
        this.isBottom = false;
      } else {
        this.isBottom = false;
        this.isTop = false;
      }

      if (element.clientHeight < element.scrollHeight) {
        return true;
      }
    }
    return false;
  }


  onScroll(event: any) {
    if (
      event.target.scrollTop >=
      (event.target.scrollHeight - event.target.clientHeight) * 0.8
    ) {
      this.isTop = false;
      this.isBottom = true;

      this.onPageEndGetRemainingItems();
    } else if (
      event.target.scrollTop <=
      (event.target.scrollHeight - event.target.clientHeight) * 0.1
    ) {
      this.isTop = true;
      this.isBottom = false;
    } else {
      this.isBottom = false;
      this.isTop = false;
    }
  }



  scrollDownLeft() {
    if (!this.categoryContainerDiv) {
      return;
    }

    let element = this.categoryContainerDiv.nativeElement;
    const smoothScrollTo = (to: number, duration: number) => {
      const start = (element as HTMLElement).scrollTop,
        change = to - start,
        startDate = +new Date();

      // t = current time
      // b = start value
      // c = change in value
      // d = duration
      const easeInOutQuad = (t: any, b: any, c: any, d: any) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        (element as HTMLElement).scrollTop = parseInt(
          easeInOutQuad(currentTime, start, change, duration)
        );
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          (element as HTMLElement).scrollTop = to;
        }
      };
      animateScroll();
    };
    // if (bottom) bottom.scrollIntoView({ behavior: 'smooth' });
    if (this.isScrollArrowUp) {
      smoothScrollTo(0, 360);
    }
    else {
      smoothScrollTo(element?.scrollHeight - element?.clientHeight, 360);
    }


  }

  scrollDown() {
    if (!this.itemContainerDiv) {
      return;
    }

    let element = this.itemContainerDiv.nativeElement;
    const smoothScrollTo = (to: number, duration: number) => {
      const start = (element as HTMLElement).scrollTop,
        change = to - start,
        startDate = +new Date();

      // t = current time
      // b = start value
      // c = change in value
      // d = duration
      const easeInOutQuad = (t: any, b: any, c: any, d: any) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        (element as HTMLElement).scrollTop = parseInt(
          easeInOutQuad(currentTime, start, change, duration)
        );
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          (element as HTMLElement).scrollTop = to;
        }
      };
      animateScroll();
    };
    // if (bottom) bottom.scrollIntoView({ behavior: 'smooth' });
    smoothScrollTo(element?.scrollHeight - element?.clientHeight, 300);
  }

  scrollTop() {
    if (!this.itemContainerDiv) {
      return;
    }

    let element = this.itemContainerDiv.nativeElement;

    const smoothScrollTo = (to: number, duration: number) => {
      const start = (element as HTMLElement).scrollTop,
        change = to - start,
        startDate = +new Date();
      /**
       * @param {any} t current time
       * @param {any} b start value
       * @param {any} c change in value
       * @param {any} d duration
       */
      const easeInOutQuad = (t: any, b: any, c: any, d: any) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        (element as HTMLElement).scrollTop = parseInt(
          easeInOutQuad(currentTime, start, change, duration)
        );
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          (element as HTMLElement).scrollTop = to;
        }
      };
      animateScroll();
    };
    // if (bottom) bottom.scrollIntoView({ behavior: 'smooth' });
    smoothScrollTo(0, 300);
  }

  getCategoryImage(
    category: Category,
    selectedCategory: Category,
    imageBaseUrl: string
  ) {
    let image = '';

    function getDeselectedImage(deCategory: Category) {
      if (
        deCategory.DeselectCategoryIcon &&
        CommonFunctions.checkVariable(deCategory.DeselectCategoryIcon) &&
        deCategory.DeselectCategoryIcon != '' &&
        deCategory.DeselectCategoryIcon.slice(-5) != null &&
        deCategory.DeselectCategoryIcon.slice(-5) != 'null'
      ) {
        return deCategory.DeselectCategoryIcon;
      }

      return '';
    }

    function getSelectedImage(seCategory: Category) {
      if (
        seCategory.CategoryIcon &&
        seCategory.CategoryIcon != '' &&
        CommonFunctions.checkVariable(seCategory.CategoryIcon) &&
        seCategory.CategoryIcon.slice(-5) != null &&
        seCategory.CategoryIcon.slice(-5) != 'null'
      ) {
        return seCategory.CategoryIcon;
      }

      return '';
    }

    let deImage = getDeselectedImage(category);
    let seImage = getSelectedImage(category);

    if (
      selectedCategory &&
      category.CategoryID == selectedCategory.CategoryID
    ) {
      if (seImage != '') {
        image = seImage;
      } else if (deImage != '') {
        image = deImage;
      } else {
        image = '';
      }
    } else {
      if (deImage != '') {
        image = deImage;
      } else if (seImage != '') {
        image = seImage;
      } else {
        image = '';
      }
    }

    return image != '' ? imageBaseUrl + image : 'assets/images/Image_21.png';
  }

  //ToDo Remove This or replace with on event change
  //there is a maximum of three rows
  getContainerStyle(item: Item): Object {
    let count: number;
    let image = document.getElementById(
      item.ImageUrl || ''
    ) as HTMLImageElement;

    if (
      this.selectedCategory &&
      this.selectedCategory.DisplayCatItemRowCount == '1'
    ) {
      count = 22;
    } else if (
      this.selectedCategory &&
      this.selectedCategory.DisplayCatItemRowCount == '2'
    ) {
      count = 18;
    } else {
      count = 14;
    }
    if (image && image.naturalHeight > image.naturalWidth) {
      return {
        height: count + 'vh',
        'line-height': count,
        width: 'auto',
      };
    }
    return { height: count + 'vh', 'line-height': count + 'vh', width: 'auto' };
  }

  //ToDo remove or replace with event change
  //there is a maximum of three rows
  getStyle(item: Item): Object {
    let count: number;

    let image = document.getElementById(
      item.ImageUrl || ''
    ) as HTMLImageElement;
    // console.dir(image);
    if (
      this.selectedCategory &&
      this.selectedCategory.DisplayCatItemRowCount == '1'
    ) {
      count = 22;
    } else if (
      this.selectedCategory &&
      this.selectedCategory.DisplayCatItemRowCount == '2'
    ) {
      count = 18;
    } else {
      count = 14;
    }

    if (image && image.naturalHeight > image.naturalWidth) {
      return {
        // 'min-width': count + 'vh',
        height: count + 'vh',
        width: 'auto',
        'border-radius': this.bordersTypeItem ? this.imageRadius : '0%',
      };
    }

    return {
      width: count + 'vh',
      'border-radius': this.bordersTypeItem ? this.imageRadius : '0%',
    };
  }

  getItemContainerHeight(
    isConcessionaire: boolean = false,
    isCartOpen: boolean = false,
    isHybridMode: boolean = false,
    isFilterApplied: string = '',
    isItemTagsSelected: string = ''
  ) {
    if (isConcessionaire)
      return isCartOpen
        ? isFilterApplied != '' || isItemTagsSelected != ''
          ? '66vh'
          : '69vh'
        : isFilterApplied != '' || isItemTagsSelected != ''
          ? '68vh'
          : '71vh';
    try {
      return isHybridMode
        ? isCartOpen
          ? '67vh'
          : '70vh'
        : isCartOpen
          ? '76vh'
          : '78.5vh';
    } catch (e: any) {
      return '78.5vh';
    }
  }

  getContainerHeight(
    isConcessionaire: boolean = false,
    isCartOpen: boolean = false,
    isHybridMode: boolean = false
  ) {
    if (isConcessionaire) return '71vh';
    try {
      return isHybridMode
        ? isCartOpen
          ? '76.8vh'
          : '79.5vh'
        : isCartOpen
          ? '76.8vh'
          : '79.5vh';
    } catch (e: any) {
      return '79vh';
    }
  }

  private scrollItemGridToTop() {
    if (this.itemsgrid) {
      let element = this.itemsgrid.nativeElement as HTMLElement;

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  /**
   * This method starts the inbuilt scanner
   * @returns
   */
  private startBarcodeScanner(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      window.onmessage = (event: any) => {
        if (event.data.messageType === 'ScannerResult') {
          if (event.data.result && event.data.result != null) {
            this.addScannedItem(event.data.result);
          } else {
            this.scanningError();
          }
        } else {
          var log = new loggingData(
            'Scanner Error',
            'Scanner Encountered Unknown Error ',
            'Error Scanner',
            `Scanner Encountered Some Error`,
            true
          );
          this.logger.sendLogToServer(log);
        }
      };

      this.scannerService.startItemScanner();
    });
  }

  /**
   * Adds / Checks scanned item in the db
   * @param code barcode received after scanning item
   */
  async addScannedItem(code: string) {
    var scannedItem = (await this.db.getItemsByCategoryIdFromSql(
      '0',
      false,
      '0',
      false,
      '0',
      0,
      3,
      code,
      this.isItemTagsSelected
    )) as unknown as Item[];
    var scannedItemVariation = await this.db.getVariationOptionBySkuID(code);
    if (
      scannedItem &&
      scannedItem.length > 0 &&
      scannedItemVariation &&
      scannedItemVariation.length > 0
    ) {
      this.scanningError();
    } else {
      if (scannedItem && scannedItem.length > 0) {
        if (scannedItem[0].ModifierCount && scannedItem[0].ModifierCount > 0) {
          this.scanningError();
        } else {
          this.addScannedBarcodeItem(scannedItem, scannedItemVariation);
        }
      } else {
        if (scannedItemVariation && scannedItemVariation.length > 0) {
          if (scannedItemVariation.length > 1) {
            this.scanningError();
          } else {
            scannedItem = (await this.db.getItemsByCategoryIdFromSql(
              '0',
              false,
              '0',
              true,
              scannedItemVariation[0].ItemID,
              0,
              3,
              code,
              this.isItemTagsSelected
            )) as unknown as Item[];
            this.addScannedBarcodeItem(scannedItem, scannedItemVariation);
          }
        } else {
          this.scanningError();
        }
      }
    }
  }

  /**
   * Adds the item into the cart
   * @param scannedItem Item got from barcode
   * @param scanItemVariation Item's variation got from barcode
   * @returns
   */
  async addScannedBarcodeItem(
    scannedItem: ItemV2[],
    scanItemVariation: VariationOption[]
  ) {
    if (scannedItem.length <= 0) {
      this.scanningError();
      return;
    }

    if (scannedItem.length > 1) {
      scannedItem = [scannedItem[0]];
      //this.scanningError();
    }

    //else {
    if (scanItemVariation != null) {
      scannedItem[0].VariationOption = scanItemVariation;
    }

    let res = await this.cartService.addToCartAsync(
      await this.scannerService.createCartItem(scannedItem[0], this.db)
    );

    if (!res.status) {
      if (res.error == 'alcohol') {
        this.scannerService.stopBarcodeScanner();
        setTimeout(() => {
          this.startBarcodeScanner();
        }, 2500);
        let error = this.language.getTextElement('max_alcohol_limit');
        Snackbar.show(error, 3000);
      } else if (res.error == 'stock') {
        let error = this.language.getTextElement('item_sold_out');
        Snackbar.show(error, 3000);
      }
      return;
    } else {
      this.cartService.doScrollCart.next(true);
      this.timeOutComponent.stillHere();
      if (this.cartService.isItemSelectionOrScan) {
        this.scannedItemAddedPopup();
        this.scannerService.stopBarcodeScanner();
        setTimeout(() => {
          this.scannedItemAddedComponent?.close();
          this.startBarcodeScanner();
        }, 1000);
      } else {
        if (this.itemSelectionmodal) {
          this.itemSelectionmodal.close();
          this.cartService.isItemSelectionOrScan = true;
          this.scannerService.stopBarcodeScanner();
          this.startBarcodeScanner();
        }
      }
      this.openCartOnAddItem();
    }
    //}
  }

  /**
   * Shows the item informative popup of scanner
   */
  private showItemSelectionPopup() {
    if (!this.cartService.isItemSelectionOrScan) {
      let ism = ItemSelectionComponent.open(this.modalService);

      this.itemSelectionmodal = ism.componentInstance;

      ism.result.then((data: string) => {
        if (data == 'continue') {
          this.cartService.isItemSelectionOrScan = true;
        }
      });
    }
  }

  /**
   * Shows popup of item successfully added into the cart
   */
  private scannedItemAddedPopup() {
    this.scannedItemAddedComponent = ScannedItemAddedComponent.open(
      this.modalService
    ).componentInstance;
  }

  setWeightAndNavigate(item: Item, weight: string) {
    // weight = weight.slice(1);
    let weightArray = weight.replace(/ /g, '').match(/[a-z]+|[^a-z]+/gi) ?? '';

    if (weightArray.length > 1) {
      let weightUnitFromServer = weightArray[weightArray.length - 1];
      let weightCountFromServer = weightArray[0];
      // weightCountFromServer = weightCountFromServer.slice(1);

      if (
        weightUnitFromServer.toLowerCase() ==
        CommonFunctions.getWeightUnit(item.WeightUnitID)
      ) {
        item.Weight = Number(weightCountFromServer.trim());
        item.Quantity = weightCountFromServer.trim();
        this.navigateToItemPage(item, 'itemDescription');
      } else {
        let errorModel = new GeneralAlertComponent(
          this.modalService,
          this.language
        );
        let title = this.language.getTextElement('titile_uh_oh');
        let subTitle = this.language
          .getTextElement('change_weight_unit')
          .replace(
            '%d',
            CommonFunctions.getWeightUnitInWords(item.WeightUnitID)
          );
        errorModel.open(title, subTitle);
        this.manageScannerInWeightScale(errorModel);
      }
    } else {
      if (weightArray.length > 0) {
        let errorModel = new GeneralAlertComponent(
          this.modalService,
          this.language
        );
        let title = this.language.getTextElement('titile_uh_oh');
        let subTitle = this.language.getTextElement('exceeded_weight_limit');
        errorModel.open(title, subTitle);
        this.manageScannerInWeightScale(errorModel);
      }
    }
  }
  /**
   * Shows error message found any conflicts while scanning
   */
  private scanningError() {
    this.scannerService.stopBarcodeScanner();
    let errorMessage = this.language.getTextElement(
      'unrecognized_item_scanned'
    );
    let okButtonText = this.language.getTextElement('lbl_ok');
    let oepc = OloErrorPopupComponent.open(
      this.modalService,
      errorMessage,
      '',
      okButtonText
    );

    oepc.result.then(
      (data: string) => {
        if (data == 'close' || data == 'canceled') {
          this.startBarcodeScanner();
        }
      },
      (reject: any) => {
        this.startBarcodeScanner();
      }
    );
  }

  /**
   * added by #nilesh
   * @param isRemovePricingAndPayments Check remove pricing flag
   * @param isHideZeroPrice check hide zero price lable if base price is zero in item
   * @param price item price
   * @returns
   */
  isPriceShow(
    isRemovePricingAndPayments: boolean,
    isHideZeroPrice: boolean,
    price: string
  ) {
    if (!isRemovePricingAndPayments) {
      if (isHideZeroPrice) {
        return Number(price) > 0;
      } else {
        return true;
      }
    } else return false;
  }

  /**
   * Method to open cart while adding item into the cart
   */
  private openCartOnAddItem() {
    this.cartService.openCartOnAddItem(this.cart);
  }

  private manageScannerInWeightScale(errorModel: any) {
    errorModel.ref.result.then(
      (data: string) => {
        if (data != '') {
          this.startBarcodeScanner();
        }
      },
      (reject: any) => {
        this.startBarcodeScanner();
      }
    );
  }

  /**
   * Applies Filter and refreshes concessionaire as per selsected filter
   */
  clickFilter(event: any) {
    var constConTypes = JSON.parse(
      JSON.stringify(this.conService.concessionaireFilterTypes)
    );
    var constSelConTypes = JSON.parse(
      JSON.stringify(this.conService.selectedConcessionaireTypes)
    );
    var constSelItemTags = JSON.parse(
      JSON.stringify(this.conService.selectedItemTags)
    );
    var consItemTagsAndGroupFilters = JSON.parse(
      JSON.stringify(this.conService.itemTagsAndGroupFilters)
    );

    let fcm = FilterConcessionaireComponent.open(this.modalService);

    event.target.disabled = true;

    fcm.result.then(
      (data: string) => {
        event.target.disabled = false;
        if (data == 'apply') {
          this.queryConcessionaireId = '';
          this.getSelConcessionaireTypes();
          this.getAndSetAllConcessionaire();
        } else {
          canceFilter(this.conService);
        }
      },
      (reject: any) => {
        event.target.disabled = false;
        setTimeout(() => {
          canceFilter(this.conService);
        }, 500);
      }
    );

    function canceFilter(conService: ConcessionaireService) {
      conService.setConcessionaireFilterTypes(constConTypes);
      conService.setSelectedConcessionaireTypes(
        constSelConTypes,
        constSelItemTags
      );
      conService.setItemTagsAndGroupFilters(consItemTagsAndGroupFilters);
    }
  }

  /**
   * Selects concessionaire as per filter selection
   */
  getSelConcessionaireTypes() {
    this.isFilterApplied = this.conService.getSelectedConcessionaireTypes(1);
    this.isItemTagsSelected = this.conService.getSelectedConcessionaireTypes(2);
    this.isFilter = this.isFilterApplied != '' || this.isItemTagsSelected != '';
    this.selConcessionaireFilters = this.conService.getSelectedFilterList();

    this.SetAllConcessioaniteValues(this.allConcessionaire);
  }

  /**
   * Updates concessionaire as per selection and deselection of filter
   * @param concessionaireTypeId
   * @param isSelected
   * @param event
   * @param isItemTag
   */
  onConcessionaireTypeSelection(
    concessionaireTypeId: string,
    isSelected: boolean,
    event: Event,
    isItemTag: boolean = false
  ) {
    this.queryConcessionaireId = '';
    this.conService.onConcessionaireTypeSelection(
      concessionaireTypeId,
      isSelected,
      event,
      isItemTag
    );
    this.getSelConcessionaireTypes();
    this.getAndSetAllConcessionaire();
  }

  /**
   * Sets branch header image
   * @param headerImageUrl
   * @param imageBaseUrl
   * @returns
   */
  setHeaderImage(headerImageUrl: string, imageBaseUrl: string) {
    return headerImageUrl && headerImageUrl != ''
      ? imageBaseUrl + headerImageUrl
      : '';
  }

  /**
   * Sets branch logo
   * @param con
   * @param imageBaseUrl
   * @param concessionaireBaseUrl
   * @returns
   */
  getConcessionaireLogo(
    con: Concessionaire,
    imageBaseUrl: string,
    concessionaireBaseUrl: string,
    isSidebarLogo: boolean = false
  ) {
    var conLogoUrl = '';
    if (!isSidebarLogo) {
      conLogoUrl =
        con.Logo && con.Logo != ''
          ? imageBaseUrl + concessionaireBaseUrl + con.Logo
          : '../../../assets/images/Image_21.png';
    } else {
      conLogoUrl =
        con.SidebarLogo && con.SidebarLogo != ''
          ? imageBaseUrl + con.SidebarLogo
          : con.Logo && con.Logo != ''
            ? imageBaseUrl + concessionaireBaseUrl + con.Logo
            : '../../../assets/images/Image_21.png';
    }
    return conLogoUrl;
  }

  /**
   * Sets filter container height based on filter selection
   * @param isFilterAvail
   * @param isItemTagAvail
   * @returns
   */
  getFilterContainerHeight(
    isFilterAvail: string = '',
    isItemTagAvail: string = '',
    conSize: number
  ) {
    try {
      if (isFilterAvail != '' || isItemTagAvail != '') {
        if (conSize > 0 && conSize) {
          return '13vh';
        } else {
          return '6vh';
        }
      } else {
        return '9.4vh';
      }
    } catch (e: any) {
      return '9.4vh';
    }
  }

  /**
   * Opens up branch list from view more click
   */
  onBrandFilter() {
    this.isHideFilter = true;

    let bfm = BrandFilterComponent.open(
      this.modalService,
      this.allConcessionaire,
      this.imageBaseUrl,
      this.concessionaireBaseUrl
    );

    bfm.result.then(
      (data: string) => {
        this.isHideFilter = false;
        if (data != '') {
          this.queryConcessionaireId = data;
          this.setDefaultConcessionaire();
        }
      },
      (reject: any) => {
        this.isHideFilter = false;
      }
    );
  }

  backtoGridScreen() {
    this.conService.clearFilter();
    this.router.navigateByUrl('/ConcessionaireGrid');
  }

  /**
   * Set active card backgrounf
   * @param isFilter Filter applied or not
   * @param selCon selected concessionaire
   * @param con Concessionaire
   * @returns
   */
  getActiveConceptCard(
    isFilter: boolean = false,
    selCon: Concessionaire,
    con: Concessionaire
  ) {
    var isActive =
      selCon.ConcessionaireID === con.ConcessionaireID ? 'active' : '';
    return isFilter
      ? 'brands-block brands-block-sm ' + isActive
      : 'brands-block ' + isActive;
  }

  getCommonImage(
    image: string | undefined,
    imageBaseUrl: string,
    placeholderImage: string
  ) {
    if (
      image &&
      CommonFunctions.checkVariable(image) &&
      image != '' &&
      image.slice(-4) != 'null' &&
      image.slice(-4) != null
    ) {
      return imageBaseUrl + image;
    } else {
      if (placeholderImage != '') {
        return imageBaseUrl + placeholderImage;
      } else return '';
    }
  }

  private isConClickStoped: boolean = false;
  private async onConStop() {
    this.isConClickStoped = true;
    await CommonFunctions.delay(500);

    this.isConClickStoped = false;
  }

}
