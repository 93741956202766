import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AskDeviceNameComponent } from './ask-device-name.component';

@Injectable({
  providedIn: 'root'
})
export class AskDeviceNamePopupService {

  constructor(private modalService:NgbModal) 
  { }

  private AskDeviceNameRef:AskDeviceNameComponent | null = null;

  public open() {
    this.close();

    let modalRef = AskDeviceNameComponent.open(this.modalService);
    modalRef.componentInstance.doit();
    this.AskDeviceNameRef = modalRef.componentInstance;
    return modalRef;
  }

  public close(){
    if(this.AskDeviceNameRef && this.AskDeviceNameRef != null){
      this.AskDeviceNameRef.close();
    }
  }

}
