import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { order } from 'src/app/models/order';
import { LanguageService } from 'src/app/services/language.service';
import { OrderHistoryService } from 'src/app/services/order-history.service';

@Component({
  selector: 'app-settings-gross-sales',
  templateUrl: './settings-gross-sales.component.html',
  styleUrls: ['./settings-gross-sales.component.css'],
})
export class SettingsGrossSalesComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  orderHistoryService: OrderHistoryService;
  grossSalesOrders: order[] = [];
  dt: Date = new Date();


  languageSub!:Subscription
  grossSalesText:string=''
  refOrderNoText:string=''
  invoiceNoText:string=''
  subtotalText:string=''
  taxText:string=''
  tipText:string=''
  upchargeText:string=''
  discountText:string=''
  totalText:string=''
  tokenNoText:string=''
  naText:string=''
  constructor(
    OrderHistoryService: OrderHistoryService,
    private datePipe: DatePipe,
    private language: LanguageService
  ) {
    this.orderHistoryService = OrderHistoryService;
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    )
  }
  loadText(){
    this.grossSalesText=this.language.getTextElement('lbl_reports_gross_sales')
    this.refOrderNoText=this.language.getTextElement('txt_reference_order_no')
    this.invoiceNoText=this.language.getTextElement('txt_invoice_no')
    this.subtotalText=this.language.getTextElement('subtotal')
    this.taxText=this.language.getTextElement('txt_tax')
    this.tipText=this.language.getTextElement('txt_tip')
    this.upchargeText=this.language.getTextElement('txt_upcharge')
    this.discountText=this.language.getTextElement('txt_discount')
    this.totalText=this.language.getTextElement('lbl_total')
    this.tokenNoText=this.language.getTextElement('txt_token_no')
    this.naText=this.language.getTextElement('text_na')
  }
  ngOnInit(): void {
    this.grossSalesReport();
  }

  grossSalesReport() {
    const date = this.datePipe.transform(new Date(), 'MMM d YYYY');

    this.allSubsCription.push(
      this.orderHistoryService
        .getOrderHistory(date!.toString())
        .subscribe((item) => {
          this.grossSalesOrders = item;
        })
    );
  }

  setGrossDate(event: any) {
    this.dt = event;
    const date = this.datePipe.transform(this.dt, 'MMM d YYYY');

    this.allSubsCription.push(
      this.orderHistoryService
        .getOrderHistory(date!.toString())
        .subscribe((item) => {
          this.grossSalesOrders = item;
        })
    );
  }
}
