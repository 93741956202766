import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ItemTransferService } from 'src/app/services/item-transfer.service';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { Variation, VariationOption } from 'src/app/models/Variations';
import {
  ComboGroupV2,
  ItemV2,
  ModifierV2,
} from 'src/app/models/item';
import { environment } from 'src/environments/environment';
import { Category } from 'src/app/models/category';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { CommonFunctions } from 'src/app/services/common';
import { ChooseDonutCustomizePopupComponent } from '../../dialogs/choose-donut-customize-popup/choose-donut-customize-popup.component';
// import { BoxBuilderComponent } from '../box-builder.component';
import { Guid } from 'src/app/models/Guid';
import { CartService } from 'src/app/services/cart.service';
import { BoxBuilderFullComponent } from '../../dialogs/box-builder-full/box-builder-full.component';
import { BoxBuilderReviewComponent } from '../box-builder-review/box-builder-item-review.component';
import { BoxBuilderItemComponent } from '../../box-builder-item/box-builder-item.component';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-box-builder-combo',
  templateUrl: './box-builder-combo.component.html',
  styleUrls: ['./../box-builder.component.css'],
})
export class BoxBuilderComboComponent implements OnInit {
  constructor(
    private readonly itemTransferService: ItemTransferService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly db: DatabaseService,
    private readonly isKioskService: IsKioskService,
    private logger: LogService,
    private readonly modalService: NgbModal,
    private readonly cartService: CartService,
    private readonly dataService: DataService
  ) {
    this.item = null;
    this.modifierGroups = new Array<ModifierV2>();
    this.ItemID = this.route.snapshot.queryParams['id'];
    this.CategoryID = this.route.snapshot.queryParams['itemID'];
    this.guid = this.route.snapshot.queryParams['guid'];
    this.isOrderReviewEdit = this.route.snapshot.queryParams['reviewEdit'];
    this.isLoyalty = this.route.snapshot.queryParams['loyalty'];
    this.parentCategory = this.route.snapshot.queryParams['parentCategory'];
    this.referenceCategory = this.route.snapshot.queryParams['category'];
  }

  @ViewChild(ChooseDonutCustomizePopupComponent, { static: true })
  chooseDonutCustomizePopup!: ChooseDonutCustomizePopupComponent;

  @ViewChild(BoxBuilderReviewComponent)
  boxBuilderReviewModel!: BoxBuilderReviewComponent;

  @Input() modifiers: ModifierV2[] = [];

  @Input() numInvisibleGroups: number = 0;

  @Input() comboGroups: ComboGroupV2[] = [] as ComboGroupV2[];

  @Input() isCombo: boolean = false;

  @Input() selectedItem: ItemV2 = new ItemV2();

  @Input() totalComboGroups: number = 0;

  @Input() selectedTotalCount: number = 0;

  @Input() inputItem: ItemV2 = new ItemV2();

  //ComboGroup  Selection Variables
  @Output() isModifierValid: boolean = false;

  @Output() setVariationsValid: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() emitSelectedVariation: EventEmitter<VariationOption[]> =
    new EventEmitter<VariationOption[]>();

  @Output() isComboChanged = new EventEmitter<boolean>();

  @Output() selectedCountEmitter = new EventEmitter<number>();

  @Output() itemImageListEmitter = new EventEmitter<any[]>();

  @Output() modifierEmitter: EventEmitter<{
    inputModifier: ModifierV2[];
    isValid: boolean;
    modifierSelectedIndex: number;
  }> = new EventEmitter();

  @Output() itemModalClose: EventEmitter<ItemV2> = new EventEmitter();

  @Output() modifierSelectedIndexEmitter: EventEmitter<number> =
    new EventEmitter();

  @Output() openReviewScreenEmitter: EventEmitter<boolean> = new EventEmitter();

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  referenceCategory: any;

  modifierGroups: ModifierV2[] = [] as ModifierV2[];

  ItemID: string;

  CategoryID: string;

  isReady: boolean = false;

  ItemName: string = '';

  currentObjPos: number = 0;

  comboGroupSelectedIndex = 0;

  currentComboGroup: ComboGroupV2 | null = null;

  IsComboGroupSelected = false;

  isVariationSelected = false;

  isModifierSelected = false;

  categoryCards!: HTMLCollection;

  IsComboGroupValid: boolean = false;

  modifierSelectedIndex = 0;

  variations: Variation[] = [] as Variation[];

  comboGroup: ComboGroupV2[] = [] as ComboGroupV2[];

  boxBuilder: any;

  modalReference: any;

  selectedVariation: VariationOption = {} as VariationOption;

  isFirstTimeLoad = true;

  modifierStartingIndex: number = 0;

  item: ItemV2 | null;

  selectedCategory: any;

  categories: any;

  currentObj: any;

  // For Edit Item Feature
  guid: string = '';

  isOrderReviewEdit = false;
  // For Edit Item Feature end
  isLoyalty: string;

  isComboItem = false;

  category: Category = {} as Category;

  quantity: number = 1;

  currentCategory: any;

  branchLogo: string | null = '';

  parentCategory: string;

  selectedCount: number = 0;

  comboItemFlags: any[] = [];

  itemImageList: any[] = [];

  refItem!: ItemV2;

  placeholderImage : string = '';
  headerText:string=''

  @Output() comboItemFlagsEmitter = new EventEmitter<any[]>();
  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.branchLogo = GeneralSetting.getBranchLogo();
    this.headerText=GeneralSetting.getBoxBuilderHeaderText();
    this.categoryCards = document.getElementsByClassName('modGrp-list');
    // this.setDefault();
    this.setComboItems();
  }

  ngOnChanges() {}

  setComboItems(isComboChange: boolean = false) {
    if (this.isCombo) {
      if (this.guid || isComboChange) {
        if (isComboChange) {
          this.selectedCount = 0;

          this.itemImageList = [];
        }

        if (this.guid) {
          this.setDefaultComboItemFlags();
        }

        for (let i = 0; i < this.comboGroups.length; i++) {
          if (!this.comboGroups[i].isHidden) {
            for (let j = 0; j < this.comboGroups[i].Items.length; j++) {
              const comboItem = this.comboGroups[i].Items[j];
              if (comboItem.isSelected) {
                let itemNew = JSON.parse(JSON.stringify(comboItem)) as ItemV2;
                this.updateComboItemFlag(itemNew, true);

                // this.itemImageList.push({ 'image': this.baseImageUrl + comboItem.ImageUrl, 'selected': true });
                this.selectedCount++;
              }
            }
          }
        }
        this.selectedCountEmitter.emit(this.selectedCount);
      } else {
        this.setDefaultComboItemFlags();
        for (let i = 0; i < this.comboGroups.length; i++) {
          if (!this.comboGroups[i].isHidden) {
            this.itemImageList.push({
              image: '../../../assets/images/box-builder/plain.png',
              selected: false,
            });
          }
        }
      }

      this.selectedItemCount();
      // console.log("combo items flag", this.comboItemFlags);
      // this.itemImageListEmitter.emit(this.itemImageList);
      this.comboItemFlagsEmitter.emit(this.comboItemFlags);
    } else {
      // itemImageList
    }
  }
  getImagePath(imageUrl: string | undefined, baseImageUrl: string, placeholderImage: string) {
    if (imageUrl && imageUrl != ''){
      return baseImageUrl + imageUrl;
    } else if(placeholderImage != ''){
      return this.baseImageUrl+placeholderImage;
    } else {
      return 'assets/images/Image_21.png';
    }
  }
  setDefaultComboItemFlags() {
    if (
      this.comboGroups &&
      this.comboGroups.length > 0 &&
      this.comboGroups[0].Items &&
      this.comboGroups[0].Items.length
    ) {
      for (let i = 0; i < this.comboGroups[0].Items.length; i++) {
        const item = this.comboGroups[0].Items[i];
        this.comboItemFlags.push({
          ItemID: item.ItemID,
          selected: false,
          item: {} as ItemV2,
        });
      }
    }
  }
  async getModifierGroups(id: string) {
    await this.db
      .getModifierByItemIDFromSql(id)
      .then(async (itemdata: any) => {
        this.modifierGroups = itemdata;
        this.modifiers =
          this.categories =
          this.modifierGroups =
            await this.db.getAllModifierIngredients(this.modifierGroups);
      })
      .then(() => {
        this.setDefaultModifierGroup();
      });
  }

  async getItemByItemID() {
    await this.db
      .getItemByItemIdFromSql(this.ItemID)
      .then(async (itemdata: any) => {
        this.item = itemdata;
      });
  }

  async initializePage() {}

  cancel() {
    this.router.navigate(['/ItemBoxBuilderMode'], {
      queryParams: { id: this.CategoryID, category: this.parentCategory },
    });
  }

  setDefaultModifierGroup() {
    this.currentObj = this.categories[0];
    // this.currentObj = this.categories[0];
    if (!this.referenceCategory) {
      // this.currentObj = res;
      this.changeCategory(this.currentObj?.CategoryID, this.currentObj);
    }
  }

  changeCategory(id: string, obj: Category) {
    this.changeCategoryID(obj);
    this.changeCategoryObj(obj);
  }

  changeCategoryID(obj: Category) {
    // console.log(obj);
    this.router.navigate([], { queryParams: { category: obj.CategoryID } });
    if (!obj.associatedItems || obj.associatedItems.length == 0) {
      try {
        var log = new loggingData(
          'Empty Category Error',
          'Category With No Items Reached',
          `UI/UX Error in Category ${obj.CategoryID}`,
          `Confirm Category Has Items To Display, or Consider Turning Category Off: ${obj}`,
          true
        );
        this.logger.sendLogToServer(log);
      } catch (e: any) {
        if (e.message.includes('btoa')) {
          var log = new loggingData(
            'Empty Category Logging Error',
            'Category With No Items Reached',
            `UI/UX Error in Category ${obj.CategoryID}`,
            `Error logging \${obj}: ${e.message}`,
            true
          );
          this.logger.sendLogToServer(log);
        } else {
          var log = new loggingData(
            'Empty Category Unknown Error',
            'Category With No Items Reached',
            `UI/UX Error in Category ${obj.CategoryID}`,
            `Unknown error logging \${obj}: ${e}`,
            true
          );
          this.logger.sendLogToServer(log);
        }
      }
    }
    this.selectedCategory = obj.CategoryID;
  }

  changeCategoryObj(id: any) {
    // this.selectedObject = id;
  }

  onComboItemSelect(event: any, item: ItemV2, pos: number) {
    //If box is full

    if (
      this.selectedTotalCount ==
      this.comboGroups.length - this.numInvisibleGroups
    ) {
      if (!this.comboItemFlags[pos].selected && item.Modifiers == undefined) {
        BoxBuilderFullComponent.open(
          this.modalService,
          this.selectedCount
        ).result.then((res: any) => {
          if (res == 'review') {
            // console.log("Go to review");
            this.openReviewScreenEmitter.emit(true);
          }
        });
        if (event.target) {
          event.target.checked = false;
          return;
        }
      } else if (item.ModifierCount && item.ModifierCount > 0) {
        BoxBuilderFullComponent.open(
          this.modalService,
          this.selectedCount
        ).result.then((res: any) => {
          if (res == 'review') {
            // console.log("Go to review");
            this.openReviewScreenEmitter.emit(true);
          }
        });
        if (event.target) {
          event.target.checked = false;
          return;
        }
      }
    }

    let itemNew = JSON.parse(JSON.stringify(item)) as ItemV2;
    if (itemNew.ModifierCount && itemNew.ModifierCount > 0) {
      // this.openCustomBuild(pos);
      this.ItemName = itemNew.ItemName!;
      this.getModifierGroups(itemNew.ItemID);
      this.db.getItemDetails(itemNew).then((data) => {
        this.openItemDetail(data, pos, false);
        this.onItemSelect(pos);
      });
    } else {
      if (event.target) {
        if (!this.comboItemFlags[pos].selected) {
          this.chooseDonutCustomizePopup.open(
            false,
            this.baseImageUrl,
            item,
            this.comboGroups.length - this.numInvisibleGroups,
            this.selectedCount
          );
          this.chooseDonutCustomizePopup.ref.result.then((res: any) => {
            if (res.status) {
              item.guid = Guid.newGuid();
              item.BoxBuilderItemGUID = Guid.newGuid();
              this.setItemQuantitySelection(item, res.Quantity, pos);
            }
          });
        } else {
          //DESELECT ITEM
          item.ItemConsolidateQuantity =
            this.comboItemFlags[pos].item.ItemConsolidateQuantity;
          this.chooseDonutCustomizePopup.open(
            true,
            this.baseImageUrl,
            item,
            this.comboGroups.length - this.numInvisibleGroups,
            this.selectedCount
          );
          this.chooseDonutCustomizePopup.ref.result.then((res: any) => {
            if (res == 'delete') {
              this.deselectItem(item, pos);
            } else if (res.status) {
              this.deselectItem(item, pos);
              this.setItemQuantitySelection(item, res.Quantity, pos);
            }
          });
        }
      }
    }
  }

  private hasItemSelected(comboGroup: ComboGroupV2): boolean {
    var selectedCount = 0;
    for (let i = 0; i < comboGroup.Items.length; i++) {
      const item = comboGroup.Items[i];
      if (item.isSelected) {
        selectedCount++;
      }
    }
    return selectedCount == Number(comboGroup.CountOption);
  }

  private selectedItemCount() {
    this.selectedCount = 0;
    this.itemImageList = [];
    for (let i = 0; i < this.comboGroups.length; i++) {
      if (!this.comboGroups[i].isHidden) {
        const items = this.comboGroups[i].Items;
        for (let j = 0; j < items.length; j++) {
          if (!this.comboGroups[i].isHidden && items[j].isSelected) {
            this.itemImageList.push({
              image: this.baseImageUrl + items[j].ImageUrl,
              selected: true,
            });
            this.selectedCount++;
          }
        }
      }
    }

    var itemImageLength = this.itemImageList.length;
    for (
      let i = 0;
      i < this.comboGroups.length - this.numInvisibleGroups - itemImageLength;
      i++
    ) {
      this.itemImageList.push({
        image: '../../../assets/images/box-builder/plain.png',
        selected: false,
      });
    }
    this.selectedCountEmitter.emit(this.selectedCount);
    this.itemImageListEmitter.emit(this.itemImageList);
    // console.log("selected item count", this.selectedCount);
  }

  private deselectItem(item: ItemV2, itemPos: number) {
    var count = 0;
    var quantity: number =
      this.comboItemFlags[itemPos].item.ItemConsolidateQuantity;
    if (this.comboGroups && this.comboGroups.length > 0) {
      for (let i = 0; i < this.comboGroups.length; i++) {
        let items = this.comboGroups[i].Items;
        if (this.hasItemSelected(this.comboGroups[i])) {
          for (let j = 0; j < items.length; j++) {
            const comboItem = items[j];
            if (comboItem.ItemID == item.ItemID && comboItem.isSelected) {
              count++;
              if (count <= quantity) {
                // console.log("combo group", this.comboGroups[i].GroupName);
                comboItem.isSelected = false;
                comboItem.Quantity = '1';
                comboItem.ItemConsolidateQuantity = 0;
                this.comboGroups[i].Items[j] = comboItem;
                this.updateComboItemFlag(comboItem, false);
                item.ItemConsolidateQuantity = 1;
                // this.comboGroups[0].Items[itemPos] = item;
                break;
              }
            }
          }
        }
      }
    }
    this.selectedItemCount();
  }

  private setItemQuantitySelection(
    item: ItemV2,
    quantity: number,
    itemPos: number
  ) {
    var count = 0;
    if (this.comboGroups && this.comboGroups.length > 0) {
      for (let i = 0; i < this.comboGroups.length; i++) {
        let items = this.comboGroups[i].Items;
        if (this.hasItemSelected(this.comboGroups[i])) {
          continue;
        } else {
          for (let j = 0; j < items.length; j++) {
            const comboItem = items[j];
            if (comboItem.ItemID == item.ItemID) {
              count++;
              if (count <= quantity) {
                // console.log("combo group", this.comboGroups[i].GroupName);
                comboItem.isSelected = true;
                comboItem.Quantity = '1';
                comboItem.Modifiers = item.Modifiers;
                comboItem.guid = item.guid;
                comboItem.BoxBuilderItemGUID = item.BoxBuilderItemGUID;
                comboItem.ItemConsolidateQuantity = quantity;
                this.comboGroups[i].Items[j] = comboItem;

                // this.comboGroups[0].Items[itemPos] = item;
                if (count == quantity) {
                  item.ItemConsolidateQuantity = quantity;
                  // console.log("After selection combogroups:", this.comboGroups);
                  // this.comboGroups[0].Items[itemPos] = comboItem;
                  this.updateComboItemFlag(item, true);
                }
                break;
              }
            }
          }
        }
      }
    }

    this.selectedItemCount();
  }

  // nullifySelections(item:ItemV2){
  //   this.item?.Modifiers =
  // }

  openItemDetail(item: ItemV2, pos: number, isCombo: boolean) {
    this.boxBuilder = new BoxBuilderItemComponent(
      this.itemTransferService,
      this.router,
      this.route,
      this.db,
      this.modalService,
      this.isKioskService,
      this.cartService
    );
    const modalReference = this.boxBuilder.open(
      item,
      isCombo,
      true,
      this.itemImageList,
      this.totalComboGroups,
      this.selectedTotalCount,
      this.numInvisibleGroups
    );

    modalReference.result.then((result: ItemV2 | null) => {
      if (result) {
        if (this.comboGroups && this.comboGroups.length > 0) {
          const resItem = result as ItemV2;
          const quantity = isNaN(resItem.ItemConsolidateQuantity as any)
            ? 1
            : resItem.ItemConsolidateQuantity;

          this.setItemQuantitySelection(resItem, quantity, pos);
        }
      } else {
      }
    });
  }

  onItemSelect(index: number) {
    this.comboGroupSelectedIndex = index;

    this.currentComboGroup = this.comboGroups[index];

    this.IsComboGroupSelected = true;
    this.isVariationSelected = false;
    this.isModifierSelected = false;

    // this.scrollToCard();
    this.checkItemValid();
  }

  async checkItemValid(openNext: boolean = false) {
    // is valid by default
    this.IsComboGroupValid = true;

    if (this.comboGroups && this.comboGroups.length > 0) {
      for (let i = 0; i < this.comboGroups.length; i++) {
        const comboGroup = this.comboGroups[i];

        const groupSelectedItem =
          CommonFunctions.getParentCountItem(comboGroup);

        // min and max data can be not correct to fix them and check
        if (comboGroup.min != 0 && comboGroup.min < 0) {
          comboGroup.min = 0;
        }

        if (comboGroup.max <= 0) {
          comboGroup.max = 99999;
        }

        if (comboGroup.IsSelected) {
          if (comboGroup.Items.length > 0) {
            if (
              groupSelectedItem >= comboGroup.min &&
              groupSelectedItem <= comboGroup.max
            ) {
              comboGroup.isValid = true;
            } else {
              this.IsComboGroupValid = false;
              comboGroup.isValid = false;
            }
          }
        } else {
          if (
            groupSelectedItem >= comboGroup.min &&
            groupSelectedItem <= comboGroup.max
          ) {
            comboGroup.isValid = true;
            comboGroup.isSelected = true;
          } else {
            comboGroup.isSelected = false;
            this.IsComboGroupValid = false;
          }
        }

        //direct to error item page
        if (openNext) {
          if (comboGroup.isValid) {
            if (i == this.comboGroupSelectedIndex) {
              if (this.comboGroupSelectedIndex != this.comboGroups.length - 1) {
                this.onItemSelect(this.comboGroupSelectedIndex + 1);
              } else {
                this.onItemSelect(this.comboGroupSelectedIndex);
              }
            }
          } else {
            this.onItemSelect(i);
            break;
          }
        }
      }
    }
  }
  updateComboItemFlag(comboItem: ItemV2, flag: boolean) {
    for (let i = 0; i < this.comboItemFlags.length; i++) {
      const element = this.comboItemFlags[i];
      if (element.ItemID == comboItem.ItemID) {
        element.selected = flag;

        if (
          element.selected &&
          element.item.Modifiers &&
          element.item.Modifiers.length > 0
        ) {
          comboItem.ItemConsolidateQuantity = this.getCountForCustomItemQty(
            element.item.ItemID
          );
        } else {
          //comboItem.ItemConsolidateQuantity = comboItem.ItemConsolidateQuantity;
        }
        element.item = comboItem;
        //element.item.isSelected = flag;
        this.comboItemFlags[i] = element;
      }
    }
    this.comboItemFlagsEmitter.emit(this.comboItemFlags);
  }

  getCountForCustomItemQty(itemId: string) {
    let comboItems: any = [];
    this.comboGroups.forEach((comboGrp) => {
      let itm = comboGrp.Items.filter(
        (x) => x.isSelected && x.ItemID == itemId
      );
      comboItems = comboItems.concat(itm);
    });
    return comboItems.length;
  }
  addToCart() {
    const item = this.createCartItem();

    this.closeModel(item);
  }

  private createCartItem(): ItemV2 {
    const cartItem = JSON.parse(JSON.stringify(this.item)) as ItemV2;

    cartItem.guid = Guid.newGuid();
    cartItem.Modifiers = this.modifiers;
    cartItem.ComboGroup = [];
    cartItem.Quantity = this.item!.Quantity;
    cartItem.IsCombo = !(
      cartItem.IsCombo.toString() == '0' ||
      cartItem.IsCombo.toString().toLowerCase() == 'false'
    );
    cartItem.AllowQuantitySelection =
      cartItem.AllowQuantitySelection == ''
        ? 'True'
        : cartItem.AllowQuantitySelection;
    cartItem.totalPrice =
      CommonFunctions.getItemPrice(cartItem) * Number(cartItem.Quantity);

    return cartItem;
  }

  closeModel(item: ItemV2) {
    this.itemModalClose.emit(item);
  }

  getBoxHeaderText(): string {
    return GeneralSetting.getBoxBuilderHeaderText();
  }
}
