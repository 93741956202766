<div id="askforname-popup" role="dialog">
  <div class="header">
    <h2 class="text-center">{{whoIsThisForText}}</h2>
  </div>
  <form [formGroup]="nameform" (ngSubmit)="onAddToOrder()" novalidate>
    <div class="info-input align-items-center">
      <div class="col-sm-6 col-sm-offset-3">
        <label>{{customerNameText}}</label>
        <input KeyboardCustom customLength="50" [ngStyle]="{ borderColor: submitted && f.name.errors ? 'red' : '' }" [(ngModel)]="customerNameForthisItem" [readonly]="isKiosk" type="text" required id="cusnameAdd" class="form-control" formControlName="name" placeholder="Name" aria-label="Name" aria-describedby="button-addon2">
        <div *ngIf="submitted && f.name.errors">
          <div *ngIf="f.name.errors.required" class="error">
            <p>{{nameRequiredText}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <button matRipple class="text-center" class="green-btn" [style.background-color]="positiveColor"
        [style.border]="positiveColor" [style.color]="positiveColorText">
        {{addToOrderText}}
      </button>
    </div>
  </form>
</div>