import { from } from 'rxjs';

export interface SqlResponse{
    tx: string,
    results: any
}

export class DatabaseHandler {
  /**
   * class though which all database transactions should be done.
   * This will allow us to move to sqlite3 as quickly as possible when we get
   * that functionality.
   */
  static db = (<any>window).openDatabase(
    'grubbrrKiosk',
    '1.0',
    'grubbrrKiosk',
    5 * Math.pow(10, 7) /*50mb*/
  );

  /**
   *
   * @param {string[]} sqlStrings each sql statement you want to execute in the transaction
   * @param {string[][]} variables each set of variables you want substituted in the sql string of the same index.
   * @param {function[]} callbacks  the callback function that you want to be called for that sql statement
   * @param {function[]} callbacksFail  the callback function that you want to be called for that sql statement
   */
  static executeSqlTransation(
    sqlStrings: String[],
    variables: String[][],
    callbacks: Function[],
    callBacksFail: Function[]
  ): void {
    this.db.transaction(function (tx: any) {
      for (var i = 0; i < sqlStrings.length; i++) {
        tx.executeSql(
          sqlStrings[i].replace(/(\r\n|\n|\r)/gm, ''),
          variables[i],
          callbacks[i],
          callBacksFail[i]
        );
      }
    });
  }

  /**
   *
   * @param {string} sqlStrings the sql statement you want to execute
   * @param {string[]} variables the set of variables you want substituted in the sql string.
   * @param {function} callback  the callback function that you want to be called for that sql statement
   * @param {function[]} callbackFail  the callback function that you want to be called for that sql statement
   */
  static executeSqlStatement(
    sqlString: String,
    variables: String[],
    callback: Function,
    callBackFail: Function
  ): void {
    this.db.transaction(function (tx: any) {
      tx.executeSql(
        sqlString.replace(/(\r\n|\n|\r)/gm, ''),
        variables,
        callback,
        callBackFail
      );
    });
  }

  static executeSqlStatementAsync (
    sqlString: String,
    variables: String[]
  ): Promise<any> {

    return new Promise<SqlResponse>((resolve: any, reject: any) => {
        this.db.transaction(function (tx: any) {
          tx.executeSql(
            sqlString.replace(/(\r\n|\n|\r)/gm, ''),
            variables,
            ((tx: string, results: any) => resolve({tx, results})),
            ((statement: any, error: any) =>{
                console.log(`Database Service , Error: ${statement}`, error);
                reject({statement, error})
            })
          );
        });
      });
  }

  static wipeDatabase() {
    //var callback = function (tx: string, result: any) {
    //};;
    var callbackFail = function (tx: string, result: any) {
      //console.log(result);
    };
    this.executeSqlStatement(
      "SELECT name FROM sqlite_master WHERE type='table' and name not like '__Webkit%'",
      [],
      function (sqlTransaction: any, sqlResultSet: any) {
        var table: any,
          tablesNumber = sqlResultSet.rows.length;
        //console.log('DATABASE RESET MODE ENABLED');
        for (var i = 0; i < tablesNumber; i++) {
          table = sqlResultSet.rows.item(i);
          //console.log('Removing table: ' + table.name);
          sqlTransaction.executeSql('DROP TABLE ' + table.name);
        }
      },
      callbackFail
    );
  }

  static getDataFromQuery<T>(query: any) {
    return from(
      new Promise<any>((resolve: any) => {
        function errorCallback(statement: any, error: any) {
          //console.log('Db response Error error', error, statement);
          resolve([]);
        }
        const getData = (tx: string, results: any) => {
          const data: T = results.rows;
          resolve(data);
        };

        DatabaseHandler.executeSqlStatement(query, [], getData, errorCallback);
      })
    );
  }
}
