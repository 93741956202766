import { Injectable } from '@angular/core';
import { ImageService } from 'src/app/services/image.service';
import { PaymentTypesService } from 'src/app/services/payment-types.service';
import { TimeService } from 'src/app/services/store-time.service';
import { DatabaseService } from 'src/app/services/database.service';
import { loggingData, LogService } from 'src/app/services/log.service';

import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { VerifoneService } from './verifone.service';
import { NcrIntegration } from './NcrIntegration';
import { KeyboardService } from './keyboard.service';
import { PusherService } from './pusher.service';

@Injectable({
  providedIn: 'root'
})
//Handles the log out functions, such as wiping data

export class LogoutService {


  constructor(
    private timeService: TimeService,
    private imageService: ImageService,
    private paymentTypesService: PaymentTypesService,
    private dataBaseService: DatabaseService,
    private logger: LogService,
    private verifoneServiec: VerifoneService,
    private ncrService: NcrIntegration,
    private keyboard:KeyboardService,
    private readonly pusher:PusherService 
  ) {

  }

  ngOnDestroy() {

  }

  wipeData() {
    this.imageService.screenArr = [];
    this.imageService.screentext = '';
    this.imageService.options = [];
    this.timeService.storeTime = [];
    this.paymentTypesService.payments = [];
    this.keyboard.manual=false
    this.dataBaseService.removeCategories();
    this.pusher.channel=null
    this.ncrService.clearNCR();
    let serialNo = GeneralSetting.getSerialNo();
    let wifi = GeneralSetting.getWifi();
    let ipAddress = GeneralSetting.getIpAddress();
    let Mac = GeneralSetting.getMacAddress();

    GeneralSetting.clearSession();

    GeneralSetting.setSerialNo(serialNo);
    GeneralSetting.setMacAddress(Mac);
    GeneralSetting.setIpAddress(ipAddress);
    GeneralSetting.setWifi(wifi);

    var log = new loggingData(
      'Branch Logout',
      'Branch Logout Complete',
      `Logout`,
      `Successful Branch Logout Complete`,
      true
    );
    this.logger.sendLogToServer(log);

    this.verifoneServiec.unRegisterDevice().toPromise().then();
  }

}
