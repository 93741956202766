import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { DatabaseHandler } from 'src/app/DatabaseHandler';
import { LogService } from 'src/app/services/log.service';
import { Category } from '../models/category';
import { formatDate } from '@angular/common';
import { CommonFunctions } from './common';
import { ComboGroupV2, ItemV2, ModifierIngredientV2, ModifierV2 } from '../models/item';

@Injectable({
  providedIn: 'root',
})
export class MenuItemService {
  CategoryID: string = '';
  selectedCategory!: Category;
  currentTime = formatDate(Date.now(), 'HH:mm', 'en-US');
  dayOfWeek: number = new Date().getDay();

  constructor(
    private logger: LogService,
  ) {

  }

  checkComboItemIsAvailableFromSql(itemId: string, check: number) {
    return new Promise<boolean>((resolve: any) => {
      this.currentTime = formatDate(Date.now(), 'HH:mm', 'en-US');
      this.dayOfWeek = new Date().getDay() + 1;
      var checkItemAvailable = `
        SELECT I.ItemID FROM
            Items as I
            CROSS JOIN ItemDayWiseMasters ID
                        ON I.ItemID =ID.ItemID AND ID.DayID='${this.dayOfWeek}'
                        AND ID.StartTime <= '${this.currentTime}' AND ID.EndTime>= '${this.currentTime}'

            AND I.IsShowOnKiosk = 'True'
            AND I.IsActive = 'True' WHERE I.ItemID ='${itemId}'
          `;
      const errorCallback = (statement: any, error: any) => {
        console.log('Menu Item Service , Error checkComboItemIsAvailableFromSql :- ', error);

        resolve(false);
      };

      const checkItemAvailability = (tx: string, results: any) => {

        if (results.rows.length > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      DatabaseHandler.executeSqlStatement(
        checkItemAvailable,
        [],
        checkItemAvailability,
        errorCallback
      );
    });
  }
  /**
   * 
   * @param itemId 
   * @param {-number- check {1 for Ingredient, 2 for Modifier, 3 for Item}
   * @returns 
   */
  checkItemIsAvailableFromSql(itemId: string, check: number) {
    return new Promise<boolean>((resolve: any) => {

      this.dayOfWeek = (new Date()).getDay();
      if (this.dayOfWeek == 0) {
        this.dayOfWeek = 7;
      }

      // this.currentTime = formatDate(Date.now(), 'HH:mm', 'en-US');
      this.currentTime = CommonFunctions.getCurrentTime();

      var checkItemAvailable = "";

      if (check == 1) {
        checkItemAvailable = `
        SELECT IngredientID FROM Ingredients WHERE IsIngredient86 = 'True' AND IngredientID ='${itemId}'`;
      } else if (check == 2) {
        checkItemAvailable = `
        SELECT ModifierID FROM ModifierMasters WHERE IsModifier86 = 'True' AND ModifierID ='${itemId}'`;
      } else {
        checkItemAvailable = `
          SELECT I.ItemID FROM
          Items as I
          CROSS JOIN ItemDayWiseMasters ID
                      ON I.ItemID =ID.ItemID AND ID.DayID='${this.dayOfWeek}'
                      AND ID.StartTime <= '${this.currentTime}' AND ID.EndTime>= '${this.currentTime}' AND ID.IsActive ='True'
                      AND I.IsShowOnKiosk = 'True'
                      AND I.IsActive = 'True' 
          inner join item_category_mappings as cmm on cmm.itemId = I.ItemID
          inner join categorymasters as cm on cm.categoryid = cmm.categoryid and cm.isdeleted = 'False' and  cm.isactive = 'True' and cm.IsShowOnKiosk = 'True'
          inner join CategoryDayWiseMasters CD ON cm.CategoryID = CD.CategoryID AND CD.DayID = '${this.dayOfWeek}'
                      AND CD.StartTime <= '${this.currentTime}'
                      AND CD.EndTime >= '${this.currentTime}'
                      AND CD.IsActive = 'True' WHERE I.ItemID ='${itemId}'`;
      }

      const errorCallback = (statement: any, error: any) => {
        resolve(false);
      };

      const checkItemAvailability = (tx: string, results: any) => {
        if (results.rows.length > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      DatabaseHandler.executeSqlStatement(
        checkItemAvailable,
        [],
        checkItemAvailability,
        errorCallback
      );
    });
  }

  checkBulkItemIsAvailableFromSql(ids: string[], check: number) {
    return new Promise<string[]>((resolve: any) => {
      this.currentTime = formatDate(Date.now(), 'HH:mm', 'en-US');

      this.dayOfWeek = CommonFunctions.getDayOfWeak();

      var checkItemAvailable = "";

      let strId = CommonFunctions.getCommaSepratedStringWithList(ids);

      if (check == 1) {
        checkItemAvailable = `
        SELECT IngredientID FROM Ingredients WHERE IsIngredient86 = 'False' AND IngredientID in (${strId})`;
      } else if (check == 2) {
        checkItemAvailable = `
        SELECT ModifierID FROM ModifierMasters WHERE IsModifier86 = 'False' AND ModifierID in (${strId})`;
      } else {
        checkItemAvailable = `
          SELECT I.ItemID FROM
          Items as I
          CROSS JOIN ItemDayWiseMasters ID
                      ON I.ItemID =ID.ItemID AND ID.DayID='${this.dayOfWeek}'
                      AND ID.StartTime <= '${this.currentTime}' AND ID.EndTime>= '${this.currentTime}'
          AND I.IsShowOnKiosk = 'True'
          AND I.IsActive = 'False' WHERE I.ItemID in (${strId})
      `;
      }

      const errorCallback = (statement: any, error: any) => {
        console.log('Menu Item Service , Error checkItemIsAvailableFromSql :- ', error);

        resolve([] as string[]);
      };
      const checkItemAvailability = (tx: string, results: any) => {
        resolve(Array.from(results.rows) as string[]);
      };
      DatabaseHandler.executeSqlStatement(
        checkItemAvailable,
        [],
        checkItemAvailability,
        errorCallback
      );
    });
  }

  async getComboItem86ByItem(comboGroups: ComboGroupV2[]) {

    let listOfSelected = CommonFunctions.getSelectedItemFromComboGroups(comboGroups, true);

    let selectedItems: ItemV2[] = [];
    let selectedModifiers: ModifierV2[] = [];
    let selectedIngridient: ModifierIngredientV2[] = [];

    if (listOfSelected.length > 0) {

      selectedItems = listOfSelected[0] as ItemV2[];
      selectedModifiers = listOfSelected[1] as ModifierV2[];
      selectedIngridient = listOfSelected[2] as ModifierIngredientV2[];
    }

    let allItemIds = selectedItems.map(x => x.ItemID);

    var unavalableItems = await this.checkBulkItemIsAvailableFromSql(allItemIds, 0);

    if (unavalableItems.length > 0) {
      return true;
    }

    let allModifierId = Array.from(new Set(selectedModifiers.map(x => x.ModifierID)));

    let listModifier86Detail = await this.checkBulkItemIsAvailableFromSql(allModifierId, 1);

    if (listModifier86Detail.length > 0) {
      return true;
    }

    let allIngredientId = Array.from(new Set(selectedIngridient.map(x => x.IngredientID)));

    let listIngredient86Detail = await this.checkBulkItemIsAvailableFromSql(allIngredientId, 2);

    if (listIngredient86Detail.length > 0) {
      return true;
    }

    return false;
  }

  async get86StatusByItem(cartItems: ItemV2[]) {
    let unavailableItems: ItemV2[] = [];

    let isModifierFound = false;

    for (let i = 0; i < cartItems.length; i++) {

      if (cartItems[i].IsCombo) {
        if (cartItems[i].ComboGroup && cartItems[i].ComboGroup.length > 0) {
          let comboStatus = await this.getComboItem86ByItem(cartItems[i].ComboGroup);
          if (comboStatus) {
            unavailableItems.push(cartItems[i]);
          }
        }
      }
      else {
        isModifierFound = false;
        if (cartItems[i]?.IsItem86 == 'False' || cartItems[i]?.SoldOut) {
          unavailableItems.push(cartItems[i]);
          isModifierFound = true;
        } else if (cartItems[i].Modifiers && cartItems[i].Modifiers.length > 0) {
          let itemModifiers = CommonFunctions.getSelectedModifiersWithIngredient(cartItems[i].Modifiers);

          if (itemModifiers[0] && (itemModifiers[0] as ModifierV2[]).length > 0) {
            let listModifier = itemModifiers[0] as ModifierV2[];

            let isModifierAvailable = (await this.checkBulkItemIsAvailableFromSql(listModifier.map(x => x.ModifierID), 2)).map((x: any) => x.ModifierID);

            if (isModifierAvailable && isModifierAvailable.length > 0) {
              unavailableItems.push(cartItems[i]);
              isModifierFound = true;
            }
          }

          if (!isModifierFound) {
            if (itemModifiers[1] && (itemModifiers[1] as ModifierIngredientV2[]).length > 0) {
              let listIngredient = itemModifiers[1] as ModifierIngredientV2[];

              let isIngredientAvailable = (await this.checkBulkItemIsAvailableFromSql(listIngredient.map(x => x.IngredientID), 1)).map((x: any) => x.IngredientID);

              if (isIngredientAvailable && isIngredientAvailable.length > 0) {

                if (unavailableItems[unavailableItems.length - 1] != cartItems[i]) {
                  unavailableItems.push(cartItems[i]);
                  break;
                }
              }
            }
          }
        }
      }
    }

    return unavailableItems;
  }

}
