 <!-- <div role = "document">Try to show</div> -->
 <div id="custominfo-popup" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="canceled()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="header">
    <h2 class="text-center">{{ orderCustomerNameInfoHeader }}</h2>
  </div>
  <div class="middle-image">
    <img src="assets/images/phonecall.png" 
        class="img-responsive"
        onerror="this.onerror=null; this.src='assets/images/phonecall.png'" />
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
    <div class="info-input form-group align-items-center">
      <div class="">
        <div *ngIf="customName === 'True'" class="inputbox">
          <label for="yourname">{{ orderCustomerNameInfoHint }}</label>
          <input
            #nameforkeyboard
            KeyboardCustom
            [readonly]="isKiosk"
            [ngStyle]="{ borderColor: isInvalid && !name?.valid ? 'red' : '' }"
            required
            id="name"
            class="form-control"
            name="yourname"
            formControlName="name"
            [formControl]="$any(name)"
            maxlength="28"
          />
          <div class="error" *ngIf="!name?.valid && isInvalid">
            <div [hidden]="!name?.errors?.required">
              <p>{{ nameRequiredText }}</p>
            </div>
            <div [hidden]="!(!name?.errors?.required)">
              <p>{{ invalidNameText }}</p>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="customPhone === 'True'" class="inputbox"> -->
        <!-- <div class="inputbox">
            <label for="mobileNumber">{{ orderCustomerPhoneInfoHint }}</label>
            <input KeyboardCustom [readonly]="isKiosk" [ngStyle]="{
                borderColor: isInvalid && !name?.valid ? 'red' : ''
              }" required type="tel" class="form-control" formControlName="mobileNumber" name="mobileNumber"
              id="mobileNumber" placeholder="{{ placeHolderPhoneInfoHint }}" [ngStyle]="{
                borderColor: isInvalid && !mobileNumber?.valid ? 'red' : ''
              }" mask="0000000000" [showMaskTyped]="false" [validation]="false" />
          </div> -->
        <div *ngIf="customPhone === 'True'" class="inputbox">
          <div class="inputbox">
            <label for="mobileNumber">{{ orderCustomerPhoneInfoHint }}</label>
            <input
              #mobileNumberforkeyboard
              KeyboardCustom
              [readonly]="isKiosk"
              layout="numeric"
              type="tel"
              class="form-control"
              formControlName="mobileNumber"
              name="mobileNumber"
              id="mobileNumber"
              [ngStyle]="{
                borderColor: isInvalid && !mobileNumber?.valid ? 'red' : ''
              }"
              mask={{mask}}
              [showMaskTyped]="false"
              [validation]="false"
            />
          </div>
        </div>
        <div class="error" *ngIf="!mobileNumber?.valid && isInvalid">
          <div [hidden]="!mobileNumber?.errors?.required">
            <p>{{ phoneNumberRequiredText }}</p>
          </div>

          <div
            [hidden]="
              mobileNumber?.value.length == 0 ||
              !(
                mobileNumber?.errors?.pattern ||
                !mobileNumber?.errors?.minLength
              )
            "
          >
            <p>{{ invalidPhoneText }}</p>

          </div>
        </div>
        <div *ngIf="customEmail === 'True'" class="inputbox">
          <label for="email">{{ orderCustomerEmailInfoHint }}</label>
          <input
            #emailforkeyboard
            KeyboardCustom
            [readonly]="isKiosk"
            id="email"
            formControlName="email"
            [formControl]="$any(email)"
            name="email"
            type="text"
            class="form-control"
            [ngStyle]="{
              borderColor: isInvalid && !email?.valid ? 'red' : ''
            }"
          />
          <div class="error" *ngIf="!email?.valid && isInvalid">

            <div [hidden]="!email?.errors?.required">
              <p>{{ emailRequiredText }}</p>
            </div>
            
            <div [hidden]="!email?.errors?.pattern">
              <p>{{ invalidEmailText }}</p>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
    <!-- <app-optin-marketing *ngIf="isAllowForMarketingCommunication"></app-optin-marketing> -->
    <div class="footer" *ngIf="showSubmit">
      <button
        type="submit"
        class="background-primary-color positive-button-text-color bold green-btn border-primary-color"
      >
        {{ submitText }}
      </button>
    </div>
  </form>
</div>

<!--email is not compulsary required code
  [ngStyle]="{ borderColor: isInvalid && !email?.valid ? 'red' : '' }" 
-->
