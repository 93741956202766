import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-usconnect-error',
  templateUrl: './usconnect-error.component.html',
  styleUrls: ['./usconnect-error.component.css']
})
export class UsconnectErrorComponent implements OnInit,OnDestroy {

  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  modalReference: any;

  error: string = '';

  checkoutText: string = ''

  paymentErrorText: string = ''

  languageSub!: Subscription;

  constructor(private modalService: NgbModal, private language:LanguageService) {

  }

  loadText() {
    this.paymentErrorText = this.language.getTextElement('txt_payment_error');
    this.checkoutText = this.language.getTextElement('lbl_checkout');
  }

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    })
  }

  public static open(modelService: NgbModal, error: string) {
    let modalReference = modelService.open(UsconnectErrorComponent, {
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.error = error;

    return modalReference;
  }

  close() {
    this.modalService.dismissAll();
  }
}
