import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-box-builder-full',
  templateUrl: './box-builder-full.component.html',
  styleUrls: ['../../box-builder/box-builder.component.css'],
})
export class BoxBuilderFullComponent implements OnInit {
  
  ngOnDestroy() {
    if (this.allSubscription.length > 0) {
      for (let i = 0; i < this.allSubscription.length; i++) {
        this.allSubscription[i].unsubscribe();
      }
    }
  }

  ref: any;

  allSubscription: Subscription[] = [];

  selectedCount!: number;

  reviewBoxText:string=''
  alreadySelectedText:string=''
  donutsText:string=''
  
  constructor(
    private readonly modalService: NgbModal,
    private language: LanguageService
  ) {
    
  }

  ngOnInit(): void {
    this.allSubscription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }

  loadText(){
    this.reviewBoxText=this.language.getTextElement('review_your_box')
    this.alreadySelectedText=this.language.getTextElement('already_selected')
    this.donutsText=this.language.getTextElement('add_remaining_b')
  }

  public static open(modalService:NgbModal, selectedCount: number) {
    var ref = modalService.open(BoxBuilderFullComponent, {
      centered: true,
      modalDialogClass: 'cd-pop',
    });
    ref.componentInstance.selectedCount = selectedCount;
    ref.componentInstance.ref = ref;
    return ref;
  } 

  openReviewScreen() {
    this.ref.close('review');
  }

  close() {
    this.ref.close();
  }
}
