import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Subject } from "rxjs";
import { LoyaltyType } from "src/app/models/loyaltyModal";
import { CartService } from "src/app/services/cart.service";
import { GeneralSetting } from "src/app/services/generalSetting.service";
import { LoyaltyService } from "src/app/services/loyalty.service";
import { UserService } from "src/app/services/user.service";
import { CustomerWelcomeModalComponent } from "../customer-welcome-modal/customer-welcome-modal.component";
import { CustomerLoginPopupComponent } from "./customer-login-popup.component";


@Injectable({
    providedIn: 'root',
  })
export class CustomerLoginModalService {
    keyboardStatus = new BehaviorSubject(false);
    returningpopuprequired = new BehaviorSubject(false);
    loyaltyType:number=0
    constructor(
      private readonly modalService: NgbModal,
      private userService:UserService,
      private loyalty:LoyaltyService,
      private readonly cartService: CartService,
    ) {
        this.loyaltyType=loyalty.loyaltyType
    }



    /**
    *@description handles all user sign in related logic for order review, menu and concessionaire grid
    * @returns 0 ---> do nothing, end execution flow
    * @returns 1 ---> route to last5
    * @returns 2 ---> get user name
    * @returns 3 ---> signout
    */
  onUserClick(loyaltyType:number) {
    this.loyaltyType=loyaltyType
    return new Promise<number>((resolve) => {
      GeneralSetting.setFromMenuLogin('true');
      if(this.userService.isUserLoggedIn){
        if(this.loyaltyType==LoyaltyType.OLOPunchh||this.loyaltyType==LoyaltyType.OLOPaytronix||this.loyaltyType==LoyaltyType.LevelUp){
          let cwm=CustomerWelcomeModalComponent.open(this.modalService,false,this.userService.name);
          cwm.result.then(
            (data) => {
              if (data == 'signout') {
                let items = this.cartService.getCartItems();
                for (let i = 0; i < items.length; i++) {
                  if (items[i].IsLoyaltyDiscountItem) {
                    this.cartService.removeFromCart(items[i]);
                    if (this.loyaltyType == LoyaltyType.OLOPunchh) {
                      this.loyalty.removeOLOPunchhReward().then(
                        (data) => {
                          this.loyalty.punchhAppliedRewardID = '';
                          this.loyalty.oloPaytronixAppliedRewardID = '';
                          this.loyalty.selectedLoyaltyReward = '';
                          this.loyalty.subSelectedLoyaltyReward.next('');
                          resolve(3);
                        },
                        () => {
                          resolve(3);
                        })
                    } else {
                      // no preceeding if condition
                      // if not OLOPunchh and a loyalty item in cart, loyalty type is OLOPaytronix
                      // levelUp does not have loyalty items
                      this.loyalty.removeOLOPaytronixReward().then(
                        (data) => {
                          this.loyalty.oloPaytronixAppliedRewardID = '';
                          this.loyalty.selectedLoyaltyReward = '';
                          this.loyalty.subSelectedLoyaltyReward.next('');
                          resolve(3);
                        },
                        () => {
                          resolve(3);
                        }
                      );
                    }
                  }
                }
                this.cartService.reward = 0;
                this.cartService.broadcastAll();
                resolve(3);
              } else {
                resolve(0);
              }
            },
            () => {
              resolve(0);
            }
            )
        } else {
          resolve(1);
        }
      } else {
        let clp = CustomerLoginPopupComponent.open(this.modalService);
        this.keyboardStatus.next(true);
        clp.componentInstance.handleOpenSideEffects()
        clp.result.then(
          (data) => {
            resolve(2);
          },
          () => {
            resolve(0);
          }
        );
      }
    });
  }
}  



















