import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { environment } from '../../../../environments/environment';
import { fadeInGrow } from '../../../animations';
import { AlcoholicItemsExceedMaxComponent } from 'src/app/components/dialogs/alcoholic-items-exceed-max/alcoholic-items-exceed-max.component';
import { OrderReviewPromotionsComponent } from 'src/app/components/order-review/order-review-promotions/order-review-promotions.component';
import { Subject, Subscription } from 'rxjs';

import { OLOService } from 'src/app/services/olo.service';
import {
  CommonFunctions,
  CategorySalesType,
  Snackbar,
} from 'src/app/services/common';
import { GeneralSetting } from '../../../services/generalSetting.service';
import { ItemV2, ModifierV2, ModifierIngredientV2 } from 'src/app/models/item';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { OloPunchhRewardInvalidationPopupComponent } from 'src/app/components/dialogs/olo-punchh-reward-invalidation-popup/olo-punchh-reward-invalidation-popup.component';
import { DatabaseService } from 'src/app/services/database.service';
import { UserService } from 'src/app/services/user.service';
import { ParBrinkService } from 'src/app/services/par-brink.service';
import {
  Integration,
  IntegrationService,
} from 'src/app/services/integration.service';
import { LanguageService } from 'src/app/services/language.service';
import { debounceTime } from 'rxjs/operators';
import { LoyaltyType } from 'src/app/models/loyaltyModal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OracleService } from 'src/app/services/oracle.service';
import { DataService } from 'src/app/services/data.service';
import { DiscountService } from 'src/app/services/discount.service';
import { FixedItemDiscount } from 'src/app/models/discounts';
import { OrderreviewService } from '../orderreview.service';
import { SMBDiscountsComponent } from '../smb-discounts/smb-discounts.component';
import { ALERT_TYPE } from 'src/app/resources/strings-en';
import { DiscountConfirmationComponent } from '../../dialogs/discount-confirmation/discount-confirmation.component';
@Component({
  selector: 'app-orderreviewitem-list',
  templateUrl: './orderreviewitem-list.component.html',
  styleUrls: ['./orderreviewitem-list.component.css'],
  animations: [fadeInGrow],
})
export class OrderreviewitemListComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  @Input() cartItems: ItemV2[] = [];

  @Input() ConcessionaireId: string = '';

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  showCalorie: any;

  @Input() sibling: any;

  @Input() promo!: OrderReviewPromotionsComponent;

  bkgdColor: string = '';

  priceColor: string | null = '';

  textColor: string = '';

  branchLogo: string = '';

  isTaxHidden: boolean = false;

  showNegativeMods: boolean = true;
  accentColor: string = '';
  bordersTypeItem: boolean = false;
  imageRadius: string = '';

  isOLO: boolean = false;

  isConcessionaire: boolean = false;

  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();

  isTaxIncluded: boolean = false;

  taxType: string = '';
  languageSub!: Subscription;
  editRewardText: string = '';
  editUpsellText: string = '';

  @Output() setScrollArrow = new EventEmitter<any>();

  cartItemChanges: Subject<string> = new Subject<string>();

  conDiscount: number = 0;

  conSubTotal: number = 0;

  placeholderImage: string = '';

  calorieText: string = '';

  fixedItemDisc: FixedItemDiscount[] = [] as FixedItemDiscount[];

  @Input() smbdiscount!: SMBDiscountsComponent;

  constructor(
    private readonly cartService: CartService,
    private readonly router: Router,
    private readonly isKioskService: IsKioskService,
    private readonly olo: OLOService,
    private loyaltyService: LoyaltyService,
    private readonly cdr: ChangeDetectorRef,
    private database: DatabaseService,
    private user: UserService,
    private parBrink: ParBrinkService,
    private language: LanguageService,
    private modalService: NgbModal,
    private integration: IntegrationService,
    private oracle: OracleService,
    private readonly dataService: DataService,
    private discountService: DiscountService,
    private or: OrderreviewService,
    private parbrink: ParBrinkService
  ) {}

  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );

    this.calorieText = this.language.getTextElement('calorie_format_text');
    this.showCalorie = GeneralSetting.getShowCalorieCounts();
    this.bkgdColor = GeneralSetting.getTextColor();
    this.textColor = GeneralSetting.getTextColor();
    this.branchLogo = GeneralSetting.getBranchLogo();
    this.accentColor = GeneralSetting.getAccentColor();
    this.isTaxHidden = GeneralSetting.getHideItemTaxPrice() == 'True';
    this.showNegativeMods = GeneralSetting.getShowNegativeModifiers() == 'True';
    this.priceColor = GeneralSetting.getPriceColor();
    this.isOLO = this.olo.isOLO;
    this.isConcessionaire =
      GeneralSetting.getIsConcessionaire().toLowerCase() == 'true';
    this.imageRadius = GeneralSetting.getBorderPercentage();
    this.bordersTypeItem =
      GeneralSetting.getBorderTypeItems().toLowerCase() == 'true';

    this.addSubscription();

    this.taxType = GeneralSetting.getTaxType().toLowerCase();

    this.setFixedItemDisc();
  }

  async setFixedItemDisc() {
    this.fixedItemDisc = await this.discountService.getFixedItemDisc();
  }

  loadText() {
    this.editRewardText = this.language.getTextElement('lbl_loyalty_item');
    this.editUpsellText = this.language.getTextElement('lbl_upsell_item');
  }

  getDiscountedPrice(item: any): number {
    return item.Price - item.DiscountAmountForCalulation;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setScrollArrow.emit(null);
    }, 1000);
  }

  private getSubtotalForConcessionaire() {
    let items = this.cartService.getCartItems();

    let conItems = items.filter(
      (x) => x.ConcessionaireId == this.ConcessionaireId
    );

    this.conSubTotal = CommonFunctions.getSubTotalByItems(conItems);
  }

  private addSubscription() {
    if (this.cartItemChanges.observers.length == 0) {
      this.allSubsCription.push(
        this.cartItemChanges.pipe(debounceTime(500)).subscribe((data) => {
          if (this.sibling.or.ncrIntegration.isNcrIntegration) {
            this.sibling.doNcrValidationBackground();
          }
        })
      );
    }

    this.allSubsCription.push(
      this.cartService.removeItemSub.subscribe((item) => {
        if (item && Object.entries(item).length > 0) {
          if (this.isConcessionaire) {
            if (item.ConcessionaireId == this.ConcessionaireId) {
              this.remove(item);
            }
          } else {
            this.remove(item);
          }
        }
      })
    );

    this.allSubsCription.push(
      this.cartService.conDetailSub.subscribe((data: any) => {
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].concessionaireId == this.ConcessionaireId) {
              this.conDiscount = data[i].Discount;
              this.conSubTotal =
                Number(data[i].SubTotal) + Number(data[i].TotalTax);
            }
          }
        }
      })
    );

    this.allSubsCription.push(
      this.cartService.sub_subtotal.subscribe((val) => {
        this.updateFixedItemOnSubtotal(val);
      })
    );
  }

  getFormattedUrl(item: any): string {
    //open items are line item rewards from punchh
    //that use a default image in the assets
    if (item.openItem) {
      return item.ImageUrl;
    } else {
      if (item.ImageUrl && item.ImageUrl != '') {
        return this.baseImageUrl + item.ImageUrl;
      } else {
        if (this.placeholderImage != '') {
          return this.baseImageUrl + this.placeholderImage;
        } else return 'assets/images/Image_21.png';
      }
    }
  }

  editItemV2(item: ItemV2): boolean {
    //if (item.IsLoyaltyDiscountItem) return false;
    if (this.sibling.awaitingLevelUpProposal) {
      return false;
    }
    if (this.oracle.isOracle) {
      this.oracle.calculationResponse = null;
    }
    if (GeneralSetting.getParBrinkIntegrationID() != '') {
      this.parBrink.edited = true;
    }
    const direction: string = CommonFunctions.getItemType(item, true);
    if (direction == 'menu') {
      return false;
    }

    if (!CommonFunctions.isItemHasModifier(item)) {
      if (item.AllowQuantitySelection.toLowerCase() != 'true') {
        return false;
      }
    }

    this.router.navigate([`/${direction}`], {
      queryParams: {
        id: item.ItemID,
        category: item.CategoryID,
        guid: item.guid,
        isFromReview: true,
        concessionaireId: item.ConcessionaireId,
      },
    });

    return false;
  }

  //getCartItems() {
  //  this.subCartItems = this.cartService.subCartItems.subscribe((cartItems) => {
  //    this.cartItems = cartItems;
  //  });
  //}

  /**
   * @description removes item from cart
   * @param {-{ItemV2} item to be removed from cart}
   */
  remove(item: ItemV2) {
    let totalDiscountAmount: number = this.getTotaldiscountAmount();
    let cartItemsTemp = this.cartService.getCartItems();
    let cartItemTempQty = item.Quantity;
    item.Quantity = (0).toString();
    let subTotal = this.calculateSubtotalTemp(cartItemsTemp, false);
    item.Quantity = cartItemTempQty;
    if (this.cartService.selectedDiscount && totalDiscountAmount >= subTotal) {
      let dcc = DiscountConfirmationComponent.open(
        this.modalService,
        this.language.getTextElement('discount_exced_total'),
        this.language.getTextElement('txt_yes'),
        this.language.getTextElement('txt_no'),
        ALERT_TYPE.DISCOUNT_NOT_APPLY
      );
      dcc.result.then((res: any) => {
        if (res == 'Yes') {
          this.smbdiscount.selectDiscountIter3();
          this.performRemove(item);
        }
      });
    } else {
      this.performRemove(item);
    }
  }

  performRemove(item: ItemV2) {
    if (this.oracle.isOracle) {
      this.oracle.calculationResponse = null;
    }
    if (item.IsLoyaltyDiscountItem) {
      this.loyaltyService.selectedLoyaltyReward = '';
      this.loyaltyService.subSelectedLoyaltyReward.next('');
    }
    this.cartService.reward = 0;
    this.cartService.sub_reward.next(0);
    this.cartService.removeFromCart(item);
    this.cartItems = this.cartService.getCartItems();
    this.or.getAllRelatedCustomeMessage();
    if (GeneralSetting.getIntegrationId() == Integration[1]) {
      this.parBrink.edited = true;
    }
    if (this.promo) {
      this.promo.items = this.cartService.getCartItems();
    }
    this.sibling.calculatePrices();
    // Check for discount is applicable or not @nilesh
    if (
      this.promo &&
      this.promo.selectedItemPromo &&
      this.promo.selectedItemPromo.RegularOrBogoDiscount == '1'
    ) {
      this.promo.applyBogo(this.promo.selectedItemPromo, true);
    } else if (
      this.promo &&
      this.promo.selectedItemPromo &&
      this.promo.selectedItemPromo.RegularOrBogoDiscount == '0' &&
      !this.promo.isItemDiscountApply(this.promo.selectedItemPromo)
    ) {
      this.promo.selectDiscount(this.promo.selectedItemPromo, false);
    }

    if (this.cartService.isEmpty()) {
      this.sibling.removeDiscount();

      if (this.isConcessionaire) {
        this.router.navigateByUrl('/ConcessionaireGrid');
      } else {
        this.router.navigateByUrl('/menu');
      }
    } else if (this.cartItems.length == 1) {
      if (this.cartItems[0].IsLoyaltyDiscountItem) {
        this.remove(this.cartItems[0]);
      }
    }
    if (
      this.loyaltyService.loyaltyType == LoyaltyType.LevelUp &&
      this.user.isUserLoggedIn
    ) {
      this.loyaltyService
        .proposeLevelUpOrder(this.cartService.getCartItems())
        .then(
          (data: any) => {
            if (data.statusCode == '400' && data.status == 'error') {
              //Problem in LevelUp Proposed Order - case 1: bad data sent
            } else if (data.statusCode == '200' && data.status == 'success') {
              //successful response from levelup -
              this.loyaltyService.levelUpObject.proposal =
                data.data.proposed_order;
              this.cartService.reward =
                this.loyaltyService.levelUpObject.proposal!.discount_amount;
              this.cartService.broadcastAll();
            }
          },
          () => {}
        );
    }
    /**
     * notify cart list change with debounce for NCR call in background @nilesh
     */
    this.notifyCartItemChangeDebounce();

    this.setScrollArrow.emit(null);
  }
  /**
   * @description performs necesarry checks to determine if item quantity can be increased and increases quanitity if so
   * @implements {checkAlkolLimit( ) if item is alcoholic
   * @param {-{number} index -the index of the item to increase quantity of, passed to helper functions
   * @todo strengthen code by separating into helper functions, and individual checks. Should be only function calls and conditional logic
   */
  increaseQuantity(index: number) {
    //this.performIncrese(index);

    this.checkForOrderLeveDiscountApplicable(true, index);
  }

  performIncreasesQty(index: number) {
    if (this.oracle.isOracle) {
      this.oracle.calculationResponse = null;
    }
    if (GeneralSetting.getParBrinkIntegrationID() != '') {
      this.parBrink.edited = true;
    }
    const cartItem = this.cartItems[index];

    // Checks fixed item quantity limit
    if (this.fixedItemDisc != undefined && this.fixedItemDisc.length > 0) {
      var isFixedItemAvail = this.discountService.isFixedItemAvail(
        this.fixedItemDisc[0],
        cartItem
      );
      if (isFixedItemAvail) {
        return;
      }
    }

    const value = Number(cartItem.Quantity);
    let maximumQuantity = cartItem.OrderMaximumQuantity;
    const alkolMax = GeneralSetting.getMaxAlcoholicItemsPerOrder();
    var isCombo = cartItem.IsCombo.toString();
    this.cartService.reward = 0;
    this.cartService.sub_reward.next(0);
    if (isCombo.toLowerCase() == '0' || isCombo.toLowerCase() == 'false') {
      if (
        cartItem.ItemCategorySalesTypeID ==
          CategorySalesType.Alcohol.toString() &&
        Number(alkolMax) > 0
      ) {
        this.checkAlkolLimit(cartItem, value);
      } else {
        if (value == maximumQuantity) {
          let error = this.language.getTextElement('item_has_max_quantity');
          error = error.replace('%d', String(maximumQuantity));
          Snackbar.show(error, 2500);
        } else {
          this.increaseQuantityAfterCheck(cartItem, value);
        }
      }
    } else {
      let totalComboAlcoholCount = CommonFunctions.isComboAlcoholic(cartItem);
      if (totalComboAlcoholCount > 0) {
        this.checkAlkolLimit(cartItem, value, totalComboAlcoholCount);
      } else {
        if (value == maximumQuantity) {
          let error = this.language.getTextElement('item_has_max_quantity');
          error = error.replace('%d', String(maximumQuantity));
          Snackbar.show(error, 2500);
        } else {
          this.increaseQuantityAfterCheck(cartItem, value);
        }
      }
    }

    if (this.isTaxIncluded) {
      this.cartService.getAndSetItemTotalWithTax(cartItem);
    } else {
      cartItem.totalPrice =
        CommonFunctions.getItemPrice(cartItem) * Number(cartItem.Quantity);
    }
    if (GeneralSetting.getIntegrationId() == Integration[1]) {
      this.parBrink.edited = true;
    }
    this.cdr.detectChanges();
    this.sibling.calculatePrices();
    if (this.promo) {
      this.promo.items = this.cartService.getCartItems();
    }

    /**
     * notify cart list change with debounce for NCR call in background @nilesh
     */
    this.notifyCartItemChangeDebounce();
  }

  calculateSubtotalTemp(
    cartItems: any,
    withTaxCheck: boolean = true,
    isIncludeDiscount: boolean = true
  ): number {
    let subtotal = 0.0;

    let isTaxIncludedInSubtotal =
      GeneralSetting.getTaxesIncludedInPrice() && withTaxCheck;
    if (
      GeneralSetting.getParBrinkIntegrationID() != '' &&
      (this.parbrink.SubTotal != 0 || this.parbrink.zeroOrder) &&
      !this.parbrink.edited
    ) {
      subtotal = this.parbrink.SubTotal;
    } else {
      for (let i = 0; i < cartItems.length; i++) {
        const item = cartItems[i];
        let quantity = item.Quantity ? Number(item.Quantity) : 1;

        let itemTotal = CommonFunctions.getItemPrice(item) * quantity;

        let itemDiscount = item.DiscountAmount > 0 ? item.DiscountAmount : 0.0;

        if (isTaxIncludedInSubtotal) {
          itemTotal = itemTotal - itemDiscount;
          let itemTax = (item.TaxPercentage * itemTotal) / 100;
          let totalWithTax = itemTotal + itemTax;

          item.totalPrice = totalWithTax;
          itemTotal = totalWithTax;
        }

        if (isIncludeDiscount) {
          subtotal +=
            item.DiscountAmount > itemTotal ? 0.0 : itemTotal - itemDiscount;
        } else {
          subtotal += itemTotal;
        }
      }
    }

    return subtotal;
  }

  checkForOrderLeveDiscountApplicable(isAdd: boolean, index: number) {
    let totalDiscountAmount: number = this.getTotaldiscountAmount();

    let cartItemsTemp = this.cartService.getCartItems();

    let cartItemTemp = cartItemsTemp[index];

    if (isAdd) {
      cartItemTemp.Quantity = (Number(cartItemTemp.Quantity) + 1).toString();
    } else {
      if (Number(cartItemTemp.Quantity) > 1) {
        cartItemTemp.Quantity = (Number(cartItemTemp.Quantity) - 1).toString();
      } else {
        cartItemTemp.Quantity = (0).toString();
      }
    }

    let subTotal = this.calculateSubtotalTemp(cartItemsTemp, false);

    //Jsut for Reset the Quantity as it is for further calculations
    if (isAdd) {
      cartItemTemp.Quantity = (Number(cartItemTemp.Quantity) - 1).toString();
    } else {
      cartItemTemp.Quantity = (Number(cartItemTemp.Quantity) + 1).toString();
    }

    if (this.cartService.selectedDiscount && totalDiscountAmount >= subTotal) {
      let dcc = DiscountConfirmationComponent.open(
        this.modalService,
        this.language.getTextElement('discount_exced_total'),
        this.language.getTextElement('txt_yes'),
        this.language.getTextElement('txt_no'),
        ALERT_TYPE.DISCOUNT_NOT_APPLY
      );
      dcc.result.then((res: any) => {
        if (res == 'Yes') {
          isAdd
            ? this.performIncreasesQty(index)
            : this.performDecreaseQTY(index);
          this.smbdiscount.selectDiscountIter3();
        } else {
          //isAdd ? this.performDecrease(index) : this.performIncrese(index);
        }
      });
    } else {
      isAdd ? this.performIncreasesQty(index) : this.performDecreaseQTY(index);
    }
  }

  notifyCartItemChangeDebounce() {
    this.cartItemChanges.next('change');
  }

  increaseQuantityAfterCheck(cartItem: ItemV2, value: any) {
    this.sibling.calculatePrices();

    cartItem.Quantity = (value + 1).toString();

    /**
     * Apply discount when quantity increase @nilesh
     */
    this.calculateItemDiscountOnQtyChanges(cartItem, true);
    /**************************************************** */
    setTimeout(() => {
      this.proposedLevelUpOrder();
    }, 0);
  }

  calculateItemDiscountOnQtyChanges(cartItem: ItemV2, isAdd: boolean) {
    /**
     * Apply discount when quantity increase/decrease @nilesh
     */
    if (
      this.promo &&
      this.promo.selectedItemPromo &&
      this.promo.selectedItemPromo.RegularOrBogoDiscount == '0'
    ) {
      if (isAdd) {
        cartItem.DiscountAmount =
          (cartItem.DiscountAmount / (Number(cartItem.Quantity) - 1)) *
          Number(cartItem.Quantity);
      } else {
        cartItem.DiscountAmount =
          (cartItem.DiscountAmount / (Number(cartItem.Quantity) + 1)) *
          Number(cartItem.Quantity);
      }
    } else if (
      this.promo &&
      this.promo.selectedItemPromo &&
      this.promo.selectedItemPromo.RegularOrBogoDiscount == '1'
    ) {
      this.promo.applyBogo(this.promo.selectedItemPromo, true);
    }

    if (cartItem.IsUpSellItem) {
      if (isAdd) {
        cartItem.DiscountAmount =
          (cartItem.DiscountAmount / (Number(cartItem.Quantity) - 1)) *
          Number(cartItem.Quantity);
      } else {
        cartItem.DiscountAmount =
          (cartItem.DiscountAmount / (Number(cartItem.Quantity) + 1)) *
          Number(cartItem.Quantity);
      }
    }

    /**************************************************** */
  }

  proposedLevelUpOrder() {
    if (
      this.loyaltyService.loyaltyType == LoyaltyType.LevelUp &&
      this.user.isUserLoggedIn
    ) {
      this.loyaltyService
        .proposeLevelUpOrder(this.cartService.getCartItems())
        .then((data: any) => {
          if (data.statusCode == '400' && data.status == 'error') {
            //Problem in LevelUp Proposed Order - case 1: bad data sent
          } else if (data.statusCode == '200' && data.status == 'success') {
            //successful response from levelup -
            this.loyaltyService.levelUpObject.proposal =
              data.data.proposed_order;
            this.cartService.reward =
              this.loyaltyService.levelUpObject.proposal!.discount_amount;
            this.cartService.sub_reward.next(
              this.loyaltyService.levelUpObject.proposal!.discount_amount
            );
            this.cartService.broadcastAll();
          }
        });
    }
  }

  /**
   * @description performs necesarry checks to determine if item quantity can be decreased and decreases quanitity if so
   * @param {-{number} index - the index of the item to reduce quantity of, passed to helper functions
   * @todo strengthen code by separating into helper functions, and individual checks. Should be only function calls and conditional logic
   */
  decreaseQuantity(index: number): void {
    this.checkForOrderLeveDiscountApplicable(false, index);
  }

  performDecreaseQTY(index: number) {
    const cartItem = this.cartItems[index];
    if (this.oracle.isOracle) {
      this.oracle.calculationResponse = null;
    }
    if (GeneralSetting.getParBrinkIntegrationID() != '') {
      this.parBrink.edited = true;
    }
    const value = Number(cartItem.Quantity);
    const minQuantity = Number(cartItem.OrderMinimumQuantity);
    this.cartService.reward = 0;
    this.cartService.sub_reward.next(0);
    var isCombo = cartItem.IsCombo.toString();
    if (isCombo.toLowerCase() == '0' || isCombo.toLowerCase() == 'false') {
      if (minQuantity > 0) {
        if (value > Number(minQuantity) && value > 1) {
          cartItem.Quantity = (value - 1).toString();
          this.sibling.calculatePrices();
          GeneralSetting.decreaseAlcohol();
          if (cartItem.CurrentStock != null) {
            let newOne = this.database.categorySubject.getValue();
            for (let i = 0; i < newOne.length; i++) {
              if (newOne[i].CategoryID == cartItem.CategoryID) {
                for (let j = 0; j < newOne[i].associatedItems!.length; j++) {
                  if (newOne[i].associatedItems![j].ItemID == cartItem.ItemID) {
                    newOne[i].associatedItems![j].CurrentStock = (
                      Number(newOne[i].associatedItems![j].CurrentStock) + 1
                    ).toString();
                    if (
                      Number(newOne[i].associatedItems![j].CurrentStock) >
                      Number(newOne[i].associatedItems![j].LowThreshold)
                    ) {
                      newOne[i].associatedItems![j].SoldOut = false;
                    }
                    this.database.categorySubject.next(newOne);
                  }
                  break;
                }
                break;
              }
            }
          }
        } else if (value == 1) {
          this.remove(cartItem);
          if (
            cartItem.ItemCategorySalesTypeID ==
            CategorySalesType.Alcohol.toString()
          ) {
            GeneralSetting.decreaseAlcohol();
          }
        }

        /**
         * Apply discount when quantity increase @nilesh
         */
        this.calculateItemDiscountOnQtyChanges(cartItem, false);
        /**************************************************** */
      } else {
        if (value > 1) {
          cartItem.Quantity = (value - 1).toString();
          this.sibling.calculatePrices();
          if (
            cartItem.ItemCategorySalesTypeID ==
            CategorySalesType.Alcohol.toString()
          ) {
            GeneralSetting.decreaseAlcohol();
          }
          if (cartItem.CurrentStock != null) {
            let newOne = this.database.categorySubject.getValue();
            for (let i = 0; i < newOne.length; i++) {
              if (newOne[i].CategoryID == cartItem.CategoryID) {
                for (let j = 0; j < newOne[i].associatedItems!.length; j++) {
                  if (newOne[i].associatedItems![j].ItemID == cartItem.ItemID) {
                    newOne[i].associatedItems![j].CurrentStock = (
                      Number(newOne[i].associatedItems![j].CurrentStock) + 1
                    ).toString();
                    if (
                      Number(newOne[i].associatedItems![j].CurrentStock) >
                      Number(newOne[i].associatedItems![j].LowThreshold)
                    ) {
                      newOne[i].associatedItems![j].SoldOut = false;
                    }
                    this.database.categorySubject.next(newOne);
                    break;
                  }
                }
                break;
              }
            }
          }
        } else if (value == 1) {
          this.remove(cartItem);
          /**
           * Check is added for remove discount selection if item level discount is not applicable after remove item @nilesh
           */
          // if (!this.promo.isItemDiscountApply(this.promo.selectedItemPromo)) {
          //   this.promo.selectDiscount(this.promo.selectedItemPromo, false);
          // }
        }
        /**
         * Apply discount when quantity increase @nilesh
         */
        this.calculateItemDiscountOnQtyChanges(cartItem, false);
        /**************************************************** */
      }
    } else {
      let totalComboAlcoholCount = CommonFunctions.isComboAlcoholic(cartItem);
      if (totalComboAlcoholCount > 0) {
        if (value == 1) {
          this.remove(cartItem);
        } else {
          let newAlcoholCount = CommonFunctions.getLatestAlcoholicCount(
            totalComboAlcoholCount,
            value,
            false
          );
          GeneralSetting.setCurrentCountofAlkol(newAlcoholCount.toString());
          cartItem.Quantity = (value - 1).toString();
        }
      } else {
        if (value == 1) {
          this.remove(cartItem);
        } else {
          cartItem.Quantity = (value - 1).toString();
        }
      }
    }
    if (this.promo) {
      this.promo.items = this.cartService.getCartItems();
    }
    if (this.isTaxIncluded) {
      this.cartService.getAndSetItemTotalWithTax(cartItem);
    } else {
      cartItem.totalPrice =
        CommonFunctions.getItemPrice(cartItem) * Number(cartItem.Quantity);
    }
    if (GeneralSetting.getIntegrationId() == Integration[1]) {
      this.parBrink.edited = true;
    }
    this.cdr.detectChanges();
    this.sibling.calculatePrices();
    if (
      this.loyaltyService.loyaltyType == LoyaltyType.LevelUp &&
      this.user.isUserLoggedIn
    ) {
      this.loyaltyService
        .proposeLevelUpOrder(this.cartService.getCartItems())
        .then(
          (data: any) => {
            if (data.statusCode == '400' && data.status == 'error') {
              //Problem in LevelUp Proposed Order - case 1: bad data sent
            } else if (data.statusCode == '200' && data.status == 'success') {
              //successful response from levelup -
              this.loyaltyService.levelUpObject.proposal =
                data.data.proposed_order;
              this.cartService.reward =
                this.loyaltyService.levelUpObject.proposal!.discount_amount;
              this.cartService.broadcastAll();
            }
          },
          () => {}
        );
    }

    /**
     * notify cart list change with debounce for NCR call in background @nilesh
     */
    this.notifyCartItemChangeDebounce();
  }

  checkQuantity(quantity: string): boolean {
    if (Number(quantity) > 1) {
      return true;
    } else {
      return false;
    }
  }

  checkAlkolLimit(
    element: ItemV2,
    currValue: any,
    totalComboAlcoholCount: any = 0
  ) {
    const alkolMax = GeneralSetting.getMaxAlcoholicItemsPerOrder();

    if (alkolMax !== null && Number(alkolMax) > 0) {
      if (Number(element.OrderMaximumQuantity.toString()) > 0) {
        element.OrderMaximumQuantity = Math.min(
          Number(element.OrderMaximumQuantity.toString()),
          Number(alkolMax)
        );
      } else {
        element.OrderMaximumQuantity = Number(alkolMax);
      }

      let newAlcoholCount = CommonFunctions.getLatestAlcoholicCount(
        totalComboAlcoholCount,
        currValue,
        true
      );
      if (newAlcoholCount <= Number(alkolMax)) {
        if (currValue < element.OrderMaximumQuantity) {
          element.Quantity = (currValue + 1).toString();
          GeneralSetting.setCurrentCountofAlkol(newAlcoholCount.toString());
        }
      } else {
        AlcoholicItemsExceedMaxComponent.open(this.modalService);
      }
    }
  }

  getNumber(num: number): any[] {
    return new Array(num);
  }

  getFormattedModifier(modifier: ModifierV2[], isRemovePrice: boolean) {
    let currenySymbol = GeneralSetting.getCurrencySymbol();
    function formatIngredient(ingredient: ModifierIngredientV2) {
      let template = '';
      if (
        (ingredient.IsDefault == 'True' &&
          GeneralSetting.getShowDefaultModifiers() == 'True' &&
          !ingredient.isNegativeModifier) ||
        (ingredient.IsSelected && ingredient.IsDefault == 'False')
      ) {
        template += `<span class="indName"> - ${ingredient.Name}`;

        /**
         * Remove unncessary if condition @nilesh
         */
        if (Number(ingredient.Quantity) > 1) {
          template += `<span>
                            x ${ingredient.Quantity}
                      </span>`;
        }
        if (!isRemovePrice) {
          if (GeneralSetting.getParBrinkIntegrationID() != '') {
            if (ingredient.IsDefault != 'True') {
              if (Number(ingredient.ExtraPrice) > 0) {
                template += `<span>
                    ${currenySymbol} ${ingredient.ExtraPrice}
                    </span>
                `;
              }
            }
          } else {
            if (Number(ingredient.ExtraPrice) > 0) {
              template += `<span>
                ${currenySymbol} ${ingredient.ExtraPrice}
                  </span>
              `;
            }
          }
        }
        template += ` </span>`;
      } else if (
        ingredient.isNegativeModifier &&
        GeneralSetting.getShowNegativeModifiers() == 'True'
      ) {
        template += `<span class="red-color"> - No ${ingredient.Name}`;

        //if (Number(ingredient.Quantity) > 1) {
        //  template += `<span>
        //                  x ${ingredient.Quantity}
        //            </span>`;
        //}

        template += `</span>`;
      }

      return template;
    }

    function isAllSelectedModifierAreDefault(modifier: any) {
      // let selectedDefaultModifiers = modifier.Ingredients.filter((ing: any) =>{ ing.IsSelected && ing.IsDefault === 'True'})
      let selectedDefaultModifiers = modifier.Ingredients.filter(function (
        ing: any
      ) {
        return ing.IsSelected && ing.IsDefault == 'True';
      });
      if (
        CommonFunctions.getSelectedIngredientCount(modifier) ==
          selectedDefaultModifiers.length ||
        CommonFunctions.getSelectedIngredientCount(modifier) == 0
      ) {
        return true;
      } else {
        return false;
      }
    }
    function formatModifier(modifier: ModifierV2) {
      let template = '';
      function hideModifierGroupName() {
        if (
          isAllSelectedModifierAreDefault(modifier) &&
          GeneralSetting.getShowDefaultModifiers() == 'False' &&
          GeneralSetting.getShowNegativeModifiers() == 'True'
        ) {
          return true;
        } else {
          return false;
        }
      }

      if (modifier.IsSelected && !hideModifierGroupName()) {
        template += `<div class="mods">`;

        template += `<div class="spacers" ></div>`;

        if (modifier.Category && modifier.Category != '') {
          template += ` <span class="categoryBold" >${modifier.Category}`;
        } else {
          template += ` <span>${modifier.Category}`;
        }

        if (
          !isNaN(Number(modifier.Quantity)) &&
          Number(modifier.Quantity) > 1
        ) {
          template += `<span>
                          x ${modifier.Quantity}
                    </span>`;
        }

        if (!isRemovePrice) {
          if (modifier.Price && Number(modifier.Price) > 0) {
            template += `<span > +${modifier.Price}</span>`;
          }
        }

        template += '</span>';

        if (modifier.Ingredients && modifier.Ingredients.length > 0) {
          const selectedIngredient =
            CommonFunctions.getSelectedModifierIngredientList(
              modifier.Ingredients,
              true
            );

          for (let i = 0; i < selectedIngredient.length; i++) {
            const ing = selectedIngredient[i];

            template += `<div>`;

            if (ing.IsIngredient) {
              let ingredient = ing as ModifierIngredientV2;

              template += formatIngredient(ingredient);
            } else if (ing.IsModifier) {
              let mod = ing as ModifierV2;
              template += formatModifier(mod);
            }
            template += `</div>`;
          }
        }
        template += `</div>`;
      }

      return template;
    }

    //console.log("html function call");

    let mainTemplate = '';

    for (let i = 0; i < modifier.length; i++) {
      mainTemplate += formatModifier(modifier[i]);
    }

    return mainTemplate;
  }

  getComboItemsConsolidated(combo: ItemV2): ItemV2[] {
    let selectedItemList: ItemV2[] = [];
    combo.ComboGroup.forEach((comboGroup) => {
      comboGroup.Items.forEach((comboItem) => {
        if (comboItem.isSelected) {
          if (
            selectedItemList.find(
              (x) => x.BoxBuilderItemGUID == comboItem.BoxBuilderItemGUID
            ) == undefined
          ) {
            selectedItemList.push(comboItem);
          }
        }
      });
    });
    return selectedItemList;
  }

  /**
   * Check and alert after every subtotal update if fixed item available in the cart
   * @param subtotal
   */
  private async updateFixedItemOnSubtotal(subtotal: number) {
    var fixedItemDiscount =
      await this.discountService.checkFreeItemOnSubtotalUpdate(subtotal);
    this.updateFreeItemQty(fixedItemDiscount);
  }

  /**
   * Check fixed item's Quantity need to be updated or removed from the cart
   * @param fixedItemDiscount
   */
  private updateFreeItemQty(fixedItemDiscount: any) {
    if (fixedItemDiscount && fixedItemDiscount != undefined) {
      const cartItemlist = this.cartService.getCartItems();

      const cartItems = cartItemlist.filter(
        (x) => x.ItemID == fixedItemDiscount.ItemID
      );
      if (cartItems && cartItems != undefined && cartItems.length > 0) {
        let index = cartItemlist.findIndex((x) => x === cartItems[0]);

        if (fixedItemDiscount.Check == 1) {
          var decQty =
            Number(cartItems[0].Quantity) - fixedItemDiscount.FixedQty;
          for (var i = 0; i < decQty; i++) {
            this.decreaseQuantity(index);
          }
        } else if (fixedItemDiscount.Check == 2) {
          this.remove(cartItems[0]);
        }
      }
    }
  }

  getTotaldiscountAmount(): number {
    let totalDiscountAmt: number = 0;

    if (this.cartService.selectedDiscount) {
      totalDiscountAmt = Number(
        this.cartService.selectedDiscount.DiscountAmount
      );
    }

    return totalDiscountAmt;
  }
}
