import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ConcessionaireFilterTypes } from 'src/app/models/concessionaireTypes';
import { ItemTagFilterMasters } from 'src/app/models/ItemTagFilterMaster';
import { DatabaseService } from 'src/app/services/database.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';
import { ConcessionaireService } from '../../menu/conc-service/concessionaire.service';

@Component({
  selector: 'app-filter-concessionaire',
  templateUrl: './filter-concessionaire.component.html',
  styleUrls: ['./filter-concessionaire.component.css']
})

export class FilterConcessionaireComponent implements OnInit {
  languageSub!:Subscription
  modalReference: any;
  concessionaireFilterTypes: ConcessionaireFilterTypes[] = [] as ConcessionaireFilterTypes[];
  positiveButton: string  = '';
  positiveButtonText: string  = '';
  itemTagsAndGroupFilters : ItemTagFilterMasters[] = [] as ItemTagFilterMasters[];
  secondaryColor: string  = '';
  primaryColor: string  = '';
  cuisineTypeText: string  = ''
  applyText: string  = ''
  cancelText: string  = ''
  constructor(private readonly modalService: NgbModal,private language:LanguageService, private readonly db: DatabaseService, private readonly conService: ConcessionaireService, ) { }
  loadText(){
    this.cuisineTypeText=this.language.getTextElement('cuisine_type')
    this.applyText =this.language.getTextElement('apply')
    this.cancelText=this.language.getTextElement('lbl_button_cancel')
  }
  ngOnInit(): void {
    this.languageSub=this.language.localeCommunicator.subscribe((val)=>{
      this.loadText()
    })
    this.positiveButton = GeneralSetting.getPositiveColor();
    this.positiveButtonText = GeneralSetting.getPositiveColorText();
    this.secondaryColor = GeneralSetting.getSecondaryColor();
    this.primaryColor = GeneralSetting.getPrimaryColor();
    
    if(this.conService.concessionaireFilterTypes.length <= 0){
      this.db.getConcessionaireTypes().then(con => {
        this.concessionaireFilterTypes = con;
        this.conService.setConcessionaireFilterTypes(this.concessionaireFilterTypes);
      });
    }else{
      this.concessionaireFilterTypes = this.conService.concessionaireFilterTypes;
    }

    if(this.conService.itemTagsAndGroupFilters.length <= 0){
      this.db.getItemGroupAndTags().then(filters => {
        this.itemTagsAndGroupFilters = filters;
        this.conService.setItemTagsAndGroupFilters(this.itemTagsAndGroupFilters);
      });
    }else{
      this.itemTagsAndGroupFilters = this.conService.itemTagsAndGroupFilters;      
    }
  }

  public static open(modalService: NgbModal) {
    var modalReference = modalService.open(FilterConcessionaireComponent, {
      size: 'lg',
      modalDialogClass: "cons-dialog",
    });

    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }

  open() {
    var modalReference = this.modalService.open(FilterConcessionaireComponent, {
      size: 'lg',
      modalDialogClass: "cons-dialog",
    });

    modalReference.componentInstance.modalReference = modalReference;
    this.modalReference = modalReference;
  }

  cancel(){
    this.modalReference.close('cancel');
  }

  apply(){
    this.modalReference.close('apply');
  }

  onConcessionaireTypeSelection(filterId : string, isSelected : boolean, event : Event, isItemTag : boolean = false){
    this.conService.onConcessionaireTypeSelection(filterId,isSelected,event,isItemTag);      
  }
}
