import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/services/cart.service';
import { LanguageService } from 'src/app/services/language.service';
import { TaxPojo } from '../../../models/tax-pojo';
import { CommonFunctions } from '../../../services/common';

@Component({
  selector: 'app-tax-info',
  templateUrl: './tax-info.component.html',
  styleUrls: ['./tax-info.component.css']
})
export class TaxInfoComponent implements OnInit,OnDestroy {

  constructor(private cartService: CartService,private language:LanguageService) { }
  languageSub!:Subscription
  selfReference!: NgbModalRef;
  taxDetailText:string=''
  okText:string=''
  taxDetail = [{
    Name: "",
    TaxId: "",
    TaxAmount: 0,
    TaxPer:0
  }];
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {
    this.taxDetailText = this.language.getTextElement('text_tax_details');
    this.okText = this.language.getTextElement('lbl_ok');
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.taxDetail = this.cartService.totalTaxTypes;
  }

  close(){
    if (this.selfReference){
      this.selfReference.close();
    }
  }

}
