import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { vwOrderCustomMessage } from 'src/app/models/vwOrderCustomMessege';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-order-custom-messages',
  templateUrl: './order-custom-messages.component.html',
  styleUrls: ['./order-custom-messages.component.css']
})
export class OrderCustomMessagesComponent implements OnInit {

  modalReference: any;

  title: string = "";

  beforeImageText: string = "";

  afterImageText: string = "";

  ImageUrl: string = "";

  baseImageUrl = this.isKioskService.isKiosk() ? environment.imageBaseUrl : environment.imageBaseUrlRemote;

  placeholderImage : string = '';

  constructor(private isKioskService: IsKioskService, private readonly dataService: DataService) { }
  
  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
  }

  canceled() {
    this.modalReference.close("closed");
  }

}
