import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DatabaseHandler } from '../DatabaseHandler';
import { Kitchen_Ingredient_Mapping, Kitchen_Item_Mapping } from '../models/BranchLoginModels';
import { OrderItemMasterReq, OrderItemModifierMasterReq } from '../models/OrderItemMasterReq';
import { OrderListReq } from '../models/OrderListReq';
import { Printer } from '../models/Printer';
import { DatabaseService } from './database.service';
import { GeneralSetting } from './generalSetting.service';
import { LogService } from './log.service';
import { OfflineOrderService } from './offline-order.service';

@Injectable({
  providedIn: 'root'
})
export class KdsService {

  constructor(private http: HttpClient,
    private database: DatabaseService,
    private logger: LogService,
  private offlineOrderService : OfflineOrderService) { }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message);
  }

  callSendDataToKds(orderData: any,ipOfKds : Printer) {
    // const currentCounter = this.increasePaymentCounter();

    const body = {
      ipAddressOfKds: JSON.stringify(ipOfKds),
      message: JSON.stringify(orderData),
      
    };

    return this.http
      .post<any>(environment.NodeUrl + 'doSendOrderToKds', body)
      .pipe(catchError(this.errorHandler));
  }

  async sendToKds(orderData: OrderListReq): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      let kdsList = await this.getKdsList();
      if (kdsList && kdsList.length > 0) {
        for (let index = 0; index < kdsList.length; index++) {
          const kds = kdsList[index];
          let orderDataTemp = {} as OrderListReq;
          orderDataTemp = JSON.parse(JSON.stringify(orderData));
          // this.callSendDataToKds(orderData, kds.KDSIPAddress).subscribe((resolve => {

          // }))
          let orderItemMasterTemp: OrderItemMasterReq[] = [];

          let ingredientKitchenMappingList: Kitchen_Ingredient_Mapping[] = await this.database.getIngredientsFromKitchenMapping(kds.KitchenID);
          let itemLKitchenMappingList: Kitchen_Item_Mapping[] = await this.database.getItemFromKitchenmapping(kds.KitchenID);

          if (ingredientKitchenMappingList.length == 0) {

            for (let index = 0; index < orderDataTemp.OrderMaster[0].OrderItemMaster.length; index++) {
              const orderItem = orderDataTemp.OrderMaster[0].OrderItemMaster[index];
              const itemLKitchenMapping = itemLKitchenMappingList?.find(
                (x: Kitchen_Item_Mapping) => x.ItemID.toString() == orderItem.ItemID.toString()) as Kitchen_Item_Mapping;

              if (itemLKitchenMapping) {
                orderItemMasterTemp.push(orderItem);
              }
            }

          } else {


            for (let index = 0; index < orderDataTemp.OrderMaster[0].OrderItemMaster.length; index++) {
              const orderItem = orderDataTemp.OrderMaster[0].OrderItemMaster[index];


              const itemLKitchenMapping = itemLKitchenMappingList?.find(
                (x: Kitchen_Item_Mapping) => x.ItemID.toString() == orderItem.ItemID.toString()) as Kitchen_Item_Mapping;

              if (itemLKitchenMapping) {

                let orderItemModifierMasterTemp: OrderItemModifierMasterReq[] = [];


                for (let index = 0; index < orderItem.OrderItemModifierMaster.length; index++) {
                  const ingredient = orderItem.OrderItemModifierMaster[index];

                  const orderItemModifierInMapping = ingredientKitchenMappingList?.find(
                    (x) => x.IngredientID.toString() == ingredient.IngredientID.toString()
                  ) as Kitchen_Ingredient_Mapping;


                  if (orderItemModifierInMapping) {
                    orderItemModifierMasterTemp.push(ingredient);
                  }
                }

                orderItem.OrderItemModifierMaster = orderItemModifierMasterTemp;
                orderItemMasterTemp.push(orderItem);
              }

            }

          }

          orderDataTemp.OrderMaster[0].OrderItemMaster = orderItemMasterTemp;
          // await this.delay(1000);
          this.callSendDataToKds(orderDataTemp, kds).toPromise().then((resolve => {

          }));
          resolve(true);

        }

      }
    });


  }

  
  getKdsList(): Promise<Printer[]>{
    return new Promise<Printer[]>(async (resolve: any, reject: any) => {
      const getKdsList = ` 
            select km.Name as kitchenName,
                    kdsm.KDSIPAddress as KDSIPAddress,
                    kdsm.Name as KDSName,
                    kdsm.IsMapDevice as IsMapDevice ,
                    kdsm.KDSID as KDSID ,
                    km.KitchenID,
                    kdsm.KDSIPAddress as KDSIPAddress,
                    km.StaffModuleID,
                    kdsm.DeviceID
                    from KitchenMasters as km
                    inner join KDSMasters as kdsm on km.KDSID = kdsm.KDSID
                    where km.IsActive = 'True' and
                    km.CompanyID = '${GeneralSetting.getCompanyId()}'
                    and kdsm.KDSTypeID ='1' and km.KDSID !=''
      `;

      const errorCallback = (statement: any, error: any) => {
        console.log('kds list error', error);
        reject(error);
      };

      const showKdsList = (tx: string, results: any) => {
        var data: Printer[] = Array.from(results.rows);
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getKdsList,
        [],
        showKdsList,
        errorCallback
      );
    });
  }
}
