import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subscription } from 'rxjs';
import { debounceTime, timeout } from 'rxjs/operators';
import { AreYouSureComponent } from 'src/app/components/dialogs/are-you-sure/are-you-sure.component';
import { AskCustomerReceiptDialogComponent } from 'src/app/components/dialogs/ask-customer-receipt-dialog/ask-customer-receipt-dialog.component';
import { ConcessionaireClosedComponent } from 'src/app/components/dialogs/concessionaire-closed/concessionaire-closed.component';
import { ConnectionLossPopupComponent } from 'src/app/components/dialogs/connection-loss-popup/connection-loss-popup.component';
import { CustominfoPopupComponent } from 'src/app/components/dialogs/custominfo-popup/custominfo-popup.component';
import { Items86PopupComponent } from 'src/app/components/dialogs/items86-popup/items86-popup.component';
import { OloErrorPopupComponent } from 'src/app/components/dialogs/olo-error-popup/olo-error-popup.component';
import { OLOTotalLoaderComponent } from 'src/app/components/dialogs/olototal-loader/olototal-loader.component';
import { OrderLevelUpsellDialogComponent } from 'src/app/components/dialogs/order-level-upsell-dialog/order-level-upsell-dialog.component';
import { PaymentErrorComponent } from 'src/app/components/dialogs/payment-error/payment-error.component';
import { PrintingProgressPopupComponent } from 'src/app/components/dialogs/printing-progress-popup/printing-progress-popup.component';
import { TableTentPopupComponent } from 'src/app/components/dialogs/table-tent-popup/table-tent-popup.component';
import { TaxInfoComponent } from 'src/app/components/dialogs/tax-info/tax-info.component';
import { TipDialogComponent } from 'src/app/components/dialogs/tip-dialog/tip-dialog.component';
import { UsconnectAccountFoundComponent } from 'src/app/components/dialogs/usconnect-account-found/usconnect-account-found.component';
import { UsconnectAccountNotFoundComponent } from 'src/app/components/dialogs/usconnect-account-not-found/usconnect-account-not-found.component';
import { UsconnectBalanceLowComponent } from 'src/app/components/dialogs/usconnect-balance-low/usconnect-balance-low.component';
import { UsconnectErrorComponent } from 'src/app/components/dialogs/usconnect-error/usconnect-error.component';
import { UsconnectScanComponent } from 'src/app/components/dialogs/usconnect-scan/usconnect-scan.component';
import { UsconnectSuccessComponent } from 'src/app/components/dialogs/usconnect-success/usconnect-success.component';
import { OrderReviewListComponent } from 'src/app/components/order-review/order-review-list/order-review-list.component';
import { OrderReviewPromotionsComponent } from 'src/app/components/order-review/order-review-promotions/order-review-promotions.component';
import { PaymentPopupComponent } from 'src/app/components/payment-popup/payment-popup.component';
import { ThankyouPopupComponent } from 'src/app/components/thankyou-popup/thankyou-popup.component';
import { CodelessDiscount } from 'src/app/models/discounts';
import { grubbrrPayCredentials } from 'src/app/models/grubbrrPayCredentials';
import { ItemV2 } from 'src/app/models/item';
import {
  LevelUpObject,
  LevelUpProposalResponse,
  LoyaltyType,
} from 'src/app/models/loyaltyModal';
import { OLOResponse } from 'src/app/models/OLOResponse';
import { OracleResponse } from 'src/app/models/OracleModels';
import { OrderListReq } from 'src/app/models/OrderListReq';
import { OrderUpRes } from 'src/app/models/OrderUpRes';
import { EPaymentType, paymentTypeData } from 'src/app/models/payment-type';
import { usConnectCreds } from 'src/app/models/usconnectCreds';
import { CartService } from 'src/app/services/cart.service';
import { CloverService } from 'src/app/services/clover.service';
import {
  CategorySalesType,
  CommonFunctions,
  Snackbar,
} from 'src/app/services/common';
import { DataService } from 'src/app/services/data.service';
import { DatabaseService } from 'src/app/services/database.service';
import { DejavooService } from 'src/app/services/dejavoo.service';
import { EatOptionsService } from 'src/app/services/eat-options.service';
import {
  Integration,
  IntegrationService,
} from 'src/app/services/integration.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { KdsService } from 'src/app/services/kds.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import {
  LanguageChoice,
  LanguageService,
} from 'src/app/services/language.service';
import { LoaderService } from 'src/app/services/loader.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { MenuItemService } from 'src/app/services/menu-item.service';
import { OfflineOrderService } from 'src/app/services/offline-order.service';
import { OLOService } from 'src/app/services/olo.service';
import { OracleService } from 'src/app/services/oracle.service';
import { OrderHistoryService } from 'src/app/services/order-history.service';
import { OtterService } from 'src/app/services/otter.service';
import { ParBrinkService } from 'src/app/services/par-brink.service';
import { PaymentGatwayService } from 'src/app/services/payment-gatway.service';
import { PaymentTypesService } from 'src/app/services/payment-types.service';
import { PaymentService } from 'src/app/services/payment.service';
import { PusherService } from 'src/app/services/pusher.service';
import {
  DomesticShippingDetails,
  InternationalShippingDetails,
  ShippingService,
} from 'src/app/services/shipping.service';
import { SMSService } from 'src/app/services/sms.service';
import { TipService } from 'src/app/services/tip.service';
import { UpsellService } from 'src/app/services/upsell.service';
import { UsconnectService } from 'src/app/services/usconnect.service';
import { UserService } from 'src/app/services/user.service';
import { VerifoneService } from 'src/app/services/verifone.service';
import { Verifone3CService } from 'src/app/services/verifone3C.service';
import { NcrResponse } from '../../../models/NcrModels/NcrResponss';
import { GeneralSetting } from '../../../services/generalSetting.service';
import { AlcoholicItemsDialogComponent } from '../../dialogs/alcoholic-items-dialog/alcoholic-items-dialog.component';
import { GeneralAlertComponent } from '../../dialogs/general-alert/general-alert.component';
import { LevelUpModalComponent } from '../../dialogs/level-up-modal/level-up-modal.component';
import { ParBrinkErrorPopUpComponent } from '../../dialogs/par-brink-error-pop-up/par-brink-error-pop-up.component';
import { PayWithIdcardLoginComponent } from '../../dialogs/pay-with-idcard-login/pay-with-idcard-login.component';
import { PaymentInsertService } from '../../dialogs/payment-insert/payment-insert.service';
import { ManagerPinDialogComponent } from '../../manager-pin-dialog/manager-pin-dialog.component';
import { TimeoutComponent } from '../../timeout/timeout.component';
import { OrderreviewService } from '../orderreview.service';
import { PrinterService } from 'src/app/services/printer.service';
import { SMBDiscountsComponent } from '../smb-discounts/smb-discounts.component';
import { reject } from 'lodash';

class ValidatedTotals {
  public Tip?: number;
  constructor(
    public Total: number,
    public SubTotal: number,
    public Tax: number
  ) {
    this.Tip = undefined;
  }
}

@Component({
  selector: 'app-order-review-footer',
  templateUrl: './order-review-footer.component.html',
  styleUrls: ['./order-review-footer.component.css'],
})
export class OrderReviewFooterComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }

    this.removeAllListeners();

    this.cartService.conDetailSub.next([]);

    if (this.ncrSubscription) {
      this.ncrSubscription.unsubscribe();
    }

    this.or.setDefaultAllUnneededProperties();
  }

  private allSubsCription: Subscription[] = [];

  @Output() subtotalChanged = new EventEmitter<number>();

  @Output() totalChanged = new EventEmitter<number>();

  @Output() taxChanged = new EventEmitter<number>();

  @Output() updateCalculation: EventEmitter<number> = new EventEmitter();

  @Output() removeEmitter: EventEmitter<boolean> = new EventEmitter();

  @Input() promo!: OrderReviewPromotionsComponent;

  @Input() smbdiscount!: SMBDiscountsComponent;

  @Input() orderReviewList!: OrderReviewListComponent;

  @ViewChild(LevelUpModalComponent) levelUpModal!: LevelUpModalComponent;

  @ViewChild(ParBrinkErrorPopUpComponent, { static: true })
  parBrinkErrorPopup!: ParBrinkErrorPopUpComponent;

  @ViewChild(TimeoutComponent) timeOutComponent!: TimeoutComponent;

  authToken: string = '';

  validatedTotals: ValidatedTotals | undefined;

  subtotal: number = 0.0;

  discount: number = 0.0;

  variableUpSell!: boolean;

  modifiableUpSell!: boolean;
  tax: number = 0.0;

  total: number = 0.0;

  shippingFee: number = 0;

  unavailableItems: any[] = [];

  modal: any;

  totalbeforetip: number = 0;

  showTipDialog = '';

  canSMS: string = 'False';

  customerPhone: string = '';

  loyaltyType: number = 0;

  //no token number for now

  reward: number = 0;

  //another default message comes from database, this is the default if there is no db message

  defaultMessage: string = 'Hi #. Your Order Number # is in progress.';

  SMSmessage: string = this.defaultMessage;

  primaryColor: string = '';

  positiveButton: string = '';

  priceColor: string = '';

  positiveButtonText: string = '';

  negativeButton: string = '';

  negativeButtonText: string = '';

  cusisshown: boolean = false;

  showcalorie: any;

  paymentTypes: paymentTypeData[] = [];

  isOlo: boolean = false;

  isParBrink: boolean = false;

  isOracle: boolean = false;

  isMenuIntegration: boolean = false;

  isDoPrintReceipt: boolean = false;

  private itemInCart: ItemV2[] = [] as ItemV2[];

  isTaxIncludedInSubtotal: boolean = false;

  //For verifone last transaction call count check

  lastTransactionCounter: number = 1;

  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();

  gpCreds: grubbrrPayCredentials = {
    AuthanticationKey: '',
    ServiceCharge: '',
    RegistrationID: '',
    TPNNumber: '',
    TPaymentID: '',
  };

  usConnectCreds: usConnectCreds = {
    AuthToken: '',
    DeviceSerialNumber: '',
    UCMID: '',
    URL: '',
  };

  listeners: EventListener[] = [];

  usConnectSerial: string = '';
  // Clover Integration
  isCloverPaymentCompleted: boolean = false;
  cloverPaymentId: string = '';
  cloverOrderId: string = '';
  cloverDeductedAmount: number = 0.0;
  cloverChargeAmount: number = 0.0;
  cloverCardType: number = 0;
  cloverBaseURL: string = '';

  isCloverPartialPayment: boolean = false;

  isConcessionaire: boolean = false;

  integrationID: number;

  taxType: string = '';
  subtotalText: string = '';
  discountText: string = '';
  calorieCountText: string = '';
  backToMenuText: string = '';
  cancelOrderText: string = '';
  payText: string = '';
  totalTaxText: string = '';
  totalVatText: string = '';
  subtotalIncludedText: string = '';
  shippingFeeText: string = '';
  totalText: string = '';
  vatText: string = '';
  taxesText: string = '';
  totalCalculationText: string = '';
  touchPayText: string = '';
  submitOrderText: string = '';
  private ncrSubscription: Subscription = new Subscription();
  private isNCRCallRunning = false;
  paymentTaken: boolean = false;
  paymentStarted: boolean = false;
  awaitingLevelUpProposal: boolean = true;

  //---------------------------------------------------------- START
  cimaToken = '';
  paymentRunAvailability: boolean = false;
  paymentStatus: string = '';
  intID: any;
  //---------------------------------------------------------- END
  constructor(
    private readonly SMSService: SMSService,
    private readonly router: Router,
    private readonly cartService: CartService,
    private readonly upsellService: UpsellService,
    private readonly paymentService: PaymentService,
    private readonly databaseService: DatabaseService,
    private readonly dataService: DataService,
    private readonly verifoneService: VerifoneService,
    private readonly loyaltyService: LoyaltyService,
    private readonly logger: LogService,
    private readonly olo: OLOService,
    private readonly parbrink: ParBrinkService,
    private readonly paymentTypeService: PaymentTypesService,
    private readonly optionsService: EatOptionsService,
    private readonly modalService: NgbModal,
    private readonly isKioskService: IsKioskService,
    private shipping: ShippingService,
    private readonly usconnectService: UsconnectService,
    private readonly dejavooService: DejavooService,
    private readonly menuItemService: MenuItemService,
    private readonly tipService: TipService,
    private readonly userService: UserService,
    private readonly cloverService: CloverService,
    private KeyboardService: KeyboardService,
    private kdsService: KdsService,
    private readonly oracle: OracleService,
    private integration: IntegrationService,
    private or: OrderreviewService,
    private language: LanguageService,
    private otterService: OtterService,
    private offlineService: OfflineOrderService,
    private orderHistoryService: OrderHistoryService,
    private pusherService: PusherService,
    private verifone3CService: Verifone3CService,
    private readonly loader: LoaderService,
    private readonly paymentGateWayService: PaymentGatwayService,
    private readonly paymentInsertModal: PaymentInsertService,
    private readonly printerService: PrinterService
  ) {
    this.integrationID = integration.integration;
  }
  loadText() {
    this.subtotalText = this.language.getTextElement('subtotal');
    this.discountText = this.language.getTextElement('txt_discount');
    this.calorieCountText = this.language.getTextElement(
      'txt_calorie_counts_are_only_an_estimate'
    );
    this.backToMenuText = this.language.getTextElement('action_back_to_menu');
    this.cancelOrderText = this.language.getTextElement('cancel_order');
    this.payText = this.language.getTextElement('lbl_pay');
    this.totalTaxText = this.language.getTextElement('txt_total_tax');
    this.totalVatText = this.language.getTextElement('lbl_total_vat');
    this.subtotalIncludedText =
      this.language.getTextElement('subtotal_included');
    this.shippingFeeText = this.language.getTextElement('shipping_fee');
    this.totalText = this.language.getTextElement('text_total');
    this.vatText = this.language.getTextElement('vat');
    this.taxesText = this.language.getTextElement('taxes');
    this.totalCalculationText = this.language
      .getTextElement('total_calculated_at_checkout')
      .replace('%d', this.taxType == '2' ? this.vatText : this.taxesText);
    this.touchPayText = this.language.getTextElement('touch_pay_to_complete');
    this.submitOrderText = this.language.getTextElement('submit_order');
  }

  ngOnInit(): void {
    this.InitData();
    this.setOlo();
    if (this.shipping.shippingDetails != undefined) {
      this.shippingFee =
        this.shipping.shippingDetails.ShippingFee *
        this.cartService.getCartItems().length;
    }
    // set tip detail in tip service
    this.tipService.getTipFixed();
    this.tipService.getTipMaster();
    this.calculationSubscriber();
    this.itemInCart = this.cartService.getCartItems();
    //set calculation properly on first time load
    this.updateCalculation.emit();
    this.isConcessionaire =
      GeneralSetting.getIsConcessionaire().toLowerCase() == 'true';
    this.isOracle = this.oracle.isOracle;
    // checkpoint - to connect to verifone 3C payment
    // this.isOracle = false;
    // this.oracle.isOracle = false;

    this.isMenuIntegration = this.isOlo || this.isParBrink || this.isOracle;
    this.or.getAllRelatedCustomeMessage();

    this.allSubsCription.push(
      this.cartService.removeOrderLevelDiscount.subscribe((data) => {
        this.removeDiscount();
      })
    );
    this.allSubsCription.push(
      this.pusherService.Item86.subscribe((x) => {
        if (x && Object.keys(x).length > 0) {
          this.cartService.updateItem86(x);
        }
      })
    );

    this.allSubsCription.push(
      this.pusherService.ItemPrice.subscribe((evt) => {
        if (!evt || Object.keys(evt).length == 0) return;
        this.cartService.updateItemPrice(evt.ItemId, evt.Price);
        this.cartService.calculateSubtotal();
      })
    );
  }

  doLevelUpProposal(payment: boolean = false, sameAccount: boolean = false) {
    return new Promise<boolean>(async (resolve, reject) => {
      this.awaitingLevelUpProposal = true;
      if (!sameAccount) {
        this.cartService.reward = 0;
        this.cartService.sub_reward.next(0);
      }
      this.loyaltyService
        .proposeLevelUpOrder(this.cartService.getCartItems(), payment)
        .then(
          (data: any) => {
            if (data.statusCode == '400' && data.status == 'error') {
              //Problem in LevelUp Proposed Order - case 1: bad data sent
              this.loyaltyService.logBadLevelUpProposal();
              this.loyaltyService.levelUpObject.fail = true;
              //GeneralAlertComponent.open( this.modalService, 'Uh Oh!', data.message );
              this.awaitingLevelUpProposal = false;
              resolve(false);
            } else if (data.statusCode == '200' && data.status == 'success') {
              //successful response from levelup -
              this.loyaltyService.levelUpObject.proposal =
                data.data.proposed_order;
              if (
                this.loyaltyService.levelUpObject.proposal!.discount_amount != 0
              ) {
                if (!sameAccount) {
                  this.promo.redeemRewardPopup.open(
                    this.loyaltyService.levelUpObject.proposal!.discount_amount,
                    this.loyaltyType
                  );
                  this.promo.redeemRewardPopup.modalReference.result.finally(
                    () => {
                      this.cartService.reward =
                        this.loyaltyService.levelUpObject.proposal!.discount_amount;
                      this.cartService.sub_reward.next(
                        this.loyaltyService.levelUpObject.proposal!
                          .discount_amount
                      );
                      this.cartService.broadcastAll();
                      this.awaitingLevelUpProposal = false;
                      resolve(true);
                    }
                  );
                } else {
                  this.awaitingLevelUpProposal = false;
                  resolve(true);
                }
              } else {
                resolve(true);
                this.awaitingLevelUpProposal = false;
              }
            } else {
              this.loyaltyService.levelUpObject.fail = true;
              let title = this.language.getTextElement('titile_uh_oh');
              GeneralAlertComponent.open(this.modalService, title, data);
              let log = new loggingData(
                'LevelUp Error',
                'Error Proposing LevelUp Order',
                'Loyalty LevelUp Error',
                'Failed to Propose Order:' + JSON.stringify(data),
                true
              );
              this.logger.sendLogToServer(log);
              this.awaitingLevelUpProposal = false;
              resolve(false);
            }
          },
          (err: any) => {
            this.awaitingLevelUpProposal = false;
            resolve(false);
            let title = this.language.getTextElement('titile_uh_oh');
            GeneralAlertComponent.open(this.modalService, title, err);
            let log = new loggingData(
              'LevelUp Error',
              'Error Proposing LevelUp Order',
              'Loyalty LevelUp Error',
              'Encountered Error While Proposing Order:' + JSON.stringify(err),
              true
            );
            this.logger.sendLogToServer(log);
          }
        );
    });
  }
  /**
   * @description initialized CSS variables, text settings, and 3rd-party Loyalty type
   * @description should not be called directly
   */
  private InitData() {
    this.taxType = GeneralSetting.getTaxType().toLowerCase();
    this.SMSService.getSMSSetting().then((element: any) => {
      this.canSMS = this.SMSService.canSMS;
      this.SMSmessage = this.SMSService.SMSmessage;
    });

    this.primaryColor = GeneralSetting.getPrimaryColor();
    this.paymentTypeService.getPaymentTypes2().then((data) => {
      this.paymentTypes = data;
    });
    this.positiveButton = GeneralSetting.getPositiveColor();
    this.positiveButtonText = GeneralSetting.getPositiveColorText();
    this.negativeButton = GeneralSetting.getNegativeColor();
    this.negativeButtonText = GeneralSetting.getNegativeColorText();
    this.showcalorie = GeneralSetting.getShowCalorieCounts();
    this.loyaltyType = this.loyaltyService.loyaltyType;
    if (
      this.loyaltyType == LoyaltyType.LevelUp &&
      this.userService.isUserLoggedIn
    ) {
      this.doLevelUpProposal();
    } else {
      this.awaitingLevelUpProposal = false;
    }
    this.priceColor = GeneralSetting.getPriceColor();
    this.showTipDialog = GeneralSetting.getShowTipDialog();
    this.isTaxIncludedInSubtotal = GeneralSetting.getTaxesIncludedInPrice();
  }
  private setOlo() {
    this.olo
      .getOloIntegrationData()
      .toPromise()
      .then(
        (data) => {
          if (data == null || data == undefined || data.length <= 0) {
            this.olo.isOLO = false;
            this.isOlo = this.olo.isOLO;
            return;
          }
          this.olo.oloIntegrationTableData = data[0];
          this.olo.isOLO = !(
            this.olo.oloIntegrationTableData.vendorID == '' ||
            this.olo.oloIntegrationTableData.vendorID == null
          );
          this.isOlo = this.olo.isOLO;
          if (this.isOlo) {
            this.isMenuIntegration = true;
          }
        },
        () => {}
      );
  }
  private calculationSubscriber() {
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
    this.allSubsCription.push(
      this.cartService.sub_subtotal.subscribe((data) => {
        this.subtotal = CommonFunctions.roundDigit(data, 2);
      })
    );

    this.allSubsCription.push(
      this.cartService.sub_total.subscribe((data) => {
        this.total = CommonFunctions.roundDigit(data, 2);
      })
    );

    this.allSubsCription.push(
      this.cartService.sub_tax.subscribe((data) => {
        this.tax = CommonFunctions.roundDigit(data, 2);
      })
    );

    this.allSubsCription.push(
      this.cartService.sub_discount.subscribe((data) => {
        this.discount = CommonFunctions.roundDigit(data, 2);
      })
    );

    this.allSubsCription.push(
      this.cartService.sub_shipping.subscribe((data) => {
        this.shippingFee = CommonFunctions.roundDigit(data, 2);
      })
    );
    this.allSubsCription.push(
      this.cartService.sub_reward.subscribe((data) => {
        this.reward = CommonFunctions.roundDigit(data, 2);
      })
    );
  }
  private async checkUpsellAndSet() {
    return new Promise<boolean>(async (resolve) => {
      if (GeneralSetting.getHasUpSold() != 'True') {
        let upsellItem: ItemV2;
        this.upsellService.getUpsellItem().then(
          async (data) => {
            //if (data == null || data.SoldOut || data.IsItem86 == 'False') {
            // Handled This condition while fetching record from SQl it selt no need to again filter out here
            if (data == null) {
              resolve(true);
              return;
            }
            upsellItem = data;
            const currentCountofAlkol = Number(
              GeneralSetting.getCurrentCountofAlkol()
            );

            const maxOrder = Number(
              GeneralSetting.getMaxAlcoholicItemsPerOrder()
            );

            if (
              currentCountofAlkol == maxOrder &&
              upsellItem.ItemCategorySalesTypeID ===
                CategorySalesType.Alcohol.toString()
            ) {
              resolve(true);
            } else {
              const variations =
                await this.databaseService.getPersistentVariationsAsync(
                  upsellItem.ItemID
                );
              upsellItem.Variations = variations;

              const isCombo = upsellItem.IsCombo.toString();

              if (isCombo.toLowerCase() == 'true') {
                const comboItem =
                  await this.databaseService.getPersistentComboGroupItemsAsync(
                    upsellItem.ItemID
                  );
                upsellItem.ComboGroup = comboItem;
              } else {
                const modifier =
                  await this.databaseService.getPersistentModifiersByIDAsync(
                    upsellItem.ItemID
                  );
                upsellItem.Modifiers = modifier;
              }

              let upsellInCart = false;

              for (let i = 0; i < this.itemInCart.length; i++) {
                if (this.itemInCart[i].ItemID == upsellItem.ItemID) {
                  upsellInCart = true;
                }
              }

              if (!upsellInCart) {
                const upsell = await this.doUpsellV2(upsellItem);
                if (
                  this.loyaltyService.loyaltyType == LoyaltyType.LevelUp &&
                  this.userService.isUserLoggedIn
                ) {
                  this.loyaltyService
                    .proposeLevelUpOrder(this.cartService.getCartItems())
                    .then(
                      (data: any) => {
                        if (
                          data.statusCode == '400' &&
                          data.status == 'error'
                        ) {
                          //Problem in LevelUp Proposed Order - case 1: bad data sent
                          this.calculatePrices();
                          resolve(true);
                        } else if (
                          data.statusCode == '200' &&
                          data.status == 'success'
                        ) {
                          //successful response from levelup -
                          this.loyaltyService.levelUpObject.proposal =
                            data.data.proposed_order;
                          this.cartService.reward =
                            this.loyaltyService.levelUpObject.proposal!.discount_amount;
                          this.cartService.sub_reward.next(
                            this.loyaltyService.levelUpObject.proposal!
                              .discount_amount
                          );
                          this.cartService.broadcastAll();
                          this.calculatePrices();

                          resolve(true);
                        }
                      },
                      () => {
                        this.calculatePrices();
                        resolve(true);
                      }
                    );
                } else if (upsell) {
                  this.calculatePrices();
                  resolve(true);
                }
              } else {
                resolve(true);
              }
            }
          },
          (err) => {
            resolve(true);
          }
        );
      } else {
        resolve(true);
      }
    });
  }
  /**
   * @description applies promotional pricing to the upsell item and opens the upsell modal
   * @param {ItemV2} upsellItem Item to upsell
   * @returns empty promise to signal next operation
   */
  private doUpsellV2(upsellItem: ItemV2): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // this.applyUpsellPromos(upsellItem).then(() => {
      GeneralSetting.setHasUpSold('True');
      let isGettingResult = false;

      let oludm = OrderLevelUpsellDialogComponent.open(
        this.modalService,
        upsellItem,
        this.variableUpSell,
        this.modifiableUpSell
      );

      if (oludm) {
        oludm.result
          .then((result: any) => {
            isGettingResult = true;
            this.calculatePrices();
            resolve(true);
          })
          .finally(async () => {
            if (isGettingResult) {
              isGettingResult = false;
              this.paymentStarted = false;
              return;
            }
            this.paymentStarted = false;
            resolve(false);
          });
      } else {
        this.paymentStarted = false;
        resolve(false);
      }
      // .result.finally(async () => {
      //   this.calculatePrices();
      //   resolve(true);
      // });
      // });
    });
  }
  /**
   * @description Master function to control the suite of modals in the payment flow
   *
   *
   */
  async startPaymentAsync() {
    if (this.paymentTaken) {
      return;
    }
    if (this.paymentStarted) {
      return;
    }
    if (this.awaitingLevelUpProposal) {
      return;
    }
    this.paymentStarted = true;
    if (!navigator.onLine) {
      var mdRef = ConnectionLossPopupComponent.open(this.modalService);
      mdRef.result.then(
        (result: any) => {
          if (result != 'close') {
            this.paymentStarted = false;
            this.startPaymentAsync();
          } else {
            this.paymentStarted = false;
            return;
          }
        },
        () => {
          this.paymentStarted = false;
          return;
        }
      );
      this.paymentStarted = false;
      return;
    }

    if (GeneralSetting.getIsConcessionaire().toLowerCase() == 'true') {
      await this.checkConcessionaireAvailableOrNot();

      if (this.cartService.getCartItems().length <= 0) {
        this.paymentStarted = false;
        return;
      }
    }

    if (this.isRemovePricingAndPayments) {
      this.afterPaymentFlow();
    } else {
      if (
        this.subtotal == 0.0 &&
        this.cartService.reward == 0 &&
        !this.parbrink.zeroOrder
      ) {
        let error = this.language
          .getTextElement('cannot_place_zero_order')
          .replace('%d', GeneralSetting.getCurrencySymbol() + '0.00.');
        Snackbar.show(error, 2500);
        this.paymentStarted = false;
        return;
      }
      if (
        this.total <= 0 &&
        GeneralSetting.getHPercentDiscount() == 'False' &&
        GeneralSetting.getAllowCheckoutWithZeroAmountWithLoyalty() == 'False' &&
        !this.parbrink.zeroOrder
      ) {
        let error = this.language
          .getTextElement('cannot_place_zero_order')
          .replace('%d', GeneralSetting.getCurrencySymbol() + '0.00.');
        Snackbar.show(error, 2500);
        this.paymentStarted = false;
        return;
      }
      if (GeneralSetting.getAddIDCheckForAlcohol() == 'True') {
        let alcoholItemCount = GeneralSetting.getCurrentCountofAlkol();
        if (
          alcoholItemCount &&
          Number(alcoholItemCount) > 0 &&
          GeneralSetting.getAlcoholicVerify() == 'false'
        ) {
          let aidm = AlcoholicItemsDialogComponent.open(this.modalService);
          aidm.result.then(async (result: any) => {
            if (result == 'over21') {
              let mpdm = ManagerPinDialogComponent.open(this.modalService);
              mpdm.result.then(
                (result: any) => {
                  if (result == 'login_success') {
                    GeneralSetting.setAcloholicVerify('true');
                    this.proceedAfterAlcoholCheck();
                  } else {
                    this.paymentStarted = false;
                    return;
                  }
                },
                () => {
                  this.paymentStarted = false;
                  return;
                }
              );
            } else if (result == 'under21') {
              this.itemInCart.forEach((item) => {
                if (
                  item.ItemCategorySalesTypeID ==
                  CategorySalesType.Alcohol.toString()
                ) {
                  this.cartService.removeFromCart(item);
                }
              });
              //this.itemInCart = this.itemInCart.filter(item => item.ItemCategorySalesTypeID != CategorySalesType.Alcohol.toString())
              this.itemInCart = this.cartService.getCartItems();
              GeneralSetting.setCurrentCountofAlkol('0');

              this.loyaltyService.levelUpObject.proposal =
                {} as LevelUpProposalResponse;
              this.cartService.reward = 0;
              await this.cartService.broadcastAll();
              if (!this.cartService.isEmpty()) {
                await this.doLevelUpProposal();
                this.proceedAfterAlcoholCheck();
              } else {
                this.removeDiscount();
                if (this.isConcessionaire) {
                  this.router.navigateByUrl('/ConcessionaireGrid');
                } else {
                  this.router.navigateByUrl('/menu');
                }
              }
            } else if (result == 'cancel') {
              this.paymentStarted = false;
              return;
            }
          });
        } else {
          this.proceedAfterAlcoholCheck();
        }
      } else {
        this.proceedAfterAlcoholCheck();
      }
    }
  }
  async checkConcessionaireAvailableOrNot() {
    return new Promise<boolean>(async (resolve) => {
      this.loader.openLoader();

      let allCon = await this.databaseService.getAllConcessionaireFromSql();

      let allOpenCon = allCon.filter((x) => !x.IsClosed && x.IsActive);

      let allRemovedCon = [] as any[];

      let cartItems = this.cartService.getCartItems();

      let allCartConIds = [] as any[];

      cartItems.forEach(function (x) {
        allCartConIds.push({ ConcessionaireId: x.ConcessionaireId });
      });

      let uniqueCon = [
        ...new Map(
          allCartConIds.map((item) => [item['ConcessionaireId'], item])
        ).values(),
      ];

      for (let i = 0; i < uniqueCon.length; i++) {
        if (
          !allOpenCon
            .map((x) => x.ConcessionaireID)
            .includes(uniqueCon[i].ConcessionaireId)
        ) {
          let isConThere = allRemovedCon.filter(
            (x) => x.ConcessionaireID == uniqueCon[i].ConcessionaireId
          );

          if (isConThere.length <= 0) {
            allRemovedCon.push(
              allCon.find(
                (x) => x.ConcessionaireID == uniqueCon[i].ConcessionaireId
              )
            );
          }
        }
      }

      allRemovedCon = allRemovedCon.filter((x) => x != undefined && x != null);

      // this.loader.closeLoader();

      if (allRemovedCon.length > 0) {
        let ccc = new ConcessionaireClosedComponent(
          this.modalService,
          this.isKioskService,
          this.language
        );

        this.modal = ccc.open(allRemovedCon);

        this.modal.result.then(async (result: any) => {
          if (result == 'removeItems') {
            let removeConIds = allRemovedCon.map((x) => x.ConcessionaireID);

            let unavailableItems = this.cartService
              .getCartItems()
              .filter((x) => removeConIds.includes(x.ConcessionaireId));

            unavailableItems.forEach((element) => {
              this.cartService.removeItemSub.next(element);

              if (this.cartService.isEmpty()) {
                this.paymentStarted = false;
                resolve(false);
              } else {
                this.paymentStarted = false;
                resolve(false);
              }
            });
            if (this.cartService.isEmpty()) {
              this.paymentStarted = false;
            }
            resolve(!this.cartService.isEmpty());
          }
        });
      } else {
        resolve(true);
      }
    });
  }
  async proceedAfterAlcoholCheck() {
    this.paymentStarted = false;
    this.loader.openLoader();
    this.calculatePrices();
    const isItemsAvailable = await this.checkItemsAvailable();
    this.loader.closeLoader();
    if (isItemsAvailable) {
      if (await this.show86InModel()) {
        const resOpenCustomInfoPopup = await this.openCustomInfoPopup();
        if (!resOpenCustomInfoPopup) {
          this.paymentStarted = false;
          return;
        }
        if (this.olo.isOLO) {
          this.openOLOValidationModel();
        } else if (this.isParBrink) {
          this.openParBrinkValidationModal();
        } else if (this.isOracle) {
          this.doOracleCalculation();
        } else if (this.or.ncrIntegration.isNcrIntegration) {
          // const resCustominfoModal = await this.custominfoModal.getinfo();
          // const resOpenCustomInfoPopup = await this.openCustomInfoPopup();
          // if (!resOpenCustomInfoPopup) {
          //   return;
          // }
          if (this.isNCRCallRunning) {
            this.or.OloTotalLoaderModel = OLOTotalLoaderComponent.open(
              this.modalService
            ).componentInstance;
            let waitUntil = interval(1000).subscribe((x) => {
              if (!this.isNCRCallRunning) {
                waitUntil.unsubscribe();
                this.or.OloTotalLoaderModel?.clear();
                this.proceedAfterNCRValidation();
              }
            });
          } else {
            this.proceedAfterNCRValidation();
          }
        } else this.paymentFlow();
      }
    } else {
      // To fix starting paymentflow after removing item from out of stock dialog
      if (await this.displayItem86Popup()) {
        if (await this.show86InModel()) {
          this.paymentFlow();
        }
      }
    }
  }
  private proceedAfterNCRValidation() {
    let data = this.or.ncrIntegration.ncrValidationResponse;
    if (data && data.data && data.data.status.toLowerCase() == 'validated') {
      this.paymentFlow();
    } else {
      let error = this.language.getTextElement('problem_completing_order');
      OloErrorPopupComponent.open(this.modalService, error);
    }
  }
  private async show86InModel(): Promise<boolean> {
    return new Promise<boolean>(async (resolve: any) => {
      const checkUpsellResponse = await this.checkUpsellAndSet();
      if (!checkUpsellResponse) return;
      await this.setCalculation();

      const resOpenTipDialog = await this.openTipDialog();

      if (!resOpenTipDialog) return;

      resolve(await this.openTableTentPopup());
    });
  }
  async openParBrinkValidationModal(recalculate: boolean = false) {
    if (!this.modalService.hasOpenModals()) {
      this.or.OloTotalLoaderModel = OLOTotalLoaderComponent.open(
        this.modalService
      ).componentInstance;
    }
    const cartItems = this.cartService.getCartItems();
    this.parbrink
      .getParBrinkOrderCalulation(cartItems, this.cartService.reward)
      .then(
        (data: any) => {
          this.or.OloTotalLoaderModel?.clear();

          if (data.resultCode === 0) {
            this.parbrink.Total =
              Number(data.responseData.Order.Total) +
              Number(this.cartService.tip);
            this.parbrink.SubTotal = Number(data.responseData.Order.Subtotal);
            this.parbrink.Tax = Number(data.responseData.Order.Tax);
            this.parbrink.edited = false;
            if (data.responseData.Order.Balance == 0) {
              this.parbrink.zeroOrder = true;
            }
            this.totalChanged.emit(
              Number(data.responseData.Order.total) +
                Number(this.cartService.tip)
            );
            this.taxChanged.emit(Number(data.responseData.Order.Tax));
            if (recalculate) {
              return;
            } else {
              this.paymentFlow();
            }
          } else if (data.resultCode == 1) {
            if (data.responseData.NotAvailableIDs) {
              let blep: string = '';
              let bloop: string[] = [];
              let cart: any = this.cartService.getCartItems();
              for (
                let i = 0;
                i < data.responseData.NotAvailableIDs.length;
                i++
              ) {
                let item: any[] = cart.filter(
                  (x: any) =>
                    x.RefItemId &&
                    x.RefItemId == data.responseData.NotAvailableIDs[i]
                );
                if (item.length > 0) {
                  blep +=
                    ', ' +
                    (item[0].DisplayName != ''
                      ? item[0].DisplayName
                      : item[0].Name);
                } else {
                  for (let j = 0; j < cart.length; j++) {
                    if (cart[j].Modifiers.length > 0) {
                      for (let k = 0; k < cart[j].Modifiers.length; k++) {
                        if (
                          cart[j].Modifiers[k].Ingredients &&
                          cart[j].Modifiers[k].Ingredients.length > 0
                        ) {
                          for (
                            let l = 0;
                            l < cart[j].Modifiers[k].Ingredients.length;
                            l++
                          ) {
                            if (
                              cart[j].Modifiers[k].Ingredients[l]
                                .RefIngredientId ==
                                data.responseData.NotAvailableIDs[i] &&
                              cart[j].Modifiers[k].Ingredients[l].IsSelected
                            ) {
                              let str: string =
                                cart[j].DisplayName ?? cart[j].name;
                              str = str + '->';
                              let a = '';
                              if (
                                cart[j].Modifiers[k].DisplayName &&
                                cart[j].Modifiers[k].DisplayName != ''
                              ) {
                                a = cart[j].Modifiers[k].DisplayName;
                              } else {
                                if (
                                  cart[j].Modifiers[k].Name &&
                                  cart[j].Modifiers[k].Name != ''
                                ) {
                                  a = cart[j].Modifiers[k].Name;
                                } else {
                                  a = cart[j].Modifiers[k].Category;
                                }
                              }
                              let b = '';
                              if (
                                cart[j].Modifiers[k].Ingredients[l]
                                  .DisplayName &&
                                cart[j].Modifiers[k].Ingredients[l]
                                  .DisplayName != ''
                              ) {
                                b =
                                  cart[j].Modifiers[k].Ingredients[l]
                                    .DisplayName;
                              } else {
                                b = cart[j].Modifiers[k].Ingredients[l].Name;
                              }
                              str = str + a + '->' + b;
                              bloop.push(str);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              blep = blep.slice(2);
              let a = ParBrinkErrorPopUpComponent.open(
                this.modalService,
                this.language,
                data.resultCode,
                blep,
                bloop
              ).componentInstance;
              a.loadText();
            } else {
              let a = ParBrinkErrorPopUpComponent.open(
                this.modalService,
                this.language,
                data.resultCode
              ).componentInstance;
              a.loadText();
              a.modalReference.result.then((data: any) => {
                if (data == 'retry') {
                  if (recalculate) {
                    return;
                  } else {
                    this.startPaymentAsync();
                  }
                } else {
                }
              });
            }
          } else if (data.resultCode == 2) {
            let a = ParBrinkErrorPopUpComponent.open(
              this.modalService,
              this.language,
              data.resultCode
            ).componentInstance;
            a.loadText();
            a.modalReference.result.then((data: any) => {
              if (data == 'retry') {
                if (recalculate) {
                  return;
                } else {
                  this.startPaymentAsync();
                }
              } else {
              }
            });
          }
        },
        (err: any) => {
          this.or.OloTotalLoaderModel?.clear();
          if (
            err.status == '400' &&
            err.error &&
            err.error.responseData &&
            err.error.responseData.ResultCode == 101
          ) {
            let a = ParBrinkErrorPopUpComponent.open(
              this.modalService,
              this.language,
              err.error.responseData.ResultCode,
              err.error.responseData.Message
            ).componentInstance;
            a.loadText();
          } else {
            let title = this.language.getTextElement('titile_uh_oh');
            let subTitle = this.language.getTextElement(
              'problem_completing_order'
            );
            GeneralAlertComponent.open(this.modalService, title, subTitle);
          }
        }
      );
  }
  private async setCalculation() {
    const allCalc = await this.cartService.calculateAll();
    this.subtotal = allCalc[0];
    if (GeneralSetting.getParBrinkIntegrationID() == '') {
      this.tax = allCalc[1];
    }
    this.discount = allCalc[2];
    this.total = allCalc[3];
    this.shippingFee = allCalc[4];
    this.reward = allCalc[5];
  }
  /**
   * @description performs OLO validation and either executes implemented functions or ends execution conditionally
   * @implements {olo.getOloValidation(cartItems, customerName, customerEmail, this.paymentService.TotalTip, this.paymentService.getRandomOrderNo()) }
   * @implements {paymentFlow() }
   * @implements {loyaltyService.removeOLOPunchhReward() if loyaltyType=Punchh and reward applied, but validation fails}
   * @param {-{string} basketid if passed, revalidates same basket}
   * @param cartItems
   * @param customerName
   * @param customerEmail
   */
  async oloFlow(
    cartItems: ItemV2[],
    customerName: string,
    customerEmail: string,
    basketid: string = ''
  ) {
    let auth: string = '';
    if (
      this.loyaltyType == LoyaltyType.OLOPunchh &&
      this.userService.isUserLoggedIn
    ) {
      auth = this.userService.punchhLoyaltyCustomer.authtoken;
    } else if (
      this.loyaltyType == LoyaltyType.OLOPaytronix &&
      this.userService.isUserLoggedIn
    ) {
      auth = this.userService.paytronixCustomer.authtoken;
    }
    const invoiceNo = this.paymentService.getRandomOrderNo();

    this.olo
      .getOloValidation(
        cartItems,
        customerName,
        customerEmail,
        this.paymentService.TotalTip,
        invoiceNo,
        this.loyaltyService.loyaltyType,
        this.loyaltyService.oloPaytronixAppliedRewardID != '',
        auth,
        basketid,
        this.smbdiscount.getDiscount()
      )
      .then(
        (data: OLOResponse) => {
          this.olo.validationRes = data;

          try {
            this.logger.sendLogToServer(
              new loggingData(
                'Olo Validation Response ',
                `OLO Validation Response
                Order Invoice No :- ${invoiceNo}
              `,
                'Response Log',
                JSON.stringify(this.olo.validationRes),
                false
              )
            );
          } catch (e) {}

          this.or.OloTotalLoaderModel?.clear();
          if (this.olo.validationRes.result.toLowerCase() == 'success') {
            this.validatedTotals = new ValidatedTotals(
              this.olo.validationRes.data.total,
              this.olo.validationRes.data.subtotal,
              this.olo.validationRes.data.tax
            );

            this.total = this.olo.validationRes.data.total;
            this.tax = this.olo.validationRes.data.tax;
            this.totalChanged.emit(this.olo.validationRes.data.total);
            this.taxChanged.emit(this.olo.validationRes.data.tax);
            if (
              this.olo.validationRes.basketData &&
              this.olo.validationRes.basketData.discount
            ) {
              this.cartService.setPromotion(
                this.olo.validationRes.basketData.discount,
                true,
                true,
                []
              );
              this.cartService.discount =
                this.olo.validationRes.basketData.discount || 0;
              this.discount = this.olo.validationRes.basketData.discount;
            } else {
              this.cartService.promotionIsTotal = false;

              this.cartService.discount =
                this.olo.validationRes.basketData.discount || 0;
              this.cartService.sub_discount.next(this.cartService.discount);
            }

            this.paymentFlow();
          } else if (
            this.olo.validationRes.result.toLowerCase() == 'fail' ||
            this.olo.validationRes.result.toLowerCase() == 'error'
          ) {
            this.OLOLoyaltyRewardRemoval();

            OloErrorPopupComponent.open(
              this.modalService,
              this.olo.validationRes.message
            );

            if (
              this.olo.validationRes.message.includes(
                'Thanks for the kind thought'
              )
            ) {
              this.paymentService.TotalTip = 0;
              this.cartService.setTip(0);
              this.cartService.calculateTotal();
            }
          } else {
            let errorMsg = this.language.getTextElement(
              'problem_completing_order'
            );
            OloErrorPopupComponent.open(this.modalService, errorMsg);
          }
        },
        (error: any) => {
          this.or.OloTotalLoaderModel?.clear();
          let errorMsg = this.language.getTextElement(
            'problem_completing_order'
          );
          OloErrorPopupComponent.open(this.modalService, errorMsg);

          try {
            this.logger.sendLogToServer(
              new loggingData(
                'Olo Validation Error ',
                `OLO Validation Error
                Order Invoice No :- ${invoiceNo}
              `,
                'Response Log',
                JSON.stringify(error),
                false
              )
            );
          } catch (e) {}
        }
      )
      .catch((x: any) => {
        this.OLOLoyaltyRewardRemoval().then((rewardRemoved: boolean) => {
          if (rewardRemoved) {
            this.or.OloTotalLoaderModel?.clear();
          } else {
            this.or.OloTotalLoaderModel?.clear();
            let errorMsg = this.language.getTextElement(
              'problem_completing_order'
            );
            OloErrorPopupComponent.open(this.modalService, errorMsg);
          }
        });
      });
  }
  OLOLoyaltyRewardRemoval() {
    return new Promise<boolean>((resolve) => {
      if (!this.userService.isUserLoggedIn) {
        resolve(false);
      } else if (this.loyaltyService.selectedLoyaltyReward == '') {
        resolve(false);
      } else if (this.loyaltyService.loyaltyType == LoyaltyType.OLOPaytronix) {
        this.loyaltyService
          .removeOLOPaytronixReward()
          .then((data: any) => {
            if (data.statusCode == '200' && data.status == 'success') {
              this.loyaltyService.oloPaytronixAppliedRewardID = '';
              this.loyaltyService.selectedLoyaltyReward = '';
              this.loyaltyService.subSelectedLoyaltyReward.next('');
              this.cartService.removeLoyaltyItem();
            }
          })
          .finally(() => {
            resolve(true);
          });
      } else if (this.loyaltyService.loyaltyType == LoyaltyType.OLOPunchh) {
        this.loyaltyService
          .removeOLOPunchhReward()
          .then((data: any) => {
            if (data.statusCode == '200' && data.status == 'success') {
              this.loyaltyService.punchhAppliedRewardID = '';
              this.loyaltyService.selectedLoyaltyReward = '';
              this.loyaltyService.subSelectedLoyaltyReward.next('');
              this.cartService.removeLoyaltyItem();
            }
          })
          .finally(() => {
            resolve(true);
          });
      } else {
        resolve(false);
      }
    });
  }
  /**
   * @description opens OLO modal and
   * @implements {loyaltyService.applyOLOPunchhReward( ) if loyaltyType = Punchh }
   * @implements  {oloFlow(cartItems, customerName, customerEmail) if loyaltyType != Punch}
   * @implements  {oloFlow(cartItems, customerName, customerEmail, basketid) if loyaltyType = Punch}
   * @todo implement error handling related to OLOPunchh reward application
   */
  private async openOLOValidationModel() {
    this.or.OloTotalLoaderModel = OLOTotalLoaderComponent.open(
      this.modalService
    ).componentInstance;
    const cartItems = this.cartService.getCartItems();
    let defaultGuestName = this.language.getTextElement('txt_guest');
    const customerName =
      GeneralSetting.getCustomerName() == ''
        ? defaultGuestName
        : GeneralSetting.getCustomerName();
    let defaultGuestEmail = this.language.getTextElement('txt_guest_email');

    const customerEmail =
      GeneralSetting.getCustomerEmail() == ''
        ? defaultGuestEmail
        : GeneralSetting.getCustomerEmail();

    if (
      this.loyaltyService.loyaltyType == LoyaltyType.OLOPunchh &&
      this.userService.isUserLoggedIn
    ) {
      if (
        this.loyaltyService.selectedLoyaltyReward != '' &&
        this.loyaltyService.punchhAppliedRewardID == ''
      ) {
        this.loyaltyService.applyOLOPunchhReward().then(
          (data: any) => {
            if (data.statusCode == '200' && data.data) {
              if (
                data.data.appliedrewards &&
                data.data.appliedrewards.length > 0
              ) {
                this.loyaltyService.punchhAppliedRewardID =
                  data.data.appliedrewards[0].rewardid;

                this.oloFlow(
                  cartItems,
                  customerName,
                  customerEmail,
                  data.data.id
                );
              } else {
                //need handling for errors on apply rewards
              }
            } else if (
              data.statusCode == '404' &&
              data.data &&
              data.data.message ==
                'Multiple rewards cannot be applied simultaneously'
            ) {
              this.oloFlow(
                cartItems,
                customerName,
                customerEmail,
                data.data.id
              );
            }
          },
          (err: any) => {
            //console.log(err);
          }
        );
      } else {
        this.oloFlow(cartItems, customerName, customerEmail);
      }
    } else if (
      this.loyaltyService.loyaltyType == LoyaltyType.OLOPaytronix &&
      this.userService.isUserLoggedIn
    ) {
      if (
        this.loyaltyService.selectedLoyaltyReward != '' &&
        this.loyaltyService.oloPaytronixAppliedRewardID == ''
      ) {
        this.loyaltyService.applyOLOPaytronixReward().then(
          (data: any) => {
            if (data.statusCode == '200' && data.data) {
              if (
                data.data.appliedrewards &&
                data.data.appliedrewards.length > 0
              ) {
                this.loyaltyService.oloPaytronixAppliedRewardID =
                  data.data.appliedrewards[0].rewardid;
                this.oloFlow(
                  cartItems,
                  customerName,
                  customerEmail,
                  data.data.id
                );
              }
            } else if (
              data.statusCode == '404' &&
              data.data &&
              data.data.message ==
                'Multiple rewards cannot be applied simultaneously'
            ) {
              this.oloFlow(
                cartItems,
                customerName,
                customerEmail,
                data.data.id
              );
            } else {
            }
          },
          (err: any) => {
            //console.log(err);
          }
        );
      } else {
        this.oloFlow(cartItems, customerName, customerEmail);
      }
    } else {
      this.oloFlow(cartItems, customerName, customerEmail);
    }
  }
  /**
   * @description Checks if tip dialog is enabled and opens tip dialog if so. Recalculates total if needed
   * @returns {Promise<boolean>} empty promise to signal next operation
   */
  private openTipDialog(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.showTipDialog === 'False') {
        resolve(true);
      } else {
        let tdm = TipDialogComponent.open(
          this.modalService,
          CommonFunctions.roundDigit(this.total - this.shippingFee, 2),
          this.shippingFee
        );

        this.totalbeforetip = this.total;

        tdm.result.then(
          (data: string) => {
            this.calculatePrices();
            if (data != 'canceled') {
              this.total = this.total + this.shippingFee;
              resolve(true);
            } else {
              if (this.isParBrink) {
                this.parbrink.Tax = 0;
                this.parbrink.Total = 0;
                this.parbrink.SubTotal = 0;
              }

              resolve(false);
            }
          },
          (reject: any) => {
            this.calculatePrices();

            this.logger.sendLogToServer(
              new loggingData(
                'Tip Modal Error',
                'Tip Modal Closed Unexpectedly',
                'Error',
                `Tip Modal Closed Unexpectedly: ${reject}`,
                true
              )
            );
            resolve(false);
          }
        );
      }
    });
  }
  private checkItemsAvailable(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const cartItems = this.cartService.getCartItems();
      this.unavailableItems = [] as ItemV2[];

      this.menuItemService
        .checkBulkItemIsAvailableFromSql(
          cartItems
            .filter((cartItem) => cartItem.ItemID && cartItem.ItemID != '')
            .map((x) => x.ItemID),
          0
        )
        .then(async (itemsAvailable: string[]) => {
          if (itemsAvailable && itemsAvailable.length > 0) {
            let unAvailableItems = cartItems.filter((x) =>
              itemsAvailable.map((y: any) => y.ItemID).includes(x.ItemID)
            );
            this.unavailableItems.push(...unAvailableItems);
          }
          let listModifier86Detail =
            await this.menuItemService.get86StatusByItem(cartItems);
          if (this.unavailableItems.length > 0) {
            listModifier86Detail = listModifier86Detail.filter(
              (x) =>
                !this.unavailableItems
                  .map((y: any) => y.ItemID)
                  .includes(x.ItemID)
            );
          }

          this.unavailableItems.push(...listModifier86Detail);

          if (this.unavailableItems.length > 0) {
            resolve(false);
          } else {
            resolve(true);
          }
        });
    });
  }
  // To check item86 in ingredients
  private async findIngredient86(
    ingredient: any[],
    promisesComboItem: any[]
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const selectedIngredient =
        CommonFunctions.getSelectedModifierIngredientList(ingredient, true);
      if (selectedIngredient) {
        for (let k = 0; k < selectedIngredient.length; k++) {
          const item = selectedIngredient[k] as any;
          if (item.IngredientID && item.IngredientID != '0') {
            promisesComboItem.push(
              await this.menuItemService.checkItemIsAvailableFromSql(
                item.IngredientID,
                1
              )
            );
          }

          if (item.Ingredients && item.Ingredients.length > 0) {
            await this.findIngredient86(item.Ingredients, promisesComboItem);
          }
        }
      }
      resolve(true);
    });
  }
  removeAllListeners() {
    if (this.listeners.length) {
      this.listeners.forEach((x) => {
        window.removeEventListener('message', x);
      });
    }
  }

  /**
   * @description Controller function that performs all operations related to the payment flow and calls the afterPaymentFlow method
   * @implements {loyaltyService.removeOLOPunchhReward() if loyaltyType = Punchh and reward has been applied, without basket being paid }
   */
  private async paymentFlow(levelUpRetry?: any) {
    if (
      this.integration.integration == Integration.Micros3700 &&
      this.cartService.reward &&
      GeneralSetting.getTPLoyaltyDiscountID() == ''
    ) {
      let userError = this.language.getTextElement('loyalty_not_configured');
      let error = this.language.getTextElement('missing_loyalty_discount_id');
      GeneralAlertComponent.open(this.modalService, userError, error);
      return;
    }
    this.calculatePrices();
    const resOpenPaymentPopUp = levelUpRetry
      ? levelUpRetry
      : await this.openPaymentPopup(CommonFunctions.roundDigit(this.total, 2));
    switch (resOpenPaymentPopUp) {
      case 'done':
        //additional state returned in some cases
        //to ensure full resolution of promise
        //and mitigate hanging execution
        return;
      case 'zeroOrder':
        this.handleZeroDollarOrder();
        return;
      case 'canceled':
        //this.OLOLoyaltyRewardRemoval();
        if (!this.userService.isUserLoggedIn) {
          this.cartService.reward = 0;
          this.cartService.tip = 0;
          this.cartService.broadcastAll();
        }
        if (this.isParBrink) {
          this.parbrink.Tax = 0;
          this.parbrink.Total = 0;
          this.parbrink.SubTotal = 0;
        }
        return;
      case 'goBack':
        this.calculatePrices();
        this.total -= this.cartService.tip;
        this.cartService.setTip(0);
        if (this.isOlo || this.isParBrink || this.isOracle) {
          this.total -= this.cartService.tax;
          this.cartService.tax = 0;
        }
        GeneralSetting.setIsTipSelected('false');
        this.openTipDialog().then((data) => {
          if (data) {
            if (this.isOlo) this.openOLOValidationModel();
            else if (this.isParBrink) {
              this.openParBrinkValidationModal();
            } else if (this.isOracle) {
              this.doOracleCalculation();
            } else this.paymentFlow();
          }
        });
        return;

      default:
        GeneralSetting.setPaymentTypeID(resOpenPaymentPopUp.PaymentTypeID);
        this.calculatePrices();
    }
    if (resOpenPaymentPopUp.PaymentTypeID == EPaymentType.LevelUp) {
      this.loyaltyService.levelUpObject.fail = false;
    }
    this.levelUpPaymentSelectedHandler(resOpenPaymentPopUp)
      .then(
        (data: any) => {
          if (data) {
            this.paySwitch(resOpenPaymentPopUp).then(
              async (response: boolean) => {
                this.paymentTaken = true;
                if (response) {
                  if (
                    this.loyaltyService.levelUpObject.proposal &&
                    this.loyaltyService.levelUpObject.proposal.uuid &&
                    resOpenPaymentPopUp.PaymentTypeID != EPaymentType.LevelUp
                  ) {
                    await this.loyaltyService
                      .completeLevelUpOrder()
                      .then((data: any) => {
                        if (data.statusCode == '200') {
                          this.loyaltyService.levelUpObject.completion =
                            data.data.order;
                          this.loyaltyService.levelUpObject.uuid =
                            data.data.order.uuid;
                        }
                      });
                  }
                  this.paymentTaken = true;
                  this.afterPaymentFlow();
                } else {
                  this.paymentTaken = false;
                  this.cartService.broadcastAll();
                }
              }
            );
          } else {
            this.paymentTaken = false;
            if (!this.flag) {
              this.cartService.reward = 0;
              this.cartService.tip = 0;
              this.cartService.broadcastAll();
              if (this.loyaltyService.loyaltyType == LoyaltyType.LevelUp) {
                this.doLevelUpProposal();
              }
            } else {
              this.modalService.dismissAll();
              this.cartService.tip = 0;
              if (!this.userService.isUserLoggedIn) {
                this.cartService.reward = 0;
                this.cartService.broadcastAll();
              }
            }
            this.flag = false;
            return;
          }
        },
        (rej: any) => {
          this.paymentTaken = false;
        }
      )
      .finally(() => {
        this.removeAllListeners();
      });
  }
  listener: EventListener | undefined;
  flag: boolean = false;
  levelUpPaymentSelectedHandler(
    resOpenPaymentPopUp: any = { PaymentTypeID: EPaymentType.LevelUp }
  ) {
    return new Promise<any>(async (resolve, reject) => {
      if (this.loyaltyType == LoyaltyType.LevelUp) {
        if (resOpenPaymentPopUp.PaymentTypeID == EPaymentType.LevelUp) {
          //case1: user logged in, has QR scanned
          if (
            this.userService.isUserLoggedIn &&
            this.loyaltyService.levelUpObject.qr != undefined
          ) {
            // no additional steps needed
            resolve(true);
          } else {
            this.levelUpModal.open(
              this.cartService.total,
              this.cartService.subtotal,
              this.cartService.tax,
              this.cartService.shippingFee,
              this.cartService.discount,
              this.cartService.reward,
              1,
              this.paymentTypes.length == 1 &&
                GeneralSetting.getShowPaymentScreen() != 'True'
            );
            this.levelUpModal.modalReference.result.then(
              (data) => {
                if (data == 1) {
                  this.loyaltyService.levelUpObject.order = undefined;
                  this.loyaltyService.levelUpObject.proposal = undefined;
                  if (
                    this.loyaltyService.levelUpObject.qr &&
                    !this.loyaltyService.levelUpObject.originalQr
                  ) {
                    this.loyaltyService.levelUpObject.originalQr =
                      this.loyaltyService.levelUpObject.qr;
                  }
                  this.paymentFlow({ PaymentTypeID: EPaymentType.LevelUp });
                } else if (data == 2) {
                  if (
                    this.loyaltyService.levelUpObject.qr &&
                    !this.loyaltyService.levelUpObject.originalQr
                  ) {
                    this.loyaltyService.levelUpObject.originalQr =
                      this.loyaltyService.levelUpObject.qr;
                  }
                  if (!this.userService.isUserLoggedIn) {
                    this.cartService.reward = 0;
                    this.cartService.broadcastAll();
                  }
                  this.paymentFlow();
                } else if (data == 0) {
                  this.flag = true;
                  resolve(false);
                } else if (data == 3) {
                  resolve(true);
                  //this.paymentFlow({ PaymentTypeID: EPaymentType.LevelUp });
                }
              },
              (res: any) => {
                resolve(false);
              }
            );
            //case2: user logged in, has no QR scanned
            var messageType = 'startScanner';
            var message = {
              messageType: messageType,
            };
            window.top!.postMessage(message, '*');
            this.listener = (event: any) => {
              if (event.data.messageType === 'ScannerResult') {
                this.removeAllListeners();
                if (event.data.result) {
                  if (
                    this.loyaltyService.levelUpObject.qr &&
                    !this.loyaltyService.levelUpObject.originalQr
                  ) {
                    this.loyaltyService.levelUpObject.originalQr =
                      this.loyaltyService.levelUpObject.qr;
                  }
                  this.loyaltyService.levelUpObject.qr = event.data.result;
                  this.levelUpModal.clear(3);
                  this.or.OloTotalLoaderModel = OLOTotalLoaderComponent.open(
                    this.modalService,
                    false,
                    false,
                    false
                  ).componentInstance;
                }
              }
            };
            this.listeners.push(this.listener);
            window.addEventListener('message', this.listener);
            //case3: user not logged in
          }
        } else {
          if (this.loyaltyService.levelUpObject.fail) {
            resolve(true);
          } else if (
            this.userService.isUserLoggedIn &&
            this.loyaltyService.levelUpObject.proposal &&
            this.loyaltyService.levelUpObject.proposal.uuid
          ) {
            resolve(true);
          } else if (this.userService.isUserLoggedIn) {
            this.or.OloTotalLoaderModel = OLOTotalLoaderComponent.open(
              this.modalService,
              true,
              false,
              false
            ).componentInstance;
            await this.loyaltyService
              .proposeLevelUpOrder(this.cartService.getCartItems())
              .then(
                async (data: any) => {
                  this.or.OloTotalLoaderModel?.clear();
                  if (data.statusCode == '200' && data.status == 'success') {
                    this.loyaltyService.levelUpObject.proposal =
                      data.data.proposed_order;
                    this.cartService.reward =
                      this.loyaltyService.levelUpObject.proposal!.discount_amount;
                    this.cartService.sub_reward.next(
                      this.loyaltyService.levelUpObject.proposal!
                        .discount_amount
                    );
                    this.cartService.broadcastAll().then(() => {
                      if (this.isParBrink) {
                        this.openParBrinkValidationModal(true).then(() => {
                          resolve(true);
                        });
                      } else if (this.isOracle) {
                        this.doOracleCalculation(true).then(() => {
                          resolve(true);
                        });
                      } else {
                        resolve(true);
                      }
                    });
                  } else {
                    let title = this.language.getTextElement('titile_uh_oh');
                    let subTitle = this.language.getTextElement(
                      'problem_contacting_loyalty'
                    );
                    let a = GeneralAlertComponent.open(
                      this.modalService,
                      title,
                      subTitle
                    );
                    a.result.then(
                      (data: any) => {
                        this.loyaltyService.levelUpObject.fail = true;
                        this.loyaltyService.levelUpObject.qr = undefined;
                        resolve(false);
                      },
                      () => {
                        resolve(false);
                      }
                    );
                  }
                },
                (err: any) => {
                  this.or.OloTotalLoaderModel?.clear();
                  // console.log(err);
                  resolve(false);
                }
              )
              .finally(() => {
                try {
                  this.or.OloTotalLoaderModel?.clear();
                } catch (e: any) {}
              });
          } else {
            resolve(true);
          }
        }
      } else {
        resolve(true);
      }
    });
  }
  async doNcrValidationBackground() {
    if (this.ncrSubscription) {
      this.ncrSubscription.unsubscribe();
      this.isNCRCallRunning = false;
    }
    this.isNCRCallRunning = true;
    let invoiceNo = this.paymentService.getRandomOrderNo();

    let ncrOrderData = await this.or.ncrIntegration.getOrderData(
      invoiceNo,
      this.total,
      this.cartService.getCartItems(),
      this.cartService.tip,
      this.cartService.discount,
      this.cartService.promo
    );

    ncrOrderData.Payments[0].Type = 'Other';
    ncrOrderData.Payments[0].Token = '';
    this.ncrSubscription = this.or.ncrIntegration
      .doCallNcr(ncrOrderData, false)
      .pipe(debounceTime(500))
      .subscribe((data: NcrResponse) => {
        this.isNCRCallRunning = false;
        if (
          data &&
          data.data &&
          data.data.status.toLowerCase() == 'validated'
        ) {
          this.or.ncrIntegration.ncrValidationResponse = data;
          this.logger.sendLogToServer(
            new loggingData(
              'Ncr Validation Order Response',
              `Ncr Validation Invoice No :- ${invoiceNo}
                `,
              'Response Success Log',
              JSON.stringify(data),
              true
            )
          );
        } else {
          let str_data = '';
          try {
            str_data = JSON.stringify(data);
          } catch (e) {
            str_data = 'Unknown Error';
          }
          this.logger.sendLogToServer(
            new loggingData(
              'Ncr Validation Order Response Error',
              `Ncr Validation Invoice No :- ${invoiceNo}`,
              'Response Error Log',
              str_data,
              true
            )
          );
        }
      });
  }
  async handleZeroDollarOrder() {
    this.or.OloTotalLoaderModel?.clear();
    GeneralSetting.setPaymentTypeID(EPaymentType.Discount);
    GeneralSetting.removePaymentMethod();
    sessionStorage.setItem('PaymentDisplayName', 'Discount / Reward');
    if (this.loyaltyType == LoyaltyType.LevelUp) {
      await this.loyaltyService.completeLevelUpOrder().then((data: any) => {
        const log = new loggingData(
          'test',
          'a',
          `completeLevelUpOrder`,
          `${JSON.stringify(data)}`,
          true
        );
        this.logger.sendLogToServer(log);
        if (data.statusCode == '200') {
          this.loyaltyService.levelUpObject.completion = data.data.order;
          this.loyaltyService.levelUpObject.uuid = data.data.order.uuid;
        } else {
        }
      });
    }
    this.paymentTaken = true;
    this.afterPaymentFlow();
  }

  async doOracleCalculation(recalculate: boolean = false) {
    let invoiceNo = this.paymentService.getRandomOrderNo();

    try {
      if (!this.modalService.hasOpenModals()) {
        this.or.OloTotalLoaderModel = OLOTotalLoaderComponent.open(
          this.modalService
        ).componentInstance;
      }

      let oracleOrderData = await this.oracle.getOrderData(
        invoiceNo,
        this.total,
        '0',
        '0',
        this.cartService.getCartItems(),
        this.cartService.tip,
        this.cartService.discount,
        this.cartService.promo,
        this.cartService.reward
      );

      this.oracle
        .calculateOrder(oracleOrderData)
        .pipe(timeout(50000))
        .toPromise()
        .then(
          (data: OracleResponse) => {
            this.or.OloTotalLoaderModel?.clear();
            if (data.Code == 0) {
              this.oracle.calculationResponse = data.Message;
              this.logger.sendLogToServer(
                new loggingData(
                  'Oracle Calcualate Order Response',
                  `Oracle Agent Api Calculate Order
                  Invoice No :- ${invoiceNo}
                `,
                  'Response Log',
                  JSON.stringify(data),
                  true
                )
              );
              if (recalculate) {
                return;
              } else {
                this.paymentFlow();
              }
            } else {
              let str_data = '';

              try {
                str_data = JSON.stringify(data);
              } catch (e) {
                str_data = 'Unknown Error';
              }

              OloErrorPopupComponent.open(
                this.modalService,
                data?.DisplayMessage ?? ''
              );

              this.logger.sendLogToServer(
                new loggingData(
                  'Oracle Calcualate Order Response Error',
                  `Oracle Agent Api Calculate Order Error
                    Invoice No :- ${invoiceNo}
                `,
                  'Response Error Log',
                  str_data,
                  true
                )
              );
            }
          },
          (error) => {
            this.or.OloTotalLoaderModel?.clear();
            let errorMsg = this.language.getTextElement(
              'problem_contacting_pos'
            );
            OloErrorPopupComponent.open(this.modalService, errorMsg);
            let str_error = '';

            try {
              str_error = JSON.stringify(error);
            } catch (e) {
              str_error = 'Unknown Error';
            }

            this.logger.sendLogToServer(
              new loggingData(
                'Oracle Calcualate Order Response Error',
                `Oracle Agent Api Calculate Order Error
                Invoice No :- ${invoiceNo}
              `,
                'Response Error Log',
                str_error,
                true
              )
            );
          }
        )
        .finally(() => {
          this.or.OloTotalLoaderModel?.clear();
        });
    } catch (error) {
      this.or.OloTotalLoaderModel?.clear();
      let errorMsg = this.language.getTextElement('problem_contacting_pos');
      OloErrorPopupComponent.open(this.modalService, errorMsg);

      let str_error = '';

      try {
        str_error = JSON.stringify(error);
      } catch (e) {
        str_error = 'Unknown Error';
      }

      this.logger.sendLogToServer(
        new loggingData(
          'Oracle Calcualate Order Response Error',
          `Oracle Agent Api Calculate Order Error Invoice No :- ${invoiceNo} `,
          'Response Error Log',
          str_error,
          true
        )
      );
    }
  }

  private isThirdPartyIntegration() {
    return (
      this.isOlo ||
      this.isParBrink ||
      this.isOracle ||
      this.or.ncrIntegration.isNcrIntegration ||
      GeneralSetting.getOtterIntegrationId() != ''
    );
  }
  /**
   * @description Routes payment options to the appropriate suite of functions
   * @param {-{data} object returned from PaypentPopUp}
   * @returns {-{Promise-boolean}} promise to signal next operation
   */
  private paySwitch(data: any): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      if (!navigator.onLine) {
        var mdRef = ConnectionLossPopupComponent.open(this.modalService);
        mdRef.result.then(
          (result: any) => {
            if (result != 'close') {
              this.paymentStarted = false;
              this.startPaymentAsync();
            } else {
              this.paymentStarted = false;
              return;
            }
          },
          () => {
            this.paymentStarted = false;
            return;
          }
        );
        this.paymentStarted = false;
        return;
      }
      // Check for offline in US connect payment @nilesh
      if (
        this.isThirdPartyIntegration() ||
        (data.PaymentTypeID == EPaymentType.Card.toString() &&
          data.ThirdPartyPaymentID != '17') ||
        data.PaymentTypeID == EPaymentType.HouseCredit.toString() ||
        data.PaymentTypeID == EPaymentType.USConnect.toString() ||
        data.PaymentTypeID == EPaymentType.IDCard.toString() ||
        data.PaymentTypeID == EPaymentType.RFID.toString()
      ) {
        this.loader.openLoader();
        this.dataService
          .IsOnlineCheckSK()
          .pipe(timeout(50000))
          .toPromise()
          .then(
            (res) => {
              this.loader.closeLoader();
              this.afterPaymentOptionSelect(data).then(
                (response) => {
                  resolve(response);
                },
                (error) => {
                  reject(error);
                }
              );
            },
            (error) => {
              this.loader.closeLoader();
              let err = this.language.getTextElement('device_offline_error');
              Snackbar.show(err, 3000);
              reject(error);
            }
          );
      } else {
        this.afterPaymentOptionSelect(data).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            resolve(error);
          }
        );
      }
    });
  }

  private afterPaymentOptionSelect(data: any) {
    return new Promise<boolean>(async (resolve, reject) => {
      if (data.PaymentTypeID == EPaymentType.Card.toString()) {
        this.calculatePrices();

        this.timeOutComponent.stopTimer();

        this.doCardPayment(data).then((response: any) => {
        //  this.timeOutComponent.startTimer();
          if (response) {
            resolve(true);
          } else {
            this.cartService.setTip(0);
            if (!this.userService.isUserLoggedIn) {
              this.cartService.reward = 0;
              this.cartService.broadcastAll();
            }
            resolve(false);
          }
        });
      } else if (data.PaymentTypeID == EPaymentType.Cash.toString()) {
        this.doCashPayment().then((response: any) => {
          resolve(true);
        });
      } else if (data.PaymentTypeID == EPaymentType.HouseCredit.toString()) {
        this.doHouseAccountPayment().then(
          (response: any) => {
            resolve(true);
          },
          () => {}
        );
      } else if (data.PaymentTypeID == EPaymentType.GiftCard.toString()) {
        this.doGiftCardPayment().then(
          (response: any) => {
            resolve(true);
          },
          () => {}
        );
      } else if (data.PaymentTypeID == EPaymentType.USConnect.toString()) {
        this.doUSConnectPayment().then(
          (response: any) => {
            resolve(true);
          },
          (reject: any) => {
            this.cartService.setTip(0);
            resolve(false);
          }
        );
      } else if (data.PaymentTypeID == EPaymentType.LevelUp) {
        this.paymentStarted = true;
        this.doLevelUpPayment().then(
          (response: any) => {
            this.paymentStarted = false;
            if (response) {
              resolve(true);
            } else {
              this.cartService.setTip(0);
              resolve(false);
            }
          },
          () => {}
        );
      } else if (data.PaymentTypeID == EPaymentType.IDCard.toString()) {
        this.doIDCardPayment().then((response: any) => {
          if (response) {
            resolve(true);
          } else {
            this.cartService.setTip(0);
            GeneralSetting.setIsTipSelected('false');
            this.calculatePrices();
            reject();
          }
        });
      } else if (data.PaymentTypeID == EPaymentType.RFID.toString()) {
        this.doRFIDCardPayment().then((response: any) => {
          if (response) {
            resolve(true);
          } else {
            this.cartService.setTip(0);
            GeneralSetting.setIsTipSelected('false');
            this.calculatePrices();
            reject();
          }
        });
      } else {
        reject(false);
      }
    });
  }

  tpPayment: boolean = false;
  doLevelUpPayment() {
    return new Promise(async (resolve, reject) => {
      if (!this.modalService.hasOpenModals()) {
        this.or.OloTotalLoaderModel = OLOTotalLoaderComponent.open(
          this.modalService
        ).componentInstance;
      }
      let a = true;
      if (
        this.loyaltyService.levelUpObject.proposal == undefined ||
        (this.loyaltyService.levelUpObject.order &&
          this.loyaltyService.levelUpObject.order.proposed_order.discount_only)
      ) {
        let sameAccount = false;
        if (
          this.loyaltyService.levelUpObject.order &&
          this.loyaltyService.levelUpObject.order.proposed_order.discount_only
        ) {
          sameAccount = true;
        }
        a = await this.doLevelUpProposal(true, sameAccount);
        await this.cartService.broadcastAll();
        if (this.isParBrink) {
          await this.openParBrinkValidationModal(true);
        } else if (this.isOracle) {
          await this.doOracleCalculation(true);
        }
      }
      if (a) {
        if (this.total == 0) {
          GeneralSetting.setPaymentTypeID(EPaymentType.LevelUp);
          this.handleZeroDollarOrder();
        } else {
          await this.loyaltyService.levelUpPayment().then(
            (data: any) => {
              this.or.OloTotalLoaderModel?.clear();
              if (!data) {
                this.loyaltyService.levelUpObject = {} as LevelUpObject;
                this.levelUpErrorModal().then((data) => {
                  resolve(data);
                });
              } else if (data.statusCode == '200' && data.status == 'success') {
                this.loyaltyService.levelUpObject.completion = data.data.order;
                this.loyaltyService.levelUpObject.uuid = data.data.order.uuid;
                GeneralSetting.setPaymentTypeID(EPaymentType.LevelUp);
                this.tpPayment = true;
                resolve(true);
              } else {
                if (data.statusCode == '409' && data.status == 'error') {
                  //"Only one transaction allowed per customer per check or You have no cards on file.Please add a valid card to pay"
                  this.levelUpErrorModal().then((data) => {
                    resolve(data);
                  });
                } else {
                  this.levelUpErrorModal().then((data) => {
                    resolve(data);
                  });
                }
              }
            },
            () => {}
          );
        }
      } else {
        this.or.OloTotalLoaderModel?.clear();
        this.levelUpErrorModal().then((data) => {
          resolve(data);
        });
      }
    });
  }
  levelUpErrorModal() {
    return new Promise<boolean>((resolve, reject) => {
      this.levelUpModal.open(
        this.cartService.total,
        this.cartService.subtotal,
        this.cartService.tax,
        this.cartService.shippingFee,
        this.cartService.discount,
        this.cartService.reward,
        2,
        this.paymentTypes.length == 1 &&
          GeneralSetting.getShowPaymentScreen() != 'True'
      );
      this.levelUpModal.modalReference.result.then(
        async (data) => {
          if (data == 1) {
            this.loyaltyService.levelUpObject.order = undefined;
            this.loyaltyService.levelUpObject.proposal = undefined;
            if (
              this.loyaltyService.levelUpObject.qr &&
              !this.loyaltyService.levelUpObject.originalQr
            ) {
              this.loyaltyService.levelUpObject.originalQr =
                this.loyaltyService.levelUpObject.qr;
            }
            this.loyaltyService.levelUpObject.qr = undefined;
            this.paymentStarted = false;
            this.paymentFlow({ PaymentTypeID: EPaymentType.LevelUp });
          } else if (data == 2) {
            if (
              this.loyaltyService.levelUpObject.qr &&
              !this.loyaltyService.levelUpObject.originalQr
            ) {
              this.loyaltyService.levelUpObject.originalQr =
                this.loyaltyService.levelUpObject.qr;
            }
            this.loyaltyService.levelUpObject.qr = undefined;
            if (!this.userService.isUserLoggedIn) {
              this.cartService.reward = 0;
              this.loyaltyService.levelUpObject = {} as LevelUpObject;
              this.cartService.broadcastAll();
            } else {
              await this.doLevelUpProposal();
            }
            if (this.oracle.isOracle) {
              await this.doOracleCalculation();
            } else {
              this.paymentStarted = false;
              this.paymentFlow();
            }
          } else {
            resolve(false);
          }
        },
        () => {
          resolve(false);
        }
      );
    });
  }
  /**
   * @description opens the payment options modal to allow guest selection of payment options
   * @param {-{number} total price to display on modal as user's total owed}
   * @returns data received from the payment modal detailing user's choice
   */
  private openPaymentPopup(total: number) {
    return new Promise<any>(async (resolve, reject) => {
      await this.or.showCustomMessageProcess('1');
      if (total) {
        if (
          this.paymentTypes.length > 1 ||
          GeneralSetting.getShowPaymentScreen() != 'False'
        ) {
          let ref = PaymentPopupComponent.open(
            this.modalService,
            total,
            this.tax,
            this.subtotal.toString(),
            this.discount.toString(),
            this.reward,
            this.paymentTypes
          );
          ref.componentInstance.doit();
          ref.result
            .then((data: any) => {
              if (data == 'Error: 1') {
                let userError = this.language.getTextElement(
                  'payment_type_not_configured'
                );
                let error = this.language.getTextElement(
                  'missing_loyalty_tender_id'
                );
                GeneralAlertComponent.open(this.modalService, userError, error);
                resolve('done');
              } else {
                GeneralSetting.setPaymentTypeID(data.PaymentTypeID);
                this.calculatePrices();
                resolve(data);
              }
            })
            .finally(() => {
              ref.componentInstance.doit2();
            });
        } else {
          GeneralSetting.setPaymentTypeID(this.paymentTypes[0].PaymentTypeID);

          if (
            this.paymentTypes[0].PaymentTypeID ==
              EPaymentType.Card.toString() &&
            this.language.languageChoice != LanguageChoice.English
          ) {
            GeneralSetting.setPaymentDisplayName(
              this.language.getTextElement('txt_pay_with_card')
            );
          } else {
            GeneralSetting.setPaymentDisplayName(
              this.paymentTypes[0].PaymentDisplayName
            );
          }

          resolve(this.paymentTypes[0]);
        }
      } else {
        resolve('zeroOrder');
      }
    });
  }
  /**
   * @description performs all operations after payment has been completed
   */
  private async afterPaymentFlow() {
    // console.log("afterPaymentFlow() is called");

    //sessionStorage.setItem('isTipSelected','')
    //const resOpenTableTentPopup = await this.openTableTentPopup();
    GeneralSetting.setUserHasPaid('True');
    await this.or.showCustomMessageProcess('2');

    this.loader.openLoader();
    // console.log("before orderListReq");
    const orderListReq = await this.getOrderData();
    // console.log("orderListReq: ", orderListReq);
    this.loader.closeLoader();
    const resOpenPrintingPopup = await this.openPrintingPopup();

    // DAVE - STAR PRINTER CHANGES
    if (GeneralSetting.getSTARPrinterSP700IPAddress()) {
      //this.printerService.sendOrderDataToSTARPrinter(orderListReq);
    }

    this.openThankYouPopup(orderListReq);
  }
  private getOrderData(): Promise<OrderListReq> {
    return new Promise<OrderListReq>(async (resolve) => {
      let subTotal = this.cartService.calculateSubtotal(false);
      if (this.oracle.isOracle && this.oracle.calculationResponse) {
        if (this.oracle.isOracleMicross) {
          subTotal =
            Number(this.oracle.calculationResponse.TotalsSubTotal) +
            this.cartService.discount +
            this.cartService.reward;
        } else {
          subTotal = Number(
            this.oracle.calculationResponse.TotalsSubTotalWithDiscount
          );
        }
      }
      const totalTip = this.cartService.tip;
      const cartItems = this.cartService.getCartItems();
      this.paymentService.TotalTax = this.tax;
      const transactionData = GeneralSetting.getPaymentResponse();
      let conDiscountDetail = this.cartService.conDetailSub.value;

      // checkpoint
      // console.log("---values check in getOrderData()---");
      // console.log("subTotal: ", subTotal);
      // console.log("totalTip: ", totalTip);
      // console.log("cartItems: ", cartItems);
      // console.log("this.paymentService.TotalTax: ", this.paymentService.TotalTax);
      // console.log("trnasactionData: ", transactionData);
      // console.log("conDiscountDetail: ", conDiscountDetail);

      const orderData = await this.paymentService.getOrderData(
        subTotal,
        cartItems,
        totalTip,
        transactionData,
        this.cartService.discount,
        this.cartService.reward,
        this.shippingFee,
        conDiscountDetail
      );
      if (
        GeneralSetting.getOtterIntegrationId() != '' &&
        orderData.OrderMaster.length &&
        orderData.OrderMaster[0].OrderSplitBill &&
        orderData.OrderMaster[0].OrderSplitBill.length > 0
      ) {
        this.cartService.orderSplitBill =
          orderData.OrderMaster[0].OrderSplitBill;
      }

      if (orderData.OrderMaster.length > 0) {
        let amount = subTotal - orderData.OrderMaster[0].OrderDiscount;
        await this.loyaltyService
          .createCustomerVisit(amount.toString())
          .then((data: any) => {
            resolve(orderData);
          });
      } else {
        resolve(orderData);
      }
    });
  }
  /**
   * @description closes the payment modal
   */
  private completePayment() {
    return new Promise<boolean>((resolve, reject) => {
      setTimeout(() => {
        if (GeneralSetting.getPaymentTypeID() === '2') {
          if (this.or.getPaymentSuccessModelRef() != null) {
            let paymentSuccessModal =
              this.or.getPaymentSuccessModelRef() as any;

            paymentSuccessModal.componentInstance.modalReference.result.then(
              () => {
                resolve(true);
              }
            );
          }

          this.or.closePaymentSuccessModel();
        } else if (GeneralSetting.getPaymentTypeID() === '19') {
          this.or.closeUsConnectSuccessModel();
          resolve(false);
        }
      }, 3000);
    });
  }

  private setCloverPrices(
    cloverTotal: number,
    cloverCardTotal: number,
    cloverTip: number
  ) {
    this.cloverChargeAmount = 0;
    this.total = Number((cloverTotal - cloverTip).toFixed(2));
    this.cartService.cloverTip = cloverTip;
    this.cartService.cloverCardTotal = Number(
      (cloverCardTotal - cloverTip).toFixed(2)
    );
    this.cartService.setTip(0);
  }

  /**
   *
   * @param data information used to determine the default payment processor
   * @returns {Promise<void>} empty promise to signal next operation
   */
  private async doCardPayment(data: any): Promise<boolean> {
    let theTotal = 0;
    theTotal = this.total + this.paymentService.UpChargeAmountCard;

    if (
      this.isOlo &&
      this.olo.validationRes &&
      Object.entries(this.olo.validationRes).length > 0 &&
      this.olo.validationRes.data
    ) {
      theTotal = this.olo.validationRes.data.total;
      this.subtotal = this.olo.validationRes.data.subtotal;
    }

    GeneralSetting.setPaymentMethod(data.ThirdPartyPaymentID);
    if (theTotal == 0) {
      return true;
    }

    // Clover Cloud Pay
    if (data.ThirdPartyPaymentID === '13') {
      // open error modal if clover device is missing necessary values
      if (this.cloverService.getCloverValidation() == false) {
        await this.openCloverPaymentErrorDialog(
          false,
          'This kiosk does not have a Clover payment device set up'
        );
        this.modalService.dismissAll();
        this.setCloverPrices(
          this.total,
          this.cartService.cloverCardTotal,
          this.cartService.tip
        );
        return false;
      } else {
        this.cloverChargeAmount = Number(this.subtotal.toFixed(2));
        this.loader.openLoader();
        // proceed clover payment
        this.loader.closeLoader();
        this.or.openPaymentInsertModal(false, false, true);
        // turn off grubbrr timeout
        if (this.timeOutComponent) {
          this.timeOutComponent.stopTimer();
        }

        await this.cloverService.createCloverCloudPaySaleRequest(
          this.validatedTotals?.SubTotal,
          this.cartService,
          this.validatedTotals?.Tax
        );

        this.loader.closeLoader();
        this.or.closePaymentInsertModel();

        if (this.cloverService.isCloverPaymentCompleted == true) {
          return true;
        }

        if (this.cloverService.isPartialPayment) {
          this.cloverService.createCloverCloudPayRefundRequest(
            this.cloverService.cloverPaymentId.toString(),
            this.cloverService.cloverOrderId.toString()
          );
          this.or.closePaymentInsertModel();
          this.openCloverPaymentErrorDialog(
            false,
            this.language.getTextElement('payment_failed')
          ).then((data: any) => {
            this.modalService.dismissAll();
            this.setCloverPrices(
              this.total,
              this.cartService.cloverCardTotal,
              this.cartService.tip
            );
            return false;
          });
        }

        await this.openCloverPaymentErrorDialog(
          false,
          this.language.getTextElement('payment_failed')
        );
        this.modalService.dismissAll();
        this.setCloverPrices(
          this.total,
          this.cartService.cloverCardTotal,
          this.cartService.tip
        );
        this.calculatePrices();
        return false;
      }
    } else {
      this.calculatePrices();
      return false;
    }
  }

  /**
   * @author Cooper Kennelly
   * @description handles login to the CIMA recycler, returns 200 if already logged in so built in redundancy
   * @returns new Promise<number> per the response status code or zero if 200
   */
  private cimaLogin() {
    return new Promise<number>((resolve, reject) => {
      fetch('https://192.168.100.109:443/api/v1/Auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          'Accept-Encoding': 'gzip, deflate, br',
          Connection: 'keep-alive',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          appId: 'devAppId',
          username: 'admin',
        }),
      })
        .then(
          (response) => {
            if (response.status == 200) {
              response.json().then(
                (data) => {
                  let token: string = data.token;
                  this.cimaToken = token;
                  resolve(0);
                },
                (err) => {
                  reject(err);
                }
              );
            } else {
              resolve(response.status);
            }
          },
          (err) => {
            reject(err);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }
  /**
   * @author Cooper Kennelly
   * @description pulls CIMA status to see if operations are available
   * @returns number promise per the response status code or zero if 200
   */
  private cimaStatus() {
    // network failure, DOC ERRORS 401, 500, 503,
    return new Promise<number>((resolve, reject) => {
      fetch('https://192.168.100.109:443/api/v1/status', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.cimaToken,
        },
      })
        .then(
          (response) => {
            if (response.status == 200) {
              response.json().then(
                (data) => {
                  this.paymentRunAvailability =
                    data.operationsAvailability.payment == 'executable'
                      ? true
                      : false;
                  resolve(0);
                },
                (err) => {
                  reject(err);
                }
              );
            } else {
              resolve(response.status);
            }
          },
          (err) => {
            reject(err);
          }
        )
        .catch((error) => {
          // do something with the error TODO
          reject(error);
        });
    });
  }
  /**
   * @author Cooper Kennelly
   * @description initializes the payment on the CIMA with a nationality and quantity payload
   * @returns new Promise<number> per the status code or zero if 200
   */
  private cimaPayment(theTotal: number) {
    // network error, DOC ERRRS 401, 500, 503,
    return new Promise<number>((resolve, reject) => {
      fetch('https://192.168.100.109:443/api/v1/operation/Payment/start', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + this.cimaToken,
          'Content-Type': 'application/json',
          Accept: '*/*',
          'Accept-Encoding': 'gzip, deflate, br',
          Connection: 'keep-alive',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          Currency: 'USD',
          Amount: Number(theTotal),
        }),
      })
        .then(
          (response) => {
            if (response.status == 200) {
              resolve(0);
            } else {
              resolve(response.status);
            }
          },
          (err) => {
            reject(err);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }
  /**
   * @author Cooper Kennelly
   * @description performs payment-status request and handles response status/codes
   * @returns new Promise<number> return response.status off the paymentStatus call to the CIMA API
   */
  private cimaPaymentResponse(cimaToken: string) {
    return new Promise<number>((resolve, reject) => {
      // network error, abort and repay DOC ERRORS 401, 500, 503, ble
      fetch('https://192.168.100.109:443/api/v1/operation/Payment/status', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + cimaToken,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            response.json().then(
              (data) => {
                if (data.status == 'completed') {
                  clearInterval(this.intID);
                  resolve(0);
                }
                if (data.status == 'faulted') {
                  // run an error handler or something
                }
              },
              (err) => {
                reject(err);
              }
            );
          } else {
            resolve(response.status);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  /**
   * @author Cooper Kennelly
   * @description initializes responseRequest interval, handles payment status request
   * @returns new Promise<boolean>
   */
  cimaPaymentInterval() {
    return new Promise<boolean>((resolve, reject) => {
      this.intID = setInterval(() => {
        this.cimaPaymentResponse.call(this, this.cimaToken).then((data) => {
          if (data == 0) {
            resolve(true);
          }
        });
      }, 2500);
    });
  }
  /**
   * @author Cooper Kennelly
   * @description HOF encompassing all the cash-payment functions as interfacing with the CIMA
   * @returns
   */
  private doCashPayment(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      /**
       * below if conditon used to temporarily remove unfinished code without git revert.
       * remove if condiion when proper check for CIMA cash handler is integrated
       */
      let a: boolean = false;
      if (a) {
        let theTotal = this.total; // im assuming there is no upcharge given its cash
        this.cimaLogin().then(
          (data) => {
            if (data == 0) {
              this.cimaStatus().then(
                (data) => {
                  if (data == 0) {
                    this.cimaPayment(theTotal).then(
                      (data) => {
                        if (data == 0) {
                          this.cimaPaymentInterval().then((data) => {
                            if (data) {
                              this.calculatePrices();
                              GeneralSetting.setPaymentMethod('1');
                              resolve(true);
                            }
                          });
                        } else {
                          //FunctionToHandleCimaPaymentErrorCodes
                        }
                      },
                      (err) => {
                        //custom handling for thrown error.
                      }
                    );
                  } else {
                    //FunctionToHandleCimaStatusErrorCodes
                  }
                },
                (err) => {
                  //custom handling for thrown error.
                }
              );
            } else {
              //FunctionToHandleCimaLoginErrorCodes
            }
          },
          (err) => {
            //custom handling for thrown error.
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  /**
   * @description undefined functionality, left as placeholder until built
   * @returns {Promise<void>} empty promise to signal next operation
   */
  private doHouseAccountPayment(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.calculatePrices();
      resolve(true);
    });
  }
  /**
   * @description undefined functionality, left as placeholder until built
   * @returns {Promise<void>} empty promise to signal next operation
   */
  private doGiftCardPayment(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.calculatePrices();
      resolve(true);
    });
  }
  private sendText(invoiceNo: string) {
    let phone = GeneralSetting.getCustomerPhone();
    let name = GeneralSetting.getCustomerName();

    // replace SMSmessage # with name if name exists
    this.SMSmessage = this.SMSmessage.replace('#', name ? name : 'Guest');
    this.SMSmessage = this.SMSmessage.replace('#', invoiceNo ? invoiceNo : '');

    if (this.canSMS === 'True') {
      // if phone exists send text
      if (phone != null && phone?.length > 0) {
        this.dataService
          .sendCustomerText(phone, this.SMSmessage)
          .toPromise()
          .then(
            (val: any) => {},
            (response: any) => {
              var log = new loggingData(
                'Send SMS',
                'Send SMS Api failed',
                'Send SMS',
                response,
                true
              );
              this.logger.sendLogToServer(log);
            }
          );
      }
    }
  }
  backToMenu(): void {
    if (this.paymentTaken) {
      return;
    }
    if (this.awaitingLevelUpProposal) {
      return;
    }
    this.removeDiscount(false);

    this.cartService.setTip(0);

    /**
     * store applied discount in cart service https://grubbrr.atlassian.net/browse/SK-1958 @nilesh
     */
    if (this.promo && this.promo.selectedSubtotalPromo) {
      this.cartService.selectedSubtotalPromo = this.promo.selectedSubtotalPromo;
    } else {
      this.cartService.selectedSubtotalPromo = {} as CodelessDiscount;
    }
    if (this.promo && this.promo.selectedItemPromo) {
      this.cartService.selectedItemPromo = this.promo.selectedItemPromo;
    } else {
      this.cartService.selectedItemPromo = {} as CodelessDiscount;
    }

    //  this.promo.removeItemLevelDiscount();
    //this.removeDiscount();

    // if (this.isConcessionaire) {
    //   this.router.navigateByUrl('/ConcessionaireGrid');
    // } else {
    this.router.navigateByUrl('/menu');
    // }
  }

  private calculatePrices() {
    this.updateCalculation.emit();
  }

  showCustomInfo(data: boolean) {
    this.cusisshown = data;
  }

  removeDiscount(removeReward: boolean = true) {
    if (!this.promo) return;
    this.cartService.didDiscount = false;
    GeneralSetting.setHPercentDiscount('False');
    if (removeReward) {
      const reward = this.loyaltyService.loyaltyRewards.find(
        (x) => x.id == this.loyaltyService.selectedLoyaltyReward
      );

      if (reward != undefined) {
        this.loyaltyService.updateLoyaltyPoints(Number(reward.points));
      }
      this.cartService.removeLoyaltyItem();
      this.loyaltyService.selectedLoyaltyReward = '';
      this.loyaltyService.subSelectedLoyaltyReward.next('');
    }

    this.cartService.setPromotion(0, true, false, []);
    this.cartService.setPromotion(0, false, false);
    this.paymentService.DiscountAmount = 0;
    this.paymentService.DiscountPer = 0;
    this.discount = 0;
    this.paymentTypeService.isCardPromo = false;

    //remove discount
    this.promo.selectedSubtotalPromo = {} as CodelessDiscount;
    this.cartService.setPromotion(0, true, false, []);
    this.cartService.setPromotion(0, false, false, []);
    this.paymentService.DiscountAmount = 0;
    this.paymentService.DiscountPer = 0;
    this.paymentService.DiscountId = '';
    this.paymentService.DiscountTypeId = '';

    // this.discount = 0;
    this.paymentTypeService.isCardPromo = false;
    // this.promo.selectedItemPromo = {} as CodelessDiscount;
    // this.updateCalculation.emit();

    this.removeEmitter.emit(true);

    this.promo.selectedPromo = {} as CodelessDiscount;

    this.promo.bogos = [];

    GeneralSetting.setDidDiscount('false');
    this.cartService.promo = {} as CodelessDiscount;
    this.calculatePrices();
  }

  //All Model Open
  /**
   * @description Conditionally opens the tableTentmodal
   * @returns {Promise<void>} empty promise to signal next operation
   */
  private openTableTentPopup(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      var currentOption = this.optionsService.getCurrentEatOption();
      if (
        (currentOption && !(currentOption.KioskTableTent == 'True')) ||
        GeneralSetting.getTableTentNumber() != ''
      ) {
        resolve(true);
      } else {
        let tpm = TableTentPopupComponent.open(this.modalService);
        tpm.componentInstance.doit();
        tpm.result
          .then(
            (result: any) => {
              if (result == 'canceled') {
                this.cartService.setTip(0);
                this.paymentStarted = false;
                this.calculatePrices();
                resolve(false);
              } else {
                this.calculatePrices();
                resolve(true);
              }
            },
            (data: any) => {
              this.paymentStarted = false;
            }
          )
          .finally(() => {
            this.KeyboardService.manual = false;
            tpm.componentInstance.doit2();
            this.KeyboardService.KeyboardStatus = false;
          });
      }
    });
  }

  /**
   * @description conditionally opens the customer info popup
   * @returns {Promise<void>} empty promise to signal next operation
   */
  private openCustomInfoPopup(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      // removed customer popup skip for now
      // add that later
      let data = await this.databaseService.getInfo();
      if (
        data.KioskAskCustomerName == 'False' &&
        data.KioskAskCustomerPhone == 'False' &&
        data.KioskAskCustomerEmail == 'False'
      ) {
        resolve(true);
      } else {
        let a = CustominfoPopupComponent.open(
          this.modalService,
          data.KioskAskCustomerName == 'True',
          data.KioskAskCustomerPhone == 'True',
          data.KioskAskCustomerEmail == 'True'
        );
        a.componentInstance.setFormDetailes();
        a.componentInstance.setAllTexts();
        a.componentInstance.setPhoneValidations();
        a.componentInstance.setEmailValidations();

        a.result.then(
          (data: any) => {
            if (data == 'canceled') {
              this.cartService.setTip(0);
              this.paymentStarted = false;
              this.calculatePrices();
              resolve(false);
            } else {
              this.cusisshown = true;
              this.calculatePrices();
              resolve(true);
            }
          },
          (reason: any) => {
            this.paymentStarted = false;
            this.KeyboardService.KeyboardStatus = false;
          }
        );
      }
    });
  }

  private openCloverPaymentErrorDialog(isVerifone: boolean, error: string) {
    // reactivate grubbrr timer
    if (this.timeOutComponent) {
      this.timeOutComponent.startTimer();
    }

    return new Promise<void>((resolve, reject) => {
      let pec = PaymentErrorComponent.open(
        this.modalService,
        this.language,
        isVerifone,
        error,
        false,
        true,
        false,
        true
      );
      pec.result.then((data: any) => {
        resolve(data);
      });
    });
  }

  private setShippingDetailToOrderUpCall(orderData: OrderListReq) {
    if (
      this.loyaltyType == LoyaltyType.LevelUp &&
      this.loyaltyService.levelUpObject.completion
    ) {
      orderData.OrderMaster[0].levelupuuid =
        this.loyaltyService.levelUpObject.completion.uuid;
      if (this.loyaltyService.levelUpObject.qr != undefined) {
        orderData.OrderMaster[0].CustomerMaster.LoyaltyId =
          this.loyaltyService.levelUpObject.qr;
      }
    }

    orderData.OrderMaster[0].ShippingTypeID =
      this.shipping.shippingDetails instanceof DomesticShippingDetails
        ? 1
        : this.shipping.shippingDetails instanceof InternationalShippingDetails
        ? 2
        : 0;

    orderData.OrderMaster[0].ShippingAmount = this.shippingFee;

    if (
      this.shipping.shippingDetails &&
      this.shipping.shippingDetails != undefined
    ) {
      orderData.OrderMaster[0].OnlineCustomerMaster.push({
        Address1:
          this.shipping.shippingDetails instanceof DomesticShippingDetails
            ? this.shipping.shippingDetails.AddressOne +
              ', ' +
              this.shipping.shippingDetails.AddressTwo
            : this.shipping.shippingDetails?.Address,
        Address2:
          this.shipping.shippingDetails instanceof DomesticShippingDetails
            ? this.shipping.shippingDetails.AddressTwo
            : '',
        AddressID: 0,
        BranchID: GeneralSetting.getBranchId(),
        City:
          this.shipping.shippingDetails instanceof DomesticShippingDetails
            ? this.shipping.shippingDetails.City
            : '',
        CityID: Number(
          this.shipping.shippingDetails instanceof DomesticShippingDetails
            ? this.shipping.shippingDetails.CityID
            : '0'
        ),
        CompanyID: GeneralSetting.getCompanyId(),
        CountryID: Number(
          this.shipping.shippingDetails instanceof DomesticShippingDetails
            ? '231'
            : this.shipping.shippingDetails.CountryID
        ),
        CustomerID: Number(GeneralSetting.getCustomerId()),
        DeliveryNotes: '',
        DisplayName: '',
        EmailID: this.shipping.shippingDetails.Email,
        Firstname: this.shipping.shippingDetails.Name,
        Lastname: '',
        MobileCode:
          this.shipping.shippingDetails instanceof DomesticShippingDetails
            ? '1'
            : this.shipping.shippingDetails.MobileCode,
        MobileNo: this.shipping.shippingDetails.Phone,
        StateID: Number(this.shipping.shippingDetails.StateID),
        ZipCode:
          this.shipping.shippingDetails instanceof DomesticShippingDetails
            ? this.shipping.shippingDetails.ZipCode
            : this.shipping.shippingDetails.PostCode,
      });
    }

    return orderData;
  }

  private async getLoyaltyCustomerVisitPoints() {
    return new Promise<any>((res) => {
      let isShowPoints = true;
      let pointsEarned = 0;
      let totalLoyaltyPoints = 0;
      let visitCount = 0;
      let isReturn = true;

      if (this.userService.isUserLoggedIn) {
        if (this.loyaltyService.isLoyalty) {
          if (this.loyaltyType == LoyaltyType.Referrizer) {
            if (this.loyaltyService.customerVisitRes.message != '') {
              isShowPoints = false;
            } else {
              isReturn = false;
              this.loyaltyService.getCustomerVisit().then(
                (data) => {
                  if (data != undefined && data != null) {
                    pointsEarned = data.data.points;

                    const reward = this.loyaltyService.loyaltyRewards.find(
                      (x) => x.id == this.loyaltyService.selectedLoyaltyReward
                    );

                    if (reward != undefined) {
                      totalLoyaltyPoints =
                        this.userService.referrizerLoyaltyUser
                          .loyaltyPointsCount +
                        pointsEarned -
                        Number(reward.points);
                    } else {
                      totalLoyaltyPoints =
                        this.userService.referrizerLoyaltyUser
                          .loyaltyPointsCount + pointsEarned;
                    }
                  }

                  res({
                    isShowPoints: isShowPoints,
                    pointsEarned: pointsEarned,
                    totalLoyaltyPoints: totalLoyaltyPoints,
                    visitCount: visitCount,
                  });
                },
                (error) => {
                  res({
                    isShowPoints: isShowPoints,
                    pointsEarned: pointsEarned,
                    totalLoyaltyPoints: totalLoyaltyPoints,
                    visitCount: visitCount,
                  });
                }
              );
            }
          } else {
            isShowPoints = false;
          }
        }

        visitCount = this.userService.referrizerLoyaltyUser.visitsCount + 1;
      } else {
        isShowPoints = false;
      }

      if (isReturn) {
        res({
          isShowPoints: isShowPoints,
          pointsEarned: pointsEarned,
          totalLoyaltyPoints: totalLoyaltyPoints,
          visitCount: visitCount,
        });
      }
    });
  }

  private async postOrderUpCall(orderData: OrderListReq) {
    let invoiceNo = this.paymentService.getRandomOrderNo();

    this.logger.sendLogToServer(
      new loggingData(
        'Order Up Call',
        `OrderUp Request
        Order Invoice no :- ${invoiceNo}
      `,
        'Request Log',
        JSON.stringify(orderData),
        false
      )
    );

    orderData = this.setShippingDetailToOrderUpCall(orderData);
    let loyaltyRes = await this.getLoyaltyCustomerVisitPoints();
    orderData.OrderMaster[0].RefTotalPoints = loyaltyRes.totalLoyaltyPoints;

    var data = this.cartService.getCartItems();
    await this.printerService.PreparePrintCustomer(data, true);
    // Add stringified print receipt data to our order to be sent to backend in OrderUp Call
    orderData.OrderMaster[0].PrintData = GeneralSetting.getPrintStringifyData();

    this.dataService
      .IsOnlineCheckSK()
      .pipe(timeout(5000))
      .toPromise()
      .then(
        (data) => {},
        async (error) => {
          console.log('sending order to kds direct with timeout of 5 seconds');
          await this.kdsService.sendToKds(orderData);
        }
      );

    await this.dataService
      .submitOrderAsync(orderData)
      .then(
        (response) => {
          if (response.ok) {
            response.json().then((data) => {
              var resData = data as OrderUpRes;

              this.doOloOrderUp(orderData);

              // we need surcharge here for re-printing receipts
              // Checking for Surcharge
              if (this.olo.orderRes.surcharge) {
                orderData.OrderMaster[0].OrderSurcharge =
                  this.olo.orderRes.surcharge;
                resData.OrderMasters[0].OrderSurcharge =
                  this.olo.orderRes.surcharge;
              }

              this.paymentService.addOrderResToLocalDb(resData, orderData);
              this.orderReviewList.cartItems = [];
              try {
                let log = new loggingData(
                  'Status of Order sent to Grubbrr backend: OrderUp Completed',
                  'OrderUp Successfully  Order Invoice no :- ' +
                    orderData.OrderMaster[0].OrderInvoiceNo,
                  'Response Log',
                  'Order Response:' + JSON.stringify(resData),
                  false
                );
                this.logger.sendLogToServer(log);
              } catch (e: any) {
                if (e.message.includes('btoa')) {
                  let log = new loggingData(
                    'Status of Order sent to Grubbrr backend: Logging Error',
                    'OrderUp Successfully Order Invoice no :- ' +
                      orderData.OrderMaster[0].OrderInvoiceNo,
                    'Response Log Error',
                    `Error Logging "Order Response + JSON.stringify(resData)": ${e.message}`,
                    false
                  );
                  this.logger.sendLogToServer(log);
                } else {
                  let log = new loggingData(
                    'Status of Order sent to Grubbrr backend: Unknown Error',
                    'OrderUp Successfully Order Invoice no :- ' +
                      orderData.OrderMaster[0].OrderInvoiceNo,
                    'Response Log Error',
                    `Unknown Error Logging "Order Response + JSON.stringify(resData)": ${e}`,
                    false
                  );
                  this.logger.sendLogToServer(log);
                }
              }
            });

            (async () => {
              try {
                GeneralSetting.removeCustomerId();
                GeneralSetting.removeCustomerLoginEmail();
                GeneralSetting.removeCustomerLoginName();
                GeneralSetting.removeCustomerLoginMobile();
                GeneralSetting.setCustomerReceiptChoice('');

                this.or.OloTotalLoaderModel?.clear();

                let pppm = PrintingProgressPopupComponent.open(
                  this.modalService,
                  this.isDoPrintReceipt
                );

                await pppm.result.then();
                this.or.OloTotalLoaderModel?.open();
                await CommonFunctions.delay(3000);

                let orderInvNo: any =
                  GeneralSetting.getTableTentNumber() != ''
                    ? Number(GeneralSetting.getTableTentNumber())
                    : GeneralSetting.getOrderInvoiceNo();

                this.or.OloTotalLoaderModel?.clear();

                ThankyouPopupComponent.open(
                  this.modalService,
                  orderInvNo,
                  loyaltyRes,
                  orderData.OrderMaster[0].OrderTotal
                );
              } catch (ex) {
                console.log(`exception inside submitOrderAsync async func`);
                //reject(undefined)
              }
            })().catch((e) => console.log('Caught: ' + e)); // Catches it.
          } else {
            this.paymentService.setOrderToOfflineDb(orderData);

            const log = new loggingData(
              'Send Order Details',
              'Send Order Details Api Failed Order Invoice no :- ' +
                orderData.OrderMaster[0].OrderInvoiceNo,
              'Response Log',
              response,
              true
            );
            this.logger.sendLogToServer(log);

            GeneralSetting.removeCustomerId();
            GeneralSetting.removeCustomerLoginEmail();
            GeneralSetting.removeCustomerLoginName();
            GeneralSetting.removeCustomerLoginMobile();
          }

          // Removing this basket id here because some error on live client for same basket used for multiple order
          if (
            this.isOlo &&
            this.olo.validationRes &&
            this.olo.validationRes.data
          ) {
            this.olo.validationRes.data.basketid = '';
          }

          this.calculatePrices();
        },
        (error) => {
          this.paymentService.setOrderToOfflineDb(orderData);

          this.logger.sendLogToServer(
            new loggingData(
              'OrderUp Error',
              `OrderUp Api Failed
              Order Invoice no :- ${invoiceNo}
            `,
              'Response Log Error',
              JSON.stringify(error),
              false
            )
          );

          if (
            this.isOlo &&
            this.olo.validationRes &&
            this.olo.validationRes.data
          ) {
            this.olo.validationRes.data.basketid = '';
          }

          GeneralSetting.removeCustomerId();
          GeneralSetting.removeCustomerLoginEmail();
          GeneralSetting.removeCustomerLoginName();
          GeneralSetting.removeCustomerLoginMobile();
        }
      )
      .catch((error) => {
        this.paymentService.setOrderToOfflineDb(orderData);
      });
  }
  private async openThankYouPopup(orderData: OrderListReq) {
    // Clover Cloud Pay setting Order ID, Transaction ID for backend
    if (this.cloverService.cloverOrderId) {
      orderData.OrderMaster[0].TPOrderID = this.cloverService.cloverOrderId;
      orderData.OrderMaster[0].TransactionID =
        this.cloverService.cloverPaymentId;
      orderData.OrderMaster[0].CardNetworkID =
        this.cloverService.cloverCardType; // add card type (e.g. visa, master card, etc.)
      GeneralSetting.setThirdPartyOrderId(this.cloverService.cloverOrderId);

      // add ExternalOrderDetails for clover backend (sync with android app)
      orderData.OrderMaster[0].ExternalOrderDetails[0] = {
        ExternalOrderDetailID: 0,
        ExternalOrderID: this.cloverService.cloverOrderId,
        OrderID: 0,
        OrderInvoiceNo: GeneralSetting.getOrderInvoiceNo(),
      };
    }

    //to set customer visit points to order up for loyalty user
    if (this.loyaltyService.isLoyalty && this.userService.isUserLoggedIn) {
      // console.log("if (this.loyaltyService && this.userService)");

      if (this.loyaltyType == LoyaltyType.Referrizer) {
        if (
          this.loyaltyService.customerVisitRes &&
          this.loyaltyService.customerVisitRes.message == ''
        ) {
          orderData.OrderMaster[0].RefEarnedPoints =
            this.loyaltyService.customerVisitRes!.points;

          //getting loyalty data
          const reward = this.loyaltyService.loyaltyRewards.find(
            (x) => x.id == this.loyaltyService.selectedLoyaltyReward
          );

          if (reward != undefined) {
            orderData.OrderMaster[0].RefRedeemedPoints = reward.points;
            orderData.OrderMaster[0].DiscountComment =
              'Loyalty ' + reward.title;
            orderData.OrderMaster[0].IsDiscount = true;
          }
          // orderData.OrderMaster[0].RefVisitCount = loyaltyRes.visitCount
        }
      }
    }

    //this.doOloOrderUp(orderData);
    this.postOrderUpCall(orderData);
  }

  private async doOloOrderUp(orderData: OrderListReq) {
    // this.or.OloTotalLoaderModel = OLOTotalLoaderComponent.open(
    //   this.modalService,
    //   true
    // ).componentInstance;

    const orderInvoiceNo = this.paymentService.getRandomOrderNo();
    const customerName = GeneralSetting.getCustomerName();
    let defaultGuestEmail = this.language.getTextElement('txt_guest_email');
    const customerEmail =
      GeneralSetting.getCustomerEmail() == ''
        ? defaultGuestEmail
        : GeneralSetting.getCustomerEmail();

    // to remove loader after 5 sec if api does not return any thing
    // setTimeout(() => {
    //   this.or.OloTotalLoaderModel?.clear();
    // }, 10000);

    let auth: string = '';

    if (
      this.loyaltyType == LoyaltyType.OLOPunchh &&
      this.userService.isUserLoggedIn &&
      this.userService.punchhLoyaltyCustomer &&
      Object.entries(this.userService.punchhLoyaltyCustomer).length > 0
    ) {
      auth = this.userService.punchhLoyaltyCustomer.authtoken;
    } else if (
      this.loyaltyType == LoyaltyType.OLOPaytronix &&
      this.userService.isUserLoggedIn &&
      this.userService.paytronixCustomer &&
      Object.entries(this.userService.paytronixCustomer).length > 0
    ) {
      auth = this.userService.paytronixCustomer.authtoken;
    }

    var oloSubmitPromise = this.olo.submitOrderToOLO(
      customerName,
      customerEmail,
      this.paymentService.getRandomOrderNo(),
      orderData.OrderMaster[0].TokenCount,
      orderData.OrderMaster[0].OrderTotal,
      orderData.OrderMaster[0].OrderTip,
      orderData.OrderMaster[0].TransactionID,
      auth
    );
    oloSubmitPromise.then(
      (data: OLOResponse) => {
        this.or.OloTotalLoaderModel?.clear();
        this.olo.orderRes = data;

        try {
          this.logger.sendLogToServer(
            new loggingData(
              'Olo Order Up Call Res',
              `OLO Order Up Res
                Order Invoice No :- ${orderInvoiceNo}`,
              'Response Log',
              JSON.stringify(this.olo.orderRes),
              false
            )
          );
        } catch (e) {
          this.logger.sendLogToServer(
            new loggingData(
              'Olo Order Up Call Res',
              `OLO Order Up Res Error
                Order Invoice No :- ${orderInvoiceNo}`,
              'Response Log',
              JSON.stringify({ error: 'UndefinedError' }),
              false
            )
          );
        }

        this.or.OloTotalLoaderModel?.clear();

        // Checking for Surcharge
        if (this.olo.orderRes.surcharge) {
          orderData.OrderMaster[0].OrderSurcharge = this.olo.orderRes.surcharge;
        }

        if (
          !orderData.OrderMaster[0].TPOrderID ||
          orderData.OrderMaster[0].TPOrderID == '' ||
          orderData.OrderMaster[0].TPOrderID == '0'
        ) {
          this.or.OloTotalLoaderModel?.clear();
          let error = this.language.getTextElement(
            'payment_recieved_problem_sending_to_kitchen'
          );
          let confirmation = this.language.getTextElement(
            'please_take_receipt_contact_manager'
          );
          let button = this.language.getTextElement('lbl_continue');
          let orpc = OloErrorPopupComponent.open(
            this.modalService,
            error,
            confirmation,
            button,
            true
          );

          orpc.result.then(() => {
            //this.postOrderUpCall(orderData);
          });
        } else {
          //this.postOrderUpCall(orderData);
        }
      },
      (error) => {
        this.or.OloTotalLoaderModel?.clear();
        let err = this.language.getTextElement(
          'payment_recieved_problem_sending_to_kitchen'
        );
        let confirmation = this.language.getTextElement(
          'please_take_receipt_contact_manager'
        );
        let button = this.language.getTextElement('lbl_continue');
        let orpc = OloErrorPopupComponent.open(
          this.modalService,
          err,
          confirmation,
          button,
          true
        );

        orpc.result.then(() => {
          //this.postOrderUpCall(orderData);
        });

        //this.postOrderUpCall(orderData);
      }
    );
  }

  /**
   * @description displays Item86Popup and offers guest to remove the unavailable Items from cart
   * @returns {Promise<void>} empty promise to signal next operation
   */
  private displayItem86Popup(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let i86pm = Items86PopupComponent.open(
        this.modalService,
        this.unavailableItems
      );
      i86pm.result.then(async (result: any) => {
        if (result == 'removeItems') {
          this.unavailableItems.forEach((element) => {
            this.cartService.removeItemSub.next(element);

            if (this.cartService.isEmpty()) {
              resolve(false);
            } else {
              resolve(false);
            }
          });
          resolve(!this.cartService.isEmpty());
        }
      });
    });
  }
  cancelOrderPopup(): void {
    if (this.paymentTaken) {
      return;
    }
    if (this.paymentStarted) {
      return;
    }
    if (this.awaitingLevelUpProposal) {
      return;
    }

    let ccm = AreYouSureComponent.open(this.modalService);
    ccm.result.then(
      (data: string) => {
        if (data === 'cancel') {
          this.removeDiscount();
          try {
            const log = new loggingData(
              'Order Cancel',
              'Order Cancelled by user',
              'Order Cancel',
              JSON.stringify(this.cartService.getCartItems()),
              false
            );
            this.logger.sendLogToServer(log);
          } catch (e: any) {
            if (e.message.includes('btoa')) {
              const log = new loggingData(
                'Order Cancel Logging Error',
                'Order Cancelled by user',
                'Order Cancel Logging Error',
                `Error Logging "JSON.stringify(this.cartService.getCartItemsV2())": ${e.message}`,
                false
              );
              this.logger.sendLogToServer(log);
            } else {
              const log = new loggingData(
                'Order Cancel Unknown Error',
                'Order Cancelled by user',
                'Order Cancel Unknown Error',
                `Unknown Error Logging "JSON.stringify(this.cartService.getCartItemsV2())": ${e}`,
                false
              );
              this.logger.sendLogToServer(log);
            }
          }

          this.cartService.clearCart();
          this.orderReviewList.cartItems = [];
          this.cartService.reward = 0;
          this.cartService.sub_reward.next(0);
          this.loyaltyService.levelUpObject = {} as LevelUpObject;
          this.calculatePrices();

          if (GeneralSetting.getEnableOrderShipment() == 'True') {
            this.shipping.shippingDetails = undefined;
          }

          this.router.navigateByUrl('/touchToStart');
        }
      },
      (reject: any) => {
        //dismissed instead of close
      }
    );
  }
  private openPrintingPopup(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (GeneralSetting.getAskCustomerIfTheyWantReceipt() == 'True') {
        let acrdc = AskCustomerReceiptDialogComponent.open(this.modalService);

        acrdc.result.then(
          (res: any) => {
            this.isDoPrintReceipt = res == 'yes';
            resolve(false);
          },
          (reject: any) => {
            this.isDoPrintReceipt = false;
            resolve(reject);
          }
        );
      }
      //If ask for customer name flag is OFF then print all receipts
      else {
        this.isDoPrintReceipt = true;
        resolve(true);
      }
    });
  }
  openTaxInfoModel() {
    const ref = this.modalService.open(TaxInfoComponent, {
      centered: true,
      windowClass: 'cd',
      size: 'lg',
    });
    ref.componentInstance.selfReference = ref;
  }
  /**
   * @description creates listener for USConnect Scans and handles all USConnect functioanlity
   * @returns {Promise<void>} empty promise to signal next operation
   */
  private doUSConnectPayment(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      window.onmessage = (event: any) => {
        if (event.data.messageType === 'ScannerResult') {
          this.usconnectService
            .getAccountBalance(this.usConnectCreds, event.data.result)
            .toPromise()
            .then(
              (val) => {
                //console.log('the val is', val);
                this.or.closeUsConnectScanComponent('');
                if (JSON.parse(val).code === undefined) {
                  //if sufficient funds => do payment
                  if (Number(JSON.parse(val).available_balance) >= this.total) {
                    let ucafc = UsconnectAccountFoundComponent.open(
                      this.modalService,
                      JSON.parse(val).available_balance,
                      '',
                      '',
                      ''
                    );

                    this.or.UsconnectAccountFoundModel =
                      ucafc.componentInstance as UsconnectAccountFoundComponent;

                    ucafc.result.then((result: any) => {
                      if (result === 'continue') {
                        this.usconnectService
                          .doTransaction(
                            this.usConnectCreds,
                            this.usConnectSerial,
                            event.data.result,
                            this.cartService.getCartItems(),
                            this.cartService.tip
                          )
                          .toPromise()
                          .then(
                            async (resp) => {
                              if (JSON.parse(resp).code === undefined) {
                                sessionStorage.setItem('paymentResponse', resp);
                                this.or.closeUsConnectScanComponent('');

                                UsconnectSuccessComponent.open(
                                  this.modalService
                                );
                                await this.completePayment();
                                resolve(true);
                              } else {
                                this.or.closeUsConnectScanComponent('');
                                var log = new loggingData(
                                  'USConnect Error',
                                  'USConnect Encountered Error ',
                                  'Error USConnect',
                                  `USConnect Encountered Error: ${JSON.parse(
                                    resp
                                  )}`,
                                  true
                                );
                                this.logger.sendLogToServer(log);

                                UsconnectErrorComponent.open(
                                  this.modalService,
                                  JSON.parse(resp).message
                                );

                                reject('fail');
                              }
                            },
                            (err) => {
                              this.or.closeUsConnectScanComponent('');
                              var log = new loggingData(
                                'USConnect Error',
                                'USConnect Encountered Error ',
                                'Error USConnect',
                                `USConnect Encountered Error: ${err}`,
                                true
                              );
                              this.logger.sendLogToServer(log);
                              let error = this.language.getTextElement(
                                'us_connect_could_not_process'
                              );
                              PaymentErrorComponent.open(
                                this.modalService,
                                this.language,
                                false,
                                error
                              );
                            }
                          );
                      }
                    });

                    // this.doUSConnectPayment();
                  } else if (event === 'payment') {
                    this.openPaymentPopup(this.total);
                  } else {
                    UsconnectBalanceLowComponent.open(this.modalService);
                  }
                }
              },
              (err) => {
                this.or.closeUsConnectScanComponent('');
                var log = new loggingData(
                  'USConnect Error',
                  'USConnect Encountered Unknown Error ',
                  'Error USConnect',
                  `USConnect Encountered Error: ${err}`,
                  true
                );
                this.logger.sendLogToServer(log);
                //console.log('cannot do us connect transaction', err);
                reject('fail');
              }
            );
        }
      };

      // get us connect credentials before starting scanner !
      this.usconnectService
        .getUSConnectCredentials()
        .toPromise()
        .then(
          (creds) => {
            if (creds.length > 0) {
              // creds exist -> get serial
              this.usConnectCreds = creds[0];
              this.usconnectService
                .getUSConnectSerial(GeneralSetting.getSerialNo())
                .toPromise()
                .then(
                  (serial) => {
                    if (serial !== '') {
                      // serial exists -> start scanner & prompt user to scan

                      this.or.usConnectScanComponent =
                        UsconnectScanComponent.open(
                          this.modalService,
                          '1',
                          this.total,
                          this.subtotal,
                          this.tax,
                          this.shippingFee,
                          this.discount,
                          this.reward,
                          this.paymentTypes.length == 1 &&
                            GeneralSetting.getShowPaymentScreen() != 'True'
                        );

                      this.or.usConnectScanComponent.result.then(
                        (event: any) => {
                          if (event === 'cancel') {
                            var messageType = 'stopScanner';
                            var message = {
                              messageType: messageType,
                            };
                            window.top!.postMessage(message, '*');
                            reject(false);
                          }
                        }
                      );

                      this.usConnectSerial = serial;
                      var messageType = 'startScanner';
                      var message = {
                        messageType: messageType,
                      };
                      window.top!.postMessage(message, '*');
                    } else {
                      // error message for no us connect serial number setup
                      var log = new loggingData(
                        'USConnect Error',
                        'USConnect Encountered Error Fetching Serial Number',
                        'Error USConnect',
                        `USConnect Encountered Error: No Serial Number Configured`,
                        true
                      );
                      this.logger.sendLogToServer(log);
                      this.or.closeUsConnectScanComponent('');
                      let error = this.language.getTextElement(
                        'us_connect_not_set_up'
                      );
                      UsconnectErrorComponent.open(this.modalService, error);
                    }
                  },
                  (err) => {
                    var log = new loggingData(
                      'USConnect Error',
                      'USConnect Encountered Error Retreiving Unique Serial Number',
                      'Error USConnect',
                      `USConnect Encountered Error: ${err}`,
                      true
                    );
                    this.logger.sendLogToServer(log);
                    console.log(
                      'err gettings us connect device unqiue serial',
                      err
                    );
                    this.or.closeUsConnectScanComponent('');
                    reject('fail');
                    let error = this.language.getTextElement(
                      'us_connect_not_set_up'
                    );
                    UsconnectErrorComponent.open(this.modalService, error);
                  }
                );
            } else {
              // error message for no us connect credentials setup !
              var log = new loggingData(
                'USConnect Error',
                'USConnect Encountered Error Retreiving USConnect Credentials',
                'Error USConnect',
                `USConnect Encountered Error: No USConnect Credentials Available`,
                true
              );
              this.logger.sendLogToServer(log);
              this.or.closeUsConnectScanComponent('');
              reject('fail');
              let error = this.language.getTextElement('us_connect_not_set_up');
              UsconnectErrorComponent.open(this.modalService, error);
            }
          },
          (err) => {
            var log = new loggingData(
              'USConnect Error',
              'USConnect Encountered Error Retreiving USConnect Credentials',
              'Error USConnect',
              `USConnect Encountered Error: USConnect Not Setup`,
              true
            );
            console.log('err getting us connect creds ', err);
            this.or.closeUsConnectScanComponent('');
            reject('fail');
            let error = this.language.getTextElement('us_connect_not_set_up');
            UsconnectErrorComponent.open(this.modalService, error);
          }
        );
    });
    // open us connect payment popup
  }
  private doIDCardPayment(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      // get us connect credentials before starting scanner !
      this.or.usConnectScanComponent = PayWithIdcardLoginComponent.open(
        this.modalService,
        '2'
      );
      this.or.usConnectScanComponent.componentInstance.doit();

      this.or.usConnectScanComponent.result
        .then((event: any) => {
          if (event === 'cancel') {
            this.cartService.setTip(0);
            GeneralSetting.setIsTipSelected('false');
            GeneralSetting.setMemberId('');
            GeneralSetting.setCustomerId('');
            this.calculatePrices();
            resolve(false);
          } else if (event && event.eventtype == 'result') {
            resolve(
              this.proceedAfterRFIDScan(
                event.data,
                EPaymentType.IDCard.toString()
              )
            );
          }
        })
        .finally(() => {
          this.KeyboardService.manual = false;
          this.or.usConnectScanComponent.componentInstance.doit2();
          this.KeyboardService.KeyboardStatus = false;
        });
    });
    // open us connect payment popup
  }

  private doRFIDCardPayment(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      // get us connect credentials before starting scanner !
      this.or.usConnectScanComponent = UsconnectScanComponent.open(
        this.modalService,
        '2'
      );

      this.or.usConnectScanComponent.result.then((event: any) => {
        if (event === 'cancel') {
          this.cartService.setTip(0);
          GeneralSetting.setIsTipSelected('false');
          this.calculatePrices();
          GeneralSetting.setMemberId('');
          GeneralSetting.setCustomerId('');
          resolve(false);
        } else if (event && event.eventtype == 'result') {
          resolve(
            this.proceedAfterRFIDScan(event.data, EPaymentType.RFID.toString())
          );
        }
      });
    });
    // open us connect payment popup
  }
  proceedAfterRFIDScan(
    scanData: string,
    paymentType: string
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.or.closeUsConnectScanComponent('');

      let ucafc = UsconnectAccountFoundComponent.open(
        this.modalService,
        this.total,
        '',
        '',
        ''
      );

      this.or.UsconnectAccountFoundModel =
        ucafc.componentInstance as UsconnectAccountFoundComponent;

      ucafc.result.then((event: any) => {
        if (event === 'continue') {
          //this.doIDCardPayment();
          this.doCashPayment().then((response: any) => {
            resolve(true);
          });
        } else if (event == 'cancel') {
          this.cartService.setTip(0);
          GeneralSetting.setIsTipSelected('false');
          GeneralSetting.setMemberId('');
          GeneralSetting.setCustomerId('');
          this.calculatePrices();
        }
      });

      this.or.UsconnectAccountFoundModel.startLoading();

      this.allSubsCription.push(
        this.dataService.getMemberFromIDCard(scanData).subscribe(
          (data: any) => {
            /***
             * Success on api
             */
            if (data && data.statusCode == 200) {
              this.or.UsconnectAccountFoundModel?.stopLoading();

              this.or.UsconnectAccountFoundModel?.setData(data);

              let tempTotal = this.total;
              if (data.data.MonthlyOrderTotal) {
                tempTotal += Number(data.data.MonthlyOrderTotal);
              }
              if (
                data.data.MonthlyOrderTotal &&
                data.data.MonthlyLimit &&
                Number(data.data.MonthlyOrderTotal) >=
                  Number(data.data.MonthlyLimit)
              ) {
                //Sorry! you have reached your monthly limit.
                this.or.UsconnectAccountFoundModel?.close('');
                let title = this.language.getTextElement('titile_uh_oh');
                let subTitle = this.language.getTextElement(
                  'monthly_limit_reached'
                );
                GeneralAlertComponent.open(this.modalService, title, subTitle);
              } else if (
                data.data.MonthlyLimit == '' ||
                data.data.MonthlyLimit == undefined
              ) {
                // Payment success
                GeneralSetting.setCustomerId(data.data.CustomerID);
                GeneralSetting.setCustomerName(data.data.Name);
                GeneralSetting.setMemberId(data.data.MemberID);
                GeneralSetting.setPaymentTypeID(paymentType);
              } else if (
                data.data.MonthlyLimit &&
                tempTotal <= Number(data.data.MonthlyLimit)
              ) {
                //Payment Success
                GeneralSetting.setCustomerId(data.data.CustomerID);
                GeneralSetting.setCustomerName(data.data.Name);
                GeneralSetting.setMemberId(data.data.MemberID);
              } else if (
                data.data.MonthlyOrderTotal &&
                data.data.MonthlyLimit &&
                Number(data.data.MonthlyOrderTotal) >= 0 &&
                Number(data.data.MonthlyLimit) >= 0 &&
                tempTotal > Number(data.data.MonthlyLimit)
              ) {
                var tempDifference: Number =
                  tempTotal - Number(data.data.MonthlyLimit);
                let title = this.language.getTextElement('titile_uh_oh');
                let subTitle = this.language
                  .getTextElement('exceeded_monthly_limit_by')
                  .replace(
                    '%d',
                    GeneralSetting.getCurrencySymbol() +
                      ' ' +
                      tempDifference.toFixed(2)
                  );

                this.or.UsconnectAccountFoundModel?.close('');

                GeneralAlertComponent.open(this.modalService, title, subTitle);
              }
            } else {
              this.or.UsconnectAccountFoundModel?.stopLoading();

              this.or.UsconnectAccountFoundModel?.close('');

              this.or.UsconnectAccountNotFoundModel =
                UsconnectAccountNotFoundComponent.open(this.modalService, true);

              this.or.UsconnectAccountNotFoundModel.result.then(
                (event: any) => {
                  if (event === 'tryagain') {
                    this.or.closeUsconnectAccountNotFoundModel('');
                    this.cartService.setTip(0);
                    GeneralSetting.setIsTipSelected('false');
                    this.calculatePrices();
                    //this.doIDCardPayment();
                  }
                }
              );
            }
          },
          (response: any) => {
            this.or.UsconnectAccountFoundModel?.stopLoading();

            this.or.UsconnectAccountFoundModel?.close('');

            this.or.UsconnectAccountNotFoundModel =
              UsconnectAccountNotFoundComponent.open(this.modalService, true);

            this.or.UsconnectAccountNotFoundModel.result.then((event: any) => {
              if (event === 'tryagain') {
                this.or.closeUsconnectAccountNotFoundModel('');
                this.cartService.setTip(0);
                GeneralSetting.setIsTipSelected('false');
                this.calculatePrices();
                //this.doIDCardPayment();
              }
            });
            //{"status":"error","statusCode":"404","data":null,"message":"Member not found"}

            var log = new loggingData(
              'Get member by ID Card Failed',
              'Encountered Error on fetching member by id',
              `Error`,
              `Encountered Error  Member ID :${response}`,
              true
            );
            this.logger.sendLogToServer(log);
          }
        )
      );
    });
  }
  getFontSize() {
    if (this.isRemovePricingAndPayments) {
      return '2.5rem';
    }

    return '4rem';
  }
}
