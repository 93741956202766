<div class="codecontainer">
    <div  *ngIf="!processing || correct" class="close" (click)="dismiss()">X</div>
  <div *ngIf="processing" class="loader">
    <img *ngIf="!correct" src="assets\images\loading_gif.gif" />
    <div *ngIf="correct">
      <div class="title primary-color">{{discountAppliedText}}</div>
      <button
      (click)="close()" 
        class="apply background-positive-button-color border-positive-button-color positive-button-text-color"
      >
       {{continueText}}
      </button>
    </div>
  </div>
  <div *ngIf="!processing">
    <div class="title primary-color">{{enterDiscountText}}</div>
    <input
      KeyboardCustom
      layout="default"
      [readonly]="isKiosk"
      [(ngModel)]="discountCode"
      class="promo-input"
      type="text"
      id="promoCode"
      maxlength="50"
    />
    <button
      (click)="closeIter3(discountCode)"
      class="apply background-positive-button-color border-positive-button-color positive-button-text-color"
    >
      {{applyDiscountText}}
    </button>
  </div>
</div>
