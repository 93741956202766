import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs/';
import { ItemTagsService as ItemTagsService1 } from '../../../services/item-tags.service';
import { AlcoholicItemsExceedMaxComponent } from '../../dialogs/alcoholic-items-exceed-max/alcoholic-items-exceed-max.component';
import {
  CategorySalesType,
  CommonFunctions,
  Snackbar,
} from '../../../services/common';
import { GeneralSetting } from '../../../services/generalSetting.service';
import { ItemV2 } from '../../../models/item';
import { Category } from '../../../models/category';
import { itemTags } from '../../../models/item-tags';
import { LanguageService } from 'src/app/services/language.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { ItemFullDescriptionComponent } from '../../dialogs/item-full-description/item-full-description.component';
import { FixedItemDiscount } from 'src/app/models/discounts';
import { DatabaseService } from 'src/app/services/database.service';
import { DiscountService } from 'src/app/services/discount.service';
@Component({
  selector: 'app-item-normal-mode-header',
  templateUrl: './item-normal-mode-header.component.html',
  styleUrls: ['./item-normal-mode-header.component.css'],
})
export class ItemNormalModeHeaderComponent implements OnInit {


  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  @Input() menuItem: ItemV2;

  @Input() Category: Category = {} as Category;

  @Input() parentQuantity: number = 1;

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  accentColor: string | null = '';

  itemTags = {} as itemTags;

  priceColor: string | null = '';

  startAtStr: string = '';

  hideStartAt: string = '';

  tempCurrOrder: number = 0;

  headerDescHasScroll: boolean = false;
  isEdit = false;

  quantity: number = 1; //to check max and min number

  @Output() quantityChange = new EventEmitter<number>();

  imageRadius: string = '';
  bordersTypeItem: boolean = false;
  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();
  languageSub!: Subscription;
  chooseQuantityText: string = '';
  regularPriceText: string = '';
  savingsText: string = '';
  placeholderImage : string = '';
  startsAtText: string = '';
  lengthy: boolean = false;
  description: string = '';

  modalReference: any;
  fixedItemDisc : FixedItemDiscount[] = [] as FixedItemDiscount[];

  constructor(
    private readonly isKioskService: IsKioskService,
    private readonly itemTagsService: ItemTagsService1,
    private readonly modalService: NgbModal,
    private language: LanguageService,
    private readonly dataService: DataService,
    private readonly databaseService: DatabaseService,
    private readonly discountService: DiscountService
  ) {
    this.menuItem = new ItemV2();
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    )
  }
  loadText() {
    this.chooseQuantityText = this.language.getTextElement('txt_choose_a_quantity');
    this.regularPriceText = this.language.getTextElement('txt_regular_price');
    this.savingsText = this.language.getTextElement('txt_savings');
    this.startsAtText = this.language.getTextElement('txt_starts_at');
  }
  ngOnInit(): void {
    if (this.menuItem.FullDescription.length > 162) {
      this.lengthy = true;
    }
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.itemTagsService
      .getItemTagsFromSQLByItemID(this.menuItem.ItemID)
      .then((tags) => {
        this.itemTags = tags[0];
      });

    this.accentColor = GeneralSetting.getAccentColor();
    this.priceColor = GeneralSetting.getPriceColor();
    this.hideStartAt = GeneralSetting.getHideStartsAtForVariablePrice();
    this.imageRadius = GeneralSetting.getBorderPercentage();
    this.bordersTypeItem =
      GeneralSetting.getBorderTypeItems().toLowerCase() == 'true';

    if (
      this.menuItem.Modifiers &&
      this.menuItem.Modifiers.length > 0 &&
      this.hideStartAt === 'False'
    ) {
      this.startAtStr = this.startsAtText;
    }

    this.quantity =
      this.menuItem.OrderMinimumQuantity == 0
        ? 1
        : this.menuItem.OrderMinimumQuantity;

    if (this.menuItem.guid) {
      this.quantity = Number(this.menuItem.Quantity);
    } else {
      this.quantity =
        this.menuItem.OrderMinimumQuantity == 0
          ? 1
          : this.menuItem.OrderMinimumQuantity;
    }

    if (this.menuItem.guid) {
      this.tempCurrOrder = Number(GeneralSetting.getCurrentCountofAlkol());
    } else {
      this.tempCurrOrder =
        Number(GeneralSetting.getCurrentCountofAlkol()) + this.quantity;
    }
    this.setFixedItemDisc();
  }

  async setFixedItemDisc(){
    this.fixedItemDisc = await this.discountService.getFixedItemDisc();
  }

  ngAfterViewInit() {
    this.isOverflow();
  }

  decreaseAmount() {
    let minNo = this.menuItem.OrderMinimumQuantity;

    if (!minNo) minNo = 1;

    let currentQty = Number(this.quantity.toString());
    var isCombo = this.menuItem.IsCombo.toString();

    if(isCombo.toLowerCase() == "0" || isCombo.toLowerCase() == "true"){
      let totalComboAlcoholCount = CommonFunctions.isComboAlcoholic(this.menuItem);
      if(totalComboAlcoholCount > 0){
        let newAlcoholCount =  CommonFunctions.getLatestAlcoholicCount(totalComboAlcoholCount,currentQty,false);
        if (currentQty > minNo) {
          currentQty = currentQty - 1;
          if (
            this.menuItem.ItemCategorySalesTypeID ===
            CategorySalesType.Alcohol.toString()
          ) {
            this.tempCurrOrder -= 1;
          }
          GeneralSetting.setCurrentCountofAlkol(newAlcoholCount);
        }
      } else {
        if (currentQty > minNo) {
          currentQty = currentQty - 1;
          if (
            this.menuItem.ItemCategorySalesTypeID ===
            CategorySalesType.Alcohol.toString()
          ) {
            this.tempCurrOrder -= 1;
          }
        }
      }
    } else {
      if (currentQty > minNo) {
        currentQty = currentQty - 1;
        if (
          this.menuItem.ItemCategorySalesTypeID ===
          CategorySalesType.Alcohol.toString()
        ) {
          this.tempCurrOrder -= 1;
        }
      }
    }

    this.quantity = currentQty;
    this.parentQuantity = this.quantity;
    this.quantityChange.emit(this.quantity);
  }

  increaseAmount() {
    if (!this.menuItem) return;

    this.increaseQuantity(this.menuItem);
  }
  increaseQuantity(menuItem: ItemV2) {
    let currentQty: number = Number(this.quantity.toString());
    // Checks fixed item quantity limit
    if(this.fixedItemDisc != undefined && this.fixedItemDisc.length > 0){
      var isFixedItemAvail = this.discountService.isFixedItemAvail(this.fixedItemDisc[0],menuItem, currentQty);
      if(isFixedItemAvail){
        return;
      }
    }
    const maxNo: number = this.menuItem.OrderMaximumQuantity;
    const alkolMax: number = Number(
      GeneralSetting.getMaxAlcoholicItemsPerOrder()
    );
    // let currentAlcoholCount = Number(GeneralSetting.getCurrentCountofAlkol());
    //const minQuantity = Number(menuItem.OrderMinimumQuantity);
    //let qtyvalue = Number(this.parentQuantity);
    var isCombo = menuItem.IsCombo.toString();

    if(isCombo.toLowerCase() == "0" || isCombo.toLowerCase() == "true"){
      let totalComboAlcoholCount = CommonFunctions.isComboAlcoholic(menuItem);
      if(totalComboAlcoholCount > 0){
        let newAlcoholCount =  CommonFunctions.getLatestAlcoholicCount(totalComboAlcoholCount,currentQty,true);
        if(newAlcoholCount <= Number(alkolMax)){
          GeneralSetting.setCurrentCountofAlkol(newAlcoholCount.toString());
          currentQty = currentQty + 1;
          this.quantity = currentQty;
        } else {
          AlcoholicItemsExceedMaxComponent.open(this.modalService);
        }
      } else {
        if (!maxNo) {
          if (
            this.menuItem.ItemCategorySalesTypeID ===
              CategorySalesType.Alcohol.toString() &&
            alkolMax > 0
          ) {
            this.checkQtyOfAlcohol(this.quantity);
          } else {
            currentQty = currentQty + 1;
            this.quantity = currentQty;
          }
        } else if (currentQty < maxNo) {
          if (
            this.menuItem.ItemCategorySalesTypeID ===
              CategorySalesType.Alcohol.toString() &&
            alkolMax > 0
          ) {
            this.checkQtyOfAlcohol(this.quantity);
          } else {
            currentQty = currentQty + 1;
            this.quantity = currentQty;
          }
        } else if (currentQty >= maxNo) {
          let error=this.language.getTextElement('item_has_max_quantity').replace('%d',maxNo.toLocaleString())
          Snackbar.show(error, 2500);
        }
      }
    } else {
      if (!maxNo) {
        if (
          this.menuItem.ItemCategorySalesTypeID ===
            CategorySalesType.Alcohol.toString() &&
          alkolMax > 0
        ) {
          this.checkQtyOfAlcohol(this.quantity);
        } else {
          currentQty = currentQty + 1;
          this.quantity = currentQty;
        }
      } else {
        if (
          this.menuItem.ItemCategorySalesTypeID ===
            CategorySalesType.Alcohol.toString() &&
          alkolMax > 0
        ) {
          this.checkQtyOfAlcohol(this.quantity);
        } else {
          currentQty = currentQty + 1;
          this.quantity = currentQty;
        }
      }
    }
    this.parentQuantity = this.quantity;
    this.quantityChange.emit(this.quantity);
  }

  private checkQtyOfAlcohol(qty: any) {
    const alkolMax: number = Number(
      GeneralSetting.getMaxAlcoholicItemsPerOrder()
    );

    const itemMax: number = this.menuItem.OrderMaximumQuantity;

    if (alkolMax !== null && Number(alkolMax) > 0) {
      const remaining = Number(alkolMax) - Number(this.tempCurrOrder);

      const currentValue: number = this.quantity;
      if (Number(itemMax) > 0) {
        if (currentValue < Number(itemMax) && remaining > 0) {
          this.quantity += 1;
          this.tempCurrOrder += 1;
        } else if (remaining <= 0) {
          AlcoholicItemsExceedMaxComponent.open(this.modalService);
        }
      } else {
        if (remaining > 0) {
          this.quantity += 1;
          this.tempCurrOrder += 1;
        } else {
          AlcoholicItemsExceedMaxComponent.open(this.modalService);
        }
      }
    }
  }

  private isOverflow() {
    const element = document.getElementById('arrowDown');
    if (element) {
      if (element.scrollHeight > element.clientHeight) {
        this.headerDescHasScroll = true;
      } else {
        this.headerDescHasScroll = false;
      }
    }
  }

  convertNumber(val: string) {
    return Number(val);
  }

  openModal() {
    const modalRef = ItemFullDescriptionComponent.open(this.modalService);
    modalRef.componentInstance.description = this.menuItem.FullDescription;
    modalRef.componentInstance.modalReference = modalRef;
  }
}
