import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-smb-discounts-alert',
  templateUrl: './smb-discounts-alert.component.html',
  styleUrls: ['./smb-discounts-alert.component.css']
})
export class SMBDiscountsAlertComponent implements OnInit {
  modalReference:NgbModalRef|undefined
  message:string=''
  languageSub!:Subscription  
  continueText:string=''
  constructor(
    private language:LanguageService) { }

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText(){
    this.continueText=this.language.getTextElement('lbl_continue')
  }
  close(){
    if(this.modalReference){
      this.modalReference.close()
    }
  }
  public static open( modalService:any,x:string=''){
      let modalReference = modalService.open(SMBDiscountsAlertComponent, {
        backdrop: 'static',
        centered: true,        
        size: 'lg ',
      });
      modalReference.componentInstance.modalReference = modalReference;
      modalReference.componentInstance.message = x;
      return modalReference;
    }
}
