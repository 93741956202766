import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { IsKioskService } from '../../services/is-kiosk.service';
import { ModifierV2 } from '../../models/item';
import { GeneralSetting } from '../../services/generalSetting.service';
import { ModifierContainerComponent } from '../modifier-container/modifier-container.component';
import { ModifierIngredientV2 } from '../../models/item';
import { CommonFunctions } from 'src/app/services/common';
import { DatabaseService } from 'src/app/services/database.service';
import { PusherService } from 'src/app/services/pusher.service';
import { CartService } from 'src/app/services/cart.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-nested-modifier',
  templateUrl: './nested-modifier.component.html',
  styleUrls: ['./nested-modifier.component.css'],
})
export class NestedModifierComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    for (let i = 0; i < this.allSubscription.length; i++) {
      this.allSubscription[i].unsubscribe();
    }
  }

  allSubscription: Subscription[] = [];

  modalReference: any;

  modifiers: ModifierV2[] = [] as ModifierV2[];

  parentModifier: ModifierV2 = new ModifierV2();

  positiveColor: string = '';

  isModifiersValid!: boolean;

  isBuilderMode: boolean = true;

  itemType: number = 1;

  itemName: string = '';

  itemId: string = '';

  itemDescription: string = '';

  @ViewChild(ModifierContainerComponent)
  modifierContainer!: ModifierContainerComponent;

  //mods selected and methods
  _selectedModifiers: ModifierV2[] = [];

  @Input() setSelectedModifiers(values: {
    inputModifier: ModifierV2[];
    isValid: boolean;
  }) {
    this._selectedModifiers = Array.from(values.inputModifier);
    this.isModifiersValid = values.isValid;
  }

  @Input() setModifiersValid(isValid: boolean) {
    this.isModifiersValid = isValid;
  }

  baseImageUrl: string = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  constructor(
    private readonly modalService: NgbModal,
    private readonly isKioskService: IsKioskService,
    private readonly pusherService: PusherService,
    private readonly db: DatabaseService,
    private cartservice: CartService,
    private logger: LogService,
    private readonly route: ActivatedRoute,
    private language: LanguageService
  ) {
    this.itemId = this.route.snapshot.queryParams['id'];
  }
  updateText: string = '';
  selectText: string = '';
  cancelText: string = ''
  loadText() {
    this.updateText = this.language.getTextElement('lbl_update');
    this.selectText = this.language.getTextElement('lbl_select');
    this.cancelText = this.language.getTextElement('lbl_button_cancel');
  }
  ngOnInit(): void {
    this.allSubscription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );

    this.positiveColor = GeneralSetting.getPositiveColor();

    this.checkAndSetModifiers();

    this.allSubscription.push(
      this.pusherService.Item86ModifierDetails.subscribe((data) => {
        if (this.modifiers) {
          this.db.update86InModifierList(
            data.ItemId,
            data.Status,
            this.modifiers,
            true,
            true
          );
          // this.initializeData(this.modifiers);
          this.cartservice.getCartItems().forEach((item) => {
            this.db.updateItem86Status(
              item,
              data.ItemId,
              data.Status,
              false,
              true,
              false
            );
          });
        }
      })
    );
  }

  private async checkAndSetModifiers() {
    if (this.modifiers.length > 1) {
      for (var i = 0; i < this.modifiers.length; i++) {
        let modifier = this.modifiers[i];

        if(modifier.Ingredients.length <= 0){
          modifier.Ingredients = await this.getNestedModifier(
            modifier.ModifierID
          );
        }
      }

      this.initializeData(this.modifiers);
    } else if (this.modifiers.length > 0 && this.modifiers[0].IsModifier) {
      let modifier = this.modifiers[0];

      if (!modifier.Ingredients || modifier.Ingredients.length <= 0) {
        this.getNestedModifier(modifier.ModifierID).then((modifiers) => {
          modifier.Ingredients = modifiers;
          this.initializeData(this.modifiers);
        });
      }
    }
  }

  private async getNestedModifier(modifierID: string) {
    let parentModifierIngredient = [] as (ModifierIngredientV2 | ModifierV2)[];

    let nestedModifiers = await this.db.getChildModifierByItemAndModifierID(
      this.itemId,
      modifierID
    );

    let Ingredient = await this.db.getIngredientsByModIDFromSql(modifierID);

    parentModifierIngredient.push(...nestedModifiers);
    parentModifierIngredient.push(...Ingredient);

    parentModifierIngredient = parentModifierIngredient.sort((a: any, b: any) =>
      Number(a.DisplayOrder) > Number(b.DisplayOrder) ? 1 : -1
    );

    return parentModifierIngredient;
  }

  public static  open(
    modalService: NgbModal,
    modifier: ModifierV2[],
    modifierIndex: number,
    parentModifier: ModifierV2,
    itemType: number = 1,
    itemName: string = '',
    itemDescription: string = ''
  ) {
    let modalReference = modalService.open(NestedModifierComponent, {
      backdrop: 'static',
      windowClass: 'itemModal nestedModifierModel',
      modalDialogClass: 'itemModal nestedModifierModel',
      centered: true,
      size: 'xl',
    });

    modalReference.componentInstance.modifiers = modifier;
    modalReference.componentInstance.itemType = itemType;
    modalReference.componentInstance.itemName = itemName;
    modalReference.componentInstance.itemDescription = GeneralSetting.getShowItemDescriptioninNestedModifiers() ?  itemDescription : '';
    modalReference.componentInstance.parentModifier = parentModifier;
    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.initializeData(modifier);
    return modalReference;
  }

  open(
    modifier: ModifierV2[],
    modifierIndex: number,
    parentModifier: ModifierV2,
    itemType: number = 1,
    itemName: string = ''
  ) {
    this.modalReference = this.modalService.open(NestedModifierComponent, {
      backdrop: 'static',
      windowClass: 'itemModal nestedModifierModel',
      modalDialogClass: 'itemModal nestedModifierModel',
      centered: true,
      size: 'xl',
    });

    this.initializeData(modifier);

    this.modalReference.componentInstance.modifiers = modifier;
    this.modalReference.componentInstance.itemType = itemType;
    this.modalReference.componentInstance.itemName = itemName;
    this.modalReference.componentInstance.parentModifier = parentModifier;
    this.modalReference.componentInstance.modalReference = this.modalReference;
    return this.modalReference;
  }

  initializeData(modifier: ModifierV2[]) {
    //this check is added when we skip one modifer step and show modifier on top on builder mode
    let isHasAllModifier = true;

    if (modifier.length > 1) {
      if (modifier && modifier.length > 0) {
        for (let i = 0; i < modifier.length; i++) {
          if (modifier[i].IsIngredient) {
            isHasAllModifier = false;
            break;
          }
        }
      }

      if (isHasAllModifier) {
        for (let i = 0; i < modifier.length; i++) {
          let selectedIngrediants = CommonFunctions.getSelectedIngredientCount(
            modifier[i]
          );

          if (selectedIngrediants > 0) {
            modifier[i].IsSelected = true;
          }
        }
      }
    }

    this.modifiers = modifier;
  }

  cancelModal() {
    if (!this.parentModifier.IsSelected) {
      if (this.modifiers && this.modifiers.length > 0) {
        for (let i = 0; i < this.modifiers.length; i++) {
          let mod = this.modifiers[i];
          mod.IsSelected = false;

          if (mod.Ingredients && mod.Ingredients.length > 0) {
            for (let j = 0; j < mod.Ingredients.length; j++) {
              if (mod.Ingredients[j].IsModifier) {
                mod.IsSelected = false;
              } else {
                const ing = mod.Ingredients[j] as ModifierIngredientV2;

                ing.IsSelected =
                  ing.IsDefault == 'True' && ing.IsIngredient86 == 'True';
              }
            }
          }
        }
      }
    }

    this.modalReference.close('cancel');
  }

  addToCart() {
    if (this.setSelectedModifiers.length <= 0) {
      this.modifierAnimation();
      return;
    }

    this.modalReference.close(this._selectedModifiers);
  }

  modifierAnimation() {
    if (this.modifierContainer) {
      this.modifierContainer.modifierScroll();
    }
  }
}
