import { Injectable } from '@angular/core';
import { GeneralSetting } from './generalSetting.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { formatDate } from '@angular/common';
import { exception } from 'console';
import { CommonFunctions } from './common';

@Injectable({
  providedIn: 'root'
})
// prettier-ignore
export class Verifone3CService {
  data: any;
  orderId: string = "";
  // cardType: string = "";

  sequenceNumber: string = "";
  requesterLocationId: string = "";
  requesterTransRefNum: string = "";
  eftSettlementType: string = "";
  emvScenarioId: string = "";
  emvTerminalId: string = "";
  timeStamp: string = "";

  resultReason: string = "";

  // prettier-ignore
  public sendErrorToServer(errorCode: any, nameOfFunction: any, errorDetail: any, debug: any) {
    const errorLog = new loggingData(
      "Verifone3C " + errorCode + " error",
      "Verifone3C failed in: " + nameOfFunction,
      "verifone3C.service.ts",
      "\n" + errorCode + " error" + ": in " + nameOfFunction +
      "\n" + "Detail: " + errorDetail +
      "\n" + "Debug: " + JSON.stringify(debug),
      true
    );

    this.logger.sendLogToServer(errorLog);
  }

  // prettier-ignore
  public sendExceptionToServer(type: any, exceptionDetail: any) {
    const errorLog = new loggingData(
      "Verifone3C " + type + " exception",
      "Verifone3C " + type + " failed: Verifone3C " + type + " request exception",
      "verifone3C.service.ts",
      "\n" + type + " > catch" +
      "\n" + "Detail: " + exceptionDetail,
      true
    );

    this.logger.sendLogToServer(errorLog);
  }
  
  // prettier-ignore
  public sendRefundToTerminal(order: any): Promise<boolean> {
    // console.log("sendRefundToTerminal() is called");

    var createdYearDate = CommonFunctions.getFormatedYearDate(order.CreatedDateFormat);
    var currentYearDateTime = formatDate(Date.now(), 'yyyyMMddHHmmss', 'en-US');

    var body = {
      sequenceNumber: order.OrderInvoiceNo.toString(),
      requesterLocationId: GeneralSetting.getEMVTerminalID(), // "00002092"
      requesterTransRefNum: createdYearDate + "-" + order.TokenCount,
      totalAmount: (Number(order.OrderTotal).toFixed(2)).toString(),
      emvTerminalId: GeneralSetting.getEMVTerminalID(),
      timeStamp: currentYearDateTime.toString()
    }

    return this.http.post(environment.NodeUrl + 'sendRefundToTerminal3C', body, { responseType: 'text' as 'json' })
    .toPromise()
    .then((response) => {
      const jsonResponse = JSON.parse(response.toString());
      // console.log("jsonResponse: ", jsonResponse);
      
      if (jsonResponse.Status == 'Approved') {
        return true;
      }
      else {
        this.sendErrorToServer(jsonResponse.ErrorMessage, "sendRefundToTerminal()", jsonResponse.ErrorMessage, jsonResponse.Debug);
        return false;
      }
    })
    .catch((exception) => {
      // console.log("exception in sendRefundToTerminal(): ", exception);
      this.sendExceptionToServer("refund", exception);

      return false;
    })
  }

  // prettier-ignore
  public sendSaleToTerminal(orderId: any, chargeAmount: any, cartService: any, tax: any): Promise<boolean> {
    /*
    // values check
    console.log("chargeAmount: ", chargeAmount);
    console.log("cartService.tip: ", cartService.tip);
    console.log("tax: ", tax);
    */
    this.orderId = orderId;

    var currentYearDate = formatDate(Date.now(), 'yyyyMMdd', 'en-US');
    var currentYearDateTime = formatDate(Date.now(), 'yyyyMMddHHmmss', 'en-US');
    
    var body = {
      sequenceNumber: orderId.toString(),    // "2"
      requesterLocationId: GeneralSetting.getEMVTerminalID(), // "00002092"
      requesterTransRefNum: currentYearDate.toString() + "-" + (Number(GeneralSetting.getOrderInvoiceNo()) + 1).toString(),   // "20220202-001"
      totalAmount: chargeAmount.toString(),
      emvTerminalId: GeneralSetting.getEMVTerminalID(), // "00002092"
      timeStamp: currentYearDateTime.toString()
    };
    
    return this.http.post(environment.NodeUrl + 'sendSaleToTerminal3C', body, { responseType: 'text' as 'json' })
    .toPromise()
    .then((response) => {
      const jsonResponse = JSON.parse(response.toString());
      // console.log("jsonResponse: ", jsonResponse);
      
      if (jsonResponse.Status == 'Approved') {
        return true;
      }
      else {
        this.resultReason = jsonResponse.ErrorMessage + ' Please try again.';
        this.sendErrorToServer(jsonResponse.ErrorMessage, "sendSaleToTerminal()", jsonResponse.ErrorMessage, jsonResponse.Debug);

        return false;
      }
    })
    .catch((exception) => {
      // console.log("exception in sendSaleToTerminal(): ", exception);
      this.sendExceptionToServer("sale", exception);

      return false;
    })
  }

  // prettier-ignore
  constructor(
    private readonly logger: LogService,
    private http: HttpClient
  ) { }
}