import { Injectable } from '@angular/core';
import { DatabaseHandler } from 'src/app/DatabaseHandler';
import { OrderListReq } from 'src/app/models/OrderListReq';
import { ExternalOrderDetail } from 'src/app/models/ExternalOrderDetail';
import { ItemInventoryReq } from 'src/app/models/ItemInventoryReq';
import { OnlineCustomerMasterReq } from 'src/app/models/OnlineCustomerMasterReq';
import { OrderOtherChargeReq } from 'src/app/models/OrderOtherChargeReq';
import { OrderPartialPaymentMasterReq } from 'src/app/models/OrderPartialPaymentMasterReq';
import { OrderRefundPaymentMasterReq } from 'src/app/models/OrderRefundPaymentMasterReq';
import { OrderMasterReq } from 'src/app/models/OrderMasterReq';
import {
  OrderItemSplitBillReq,
  OrderSplitBillReq,
} from 'src/app/models/OrderSplitBillReq';
import { OrderSuccessPaymentMasterReq } from 'src/app/models/OrderSuccessPaymentMasterReq';
import { CustomerListReq } from 'src/app/models/CustomerListReq';
import { CurbsideDetailMaster } from 'src/app/models/CurbsideDetailMaster';
import { VariationOption } from 'src/app/models/Variations';
import {
  OrderUpRes,
  OrderMasterRes,
  OrderItemMasterRes,
  OrderItemModifierMasterRes,
  CustomerMasterRes,
  CurbsideDetailMasterRes,
  OrderItemModifierGroupMasterRes,
  OrderPaymentSuccess,
} from 'src/app/models/OrderUpRes';
import {
  OrderItemMasterReq,
  OrderItemModifierMasterReq,
} from 'src/app/models/OrderItemMasterReq';
import { LogService, loggingData } from 'src/app/services/log.service';
import { CommonCalculations, CommonFunctions } from 'src/app/services/common';
import { OLOService } from 'src/app/services/olo.service';
import { ModifierV2, ModifierIngredientV2, ItemV2 } from '../models/item';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import {
  IVerifoneOrderRefundReq,
  IVerifoneTransactionResData,
} from 'src/app/models/VerifoneResponse';
import { ParBrinkService } from 'src/app/services/par-brink.service';
import { OracleService } from 'src/app/services/oracle.service';
import { OrderDetailService } from './order-detail.service';
import { ConcessionaireItemSplitBill } from '../models/ConcessionaireItemSplitBill';
import { ConcessioniareOrderSplitBill } from '../models/ConcessioniareOrderSplitBill';
import { ConcessionaireDiscontDetail } from '../models/ConcessioanireDiscountDetail';
import { EPaymentType } from '../models/payment-type';
import { formatDate } from '@angular/common';
import { get } from 'lodash';
import { LanguageService } from './language.service'

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  public OrderTotal: number = 0;

  public SubTotal: number = 0;

  public TotalTax: number = 0;

  public TotalTip: number = 0;

  public TaxPer: number = 0;

  public ExtraCharge: number = 0;

  public IsDiscount: boolean = false;

  public DiscountAmount: number = 0;

  public DiscountPer: number = 0;

  public DiscountTypeId: string = '';

  public DiscountId: string = '';

  public OrderType: number = 0; // like:- Dine In , Take out , Delivery

  public otherCharges: number = 0;

  public CustomerName: string = 'Guest';

  public CustomerEmail: string = '';

  public CustomerMobileNo: string = '0000000000';

  public IsUpChargeCard: boolean = false;

  public IsPercentageCard: boolean = false;

  public PercentageOrAmounCard: number = 0.0;

  public UpChargeAmountCard: number = 0.0;

  public orderInvoiceNo = '';

  public CustomerID: string = '0';

  public PercentageOrFlatRateVal: string = '0';
  public PercentageOrFlatRate: string = '0';

  constructor(
    private readonly logger: LogService,
    private readonly olo: OLOService,
    private readonly parbrink: ParBrinkService,
    private readonly oracle: OracleService,
    private readonly orderDetail: OrderDetailService,
    private readonly language: LanguageService,
  ) { }

  getRandomOrderNo() {
    const invoiceNo = (Math.floor(Math.random() * 99999999) + 1).toString();

    if (this.orderInvoiceNo == '') this.orderInvoiceNo = invoiceNo;

    return this.orderInvoiceNo;
  }

  //For creating and getting random invoice no
  getOrderInvoiceNo() {
    let lastResetDate = new Date(
      formatDate(
        new Date(GeneralSetting.getOrderInvoiceNoResetDate()),
        'MM/dd/YYYY',
        'en-US'
      )
    ).getTime();
    let cDate = formatDate(Date.now(), 'MM/dd/YYYY', 'en-US');
    let currentDate = new Date(cDate).getTime();
    if (currentDate > lastResetDate) {
      GeneralSetting.setOrderInvoiceNo('0');
      GeneralSetting.setOrderInvoiceNoResetDate(cDate);
    }
    const orderInvoiceNox = GeneralSetting.getOrderInvoiceNo();

    const updatedNo = (Number(orderInvoiceNox) + 1).toString();

    GeneralSetting.setOrderInvoiceNo(updatedNo);
  }

  getOrderData(
    subTotal: number,
    cartItem: any,
    orderTip: number,
    transactionData: any,
    orderDiscount: number,
    reward: number,
    shippingFee: number, // pretty sure surcharge is coming in here
    discountConcessionaire: ConcessionaireDiscontDetail[]
  ): Promise<OrderListReq> {
    return new Promise<OrderListReq>(async (resolve) => {
      const objOrderReq = {} as OrderListReq;

      const arrOrderMasterReq = [] as OrderMasterReq[];

      const branchId = GeneralSetting.getBranchId();
      const companyId = GeneralSetting.getCompanyId();

      const appRefId = CommonFunctions.getRandomGuid();
      this.getOrderInvoiceNo();
      const invoiceNo = this.getRandomOrderNo();

      GeneralSetting.setInvoiceNoToDb(invoiceNo);

      const createdDate = CommonFunctions.getDateV2();

      const orderPlaceID = 1;
      //const strOrderTypeID = GeneralSetting.getOrderTypeId();
      //const orderTypeID = parseInt(strOrderTypeID == null ? '0' : strOrderTypeID);
      const orderTypeID = GeneralSetting.getOrderTypeId();
      const staffModuleId =
        GeneralSetting.getStaffModuleID() == ''
          ? 0
          : Number(GeneralSetting.getStaffModuleID());

      let orderInvoiceNo = GeneralSetting.getOrderInvoiceNo();
      let token_count =
        orderInvoiceNo && orderInvoiceNo != null ? Number(orderInvoiceNo) : 0;

      const customerID = GeneralSetting.getCustomerId();
      const customerName: string = this.getCustomerName();

      //Calculations
      subTotal = CommonFunctions.roundDigit(subTotal, 2);
      let extraCharge = CommonFunctions.roundDigit(this.ExtraCharge, 2);
      let orderTax = CommonFunctions.roundDigit(this.TotalTax, 3);

      orderTip = CommonFunctions.roundDigit(orderTip, 2);
      //let orderDiscount = CommonFunctions.roundDigit(this.DiscountAmount, 2);
      let taxableAmount = CommonFunctions.roundDigit(
        this.SubTotal - orderDiscount,
        2
      );

      let isTableTent = await this.orderDetail.isTableTent(orderTypeID);

      let orderPrefix = GeneralSetting.getOrderPrefix();
      if (orderPrefix) {
        orderPrefix += '-';
      }

      let orderTotal = CommonFunctions.roundDigit(
        subTotal +
        extraCharge +
        orderTax +
        orderTip -
        reward -
        orderDiscount +
        this.UpChargeAmountCard +
        shippingFee,
        2
      );

      if (this.olo.isOLO) {
        orderTax = this.olo.validationRes.data.tax;
        taxableAmount = this.olo.validationRes.data.tax;
        orderTotal = this.olo.validationRes.data.total;
        subTotal = this.olo.validationRes.data.subtotal;
        if (this.olo.validationRes.basketData?.totalfees) {
          this.otherCharges = this.olo.validationRes.basketData.totalfees;
        }
        if (
          this.olo.validationRes &&
          this.olo.validationRes.basketData &&
          this.olo.validationRes.basketData.discount &&
          this.olo.validationRes.basketData.discount > 0
        ) {
          orderDiscount = this.olo.validationRes.basketData.discount;
        }
      }
      if (GeneralSetting.getParBrinkIntegrationID() != '') {
        orderTotal = this.parbrink.Total;
        orderTax = this.parbrink.Tax;
        taxableAmount = this.parbrink.Tax;
        subTotal = this.parbrink.SubTotal;
      }
      if (this.oracle.isOracle && this.oracle.calculationResponse) {
        if (this.oracle.isOracleMicross) {
          if (!isNaN(Number(this.oracle.calculationResponse.TotalsSubTotal))) {
            subTotal =
              Number(this.oracle.calculationResponse.TotalsSubTotal) +
              orderDiscount +
              reward;
          }
        } else {
          if (
            !isNaN(
              Number(this.oracle.calculationResponse.TotalsSubTotalWithDiscount)
            )
          ) {
            subTotal = Number(
              this.oracle.calculationResponse.TotalsSubTotalWithDiscount
            );
          }
        }
        if (!isNaN(Number(this.oracle.calculationResponse.TotalsTaxTotals))) {
          orderTax = Number(this.oracle.calculationResponse.TotalsTaxTotals);
          taxableAmount = orderTax;
        }
        if (!isNaN(Number(this.oracle.calculationResponse.TotalsTotalDue))) {
          orderTotal = Number(this.oracle.calculationResponse.TotalsTotalDue);
        }
      }
      subTotal = CommonFunctions.roundDigit(subTotal, 2);
      const customerAppRefId = appRefId;

      const objCustomerMaster = this.getCustomerDetailForOrder(
        customerAppRefId,
        companyId,
        branchId,
        customerID,
        customerName
      );

      const objCartItems = this.getCartItemsForOrder(
        cartItem,
        appRefId,
        companyId,
        branchId
      );

      const orderPayment = this.orderDetail.getOrderPaymentDetails(
        transactionData ? JSON.parse(transactionData) : '',
        orderTotal,
        orderInvoiceNo
      );

      let PaymentGatewayID: string = GeneralSetting.getPaymentMethod();
      var cardNetworkId: string = '0';
      if (PaymentGatewayID == '17' && transactionData) {
        let transactionDataModel = JSON.parse(
          transactionData
        ) as IVerifoneTransactionResData;
        if (transactionDataModel) {
          cardNetworkId = await this.getCardNetworkID(
            transactionDataModel.PAYMENT_MEDIA
          );
        }
      } else if (PaymentGatewayID == '13') {
      } else if (PaymentGatewayID == '14') {
        let tx = JSON.parse(transactionData);
        let parser = new DOMParser();
        let xmlResponse = parser.parseFromString(tx, 'text/xml');

        let extData = tx
          ? xmlResponse
            .getElementsByTagName('ExtData')[0]
            .childNodes[0].nodeValue!.toString()
          : '';
        let arr = extData.split(',');

        // get card type
        for (let element in arr) {
          if (Object.prototype.hasOwnProperty.call(arr, element)) {
            if (arr[element].toString().indexOf('CardType') !== -1) {
              let myArr = arr[element].split('=');
              cardNetworkId = await this.getCardNetworkID(myArr[1]);
            }
          }
        }
      }

      const concessioniareOrderSplitBill = this.getConcessioniareOrderSplitBill(
        cartItem,
        appRefId,
        createdDate,
        subTotal,
        orderTotal,
        orderTip,
        orderDiscount,
        cardNetworkId,
        discountConcessionaire
      );

      orderPayment.AppRefID = appRefId;
      orderPayment.AppRefIDOrder = appRefId;
      orderPayment.OrderInvoiceNumber = invoiceNo;
      const objOrderMasterReq = {
        OrderID: 0,
        OrderInvoiceNo: invoiceNo,
        TableID:
          GeneralSetting.getTableTentNumber() != ''
            ? Number(GeneralSetting.getTableTentNumber())
            : 0,
        KitchenID: 0,
        SectionID: 0,
        ModuleID: 2,
        StaffModuleID: staffModuleId,
        StaffID: 0,
        CompanyID: companyId,
        BranchID: branchId,
        CustomerID: customerID,
        OrderStatusID:
          GeneralSetting.getPaymentTypeID() == '2' ||
            GeneralSetting.getPaymentTypeID() == '19' ||
            GeneralSetting.getPaymentTypeID() == '21' ||
            GeneralSetting.getPaymentTypeID() == '23' ||
            GeneralSetting.getPaymentTypeID() == '6' ||
            GeneralSetting.getPaymentTypeID() == '22'
            ? 2
            : GeneralSetting.getIsRemovePricingAndPayments()
              ? '2'
              : '1', // if paid with credit card -> 2
        Subtotal: subTotal,
        OrderTax: orderTax,
        ExtraCharge: extraCharge,
        ExtraComment: '',
        IsDiscount: this.IsDiscount,
        OrderDiscount: orderDiscount + reward,
        DiscountComment: '',

        // Surcharge
        OrderSurcharge: shippingFee,

        OrderTip: orderTip,
        OrderTotal: orderTotal,
        IsRefunded: false,
        RefundedAmount: 0.0,
        RefundedComment: '',
        OrderTypeID: orderTypeID.toString(),
        DineTypeID: 0,
        CustomerIP: '',
        PaidDateUtc: createdDate.toString(),
        CreatedBy: 0,
        IsDeleted: false,
        CreatedDate: createdDate.toString(),
        CustomOrderNumber: '',
        OrderComment: '',
        IsCancel: false,
        CancelComment: '',
        CancelAmount: 0.0,
        PaymentTypeID:
          GeneralSetting.getPaymentTypeID() != ''
            ? GeneralSetting.getPaymentTypeID()
            : '1', // 2 for card, 1 for cash
        CardTypeID: 0,
        CardNetworkID: Number(cardNetworkId),
        OrderPlaceID: orderPlaceID,
        TenorAmount: 0.0,
        ChangeAmount: 0.0,
        OrderSize: 0,
        AppRefID: appRefId,
        CustomerAppRefID: customerAppRefId,
        TokenCount: orderPrefix + token_count.toString(),
        OrderPrefix: orderPrefix + GeneralSetting.getDeviceName() + '-' + token_count.toString(),
        T_TokenCount: token_count,
        D_TokenCount: token_count,
        OtherCharges: this.otherCharges,
        StaffLocationID: 0,
        BranchSectionConcessionaireID: 0,
        MergeTableID: 0,
        IsMergeTable: false,
        DiscountTypeOnID: 0,
        ThirdPartyPaymentID:
          GeneralSetting.getPaymentMethod() != ''
            ? Number(GeneralSetting.getPaymentMethod())
            : 0,
        TransactionID: GeneralSetting.getRetRef(),
        AuthorizationID: GeneralSetting.getVerifoneCtrouid(),
        SplitbillType: '',
        EvenallySize: '',
        ItemwiseSize: '',
        IsSplitBill: false,
        AttandanceAppRefID: '',
        OrderCashDiscount: 0.0,
        RefundedDate: '',
        IsUpChargeCard: this.IsUpChargeCard,
        IsPercentageCard: this.IsPercentageCard,
        PercentageOrAmounCard: this.PercentageOrAmounCard,
        UpChargeAmountCard: this.UpChargeAmountCard,
        IsUpChargeCash: false,
        IsPercentageCash: false,
        PercentageOrAmounCash: 0.0,
        UpChargeAmountCash: 0.0,
        PercentageOrFlatRateVal: this.PercentageOrFlatRateVal,
        PercentageOrFlatRate: this.PercentageOrFlatRate,
        OrderDiscountTypeID: this.DiscountTypeId,
        DiscountSelectedTypeID: this.DiscountId,
        CustomerGroupID: 0,
        CustomerName: customerName,
        AutoGratuityTip: 0.0,
        AutoGratuityPercentage: 0.0,
        TenderTypeID: 0,
        RefEarnedPoints: 0,
        RefDiscRedeemedPoints: 0,
        RefRedeemedPoints: 0,
        RefTotalPoints: 0,
        TaxableAmount: taxableAmount,
        CFDID: '',
        PickupDate: '',
        DeliveryDate: '',
        IsMergeOrder: false,
        MergeOrderInvoiceNo: '',
        TPOrderID: '',
        OraclePriceDifferent: 0.0,
        CloverOrderId: '',
        IsFireOrder: false,
        RevenueCenterID: 0,
        CustomerMaster: objCustomerMaster,
        ConcessioniareOrderSplitBill: concessioniareOrderSplitBill,
        CurbsideDetail: {} as CurbsideDetailMaster,
        ExternalOrderDetails: [] as ExternalOrderDetail[],
        ItemInventory: [] as ItemInventoryReq[],
        OnlineCustomerMaster: [] as OnlineCustomerMasterReq[],
        OrderItemMaster: objCartItems,
        OrderOtherCharge: [] as OrderOtherChargeReq[],
        OrderPartialPaymentMaster: [] as OrderPartialPaymentMasterReq[],
        OrderRefundPaymentMaster: [] as OrderRefundPaymentMasterReq[],
        OrderSplitBill: concessioniareOrderSplitBill,
        OrderSuccessPaymentMaster: [orderPayment],
        levelupuuid: '',
        ShippingAmount: 0,
        ShippingTypeID: 0,
      } as OrderMasterReq;

      arrOrderMasterReq.push(objOrderMasterReq);
      objOrderReq.OrderMaster = arrOrderMasterReq;
      objOrderReq.CloverCustomerID = '';

      resolve(objOrderReq);
    });
  }

  private getCustomerName() {
    let customerName = GeneralSetting.getCustomerName();

    if (customerName == '') {
      customerName =
        GeneralSetting.getCustomerLoginName() == ''
          ? 'Guest'
          : GeneralSetting.getCustomerLoginName();
    }

    return customerName;
  }

  private getCustomerMobile() {
    let customerPhone = GeneralSetting.getCustomerPhone();

    if (customerPhone == '') {
      customerPhone =
        GeneralSetting.getCustomerLoginMobile() == ''
          ? this.CustomerMobileNo
          : GeneralSetting.getCustomerLoginMobile();
    }

    return customerPhone;
  }

  private getCustomerEmail() {
    let customerEmail = GeneralSetting.getCustomerEmail();

    if (customerEmail == '') {
      customerEmail = GeneralSetting.getCustomerLoginEmail();
    }

    return customerEmail;
  }

  private getCustomerDetailForOrder(
    customerAppRefId: string,
    companyId: string,
    branchId: string,
    customerID: string,
    customerName: string
  ): CustomerListReq {
    const currentDate = CommonFunctions.getDate();

    const customerEmail = this.getCustomerEmail();
    const mobileNo = this.getCustomerMobile();

    const objCustomerDetail: CustomerListReq = {
      AppRefID: customerAppRefId,
      BAddress1: '0',
      BAddress2: '0',
      BCityID: 0,
      BCountryID: 0,
      BStateID: 0,
      BZipCode: '0',
      BranchID: branchId,
      CompanyID: companyId,
      CompanyName: '0',
      ConsumerPan: '',
      CreatedBy: 1,
      CreatedDate: currentDate.toString(),
      CustomerID: customerID,
      CustomerSignupTypeID: 0,
      CustomerTypeID: 0,
      DOA: '',
      DOB: '',
      DisplayName: customerName ?? '',
      EmailID: customerEmail,
      Facebook: ' ',
      FullName: customerName ?? '',
      Gender: 0,
      IndustryID: 0,
      Instagram: '',
      Linkedin: '',
      MobileCode: '',
      MobileNo: mobileNo ?? '',
      NoOfGuest: 0,
      Notes: '',
      Password: '',
      RefLoyaltyCustomerID: ' ',
      SAddress1: '',
      SAddress2: '',
      SCityID: 0,
      SCountryID: 0,
      SStateID: 0,
      SZipCode: '',
      Skype: '',
      TaxNo: '',
      TaxTypeID: 0,
      TitleID: 0,
      Twitter: '',
      UpdatedBy: 1,
      UpdatedDate: currentDate.toString(),
      Website: '',
      BankedRewards: '',
      EmailVerified: false,
      LoyaltyId: '',
      MembershipLevel: '',
      MembershipLevelId: '',
      NetBalance: '',
      NetDebits: '',
      PendingPoints: '',
      PointsBalance: '',
      RefCloverCustomerID: '',
      SignupAnniversaryDay: '',
      TotalCredits: '',
      TotalDebits: '',
      TotalPointCredits: '',
      MemberID: GeneralSetting.getMemberId(),
      IsAllowForMarketingCommunication:
        GeneralSetting.getIsAllowForMarketingCommunication(),
      IsActive: 'True',
    };

    return objCustomerDetail;
  }

  private getItemModifier(
    objItem: ItemV2,
    appRefIDOrder: string,
    itemAppRefId: string
  ): OrderItemModifierMasterReq[] {
    const modifiers = [] as OrderItemModifierMasterReq[];

    if (objItem.Modifiers && objItem.Modifiers.length <= 0) {
      return modifiers;
    }

    const Mods = objItem.Modifiers;

    for (let modIndex = 0; modIndex < Mods.length; modIndex++) {
      if (Mods[modIndex].IsSelected) {
        if (Mods[modIndex] != undefined) {
          const objCartItemModifier = Mods[modIndex].Ingredients?.filter(
            (x: any) => x.IsSelected || x.isNegativeModifier == true
          );

          if (
            objCartItemModifier != undefined &&
            objCartItemModifier.length > 0
          ) {
            const modifier = this.getModifierIngredients(
              objCartItemModifier,
              appRefIDOrder,
              itemAppRefId
            );

            if (modifier) {
              for (let objMod of modifier) {
                modifiers.push(objMod);
              }
            }
          }
        }
      }
    }
    return modifiers;
  }

  private getModifierIngredients(
    mods: (ModifierV2 | ModifierIngredientV2)[],
    appRefIDOrder: string,
    itemAppRefId: string
  ) {
    const dataList = [] as OrderItemModifierMasterReq[];

    if (mods.length > 0) {
      for (let index = 0; index < mods.length; index++) {
        const modifier = mods[index];

        if (modifier.IsIngredient) {
          const objIngredient = modifier as ModifierIngredientV2;

          if (
            !(
              ((objIngredient.IsDefault == 'True' &&
                objIngredient.isSendtoPOS == 'False' &&
                objIngredient.IsSelected) ||
                (objIngredient.IsDefault == 'True' &&
                  objIngredient.isSendtoPOS == 'True' &&
                  !objIngredient.IsSelected)) &&
              GeneralSetting.getParBrinkIntegrationID() != ''
            )
          ) {
            if (objIngredient.IsSelected || objIngredient.isNegativeModifier) {
              if (objIngredient.IsIngredient86 == 'True') {
                const ing = this.getIngredientDataByModifier(
                  objIngredient,
                  appRefIDOrder,
                  itemAppRefId
                );
                dataList.push(ing);
              }
            }
          }
        } else {
          const objModifier = modifier as ModifierV2;

          if (objModifier.IsSelected) {
            const options = this.getModifierIngredients(
              objModifier.Ingredients,
              appRefIDOrder,
              itemAppRefId
            ) as OrderItemModifierMasterReq[];
            for (let opt of options) {
              dataList.push(opt);
            }
          }
        }
      }
    }

    return dataList;
  }

  getModifierName(ModifierID: string): Promise<string> {
    return new Promise((res: any) => {
      const sqlString = `SELECT Category FROM ModifierMasters WHERE ModifierID = '${ModifierID}'`;

      const errorCallback = (tx: string, error: any) => { };

      const setName = (tx: string, results: any) => {
        res(results.rows[0].Category);
      };

      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        setName,
        errorCallback
      );
    });
  }

  getIngredientName(IngredientID: string): Promise<string> {
    return new Promise((res: any, rej: any) => {
      const sqlString = `SELECT Name FROM Ingredients WHERE IngredientID = '${IngredientID}'`;

      const errorCallback = (tx: string, error: any) => {
        console.log('getting IngredientID name error', error.message);
      };

      const setName = (tx: string, results: any) => {
        res(results.rows[0].Name);
      };

      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        setName,
        errorCallback
      );
    });
  }

  private getIngredientDataByModifier(
    ingredients: ModifierIngredientV2,
    appRefIDOrder: string,
    itemAppRefId: string
  ) {
    const totalPrice =
      (ingredients.ExtraPrice ? Number(ingredients.ExtraPrice) : 0) *
      (ingredients.Quantity ? Number(ingredients.Quantity) : 1);

    const objItemModifier = {
      AppRefID: CommonFunctions.getRandomGuid(),
      AppRefIDOrder: appRefIDOrder,
      AppRefIDOrderItem: itemAppRefId,
      Countable: ingredients.Countable == 'True',
      ExtraPrice: Number(ingredients.ExtraPrice),
      IngredientID: ingredients.IngredientID,
      IngredientName: ingredients.DisplayName
        ? ingredients.DisplayName
        : ingredients.Name,
      IsDefault: ingredients.IsDefault == 'True',
      IsNegativeModifier: ingredients.isNegativeModifier,
      IsPaid: Number(ingredients.ExtraPrice) > 0 ? 1 : 0,
      ModifierID: ingredients.ModifierID,
      Price: Number(ingredients.ExtraPrice),
      Quantity: ingredients.Quantity ? Number(ingredients.Quantity) : 1,
      TotalPrice: GeneralSetting.getIsRemovePricingAndPayments()
        ? 0
        : totalPrice,
      OrderID: 0,
      OrderItemID: '0',
      LogicID: 0,
      ModifierDetailID: ingredients.ModifierDetailID,
      OrderItemModifierID: '0',
      SelectionParentID: ingredients.ParentID,
      UnitTypeID: 0,
    } as OrderItemModifierMasterReq;

    return objItemModifier;
  }

  private getCartItemsForOrder(
    cartItems: ItemV2[],
    appRefIDOrder: string,
    companyId: string,
    branchId: string
  ) {
    const currentDate = CommonFunctions.getDate();
    const objCartItems = [] as OrderItemMasterReq[];

    for (let index = 0; index < cartItems.length; index++) {
      const objItem = cartItems[index] as ItemV2;

      const isCombo = objItem.IsCombo.toString();
      const IsUpSellItem = cartItems[index]?.IsUpSellItem ?? false;

      if (isCombo.toLowerCase() == 'true') {
        let hasModifier = objItem.Modifiers && objItem.Modifiers.length > 0;

        const objOrderItem = this.getOrderItem(
          objItem,
          currentDate,
          appRefIDOrder,
          branchId,
          companyId,
          false,
          objItem.ItemID,
          false,
          hasModifier
        );
        objOrderItem.UpSellType = IsUpSellItem ? 1 : 0;
        objCartItems.push(objOrderItem);

        const AppRefOrderIDCombo =
          objOrderItem.AppRefID == null
            ? CommonFunctions.getRandomGuid()
            : objOrderItem.AppRefID;

        for (let i = 0; i < objItem.ComboGroup.length; i++) {
          const optionItem = objItem.ComboGroup[i].Items;
          if (optionItem != null && optionItem != undefined) {
            for (let j = 0; j < optionItem.length; j++) {
              if (optionItem[j].isSelected) {
                let isHasModifier =
                  optionItem[j].Modifiers && optionItem[j].Modifiers.length > 0
                    ? true
                    : false;

                const comboItem = this.getOrderItem(
                  optionItem[j],
                  currentDate,
                  AppRefOrderIDCombo,
                  branchId,
                  companyId,
                  true,
                  objItem.ItemID,
                  true,
                  isHasModifier
                );
                objCartItems.push(comboItem);
              }
            }
          }
        }
      } else {
        let isHasModifier =
          objItem.Modifiers && objItem.Modifiers.length > 0 ? true : false;

        const objOrderItem = this.getOrderItem(
          objItem,
          currentDate,
          appRefIDOrder,
          branchId,
          companyId,
          false,
          '0',
          false,
          isHasModifier
        );
        objCartItems.push(objOrderItem);
      }
    }

    return objCartItems;
  }

  private getOrderItem(
    objItem: ItemV2,
    currentDate: string,
    appRefIDOrder: string,
    branchId: string,
    companyId: string,
    IsCombo: boolean,
    comboId: string,
    isComboItem: boolean = false,
    hasModifier: boolean = true
  ) {
    const itemAppRefId = CommonFunctions.getRandomGuid();

    let itemAttributeID = '0';
    let itemAttributeOptionID = '0';

    if (objItem.VariationOption) {
      if (objItem.VariationOption.length > 0) {
        const objItemVariation = objItem.VariationOption[0] as VariationOption;

        itemAttributeID = objItemVariation.ItemAttributeID;
        itemAttributeOptionID = objItemVariation.ItemAttributeOptionID;
      }
    }

    let arrItemModifier = [] as OrderItemModifierMasterReq[];
    if (hasModifier) {
      arrItemModifier = this.getItemModifier(
        objItem,
        appRefIDOrder,
        itemAppRefId
      );
    }

    const discountAmount =
      (objItem.DiscountPer * CommonFunctions.getItemPrice(objItem)) / 100;

    const objOrderItem = this.createOrderItem(
      objItem,
      itemAppRefId,
      appRefIDOrder,
      branchId,
      companyId,
      currentDate,
      itemAttributeID,
      itemAttributeOptionID,
      arrItemModifier,
      discountAmount,
      IsCombo,
      comboId,
      isComboItem
    );

    return objOrderItem;
  }

  private createOrderItem(
    objItem: ItemV2,
    itemAppRefId: string,
    appRefIDOrder: string,
    branchId: string,
    companyId: string,
    currentDate: string,
    itemAttributeID: string,
    itemAttributeOptionID: string,
    arrItemModifier: OrderItemModifierMasterReq[],
    discountAmount: number,
    isCombo: boolean,
    comboId: string,
    isComboItem: boolean
  ): OrderItemMasterReq {
    //customerID
    const customerID = GeneralSetting.getCustomerId();
    if (customerID != '') {
      this.CustomerID = customerID;
    }

    let quantity: number = isNaN(Number(objItem.Quantity))
      ? 1
      : Number(objItem.Quantity);
    let itemTotalPrice = CommonFunctions.getItemPrice(objItem) * quantity;
    let itemDiscount =
      objItem.DiscountAmount > 0 ? objItem.DiscountAmount : 0.0;
    let itemTax = Number(
      ((itemTotalPrice - itemDiscount) * objItem.TaxPercentage) / 100
    );
    //console.log("objOrderItem : ", objItem);
    const objOrderItem = {
      AppRefID: itemAppRefId,
      AppRefIDOrder: appRefIDOrder,
      BranchID: branchId,
      CompanyID: companyId,
      CreatedDate: currentDate.toString(),
      CategoryID: objItem.CategoryID,
      CategoryName: objItem.CategoryName,
      ItemAttributeID: itemAttributeID,
      ItemAttributeOptionID: itemAttributeOptionID,
      ItemID: objItem.ItemID,
      ItemName: objItem.Name == undefined ? '' : objItem.Name,
      ItemPrice: Number(objItem.Price),
      ItemQuantity: quantity,
      Weight: objItem.Weight,
      WeightUnitID: objItem.WeightUnitID,
      ItemTax: itemTax,
      TaxPer: objItem.TaxPercentage,
      ItemTotal: itemTotalPrice, //CommonFunctions.getItemPrice(objItem),
      OriginalProductCost: Number(objItem.Price),
      OrderItemModifierMaster: arrItemModifier,
      StartDate: currentDate.toString(),
      TaxGroupID:
        objItem.TaxGroupId == undefined || objItem.TaxGroupId == null
          ? 0
          : objItem.TaxGroupId,
      EndDate: currentDate.toString(),
      DiscountAmount: objItem.DiscountAmount,
      DiscountPercentage: objItem.DiscountPer,
      DiscountComment: '',
      IsDiscount: objItem.IsDiscount,
      DiscountID: objItem.DiscountID,
      OrderItemCashDiscount: discountAmount,
      DiscountTypeID: objItem.DiscountTypeID,
      OrderItemComment: objItem.specialRequest,
      UpSellItemID: objItem.UpSellItemID,
      UpSellType: objItem.IsUpSellItem ? 1 : 0,
      UpsellItemPrice: objItem.UpSellItemPrice,
      OrderItemModifierGroupMaster: [],
      IsCombo: isCombo,
      ComboID: comboId,
      CustomerID: this.CustomerID,
      CreatedBy: 1,
      GuestName: objItem.GuestName,
      BranchSectionConcessionaireID: objItem.ConcessionaireId
        ? Number(objItem.ConcessionaireId)
        : 0,
      CancelComment: '',
      CancelledAmount: 0,
      ChefID: 0,
      ComplimentaryAmount: 0,
      ComplimentaryComment: '',
      CourseID: 0,
      EvenallyPaidno: '',
      EvenalyChanged: '',
      EvenalyPayment: '',
      EvenalyPaymenttype: '',
      EvenalyTendered: '',
      HasModifiedPrice: false,
      HoldItem: false,
      IsCancel: false,
      IsComplimentary: false,
      IsInventoryTracked: false,
      IsLoyaltyItem: objItem.IsLoyaltyDiscountItem,
      IsPercentage: false,
      IsRefunded: false,
      IsSchedulePromotionAvailable: false,
      IsSpoiled: false,
      ItemCategorySalesTypeID: 0,
      ItemwisePageNo: '',
      KitchenID: 0,
      KotTokenNo: 0,
      OrderID: 0,
      OrderItemID: 0,
      RedeemPoints: 0,
      RefundedAmount: 0,
      RefundedComment: '',
      SchedulePromotionID: 0,
      SplitbillChanged: '',
      SplitbillPayement: '',
      SplitbillPayementType: '',
      SplitbillTendered: '',
      SpoiledAmount: 0,
      SpoiledComment: '',
      StaffLocationID: 0,
      TableID: objItem.TableTentNumber
        ? Number(objItem.TableTentNumber) ?? 0
        : 0,
      TableSeatNo: 0,
      TaxLocationID: 0,
      OrderReviewDisplayName: objItem.OrderReviewDisplayName,
    } as OrderItemMasterReq;

    return objOrderItem;
  }

  async addOrderResToLocalDb(resData: OrderUpRes, reqData: OrderListReq) {
    // TODO I put this here because I couldn't find created date format anywhere
    resData.OrderMasters[0].CreatedDateFormat = new Date().toLocaleString(
      'en-US'
    );

    // Add stringified print receipt data to OrderMaster table
    let printData = GeneralSetting.getPrintData();
    printData.data.copyType = this.language.getTextElement('duplicate_copy');
    resData.OrderMasters[0].PrintData = JSON.stringify(printData);

    if (resData.OrderMasters.length > 0) {
      resData.OrderMasters[0].IsOrderFailed = 'false';
      if (resData.CustomerMasters.length > 0) {
        resData.OrderMasters[0].CustomerPhone =
          resData.CustomerMasters[0].MobileNo;
      }
      resData.OrderMasters[0].CreatedDate = reqData.OrderMaster[0].CreatedDate;
      resData.OrderMasters[0].OrderUpStatus = '1';
      this.insertOrderMasterData(resData.OrderMasters);
    }

    if (resData.OrderItemMasters.length > 0)
      this.insertOrderItemData(resData.OrderItemMasters, reqData.OrderMaster);

    if (resData.CustomerMasters.length > 0)
      this.insertCustomerMasterData(resData.CustomerMasters);

    if (resData.CurbsideMaster.length > 0)
      this.insertCurbsideDetailMasterData(resData.CurbsideMaster);

    if (resData.OrderItemModifierGroupMaster.length > 0)
      this.insertOrderItemModifierGroupMasterData(
        resData.OrderItemModifierGroupMaster
      );

    if (GeneralSetting.getPaymentMethod() === '17') {
      //Save verifone response into DB
      let orderPaymentSuccess: OrderPaymentSuccess[] = [];
      let paymentResponseSession: any = GeneralSetting.getPaymentResponse();
      try {
        let paymentresponse = JSON.parse(paymentResponseSession);
        orderPaymentSuccess.push({
          invoiceNumber: GeneralSetting.getInvoiceNoToDb(),
          cardNumber: paymentresponse.ACCT_NUM,
          cardNetwork: paymentresponse.PAYMENT_MEDIA,
          paymentType: paymentresponse.PAYMENT_TYPE,
          transactionId: paymentresponse.TROUTD,
          intrnSeqNum: paymentresponse.INTRN_SEQ_NUM,
          responseText: paymentresponse.RESPONSE_TEXT,
          result: paymentresponse.RESULT,
          approvedAmount: paymentresponse.TRANS_AMOUNT,
        });
        this.insertOrderPaymentMasterData(orderPaymentSuccess);
      } catch (error) { }
    } else if (GeneralSetting.getPaymentMethod() === '13') {
      let result = JSON.parse(GeneralSetting.getPaymentResponse()); //Save verifone response into DB
      let orderPaymentSuccess: OrderPaymentSuccess[] = [];

      let cardNumber = '******----';
      let cardNetwork = '';

      if (
        result.payment.cardTransaction &&
        typeof result.payment.cardTransaction !== 'undefined'
      ) {
        cardNumber =
          result.payment.cardTransaction.first6 +
          '******' +
          result.payment.cardTransaction.last4;
        cardNetwork = result.payment.cardTransaction.cardType;
      }

      orderPaymentSuccess.push({
        invoiceNumber: GeneralSetting.getInvoiceNoToDb(),
        cardNumber: cardNumber,
        cardNetwork: cardNetwork,
        paymentType: result.payment.tender.label,
        transactionId: result.payment.externalPaymentId,
        intrnSeqNum: '',
        responseText: '',
        result: '',
        approvedAmount: (
          (Number(result.payment.amount) + Number(result.payment.tipAmount)) /
          100
        ).toFixed(2),
      });
      this.insertOrderPaymentMasterData(orderPaymentSuccess);
    }

    this.insertConcessionaireOrderSplitBillData(resData.OrderSplitBill);
  }

  private insertOrderMasterData(OrderMasters: OrderMasterRes[]) {
    var statements = [];
    var variables = [];
    var callbacks = [];
    var callbacksFail = [];

    const firstRow = Object.values(OrderMasters)[0];
    var LogError = (error: string): void => {
      var log = new loggingData(
        'Create OrderMasters and insert  data to websql',
        'Create OrderMasters and insert data to websql failed',
        'Create/insert data to websql',
        error,
        true
      );
    };
    var doNothing = (): void => { };

    var create = `CREATE TABLE IF NOT EXISTS OrderMasters (`;
    Object.keys(firstRow).forEach((key) => (create += `${key}, `));
    create = create.slice(0, -2); //remove last ", "
    create += `)`;
    statements.push(create as any);
    variables.push([] as any);
    callbacks.push(doNothing as any);
    callbacksFail.push(LogError as any);

    Object.values(OrderMasters).forEach((row) => {
      const rowKeys = Object.keys(row);
      let statement = `INSERT INTO OrderMasters (`;
      let vars: String[] = [];
      rowKeys.forEach((key) => {
        statement += `${key}, `;
      });

      statement = statement.slice(0, -2); //remove last ", "
      statement += `) VALUES (`;

      const values: String[] = Object.values(row);

      statement += `?, `.repeat(values.length);
      statement = statement.slice(0, -2); //remove last ", "
      statement += `)`;

      vars = vars.concat(values);

      statements.push(statement as any);
      variables.push(vars as any);
      callbacks.push(doNothing as any);
      callbacksFail.push(LogError as any);
    });

    DatabaseHandler.executeSqlTransation(
      statements as any,
      variables as any,
      callbacks as any,
      callbacksFail as any
    );
  }

  private insertOrderItemData(
    OrderItemMasters: OrderItemMasterRes[],
    OrderMastersReq: OrderMasterReq[]
  ) {
    var statements = [];
    var variables = [];
    var callbacks = [];
    var callbacksFail = [];

    try {
      for (var j = 0; j < OrderMastersReq.length; j++) {
        for (var i = 0; i < OrderItemMasters.length; i++) {
          if (
            OrderItemMasters[i].AppRefID ==
            OrderMastersReq[j].OrderItemMaster[i].AppRefID
          ) {
            OrderItemMasters[i].OrderReviewDisplayName =
              OrderMastersReq[j].OrderItemMaster[i].OrderReviewDisplayName;
          }
        }
      }
    } catch (e) { }

    const firstRow = Object.values(OrderItemMasters)[0];

    var LogError = (error: string): void => {
      var log = new loggingData(
        'Create OrderItemMasters and insert  data to websql',
        'Create OrderItemMasters and insert data to websql failed',
        'Create/insert data to websql',
        error,
        true
      );
    };
    var doNothing = (): void => { };

    var create = `CREATE TABLE IF NOT EXISTS OrderItemMasters (`;
    Object.keys(firstRow).forEach((key) => (create += `${key}, `));
    create = create.slice(0, -2); //remove last ", "
    create += `)`;
    statements.push(create as any);
    variables.push([] as any);
    callbacks.push(doNothing as any);
    callbacksFail.push(LogError as any);

    Object.values(OrderItemMasters).forEach((row) => {
      let rowKeys = Object.keys(row);
      let statement = `INSERT INTO OrderItemMasters (`;
      let vars: String[] = [];
      rowKeys.forEach((key) => {
        statement += `${key}, `;
      });

      statement = statement.slice(0, -2); //remove last ", "
      statement += `) VALUES (`;

      let values: String[] = Object.values(row);

      statement += `?, `.repeat(values.length);
      statement = statement.slice(0, -2); //remove last ", "
      statement += `)`;

      vars = vars.concat(values);

      statements.push(statement as any);
      variables.push(vars as any);
      callbacks.push(doNothing as any);
      callbacksFail.push(LogError as any);
    });

    DatabaseHandler.executeSqlTransation(
      statements as any,
      variables as any,
      callbacks as any,
      callbacksFail as any
    );
  }

  private insertOrderItemModifierData(
    OrderItemModifierMaster: OrderItemModifierMasterRes[]
  ) {
    var statements = [];
    var variables = [];
    var callbacks = [];
    var callbacksFail = [];
    const firstRow = Object.values(OrderItemModifierMaster)[0];

    var LogError = (error: string): void => {
      var log = new loggingData(
        'Create OrderItemModifierMasters and insert  data to websql',
        'Create OrderItemModifierMasters and insert data to websql failed',
        'Create/insert data to websql',
        error,
        true
      );
    };
    var doNothing = (): void => { };

    var create = `CREATE TABLE IF NOT EXISTS OrderItemModifierMasters (`;
    Object.keys(firstRow).forEach((key) => (create += `${key}, `));
    create = create.slice(0, -2); //remove last ", "
    create += `)`;
    statements.push(create as any);
    variables.push([] as any);
    callbacks.push(doNothing as any);
    callbacksFail.push(LogError as any);

    Object.values(OrderItemModifierMaster).forEach((row) => {
      let rowKeys = Object.keys(row);
      let statement = `INSERT INTO OrderItemModifierMasters (`;
      let vars: String[] = [];
      rowKeys.forEach((key) => {
        statement += `${key}, `;
      });

      statement = statement.slice(0, -2); //remove last ", "
      statement += `) VALUES (`;

      let values: String[] = Object.values(row);

      statement += `?, `.repeat(values.length);
      statement = statement.slice(0, -2); //remove last ", "
      statement += `)`;

      vars = vars.concat(values);

      statements.push(statement as any);
      variables.push(vars as any);
      callbacks.push(doNothing as any);
      callbacksFail.push(LogError as any);
    });

    DatabaseHandler.executeSqlTransation(
      statements as any,
      variables as any,
      callbacks as any,
      callbacksFail as any
    );
  }

  private insertCustomerMasterData(CustomerMaster: CustomerMasterRes[]) {
    var statements = [];
    var variables = [];
    var callbacks = [];
    var callbacksFail = [];

    const firstRow = Object.values(CustomerMaster)[0];

    var LogError = (error: string): void => {
      var log = new loggingData(
        'Create  CustomerMasters and insert  data to websql',
        'Create  CustomerMasters and insert data to websql failed',
        'Create/insert data to websql',
        error,
        true
      );
    };
    var doNothing = (): void => { };

    var create = `CREATE TABLE IF NOT EXISTS CustomerMasters (`;
    Object.keys(firstRow).forEach((key) => (create += `${key}, `));
    create = create.slice(0, -2); //remove last ", "
    create += `)`;
    statements.push(create as any);
    variables.push([] as any);
    callbacks.push(doNothing as any);
    callbacksFail.push(LogError as any);

    Object.values(CustomerMaster).forEach((row) => {
      let rowKeys = Object.keys(row);
      let statement = `INSERT INTO CustomerMasters (`;
      let vars: String[] = [];
      rowKeys.forEach((key) => {
        statement += `${key}, `;
      });

      statement = statement.slice(0, -2); //remove last ", "
      statement += `) VALUES (`;

      let values: String[] = Object.values(row);

      statement += `?, `.repeat(values.length);
      statement = statement.slice(0, -2); //remove last ", "
      statement += `)`;

      vars = vars.concat(values);

      statements.push(statement as any);
      variables.push(vars as any);
      callbacks.push(doNothing as any);
      callbacksFail.push(LogError as any);
    });

    DatabaseHandler.executeSqlTransation(
      statements as any,
      variables as any,
      callbacks as any,
      callbacksFail as any
    );
  }

  private insertCurbsideDetailMasterData(
    CurbsideDetailMaster: CurbsideDetailMasterRes[]
  ) {
    var statements = [];
    var variables = [];
    var callbacks = [];
    var callbacksFail = [];

    const firstRow = Object.values(CurbsideDetailMaster)[0];

    var LogError = (error: string): void => {
      var log = new loggingData(
        'Create CurbsideDetailMasters and insert  data to websql',
        'Create CurbsideDetailMasters and insert data to websql failed',
        'Create/insert data to websql',
        error,
        true
      );
    };
    var doNothing = (): void => { };

    var create = `CREATE TABLE IF NOT EXISTS CurbsideDetailMasters (`;
    Object.keys(firstRow).forEach((key) => (create += `${key}, `));
    create = create.slice(0, -2); //remove last ", "
    create += `)`;
    statements.push(create as any);
    variables.push([] as any);
    callbacks.push(doNothing as any);
    callbacksFail.push(LogError as any);

    Object.values(CurbsideDetailMaster).forEach((row) => {
      let rowKeys = Object.keys(row);
      let statement = `INSERT INTO CurbsideDetailMasters (`;
      let vars: String[] = [];
      rowKeys.forEach((key) => {
        statement += `${key}, `;
      });

      statement = statement.slice(0, -2); //remove last ", "
      statement += `) VALUES (`;

      let values: String[] = Object.values(row);

      statement += `?, `.repeat(values.length);
      statement = statement.slice(0, -2); //remove last ", "
      statement += `)`;

      vars = vars.concat(values);

      statements.push(statement as any);
      variables.push(vars as any);
      callbacks.push(doNothing as any);
      callbacksFail.push(LogError as any);
    });

    DatabaseHandler.executeSqlTransation(
      statements as any,
      variables as any,
      callbacks as any,
      callbacksFail as any
    );
  }

  private insertOrderItemModifierGroupMasterData(
    OrderItemModifierGroupMaster: OrderItemModifierGroupMasterRes[]
  ) {
    var statements = [];
    var variables = [];
    var callbacks = [];
    var callbacksFail = [];

    const firstRow = Object.values(OrderItemModifierGroupMaster)[0];

    var LogError = (error: string): void => {
      var log = new loggingData(
        'Create OrderItemModifierGroupMasters and insert  data to websql',
        'Create OrderItemModifierGroupMasters and insert data to websql failed',
        'Create/insert data to websql',
        error,
        true
      );
    };
    var doNothing = (): void => { };

    var create = `CREATE TABLE IF NOT EXISTS OrderItemModifierGroupMasters (`;
    Object.keys(firstRow).forEach((key) => (create += `${key}, `));
    create = create.slice(0, -2); //remove last ", "
    create += `)`;
    statements.push(create as any);
    variables.push([] as any);
    callbacks.push(doNothing as any);
    callbacksFail.push(LogError as any);

    Object.values(OrderItemModifierGroupMaster).forEach((row) => {
      let rowKeys = Object.keys(row);
      let statement = `INSERT INTO OrderItemModifierGroupMasters (`;
      let vars: String[] = [];
      rowKeys.forEach((key) => {
        statement += `${key}, `;
      });

      statement = statement.slice(0, -2); //remove last ", "
      statement += `) VALUES (`;

      let values: String[] = Object.values(row);

      statement += `?, `.repeat(values.length);
      statement = statement.slice(0, -2); //remove last ", "
      statement += `)`;

      vars = vars.concat(values);

      statements.push(statement as any);
      variables.push(vars as any);
      callbacks.push(doNothing as any);
      callbacksFail.push(LogError as any);
    });

    DatabaseHandler.executeSqlTransation(
      statements as any,
      variables as any,
      callbacks as any,
      callbacksFail as any
    );
  }

  private insertOrderSuccessPaymentMasterData(
    listPaymentData: OrderSuccessPaymentMasterReq[]
  ) {
    var statements = [];
    var variables = [];
    var callbacks = [];
    var callbacksFail = [];

    const firstRow = Object.values(listPaymentData)[0];

    var LogError = (error: string): void => {
      var log = new loggingData(
        'Create  OrderSuccessPaymentMaster and insert  data to websql',
        'Create  OrderSuccessPaymentMaster and insert data to websql failed',
        'Create/insert data to websql',
        error,
        true
      );
    };
    var doNothing = (): void => { };

    var create = `CREATE TABLE IF NOT EXISTS OrderSuccessPaymentMaster (`;
    Object.keys(firstRow).forEach((key) => (create += `${key}, `));
    create = create.slice(0, -2); //remove last ", "
    create += `)`;
    statements.push(create as any);
    variables.push([] as any);
    callbacks.push(doNothing as any);
    callbacksFail.push(LogError as any);

    Object.values(listPaymentData).forEach((row) => {
      let rowKeys = Object.keys(row);
      let statement = `INSERT INTO OrderSuccessPaymentMaster (`;
      let vars: String[] = [];
      rowKeys.forEach((key) => {
        statement += `${key}, `;
      });

      statement = statement.slice(0, -2); //remove last ", "
      statement += `) VALUES (`;

      let values: String[] = Object.values(row);

      statement += `?, `.repeat(values.length);
      statement = statement.slice(0, -2); //remove last ", "
      statement += `)`;

      vars = vars.concat(values);

      statements.push(statement as any);
      variables.push(vars as any);
      callbacks.push(doNothing as any);
      callbacksFail.push(LogError as any);
    });

    DatabaseHandler.executeSqlTransation(
      statements as any,
      variables as any,
      callbacks as any,
      callbacksFail as any
    );
  }

  private insertConcessionaireOrderSplitBillData(
    listSplitBill: ConcessioniareOrderSplitBill[]
  ) {
    if (listSplitBill && listSplitBill.length <= 0) {
      return;
    }

    var statements = [];
    var variables = [];
    var callbacks = [];
    var callbacksFail = [];

    const firstRow = Object.values(listSplitBill)[0];

    var LogError = (error: string): void => {
      var log = new loggingData(
        'Create  OrderSuccessPaymentMaster and insert  data to websql',
        'Create  OrderSuccessPaymentMaster and insert data to websql failed',
        'Create/insert data to websql',
        error,
        true
      );
    };
    var doNothing = (): void => { };

    var create = `CREATE TABLE IF NOT EXISTS OrderConcessionaireSplitBill (`;
    Object.keys(firstRow).forEach((key) => (create += `${key}, `));
    create = create.slice(0, -2); //remove last ", "
    create += `)`;
    statements.push(create as any);
    variables.push([] as any);
    callbacks.push(doNothing as any);
    callbacksFail.push(LogError as any);

    Object.values(listSplitBill).forEach((row) => {
      let rowKeys = Object.keys(row);
      let statement = `INSERT INTO OrderConcessionaireSplitBill (`;
      let vars: String[] = [];
      rowKeys.forEach((key) => {
        statement += `${key}, `;
      });

      statement = statement.slice(0, -2); //remove last ", "
      statement += `) VALUES (`;

      let values: String[] = Object.values(row);

      statement += `?, `.repeat(values.length);
      statement = statement.slice(0, -2); //remove last ", "
      statement += `)`;

      vars = vars.concat(values);

      statements.push(statement as any);
      variables.push(vars as any);
      callbacks.push(doNothing as any);
      callbacksFail.push(LogError as any);
    });

    DatabaseHandler.executeSqlTransation(
      statements as any,
      variables as any,
      callbacks as any,
      callbacksFail as any
    );
  }

  insertOrderPaymentMasterData(OrderPaymentSuccess: OrderPaymentSuccess[]) {
    var statements = [];
    var variables = [];
    var callbacks = [];
    var callbacksFail = [];

    const firstRow = Object.values(OrderPaymentSuccess)[0];

    var LogError = (error: string): void => {
      var log = new loggingData(
        'Create OrderPaymentSuccess and insert  data to websql',
        'Create OrderPaymentSuccess and insert data to websql failed',
        'Create/insert data to websql',
        error,
        true
      );
    };
    var doNothing = (): void => { };

    var create = `CREATE TABLE IF NOT EXISTS OrderPaymentSuccess (`;
    Object.keys(firstRow).forEach((key) => (create += `${key}, `));
    create = create.slice(0, -2); //remove last ", "
    create += `)`;
    statements.push(create as any);
    variables.push([] as any);
    callbacks.push(doNothing as any);
    callbacksFail.push(LogError as any);

    Object.values(OrderPaymentSuccess).forEach((row) => {
      const rowKeys = Object.keys(row);
      let statement = `INSERT INTO OrderPaymentSuccess (`;
      let vars: String[] = [];
      rowKeys.forEach((key) => {
        statement += `${key}, `;
      });

      statement = statement.slice(0, -2); //remove last ", "
      statement += `) VALUES (`;

      const values: String[] = Object.values(row);

      statement += `?, `.repeat(values.length);
      statement = statement.slice(0, -2); //remove last ", "
      statement += `)`;

      vars = vars.concat(values);

      statements.push(statement as any);
      variables.push(vars as any);
      callbacks.push(doNothing as any);
      callbacksFail.push(LogError as any);
    });

    DatabaseHandler.executeSqlTransation(
      statements as any,
      variables as any,
      callbacks as any,
      callbacksFail as any
    );
  }

  setUpchargeCardData(
    isUpChargeCard: boolean = false,
    isPercentageCard: boolean = false,
    percentageOrAmounCard: number = 0.0,
    upChargeAmountCard: number = 0.0
  ) {
    this.IsUpChargeCard = isUpChargeCard;
    this.IsPercentageCard = isPercentageCard;
    this.PercentageOrAmounCard = percentageOrAmounCard;
    this.UpChargeAmountCard = upChargeAmountCard;
  }

  setOrderToOfflineDb(orderData: OrderListReq) {
    // console.log(orderData);
    const listObjOrderMasterData = this.getOrderMasterForDB(
      orderData.OrderMaster[0]
    );

    listObjOrderMasterData[0].IsOrderFailed = 'true';

    const itemModifier = this.getOrderItemDataForDB(orderData.OrderMaster[0]);
    console.log('item modifier: ' + itemModifier);
    const listDbOrderItem = itemModifier[0] as OrderItemMasterRes[];

    const listOrderItemModifier =
      itemModifier[1] as OrderItemModifierMasterRes[];

    const listCustomerMaster = this.getCustomerMasterForDB(
      orderData.OrderMaster[0].CustomerMaster
    );

    const listPaymentData = orderData.OrderMaster[0].OrderSuccessPaymentMaster;

    const concessioanreSplitBill =
      orderData.OrderMaster[0].ConcessioniareOrderSplitBill;

    listObjOrderMasterData[0].CustomerPhone =
      orderData.OrderMaster[0].CustomerMaster.MobileNo;
    this.insertOrderMasterData(listObjOrderMasterData);
    if (listDbOrderItem.length > 0) {
      this.insertOrderItemData(listDbOrderItem, orderData.OrderMaster);

      if (listOrderItemModifier.length > 0) {
        this.insertOrderItemModifierData(listOrderItemModifier);
      }
    }

    this.insertCustomerMasterData(listCustomerMaster);

    if (listPaymentData.length > 0) {
      this.insertOrderSuccessPaymentMasterData(listPaymentData);
    }

    this.insertConcessionaireOrderSplitBillData(concessioanreSplitBill);
  }

  private getOrderMasterForDB(orderMaster: OrderMasterReq) {
    return [
      {
        OrderID: '0',
        OrderSource:orderMaster.OrderSource,
        OrderInvoiceNo: orderMaster.OrderInvoiceNo,
        InvoiceRefNo: orderMaster.OrderInvoiceNo,
        TableID: orderMaster.TableID.toString(),
        KitchenID: orderMaster.KitchenID.toString(),
        SectionID: orderMaster.SectionID.toString(),
        ModuleID: orderMaster.ModuleID.toString(),
        StaffModuleID: orderMaster.StaffModuleID.toString(),
        StaffID: orderMaster.StaffID.toString(),
        CompanyID: orderMaster.CompanyID.toString(),
        BranchID: orderMaster.BranchID.toString(),
        CustomerID: orderMaster.CustomerID.toString(),
        CustomerName: orderMaster.CustomerName.toString(),
        OrderStatusID: orderMaster.OrderStatusID.toString(),
        OrderTip: orderMaster.OrderTip.toString(),
        Subtotal: orderMaster.Subtotal.toString(),
        OrderTax: orderMaster.OrderTax.toString(),
        ExtraCharge: orderMaster.ExtraCharge.toString(),
        ExtraComment: orderMaster.ExtraComment,
        IsDiscount: orderMaster.IsDiscount.toString(),
        OrderDiscount: orderMaster.OrderDiscount.toString(),
        DiscountComment: orderMaster.DiscountComment,
        OrderTotal: orderMaster.OrderTotal.toString(),
        IsRefunded: orderMaster.IsRefunded.toString(),
        RefundedAmount: orderMaster.RefundedAmount.toString(),
        RefundedComment: orderMaster.RefundedComment,
        OrderTypeID: orderMaster.OrderTypeID.toString(),
        DineTypeID: orderMaster.DineTypeID.toString(),
        CustomerIP: orderMaster.CustomerIP,
        PaidDateUtc: orderMaster.PaidDateUtc,
        CreatedBy: orderMaster.CreatedBy.toString(),
        IsDeleted: orderMaster.IsDeleted.toString(),
        CreatedDate: orderMaster.CreatedDate,
        CreatedDateFormat: '',
        CustomOrderNumber: orderMaster.CustomOrderNumber,
        OrderComment: orderMaster.OrderComment,
        IsCancel: orderMaster.IsCancel.toString(),
        CancelComment: orderMaster.CancelComment,
        CancelAmount: orderMaster.CancelAmount.toString(),
        PaymentTypeID: orderMaster.PaymentTypeID.toString(),
        CardTypeID: orderMaster.CardTypeID.toString(),
        CardNetworkID: orderMaster.CardNetworkID.toString(),
        OrderPlaceID: orderMaster.OrderPlaceID.toString(),
        TenorAmount: orderMaster.TenorAmount.toString(),
        ChangeAmount: orderMaster.ChangeAmount.toString(),
        OrderSize: orderMaster.OrderSize.toString(),
        TokenCount: orderMaster.TokenCount.toString(),
        T_TokenCount: orderMaster.T_TokenCount.toString(),
        D_TokenCount: orderMaster.D_TokenCount.toString(),
        AppRefID: orderMaster.AppRefID,
        CustomerAppRefID: orderMaster.CustomerAppRefID,
        OtherCharges: orderMaster.OrderOtherCharge.toString(),
        StaffLocationID: orderMaster.StaffLocationID.toString(),
        BranchSectionConcessionaireID:
          orderMaster.BranchSectionConcessionaireID.toString(),
        MergeTableID: orderMaster.MergeTableID.toString(),
        IsMergeTable: orderMaster.IsMergeTable.toString(),
        DiscountTypeOnID: orderMaster.DiscountTypeOnID.toString(),
        TransactionID: orderMaster.TransactionID,
        AuthorizationID: orderMaster.AuthorizationID,
        SplitbillType: orderMaster.SplitbillType,
        EvenallySize: orderMaster.EvenallySize,
        ItemwiseSize: orderMaster.ItemwiseSize,
        IsSplitBill: orderMaster.IsSplitBill.toString(),
        AttandanceAppRefID: orderMaster.AttandanceAppRefID,
        ThirdPartyPaymentID: orderMaster.ThirdPartyPaymentID.toString(),
        OrderCashDiscount: orderMaster.OrderCashDiscount.toString(),
        RefundedDate: orderMaster.RefundedDate,
        IsUpChargeCard: orderMaster.IsUpChargeCard.toString(),
        IsPercentageCard: orderMaster.IsPercentageCard.toString(),
        PercentageOrAmounCard: orderMaster.PercentageOrAmounCard.toString(),
        UpChargeAmountCard: orderMaster.UpChargeAmountCard.toString(),
        IsUpChargeCash: orderMaster.IsUpChargeCash.toString(),
        IsPercentageCash: orderMaster.IsPercentageCash.toString(),
        PercentageOrAmounCash: orderMaster.PercentageOrAmounCash.toString(),
        UpChargeAmountCash: orderMaster.UpChargeAmountCash.toString(),
        PercentageOrFlatRateVal: orderMaster.PercentageOrFlatRateVal,
        PercentageOrFlatRate: orderMaster.PercentageOrFlatRate,
        OrderDiscountTypeID: orderMaster.OrderDiscountTypeID,
        DiscountSelectedTypeID: orderMaster.DiscountSelectedTypeID,
        CustomerGroupID: orderMaster.CustomerGroupID.toString(),
        AutoGratuityTip: orderMaster.AutoGratuityTip.toString(),
        AutoGratuityPercentage: orderMaster.AutoGratuityPercentage.toString(),
        RefundType: '',
        IsAdjusted: '',
        AdjustCheckAmountType: '',
        AdjustCheckAmount: '',
        AdjustTipAmountType: '',
        AdjustTipAmount: '',
        TPOrderID: orderMaster.TPOrderID,
        OrderKitchenStatusID: '',
        DeliveryAddressID: '',
        TenderTypeID: orderMaster.TenderTypeID.toString(),
        RefEarnedPoints: orderMaster.RefEarnedPoints.toString(),
        RefDiscRedeemedPoints: orderMaster.RefDiscRedeemedPoints.toString(),
        RefRedeemedPoints: orderMaster.RefRedeemedPoints.toString(),
        RefTotalPoints: orderMaster.RefTotalPoints.toString(),
        TaxableAmount: orderMaster.TaxableAmount.toString(),
        CFDID: orderMaster.CFDID,
        PickupDate: orderMaster.PickupDate,
        DeliveryDate: orderMaster.DeliveryDate,
        IsMergeOrder: orderMaster.IsMergeOrder.toString(),
        MergeOrderInvoiceNo: orderMaster.MergeOrderInvoiceNo,
        IsFireOrder: orderMaster.IsFireOrder.toString(),
        RevenueCenterID: orderMaster.RevenueCenterID.toString(),
        IsOrderFailed: 'false',
        OrderUpStatus: '0',

        OrderSurcharge: orderMaster.OrderSurcharge,
      },
    ] as OrderMasterRes[];
  }

  private getOrderItemDataForDB(orderMaster: OrderMasterReq) {
    const listOrderItemRes = [] as OrderItemMasterRes[];

    const listObjOrderModifier = [] as OrderItemModifierMasterRes[];

    for (let index = 0; index < orderMaster.OrderItemMaster.length; index++) {
      const objOrderItem = orderMaster.OrderItemMaster[index];

      let objOrderItemDb = {} as OrderItemMasterRes;
      objOrderItemDb.OrderItemID = objOrderItem.OrderItemID.toString();
      objOrderItemDb.OrderID = objOrderItem.OrderID.toString();
      objOrderItemDb.TableID = objOrderItem.TableID.toString();
      objOrderItemDb.TableSeatNo = objOrderItem.TableSeatNo.toString();
      objOrderItemDb.CompanyID = objOrderItem.CompanyID.toString();
      objOrderItemDb.BranchID = objOrderItem.BranchID.toString();
      objOrderItemDb.CustomerID = objOrderItem.CustomerID.toString();
      objOrderItemDb.KitchenID = objOrderItem.KitchenID.toString();
      objOrderItemDb.ChefID = objOrderItem.ChefID.toString();
      objOrderItemDb.CategoryID = objOrderItem.CategoryID.toString();
      objOrderItemDb.ItemID = objOrderItem.ItemID.toString();
      objOrderItemDb.ItemName = objOrderItem.ItemName.toString();
      objOrderItemDb.ItemQuantity = objOrderItem.ItemQuantity.toString();
      objOrderItemDb.ItemPrice = objOrderItem.ItemPrice.toString();
      objOrderItemDb.TaxGroupID = objOrderItem.TaxGroupID.toString();
      objOrderItemDb.TaxPer = objOrderItem.TaxPer.toString();
      objOrderItemDb.ItemTax = objOrderItem.ItemTax.toString();
      objOrderItemDb.TaxLocationID = objOrderItem.TaxLocationID.toString();
      objOrderItemDb.ItemTotal = objOrderItem.ItemTotal.toString();
      objOrderItemDb.OriginalProductCost =
        objOrderItem.OriginalProductCost.toString();
      objOrderItemDb.StartDate = objOrderItem.StartDate;
      objOrderItemDb.EndDate = objOrderItem.EndDate;
      objOrderItemDb.CreatedBy = objOrderItem.CreatedBy.toString();
      objOrderItemDb.CreatedDate = objOrderItem.CreatedDate.toString();
      objOrderItemDb.OrderItemComment = objOrderItem.OrderItemComment;
      objOrderItemDb.IsCancel = objOrderItem.IsCancel.toString();
      objOrderItemDb.CancelComment = objOrderItem.CancelComment.toString();
      objOrderItemDb.IsSpoiled = objOrderItem.IsSpoiled.toString();
      objOrderItemDb.SpoiledComment = objOrderItem.SpoiledComment.toString();
      objOrderItemDb.IsComplimentary = objOrderItem.IsComplimentary.toString();
      objOrderItemDb.ComplimentaryComment =
        objOrderItem.ComplimentaryComment.toString();
      objOrderItemDb.ComplimentaryAmount =
        objOrderItem.ComplimentaryAmount.toString();
      objOrderItemDb.IsDiscount = objOrderItem.IsDiscount
        ? objOrderItem.IsDiscount.toString()
        : 'false';
      objOrderItemDb.DiscountID = objOrderItem.DiscountID
        ? objOrderItem.DiscountID.toString()
        : '0';
      objOrderItemDb.DiscountTypeID = objOrderItem.DiscountTypeID
        ? objOrderItem.DiscountTypeID.toString()
        : '0';
      objOrderItemDb.IsPercentage = objOrderItem.IsPercentage.toString();
      objOrderItemDb.DiscountComment = objOrderItem.DiscountComment;
      objOrderItemDb.DiscountAmount = objOrderItem.DiscountAmount
        ? objOrderItem.DiscountAmount.toString()
        : '0';
      objOrderItemDb.DiscountPercentage = objOrderItem.DiscountPercentage
        ? objOrderItem.DiscountPercentage.toString()
        : '0';
      objOrderItemDb.IsRefunded = objOrderItem.IsRefunded.toString();
      objOrderItemDb.RefundedAmount = objOrderItem.RefundedAmount.toString();
      objOrderItemDb.RefundedComment = objOrderItem.RefundedComment;
      objOrderItemDb.CourseID =
        objOrderItem.CourseID == null ? '' : objOrderItem.CourseID.toString();
      objOrderItemDb.HoldItem = objOrderItem.HoldItem.toString();
      objOrderItemDb.IsCombo = objOrderItem.IsCombo.toString();
      objOrderItemDb.ComboID = objOrderItem.ComboID.toString();
      objOrderItemDb.ItemCategorySalesTypeID =
        objOrderItem.ItemCategorySalesTypeID.toString();
      objOrderItemDb.KotTokenNo = objOrderItem.KotTokenNo.toString();
      objOrderItemDb.AppRefIDOrder = objOrderItem.AppRefIDOrder ?? '';
      objOrderItemDb.AppRefID = objOrderItem.AppRefID ?? '';
      objOrderItemDb.StaffLocationID = objOrderItem.StaffLocationID.toString();
      objOrderItemDb.BranchSectionConcessionaireID =
        objOrderItem.BranchSectionConcessionaireID.toString();
      objOrderItemDb.SplitbillPayement = '';
      objOrderItemDb.SplitbillPayementType = '';
      objOrderItemDb.SplitbillTendered = '';
      objOrderItemDb.SplitbillChanged = '';
      objOrderItemDb.EvenalyPayment = '';
      objOrderItemDb.EvenalyPaymenttype = '';
      objOrderItemDb.EvenalyTendered = '';
      objOrderItemDb.EvenalyChanged = '';
      objOrderItemDb.EvenallyPaidno = '';
      objOrderItemDb.ItemwisePageNo = '';
      objOrderItemDb.OrderItemCashDiscount =
        objOrderItem.OrderItemCashDiscount.toString();
      objOrderItemDb.ItemAttributeID = objOrderItem.ItemAttributeID.toString();
      objOrderItemDb.ItemAttributeOptionID =
        objOrderItem.ItemAttributeOptionID.toString();
      objOrderItemDb.IsLoyaltyItem = objOrderItem.IsLoyaltyItem
        ? objOrderItem.IsLoyaltyItem.toString()
        : 'false';
      objOrderItemDb.CancelledAmount = objOrderItem.CancelledAmount.toString();
      objOrderItemDb.SpoiledAmount = '';
      objOrderItemDb.IsSchedulePromotionAvailable =
        objOrderItem.IsSchedulePromotionAvailable.toString();
      objOrderItemDb.SchedulePromotionID =
        objOrderItem.SchedulePromotionID.toString();
      objOrderItemDb.RedeemPoints =
        objOrderItem.RedeemPoints == null
          ? ''
          : objOrderItem.RedeemPoints.toString();
      objOrderItemDb.IsInventoryTracked =
        objOrderItem.IsInventoryTracked.toString();
      objOrderItemDb.UpSellItemID = objOrderItem.UpSellItemID
        ? objOrderItem.UpSellItemID.toString()
        : '0';
      objOrderItemDb.UpSellType = objOrderItem.UpSellType.toString();
      objOrderItemDb.HasModifiedPrice =
        objOrderItem.HasModifiedPrice.toString();
      objOrderItemDb.UpsellItemPrice = objOrderItem.UpsellItemPrice
        ? objOrderItem.UpsellItemPrice.toString()
        : '0';
      objOrderItemDb.GuestName = objOrderItem.GuestName;
      objOrderItemDb.ConcessionaireID =
        objOrderItem.BranchSectionConcessionaireID.toString();
      objOrderItemDb.OrderReviewDisplayName =
        objOrderItem.OrderReviewDisplayName.toString();

      listOrderItemRes.push(objOrderItemDb);

      const listObjModifier = this.getItemModifierForDB(objOrderItem);

      for (
        let modifierIndex = 0;
        modifierIndex < listObjModifier.length;
        modifierIndex++
      ) {
        listObjOrderModifier.push(listObjModifier[modifierIndex]);
      }
    }

    return [listOrderItemRes, listObjOrderModifier];
  }

  private getItemModifierForDB(orderItem: OrderItemMasterReq) {
    const listObjOrderModifier = [] as OrderItemModifierMasterRes[];

    for (
      let index = 0;
      index < orderItem.OrderItemModifierMaster.length;
      index++
    ) {
      const objModifier = orderItem.OrderItemModifierMaster[index];

      const modifier = {
        OrderItemModifierID: objModifier.OrderItemModifierID.toString(),
        OrderID: objModifier.OrderID.toString(),
        OrderItemID: objModifier.OrderItemID.toString(),
        ModifierDetailID: objModifier.ModifierDetailID.toString(),
        ModifierID: objModifier.ModifierID.toString(),
        SelectionParentID:
          objModifier.SelectionParentID == null
            ? ''
            : objModifier.SelectionParentID.toString(),
        ModifierName: '',
        IngredientID: objModifier.IngredientID.toString(),
        IngredientName: '',
        UnitTypeID: objModifier.UnitTypeID.toString(),
        Quantity: objModifier.Quantity.toString(),
        ExtraPrice: objModifier.ExtraPrice.toString(),
        TotalPrice: objModifier.TotalPrice.toString(),
        Countable: objModifier.Countable.toString(),
        IsDefault: objModifier.IsDefault.toString(),
        LogicID: objModifier.LogicID.toString(),
        AppRefIDOrder: objModifier.AppRefIDOrder,
        AppRefIDOrderItem: objModifier.AppRefIDOrderItem,
        AppRefID: objModifier.AppRefID,
        IsPaid: objModifier.IsPaid.toString(),
        IsNegativeModifier: objModifier.IsNegativeModifier.toString(),
        Price: objModifier.Price.toString(),
      } as OrderItemModifierMasterRes;

      listObjOrderModifier.push(modifier);
    }

    return listObjOrderModifier;
  }

  private getCustomerMasterForDB(customerData: CustomerListReq) {
    return [
      {
        AccountNo: '',
        AppRefID: customerData.AppRefID,
        BAddress1: customerData.BAddress1,
        BAddress2: customerData.BAddress2,
        BCityID:
          customerData.BCityID == null ? '0' : customerData.BCityID.toString(),
        BCityName: '',
        BCountryID:
          customerData.BCountryID == null ? '0' : customerData.BCountryID,
        BCountryName: '',
        BStateID: customerData.BStateID == null ? '0' : customerData.BStateID,
        BStateName: '',
        BZipCode: customerData.BZipCode,
        BankedRewards: customerData.BankedRewards,
        BranchID: customerData.BranchID.toString(),
        CanOrderOnDIYDevices: '',
        CompanyID: customerData.CompanyID.toString(),
        CompanyName: customerData.CompanyName,
        ConsumerPan: customerData.ConsumerPan,
        CreatedBy: customerData.CreatedBy.toString(),
        CreatedDate: customerData.CreatedDate,
        CustomerID: customerData.CustomerID.toString(),
        CustomerNo: customerData.MobileNo,
        CustomerSignupTypeID: customerData.CustomerSignupTypeID.toString(),
        CustomerTypeID: customerData.CustomerTypeID.toString(),
        DOA: customerData.DOA,
        DOB: customerData.DOB,
        DisplayName: customerData.DisplayName,
        EmailID: customerData.EmailID,
        EmailVerified:
          customerData.EmailVerified == null
            ? 'false'
            : customerData.EmailVerified.toString(),
        Facebook: customerData.Facebook,
        FullName: customerData.FullName,
        Gender: customerData.Gender.toString(),
        IPAddress: '',
        ImageUrl: '',
        IndustryID:
          customerData.IndustryID == null
            ? '0'
            : customerData.IndustryID.toString(),
        Instagram: customerData.Instagram,
        IsActive: 'true',
        IsDeleted: 'false',
        IsGuest: 'true',
        Linkedin: customerData.Linkedin,
        LoyaltyId: customerData.LoyaltyId,
        MembershipLevel: customerData.MembershipLevel,
        MembershipLevelId: customerData.MembershipLevelId,
        MobileCode: customerData.MobileCode,
        MobileNo: customerData.MobileNo,
        NetBalance: customerData.NetBalance,
        NetDebits: customerData.NetDebits,
        NoOfGuest: customerData.NoOfGuest.toString(),
        Notes: customerData.Notes,
        Password: customerData.Password,
        PendingPoints: customerData.PendingPoints,
        PointsBalance: customerData.PointsBalance,
        RefLoyaltyCustomerID: customerData.RefLoyaltyCustomerID,
        RefUserID: '0',
        SAddress1: customerData.SAddress1,
        SAddress2: customerData.SAddress2,
        SCityID:
          customerData.SCityID == null ? '0' : customerData.SCityID.toString(),
        SCityName: '',
        SCountryID:
          customerData.SCountryID == null
            ? '0'
            : customerData.SCountryID.toString(),
        SCountryName: '',
        SStateID:
          customerData.SStateID == null
            ? '0'
            : customerData.SStateID.toString(),
        SStateName: '',
        SZipCode: customerData.SZipCode,
        SignupAnniversaryDay: customerData.SignupAnniversaryDay,
        Skype: customerData.Skype,
        TaxNo: customerData.TaxNo,
        TaxTypeID:
          customerData.TaxTypeID == null
            ? '0'
            : customerData.TaxTypeID.toString(),
        TitleID: customerData.TitleID.toString(),
        TotalCredits: customerData.TotalCredits,
        TotalDebits: customerData.TotalDebits,
        TotalPointCredits: customerData.TotalPointCredits,
        Twitter: customerData.Twitter,
        UpdatedBy: customerData.UpdatedBy.toString(),
        UpdatedDate: customerData.UpdatedDate,
        Website: customerData.Website,
      },
    ] as CustomerMasterRes[];
  }

  //For CurbSide
  private getCurbSideMasterForDB() {
    return {
      CSID: 0,
      CSCarMan: '',
      CSCarModel: '',
      CSCarColor: '',
      OrderID: 0,
      CompanyID: '0',
      BranchID: '0',
    } as CurbsideDetailMasterRes;
  }

  private getOrderGroupMasterForDB() {
    return {
      OrderID: '',
      ItemID: '',
      ModifierID: '',
      ParentModifierID: '',
      Price: '',
    };
  }

  getCardNetworkID(cardtype: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let sqlStr = `Select CardNetworkID from CardNetworkMasters where NetworkNameText LIKE '%${cardtype}%'`;
      const errorCallback = (tx: string, error: any) => {
        resolve('0');
      };

      const setName = (tx: string, results: any) => {
        if (results.rows.length > 0) {
          resolve(results.rows[0].CardNetworkID);
        } else {
          resolve('0');
        }
      };

      DatabaseHandler.executeSqlStatement(sqlStr, [], setName, errorCallback);
    });
  }

  getConcessioniareOrderSplitBill(
    items: ItemV2[],
    appRefId: string,
    createdData: string,
    orderSubTotal: number,
    orderTotal: number,
    orderTip: number,
    orderDiscount: number,
    cardNetworkId: string,
    discountConcessionaireDetails: ConcessionaireDiscontDetail[]
  ) {
    var allConcessionaire: any[] = [];
    var concessionaireTotal: ConcessioniareOrderSplitBill[] = [];

    items.forEach(function (x) {
      allConcessionaire.push({
        ConcessionaireId: x.ConcessionaireId,
      });
    });
    let uniqueCon = [
      ...new Map(
        allConcessionaire.map((item) => [item['ConcessionaireId'], item])
      ).values(),
    ];
    for (let index = 0; index < uniqueCon.length; index++) {
      let con = uniqueCon[index];
      let conItems = items.filter(
        (x) => x.ConcessionaireId == con.ConcessionaireId
      );

      let conDiscount: number = 0;

      let conDiscountDetails = discountConcessionaireDetails.filter(
        (x) => x.concessionaireId == con.ConcessionaireId
      );

      if (conDiscountDetails.length > 0) {
        conDiscount = Number(conDiscountDetails[0].Discount);
      }

      if (conItems.length > 0) {
        let conTax = CommonCalculations.getItemTax(
          conItems as any,
          0,
          orderSubTotal,
          conDiscount,
          conDiscountDetails
        ).totalTax;

        let conSubTotal = CommonFunctions.getSubTotalByItems(conItems);

        orderDiscount = 0;
        let appRefID = CommonFunctions.getRandomGuid();
        let concessionaireItemSplitBill: OrderItemSplitBillReq[] = [];
        for (let index = 0; index < conItems.length; index++) {
          const conItem = conItems[index];
          let itemTotal =
            CommonFunctions.getItemPrice(conItem) *
            Number(conItem.Quantity ?? 1);

          orderDiscount +=
            conItem.DiscountAmount && conItem.DiscountAmount != 0
              ? conItem.DiscountAmount
              : 0;

          concessionaireItemSplitBill.push({
            AppRefID: appRefID,
            AssignQty: '1',
            CheckNo: '0',
            ConcessionaireID: con.ConcessionaireId,
            DiscountAmount: conItem.DiscountAmount,
            InvoiceNo: GeneralSetting.getInvoiceNoToDb(),
            ItemTax: (conItem.TaxAmount ? conItem.TaxAmount : 0.0).toFixed(2),
            ItemTotal: itemTotal.toFixed(2),
            OrderID: '0',
            OrderSplitBillID: '0',
            SplitCheckCount: '0',
            TotalQty: (conItem.Quantity ? conItem.Quantity : 1).toString(),
          });
        }
        if (orderDiscount > 0) {
          conDiscount += orderDiscount;
        }

        let sutotalPer = (conSubTotal / orderSubTotal) * 100;

        let conTip = (sutotalPer * orderTip) / 100;

        let storeTotal = conSubTotal + conTax + conTip - conDiscount;

        let tempCnDiscount = typeof conDiscount === 'string' ? parseFloat(conDiscount).toFixed(2) : conDiscount.toFixed(2);

        concessionaireTotal.push({
          SplitInvoiceNo: GeneralSetting.getInvoiceNoToDb() + '-' + (index + 1),
          OrderID: '0',
          PaymentAmount: storeTotal.toFixed(2),
          TenderedAmount: '0',
          TipAmount: conTip.toFixed(2),
          SubTotal: conSubTotal.toFixed(2),
          OrderTax: conTax.toFixed(2),
          OrderDiscount: tempCnDiscount,
          ExtraCharge: '0',
          OtherCharges: '0',
          SplitAmount: '0',
          AutoGratuityTip: '0',
          SeatNo: (index + 1).toString(),
          PaymentPageNo: '0',
          PaymentTypeID:
            GeneralSetting.getPaymentTypeID() != ''
              ? Number(GeneralSetting.getPaymentTypeID())
              : 1,
          CardTypeID: '0',
          CardNetworkID: cardNetworkId,
          ThirdPartyPaymentID: GeneralSetting.getPaymentMethod(),
          TransactionID: '0',
          AuthorizationID: '0',
          IsRefunded: '0',
          RefundedAmount: '0',
          RefundedComment: '0',
          UpchargeCardAmount: '0',
          OrderCashDiscount: '0',
          StaffLocationID: GeneralSetting.getstaffLocationID(),
          BranchSectionConcessionaireID: con.ConcessionaireId,
          CreatedBy: '0',
          CreatedDate: createdData,
          AppRefID: appRefID,
          AppRefIDOrder: appRefId,
          ChangedAmount: '0',
          InvoiceNo: GeneralSetting.getInvoiceNoToDb(),
          OrderSplitBillID: '0',
          ThirdPartyOrderID: '0',
          ItemSplitBill: concessionaireItemSplitBill,
        });
      }
    }

    return concessionaireTotal;
  }
}
