import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.css'],
})
export class ComingSoonComponent implements OnInit {
  modalService!: NgbModal;

  constructor(public ModalService: NgbModal) {
    this.modalService = ModalService;
  }

  ngOnInit(): void {}

  open() {
    this.modalService.open(ComingSoonComponent, { centered: true, size: 'lg' });
  }
}
