import { Injectable } from '@angular/core';
import { from, Observable, BehaviorSubject, Subject } from 'rxjs';
import { DatabaseHandler } from '../DatabaseHandler';
import { DiscountMaster, DiscountItemMapping } from '../models/DiscountMaster';
import {
  ItemV2,
  ModifierV2,
  ModifierIngredientV2,
  ComboGroupV2,
} from '../models/item';
import { loggingData, LogService } from 'src/app/services/log.service';
import { Category } from '../models/category';
import { VariationOption, Variation } from '../models/Variations';
import { PusherMaster } from 'src/app/models/PusherMaster';
import { CommonFunctions } from './common';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { Concessionaire } from 'src/app/models/Concessionaire';
import {
  Kitchen_Ingredient_Mapping,
  Kitchen_Item_Mapping,
  StaffModuleMaster,
} from '../models/BranchLoginModels';
import { Printer } from '../models/Printer';
import { ReceiptGroup } from '../models/ReceiptGroup';
import { ConcessionaireFilterTypes } from '../models/concessionaireTypes';
import {
  ItemTagFilterMasters,
  ItemTagGroupMappings,
} from '../models/ItemTagFilterMaster';
import { forEach, result } from 'lodash';
import { OrderPaymentSuccess } from '../models/OrderUpRes';
import { EatOptionsService } from './eat-options.service';
import { ParBrinkOverride } from './par-brink.service';
import { FixedItemDiscount } from '../models/discounts';
import { LanguageService } from './language.service';
import { CustomRulesMaster } from '../models/CustomRulesMaster';
@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  private category = [] as any[];

  public categorySubject = new BehaviorSubject<Category[]>([] as Category[]);

  public concessionaireSubject = new BehaviorSubject<Concessionaire[]>(
    [] as Concessionaire[]
  );

  public MenuRefresh: Subject<boolean> = new Subject<boolean>();

  public downloadImageQueue = new Array<string>();

  constructor(private logger: LogService) {
    this.mapPrinters();
  }

  promos: any[] = [];

  isDoRefresh: boolean = false;

  isRefreshedinSettings: boolean = false;

  starKitchenPrinters: Printer[] = [];

  chooseUpToText: string = 'Choose';
  setLanguageService(language: LanguageService) {
    this.chooseUpToText = language.getTextElement('txt_choose_up_to');
  }

  getKitchenPrinters(refresh: boolean): Observable<Printer[]> {
    // console.log('getKitchenPrinters() is called');

    return from(
      new Promise<Printer[]>(async (resolve: any, reject: any) => {
        localStorage.setItem('kitchenPrinters', JSON.stringify(null));
        var allPrinters: Printer[] = [];
        var kitchenPrinters = await this.getOnlyKitchenPrinters(allPrinters);
        var invoicePrinters = await this.getOnlyInvoicePrinters(allPrinters);

        // console.log('kitchenPrinters: ' + JSON.stringify(kitchenPrinters));

        for (let i = 0; i < kitchenPrinters.length; i++) {
          if (
            kitchenPrinters[i].IpAddress ==
            GeneralSetting.getSTARPrinterSP700IPAddress()
          ) {
            GeneralSetting.setSTARPrinterSP700KitchenName(
              kitchenPrinters[i].Name
            );
          }
        }

        allPrinters = allPrinters.filter((x) => x.IpAddress != '');
        // allPrinters = allPrinters.concat(invoicePrinters)
        // const tryPrinters = (tx: string, result: any) => {
        // var returnedPrinterTests = 0
        // var totalPrinterTests = result.rows.length;
        window.onmessage = (event: any) => {
          if (event.data.messageType == 'PrinterTestReturn') {
            // only messages that of the type we want

            var currentPrinters: Printer[] = event.data.data;
            // currentPrinters = _.uniqBy(currentPrinters, 'IpAddress');
            console.log('currentPrinters : ', currentPrinters);
            for (let printer of currentPrinters) {
              if (printer.isConnected == 'true') {
                this.doUpdateDeviceStatus(printer.StaffModuleID, '1');
              } else {
                this.doUpdateDeviceStatus(printer.StaffModuleID, '2');
              }
            }

            //This will add seiko and star printer along with EPSON
            this.starKitchenPrinters.forEach((seiKoStarPrinter) => {
              currentPrinters.push(seiKoStarPrinter);
            });

            localStorage.setItem(
              'kitchenPrinters',
              JSON.stringify(currentPrinters)
            ); // add to printers
            resolve(currentPrinters);
          }
        };

        if (allPrinters.length > 0) {
          // for (const printer of result.rows) {

          // }
          let data = [];

          // for (let row of _.uniqBy(result.rows, 'IpAddress') as any[]) {
          this.starKitchenPrinters = [];
          for (let row of allPrinters as any[]) {
            row['KID'] = this.printMappingMaster.get(row.StaffModuleID) || [
              '0',
            ];
            if (
              row.IsKitchenPrinter == 'True' &&
              row.BrandName &&
              (row.BrandName.toLowerCase() == 'seiko' ||
                row.BrandName.toLowerCase() == 'star')
            ) {
              this.starKitchenPrinters.push(row);
              data.push(row);
            } else {
              data.push(row);
            }
          }

          let messageType = '';
          if (refresh) {
            messageType = 'refreshPrinters';
          } else {
            messageType = 'getPrinters';
          }
          let message = {
            messageType: messageType,
            data: data,
          };
          window.top!.postMessage(message, '*');
          resolve(data);
        } else {
          this.starKitchenPrinters = [];
          let messageType = 'refreshPrinters';
          let message = {
            messageType: messageType,
            data: [],
          };
          window.top!.postMessage(message, '*');
          resolve([]);
        }

        // };
        // var callback = tryPrinters;
        // var callbackFail = function (tx: string, result: any) {
        //   //console.log(result);
        // };
        // DatabaseHandler.executeSqlStatement(
        //   sqlString,
        //   [],
        //   callback,
        //   callbackFail
        // );
      })
    );
  }

  async getDiscountItemsByCustomRulesID(customRulesID: number) {
    return new Promise<string[]>((resolve: any, reject: any) => {
      let getDiscountItems = `Select ItemID from DiscountCustomRulesItemMaster as crim where crim.CustomRulesID = '${customRulesID}'`;

      const errorCallback = (statement: any, error: any) => {
        resolve([] as string[]);
      };

      const callback = (tx: string, results: any) => {
        let data: any[] = Array.from(results.rows);
        let data1 = data.map((x) => x.ItemID);
        resolve(data1);
      };

      DatabaseHandler.executeSqlStatement(
        getDiscountItems,
        [],
        callback,
        errorCallback
      );
    });
  }

  async getCustomRulesByOrderTypeID(orderTypeID: string) {
    return new Promise<CustomRulesMaster[]>((resolve: any, reject: any) => {
      let getCustomRules = `Select * from
        DiscountCustomRuleMaster as crm
        left join DiscountCustomRulesOrderTypeMaster as crotm
          on crm.CustomRulesID = crotm.CustomRulesID where crotm.OrderTypeID = '${orderTypeID}'`;

      const errorCallback = (statement: any, error: any) => {
        resolve([] as CustomRulesMaster[]);
      };

      const callback = (tx: string, results: any) => {
        var data: CustomRulesMaster[] = Array.from(results.rows);
        for (const rule of data) {
          rule.isCouponAvailable =
            rule.hasOwnProperty('CouponCode') &&
            rule['CouponCode'] != '' &&
            rule['CouponCode'] != null;
        }
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getCustomRules,
        [],
        callback,
        errorCallback
      );
    });
  }

  async getCategoriesByCustomRulesID(customRulesID: number) {
    return new Promise<string[]>((resolve: any, reject: any) => {
      let getCategories = `Select CategoryID from DiscountCustomRulesCategoryMaster as crcm where crcm.CustomRulesID = '${customRulesID}'`;

      const errorCallback = (statement: any, error: any) => {
        resolve([] as string[]);
      };

      const callback = (tx: string, results: any) => {
        let data: any[] = Array.from(results.rows);
        let data1 = data.map((x) => x.CategoryID);
        resolve(data1);
      };

      DatabaseHandler.executeSqlStatement(
        getCategories,
        [],
        callback,
        errorCallback
      );
    });
  }

  async getCategoriesByItemID(itemId: string) {
    return new Promise<string[]>((resolve: any, reject: any) => {
      let getCategories = `SELECT cm.Name AS Name,cm.DisplayName AS DisplayName,cm.CategoryID AS CategoryID FROM categorymasters as cm INNER JOIN item_category_mappings ON cm.CategoryID = item_category_mappings.CategoryID and cm.isdeleted = 'False' and cm.isactive = 'True' and cm.IsShowOnKiosk = 'True' INNER JOIN items ON item_category_mappings.itemId = '${itemId}' order  by  cast(cm.displayorder as int) asc  limit 0,1`;

      const errorCallback = (statement: any, error: any) => {
        resolve([] as string[]);
      };

      const callback = (tx: string, results: any) => {
        let data: any[] = Array.from(results.rows);
        resolve(data);
      };

      //console.log('getCategoriesByItemID ---' + getCategories);

      DatabaseHandler.executeSqlStatement(
        getCategories,
        [],
        callback,
        errorCallback
      );
    });
  }

  async getAllPrinters() {
    var Printers: Printer[] = [];
    let data = [];
    await this.getOnlyKitchenPrinters(Printers);
    await this.getOnlyInvoicePrinters(Printers);

    Printers = Printers.filter((x) => x.IpAddress != '');

    if (Printers.length > 0) {
      for (let row of Printers as any[]) {
        row['KID'] = this.printMappingMaster.get(row.StaffModuleID) || ['0'];
        if (
          row.IsKitchenPrinter == 'True' &&
          row.BrandName &&
          (row.BrandName.toLowerCase() == 'seiko' ||
            row.BrandName.toLowerCase() == 'star')
        ) {
        } else {
          if (
            !(
              data.filter((p) => p.StaffModuleID == row.StaffModuleID).length >
              0
            )
          ) {
            data.push(row);
          }
        }
      }
    }
    return data;
  }

  getOnlyKitchenPrinters(printers: Printer[]): Promise<Printer[]> {
    return new Promise<Printer[]>((resolve: any, reject: any) => {
      let branchId = GeneralSetting.getBranchId();
      let companyId = GeneralSetting.getCompanyId();
      // only take IsKitchenPrinter variable for kitchenprinters
      var sqlString: string = `
        select km.Name as Name,
        sm.IpAddress as IpAddress,
        sm.IsKitchenPrinter as IsKitchenPrinter,
        pm.BrandName as BrandName,
        pm.ModelName as ModelName,
        km.KitchenID,
        km.KDSIPAddress ,
        sm.StaffModuleID,
        sm.PrinterStatus from StaffModuleMasters as sm inner join KitchenMasters as
        km on km.StaffModuleID=sm.StaffModuleID inner join PrinterMasters as
        pm on pm.PrinterID = sm.PrinterID where sm.ModuleID = '4' and km.IsActive = 'True'
        and sm.CompanyID = '${companyId}' and sm.IsKitchenPrinter = 'True' and pm.IsActive = 'True'
      `;

      const tryPrinters = (tx: string, result: any) => {
        let data = Array.from(result.rows);
        data.forEach((element: any) => {
          element.IsInvoicePrinter = 'False';
          printers.push(element);
        });
        resolve(result.rows);
      };
      var callback = tryPrinters;
      var callbackFail = function (tx: string, result: any) {
        //console.log(result);
        resolve('');
      };
      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        callback,
        callbackFail
      );
    });
  }

  getOnlyInvoicePrinters(printers: Printer[]): Promise<Printer[]> {
    return new Promise<Printer[]>(async (resolve: any, reject: any) => {
      var staffModuleId = await this.getInvoicePrinterIDbyDeviceID();

      var sqlString: string = `
        select sm.Name as Name,
        sm.IpAddress as IpAddress,
        pm.BrandName as BrandName,
        pm.ModelName as ModelName,
        sm.StaffModuleID,
        sm.PrinterStatus
        from StaffModuleMasters as sm
        inner join PrinterMasters as pm on pm.PrinterID = sm.PrinterID
          where sm.ModuleID = '4'  and sm.IsInvoicePrinter = 'True' and pm.IsActive = 'True'and sm.StaffModuleID = '${staffModuleId}'
      `;

      // SELECT *
      //   FROM StaffModuleMasters
      // WHERE IsKitchenPrinter = 'True' OR IsInvoicePrinter = 'True'`

      const tryPrinters = (tx: string, result: any) => {
        let data = Array.from(result.rows);
        data.forEach((element: any) => {
          element.IsKitchenPrinter = 'False';
          element.IsInvoicePrinter = 'True';
          printers.push(element);
        });
        resolve(result.rows);
      };
      var callback = tryPrinters;
      var callbackFail = function (tx: string, result: any) {
        //console.log(result);
        resolve('');
      };
      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        callback,
        callbackFail
      );
    });
  }

  getInvoicePrinterIDbyDeviceID(): Promise<string> {
    return new Promise<string>((resolve: any, reject: any) => {
      var sqlString: string = `
        SELECT InvoicePrinterID
        FROM StaffModuleMasters
        WHERE DeviceID = '${GeneralSetting.getSerialNo()}'
      `;

      const tryPrinters = (tx: string, result: any) => {
        if (result.rows.length > 0) {
          resolve(result.rows[0].InvoicePrinterID);
        } else {
          resolve('');
        }
      };
      var callback = tryPrinters;
      var callbackFail = function (tx: string, result: any) {
        //console.log(result);
        resolve('');
      };
      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        callback,
        callbackFail
      );
    });
  }

  printMappingMaster!: Map<String, String[]>;

  // printerQueue: any[] = [];
  mapPrinters() {
    this.printMappingMaster = new Map<String, String[]>();
    let query = `
    select
    sp.StaffModuleID as "SID",
    k.KitchenID as "KID"
    from StaffModuleMasters sp
    JOIN KitchenMasters k
    ON
    sp.StaffModuleID = k.StaffModuleID
    GROUP BY k.KitchenID`;

    const exec = (tx: string, result: any) => {
      var data = [];
      for (const row of result.rows) {
        let key = this.printMappingMaster.get(row.SID);
        if (key) {
          key.push(row.KID);
        } else {
          this.printMappingMaster.set(row.SID, [row.KID]);
        }
      }
    };
    var callbackFail = (tx: string, result: any) => {
      //console.log(result);
      var log = new loggingData(
        'Printer Error',
        'Encountered Error Fetching PrintMasters',
        `Printer Error`,
        `Encountered Error Fetching PrintMasters: ${result}`,
        true
      );
      this.logger.sendLogToServer(log);
    };
    DatabaseHandler.executeSqlStatement(query, [], exec, callbackFail);
  }

  async doUpdateDeviceStatus(staffModuleId: String, status: String) {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json; charset=UTF-8');
    myHeaders.append('CompanyID', GeneralSetting.getCompanyId());
    myHeaders.append('BranchID', GeneralSetting.getBranchId());
    myHeaders.append('DeviceID', GeneralSetting.getSerialNo());

    let currentTime = this.getFormattedDate();

    let map = [
      {
        StaffModuleID: staffModuleId,
        StatusId: status,
        StatusDate: currentTime,
      },
    ];

    var raw = JSON.stringify(map);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    var response = await fetch(
      'https://staging.grubbrr.com/api/DeviceStatusUpdateSK',
      requestOptions as any
    );
    // .then(response => response.text())
    // .then(result => //console.log(result))
    // .catch(error => //console.log('error', error));
    console.log('response on status update : ', response);
    if (!response.ok) {
      //console.log(response);
      switch (response.status) {
        case 401:
          // Utils.sendLog("Device Status Update Failed", "Device Status Update Failed", "Response Log", "\nError:401 Handle unauthorized")
          break;
        case 400:
          // Utils.sendLog("Device Status Update Failed", "Device Status Update Failed", "Response Log", "\nError:400 Bad Request")
          break;
        case 500:
          // Utils.sendLog("Device Status Update Failed", "Device Status Update Failed", "Response Log", "\nError:500 Internal Server Error")
          break;
        default:
          // Utils.sendLog("Device Status Update Failed", "Device Status Update Failed", "Response Log", "\nError:Error occurred")
          break;
      }
    }
  }

  getFormattedDate() {
    return new Date().toLocaleString('en-US');
  }

  hasCategory() {
    return this.category.length > 0;
  }

  removeCategories() {
    this.category = [];
    this.categorySubject.next([]);
  }

  //IsItemDetailScreenShowKiosk removed this flag as it was not working
  getDetailSetting() {
    const settingQuery = `select  IsMinimizeSpacing,AskCustomerIfTheyWantReceipt, HideZReport from ModuleGeneralSettings`;

    const errorCallback = (statement: any, error: any) => {
      console.log(
        'Database Service , Getting Detail Setting from sql error',
        error.message
      );
    };

    const setSetting = (tx: string, results: any) => {
      if (results.rows.length > 0) {
        // GeneralSetting.setIsItemDetailScreenShowKiosk(
        //   results.rows[0].IsItemDetailScreenShowKiosk
        // );

        GeneralSetting.setHideZReport(results.rows[0].HideZReport);
        GeneralSetting.setIsMinimizeSpacing(results.rows[0].IsMinimizeSpacing);

        GeneralSetting.setAskCustomerIfTheyWantReceipt(
          results.rows[0].AskCustomerIfTheyWantReceipt
        );
      }
    };
    DatabaseHandler.executeSqlStatement(
      settingQuery,
      [],
      setSetting,
      errorCallback
    );
  }

  getDiscountByBogoItem(discountIds: any[]) {
    return from(
      new Promise<DiscountMaster[]>((resolve: any) => {
        let getDiscounts = `select * from discountMasters where RegularOrBogoDiscount='1' and DiscountId in (`;

        for (let i = 0; i < discountIds.length; i++) {
          if (i == 0) {
            getDiscounts += "'" + discountIds[i] + "'";
          } else {
            getDiscounts += `,\'${discountIds[i]}\'`;
          }
        }
        getDiscounts += ')';

        const errorCallback = (statement: any, error: any) => {
          console.log(
            'Database Service , Error get Discount By Bogo Item',
            error.message
          );
        };

        const setModifiers = (tx: string, results: any) => {
          var data: DiscountMaster[] = Array.from(results.rows);

          resolve(data);
        };

        DatabaseHandler.executeSqlStatement(
          getDiscounts,
          [],
          setModifiers,
          errorCallback
        );
      })
    );
  }

  getDiscountItems(itemId: any[]) {
    return from(
      new Promise<DiscountItemMapping[]>((resolve: any, reject: any) => {
        let getDiscounts = `select * from Discount_Item_Mappings where ItemId in (`;

        for (let i = 0; i < itemId.length; i++) {
          if (i == 0) {
            getDiscounts += "'" + itemId[i] + "'";
          } else {
            getDiscounts += `,\'${itemId[i]}\'`;
          }
        }

        getDiscounts += ')';

        const errorCallback = (statement: any, error: any) => {
          console.log(
            'Database Service , Error get Discount Items',
            error.message
          );

          reject();
        };

        const setModifiers = (tx: string, results: any) => {
          var data: DiscountItemMapping[] = Array.from(results.rows);

          resolve(data);
        };

        DatabaseHandler.executeSqlStatement(
          getDiscounts,
          [],
          setModifiers,
          errorCallback
        );
      })
    );
  }

  parBrinkOverride(item: any, modifiers: any) {
    return new Promise<ParBrinkOverride[]>((resolve, reject) => {
      let mods: string = '';
      for (let i = 0; i < modifiers.length; i++) {
        mods += "'" + modifiers[i].ModifierID + "'";
        if (i + 1 != (item.Modifiers ? item.Modifiers.length : 0)) {
          mods += ',';
        }
      }
      if (mods[mods.length - 1]) {
        mods = mods.slice(0, -1);
      }
      const query = `
      SELECT ItemID
    , ModifierID
    , IngredientID
    , isDefault
    , DisplayOrder
    , KOTKDSDisplayOrder
    , isSendtoPOS
    , IsSendNegativeModifierstoPOS

    from ModifierOverrideDetail
    WHERE  ModifierOverrideDetail.ItemID = '${item.ItemID}'
    AND ModifierOverrideDetail.ModifierID IN (${mods})
    `;
      console.log(query);
      const errorCallback = (statement: any, error: any) => {
        console.log(
          'Database Service , Error parBrinkOverride from sql',
          error.message
        );

        resolve([]);
      };
      const modify = (tx: string, results: any) => {
        resolve(results.rows);
      };
      DatabaseHandler.executeSqlStatement(query, [], modify, errorCallback);
    });
  }
  getInfo() {
    return new Promise<any>((resolve, reject) => {
      // if (this.customName != '') {
      //   resolve(true);
      // }
      //get eating option from session storage location-preference -- dine in/take out
      var orderTypeId = GeneralSetting.getOrderTypeId();

      //query
      var sqltext = `
    SELECT
      KioskAskCustomerName,
      KioskAskCustomerPhone,
      KioskAskCustomerEmail

    FROM
      OrderTypeMappingMasters

    WHERE
      OrderTypeId = '${orderTypeId}'
    `;

      //callback handler
      const setInfo = (tx: string, results: any) => {
        var infoObj = results.rows[0];

        // this.customName = infoObj && infoObj.KioskAskCustomerName;
        // this.customPhone = infoObj && infoObj.KioskAskCustomerPhone;
        // this.customEmail = infoObj && infoObj.KioskAskCustomerEmail;

        // if (this.customName === 'True' || this.customPhone === 'True' || this.customEmail === 'True') {
        //   this.setEmailValidations();
        //   this.setPhoneValidations();
        //   this.setNameValidations();
        // }

        resolve(infoObj);
      };

      //error
      const errorCallback = (statement: any, error: any) => {
        console.log('Error Getting OrderTypeMappingMasters from sql');
        resolve(true);
      };

      DatabaseHandler.executeSqlStatement(sqltext, [], setInfo, errorCallback);
    });
  }
  getUpSellItemsByItemId(
    itemId: string,
    isConcessionaire: boolean = false,
    concessaionaireId: string = ''
  ) {
    return new Promise<ItemV2[]>((resolve: any, reject: any) => {
      let currentTime = CommonFunctions.getCurrentTime();
      let dayId = new Date().getDay();
      if (dayId == 0) {
        dayId = 7;
      }

      let currentDate = CommonFunctions.getCurrentformatedtDate(new Date());

      let getUpsellItems = `
            select DISTINCT

              UpSellItemMasters.ItemID,
              case when UpSellItemMasters.DisplayName = '' then items.name
                                 else UpSellItemMasters.DisplayName
                             end as Name,
              case when UpSellItemMasters.DisplayName = '' then items.name
                    else UpSellItemMasters.DisplayName
                end as ItemName,
              items.KOTorKDSDisplayName AS KOTDisplayName,
              case when UpSellItemMasters.DisplayName = '' then items.OrderReviewDisplayName
                  else UpSellItemMasters.DisplayName
                end as OrderReviewDisplayName,
              items.FullDescription,
              items.IsItem86,
              items.IsItemDetailScreenShowKiosk AS  IsItemDetailScreenShowKiosk,
              items.fulldescriptionlang as FullDescriptionLang,
              items.ShortDescription,
              items.shortdescriptionlang as ShortDescriptionLang,
              items.taxgroupid as TaxGroupId,

              UpSellItemMasters.ExtraPrice as Price,
              UpSellItemMasters.Discount as DiscountAmount,
              UpSellItemMasters.Discount as DiscountAmountForCalulation,
              cast(UpSellItemMasters.DisplayOrder as int) as DisplayOrder,
              items.calorie as Calorie,
              items.MaxCalories as MaxCalories,
              items.itemcategorysalestypeid as ItemCategorySalesTypeID,
              (
                select  imageurl
                  from  itemimages
                  where  itemimages.itemid == items.itemid
                  limit  1
               ) as ImageUrl,
              UpSellItemMasters.CategoryID,
              Case when CategoryMasters.DisplayName = '' then CategoryMasters.Name else CategoryMasters.DisplayName end AS CategoryName,
              items.IsBuilderMode,
              items.isboxbuildermode as IsBoxBuilderMode,
              items.IsAskForName,
              items.RefItemId,
              items.orderminimumquantity as OrderMinimumQuantity,
              UpSellItemMasters.OrderMaximumQuantity as OrderMaximumQuantity,
              items.IsCombo,
              items.lowstockactivity as LowStockActivity,
              items.EnablePricebyWeight as EnablePricebyWeight,
              RIS.CurrentStock AS CurrentStock,
              RIS.LowThreshold AS LowThreshold,
              (SELECT GROUP_CONCAT(DISTINCT(FM.IconImage)) FROM Items AS I
                  JOIN FilterMasters AS FM
                    ON FM.FilterID = IFM.FilterID
                    AND I.IsDeleted = 'False'
                  JOIN Item_Filter_Mappings as IFM
                    ON IFM.ItemID = I.ItemID
                    WHERE I.IsActive = 'True' AND items.ItemID = I.ItemID
                    GROUP BY I.ItemID) as ItemTags,
              UpSellItemMasters.CustomMessage,
              UpSellItemMasters.UpSellGroupName as DiscountName,
              UpSellItemMasters.UpSellItemID,
              UpSellItemMasters.AllowQuantitySelection as AllowQuantitySelection ,
              CategoryMasters.CategoryID AS CategoryID,
              mm.modifiercount as ModifierCount,
              ia.attributecount as AttributeCount

          from
              UpSellItemMasters`;

      if (isConcessionaire) {
        getUpsellItems += ` inner join Concessionaire_Item_Mappings as cim on cim.ItemID = UpSellItemMasters.UpSellItemID and cim.ConcessionaireID = '${concessaionaireId}'`;
      }

      getUpsellItems += `
              Inner JOIN Item_Category_Mappings ON Item_Category_Mappings.itemID = UpSellItemMasters.ItemID
              Inner join CategoryMasters on CategoryMasters.categoryId = Item_Category_Mappings.categoryid
                      AND CategoryMasters.isdeleted = 'False' and CategoryMasters.isactive = 'True'
                      AND CategoryMasters.IsShowOnKiosk = 'True'
                      AND UpSellItemMasters.CategoryId = CategoryMasters.categoryId
              Inner join CategoryDayWiseMasters CD ON CategoryMasters.CategoryID = CD.CategoryID AND CD.DayID = '${dayId}'
                      AND CD.StartTime <= time('${currentTime}')
                      AND CD.EndTime >= time('${currentTime}')
                      AND CD.IsActive = 'True'
              Inner JOIN items ON items.ItemID = UpSellItemMasters.ItemID
                    and items.isdeleted = 'False'
                    and items.IsActive = 'True'
                    and items.IsItem86 = 'True'
                    and  items.isshowonkiosk = 'True'
                    and  items.iscomboitemonly = 'False'
                    and (items.StartDate <= '${currentDate}' OR items.StartDate = '')
                    AND(items.EndDate >= '${currentDate}' OR items.EndDate = '')
              Inner JOIN ItemDayWiseMasters IDWM ON IDWM.ItemID = items.ItemID and IDWM.DayID = '${dayId}'
                      and IDWM.StartTime <= time('${currentTime}')
                      AND IDWM.EndTime >= time('${currentTime}')
                      AND IDWM.IsActive ='True'
              LEFT JOIN ReadyItemStock as RIS ON items.ItemID = RIS.ItemID
              left  join (select '0' as itemid, '0' as modifiercount
              ) as mm on  mm.itemid = '0'
                      left  join  (
                             select  case when ia.itemid = '0' then caim.itemid
                                          else ia.itemid
                                      end as itemid
                                    ,count(ia.itemid) as attributecount
                               from  itemattributes as ia
                               left  join  commonattribute_item_mappings as caim
                                 on  caim.itemattributeid = ia.itemattributeid
                              group  by  ia.itemid
                                    ,caim.itemid
                             ) as ia
                        on  ia.itemid = items.itemid

              where
              UpSellItemMasters.UpSellItemID = '${itemId}'
        `;

      const errorCallback = (statement: any, error: any) => {
        console.log(
          'Database Service , Error getUpSellItemsByItemId from sql',
          error.message
        );

        resolve([] as ItemV2[]);
      };

      const setModifiers = async (tx: string, results: any) => {
        var data: ItemV2[] = Array.from(results.rows);

        let itemIdList = Array.from(data.map((x) => x.ItemID));

        let modifierCount = (await this.getModifierCountByItemIds(
          itemIdList
        )) as any;

        data.map((x: any) => {
          x.IsCombo = !(x.IsCombo == '0');
          if (
            x.AllowQuantitySelection == null ||
            x.AllowQuantitySelection == undefined
          ) {
            x.AllowQuantitySelection = 'True';
          }
          x.SoldOut = CommonFunctions.isItemSoldOut(x);
          x.IconImages = x.ItemTags?.toString().split(',');
          x.Quantity = '1';

          x.AttributeCount = x.AttributeCount ?? 0;

          x.OrderMinimumQuantity = 0;
          x.ModifierCount = modifierCount[x.ItemID];
        });
        resolve(data);
      };
      DatabaseHandler.executeSqlStatement(
        getUpsellItems,
        [],
        setModifiers,
        errorCallback
      );
    });
  }

  // To get all category and related items and add to CategorySubject
  async getPersistentCategoriesAsync(doAgain: boolean) {
    return new Promise<Category[]>((resolve: any, reject: any) => {
      //this.downloadImageQueue = [];

      //(async () => {
      //  if (this.category.length != 0 && !doAgain) {
      //    resolve(this.category);
      //    return;
      //  }
      //  const categories = (await this.getCategoriesFromSql()) as Category[];

      //  if (!categories) return [];

      //  if (categories.length == 0) return [];

      //  for (let i = 0; i < categories.length; i++) {
      //    const cat = categories[i];

      //    if (
      //      cat.DeselectCategoryIcon == '' ||
      //      cat.DeselectCategoryIcon == undefined ||
      //      cat.DeselectCategoryIcon == null
      //    ) {
      //      cat.DeselectCategoryIcon = cat.CategoryIcon;
      //    }

      //    const items = await this.getItemsFromSql(cat.CategoryID.toString());

      //    if (items && items.length > 0) {
      //      for (let j = 0; j < items.length; j++) {
      //        const item = items[j];

      //        item.CategoryName = cat.Name;

      //        const variations = await this.getPersistentVariationsAsync(
      //          item.ItemID
      //        );
      //        item.Variations = variations;

      //var isCombo = item.IsCombo ? item.IsCombo.toString() : 'False'

      //        if (isCombo.toLowerCase() == 'true') {
      //          const comboItem = await this.getPersistentComboGroupItemsAsync(
      //            item.ItemID
      //          );
      //          item.ComboGroup = comboItem;
      //        } else {
      //          const modifier = await this.getPersistentModifiersByIDAsync(
      //            item.ItemID
      //          );

      //          item.Modifiers = modifier;
      //          if(GeneralSetting.getParBrinkIntegrationID()!= ''){
      //            item.Modifiers = await this.parBrinkOverride(item)
      //          }

      //        }
      //      }
      //    }

      //    cat.associatedItems = items;
      //  }

      //  this.category = categories;

      //  this.categorySubject.next(categories);

      //  resolve(this.category);
      //  return;
      //})();
      resolve([] as Category[]);
    });
  }

  // To get all category and related items and add to CategorySubject
  async setItem86InPersistentCategoriesAsync(
    itemId: string,
    itemStatus: string
  ) {
    return new Promise<boolean>((resolve: any, reject: any) => {
      (async () => {
        const categories = this.category as Category[];

        if (!categories) return [];

        if (categories.length == 0) return [];

        for (let i = 0; i < categories.length; i++) {
          const cat = categories[i];
          if (
            cat.DeselectCategoryIcon == '' ||
            cat.DeselectCategoryIcon == undefined ||
            cat.DeselectCategoryIcon == null
          ) {
            cat.DeselectCategoryIcon = cat.CategoryIcon;
          }

          let item = cat.associatedItems?.find((x: any) => x.ItemID == itemId);

          if (item) {
            item.IsActive = itemStatus;
          }
        }

        this.category = categories;
        resolve(true);
        return;
      })();
    });
  }
  //Diffrent Db Called Added here

  getItemByItemIdFromSql(itemId: string) {
    return new Promise<any[]>((resolve: any) => {
      let currentTime = CommonFunctions.getCurrentTime();
      let dayId = new Date().getDay();
      if (dayId == 0) {
        dayId = 7;
      }

      let currentDate = CommonFunctions.getCurrentformatedtDate(new Date());

      let priceQuery = '';
      if (
        GeneralSetting.getMenuLevelID() &&
        GeneralSetting.getMenuLevelID() != '0'
      ) {
        let menuLevelID = GeneralSetting.getMenuLevelID();
        priceQuery = `, CASE WHEN
        (select count(Price) from OtherPrice_Item_Mappings as OIM where  OIM.OPMID = '${menuLevelID}' AND OIM.ItemID=im.ItemID)  > 0
        THEN
        (select Price from OtherPrice_Item_Mappings as OIM where  OIM.OPMID = '${menuLevelID}' AND OIM.ItemID=im.ItemID)
        Else
        im.Price end as Price `;
      } else {
        priceQuery = `,im.price as Price `;
      }
      let getItemStatement =
        `select  distinct im.itemid as ItemID
                           ,case when im.displayname = '' then im.name
                                 else im.displayname
                             end as Name
                           ,case when im.displayname = '' then im.name
                                 else im.displayname
                             end as DisplayName
                           ,im.kotorkdsdisplayname as KOTDisplayName
                           ,im.fulldescription as FullDescription
                           ,im.isitemdetailscreenshowkiosk as IsItemDetailScreenShowKiosk
                           ,im.fulldescriptionlang as FullDescriptionLang
                           ,im.shortdescription as ShortDescription
                           ,im.shortdescriptionlang as ShortDescriptionLang
                           ,im.taxgroupid as TaxGroupId
                           ` +
        priceQuery +
        `
                           ,im.isactive as IsActive
                           ,cast(im.displayorder as int) as DisplayOrder
                           ,im.calorie as Calorie
                           ,im.MaxCalories as MaxCalories
                           ,im.itemcategorysalestypeid as ItemCategorySalesTypeID
                           ,(
                             select  imageurl
                               from  itemimages
                              where  itemimages.itemid == im.itemid
                              limit  1
                             ) as ImageUrl
                           ,cm.categoryid as CategoryID
                           ,cm.displayname as CategoryName
                           ,im.isbuildermode as IsBuilderMode
                           ,im.isboxbuildermode as IsBoxBuilderMode
                           ,im.IsAskForName
                           ,im.RefItemId
                           ,im.orderminimumquantity as OrderMinimumQuantity
                           ,im.ordermaximumquantity as OrderMaximumQuantity
                           ,mm.modifiercount as ModifierCount
                           ,ia.attributecount as AttributeCount
                           ,im.IsCombo
                           ,im.lowstockactivity as LowStockActivity
                           ,ris.currentstock as CurrentStock
                           ,ris.lowthreshold as LowThreshold
                           ,(SELECT GROUP_CONCAT(DISTINCT(FM.IconImage)) FROM Items AS I
                              JOIN FilterMasters AS FM
                                ON FM.FilterID = IFM.FilterID
                                AND I.IsDeleted = 'False'
                              JOIN Item_Filter_Mappings as IFM
                                ON IFM.ItemID = I.ItemID
                                WHERE I.IsActive = 'True' AND IM.ItemID = I.ItemID
                                GROUP BY I.ItemID) as ItemTags,
                                im.ReceiptItemGroupID as ReceiptItemGroupID

                       from items as im
                    inner join item_category_mappings as cmm on cmm.itemId = im.itemId
                    inner join  categorymasters as cm on cm.categoryid = cmm.categoryid and cm.isdeleted = 'False' and  cm.isactive = 'True' and cm.IsShowOnKiosk = 'True'
                    inner JOIN ItemDayWiseMasters IDWM ON IDWM.ItemID = IM.ItemID and IDWM.DayID = '${dayId}'
                                and IDWM.StartTime <= time('${currentTime}')
                                AND IDWM.EndTime >= time('${currentTime}')
                                AND IDWM.IsActive ='True'
                    inner join CategoryDayWiseMasters CD ON cm.CategoryID = CD.CategoryID AND CD.DayID = '${dayId}'
                                AND CD.StartTime <= time('${currentTime}')
                                AND CD.EndTime >= time('${currentTime}')
                                AND CD.IsActive = 'True'
                      left  join  readyitemstock as ris
                        on  im.itemid = ris.itemid
                      left  join (select '0' as itemid, '0' as modifiercount) as mm on  mm.itemid = '0'
                      left  join  (
                             select  case when ia.itemid = '0' then caim.itemid
                                          else ia.itemid
                                      end as itemid
                                    ,count(ia.itemid) as attributecount
                               from  itemattributes as ia
                               left  join  commonattribute_item_mappings as caim
                                 on  caim.itemattributeid = ia.itemattributeid
                              group  by  ia.itemid
                                    ,caim.itemid
                             ) as ia
                        on  ia.itemid = im.itemid

                     where
                            im.isdeleted = 'False'
                       and  im.isactive = 'True'
                       and  im.isshowonkiosk = 'True'
                       and  im.iscomboitemonly = 'False'
                    and (IM.StartDate <= '${currentDate}' OR IM.StartDate = '')
                    AND(IM.EndDate >= '${currentDate}' OR IM.EndDate = '')
                    And im.itemid = '${itemId}'
                    order  by  cast(im.displayorder as int)
                    limit 1
                      `;

      const errorCallback = (statement: any, error: any) => {
        console.log('getChildModifierByItemAndModifierID :- ', error.message);

        resolve([]);
      };
      const setItems = async (tx: string, results: any) => {
        var data: ItemV2[] = Array.from(results.rows);

        let itemIdList = Array.from(data.map((x) => x.ItemID));

        let modifierCount = (await this.getModifierCountByItemIds(
          itemIdList
        )) as any;

        data.map((x: any) => {
          x.IsCombo = !(x.IsCombo == '0');
          x.AllowQuantitySelection = 'True';
          x.SoldOut = CommonFunctions.isItemSoldOut(x);
          x.IconImages = x.ItemTags?.toString().split(',');
          x.OrderMaximumQuantity = 99999;
          x.OrderMinimumQuantity = 0;
          x.ReceiptItemGroupID = x.ReceiptItemGroupID
            ? x.ReceiptItemGroupID
            : '0';
          x.ModifierCount = modifierCount[x.ItemID];
        });

        resolve(data);
      };
      DatabaseHandler.executeSqlStatement(
        getItemStatement,
        [],
        setItems,
        errorCallback
      );
    });
  }

  //For variation

  async getPersistentVariationsAsync(id: string) {
    let variations = await this.getVariationsByItemIDFromSql(id);

    for (let i = 0; i < variations.length; i++) {
      let variation = variations[i];

      if (variation && variation != undefined) {
        let variationOptions = await this.getVariationOptionByIDFromSql(
          variation.ItemAttributeID
        );
        variation.variationOptions = variationOptions;
      }
    }

    return variations;
  }

  getVariationOptionByID(id: string): Observable<VariationOption[]> {
    return from(this.getVariationOptionByIDFromSql(id));
  }

  getVariationOptionByIDFromSql(id: string, skuId: string = '') {
    return new Promise<VariationOption[]>((resolve: any) => {
      let priceQuery = '';
      if (
        GeneralSetting.getMenuLevelID() &&
        GeneralSetting.getMenuLevelID() != '0'
      ) {
        let menuLevelID = GeneralSetting.getMenuLevelID();
        priceQuery = `, CASE WHEN
        (select count(Price) from OtherPrice_IAO_Mappings as OPIAO where  OPIAO.OPMID = '${menuLevelID}' AND OPIAO.ItemAttributeOptionID=IAO.ItemAttributeOptionID)  > 0
        THEN
        (select Price from OtherPrice_IAO_Mappings as OPIAO where  OPIAO.OPMID = '${menuLevelID}' AND OPIAO.ItemAttributeOptionID=IAO.ItemAttributeOptionID)
        Else
        IAO.Price END AS Price`;
      } else {
        priceQuery = `,IAO.Price AS Price`;
      }
      let getItems =
        `SELECT DISTINCT
          IAO.ItemAttributeOptionID AS ItemAttributeOptionID
        , IAO.OptionName
        , IAO.ImageUrl` +
        priceQuery +
        `, IAO.Calories
        , IAO.ItemAttributeID
        , IAO.ItemID
        , IAO.DisplayOrder

      FROM
        ItemAttributeOptions AS IAO

      WHERE
      IAO.IsActive = 'True'
        AND IAO.ItemAttributeID = '${id}'`;

      if (skuId != '') {
        getItems += ` AND IAO.SKU = '${skuId}'`;
      }

      getItems += ` ORDER BY CAST(IAO.DisplayOrder AS int)`;

      const logError = (statement: any, error: any) => {
        console.log(
          'Database Service , Error getVariationOptionByIDFromSql',
          error.message
        );

        resolve([]);
      };

      const setItems = (transaction: String, results: any) => {
        var data: VariationOption[] = Array.from(results.rows);
        for (let dat of data) {
          // if (dat.ImageUrl && dat.ImageUrl != '') {
          //   this.downloadImageQueue.push(dat.ImageUrl);
          // }

          dat.isSelected = false;
        }

        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(getItems, [], setItems, logError);
    });
  }

  getVariationsByItemIDFromSql(id: string) {
    return new Promise<Variation[]>((resolve: any) => {
      const getItems = `SELECT DISTINCT
      IA.ItemAttributeID AS ItemAttributeID
      ,IA.Name AS Name
      ,IA.ItemID AS ItemID

      FROM
        ItemAttributes AS IA

      WHERE
      IA.IsActive = 'True'
        AND IA.ItemID = '${id}'

      UNION
      SELECT DISTINCT
      CAIM.ItemAttributeID AS ItemAttributeID
      ,Name AS Name
      ,CAIM.ItemID AS ItemID

      FROM
      CommonAttribute_Item_Mappings AS CAIM
      LEFT JOIN ItemAttributes
      ON ItemAttributes.ItemAttributeID = CAIM.ItemAttributeID

      WHERE
        CAIM.ItemID = '${id}'
      `;

      const logError = (statement: any, error: any) => {
        console.log(
          'Database Service , Error getVariationsByItemIDFromSql',
          error.message
        );

        resolve([]);
      };

      const setItems = (transaction: String, results: any) => {
        var data: Variation[] = Array.from(results.rows);

        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(getItems, [], setItems, logError);
    });
  }

  getPersistentVariations(id: string): Promise<Variation[]> {
    return new Promise<Variation[]>((resolve: any) => {
      this.getVariationsByItemIDFromSql(id).then((variations: any) => {
        variations.forEach((variation: any) => {
          this.getVariationOptionByIDFromSql(variation.ItemAttributeID)
            .then((options: any) => {
              variation.variationOptions = options;
            })
            .finally(() => resolve(variations));
        });
      });
    });
  }

  getVariationOptionByOptionID(id: string) {
    return new Promise<VariationOption[]>((resolve: any) => {
      const getItems = `SELECT DISTINCT
          IAO.ItemAttributeOptionID AS ItemAttributeOptionID
        , IAO.OptionName
        , IAO.ImageUrl
        , IAO.Price
        , IAO.Calories
        , IAO.ItemAttributeID
        , IAO.ItemID
        , IAO.DisplayOrder

      FROM
        ItemAttributeOptions AS IAO

      WHERE
      IAO.IsActive = 'True'
        AND IAO.ItemAttributeOptionID = '${id}'
      `;

      const logError = (statement: any, error: any) => {
        console.log(
          'Database Service , Error getVariationOptionByOptionID',
          error.message
        );

        resolve([]);
      };

      const setItems = (transaction: String, results: any) => {
        var data: VariationOption[] = Array.from(results.rows);

        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(getItems, [], setItems, logError);
    });
  }

  //For variation end

  //For Combo

  async getPersistentComboGroupItemsAsync(id: string) {
    let comboGroups = await this.getAllComboGroupsFromSQl(id);

    for (var i = 0; i < comboGroups.length; i++) {
      let group = comboGroups[i];
      let groupItems = await this.getComboItemsFromSql(group.ComboGroupID, id);
      var isHidden = true;
      for (let j = 0; j < groupItems.length; j++) {
        const comboItem = groupItems[j];
        if (comboItem.IsInvisibleItem == 'False') {
          isHidden = false;
          break;
        } else {
          comboItem.isSelected = true;
        }
      }

      group.isHidden = isHidden;
      group.Items = groupItems;

      if (groupItems && groupItems != undefined) {
        for (let k = 0; k < groupItems.length; k++) {
          if (groupItems[k].isSelected) {
            comboGroups[i].IsSelected = true;
            break;
          }
        }
      }
    }

    return await comboGroups;
  }

  // For combo End

  async getPersistentModifiersByIDAsync(id: string) {
    let queryMods = await this.getModifierByItemIDFromSql(id);

    let formattedModifier = await this.getAllModifierIngredients(queryMods);

    return formattedModifier.sort((a: ModifierV2, b: ModifierV2) =>
      Number(a.DisplayOrder) > Number(b.DisplayOrder) ? 1 : -1
    );
  }

  async getAllModifierIngredients(allModifier: ModifierV2[]) {
    let parentModifiers = allModifier.filter(
      (x) => x.ParentID == '' || x.ParentID == '0'
    );

    const childModifiers = allModifier.filter(
      (x) => x.ParentID != '' && x.ParentID != '0'
    );

    for (
      let parentModifierIndex = 0;
      parentModifierIndex < parentModifiers.length;
      parentModifierIndex++
    ) {
      let getModifierIngPer = performance.now();

      console.log(
        'get Modifier Ingridient by ModifierId Performance :- ' +
          getModifierIngPer
      );

      const modifier = parentModifiers[parentModifierIndex];

      modifier.IsForced =
        (modifier.IsModifier86 == 'False' && 'False') || modifier.IsForced;
      modifier.isValid =
        (modifier.IsModifier86 == 'False' && true) || modifier.isValid;

      const ingredients = await this.getIngredientsByModIDFromSql(
        modifier.ModifierID
      );
      modifier.Ingredients = ingredients;

      if (ingredients && ingredients != undefined) {
        for (let i = 0; i < ingredients.length; i++) {
          if (ingredients[i].IsSelected) {
            modifier.IsSelected = true;
            break;
          }
        }
      }

      let nestedModifiers = await this.getChildModifierByItemAndModifierID(
        modifier.ItemID,
        modifier.ModifierID
      );

      modifier.Ingredients.push(...nestedModifiers);

      modifier.Ingredients = modifier.Ingredients.sort((a: any, b: any) =>
        Number(a.DisplayOrder) > Number(b.DisplayOrder) ? 1 : -1
      );

      const childModifer = childModifiers.filter(
        (x) => x.ParentID == modifier.ModifierID
      );

      console.log(
        'get Modifier Ingridient by ModifierId Performance End :- ' +
          (getModifierIngPer - performance.now()) / 1000
      );

      modifier.Ingredients.push(...childModifer);
    }

    parentModifiers = parentModifiers.filter(
      (x) => x.Ingredients && x.Ingredients.length > 0
    );

    return parentModifiers;
  }

  // For Modifier end
  getPromoPricingFromSql() {
    if (this.promos.length > 0) {
      return new Promise((resolve, reject) => {
        resolve(this.promos);
      });
    } else {
      const date = new Date();
      let day = String(date.getDay());
      if (day == '0') {
        day = '7';
      }
      return new Promise((resolve) => {
        const getPromoString = `
      SELECT DISTINCT
      SPPCM.SchedulePromotionId
      , SPPCM.CategoryId
      , SPPM.IsByWeek
      , SPPM.IsByDate
      , SPPM.StartDate
      , SPPM.EndDate
      , SPPM.Name
      , SIWDM.DayID
      , SIWDM.StartTime
      , SIWDM.EndTime
      , SPPDM.ItemID
      , SPPDM.BasePrice
      , SPPDM.NewPrice
      , ICM.CategoryID
      , ICM.ItemID
      FROM
      SchedulePromoPricingCategoryMappings AS SPPCM
      LEFT JOIN
      SchedulePromoPricingMasters AS SPPM
      ON
      SPPCM.SchedulePromotionId = SPPM.SchedulePromotionId
      LEFT JOIN
      ScheduleItemWeekdayMaster AS SIWDM
      ON SPPCM.SchedulePromotionId=SIWDM.SchedulePromotionId
      LEFT JOIN
      SchedulePromoPricingDetailMasters AS SPPDM
      ON SPPCM.SchedulePromotionId=SPPDM.SchedulePromotionId
      JOIN Item_Category_Mappings as ICM on SPPDM.ItemID=ICM.ItemID
       `;
        const errorCallback = (statement: any, error: any) => {
          console.log(
            'Database Service , Error getPromoPricingFromSql',
            error.message
          );

          resolve([]);
        };
        const setItems = (tx: string, results: any) => {
          let data: any[] = Array.from(results.rows);
          let hours = String(date.getHours());
          if (hours.length === 1) {
            hours = '0' + hours;
          }
          let minutes = String(date.getMinutes());
          if (minutes.length === 1) {
            minutes = '0' + minutes;
          }
          let numMinutes = Number(minutes);
          let numHours = Number(hours);
          let applicableData: any[] = data.filter((el: any) => {
            const date = new Date();
            let day = String(date.getDay());
            if (day == '0') {
              day = '7';
            }
            //check if element by week
            if (el.IsByWeek == 'True') {
              // if yes, check day similarity
              if (el.DayID == day) {
                //if day similarity, check time similarity
                //reserve data space for time comparison in parental scope
                let tempTransform: any;
                let transformedTime: any;
                let splitTime: any;
                let StartHours: any;
                let StartMinutes: any;
                let EndHours: any;
                let EndMinutes: any;
                if (
                  el.StartTime &&
                  el.StartTime != '' &&
                  el.StartTime != null
                ) {
                  //begin transformation
                  tempTransform = el.StartTime.split('.');
                  transformedTime = tempTransform[0];
                  splitTime = transformedTime.split(':');
                  StartHours = Number(splitTime[0]);
                  StartMinutes = Number(splitTime[1]);
                  //begin time comparison
                  if (
                    numHours > StartHours ||
                    (numHours == StartHours && numMinutes >= StartMinutes)
                  ) {
                    tempTransform = el.EndTime.split('.');
                    transformedTime = tempTransform[0];
                    splitTime = transformedTime.split(':');
                    EndHours = Number(splitTime[0]);
                    EndMinutes = Number(splitTime[1]);
                    if (
                      numHours < EndHours ||
                      (numHours == EndHours && numMinutes <= EndMinutes)
                    ) {
                      //if yes, check date similarity
                      if (el.IsByDate == 'True') {
                        //if date similar, return item
                        let elStartDate = Date.parse(
                          String(
                            el.StartDate.slice(0, -2) +
                              ' ' +
                              el.StartDate.slice(-2)
                          )
                        );
                        let elEndDate = Date.parse(
                          String(
                            el.EndDate.slice(0, -2) + ' ' + el.EndDate.slice(-2)
                          )
                        );
                        if (
                          Date.parse(String(date)) >= elStartDate &&
                          Date.parse(String(date)) <= elEndDate
                        ) {
                          return el;
                        }
                      } else {
                        return el;
                      }
                    } //else nothing
                  }
                }
              }
            } else {
              //if date similar, return item
              let elStartDate = Date.parse(
                String(el.StartDate.slice(0, -2) + ' ' + el.StartDate.slice(-2))
              );
              let elEndDate = Date.parse(
                String(el.EndDate.slice(0, -2) + ' ' + el.EndDate.slice(-2))
              );
              if (
                Date.parse(String(date)) >= elStartDate &&
                Date.parse(String(date)) <= elEndDate
              ) {
                return el;
              }
            }
          });
          this.promos = applicableData;
          resolve(applicableData);
        };
        DatabaseHandler.executeSqlStatement(
          getPromoString,
          [],
          setItems,
          errorCallback
        );
      });
    }
  }

  getPusherCredentials(): Promise<PusherMaster[]> {
    return new Promise<PusherMaster[]>((resolve: any) => {
      const getPusher = ` SELECT * FROM PusherSettingMasters`;

      const errorCallback = (statement: any, error: any) => {
        console.log(
          'Database Service , Error getPusherCredentials',
          error.message
        );

        resolve(undefined);
      };

      const getPusherCrednetials = (tx: string, results: any) => {
        var data: PusherMaster[] = Array.from(results.rows);

        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getPusher,
        [],
        getPusherCrednetials,
        errorCallback
      );
    });
  }

  /**
   * soldout/unsold item will show item as soldout/available in menu @nilesh
   * @param itemId
   * @param itemStatus
   * @returns
   */
  updateItem86ById(itemId: string, itemStatus: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      let query = `select * from Items where ItemID = '${itemId}'`;
      let isItem = await DatabaseHandler.getDataFromQuery<any[]>(
        query
      ).toPromise();
      if (!isItem || isItem.length <= 0) {
        this.isDoRefresh = true;
        this.MenuRefresh.next(true);
        return;
      }

      const DataCallBack = (tx: any, results: any) => {
        resolve(true);
      };

      const ErrorCallBack = (tx: any, results: any) => {
        console.log(
          'Database Service , Error updateItemStatusById',
          results.message
        );

        resolve(true);
      };

      var sqlString =
        "UPDATE Items SET IsItem86 = '" +
        itemStatus +
        "' WHERE ItemID = '" +
        itemId +
        "'";
      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        DataCallBack,
        ErrorCallBack
      );
    });
  }

  async updateItemPriceById(itemId: string, price: number): Promise<boolean> {
    let query = `select * from Items where ItemID = '${itemId}'`;
    let isItem = await DatabaseHandler.getDataFromQuery<any[]>(
      query
    ).toPromise();
    if (!isItem || isItem.length <= 0) {
      this.isDoRefresh = true;
      this.MenuRefresh.next(true);
      return true;
    }

    var sqlString =
      "UPDATE Items SET Price = '" +
      price.toFixed(2) +
      "' WHERE ItemID = '" +
      itemId +
      "'";
    try {
      var resp = await DatabaseHandler.executeSqlStatementAsync(sqlString, []);
      return true;
    } catch (ex) {
      return false;
    }
  }

  updateConcessionaireActiveById(
    conId: string,
    itemStatus: string
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const DataCallBack = (tx: any, results: any) => {
        resolve(true);
      };

      const ErrorCallBack = (tx: any, results: any) => {
        console.log(
          'Database Service , Error updateItemStatusById',
          results.message
        );

        resolve(true);
      };

      var sqlString =
        "UPDATE ConcessionaireMasters SET IsActive = '" +
        itemStatus +
        "' WHERE ConcessionaireID = '" +
        conId +
        "'";

      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        DataCallBack,
        ErrorCallBack
      );
    });
  }

  /**
   * Enable/Disable item will hide/show item in menu @nilesh
   * @param itemId
   * @param itemStatus Enable disable item status
   * @returns
   */
  updateItemStatusById(itemId: string, itemStatus: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      let query = `select * from Items where ItemID = '${itemId}'`;
      let isItem = await DatabaseHandler.getDataFromQuery<any[]>(
        query
      ).toPromise();
      if (!isItem || isItem.length <= 0) {
        this.isDoRefresh = true;
        this.MenuRefresh.next(true);
        return;
      }

      const DataCallBack = (tx: any, results: any) => {
        resolve(true);
      };

      const ErrorCallBack = (tx: any, results: any) => {
        console.log(
          'Database Service , Error updateItemStatusById',
          results.message
        );

        resolve(true);
      };

      var sqlString =
        "UPDATE Items SET IsActive = '" +
        itemStatus +
        "' WHERE ItemID = '" +
        itemId +
        "'";
      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        DataCallBack,
        ErrorCallBack
      );
    });
  }

  async updateModifierStatusById(itemId: string, itemStatus: string) {
    let query = `select * from ModifierMasters where ModifierID = '${itemId}'`;
    let isModifier = await DatabaseHandler.getDataFromQuery<any[]>(
      query
    ).toPromise();
    if (!isModifier || isModifier.length <= 0) {
      this.isDoRefresh = true;
      this.MenuRefresh.next(true);
      return;
    }

    this.update86StatusById(itemId, itemStatus, true, false);

    const DataCallBack = (tx: any, results: any) => {
      //console.log("Success data change item status");
    };

    const ErrorCallBack = (tx: any, results: any) => {
      console.log(
        'Database Service , Error updateModifierStatusById',
        results.message
      );
    };

    var sqlString =
      "UPDATE ModifierMasters SET IsModifier86 = '" +
      itemStatus +
      "' WHERE ModifierID = '" +
      itemId +
      "'";

    DatabaseHandler.executeSqlStatement(
      sqlString,
      [],
      DataCallBack,
      ErrorCallBack
    );
  }

  private async setModifierStatus(
    modifiers: (ModifierV2 | ModifierIngredientV2)[],
    id: string,
    itemStatus: string,
    isIngredient: boolean,
    needToUpdateIsSelected: boolean
  ) {
    for (let k = 0; k < modifiers.length; k++) {
      const modifier = modifiers[k];

      if (!isIngredient && modifier.IsModifier) {
        const mod = modifier as ModifierV2;

        if (modifier.ModifierID == id) {
          mod.IsModifier86 = itemStatus;
          if (needToUpdateIsSelected) {
            if (mod.IsModifier86 == 'False') {
              mod.IsSelected = false;
            }
          }
        }

        if (mod.Ingredients && mod.Ingredients.length > 0) {
          if (isIngredient) {
            const childModifierList = mod.Ingredients.filter(
              (x) => x.IsModifier
            ) as ModifierV2[];

            this.setModifierStatus(
              childModifierList,
              id,
              itemStatus,
              isIngredient,
              needToUpdateIsSelected
            );
          } else {
            const childModifierList = mod.Ingredients;
            //   .filter(
            //   (x) => x.IsIngredient
            // ) as ModifierIngredientV2[];

            this.setModifierStatus(
              childModifierList,
              id,
              itemStatus,
              isIngredient,
              needToUpdateIsSelected
            );
          }
        }
      } else {
        if (modifier.IsModifier) {
          const mod = modifier as ModifierV2;

          if (mod.Ingredients && mod.Ingredients.length > 0) {
            const childModifierList = mod.Ingredients;
            //   .filter(
            //   (x) => x.IsIngredient
            // ) as ModifierIngredientV2[];

            this.setModifierStatus(
              childModifierList,
              id,
              itemStatus,
              isIngredient,
              needToUpdateIsSelected
            );
          }
        } else if (modifier.IngredientID == id) {
          const ing = modifier as ModifierIngredientV2;

          ing.IsIngredient86 = itemStatus;
          if (needToUpdateIsSelected) {
            if (ing.IsIngredient86 == 'False') {
              ing.IsSelected = false;
            }
          }
        }
      }
    }
  }

  private async update86StatusById(
    Id: string,
    status: string,
    isModifier: boolean,
    isIngredient: boolean
  ) {
    const allCategory = this.category as Category[];

    for (let i = 0; i < allCategory.length; i++) {
      if (
        allCategory[i].associatedItems &&
        allCategory[i].associatedItems != undefined
      ) {
        const items = allCategory[i].associatedItems as ItemV2[];
        for (let j = 0; j < items.length; j++) {
          const item = items[j] as ItemV2;
          var isCombo = item.IsCombo ? item.IsCombo.toString() : 'False';

          if (isCombo.toLowerCase() != '0' && isCombo.toLowerCase() == 'true') {
            for (const comboGroup of item.ComboGroup) {
              for (const comboItem of comboGroup.Items) {
                this.updateItem86Status(
                  comboItem,
                  Id,
                  status,
                  isModifier,
                  isIngredient,
                  false
                );
              }
            }
          } else {
            this.updateItem86Status(
              item,
              Id,
              status,
              isModifier,
              isIngredient,
              false
            );
          }
        }
      }
    }
  }

  async updateItem86Status(
    item: ItemV2,
    Id: string,
    status: string,
    isModifier: boolean,
    isIngredient: boolean,
    needToUpdateIsSelected: boolean
  ) {
    var isCombo = item.IsCombo ? item.IsCombo.toString() : 'False';

    if (isCombo.toLowerCase() != '0' && isCombo.toLowerCase() == 'true') {
      for (const comboGroup of item.ComboGroup) {
        for (const comboItem of comboGroup.Items) {
          this.updateItem86Status(
            comboItem,
            Id,
            status,
            isModifier,
            isIngredient,
            false
          );
        }
      }
    } else {
      if (item && item.Modifiers) {
        this.setModifierStatus(
          item.Modifiers,
          Id,
          status,
          isIngredient,
          needToUpdateIsSelected
        );
      }
    }
  }

  async update86InModifierList(
    Id: string,
    status: string,
    modifierList: ModifierV2[],
    isIngredient: boolean,
    needToUpdateIsSelected: boolean
  ) {
    this.setModifierStatus(
      modifierList,
      Id,
      status,
      isIngredient,
      needToUpdateIsSelected
    );
  }

  async updateIngredientStatusById(itemId: string, itemStatus: string) {
    let query = `select * from Ingredients where IngredientID = '${itemId}'`;
    let isIngredient = await DatabaseHandler.getDataFromQuery<any[]>(
      query
    ).toPromise();
    if (!isIngredient || isIngredient.length <= 0) {
      this.isDoRefresh = true;
      this.MenuRefresh.next(true);
      return;
    }

    this.update86StatusById(itemId, itemStatus, false, true);

    const DataCallBack = (tx: any, results: any) => {
      // console.log("Success data change item status");
    };

    const ErrorCallBack = (tx: any, results: any) => {
      // console.log("Error data change item status", results);
    };

    var sqlString =
      "UPDATE Ingredients SET IsIngredient86 = '" +
      itemStatus +
      "' WHERE IngredientID = '" +
      itemId +
      "'";
    DatabaseHandler.executeSqlStatement(
      sqlString,
      [],
      DataCallBack,
      ErrorCallBack
    );
  }

  getAllItemTags() {
    return new Promise<any[]>((resolve: any) => {
      const getItemTags = `
                SELECT
                    GROUP_CONCAT(DISTINCT(FM.IconImage)) AS 'AllTags'
                    ,GROUP_CONCAT(DISTINCT(FM.FilterName)) AS 'AllTagNames'
                  FROM
                    FilterMasters as FM
                `;

      const logError = (statement: any, error: any) => {
        resolve([]);
      };
      const setItems = (transaction: string, results: any) => {
        var data: any[] = [];

        for (let i = 0; i < results.rows.length; i++) {
          let it = {
            AllTags: results.rows[i].AllTags?.toString().split(','),
            AllTagNames: results.rows[i].AllTagNames?.toString().split(','),
          };

          data.push(it);
        }

        resolve(data);
      };
      DatabaseHandler.executeSqlStatement(getItemTags, [], setItems, logError);
    });
  }

  // To get all category and related items and add to CategorySubject
  getItemDetails(item: ItemV2) {
    return new Promise<ItemV2>((resolve: any, reject: any) => {
      (async () => {
        if (item != null) {
          const variations = await this.getPersistentVariationsAsync(
            item.ItemID
          );
          item.Variations = variations;

          var isCombo = item.IsCombo ? item.IsCombo.toString() : 'False';
          if (isCombo.toLowerCase() == 'true') {
            const comboItem = await this.getPersistentComboGroupItemsAsync(
              item.ItemID
            );
            item.ComboGroup = comboItem;
            item.Modifiers = [];
          } else {
            const modifier = await this.getPersistentModifiersByIDAsync(
              item.ItemID
            );
            item.Modifiers = modifier;
          }
        }
        resolve(item);
      })();
    });
  }

  /**
   * get loyalty program setting from database
   */
  getLoyaltyProgram() {
    return new Promise<Boolean>((resolve) => {
      var sqlStr =
        'SELECT IsActive FROM LoyaltyPrograms WHERE IsActive = "True"';

      const callback = (tx: string, data: any) => {
        if (data.rows.length > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      const error = (err: any) => {
        var log = new loggingData(
          'Loyalty Program Error',
          'Loyalty Program Encountered Error etching data',
          'Loyalty Error',
          `Loyalty Program Encountered Error: ${err}`,
          true
        );
        this.logger.sendLogToServer(log);
        resolve(false);
      };

      DatabaseHandler.executeSqlStatement(sqlStr, [], callback, error);
    });
  }

  //Get Day Id from Day
  getDayId(dayName: string) {
    return new Promise<string>((resolve: any, reject: any) => {
      const getDayId = `select DayID from WeekdayMasters where DayName='${dayName}'
        `;

      const errorCallback = (statement: any, error: any) => {
        console.log('Database Service , Error getDayId :- ', error);
        resolve([] as ModifierV2[]);
      };

      const showDayId = (tx: string, results: any) => {
        var data = results.rows[0].DayID;
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getDayId,
        [],
        showDayId,
        errorCallback
      );
    });
  }

  getCategoryHeader(): Promise<any> {
    return new Promise((resolve, rejects) => {
      const CallBack = (tx: any, result: any): void => {
        resolve(result.rows);
      };
      const ErrorCallBack = (tx: any, result: any): void => {
        resolve([]);
      };
      var sqlString = 'SELECT HeaderImage, CategoryID FROM CategoryMasters';
      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        CallBack,
        ErrorCallBack
      );
    });
  }

  //QueryBasedApproach

  private getMinMaxRule(
    min: number,
    max: number,
    minNoOfSelection: string,
    CountOption: string
  ) {
    let rule = '';

    if (min > 0 || max > 0) {
      if (min) {
        // has min
        if (max > 0) {
          // has min and max
          if (min == max) {
            rule = `Choose exactly ${minNoOfSelection}`; // min and max are the same
          } else {
            rule = `Choose between ${minNoOfSelection} and ${CountOption}`; // min and max are different
          }
        } else {
          rule = `Choose at least ${minNoOfSelection}`; // only has min
        }
      } else if (max > 0) {
        // has max
        if (min == 1 && max == 1) {
          rule = `Choose exactly ${CountOption}`;
        } else if (max == 1) {
          rule = `Choose Any ${CountOption}`;
        } else if (max > 1) {
          rule = `Choose Up To ${CountOption}`;
        }
      }
    }

    return [min, max, rule];
  }

  getAllCategoryFromSql(categoryId: string, byId: boolean = false) {
    return new Promise<Category[]>((resolve: any, reject: any) => {
      let dayId = new Date().getDay();
      if (dayId == 0) {
        dayId = 7;
      }

      let getCategories = ` SELECT DISTINCT
                          CM.CategoryID AS CategoryID
                          ,CM.Name
                          ,CM.DisplayName AS DisplayName
                          ,CM.Description AS Description
                          ,CM.ParentCategoryID AS ParentCategoryID
                          ,CM.IncludeInTopMenu AS IncludeInTopMenu
                          ,CAST(CM.DisplayOrder AS int) AS DisplayOrder
                          ,CM.ColorCode AS ColorCode
                          ,CM.HeaderImage AS HeaderImage
                          ,CM.CategoryIcon AS CategoryIcon
                          ,CM.DeselectCategoryIcon AS DeselectCategoryIcon
                          ,CM.DisplayCatItemRowCount
                          ,CM.HideHeaderText AS HideHeaderText
                          ,CM.BackgroundImage AS BackgroundImage
                          ,CM.IsAlkol AS IsAlkol
                          ,CM.DisplayCategory AS DisplayCategory
                          ,CM.ShowasClassicModifierGroup  AS ShowasClassicModifierGroup
                          ,CM.LabelforItemSelection AS LabelforItemSelection
                          ,(select CategoryImages.ImageUrl
                               from  CategoryImages
                              where  CategoryImages.CategoryID == CM.CategoryID
                              limit  1
                             ) as ImageUrl
                          FROM CategoryMasters as CM
                          JOIN CategoryDayWiseMasters CD ON CM.CategoryID = CD.CategoryID AND CD.DayID = '${dayId}'
                              AND CD.StartTime <= time('${CommonFunctions.getCurrentTime()}')
                              AND CD.EndTime >= time('${CommonFunctions.getCurrentTime()}')
                              AND CD.IsActive = 'True'
                          WHERE
                              CM.IsDeleted = 'False'
                              AND CM.IsActive = 'True'

                              AND CM.DisplayCategory = 'True'
                              AND CM.IsShowOnKiosk = 'True'
      `;

      if (byId) {
        getCategories += `And CM.CategoryID = ${categoryId}`;
      }

      getCategories += ' ORDER BY CAST(CM.DisplayOrder AS INT) ASC';

      const errorCallback = (statement: any, error: any) => {
        console.log('Category Get Error', error);

        this.categorySubject.next([]);
        resolve([] as Category[]);
      };

      const showCategories = (tx: string, results: any) => {
        var data: Category[] = [];

        for (var i = 0; i < results.rows.length; i++) {
          const cat = results.rows[i];

          const category = {
            CurrentStock: '',
            LowThreshold: '',
            SoldOut: false,
            IconImages: [] as string[],
            ItemTags: [] as string[],
            CategoryIcon: cat.CategoryIcon,
            DeselectCategoryIcon: cat.DeselectCategoryIcon,
            Name:
              !cat.DisplayName || cat.DisplayName == ''
                ? cat.Name
                : cat.DisplayName,
            ImageUrl: cat.ImageUrl,
            CategoryID: cat.CategoryID,
            items: [] as any[],
            isActive: true,
            associatedItems: [] as ItemV2[],
            ParentCategoryID: cat.ParentCategoryID,
            isSub: false,
            parent: {} as Category,
            HideHeaderText: cat.HideHeaderText,
            IncludeInTopMenu: cat.IncludeInTopMenu,
            DisplayOrder: cat.DisplayOrder,
            ColorCode: cat.ColorCode,
            HeaderImage: cat.HeaderImage,
            DisplayCatItemRowCount: cat.DisplayCatItemRowCount,
            BackgroundImage: cat.BackgroundImage,
            isAlkol: cat.IsAlkol,
            DisplayName:
              !cat.DisplayName || cat.DisplayName == ''
                ? cat.Name
                : cat.DisplayName,
            DayIDs: [] as string[],
            EndTimes: [] as string[],
            StartTimes: [] as string[],
            TimeActives: [] as string[],
            DisplayCategory: cat.DisplayCategory,
            ShowasClassicModifierGroup: cat.ShowasClassicModifierGroup,
            LabelforItemSelection: cat.LabelforItemSelection,
            Description: cat.Description,
          } as Category;

          if (cat.EndTimes) {
            category.EndTimes = results.rows[i].EndTimes.split(',');
          }
          if (cat.StartTimes) {
            category.StartTimes = results.rows[i].StartTimes.split(',');
          }
          if (cat.TimeActives) {
            category.TimeActives = results.rows[i].TimeActives.split(',');
          } else {
            category.TimeActives = [
              'False',
              'False',
              'False',
              'False',
              'False',
              'False',
              'False',
            ];
          }
          if (cat.DayIDs) {
            category.DayIDs = cat.DayIDs.split(',');
          }
          if (cat.DisplayCategory) {
            category.DisplayCategory = cat.DisplayCategory;
          }

          cat.associatedItems = [];

          if (cat.isSub) {
            cat.isSub = false;
          }

          if (category) {
            data.push(category);
          }
        }

        this.categorySubject.next(data);
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getCategories,
        [],
        showCategories,
        errorCallback
      );
    });
  }

  getModifierCountByItemIds(itemIdList: string[]) {
    return new Promise((resolve) => {
      let itemIds = CommonFunctions.getCommaSepratedStringWithList(itemIdList);

      let sqlQuery = `select
              distinct ItemId,
                case
                  when modifierCount > 0 then 1
                  else 0
                end as modifiercount
              from
              (
                  select
                      mm.ItemId,
                      count(mm.ModifierId) as modifierCount
                  from
                      modifiermasters as mm
                  where
                      mm.IsActive = 'True' and
                      mm.ItemId in (${itemIds})
                  group by
                      mm.ItemId
                  union
                  select
                      distinct cmim.ItemId,
                      count(cmim.ModifierId) as modifierCount
                  from
                      commonmodifier_item_mappings as cmim
                      inner join  modifiermasters as mm on  mm.IsShowOnKIOSK ='True' and mm.modifierid = cmim.modifierid
                  where
                      mm.IsActive = 'True' and
                      cmim.IsActive = 'True'
                      and cmim.ItemId in (${itemIds})
                  group by
                      cmim.ItemId
              )
               where modifierCount > 0
      `;

      const logError = (statement: any, error: any) => {
        console.log('Item Get Error :- ' + error);

        resolve({} as any);
      };

      const setItems = (transaction: String, results: any) => {
        var data: any[] = Array.from(results.rows);
        if (data.length > 0) {
          data = Object.assign(
            {},
            ...data.map((x) => ({ [x.ItemId]: x.modifiercount }))
          );
        }
        resolve(Object.keys(data).length > 0 ? data : {});
      };

      DatabaseHandler.executeSqlStatement(sqlQuery, [], setItems, logError);
    });
  }

  getItemsByCategoryIdFromSql(
    categoryID: string = '0',
    isConcessionaire: boolean = false,
    concessaionaireId: string = '',
    isSingleItem: boolean = false,
    itemId: string = '',
    offSet: number = 0,
    barcodeCheck: number = 0,
    scanitemsearch: string = '',
    itemTagFilters: string = '',
    IsHideBarcodeItems: boolean = false
  ) {
    return new Promise<ItemV2[]>((resolve: any, reject: any) => {
      let currentTime = CommonFunctions.getCurrentTime();
      let dayId = new Date().getDay();
      if (dayId == 0) {
        dayId = 7;
      }

      let currentDate = CommonFunctions.getCurrentformatedtDate(new Date());
      let priceQuery = '';
      if (
        GeneralSetting.getMenuLevelID() &&
        GeneralSetting.getMenuLevelID() != '0'
      ) {
        let menuLevelID = GeneralSetting.getMenuLevelID();
        priceQuery = `, CASE WHEN
        cast((select Price from OtherPrice_Item_Mappings as OIM where  OIM.OPMID = '${menuLevelID}' AND OIM.ItemID=im.ItemID limit 1) as int)  > 0
        THEN
        (select Price from OtherPrice_Item_Mappings as OIM where  OIM.OPMID = '${menuLevelID}' AND OIM.ItemID=im.ItemID limit 1)
        Else
        im.Price end as Price `;
      } else {
        priceQuery = `,im.price as Price `;
      }

      let categoryName = '';
      if (categoryID !== '0') {
        categoryName = `, cm.categoryid as CategoryID
      ,case when cm.displayname = '' then cm.name
           else cm.displayname
       end as CategoryName`;
      }

      let getItems =
        `select  distinct im.itemid as ItemID
                           ,case when im.displayname = '' then im.name
                                 else im.displayname
                             end as Name
                           ,case when im.displayname = '' then im.name
                                 else im.displayname
                             end as DisplayName
                           ,im.kotorkdsdisplayname as KOTDisplayName
                           ,im.OrderReviewDisplayName
                           ,im.fulldescription as FullDescription
                           ,im.isitemdetailscreenshowkiosk as IsItemDetailScreenShowKiosk
                           ,im.fulldescriptionlang as FullDescriptionLang
                           ,im.shortdescription as ShortDescription
                           ,im.shortdescriptionlang as ShortDescriptionLang
                           ,im.taxgroupid as TaxGroupId
                           ` +
        priceQuery +
        `
                           ,im.isactive as IsActive
                           ,im.IsItem86 as IsItem86
                           ,im.WeightUnits as WeightUnitID
                           ,im.EnablePricebyWeight as EnablePricebyWeight
                           ,cast(im.displayorder as int) as DisplayOrder
                           ,im.calorie as Calorie
                           ,im.MaxCalories as MaxCalories
                           ,im.itemcategorysalestypeid as ItemCategorySalesTypeID
                           ,(
                             select  imageurl
                               from  itemimages
                              where  itemimages.itemid == im.itemid
                              limit  1
                             ) as ImageUrl` +
        categoryName +
        `,im.isbuildermode as IsBuilderMode
                           ,im.isboxbuildermode as IsBoxBuilderMode
                           ,im.IsAskForName
                           ,im.RefItemId
                           ,im.orderminimumquantity as OrderMinimumQuantity
                           ,im.ordermaximumquantity as OrderMaximumQuantity
                           ,mm.modifiercount as ModifierCount
                           ,ia.attributecount as AttributeCount
                           ,im.IsCombo
                           ,im.AskForTentNumber
                           ,im.lowstockactivity as LowStockActivity
                           ,ris.currentstock as CurrentStock
                           ,ris.lowthreshold as LowThreshold
                           ,(SELECT GROUP_CONCAT(DISTINCT(FM.IconImage)) FROM Items AS I
                              JOIN FilterMasters AS FM
                                ON FM.FilterID = IFM.FilterID
                                AND I.IsDeleted = 'False'
                              JOIN Item_Filter_Mappings as IFM
                                ON IFM.ItemID = I.ItemID
                                WHERE I.IsActive = 'True' AND IM.ItemID = I.ItemID
                                GROUP BY I.ItemID


                                ) as ItemTags
                                ,im.ReceiptItemGroupID as ReceiptItemGroupID

                       `;

      if (isConcessionaire) {
        getItems += ` from Concessionaire_Item_Mappings as cim
                      inner join item_category_mappings as cmm on cmm.itemid = cim.itemid `;
        if (categoryID != '0')
          getItems += `and cmm.categoryId = '${categoryID}'`;

        if (itemTagFilters != '') {
          var filterLength = itemTagFilters.split(',').length;
          getItems += ` JOIN (Select count(1) as countitem,IFMG.FilterID as FilterID,
                I.itemid as ItemID From Items as I
                JOIN Item_Filter_Mappings as IFM ON IFM.ItemID = I.itemid
                JOIN FilterMasters AS FM ON FM.FilterID = IFM.FilterID AND FM.IsActive = 'True'
                JOIN Filter_ItemTagGroup_Mappings as IFMG ON IFM.FilterID = IFMG.FilterID
                JOIN item_category_mappings as cmm on cmm.itemid = I.itemid where IFM.FilterID IN (${itemTagFilters})
                group by I.itemid  Having countitem >= ${filterLength}) as sim on sim.ItemID = cim.ItemID `;
        }
      } else {
        getItems += `from item_category_mappings as cmm `;
      }

      let categoryInnerJoin = '';
      if (categoryID !== '0') {
        categoryInnerJoin = `inner join categorymasters as cm on cm.categoryid = cmm.categoryid and  cm.isdeleted = 'False' and  cm.isactive = 'True' and cm.IsShowOnKiosk = 'True'`;
      }

      getItems +=
        `
                    inner join items as im on
                          im.itemId = cmm.itemId
                          and im.isactive = 'True'
                          and im.isshowonkiosk = 'True'
                          and im.iscomboitemonly = 'False'
                          and (IM.StartDate <= '${currentDate}' OR IM.StartDate = '')
                          AND(IM.EndDate >= '${currentDate}' OR IM.EndDate = '')` +
        categoryInnerJoin;

      if (categoryID != '0')
        getItems += ` and  cmm.categoryid = '${categoryID}' `;

      getItems += ` inner JOIN ItemDayWiseMasters IDWM ON IDWM.ItemID = IM.ItemID and IDWM.DayID = '${dayId}' and IDWM.StartTime <= time('${CommonFunctions.getCurrentTime()}')
                      AND IDWM.EndTime >= time('${CommonFunctions.getCurrentTime()}')
                      AND IDWM.IsActive = 'True'
                      left  join  readyitemstock as ris
                        on  im.itemid = ris.itemid
                      left  join (select '0' as itemid, '0' as modifiercount
                              ) as mm on  mm.itemid = '0'
                      left  join  (
                             select  case when ia.itemid = '0' then caim.itemid
                                          else ia.itemid
                                      end as itemid
                                    ,count(ia.itemid) as attributecount
                               from  itemattributes as ia
                               left  join  commonattribute_item_mappings as caim
                                 on  caim.itemattributeid = ia.itemattributeid
                              group  by  ia.itemid
                                    ,caim.itemid
                             ) as ia
                        on  ia.itemid = im.itemid

                     where `;

      if (isConcessionaire) {
        getItems += ` cim.ConcessionaireID = '${concessaionaireId}' and `;
      }

      if (categoryID != '0')
        getItems += `cmm.categoryId = '${categoryID}' and `;

      getItems += ` im.isdeleted = 'False'`;

      if (isSingleItem) {
        getItems += ` And im.itemid = '${itemId}' `;
      } else {
        if (
          GeneralSetting.getUseBarcodeScanner() === 'True' &&
          (IsHideBarcodeItems || barcodeCheck == 2 || barcodeCheck == 3)
        ) {
          var attributeSkuQuery = `select COUNT(*) from ItemAttributes as IA inner join ItemAttributeOptions as IAO On
          IA.ItemAttributeID = IAO.ItemAttributeID WHERE IA.ItemID = im.itemID`;

          if (barcodeCheck == 1) {
            getItems += ` And (im.ItemBarcodeID != '' OR (im.ItemBarcodeID = '' AND (${attributeSkuQuery} AND IAO.SKU != '')> 0 ))`;
          } else if (barcodeCheck == 2) {
            let categoyName = '';
            if (categoryID != '0') {
              categoyName = `OR cm.Name LIKE '%${scanitemsearch}%'
              OR cm.displayname LIKE '%${scanitemsearch}%'`;
            }

            getItems +=
              ` And im.ItemBarcodeID LIKE '%${scanitemsearch}%' OR im.Name LIKE '%${scanitemsearch}%' OR im.displayname LIKE '%${scanitemsearch}%' OR im.NameLang
                        LIKE '%${scanitemsearch}%' OR im.ItemBarcodeID = '${scanitemsearch}'` +
              categoryName;
          } else if (barcodeCheck == 3) {
            getItems += ` And im.ItemBarcodeID = '${scanitemsearch}' `;
          } else {
            getItems += ` And im.ItemBarcodeID = '' AND (${attributeSkuQuery}) = (${attributeSkuQuery} AND IAO.SKU = '')`;
          }
        }
      }

      getItems += ` order  by  cast(cmm.displayorder as int) asc  limit ${offSet},50`; //changed im.displayorder to cmm.displayorder to get items based on displayorder

      //console.log('getItemsByCategoryIdFromSql :- ' + getItems);

      const logError = (statement: any, error: any) => {
        console.log('Item Get Error :- ' + error);

        resolve([] as ItemV2[]);
      };

      const setItems = async (transaction: String, results: any) => {
        var data: ItemV2[] = Array.from(results.rows);

        let itemIdList = Array.from(data.map((x) => x.ItemID));

        let modifierCount = (await this.getModifierCountByItemIds(
          itemIdList
        )) as any;

        data.map((x: any) => {
          x.IsCombo = !(x.IsCombo == '0');
          x.AllowQuantitySelection = 'True';
          if (x.IsItem86 == 'False' || CommonFunctions.isItemSoldOut(x)) {
            x.SoldOut = true;
          }

          x.IconImages = x.ItemTags?.toString().split(',');
          x.OrderMaximumQuantity = 99999;
          x.OrderMinimumQuantity = 0;
          x.Modifiers = [];
          x.Variations = [];
          x.OrderMaximumQuantity = 99999;
          x.OrderMinimumQuantity = 0;
          x.Quantity = '1';
          x.ReceiptItemGroupID = x.ReceiptItemGroupID
            ? x.ReceiptItemGroupID
            : '0';
          x.ModifierCount = modifierCount[x.ItemID];
        });
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(getItems, [], setItems, logError);
    });
  }

  getAllConcessionaireFromSql(
    confilter: string = '',
    itemTagFilters: string = ''
  ) {
    return new Promise<Concessionaire[]>((resolve: any, reject: any) => {
      let dayId = new Date().getDay();
      if (dayId == 0) {
        dayId = 7;
      }

      let currentTime = CommonFunctions.getCurrentTime();
      let getConcessionaire = '';

      if (GeneralSetting.getIsAssignSpecificConcessionaire()) {
        getConcessionaire = `select Distinct
                              cm.ConcessionaireID as ConcessionaireId,
                              cm.ConcessionaireID,
                              cm.Name,
                              cm.ColorCode,
                              cm.Logo,
                              cm.SidebarLogo,
                              cm.RefConcessionaireID,
                              cm.IsActive,
                              CAST(cm.orderpriority AS INT),
                              cdm.IsDefaultConcessionaire,
                              Count(cim.ItemID) as ItemCount,
                              case
                                  when (cm.IsActive = 'True' or  cm.IsActive = 'true') then (case when (
                                      cwdm.IsActive = 'true'
                                      or cwdm.IsActive == 'True'
                                  ) then 0
                                  else 1
                              end) else 1 end as IsClosed

                            from ConcessionaireDeviceMappings as cdm
                            inner join ConcessionaireMasters as cm on cm.ConcessionaireId = cdm.ConcessionaireId
                            left join Concessionaire_Item_Mappings as cim on cim.ConcessionaireID = cm.ConcessionaireID
                            left join ConcessionaireDayWiseMasters as cwdm on cwdm.ConcessionaireID = cm.ConcessionaireID
                            and cwdm.StartTime <= time('${currentTime}') and cwdm.EndTime >= time('${currentTime}')
                            and cwdm.DayID = '${dayId}'

                            where cdm.staffModuleId = '${GeneralSetting.getStaffModuleID()}'`;
        if (confilter != '') {
          getConcessionaire += ` and cm.ConcessionaireTypeID IN(${confilter})`;
        }
        getConcessionaire += ` group by cm.ConcessionaireID,cm.Name,cm.ColorCode,cm.Logo,cm.OrderPriority,cdm.IsDefaultConcessionaire
                            Having COUNT(cim.ItemID) > 0
                            order by CAST(cm.orderpriority AS INT) ASC`;
      } else {
        getConcessionaire = `select Distinct cm.ConcessionaireID as ConcessionaireId ,
                              cm.ConcessionaireID,
                              cm.Name,
                              cm.ColorCode,
                              cm.Logo,
                              cm.SidebarLogo,
                              cm.ConcessionaireTypeID,
                              cm.RefConcessionaireID,
                              cm.IsActive,
                              CAST(cm.orderpriority AS INT),
                              Count(cim.ItemID) as ItemCount,
                              case
                                  when (cm.IsActive = 'True' or  cm.IsActive = 'true') then (case when (
                                      cwdm.IsActive = 'true'
                                      or cwdm.IsActive == 'True'
                                  ) then 0
                                  else 1
                              end) else 1 end as IsClosed

                              from ConcessionaireMasters as cm
                              left join Concessionaire_Item_Mappings as cim on cim.ConcessionaireID = cm.ConcessionaireID

                              left join ConcessionaireDayWiseMasters as cwdm on cwdm.ConcessionaireID = cm.ConcessionaireID
                                and cwdm.StartTime <= time('${currentTime}') and cwdm.EndTime >= time('${currentTime}')
                                and cwdm.DayID = '${dayId}'
                                `;

        if (confilter != '' || itemTagFilters != '') {
          if (itemTagFilters != '') {
            var filterLength = itemTagFilters.split(',').length;
            getConcessionaire += ` JOIN (Select count(1) as countitem,IFMG.FilterID as FilterID,
                  I.itemid as ItemID From Items as I
                  JOIN Item_Filter_Mappings as IFM ON IFM.ItemID = I.itemid
                  JOIN FilterMasters AS FM ON FM.FilterID = IFM.FilterID AND FM.IsActive = 'True'
                  JOIN Filter_ItemTagGroup_Mappings as IFMG ON IFM.FilterID = IFMG.FilterID
                  JOIN item_category_mappings as cmm on cmm.itemid = I.itemid where IFM.FilterID IN (${itemTagFilters})
                  group by I.itemid  Having countitem >= ${filterLength}) as im on im.ItemID = cim.ItemID `;
          }

          if (confilter != '') {
            // getConcessionaire += ` where cm.ConcessionaireTypeID IN(${confilter}) `;
            var conLength = confilter.split(',').length;

            getConcessionaire += ` JOIN (Select count(1) as ConcessionaireCount,ConcessionaireID,
            ConcessionaireTypeID from Concessionaire_ConcessionaireType_Mappings
            where ConcessionaireTypeID IN(${confilter})
            group by ConcessionaireID  Having  ConcessionaireCount >= ${conLength}) as cctm on cctm.ConcessionaireID = cm.ConcessionaireID `;
          }

          getConcessionaire += ` group by cm.ConcessionaireID,cm.Name,cm.ColorCode,cm.Logo,cm.OrderPriority
          Having COUNT(cim.ItemID) > 0 order by CAST(cm.orderpriority AS INT) ASC`;
        } else {
          getConcessionaire += ` group by cm.ConcessionaireID,cm.Name,cm.ColorCode,cm.Logo,cm.OrderPriority
                            Having COUNT(cim.ItemID) > 0
                            order by
                                   case
                                      when (cm.IsActive = 'True' or  cm.IsActive = 'true') then (case when (
                                          cwdm.IsActive = 'true'
                                          or cwdm.IsActive == 'True'
                                      ) then 0
                                      else 1
                                    end) else 1 end,
                            CAST(cm.orderpriority AS INT) ASC`;
        }
      }

      const errorCallback = (statement: any, error: any) => {
        console.log('Concessionaire Get Error', error);

        this.concessionaireSubject.next([]);
        resolve([] as Concessionaire[]);
      };

      const showCategories = (tx: string, results: any) => {
        var data: Concessionaire[] = Array.from(results.rows);

        data.forEach((x: any) => {
          x.IsClosed = x.IsClosed == 0 ? false : true;
        });

        this.concessionaireSubject.next(data);
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getConcessionaire,
        [],
        showCategories,
        errorCallback
      );
    });
  }

  private getAllConcessionaireItemMappingByConId(conId: string) {
    let query =
      "select ItemId from concessionaire_Item_Mappings where ConcessionaireId = '" +
      conId +
      "'";

    return DatabaseHandler.getDataFromQuery<any[]>(query).toPromise();
  }

  getAllCategoryByConcessionaireId(
    concessionaireId: string,
    itemTagFilters: string = ''
  ) {
    return new Promise<Category[]>(async (resolve: any, reject: any) => {
      let dayId = new Date().getDay();
      if (dayId == 0) {
        dayId = 7;
      }

      let allItemIds: any[] = await this.getAllConcessionaireItemMappingByConId(
        concessionaireId
      );
      let itemIdsCommaSaprated = '';
      if (allItemIds.length > 0) {
        let itemIds = Array.from(new Set(Array.from(allItemIds))).map(
          (x) => x.ItemID
        );
        itemIdsCommaSaprated =
          CommonFunctions.getCommaSepratedStringWithList(itemIds);
      }

      let getConcessionaireCategory = ` select distinct
                      CM.CategoryID AS CategoryID
                      ,CM.Name
                      ,CM.DisplayName AS DisplayName
                      ,CM.Description AS Description
                      ,CM.ParentCategoryID AS ParentCategoryID
                      ,CM.IncludeInTopMenu AS IncludeInTopMenu
                      ,CAST(CM.DisplayOrder AS int) AS DisplayOrder
                      ,CM.ColorCode AS ColorCode
                      ,CM.HeaderImage AS HeaderImage
                      ,CM.CategoryIcon AS CategoryIcon
                      ,CM.DeselectCategoryIcon AS DeselectCategoryIcon
                      ,CM.DisplayCatItemRowCount
                      ,CM.HideHeaderText AS HideHeaderText
                      ,CM.BackgroundImage AS BackgroundImage
                      ,CM.IsAlkol AS IsAlkol
                      ,CM.DisplayCategory AS DisplayCategory
                      ,CI.ImageUrl AS ImageUrl
                  from Item_Category_Mappings as Icm
                inner join CategoryMasters as cm on cm.CategoryID = icm.CategoryId
                    And CM.IsDeleted = 'False'
                    AND CM.IsActive = 'True'
                    AND CM.DisplayCategory = 'True'
                    AND CM.IsShowOnKiosk = 'True'
                inner join CategoryDayWiseMasters CD ON CM.CategoryID = CD.CategoryID AND CD.DayID = '${dayId}'
                      AND CD.StartTime <= time('${CommonFunctions.getCurrentTime()}')
                      AND CD.EndTime >= time('${CommonFunctions.getCurrentTime()}')
                      AND CD.IsActive = 'True'
                LEFT JOIN CategoryImages as CI ON CI.CategoryID = CM.CategoryID `;

      if (itemTagFilters != '') {
        var filterLength = itemTagFilters.split(',').length;
        getConcessionaireCategory += ` JOIN (Select count(1) as countitem,IFMG.FilterID as FilterID,
                        I.itemid as ItemID From Items as I
                        JOIN Item_Filter_Mappings as IFM ON IFM.ItemID = I.itemid
                        JOIN FilterMasters AS FM ON FM.FilterID = IFM.FilterID AND FM.IsActive = 'True'
                        JOIN Filter_ItemTagGroup_Mappings as IFMG ON IFM.FilterID = IFMG.FilterID
                        JOIN item_category_mappings as cmm on cmm.itemid = I.itemid where IFM.FilterID IN (${itemTagFilters})
                        group by I.itemid  Having countitem >= ${filterLength}) as sim on sim.ItemID = cim.ItemID `;
      }

      getConcessionaireCategory += ` where icm.ItemId in (${itemIdsCommaSaprated})
                        ORDER BY CAST(CM.DisplayOrder AS INT) ASC`;

      const errorCallback = (statement: any, error: any) => {
        console.log('Concessionaire Category Get Error', error);

        this.category = [];
        this.categorySubject.next([]);
        resolve([] as Category[]);
      };

      const showCategories = (tx: string, results: any) => {
        var data: Category[] = [];

        for (var i = 0; i < results.rows.length; i++) {
          const cat = results.rows[i];

          const category = {
            CurrentStock: '',
            LowThreshold: '',
            SoldOut: false,
            IconImages: [] as string[],
            ItemTags: [] as string[],
            CategoryIcon: cat.CategoryIcon,
            DeselectCategoryIcon: cat.DeselectCategoryIcon,
            Name:
              !cat.DisplayName || cat.DisplayName == ''
                ? cat.Name
                : cat.DisplayName,
            ImageUrl: cat.ImageUrl,
            CategoryID: cat.CategoryID,
            items: [] as any[],
            isActive: true,
            associatedItems: [] as ItemV2[],
            ParentCategoryID: cat.ParentCategoryID,
            isSub: false,
            parent: {} as Category,
            HideHeaderText: cat.HideHeaderText,
            IncludeInTopMenu: cat.IncludeInTopMenu,
            DisplayOrder: cat.DisplayOrder,
            ColorCode: '',
            HeaderImage: cat.HeaderImage,
            DisplayCatItemRowCount: cat.DisplayCatItemRowCount,
            BackgroundImage: cat.BackgroundImage,
            isAlkol: cat.IsAlkol,
            DisplayName:
              !cat.DisplayName || cat.DisplayName == ''
                ? cat.Name
                : cat.DisplayName,
            DayIDs: [] as string[],
            EndTimes: [] as string[],
            StartTimes: [] as string[],
            TimeActives: [] as string[],
            DisplayCategory: cat.DisplayCategory,
          } as Category;

          if (cat.DisplayCategory) {
            category.DisplayCategory = cat.DisplayCategory;
          }

          cat.associatedItems = [];

          if (cat.isSub) {
            cat.isSub = false;
          }

          if (category) {
            data.push(category);
          }
        }

        this.category = data;
        this.categorySubject.next(data);
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getConcessionaireCategory,
        [],
        showCategories,
        errorCallback
      );
    });
  }

  getChildModifierByItemAndModifierID(itemId: string, modifierId: string) {
    return new Promise<ModifierV2[]>((resolve: any, reject: any) => {
      const getItems = `
      SELECT
        MM.ModifierID AS ModifierID
        ,MM.IsModifier86 as IsModifier86
        ,MM.IsForced AS IsForced
        ,MM.CountOption AS CountOption
        ,MM.MinNoOfSelection AS MinNoOfSelection
        ,Case when MM.DisplayName = '' then MM.Category else MM.DisplayName end AS Category
        ,MM.Price AS Price
        ,CAST(MM.DisplayOrder AS INT) AS DisplayOrder
        ,MM.DisplayName AS DisplayName
        ,MM.Description AS Description
        ,MM.RefModifierID
        ,MM.ParentID
        ,MM.ItemID
        ,'0' as ParentModifierID
        ,MM.KOTDisplayOrder as KOTDisplayOrder
        ,MM.KOTorKDSDisplayName as KOTDisplayName
        ,MM.SelectedImage as ImageUrl
        ,MM.DeselectedImage as DeselectImageUrl
        ,MM.IsSubGroup as IsSubGroup
        ,MM.isOloOptionGroup as isOloOptionGroup
        ,MM.MinAggregateQty as MinAggregateQty
        ,MM.MaxAggregateQty as MaxAggregateQty
        ,MM.MinChoiceQty as MinChoiceQty
        ,MM.MaxChoiceQty as MaxChoiceQty
        ,MM.ChoiceQtyIncrement as ChoiceQtyIncrement
        ,MM.UseAdvancedModifierQuantitySettings as UseAdvancedModifierQuantitySettings
      FROM
          ModifierMasters as MM
          LEFT JOIN Ingredients as I ON MM.IngredientID = I.IngredientID
      WHERE
          mm.ParentID = '${modifierId}'
          AND MM.IsActive = 'True'
          AND MM.IsShowOnKIOSK = 'True'
      UNION

      SELECT DISTINCT
          MM.ModifierID AS ModifierID
          ,MM.IsModifier86 as IsModifier86
          ,MM.IsForced AS IsForced
          ,MM.CountOption AS CountOption
          ,MM.MinNoOfSelection AS MinNoOfSelection
          ,Case when MM.DisplayName = '' then MM.Category else MM.DisplayName end AS Category
          ,MM.Price AS Price
          ,CAST(MM.DisplayOrder AS INT) AS DisplayOrder
          ,MM.DisplayName AS DisplayName
          ,MM.Description AS Description
          ,MM.RefModifierID
          ,case when MM.ParentID = '0' then cmim.ParentModifierID else mm.ParentID end as ParentID
          ,case when MM.ItemID = '0' then cmim.ItemID else MM.ItemId end AS ItemID
          ,CMIM.ParentModifierID
          ,CMIM.KOTDisplayOrder as KOTDisplayOrder
          ,MM.KOTorKDSDisplayName as KOTDisplayName
          ,MM.SelectedImage as ImageUrl
          ,MM.DeselectedImage as DeselectImageUrl
          ,MM.IsSubGroup as IsSubGroup
          ,MM.isOloOptionGroup as isOloOptionGroup
          ,MM.MinAggregateQty as MinAggregateQty
          ,MM.MaxAggregateQty as MaxAggregateQty
          ,MM.MinChoiceQty as MinChoiceQty
          ,MM.MaxChoiceQty as MaxChoiceQty
          ,MM.ChoiceQtyIncrement as ChoiceQtyIncrement
          ,MM.UseAdvancedModifierQuantitySettings as UseAdvancedModifierQuantitySettings

          from
            commonmodifier_item_mappings as cmim
            inner join modifiermasters as mm on mm.isactive = 'True' and mm.ParentID = '0'
            and mm.IsShowOnKIOSK = 'True'
            and mm.modifierid = cmim.modifierid
            where
            cmim.ParentModifierID = '${modifierId}' and cmim.itemid = '${itemId}'
            and cmim.IsActive = 'True'
      `;

      const logError = (statement: any, error: any) => {
        console.log('getChildModifierByItemAndModifierID :- ', error);

        resolve([] as ModifierV2[]);
        //console.log('modifier error', error);
      };

      const setItems = async (transaction: String, results: any) => {
        var data: ModifierV2[] = [];

        for (var i = 0; i < results.rows.length; i++) {
          let md = results.rows[i];

          let modifier = new ModifierV2();
          modifier.ModifierID = md.ModifierID;
          modifier.ItemID = itemId;
          modifier.IsModifier86 = md.IsModifier86;
          modifier.IsForced = md.IsForced;
          modifier.CountOption = md.CountOption;
          modifier.MinNoOfSelection = md.MinNoOfSelection;
          modifier.Category = md.Category;
          if (md.DisplayName != '') {
            modifier.Category = md.DisplayName;
          }
          modifier.Price = md.Price;
          modifier.DisplayOrder = md.DisplayOrder;
          modifier.DisplayName = md.DisplayName;
          modifier.Description = md.Description;
          modifier.IngredientID = '0';
          modifier.ParentID = md.ParentID;
          modifier.RefModifierId = md.RefModifierID;
          modifier.SelectedImage = md.ImageUrl;
          modifier.DeselectedImage = md.DeselectImageUrl;
          modifier.ImageUrl = md.ImageUrl;
          modifier.count = 0;
          modifier.Ingredients = [];
          modifier.max = Number.MAX_SAFE_INTEGER;
          modifier.isValid = false;
          modifier.min = Number.MIN_SAFE_INTEGER;
          modifier.rule = '';
          modifier.IsSelected = false;
          modifier.ParentModifierID = md.ParentModifierID;
          modifier.KOTDisplayOrder = md.KOTDisplayOrder;
          modifier.KOTDisplayName = md.KOTDisplayName;
          modifier.IsSubGroup =
            md.IsSubGroup && md.IsSubGroup.toLowerCase() == 'true';
          modifier.isOloOptionGroup = md.isOloOptionGroup;

          let max = Number(modifier.CountOption);
          let min = Number(modifier.MinNoOfSelection);
          if (min > 0 || max > 0) {
            if (min) {
              // has min
              if (max > 0) {
                // has min and max
                if (min == max) {
                  modifier.rule = `Choose exactly ${modifier.MinNoOfSelection}`; // min and max are the same
                  modifier.max = max;
                  modifier.min = min;
                } else {
                  modifier.rule = `Choose between ${modifier.MinNoOfSelection} and ${modifier.CountOption}`; // min and max are different
                  modifier.max = max;
                  modifier.min = min;
                }
              } else {
                modifier.rule = `Choose at least ${modifier.MinNoOfSelection}`; // only has min
                modifier.min = min;
              }
            } else if (max > 0) {
              // has max
              if (min == 1 && max == 1) {
                modifier.rule = `Choose exactly ${modifier.CountOption}`;
                modifier.max = max;
                modifier.min = 1;
              } else if (max == 1) {
                modifier.max = max;
                modifier.min = min;
                modifier.rule = `Choose Any ${modifier.CountOption}`;
              } else if (max > 1) {
                modifier.max = max;
                modifier.min = min;
                modifier.rule = `Choose Up To ${modifier.CountOption}`;
              }
            }
          } else {
            modifier.min = 0;
            modifier.max = 0;
          }

          if (modifier.IsSubGroup) {
            modifier.Ingredients =
              await this.getNestedModifierByItemAndModifier(
                itemId,
                modifier.ModifierID
              );
          }
          //Min max AggregateQty
          modifier.MaxAggregateQty = md.MaxAggregateQty;
          modifier.MinAggregateQty = md.MinAggregateQty;
          modifier.MaxChoiceQty = md.MaxChoiceQty;
          modifier.MinChoiceQty = md.MinChoiceQty;
          modifier.ChoiceQtyIncrement = md.ChoiceQtyIncrement;
          modifier.UseAdvancedModifierQuantitySettings =
            md.UseAdvancedModifierQuantitySettings;
          if (Number(modifier.MaxAggregateQty) > 1) {
            modifier.ruleAggregate =
              'Select total of ' + modifier.MaxAggregateQty;
          } else {
            modifier.ruleAggregate = modifier.rule;
          }
          data.push(modifier);
        }
        // //console.log('modifier data', data);
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(getItems, [], setItems, logError);
    });
  }

  public async getNestedModifierByItemAndModifier(
    itemId: string,
    modifierId: string
  ) {
    let parentModifierIngredient = [] as (ModifierIngredientV2 | ModifierV2)[];

    let nestedModifiers = await this.getChildModifierByItemAndModifierID(
      itemId,
      modifierId
    );

    let Ingredient = await this.getIngredientsByModIDFromSql(modifierId);

    parentModifierIngredient.push(...nestedModifiers);
    parentModifierIngredient.push(...Ingredient);

    parentModifierIngredient = parentModifierIngredient.sort((a: any, b: any) =>
      Number(a.DisplayOrder) > Number(b.DisplayOrder) ? 1 : -1
    );

    return parentModifierIngredient;
  }

  getAllComboGroupsFromSQl(comboId: string) {
    return new Promise<ComboGroupV2[]>((resolve: any) => {
      var comboGroupQuery = `select
                              cgm.ComboGroupID,
                              GroupName,
                              CountOption,
                              GroupNameLang,
                              MinNoOfSelection,
                              DisplayOrder,
	                          case when cgim.IsInvisibleCount > 0 then 1 else 0 end as HasInvisibleItem
                            from
                            ComboGroupMasters as cgm
		                    left join (select cgim.ComboGroupID, Count(cgim.IsInvisibleItem) as IsInvisibleCount from ComboGroupItemMasters as cgim
								 inner join items as im on im.ItemId = cgim.ItemId And im.isdeleted = 'False'
									  and im.isactive = 'True'
									  and im.isshowonkiosk = 'True'
								where cgim.IsInvisibleItem = 'True' and cgim.ComboID = '${comboId}'
								group by cgim.ComboGroupID
							) as cgim on cgim.ComboGroupID = cgm.ComboGroupID and cgim.IsInvisibleCount > 0
                            where
                            cgm.ComboGroupID
                            in (select distinct ComboGroupID from ComboGroupItemMasters where ComboID = '${comboId}')
                            ORDER BY CAST(DisplayOrder AS INT);`;

      const errorCallback = (statement: any, error: any) => {
        // console.log('Database Serviec , Error getAllComboGroupsFromSQl :- ' + error);
        resolve([]);
      };

      const setComboGroup = (tx: string, results: any) => {
        // var data: ComboGroup[] = Array.from(results.rows);
        var data: ComboGroupV2[] = [];
        for (var i = 0; i < results.rows.length; i++) {
          let cg = results.rows[i];

          var comboGroup = {
            ComboGroupID: cg.ComboGroupID,
            GroupName: cg.GroupName,
            CountOption: cg.CountOption,
            GroupNameLang: cg.GroupNameLang,
            MinNoOfSelection: cg.MinNoOfSelection,
            DisplayOrder: cg.DisplayOrder,
            isSelected: false,
            count: 0,
            height: 0,
            IsForced: cg.MinNoOfSelection == '0' ? 'False' : 'True',
            max: Number.MAX_SAFE_INTEGER,
            isValid: cg.MinNoOfSelection == '0',
            min: Number.MIN_SAFE_INTEGER,
            selectedIngrediants: [],
            ingrediants: [],
            associatedOptions: [],
            associatedVariations: [],
            rule: '',
            isReady: false,
            Items: [] as ItemV2[],
            Variations: [] as Variation[],
            IsSelected: false,
            isHidden: false,
            HasInvisibleItem: cg.HasInvisibleItem == 1,
          } as ComboGroupV2;

          let max = Number(cg.CountOption);
          let min = Number(cg.MinNoOfSelection);

          let ruleDetail = this.getMinMaxRule(
            min,
            max,
            cg.MinNoOfSelection,
            cg.CountOption
          );
          comboGroup.min = Number(ruleDetail[0]);
          comboGroup.max = Number(ruleDetail[1]);
          comboGroup.rule = ruleDetail[2].toString();

          data.push(comboGroup);
        }
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        comboGroupQuery,
        [],
        setComboGroup,
        errorCallback
      );
    });
  }

  getComboItemsFromSql(comboGroupID: string, productId: string) {
    return new Promise<ItemV2[]>((resolve: any) => {
      let currentTime = CommonFunctions.getCurrentTime();
      let dayId = new Date().getDay();
      if (dayId == 0) {
        dayId = 7;
      }

      let currentDate = CommonFunctions.getCurrentformatedtDate(new Date());
      let priceQuery = '';
      if (
        GeneralSetting.getMenuLevelID() &&
        GeneralSetting.getMenuLevelID() != '0'
      ) {
        let menuLevelID = GeneralSetting.getMenuLevelID();
        priceQuery = `CASE WHEN
        (select count(Price) from OtherPrice_Item_Mappings as OIM where  OIM.OPMID = '${menuLevelID}' AND OIM.ItemID=im.ItemID)  > 0
        THEN
        (select Price from OtherPrice_Item_Mappings as OIM where  OIM.OPMID = '${menuLevelID}' AND OIM.ItemID=im.ItemID)
        Else
        im.Price end as Price ,`;
      } else {
        priceQuery = `im.price as Price ,`;
      }

      var itemsByComboGroupQuery =
        `
            select
              distinct im.itemid as ItemID,
              case
                when im.displayname = '' then im.name
                else im.displayname
              end as Name,
              case
                when im.displayname = '' then im.name
                else im.displayname
              end as DisplayName,
              im.kotorkdsdisplayname as KOTDisplayName,
              im.fulldescription as FullDescription,
              im.isitemdetailscreenshowkiosk as IsItemDetailScreenShowKiosk,
              im.fulldescriptionlang as FullDescriptionLang,
              im.shortdescription as ShortDescription,
              im.shortdescriptionlang as ShortDescriptionLang,
              im.taxgroupid as TaxGroupId,
              ` +
        priceQuery +
        `
              im.isactive as IsActive,
              cast(cgim.Displayorder as int) as DisplayOrder,
              im.calorie as Calorie,
              im.MaxCalories as MaxCalories,
              im.itemcategorysalestypeid as ItemCategorySalesTypeID,
              (
                select
                  imageurl
                from
                  itemimages
                where
                  itemimages.itemid == im.itemid
                limit
                  1
              ) as ImageUrl,
              im.isbuildermode as IsBuilderMode,
              im.isboxbuildermode as IsBoxBuilderMode,
              im.IsAskForName,
              im.RefItemId,
              im.orderminimumquantity as OrderMinimumQuantity,
              im.ordermaximumquantity as OrderMaximumQuantity,
              mm.modifiercount as ModifierCount,
              ia.attributecount as AttributeCount,
              im.IsCombo,
              im.lowstockactivity as LowStockActivity,
              ris.currentstock as CurrentStock,
              ris.lowthreshold as LowThreshold,
               (
                SELECT
                  GROUP_CONCAT(DISTINCT(FM.IconImage))
                FROM
                  Items AS I
                  JOIN FilterMasters AS FM ON FM.FilterID = IFM.FilterID
                  AND I.IsDeleted = 'False'
                  JOIN Item_Filter_Mappings as IFM ON IFM.ItemID = I.ItemID
                WHERE
                  I.IsActive = 'True'
                  AND IM.ItemID = I.ItemID
                GROUP BY
                  I.ItemID
              ) as ItemTags,
              cgim.ComboGroupID,
              cgim.IsDefault,
              cgim.ExtraPrice,
              cgim.RefComboGroupItemID,
              cgim.IsInvisibleItem
            from ComboGroupItemMasters as cgim
              inner join items as im on im.ItemId = cgim.ItemId
              JOIN ItemDayWiseMasters IDWM ON IDWM.ItemID = IM.ItemID and IDWM.DayID = '${dayId}'
              and IDWM.StartTime <= time('${currentTime}')
              AND IDWM.EndTime >= time('${currentTime}')
              AND IDWM.IsActive ='True'
              left join readyitemstock as ris on im.itemid = ris.itemid

              left  join (select distinct itemId,
                case
                    when modifierCount > 0 then 1
                    else 0
                end as modifiercount
            from (
                    select case when mm.ItemId = '0' or mm.ItemId = '' then cmim.Itemid else mm.ItemId end as ItemId,
                    count(case when mm.ItemId = '0' or mm.ItemId = '' then cmim.Itemid else mm.ItemId end) as modifiercount
                    from modifiermasters as mm
                    left join commonmodifier_item_mappings as cmim on cmim.ModifierId = mm.ModifierId AND cmim.IsActive='True'
                    where mm.IsActive='True'
                    group by mm.ItemId, cmim.ItemId
                )
            where modifiercount > 0
            ) as mm on  mm.itemid = im.itemid

              left join (
                select
                  case
                    when ia.itemid = '0' then caim.itemid
                    else ia.itemid
                  end as itemid,
                  count(ia.itemid) as attributecount
                from
                  itemattributes as ia
                  left join commonattribute_item_mappings as caim on caim.itemattributeid = ia.itemattributeid
                group by
                  ia.itemid,
                  caim.itemid
              ) as ia on ia.itemid = im.itemid
              Inner join CategoryMasters CM on CM.CategoryID = cgim.CategoryID
                    And CM.IsDeleted = 'False'
              Inner join CategoryDayWiseMasters CD ON CM.CategoryID = CD.CategoryID AND CD.DayID = '${dayId}'
                    AND CD.StartTime <= time('${currentTime}')
                    AND CD.EndTime >= time('${currentTime}')
                    AND CD.IsActive = 'True'
            where
              cgim.ComboID = '${productId}'
              AND cgim.ComboGroupID = '${comboGroupID}'
              And im.isdeleted = 'False'
              and im.isactive = 'True'
              and im.isshowonkiosk = 'True'

            order by
              CAST(Cgim.DisplayOrder AS INT);`;

      //
      // Inner join CategoryMasters CM on CM.CategoryID = cgim.CategoryID
      // And CM.IsDeleted = 'False'
      // AND CM.IsActive = 'True'
      // AND CM.DisplayCategory = 'True'
      // AND CM.IsShowOnKiosk = 'True'

      const errorCallback = (statement: any, error: any) => {
        console.log(
          `Combo Group ItemGet Error :- GroupId : ${comboGroupID} : ComboId : ${productId}`
        );

        resolve([] as ItemV2[]);
      };

      const setComboGroupItems = async (tx: string, results: any) => {
        var data: ItemV2[] = Array.from(results.rows);

        for (let i = 0; i < data.length; i++) {
          let x = data[i];
          x.Price = x.ExtraPrice;
          x.IsCombo = !(results.rows[i].IsCombo == '0');
          x.SoldOut = CommonFunctions.isItemSoldOut(x);
          x.isSelected = x.IsDefault == 'True' && !x.SoldOut;
          x.IconImages = results.rows[i].ItemTags?.toString().split(',');
          x.OrderMaximumQuantity = 99999;
          x.OrderMinimumQuantity = 0;
          if (x.isSelected) {
            x.Modifiers = await this.getAndSetModifierForComboDefaultItem(
              x.ItemID
            );
            x.Variations = await this.getPersistentVariationsAsync(x.ItemID);
          }
        }

        data.map((x: any) => {});
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        itemsByComboGroupQuery,
        [],
        setComboGroupItems,
        errorCallback
      );
    });
  }

  private async getAndSetModifierForComboDefaultItem(itemId: string) {
    let allModifier = await this.getModifierByItemIDFromSql(itemId);

    const parentModifiers = allModifier.filter(
      (x: any) => x.ParentID == '' || x.ParentID == '0'
    );

    const childModifiers = allModifier.filter(
      (x: any) => x.ParentID != '' && x.ParentID != '0'
    );

    for (
      let parentModifierIndex = 0;
      parentModifierIndex < parentModifiers.length;
      parentModifierIndex++
    ) {
      // let getModifierIngPer = performance.now();

      // console.log("get Modifier Ingridient by ModifierId Performance :- " + getModifierIngPer);

      const modifier = parentModifiers[parentModifierIndex];

      modifier.IsForced =
        (modifier.IsModifier86 == 'False' && 'False') || modifier.IsForced;
      modifier.isValid =
        (modifier.IsModifier86 == 'False' && true) || modifier.isValid;

      const ingredients = await this.getIngredientsByModIDFromSql(
        modifier.ModifierID
      );
      modifier.Ingredients = ingredients;

      let nestedModifiers = await this.getChildModifierByItemAndModifierID(
        itemId,
        modifier.ModifierID
      );

      modifier.Ingredients.push(...nestedModifiers);

      modifier.Ingredients = modifier.Ingredients.sort((a: any, b: any) =>
        Number(a.DisplayOrder) > Number(b.DisplayOrder) ? 1 : -1
      );

      const childModifer = childModifiers.filter(
        (x) => x.ParentID == modifier.ModifierID
      );

      // console.log("get Modifier Ingridient by ModifierId Performance End :- " + ((getModifierIngPer - performance.now()) / 1000));

      modifier.Ingredients.push(...childModifer);
    }

    return parentModifiers;
  }

  getModifierByItemIDFromSql(id: string) {
    return new Promise<ModifierV2[]>((resolve: any, reject: any) => {
      const getItems = `
      SELECT
      MM.ModifierID AS ModifierID
      ,MM.IsModifier86 as IsModifier86
      ,MM.IsForced AS IsForced
      ,MM.CountOption AS CountOption
      ,MM.MinNoOfSelection AS MinNoOfSelection
      ,Case when MM.DisplayName = '' then MM.Category else MM.DisplayName end AS Category
      ,MM.Price AS Price
      ,CAST(MM.DisplayOrder AS INT) AS DisplayOrder
      ,MM.DisplayName AS DisplayName
      ,MM.Description AS Description
      ,MM.RefModifierID
      ,MM.ParentID
      ,MM.ItemID
      ,'0' as ParentModifierID
      ,MM.KOTDisplayOrder as KOTDisplayOrder
      ,MM.KOTorKDSDisplayName as KOTDisplayName
      ,MM.SelectedImage as ImageUrl
      ,MM.DeselectedImage as DeselectImageUrl
      ,MM.IsSubGroup as IsSubGroup
      ,MM.isOloOptionGroup as isOloOptionGroup
      ,MM.IsStartinCollapsedMode as IsStartinCollapsedMode
      ,MM.MinAggregateQty as MinAggregateQty
      ,MM.MaxAggregateQty as MaxAggregateQty
      ,MM.MinChoiceQty as MinChoiceQty
      ,MM.MaxChoiceQty as MaxChoiceQty
      ,MM.ChoiceQtyIncrement as ChoiceQtyIncrement
      ,MM.UseAdvancedModifierQuantitySettings as UseAdvancedModifierQuantitySettings
      FROM
        ModifierMasters as MM
      LEFT JOIN Ingredients as I
        ON MM.IngredientID = I.IngredientID
      WHERE
        MM.ItemID = '${id}'
          AND MM.IsActive = 'True'
          AND MM.IsShowOnKIOSK = 'True'

      UNION
      SELECT
        MM.ModifierID AS ModifierID
        ,MM.IsModifier86 as IsModifier86
        ,MM.IsForced AS IsForced
        ,MM.CountOption AS CountOption
        ,MM.MinNoOfSelection AS MinNoOfSelection
        ,Case when MM.DisplayName = '' then MM.Category else MM.DisplayName end AS Category
        ,MM.Price AS Price
        ,CAST((Case when cmim.DisplayOrder = '0' then MM.DisplayOrder else cmim.DisplayOrder end) AS INT) AS DisplayOrder
        ,MM.DisplayName AS DisplayName
        ,MM.Description AS Description
        ,MM.RefModifierID
        ,case when MM.ParentID = '0' then cmim.ParentModifierID else mm.ParentID end as ParentID
        ,case when MM.ItemID = '0' then cmim.ItemID else MM.ItemId end AS ItemID
        ,CMIM.ParentModifierID
        ,CMIM.KOTDisplayOrder as KOTDisplayOrder
        ,MM.KOTorKDSDisplayName as KOTDisplayName
        ,MM.SelectedImage as ImageUrl
        ,MM.DeselectedImage as DeselectImageUrl
        ,MM.IsSubGroup as IsSubGroup
        ,MM.isOloOptionGroup as isOloOptionGroup
        ,MM.IsStartinCollapsedMode as IsStartinCollapsedMode
        ,MM.MinAggregateQty as MinAggregateQty
        ,MM.MaxAggregateQty as MaxAggregateQty
        ,MM.MinChoiceQty as MinChoiceQty
        ,MM.MaxChoiceQty as MaxChoiceQty
        ,MM.ChoiceQtyIncrement as ChoiceQtyIncrement
        ,MM.UseAdvancedModifierQuantitySettings as UseAdvancedModifierQuantitySettings
        from commonmodifier_item_mappings as cmim
        inner join  modifiermasters as mm on  mm.isactive = 'True' and mm.IsShowOnKIOSK ='True' and mm.modifierid = cmim.modifierid
        and mm.ParentID = '0'
        where cmim.itemid = '${id}' and CMIM.ParentModifierID = '0' and cmim.IsActive='True'
        ORDER BY DisplayOrder
      `;

      const logError = (statement: any, error: any) => {
        console.log('getModifierByItemIDFromSql :- ', error);

        resolve([] as ModifierV2[]);
        //console.log('modifier error', error);
      };

      const setItems = (transaction: String, results: any) => {
        var data: ModifierV2[] = [];

        for (var i = 0; i < results.rows.length; i++) {
          let md = results.rows[i];

          let modifier = new ModifierV2();
          modifier.ModifierID = md.ModifierID;
          modifier.ItemID = id;
          modifier.IsModifier86 = md.IsModifier86;
          modifier.IsForced = md.IsForced;
          modifier.CountOption = md.CountOption;
          modifier.MinNoOfSelection = md.MinNoOfSelection;
          modifier.Category = md.Category;
          if (md.DisplayName != '') {
            modifier.Category = md.DisplayName;
          }
          modifier.Price = md.Price;
          modifier.DisplayOrder = md.DisplayOrder;
          modifier.DisplayName = md.DisplayName;
          modifier.Description = md.Description;
          modifier.IngredientID = '0';
          modifier.ParentID = md.ParentID;
          modifier.RefModifierId = md.RefModifierID;
          modifier.SelectedImage = md.ImageUrl;
          modifier.DeselectedImage = md.DeselectImageUrl;
          modifier.ImageUrl = md.ImageUrl;
          modifier.count = 0;
          modifier.Ingredients = [];
          modifier.max = Number.MAX_SAFE_INTEGER;
          modifier.isValid = false;
          modifier.min = Number.MIN_SAFE_INTEGER;
          modifier.rule = '';
          modifier.IsSelected = false;
          modifier.ParentModifierID = md.ParentModifierID;
          modifier.KOTDisplayOrder = md.KOTDisplayOrder;
          modifier.KOTDisplayName = md.KOTDisplayName;
          modifier.IsSubGroup =
            md.IsSubGroup && md.IsSubGroup.toLowerCase() == 'true';
          modifier.isOloOptionGroup = md.isOloOptionGroup;
          modifier.IsStartinCollapsedMode = md.IsStartinCollapsedMode;
          let max = Number(modifier.CountOption);
          let min = Number(modifier.MinNoOfSelection);
          if (min > 0 || max > 0) {
            if (min) {
              // has min
              if (max > 0) {
                // has min and max
                if (min == max) {
                  modifier.rule = `Choose exactly ${modifier.MinNoOfSelection}`; // min and max are the same
                  modifier.max = max;
                  modifier.min = min;
                } else {
                  modifier.rule = `Choose between ${modifier.MinNoOfSelection} and ${modifier.CountOption}`; // min and max are different
                  modifier.max = max;
                  modifier.min = min;
                }
              } else {
                modifier.rule = `Choose at least ${modifier.MinNoOfSelection}`; // only has min
                modifier.min = min;
              }
            } else if (max > 0) {
              // has max
              if (min == 1 && max == 1) {
                modifier.rule = `Choose exactly ${modifier.CountOption}`;
                modifier.max = max;
                modifier.min = 1;
              } else if (max == 1) {
                modifier.max = max;
                modifier.min = min;
                modifier.rule = `Choose Any ${modifier.CountOption}`;
              } else if (max > 1) {
                modifier.max = max;
                modifier.min = min;
                modifier.rule = `Choose Up To ${modifier.CountOption}`;
              }
            }
          } else {
            modifier.min = 0;
            modifier.max = 0;
          }

          //Min max AggregateQty
          modifier.MaxAggregateQty = md.MaxAggregateQty;
          modifier.MinAggregateQty = md.MinAggregateQty;
          modifier.MaxChoiceQty = md.MaxChoiceQty;
          modifier.MinChoiceQty = md.MinChoiceQty;
          modifier.ChoiceQtyIncrement = md.ChoiceQtyIncrement;
          modifier.UseAdvancedModifierQuantitySettings =
            md.UseAdvancedModifierQuantitySettings;

          if (Number(modifier.MaxAggregateQty) > 1) {
            modifier.ruleAggregate =
              'Select total of ' + modifier.MaxAggregateQty;
          } else {
            modifier.ruleAggregate = modifier.rule;
          }
          data.push(modifier);
        }
        // //console.log('modifier data', data);
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(getItems, [], setItems, logError);
    });
  }

  getIngredientsByModIDFromSql(id: string) {
    return new Promise<ModifierIngredientV2[]>((resolve: any, reject: any) => {
      let priceQuery = '';
      if (
        GeneralSetting.getMenuLevelID() &&
        GeneralSetting.getMenuLevelID() != '0'
      ) {
        let menuLevelID = GeneralSetting.getMenuLevelID();
        priceQuery = `, CASE WHEN
        (select count(Price) from OtherPrice_Ingredient_Mappings as OIM where  OIM.OPMID = '${menuLevelID}' AND OIM.IngredientID=MDM.IngredientID)  > 0
        THEN
        (select Price from OtherPrice_Ingredient_Mappings as OIM where  OIM.OPMID = '${menuLevelID}' AND OIM.IngredientID=MDM.IngredientID)
        Else
        MDM.ExtraPrice END AS ExtraPrice`;
      } else {
        priceQuery = `,MDM.ExtraPrice AS ExtraPrice`;
      }
      const getItems =
        `
      SELECT DISTINCT
      MM.ModifierID AS ModifierID

     ,MM.IsForced AS IsForced
     ,MM.ItemID AS ItemID ` +
        priceQuery +
        `,MDM.Quantity AS Quantity
     ,MDM.Countable AS Countable
     ,MDM.MaxQty AS MaxQty
     ,MDM.PortionCount AS PortionCount
     ,CAST(MDM.DisplayOrder AS INT) AS DisplayOrder
     ,MDM.IsPremium AS IsPremium
     ,MDM.IsDefault AS IsDefault
     ,MDM.ModifierDetailID
     ,MM.IsModifier86 as IsModifier86
     ,MDM.KOTKDSDisplayOrder as KOTDisplayOrder
     ,I.IngredientID AS IngredientID
     ,Case when I.DisplayName = '' then I.Name else I.DisplayName end AS Name
     ,I.DisplayName AS DisplayName
     ,I.ImageUrl AS ImageUrl
     ,I.DeselectImageUrl AS DeselectImageUrl
     ,I.IsAllergic AS IsAllergic
     ,I.IsIngredient86 AS IsIngredient86
     ,I.Calorie AS Calorie
     ,I.RefIngredientId
     ,I.IsItem
     ,MM.ParentID
     ,MM.RefModifierID
     ,I.KOTPrintDisplayName as KOTDisplayName
     ,(SELECT GROUP_CONCAT(DISTINCT(FM.IconImage)) FROM
      ModifierMasters AS MM, Ingredient_Filter_Mappings as IFM
      JOIN FilterMasters AS FM
        ON FM.FilterID = IFM.FilterID
      JOIN Ingredients AS I
        ON I.IngredientID = MDM.IngredientID
        AND I.IsActive = 'True'
        AND I.IsDeleted = 'False'
        AND I.IsShowOnKIOSK = 'True'
      JOIN Ingredient_Filter_Mappings
        ON IFM.IngredientID = I.IngredientID
        WHERE
        MM.ModifierID = '${id}'
        AND MM.IsActive = 'True'
        AND MM.IsShowOnKIOSK = 'True'
        AND MDM.IngredientID = I.IngredientID
        GROUP BY I.IngredientID ) as ItemTags
         FROM
         ModifierMasters as MM
         JOIN ModifierDetailMasters as MDM
         ON MDM.ModifierID = MM.ModifierID
         AND MDM.IsShowOnKiosk = 'True'
         AND MDM.IsActive = 'True'
         JOIN Ingredients as I
         ON I.IngredientID = MDM.IngredientID
         AND I.IsActive = 'True'
         AND I.IsDeleted = 'False'
         AND I.IsShowOnKIOSK = 'True'
             WHERE
             MM.ModifierID = '${id}'
             AND MM.IsActive = 'True'
             AND MM.IsShowOnKIOSK = 'True'

        ORDER BY (I.IsIngredient86) DESC, CAST(MDM.DisplayOrder AS int)
      `;

      const logError = (statement: any, error: any) => {
        // console.log('OLO Ingredient By Modifier ID Error :- ' + error);

        resolve([] as ModifierIngredientV2[]);
      };

      const setItems = (transaction: String, results: any) => {
        var listModIngredient: ModifierIngredientV2[] = [];
        for (let i = 0; i < results.rows.length; i++) {
          const md = results.rows[i];

          if (md.ModifierID == '24919') {
          }

          const modIngredient = new ModifierIngredientV2();
          modIngredient.ModifierID = md.ModifierID;
          modIngredient.IsForced = md.IsForced;
          modIngredient.ItemID = md.ItemID;
          modIngredient.ExtraPrice = md.ExtraPrice;
          modIngredient.Quantity = '1';
          modIngredient.Countable = md.Countable;
          modIngredient.MaxQty = md.MaxQty;
          modIngredient.PortionCount = md.PortionCount;
          modIngredient.DisplayOrder = md.DisplayOrder;
          modIngredient.IsPremium = md.IsPremium;
          modIngredient.IsDefault = md.IsDefault;
          modIngredient.ModifierDetailID = md.ModifierDetailID;
          modIngredient.IngredientID = md.IngredientID;
          modIngredient.Name = md.Name;
          if (md.DisplayName != '') {
            modIngredient.Name = md.DisplayName;
          }
          modIngredient.DisplayName = md.DisplayName;
          modIngredient.ImageUrl = md.ImageUrl;
          modIngredient.DeselectImageUrl = md.DeselectImageUrl;
          modIngredient.IsAllergic = md.IsAllergic;
          modIngredient.Calorie = md.Calorie;
          modIngredient.IngredientID = md.IngredientID;
          modIngredient.count = 0;
          modIngredient.height = 0;
          modIngredient.ItemTags =
            results.rows[i].ItemTags?.toString().split(',');
          modIngredient.IsIngredient86 = md.IsIngredient86;
          modIngredient.IsSelected =
            modIngredient.IsDefault == 'True' &&
            modIngredient.IsIngredient86 == 'True';
          modIngredient.ParentID = md.ParentID;
          (modIngredient.IsModifier86 = md.IsModifier86),
            (modIngredient.isNegativeModifier =
              modIngredient.IsDefault == 'True' &&
              (modIngredient.IsIngredient86 == 'False' ||
                modIngredient.IsModifier86 == 'False'));
          modIngredient.KOTDisplayOrder = md.KOTDisplayOrder;
          modIngredient.RefIngredientId = md.RefIngredientId;
          modIngredient.IsItem = md.IsItem;
          if (modIngredient.ImageUrl && modIngredient.ImageUrl != '') {
            this.downloadImageQueue.push(modIngredient.ImageUrl);
          }

          if (
            modIngredient.DeselectImageUrl &&
            modIngredient.DeselectImageUrl != ''
          ) {
            this.downloadImageQueue.push(modIngredient.DeselectImageUrl);
          }

          listModIngredient.push(modIngredient);
        }

        resolve(listModIngredient);
      };

      DatabaseHandler.executeSqlStatement(getItems, [], setItems, logError);
    });
  }

  async getAllComboGroupItems(comboGroup: ComboGroupV2[], itemId: string) {
    for (var i = 0; i < comboGroup.length; i++) {
      let getcomboItemPer = performance.now();

      //console.log("get Combo Item by comboId Performance :- " + getcomboItemPer);

      let comboGroupItems = await this.getComboItemsFromSql(
        comboGroup[i].ComboGroupID,
        itemId
      );

      let isHidden = comboGroupItems.find(
        (x) => x.IsInvisibleItem.toLowerCase() == 'true'
      );
      if (isHidden) {
        comboGroup[i].isHidden = true;
      } else {
        comboGroup[i].isHidden = false;
        comboGroup[i].isSelected = true;
      }

      //let items = await this.getComboItem(comboGroup[i].ComboGroupID, itemId, i);
      const getComboItemPerEnd = performance.now();

      //console.log("get Combo Item by ComboId Performance End :- " + getComboItemPerEnd);

      // console.log("get Combo Item by ComboId Performance End :- " + ((getComboItemPerEnd - getcomboItemPer) / 1000));

      comboGroup[i].Items = comboGroupItems;

      if (comboGroupItems && comboGroupItems != undefined) {
        for (let j = 0; j < comboGroupItems.length; j++) {
          if (comboGroupItems[j].isSelected) {
            comboGroup[i].IsSelected = true;
            break;
          }
        }
      }
    }

    return JSON.parse(JSON.stringify(comboGroup));
  }

  getAllConcessionaireForSettingFromSql() {
    return new Promise<Concessionaire[]>((resolve: any, reject: any) => {
      let getConcessionaire = ` select Distinct
                              cm.ConcessionaireID,
                              cm.Name,
                              cm.ColorCode,
                              cm.Logo,
                              cm.SidebarLogo,
                              cm.orderpriority,
                              Count(cim.ItemID) as ItemCount,
                              cdm.IsDefaultConcessionaire,
                              CASE WHEN EXISTS (SELECT ConcessionaireId FROM ConcessionaireDeviceMappings WHERE ConcessionaireDeviceMappings.ConcessionaireID = cm.ConcessionaireID) then 1 else 0 end as IsSelected

                            from ConcessionaireMasters as cm
                            left join ConcessionaireDeviceMappings as cdm on cdm.ConcessionaireId = cm.ConcessionaireId
                            left join Concessionaire_Item_Mappings as cim on cim.ConcessionaireID = cm.ConcessionaireID
                            where cm.IsActive = 'True'
                            group by cm.ConcessionaireID,cm.Name,cm.ColorCode,cm.Logo,cm.OrderPriority
                            Having COUNT(cim.ItemID) > 0
                            order by cm.orderpriority`;

      const errorCallback = (statement: any, error: any) => {
        console.log('Concessionaire Get Error', error);

        this.concessionaireSubject.next([]);
        resolve([] as Concessionaire[]);
      };

      const showCategories = (tx: string, results: any) => {
        var data: Concessionaire[] = [];

        for (let rr of results.rows) {
          let IsDefaultConcessionaire = rr.IsDefaultConcessionaire
            ? rr.IsDefaultConcessionaire.toLowerCase() == 'true'
            : false;

          let con: Concessionaire = {
            ConcessionaireID: rr.ConcessionaireID,
            Name: rr.Name,
            ColorCode: rr.ColorCode,
            Description: '',
            HeaderImage: '',
            IsDefaultConcessionaire: IsDefaultConcessionaire,
            IsSelected: rr.IsSelected,
            Logo: rr.Logo,
            orderpriority: rr.orderpriority,
            SidebarLogo: rr.SidebarLogo,
            IsActive: true,
            IsClosed: false,
            RefConcessionaireID: '',
          };

          data.push(con);
        }

        this.concessionaireSubject.next(data);
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getConcessionaire,
        [],
        showCategories,
        errorCallback
      );
    });
  }

  getIngredientsFromKitchenMapping(
    kitchenID: string
  ): Promise<Kitchen_Ingredient_Mapping[]> {
    return new Promise<Kitchen_Ingredient_Mapping[]>((resolve, rejects) => {
      const CallBack = (tx: any, result: any): void => {
        var data: Kitchen_Ingredient_Mapping[] = Array.from(result.rows);

        resolve(data);
      };
      const ErrorCallBack = (tx: any, result: any): void => {
        resolve([]);
      };

      let query = '';

      query = `select * from Kitchen_Ingredient_Mapping where KitchenID = '${kitchenID}'
          `;

      DatabaseHandler.executeSqlStatement(query, [], CallBack, ErrorCallBack);
    });
  }

  getItemFromKitchenmapping(kitchenId: any): Promise<Kitchen_Item_Mapping[]> {
    return new Promise<Kitchen_Item_Mapping[]>((resolve: any) => {
      let query = `
      select *
      from
      Kitchen_Item_Mapping
      WHERE KitchenId ='${kitchenId}'`;

      const exec = (tx: string, result: any) => {
        var data: Kitchen_Item_Mapping[] = Array.from(result.rows);

        resolve(data);
      };
      const callbackFail = function () {};
      DatabaseHandler.executeSqlStatement(query, [], exec, callbackFail);
    });
  }

  /**
   *
   * @param id Getting variations of item from SKU id
   * @returns
   */
  getVariationsBySkuIDFromSql(id: string) {
    return new Promise<any>((resolve: any) => {
      const getItems = `select IA.ItemAttributeID As ItemAttributeId,IA.ItemID As Itemid
      FROM ItemAttributeOptions AS IAO
      INNER JOIN ItemAttributes AS IA ON IA.ItemAttributeID = IAO.ItemAttributeID
      where IAO.Sku = '${id}'
      union

      select caim.ItemAttributeID As ItemAttributeId,caim.ItemID As Itemid
      FROM CommonAttribute_Item_Mappings AS caim
      INNER JOIN ItemAttributes AS IA ON IA.ItemAttributeID = caim.ItemAttributeID
      inner join ItemAttributeOptions AS IAO on iao.ItemAttributeID = IA.ItemAttributeID
      where IAO.Sku = '${id}'`;

      const logError = (statement: any, error: any) => {
        console.log(
          'Database Service , Error getVariationsByItemIDFromSql',
          error.message
        );

        resolve({});
      };

      const setItems = (transaction: String, results: any) => {
        var data: any[] = Array.from(results.rows);

        if (data.length > 0) {
          resolve(data[0]);
        } else {
          resolve({});
        }
      };

      DatabaseHandler.executeSqlStatement(getItems, [], setItems, logError);
    });
  }

  /**
   * Checks variation available or not based on scanned code
   * @param id
   * @returns
   */
  async getVariationOptionBySkuID(id: string) {
    let attributeDetail = await this.getVariationsBySkuIDFromSql(id);

    if (attributeDetail && Object.entries(attributeDetail).length > 0) {
      let itemAttributeID = attributeDetail.ItemAttributeId;
      let itemid = attributeDetail.Itemid;

      var variationOption = await this.getVariationOptionByIDFromSql(
        itemAttributeID,
        id
      );

      if (variationOption && variationOption.length > 0) {
        variationOption[0].ItemID = itemid;
        variationOption[0].isSelected = true;
        return variationOption;
      }
    }

    return [];
  }

  /**
   * Get Device ID , HSN and Name details from staffmodulemaster @nilesh
   */
  getDeviceHSNByDeviceID(deviceID: string) {
    return new Promise<string>((resolve) => {
      const query = `SELECT HSN,DeviceID,Name from StaffModuleMasters WHERE DeviceID = '${deviceID}'`;

      const error = (statement: any, error: any) => {
        // console.log("Database Service , Error getVariationsByItemIDFromSql", error.message);

        resolve('');
      };

      const success = (transaction: String, results: any) => {
        var data: any[] = Array.from(results.rows);

        if (data.length > 0) {
          resolve(data[0].HSN);
        } else {
          resolve('');
        }
      };

      DatabaseHandler.executeSqlStatement(query, [], success, error);
    });
  }

  /**
   * Get Name details from staffmodulemaster @nilesh
   */
  getDeviceNameByDeviceID(deviceID: string) {
    return new Promise<string>((resolve) => {
      const query = `SELECT Name from StaffModuleMasters WHERE DeviceID = '${deviceID}'`;

      const error = (statement: any, error: any) => {
        // console.log("Database Service , Error getVariationsByItemIDFromSql", error.message);

        resolve('');
      };

      const success = (transaction: String, results: any) => {
        var data: any[] = Array.from(results.rows);

        if (data.length > 0) {
          resolve(data[0].Name);
        } else {
          resolve('');
        }
      };

      DatabaseHandler.executeSqlStatement(query, [], success, error);
    });
  }

  gatherAllTaxesFromSql() {
    return new Promise<any[]>((resolve, reject) => {
      let taxQuery = `select TGMP.TaxGroupID,TGMP.TaxID,TGM.TaxGroupName,TM.TaxName,TM.Percentage,TM.IsActive,TGM.IsActive
      from TaxGroup_Mappings as TGMP, TaxGroupMasters as TGM ,
      TaxMasters as TM
      where TGMP.TaxGroupId = TGM.TaxGroupid and TGMP.taxid = TM.taxid AND TM.IsActive='True' And TGM.IsActive='True'`;

      const callback = (tx: string, result: any) => {
        let allTaxDetails = [];
        for (let i = 0; i < result.rows.length; i++) {
          allTaxDetails.push(result.rows[i]);
        }

        resolve(allTaxDetails);
      };

      const errorCallback = (tx: string, result: any) => {
        console.log(
          'Cart Service, Error Gathering all texes from sql',
          result.message
        );

        resolve([]);
      };

      DatabaseHandler.executeSqlStatement(
        taxQuery,
        [],
        callback,
        errorCallback
      );
    });
  }

  getItemKitchensFromSql(item: any) {
    return new Promise<string[]>((resolve: any, reject: any) => {
      let query = `
      select k.KitchenID as KID
      from
      Kitchen_Item_Mapping K
      JOIN Items i
      ON
      i.ItemID = k.ItemID WHERE i.ItemID='${item.ItemID}'`;

      const exec = (tx: string, result: any) => {
        const kitchens: string[] = [];
        for (const row of result.rows) {
          kitchens.push(row.KID);
        }

        resolve(kitchens);
      };

      const callbackFail = function () {
        reject([]);
      };

      DatabaseHandler.executeSqlStatement(query, [], exec, callbackFail);
    });
  }

  getReceiptItemGroups() {
    return new Promise<ReceiptGroup[]>((resolve: any, reject: any) => {
      let query = `
      SELECT ReceiptItemGroupID,ReceiptItemGroupName as name from ReceiptItemGroups`;

      const exec = (tx: string, result: any) => {
        const receiptItemGroups: ReceiptGroup[] = Array.from(result.rows);
        resolve(receiptItemGroups);
      };

      const callbackFail = function (tx: string, result: any) {
        resolve([]);
      };

      DatabaseHandler.executeSqlStatement(query, [], exec, callbackFail);
    });
  }

  /**
   * Gets Concessionaire Types
   * @returns
   */
  getConcessionaireTypes() {
    return new Promise<ConcessionaireFilterTypes[]>(
      (resolve: any, reject: any) => {
        let getConcessionaireTypes = ` select * from ConcessionaireTypes ORDER BY Name ASC`;

        const errorCallback = (statement: any, error: any) => {
          console.log('Concessionaire Type Get Error', error);

          resolve([] as ConcessionaireFilterTypes[]);
        };

        const setConcessionaireTypes = (tx: string, results: any) => {
          var data: ConcessionaireFilterTypes[] = Array.from(results.rows);
          resolve(data);
        };

        DatabaseHandler.executeSqlStatement(
          getConcessionaireTypes,
          [],
          setConcessionaireTypes,
          errorCallback
        );
      }
    );
  }

  getConcessionaireRefIdByConcessionaireIDFromSql(ConcessionaireId: string) {
    return new Promise<string>((resolve: any, reject: any) => {
      let getConcessionaire = '';
      getConcessionaire = `select cm.RefConcessionaireID
                              from ConcessionaireMasters as cm
                              Where cm.ConcessionaireId = '${ConcessionaireId}'`;

      const errorCallback = (statement: any, error: any) => {
        resolve('');
      };

      const showCategories = (tx: string, results: any) => {
        if (results.rows && results.rows.length > 0) {
          resolve(results.rows[0].RefConcessionaireID);
        } else {
          resolve('');
        }
      };

      DatabaseHandler.executeSqlStatement(
        getConcessionaire,
        [],
        showCategories,
        errorCallback
      );
    });
  }

  /**
   * Get Item Tags Group
   */
  async getItemGroupAndTags() {
    let itemTagGroupList = await this.getItemTagGroup();

    if (itemTagGroupList && Object.entries(itemTagGroupList).length > 0) {
      itemTagGroupList.forEach(async (element) => {
        var itemTags = await this.getItemTags(element.ItemTagGroupID);
        element.FilterItemTagGroupMapping = itemTags;
      });
      return itemTagGroupList;
    }
    return [];
  }

  /**
   * Gets Item Tag Groups here
   * @returns
   */
  getItemTagGroup() {
    return new Promise<ItemTagFilterMasters[]>((resolve: any, reject: any) => {
      let getItemTagGroup = `Select ItemTagGroupID, ItemTagGroupName
        from ItemTagGroupMasters ORDER BY CAST(DisplayOrder AS int)`;
      const errorCallback = (statement: any, error: any) => {
        console.log('ItemTagFilterMasters Get Error', error);

        resolve([] as ItemTagFilterMasters[]);
      };

      const setItemTagGroup = (tx: string, results: any) => {
        var data: ItemTagFilterMasters[] = Array.from(results.rows);
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getItemTagGroup,
        [],
        setItemTagGroup,
        errorCallback
      );
    });
  }

  /**
   * Gets Item tags of group id
   * @param groupId to get Item Tags
   * @returns
   */
  getItemTags(groupId: string = '') {
    return new Promise<ItemTagGroupMappings[]>((resolve: any, reject: any) => {
      let getItemTagsOfGroup = `Select FM.FilterID,
        case when FM.DisplayName = '' then FM.FilterName
          else FM.DisplayName
          end as FilterName
        from ItemTagGroupMasters ITGM
        JOIN Filter_ItemTagGroup_Mappings FITGM ON FITGM.ItemTagGroupID = ITGM.ItemTagGroupID
        JOIN FilterMasters FM ON FM.FilterID = FITGM.FilterID where FITGM.ItemTagGroupID ='${groupId}'
        AND FM.IsActive = 'True' ORDER BY FilterName ASC `;
      const errorCallback = (statement: any, error: any) => {
        console.log('ItemTagFilterMasters Get Error', error);
        ItemTagGroupMappings;
        resolve([] as ItemTagGroupMappings[]);
      };

      const setItemTagsOfGroup = (tx: string, results: any) => {
        var data: ItemTagGroupMappings[] = Array.from(results.rows);
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getItemTagsOfGroup,
        [],
        setItemTagsOfGroup,
        errorCallback
      );
    });
  }
  getOrderPaymentSuccess(orderInvoiceNo: string = '') {
    return new Promise<OrderPaymentSuccess[]>((resolve: any, reject: any) => {
      let getOrderPaymentsuccess = `Select * from OrderPaymentSuccess where invoiceNumber = '${orderInvoiceNo}'`;
      const errorCallback = (statement: any, error: any) => {
        resolve([] as OrderPaymentSuccess[]);
      };

      const setItemTagsOfGroup = (tx: string, results: any) => {
        var data: OrderPaymentSuccess[] = Array.from(results.rows);
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getOrderPaymentsuccess,
        [],
        setItemTagsOfGroup,
        errorCallback
      );
    });
  }

  getPaymentDeviceFromSql(): Promise<StaffModuleMaster> {
    return new Promise((resolve) => {
      const query = `SELECT hsn FROM StaffModuleMasters WHERE DeviceID = '${GeneralSetting.getSerialNo()}'`;

      function callback(tx: string, data: any) {
        if (data.rows && data.rows.length > 0) {
          resolve(data.rows[0]);
        } else {
          resolve({} as StaffModuleMaster);
        }
      }

      function errorCallback(tx: string, data: any) {
        resolve({} as StaffModuleMaster);
      }
      DatabaseHandler.executeSqlStatement(query, [], callback, errorCallback);
    });
  }

  async updateCommonModifierStatusById(
    modifierId: string,
    itemId: string,
    itemStatus: string,
    check: Number
  ) {
    let query = '';
    var sqlString = '';
    if (check == 1) {
      query = `select * from commonmodifier_item_mappings where ModifierID = '${modifierId}'`;
    } else {
      query = `select * from commonmodifier_item_mappings where ModifierID = '${modifierId}' and ItemID = '${itemId}'`;
    }

    let isModifier = await DatabaseHandler.getDataFromQuery<any[]>(
      query
    ).toPromise();
    if (!isModifier || isModifier.length <= 0) {
      this.isDoRefresh = true;
      this.MenuRefresh.next(true);
      return;
    }

    this.update86StatusById(itemId, itemStatus, true, false);

    const DataCallBack = (tx: any, results: any) => {
      //console.log("Success data change item status");
    };

    const ErrorCallBack = (tx: any, results: any) => {
      console.log(
        'Database Service , Error updateModifierStatusById',
        results.message
      );
    };

    if (check == 1) {
      sqlString =
        "UPDATE commonmodifier_item_mappings SET IsModifier86 = '" +
        itemStatus +
        "' WHERE ModifierID = '" +
        modifierId +
        "'";
    } else {
      sqlString =
        "UPDATE commonmodifier_item_mappings SET IsModifier86 = '" +
        itemStatus +
        "' WHERE ModifierID = '" +
        modifierId +
        "' and ItemID = '" +
        itemId +
        "'";
    }
    DatabaseHandler.executeSqlStatement(
      sqlString,
      [],
      DataCallBack,
      ErrorCallBack
    );
  }
}
