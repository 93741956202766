import { Injectable, NgZone } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { promise } from 'protractor';
import { Subscription } from 'rxjs';
import { DatabaseService } from './database.service';
import { loggingData, LogService } from './log.service';
import { PusherService } from './pusher.service';
import { SaveSettingsService } from './save-settings.service';
@Injectable({
  providedIn: 'root',
})
export class LoginGuardService {
  allSubscriptions: Subscription[] = [];
  isLogin: boolean = true;
  constructor(
    private router: Router,
    private logger: LogService,
    private readonly db: DatabaseService,
    private readonly pusherService: PusherService,
    private readonly saveSettingsService: SaveSettingsService,
    private _ngZone: NgZone
  ) { }
  checkDatabase() {// @ts-ignore: not all code paths return a value error

    const isLoggedIn = localStorage.getItem('IsDataBasePresent');
    if (isLoggedIn) {
      this.db.getDetailSetting();
      this.db.getKitchenPrinters(true);
      this.pusherService.getPusherKeys(true);
      this.saveSettingsService.theme();
      var log = new loggingData(
        'KIOSK Restarted',
        'Redirect to touch to start',
        'Log',
        'KIOSK Restarted, Redirect to touch to start.',
        false
      );
      this.logger.sendLogToServer(log);
      return new Promise<boolean>((resolve) => {
        this.saveSettingsService.themeSub.subscribe((value) => {
          if (this.isLogin) {
            this.isLogin = false;
            this._ngZone.run(this.redirect.bind(this))
            resolve(false);
            //this.saveSettingsService.themeSub.unsubscribe();
          }
        });
      });
    } else {
      return (true);
    }

  }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let databasePresent = await this.checkDatabase();
    return databasePresent;
  }
  redirect() {
    this.router.navigateByUrl('/touchToStart?IsRestarted=true');
  }
}