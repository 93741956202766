import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { environment } from '../../../environments/environment';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { DatabaseService } from '../../services/database.service';

@Component({
  selector: 'app-item-tags-key',
  templateUrl: './item-tags-key.component.html',
  styleUrls: ['./item-tags-key.component.css'],
})
export class ItemTagsKeyComponent implements OnInit, OnDestroy {
  ShowItemTagImages: string | null = GeneralSetting.getShowItemTagImages();


  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  constructor(
    private db: DatabaseService,
    private isKioskService: IsKioskService
  ) {}


  item: any;
  @Input() itemTagArray: any;
  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;
  allTags: any;
  allTagsMap = new Map();
  itemTags = new Map();

  setItemTags() {
    this.itemTags = new Map();
    this.db.getAllItemTags().then((data: any[]) => {
      if (data && data.length > 0) {
        this.allTags = data[0];
        if (this.allTags && this.allTags.AllTags) {
          for (var i = 0; i < this.allTags.AllTags.length; i++) {
            this.allTagsMap.set(
              this.allTags.AllTagNames[i],
              this.allTags.AllTags[i]
            );
          }
        }
        for (const tag of this.itemTagArray || []) {
          for (const [key, value] of this.allTagsMap) {
            if (tag == value) {
              this.itemTags.set(key, value);
            }
          }
        }
      }
    });
  }

  ngOnInit(): void {

    this.setItemTags();
  }
}
