import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-usconnect-balance-low',
  templateUrl: './usconnect-balance-low.component.html',
  styleUrls: ['./usconnect-balance-low.component.css']
})
export class UsconnectBalanceLowComponent implements OnInit,OnDestroy {

  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  modalReference: any;

  checkoutText:string=''

  notEnoughBalanceText:string=''

  tryDifferentMethodText:string=''

  useAnotherMethodText:string=''

  languageSub!: Subscription;

  constructor(private modalService: NgbModal,private language: LanguageService) {}

  loadText() {
    this.checkoutText = this.language.getTextElement('lbl_checkout');
    this.notEnoughBalanceText = this.language.getTextElement('not_enough_balance');
    this.tryDifferentMethodText = this.language.getTextElement('card_insufficient_funds_try_different_method');
    this.useAnotherMethodText = this.language.getTextElement('use_another_method');
  }

  ngOnInit(): void { }

  public static open(modelService: NgbModal) {
    var modalReference = modelService.open(UsconnectBalanceLowComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }

  close(event: string) {
    this.modalReference.close(event);
  }

}
