import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Guid } from 'src/app/models/Guid';
import { ComboGroupV2, ItemV2, ModifierV2 } from 'src/app/models/item';
import { VariationOption } from 'src/app/models/Variations';
import {
  CategorySalesType,
  CommonFunctions,
  Snackbar,
} from 'src/app/services/common';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { LastfiveorderService } from 'src/app/services/lastfiveorder.service';
import { environment } from 'src/environments/environment';
import { AlcoholicItemsExceedMaxComponent } from '../../dialogs/alcoholic-items-exceed-max/alcoholic-items-exceed-max.component';

// import { BoxBuilderComponent } from '../box-builder.component';
import { BoxBuilderReviewComponent } from '../box-builder-review/box-builder-item-review.component';
import { CartService } from 'src/app/services/cart.service';
import { Concessionaire } from '../../../models/Concessionaire';
import { Subscription } from 'rxjs';
import { DatabaseService } from '../../../services/database.service';
import { GeneralAlertComponent } from '../../dialogs/general-alert/general-alert.component';
import { LanguageService } from 'src/app/services/language.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-box-builder-footer',
  templateUrl: './box-builder-footer.component.html',
  styleUrls: ['./../box-builder.component.css'],
})
export class BoxBuilderFooterComponent implements OnInit {
  @Input() categoryID: any;

  @Input() inputItem: ItemV2 = new ItemV2();

  @Input() boxBuilderItemDetailModal!: any;

  @Input() isCombo: boolean = false;

  @Input() quantityWhenAdd: number = 1;

  @Input() cartQuantity: number = 0;

  @Input() modifiers: ModifierV2[] = [];

  @Input() variations: VariationOption[] = [];

  @Input() comboGroup: ComboGroupV2[] = [];

  @Input() comboGroups: ComboGroupV2[] = [];

  @Input() itemID: any;

  @Input() isModifiersValid: boolean = false;

  @Input() isVariationsValid: boolean = false;

  @Input() isComboGroupValid: boolean = false;

  @Input() isReady: boolean = false;

  // @Input() boxBuilderModal!: BoxBuilderComponent;

  @Input() isComboItem = false;

  @Input() comboItemFlags: any[] = [];

  @Input() selectedTotalCount: number = 0;

  @Input() totalComboGroups: number = 0;

  @Input() currentSelectedModifierIndex: number = 0;

  @Input() numInvisibleGroups: number = 0;

  @Output() itemModalClose = new EventEmitter<any>();

  @Output() selectedCountEmitter = new EventEmitter<number>();

  @Output() comboSelectionEmitter = new EventEmitter<ComboGroupV2[]>();

  @Input() itemImageList: any[] = [];

  @Input() isEdit: boolean = false;

  @ViewChild(BoxBuilderReviewComponent)
  boxBuilderReviewModel!: BoxBuilderReviewComponent;
  @ViewChild(GeneralAlertComponent) generalAlertDialog!: GeneralAlertComponent;

  @Output() itemImageListEmitter = new EventEmitter<any[]>();

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  modGrpImg: string = '/assets/images/Image_21.png';

  item!: ItemV2;

  alcoholicExceedMaxPopup!: AlcoholicItemsExceedMaxComponent;

  itemGuestName: string = '';

  specialRequest: string = '';

  totalQuantity = [] as any[];

  selectedQuantity: number = -1;

  selectableQuantity!: number;

  concessionaireId: string = '';

  footerImage: string | null = '';

  cancelText: string = '';
  reviewBoxText: string = ''
  languageSub!: Subscription;

  concessionaire: Concessionaire = {} as Concessionaire;

  isConcessionaire: boolean = false;
  private allSubsCription: Subscription[] = [];
  constructor(
    private readonly router: Router,
    private readonly lastfiveorderService: LastfiveorderService,
    private readonly isKioskService: IsKioskService,
    private readonly cartService: CartService,
    route: ActivatedRoute,
    private readonly modalService: NgbModal,
    private language: LanguageService,
    private databaseService: DatabaseService
  ) {
    this.concessionaireId = route.snapshot.queryParams['concessionaireId'];
  }
  loadText() {
    this.cancelText = this.language.getTextElement('lbl_button_cancel');
    this.reviewBoxText = this.language.getTextElement('review_your_box');
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.footerImage =
      GeneralSetting.getBoxBuilderFooterImage() != ''
        ? GeneralSetting.getBoxBuilderFooterImage()
        : GeneralSetting.getBranchLogo();

    if (this.numInvisibleGroups) {
      this.totalQuantity = Array.from(
        Array(this.totalComboGroups - this.numInvisibleGroups).keys()
      );
    }
    this.isConcessionaire =
      GeneralSetting.getIsConcessionaire().toLowerCase() == 'true';

    if (this.isConcessionaire) {
      this.allSubsCription.push(
        this.databaseService.concessionaireSubject.subscribe((conList) => {
          let con = conList.find((x) => x.ConcessionaireID == this.concessionaireId);
          if (con) {
            this.concessionaire = con;
          }
        })
      );
    }
  }

  ngOnDestroy() {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }
  cancel() {
    if (this.isCombo) {
      this.router.navigate(['/menu'], {
        queryParams: {
          category: this.inputItem.CategoryID,
          concessionaireId: this.concessionaireId,
        },
      });
    } else {
      this.itemModalClose.emit(null);
    }
  }

  addToCart() {
    const item = this.createCartItem();

    this.closeModel(item);
  }

  private createCartItem(): ItemV2 {
    const cartItem = JSON.parse(JSON.stringify(this.inputItem)) as ItemV2;
    if (this.isEdit) {
      cartItem.guid = this.inputItem.guid;
      cartItem.BoxBuilderItemGUID = this.inputItem.BoxBuilderItemGUID;
    } else {
      cartItem.guid = Guid.newGuid();
      cartItem.BoxBuilderItemGUID = Guid.newGuid();
    }
    cartItem.Modifiers = this.modifiers;
    cartItem.ComboGroup = [];
    cartItem.Quantity = this.inputItem!.Quantity;
    cartItem.ItemConsolidateQuantity = this.inputItem.ItemConsolidateQuantity;
    cartItem.IsCombo = !(
      cartItem.IsCombo.toString() == '0' ||
      cartItem.IsCombo.toString().toLowerCase() == 'false'
    );
    cartItem.AllowQuantitySelection =
      cartItem.AllowQuantitySelection == ''
        ? 'True'
        : cartItem.AllowQuantitySelection;
    cartItem.totalPrice =
      CommonFunctions.getItemPrice(cartItem) * Number(cartItem.Quantity);

    return cartItem;
  }

  private createCartCombo(): ItemV2 {
    const cartItem = JSON.parse(JSON.stringify(this.inputItem)) as ItemV2;

    cartItem.guid = this.inputItem.guid ? this.inputItem.guid : Guid.newGuid();
    cartItem.Modifiers = this.modifiers;
    cartItem.VariationOption = this.variations;
    cartItem.ComboGroup = this.inputItem.ComboGroup;
    cartItem.Quantity = this.quantityWhenAdd.toString();
    cartItem.AllowQuantitySelection =
      cartItem.AllowQuantitySelection == ''
        ? 'True'
        : cartItem.AllowQuantitySelection;
    cartItem.GuestName = this.itemGuestName;
    cartItem.specialRequest = this.specialRequest;
    cartItem.totalPrice =
      CommonFunctions.getItemPrice(cartItem) * Number(cartItem.Quantity);

    return cartItem;
  }

  closeModel(item: ItemV2) {
    this.itemModalClose.emit(item);
  }

  onClick(event: any, pos: number) {
    this.selectedQuantity = pos;
    this.inputItem.Quantity = (this.selectedQuantity + 1).toString();
    this.inputItem.ItemConsolidateQuantity = this.selectedQuantity + 1;
  }

  ngOnChanges() {
    this.selectableQuantity =
      this.totalQuantity.length -
      this.selectedTotalCount +
      this.inputItem.ItemConsolidateQuantity;
  }

  private checkValidityShowAnimation(item: ItemV2) {
    if (item.Modifiers != undefined) {
      for (let i = 0; i < item.Modifiers.length; i++) {
        const count = CommonFunctions.getSelectedIngredientCount(
          item.Modifiers[i]
        );

        if (
          !this.checkValidModifierSelection(
            this.inputItem.Modifiers[i].min,
            this.inputItem.Modifiers[i].max,
            count
          )
        ) {
          // this.modifierAnimation();
          return false;
        }
      }
    }

    return true;
  }

  checkValidModifierSelection(min: number, max: number, count: number) {
    // Required
    if (min == max) {
      if (count == min) {
        return true;
      } else {
        return false;
      }
    }

    //Choose Up to max
    if (min == 0 && max >= 1) {
      if (count <= max) {
        return true;
      } else {
        return false;
      }
    }

    //Select at least one
    if (min == 1 && max > 1) {
      if (count >= 1 && count <= max) {
        return true;
      } else {
        return false;
      }
    }

    //select at least more then one
    if (min > 1 && max > 1) {
      if (count > min || count <= max) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  /**
   * @description Appropriately routes to correct screen if edit item, and calls alcohol checks if alcoholic before editing cart
   */
  private checkEditItem(): boolean {
    if (this.inputItem.guid) {
      if (this.inputItem.GuestName && this.inputItem.GuestName != '') {
        this.itemGuestName = this.inputItem.GuestName;
      }
      if (
        !(
          this.inputItem.ItemCategorySalesTypeID ===
          CategorySalesType.Alcohol.toString()
        )
      ) {
        //this.cartService.addToCartV2(this.createCartItem());
        //this.location.back();
        return true;
      } else if (this.checkAndShowAlcoholLimitModel()) {
        //this.cartService.addToCartV2(this.createCartItem());
        //this.location.back();
        return true;
      }
    }

    return false;
  }

  private checkAndShowAlcoholLimitModel(): boolean {
    const currentCountofAlkol = GeneralSetting.getCurrentCountofAlkol();
    const maxOrder = GeneralSetting.getMaxAlcoholicItemsPerOrder();

    if (maxOrder == null) {
      return true;
    }

    const tempQuantity = this.inputItem.guid
      ? this.quantityWhenAdd
      : this.quantityWhenAdd - Number(this.inputItem.Quantity);

    if (
      currentCountofAlkol != null &&
      Number(maxOrder) > 0 &&
      tempQuantity + Number(currentCountofAlkol) > Number(maxOrder)
    ) {
      AlcoholicItemsExceedMaxComponent.open(this.modalService);
      return false;
    }

    return true;
  }

  calculateTotal() {
    if (this.inputItem == undefined || this.inputItem.Price == undefined) {
      return;
    }
    this.inputItem.VariationOption = this.variations;
  }

  private redirectToPageAfterAddToCart() {
    if (this.checkIfFromLastFivePageForCombo()) {
      this.router.navigateByUrl('/lastfiveorder').then(() => {
        this.lastfiveorderService.updateitemList(
          this.itemID,

          this.variations,

          this.modifiers
        );
      });

      GeneralSetting.setIntoCombo('false');
    } else {
      if (this.getParameterByName('isFromReview') === 'true') {
        this.router.navigateByUrl('/orderReview');
      } else {
        this.router.navigate(['/menu'], {
          queryParams: {
            category: this.inputItem.CategoryID,
            id: this.inputItem.ItemID,
            concessionaireId: this.concessionaireId,
          },
        });
      }
    }
  }

  getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  private checkIfFromLastFivePageForCombo() {
    const checkIfLastfiveorder = GeneralSetting.getLastFiveOrderChosen();
    const intocombo = GeneralSetting.getIntoCombo();

    if (
      checkIfLastfiveorder &&
      checkIfLastfiveorder === 'true' &&
      intocombo &&
      intocombo === 'true'
    )
      return true;

    return false;
  }

  getBaseImageUrl() {
    this.baseImageUrl = this.isKioskService.isKiosk()
      ? environment.imageBaseUrl
      : environment.imageBaseUrlRemote;
    return this.baseImageUrl;
  }

  async reviewYourBox() {
    let requisitesForCartItem = {
      modifiers: this.modifiers,
      variations: this.variations,
      quantityWhenAdd: this.quantityWhenAdd,
      itemGuestName: this.itemGuestName,
      specialRequest: this.specialRequest,
      itemID: this.itemID,
    };
    if (this.selectedTotalCount == 0) {
      this.generalAlertDialog.open('Uh Oh!', 'Please add item to box');
      return;
    }
    this.boxBuilderReviewModel.open(
      this.selectedTotalCount,
      this.inputItem,
      this.comboItemFlags,
      this.itemImageList,
      requisitesForCartItem,
      this.numInvisibleGroups,
      this.isConcessionaire,
      this.concessionaire
    );
    this.boxBuilderReviewModel.ref.result.then(
      async (res: any) => {
        if (res == 'addToCart') {
          if (this.isCombo) {
            let res = await this.cartService.addToCartAsync(
              this.createCartCombo()
            );
            if (!res.status) {
              if (res.error == 'alcohol') {
                Snackbar.show(
                  'Exceeded maximum alcohol quantity for this item',
                  3000
                );
              }

              return;
            }

            GeneralSetting.setCurrentCountofAlkol(
              Number(GeneralSetting.getCurrentCountofAlkol()) +
              Number(this.quantityWhenAdd)
            );

            this.redirectToPageAfterAddToCart();
            //this.location.back();
          }
        } else if (res && res.message && res.message == 'Add_Remaining') {
          let comboGroup = res.data as ComboGroupV2[];
          let selectedcount = 0;
          for (let i = 0; i < this.comboGroups.length; i++) {
            if (!this.comboGroups[i].isHidden) {
              for (let j = 0; j < this.comboGroups[i].Items.length; j++) {
                const comboItem = this.comboGroups[i].Items[j];
                if (comboItem.isSelected) {
                  let itemNew = JSON.parse(JSON.stringify(comboItem)) as ItemV2;

                  // this.itemImageList.push({ 'image': this.baseImageUrl + comboItem.ImageUrl, 'selected': true });
                  selectedcount++;
                }
              }
            }
          }
          this.itemImageList = res.itemImageList;
          this.itemImageListEmitter.emit(this.itemImageList);
          this.selectedCountEmitter.emit(selectedcount);
          this.comboSelectionEmitter.emit(comboGroup);
        }
      }
      // }
    );
  }
}
