<div id="custominfo-popup" role="dialog">
  <div class="header">
    <h3 class="text-center"><b>{{oopsText}}</b></h3>
    <h3 class="text-center">{{ errorMsg }}</h3>
  </div>
  <div class="popup-footer">
    <button type="submit" class="text-right plain-btn" (click)="close()">
      {{okText}}
    </button>
  </div>
</div>
