import {
  Component,
  OnDestroy,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { loggingData, LogService } from 'src/app/services/log.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LastFiveOrderComponent } from 'src/app/components/last-five-order/last-five-order.component';
import { UserService } from 'src/app/services/user.service';
import { LastfiveorderService } from 'src/app/services/lastfiveorder.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { LanguageService } from 'src/app/services/language.service';
import { CustomerLoginModalService } from 'src/app/components/dialogs/customer-login-popup/customer-login-popup.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-returning-customer-dialog',
  templateUrl: './returning-customer-dialog.component.html',
  styleUrls: ['./returning-customer-dialog.component.css'],
})
export class ReturningCustomerDialogComponent implements OnInit {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  modalReference: any;
  keySub!: Subscription
  infoform!: FormGroup;
  submitted = false;
  isKiosk = this.isKioskService.isKiosk();
  positiveColor: string | null = '';
  positiveColorText: string | null = '';
  negativeColor: string | null = '';
  negativeColorText: string | null = '';
  isUKPhoneFormat: boolean = false

  phoneLength: number = 10
  mask: string = "000-000-0000"

  customerPhoneNo!: string;
  customerName!: string;
  customerEmail!: string;
  customerID!: string;
  showName: boolean = false;
  directToMenu: boolean = false;

  @ViewChild(LastFiveOrderComponent)

  lastfive!: LastFiveOrderComponent;

  myAccountText: string = ''
  phoneNumberText: string = ''
  nameText: string = ''
  emailText: string = ''
  continueText: string = ''
  invalidPhoneText: string = ''
  emailRequiredText: string = ''
  invalidEmailText: string = ''
  signInTopText: string = ''
  signInBottomText: string = ''
  enterPhoneToBeginText: string = ''
  addNameToCompleteText: string = ''
  addValidNameToCompleteText: string = ''
  continueAsGuestText: string = ''

  @ViewChild('phoneforreturningcus')
  phoneforreturningcusElement!: ElementRef;

  isAllowForMarketingCommunication: boolean = false;
  checkedTermsAndConstions: boolean = false;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private dataservice: DataService,
    private router: Router,
    private lastfiveorderservice: LastfiveorderService,
    private userService: UserService,
    private lastFiveService: LastfiveorderService,
    private logger: LogService,
    private KeyboardService: KeyboardService,
    private readonly isKioskService: IsKioskService,
    private customerLoginService: CustomerLoginModalService,
    private language: LanguageService,
    private readonly loader: LoaderService,
  ) {
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    )
  }
  loadText() {
    this.phoneNumberText = this.language.getTextElement('hint_phone_number');
    this.nameText = this.language.getTextElement('txt_name');
    this.myAccountText = this.language.getTextElement('lbl_my_account');
    this.emailText = this.language.getTextElement('email');
    this.continueText = this.language.getTextElement('lbl_continue');
    this.invalidPhoneText = this.language.getTextElement('invalid_phone_number')
    this.emailRequiredText = this.language.getTextElement('email_is_required')
    this.invalidEmailText = this.language.getTextElement('invalid_email')
    this.signInTopText = this.language.getTextElement('lbl_existing_dialog_description_with_email_a')
    this.signInBottomText = this.language.getTextElement('lbl_existing_dialog_description_with_email_b')
    this.enterPhoneToBeginText = this.language.getTextElement('enter_phone_to_begin')
    this.addNameToCompleteText = this.language.getTextElement('add_name_to_complete')
    this.addValidNameToCompleteText = this.language.getTextElement('add_valid_name_to_complete')
    this.continueAsGuestText = this.language.getTextElement('continue_as_guest').toLocaleUpperCase()
  }
  ngOnInit(): void {

    this.isUKPhoneFormat = GeneralSetting.getCountry() == "230"
    if (this.isUKPhoneFormat) {
      this.phoneLength = 11
      this.mask = "00000-000000"

    }
    this.infoform = this.formBuilder.group({
      phoneNumber: ['', [Validators.required, Validators.pattern('[1-9]\d*|0\d{11}(?!0+$)')],],
      // email: ['', [Validators.email]],
      cusotmername: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]*$"), Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    });
    this.negativeColorText = GeneralSetting.getNegativeColorText()
    this.negativeColor = GeneralSetting.getNegativeColor()
    this.positiveColor = GeneralSetting.getPositiveColor();
    this.positiveColorText = GeneralSetting.getPositiveColorText()
    if (GeneralSetting.getAllowCustomerOptInForMarketing()) {
      this.isAllowForMarketingCommunication = true;
    }
  }

  ngAfterViewInit() {
    if (this.customerLoginService.returningpopuprequired.getValue()) {
      this.KeyboardPopup();
    }
  }
  // add click event on first input field @jay
  public KeyboardPopup() {
    let phoneforreturningcusEle: HTMLElement = this.phoneforreturningcusElement.nativeElement as HTMLElement;
    phoneforreturningcusEle.click();
    this.customerLoginService.returningpopuprequired.next(false);
  }


  get f() {
    return this.infoform.controls;
  }

  open() {
    this.modalReference = this.modalService.open(
      ReturningCustomerDialogComponent,
      { backdrop: 'static', centered: true, size: 'lg' }
    );
    this.modalReference.componentInstance.modalReference = this.modalReference;
    return this.modalReference
  }

  dismiss() {
    this.modalReference.close();
  }

  onchange() {
    if (this.customerPhoneNo && this.customerPhoneNo.length === this.phoneLength) {
      GeneralSetting.setCustomerLoginMobile(this.customerPhoneNo);
      GeneralSetting.setCustomerLoginEmail(this.customerEmail);

      this.dataservice.getCustomerByMobileNo(this.customerPhoneNo).toPromise().then(
        (data: any) => {
          // to check whether popup is open or not and check whether user allow to optin for marketing communication or not
          if (data.data.CustomerMaster === null) {
            this.showName = true;
            this.directToMenu = true;
            // this.isAllowForMarketingCommunication = true;
            // GeneralSetting.setIsAllowForMarketingCommunication('True');
          } else {
            this.customerID = data.data.CustomerMaster.CustomerID;
            this.showName = true;
            this.userService.returningCustomer = data.data.CustomerMaster

            GeneralSetting.setCustomerLoginMobile(this.customerPhoneNo);
            GeneralSetting.setCustomerLoginName(this.userService.returningCustomer.FullName);

            GeneralSetting.setCustomerId(this.customerID);
            this.customerName = data.data.CustomerMaster.FullName;


            if (GeneralSetting.getAllowCustomerOptInForMarketing()) {
              this.isAllowForMarketingCommunication = data.data.CustomerMaster.IsAllowForMarketingCommunication == 'False'
              if (data.data.CustomerMaster.IsAllowForMarketingCommunication == 'True' || this.isAllowForMarketingCommunication) {
                GeneralSetting.setIsAllowForMarketingCommunication('True');
              }
            } else {
              this.isAllowForMarketingCommunication = false;
            }
          }
        },
        (response: any) => {
          var log = new loggingData('Returning Customer', 'Returning Customer Error', 'ReturningCustomer Error', `Returning Customer With Phone Number ${this.customerPhoneNo} Error Encountered: ${response}`, true);
          this.logger.sendLogToServer(log);
        }
      );
    }
    else {
      this.customerName = "";
      GeneralSetting.setCustomerId("");
    }
  }
  onContinue() {
    this.KeyboardService.KeyboardStatus = false;
    this.submitted = true;
    // check for allow for marketing communication and check for terms and conditions
    if (this.infoform.invalid || (this.isAllowForMarketingCommunication && !this.checkedTermsAndConstions)) {
      return;
    }
    GeneralSetting.setCustomerName(this.customerName.trim());
    GeneralSetting.setCustomerLoginName(this.customerName.trim());
    this.userService.isUserLoggedIn = true;
    let customerID = GeneralSetting.getCustomerId()
    this.loader.openLoader();
    this.dataservice.getOrderHistoryByCustomerID(customerID)
      .toPromise()
      .then((data: any) => {
        this.lastfiveorderservice.OrderResponse = data;
        this.dismiss();
        this.loader.closeLoader();
        if (this.lastfiveorderservice.OrderResponse.OrderItemMasters.length) {
          this.modalReference.result.then(() => {
            this.router.navigateByUrl('/lastfiveorder').then(() => {
              this.lastFiveService.clearhistoryData();
            });
          });
        }
      })
      .catch((err) => {
        this.loader.closeLoader();
        this.dismiss();
      });
    // this.modalReference.result.then(() => {      
    //   this.router.navigateByUrl('/lastfiveorder').then(() => {
    //     this.lastFiveService.clearhistoryData();
    //   });
    // });
  }

  guestLogin() {
    this.KeyboardService.KeyboardStatus = false;
    this.dismiss();
  }
}
