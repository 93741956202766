import { Component, OnDestroy, OnInit } from '@angular/core';
import { Console } from 'console';
import { Subscription } from 'rxjs';
import { StoreTime } from 'src/app/models/storeTime';
import { LanguageService } from 'src/app/services/language.service';
import { LogService } from 'src/app/services/log.service';
import { DatabaseHandler } from '../../../DatabaseHandler';

@Component({
  selector: 'app-settings-store-timings',
  templateUrl: './settings-store-timings.component.html',
  styleUrls: ['./settings-store-timings.component.css'],
})
export class SettingsStoreTimingsComponent implements OnInit,OnDestroy {
  storeTime: StoreTime[] = [];
  enterTime: StoreTime = {
    start: '',
    end: '',
    dayId: 0,
  };
  languageSub!: Subscription;
  daysText: string = ''; 
  startTimeText: string = ''; 
  endTimeText: string = ''; 
  sundayText: string = ''
  mondayText: string = ''
  tuesdayText: string = ''
  wednesdayText: string = ''
  thursdayText: string = ''
  fridayText: string = ''
  saturdayText: string = ''
  constructor(private logger: LogService, private language: LanguageService) {}
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {
    this.daysText=this.language.getTextElement('days')
    this.startTimeText=this.language.getTextElement('start_time')
    this.endTimeText=this.language.getTextElement('end_time')
    this.sundayText=this.language.getTextElement('sunday')
    this.mondayText=this.language.getTextElement('monday')
    this.tuesdayText=this.language.getTextElement('tuesday')
    this.wednesdayText=this.language.getTextElement('wednesday')
    this.thursdayText=this.language.getTextElement('thursday')
    this.fridayText=this.language.getTextElement('friday')
    this.saturdayText=this.language.getTextElement('saturday')
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.getData().then((element: any) => {
      this.storeTime = element;
    });
  }

  getData(): Promise<any> {
    return new Promise((resolve, reject) => {
      const clone = (time: StoreTime): StoreTime => {
        return {
          start: time.start,
          end: time.end,
          dayId: time.dayId,
        };
      };

      function toStandardTime(militaryTime: any): string {
        militaryTime = militaryTime.split(':');
        if (
          (militaryTime[0].charAt(0) == 1 && militaryTime[0].charAt(1) > 2) ||
          militaryTime[0].charAt(0) === '2'
        ) {
          return militaryTime[0] - 12 + ':' + militaryTime[1] + ' PM';
        } else if (militaryTime[0] === '00') {
          return 12 + ':' + militaryTime[1] + ' AM';
        } else {
          return militaryTime.join(':') + ' AM';
        }
      }

      const errorCallback = (tx: any, result: any): void => {
        console.log(
          'GeneralWeekDayMasters getting store time error ',
          result.message
        );
        reject();
      };

      const DataCallback = (tx: any, result: any): void => {
        for (var i = 0; i < 7; i++) {
          if (typeof result.rows[i] === 'undefined') {
            this.enterTime.start = toStandardTime('00:00');
            this.enterTime.end = toStandardTime('23:59');
            this.storeTime.push(clone(this.enterTime));
          } else {
            this.enterTime.start = toStandardTime(
              result.rows[i].StartTime.slice(0, 5)
            );
            this.enterTime.end = toStandardTime(
              result.rows[i].EndTime.slice(0, 5)
            );
            this.storeTime.push(clone(this.enterTime));
          }
        }
        resolve(this.storeTime);
      };

      var sqlString = 'Select * FROM GeneralWeekDayMasters ORDER BY DayID';
      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        DataCallback,
        errorCallback
      );
    });
  }
}
