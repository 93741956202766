<div class="bol-modifier-page">
    <div class="header">
        <h1>Large Bol <span>- Small Bol</span></h1>
    </div>
    <div class="bol-indi-bar">
        <div class="box">
            <div class="indi-content">
                <div class="three-indi">
                    <div class="me-4">
                    <div class="ind-sm-img mb-4">
                        <img src="../../../../assets/images/avaa.jpg" />
                    </div>
                    <div class="ind-sm-img">
                        <img src="../../../../assets/images/avaa.jpg" />
                    </div>
                </div>
                    <div class="ind-sm-img">
                        <img src="../../../../assets/images/avaa.jpg" />
                    </div>
                </div>
                

            </div>
            <h2>Bases and Veggies</h2>
        </div>
        <div class="next">
            <i class="fas fa-arrow-right"></i>
        </div>
        <div class="box">
            <div class="indi-content">
                <div class="ind-sm-img">
                    <img src="../../../../assets/images/avaa.jpg" />
                </div>
                <div class="ind-sm-img ms-4">
                    <img src="../../../../assets/images/avaa.jpg" />
                </div>
            </div>
            <h2>Protins</h2>
        </div>
        <div class="next">
            <i class="fas fa-arrow-right"></i>
        </div>
        <div class="box">
            <div class="indi-content">
                <div class="ind-sm-img">
                    <img src="../../../../assets/images/avaa.jpg" />
                </div>
            </div>
            <h2>Extras</h2>
        </div>
        <div class="next">
            <i class="fas fa-arrow-right"></i>
        </div>
        <div class="box active">
            <div class="indi-content">
                <i class="fas fa-plus-circle"></i>
            </div>
            <h2>Premium Add-Ons</h2>
        </div>
        <div class="next">
            <i class="fas fa-arrow-right"></i>
        </div>
        <div class="box">
            <div class="indi-content">
                <i class="fas fa-plus-circle"></i>
            </div>
            <h2>Sauce It Up!</h2>
        </div>
        <div class="next">
            <i class="fas fa-arrow-right"></i>
        </div>
        <div class="box">
            <div class="indi-content">
                <i class="fas fa-plus-circle"></i>
            </div>
            <p>Want More? Add Additional Items $ </p>
        </div>
    </div>
    <div class="bol-indi-box">
        <p class="req">Required. <span>Choose between 2 TO 3</span></p>
        <div class="row">
            <div class="col-4">
                <div class="indi-item active">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                    <div class="qty">
                        <button class="btn btn-link"><i class="fas fa-minus-circle"></i></button>
                        <span>1</span>
                        <button class="btn btn-link"><i class="fas fa-plus-circle"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item active">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                    <div class="qty">
                        <button class="btn btn-link"><i class="fas fa-minus-circle"></i></button>
                        <span>1</span>
                        <button class="btn btn-link"><i class="fas fa-plus-circle"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item active">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                    <div class="qty">
                        <button class="btn btn-link"><i class="fas fa-minus-circle"></i></button>
                        <span>1</span>
                        <button class="btn btn-link"><i class="fas fa-plus-circle"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
        </div>
        <h1><i class="fas fa-star"></i> Want more small bol</h1>
        <p class="req"><span>Choose any 1</span></p>
        <div class="row">
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="indi-item">
                    <div class="indi-img">
                        <img src="../../../../assets/images/Image_21.png" alt="indi">
                    </div>
                    <div class="indi-info">
                        <h5>Base: Jasmine Rice</h5>
                        <img src="../../../../assets/images/information.png" alt="indi-type">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-item-footer class="footer">
    </app-item-footer>
</div>