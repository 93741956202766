import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { DataService } from 'src/app/services/data.service';
import { ItemV2, ModifierIngredientV2, ModifierV2 } from 'src/app/models/item';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { DatabaseService } from './database.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { Category } from '../models/category';
import __ from 'circular-json';
import { UserService } from './user.service';
import { SchedulePricingService } from './schedule-pricing.service';

@Injectable({
  providedIn: 'root',
})
export class LastfiveorderService {
  public customerID!: string;

  public orderItemMasters: any;

  public orderItemModifierMaster: any;

  public itemlist: ItemV2[] = [];

  public show86mods: boolean = true;

  public numofItemsAdded: number = 0;

  public mods86List: any[] = [];

  public noHistory: boolean = false;

  Categories: Category[] = [];

  dayOfWeek: number = 0;

  currentTime: any;

  constructor(
    private dataservice: DataService,
    private databaseService: DatabaseService,
    private logger: LogService,
    private user: UserService,
    private schedulePricing: SchedulePricingService
  ) {}
  getItemList() {
    return this.itemlist;
  }

  mod(n: number, m: number) {
    return ((n % m) + m) % m;
  }

  getLastFiveOrders() {
    return new Promise<ItemV2[]>(async (resolve, reject) => {
      if (this.itemlist.length) {
        resolve(this.itemlist);
        return;
      }
      this.dayOfWeek = this.mod(new Date().getDay() - 1, 7);
      this.currentTime = formatDate(Date.now(), 'HH:mm', 'en-US');
      this.Categories = this.databaseService.categorySubject.getValue();

      this.Categories = this.Categories.filter(
        (x: any) =>
          x.TimeActives[this.dayOfWeek] == 'False' ||
          (x.StartTimes[this.dayOfWeek] <= this.currentTime &&
            x.EndTimes[this.dayOfWeek] >= this.currentTime)
      );
      var id = GeneralSetting.getCustomerId();
      this.customerID = id == '' ? '-1' : id;
      if (
        this.customerID == '-1' &&
        this.user.punchhLoyaltyCustomer &&
        this.user.punchhLoyaltyCustomer.Phone
      ) {
        this.customerID = this.user.punchhLoyaltyCustomer.Phone;
      }
      if (this.OrderResponse != undefined) {
        this.orderItemMasters = this.OrderResponse.OrderItemMasters;
        this.orderItemModifierMaster =
          this.OrderResponse.OrderItemModifierMaster;
        if (this.orderItemMasters.length === 0) {
          this.noHistory = true;
          resolve(this.itemlist);
        }
        await this.orderItemMasters.sort((a: any, b: any) => {
          return b.OrderItemID - a.OrderItemID;
        });
        this.orderItemMasters = this.getdistictArray(this.orderItemMasters);
        this.orderItemMasters = this.comboCheck(this.orderItemMasters);
        this.itemdataProcessV2(this.orderItemMasters).then(async () => {
          if (this.itemlist.length > 5) {
            this.itemlist = this.itemlist.slice(0, 5);
          }
          resolve(this.itemlist);
        });
      } else {
        this.dataservice
          .getOrderHistoryByCustomerID(this.customerID)
          .toPromise()
          .then(
            (data: any) => {
              this.orderItemMasters = data.OrderItemMasters;
              this.orderItemModifierMaster = data.OrderItemModifierMaster;
              if (this.orderItemMasters.length === 0) {
                this.noHistory = true;
                resolve(this.itemlist);
              }
            },
            (response: any) => {
              var log = new loggingData(
                'LastFive Error',
                'Encountered Error on Fetching Customer History',
                `Error`,
                `Encountered Error With Customer ID ${this.customerID}:${response}`,
                true
              );
              this.logger.sendLogToServer(log);
            }
          )
          .finally(async () => {
            await this.orderItemMasters.sort((a: any, b: any) => {
              return b.OrderItemID - a.OrderItemID;
            });
            this.orderItemMasters = this.getdistictArray(this.orderItemMasters);
            this.orderItemMasters = this.comboCheck(this.orderItemMasters);
            this.itemdataProcessV2(this.orderItemMasters).then(async () => {
              if (this.itemlist.length > 5) {
                this.itemlist = this.itemlist.slice(0, 5);
              }              
              resolve(this.itemlist);
            });
          });
      }
    });
  }

  comboCheck(arr: any[]) {
    let tempArr = [];
    let newArray = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].ComboID != '0') {
        if (arr[i].ComboID === arr[i].ItemID) {
          arr[i].comboData = tempArr;
          newArray.push(arr[i]);
          tempArr = [];
        } else {
          tempArr.push(arr[i]);
        }
      } else {
        newArray.push(arr[i]);
      }
    }
    return newArray;
  }

  itemdataProcessV2 = async (orderItemMasters: any) => {
    let itemArr: ItemV2[] = [];
    for (var i = 0; i < orderItemMasters.length; i++) {
      if (this.itemlist.length < 5) {
        if (
          orderItemMasters[i].ComboID != '0' &&
          orderItemMasters[i].ComboID === orderItemMasters[i].ItemID
        ) {
          //parent combo container
          let data: any = await this.comboProcessor(orderItemMasters[i]);
          if (data && data.success) {
            this.itemlist.push(data.item);
          }
        } else {
          let data: any = await this.normalProcessor(orderItemMasters[i]);
          if (data && data.success) {
            data.item.ComboGroup = [];
            this.itemlist.push(data.item);
          }
        }
      }
    }
  };

  comboProcessor = async (previousItem: any) => {
    let a: any;
    await this.databaseService
      .getItemByItemIdFromSql(previousItem.ItemID)
      .then(async (itemdata: any) => {
        if (itemdata.length === 0) return;
        if (itemdata.length > 0) {
          itemdata[0].CategoryID = previousItem.CategoryID;
          itemdata[0] = await JSON.parse(JSON.stringify(itemdata[0]));
          if (itemdata[0].isSoldout) {
            return;
          }
          await this.newModifierDataProcessor(
            previousItem,
            this.orderItemModifierMaster
          ).then((data: any) => {
            if (data && data.success) {
              a = data;
            } else {
              return;
            }
          });
        }
      });
    return a;
  };

  normalProcessor = async (previousItem: any) => {
    let a: any;
    await this.databaseService
      .getItemByItemIdFromSql(previousItem.ItemID)
      .then(async (itemdata: any) => {
        if (itemdata.length === 0) return;
        if (itemdata.length > 0) {

          itemdata[0].CategoryID = previousItem.CategoryID;
          itemdata[0] = await JSON.parse(JSON.stringify(itemdata[0]));
          if (itemdata[0].isSoldout) {
            return;
          }
          
          await this.newModifierDataProcessor(
            previousItem,
            this.orderItemModifierMaster
          ).then((data: any) => {
            if (data && data.success) {
              a = data;
            } else {
              return;
            }
          });
        }
      });
    return a;
  };

  getdistictArray(orderItemMasters: any) {
    let uniqueIds = new Set();
    const list = [
      ...new Set(
        orderItemMasters.filter((obj: any) => {
          if (obj.ComboID != '0') {
            return obj;
          }
          if (!uniqueIds.has(obj.ItemID)) {
            uniqueIds.add(obj.ItemID);
            return obj;
          }
        })
      ),
    ];
    return list;
  }

  newModifierDataProcessor = async (
    currentOrderItemMasters: any,
    orderItemModifierMaster: any
  ) => {
    var modsarr: any[] = [];
    let modsname = '';
    var itemChosen = {} as ItemV2;
    let tempCat: any;

    let tempItem = {} as ItemV2;

    let tempItemList = await this.databaseService.getItemsByCategoryIdFromSql(
      currentOrderItemMasters.CategoryID,
      false,
      '',
      true,
      currentOrderItemMasters.ItemID,
      0
    );

    if (tempItemList.length > 0) {
      tempItem = tempItemList[0];
    }
    if (!tempItem || Object.entries(tempItem).length == 0) {
      return { tempItem };
    }

    itemChosen = tempItem;
    if (itemChosen.SoldOut) {
      return;
    }
    if (!itemChosen) return { itemChosen };

    itemChosen=(await this.schedulePricing.findAndApplySchedulePricing([itemChosen]))[0]
    if (
      itemChosen &&
      itemChosen.OrderMinimumQuantity &&
      itemChosen.OrderMinimumQuantity.toString().trim() != ''
    ) {
      itemChosen.Quantity = itemChosen.OrderMinimumQuantity.toString();
    } else if (itemChosen) {
      itemChosen.Quantity = '1';
    }

    if (
      itemChosen.ModifierCount &&
      itemChosen.ModifierCount === 0 &&
      itemChosen.IsCombo == false &&
      itemChosen.AttributeCount === 0
    ) {
      return { item: itemChosen, success: true };
    }

    if (itemChosen.IsCombo == false) {
      if (itemChosen.AttributeCount > 0) {
        itemChosen.Variations =
          await this.databaseService.getPersistentVariationsAsync(
            itemChosen.ItemID
          );

        for (
          var i = 0;
          i < itemChosen.Variations[0].variationOptions.length;
          i++
        ) {
          if (
            itemChosen.Variations[0].variationOptions[i]
              .ItemAttributeOptionID ===
            currentOrderItemMasters.ItemAttributeOptionID
          ) {
            itemChosen.Variations[0].variationOptions[i].isSelected = true;
            modsarr.push(
              modsname +
                itemChosen.Variations[0].variationOptions[i].OptionName +
                ', '
            );
          } else {
            itemChosen.Variations[0].variationOptions[i].isSelected = false;
          }
        }
      }

      if (
        itemChosen.ModifierCount &&
        itemChosen.ModifierCount > 0 &&
        orderItemModifierMaster.length
      ) {
        let tempItemModifer =
          await this.databaseService.getModifierByItemIDFromSql(
            itemChosen.ItemID
          );

        itemChosen.Modifiers =
          await this.databaseService.getAllModifierIngredients(tempItemModifer);

        for (let j = 0; j < orderItemModifierMaster.length; j++) {
          if (
            orderItemModifierMaster[j].OrderItemID ==
            currentOrderItemMasters.OrderItemID
          ) {
            for (let k = 0; k < itemChosen.Modifiers.length; k++) {
              for (
                let l = 0;
                l < itemChosen.Modifiers[k].Ingredients.length;
                l++
              ) {
                if (itemChosen.Modifiers[k].Ingredients[l].IsIngredient) {
                  if (
                    itemChosen.Modifiers[k].Ingredients[l].IngredientID ==
                      orderItemModifierMaster[j].IngredientID &&
                    itemChosen.Modifiers[k].ModifierID ==
                      orderItemModifierMaster[j].ModifierID
                  ) {
                    itemChosen.Modifiers[k].IsSelected = true;

                    if (itemChosen.Modifiers[k].Ingredients[l].IsModifier) {
                      let tempItemModifier = itemChosen.Modifiers[k]
                        .Ingredients[l] as ModifierV2;

                      if (
                        orderItemModifierMaster[j].IsNegativeModifier == 'True'
                      ) {
                        modsarr.push(
                          'no ' +
                            orderItemModifierMaster[j].IngredientName +
                            ', '
                        );

                        tempItemModifier.IsSelected = false;
                      } else {
                        itemChosen.Modifiers[k].Ingredients[l].IsSelected =
                          true;
                        modsarr.push(
                          orderItemModifierMaster[j].IngredientName + ', '
                        );
                      }
                    } else {
                      let tempItemIngredient = itemChosen.Modifiers[k]
                        .Ingredients[l] as ModifierIngredientV2;

                      if (
                        orderItemModifierMaster[j].IsNegativeModifier == 'True'
                      ) {
                        modsarr.push(
                          'no ' +
                            orderItemModifierMaster[j].IngredientName +
                            ', '
                        );

                        tempItemIngredient.IsSelected = false;
                        tempItemIngredient.IsNegativeModifier = 'True';
                        tempItemIngredient.isNegativeModifier = true;
                      } else {
                        tempItemIngredient.IsSelected = true;
                        tempItemIngredient.IsNegativeModifier = 'False';
                        tempItemIngredient.isNegativeModifier = false;
                        modsarr.push(
                          orderItemModifierMaster[j].IngredientName + ', '
                        );
                      }
                    }
                  }
                } else {
                  let apple = itemChosen.Modifiers[k].Ingredients[
                    l
                  ] as ModifierV2;

                  if (apple.Ingredients.length == 0) {
                    apple.IsForced =
                      (apple.IsModifier86 == 'False' && 'False') ||
                      apple.IsForced;
                    apple.isValid =
                      (apple.IsModifier86 == 'False' && true) || apple.isValid;

                    const ingredients =
                      await this.databaseService.getIngredientsByModIDFromSql(
                        apple.ModifierID
                      );
                    apple.Ingredients = ingredients;

                    let nestedModifiers =
                      await this.databaseService.getChildModifierByItemAndModifierID(
                        apple.ItemID,
                        apple.ModifierID
                      );

                    apple.Ingredients.push(...nestedModifiers);

                    apple.Ingredients = apple.Ingredients.sort(
                      (a: any, b: any) =>
                        Number(a.DisplayOrder) > Number(b.DisplayOrder) ? 1 : -1
                    );
                  }

                  for (let m = 0; m < apple.Ingredients.length; m++) {
                    let data: any = await this.nestedModCheck(
                      apple.Ingredients[m],
                      orderItemModifierMaster[j],
                      modsarr,
                      apple
                    );
                    if (data && data.res) {
                      itemChosen.Modifiers[k].IsSelected = true;
                    }
                  }
                }
              }
            }
          }
        }
      }

      itemChosen.last5modsName = modsarr.join('');
      return { item: itemChosen, success: true };
    } else {
      itemChosen.OrderItemID = currentOrderItemMasters.OrderItemID;

      if (!itemChosen.ComboGroup || itemChosen.ComboGroup.length == 0) {
        let tempComboGroups =
          await this.databaseService.getAllComboGroupsFromSQl(
            itemChosen.ItemID
          );

        itemChosen.ComboGroup =
          await this.databaseService.getAllComboGroupItems(
            tempComboGroups,
            itemChosen.ItemID
          );
      }
      if (
        currentOrderItemMasters.comboData &&
        currentOrderItemMasters.comboData.length
      ) {
        for (let i = 0; i < currentOrderItemMasters.comboData.length; i++) {
          for (let a = 0; a < itemChosen.ComboGroup.length; a++) {
            for (let b = 0; b < itemChosen.ComboGroup[a].Items.length; b++) {
              if (currentOrderItemMasters.comboData[i].checkedInLast5 == true) {
                continue;
              }
              if (
                itemChosen.ComboGroup[a].Items[b].ItemID ===
                  currentOrderItemMasters.comboData[i].ItemID &&
                itemChosen.ComboGroup[a].Items[b].checkedInLast5 != true &&
                itemChosen.ComboGroup[a].checkedInLast5 != true
              ) {
                currentOrderItemMasters.comboData[i].checkedInLast5 = true;
                let tempItemModifer =
                  await this.databaseService.getModifierByItemIDFromSql(
                    itemChosen.ComboGroup[a].Items[b].ItemID
                  );
                itemChosen.ComboGroup[a].Items[b].Modifiers =
                  await this.databaseService.getAllModifierIngredients(
                    tempItemModifer
                  );
                itemChosen.ComboGroup[a].Items[b].Quantity =
                  currentOrderItemMasters.comboData[i].ItemQuantity;
                itemChosen.ComboGroup[a].Items[b].isSelected = true;
                itemChosen.ComboGroup[a].count++;
                if (
                  itemChosen.ComboGroup[a].count == itemChosen.ComboGroup[a].max
                ) {
                  itemChosen.ComboGroup[a].checkedInLast5 = true;
                }
                itemChosen.ComboGroup[a].Items[b].checkedInLast5 = true;
                itemChosen.ComboGroup[a].Items[b].OrderItemID =
                  currentOrderItemMasters.comboData[i].OrderItemID;
                if (itemChosen.ComboGroup[a].Items[b].Name != undefined) {
                  if (
                    itemChosen.ComboGroup[a].Items[b].Name?.trim().length &&
                    itemChosen.ComboGroup[a].Items[b].nameAddedtoLast5 != true
                  ) {
                    modsarr.push(
                      itemChosen.ComboGroup[a].Items[b].Name?.toUpperCase() +
                        '-->'
                    );
                    itemChosen.ComboGroup[a].Items[b].nameAddedtoLast5 = true;
                  } else if (
                    itemChosen.ComboGroup[a].Items[b].Name?.trim().length &&
                    itemChosen.ComboGroup[a].Items[b].nameAddedtoLast5 != true
                  ) {
                    modsarr.push(
                      itemChosen.ComboGroup[a].Items[b].Name?.toUpperCase() +
                        '-->'
                    );
                    itemChosen.ComboGroup[a].Items[b].nameAddedtoLast5 = true;
                  } else if (
                    itemChosen.ComboGroup[a].Items[b].nameAddedtoLast5 != true
                  ) {
                    modsarr.push(
                      itemChosen.ComboGroup[a].Items[
                        b
                      ].KOTDisplayName.toUpperCase() + '-->'
                    );
                    itemChosen.ComboGroup[a].Items[b].nameAddedtoLast5 = true;
                  }
                }

                if (
                  currentOrderItemMasters.comboData[i].ItemAttributeID != '0'
                ) {
                  itemChosen.ComboGroup[a].Items[b].Variations =
                    await this.databaseService.getPersistentVariationsAsync(
                      itemChosen.ComboGroup[a].Items[b].ItemID
                    );
                  for (
                    let c = 0;
                    c <
                    itemChosen.ComboGroup[a].Items[b].Variations[0]
                      .variationOptions.length;
                    c++
                  ) {
                    if (
                      itemChosen.ComboGroup[a].Items[b].Variations[0]
                        .variationOptions[c].ItemAttributeOptionID ===
                      currentOrderItemMasters.comboData[i].ItemAttributeOptionID
                    ) {
                      itemChosen.ComboGroup[a].Items[
                        b
                      ].Variations[0].variationOptions[c].isSelected = true;
                      modsarr.push(
                        itemChosen.ComboGroup[a].Items[b].Variations[0]
                          .variationOptions[c].OptionName + ', '
                      );
                    }
                  }
                }
                for (let j = 0; j < orderItemModifierMaster.length; j++) {
                  if (
                    currentOrderItemMasters.comboData[i].OrderItemID ===
                    orderItemModifierMaster[j].OrderItemID
                  ) {
                    if (
                      itemChosen.ComboGroup[a].Items[b].OrderItemID ==
                      orderItemModifierMaster[j].OrderItemID
                    ) {
                      if (
                        itemChosen.ComboGroup[a].Items[b].Modifiers &&
                        itemChosen.ComboGroup[a].Items[b].Modifiers.length > 0
                      ) {
                        for (
                          let m = 0;
                          m <
                          itemChosen.ComboGroup[a].Items[b].Modifiers.length;
                          m++
                        ) {
                          for (
                            let n = 0;
                            n <
                            itemChosen.ComboGroup[a].Items[b].Modifiers[m]
                              .Ingredients.length;
                            n++
                          ) {
                            if (
                              itemChosen.ComboGroup[a].Items[b].Modifiers[m]
                                .Ingredients[n].IsIngredient
                            ) {
                              if (
                                itemChosen.ComboGroup[a].Items[b].Modifiers[m]
                                  .Ingredients[n].IngredientID ==
                                  orderItemModifierMaster[j].IngredientID &&
                                itemChosen.ComboGroup[a].Items[b].Modifiers[m]
                                  .ModifierID ==
                                  orderItemModifierMaster[j].ModifierID
                              ) {
                                itemChosen.ComboGroup[a].Items[b].Modifiers[
                                  m
                                ].IsSelected = true;

                                let tempIng = itemChosen.ComboGroup[a].Items[b]
                                  .Modifiers[m].Ingredients[
                                  n
                                ] as ModifierIngredientV2;

                                if (
                                  orderItemModifierMaster[j]
                                    .IsNegativeModifier == 'True'
                                ) {
                                  tempIng.IsNegativeModifier = 'True';
                                  modsarr.push(
                                    'no ' +
                                      orderItemModifierMaster[j]
                                        .IngredientName +
                                      ', '
                                  );
                                  tempIng.IsSelected = false;
                                } else {
                                  tempIng.IsNegativeModifier = 'False';
                                  modsarr.push(
                                    orderItemModifierMaster[j].IngredientName +
                                      ', '
                                  );
                                  tempIng.IsSelected = true;
                                }
                                break;
                              }
                            } else {
                              let tempNestedModifier = itemChosen.ComboGroup[a]
                                .Items[b].Modifiers[m].Ingredients[
                                n
                              ] as ModifierV2;

                              if (tempNestedModifier.Ingredients.length == 0) {
                                tempNestedModifier.IsForced =
                                  (tempNestedModifier.IsModifier86 == 'False' &&
                                    'False') ||
                                  tempNestedModifier.IsForced;
                                tempNestedModifier.isValid =
                                  (tempNestedModifier.IsModifier86 == 'False' &&
                                    true) ||
                                  tempNestedModifier.isValid;

                                const ingredients =
                                  await this.databaseService.getIngredientsByModIDFromSql(
                                    tempNestedModifier.ModifierID
                                  );
                                tempNestedModifier.Ingredients = ingredients;

                                let nestedModifiers =
                                  await this.databaseService.getChildModifierByItemAndModifierID(
                                    tempNestedModifier.ItemID,
                                    tempNestedModifier.ModifierID
                                  );

                                tempNestedModifier.Ingredients.push(
                                  ...nestedModifiers
                                );

                                tempNestedModifier.Ingredients =
                                  tempNestedModifier.Ingredients.sort(
                                    (a: any, b: any) =>
                                      Number(a.DisplayOrder) >
                                      Number(b.DisplayOrder)
                                        ? 1
                                        : -1
                                  );
                              }

                              for (
                                let m = 0;
                                m < tempNestedModifier.Ingredients.length;
                                m++
                              ) {
                                let data: any = await this.nestedModCheck(
                                  tempNestedModifier.Ingredients[m],
                                  orderItemModifierMaster[j],
                                  modsarr,
                                  tempNestedModifier
                                );
                                if (data && data.res) {
                                  itemChosen.ComboGroup[a].Items[b].Modifiers[
                                    m
                                  ].IsSelected = true;
                                }
                              }
                            }
                          }
                        }
                      }
                      break;
                    }
                  }
                }
                break;
              }
            }
          }
        }
      }
      itemChosen.last5modsName = modsarr.join('');
      return { item: itemChosen, success: true };
    }
  };

  async nestedModCheck(
    searchable: any,
    searchTarget: any,
    modsarr: string[],
    searchableParent: any
  ) {
    if (searchable.IsIngredient) {
      if (
        searchable.IngredientID == searchTarget.IngredientID &&
        searchable.ModifierID == searchTarget.ModifierID
      ) {
        searchableParent.IsSelected = true;
        if (searchTarget.IsNegativeModifier == 'True') {
          searchable.IsSelected = false;
          searchable.IsNegativeModifier = 'True';
          searchable.isNegativeModifier = true;
          modsarr.push('no ' + searchTarget.IngredientName + ', ');
          return { modsarr: modsarr, res: true };
        } else {
          searchable.IsSelected = true;
          searchable.IsNegativeModifier = 'False';
          searchable.isNegativeModifier = false;
          modsarr.push(searchTarget.IngredientName + ', ');
          return { modsarr: modsarr, res: true };
        }
      } else {
        return { modsarr: modsarr, res: false };
      }
    } else {
      let tempModifier = searchable as ModifierV2;

      if (tempModifier.Ingredients.length == 0) {
        tempModifier.IsForced =
          (tempModifier.IsModifier86 == 'False' && 'False') ||
          tempModifier.IsForced;
        tempModifier.isValid =
          (tempModifier.IsModifier86 == 'False' && true) ||
          tempModifier.isValid;

        const ingredients =
          await this.databaseService.getIngredientsByModIDFromSql(
            tempModifier.ModifierID
          );
        tempModifier.Ingredients = ingredients;

        let nestedModifiers =
          await this.databaseService.getChildModifierByItemAndModifierID(
            tempModifier.ItemID,
            tempModifier.ModifierID
          );

        tempModifier.Ingredients.push(...nestedModifiers);

        tempModifier.Ingredients = tempModifier.Ingredients.sort(
          (a: any, b: any) =>
            Number(a.DisplayOrder) > Number(b.DisplayOrder) ? 1 : -1
        );
      }

      for (let i = 0; i < tempModifier.Ingredients.length; i++) {
        let data1: any = await this.nestedModCheck(
          tempModifier.Ingredients[i],
          searchTarget,
          modsarr,
          tempModifier
        );
        if (data1.res) {
          searchableParent.IsSelected = true;
          tempModifier.IsSelected = true;
          return data1;
        } else {
          return data1;
        }
      }
    }
  }

  increaseNumofItems(item: any) {
    if (Number(item.OrderMinimumQuantity) === 0) {
      this.numofItemsAdded++;
    } else {
      this.numofItemsAdded += Number(item.OrderMinimumQuantity);
    }
  }

  decreaseNumofItems(item: any) {
    if (Number(item.OrderMinimumQuantity) === 0) {
      this.numofItemsAdded--;
    } else {
      this.numofItemsAdded -= Number(item.OrderMinimumQuantity);
    }
  }

  updateitemList(itemID: string, variations: any, modifiers: any) {
    var index = this.itemlist.findIndex((z) => z.ItemID == itemID);
    this.itemlist[index].isSelected = true;
    this.increaseNumofItems(this.itemlist[index]);
  }
  OrderResponse: any;
  clearhistoryData() {
    this.OrderResponse = undefined;
    this.itemlist = [];
  }
}
