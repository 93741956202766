<div role="tabpanel" class="tab-pane" id="paymentsettings">
  <!--Payment Setting-->
  <h2>{{ paymentSettingsText }}</h2>
  <div class="row mb-3rem">
    <div class="col-md-6 col-6">{{ paymentGatewayText }}</div>
    <div class="col-md-6 col-6 text-right" id="thankYouMessage">
      {{ cardPayment }}
    </div>
  </div>
  <div class="row mb-3rem">
    <div class="col-md-6 col-6">{{ connectionTypeText }}</div>
    <div class="col-md-6 col-6 text-right" id="thankYouMessage">
      {{ tcpIPText }}
    </div>
  </div>
  <div class="row mb-3rem">
    <div class="col-md-6 col-6">{{ deviceHsnText }}</div>
    <div class="col-md-6 col-6 text-right" id="deviceHsn">{{ hsn }}</div>
  </div>
  <div class="row mb-3rem">
    <div class="col-md-6 col-6">{{ merchantIdText }}</div>
    <div class="col-md-6 col-6 text-right" id="merchantId">
      {{ merchantId }}
    </div>
  </div>
  <div class="row mb-3rem">
    <div class="col-md-6 col-6">{{ allowCashText }}</div>
    <div class="col-md-6 col-6 text-left">
      <div class="switch_box">
        <input
          type="checkbox"
          class="switch_1"
          [checked]="boxchecked1"
          (change)="onChecked($event, 1)"
        />
      </div>
    </div>
  </div>
  <div class="row mb-3rem">
    <div class="col-md-6 col-6">{{ roundUpTotalText }}</div>
    <div class="col-md-6 col-6 text-left">
      <div class="switch_box">
        <input
          type="checkbox"
          class="switch_1"
          [checked]="boxchecked2"
          (change)="onChecked($event, 2)"
        />
      </div>
    </div>
  </div>

  <br />
  <!--  <div class=" row mb-3rem">
      <div class="col-md-6 col-6">Payment Gateway:</div>
      <div class="col-md-6 col-6 text-left">
          <input type="radio" id="cardconnect" name="gateway" value="cardconnect" >
          <label for="cardconnect">Cardconnect</label><br>
          <input type="radio" id="payanywhere" name="gateway" value="PayAnywhere">
          <label for="payanywhere">PayAnywhere</label><br>
          <input type="radio" id="elavc" name="gateway" value="Elavc">
          <label for="elavc">Elavc</label></div>
  </div> -->
  <!--Payment Setting-->
  <!-- <div class=" row mb-3rem">
      <table class="table table-borderless border-white border-radius-10">
          <thead>
            <tr>
              <th scope="col">Tax Name</th>
              <th scope="col">Percentage</th>
            </tr>
          </thead>
          <tbody>
            <div *ngFor="let taxes of TaxSchema | keyvalue" class="tax-item">
              <tr>
                <td scope="row">{{taxes.key}}</td>
                <td *ngFor="let tax of taxes.value">
                  {{tax[0]}} {{tax[1]}}%
                </td>
              </tr>
           </div>

          </tbody>
        </table>
  </div> -->

  <div
    class="row mb-3rem"
    style="display: flex; font-weight: 600; padding: 1rem"
    *ngIf="TaxSchema && TaxSchema.size > 0"
  >
    <ul>
      <li
        style="list-style: none"
        *ngFor="let taxes of TaxSchema | keyvalue"
        class="tax-item"
      >
        {{ taxes.key }}
        <ul>
          <li
            style="list-style: none; font-weight: 400"
            *ngFor="let tax of taxes.value"
          >
            - {{ tax[0] }} ({{ tax[1] | number: "1.2-2" }}%)
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <!-- <div class=" row mb-3rem">
    <div class="col-md-6 col-6"> Show Tip Dialog:</div>
    <div class="col-md-6 col-6 text-right">
      <div class="switch_box">
        <input type="checkbox" class="switch_1">
      </div>
    </div>
  </div>
  <div class=" row mb-3rem">
    <div class="col-md-6 col-6">Auto Close Modifier Row:</div>
    <div class="col-md-6 col-6 text-left">
      <div class="switch_box">
        <input type="checkbox" class="switch_1">
      </div></div>
  </div>
  <div class=" row mb-3rem">
    <div class="col-md-6 col-6">Use Loyalty Redeem:</div>
    <div class="col-md-6 col-6 text-left">
      <div class="switch_box">
        <input type="checkbox" class="switch_1">
      </div>
    </div>
  </div>
  <div class=" row mb-3rem">
    <div class="col-md-6 col-6">Show Calorie Count:</div>
    <div class="col-md-6 col-6 text-left">
      <div class="switch_box">
        <input type="checkbox" class="switch_1">
      </div>
    </div>
  </div>

  <div class=" row mb-3rem">
    <div class="col-md-6 col-6">Open Item As:</div>
    <div class="col-md-6 col-6 text-left">
      <input type="radio" id="popup" name="open" value="Popup Up">
      <label for="tcpip">Popup</label><br>
      <input type="radio" id="screen" name="open" value="Screen">
      <label for="usb">Screen</label>
    </div>
  </div>-->

  <!-- <div class="row mb-3rem" *ngIf="showVerifoneButton">
    <div class="col-md-12 col-12 text-left">
      <div class="btn2">
        <button (click)="onVerifoneRegisterClick()">
          <i class="fa fa-sync"></i> {{registerVerifoneText}}
        </button>
      </div>
    </div>
  </div> -->
  <div class="row mb-3rem" class="setting-payment-button">
    <button (click)="pairFlex()" class="kiosk-setting-button">
      <i class="fa fa-sync"></i> {{pairFlexText}}
    </button>
  </div>
</div>
