
export class ItemTagFilterMasters {
  ItemTagGroupID: string = '';
  ItemTagGroupName: string = '';
  FilterItemTagGroupMapping : ItemTagGroupMappings[] = [];
}


export class ItemTagGroupMappings {
  FilterID: string = '';
  FilterName: string = '';
  isSelected: boolean = false;
}
