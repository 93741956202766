import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IUpdateModalService } from 'src/app/models/IUpdateModelService';
import { DownloadingPopupComponent } from './downloading-popup.component';

@Injectable({
  providedIn: 'root'
})
export class DownloadingPopupService implements IUpdateModalService {

  constructor(private modalService:NgbModal) 
  { }

  private downloadingPopupRef:DownloadingPopupComponent | null = null;

  public open() {
    this.close();

    this.downloadingPopupRef = DownloadingPopupComponent.open(this.modalService).componentInstance;
  }

  public close(){
    if(this.downloadingPopupRef && this.downloadingPopupRef != null){
      this.downloadingPopupRef.clear();
    }
  }

  public setMessage(message:string){
    if(this.downloadingPopupRef && this.downloadingPopupRef != null){
      this.downloadingPopupRef.setCurrentStateMessage(message);
    }
  }

  public setSecondMessage(message:string){
    if(this.downloadingPopupRef && this.downloadingPopupRef != null){
      this.downloadingPopupRef.setIndexText(message);
    }
  }

}
