import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CurrencyPipe } from 'src/app/CustomTools/currency.pipe';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-payment-total-row',
  templateUrl: './payment-total-row.component.html',
  styleUrls: ['./payment-total-row.component.css']
})
export class PaymentTotalRowComponent implements OnInit, OnDestroy {
  @Input() total!:number
  @Input() subtotal!:number
  @Input() tax!:number
  @Input() discount!:number
  @Input() reward!:number
  @Input() shipping!:number
  @Input() tip!:number
  
  totalText:string=''
  subtotalText:string=''
  tipText:string=''
  taxesAndFeesText:string = ''
  taxText:string=''
  discountText:string=''
  rewardText:string=''
  shippingFeeText:string=''
  collapsed:boolean=true
  languageSub!:Subscription
  constructor(private language:LanguageService) { }
ngOnDestroy(): void {
    if(this.languageSub){
      this.languageSub.unsubscribe()
    }
}
loadText(){
  this.totalText=this.language.getTextElement('text_total')
  this.subtotalText=this.language.getTextElement('lbl_subtotal')
  this.tipText=this.language.getTextElement('lbl_tip')
  this.taxesAndFeesText=this.language.getTextElement('lbl_taxes_and_fees')
  this.taxText=this.language.getTextElement('lbl_tax')
  this.discountText=this.language.getTextElement('lbl_discount')
  this.rewardText=this.language.getTextElement('reward')
  this.shippingFeeText=this.language.getTextElement('lbl_shipping_fee')
}
  ngOnInit(): void {
    this.languageSub=this.language.localeCommunicator.subscribe((val)=>{
      this.loadText()
    })
  }
  swap(){
    this.collapsed=!this.collapsed
  }
}
