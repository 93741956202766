<section class="review-heading">
    <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
        <h3>{{item.Name}}</h3>
        <a class="close-btn" (click)="cancel('Add_Remaining')">
            <!-- <img src="images/close icon.png" /> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="none">
                <rect x="1" y="1" width="68" height="68" rx="19" fill="#fff" [ngStyle]="{stroke:getAccentColor()}"
                    stroke-width="2" />
                <path
                    d="m36.312 36.105 8.478 8.478-.207.207-8.478-8.478L35 35.207l-1.105 1.105-8.478 8.478-.207-.207 8.478-8.478L34.793 35l-1.105-1.105-8.478-8.478.207-.207 8.478 8.478L35 34.793l1.105-1.105 8.478-8.478.207.207-8.478 8.478L35.207 35l1.105 1.105z"
                    [ngStyle]="{stroke:getAccentColor()}" stroke-width="3.125" />
            </svg>
            <!-- <img src="../../../../assets/images/box-builder/close icon.png" /> -->

        </a>
    </div>
</section>
<section class="review-list">
    <!--Item 1-->
    <div *ngIf="comboItemFlags">
        <div *ngFor="let item of selectedItemList; index as i">
            <div class="d-flex flex-row justify-content-between align-items-center each-item" *ngIf="item.isSelected">
                <img src="{{(item.ImageUrl && item.ImageUrl !='')
                                ? baseImageUrl+item.ImageUrl
                                : placeholderImage != '' 
                                    ? baseImageUrl+placeholderImage 
                                    : 'assets/images/Image_21.png'}}"
                    onerror="this.onerror=null; this.src='assets/images/Image_21.png'" alt="item-image" />
                <div class="qty-btn">
                    <button class="btn btn-minus opacity-5" (click)="decreaseQuantity(item)"> <i class="material-icons fas fa-minus"></i></button>
                    <div>{{item.ItemConsolidateQuantity}} </div>
                    <button class="btn btn-plus" (click)="increaseQuantity(item)"><i class="material-icons fas fa-plus"></i></button>

                </div>
                <div class="info">
                    <h4>{{item.Name}}</h4>
                    <h5 class="description">{{item.FullDescription}}</h5>
                </div>

                <button class="btn btn-edit" (click)="editItemV2(item, i ,false )"
                    [ngStyle]="{'visibility':item.Modifiers && item.Modifiers.length>0? 'visible':'hidden'}">
                    {{editText}}
                </button>
                <div class="delete-icon"><i class="material-icons fas fas fa-trash" (click)="deleteItem(item)"></i></div>
            </div>
        </div>
    </div>



</section>
<section class="footer-review">
    <button *ngIf="item.ComboGroup.length-(numInvisibleGroups+selectedCount)>0" class="btn btn-outline"
        (click)="cancel('Add_Remaining')">{{addRemainingAText}}
        {{item.ComboGroup.length-(numInvisibleGroups+selectedCount)}}{{addRemainingBText}}</button>
    <button class="btn btn-checkout" [disabled]="item.ComboGroup.length-(numInvisibleGroups+selectedCount)>0"
        [ngClass]="(item.ComboGroup.length-(numInvisibleGroups+selectedCount)>0)?'disabled-btn':''"
        data-bs-toggle="modal" data-bs-target="#review-popup" (click)="addToCart()">{{addToCartText}}</button>
</section>

<!-- Modal -->
<div class="modal fade" id="review-popup" tabindex="-1" aria-labelledby="review-popup" aria-hidden="true">
    <div class="modal-dialog customize-donut  modal-dialog-centered">
        <div class="modal-content">

            <a type="button" data-bs-dismiss="modal" aria-label="Close" class="close-btn">
                <img src="../../../../assets/images/box-builder/close-icon-o.png" class="img-fluid" alt="X" /></a>
            <div class="modal-body">

                <div class="content1">
                    {{selectedText}}<br />12{{donutsText}}
                </div>

            </div>
            <div class="modal-footer">
                <button class="btn btn-add active" id="btn-box">{{reviewBoxText}}</button>
            </div>

        </div>
    </div>
</div>