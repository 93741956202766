<div role="tabpanel" class="tab-pane" id="refresh-data">
    <!--Loading Menu-->
    <div class="loading-overlay">
        <div class="loading-div">
            <h2 class="fas fa-spinner fa-3x fa-spin"></h2><br/>
            <div class="clearfix"></div>
            <h3>{{refreshingText}}</h3>
        </div>
    </div>
    <div class="margin-center">
        <button id="load-button" class="green-btn" (click)="doRefresh()">{{refreshText}}</button>
        <p>{{lastSyncText}} {{dataDate}}</p>
    </div>
      <!--End Loading Menu-->
</div>
