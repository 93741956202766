import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-out-of-stock',
  templateUrl: './out-of-stock.component.html',
  styleUrls: ['./out-of-stock.component.css'],
})
export class OutOfStockComponent implements OnInit,OnDestroy {
  positiveButtonTextColor: string | null = '';
  errorMsg: string = "";
  modalReference: any;
  languageSub!:Subscription
  uhOhText:string=''
  closeText:string=''
  constructor(private modalService: NgbModal,private language:LanguageService) {}
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText(){
    this.uhOhText=this.language.getTextElement("lbl_uh_oh")
    this.closeText=this.language.getTextElement("close")
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.positiveButtonTextColor = GeneralSetting.getPositiveColorText()
  }

  public static open(modalService: NgbModal,language:LanguageService, errorCode: number) {
    let errorMsg = "";

    if (errorCode === 1) {
      errorMsg = language.getTextElement('txt_you_cannot_add_this_item_to_cart')
    } else {
      errorMsg = language.getTextElement('msg_out_of_stock_item')
    }

    const modalReference = modalService.open(OutOfStockComponent, { centered: true, size: 'lg' });
    modalReference.componentInstance.errorMsg = errorMsg;
    modalReference.componentInstance.modalReference = modalReference;
  
    return modalReference;
  }

  open(errorCode: number) {
    if (errorCode === 1) {
      this.errorMsg = this.language.getTextElement('txt_you_cannot_add_this_item_to_cart')
    } else {
      this.errorMsg = this.language.getTextElement('msg_out_of_stock_item')
    }

    const modalReference = this.modalService.open(OutOfStockComponent, { centered: true, size: 'lg' });
    modalReference.componentInstance.errorMsg = this.errorMsg;
    modalReference.componentInstance.modalReference = modalReference;
    this.modalReference = modalReference;
    return modalReference;
  }

  close() {
    if (this.modalReference) this.modalReference.close('cancel');
  }
}
