import { NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { RouterModule, Routes } from '@angular/router';
import { BranchLoginComponent } from './components/branch-login/branch-login.component';
import { ItemIndividualComponent } from './components/item-individual/item-individual.component';
import { ManagerPasscodeComponent } from './components/manager-passcode/manager-passcode.component';
import { OrderReviewComponent } from './components/order-review/order-review.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TouchToStartComponent } from './components/touch-to-start/touch-to-start.component';
import { LastFiveOrderComponent } from './components/last-five-order/last-five-order.component';
import { ItemNormalModeComponent } from './components/item-normal-mode/item-normal-mode.component';
import { ItemBuilderModeComponent } from './components/item-builder-mode/item-builder-mode.component';
import { BoxBuilderComponent } from './components/box-builder/box-builder.component';
import { BoxBuilderItemDetail } from './components/box-builder-item/box-builder-item-detail/box-builder-item-detail.component';
import { BoxBuilderReviewComponent } from './components/box-builder/box-builder-review/box-builder-item-review.component';
import { MenuComponent } from './components/menu/menu.component';
import { ConcessionaireGridComponent } from './components/concessionaire-grid/concessionaire-grid.component';
import { SearchScannableItemComponent } from './components/search-scannable-item/search-scannable-item.component';
import { LevelUpModalComponent } from './components/dialogs/level-up-modal/level-up-modal.component';
import { LoginGuardService } from './services/login-guard.service';
import { NetworkGuardService } from './services/network-guard.service';
import { NetworkErrorPageComponent } from './components/network-error-page/network-error-page.component';
import { MasterGuardService } from './services/masterguard.service';
export const routes: Routes = [
  {
    path: '',
    component: BranchLoginComponent,
    data: { animation: 'branchLogin' },
    canActivate: [MasterGuardService],
  },
  {
    path:'networkerror',
    component:NetworkErrorPageComponent
  },
  {
    path: 'passcode',
    component: ManagerPasscodeComponent,
    data: { animation: 'managerPasscode' },
  },
  {
    path: 'touchToStart',
    component: TouchToStartComponent,
    data: { animation: 'touchToStart' },
  },
  {
    path: 'menu',
    component: MenuComponent,
    data: { animation: 'menu' },
  },
  {
    path: 'orderReview',
    component: OrderReviewComponent,
    data: { animation: 'orderReview' },
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { animation: 'settings' },
  },
  {
    path: 'itemDescription',
    component: ItemIndividualComponent,
    data: { animation: 'itemIndividual' },
  },
  {
    path: 'settings/logout',
    component: SettingsComponent,
  },
  {
    path: 'lastfiveorder',
    component: LastFiveOrderComponent,
  },
  {
    path: 'ItemNormalMode',
    component: ItemNormalModeComponent,
  },
  {
    path: 'ItemBuilderMode',
    component: ItemBuilderModeComponent,
  },
  {
    path: 'ItemBoxBuilderMode',
    component: BoxBuilderComponent,
  },
  {
    path: 'buildCustomItem',
    component: BoxBuilderItemDetail,
  },
  {
    path: 'ReviewCustomBox',
    component: BoxBuilderReviewComponent,
  },
  {
    path: 'ConcessionaireGrid',
    component: ConcessionaireGridComponent,
  },
  {
    path: 'SearchScannable',
    component: SearchScannableItemComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [MasterGuardService,
    LoginGuardService,
    NetworkGuardService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
  ],
})
export class AppRoutingModule {}
