<section>
  <div class="scan" role="document">
    <div class="payment-modal">
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close('cancel')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <!-- <h2 class="text-center">{{ title }}</h2> -->
     
      <span [ngClass]="paymentType == '1' ? 'title' : 'subTitle'">{{
        subTitle
      }}</span>      
      <div *ngIf="showPayment">
      <app-payment-total-row
        [total]="total"
        [subtotal]="subtotal"
        [discount]="discount"
        [tax]="tax"
        [shipping]="shipping"
        [reward]="reward"
      ></app-payment-total-row>
      </div>
      <input
        id="barcode"
        type="text"
        hideKeyboard
        class="cdk-visually-hidden"
        [formControl]="barcodeInput"
      />

      <h4 *ngIf="paymentType == '1'" class="text-center">
      {{holdBarcodeText}}
      </h4>
      <div class="col-md-12 col-xs-12 p-0 insert-img">
        <span *ngIf="paymentType == '1'">
          <img
            src="../../../../assets/images/PayWithUSConnect.gif"
            onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
          />
        </span>
        <span *ngIf="paymentType == '2'">
          <img
            src="../../../../assets/images/RFID_tap.gif"
            onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
          />
        </span>
      </div>
    </div>
  </div>
</section>
