import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { DatabaseHandler } from '../DatabaseHandler';
import { itemwise } from '../models/itemwise';
import { LogService } from 'src/app/services/log.service';

@Injectable({
  providedIn: 'root',
})
export class ItemwiseSalesService {
  constructor(private logger: LogService) {}

  getTotalItems(date: string): Observable<string> {
    return from(this.getTotalItemsFromSql(date));
  }

  getTotalItemsFromSql(date: string) {
    const dateSplit = date.split(' ');

    return new Promise<string>((resolve: any, reject: any) => {
      var itemsQuery = `
      SELECT sum(OIM.ItemQuantity) as Item
      FROM OrderItemMasters as OIM
      INNER JOIN OrderMasters as OM
      ON OIM.OrderId = OM.OrderId
      WHERE OM.CreatedDate LIKE '${dateSplit[0]}% ${dateSplit[1]}% ${dateSplit[2]}%'
      `;

      const errorCallback = (statement: any, error: any) => {

        console.log('Item wise sales Service , Error getTotalItemsFromSql :- ', error);

        reject(error);
      };

      const setItems = (tx: string, results: any) => {
        // var data : order[] = Array.from(results.rows);

        resolve(results.rows[0]);
      };

      DatabaseHandler.executeSqlStatement(
        itemsQuery,
        [],
        setItems,
        errorCallback
      );
    });
  }

  getItemReport(date: string): Observable<itemwise[]> {
    return from(this.getItemReportFromSql(date));
  }

  getItemReportFromSql(date: string) {
    const dateSplit = date.split(' ');

    return new Promise<itemwise[]>((resolve: any, reject: any) => {
      var reportQuery = `
      SELECT OIM.ItemName, sum(OIM.ItemQuantity) as Quantity, OIM.ItemPrice, OIM.ItemID, * FROM OrderItemMasters as OIM INNER JOIN OrderMasters as OM ON OIM.OrderId = OM.OrderId  WHERE OM.CreatedDate LIKE '${dateSplit[0]}% ${dateSplit[1]}% ${dateSplit[2]}%' GROUP BY OIM.ItemID ORDER BY Quantity DESC`;

      const errorCallback = (statement: any, error: any) => {
        console.log('Item wise sales Service , Error getItemReportFromSql :- ', error);

        reject(error);
      };

      const setItems = (tx: string, results: any) => {
        var data: itemwise[] = Array.from(results.rows);

        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        reportQuery,
        [],
        setItems,
        errorCallback
      );
    });
  }
}
