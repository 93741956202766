<div class="download">
  <div class="loading-gif">
    <img src="assets/images/loading/grubbrr_menu_refresh_animation.gif" onerror="this.onerror=null; this.src='assets/images/Image_21.png'"/>
  </div>
  <h1 class="loading-text">
    {{ currentStateText }}
  </h1>
  <p class="index">
    {{ indexText }}
  </p>
  <p class="path">
    {{path}}
  </p>
</div>
