<section>
  <div class="payment-insert" role="document">
    <div class="payment-modal">
      <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()"  >
            <span aria-hidden="true">&times;</span>
        </button> -->

      <h2 class="text-center">{{ isRefund ? refundText : checkoutText }}</h2>
      <h3 class="text-center">
        {{ isRefund ? refundCardText : payWithCardText }}
      </h3>
      <div *ngIf="showPayment">      
        <app-payment-total-row
        [total]="total"
        [subtotal]="subtotal"
        [tip]="tip"
        [discount]="discount"
        [tax]="tax"
        [shipping]="shipping"
        [reward]="reward"
        ></app-payment-total-row>
      </div>
      <h4 class="text-center">
        {{followInstructionsText}}
      </h4>
      <div class="col-md-12 col-xs-12 p-0 insert-img">
        <!-- <img src="../../../../assets/images/verifone.gif" onerror="this.onerror=null; this.src='assets/images/Image_21.png'"/> -->

        <img *ngIf="isVerifone"
             [attr.src]="['../../../../assets/images/verifone.gif']"
             onerror="this.onerror=null; this.src='assets/images/Image_21.png'" />
        <img *ngIf="!isVerifone"
             [attr.src]="[
            isClover
              ? '../../../../assets/images/Clover-insert.gif '
              : '../../../../assets/images/Dejavoo-taporinsert.gif '
          ]"
             onerror="this.onerror=null; this.src='assets/images/Image_21.png'" />

      </div>

      <div class="col-md-12 col-xs-12 text-center" *ngIf="isCancel && isVerifone">
        <button type="button" class="text-center negative-btn" (click)="onCancelClick()">
          <span aria-hidden="true">{{cancelText}}</span>
        </button>
      </div>

    </div>
  </div>
</section>
