import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  
  ngOnDestroy() {
    if(this.timeOut) clearTimeout(this.timeOut);
  }

  ref: any;

  modalReference: any;

  isDialogOpen: boolean = false;

  isTimeOut:boolean = false;

  timeOutMiliSec:number = 5000;

  private timeOut:any = null;
 
  constructor(private modalService: NgbModal) {

  }

  ngOnInit(): void {

    if (this.isTimeOut) {
     this.timeOut = setTimeout(() => {
        this.close();
      },this.timeOutMiliSec);
    }
  }

  public static open(modalService:NgbModal,isDialogOpen:boolean = false, isTimeOut:boolean = false,timeOutMiliSec:number = 5000) {

    isDialogOpen = true;

    let ref = modalService.open(LoadingComponent, {
      backdrop: 'static',
      centered: true,
      size: 'sm',
      modalDialogClass: "loadingComponent"
    });

    // console.log('open')
    ref.componentInstance.ref = ref;
    ref.componentInstance.isDialogOpen = isDialogOpen;
    ref.componentInstance.isTimeOut = isTimeOut;
    ref.componentInstance.timeOutMiliSec = timeOutMiliSec;

    return ref;
  }

  close() {
    if(this.timeOut) clearTimeout(this.timeOut);
    this.isDialogOpen = false;
    this.ref.close();
  }

}
