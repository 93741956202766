<div id="emailreceipt-popup" role="dialog">
  <!-- <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="canceled()"
    >
      <span aria-hidden="true">&times;</span>
    </button> -->
  <div class="header">
    <h2 class="text-center dark-title">{{ emailReceiptText }}</h2>
  </div>

  <div class="caption text-center">
    <p>{{ emailReceiptMessageText }}.</p>
  </div>

  <div class="middle-image">
    <img
      src="assets/images/ic_email_receipt.png"
      onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
      class="img-responsive"
    />
  </div>

  <form [formGroup]="emailform" novalidate>
    <div class="align-items-center text-center input-box">
      <label>{{ emailText }}</label>
      <input
        KeyboardCustom
        [readonly]="isKiosk"
        id="emailInputter"
        name="emailInputter"
        type="text"
        class="form-control"
        formControlName="email"
        [(ngModel)]="userEmail"
        value="{{ userEmail }}"
        [ngStyle]="{
          borderColor: submitted && f.email.errors ? 'red' : '#4169E1'
        }"
        style="height: 10rem; width: 45rem"
      />
      <button
        matRipple
        type="button"
        (click)="onemailsubmit()"
        class="btn"
        id="submitEmail"
        [disabled]="isValid"
      >
        <i class="fas fa-check"></i>
      </button>
    </div>

    <div *ngIf="submitted && f.email.errors">
      <div *ngIf="f.email.errors.required" class="error">
        <p>{{ emailRequiredText }}</p>
      </div>
      <div *ngIf="f.email.errors.email" class="error">
        <p>{{ emailInvalidText }}</p>
      </div>
    </div>
    <div *ngIf="isValid" class="spinner-border m-5" role="status"></div>
  </form>

  <div *ngIf="!isValid" style="display: flex; justify-content: center;align-self: stretch;">
    <div
      matRipple
      appDebounceClick
      class="cancel"
      (debounceClick)="dismissModal()"
      [ngStyle]="{
        'background-color': negativeButton,
        border: negativeButton,
        color: negativeButtonText,
        height: '5rem',
        'padding-top': '8px',
        'border-radius': '1rem'
      }"
    >
      <div class="centeredText" style="width:200px;">{{ cancelText }}</div>
    </div>
  </div>
</div>
