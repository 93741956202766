import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { loggingData, LogService } from 'src/app/services/log.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralSetting } from '../../../services/generalSetting.service';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import {
  ShippingService,
  DomesticShippingDetails,
  InternationalShippingDetails,
} from 'src/app/services/shipping.service';
import x from './shippingCountryList.json';
import y from './shippingStateList.json';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-shipping-modal',
  templateUrl: './shipping-modal.component.html',
  styleUrls: ['./shipping-modal.component.css'],
})
export class ShippingModalComponent implements OnInit,OnDestroy {
  formContact!: FormGroup;
  formAddress!: FormGroup;
  domesticFee: string = GeneralSetting.getDomesticShippingFee();
  internationalFee: string = GeneralSetting.getInternationalShippingFee();
  Name: string = '';
  AddressTwo: string = '';
  City: string = '';
  Code: string = '';
  State: string = '';
  ZipCode: string = '';
  Country: string = '';
  Phone: string = '';
  isContactForm = false;
  get name() {
    return this.formContact.get('name');
  }
  get email() {
    return this.formContact.get('email');
  }
  get phone() {
    return this.formContact.get('phone');
  }
  get code() {
    return this.formContact.get('code');
  }
  get addressOne() {
    return this.formAddress.get('addressOne');
  }
  get addressTwo() {
    return this.formAddress.get('addressTwo');
  }
  get city() {
    return this.formAddress.get('city');
  }
  get state() {
    return this.formAddress.get('state');
  }
  get zipCode() {
    return this.formAddress.get('zipCode');
  }
  get country() {
    return this.formAddress.get('country');
  }
  get address() {
    return this.formAddress.get('address');
  }
  get postCode() {
    return this.formAddress.get('postCode');
  }

  @ViewChild('nameforshipping')
  nameforshippingElement!: ElementRef;
  
  modalService: NgbModal;
  modalReference: any;
  isInvalid: boolean = false;
  isDomestic: boolean = true;
  placeHolderNameInfoHint: string = '';
  placeHolderAddressOneInfoHint: string = '';
  placeHolderPhoneInfoHint: string = '';
  placeHolderCodeInfoHint: string = '';
  placeHolderEmailInfoHint: string = '';
  placeHolderAddressTwoInfoHint: string ='';
  placeHolderAddressInfoHint: string = '';
  placeHolderCityInfoHint: string = '';
  placeHolderStateInfoHint: string = '';
  placeHolderZipCodeInfoHint: string = '';
  filteredCountryList: any;
  filteredStateList: any;
  filteredCityList: any;
  focusCountry = false;
  focusState = false;
  focusCity = false;
  countryList;
  stateList: any[] = y;
  cityList: any[] = [];
  selectedCountry: any;
  selectedState: any;
  selectedCity: any;
  isKiosk = this.isKioskService.isKiosk();
  checkoutText:string=''
  nameText:string=''
  phoneNumberText:string=''
  emailText:string=''
  nextText:string=''
  backText:string=''
  addressText:string=''
  submitText:string=''
  nameRequiredText:string=''
  emailRequiredText:string=''
  contactDetailsText:string=''
  domesticText:string=''
  internationalText:string=''
  countryCodeText:string=''
  codeRequiredText:string=''
  addShippingAddressText:string=''
  addressLineOneText:string=''
  addressRequiredText:string=''
  addressLineTwoText:string=''
  countryText:string=''
  typeYourCountryText:string=''
  countryRequiredText:string=''
  stateText:string=''
  typeStateText:string=''
  stateRequiredText:string=''
  zipCodeText:string=''
  zipRequiredText:string=''
  postCodeText:string=''
  postCodeRequiredText:string=''
  cityText:string=''
  typeCityText:string=''
  cityRequiredText:string=''
  domesticFeeText:string=''
  internationalFeeText:string=''
  phoneRequiredText:string=''
  emailInvalidText:string=''
  languageSub!:Subscription
  constructor(
    modalService: NgbModal,
    private formBuilder: FormBuilder,
    private shippingService: ShippingService,
    private logger: LogService,
    private KeyboardService: KeyboardService,
    private readonly isKioskService: IsKioskService,
    private language:LanguageService
  ) {
    this.modalService = modalService;
    this.countryList = x;
  }
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {
    this.placeHolderNameInfoHint = this.language.getTextElement('enter_name');
    this.placeHolderAddressOneInfoHint = this.language.getTextElement('street_name');
    this.placeHolderPhoneInfoHint = this.language.getTextElement('hint_phone_number');
    this.placeHolderCodeInfoHint = this.language.getTextElement('hint_area_code');
    this.placeHolderEmailInfoHint = this.language.getTextElement('enter_your_email');
    this.placeHolderAddressTwoInfoHint = this.language.getTextElement('hint_address_two');
    this.placeHolderAddressInfoHint = this.language.getTextElement('hint_address');
    this.placeHolderEmailInfoHint = this.language.getTextElement('enter_your_email');
    this.nameText = this.language.getTextElement('hint_name');
    this.checkoutText = this.language.getTextElement('lbl_checkout');
    this.phoneNumberText = this.language.getTextElement('hint_phone_number');
    this.emailText = this.language.getTextElement('email');
    this.nextText = this.language.getTextElement('next');
    this.backText = this.language.getTextElement('back');
    this.addressText = this.language.getTextElement('address');
    this.submitText = this.language.getTextElement('lbl_submit');
    this.nameRequiredText=this.language.getTextElement('name_is_required')
    this.emailRequiredText=this.language.getTextElement('email_is_required')
    this.contactDetailsText=this.language.getTextElement('add_your_contact_details')
    this.domesticText=this.language.getTextElement('domestic')
    this.internationalText=this.language.getTextElement('international')
    this.countryCodeText=this.language.getTextElement('country_code')
    this.codeRequiredText=this.language.getTextElement('code_is_required')
    this.addShippingAddressText=this.language.getTextElement('add_your_shipping_address')
    this.addressLineOneText=this.language.getTextElement('address_line_1')
    this.addressRequiredText=this.language.getTextElement('address_is_required')
    this.addressLineTwoText=this.language.getTextElement('address_line_2')
    this.countryText=this.language.getTextElement('country')
    this.typeYourCountryText=this.language.getTextElement('type_your_country')
    this.countryRequiredText=this.language.getTextElement('country_is_required')
    this.stateText=this.language.getTextElement('state')
    this.typeStateText=this.language.getTextElement('type_your_state')
    this.stateRequiredText=this.language.getTextElement('state_is_required')
    this.zipCodeText=this.language.getTextElement('zip_code')
    this.zipRequiredText=this.language.getTextElement('zip_code_is_required')
    this.postCodeText=this.language.getTextElement('post_code')
    this.postCodeRequiredText=this.language.getTextElement('post_code_is_required')
    this.cityText=this.language.getTextElement('city')
    this.typeCityText=this.language.getTextElement('type_your_city')
    this.cityRequiredText=this.language.getTextElement('city_is_required')
    this.domesticFeeText=this.language.getTextElement('a_fee_of_per_item_will_be_applied_to_domestic_orders').replace('%d',GeneralSetting.getCurrencySymbol()+this.domesticFee)
    this.domesticFeeText=this.language.getTextElement('a_fee_of_per_item_will_be_applied_to_international_orders').replace('%d',GeneralSetting.getCurrencySymbol()+this.internationalFee)
    this.phoneRequiredText=this.language.getTextElement('phone_required')
    this.emailInvalidText=this.language.getTextElement('email_invalid')
  }
  ngOnInit(): void {
    this.countryList = x;

    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });

    this.formContact = this.formBuilder.group({
      name: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl(''),
      code: new FormControl(''),
    });
    this.formAddress = this.formBuilder.group({
      state: new FormControl(''),
      addressOne: new FormControl(''),
      addressTwo: new FormControl(''),
      city: new FormControl(''),
      zipCode: new FormControl(''),
      postCode: new FormControl(''),
      address: new FormControl(''),
      country: new FormControl(''),
    });
    this.formContact.controls['name'].setValidators([Validators.required]);
    this.formContact.controls['phone'].setValidators([Validators.required]);
    this.formContact.controls['email'].setValidators([
      Validators.required,
      Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,3}$'),
    ]);
    this.formAddress.controls['addressOne'].setValidators([
      Validators.required,
    ]);
    this.formAddress.controls['city'].setValidators([Validators.required]);
    this.formAddress.controls['state'].setValidators([Validators.required]);
    this.formAddress.controls['zipCode'].setValidators([
      Validators.required,
      //Validators.pattern('^((\\+91-?)|0)?[0-9]{5}$'),
    ]);
    if(this.shippingService.shippingDetails instanceof DomesticShippingDetails){
      this.isDomestic=true
      this.name?.setValue(this.shippingService.shippingDetails.Name)
      this.phone?.setValue(this.shippingService.shippingDetails.Phone)
      this.Phone=this.shippingService.shippingDetails.Phone
      this.email?.setValue(this.shippingService.shippingDetails.Email)
      this.state?.setValue(this.shippingService.shippingDetails.State)
      this.State=this.shippingService.shippingDetails.State
      this.city?.setValue(this.shippingService.shippingDetails.City)
      this.City=this.shippingService.shippingDetails.City
      this.selectedCity=this.shippingService.shippingDetails.selectedCity
      this.selectedState=this.shippingService.shippingDetails.selectedState
      this.addressOne?.setValue(this.shippingService.shippingDetails.AddressOne)
      this.addressTwo?.setValue(this.shippingService.shippingDetails.AddressTwo)
      this.zipCode?.setValue(this.shippingService.shippingDetails.ZipCode)
    } else if(this.shippingService.shippingDetails instanceof InternationalShippingDetails){
      this.isDomestic=false
      this.addressOne?.clearValidators()
      this.addressOne?.updateValueAndValidity()
      this.name?.setValue(this.shippingService.shippingDetails.Name)
      this.city?.clearValidators()
      this.city?.updateValueAndValidity()
      this.code?.setValue(this.shippingService.shippingDetails.MobileCode)
      this.Code=this.shippingService.shippingDetails.MobileCode
      this.phone?.setValue(this.shippingService.shippingDetails.Phone)
      this.Phone=this.shippingService.shippingDetails.Phone
      this.address?.setValue(this.shippingService.shippingDetails.Address)
      this.postCode?.setValue(this.shippingService.shippingDetails.PostCode)
      this.zipCode?.clearValidators()
      this.zipCode?.updateValueAndValidity()
      this.email?.setValue(this.shippingService.shippingDetails.Email)
      this.state?.setValue(this.shippingService.shippingDetails.State)
      this.State=this.shippingService.shippingDetails.State
      this.selectedState=this.shippingService.shippingDetails.selectedState
      this.selectedCountry=this.shippingService.shippingDetails.selectedCountry
      this.country?.setValue(this.shippingService.shippingDetails.Country)
      this.Country=this.shippingService.shippingDetails.Country
    }
  }

  ngAfterViewInit() {
    this.KeyboardPopup(); 
  }
  // add click event on first input field @jay
  KeyboardPopup(){
    let nameforshippingEle: HTMLElement = this.nameforshippingElement.nativeElement as HTMLElement;
    nameforshippingEle.click();   
  } 

  onCountryFocus() {
    this.focusCountry = true;
    this.focusState = false;
    this.focusCity = false;
  }
  onStateFocus() {
    this.focusState = true;
    this.focusCountry = false;
    this.focusCity = false;
  }
  onOutsideFocus() {
    this.focusState = false;
    this.focusCountry = false;
    this.focusCity = false;
  }
  onCityFocus() {
    this.focusCity = true;
    this.focusCountry = false;
    this.focusState = false;
  }

  public static open(modalService:NgbModal) {
    let modalReference = modalService.open(ShippingModalComponent, {
      backdrop: 'static',
      size: 'lg',
      modalDialogClass:'absolutePos25'
    });
    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }

  open(): void {
    this.modalReference = this.modalService.open(ShippingModalComponent, {
      backdrop: 'static',
      size: 'lg',
      modalDialogClass:'absolutePos25'
    });
    this.modalReference.componentInstance.modalReference = this.modalReference;
    this.modalReference.componentInstance.countryList = this.countryList;
  }
  
  onCountryChange() {
    this.filteredCountryList = this.countryList.filter((x) =>
      x.CountryName.toLowerCase().includes(this.Country.toLowerCase())
    );
  }
  onStateChange() {
    this.filteredStateList = this.stateList.filter((x) => {
      return x.StateName.toLowerCase().includes(this.State.toLowerCase());
    });
  }

  onCityChange() {
    this.filteredCityList = this.cityList.filter((x) =>
      x.CityName.toLowerCase().includes(this.City.toLowerCase())
    );
  }

  onPhoneChange() {}

  onCodeChange() {}

  async chooseCountry(item: any) {
    this.selectedCountry = item;
    this.Country = item.CountryName;
    this.focusCountry = false;
    this.KeyboardService.KeyboardStatus = false;
    this.shippingService.getStates(item.CountryID).then(
      (a: any) => {
        if (a.statusCode == '200') {
          this.stateList = a.data;
        }
      },
      (a: any) => {
        //error fetching states
        var log = new loggingData(
          'Shipping Modal Fetch Error',
          'Failed to Fetch State Data',
          `API Failure`,
          `Error Encountered Fetching State Data: ${JSON.stringify(a)}`,
          true
        );
        this.logger.sendLogToServer(log);
      }
    );
  }
  chooseState(item: any) {
    this.selectedState = item;
    this.State = item.StateName;
    this.focusState = false;
    this.KeyboardService.KeyboardStatus = false;

    this.shippingService.getCities(item.StateID).then(
      (a: any) => {
        if (a.statusCode == '200') {
          this.cityList = a.data;
        }
      },
      (a: any) => {
        //error fetching cities
        var log = new loggingData(
          'Shipping Modal Fetch Error',
          'Failed to Fetch City Data',
          `API Failure`,
          `Error Encountered Fetching State Data: ${JSON.stringify(a)}`,
          true
        );
        this.logger.sendLogToServer(log);
      }
    );
  }
  chooseCity(item: any) {
    this.selectedCity = item;
    this.City = item.CityName;
    this.focusCity = false;
    this.KeyboardService.KeyboardStatus = false;
  }
  setInternational() {
    if (this.isDomestic) {
      this.selectedState = null;
      this.selectedCity = null;
      this.State = '';
      this.City = '';
      this.stateList = [];
      this.filteredStateList = [];
      this.focusState = false;
      this.focusCountry = false;
      this.focusCity = false;
      this.isInvalid = false;
      this.isDomestic = false;
      this.ZipCode = ''; 
      this.formAddress.controls['address'].setValidators([Validators.required]);
      this.formAddress.controls['address'].updateValueAndValidity();
      this.formAddress.controls['country'].setValidators([Validators.required]);
      this.formAddress.controls['country'].updateValueAndValidity();
      this.formContact.controls['code'].setValidators([Validators.required]);
      this.formContact.controls['code'].updateValueAndValidity();
      this.formAddress.controls['postCode'].setValidators([
        Validators.required,
      ]);
      this.formAddress.controls['postCode'].updateValueAndValidity();
      this.formAddress.controls['city'].clearValidators();
      this.formAddress.controls['city'].updateValueAndValidity();
      this.formAddress.controls['zipCode'].clearValidators();
      this.formAddress.controls['zipCode'].updateValueAndValidity();
      this.formAddress.controls['addressOne'].clearValidators();
      this.formAddress.controls['addressOne'].updateValueAndValidity();
    }
  }
  setDomestic() {
    if (!this.isDomestic) {
      this.stateList = y;
      this.filteredStateList = this.stateList;
      this.selectedState == null;
      this.State = '';
      this.focusState = false;
      this.focusCountry = false;
      this.focusCity = false;
      this.isInvalid = false;
      this.isDomestic = true;
      this.formAddress.controls['addressOne'].setValidators([
        Validators.required,
      ]);
      this.formAddress.controls['city'].setValidators([Validators.required]);
      this.formAddress.controls['state'].setValidators([Validators.required]);
      this.formAddress.controls['zipCode'].setValidators([
        Validators.required,
        //Validators.pattern('^((\\+91-?)|0)?[0-9]{5}$'),
      ]);
      this.formAddress.controls['address'].clearValidators();
      this.formAddress.controls['address'].updateValueAndValidity();
      this.formAddress.controls['postCode'].clearValidators();
      this.formAddress.controls['postCode'].updateValueAndValidity();
      this.formContact.controls['code'].clearValidators();
      this.formContact.controls['code'].updateValueAndValidity();
      this.formAddress.controls['country'].clearValidators();
      this.formAddress.controls['country'].updateValueAndValidity();
    }
  }
  toContact(){
    this.isContactForm=false
  }
  cancel() {
    this.KeyboardService.KeyboardStatus = false;
    this.modalReference.close('canceled');
  }
  details: any={};

  onContactSubmit() {
    if (this.formContact.status == 'VALID') {
      this.KeyboardService.KeyboardStatus = false;
      this.isInvalid = false
      this.isContactForm=true
    } else {
      this.isInvalid = true;
    }
  }
  onSubmit() {
    if (
      this.formAddress.status == 'VALID'
    ) {
      let details;
      if (this.isDomestic) {
        if (this.selectedState && this.selectedCity) {
          this.KeyboardService.KeyboardStatus = false;
          details = new DomesticShippingDetails(
            this.name!.value,
            this.phone!.value,
            this.email!.value,
            this.selectedState,
            this.selectedCity,
            this.addressOne!.value,
            this.zipCode!.value,
            GeneralSetting.getDomesticShippingFee(),
            this.addressTwo!.value
          );
        }
      } else {
        if (this.selectedCountry && this.selectedState) {
          this.KeyboardService.KeyboardStatus = false;
          details = new InternationalShippingDetails(
            this.name!.value,
            this.phone!.value,
            this.email!.value,
            this.selectedState,
            this.selectedCountry,
            this.address!.value,
            this.postCode!.value,
            GeneralSetting.getInternationalShippingFee(),
            this.code!.value
          );
        }
      }
      this.shippingService.shippingDetails = details;
      this.modalReference.close('success');
    } else {
      this.isInvalid = true;
    }
  }
}
