import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-item-switch-warning',
  templateUrl: './item-switch-warning.component.html',
  styleUrls: ['./item-switch-warning.component.css'],
})
export class ItemSwitchWarningComponent implements OnInit,OnDestroy {
  modalRef!: NgbModalRef;
languageSub!:Subscription
attentionText:string=''
alreadyCustomizedText:string=''
confirmationText:string=''
cancelText:string=''
startNewItemText:string=''
  constructor(private language:LanguageService) {}
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText(){
    this.attentionText=this.language.getTextElement('txt_attention')
    this.alreadyCustomizedText=this.language.getTextElement('txt_you_have_already_customised_an_item')
    this.confirmationText=this.language.getTextElement('txt_are_you_sure_you_want_to_clear_it_and_start_a_new_item')
    this.cancelText=this.language.getTextElement('lbl_button_cancel')
    this.startNewItemText=this.language.getTextElement('txt_start_new_item')
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
    this.loadText();
  });}

  public static open(modalService:NgbModal,){
    const itemSwitchRef = modalService.open(ItemSwitchWarningComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    itemSwitchRef.componentInstance.modalRef = itemSwitchRef;
    return itemSwitchRef;
  }

  start() {
    this.modalRef.close('start');
  }

  cancel() {
    this.modalRef.close('cancel');
  }
}
