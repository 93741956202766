import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-upsell-combo-message',
  templateUrl: './upsell-combo-message.component.html',
  styleUrls: ['./upsell-combo-message.component.css'],
})
export class UpsellComboMessageComponent implements OnInit, OnDestroy {
  
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  modalReference: any;

  languageSub!: Subscription;

  noThanksText: string = '';

  yesPleaseText: string = '';
  customItemText:string=''
  wantToCustomizeText:string=''
  constructor(
    private modalService: NgbModal,
    private language: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  loadText() {
    this.noThanksText = this.language.getTextElement('lbl_no_thanks');
    this.yesPleaseText = this.language.getTextElement('txt_yes_please');
    this.customItemText = this.language.getTextElement('customizable_item');
    this.wantToCustomizeText = this.language.getTextElement('want_to_customize_item');
  }

  public static open(modalService: NgbModal) {
    let modalReference = modalService.open(UpsellComboMessageComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });
    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }

  open() {
    this.modalReference = this.modalService.open(UpsellComboMessageComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });
    this.modalReference.componentInstance.modalReference = this.modalReference;
  }

  close() {
    this.modalReference.close('close');
  }

  continue() {
    this.modalReference.close('continue');
  }
  
}
