<form #loginForm="ngForm">
  <div class="form-group">
    <div class="input-group login-userinput b-bottom-1-light-gray">
      <span class="input-group-addon bg-none b-0"
        ><span class="far fa-user"></span
      ></span>
      <input
        KeyboardCustom
        layout="default"
        customLength="350"
        [readonly]="isKiosk"
        name="username"
        class="form-control bg-none b-0 b-shadow-0 pl-0"
        [(ngModel)]="username"
        id="name-text"
        placeholder="{{ userText }}"
        rows="10"
      />
    </div>
  </div>
  <div class="form-group">
    <div class="input-group b-bottom-1-light-gray">
      <span class="input-group-addon bg-none b-0"
        ><span class="fas fa-lock"></span
      ></span>
      <input
        KeyboardCustom
        customLength="350"
        placeholder="{{ passwordText }}"
        [type]="!isShown ? 'password' : 'text'"
        name="password"
        [readonly]="isKiosk"
        class="form-control bg-none b-0 b-shadow-0 pl-0"
        [(ngModel)]="password"
        id="txtPassword"
        rows="10"
      />

      <span id="showPassword" class="input-group-btn bg-none b-0 b-shadow-0">
        <button
          class="btn btn-default reveal bg-none b-0 b-shadow-0"
          type="button"
        >
          <span
            [ngClass]="!isShown ? 'far fa-eye-slash' : 'far fa-eye'"
            (click)="revealPassword()"
          ></span>
        </button>
      </span>
    </div>
  </div>
  <div>
    <div class="position-relative">
      <div style="height: 15rem" *ngIf="!isModalOpen">
        <button
          *ngIf="!isLoading"
          appDebounceClick
          (debounceClick)="doLogin()"
          [debounceTime]="500"
          type="submit"
          id="login-button"
          class="btn btn-primary btn-block login-button"
        >
          {{ loginText }}
        </button>
      </div>
    </div>
  </div>
</form>
<!-- <button class="btn btn-primary btn-block login-button" (click)="startScanner()">Scan QR</button> -->
<div class="page-loader" *ngIf="isLoading">
  <img
    src="/assets/images/loading/refresh_loading.gif"
    class="loading-spinner"
  />
</div>

<div class="details">
  <p>{{ ipAddressText }} {{ ipAddress || "N/A" }}</p>
  <p>{{ versionNameText }} {{ version }}</p>
  <p>{{ appIDText }} {{ deviceID || "N/A" }}</p>
  <p>{{ environmentText }} {{ currentEnvironmentText }}</p>
</div>

<div id="snackbar">{{ invalidText }}</div>
<app-connection-loss-popup [hidden]="true"></app-connection-loss-popup>
<app-downloading-popup [hidden]="true"></app-downloading-popup>
<app-ask-configure-your-device [hidden]="true"></app-ask-configure-your-device>
<app-ask-device-name [hidden]="true"></app-ask-device-name>
