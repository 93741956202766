<div id="alcoholicitems">
  <div class="modal-body alcoholicitems-modal">
    <div class='header-part'>
      <div *ngIf="model_status==2" class="d-flex justify-content-center" style="margin-top: 5rem;">
        <div class="spinner-border"
          style="width: 15rem; height: 15rem;border-width: 10px;margin-top: 7rem;margin-bottom: 7rem;" role="status">
          <span class="sr-only">{{loadingText}}...</span>
        </div>
      </div>

      <img *ngIf="model_status==1 || model_status==3" src="../../../../assets/images/beer.png"
        onerror="this.onerror=null; this.src='assets/images/Image_21.png';" />

      <h1 class="text-center">{{titleText}}</h1>
      <h4 *ngIf="model_status==1 || model_status==3" class="text-center">{{subTitleText}}</h4>
    </div>
    <div class="content">


      <div class="col-12 col-md-12 popup-footer text-center">
        <button matRipple class="green-btn text-uppercase" type="submit" name="submits"
          (click)="onVerifyClick()">{{positiveButtonText}}</button>
        <br />
        <button matRipple class="white-btn text-uppercase" (click)="cancelModal()">{{negativeButtonText}}</button>
      </div>
    </div>
  </div>
</div>