import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { GeneralSetting } from '../../../services/generalSetting.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.css'],
})
export class PromoCodeComponent implements OnInit,OnDestroy {
  submitted = false;
  promoform!: FormGroup;
  modalReference: NgbModalRef = {} as NgbModalRef;
  promoInput: string = '';
  positiveColor: string | null = GeneralSetting.getPositiveColor();
  negativeColor: string | null = GeneralSetting.getNegativeColor();
  positiveTextColor: string | null = GeneralSetting.getPositiveColorText();
  negativeTextColor: string | null = GeneralSetting.getNegativeColorText();
  isKiosk = this.isKioskService.isKiosk();
  languageSub!: Subscription;
  promoCodeText: string = '';
  enterPromoCodeText: string = '';
  cancelText: string = '';
  applyCodeText: string = '';
  keySub!:Subscription
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {
    this.promoCodeText = this.language.getTextElement('txt_promo_code');
    this.enterPromoCodeText = this.language.getTextElement(
      'txt_please_enter_promo_code'
    );
    this.cancelText = this.language.getTextElement('lbl_button_cancel');
    this.applyCodeText=this.language.getTextElement('apply_code')
  }
  constructor(
    private modal: NgbModal,
    private KeyboardService: KeyboardService,
    private readonly isKioskService: IsKioskService,
    private formBuilder: FormBuilder,
    private language: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.promoform = this.formBuilder.group({
      promoCode: ['', Validators.required],
    });
  }
  get f() {
    return this.promoform.controls;
  }
  open() {
    this.modalReference = this.modal.open(PromoCodeComponent, {
      size: 'lg',
      centered: true,
    });
    this.modalReference.componentInstance.modalReference = this.modalReference;
    return this.modalReference;
  }

  doit2(){
    if(this.keySub){
      this.keySub.unsubscribe()
    }
    this.KeyboardService.newBoardSubject.next()
  }

  
  // used to open keyboard based on dialog required layout, maxlength and assign value to dialog/screen input field @jay
  doit(){
    this.KeyboardService.manual=true
    this.KeyboardService.maxLength=50
    this.KeyboardService.clearSub.next()
    this.KeyboardService.open("shift");
    this.KeyboardService.stringSub.next()
    this.keySub=this.KeyboardService.stringSub.subscribe((val)=>{
      this.handlepromoInputChange(val);
    })
  }
  // to handle dialog/screen input field value as a first character and character after "." upercase
  handlepromoInputChange(val:string){
    this.modalReference.componentInstance.promoInput=val
    if(this.modalReference.componentInstance.promoInput == "" || (this.modalReference.componentInstance.promoInput).trim().slice(-1) == "."){
      this.KeyboardService.handleupperlowercase.next(true)
    }else{
      this.KeyboardService.handleupperlowercase.next(false)
    }
  }

  apply() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.promoform.invalid) {
      return;
    }
    this.KeyboardService.KeyboardStatus = false;
    this.modalReference.close(this.promoInput);
  }

  cancel() {
    this.KeyboardService.KeyboardStatus = false;
    this.modalReference.close('canceled');
  }
}
