<ng-container *ngIf="item">
  <main class="meulist" *ngIf="category">
    <div class="row vertical-center border-black" [ngStyle]="{ 'background-color': bkgdColor }" *ngIf="!isConcessionaire">
      <div class="first-row .no-border col-md-3 col-xs-3 p-1rem bg-white">
        <img src="{{ baseImageUrl + branchLogo }}" onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
          class="col-md-8 col-md-offset-2 col-xs-8 col-xs-offset-2" />
      </div>

      <div class="second-row col-md-8 col-xs-8 p-1rem">
        <p class="white category-name">
          {{ category.DisplayName | uppercase }}
        </p>
      </div>
    </div>

    <div class="cons-header"  *ngIf="isConcessionaire"
    style="background-image : url({{ concessionaireHeaderImage | functionCall : setHeaderImage : baseImageUrl }})" [style.background-color]="bkgdColor">
        <div class="company-logo">
            <img *ngIf="!isShowBranchLogo" src="{{baseImageUrl + branchLogo}}" onerror="this.src='assets/images/Image_21.png'" class="img-fluid" alt="logo" />
        </div>
    </div>

    <div class="indiv-item-container">
      <div class="col-md-12 col-xs-12 text-center bmargin6 individual-item">
        <h3 id="ItemDescription">{{ category.DisplayName | uppercase }}</h3>
        <h2 id="ItemName" class="tmargin4 accent-color">
          {{ item.Name }}
        </h2>
        <h4 id="ItemPrice" [ngStyle]="{ color: priceColor }" *ngIf="!isRemovePricingAndPayments">{{ item.Price |
          CurrencyPipe }}
          <span *ngIf="item.EnablePricebyWeight && item.EnablePricebyWeight == 'True'">
            <span [ngSwitch]="item.WeightUnitID">
              <span *ngSwitchCase="0">{{perOzText}}</span>
              <span *ngSwitchCase="1">{{perPoundText}}</span>
              <span *ngSwitchCase="2">{{perGramText}}</span>
              <span *ngSwitchCase="3">{{perKilogramText}}</span>
              <span *ngSwitchDefault></span>
            </span>
          </span>
        </h4>
        <img id="ItemImage" src="{{ item.ImageUrl && item.ImageUrl !='' ? baseImageUrl + item.ImageUrl : placeholderImage !='' ? baseImageUrl+placeholderImage : 'assets/images/Image_21.png'}}" 
          onerror="this.src='assets/images/Image_21.png'"
          class="col-md-10 col-md-offset-1 col-xs-10 col-xs-offset-1" alt=""
          [ngStyle]="{'border-radius': bordersTypeItem ? imageRadius : '0%'}" />
      </div>

      <div class="row itemTagsContainer">
        <div *ngFor="let image of item.IconImages" class="col itemTagsDiv">
          <img *ngIf="image != null && image != ''" src="{{ image && image !='' ? baseImageUrl + image : placeholderImage !='' ? baseImageUrl+placeholderImage : 'assets/images/Image_21.png' }}"
            onerror="this.onerror=null; this.src='assets/images/Image_21.png'" class="col-md-3" />
        </div>
      </div>
      <div class="productDescription">
        <h2 *ngIf="item.FullDescription.length > 0" [ngStyle]="{ color: bkgdColor }">
          {{productDescriptionText}}
        </h2>
        <p>{{ item.FullDescription }}</p>
      </div>
      <div class="col-md-12 col-xs-12 individual-item-qty">
        <div *ngIf="!(item.EnablePricebyWeight && item.EnablePricebyWeight == 'True'); else weightLayout">
          <h2><span class="font-size6">{{quantityText}}</span></h2>
          <div class="num-block skin-4">
            <div class="num-in">
              <input id="QtyBox" type="text" class="in-num bpad10 tpad4 bold" value="{{ quantity }}"
                [(ngModel)]="quantity" disabled style="background-color: white" />
              <div *ngIf="!item.IsLoyaltyDiscountItem" class="all-span">
                <span id="QtyBoxPlus" class="plus" (click)="increaseQuantity()">
                  <img src="../../../assets/images/plus.svg"
                    onerror="this.onerror=null; this.src='assets/images/Image_21.png'" />
                </span>
                <span id="QtyBoxMinus" class="minus dis" (click)="decreaseQuantity()">
                  <img src="../../../assets/images/minus.svg" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <ng-template #weightLayout>
          <div class="weight-info">
            <h2><span class="font-size6">{{weightText|uppercase}}</span></h2>
            <p>{{itemWeightText}}
              <span [ngStyle]="{'color': positiveButton}">{{item.Weight}}
                <span [ngSwitch]="item.WeightUnitID">
                  <span *ngSwitchCase="0">{{ozText}}</span>
                  <span *ngSwitchCase="1">{{poundText}}</span>
                  <span *ngSwitchCase="2">{{gramText}}</span>
                  <span *ngSwitchCase="3">{{kilogramText}}</span>
                  <span *ngSwitchDefault></span>
                </span>
              </span>
            </p>
            <p>{{totalPriceText}} <span [ngStyle]="{'color': positiveButton}">{{ ((item.Price | numberConvert) *
                item.Weight) | number: "1.2-2" | CurrencyPipe }}</span></p>
            <div class="text-center">

              <div *ngIf="isgettingData" class="spinner-border" style="width: 10rem; height: 10rem; border-width: 1rem;"
                role="status" [ngStyle]="{
               color: positiveButton
               }">
                <span class="sr-only">{{loadingText}}</span>
              </div>

              <button *ngIf="!isgettingData" class="reCalculateButton" matRipple (click)="onRecalculate()" [ngStyle]="{
            'background-color': positiveButton,
            border: positiveButton,
            color: positiveButtonText
          }">
                {{recalculateText}}
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <app-item-footer class="footer" [isReady]="true" [isComboItem]="false" [itemID]="item.ItemID"
      [categoryID]="category.CategoryID" [isModifiersValid]="false" [isVariationsValid]="false"
      [isComboGroupValid]="false" [inputItem]="item" [quantityWhenAdd]="quantity" [cartQuantity]="cartQuantity"
      [individualMode]="this">
    </app-item-footer>
  </main>

</ng-container>
<app-timeout style="display: none" [hidden]="true"></app-timeout>