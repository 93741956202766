import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ItemV2 } from 'src/app/models/item';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-olo-punchh-reward-invalidation-popup',
  templateUrl: './olo-punchh-reward-invalidation-popup.component.html',
  styleUrls: ['./olo-punchh-reward-invalidation-popup.component.css'],
})
export class OloPunchhRewardInvalidationPopupComponent implements OnInit,OnDestroy {

  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  languageSub!: Subscription;

  item: ItemV2 = {} as ItemV2;
  
  case: number = 0;
  
  modalReference: any;
  
  cancelText: string = '';
  
  okayText: string = '';
  
  uhOhText: string = '';
  
  makeChangeAnywaysText: string = '';
  
  changeIncompatibleText:string=''
  
  constructor(private readonly modalService: NgbModal, private language:LanguageService) {}

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  loadText(){
    this.cancelText=this.language.getTextElement('lbl_button_cancel')
    this.okayText=this.language.getTextElement('lbl_ok')
    this.uhOhText=this.language.getTextElement('lbl_uh_oh')
    this.makeChangeAnywaysText=this.language.getTextElement('do_you_want_to_make_the_change_anyways')
    this.changeIncompatibleText=this.language.getTextElement('the_requested_change_is_incompatible_with_your_reward')
  }

  public static open(modalService:NgbModal,item: ItemV2, c: number) {
    const modalReference = modalService.open(
      OloPunchhRewardInvalidationPopupComponent,
      {
        backdrop: 'static',
        size: 'lg',
        centered: true,
      }
    );

    modalReference.componentInstance.item = item;
    modalReference.componentInstance.case = c;
    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }

  open(item: ItemV2, c: number) {
    const modalReference = this.modalService.open(
      OloPunchhRewardInvalidationPopupComponent,
      {
        backdrop: 'static',
        size: 'lg',
        centered: true,
      }
    );
    modalReference.componentInstance.item = item;
    this.item = item;
    modalReference.componentInstance.case = c;
    this.case = c;
    modalReference.componentInstance.modalReference = modalReference;
    this.modalReference = modalReference;
    return modalReference;
  }

  close(message: string) {
    this.modalReference.close(message);
  }

}
