import { catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { ItemV2, ModifierIngredientV2, ModifierV2 } from 'src/app/models/item';
import { Variation, VariationOption } from './../models/Variations';
import { DatabaseService } from 'src/app/services/database.service';
import { forEach } from 'lodash';
import { Injectable } from '@angular/core';
import { rejects } from 'assert';
import { from, Observable, throwError } from 'rxjs';
import { DatabaseHandler } from '../DatabaseHandler';
import { order } from '../models/order';
import {
  OrderItemMasterReq,
  OrderItemModifierGroupMasterReq,
  OrderItemModifierMasterReq,
} from '../models/OrderItemMasterReq';
import { LogService, loggingData } from '../services/log.service';
import { OrderMasterRes } from '../models/OrderUpRes';
import { ConcessioniareOrderSplitBill } from '../models/ConcessioniareOrderSplitBill';

@Injectable({
  providedIn: 'root',
})
export class OrderHistoryService {
  constructor(
    private logger: LogService,
    private databaseService: DatabaseService,
    private http: HttpClient
  ) { }
  orders: order[] = [];

  formatCombo(items: any) {
    // console.log('the items are', items);
    items.forEach((item: any) => {
      if (item.isCombo == 'False' && item.ComboID != '0' && !item.comboItems) {
        item.ComboGroup.push({ Items: [] });
        item.ComboGroup[0].Items = items.filter(
          (x: any) => x.isCombo == 'True' && x.ComboID == item.ComboID
        );
        // console.log('the combo items are', item.comboItems);
      }
    });

    let itemsReturn = items.filter((x: any) => x.isCombo.toString().toLowerCase() == 'false');
    itemsReturn.forEach((item: any) => {
      if (item.isCombo == 'False' &&
        item.ComboGroup &&
        item.ComboGroup.length > 0 &&
        item.ComboGroup[0].Items &&
        item.ComboGroup[0].Items.length > 0) {
        item.IsCombo = 'True'
      }
    });
    return itemsReturn;
  }

  formatModifiers(
    modifiers: ModifierV2[],
    ingredients: ModifierIngredientV2[]
  ) {
    ingredients.forEach((ing: ModifierIngredientV2) => {
      let index = modifiers.findIndex(
        (x: ModifierV2) => x.ModifierID == ing.ModifierID
      );
      if (index != -1 && ing.Name != '') {
        modifiers[index].Ingredients.push(ing);
      }
    });

    modifiers.forEach((mod: ModifierV2) => {
      if (mod.ParentID != '0' || mod.SelectionParentID != '0') {
        let index = modifiers.findIndex(
          (x: ModifierV2) =>
            x.ModifierID == mod.ParentID ||
            x.ModifierID == mod.SelectionParentID
        );
        if (index != -1) modifiers[index].Ingredients.push(mod);
      }
    });

    return modifiers.filter(
      (x: ModifierV2) => x.ParentID == '0' || x.SelectionParentID == '0'
    );
  }

  hasParent(ing: ModifierIngredientV2, ingredients: ModifierIngredientV2[]) {
    return (
      (ing.SelectionParentID &&
        ing.SelectionParentID != '0' &&
        !ingredients.some((x: any) => x.ModifierID == ing.SelectionParentID)) ||
      (ing.ParentID &&
        ing.ParentID != '0' &&
        !ingredients.some((x: any) => x.ModifierID == ing.ParentID))
    );
  }

  async getOrders(date: string) {
    this.orders = await this.getOrderHistoryFromSql(date);

    for (const order of this.orders) {
      order.items = await this.getOrderItemHistoryFromSql(order.OrderID, order.AppRefID);

      let isRefund = order.IsRefunded.toString();
      order.IsRefunded = (isRefund == 'True' || isRefund == 'true') ? "True" : "False";

      for (const item of order.items) {
        item.Modifiers = [];
        item.ComboGroup = [];
        item.isSelected = true;

        // temporarily store ingredients in item because that is how orders are structured
        item.Ingredients = await this.getOrderModifierHistoryFromSql(
          item.OrderItemID ? item.OrderItemID : ''
        );
        for (const ing of item.Ingredients) {
          ing.IsSelected = true;
          ing.isNegativeModifier = ing.IsNegativeModifier == 'True';
          ing.IsIngredient = true;
          ing.IsModifier = false;

          // console.log('ingredients!!!!', ing, item.Modifiers);

          // for nested mods find nested mods from SQL and push to ingredients array since orders don't store nested mods
          if (this.hasParent(ing, item.Ingredients)) {
            // console.log('mod with parent', ing, ing.SelectionParentID);

            item.Ingredients.push(
              ...(await this.getNestedModifierByModifierIDFromSql(
                ing.SelectionParentID || ing.ParentID, item.ItemID
              ))
            );
          }

          // orders also don't store modifiers so find modifier from Ingredients
          if (
            !item.Modifiers.some((x: any) => x.ModifierID == ing.ModifierID)
          ) {
            item.Modifiers.push(
              ...(await this.getNestedModifierByModifierIDFromSql(
                ing.ModifierID,  item.ItemID
              ))
            );
            // initialize the ingredients array for each modifier
            if (item.Modifiers && item.Modifiers.length > 0) {
              item.Modifiers[item.Modifiers.length - 1].Ingredients = [];
            }
          }
        }
        // put nested mods in proper format
        item.Modifiers = this.formatModifiers(item.Modifiers, item.Ingredients);
        item.Ingredients = [];
        item.Variations =
          await this.databaseService.getVariationsByItemIDFromSql(
            item.ItemID.toString()
          );
        item.VariationOption =
          await this.databaseService.getVariationOptionByOptionID(
            item.ItemAttributeOptionID || ''
          );

        if (item.VariationOption && item.VariationOption[0]) {
          item.VariationOption[0].isSelected = true;
        }

        if (item.Variations && item.Variations[0]) {
          item.Variations[0].variationOptions = item.VariationOption;
        }
        item.VariationOption = [];
      }
      order.items = this.formatCombo(order.items) as ItemV2[];
    }
    return this.orders;
  }

  getOrderHistory(date: string): Observable<order[]> {
    return from(this.getOrderHistoryFromSql(date));
  }

  getOrderHistoryFromSql(date: string) {
    const dateSplit = date.split(' ');

    return new Promise<order[]>((resolve: any, reject: any) => {
      var historyQuery = `
      SELECT
       *
      FROM
        OrderMasters
      WHERE
      CreatedDate LIKE '${dateSplit[0]}% %${dateSplit[1]}% ${dateSplit[2]}%'
      ORDER BY
        rowid DESC
        `;

      const errorCallback = (statement: any, error: any) => {
        // const log = new loggingData(
        //   'Get OrderHistory',
        //   'Fail to get order History',
        //   'Get OrderHistory',
        //   error.message,
        //   true
        // );
        // this.logger.sendLogToServer(log);
        reject(error);
      };

      const setItems = (tx: string, results: any) => {
        var data: order[] = Array.from(results.rows);
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        historyQuery,
        [],
        setItems,
        errorCallback
      );
    });
  }

  getOrderItemHistory(OrderID: string, appRefOrderId: string): Observable<ItemV2[]> {
    return from(this.getOrderItemHistoryFromSql(OrderID, appRefOrderId));
  }

  getOrderItemHistoryFromSql(OrderID: string, appRefOrderId: string) {
    return new Promise<ItemV2[]>((resolve: any, reject: any) => {
      let sqlString = `SELECT ItemQuantity AS Quantity, ItemPrice as Price, ItemName as Name, TaxPer as TaxPercentage,
      ItemTotal as totalPrice, OrderItemComment as specialRequest, IsCombo as isCombo, ItemPrice as ExtraPrice,OrderReviewDisplayName,BranchSectionConcessionaireID as ConcessionaireId,
      * FROM OrderItemMasters WHERE OrderID = '${OrderID}' OR AppRefIDOrder = '${appRefOrderId}'`;

      const errorCallback = (statement: any, error: any) => {
        // const log = new loggingData(
        //   'Get OrderItemHistory',
        //   'Fail to get order item History',
        //   'Get OrderItemHistory',
        //   error.message,
        //   true
        // );
        // this.logger.sendLogToServer(log);
        resolve([]);
      };

      const setItem = (tx: string, results: any) => {
        // console.log(results.rows);
        resolve(Array.from(results.rows));
      };

      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        setItem,
        errorCallback
      );
    });
  }

  getOrderModifierHistoryFromSql(OrderItemID: string) {
    return new Promise<ModifierIngredientV2[]>((res: any, rej: any) => {
      let create =
        'CREATE TABLE IF NOT EXISTS OrderItemModifierMasters (id unique, log)';
      let sqlString = `SELECT IngredientName as Name, IngredientName as DisplayName, * FROM OrderItemModifierMasters WHERE OrderItemID = '${OrderItemID}'`;

      const errorCallback = (statement: any, error: any) => {
        // const log = new loggingData(
        //   'Get OrderModifierHistory',
        //   'Fail to get order modifier history',
        //   'Get OrderModifierHistory',
        //   error.message,
        //   true
        // );
        // this.logger.sendLogToServer(log);
        res([]);
        rej(error);
      };

      const setMods = (tx: string, results: any) => {
        // console.log('order mods', results.rows);
        res(Array.from(results.rows));
      };

      DatabaseHandler.executeSqlStatement(create, [], () => { }, errorCallback);

      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        setMods,
        errorCallback
      );
    });
  }

  getNestedModifierByModifierIDFromSql(modifierId: string, ItemID: string) {
    return new Promise<ModifierV2[] | any>((resolve: any, reject: any) => {
      const getModifier = `
       SELECT
       MM.ModifierID AS ModifierID
      ,MM.IsModifier86 as IsModifier86
      ,MM.IsForced AS IsForced
      ,MM.CountOption AS CountOption
      ,MM.MinNoOfSelection AS MinNoOfSelection
      ,MM.Category AS Category
      ,MM.Price AS Price
      ,CAST(MM.DisplayOrder AS INT) AS DisplayOrder
      ,MM.DisplayName AS DisplayName
      ,MM.Description AS Description
      ,MM.IngredientID AS IngredientID
      ,MM.ParentID AS ParentID
      ,MM.RefModifierid
      ,MM.SelectedImage
      ,MM.DeselectedImage
      ,I.ImageUrl
      ,'0' as ParentModifierID

      FROM
        ModifierMasters as MM
      LEFT JOIN Ingredients as I
        ON MM.IngredientID = I.IngredientID
      WHERE
        MM.ModifierID = '${modifierId}'
          AND MM.ItemID = '${ItemID}'
          AND MM.IsActive = 'True'
          AND MM.IsShowOnKIOSK = 'True'

      UNION
      SELECT
        MM.ModifierID AS ModifierID
        ,MM.IsModifier86 as IsModifier86
        ,MM.IsForced AS IsForced
        ,MM.CountOption AS CountOption
        ,MM.MinNoOfSelection AS MinNoOfSelection
        ,MM.Category AS Category
        ,MM.Price AS Price
        ,CAST((Case when cmim.DisplayOrder = '0' then MM.DisplayOrder else cmim.DisplayOrder end) AS INT) AS DisplayOrder
        ,MM.DisplayName AS DisplayName
        ,MM.Description AS Description
        ,MM.IngredientID AS IngredientID
        ,case when MM.ParentID = '0' then cmim.ParentModifierID else mm.ParentID end as ParentID
        ,MM.RefModifierid
        ,MM.SelectedImage
        ,MM.DeselectedImage
        ,I.ImageUrl
        ,CMIM.ParentModifierID

      FROM
        CommonModifier_Item_Mappings as CMIM
      LEFT JOIN
        ModifierDetailMasters as MDM
          ON CMIM.ModifierID = MDM.ModifierID
      LEFT JOIN
               ModifierMasters as MM
          ON( CMIM.ModifierID = MM.ModifierID)
      LEFT JOIN Ingredients as I
        ON MM.IngredientID = I.IngredientID
      WHERE
        (cmim.ModifierID = '${modifierId}')
        AND cmim.ItemID = '${ItemID}'
        AND MM.IsActive = 'True'
        AND MM.IsShowOnKIOSK = 'True'
        ORDER BY DisplayOrder
      `;

      const logError = (statement: any, error: any) => {

        console.log('Order History Service, Error getNestedModifierByModifierIDFromSql :- ', error);

        resolve([]);
      };

      const setItems = (transaction: String, results: any) => {
        var data: ModifierV2[] = [];

        for (var i = 0; i < results.rows.length; i++) {
          let md = results.rows[i];

          let modifier = new ModifierV2();
          modifier.ModifierID = md.ModifierID;
          modifier.IsModifier86 = md.IsModifier86;
          modifier.IsForced = md.IsForced;
          modifier.CountOption = md.CountOption;
          modifier.MinNoOfSelection = md.MinNoOfSelection;
          modifier.Category = md.Category;
          modifier.Price = md.Price;
          modifier.DisplayOrder = md.DisplayOrder;
          modifier.DisplayName = md.DisplayName;
          modifier.Description = md.Description;
          modifier.IngredientID = md.IngredientID;
          modifier.ParentID = md.ParentID;
          modifier.RefModifierId = md.RefModifierid;
          modifier.SelectedImage = md.SelectedImage;
          modifier.DeselectedImage = md.DeselectedImage;
          modifier.ImageUrl = md.ImageUrl;
          modifier.count = 0;
          modifier.Ingredients = [];
          modifier.max = Number.MAX_SAFE_INTEGER;
          modifier.isValid = true;
          modifier.min = Number.MIN_SAFE_INTEGER;
          modifier.rule = '';
          modifier.IsSelected = true;
          modifier.ParentModifierID = md.ParentModifierID;
          //modifier.IsModifier86 = md.IsModifier86 == "True"? "False" : "True";

          let max = Number(modifier.CountOption);
          let min = Number(modifier.MinNoOfSelection);
          if (min > 0 || max > 0) {
            if (min) {
              // has min
              if (max > 0) {
                // has min and max
                if (min == max) {
                  modifier.rule = `Choose exactly ${modifier.MinNoOfSelection}`; // min and max are the same
                  modifier.max = max;
                  modifier.min = min;
                } else {
                  modifier.rule = `Choose between ${modifier.MinNoOfSelection} and ${modifier.CountOption}`; // min and max are different
                  modifier.max = max;
                  modifier.min = min;
                }
              } else {
                modifier.rule = `Choose at least ${modifier.MinNoOfSelection}`; // only has min
                modifier.min = min;
              }
            } else if (max > 0) {
              // has max
              if (modifier.IsForced == 'True' && max == 1) {
                modifier.rule = `Choose exactly ${modifier.CountOption}`;
                modifier.max = max;
                modifier.min = 1;
              } else {
                modifier.max = max;
                modifier.min = min;
                modifier.rule = `Choose up to ${modifier.CountOption}`;
              }
            }
          } else {
            modifier.min = 0;
            modifier.max = 0;
          }

          data.push(modifier);
        }
        // //console.log('modifier data', data);
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(getModifier, [], setItems, logError);
    });
  }

  getConcesisonaireOrderSplitBillByInvoiceNoFromSql(OrderId: string, InvoiceNo: string) {
    return new Promise<ConcessioniareOrderSplitBill[]>((resolve: any, reject: any) => {
      let getOrderConcessionaireSplitBill = '';

      //if (!OrderId || OrderId == "") {
      getOrderConcessionaireSplitBill = `select * from OrderConcessionaireSplitBill where SplitInvoiceNo like '%${InvoiceNo}%'`;
      //}
      //else {
      //  getOrderConcessionaireSplitBill = `select * from OrderConcessionaireSplitBill where OrderId like "%${OrderId}%"`;
      //}

      const logError = (statement: any, error: any) => {

        console.log('Concessionaire Order Split Bill Data , Error getConcesisonaireOrderSplitBillByInvoiceNoFromSql :- ', error);

        resolve([] as ConcessioniareOrderSplitBill[]);
      };

      const setItems = (transaction: String, results: any) => {
        var data: ConcessioniareOrderSplitBill[] = Array.from(results.rows);

        // //console.log('modifier data', data);
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(getOrderConcessionaireSplitBill, [], setItems, logError);
    });
  }

  sendRefundOrderAPI(order: order | OrderMasterRes) {
    const headers = new HttpHeaders()
      .set('Version', '1.0')
      .set('Content-Type', 'application/json')
      .set('CompanyID', GeneralSetting.getCompanyId())
      .set('BranchID', GeneralSetting.getBranchId())
      .set('DeviceID', GeneralSetting.getSerialNo());

    const body = JSON.stringify(order);
    // console.log('Order API body', body);

    return this.http
      .post(environment.apiUrl + 'RefundOrder', body, {
        headers,
      })
      .pipe(catchError(this.errorHandler));
  }

  /**
   * error handler
   * @param error
   * @returns error message
   */
  errorHandler(error: HttpErrorResponse) {
    var log = new loggingData(
      'Data Error',
      'Encountered Error on send Refund Data Fetch',
      `Error`,
      `Encountered Error: ${error}`,
      true
    );
    this.logger.sendLogToServer(log);
    return throwError(error.message);
  }

}
