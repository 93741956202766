<div id="discountSuccess">
    <div class="modal-body configureDevice-modal">
        <div class="content">
            <h2><b>{{title}}</b></h2>
            <p>{{subTitle}}</p>
        </div>
    </div>
    <div class="modal-footer">

        <button matRipple class="ok-btn text-uppercase" (click)="onOkClick()">{{okText}}</button>

        <!-- <button matRipple class="white-btn warn text-uppercase" (click)="cancelModal()">No</button> -->

    </div>
</div>