import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IsKioskService } from './is-kiosk.service';
import { GeneralSetting } from './generalSetting.service';
import { catchError } from 'rxjs/operators';
import { loggingData, LogService } from 'src/app/services/log.service';
import { Observable, observable, throwError } from 'rxjs';
import { CommonFunctions } from './common';
import { FixedItemDiscount } from '../models/discounts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ItemV2 as Item,
  ItemV2,
  ModifierV2 as Modifier,
} from 'src/app/models/item';
import { GeneralAlertComponent } from '../components/dialogs/general-alert/general-alert.component';
import { LanguageService } from './language.service';
import { CartService } from './cart.service';
import { DatabaseService } from './database.service';
import { DatabaseHandler } from '../DatabaseHandler';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {
  baseUrl: string = environment.apiUrl;
  checkDiscountLimitationUrl: string = 'CheckDiscountLimitation';
  isKiosk: boolean = true;
  fixedItemDisc : FixedItemDiscount[] = [] as FixedItemDiscount[];
  errorModel!: GeneralAlertComponent;

  constructor(private http: HttpClient, isKioskService: IsKioskService, private logger: LogService,
    public modalService: NgbModal,
    private language: LanguageService,
    private cartService: CartService,
    private database: DatabaseService,
    ) {
    this.isKiosk = isKioskService.isKiosk();
   }

  setFixedItemDisc(fixedItemDisc : FixedItemDiscount[]){
    this.fixedItemDisc = fixedItemDisc;
  }

  async getFixedItemDisc(){
    if (this.fixedItemDisc && this.fixedItemDisc.length > 0) return this.fixedItemDisc;
    else {
      this.fixedItemDisc = await this.getFixedItemDiscount();
      this.setFixedItemDisc(this.fixedItemDisc);
      return this.fixedItemDisc;
    }
  }

  checkDisountLimitation(
    customerID: string,
    discountID: number
  ) {

    const headers = new HttpHeaders()
      .set('Version','1.0')
      .set('Content-Type', 'application/json')
      .set('CompanyID', GeneralSetting.getCompanyId())
      .set('BranchID', GeneralSetting.getBranchId());

    const body = {
      date: CommonFunctions.getCurrentDateTime(),
      CustomerID: customerID,
      DiscountID: discountID,
    };

    return new Observable<object>();
  }

  /**
 * error handler
 * @param error
 * @returns error message
 */
    errorHandler(error: HttpErrorResponse) {
    var log = new loggingData(
      'Data Error',
      'Encountered Error on Data Fetch',
      `Error`,
      `Encountered Error: ${error}`,
      true
    );
    this.logger.sendLogToServer(log);
    return throwError(error.message);
  }

  private getFixedQuantity(fixedItemDisc : FixedItemDiscount, itemID: String, subTotal : number) : number{
    let fixedQty = 1, times = 1;
    let limitedTimes = Number(fixedItemDisc.LimitationTimes);
    let maxDiscQty = Number(fixedItemDisc.MaximumDiscountedQuantity);     
    times  = parseInt((subTotal / fixedItemDisc.MinOrderAmount).toString());
    let maxItem = times * maxDiscQty;
    if(limitedTimes <= maxItem){
      fixedQty = limitedTimes;
    }else{
      fixedQty = maxItem;
    }
  
    return fixedQty;
  }

  public checkFixedFreeItem(fixedItemDisc : FixedItemDiscount, itemID: String, subTotal : number, itemQty : number = 0) : number{
    let cartItemss = this.cartService.getCartItems();
    let increamentCheck = 0, itemQuantity = 0;
    if(fixedItemDisc != undefined && fixedItemDisc && fixedItemDisc.ItemID == itemID){
      let fixedQty = 1;
      let limitedTimes = Number(fixedItemDisc.LimitationTimes);
      let maxDiscQty = Number(fixedItemDisc.MaximumDiscountedQuantity);     
      if(subTotal >= fixedItemDisc.MinOrderAmount){
        fixedQty = this.getFixedQuantity(fixedItemDisc, itemID, subTotal);
        const cartItems = cartItemss.filter(
          (x) => x.ItemID == itemID
        );

        if(cartItems && cartItems != undefined && cartItems.length > 0 && itemQty == 0){
          itemQuantity =  Number(cartItems[0].Quantity);
        }else{
          itemQuantity = itemQty;
        }

        if(itemQuantity > 0){
          if(fixedQty == limitedTimes && itemQuantity+1 > limitedTimes){
            increamentCheck = 1;
            this.showFixedItemAlert(limitedTimes,fixedItemDisc.MinOrderAmount,fixedItemDisc.ItemName,true);
          }else if(itemQuantity >= fixedQty){
            increamentCheck = 1;
            this.showFixedItemAlert(itemQuantity+1,this.getMinOrderSubtotal(Number(fixedItemDisc.MinOrderAmount),maxDiscQty,(fixedQty + maxDiscQty)),fixedItemDisc.ItemName);
          }else{
            increamentCheck = 0;
          }
        }else{
          increamentCheck = 0;
        }
      }else{
        increamentCheck = 1;
        this.showFixedItemAlert(1,Number(fixedItemDisc.MinOrderAmount),fixedItemDisc.ItemName);
      }
    }
    return increamentCheck;
  }

  private showFixedItemAlert(fixedQty : number,minOrderAmount : number, itemName : string,isLimitReached : boolean = false){
    setTimeout(() => {
      if( this.errorModel == undefined){
        this.errorModel = new GeneralAlertComponent( this.modalService,this.language);
      }else{
        this.errorModel.onOkClick();
      }

      if(isLimitReached){
        this.errorModel.open(
          'Uh Oh!',
          'Maximum limit of free item quantity(s) '+fixedQty+' has been reached'
        );
      }else{
        this.errorModel.open(
          'Uh Oh!',
          'You need a minimum order total of $'+CommonFunctions.roundDigit(
            Number(minOrderAmount), 2).toFixed(2)+' to have '+fixedQty+' quantity(s) of '+itemName
        );
      }   
    }, 200);
  }

  public isFixedItemAvail(fixedItemDisc : FixedItemDiscount,item: Item, itemQty : number = 0) : boolean{
    let subTotal = this.cartService.calculateSubtotal(false,false);
    var qty = this.checkFixedFreeItem(fixedItemDisc, item.ItemID, subTotal, itemQty);
    return qty > 0;
  }

  private getMinOrderSubtotal(minOrder : number, maxQty : number, newQty : number){
    return (minOrder * newQty) / maxQty;
  }

  public checkFreeItemExitsInDec(fixedItemDisc : FixedItemDiscount,item: Item, isRemoveItem : boolean) : FixedItemDiscount{
    let subTotal = this.cartService.calculateSubtotal(false,false);
    let fixedItemDiscDec = {} as FixedItemDiscount ; 
    if(fixedItemDisc != undefined && item.ItemID != fixedItemDisc.ItemID){
      
      var itemQty = Number(item.Quantity);
      var itemPrice = CommonFunctions.getItemPrice(item, false);

      if(isRemoveItem){
        subTotal = subTotal - (itemPrice * Number(item.Quantity));
        if(subTotal < fixedItemDisc.MinOrderAmount){
          fixedItemDiscDec = this.checkFixedFreeItemInDec(fixedItemDisc, fixedItemDisc.ItemID,subTotal, true);
        }else{
          fixedItemDiscDec = this.checkFixedFreeItemInDec(fixedItemDisc, fixedItemDisc.ItemID,subTotal);
        }
      }else{
        if(itemQty > 1){
          subTotal = subTotal - itemPrice;
          fixedItemDiscDec = this.checkFixedFreeItemInDec(fixedItemDisc, fixedItemDisc.ItemID,subTotal);
        }
        else if(itemQty == 1){
          subTotal = subTotal - itemPrice;
          if(subTotal < fixedItemDisc.MinOrderAmount){
            fixedItemDiscDec = this.checkFixedFreeItemInDec(fixedItemDisc, fixedItemDisc.ItemID,subTotal, true);
          }else{
            fixedItemDiscDec = this.checkFixedFreeItemInDec(fixedItemDisc, fixedItemDisc.ItemID,subTotal);
          }
        }
      }
    }
    return fixedItemDiscDec;
  }


  public checkFixedFreeItemInDec(fixedItemDisc : FixedItemDiscount, itemID: string, subTotal : number, isremove : boolean = false) : FixedItemDiscount{
    let cartItemss = this.cartService.getCartItems();
    let increamentCheck = 0;
    let fixedItemDiscDec = {} as FixedItemDiscount ; 
    if(fixedItemDisc != undefined && fixedItemDisc && fixedItemDisc.ItemID == itemID && !isremove){
      let fixedQty = 1;
      let limitedTimes = Number(fixedItemDisc.LimitationTimes);
      let maxDiscQty = Number(fixedItemDisc.MaximumDiscountedQuantity);   
      const cartItems = cartItemss.filter(
        (x) => x.ItemID == itemID
      );
      if(cartItems && cartItems != undefined && cartItems.length > 0 && cartItems[0].ItemID == fixedItemDisc.ItemID){
        if(subTotal >= fixedItemDisc.MinOrderAmount){
          fixedQty = this.getFixedQuantity(fixedItemDisc, itemID, subTotal);
            if(fixedQty == limitedTimes && Number(cartItems[0].Quantity) > limitedTimes){
              increamentCheck = 1;
              this.showFixedItemAlert(limitedTimes,fixedItemDisc.MinOrderAmount,fixedItemDisc.ItemName,true);
            }else if(Number(cartItems[0].Quantity) > fixedQty){
              increamentCheck = 1;
              this.showFixedItemAlert(Number(cartItems[0].Quantity),this.getMinOrderSubtotal(Number(fixedItemDisc.MinOrderAmount),maxDiscQty,(fixedQty + maxDiscQty)),fixedItemDisc.ItemName);
            }else{
              increamentCheck = 0;
            }
            fixedItemDiscDec = {
              ItemID : itemID,
              MinOrderAmount: 0,
              LimitationTimes: 0,
              MaximumDiscountedQuantity: 0,
              Check: increamentCheck,
              FixedQty: fixedQty,
              ItemName:'',
            };
        }else{
          this.showFixedItemAlert(1,Number(fixedItemDisc.MinOrderAmount),fixedItemDisc.ItemName);
          fixedItemDiscDec = {
            ItemID : itemID,
            MinOrderAmount: 0,
            LimitationTimes: 0,
            MaximumDiscountedQuantity: 0,
            Check: 2,
            FixedQty: 0,
            ItemName:'',
          }; 
        }      
      }else{
        fixedItemDiscDec = {
          ItemID : itemID,
          MinOrderAmount: 0,
          LimitationTimes: 0,
          MaximumDiscountedQuantity: 0,
          Check: increamentCheck,
          FixedQty: fixedQty,
          ItemName:'',
        };
      }
    }else{
        fixedItemDiscDec = {
          ItemID : itemID,
          MinOrderAmount: 0,
          LimitationTimes: 0,
          MaximumDiscountedQuantity: 0,
          Check: 2,
          FixedQty: 0,
          ItemName:'',
        };   
    }
    return fixedItemDiscDec;
  }
  

  public async checkFreeItemOnSubtotalUpdate(subTotal : number) : Promise<FixedItemDiscount>{
    let fixedItemDisc = await this.getFixedItemDisc();
    let fixedItemDiscDec = {} as FixedItemDiscount ; 
    if(fixedItemDisc != undefined && this.fixedItemDisc.length > 0){
      fixedItemDiscDec = this.checkFixedFreeItemInDec(fixedItemDisc[0], fixedItemDisc[0].ItemID,subTotal);
    }
    return fixedItemDiscDec;
  }

  public getFixedItemDiscount(): Promise<FixedItemDiscount[]> {
    return new Promise((resolve, reject) => {
      let currentDate = CommonFunctions.getCurrentformatedtDate(new Date());

      let query = `SELECT dm.MinOrderAmount,dm.LimitationTimes,dm.MaximumDiscountedQuantity,DIM.ItemID as ItemID,
      case when I.displayname = '' then I.Name
      else I.displayname
      end as ItemName
      FROM DiscountMasters as dm 
      inner join Discount_Item_Mappings DIM on DM.DiscountID = DIM.DiscountID
      inner join Items I on I.ItemID = DIM.ItemID
      WHERE dm.DiscountTypeID IN ('7')
       AND (dm.IsShowOnKiosk = 'true' OR dm.IsShowOnKiosk = 'True')
       AND (dm.IsActive = 'true' OR dm.IsActive = 'True')
       AND (dm.StartDate <= '${currentDate}' OR dm.StartDate = '')
       AND (dm.EndDate >= '${currentDate}' OR dm.EndDate = '') 
       AND  dm.BranchID ='${GeneralSetting.getBranchId()}'
       AND  dm.CompanyID = '${GeneralSetting.getCompanyId()}' ORDER BY dm.CreatedDate DESC LIMIT 1;`;
      
      const callback = (tx: string, results: any) => {
        var data: FixedItemDiscount[] = Array.from(results.rows);

        resolve(data);
      };

      const errorCallback = (tx: string, results: any) => {
        resolve([] as FixedItemDiscount[]);
      };
      DatabaseHandler.executeSqlStatement(query, [], callback, errorCallback);
    });
  }
}
