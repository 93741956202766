<div class="modal-content" id="OLOTotalLoader">
    <div class="modal-body">
        <h1 class="text-center">{{checkoutText|uppercase}}</h1>
        <div class="content">
            <h1 class="text-center" style="font-size: 50px;">
                <b *ngIf="isApplyingDiscount;else removingDiscount">{{applyingDiscountText}}</b>
                <ng-template #removingDiscount>
                    <b>{{removingDiscountText}}</b>
                </ng-template>
            </h1>
            <h1 class="text-center">{{pleaseBePatientText}}</h1>
            <img src="../../../../assets/images/loading/olo_animation.gif"
                onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
                class="col-md-6 offset-md-3 col-6 col-offset-3" alt="" />
        </div>
    </div>
</div>