import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadingComponent } from '../../loading/loading.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ScanningItemComponent } from '../dialogs/scanning-item/scanning-item.component';

import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-hybrid-section',
  templateUrl: './hybrid-section.component.html',
  styleUrls: ['./hybrid-section.component.css'],
})
export class HybridSectionComponent implements OnInit {
  tapToScan: string = '';

  ngOnDestroy() {
    //Clearing interval
    var countInterval = this.intervalID != undefined ? this.intervalID : 0;
    for (var a = 1; a <= countInterval; a++) {
      clearInterval(a);
    }
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  languageSub!: Subscription;
  @ViewChild(ScanningItemComponent) scanninganitemModal!: ScanningItemComponent;

  modalRef!: NgbModalRef;

  scanItemMsg = '';

  scanImageTitle = '';

  scanItemFooter = '';

  scanItemInfo = '';

  barWidth = 0;

  intervalID: any;
  orText: string = '';
  scanText: string = '';
  selectText: string = '';
  hybridDescriptionText: string = '';
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private language: LanguageService
  ) {}

  openscanitem(): void {
    if (this.scanninganitemModal) {
      this.scanninganitemModal.open();
    }

    this.scanninganitemModal.modalReference.result.then((data: string) => {
      if (data == 'continue') {
        this.startContentAnimation();
      }
    });
  }

  opensearchscannable(): void {
    this.router.navigateByUrl('/SearchScannable');
  }
  loadText() {
    this.tapToScan = this.language.getTextElement('tap_to_scan');
    this.orText = this.language.getTextElement('txt_or');
    this.scanText = this.language.getTextElement('txt_scan');
    this.selectText = this.language.getTextElement('txt_select');
    this.hybridDescriptionText =
      this.language.getTextElement('hybrid_description');
  }
  ngOnInit() {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
      this.setScanContent();
      this.startContentAnimation();
    });
  }

  setScanContent() {
    this.scanImageTitle = `<strong>${this.scanText}</strong>`;
    this.scanItemMsg = `<strong>${this.scanText}</strong> ${
      this.orText
    } <strong>${this.selectText}</strong> ${this.language.getTextElement(
      'scan_item_hint_last'
    )}.`;
    this.scanItemFooter = this.language.getTextElement('items_without_barcode');
    this.scanItemInfo = '../../../assets/images/info.png';
  }

  setSelectContent() {
    this.scanItemMsg = `${this.language.getTextElement(
      'scan_item_hint_a'
    )}</br>${this.language.getTextElement(
      'scan_item_hint_b'
    )}<strong>${this.language.getTextElement('scan_item_hint_c')}</strong>.`;
    this.scanItemFooter = '';
    this.scanItemInfo = '';
  }

  startContentAnimation() {
    this.intervalID = setInterval(() => {
      if (this.barWidth >= 7) {
        this.barWidth = 0;
      } else if (this.barWidth <= 3) {
        this.barWidth++;
        this.setScanContent();
      } else {
        this.barWidth++;
        this.setSelectContent();
      }
    }, 1000);
  }
}
