import { HttpErrorResponse } from '@angular/common/http';

export enum APIResponses {
  UnAuthorized = 1,
  Offline = 2,
}
export interface APIResponse {
  res: HttpErrorResponse;
  Code: number;
}

export interface ReferrizerCustomerResponse {
  data: {
    data: ReferrizerCustomerAPIResponse;
    message: string;
    status: string;
    statusCode: string;
  };
  errArr: APIResponse[];
}
export interface ReferrizerCustomerAPIResponse {
  access_token: null | string;
  app_id: null | string;
  born_at: null | string;
  cause_id: null | string;
  connected_to_facebook: null | string;
  custom_gender: null | string;
  customerID: string;
  debit_card_only: null | string;
  description: string;
  email: string;
  firstName: string;
  firstVisitDate: string;
  gender: null | string;
  global_credit_amount: null | string;
  id: string;
  isAllowForMarketingCommunication: string;
  lastName: string;
  lastVisitDate: string;
  loyaltyPointsCount: number;
  merchants_visited_count: null | string;
  message: string;
  orders_count: null | string;
  phone: string;
  preferred_currency: null | string;
  punchUserDigest: string;
  registrationDate: string;
  terms_accepted_at: null | string;
  total_savings_amount: null | string;
  type: string;
  userId: string;
  visitsCount: number;
}
export interface OLOPaytronixCustomerResponse {
  data: {
    data: OLOPaytronixCustomerAPIResponse;
    message: string;
    status: string;
    statusCode: string;
  };
  errArr: APIResponse[];
}
export interface OLOPaytronixCustomerAPIResponse {
  authorizationcode: string | null;
  authtoken: string;
  basketid: string | null;
  contactnumber: string | null;
  emailaddress: string | null;
  expiresin: string | null;
  firstname: string | null;
  lastname: string | null;
  provider: string;
  providertoken: string;
  provideruserid: string;
  refreshtoken: string | null;
}

export interface RemoteInstallNotification{
  deviceIds: string[];
  kioskReleaseInformation: KioskRelease;
}

export interface KioskRelease{
  Name: string;
  Version: string;
  ReleaseNotes: string;
  PackageUrl: string;
}

export interface RemoteInstallACKNotification {
  status: string;
  deviceId: string;
  locationId: string;
  KioskReleaseInformation: KioskReleaseACK;
}

export interface KioskReleaseACK {
  name: string;
  version: string;
  releaseNotes: string;
  packageUrl: string;
}

export enum InstallationACKType {
  INSTALLING = "installing",
  SUCCESS = "success",
  ERROR = "error",
}

export interface HealthLogModel {
  kioskHealthTime: Date,
  deviceName: string,
  serialNo: string,
  internetConnecationText: string,
  isInternetConnecation: boolean,
  grubbrrCloudText: string,
  isGrubbrrCloud: boolean,
  grubbrrCloverMerchantId: string,
  grubbrrCloverDeviceId: string,
  isGrubbrrClover: boolean,
  kitchenPrinterText: string,
  isKitchenPrinter: boolean,
  ReceiptPrinterStatus: ReceiptPrinterStatus[]
  KIOSKBuildVersion: string,
  isKIOSKBuildVersion: boolean,
  grubbrrCloverApiRes: string,
  locationId?: string
}

export interface ReceiptPrinterStatus {
  value: string,
  severity: string
}

export enum SystemHealthStatus {
  Success = "success",
  Error = "error",
  LowWarning = "low-warning",
  HighWarning = "high-warning",
  Info = "info"
}

export enum BixolonPrinterStatus {
  Connected = "Connected",
  InsufficientPaper = "Insufficient Paper",
  OutOfPaper = "Out Of Paper",
  CoverIsOpen = "Cover Is Open",
  CutterError = "Cutter Error",
  OtherError = "Other Error"
}

export interface HealthLogRequest {
  kioskBuildVersion: string;
  deviceName: string;
  serialNo: string;
  locationId: string;
  isGrubbrrCloud: boolean;
  sendNotification?: boolean;
  kioskHealthTime: Date
  details: LogDetail[];
}
export interface LogDetail {
    label: string;
    labelText?: string;
    value: string;
    severity: string;
    details?: LogRecord[];
}

export interface LogRecord {
  value: string,
  severity: string
}