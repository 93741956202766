import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-items86-popup',
  templateUrl: './items86-popup.component.html',
  styleUrls: ['./items86-popup.component.css']
})
export class Items86PopupComponent implements OnInit,OnDestroy {
  languageSub!:Subscription
  modalReference: any;
  unavailableItems: any[] = []
  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;
  
    uhOhText:string=''
    itemText:string=''
    unavailableText:string=''
    itemsUnavailableText:string=''
    removeFromCartText:string=''
  placeholderImage : string = '';
    soldOutText:string=''
  constructor(private readonly modalService: NgbModal,
    private isKioskService: IsKioskService,
    private language:LanguageService,
    private readonly dataService: DataService) {
   }
   ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
   }
   loadText(){
    this.uhOhText=this.language.getTextElement('lbl_uh_oh')
    this.soldOutText=this.language.getTextElement('sold_out')
    this.itemText=this.language.getTextElement('item')
    this.unavailableText=this.language.getTextElement('items_unavailable')
    this.itemsUnavailableText=this.language.getTextElement('below_items_are_not_available_anymore_consider_deleting_them')
    this.removeFromCartText=this.language.getTextElement('remove_from_cart')
  }
  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  public static open(modalService:NgbModal,unavailableItems:any[]) {
    let modalReference = modalService.open(Items86PopupComponent, {
      backdrop: 'static',
      size: 'xl',
      centered: true,
    });
    modalReference.componentInstance.unavailableItems = unavailableItems;
    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }

  open(unavailableItems:any[]) {
    this.modalReference = this.modalService.open(Items86PopupComponent, {
      backdrop: 'static',
      size: 'xl',
      centered: true,
    });
    this.modalReference.componentInstance.unavailableItems = unavailableItems;
    this.modalReference.componentInstance.modalReference = this.modalReference;
    return this.modalReference;
  }

  removeFromCart() {
    this.modalReference.close('removeItems');
  }

}
