import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';
import { loggingData, LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-order-type-warning',
  templateUrl: './order-type-warning.component.html',
  styleUrls: ['./order-type-warning.component.css'],
})
export class OrderTypeWarningComponent implements OnInit,OnDestroy {
  positiveColor: string | null = GeneralSetting.getPositiveColor();
  modalRef!: NgbModalRef;
  languageSub!: Subscription;
  uhOhText: string = '';
  okText: string = '';
  contactManagerText: string = '';
  constructor(
    private modalService: NgbModal,
    private logger: LogService,
    private language: LanguageService
  ) {}
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {
    this.uhOhText = this.language.getTextElement('titile_uh_oh');
    this.okText = this.language.getTextElement('lbl_ok');
    this.contactManagerText = this.language.getTextElement('please_contact_manager_order_type_is_not_available');
  }
  open() {
    this.modalRef = this.modalService.open(OrderTypeWarningComponent, {
      size: 'lg',
      centered: true,
    });
    this.modalRef.componentInstance.modalRef = this.modalRef;
  }

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  cancel() {
    var log = new loggingData(
      'Order Type Warning',
      'Order Types Not Available',
      'Configuration Error',
      'No Order Types Available',
      true
    );
    this.logger.sendLogToServer(log);
    this.modalRef.close();
  }
}
