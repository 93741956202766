import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { DatabaseHandler } from "../DatabaseHandler";
import { NCRVendorIntegrations } from "../models/BranchLoginModels";
import { Guid } from "../models/Guid";
import { ItemV2 as Item, ModifierIngredientV2 as Ingredient, ModifierV2 as Modifier } from "../models/item";
import { NcrFulfillmentReq, NcrOrderLineReq, NcrRequest } from "../models/NcrModels/NcrRequest";
import { NcrResponse } from "../models/NcrModels/NcrResponss";
//import { OrderTypeEnum } from "../models/OrderType";
import { CommonFunctions } from "./common";
import { GeneralSetting } from "./generalSetting.service";
import { HttpConnection, IHttpHeaders } from "./http.service";
import { IsKioskService } from "./is-kiosk.service";
import { loggingData, LogService } from "./log.service";
import { OrderDetailService } from "./order-detail.service";



@Injectable({
  providedIn: 'root',
})
export class NcrIntegration {

  private isKiosk: boolean = false;

  isNcrIntegration: boolean = false;

  ncrData: NCRVendorIntegrations = {} as NCRVendorIntegrations;

  ncrValidationResponse: NcrResponse = {} as NcrResponse;

  ncrOrderUpResponse: NcrResponse = {} as NcrResponse;

  constructor(private logger: LogService,
    private http: HttpClient,
    checkKiosk: IsKioskService,
    private orderDetail: OrderDetailService
  ) {

    this.getAndSetNcrIntegrationData();

    //NCRVendorIntegrations

    this.isKiosk = checkKiosk.isKiosk();
  }

  public async getAndSetNcrIntegrationData() {

    let data = await this.getNcrIntegrationDataSql();

    if (data && data.length > 0) {

      this.ncrData = data[0];

      this.isNcrIntegration = true;
    }
  }

  private getNcrIntegrationDataSql() {
    let query = "select * from NCRVendorIntegrations";

    return DatabaseHandler.getDataFromQuery<NCRVendorIntegrations[]>(query).toPromise<NCRVendorIntegrations[]>();
  }

  public doCallNcr(ncrReq: NcrRequest, isSubmitOrder: boolean = false) {

    const url = environment.apiUrl + "NCROrder";
    var headers = [] as IHttpHeaders[];
    headers.push({ key: 'Content-Type', value: 'application/Json', } as IHttpHeaders);
    headers.push({ key: 'Version', value: '1.0' } as IHttpHeaders);
    headers.push({ key: 'BranchID', value: GeneralSetting.getBranchId() } as IHttpHeaders);
    headers.push({ key: 'CompanyID', value: GeneralSetting.getCompanyId() } as IHttpHeaders);
    headers.push({ key: 'DeviceID', value: GeneralSetting.getSerialNo() } as IHttpHeaders);

    // const headers = new HttpHeaders()
    //   .set('Version', '1.0')
    //   .set('CompanyID', GeneralSetting.getCompanyId())
    //   .set('BranchID', GeneralSetting.getBranchId())
    //   .set('DeviceID', GeneralSetting.getSerialNo())

    ncrReq.Status = isSubmitOrder ? "OrderPlaced" : "ReadyForValidation";

    this.logger.sendLogToServer(new loggingData(
      `Ncr ${isSubmitOrder ? "Order Up" : "Validation"} Order Request`,
      `Ncr ${isSubmitOrder ? "Order Up" : "Validation"} Api Call
        Invoice No :- ${ncrReq.ReferenceId}
      `,
      'Request Log',
      JSON.stringify(ncrReq),
      true
    ));

    // this.http.post(url, ncrReq, { headers: headers });
    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(url)
      .addMethod('POST')
      .addHeader(headers)
      .addBody(ncrReq)
      .post();
  }

  public async getOrderData(orderInvoiceNo: string,
    orderTotal: number,
    cartItems: Item[],
    tip: any,
    discount: any,
    promo: any) {

    let fullfillMent = this.getOrderFullFillment();

    let customer = await this.getOrderCustomer();

    let paymentDetail = this.getOrderPayment(orderTotal, orderInvoiceNo);

    let orderLineItem = this.getOrderItems(cartItems);

    return {
      SourceOrganization: "grubbrr",
      Channel: "WalkIn",
      Comments: "",
      Currency: "USD",
      customer: customer,
      ErrorDescription: "",
      Fees: null,
      fulfillment: fullfillMent,
      OrderLines: orderLineItem,
      GroupMembers: null,
      Owner: "Grubbrr",
      Payments: paymentDetail,
      ReferenceId: orderInvoiceNo,
      AdditionalReferenceIds: null,
      Source: "Grubbrr",
      Status: "",
      TaxExempt: false,
      Taxes: null,
      TotalModifiers: null,
      Totals: null,
      PartySize: 0,
      Promotions: [],
      CheckInDetails: "",
      id: null
    }
  }

  private async getOrderCustomer() {

    let email = GeneralSetting.getCustomerEmail();

    let phone = GeneralSetting.getCustomerPhone();

    let name = await this.orderDetail.getCustomerNameForIntegration();

    return {
      Email: email,
      ExternalIds: null,
      FirstName: "0",
      Id: "",
      LastName: "",
      Name: name,
      Phone: phone,
      PhoneExtension: ""
    }
  }

  private getOrderFullFillment() {

    const orderType = this.getOrderType();

    return {
      Address: null,
      LeadTimes: "",
      Notes: "",
      PickupDate: "",
      PickupLocation: "",
      Status: "",
      StatusLabel: "",
      Type: orderType,
      TypeLabel: "",
      AutoRelease: true,
      Catering: false
    } as NcrFulfillmentReq;
  }

  private getOrderType() {
    //[Other, DineIn, Delivery, Curbside, Notification, Pickup, DriveThru] ncr supported order type
    const orderTypeID: any = GeneralSetting.getOrderTypeId() == "" ? "0" : GeneralSetting.getOrderTypeId();

    switch (orderTypeID) {
      case "1": return "DineIn";
      case "2": return "Pickup";
      case "3": return "Delivery";
      case "8": return "Curbside";
      case "10": return "DriveThru";
      default:
        return "Other";
    }
  }

  private getOrderPayment(orderTotal: number, orderInvoiceNo: string) {

    let txData = GeneralSetting.getPaymentResponse();

    let paymentDetail = this.orderDetail.getOrderPaymentDetails(txData ? JSON.parse(txData) : '', orderTotal, orderInvoiceNo);

    let type = this.getPaymentType();
    return [
      {
        Amount: orderTotal,
        Description: null,
        Gratuity: 0,
        LineId: null,
        MaskedPan: paymentDetail.maskpan,
        ReferenceId: paymentDetail.OrderID,
        Status: "Paid",
        SubType: type == 'Cash' ? null : "Prepaid",
        Token: paymentDetail.retref,
        Type: type,
        PayBalance: false,
        accountNumber: "",
        expiration: null,
        paymentTime: null
      }
    ]

  }
  private getPaymentType() {
    const paymentTypeID: any = GeneralSetting.getPaymentTypeID() == "" ? "0" : GeneralSetting.getPaymentTypeID();
    switch (paymentTypeID) {
      case "1": return "Cash";
      case "2": return "CreditDebit";
      default:
        return "Other";
    }
  }

  private getOrderItems(items: Item[]) {

    let orderLine: NcrOrderLineReq[] = [] as NcrOrderLineReq[];

    for (var i = 0; i < items.length; i++) {

      let item = items[i];

      if (item.IsCombo) {
        orderLine = orderLine.concat(this.getComboLineItems(item));
      }
      else {
        orderLine = orderLine.concat(this.getLineItem(item));
      }
    }

    return orderLine;
  }

  private getComboLineItems(item: Item) {

    let orderLine: NcrOrderLineReq[] = [] as NcrOrderLineReq[];

    //orderLine = orderLine.concat(this.getLineItem(item));

    if (item.ComboGroup && item.ComboGroup.length > 0) {

      for (var i = 0; i < item.ComboGroup.length; i++) {

        if (item.ComboGroup[i] && item.ComboGroup[i].Items && item.ComboGroup[i].Items.length > 0) {

          for (var j = 0; j < item.ComboGroup[i].Items.length; j++) {

            if (item.ComboGroup[i].Items[j].isSelected) {
              orderLine = orderLine.concat(orderLine.concat(this.getLineItem(item.ComboGroup[i].Items[j], item.ComboGroup[i].Items[j].guid)));
            }
          }
        }
      }
    }

    return orderLine;
  }

  private getLineItem(item: Item, parentGuid: string = "") {

    let orderLine: NcrOrderLineReq[] = [] as NcrOrderLineReq[];

    let itemCost = CommonFunctions.getItemPrice(item);

    let totalPrice = itemCost * (Number(item.Quantity ?? "1"));

    let itemOrderLine = {
      Comments: item.specialRequest,
      Description: item.Name,
      ExtendedAmount: totalPrice,
      ItemType: "MENU_ITEM",
      LineId: item.guid,
      Notes: item.specialRequest,
      ParentLineId: parentGuid,
      PriceModifiers: null,
      ProductId: {
        Type: "SKU",
        Value: Number(item.RefItemId ?? "0")
      },
      Quantity: {
        UnitOfMeasure: null,
        UnitOfMeasureLabel: null,
        Value: Number(item.Quantity)
      },
      ScanData: null,
      SubstitutionAllowed: false,
      SupplementalData: null,
      Taxes: null,
      UnitPrice: itemCost,
      ModifierCode: null,
      LinkGroupCode: null,
      FulfillmentResult: null,
      GroupMemberId: null,
      OverridePrice: false
    } as NcrOrderLineReq;

    orderLine.push(itemOrderLine);

    if (item.Modifiers && item.Modifiers.length > 0) {
      orderLine = orderLine.concat(this.getLineModifier(item.Modifiers, item.guid));
    }

    return orderLine;
  }

  private getLineModifier(modifiers: (Modifier | Ingredient)[], parentGuid: string) {

    let orderLine: NcrOrderLineReq[] = [] as NcrOrderLineReq[];

    for (let i = 0; i < modifiers.length; i++) {
      if (!modifiers[i].IsSelected) continue;

      if (modifiers[i]) {
        if (modifiers[i].IsModifier) {
          let modifier = modifiers[i] as Modifier;

          if (modifier.Ingredients && modifier.Ingredients.length > 0) {
            orderLine = orderLine.concat(this.getLineModifier(modifier.Ingredients, parentGuid));
          }
        }
        else {
          orderLine.push(this.getIngredientOrderItem(modifiers[i] as Ingredient, parentGuid));
        }
      }
    }

    return orderLine;
  }

  private getIngredientOrderItem(ing: Ingredient, parentGuid: string) {

    let itemCost = Number(ing.ExtraPrice);
    let totalPrice = itemCost * (Number(ing.Quantity ?? "1"));

    return {
      Comments: "",
      Description: ing.Name,
      ExtendedAmount: totalPrice,
      ItemType: "MENU_ITEM",
      LineId: CommonFunctions.getRandomGuid(),
      Notes: null,
      ParentLineId: parentGuid,
      PriceModifiers: null,
      ProductId: {
        Type: "SKU",
        Value: Number(ing.RefIngredientId ?? "0")
      },
      Quantity: {
        UnitOfMeasure: null,
        UnitOfMeasureLabel: null,
        Value: Number(ing.Quantity ?? "1")
      },
      ScanData: null,
      SubstitutionAllowed: false,
      SupplementalData: null,
      Taxes: null,
      UnitPrice: itemCost,
      ModifierCode: null,
      LinkGroupCode: null,
      FulfillmentResult: null,
      GroupMemberId: null,
      OverridePrice: false
    } as NcrOrderLineReq;
  }

  public clearNCR() {
    this.isNcrIntegration = false;
    this.ncrData = {} as NCRVendorIntegrations;
    this.ncrValidationResponse = {} as NcrResponse;
    this.ncrOrderUpResponse = {} as NcrResponse;
  }

}
