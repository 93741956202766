import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PrinterService } from 'src/app/services/printer.service';
import { CartService } from 'src/app/services/cart.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { loggingData, LogService } from 'src/app/services/log.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { CommonFunctions } from 'src/app/services/common';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-printing-progress-popup',
  templateUrl: './printing-progress-popup.component.html',
  styleUrls: ['./printing-progress-popup.component.css'],
})
export class PrintingProgressPopupComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];
  sendingOrderText: string = '';
  pleaseWaitWhileSendingToKitchenText: string = '';
  printingReceiptText: string = '';
  pleaseWaitForReceiptText: string = '';

  printOpenData: any = ";"

  constructor(
    public printerService: PrinterService,
    private cartService: CartService,
    private modalservice: NgbModal,
    private logger: LogService,
    private language: LanguageService
  ) {
    this.allSubsCription.push(
      language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }

  @Input() @Output() state: string = 'kitchen';
  modalReference: any;
  loadText() {
    this.sendingOrderText = this.language.getTextElement(
      'sending_order_to_the_kitchen'
    );
    this.pleaseWaitWhileSendingToKitchenText = this.language.getTextElement(
      'please_wait_while_we_send_your_order_to_the_kitchen'
    );
    this.printingReceiptText = this.language.getTextElement('printing_your_receipt')
    this.pleaseWaitForReceiptText = this.language.getTextElement('please_wait_for_your_receipt_to_print')
  }

  ngOnInit(): void {
    this.kitchenPrint(this.printOpenData);
  }

  public static open(modalservice: NgbModal, res: boolean) {
    //console.log('open the printing popup');
    let modalReference = modalservice.open(
      PrintingProgressPopupComponent,
      {
        backdrop: 'static',
        centered: true,
        size: 'lg',
      }
    );
    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.printOpenData = res;
    return modalReference;
  }

  open(res: boolean) {
    //console.log('open the printing popup');
    let modalReference = this.modalservice.open(
      PrintingProgressPopupComponent,
      {
        backdrop: 'static',
        centered: true,
        size: 'lg',
      }
    );
    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.printerService = this.printerService;
    modalReference.componentInstance.cartService = this.cartService;
    modalReference.componentInstance.printOpenData = res;
    this.modalReference = modalReference;
  }

  close() {
    if (this.modalReference) {
      this.modalReference.close();
    }
  }

  private kitchenPrintSubscription() {
    return new Promise<boolean>((resolve, reject) => {
      this.allSubsCription.push(
        this.printerService.kitchenDataCreated.pipe(timeout(20000)).subscribe(data => {
          try {
            resolve(data);
          }
          catch (ex) {
          }
        },error => {
          try {
            resolve(false);
          }
          catch (ex) {
          }
        })
      );
    });
  }

  async kitchenPrint(printCus: boolean) {
    //console.log('kitchen printing');
    var data = this.cartService.getCartItems();
    this.printerService.resetState();

    // if (printCus) { //Comment this condition becase we need to print token receipt if customer says NO for his print
    this.state = 'customer';
    await this.customerPrint(printCus).then();

    // }
    this.state = 'kitchen';

    this.printerService.PrintKitchen(data);

    await this.kitchenPrintSubscription().then();

    await CommonFunctions.delay(4000);

    this.modalReference.close('success');
    // var state = this.printerService.PrintKitchen(data).subscribe((state) => {
    //   //console.log(
    //     `printerService.PrintKitchen subscrition result: ${JSON.stringify(
    //       state
    //     )}`
    //   );
    //   if (state == 'success') {
    //     // print next
    //     //console.log(`PrintKitchen RESOLVE: success`);
    //       this.modalReference.close('success');
    //     this.state = 'customer';
    //     this.customerPrint();
    //   } else {
    //     //console.log(`PrintKitchen RESOLVE: failure`);
    //       this.modalReference.close('success');
    //     this.state = 'failureKitchen';
    //     this.customerPrint();
    //   }
    // });
  }

  customerPrint(printCus: boolean) {
    return new Promise<boolean>((resolve, reject) => {
      var data = this.cartService.getCartItems();
      this.allSubsCription.push(
        this.printerService.PrintCustomer(data, printCus).subscribe((state) => {
          // console.log(
          //   `printerService.customerPrint subscrition result: ${JSON.stringify(state)}`
          // );
          var log = new loggingData(
            'Customer Receipt',
            'Customer Receipt Print Status',
            'Print Confirmation Status',
            `Customer Receipt Print Status is ${state} for Invoice ${GeneralSetting.getInvoiceNoToDb()}`,
            true
          );
          this.logger.sendLogToServer(log);
          if (state == 'success') {
            //console.log(`customerPrint RESOLVE: success`);
            if (this.modalReference) {

              this.modalReference.close();
            }
            resolve(true);
          }
          else {
            resolve(false);
          }
        })
      );
    });
  }

  printThis() {
    //console.log(this.state);
  }
}
