import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-refresh-data-loading',
  templateUrl: './refresh-data-loading.component.html',
  styleUrls: ['./refresh-data-loading.component.css'],
})
export class RefreshDataLoadingComponent implements OnInit {
  ref: any;
  currentStateText: string = "";
  refreshingDataText:string=''
  languageSub!:Subscription
  constructor(private modalService: NgbModal,private language:LanguageService) {}

  ngOnInit(): void {
    this.languageSub=this.language.localeCommunicator.subscribe((val)=>{
      this.loadText()
    })
  }
  loadText(){
  this.refreshingDataText=this.language.getTextElement('refreshing_data')
  }
  open() {
    let ref = this.modalService.open(RefreshDataLoadingComponent, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });
    // console.log('open')
    ref.componentInstance.ref = ref;
    this.ref = ref;
    return ref;
  }
  clear() {
    this.modalService.dismissAll('');
  }
}
