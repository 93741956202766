<div id="emailreceipt-popup" role="dialog">
    <div class="header">
        <h3 class="text-center grey-title">{{headerText}}</h3>
    </div>

    <div class="middle-image">
        <img src="assets/images/ic_email_receipt.png" onerror="this.onerror=null; this.src='assets/images/Image_21.png'" class="img-responsive" />
    </div>

    <div class="main-text text-center">
        <h2>{{mainText}}</h2>
    </div>

    <div class="secondary-text text-center">
        <p>{{secondaryText}}</p>
    </div>

    <div class="finsihtext  text-center " (click)="dismissModal()">
        <button class="green-btn background-positive-button-color positive-button-text-color">{{finishText}}</button>
    </div>

</div>