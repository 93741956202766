<!-- <-- This is a duplicate modal, delete it. -->

<div id="alcoholic-exceed" class="alcoholic-exceed">
    <div class="header text-center">
        <h1>{{uhOhText}}</h1>
    </div>
    <div class="caption text-center">
        <p>{{alcoholicLimitText}}</p>
    </div>

    <div class="footer text-center" (click)="dismiss()">
        <p>{{closedText}}</p>
    </div>
</div>