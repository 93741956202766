import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BranchLoginStreamServiceService } from 'src/app/services/branch-login-stream-service.service';
import { DataService } from 'src/app/services/data.service';
import { FlexPairingService } from 'src/app/services/flex-pairing.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { DownloadingPopupService } from '../downloading-popup/downloading-popup.service';

@Component({
  selector: 'app-flex-pairing',
  templateUrl: './flex-pairing.component.html',
  styleUrls: ['./flex-pairing.component.css'],
})
export class FlexPairingComponent implements OnInit {
  modalReference: any;
  constructor(private modal: NgbModal, private flexPair: FlexPairingService,private branchLoginStreamService:BranchLoginStreamServiceService,private downloadPopupService:DownloadingPopupService, ){}
  title: string = '';
  bodya: string = '';
  bodyb: string = '';
  imageSrc: string = '';
  buttonOneText: string = '';
  buttonTwoText: string = '';

  deviceId: string=GeneralSetting.getDeviceHSN().toString()
  merchantId: string=GeneralSetting.getMerchantId().toString()
  accessToken:string= GeneralSetting.getAccessToken().toString()
  friendlyId: string=GeneralSetting.getFriendlyId().toString()
  ngOnInit(): void {}
  public static open(modalService: NgbModal) {
    let modalReference = modalService.open(FlexPairingComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;

    return modalReference;
  }
  async retry() {
    if (this.flexPair.state === '2') {
      this.finish();
    } else {
      
      let oldHsn:string = GeneralSetting.getDeviceHSN();
      let counter : number = 0;

      await this.branchLoginStreamService.doLogin(GeneralSetting.getBranchLoginUserId(),GeneralSetting.getBranchLoginPass(),GeneralSetting.getSerialNo(),this.downloadPopupService)
      this.deviceId=GeneralSetting.getDeviceHSN().toString()
      this.merchantId=GeneralSetting.getMerchantId().toString()
      this.accessToken=GeneralSetting.getAccessToken().toString()
      this.friendlyId=GeneralSetting.getFriendlyId().toString()
      // Ugly,but wait for our settings to change
      this.flexPair.setWaiting();
      this.waitAndTest(counter, oldHsn)
    }
  }

  waitAndTest(counter : number, oldHsn : string) {
    if(counter >= 5) {
      this.flexPair.callPing();
      return;
    }

    setTimeout(() =>{
      let newHsn : String = GeneralSetting.getDeviceHSN();
      if(newHsn !== oldHsn) {
        this.flexPair.callPing();
      }
      else {
        counter++;
        this.waitAndTest(counter, oldHsn);
      }
    }, 1000);
  }

  finish() {
    this.flexPair.closeFlexPairingModal();
  }
  startPairing() {}
}
