import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemV2 as Item, ItemV2, ModifierV2 } from 'src/app/models/item';
import { CartService } from 'src/app/services/cart.service';
import { environment } from 'src/environments/environment';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import {
  CategorySalesType,
  CommonFunctions,
  Snackbar,
} from 'src/app/services/common';
import { AlcoholicItemExceedMaxComponent } from 'src/app/components/dialogs/alcoholic-item-exceed-max/alcoholic-item-exceed-max.component';
import { UpsellComboMessageComponent } from 'src/app/components/dialogs/upsell-combo-message/upsell-combo-message.component';
import { ComboItemPopupComponent } from 'src/app/components/combo-item-popup/combo-item-popup.component';
import { AlcoholicOver21DialogComponent } from 'src/app/components/dialogs/alcoholic-over21-dialog/alcoholic-over21-dialog.component';
import { OutOfStockComponent } from '../../out-of-stock/out-of-stock.component';
import { SoldOutComponent } from '../sold-out/sold-out.component';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { DataService } from 'src/app/services/data.service';
import { loggingData, LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-order-level-upsell-dialog',
  templateUrl: './order-level-upsell-dialog.component.html',
  styleUrls: ['./order-level-upsell-dialog.component.css'],
})
export class OrderLevelUpsellDialogComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  languageSub!: Subscription;
  upsellHeaderText: string | null = "Don't miss out on";

  positiveColor: string = '';

  positiveColorText: string = '';

  negativeColor: string = '';

  negativeColorText: string = '';

  priceColor: string | null = '';

  ref: any;

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  item: Item = {} as Item;

  @ViewChild(ComboItemPopupComponent) itemPopupOpen!: ComboItemPopupComponent;

  @ViewChild(AlcoholicOver21DialogComponent)
  alcoholic21pop!: AlcoholicOver21DialogComponent;

  @ViewChild(AlcoholicItemExceedMaxComponent)
  alcoholicExceedMax!: AlcoholicItemExceedMaxComponent;

  @ViewChild(UpsellComboMessageComponent)
  comboMessage!: UpsellComboMessageComponent;

  maxAlcoholAmount: number = 9999;

  //upSellPrice:number = 0;

  @ViewChild(OutOfStockComponent) outOfStockComponent!: OutOfStockComponent;

  @ViewChild(SoldOutComponent) soldOutModal!: SoldOutComponent;

  imageRadius: string = '';
  bordersTypeItem: boolean = false;
  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();
  addToOrderText: string = '';
  goToCheckoutText: string = '';
  requirementsNotMetText: string = '';
  placeholderImage : string = '';

  constructor(
    private readonly modalService: NgbModal,
    private readonly cartService: CartService,
    private readonly isKioskService: IsKioskService,
    private language: LanguageService,
    private readonly dataService: DataService,
    private logger: LogService,
  ) {}

  loadText() {
    this.addToOrderText = this.language.getTextElement('lbl_rec_add_to_order');
    this.requirementsNotMetText = this.language.getTextElement(
      'requirements_not_met'
    );
    this.goToCheckoutText = this.language.getTextElement(
      'lbl_rec_go_to_checkout'
    );
    const upsellOverrideText = this.language.getCustomMapValues('menu_setting_display__upsell_modal_header_text');
    if(upsellOverrideText && upsellOverrideText !== '') {
      this.upsellHeaderText = upsellOverrideText;
    } else {
      this.upsellHeaderText =
      GeneralSetting.getUpSellHeaderText() == ''
        ? "Don't miss out on "
        : GeneralSetting.getUpSellHeaderText();
    }
  }

  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });

    this.positiveColor = GeneralSetting.getPositiveColor();
    this.positiveColorText = GeneralSetting.getPositiveColorText();
    this.negativeColor = GeneralSetting.getNegativeColor();
    this.negativeColorText = GeneralSetting.getNegativeColorText();
    this.priceColor = GeneralSetting.getPriceColor();

    this.maxAlcoholAmount = Number(
      GeneralSetting.getMaxAlcoholicItemsPerOrder()
    );

    this.bordersTypeItem =
      GeneralSetting.getBorderTypeItems().toLowerCase() == 'true';

    this.imageRadius = GeneralSetting.getBorderPercentage();
  }

  public static open(
    modalService: NgbModal,
    item: Item,
    variableUpSell: boolean,
    modifiableUpSell: boolean
  ) {
    const upSellItem = JSON.parse(JSON.stringify(item));
    let ref = null;

    if (!modalService.hasOpenModals()) {
      const ref = modalService.open(OrderLevelUpsellDialogComponent, {
        backdrop: 'static',
        size: 'lg',
        centered: true,
      });

      ref.componentInstance.item = upSellItem;
      ref.componentInstance.variableUpSell = variableUpSell;
      ref.componentInstance.modifiableUpSell = modifiableUpSell;

      ref.componentInstance.ref = ref;
      return ref;
    }
    return ref;
  }

  open(item: Item, variableUpSell: boolean, modifiableUpSell: boolean) {
    const upSellItem = JSON.parse(JSON.stringify(item));
    if (!this.modalService.hasOpenModals()) {
      const ref = this.modalService.open(OrderLevelUpsellDialogComponent, {
        backdrop: 'static',
        size: 'lg',
        centered: true,
      });

      this.item = upSellItem;
      this.item.Quantity = '1';
      ref.componentInstance.item = upSellItem;
      ref.componentInstance.variableUpSell = variableUpSell;
      ref.componentInstance.modifiableUpSell = modifiableUpSell;
      let reccomend= this.language.getTextElement('title_recommendation')
      ref.componentInstance.upsellHeaderText =
        this.upsellHeaderText == ''
          ? reccomend
          : this.upsellHeaderText;
      ref.componentInstance.ref = ref;
      this.ref = ref;
      return ref;
    }
    return this.ref;
  }

  private onAlcoholicItem(item: Item) {
    AlcoholicOver21DialogComponent.open(this.modalService).result.then(
      async (data: any) => {
        if (data === 'over') {
          if (Number(item.Quantity) <= this.maxAlcoholAmount) {
            if (CommonFunctions.isItemHasModifier(item)) {
              this.openItemPopup(item);
            } else {
              await this.cartService.addToCartAsync(item);
              // Moved this increment to Cart services's checkAlcohol method - Rekha
              // const currentAlcohol = Number(GeneralSetting.getCurrentCountofAlkol());
              // GeneralSetting.setCurrentCountofAlkol(Number(currentAlcohol + Number(item.Quantity)).toString() );
              this.dismiss();
            }
          }
        }
      }
    );
  }

  private openItemPopup(item: Item) {
    const itemTypeStr = CommonFunctions.getItemTypeV2(item);
    let itemType = 1;

    if (itemTypeStr == 'ItemBuilderMode') {
      itemType = 2;
    }

    const inputItem = JSON.parse(JSON.stringify(item));

    this.itemPopupOpen.open(inputItem, itemType);

    this.itemPopupOpen.modalReference.result.then(async (data: any) => {
      
      if (data != 'cancel') {
        const upsellItem = data as Item;

        var log = new loggingData(
          `Order details: Upsells Added`,
          `Order details: Upsells Added`,
          `order-level-upsell-dialog.component.ts`,
          `Order details: Discounts Applied -  ${JSON.stringify(upsellItem)}`,
          true
        );
        this.logger.sendLogToServer(log);
        await this.cartService.addToCartAsync(upsellItem);
        // Moved this increment to Cart services's checkAlcohol method - Rekha
        // const currentAlcohol = Number(GeneralSetting.getCurrentCountofAlkol());
        // GeneralSetting.setCurrentCountofAlkol(Number(currentAlcohol + Number(upsellItem.Quantity)).toString() );
        this.dismiss();
      }
    });
  }

  checkItem86() {
    CommonFunctions.checkForSoldOutV2(this.item).then((data: ModifierV2[]) => {
      if (data.length > 0) {
        if (GeneralSetting.getIsmDoNotAllowAddToCartKiosk() == 'True') {
          this.outOfStockComponent.open(1);
        } else {
          this.soldOutPath(data, this.item);
        }
      } else {
        this.upsellAdd();
      }
    });
  }

  async upsellAdd() {
    
    this.item.IsUpSellItem = true;
    this.item.UpSellItemID = this.item.ItemID;
    this.item.UpSellItemGuid = this.item.guid;
    this.item.UpSellItemPrice = CommonFunctions.getItemPrice(this.item);

    if (CommonFunctions.isItemHasModifier(this.item)) {
      if (
        this.item.ItemCategorySalesTypeID ==
        CategorySalesType.Alcohol.toString()
      ) {
        if (Number(this.item.Quantity) <= this.maxAlcoholAmount) {
          this.onAlcoholicItem(this.item);
        } else {
          let error=this.language.getTextElement('max_alcohol_limit')
          Snackbar.show(error, 2500);
        }
      } else {
        this.openItemPopup(this.item);
      }
    } else {
      if (
        this.item.ItemCategorySalesTypeID ==
        CategorySalesType.Alcohol.toString()
      ) {
        if (Number(this.item.Quantity) <= this.maxAlcoholAmount) {
          this.onAlcoholicItem(this.item);
        } else {
          let error=this.language.getTextElement('max_alcohol_limit')
          Snackbar.show(error, 2500);
        }
      } else {
        await this.cartService.addToCartAsync(this.item);
        this.dismiss();
      }
    }
  }

  dismiss() {
    this.ref.close('cancel');
  }

  // Show dialog of 'Allow Add item to cart'
  private soldOutPath(data: ModifierV2[], item: ItemV2) {
    this.soldOutModal.open(data, item);
    this.soldOutModal.modalReference.result.then((data: string) => {
      if (data == 'continue') {
        this.upsellAdd();
      }
      //else{}
      //no else block
      //if reached, user selected back to menu
    });
  }
}
