import { Component, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoutConfirmationDialogComponent } from 'src/app/components/dialogs/logout-confirmation-dialog/logout-confirmation-dialog.component';
import { ManagerPinDialogComponent } from '../manager-pin-dialog/manager-pin-dialog.component';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { Subscription } from 'rxjs';
import { loggingData, LogService } from 'src/app/services/log.service';
import { ComingSoonComponent } from '../coming-soon/coming-soon.component';
import { SaveSettingsService } from 'src/app/services/save-settings.service';
import { RefreshDataLoadingComponent } from '../dialogs/refresh-data-loading/refresh-data-loading.component';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { DatabaseService } from 'src/app/services/database.service';
import { PusherService } from 'src/app/services/pusher.service';
import { SettingsItemsComponent } from './settings-items/settings-items.component';
import { LanguageService } from 'src/app/services/language.service';
import { LoginService } from 'src/app/services/login.service';
import { RetryConfirmationDialogComponent } from '../dialogs/retry-confirmation-dialog/retry-confirmation-dialog.component';
import { PreloaderService } from 'src/app/services/preloader.service';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit, OnDestroy {

  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  deviceInfo: boolean = false;

  generalSettings: boolean = true;

  reports: boolean = false;

  zreport: boolean = false;

  refreshRunning: boolean = false;

  refreshRequired: boolean = false;

  itemwise: boolean = false;

  grosssales: boolean = false;

  orderhistory: boolean = false;

  concessionaireShow: boolean = false;

  doMenuUpdate: boolean = false;

  items: boolean = false;
  storeTimings: boolean = false;
  refreshData: boolean = false;
  payment: boolean = false;
  healthLogs: boolean = false;

  doRefreshDB: boolean = true;

  dt: Date = new Date();
  @Output() zReportData: any;
  totalDownloadSets: number = 0;
  totalDownloadSetsQueued: number = 0;
  downloadUrls: string[] = [];
  isHideZReport: boolean = GeneralSetting.getHideZReport() == 'True';

  @ViewChild(RefreshDataLoadingComponent)
  downloadModalRef!: RefreshDataLoadingComponent;

  @ViewChild(LogoutConfirmationDialogComponent)
  logoutModal!: LogoutConfirmationDialogComponent;

  @ViewChild(ManagerPinDialogComponent)
  managerPin!: ManagerPinDialogComponent;

  @ViewChild(ComingSoonComponent)
  comingSoon!: ComingSoonComponent;

  @ViewChild(SettingsItemsComponent)
  settingItemComponent!: SettingsItemsComponent;

  dataService: DataService;
  dataDate: string = '';

  languageSub!: Subscription;
  settingsText: string = '';
  deviceInfoText: string = '';
  launchKioskText: string = '';
  itemSalesText: string = '';
  paymentText: string = '';
  printerText: string = '';
  zReportText: string = '';
  grossSalesText: string = '';
  orderHistoryText: string = '';
  healthLogsText: string = '';
  itemsText: string = '';
  storeTimingText: string = '';
  logoutText: string = '';
  exportDBText: string = '';
  lastSyncText: string = '';
  refreshingMenuText: string = '';
  concessionaireText: string = '';
  refreshDBText: string = '';
  refreshDisable: boolean = false;

  constructor(
    DataService: DataService,
    public modalService: NgbModal,
    private router: Router,
    private logger: LogService,
    private saveSettingsService: SaveSettingsService,
    private db: DatabaseService,
    private language: LanguageService,
    private loginService: LoginService,
    private preloader: PreloaderService,
  ) {
    this.dataService = DataService;
    this.bindPusher();
    this.dataDate = localStorage.getItem('syncDate') || '';
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    )
  }
  loadText() {
    this.settingsText = this.language.getTextElement('action_settings')
    this.deviceInfoText = this.language.getTextElement('lbl_device_info')
    this.launchKioskText = this.language.getTextElement('lbl_launch_kiosk_text')
    this.paymentText = this.language.getTextElement('payment')
    this.printerText = this.language.getTextElement('lbl_printers')
    this.zReportText = this.language.getTextElement('txt_z_report')
    this.grossSalesText = this.language.getTextElement('lbl_reports_gross_sales')
    this.orderHistoryText = this.language.getTextElement('lbl_reports_order_history')
    this.healthLogsText = this.language.getTextElement('lbl_health_logs');
    this.itemsText = this.language.getTextElement('txt_items')
    this.storeTimingText = this.language.getTextElement('lbl_store_timing')
    this.logoutText = this.language.getTextElement('txt_logout')
    this.exportDBText = this.language.getTextElement('lbl_export_db')
    this.lastSyncText = this.language.getTextElement('text_last_sync_on')
    this.refreshingMenuText = this.language.getTextElement('refreshing_menu')
    this.concessionaireText = this.language.getTextElement('concessionaire')
    this.refreshDBText = this.language.getTextElement('refresh_db')
    this.itemSalesText = this.language.getTextElement('item_sales')
  }
  ngOnInit(): void {
    this.setGeneralSettings();
    this.zReport();

    // this.reloadSelf();
  }

  // TODO commenting this out for now since it is breaking the kiosk
  // reloadSelf() {
  //   this.reloadSelfSub = this.saveSettingsService.reloadSelfSubject.subscribe(shouldReload => {
  //     if (shouldReload) {
  //       window.location.reload();
  //     }
  //   });
  // }

  zReport() {
    // this.none = false;
    // this.itemwise = false;
    // this.orderhistory = false;
    // this.grossSales = false;
    // this.zreport = true;

    // set current date as display date
    // this.dateDisplay = new Date().toDateString()
    // do post request with current date
    this.allSubsCription.push(this.dataService
      .getZreport(this.dt.toDateString(), this.dt.toDateString())
      .subscribe(
        (val) => {
          this.zReportData = val;

          var log = new loggingData(
            'Z Report',
            'Success',
            'General',
            'Z report from cloud are successful',
            false
          );

          this.logger.sendLogToServer(log);
        },
        (response) => {
          var log = new loggingData(
            'POS Z report',
            'POS Z report Api Failed',
            'POS Z report',
            response,
            true
          );

          this.logger.sendLogToServer(log);
        },
        () => { }
      )
    );
  }

  openLogoutConfirmation() {
    this.logoutModal.open();
    this.logoutModal.modalReference.result.then(
      (result: any) => { },
      (reject: any) => { }
    );
  }

  private setAllFalse() {
    this.deviceInfo = false;
    this.generalSettings = false;
    this.reports = false;
    this.items = false;
    this.storeTimings = false;
    this.refreshData = false;
    this.payment = false;
    this.zreport = false;
    this.itemwise = false;
    this.grosssales = false;
    this.orderhistory = false;
    this.concessionaireShow = false;
    this.healthLogs = false;
  }

  setDeviceInfo() {
    this.setAllFalse();

    this.deviceInfo = true;
  }

  setGeneralSettings() {
    this.setAllFalse();

    this.generalSettings = true;
  }

  setReports() {
    this.setAllFalse();

    this.reports = true;
  }

  setZR() {
    this.setAllFalse();
    this.zreport = true;
  }

  setGS() {
    this.setAllFalse();
    this.grosssales = true;
  }

  setIS() {
    this.setAllFalse();

    this.itemwise = true;
  }

  setOH() {
    this.setAllFalse();
    this.orderhistory = true;
  }

  setHL() {
    this.setAllFalse();
    this.healthLogs = true;
  }

  setItems() {
    this.setAllFalse();

    this.items = true;
  }

  setStoreTiming() {
    this.setAllFalse();

    this.storeTimings = true;
  }

  setRefreshData() {
    this.setAllFalse();

    this.refreshData = true;
  }

  setPayment() {
    this.setAllFalse();

    this.payment = true;
  }

  setConcessionaire() {
    this.setAllFalse();

    this.concessionaireShow = true;
  }

  Login() {
    this.router.navigateByUrl('/passcode');
  }

  LaunchKiosk() {
    if (!this.saveSettingsService.stillRunning) {
      if (this.doMenuUpdate) {
        this.db.removeCategories();
      }
      setTimeout(() => {
        this.router.navigateByUrl('/touchToStart');
      }, 300);
    } else {
      //Added this comment for ticket SK-1820 by Yash Patel
      console.log("On menu sync Launch kiosk and refresh DB got stuck. line 295-setting.component.ts");
    }
  }

  private bindPusher() {
    this.allSubsCription.push(
      this.db.MenuRefresh.subscribe(async (x) => {
        if (x) {
          if (!this.refreshRunning) {
            await this.refershDB()
          } else {
            this.refreshRequired = true;
          }
        }
      })
    );
  }

  private async isrefreshRequired() {
    if (this.refreshRequired) {
      await this.refershDB()
      this.refreshRequired = false;
    }
  }

  private async refershDB() {
    this.refreshRunning = true;
    this.logger.sendLogToServer(
      new loggingData(
        'Refresh Data Pusher',
        'Refresh Settings Data Received',
        'Refresh Settings Data Pusher',
        `Settings Menu Refresh Received`,
        true
      )
    );

    this.dataDate = new Date().toLocaleString();
    localStorage.setItem("syncDate", this.dataDate);

    var refreshData = await this.loginService.doRefresh();
    if (!refreshData) {
      RetryConfirmationDialogComponent.open(this.modalService)
    }

    this.refreshRunning = false;
    this.isrefreshRequired()
  }

  async doRefresh() {
    this.refreshRunning = true;
    this.refreshDisable = true
    setTimeout(() => {
      this.refreshDisable = false
    }, 5000);
    this.dataDate = new Date().toLocaleString();
    localStorage.setItem("syncDate", this.dataDate);

    var refreshData = await this.loginService.doRefresh();
    if (!refreshData) {
      RetryConfirmationDialogComponent.open(this.modalService)
    }
    this.refreshRunning = false;
    this.isrefreshRequired()
  }

  exportDatabase() {
    this.comingSoon.open();
  }

  updateMenu(event: any) {
    this.doMenuUpdate = event;
  }

  setRefreshDBFlag(event: any) {
    this.doRefreshDB = event;
  }

  refershZzReport(e: any) {
    this.zReport();
  }

  ngAfterViewInit() {
    this.preloader.hide();
  }

}
