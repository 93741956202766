<div id="alcoholicitems">
    <div class="modal-body alcoholicitems-modal">
        <div class="content">
            <h3 class="text-center">{{registerDeviceNameText}}</h3>
            <div class="row">
                <h4 class="text-left">{{nameDeviceText}}</h4>
                <input KeyboardCustom [readonly]="isKiosk" customLength="350" id="deviceName" placeholder="e.g. Lobby" [(ngModel)]="deviceName">
            </div>
            <div class="modal-footer">
                <div class="col-12 col-md-12 popup-footer text-center row d-flex justify-content-end">
                    <!-- <button matRipple class="white-btn warn text-uppercase" (click)="onSkip()">{{skipText}}</button> -->
                    <button matRipple class="green-btn text-uppercase" (click)="onNext()">{{nextText}}</button>
                </div>
            </div>
        </div>
    </div>
</div>