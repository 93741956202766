<div id="alcoholic-exceed" class="alcoholic-exceed">
  <div class="header text-center">
    <h1>{{uhOhText}}</h1>
  </div>
  <div class="caption text-center">
    <p>
      {{alcoholicLimitText}}
    </p>
  </div>
  <div class="centered" style="display: flex; justify-content: center">
    <button
      class="white-btn centered"
      (click)="dismiss()"
      [style.background]="negativeColor"
      [style.color]="negativeColorText"
    >
      {{closeText|uppercase}}
    </button>
  </div>
</div>
