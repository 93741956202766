<section>
    <div class="scan" role="document">
        <div class="payment-modal">
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close('cancel')"  >
            <span aria-hidden="true">&times;</span>
        </button> -->
            <h2 class="text-center">{{checkoutText|uppercase}}</h2>


            <h3 class="text-center">{{title}}</h3>
            <h5 *ngIf="status==2" class="balance">
                {{label_available_balance}}
                <span>{{availableBalance | CurrencyPipe}}</span>
            </h5>
            <h4 class="text-center">{{subTitle}}</h4>
            <div *ngIf="status==1" class="d-flex justify-content-center" style="margin-top: 5rem;">
                <div class="spinner-border" style="width: 15rem; height: 15rem;border-width: 10px;" role="status">
                    <span class="sr-only">{{loadingText}}</span>
                </div>
            </div>

            <div *ngIf="status==2" class="col-md-12 col-xs-12 p-0 insert-img">
                <p class="text-center font-size6 tmargin6">{{yourTotalText}}</p>
                <p class="text-center font-size10 bold">{{total | CurrencyPipe }}</p>
            </div>
            <!-- <button>PROCEED WITH PAYMENT</button>
            <button></button> -->

            <!-- <div class="modal-footer"> -->
            <div *ngIf="status==2" class="col-md-12 col-xs-12 p-0 text-center">
                <button
                    class="btn positive-button-text-color background-positive-button-color border-positive-button-color text-uppercase"
                    data-dismiss="modal" aria-label="Close" type="submit" name="submits" (click)="close('continue')">
                    {{proceedWithPaymentText|uppercase}}
                </button>
                <br />
                <button
                    class="btn warn text-uppercase negative-button-text-color background-negative-button-color border-negative-button-color"
                    data-dismiss="modal" aria-label="Close" (click)="close('cancel')">
                    {{cancelText|uppercase}}
                </button>
            </div>
            <!-- </div> -->

        </div>
    </div>

</section>