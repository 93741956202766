import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { EmailReceiptThankyouDialogComponent } from '../email-receipt-thankyou-dialog/email-receipt-thankyou-dialog.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/services/cart.service';
import { EatOptionsService } from 'src/app/services/eat-options.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { CurrencyPipe } from 'src/app/CustomTools/currency.pipe';
import { CurrencyService } from 'src/app/services/currency.service';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { UserService } from 'src/app/services/user.service';
import { GeneralSetting } from '../../../services/generalSetting.service';
import { ItemV2, ModifierIngredientV2, ModifierV2 } from 'src/app/models/item';
import { CommonCalculations, CommonFunctions } from 'src/app/services/common';
import { loggingData, LogService } from 'src/app/services/log.service';
import { OLOService } from '../../../services/olo.service';
import { VariationOption } from '../../../models/Variations';
import { ShippingService } from 'src/app/services/shipping.service';
import {
  Integration,
  IntegrationService,
} from 'src/app/services/integration.service';
import { DatabaseService } from '../../../services/database.service';
import { OracleService } from '../../../services/oracle.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { LanguageService } from 'src/app/services/language.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { LoyaltyType } from 'src/app/models/loyaltyModal';
import { EPaymentType } from 'src/app/models/payment-type';

@Component({
  selector: 'app-email-receipt-dialog',
  templateUrl: './email-receipt-dialog.component.html',
  styleUrls: ['./email-receipt-dialog.component.css'],
})
export class EmailReceiptDialogComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  ref: any;

  emailform!: FormGroup;

  submitted = false;

  userEmail: string = '';

  items = this.CartService.getCartItems();

  CurrencySymbol = this.CurrencyService.CurrencySymbol;

  subtotal = this.CartService.calculateSubtotal();

  upchargeAmount = this.CartService.getUpchargeAmount();

  total = this.CartService.total;

  pipe = new DatePipe('en-US');

  dpipe = new DecimalPipe('en-US');

  cpipe = new CurrencyPipe(this.CurrencyService);

  itemized = ``;

  tax = 0;

  isLoyalty: boolean = false;

  pointsEarned: number = 0;
  isShowPoints: boolean = true;
  visitCount: number = 0;

  totalLoyaltyPoints: number = 0;

  reward = '';

  rewardRedeem = '';

  isrewardRedeem: boolean = false;

  invoiceno = GeneralSetting.getInvoiceNoToDb() || '0';

  isKiosk = this.isKioskService.isKiosk();

  token: any;
  TaxSchema!: Map<string, Array<any>>;
  discount = this.CartService.discount;

  baseImageUrl = environment.imageBaseUrlRemote;
  isConcessionaire: boolean = false;

  isLoyaltyUsed: boolean = false;

  taxType: string = '';

  isValid = false;

  integrationID: number;
  cancelText: string = '';
  emailText: string = '';
  emailInvalidText: string = '';
  emailReceiptText: string = '';
  emailReceiptMessageText: string = '';
  emailRequiredText: string = '';

  negativeButton: string | null = '';
  negativeButtonText: string | null = '';

  constructor(
    public modalService: NgbModal,
    private isKioskService: IsKioskService,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private router: Router,
    private CartService: CartService,
    private EatOptionService: EatOptionsService,
    private CurrencyService: CurrencyService,
    private loyaltyService: LoyaltyService,
    private userService: UserService,
    private logger: LogService,
    private olo: OLOService,
    private shipping: ShippingService,
    private readonly db: DatabaseService,
    private integration: IntegrationService,
    private oracle: OracleService,
    private KeyboardService: KeyboardService,
    private language: LanguageService
  ) {
    this.integrationID = integration.integration;
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }
  loadText() {
    this.cancelText = this.language.getTextElement('lbl_button_cancel');
    this.emailText = this.language.getCustomMapValues(
      'menu_setting_receipt__email_receipt_button_text'
    );
    this.emailInvalidText = this.language.getTextElement(
      'txt_email_address_is_invalid'
    );
    this.emailReceiptText = this.language.getTextElement('email_receipt');
    this.emailReceiptMessageText = this.language.getTextElement(
      'email_receipt_message'
    );
    this.emailRequiredText = this.language.getTextElement('email_is_required');
  }
  ngOnInit(): void {
    this.negativeButton = GeneralSetting.getNegativeColor();
    this.negativeButtonText = GeneralSetting.getNegativeColorText();
    
    this.userEmail = GeneralSetting.getCustomerEmail();
    this.invoiceno = GeneralSetting.getInvoiceNoToDb();
    this.isConcessionaire =
      GeneralSetting.getIsConcessionaire().toLowerCase() == 'true';

    this.taxType = GeneralSetting.getTaxType().toLowerCase();

    this.emailform = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.isLoyaltyEnable();

    this.TaxSchema = new Map<string, Array<any>>();

    this.db.gatherAllTaxesFromSql().then((taxGroups: any) => {
      taxGroups.forEach((taxGroup: any) => {
        if (this.TaxSchema.get(taxGroup.TaxGroupName)) {
          this.TaxSchema.get(taxGroup.TaxGroupName)?.push([
            taxGroup.TaxName,
            taxGroup.Percentage,
          ]);
        } else {
          this.TaxSchema.set(taxGroup.TaxGroupName, [
            [taxGroup.TaxName, taxGroup.Percentage],
          ]);
        }
      });
    });

    this.getTax();
    this.KeyboardPopup();
  }
  // add click event on first input field @jay
  KeyboardPopup() {
    let emailInputterEle: HTMLElement = document.getElementById(
      'emailInputter'
    ) as HTMLElement;
    emailInputterEle.click();
  }

  private getTax() {
    let conDisDetail = this.CartService.conDetailSub.value;

    this.tax = CommonCalculations.getItemTax(
      this.CartService.getCartItems(),
      this.CartService.reward,
      this.CartService.subtotal,
      this.CartService.discount,
      conDisDetail
    ).totalTax;
  }

  get f() {
    return this.emailform.controls;
  }

  public static open(modalService: NgbModal, tok: any) {
    const ref = modalService.open(EmailReceiptDialogComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });
    ref.componentInstance.token = tok;
    ref.componentInstance.ref = ref;
    return ref;
  }

  onemailsubmit() {
    this.submitted = true;

    if (this.emailform.invalid) {
      return;
    }
    var name = GeneralSetting.getCustomerName();
    if (name == '') {
      name = 'customer';
    }
    //else send the email to user
    if (this.userEmail) {
      this.isValid = true;

      this.generateEmailTemp().then((emailTemplate) => {
        this.allSubsCription.push(
          this.dataService
            .sendEmail(this.userEmail!, name!, emailTemplate)
            .subscribe(
              (val) => {},
              (error: any) => {
                var log = new loggingData(
                  'Email Receipt',
                  'Email Receipt Error Recieved',
                  'Email Error',
                  `Encountered Error on Emailing Receipt: ${error} for Invoice ${this.invoiceno}`,
                  true
                );
                this.logger.sendLogToServer(log);
                this.isValid = false;
                this.ref.close();
                /***
                 * Close email receipt dialog and show email thank you. @nilesh
                 */
                this.ref.result.then(() => {
                  EmailReceiptThankyouDialogComponent.open(this.modalService);
                });
              },
              () => {
                this.isValid = false;
                this.ref.close();
                this.ref.result.then(() => {
                  EmailReceiptThankyouDialogComponent.open(this.modalService);
                });
              }
            )
        );
      });
    }
  }

  dismissModal() {
    this.ref.close();
    setTimeout(() => {
      this.shipping.shippingDetails = undefined;
      this.router.navigateByUrl('/touchToStart');
    }, 300);
  }

  isLoyaltyEnable() {
    if (this.userService.isUserLoggedIn) {
      this.isLoyalty = this.loyaltyService.isLoyalty;
      if (
        this.isLoyalty &&
        this.loyaltyService.loyaltyType == LoyaltyType.Referrizer
      ) {
        if (this.loyaltyService.customerVisitRes.message != '') {
          this.isShowPoints = false;
        } else {
          this.loyaltyService.getCustomerVisit().then((data) => {
            if (data != undefined && data != null) {
              this.pointsEarned = data.data.points;
              const reward = this.loyaltyService.loyaltyRewards.find(
                (x) => x.id == this.loyaltyService.selectedLoyaltyReward
              );
              if (reward != undefined) {
                this.isLoyaltyUsed = true;
                this.totalLoyaltyPoints =
                  this.userService.referrizerLoyaltyUser.loyaltyPointsCount +
                  this.pointsEarned -
                  Number(reward.points);
                this.isrewardRedeem = true;
                this.rewardRedeem += '            <tr>\n';
                let redeemText = this.language.getTextElement('redeem');
                this.rewardRedeem +=
                  `                <td style="text-align: left;">${redeemText}[` +
                  reward.points +
                  ' points]</td>\n';
                if (
                  reward.value != null &&
                  reward.value != '' &&
                  reward.value != '0'
                ) {
                  this.rewardRedeem +=
                    '                <td style="text-align: right;">-' +
                    this.cpipe.transform(
                      this.dpipe.transform(this.discount, '1.2-2')
                    ) +
                    '</td>\n';
                } else {
                  this.rewardRedeem +=
                    '                <td style="text-align: right;"></td>\n';
                }
                this.rewardRedeem += '            </tr>\n';
              } else {
                this.totalLoyaltyPoints =
                  this.userService.referrizerLoyaltyUser.loyaltyPointsCount +
                  this.pointsEarned;
              }
            }

            this.visitCount =
              this.userService.referrizerLoyaltyUser.visitsCount + 1;

            let number = this.userService.referrizerLoyaltyUser.phone;
            number = number.replace('(\\d{3})(\\d{3})(\\d+)', '($1) $2-$3');
            this.reward +=
              '<table style="width:100%;background-color: #F9F9F9;padding: 5px;border-radius: 1%;">';
            this.reward += '<tbody><tr>\n ';
            let rewardsText = this.language.getTextElement('lbl_rewards');
            this.reward += `                <td style="text-align: left; padding: 15px;"><b>${rewardsText}</b></td>\n`;
            let accountText = this.language.getTextElement('account_label');
            this.reward +=
              `                <td style="text-align: center; padding: 15px;"><b style="font-weight: 500;">${accountText}</b> ` +
              number +
              '</td>\n';
            let earnedTodayText =
              this.language.getTextElement('earned_today_label');
            this.reward +=
              `                <td style="text-align: right; padding: 15px;"><b style="font-weight: 500;">${earnedTodayText}</b> ` +
              this.pointsEarned +
              '</td>\n';
            this.reward += '            </tr>\n';
            this.reward += '                 <tr>\n';
            this.reward += '                <td></td>\n';
            let totalVisitText =
              this.language.getTextElement('total_visit_label');
            this.reward +=
              `<td style="text-align: center; padding-top: 5px;padding-right: 15px;padding-bottom: 15px;"><b style="font-weight: 500;">${totalVisitText}</b> ` +
              this.visitCount +
              '</td>\n';
            let totalPointsText =
              this.language.getTextElement('lbl_total_points');
            this.reward +=
              `             <td style="text-align: right; padding-top: 5px;padding-right: 15px;padding-bottom: 15px;"><b style="font-weight: 500;">${totalPointsText}</b> ` +
              this.totalLoyaltyPoints +
              '</td>\n';
            this.reward += '             </tr>\n</tbody></table>';
          });
        }
      } else if (
        this.isLoyalty &&
        this.loyaltyService.loyaltyType == LoyaltyType.LevelUp
      ) {
        const reward =
          this.loyaltyService.levelUpObject.proposal?.discount_amount;
        if (reward != undefined && reward != 0) {
          this.isrewardRedeem = true;
          this.rewardRedeem += '            <tr>\n';
          let rewardText = this.language.getTextElement('lbl_reward');
          this.rewardRedeem += `                <td style="text-align: left;">${rewardText}</td>\n`;
          this.rewardRedeem +=
            '                <td style="text-align: right;">-' +
            this.cpipe.transform(this.dpipe.transform(reward, '1.2-2')) +
            '</td>\n';
        }
      }
    } else {
      this.isLoyalty = false;
    }
  }

  getIngredientTemplate(
    ingredient: ModifierIngredientV2,
    itemQuantity: number
  ) {
    let template: string = '';

    if (
      ((ingredient.IsDefault == 'True' &&
        ingredient.isSendtoPOS == 'False' &&
        ingredient.IsSelected) ||
        (ingredient.IsDefault == 'True' &&
          ingredient.isSendtoPOS == 'True' &&
          !ingredient.IsSelected)) &&
      Integration.Parbrink
    ) {
      return template;
    }
    //for (let index = 0; index < selectedIngredient.length; index++) {
    //  const ingredient = selectedIngredient[index] as ModifierIngredientV2;

    const boldness = '400;';
    //For ingredient

    let ingredientPriceWithSign: string = '';
    let ingredientTotalPriceWithSign: string = '';
    /*if (ingredient.IsPremium == "True") {*/
    let ingredientPrice: number =
      ingredient.ExtraPrice && Number(ingredient.ExtraPrice) > 0
        ? Number(ingredient.ExtraPrice)
        : 0;
    //}
    const quantity =
      Number(ingredient.Quantity) > 0 ? Number(ingredient.Quantity) : 1;

    if (ingredientPrice > 0) {
      ingredientPriceWithSign =
        '' +
        this.cpipe.transform(
          this.dpipe.transform(ingredientPrice * quantity, '1.2-2')
        );
      ingredientTotalPriceWithSign =
        '' +
        this.cpipe.transform(
          this.dpipe.transform(
            ingredientPrice * quantity * itemQuantity,
            '1.2-2'
          )
        );
    }
    if (
      (ingredient.IsSelected &&
        ingredient.IsDefault == 'True' &&
        GeneralSetting.getShowDefaultModifiers() == 'True' &&
        !ingredient.isNegativeModifier) ||
      (ingredient.IsSelected && ingredient.IsDefault == 'False')
    ) {
      template +=
        '<tr>\n' +
        '<td></td>\n' +
        '<td><b style="font-weight: ' +
        boldness +
        ';font-size:90%;">' +
        // indent.repeat(ingredient.height) +
        '&nbsp;- ' +
        ingredient.Name.substring(0, 34) +
        ' ' +
        (quantity > 1 ? ' (' + quantity + ')' : '') +
        '</b></td>\n' +
        "<td style='text-align: right;'>" +
        ingredientPriceWithSign +
        '</td>\n' +
        "<td style='text-align: right;'>" +
        ingredientTotalPriceWithSign +
        '</td>\n ' +
        '</tr>\n';
      //oneItem += "        " + indent.repeat(mod.height) + mod.Name.substring(0,(34-(mod.height*indent.length))) + "\n";
    } else if (
      ingredient.isNegativeModifier &&
      GeneralSetting.getShowNegativeModifiers() == 'True'
    ) {
      template +=
        '<tr>\n' +
        '<td></td>\n' +
        '<td><b style="font-weight: ' +
        boldness +
        ';font-size:90%;color:red;">' +
        // indent.repeat(ingredient.height) +
        '&nbsp;- No ' +
        ingredient.Name.substring(0, 34) +
        ' ' +
        '</b></td>\n' +
        '<td></td>\n' +
        '<td></td>\n' +
        '</tr>\n';
    }
    // if (GeneralSetting.getShowDefaultModifiers() == 'False' &&
    //   GeneralSetting.getShowNegativeModifiers() == 'True' &&
    //   ingredient.IsSelected &&
    //   (ingredient.IsDefault == 'True')) {
    //   template += "";
    //   return template
    // }
    // else {
    //   if (ingredient.IsSelected) {
    //     template +=
    //       "<tr>\n" +
    //       "<td></td>\n" +
    //       '<td><b style="font-weight: ' +
    //       boldness +
    //       ';font-size:90%;">' +
    //       // indent.repeat(ingredient.height) +
    //       "&nbsp;- " +
    //       ingredient.Name.substring(0, 34) + " " + (quantity > 1 ? " (" + quantity + ")" : "") +
    //       "</b></td>\n" +
    //       "<td style='text-align: right;'>" +
    //       ingredientPriceWithSign +
    //       "</td>\n" +
    //       "<td style='text-align: right;'>" +
    //       ingredientTotalPriceWithSign
    //       +
    //       "</td>\n " +
    //       "</tr>\n";
    //     //oneItem += "        " + indent.repeat(mod.height) + mod.Name.substring(0,(34-(mod.height*indent.length))) + "\n";
    //   }
    //   else if (ingredient.IsDefault && ingredient.isNegativeModifier) {
    //     template +=
    //       "<tr>\n" +
    //       "<td></td>\n" +
    //       '<td><b style="font-weight: ' +
    //       boldness +
    //       ';font-size:90%;color:red;">' +
    //       // indent.repeat(ingredient.height) +
    //       "&nbsp;- No " +
    //       ingredient.Name.substring(0, 34) + " " +
    //       "</b></td>\n" +
    //       "<td></td>\n" +
    //       "<td></td>\n" +
    //       "</tr>\n";
    //   }
    // }
    //}

    return template;
  }

  getModifierTemplate(
    modifiers: (ModifierV2 | ModifierIngredientV2)[],
    itemQuantity: number
  ) {
    let template = '';

    for (const mod of modifiers) {
      if (!mod.IsSelected && !mod.IsIngredient) continue;

      if (mod.IsIngredient) {
        const ing = mod as ModifierIngredientV2;

        template += this.getIngredientTemplate(ing, itemQuantity);
      } else if (mod.IsModifier) {
        const modifier = mod as ModifierV2;

        const count = CommonFunctions.getSelectedIngredientCount(modifier);
        if (count <= 0) continue;

        if (modifier.Category != '') {
          if (!this.hideModifierGroupName(modifier)) {
            let qty =
              Number(modifier.Quantity) > 1 ? Number(modifier.Quantity) : 1;
            const boldness = '500;';
            template +=
              '<tr>\n' +
              '<td></td>\n' +
              '<td><b style="font-weight: ' +
              boldness +
              ';font-size:95%;">' +
              // indent.repeat(ingredient.height) +
              '&nbsp;' +
              modifier.Category.substring(0, 80) +
              ' ' +
              (qty > 1 ? ' (' + qty + ')' : '') +
              '</b></td>\n' +
              "<td style='text-align: right;'>" +
              (Number(modifier.Price) > 0
                ? this.cpipe.transform(
                    CommonFunctions.roundDigit(
                      Number(modifier.Price),
                      2
                    ).toFixed(2)
                  )
                : '') +
              '</td>\n' +
              "<td style='text-align: right;'>" +
              (Number(modifier.Price) > 0
                ? this.cpipe.transform(
                    CommonFunctions.roundDigit(
                      Number(modifier.Price) * qty * itemQuantity,
                      2
                    ).toFixed(2)
                  )
                : '') +
              '</td>\n' +
              '</tr>\n';
          } else {
            template += '';
          }
        }

        if (modifier.Ingredients && modifier.Ingredients.length > 0) {
          template += this.getModifierTemplate(
            modifier.Ingredients,
            itemQuantity
          );
        }
      }
    }

    return template;
  }

  getVariationTemplate(
    variationGroup: string,
    variation: VariationOption,
    itemQuantity: number
  ) {
    let template: string = '';

    template +=
      '<tr>\n' +
      '<td></td>\n' +
      '<td><b style="font-weight: 500;">' +
      '&nbsp;' +
      variationGroup +
      ':' +
      variation.OptionName.substring(0, 34) +
      '</b></td>\n' +
      "<td style='text-align: right;'>" +
      this.cpipe.transform(
        CommonFunctions.roundDigit(Number(variation.Price), 2).toFixed(2)
      ) +
      '</td>\n' +
      "                <td style='text-align: right;'>" +
      this.cpipe.transform(
        (
          itemQuantity * CommonFunctions.roundDigit(Number(variation.Price), 2)
        ).toFixed(2)
      ) +
      '</td>\n ' +
      '</tr>\n';

    return template;
  }

  hideModifierGroupName(modifier: any) {
    if (
      this.isAllSelectedModifierAreDefault(modifier) &&
      GeneralSetting.getShowDefaultModifiers() == 'False' &&
      GeneralSetting.getShowNegativeModifiers() == 'True'
    ) {
      return true;
    } else {
      return false;
    }
  }

  isAllSelectedModifierAreDefault(modifier: any) {
    let selectedDefaultModifiers = modifier.Ingredients.filter(function (
      ing: any
    ) {
      return ing.IsSelected && ing.IsDefault == 'True';
    });
    if (
      CommonFunctions.getSelectedIngredientCount(modifier) ==
        selectedDefaultModifiers.length ||
      CommonFunctions.getSelectedIngredientCount(modifier) == 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  getconcessionairedetails(concessionaireId: string = '') {
    let ConDetail = this.CartService.conDetailSub.value.filter(
      (ele) => ele.concessionaireId == concessionaireId
    )[0];
    let Condiscount = Number(ConDetail.Discount);
    let Consubtotal = Number(ConDetail.SubTotal);
    let ConTax = Number(ConDetail.TotalTax);
    let Contotal = Consubtotal - Condiscount + ConTax;
    let addCondetails = ``;
    if (Condiscount) {
      addCondetails +=
        '            <tr>\n' +
        `                <td></td>\n` +
        `                <td></td>\n` +
        `                <td <b style="font-weight: 400;font-size:90%; text-align: right;">Discount</b></td>\n` +
        `                <td style='text-align: right;'>-${
          this.CurrencySymbol + Condiscount.toFixed(2)
        }</td>\n` +
        '            </tr>\n';
    }
    addCondetails +=
      '            <tr>\n' +
      `                <td></td>\n` +
      `                <td></td>\n` +
      `                <td <b style="font-weight: 600;  min-width: 100px; text-align: right;">Store Total</b></td>\n` +
      `                <td style='text-align: right;'>${
        this.CurrencySymbol + Contotal.toFixed(2)
      }</td>\n` +
      '            </tr>\n';
    return addCondetails;
  }

  generateItemized(
    items: ItemV2[],
    isCombo: boolean = false,
    concessionaireId: string = ''
  ): Promise<string> {
    return new Promise<string>(async (resolve) => {
      let comboCheck = isCombo;

      let itemsList = ``;
      for (let i = 0; i < items.length; i++) {
        let item = items[i];

        if (!item.Quantity || item.Quantity.toString() == '') {
          item.Quantity = '1';
        }

        if (
          this.isConcessionaire &&
          !comboCheck &&
          item.ConcessionaireId != concessionaireId
        ) {
          continue;
        }

        if (!item.isSelected && comboCheck) continue;

        var eachPrice = CommonFunctions.roundDigit(Number(item.Price), 2);

        const indent = '&nbsp;';

        var oneItem = ``;

        var totalPrice = Number(item.Quantity) * Number(item.Price);

        var weightUnit = CommonFunctions.getWeightUnit(item.WeightUnitID);

        var discAmount =
          item.DiscountAmount && Number(item.DiscountAmount) > 0
            ? item.DiscountAmount
            : 0;

        let isEnabledPriceWeight =
          item.EnablePricebyWeight &&
          item.EnablePricebyWeight.toLocaleLowerCase() == 'true';
        var eachVal =
          (eachPrice > 0 || item.IsLoyaltyDiscountItem
            ? this.cpipe.transform(eachPrice.toFixed(2))
            : '') + '';
        eachVal = !isEnabledPriceWeight ? eachVal : eachVal + '/' + weightUnit;

        if (!comboCheck) {
          let qty = !isEnabledPriceWeight
            ? item.Quantity
            : item.Quantity + weightUnit;
          oneItem +=
            '<tr>\n' +
            '<td>' +
            qty +
            '</td>\n' +
            '<td> <b style="font-weight:800;">' +
            (item.OrderReviewDisplayName && item.OrderReviewDisplayName != ''
              ? item.OrderReviewDisplayName
              : item.Name) +
            '</b></td>\n' +
            "<td style='text-align: right;'>" +
            eachVal +
            '</td>\n' +
            "<td style='text-align: right;'>" +
            (item.IsLoyaltyDiscountItem
              ? this.language
                  .getTextElement('txt_free_item')
                  .toLocaleUpperCase()
              : totalPrice > 0
              ? this.cpipe.transform(
                  CommonFunctions.roundDigit(totalPrice, 2).toFixed(2)
                )
              : '') +
            '</td>\n ' +
            '</tr>\n';
        } else {
          oneItem +=
            '<tr>\n' +
            '<td>' +
            '</td>\n' +
            '<td> <b style="font-weight:700;">' +
            (item.OrderReviewDisplayName && item.OrderReviewDisplayName != ''
              ? item.OrderReviewDisplayName
              : item.Name) +
            (Number(item.Quantity) > 1 ? ' X ' + item.Quantity : '') +
            '</b></td>\n' +
            "<td style='text-align: right;'>" +
            eachVal +
            '</td>\n' +
            "<td style='text-align: right;'>" +
            (item.IsLoyaltyDiscountItem
              ? this.language
                  .getTextElement('txt_free_item')
                  .toLocaleUpperCase()
              : totalPrice > 0
              ? this.cpipe.transform(
                  CommonFunctions.roundDigit(totalPrice, 2).toFixed(2)
                )
              : '') +
            '</td>\n ' +
            '</tr>\n';
        }

        const isCombo = item.IsCombo.toString();

        if (isCombo.toLowerCase() == 'true') {
          //Check if item is combo item @nilesh
          if (item.ComboGroup && item.ComboGroup.length > 0) {
            for (const comboGroup of item.ComboGroup) {
              if (comboGroup.Items && comboGroup.Items.length > 0) {
                oneItem += await this.generateItemized(comboGroup.Items, true);
              }
            }
          }
        } else if (
          item.Modifiers &&
          item.Modifiers.length == 0 &&
          item.Variations &&
          item.Variations.length == 0
        ) {
          oneItem += '<tr></tr>';
        } else {
          //Print item variations @nilesh
          if (item.VariationOption && item.VariationOption.length > 0) {
            oneItem += this.getVariationTemplate(
              item.Variations[0].Name,
              item.VariationOption[0],
              Number(item.Quantity)
            );
          }

          if (item.Modifiers && item.Modifiers.length > 0) {
            oneItem += this.getModifierTemplate(
              item.Modifiers,
              Number(item.Quantity)
            );
          }
        }

        if (item.IsDiscount && discAmount > 0) {
          oneItem +=
            '<tr>\n' +
            '<td></td>\n' +
            "<td><b style='font-weight: 600;'>" +
            item.DiscountName +
            ' ' +
            '</b></td>\n' +
            '<td></td>\n' +
            "<td style='text-align: right;font-weight: 600;'>-" +
            (discAmount > 0
              ? this.cpipe.transform(discAmount.toFixed(2))
              : '') +
            '</td>\n' +
            '</tr>\n';
        } else if (item.IsBOGO) {
          oneItem +=
            '<tr>\n' +
            '<td></td>\n' +
            "<td><b style='font-weight: 600;'>" +
            item.DiscountName +
            ' ' +
            '</b></td>\n' +
            '<td></td>\n' +
            '<td</td>\n' +
            '</tr>\n';
        }

        //Item Tax [Start]
        if (
          item.TaxPercentage &&
          Number(item.TaxPercentage) > 0 &&
          item.totalPrice &&
          Number(item.totalPrice) > 0 &&
          !(GeneralSetting.getHideItemTaxPrice() == 'True')
        ) {
          var totalTax =
            (item.TaxPercentage *
              ((item.totalPrice ? item.totalPrice : 1) -
                (item.DiscountAmount > 0 ? item.DiscountAmount : 0))) /
            100;
          oneItem +=
            '<tr>\n' +
            '<td></td>\n' +
            "<td><b style='font-weight: 400;font-size:90%;'>" +
            ` ${GeneralSetting.getTaxType() == '2' ? 'VAT' : 'Item Tax'}` +
            ' ' +
            `${this.dpipe.transform(item.TaxPercentage, '1.2-2')}%` +
            ' ' +
            '</b></td>\n' +
            '<td></td>\n' +
            "<td style='text-align: right;'>" +
            this.cpipe.transform(
              CommonFunctions.roundDigit(totalTax, 2).toFixed(2)
            ) +
            '</td>\n' +
            '</tr>\n';
        }
        //Item Tax [STOP]

        if (
          item.specialRequest &&
          item.specialRequest != 'undefined' &&
          item.specialRequest != ''
        ) {
          oneItem +=
            '<tr>\n' +
            '<td></td>\n' +
            '<td><b style="font-weight: 400;">' +
            'Sp: ' +
            item.specialRequest +
            '</b></td>\n' +
            '</tr>\n';
        }
        if (
          item.GuestName &&
          item.GuestName != 'undefined' &&
          item.GuestName != ''
        ) {
          oneItem +=
            '<tr>\n' +
            '<td></td>\n' +
            '<td><b style="font-weight: 400;">' +
            'Customer Name: ' +
            item.GuestName +
            '</b></td>\n' +
            '</tr>\n';
        }
        itemsList += oneItem;
      }
      this.itemized = itemsList;
      resolve(itemsList);
    });
  }

  stringToHex(tmp: any) {
    let str = '';
    const tmp_len = tmp.length;
    let c: any;

    for (let i = 0; i < tmp_len; i += 1) {
      c = tmp.charCodeAt(i).toString(16);
      c == 'a' ? (c = '0A') : null;
      str += c.toString(16);

      i == tmp_len - 1 ? (str += '1B69') : null;
    }
    return str;
  }

  numberSpaces(number: any) {
    let total: any = Number(number).toFixed(2).toString().split('.');
    if (total[0].length == 1) {
      total[0] = '   ' + total[0];
    } else if (total[0].length == 2) {
      total[0] = '  ' + total[0];
    } else if (total[0].length == 3) {
      total[0] = ' ' + total[0];
    }
    total = total[0] + '.' + total[1];
    return total;
  }

  center(str: any) {
    if (str.length > 42) {
      var numspace = (42 - str.substring(0, 42).length) / 2;

      var emptySpace = '';
      for (let i = 0; i < numspace; i++) {
        emptySpace += ' ';
      }
      var output = emptySpace + str + emptySpace;

      const numspace2 = (42 - str.substring(42, 84).length) / 2;

      var emptySpace = '';
      for (let i = 0; i < numspace2; i++) {
        emptySpace += ' ';
      }
      output += '\n' + emptySpace + str + emptySpace;

      return output;
    } else {
      var numspace = (42 - str.length) / 2;

      var emptySpace = '';
      for (let i = 0; i < numspace; i++) {
        emptySpace += ' ';
      }
      var output = emptySpace + str + emptySpace;
      return output;
    }
  }

  left(str: any) {
    const numspace = 42 - str.length;

    let emptySpace = '';
    for (let i = 0; i < numspace; i++) {
      emptySpace += ' ';
    }

    const output = emptySpace + str;

    return output;
  }

  split(str: any, str2: any) {
    const numspace = 42 - str.length - str2.length;

    let emptySpace = '';
    for (let i = 0; i < numspace; i++) {
      emptySpace += ' ';
    }

    const output = str + emptySpace + str2;

    return output;
  }

  lineSpacing(quantity: any, item: any, pricePer: any, priceTotal: any) {
    const firstSpaceTotal = 7;
    const firstSpace = firstSpaceTotal - quantity.length;

    const secondSpaceTotal = 20;
    const secondSpace =
      secondSpaceTotal - item.toString().substring(0, 17).length;

    const thirdSpaceTotal = 7;
    const thirdSpace = thirdSpaceTotal - pricePer.length;

    const fourthSpaceTotal = 7;
    const fourthSpace = fourthSpaceTotal - priceTotal.length;

    let emptySpace1 = '';
    for (let i = 0; i < firstSpace; i++) {
      emptySpace1 += ' ';
    }

    const firstWord = quantity + emptySpace1;

    let emptySpace2 = '';
    for (let i = 0; i < secondSpace; i++) {
      emptySpace2 += ' ';
    }

    const secondWord = item.toString().substring(0, 17) + emptySpace2;

    let emptySpace3 = '';
    for (let i = 0; i < thirdSpace; i++) {
      emptySpace3 += ' ';
    }

    const thirdWord = pricePer + emptySpace3;

    // var emptySpace4 = "";
    // for(i = 0; i < fourthSpace; i++) {
    // 	emptySpace4 += " ";
    // }

    const fourthWord = priceTotal; //+ emptySpace4;

    const word = firstWord + secondWord + '$' + thirdWord + '$' + fourthWord;

    return word;
  }

  customerNameSet() {
    let temp = '';
    let customerNameText = this.language.getTextElement('txt_customer_name');
    let customerName = GeneralSetting.getCustomerName();
    if (customerName === '') {
      customerName = this.language.getTextElement('txt_guest');
    }
    temp +=
      '           <tr>' +
      '                <td align="left">\n' +
      '                   ' +
      '\n' +
      '                </td>\n' +
      '                <td align="right">\n' +
      `                   ${customerNameText} ` +
      customerName +
      '\n' +
      '                </td>\n' +
      '             </tr>          ';

    return temp;
  }

  getThirdPartyOrderId() {
    let temp = '';

    if (GeneralSetting.getThirdPartyOrderId() == '') return temp;

    let orderId = '';

    if (GeneralSetting.getThirdPartyOrderId().length < 6) {
      orderId = String(GeneralSetting.getThirdPartyOrderId().toUpperCase());
    } else {
      orderId = String(
        GeneralSetting.getThirdPartyOrderId().substr(
          GeneralSetting.getThirdPartyOrderId().length - 6
        )
      ).toUpperCase();
    }
    let tpOrderIDText = this.language.getTextElement('tp_order_id_label');
    temp +=
      '           <tr>' +
      '                <td align="left">\n' +
      '                   ' +
      '\n' +
      '                </td>\n' +
      '                <td align="right">\n' +
      `                   ${tpOrderIDText} ` +
      orderId +
      '\n' +
      '                </td>\n' +
      '             </tr>          ';

    return temp;
  }

  subtotalWithDiscount() {
    if (this.CartService.discount != 0 && this.CartService.reward == 0) {
      return this.subtotal - this.CartService.discount;
    } else if (this.CartService.discount != 0 && this.CartService.reward != 0) {
      return (
        this.subtotal - this.CartService.discount - this.CartService.reward
      );
    } else if (this.CartService.discount == 0 && this.CartService.reward != 0) {
      return this.subtotal - this.CartService.reward;
    } else return this.subtotal;
  }

  generateEmailTemp(): Promise<string> {
    return new Promise<string>(async (resolveEmail) => {
      let isTableTent =
        this.EatOptionService.getCurrentEatOption().KioskTableTent;
      let tableTentNumber = GeneralSetting.getTableTentNumber();

      let numStr: string = '';

      let tok = GeneralSetting.getOrderPrefix();
      let BranchName = GeneralSetting.getBranchName();
      let BranchAddress = GeneralSetting.getBranchAddress1();
      let BranchAddressSecond = GeneralSetting.getBranchAddress2();
      let BranchContact = GeneralSetting.getBranchContactNo();
      let branchLogo = GeneralSetting.getBranchLogo();
      let Current = new Date();
      let OrderType = GeneralSetting.getLocationPreference();
      let upChargeCardKioskAmount = GeneralSetting.getUpChargeCardKIOSKAmount();
      let usePercentageCardKIOSK =
        GeneralSetting.getUsePercentageCardKIOSK() == 'True';
      let percString =
        (usePercentageCardKIOSK &&
          (parseInt(upChargeCardKioskAmount as string) / 100) * this.total) ||
        parseInt(upChargeCardKioskAmount as string);
      let paymentType = GeneralSetting.getPaymentTypeID();
      let isCard = paymentType == '2';
      let shouldShow =
        isCard &&
        upChargeCardKioskAmount != null &&
        Number(upChargeCardKioskAmount) > 0.0;

      let invoiceNo = String(
        this.invoiceno.substr(this.invoiceno.length - 6)
      ).toUpperCase();

      // Display Tip
      let showTip =
        GeneralSetting.getShowTipDialog() == 'True' && this.CartService.tip > 0;
      let tipText = '';

      if (showTip) {
        let tiptext = this.language.getTextElement('txt_tip');
        tipText +=
          '            </tr>\n' +
          '            <tr>\n' +
          `                <td style="text-align: left;">${tiptext}:</td>\n` +
          '                <td style="text-align: right;">' +
          this.cpipe.transform(
            this.dpipe.transform(this.CartService.tip, '1.2-2')
          ) +
          '</td>\n' +
          '            </tr>\n';
      }
      //display Shipping
      let showShipping =
        GeneralSetting.getEnableOrderShipment() == 'True' &&
        GeneralSetting.getOrderTypeId() == '3';
      let shippingText = '';
      if (showShipping) {
        let shippingFeeText = this.language.getTextElement('shipping_fee');
        shippingText +=
          '            </tr>\n' +
          '            <tr>\n' +
          `                <td style="text-align: left;">${shippingFeeText}:</td>\n` +
          '                <td style="text-align: right;">' +
          this.cpipe.transform(
            this.dpipe.transform(this.CartService.shippingFee, '1.2-2')
          ) +
          '</td>\n' +
          '            </tr>\n';
      }

      let orderTotal = this.CartService.total;
      let totalTax = this.CartService.getItemTotalTax();

      let serviceFee = 0;
      let serviceFeeText = '';
      let surcharge = 0;

      ///////////////////////////////////////////////////////////////////////////

      if (this.olo.isOLO) {
        surcharge = this.olo?.orderRes?.surcharge ?? 0,
        orderTotal = this.olo.validationRes.data.total + surcharge;
        this.subtotal = this.olo.validationRes.data.subtotal;
        totalTax = this.olo.validationRes.data.tax;

        serviceFee = surcharge //this.olo.validationRes?.basketData?.totalfees;

      }
      if (serviceFee && serviceFee > 0) {
        let serviceText = this.language.getTextElement('lbl_surcharge');
        serviceFeeText =
          '            </tr>\n' +
          '            <tr>\n' +
          `                <td style="text-align: left;">${serviceText}</td>\n` +
          '                <td style="text-align: right;">' +
          this.cpipe.transform(this.dpipe.transform(serviceFee, '1.2-2')) +
          '</td>\n' +
          '            </tr>\n';
      } else if (this.integrationID == Integration.Parbrink) {
        totalTax = this.CartService.tax;
        this.subtotal = this.CartService.subtotal;
      } else if (this.oracle.isOracle) {
        if (this.oracle.isOracleMicross) {
          this.subtotal = this.oracle.calculationResponse
            ? Number(this.oracle.calculationResponse.TotalsSubTotal) +
              this.CartService.discount +
              this.CartService.reward
            : 0.0;
        } else {
          this.subtotal = this.oracle.calculationResponse
            ? Number(this.oracle.calculationResponse.TotalsSubTotalWithDiscount)
            : 0.0;
        }
        orderTotal = this.oracle.calculationResponse
          ? Number(this.oracle.calculationResponse.TotalsTotalDue)
          : 0.0;
        totalTax = this.oracle.calculationResponse
          ? Number(this.oracle.calculationResponse.TotalsTaxTotals)
          : 0.0;
      }

      //Display Taxes
      let taxText = '';

      if (
        this.olo.isOLO ||
        this.integrationID == Integration.Parbrink ||
        this.oracle.isOracle
      ) {
        let taxtext = this.language.getTextElement('lbl_taxes_and_fees');
        let vattext = this.language.getTextElement('vat');
        taxText +=
          `<tr>\n` +
          `<td style="text-align: left;">${
            this.taxType == '2' ? `${vattext}` : `${taxtext}:`
          } </td>` +
          `<td style="text-align: right;">${this.cpipe.transform(
            this.dpipe.transform(totalTax, '1.2-2')
          )}</td>` +
          `</tr>\n`;
      } else {
        if (!GeneralSetting.getTaxesIncludedInPrice()) {
          this.CartService.totalTaxTypes.forEach((taxes: any) => {
            taxText +=
              `<tr>\n` +
              `<td style="text-align: left;">${
                taxes.Name
              }(${this.dpipe.transform(taxes.TaxPer, '1.2-2')}%)</td>` +
              `<td style="text-align: right;">${this.cpipe.transform(
                this.dpipe.transform(taxes.TaxAmount, '1.2-2')
              )}:</td>` +
              `</tr>\n`;
          });

          // this.TaxSchema.forEach((taxes: any) => {
          //   taxes.forEach((tax: any) => {
          //     taxText += `<tr>\n` +
          //       `<td style="text-align: left;">${tax[0]}(${this.dpipe.transform(tax[1], "1.2-2")}%)</td>` +
          //       `<td style="text-align: right;">${this.cpipe.transform(this.dpipe.transform(
          //         ((this.subtotalWithDiscount()) * parseFloat(tax[1])) / 100, "1.2-2"
          //       ))}</td>` +
          //       `</tr>\n`;
          //   })
          // });
        } else {
          let taxtext = this.language.getTextElement('txt_tax');
          let vattext = this.language.getTextElement('vat');
          let subtotalText = this.language.getTextElement(
            'included_in_subtotal'
          );
          taxText +=
            `<tr>\n` +
            `<td style="text-align: left;">${
              this.taxType == '2' ? `${vattext}` : `${taxtext}`
            }:</td>` +
            `<td style="text-align: right;">*${subtotalText}</td>` +
            `</tr>\n`;
        }
      }

      //Is promo applied
      let discountText = '';
      if (this.discount > 0 && !this.isLoyaltyUsed) {
        let discounttext = this.language.getTextElement('text_discount');
        discountText +=
          '            <tr>\n' +
          `                <td style="text-align: left;">${discounttext} ` +
          (this.CartService.promo.Name
            ? '(' + this.CartService.promo.Name + ')'
            : '') +
          ':</td>\n' +
          '                <td style="text-align: right;">' +
          '-' +
          this.cpipe.transform(this.dpipe.transform(this.discount, '1.2-2')) +
          '</td>\n' +
          '            </tr>';
      }
      let rewardText = '';
      if (this.CartService.reward > 0 && !this.isrewardRedeem) {
        let rewardsText = this.language.getTextElement('lbl_rewards');
        rewardText +=
          '            <tr>\n' +
          `                <td style="text-align: left;">${rewardsText}:</td>\n` +
          '                <td style="text-align: right;">' +
          '-' +
          this.cpipe.transform(
            this.dpipe.transform(this.CartService.reward, '1.2-2')
          ) +
          '</td>\n' +
          '            </tr>\n';
      }

      let tableTentText = this.language.getTextElement('table_tent_label');
      let orderTokenText = this.language.getTextElement('order_token_label');

      if (isTableTent == 'True') {
        numStr = `${tableTentText} `;
      } else {
        numStr = `${orderTokenText} `;
      }
      // //console.log('THE PAYMENT TYPE IS',paymentType)
      let paidStr = this.language.getTextElement('pay_with');

      switch (paymentType) {
        case '2': {
          paidStr += this.language.getTextElement('text_card');
          break;
        }
        case '1': {
          paidStr += this.language.getTextElement('text_cash');
          break;
        }
        case '13': {
          paidStr += this.language.getTextElement('house_account');
          break;
        }
        case '17': {
          paidStr += this.language.getTextElement('gift_card');
          break;
        }

        case '22': {
          paidStr += this.language.getTextElement('text_loyalty_app');
          break;
        }
        case '6': {
          paidStr += this.language.getTextElement('text_discount_reward');
          break;
        }
        case EPaymentType.RFID.toString(): {
          paidStr = this.language.getTextElement('pay_with_Rfid');
          break;
        }
        case EPaymentType.IDCard.toString(): {
          paidStr = this.language.getTextElement('pay_with_id_card');
          break;
        }
      }
      let receiptFromText = this.language
        .getTextElement('receipt_from_branch')
        .replace('%d', BranchName);
      let orderIDText = this.language.getTextElement('order_id_label');
      let qtyText = this.language.getTextElement('lbl_cart_qty');
      let itemText = this.language.getTextElement('item');
      let eachText = this.language.getTextElement('text_each');
      let totalText = this.language.getTextElement('lbl_total');

      const imagePlaceHolder =
        (branchLogo && branchLogo !== '' && branchLogo !== 'null')
          ? '<img src="' +
            this.baseImageUrl +
            branchLogo +
            ' " alt="Placeholder for Customer Logo Image" style="max-height: 75px;object-fit: cover;">'
          : '';

      let emailTemplate =
        '<html><head><meta charset="UTF-8">\n' +
        '' +
        '<meta name="viewport" content="width=device-width, initial-scale=1">\n' +
        '<meta http-equiv="Content-Type" content="text/html; charset=windows-1252">\n' +
        '  \n' +
        '</head>\n' +
        '<body style="font-size:20px;">\n' +
        '\n' +
        '    <div style="max-width: 720px; margin: 0 auto; width: 100%;">\n' +
        '\n' +
        '        <table style="width:100%;background-color: #F9F9F9;padding: 5px;border-radius: 1%;font-family:OpenSans-Regular;">\n' +
        '            <tbody><tr>\n' +
        '                <td align="center">' + imagePlaceHolder + '</td>\n' +
        '            </tr>\n' +
        '        </tbody></table>\n' +
        '        <table style="width:100%;background-color: white;padding: 5px;border-radius: 1%;font-family: &#39;Open Sans&#39;, sans-serif;" >\n' +
        '            <tbody><tr>\n' +
        '                <td align="center">\n' +
        `                    ${receiptFromText} ` +
        '\n' +
        '                </td>\n' +
        '            </tr>\n' +
        '        </tbody></table>\n' +
        '        <table style="width:100%;background-color: white;padding: 5px;border-radius: 1%;font-family: &#39;Open Sans&#39;, sans-serif;" >\n' +
        '            <tbody><tr>\n' +
        '                <td align="left">\n' +
        '                    <font color="#94A0AF">' +
        BranchAddress +
        '<br>' +
        (BranchAddressSecond || '') +
        '<br>' +
        BranchContact +
        '</font>\n' +
        '                </td>\n' +
        '                <td align="right">\n' +
        `                    ${orderIDText} ` +
        invoiceNo +
        ' <br> ' +
        numStr +
        ((isTableTent == 'True' && +tableTentNumber) ||
          '#' + tok + '-' + this.token) +
        '\n' +
        '                </td>\n' +
        '            </tr>\n' +
        this.customerNameSet() +
        this.getThirdPartyOrderId() +
        '        </tbody></table>\n' +
        '\n' +
        ' <table style="width:100%;background-color: #F9F9F9;padding: 5px;border-radius: 1%;font-family: &#39;Open Sans-Regular&#39;, sans-serif;" >\n' +
        '            <tbody><tr>\n' +
        '               <td align="left"><b>' +
        (OrderType || 'IN') +
        '</b></td>\n' +
        '\n' +
        '                <td align="right"> ' +
        this.pipe.transform(Current, 'short') +
        '</td>\n' +
        '            </tr>\n' +
        '        </tbody></table>\n' +
        '        <table style="width:100%;background-color: #F9F9F9;padding: 5px;border-radius: 1%;font-family: &#39;Open Sans-Regular&#39;, sans-serif;" ;"="">\n' +
        '            <tbody>\n <col width="10%" > <col width="65%"><col width="10%" style="text-align: center;" align="right"><col width="15%" style="text-align: center;" align="right">' +
        '            <tr>\n' +
        `                <td><b>${qtyText.toLocaleUpperCase()}</b></td>\n` +
        `                <td><b>${itemText.toLocaleUpperCase()}</b></td>\n` +
        `                <td style='text-align: right;'><b>${eachText.toLocaleUpperCase()}</b></td>\n` +
        `                <td style='text-align: right;'><b>${totalText.toLocaleUpperCase()}</b></td>\n` +
        '            </tr>\n' +
        '            <tr>\n' +
        '                <td></td>\n' +
        '            </tr>\n' +
        '            <tr>\n' +
        '                <td></td>\n' +
        '            </tr>\n';

      if (this.isConcessionaire) {
        var allConcessionaire: any[] = [];

        this.items.forEach(function (x) {
          allConcessionaire.push({
            ConcessionaireId: x.ConcessionaireId,
            ConcessionaireName: x.ConcessionaireName,
            ConcessionaireColorCode: x.ConcessionaireColorCode,
          });
        });

        //console.log("allConcessionaire :- ", allConcessionaire);

        let uniqueCon = [
          ...new Map(
            allConcessionaire.map((item) => [item['ConcessionaireId'], item])
          ).values(),
        ];

        for (var i = 0; i < uniqueCon.length; i++) {
          if (i == 0) {
            if (uniqueCon[i].ConcessionaireColorCode != '') {
              emailTemplate += `<tr>
                                <td style="width: 100%;text-align: center;border-bottom:1px solid black;color:${uniqueCon[i].ConcessionaireColorCode}" colspan="4"> ${uniqueCon[i].ConcessionaireName} </td>
                             </tr>`;
            } else {
              emailTemplate += `<tr>
                              <td style="width: 100%;text-align: center;border-bottom:1px solid black;" colspan="4"> ${uniqueCon[i].ConcessionaireName} </td>
                           </tr>`;
            }
          } else {
            if (uniqueCon[i].ConcessionaireColorCode != '') {
              emailTemplate += `<tr>
                                <td style="width: 100%;text-align: center;border-bottom:1px solid black;border-top:1px solid black;color:${uniqueCon[i].ConcessionaireColorCode}" colspan="4"> ${uniqueCon[i].ConcessionaireName} </td>
                             </tr>`;
            } else {
              emailTemplate += `<tr>
                              <td style="width: 100%;text-align: center;border-bottom:1px solid black;border-top:1px solid black;" colspan="4"> ${uniqueCon[i].ConcessionaireName} </td>
                           </tr>`;
            }
          }
          let itemized = await this.generateItemized(
            this.items,
            false,
            uniqueCon[i].ConcessionaireId
          );

          emailTemplate += itemized;
          emailTemplate += `<tr><td style="padding: 10px;"></td></tr>`;
          emailTemplate += this.getconcessionairedetails(
            uniqueCon[i].ConcessionaireId
          );
        }
      } else {
        let itemized = await this.generateItemized(this.items);

        emailTemplate += itemized;
      }
      let subtotalText = this.language.getTextElement('subtotal');
      let nonCashFees = this.language.getTextElement('non_cash_fees');
      let paymentTypeText = this.language.getTextElement('text_payment_type');
      let recievingEmailText = this.language
        .getTextElement('recieving_email_due_to')
        .replace('%d', BranchName)
        .replace('%e', '<font color="skyblue">GRUBBRR™</font>');

      emailTemplate +=
        '            <tr>\n' +
        '                <td></td>\n' +
        '            </tr>\n' +
        '        </tbody></table>\n' +
        '        <table style="width:100%;background-color: #F9F9F9;padding: 5px;border-radius: 1%;font-family: &#39;Open Sans-Regular&#39;, sans-serif;" ;"="">\n' +
        '            <tbody>\n <col width="50%" > <col width="50%" align="right">' +
        '            <tr>\n' +
        `                <td style="text-align: left;">${subtotalText}:</td>\n` +
        '                <td style="text-align: right;">' +
        this.cpipe.transform(this.dpipe.transform(this.subtotal, '1.2-2')) +
        '</td>\n' +
        '            </tr>\n' +
        this.rewardRedeem +
        '\n' +
        discountText +
        '\n' +
        taxText +
        shippingText +
        tipText +
        ((shouldShow &&
          `                <td style="text-align: left;">${nonCashFees}` +
            ((usePercentageCardKIOSK &&
              ' (' +
                parseInt(upChargeCardKioskAmount as string).toFixed(2) +
                '%)') ||
              '') +
            '</td>\n' +
            '                <td style="text-align: right;">' +
            this.dpipe.transform(this.upchargeAmount || 0, '1.2-2') +
            '</td>\n' +
            '            </tr>\n') ||
          '') +
        '            </tr>\n' +
        '\n' +
        rewardText +
        '\n' +
        '            <tr>\n' +
        `                <td style="text-align: left;">${totalText}:</td>\n` +
        '                <td style="text-align: right;">' +
        this.cpipe.transform(
          this.dpipe.transform(orderTotal + this.upchargeAmount || 0, '1.2-2')
        ) +
        '</td></tr>\n' +
       
        serviceFeeText +

        '            <tr>\n' +
        `                <td style="text-align: left;">${paymentTypeText}</td>\n` +
        '                <td style="text-align: right;">' +
        paidStr +
        '</td>\n' +
        // "            </tr>\n" +
        // "getOraclePriceDifference(orderDetail.getOraclePriceDifferent())" +
        // "            <tr>\n" +
        '                <td></td>\n' +
        '            </tr>\n' +
        '            <tr>\n' +
        '                <td></td>\n' +
        '            </tr>\n' +
        // "            <tr>\n" +
        // "                <td style=\"text-align: left;\">Tenderd</td>\n" +
        // "                <td style=\"text-align: right;\">" + "UIHelper.displayPrice(orderDetail.getTenorAmount(), false)" + "</td>\n" +
        // "            </tr>\n" +
        // "            <tr>\n" +
        // "                <td></td>\n" +
        // "            </tr>\n" +
        // "            <tr>\n" +
        // "                <td style=\"text-align: left;\">Change</td>\n" +
        // "                <td style=\"text-align: right;\">" + "UIHelper.displayPrice(orderDetail.getChangeAmount(), false)" + "</td>\n" +
        // "            </tr>\n" +
        // "            <tr>\n" +
        // "                <td></td>\n" +
        // "            </tr>\n" +
        // "            <tr>\n" +
        // "                <td></td>\n" +
        // "            </tr>\n" +
        // "            <tr>\n" +
        // "                <td style=\"text-align: left;\">Payment Type</td>\n" +
        // "                <td style=\"text-align: right;\">" + "paymentType" + "</td>\n" +
        // "            </tr>\n" +
        '        </tbody></table>\n' +
        '\n' +
        '        <table style="width:100%;background-color: white;padding: 5px;border-radius: 1%;"></table>\n' +
        '\n' +
        this.reward +
        '\n' +
        '        <table style="width:100%;background-color: white;padding: 5px;border-radius: 1%;font-family:OpenSans-Regular" ;>\n' +
        '            <tbody><tr>\n' +
        `                <td><font color="grey">${recievingEmailText}</font></td>\n` +
        '            </tr>\n' +
        '        </tbody></table>\n' +
        '\n' +
        '\n' +
        '    </div>\n' +
        '\n' +
        '</body></html>';

      resolveEmail(emailTemplate);
    });
  }
}
