<ng-container *ngIf="!isCombo">
    <section class="bottom-section don-bottom">

        <div class="left-section">
            <h3>{{currentSelectionText}}</h3>
            <div class="row">
                <div class="col-6"><img
                        src="{{ modifiers[currentSelectedModifierIndex].DeselectedImage ? ((baseImageUrl ? baseImageUrl : getBaseImageUrl())  + modifiers[currentSelectedModifierIndex].DeselectedImage) : placeholderImage != '' ? baseImageUrl+placeholderImage :'/assets/images/Image_21.png' }}"
                        class="img-fluid" />
                </div>
                <div class="col-6">
                    <ng-container *ngFor="let mod of modifiers; index as i">
                        <h4 class="coating-head" *ngIf="i<3 ">{{ mod.Category }}</h4>
                        <div class="footer-ing" *ngIf="i<3 && mod.Ingredients.length>0">
                            <ng-container >
                                <ng-container *ngFor=" let ing of mod.Ingredients;index as i ">
                                    <span class="type h5" *ngIf="ing.IsSelected">{{ ing.Name }}</span>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="right-section">
            <h3>{{howManyText}}</h3>
            <ng-container *ngIf="!isEdit">
                <div class="d-flex flex-row flex-wrap quantity six-btn" [ngClass]="isReady ? '' : 'quantity-selector'" >
                    <button class="btn donut-qty" *ngFor="let item of totalQuantity; let i = index"
                        [ngClass]="{active: ((selectedQuantity== -1)&&(isReady) )? i==0 : i==selectedQuantity }" (click)="onClick($event,i)"
                        [disabled]="!(i <  (totalQuantity.length - selectedTotalCount))">
                        {{i+1}}
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="isEdit" >
                <div class="d-flex flex-row flex-wrap quantity six-btn" [ngClass]="isReady ? '' : 'quantity-selector'" >
                    <button class="btn donut-qty" *ngFor="let item of totalQuantity; let i = index"
                        [ngClass]="{active: i==(inputItem.ItemConsolidateQuantity-1)}" (click)="onClick($event,i)"
                        [disabled]="!(i <  selectableQuantity)">
                        {{i+1}}
                    </button>
                </div>
            </ng-container>
            <button class="btn btn-add active" [ngClass]="isReady ? '' : 'disabled-btn'"
                (click)="isReady ? addToCart() : null">{{addDonutText}}</button>
            <button class="btn btn-cancel" (click)="cancel()">{{cancelText}}</button>
        </div>

    </section>
</ng-container>