<div class="header" [ngStyle]="{ 'background-color': primaryColor }">
  <div class="Category">{{ Item?.CategoryName || "" }}</div>
  <div class="ItemName">
    {{ Item == undefined || Item.Name == undefined ? "" : Item?.Name }}
  </div>
  <div class="ItemDesc">
    {{
      Item == undefined || Item.FullDescription == undefined
        ? ""
        : Item?.FullDescription
    }}
  </div>
  <div *ngIf="Item?.OldPrice == undefined; else promoBlock" class="startsAt">
    <div *ngIf="itemPrice != 0.00" [ngStyle]="{ color: priceColor }">
      {{ priceStr + ( Item.Price | number: "1.2-2" | CurrencyPipe )}}
    </div>
  </div>
  <ng-template #promoBlock>
    <div>
      <span [ngStyle]="{ color: priceColor }">
        {{regularPriceText}}
        {{ Item?.OldPrice | number: "1.2-2" | CurrencyPipe }}
      </span><span class="startsAt hpad4" [ngStyle]="{ color: priceColor }">
        {{ StartAtStr }}
        {{ Item?.Price | number: "1.2-2" | CurrencyPipe }}
      </span><span [ngStyle]="{ color: priceColor }">
        {{savingsText}}
        {{ savings | number: "1.2-2" | CurrencyPipe }}
      </span>
    </div>
  </ng-template>
</div>
