<div id="upsell-popup">
    <div class="item86-modal">
        <h1 class="text-center">{{uhOhText}}</h1>
        <h2 class="text-center">{{unavailableText}}</h2>
        <div class="custom-message">
            {{itemsUnavailableText}}
         </div>
        <div>
            <h5>{{itemText}}</h5>
            <div class="scroll-item-box">
                <div class="row scroll-item">
                    <div class="items" *ngFor="let item of unavailableItems">
                        <img  src="{{
                                    (item.ItemImage 
                                        ? (item.ItemImage.startsWith(baseImageUrl) 
                                            ? item.ItemImage 
                                            : baseImageUrl + item.ItemImage) 
                                        :(item.ImageUrl 
                                            ? (item.ImageUrl.startsWith(baseImageUrl) 
                                                ? item.ImageUrl 
                                                : baseImageUrl + item.ImageUrl) 
                                            : 
                                            placeholderImage !='' 
                                                ? baseImageUrl+placeholderImage 
                                                : 'assets/images/Image_21.png')
                                        )
                                    }}"
                        onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
                                class="img-fluid" />
                        <h2 class="item-name">{{ item.Name }}</h2>
                        <span class="sold-out">{{soldOutText}}</span>    
                    </div>
                </div>
            </div>
        </div>
        <div class="popup-footer text-center">
            <button class="background-primary-color bold" (click)='removeFromCart()'>{{removeFromCartText}}</button>
        </div>
        
    </div>
</div>
