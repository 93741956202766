<!----------------------------------------------------------- Primary Page With Items   ---------------------------------------------------------------------------------->


<section class="zig-zag-bottom zig-zag-top dn-combo">

  <div class="wizard-container dbox-wrap">
    <ng-container *ngIf="isCombo && comboGroups && comboGroups.length > 0">
      <div class="title-wizard">
        <span class="title">{{headerText}}</span>
        <span class="sub-title">{{inputItem.ShortDescription}}</span>
      </div>
      <div class="choices h-49vh">
        <div class="choices-box" *ngFor=" let item of comboGroups[0].Items; let i = index">
          <label for="cb{{i}}" name="choice" id="cb{{i}}" [ngClass]="{active: this.comboItemFlags[i].selected}"
            (click)="onComboItemSelect($event,item, i)">
            <div class="choice-type">
              <!-- <img src="{{ baseImageUrl + item.ImageUrl }}"
                onerror="this.onerror=null; this.src='assets/images/Image_21.png'" alt="" /> -->
              <img src="{{ item.ImageUrl | functionCall : getImagePath: baseImageUrl : placeholderImage}}"
                onerror="this.onerror=null; this.src='assets/images/Image_21.png'" alt="" />
              <span>
                {{item.Name}}
              </span>
            </div>
            <div class="qty">{{this.comboItemFlags[i].item.ItemConsolidateQuantity}}</div>
          </label>
        </div>
      </div>
    </ng-container>
  </div>
</section>

<!-- Modals -->
<div style="display: none">
  <app-choose-donut-customize-popup>

  </app-choose-donut-customize-popup>
</div>
<!-- Box Full Popup -->
<div style="display: none">
  <app-box-builder-full>

  </app-box-builder-full>
</div>