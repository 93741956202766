<div class="cartFooter ">
  <footer class="menu-cart2" style=" height:{{itemContainerHeight}}">
    <app-scan-cart-item (changePrice)="updatePrice()" [isCartOpen]="true" [removeAlk]="removeAlk"
      [isHybrid]="false"></app-scan-cart-item>
  </footer>
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <app-scan-checkout-box (cleared)="clearCart()" [isCartOpen]="true">
      </app-scan-checkout-box>
    </div>
  </div>
</div>