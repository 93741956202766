import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralSetting } from '../../../services/generalSetting.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-are-you-sure',
  templateUrl: './are-you-sure.component.html',
  styleUrls: ['./are-you-sure.component.css'],
})
export class AreYouSureComponent implements OnInit, OnDestroy {
    
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  
  ref: any;
  
  languageSub!: Subscription;

  positiveColor: string | null = '';
  
  positiveColorText: string | null = '';
  
  negativeColor: string | null = '';
  
  negativeColorText: string | null = '';
  
  cancelOrderImage: string | null = '';
  
  yesText: string = '';
  
  noText: string = '';
  
  cancelOrderText: string = '';
  
  confirmText: string = '';
  
  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  constructor(private readonly isKioskService: IsKioskService,
    private language: LanguageService
  ) {}
  
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    // if (GeneralSetting.getCancelOrderImage() != '') {
    //   this.cancelOrderImage =
    //     this.baseImageUrl + GeneralSetting.getCancelOrderImage();
    // } else {
      this.cancelOrderImage = 'assets/images/ic_cancel_order_placeholder.png';
    // }
    this.positiveColor = GeneralSetting.getPositiveColor();
    this.positiveColorText = GeneralSetting.getPositiveColorText();
    this.negativeColor = GeneralSetting.getNegativeColor();
    this.negativeColorText = GeneralSetting.getNegativeColorText();
  }

  loadText() {
    this.cancelOrderText = this.language.getTextElement('cancel_order');
    this.confirmText = this.language.getTextElement(
      'txt_are_you_sure_you_want_to_cancel_this_order_and_start_again'
    );
    this.yesText = this.language.getTextElement('txt_yes_cancel_order');
    this.noText = this.language.getTextElement('txt_no_back_to_order');
  }

  public static open(modalService: NgbModal) {
    var ref = modalService.open(AreYouSureComponent, {
      size: 'lg',
      centered: true,
    });
    ref.componentInstance.ref = ref;
    return ref;
  }

  cancel() {
    this.ref.close('cancel');
  }
 
  close() {
    this.ref.close('close');
  }
}
