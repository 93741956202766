import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { DatabaseHandler } from '../DatabaseHandler';
import { LogService } from '../services/log.service';
import * as _ from 'lodash';
import { itemTags } from '../models/item-tags';

@Injectable({
  providedIn: 'root',
})
export class ItemTagsService {

  CategoryID: string = '';

  constructor(private logger: LogService) { }

  getItemTagsByItemID(itemID: string): Observable<itemTags[]> {
    return from(this.getItemTagsFromSQLByItemID(itemID));
  }

  getItemTagsByCatID(): Observable<itemTags[]> {
    return from(this.getItemTagsFromSQLByCategoryID(this.CategoryID));
  }

  getItemTagsByComboID(
    comboGroupID: string,
    productId: string
  ): Observable<itemTags[]> {
    return from(this.getItemTagsFromSQLByComboID(comboGroupID, productId));
  }

  getItemTagsByModID(id: string): Observable<itemTags[]> {
    return from(this.getItemTagsFromSQLByModifierID(id));
  }

  getItemTagsFromSQLByItemID(itemID: string) {
    return new Promise<itemTags[]>((resolve: any, reject: any) => {
      const getFilterIcons = `SELECT
      I.ItemID,
      I.Name,
      GROUP_CONCAT(DISTINCT(FM.IconImage)) AS 'ItemTags', 
      GROUP_CONCAT(DISTINCT(FM.FilterName)) AS 'TagNames',
      IFM.ItemID,
      IFM.FilterID,
      FM.FilterID,
      FM.IconImage,
      FM.FilterName
      FROM Items as I
      LEFT JOIN Item_Filter_Mappings as IFM
        ON IFM.ItemID = I.ItemID
      LEFT JOIN FilterMasters as FM
        ON FM.FilterID = IFM.FilterID
        WHERE
        I.ItemID = '${itemID}'
        AND I.IsActive = 'True'
        AND I.IsDeleted = 'False'
        AND I.IsShowOnKiosk = 'True'
        GROUP BY
        I.ItemID
    `;
      const logError = (statement: any, error: any) => {
        console.log('Item Tags Service , Error getItemTagsFromSQLByItemID :- ', error);

        resolve([]);
      };

      const setItems = (transaction: String, results: any) => {
        var data: itemTags[] = Array.from(results.rows);

        for (let i = 0; i < data.length; i++) {
          data[i].ItemTags = data[i].ItemTags?.toString().split(',');
          data[i].TagNames = data[i].TagNames?.toString().split(',');
        }

        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getFilterIcons,
        [],
        setItems,
        logError
      );
    });
  }

  getItemTagsFromSQLByCategoryID(categoryID: string) {
    return new Promise<itemTags[]>((resolve: any, reject: any) => {
      const getItemTags = `   SELECT 
          I.ItemID, 
          I.Name,
          GROUP_CONCAT(DISTINCT(FM.IconImage)) AS 'ItemTags', 
          GROUP_CONCAT(DISTINCT(FM.FilterName)) AS 'TagNames' 
          FROM
          Items as I, 
          FilterMasters as FM
          JOIN Item_Category_Mappings as CMM
            ON CMM.itemID = I.ItemID
          JOIN CategoryMasters as CM
            ON CM.CategoryID = CMM.categoryID
            AND CM.IsDeleted = 'False'
            AND CM.IsActive = 'True'
            AND CMM.CategoryID = '${categoryID}'
          JOIN Item_Filter_Mappings as IFM
            ON IFM.ItemID = I.ItemID
          JOIN FilterMasters
              ON FM.FilterID = IFM.FilterID
              GROUP BY
              I.ItemID
    `;
      const logError = (statement: any, error: any) => {
        console.log('Item Tags Service , Error getItemTagsFromSQLByCategoryID :- ', error);

        resolve([]);

      };

      const setItems = (transaction: String, results: any) => {
        var data: itemTags[] = Array.from(results.rows);
        for (let i = 0; i < data.length; i++) {
          data[i].ItemTags = data[i].ItemTags?.toString().split(',');
          data[i].TagNames = data[i].TagNames?.toString().split(',');
        }

        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(getItemTags, [], setItems, logError);
    });
  }

  getItemTagsFromSQLByComboID(comboGroupID: string, productId: string) {
    return new Promise<itemTags[]>((resolve: any, reject: any) => {
      const getItemTags = `
      SELECT 
      I.ItemID,
      I.Name,
      GROUP_CONCAT(DISTINCT(FM.IconImage)) AS 'ItemTags', 
      GROUP_CONCAT(DISTINCT(FM.FilterName)) AS 'TagNames',
      ComboGroupItemMasters.ComboID
      FROM
      Items as I, ComboGroupItemMasters
      JOIN Item_Category_Mappings as CMM
        ON CMM.itemID = I.ItemID
      JOIN CategoryMasters as CM
        ON CM.CategoryID = CMM.categoryID
        AND CM.IsDeleted = 'False'
        AND CM.IsActive = 'True'
        AND ComboGroupItemMasters.ComboID = '${productId}'
        AND ComboGroupItemMasters.ComboGroupID= '${comboGroupID}'  
      LEFT JOIN Item_Filter_Mappings as IFM
        ON IFM.ItemID = I.ItemID
      JOIN FilterMasters as FM
          ON FM.FilterID = IFM.FilterID 
          WHERE
          I.ItemId = ComboGroupItemMasters.ItemId
          AND
          I.isActive = 'True'
          GROUP BY
          I.ItemID
      `;
      const logError = (statement: any, error: any) => {
        console.log('Item Tags Service , Error getItemTagsFromSQLByComboID :- ', error);

        resolve([]);
      };
      const setItems = (transaction: String, results: any) => {
        var data: itemTags[] = Array.from(results.rows);
        for (let i = 0; i < data.length; i++) {
          data[i].ItemTags = data[i].ItemTags?.toString().split(',');
          data[i].TagNames = data[i].TagNames?.toString().split(',');
        }

        resolve(data);
      };
      DatabaseHandler.executeSqlStatement(getItemTags, [], setItems, logError);
    });
  }

  getItemTagsFromSQLByModifierID(id: string) {
    return new Promise<itemTags[]>((resolve: any, reject: any) => {
      const getItemTags = `
      SELECT 
      MM.ModifierID AS ModifierID 
      ,GROUP_CONCAT(DISTINCT(FM.IconImage)) AS 'ItemTags' 
      ,GROUP_CONCAT(DISTINCT(FM.FilterName)) AS 'TagNames' 
      ,I.IngredientID AS IngredientID 
      ,I.Name AS Name 
      ,I.DisplayName AS DisplayName 
      FROM 
      ModifierMasters AS MM, Ingredient_Filter_Mappings as IFM 
      JOIN ModifierDetailMasters AS MDM 
        ON MDM.ModifierID = MM.ModifierID 
        AND MDM.IsActive = 'True' 
      JOIN FilterMasters AS FM 
        ON FM.FilterID = IFM.FilterID  
      JOIN Ingredients AS I 
        ON I.IngredientID = MDM.IngredientID 
        AND I.IsActive = 'True' 
        AND I.IsDeleted = 'False' 
      JOIN Ingredient_Filter_Mappings
        ON IFM.IngredientID = I.IngredientID 
        WHERE 
        MM.ModifierID = '${id}' 
        AND MM.IsActive = 'True' 
        AND MM.IsShowOnKIOSK = 'True'
        AND MDM.IngredientID = I.IngredientID 
        GROUP BY I.IngredientID 
      `;
      const logError = (statement: any, error: any) => {
        console.log('Item Tags Service , Error getItemTagsFromSQLByModifierID :- ', error);

        resolve([]);
      };
      const setItems = (transaction: String, results: any) => {
        var data: itemTags[] = Array.from(results.rows);
        for (let i = 0; i < data.length; i++) {
          data[i].ItemTags = data[i].ItemTags?.toString().split(',');
          data[i].TagNames = data[i].TagNames?.toString().split(',');
        }

        resolve(data);
      };
      DatabaseHandler.executeSqlStatement(getItemTags, [], setItems, logError);
    });
  }
 
}
