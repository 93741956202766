<!-- <button (click)="printThis()">print container</button> -->
<div class="variation-container">
  <div *ngFor="let item of inputVariations; let i = index">
    <app-variation
      [variation]="item"
      [variationIndex]="i"
      (SelectedVariationEmiter)="setVariationOptionSelected($event, i)"
    ></app-variation>
  </div>
</div>
