import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ManagerPinDialogComponent } from 'src/app/components/manager-pin-dialog/manager-pin-dialog.component';
import { LanguageService } from 'src/app/services/language.service';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-logout-confirmation-dialog',
  templateUrl: './logout-confirmation-dialog.component.html',
  styleUrls: ['./logout-confirmation-dialog.component.css'],
  encapsulation: ViewEncapsulation.None,
  styles: [`.customLogout .modal-content {
    padding: 0;
  border-radius:2rem;
  background-color: rgba(0,0,0,0)
 }`]
})
export class LogoutConfirmationDialogComponent implements OnInit,OnDestroy {
  modalReference: any;
languageSub!:Subscription
  @Input() context: any;
  @ViewChild(ManagerPinDialogComponent) managerPin!: ManagerPinDialogComponent;
confirmationText:string=''
cancelText:string=''
yesText:string=''
confirmLogoutText:string=''
wipeDataText:string=''
  constructor(private router: Router, public modalService: NgbModal, private language:LanguageService) {

  }
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText(){
    this.confirmationText=this.language.getTextElement('txt_confirmation')
    this.cancelText=this.language.getTextElement('lbl_button_cancel')
    this.yesText=this.language.getTextElement('txt_yes')
    this.confirmLogoutText=this.language.getTextElement('confirm_logout')
    this.wipeDataText=this.language.getTextElement('wipe_all_data')
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  open() {
    let ref = this.modalService.open(LogoutConfirmationDialogComponent, {
      centered: true,
      size: 'md',
      windowClass: 'customLogout'
    });
    ref.componentInstance.modalReference = ref;
    this.modalReference = ref;
  }

  confirmed() {
    this.modalReference.close();
    this.managerPin.open();
    this.router.navigateByUrl('/settings/logout');
    // window.onmessage = (event: any) => {
    //   if (
    //     event.data.messageType == 'DeleteFinish' ||
    //     event.data.messageType == 'Delete'
    //   ) {
    //     this.router.navigateByUrl('/settings/logout');
    //   }
    // };

    // var message = {
    //   messageType: 'Delete',
    // };
    // window!.top!.postMessage(message, '*');
  }

  canceled() {
    this.modalReference.close();
  }
}
