<main class="meulist review">
    <div class="position-relative h-85vh">

        <div class="cons-header" [style.background-color]="bkgdColor"
        style="background-image : url({{ concessionaireHeaderImage | functionCall : setHeaderImage : imageBaseUrl }})">
            <h2>{{reviewOrderText}}</h2>
            <button class="btn btn-primary" *ngIf="isLoyalty && useLoyalty && !isRemovePricingAndPayments" (click)="onUserClick()">
                <span class="material-icons"> account_circle </span>{{ getUserName() }}
            </button>
        </div>

        <app-order-review-list
            style="height :{{ hasPromos | functionCall : getHeightOfList : loyaltyPromos : isLoyalty : hasPromosCoupon :  isRemovePricingAndPayments}}"
            [hasPromos]="hasPromos" [loyaltyPromos]="loyaltyPromos" [sibling]="orderReviewFooterChild" [promo]="promo"
            [hasPromosCoupon] = "hasPromosCoupon" [smbdiscount]="smbdiscount">
        </app-order-review-list>


        <app-smb-discounts></app-smb-discounts>
        <!-- <app-order-review-promotions [subtotal]="subtotalOutput" [orderReviewFooter]="orderReviewFooterChild"
            [total]="totalOutput" [inputTax]="taxOutput" (signInClicked)="onUserClick()"
            (hasPromos)="checkPromos($event)" (updateCalculation)="updatePrices()"
            (hasPromosLoyalty)="checkPromosLoyalty($event)" (hasPromosCoupon)="checkHasPromoCoupon($event)">
        </app-order-review-promotions> -->
    </div>

    <app-order-review-footer [promo]="promo" [smbdiscount]="smbdiscount" [orderReviewList]="cart" (subtotalChanged)="getSubtotal($event)"
        (totalChanged)="getTotal($event)" (taxChanged)="getTax($event)" (updateCalculation)="updatePrices()"
        (removeEmitter)="removePromo($event)"></app-order-review-footer>
    <app-customer-login-popup [hidden]="true"></app-customer-login-popup>
    <app-customer-welcome-modal [hidden]="true"></app-customer-welcome-modal>
</main>
<!--
<div class="cons-two">
    <div class="scroll-btn">
        <span class="material-icons">
            keyboard_double_arrow_down
        </span>
    </div>

    <div class="cons-header" [style.background-color]="bkgdColor"
        style="background-image : url({{ concessionaireHeaderImage | functionCall : setHeaderImage : imageBaseUrl }})">
        <h2>Order Review</h2>
        <button class="btn btn-primary" *ngIf="isLoyalty && !isRemovePricingAndPayments" (click)="onUserClick()">
            <span class="material-icons"> account_circle </span>{{ getUserName() }}
        </button>
    </div>

    <app-order-review-list
        style="height :{{ hasPromos | functionCall : getHeightOfList : loyaltyPromos : isLoyalty : hasPromosCoupon :  isRemovePricingAndPayments}}"
        [hasPromos]="hasPromos" [loyaltyPromos]="loyaltyPromos" [sibling]="orderReviewFooterChild" [promo]="promo">
    </app-order-review-list>
    <app-order-review-promotions [subtotal]="subtotalOutput" [orderReviewFooter]="orderReviewFooterChild"
        [total]="totalOutput" [inputTax]="taxOutput" (signInClicked)="onUserClick()" (hasPromos)="checkPromos($event)"
        (updateCalculation)="updatePrices()" (hasPromosLoyalty)="checkPromosLoyalty($event)"
        (hasPromosCoupon)="checkHasPromoCoupon($event)"></app-order-review-promotions>

<app-order-review-footer [promo]="promo" [orderReviewList]="cart" (subtotalChanged)="getSubtotal($event)"
    (totalChanged)="getTotal($event)" (taxChanged)="getTax($event)" (updateCalculation)="updatePrices()"
    (removeEmitter)="removePromo($event)"></app-order-review-footer>
<app-customer-login-popup [hidden]="true"></app-customer-login-popup>
</div> -->
