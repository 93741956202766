export interface Message {
  messageType: string;
  data?: any;
  baseUrl?: string;
}

export interface DownloadMessage {
  messageType: string;
  imageUrl: string;
  failed: boolean;
  onlineImage: string;
  timedOut: boolean;
}

export enum MessageType {
  Print = "Print",
  Download = "Download",
  startDownload = "startDownload",
  Delete = "Delete",
  sendToPrinters = "sendToPrinters",
  getPrinters = "getPrinters",
  refreshPrinters = "refreshPrinters",
  getSerialNo = "getSerialNo",
  startScanner = "startScanner",
  stopScanner = "stopScanner",
  getDeviceName = "getDeviceName",
  getExistingFiles = "getExistingFiles",
  SerialNoReturn = "SerialNoReturn",
  ExistingFiles = "ExistingFiles",
  DownloadImage = "DownloadImage",
  CompletedImage = "CompletedImage",
  DownloadComplete = "DownloadComplete"
}
