<div class="weight-wrap">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click) = "cancel()">
        <span aria-hidden="true">&times;</span>
      </button>
    <img src="../../../../assets/images/Scale.gif" alt="weight image" />
    
    <h1>{{placeOnScaleText}}</h1>
    <p>{{placeOnScaleByKioskText}}</p>
    <div *ngIf="isgettingData"
     class="spinner-border" 
     style="width: 10rem; height: 10rem; border-width: 1rem;" 
     role="status"
      [ngStyle]="{
               color: positiveButton
               }">
        <span class="sr-only">{{loadingText}}</span>
    </div>
    <button *ngIf="!isgettingData" class="mat-ripple sk-btn" (click) = 'continue()'>{{continueText}}</button>
</div>
