<section>
    <div class="payment-insert" role="document">
        <div class="payment-modal">          
            <h2 class="text-center">{{paymentApprovedText}}</h2>                
            <div class="col-md-12 col-xs-12 p-0 insert-img">
                <!-- Verifone -->
                <img [attr.src]="[isVerifone? '../../../../assets/images/Payment-Complete.gif' : '../../../../assets/images/Dejavoo-approved.gif']" onerror="this.onerror=null; this.src='assets/images/Image_21.png'" />

                <!-- Clover-->
                <!-- <img [attr.src]="['../../../../assets/images/Clover-complete.gif']" onerror="this.onerror=null; this.src='assets/images/Image_21.png'" /> -->
            </div>
        </div>
    </div>
</section>
