<div class="container">
  <i (click)="cancel()" class="fas fa-times"></i>
  <div class="contents">
    <p class="uhoh">{{uhOhText}}</p>
    <p class="message">{{contactManagerText}}</p>
    <div (click)="cancel()" [style.backgroundColor]="positiveColor" class="ok">
      <p>{{okText}}</p>
    </div>
  </div>
</div>
