import { stringify } from 'flatted';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { DatabaseHandler } from '../DatabaseHandler';
import { grubbrrPayCredentials } from '../models/grubbrrPayCredentials';
import { loggingData, LogService } from 'src/app/services/log.service';
import { environment } from '../../environments/environment';
import { GeneralSetting } from './generalSetting.service';
import { PaymentErrorDialogService } from '../components/dialogs/payment-error/payment-error-dialog.service';
import { PaymentInsertService } from '../components/dialogs/payment-insert/payment-insert.service';
import { DatabaseService } from './database.service';
import { PaymentTypesService } from './payment-types.service';
import { PaymentSuccessDialogService } from '../components/dialogs/payment-success/payment-success-dialog.service';
import { RefundSuccessDialogService } from '../components/dialogs/refund-success/refund-success-dialog.service';
import { LanguageService } from './language.service';
import { resolve } from 'dns';
import { timeStamp } from 'console';


@Injectable({
  providedIn: 'root',
})
export class DejavooService {
  gpCreds: grubbrrPayCredentials = {
    AuthanticationKey: '',
    ServiceCharge: '',
    RegistrationID: '',
    TPNNumber: '',
    TPaymentID: '',
  };

  NodeUrl: string = environment.NodeUrl;

  constructor(private http: HttpClient,
    private logger: LogService,
    private language: LanguageService,
    private readonly paymentErrorDialog: PaymentErrorDialogService,
    private readonly paymentInsertDialog: PaymentInsertService,
    private readonly paymentTypeService: PaymentTypesService,
    private readonly paymentSuccessModel: PaymentSuccessDialogService,
    private readonly refundSucceeDialog: RefundSuccessDialogService
  ) { }

  getGrubbrrPayDevice(deviceId: string): Observable<any> {
    return from(this.getGrubbrrPayDeviceFromSql(deviceId));
  }

  getGrubbrrPayDeviceFromSql(deviceId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let query = `SELECT * FROM GrubbrrPay WHERE DeviceID = '${deviceId}'`;

      function callback(tx: string, data: any) {
        // var results: grubbrrPayCredentials[] = Array.from(data.rows);
        resolve(Array.from(data.rows));
      }

      function errorCallback(tx: string, data: any) {
        console.log("GrubbrrPay Device detail get Error");

        resolve([]);
      }
      DatabaseHandler.executeSqlStatement(query, [], callback, errorCallback);
    });
  }

  getDeviceStatus(deviceId: string) {
    let body = {
      id: deviceId,
    };

    var headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.NodeUrl + 'proxy-dejavoo', JSON.stringify(body), {
      headers,
      responseType: 'text',
    }).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    var log = new loggingData(
      'Data Error',
      'Encountered Error on Data Fetch',
      `Error`,
      `Encountered Error: ${error}`,
      true
    );
    this.logger.sendLogToServer(log);
    return throwError(error.message);
  }

  doTransaction(
    obj: any,
    total: string,
    type: 'refund' | 'payment' = 'payment',
    refID: string | null = null
  ) {
    let newTotal = 0;
    if (type == 'payment') {
      newTotal = Number(total) + Number(obj.ServiceCharge);
    } else {
      newTotal = Number(total);
    }

    const body = {
      refID: refID,
      regID: obj.RegistrationID,
      authToken: obj.AuthanticationKey,
      amount: newTotal.toFixed(2),
      TPN: obj.TPNNumber
    };

    var headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(this.NodeUrl + `${type}-dejavoo`, JSON.stringify(body), {
        headers,
        responseType: 'text',
      })
      .pipe(catchError(this.errorHandler));
  }

  public async doPayment(
    amount: number,
    invoicNo: string,
    calculationData: {
      total: number;
      subtotal: number;
      tax: number;
      tip: number;
      shippingFee: number;
      discount: number;
      reward: number;
    }) {

    let error = this.language.getTextElement('txt_kiosk_payment_device_not_set_up');

    this.paymentInsertDialog.openPaymentInsertModal(
      false,
      calculationData,
      this.paymentTypeService.payments.length == 1 &&
      GeneralSetting.getShowPaymentScreen() == 'True'
    );

    let grubbrrPayDevice = await this.getGrubbrrPayDeviceFromSql(GeneralSetting.getSerialNo()).then();

    if (!grubbrrPayDevice || grubbrrPayDevice.length <= 0) {
      this.logger.sendLogToServer(new loggingData(
        'GrubbrrPay Payment Error',
        `GrubbrrPay Encountered Error Fetching Device Data
          order invoice no :- ${invoicNo}
        `,
        'Error Payment GrubbrrPay',
        `GrubbrrPay Device Not Set Up`,
        true
      ));

      this.paymentInsertDialog.closePaymentInsertModel();

      await this.paymentErrorDialog.openPaymentErrorDialog(
        false,
        error
      );

      return false;
    }

    let gpCreds = grubbrrPayDevice[0];

    let deviceStatus = await this.getDeviceStatusAsync(gpCreds.TPNNumber);

    if (!deviceStatus) {
      this.logger.sendLogToServer(new loggingData(
        'GrubbrrPay Payment Error',
        `GrubbrrPay Payment Offline
          order invoice no :- ${invoicNo}
        `,
        'GrubbrrPay Device Offline',
        `GrubbrrPay Device offline`,
        true
      ));

      error = this.language.getTextElement('txt_kiosk_payment_device_offline');

      this.paymentInsertDialog.closePaymentInsertModel();

      await this.paymentErrorDialog.openPaymentErrorDialog(
        false,
        error
      );

      return false;
    }

    let transRes = await this.doTransactionAsync(gpCreds,amount.toString());

    if(transRes.IsError){
      this.logger.sendLogToServer(new loggingData(
        'GrubbrrPay Payment Error',
        `GrubbrrPay Pay Encounter unknown Error
          order invoice no :- ${invoicNo}
        `,
        'Error Payment GrubbrrPay',
        `GrubbrrPay Encountered Error: ${transRes}`,
        true
      ));

      error = this.language.getTextElement('txt_transaction_failed');

      this.paymentInsertDialog.closePaymentInsertModel();

      await this.paymentErrorDialog.openPaymentErrorDialog(
        false,
        error
      );

      return false;
    }
    
    this.logger.sendLogToServer(new loggingData(
      'GrubbrrPay Payment Res',
      `GrubbrrPay Payment Succees
        order invoice no :- ${invoicNo}
      `,
      'Grubbrr Pay Payment Sucess',
      JSON.stringify(transRes) ,
      true
    ));

    GeneralSetting.setPaymentResponse(JSON.stringify(transRes.TransactionData));

    this.paymentInsertDialog.closePaymentInsertModel();

    return true;
  }

  private getDeviceStatusAsync(deviceId: string) {
    return new Promise<boolean>((resolve) => {
      let body = {
        id: deviceId,
      };

      var headers = new HttpHeaders().set('Content-Type', 'application/json');
      this.http.post(this.NodeUrl + 'proxy-dejavoo', JSON.stringify(body), {
        headers,
        responseType: 'text',
      }).pipe(catchError(this.errorHandler))
        .pipe(timeout(300000))
        .toPromise().then(data => {
          if (data == "Online") {
            resolve(true);
          }
          else {
            resolve(false);
          }
        }, error => {
          resolve(false);
        })
    });
  }

  private doTransactionAsync(
    obj: any,
    total: string,
    type: 'refund' | 'payment' = 'payment',
    refID: string | null = null
  ) {
    return new Promise<any>((resolve) => {

      let newTotal = 0;
      if (type == 'payment') {
        newTotal = Number(total) + Number(obj.ServiceCharge);
      } else {
        newTotal = Number(total);
      }

      const body = {
        refID: refID,
        regID: obj.RegistrationID,
        authToken: obj.AuthanticationKey,
        amount: newTotal.toFixed(2),
        TPN: obj.TPNNumber
      };

      var headers = new HttpHeaders().set('Content-Type', 'application/json');
      this.http
        .post(this.NodeUrl + `${type}-dejavoo`, JSON.stringify(body), {
          headers,
          responseType: 'text',
        })
        .pipe(catchError(this.errorHandler))
        .pipe(timeout(300000))
        .toPromise()
        .then(async (data: any) => {
       
          var res = {
            IsError: true,
            IsSuccess: false,
            Error: "Payment Device Connection Error",
            ErrorCode: "0",
            ErrorMessage: "Payment Device Connection Error",
            IsCancel: false,
            IsNoDevice: true,
            IsTimeout: true,
            ErrorData: "",
            TransactionData: data
          };

          let resStatus = this.onPaymentResponse(data);

          if (resStatus) {
            res.IsError = false;
            res.IsSuccess = true;
            res.ErrorData = "";
            res.TransactionData = data;
            resolve(res);
          }
          else {
            res.IsError = true;
            res.IsSuccess = false;
            res.ErrorData = data;
            res.TransactionData = "";
            res.ErrorMessage = "Tranaction Failed";
            resolve(res);
          }
        }, error => {
          this.logger.sendLogToServer(new loggingData(
            'GrubbrrPay Payment Res Error',
            'GrubbrrPay Res Error',
            'Grubbrr Pay Payment Res Error',
            JSON.stringify(error) ,
            true
          ));
          var res = {
            IsError: true,
            IsSuccess: false,
            Error: "Payment Device Connection Error",
            ErrorCode: "0",
            ErrorMessage: "Transaction Failed",
            IsCancel: false,
            IsNoDevice: true,
            IsTimeout: true,
            ErrorData: error,
            TransactionData: undefined
          };
          resolve(res);
        });
    });
  }

  private onPaymentResponse(resData: any) {
    let parser = new DOMParser();
    let xmlResponse = parser.parseFromString(resData, 'text/xml');

    let resultCode = '';
    try {
      resultCode = xmlResponse
        .getElementsByTagName('ResultCode')[0]
        .childNodes[0].nodeValue!.toString();
    } catch (e) {
      return false;
    }

    if (resultCode !== '0') {
      return false;
    } else {
      try {
        let extData = xmlResponse
          .getElementsByTagName('ExtData')[0]
          .childNodes[0].nodeValue!.toString();
  
        let arr = extData.split(',');
  
        // get card type
        for (let element in arr) {
          if (Object.prototype.hasOwnProperty.call(arr, element)) {
            if (arr[element].toString().indexOf('CardType') !== -1) {
              let myArr = arr[element].split('=');
              GeneralSetting.setCardType(myArr[1]);
              break;
            }
          }
        }
      } catch (e) { }

      return true;
    }
  }

}
