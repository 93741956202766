<div class="alert-container">
    <div class="title primary-color">
        {{message}}
    </div>
    <button
      (click)="close()" 
        class="continue background-positive-button-color border-positive-button-color positive-button-text-color"
      >
       {{continueText}}
      </button>
</div>
