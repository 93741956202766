import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-special-request-dialog',
  templateUrl: './special-request-dialog.component.html',
  styleUrls: ['./special-request-dialog.component.css'],
})
export class SpecialRequestDialogComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  specialRequest: string = '';

  specialRequestText: string = '';

  perfectOrderText: string = '';

  yourRequestText: string = '';

  addSpecialRequestText: string = '';

  cancelText: string = '';

  oldSpecialRequest: string = '';

  ref: any;

  isKiosk = this.isKioskService.isKiosk();

  positiveColor: string | null = '';

  positiveColorText: string | null = '';

  negativeColor: string | null = '';
  negativeColorText: string | null = '';

  languageChoice:number=0
  languageSub!: Subscription;
  keySub!:Subscription

  @Output() closeModal = new EventEmitter<boolean>();

  constructor(
    private language: LanguageService,
    private readonly modalService: NgbModal,
    private KeyboardService: KeyboardService,
    private readonly isKioskService: IsKioskService
  ) {}

  ngOnInit(): void {
    this.KeyboardService.manual=false
    this.positiveColor = GeneralSetting.getPositiveColor();
    this.positiveColorText = GeneralSetting.getPositiveColorText();
    this.negativeColor = GeneralSetting.getNegativeColor();
    this.negativeColorText = GeneralSetting.getNegativeColorText();
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.languageChoice=val
      this.loadText();
    });
  }

  loadText() {
    this.specialRequestText = this.language.getTextElement( 'txt_special_request' );
    this.perfectOrderText = this.language.getCustomMapValues( 'menu_setting_display__special_request_header_text' );
    this.yourRequestText = this.language.getTextElement('your_request');
    this.addSpecialRequestText = this.language.getCustomMapValues( 'menu_setting_display__special_request_button_text' );
    this.cancelText = this.language.getTextElement('lbl_button_cancel');
  }

  public static open(modalService:NgbModal,specialRequest: string) {
    const ref = modalService.open(SpecialRequestDialogComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    ref.componentInstance.ref = ref;
    ref.componentInstance.specialRequest = specialRequest;
    ref.componentInstance.oldSpecialRequest = specialRequest;

    return ref;
  }

  open(specialRequest: string) {
    const ref = this.modalService.open(SpecialRequestDialogComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.specialRequest = specialRequest;
    ref.componentInstance.oldSpecialRequest = specialRequest;
    this.ref = ref;
  }

  doit2(){
    if(this.keySub){
      this.keySub.unsubscribe()
    }
    this.KeyboardService.newBoardSubject.next()
  }


  // used to open keyboard based on dialog required layout, maxlength and assign value to dialog/screen input field @jay
  doit(){
    this.KeyboardService.manual=true
    this.KeyboardService.maxLength=350
    this.KeyboardService.clearSub.next()
    let layout = this.languageChoice==2?'defSpan':'default';
    this.KeyboardService.open(layout);
    this.KeyboardService.stringSub.next()
    this.keySub=this.KeyboardService.buttonclicked.subscribe((val)=>{
      // this.handlespecialRequestChange(val)
    })
  }
  // to handle dialog/screen input field value as a first character and character after "." upercase and edit the request
  handlespecialRequestChange(val:string){
    let notusedvalue = ['{enter}','{alt}','{shift}','{default}']
    if(!notusedvalue.includes(val)){
      if(this.ref.componentInstance.specialRequest!=undefined){
        if(val == '{bksp}'){
          this.ref.componentInstance.specialRequest=this.ref.componentInstance.specialRequest.slice(0,-1);
        }else if(val == '{space}'){
          this.ref.componentInstance.specialRequest += ' '
        }
        else{
        this.ref.componentInstance.specialRequest+=val
        }
      }else{
        this.ref.componentInstance.specialRequest = '';
        this.ref.componentInstance.specialRequest+=val
      }
    }
  }

  addSpecialRequest() {
    this.KeyboardService.KeyboardStatus = false;
    if(this.specialRequest){
      this.specialRequest = this.specialRequest.trim();
    }
    this.ref.close(this.specialRequest);
  }

  cancelModal() {
    this.KeyboardService.KeyboardStatus = false;
    if (this.specialRequest != '' || this.specialRequest != null) {
      this.ref.close(this.oldSpecialRequest);
    } else {
      this.ref.close();
    }
  }
}
