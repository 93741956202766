import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IHttpHeaders } from './http.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { loggingData, LogService } from './log.service';
import { VEvoTransactionRes } from '../models/VerifoneEvoModels';
import { GeneralSetting } from './generalSetting.service';
import { LoaderService } from './loader.service';
import { PaymentErrorDialogService } from '../components/dialogs/payment-error/payment-error-dialog.service';
import { DatabaseService } from './database.service';
import { PaymentInsertService } from '../components/dialogs/payment-insert/payment-insert.service';
import { PaymentTypesService } from './payment-types.service';
import { CommonFunctions } from './common';
import { PaymentSuccessDialogService } from '../components/dialogs/payment-success/payment-success-dialog.service';
import { RefundSuccessDialogService } from '../components/dialogs/refund-success/refund-success-dialog.service';
@Injectable({
  providedIn: 'root',
})
export class VerifoneEvoService {
  private connectAndTransactionApi = 'vEvoDoPayment';
  private connectAndRefundApi = 'vEvoDoRefund';

  constructor(
    private http: HttpClient,
    private readonly logger: LogService,
    private readonly loader: LoaderService,
    private readonly paymentErrorDialog: PaymentErrorDialogService,
    private readonly paymentInsertDialog: PaymentInsertService,
    private readonly databaseService: DatabaseService,
    private readonly paymentTypeService: PaymentTypesService,
    private readonly paymentSuccessModel: PaymentSuccessDialogService,
    private readonly refundSucceeDialog: RefundSuccessDialogService
  ) { }

  public async doPayment(
    amount: number,
    invoicNo: string,
    calculationData: {
      total: number;
      subtotal: number;
      tax: number;
      tip:number;
      shippingFee: number;
      discount: number;
      reward: number;
    }
  ) {

    let ip = await this.getConncetionParameter();

    if (ip == "") {
      await this.paymentErrorDialog.openPaymentErrorDialog(
        true,
        'Payment Device not set up.'
      );
      return false;
    }

    this.paymentInsertDialog.openPaymentInsertModal(
      true,
      calculationData,
      this.paymentTypeService.payments.length == 1 &&
      GeneralSetting.getShowPaymentScreen() == 'True'
    );

    let amountStr = CommonFunctions.roundDigit(amount, 2)
      .toFixed(2)
      .replace(/\./g, '');

    let paymentData = {
      Amount: amountStr,
      ip: ip,
      port: '42173',
    };

    let transactionRes = await this.startPayment(paymentData, invoicNo);

    if (transactionRes) {
      this.loader.closeLoader();

      if (transactionRes.IsError) {
        this.paymentInsertDialog.closePaymentInsertModel();
        await this.paymentErrorDialog.openPaymentErrorDialog(
          true,
          transactionRes.ErrorMessage
        );
        return false;
      }

      if (transactionRes.IsSuccess) {
        this.paymentInsertDialog.closePaymentInsertModel();

        this.paymentSuccessModel.openPaymentSuccessModel(true);

        await CommonFunctions.delay(1000);

        this.paymentSuccessModel.closePaymentSuccessModel();

        return true;
      }
    }

    return false;
  }

  private async startPayment(paymentData: any, orderInvoiceNo: string) {
    var transactionRes = await this.doConnectAndStartEvoPayment(
      paymentData,
      orderInvoiceNo
    );
    if (!transactionRes) {
      this.logger.sendLogToServer(
        new loggingData(
          'Verifone Evo Transaction Res Failed',
          `Transaction Res Failed
           Order Invoice no :- ${orderInvoiceNo}
        `,
          'Response Log Failes',
          'No Response Received',
          false
        )
      );
      return transactionRes;
    }

    if (transactionRes.IsError) {
      //Add Transaction Error
      this.logger.sendLogToServer(
        new loggingData(
          'Verifone Evo Transaction Res Error',
          `Transaction Res Error 
           Order Invoice no :- ${orderInvoiceNo}
        `,
          'Response Log',
          JSON.stringify(transactionRes),
          false
        )
      );
      return transactionRes;
    }

    this.logger.sendLogToServer(
      new loggingData(
        'Verifone Evo Transaction Res Success',
        `Transaction Res Success 
         Order Invoice no :- ${orderInvoiceNo}
      `,
        'Response Log',
        JSON.stringify(transactionRes),
        false
      )
    );
    GeneralSetting.setPaymentResponse(
      JSON.stringify(transactionRes.TransactionData)
    );

    GeneralSetting.setCardType(transactionRes.TransactionData?.CardProductName);

    return transactionRes;
  }

  private async doConnectAndStartEvoPayment(
    paymentData: any,
    orderInvoiceNo: string
  ) {
    return new Promise<VEvoTransactionRes>((resolve) => {

      var headers = [] as IHttpHeaders[];
      headers.push({
        key: 'Content-Type',
        value: 'application/json',
      } as IHttpHeaders);
      headers.push({ key: 'Accept', value: 'application/json' } as IHttpHeaders);

      this.http
        .post<VEvoTransactionRes>(
          environment.NodeUrl + this.connectAndTransactionApi,
          paymentData
        )
        .pipe(catchError(this.errorHandler))
        .toPromise().then(data => {
          resolve(data);
        }, error => {
          var res = {
            IsError: true,
            IsSuccess: false,
            Error: "Payment Device Connection Error",
            ErrorCode: "0",
            ErrorMessage: "Payment Device Connection Error",
            IsCancel: false,
            IsNoDevice: true,
            IsTimeout: true,
            ErrorData: error,
            TransactionData: undefined
          } as VEvoTransactionRes;
          resolve(res);
        });

    });
  }

  private errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }

  private async getConncetionParameter() {
    const paymentCred = await this.databaseService.getPaymentDeviceFromSql();
    //paymentCred.HSN = '10.10.88.206';

    if (Object.entries(paymentCred).length < 0 || paymentCred.HSN == '') {
      return "";
    }

    GeneralSetting.setVerifonePosIp(paymentCred.HSN);

    return paymentCred.HSN;
  }

  public async doRefund(
    amount: number,
    transactionId: string,
    orderInvoiceNo: string
  ) {
    this.loader.openLoader();

    let ip = await this.getConncetionParameter();
    if (ip == "") {
      this.loader.closeLoader();
      await this.paymentErrorDialog.openPaymentErrorDialog(
        true,
        'Payment Device not set up.'
      );
      return false;
    }

    let amountStr = CommonFunctions.roundDigit(amount, 2)
      .toFixed(2)
      .replace(/\./g, '');

    let paymentData = {
      Amount: amountStr,
      ip: ip,
      port: '42173',
      tId: transactionId,
    };

    let transactionRes = await this.startRefund(paymentData, orderInvoiceNo);

    if (transactionRes) {
      this.loader.closeLoader();

      if (transactionRes.IsError) {
        await this.paymentErrorDialog.openPaymentErrorDialog(
          true,
          transactionRes.ErrorMessage
        );
        return false;
      }

      if (transactionRes.IsSuccess) {
        this.refundSucceeDialog.openRefundSuccessDialog();
        return true;
      }
    }

    return false;
  }

  private async startRefund(paymentData: any, orderInvoiceNo: string) {
    var transactionRes = await this.doConnectAndStartEvoRefund(paymentData);
    if (!transactionRes) {
      this.logger.sendLogToServer(
        new loggingData(
          'Verifone Evo Refund Res Failed',
          `Refund Res Failed
           Order Invoice no :- ${orderInvoiceNo}
        `,
          'Response Log Failes',
          'No Response Received',
          false
        )
      );
      return transactionRes;
    }

    if (transactionRes.IsError) {
      //Add Transaction Error
      this.logger.sendLogToServer(
        new loggingData(
          'Verifone Evo Refund Res Error',
          `Refund Res Error 
           Order Invoice no :- ${orderInvoiceNo}
        `,
          'Response Log',
          JSON.stringify(transactionRes),
          false
        )
      );
      return transactionRes;
    }

    this.logger.sendLogToServer(
      new loggingData(
        'Verifone Evo Refund Res Success',
        `Refund Res Success 
         Order Invoice no :- ${orderInvoiceNo}
      `,
        'Response Log',
        JSON.stringify(transactionRes),
        false
      )
    );

    return transactionRes;
  }

  private async doConnectAndStartEvoRefund(paymentData: any) {
    return new Promise<VEvoTransactionRes>((resolve) => {

      var headers = [] as IHttpHeaders[];
      headers.push({
        key: 'Content-Type',
        value: 'application/json',
      } as IHttpHeaders);
      headers.push({ key: 'Accept', value: 'application/json' } as IHttpHeaders);

      this.http
        .post<VEvoTransactionRes>(
          environment.NodeUrl + this.connectAndRefundApi,
          paymentData
        )
        .pipe(catchError(this.errorHandler))
        .toPromise().then(data => {
          resolve(data);
        }, error => {
          var res = {
            IsError: true,
            IsSuccess: false,
            Error: "Payment Device Connection Error",
            ErrorCode: "0",
            ErrorMessage: "Payment Device Connection Error",
            IsCancel: false,
            IsNoDevice: true,
            IsTimeout: true,
            ErrorData: error,
            TransactionData: undefined
          } as VEvoTransactionRes;
          resolve(res);
        });
    });
  }
}
