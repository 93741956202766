import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { Concessionaire } from '../../../models/BranchLoginModels';

@Component({
  selector: 'app-concessionaire-closed',
  templateUrl: './concessionaire-closed.component.html',
  styleUrls: ['./concessionaire-closed.component.css']
})
export class ConcessionaireClosedComponent implements OnInit {

  modalReference: any;

  languageSub!:Subscription

  removedConcessionaire: Concessionaire[] = [];

  concessionaireBaseUrl: string = "/uploads/ConcessionaireLogo/";

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  uhOhText:string=''
  concessionaireClosedText:string=''
  okText:string=''

  constructor(private readonly modalService: NgbModal,
    private isKioskService: IsKioskService,
    private language:LanguageService) {
  }
  loadText(){
    this.uhOhText=this.language.getTextElement('titile_uh_oh')
    this.concessionaireClosedText=this.language.getTextElement('concessionaire_are_closed')
    this.okText=this.language.getTextElement('txt_ok')
  }
  ngOnInit(): void {
    this.languageSub=this.language.localeCommunicator.subscribe((val)=>{
      this.loadText()
    })
  }

  open(removedConcessionaire: any[]) {
    this.modalReference = this.modalService.open(ConcessionaireClosedComponent, {
      backdrop: 'static',
      size: 'xl',
      centered: true,
    });
    this.modalReference.componentInstance.removedConcessionaire = removedConcessionaire;
    this.modalReference.componentInstance.modalReference = this.modalReference;
    return this.modalReference;
  }

  removeFromCart() {
    this.modalReference.close('removeItems');
  }

  getConcessionaireLogo(con: Concessionaire, imageBaseUrl: string, concessionaireBaseUrl: string) {
    return con.Logo && con.Logo != '' ? imageBaseUrl + concessionaireBaseUrl + con.Logo : '../../../assets/images/Image_21.png';
  }

}
