import { Injectable } from '@angular/core';
import { DatabaseHandler } from '../DatabaseHandler';
import { LogService } from 'src/app/services/log.service';
import { CommonFunctions } from './common';
import { ItemV2 } from '../models/item';
export interface SchedulePromos{
  SchedulePromotionId:string,
  Name:string,
  ItemID:string,
  NewPrice:string
}
@Injectable({
  providedIn: 'root',
})
export class SchedulePricingService {
  constructor(private logger: LogService) {}

  /**
   * @author Skarzold 
   * @description queries and applies schedule pricing for the specified items 
   * @param items the array of items to apply schedule pricing to
   * @returns promise containing modified items array with applied schedule pricing
   * @todo currently querying and recieving data for multiple menu tiers.
   *  Ideally will not receive data for more than one tier, however team seems to be 
   *  on different page regarding menu tiers' implementation.
   */
  findAndApplySchedulePricing(items: ItemV2[]): Promise<ItemV2[]> {
    return new Promise<ItemV2[]>((resolve) => {
      let a = this.getPromoPricingByDateFromSqlV2(items);
      let b = this.getPromoPricingByDayFromSql(items);
      Promise.all([a, b]).then((data:any) => {
        let c = [].concat.apply([], data);
        let newItems = this.applyPromos(items, c)
        resolve(newItems);
      });
    });
  }
  private applyPromos(items:ItemV2[],promos:SchedulePromos[]): ItemV2[]{
    for(let i=0;i<items.length;i++){
      let appliedSchedulePromos = promos.filter((x:SchedulePromos)=>x.ItemID==items[i].ItemID)
      for(let k=0;k<appliedSchedulePromos.length;k++){
        if(Number(appliedSchedulePromos[k].NewPrice) < Number(items[i].Price)&&Number(appliedSchedulePromos[k].NewPrice)){
          if(!items[i].AppliedPromoName){
            items[i].AppliedPromoName=appliedSchedulePromos[k].Name
            items[i].OldPrice=items[i].Price
            items[i].Price=appliedSchedulePromos[k].NewPrice
          }else{
            items[i].AppliedPromoName=appliedSchedulePromos[k].Name
            items[i].Price=appliedSchedulePromos[k].NewPrice
          }
        }
      }
    }
    return items
  }
  private getPromoPricingByDayFromSql(items: ItemV2[]): Promise<SchedulePromos[]> {
    return new Promise<SchedulePromos[]>((resolve) => {
      let currentTime = CommonFunctions.getCurrentTime();
      let dayId = new Date().getDay();
      if (dayId == 0) {
        dayId = 7;
      }
      let itemsString = '';
      for (let i = 0; i < items.length; i++) {
        itemsString += "'" + items[i].ItemID + "'" + ',';
      }
      itemsString = itemsString.slice(0, -1);
      const getPromoString = `SELECT DISTINCT 
      SPPCM.SchedulePromotionId      
        , SPPM.Name
        , SPPDM.ItemID
        , SPPDM.NewPrice
      FROM
        SchedulePromoPricingCategoryMappings AS SPPCM 
      LEFT JOIN SchedulePromoPricingMasters AS SPPM
        ON SPPCM.SchedulePromotionId = SPPM.SchedulePromotionId
      LEFT JOIN SchedulePromoPricingDetailMasters AS SPPDM
        ON SPPCM.SchedulePromotionId=SPPDM.SchedulePromotionId
      LEFT JOIN ScheduleItemWeekDayMaster AS SIWDM
        ON SIWDM.SchedulePromotionId=SPPDM.SchedulePromotionId
      WHERE 
      SPPM.IsByWeek = 'True'
      AND SPPDM.ItemID IN (${itemsString})
      AND SIWDM.DayID = '${dayId}'
      AND SIWDM.StartTime <= time('${currentTime}')
      AND SIWDM.EndTime >=time('${currentTime}')
      `;
      const errorCallback = (statement: any, error: any) => {
        console.log(
          'Schedule Pricing Service, Error getPromoPricingFromSql :- ',
          error
        );
        resolve([] as SchedulePromos[]);
      };
      const setItems = (tx: string, results: any) => {
        let data: SchedulePromos[] = Array.from(results.rows);
        resolve(data);
      };
      DatabaseHandler.executeSqlStatement(
        getPromoString,
        [],
        setItems,
        errorCallback
      );
    });
  }
  private getPromoPricingByDateFromSqlV2(items: ItemV2[]): Promise<SchedulePromos[]> {
    return new Promise<SchedulePromos[]>((resolve) => {
      let currentDate = CommonFunctions.getCurrentformatedtDate(new Date());
      let itemsString = '';
      for (let i = 0; i < items.length; i++) {
        itemsString += "'" + items[i].ItemID + "'" + ',';
      }
      itemsString = itemsString.slice(0, -1);
      const getPromoString = `
      SELECT DISTINCT 
        SPPCM.SchedulePromotionId      
        , SPPM.Name
        , SPPDM.ItemID
        , SPPDM.NewPrice
      FROM
        SchedulePromoPricingCategoryMappings AS SPPCM 
      LEFT JOIN SchedulePromoPricingMasters AS SPPM
        ON SPPCM.SchedulePromotionId = SPPM.SchedulePromotionId
      LEFT JOIN SchedulePromoPricingDetailMasters AS SPPDM
        ON SPPCM.SchedulePromotionId=SPPDM.SchedulePromotionId
      WHERE 
      SPPM.IsByDate = 'True'      
      AND SPPDM.ItemID IN (${itemsString})
      AND SPPM.StartDate <= '${currentDate}'
      AND SPPM.EndDate >='${currentDate}'
      `;
      const errorCallback = (statement: any, error: any) => {
        console.log(
          'Schedule Pricing Service, Error getPromoPricingFromSql :- ',
          error
        );
        resolve([] as SchedulePromos[]);
      };
      const setItems = (tx: string, results: any) => {
        let data: SchedulePromos[] = Array.from(results.rows);
        resolve(data);
      };
      DatabaseHandler.executeSqlStatement(
        getPromoString,
        [],
        setItems,
        errorCallback
      );
    });
  }
}

  // getPromoPricingFromSql() {
  //   const date = new Date();
  //   let day = String(date.getDay());
  //   if (day == '0') {
  //     day = '7';
  //   }
  //   return new Promise((resolve) => {
  //     const getPromoString = `
  //     SELECT DISTINCT
  //     SPPCM.SchedulePromotionId
  //     , SPPCM.CategoryId
  //     , SPPM.IsByWeek
  //     , SPPM.IsByDate
  //     , SPPM.StartDate
  //     , SPPM.EndDate
  //     , SPPM.Name
  //     , SIWDM.DayID
  //     , SIWDM.StartTime
  //     , SIWDM.EndTime
  //     , SPPDM.ItemID
  //     , SPPDM.BasePrice
  //     , SPPDM.NewPrice
  //     , ICM.CategoryID
  //     , ICM.ItemID
  //     FROM
  //     SchedulePromoPricingCategoryMappings AS SPPCM
  //     LEFT JOIN 
  //     SchedulePromoPricingMasters AS SPPM
  //     ON 
  //     SPPCM.SchedulePromotionId = SPPM.SchedulePromotionId
  //     LEFT JOIN 
  //     ScheduleItemWeekdayMaster AS SIWDM
  //     ON SPPCM.SchedulePromotionId=SIWDM.SchedulePromotionId
  //     LEFT JOIN
  //     SchedulePromoPricingDetailMasters AS SPPDM
  //     ON SPPCM.SchedulePromotionId=SPPDM.SchedulePromotionId
  //     JOIN Item_Category_Mappings as ICM on SPPDM.ItemID=ICM.ItemID
  //      `;
  //     const errorCallback = (statement: any, error: any) => {
  //       //console.log(error);
  //       console.log(
  //         'Schedule Pricing Service, Error getPromoPricingFromSql :- ',
  //         error
  //       );

  //       resolve([]);
  //     };
  //     const setItems = (tx: string, results: any) => {
  //       let data: any[] = Array.from(results.rows);
  //       let hours = String(date.getHours());
  //       if (hours.length === 1) {
  //         hours = '0' + hours;
  //       }
  //       let minutes = String(date.getMinutes());
  //       if (minutes.length === 1) {
  //         minutes = '0' + minutes;
  //       }
  //       let numMinutes = Number(minutes);
  //       let numHours = Number(hours);
  //       let applicableData: any[] = data.filter((el: any) => {
  //         const date = new Date();
  //         let day = String(date.getDay());
  //         if (day == '0') {
  //           day = '7';
  //         }
  //         //check if element by week
  //         if (el.IsByWeek == 'True') {
  //           // if yes, check day similarity
  //           if (el.DayID == day) {
  //             //if day similarity, check time similarity
  //             //reserve data space for time comparison in parental scope
  //             let tempTransform: any;
  //             let transformedTime: any;
  //             let splitTime: any;
  //             let StartHours: any;
  //             let StartMinutes: any;
  //             let EndHours: any;
  //             let EndMinutes: any;
  //             if (el.StartTime && el.StartTime != '' && el.StartTime != null) {
  //               //begin transformation
  //               tempTransform = el.StartTime.split('.');
  //               transformedTime = tempTransform[0];
  //               splitTime = transformedTime.split(':');
  //               StartHours = Number(splitTime[0]);
  //               StartMinutes = Number(splitTime[1]);
  //               //begin time comparison
  //               if (
  //                 numHours > StartHours ||
  //                 (numHours == StartHours && numMinutes >= StartMinutes)
  //               ) {
  //                 tempTransform = el.EndTime.split('.');
  //                 transformedTime = tempTransform[0];
  //                 splitTime = transformedTime.split(':');
  //                 EndHours = Number(splitTime[0]);
  //                 EndMinutes = Number(splitTime[1]);
  //                 if (
  //                   numHours < EndHours ||
  //                   (numHours == EndHours && numMinutes <= EndMinutes)
  //                 ) {
  //                   //if yes, check date similarity
  //                   if (el.IsByDate == 'True') {
  //                     //if date similar, return item
  //                     let elStartDate = Date.parse(
  //                       String(
  //                         el.StartDate.slice(0, -2) +
  //                           ' ' +
  //                           el.StartDate.slice(-2)
  //                       )
  //                     );
  //                     let elEndDate = Date.parse(
  //                       String(
  //                         el.EndDate.slice(0, -2) + ' ' + el.EndDate.slice(-2)
  //                       )
  //                     );
  //                     if (
  //                       Date.parse(String(date)) >= elStartDate &&
  //                       Date.parse(String(date)) <= elEndDate
  //                     ) {
  //                       return el;
  //                     }
  //                   } else {
  //                     return el;
  //                   }
  //                 } //else nothing
  //               }
  //             }
  //           }
  //         } else {
  //           //if date similar, return item
  //           let elStartDate = Date.parse(
  //             String(el.StartDate.slice(0, -2) + ' ' + el.StartDate.slice(-2))
  //           );
  //           let elEndDate = Date.parse(
  //             String(el.EndDate.slice(0, -2) + ' ' + el.EndDate.slice(-2))
  //           );
  //           if (
  //             Date.parse(String(date)) >= elStartDate &&
  //             Date.parse(String(date)) <= elEndDate
  //           ) {
  //             return el;
  //           }
  //         }
  //       });

  //       resolve(applicableData);
  //     };
  //     DatabaseHandler.executeSqlStatement(
  //       getPromoString,
  //       [],
  //       setItems,
  //       errorCallback
  //     );
  //   });
  // }
