import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IsKioskService } from 'src/app/services/is-kiosk.service';

import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-item-ask-name-popup',
  templateUrl: './item-ask-name-popup.component.html',
  styleUrls: ['./item-ask-name-popup.component.css'],
})
export class ItemAskNamePopupComponent implements OnInit, OnDestroy {
 
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
    if(this.KeyboardService._KeyboardOpen){
      this.KeyboardService.KeyboardStatus = false;
    }
  }
 
  modalReference: any;

  languageSub!: Subscription;
 
  customerNameForthisItem: string | null = '';
 
  whoIsThisForText: string = '';

  customerNameText: string = '';

  addToOrderText: string = '';

  nameRequiredText: string = '';

  positiveColor: string | null = '';
  
  positiveColorText: string | null = '';
  
  nameform!: FormGroup;

  submitted = false;

  isKiosk = this.isKioskService.isKiosk();

  constructor(
    public modalService: NgbModal,
    private formBuilder: FormBuilder,
    private KeyboardService: KeyboardService,
    private readonly isKioskService: IsKioskService,
    private language: LanguageService
  ) {}

  
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.nameform = this.formBuilder.group({
      name: ['', Validators.required],
    });

    this.positiveColor = GeneralSetting.getPositiveColor();
    this.positiveColorText = GeneralSetting.getPositiveColorText();
    this.customerNameForthisItem = '';
    this.KeyboardPopup();
  }
  // add click event on first input field @jay
  KeyboardPopup(){
    let cusnameAddEle: HTMLElement = document.getElementById('cusnameAdd') as HTMLElement;
    cusnameAddEle.click();
  }

  loadText() {
    this.customerNameText = this.language.getTextElement('txt_customer_name');
    this.whoIsThisForText = this.language.getTextElement( 'txt_who_is_this_item_for' );
    this.addToOrderText = this.language.getTextElement('lbl_add_to_order');
    this.nameRequiredText =this.language.getTextElement('name_is_required')
  }

  get f() {
    return this.nameform.controls;
  }

  public static open(modalService: NgbModal) {
    let modalReference = modalService.open(ItemAskNamePopupComponent, {
      // backdrop: 'static',
      centered: true,
      size: 'lg',
    });

    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }

  open() {
    let modalReference = this.modalService.open(ItemAskNamePopupComponent, {
      // backdrop: 'static',
      centered: true,
      size: 'lg',
    });

    modalReference.componentInstance.modalReference = modalReference;
    this.modalReference = modalReference;
  }


  onAddToOrder() {
    this.KeyboardService.KeyboardStatus = false;
    this.submitted = true;

    // stop here if form is invalid
    if (this.nameform.invalid) {
      return;
    }

    this.modalReference.close(`${this.customerNameForthisItem}`);
  }

  keyPressNumbers(event: any) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z_ ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  
}
