import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Snackbar } from 'src/app/services/common';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { UpdateMenuModelService } from '../update-menu/update-menu-model.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { DataService } from 'src/app/services/data.service';
import { LoaderService } from 'src/app/services/loader.service';
import { timeout } from 'rxjs/operators';
import { LoginService } from 'src/app/services/login.service';
import { CartService } from 'src/app/services/cart.service';
import { SaveSettingsService } from 'src/app/services/save-settings.service';

@Component({
  selector: 'app-retry-confirmation-dialog',
  templateUrl: './retry-confirmation-dialog.component.html',
  styleUrls: ['./retry-confirmation-dialog.component.css']
})
export class RetryConfirmationDialogComponent implements OnInit, OnDestroy {
  confirmationText: string = ''
  cancelText: string = ''
  yesText: string = ''
  confirmLogoutText: string = ''
  wipeDataText: string = ''
  languageSub!: Subscription
  closeRetryConfirmation!: Subscription
  ref: any = null
  constructor(private language: LanguageService, private loginService: LoginService,
    private logger: LogService, private loader: LoaderService, private dataService: DataService,
    private cart: CartService, private saveSettingsService: SaveSettingsService) {

  }
  ngOnDestroy(): void {
    GeneralSetting.setRetryDilogIsOpen('False');
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
    if (this.closeRetryConfirmation) {
      this.closeRetryConfirmation.unsubscribe();
    }
    this.ref = null;
  }


  ngOnInit(): void {

    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });

    // let that = this;
    this.closeRetryConfirmation = this.cart.closeRetryConfirmationDialog.subscribe((isCloseed) => {
      if (isCloseed) {
        this.closeDialog();
      }
    })
  }
  loadText() {
    this.confirmationText = this.language.getTextElement('retry_data_title')
    this.yesText = this.language.getTextElement('sync_button')
    this.confirmLogoutText = this.language.getTextElement('retry_message')
  }
  closeDialog() {
    GeneralSetting.setRetryDilogIsOpen('False')
    if (this.ref) this.ref.close();
  }

  oneTimeCall: boolean = true;
  confirmed() {
    if (this.oneTimeCall) {
      try {
        this.oneTimeCall = false;
        this.saveSettingsService.stillRunning = true;
        this.loader.openLoader();
        this.dataService
          .IsOnlineCheckSK()
          // .pipe(timeout(50000))
          .toPromise()
          .then(
            async (data) => {
              this.loader.closeLoader()

              var res = await this.loginService.doRefresh()
              this.saveSettingsService.stillRunning = false;
              if (!res) {
                //close All models 
                //close All models 
                //close All models 
                this.oneTimeCall = true;
                this.logger.sendLogToServer(new loggingData(
                  'Retry Sync Data',
                  'Retry Data Api call failed',
                  'Retry Sync Data',
                  "",
                  true
                ));
                Snackbar.show(this.language.getTextElement('network_issue'), 3000);
              } else {
                this.closeDialog()
              }
            },
            (error) => {
              this.oneTimeCall = true;
              this.saveSettingsService.stillRunning = false;
              this.loader.closeLoader()
              let err = this.language.getTextElement('device_offline_error')
              this.loader.closeLoader()
              Snackbar.show(
                err,
                3000
              );
            }
          )
      }
      catch (err) {
        this.oneTimeCall = true;
        this.saveSettingsService.stillRunning = false;
      }
    }
  }


  static open(modalService: NgbModal) {
    let getRetryDilogIsOpen = GeneralSetting.getRetryDilogIsOpen();
    if (!getRetryDilogIsOpen) {
      GeneralSetting.setRetryDilogIsOpen('True')
      var modalReference = modalService.open(RetryConfirmationDialogComponent, {
        size: 'lg',
        centered: true,
        modalDialogClass: 'customRetry',
        backdrop: 'static',
        keyboard: false
      });
      modalReference.componentInstance.ref = modalReference
      return modalReference
    } else {
      return
    }
  }
}