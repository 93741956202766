import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-olototal-loader',
  templateUrl: './olototal-loader.component.html',
  styleUrls: ['./olototal-loader.component.css'],
})
export class OLOTotalLoaderComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }


  modalReference: any;

  languageSub!: Subscription;

  isOrderUp: boolean = false;

  isRewardCheck: boolean = false;

  isLevelUpPayment: boolean = false

  headerText = ''

  calculatingTotalText: string = ''

  taxAndTotalText: string = ''

  availableRewardText: string = ''
  processingPaymentText: string = ''


  constructor(private readonly modalService: NgbModal,private language:LanguageService) {}


  ngOnInit(): void {
    this.getHeaderText();
    this.languageSub=this.language.localeCommunicator.subscribe((val)=>{
      this.loadText()
    })
  }

  loadText() {
    this.calculatingTotalText = this.language.getTextElement('calculating_total')
    this.taxAndTotalText = this.language.getTextElement('please_be_patient_for_a_moment_while_we_calculate_your_order_for_taxes_and_total')
    this.availableRewardText = this.language.getTextElement('please_be_patient_for_a_moment_while_we_pull_up_available_rewards')
    this.processingPaymentText = this.language.getTextElement('please_wait_processing_payment')
  }

  /**
  *
  * @param {boolean-defaults False} isOrderUp displays loader without order calculation text if true
  * @param {boolean-defaults False} isRewardCheck displays loader with OLOPunchh reward text if true
  */
  public static open(modalService: NgbModal, isOrderUp: boolean = false, isRewardCheck: boolean = false, isLevelUpPayment: boolean = false) {
    let modalReference = modalService.open(OLOTotalLoaderComponent, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });
    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.isOrderUp = isOrderUp;
    modalReference.componentInstance.isRewardCheck = isRewardCheck;
    modalReference.componentInstance.isLevelUpPayment = isLevelUpPayment;
    return modalReference;
  }

  /**
   * 
   * @param {boolean-defaults False} isOrderUp displays loader without order calculation text if true
   * @param {boolean-defaults False} isRewardCheck displays loader with OLOPunchh reward text if true
   */
  open(isOrderUp: boolean = false,isRewardCheck: boolean = false,isLevelUpPayment: boolean = false ) {
    const modalReference = this.modalService.open(OLOTotalLoaderComponent, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });
    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.isOrderUp = isOrderUp;
    modalReference.componentInstance.isRewardCheck = isRewardCheck;
    modalReference.componentInstance.isLevelUpPayment = isLevelUpPayment;
    this.modalReference = modalReference;
  }

  getHeaderText() { 
    if (!this.isOrderUp) {
     this.headerText =  this.language.getTextElement('lbl_checkout')

    } else { 
      // this.headerText = 'Please wait while your order is being placed'
      this.headerText = this.language.getTextElement('txt_please_wait_order_is_being_placed');
    }
    
  }
  clear() {
    this.modalReference.close();
  }
}
