import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-usconnect-success',
  templateUrl: './usconnect-success.component.html',
  styleUrls: ['./usconnect-success.component.css'],
})
export class UsconnectSuccessComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  modalReference: any;

  checkoutText: string = '';

  successText: string = '';

  paymentCompleteText: string = '';

  languageSub!: Subscription;

  constructor(private language: LanguageService) {}

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  loadText() {
    this.checkoutText = this.language.getTextElement('txt_yes');
    this.successText = this.language.getTextElement('text_success');
    this.paymentCompleteText = this.language.getTextElement('txt_payment_complete');
  }

  public static open(modelService: NgbModal) {
    let modalReference = modelService.open(UsconnectSuccessComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }

  close() {
    this.modalReference.close();
  }

}
