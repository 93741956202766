import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from 'src/app/services/cart.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';
import { PaymentTypesService } from 'src/app/services/payment-types.service';
import { PaymentInsertComponent } from './payment-insert.component';

@Injectable({
  providedIn: 'root',
})
export class PaymentInsertService {
  constructor(
    private readonly modalService: NgbModal,
    private readonly language: LanguageService,
    private readonly cartService: CartService,
    private readonly paymentTypeService: PaymentTypesService
  ) {}

  private paymentInsertModel?: PaymentInsertComponent;

  public openPaymentInsetModel(
    isVerifone: boolean,
    isRefund: boolean = false,
    isClover?: boolean,
    payWithCardText: string = ''
  ) {
    if (this.paymentInsertModel) {
      try {
        this.closePaymentInsertModel();
      } catch (e) {}
    }

    this.paymentInsertModel = PaymentInsertComponent.open(
      this.modalService,
      this.language,
      isVerifone,
      isRefund,
      this.cartService.total,
      this.cartService.subtotal,
      this.cartService.tax,
      this.cartService.tip,
      this.cartService.shippingFee,
      this.cartService.discount,
      this.cartService.reward,
      this.paymentTypeService.payments.length == 1 &&
        GeneralSetting.getShowPaymentScreen() == 'True',
      isClover,
      payWithCardText
    ).componentInstance;
  }

  public openPaymentInsertModal(
    isVerifone: boolean,
    calculationData: {
      total: number;
      subtotal: number;
      tax: number;
      tip: number;
      shippingFee: number;
      discount: number;
      reward: number;
    },
    showPayment: boolean,
    isClover?: boolean,
    isRefund: boolean = false
  ) {
    if (this.paymentInsertModel) {
      try {
        this.closePaymentInsertModel();
      } catch (e) {}
    }

    this.paymentInsertModel = PaymentInsertComponent.open(
      this.modalService,
      this.language,
      isVerifone,
      isRefund,
      calculationData.total,
      calculationData.subtotal,
      calculationData.tax,
      calculationData.tip,
      calculationData.shippingFee,
      calculationData.discount,
      calculationData.reward,
      showPayment,
      isClover
    ).componentInstance;
  }

  public closePaymentInsertModel() {
    if (this.paymentInsertModel) {
      this.paymentInsertModel.close();
    }
  }

  public setHeaderText(headerText:string) {
    try {
      if (!this.paymentInsertModel) {
        return;
      }

      if(headerText == ""){
        headerText = this.language.getTextElement('pay_with_card')
      }

      this.paymentInsertModel.payWithCardText = headerText;
    } catch (e) {

    }
  }
}
