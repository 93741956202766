import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import { ItemV2 as Item, ModifierV2 as Modifier, ComboGroupV2 as ComboGroup, ModifierIngredientV2 } from 'src/app/models/item';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ItemService {
  constructor(private db: DatabaseService) {

  }

  setItemTagsSub:Subject<boolean> = new Subject<boolean>();

  async getAllComboGroupItems(comboGroup: ComboGroup[],itemId:string) {

    for (var i = 0; i < comboGroup.length; i++) {

      let comboGroupItems = await this.db.getComboItemsFromSql(comboGroup[i].ComboGroupID, itemId);

      let isHidden = comboGroupItems.find(x => x.IsInvisibleItem.toLowerCase() == "true");
      if (isHidden) {
        comboGroup[i].isHidden = true;
      }
      else {
        comboGroup[i].isHidden = false;
        comboGroup[i].isSelected = true;
      }
      comboGroup[i].Items = comboGroupItems;
      
      if(comboGroupItems && comboGroupItems != undefined){
        for(let j=0; j< comboGroupItems.length ; j++){
          if(comboGroupItems[j].isSelected){
            comboGroup[i].IsSelected = true;  
            break;  
          }
        }
      }
    }

    return JSON.parse(JSON.stringify(comboGroup));
  }

  async getAllModifierIngredients(allModifier: Modifier[]) {
    let parentModifiers = allModifier.filter(
      (x) => x.ParentID == '' || x.ParentID == '0'
    );

    for (let parentModifierIndex = 0; parentModifierIndex < parentModifiers.length; parentModifierIndex++) {
      const modifier = parentModifiers[parentModifierIndex];
      modifier.IsForced = (modifier.IsModifier86 == 'False' && 'False') || modifier.IsForced;
      modifier.isValid = (modifier.IsModifier86 == 'False' && true) || modifier.isValid;

      const ingredients = await this.db.getIngredientsByModIDFromSql(modifier.ModifierID);
      modifier.Ingredients = ingredients;

      if(ingredients && ingredients != undefined){
        for(let i=0; i<ingredients.length ; i++){
          if(ingredients[i].IsSelected){
            modifier.IsSelected = true;  
            break;  
          }
        }
      }

      let nestedModifiers = await this.db.getChildModifierByItemAndModifierID(modifier.ItemID, modifier.ModifierID);

      modifier.Ingredients.push(...nestedModifiers);

      modifier.Ingredients = modifier.Ingredients.sort((a: any, b: any) => Number(a.DisplayOrder) > Number(b.DisplayOrder) ? 1 : -1);
    }

    parentModifiers = parentModifiers.filter(x => x.Ingredients && x.Ingredients.length > 0);

    return parentModifiers;
  }


}
