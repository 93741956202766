import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IUpdateModalService } from 'src/app/models/IUpdateModelService';
import { UpdateMenuComponent } from './update-menu.component';

@Injectable({
  providedIn: 'root'
})
export class UpdateMenuModelService implements IUpdateModalService {

  constructor(private modalService:NgbModal) 
  { }

  private modalRef: UpdateMenuComponent | null = null;

  public open() {
    this.close();

    this.modalRef = UpdateMenuComponent.open(this.modalService).componentInstance;
  }

  public close(){
    if(this.modalRef && this.modalRef != null){
      this.modalRef.clear();
    }
  }

  public setMessage(message:string) {
    if(this.modalRef && this.modalRef != null){
      this.modalRef.setCurrentStateMessage(message);
    }
  }

  public setSecondMessage(message:string){
    if(this.modalRef && this.modalRef != null){
      this.modalRef.setIndexText(message);
    }
  }

}
