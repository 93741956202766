<div id="upsell-popup">
  <div class="upsell-modal">
    <h5 class="modal-title text-center"></h5>
    <div class="custom-message">
      {{
      (upSellItems.length > 0
      ? upSellItems[0].CustomMessage == ""
      ? addOnItemText
      : upSellItems[0].CustomMessage
      : addOnItemText
      ) | uppercase
      }}
    </div>
    <div *ngIf="upSellItems.length > 1; else single">
      <div class="scroll-item-box">
        <div class="row scroll-item">
          <div class="items" *ngFor="let item of upSellItems">
            <div *ngIf="item.ItemID">
              <div class="item-combo text-center">
                <div class="upsell-Sellection">
                  <input type="checkbox" id="{{ 'up_' +'' +item.ItemID }}" class="d-none"
                    (click)="onItemSelect($event, item)" [checked]="checkItemChecked(item.ItemID)" />
                  <label for="{{ item.ItemID }}" class="item-card multiple" (click)="onItemSelect($event, item)">
                    <a href="javascript:void(0)" (click)="onItemRemove($event,item)" class="remove-UpSell-item">
                      <i class="fas fa-times"></i>
                    </a>
                    <h2 class="item-name">{{ item.Name }}</h2>
                    <img
                      src="{{ item.ImageUrl && item.ImageUrl != '' ? baseImageUrl + item.ImageUrl : placeholderImage !='' ? baseImageUrl+placeholderImage : 'assets/images/Image_21.png' }}"
                      onerror="this.onerror=null; this.src='assets/images/Image_21.png';" alt=""
                      class="img-fluid aon-item-img"
                      [ngStyle]="{'border-radius': bordersTypeItem ? imageRadius : '0%'}" />

                    <!-- <span class="item-price" [ngStyle]="{ color: priceColor }" *ngIf="!isRemovePricingAndPayments">{{ item.Price | CurrencyPipe }} (-{{item.DiscountAmount | CurrencyPipe}})</span> -->
                    <div class="item-container" *ngIf="!isRemovePricingAndPayments">
                      <div class="item-price" [ngStyle]="{ color: priceColor }">
                        <div class="price-discount-container">
                          <p [style.textDecoration]="item.DiscountAmount > 0 ? 'line-through' : ''"
                            [style.color]="textColor" class="price-text">
                            {{ getItemPrice(item) }}
                          </p>
                          <p class="item-discount" *ngIf="item.DiscountAmount > 0">
                            &nbsp;&nbsp;{{ getDiscountedPrice(item)| numberConvert | number: "1.2-2" | currency }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="row" *ngIf="item && item.AllowQuantitySelection == 'True'; else spacer">
                      <div *ngIf="checkItemChecked(item.ItemID)" class="col-md-12 col-12 p-0">
                        <div class="number-counter">
                          <div class="number-content">
                            <span id="{{ item.ItemID }}" class="input-number-decrement JS_ItemDecrement" data-itemId="1"
                              (click)="decreaseQuantity($event,item)">
                              <img src="../../../../assets/images/minus.svg"
                                onerror="this.onerror=null; this.src='assets/images/Image_21.png';" />



                              <!-- <i class="fa fa-minus" ></i>-->
                            </span>
                            <input id="{{ item.ItemID }}" class="input-number-2 JS_ItemQuantity" disabled="disabled"
                              data-itemId="1" id="1" type="text" value="{{ item.Quantity }}" min="0" max="" />
                            <span id="{{ item.ItemID }}" class="input-number-increment JS_ItemIncrement" data-itemId="1"
                              (click)="increaseQuantity($event,item)">
                              <!-- <i class="fa fa-plus"></i>-->
                              <img src="../../../../assets/images/plus.svg"
                                onerror="this.onerror=null; this.src='assets/images/Image_21.png';" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="!checkItemChecked(item.ItemID)" class="col-md-12 col-12 p-0">
                        <div class="number-counter">
                          <div class="number-content">

                            <span id="{{ item.ItemID }}"
                              class="input-number-increment JS_ItemIncrement green-background custom-width"
                              [style.background-color]="positiveColor" [style.border]="positiveColor"
                              [style.color]="positiveColorText" data-itemId="1" (click)="increaseQuantity($event,item)">
                              Add &nbsp;&nbsp;
                              <!-- <i class="fa fa-plus"></i>-->
                              <img src="../../../../assets/images/plus-white.svg"
                                onerror="this.onerror=null; this.src='assets/images/Image_21.png';"
                                class="white-tint-img" />
                            </span>

                          </div>
                        </div>

                      </div>
                    </div>
                    <ng-template #spacer>
                      <div *ngIf="checkItemChecked(item.ItemID)" class="col-md-12 col-12 p-0">
                        <div class="number-counter">
                          <div class="number-content">
                            <span id="{{ item.ItemID }}" class="input-number-decrement JS_ItemDecrement" data-itemId="1"
                              (click)="decreaseQuantity($event,item)">
                              <img src="../../../../assets/images/minus.svg"
                                onerror="this.onerror=null; this.src='assets/images/Image_21.png';" />



                              <!-- <i class="fa fa-minus" ></i>-->
                            </span>
                            <input id="{{ item.ItemID }}" class="input-number-2 JS_ItemQuantity" disabled="disabled"
                              data-itemId="1" id="1" type="text" value="{{ item.Quantity }}" min="0" max="" />
                            <span id="{{ item.ItemID }}" class="input-number-increment JS_ItemIncrement" data-itemId="1"
                              (click)="increaseQuantity($event,item)">
                              <!-- <i class="fa fa-plus"></i>-->
                              <img src="../../../../assets/images/plus.svg"
                                onerror="this.onerror=null; this.src='assets/images/Image_21.png';" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="!checkItemChecked(item.ItemID)" class="col-md-12 col-12 p-0">
                        <div class="number-counter">
                          <div class="number-content">

                            <span id="{{ item.ItemID }}"
                              class="input-number-increment JS_ItemIncrement green-background custom-width"
                              [style.background-color]="positiveColor" [style.border]="positiveColor"
                              [style.color]="positiveColorText" data-itemId="1" (click)="increaseQuantity($event,item)">
                              Add &nbsp;&nbsp;
                              <!-- <i class="fa fa-plus"></i>-->
                              <img src="../../../../assets/images/plus-white.svg"
                                onerror="this.onerror=null; this.src='assets/images/Image_21.png';"
                                class="white-tint-img" />
                            </span>

                          </div>
                        </div>

                      </div>
                    </ng-template>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #single>
      <div class="items" *ngFor="let item of upSellItems">
        <div *ngIf="item.ItemID">
          <div class="item-combo text-center">
            <div class="upsell-Sellection">
              <!-- <input
                type="checkbox"
                id="{{ item.ItemID }}"
                class="d-none"

                [checked]="checkItemChecked(item.ItemID)"
              /> -->
              <label for="{{ item.ItemID }}" class="item-card">
                <a href="javascript:void(0)" class="remove-UpSell-item">
                  <i class="fas fa-times"></i>
                </a>

                <img src="{{
                    item.ImageUrl && item.ImageUrl != ''
                      ? baseImageUrl + item.ImageUrl
                      : placeholderImage !='' 
                        ? baseImageUrl+placeholderImage 
                        : 'assets/images/Image_21.png'
                  }}" onerror="this.onerror=null; this.src='assets/images/Image_21.png';" alt=""
                  class="img-fluid upsell-img" />
                <h2 class="item-name">{{ item.Name }}</h2>
                <!-- <span class="item-price"
                  [ngStyle]="{ color: priceColor,'border-radius': bordersTypeItem ? imageRadius : '0%'}"
                  *ngIf="!isRemovePricingAndPayments">{{ item.Price | CurrencyPipe }} Discount
                  :{{item.DiscountAmount}}</span> -->

                  <div class="item-container" *ngIf="!isRemovePricingAndPayments">
                    <div class="item-price" [ngStyle]="{ color: priceColor }">
                      <div class="price-discount-container">
                        <p [style.textDecoration]="item.DiscountAmount > 0 ? 'line-through' : ''"
                          [style.color]="textColor" class="price-text">
                          {{ getItemPrice(item) }}
                        </p>
                        <p class="item-discount" *ngIf="item.DiscountAmount > 0">
                          &nbsp;&nbsp;{{ getDiscountedPrice(item)| numberConvert | number: "1.2-2" | currency }}
                        </p>
                      </div>
                    </div>
                  </div>
                <div class="row" *ngIf="item && item.AllowQuantitySelection == 'True'">

                  <div class="col-md-12 col-12 p-0">
                    <div class="number-counter">
                      <div class="number-content">
                        <span id="{{ item.ItemID }}" class="input-number-decrement JS_ItemDecrement" data-itemId="1"
                          (click)="decreaseQuantity($event,item)">
                          <img src="../../../../assets/images/minus.svg"
                            onerror="this.onerror=null; this.src='assets/images/Image_21.png';" />


                          <!-- <i class="fa fa-minus" ></i>-->
                        </span>
                        <input id="{{ item.ItemID }}" class="input-number-2 JS_ItemQuantity" disabled="disabled"
                          data-itemId="1" type="text" value="{{ item.Quantity }}" min="0" max="" />
                        <span id="{{ item.ItemID }}" class="input-number-increment JS_ItemIncrement" data-itemId="1"
                          (click)="increaseQuantity($event,item)">
                          <!-- <i class="fa fa-plus"></i>-->
                          <img src="../../../../assets/images/plus.svg"
                            onerror="this.onerror=null; this.src='assets/images/Image_21.png';" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <div class="col-12 col-md-12 popup-footer text-center">
      <a matRipple href="javascript:void(0)" class="green-btn text-uppercase w-100-p" (click)="addUpSellItemToOrderV2()"
        [style.background-color]="positiveColor" [style.border]="positiveColor"
        [style.color]="positiveColorText">{{addToOrderText}}</a>
      <!--<br />-->
      <div class="clearfix"></div>
      <a matRipple href="javascript:void(0)" class="plain-btn w-100-p" (click)="dismiss()"
        [style.background-color]="negativeColor" [style.border]="negativeColor"
        [style.color]="negativeColorText">{{noThanksText}}</a>
    </div>
  </div>
</div>