
    <div id="confirmation" >
        <!-- <div class="modal-dialog modal-dialog-centered" role="document"> -->
            <!-- <div class="modal-content"> -->
            <div class="modal-header">
                <h3 class="modal-title text-center" id="confirmationlabel">{{confirmationText}}</h3>
            </div>
            <div class="modal-body">
                <div class="row">
                <div class="col-md-3 col-3"><i class="fa fa-exclamation-triangle warn"></i></div>
                <div class="col-md-9 col-9">{{confirmLogoutText}}<br/>
                {{wipeDataText}}
                </div>
                </div>
            </div>
            <div class="modal-footer w-100-p">
                <button type="button" class="btn btn-secondary float-left" data-dismiss="modal" (click)="canceled()">{{cancelText}}</button>
                <button type="button" class="btn btn-primary float-right red-bg" (click)="confirmed()">{{yesText}}</button>
            </div>
            <!-- </div> -->
        <!-- </div> -->
    </div>

    <app-manager-pin-dialog [hidden]='true'></app-manager-pin-dialog>