import { Injectable, OnInit } from '@angular/core';
import { EatOptionsService } from '../services/eat-options.service';
import { eatOption } from '../models/eat-option';
import { content } from '../models/images';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { DatabaseHandler } from 'src/app/DatabaseHandler';
import { LogService } from 'src/app/services/log.service';
@Injectable({
  providedIn: 'root',
})
export class ImageService {
  singleVideo: string = 'assets/videos/Golden Chick.mp4';
  screenArr: content[] = [];
  options: eatOption[] = [];
  showSlide: boolean = true;
  screentext: string | null | undefined = '';
  activeID: number = 0;
  optionsService: EatOptionsService;
  constructor(
    EatOptionsService: EatOptionsService,
    private logger: LogService,
    private isKioskService: IsKioskService
  ) {
    this.optionsService = EatOptionsService;
    this.getOptions();
  }

  getOptions() {
    return new Promise<eatOption[]>((resolve: any, reject: any) => {
      this.optionsService.getEatOptions().then((options) => {
        this.options = options;
        resolve(this.options);
      });
    });
  }

  async getScreen() {
    // console.log(this.screenArr);
    // if (this.screenArr.length === 0) {
      this.screenArr = await this.getImgsFromSql();
      // console.log('screen array', this.screenArr);
      return this.screenArr;
    // } else {
    //   return this.screenArr;
    // }
  }

  getImgsFromSql() {
    return new Promise<content[]>((resolve: any, reject: any) => {
      //query
      var sqltext = `
    SELECT
      ScreenSaverID,
      Name,
      AnimationDirectionID,
      ImagePath,
      SliderText,
      DisplayOrder,
      VideoPath
    FROM
      ScreenSaverMasters
    WHERE
      IsActive = 'True'
    `;

      //callback
      const getimgarr = (tx: string, result: any) => {
        var data = Array.from(result.rows);
        this.screenArr = [];
        // console.log("the data is", data);
        data.forEach((element: any) => {
          if (element.VideoPath == '') {
            this.screenArr.push(element);
            element.video = false;
          } else {
            if (this.isKioskService.isKiosk()) {
              element.VideoPath =
                // 'http://localhost:4200/static/' +
                element.VideoPath.replace('https://', '');
            }
            this.screenArr.push(element);
            element.video = true;
          }
        });

        if (this.screenArr.length <= 0)
          resolve([]);

        //if only one video
        if (this.screenArr.length == 1 && this.screenArr[0].video) {
          if (this.screenArr[0].VideoPath) {
            this.singleVideo = this.screenArr[0].VideoPath;
            this.showSlide = false;
          }
        }

        //sort array by order
        this.screenArr.sort((a, b) => {
          return a.DisplayOrder - b.DisplayOrder;
        });
        if (
          this.screenArr[0]&&
          this.screenArr[0].SliderText&&
          this.screenArr[0].SliderText.trim() &&
          this.screenArr[0].SliderText.trim() != '' &&
          this.screenArr[0].SliderText.trim() != null &&
          this.screenArr[0].SliderText.trim() != undefined
        ) {
          this.screentext = this.screenArr[0].SliderText.trim();
        }

        this.activeID = this.screenArr[0]?.ScreenSaverID;
        resolve(this.screenArr);
      };

      //error
      const errorCallback = (statement: any, error: any) => {
        //if no such table
        this.showSlide = false;

        console.log('Image Service , Error getImgsFromSql :- ', error);

        reject();
      };

      DatabaseHandler.executeSqlStatement(
        sqltext,
        [],
        getimgarr,
        errorCallback
      );
    });
  }
}
