<div id="item-full-description">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-body item-full-description-modal">
    <div class="content">
      <p >{{description}}</p>
      <!-- <div class="modal-footer">
        <div class="col-12 col-md-12 popup-footer text-center">
          <button matRipple class="green-btn text-uppercase" type="button" name="submits" (click)="close()"
            [style.background-color]="positiveColor" [style.color]='positiveColorText'>{{closeText}}</button>
        </div>
      </div> -->
    </div>
  </div>
</div>