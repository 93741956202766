import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { LanguageService } from 'src/app/services/language.service';
// import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-terms-conditions-qr',
  templateUrl: './terms-conditions-qr.component.html',
  styleUrls: ['./terms-conditions-qr.component.css']
})
export class TermsConditionsQrComponent implements OnInit {
  ref: any;


  title = 'angular10qrcodegeneration';

  // We can have Canvas/Img/Url as elementType
  elementType = NgxQrcodeElementTypes.URL;

  // We can have High/Low/Medium/Quartile
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  // Need to specify the valid website address
  value = '';
  termsAndConditionsText: string = '';
  scanBarcodeText: string = '';
  backBtnText: string='';


  constructor(private language: LanguageService) { }

  ngOnInit(): void {
    this.termsAndConditionsText = this.language.getTextElement('lbl_terms_and_conditions');
    this.scanBarcodeText = this.language.getTextElement('lbl_scan_barcode');
    this.backBtnText = this.language.getTextElement('back');
  }
  /**
   * @author Om kanada
   * @param url // pass url to generate qr code
   * @description This method is used to open the modal and pass the url to generate qr code
   * @returns void
   */
  public static open(url: string,modalService:NgbModal) {
    var ref = modalService.open(TermsConditionsQrComponent, { backdrop: 'static', centered: true, size: 'lg' });
    ref.componentInstance.ref = ref;
    ref.componentInstance.value = url;
    return ref;
  }

  dismiss() {
    this.ref.close();
  }

}
