<div class="scan-selection-bar">
  <div class="row">
    <div class="scan-select-image">
      <div class="bold-text">
        <div class="col-10 col-md-10 centered-text" style="width: 115px;">
          <strong style="font-size: 18px;"
            >{{scanText}}</strong
          >
        </div>
        <div class="col-10 col-md-10">
          <img src="../../../assets/images/scan-item.png" class="scanimg" />
        </div>
      </div>
    </div>

    {{ orText | uppercase }}

    <div class="scan-select-image">
      <div class="bold-text">
        <div class="col-10 col-md-10 centered-text" style="width: 115px;">
          <strong style="font-size: 18px;">{{selectText}}</strong>
        </div>
        <div class="col-10 col-md-10">
          <img
            src="../../../assets/images/item-selection.png"
            class="scanimg"
          />
        </div>
      </div>
    </div>

    <div class="scan-select-text">
      <div class="bold-text">
        <div class="col-10 col-md-10">
          <p style="margin-bottom: 0;" [innerHtml]="scanItemMsg"></p>
        </div>

        <div class="col-2 help col-md-2">
          <button
            id="scanitem"
            type="button"
            matRipple
            data-toggle="scanninganitemModal"
            data-target="#scanning-item"
            (click)="openscanitem()"
          >
            <span class="material-icons material-icons-outlined">
              help_outline
            </span>
          </button>
        </div>
      </div>
      <div class="light" *ngIf="scanItemInfo">
        <div class="col-1 col-md-1">
          <img src="{{ scanItemInfo }}"/>
        </div>
        <div class="col-8 col-md-8" style="line-height: 135%;">
          <p style="margin-bottom: 0;" [innerHtml]="scanItemFooter"></p>
        </div>
      </div>
    </div>

    <div class="scan-select-image">
      <div class="bold-text">
        <div class="col-10 col-md-10 centered-text">
          <strong
            style="text-align: center; font-size: 12px"
            (click)="opensearchscannable()"
            >{{tapToScan}}</strong
          >
        </div>
        <div class="col-10 col-md-10">
          <img
            src="../../assets/images/barcode-identification.png"
            class="barscan"
            (click)="opensearchscannable()"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<app-scanning-item [hidden]="true"></app-scanning-item>
