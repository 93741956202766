<div class="col-md-12 col-12 header-report">
  <h2><span>{{itemSalesReportText}}</span></h2>
  <h4>
    <input
    style="width: 12rem; font-weight: 700; margin-left: 1rem;margin-right: 1rem"
    matInput
      readonly
      [matDatepicker]="datePicker"
      [ngModel]="dt"
      (ngModelChange)="setItemwiseDate($event)"
      id="datePicker"
    />
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker touchUi #datePicker></mat-datepicker>
  </h4>
</div>
<!--<p>{{dt3 | date:'MM/dd/yyyy'}}</p>-->
<br /><br />
<div class="row">
  <table
    class="
      table
      orderHistoryTable
      table-borderless
      border-white border-radius-10
    "
  >
    <thead>
      <tr>
        <th>{{itemNameText}}</th>
        <th *ngIf="!isRemovePricingAndPayments">{{eachText}}</th>
        <th>{{quantityText}}</th>
        <th *ngIf="!isRemovePricingAndPayments">{{totalText}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let itemwise of itemwiseOrders">
        <td>{{ itemwise.ItemName }}</td>
        <td *ngIf="!isRemovePricingAndPayments">{{ itemwise.ItemPrice | number: "1.2-2" | CurrencyPipe }}</td>
        <td>{{ itemwise.Quantity }}</td>
        <td *ngIf="!isRemovePricingAndPayments">
          {{
            computePrice(itemwise.ItemPrice, itemwise.Quantity)
              | number: "1.2-2"
              | CurrencyPipe
          }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<button (click)="printItemwise()">{{printText}}</button>
<br />
<br />
