<div id="scanning-item">
  <div class="modal-body scanning-item-modal">
    <h2>{{itemSelectionHelperText}}</h2>
    <h1 class="text-center">{{scanDialogTitle}}</h1>
    <div class="content">
      <h4 class="text-center" [innerHtml] ="scanDialogDesc"></h4>
      <div class="img-bar">
        <img src="{{scanDialogGifLink}}" class="scanimg" />
      </div>
        <div class="light-text">
          <div class="col-1 col-md-1">
            <img src="../../../../assets/images/info.png"/>
          </div>
          <div class="col-10 col-md-10">{{itemsWithoutBarcodeText}}</div>
        </div>
        <div class="modal-footer">
          <div class="col-12 col-md-12 popup-footer text-center">
            <button matRipple class="green-btn text-uppercase" type="submit" name="submits" (click)="close()" [style.background-color]="positiveColor" [style.color]='positiveColorText'>{{closeText}}</button>
           <!-- <button matRipple class="green-btn text-uppercase" type="submit" name="submits"
              [style.background-color]="positiveColor" [style.color]='positiveColorText'>Continue</button>
           -->  </div>
        </div>
      </div>
    </div>
  </div>