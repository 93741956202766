import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from 'src/app/services/cart.service';
import { CloverService } from 'src/app/services/clover.service';
import { DataService } from 'src/app/services/data.service';
import { DatabaseService } from 'src/app/services/database.service';
import { DejavooService } from 'src/app/services/dejavoo.service';
import { EatOptionsService } from 'src/app/services/eat-options.service';
import { IntegrationService } from 'src/app/services/integration.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { KdsService } from 'src/app/services/kds.service';
import { LogService } from 'src/app/services/log.service';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { MenuItemService } from 'src/app/services/menu-item.service';
import { OLOService } from 'src/app/services/olo.service';
import { OracleService } from 'src/app/services/oracle.service';
import { ParBrinkService } from 'src/app/services/par-brink.service';
import { PaymentTypesService } from 'src/app/services/payment-types.service';
import { PaymentService } from 'src/app/services/payment.service';
import { ShippingService } from 'src/app/services/shipping.service';
import { TipService } from 'src/app/services/tip.service';
import { UpsellService } from 'src/app/services/upsell.service';
import { UsconnectService } from 'src/app/services/usconnect.service';
import { UserService } from 'src/app/services/user.service';
import { VerifoneService } from 'src/app/services/verifone.service';
import { SMSService } from 'src/app/services/sms.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { NcrIntegration } from 'src/app/services/NcrIntegration';
import { DatabaseHandler } from 'src/app/DatabaseHandler';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { CommonFunctions } from 'src/app/services/common';
import {
  OrderCustomMessage,
  vwOrderCustomMessage,
} from 'src/app/models/vwOrderCustomMessege';
import { OrderCustomMessagesComponent } from '../../shared/order-custom-messages/order-custom-messages.component';
import { UsconnectAccountFoundComponent } from '../dialogs/usconnect-account-found/usconnect-account-found.component';
import { OLOTotalLoaderComponent } from '../dialogs/olototal-loader/olototal-loader.component';
import { PaymentInsertComponent } from '../dialogs/payment-insert/payment-insert.component';
import { PaymentSuccessComponent } from '../dialogs/payment-success/payment-success.component';
import { LanguageService } from 'src/app/services/language.service';
import { VerifoneEvoService } from 'src/app/services/verifone-evo.service';
import { LoaderService } from 'src/app/services/loader.service';
import { PaymentErrorComponent } from '../dialogs/payment-error/payment-error.component';

@Injectable({
  providedIn: 'root',
})
export class OrderreviewService {
  constructor(
    public readonly SMSService: SMSService,
    public readonly router: Router,
    public readonly cartService: CartService,
    public readonly upsellService: UpsellService,
    public readonly paymentService: PaymentService,
    public readonly databaseService: DatabaseService,
    public readonly dataService: DataService,
    public readonly verifoneService: VerifoneService,
    public readonly loyaltyService: LoyaltyService,
    public readonly logger: LogService,
    public readonly olo: OLOService,
    public readonly parbrink: ParBrinkService,
    public readonly paymentTypeService: PaymentTypesService,
    public readonly optionsService: EatOptionsService,
    public readonly modalService: NgbModal,
    public readonly isKioskService: IsKioskService,
    public readonly shipping: ShippingService,
    public readonly usconnectService: UsconnectService,
    public readonly dejavooService: DejavooService,
    public readonly menuItemService: MenuItemService,
    public readonly tipService: TipService,
    public readonly userService: UserService,
    public readonly cloverService: CloverService,
    public readonly KeyboardService: KeyboardService,
    public readonly kdsService: KdsService,
    public readonly oracle: OracleService,
    public readonly integration: IntegrationService,
    public readonly ncrIntegration: NcrIntegration,
    public language: LanguageService,
    public readonly verifeonEvo: VerifoneEvoService,
    public loader: LoaderService
  ) {}

  usConnectScanComponent!: any;

  usConnectSuccessModel!: any;

  UsconnectAccountNotFoundModel!: any;

  UsconnectAccountFoundModel!: UsconnectAccountFoundComponent | null;

  OloTotalLoaderModel!: OLOTotalLoaderComponent | null;

  private paymentInsertModel!: PaymentInsertComponent | null;

  private paymentSuccessModal!: PaymentSuccessComponent | null;

  private paymentSuccessModalRef: any | null;

  public setDefaultAllUnneededProperties() {
    this.usConnectScanComponent = undefined;
    this.usConnectSuccessModel = undefined;
    this.UsconnectAccountNotFoundModel = undefined;
    this.UsconnectAccountFoundModel = null;
    this.OloTotalLoaderModel = null;
    this.paymentInsertModel = null;
    this.paymentSuccessModal = null;
    this.paymentSuccessModalRef = null;
  }

  private customeMessages: OrderCustomMessage[] = [];

  /*  1 Items is In cart
   *  2 Category is in cart
   *  3 Order type
   *  4 Always Show
   *
   *  Payment Type
   *  1. Before Payment
   *  2. After Payment
   * */

  getAllRelatedCustomeMessage() {
    //to check all category
    let allCategoryIds = CommonFunctions.getCommaSepratedStringWithList(
      this.cartService.getAllCartItemCategoryIds()
    );

    //to check all items
    let allItemIds = CommonFunctions.getCommaSepratedStringWithList(
      this.cartService.getAllCartItemIds()
    );

    // to check with order type
    let orderTypeId = GeneralSetting.getOrderTypeId();

    var sqlString: string = `
          select distinct
              cm.Name,
              cm.Title,
              cm.Message,
              cm.AdditionalText,
              cm.Image,
              cm.Type
          from CustomMessages as cm
          where cm.BranchID = '${GeneralSetting.getBranchId()}' and IsActive = 'True' and
          (   (cm.MessageTriggeres = '1' and cm.CMID in (select cim.CMID from CustomMessage_Item_Mappings as cim where cim.ItemID in (${allItemIds}) ))
              or (cm.MessageTriggeres = '2' and cm.CMID in (select ccm.CMID from CustomMessage_Category_Mappings as ccm where ccm.CategoryID in (${allCategoryIds}) ))
              or (cm.MessageTriggeres = '3' and cm.CMID in (select com.CMID from CustomMessage_OrderType_Mappings as com where com.OrderTypeID = '${orderTypeId}'))
              or (cm.MessageTriggeres = '4')
          )

          ORDER BY CAST(cm.DisplayPriority AS int)
      `;

    const logError = (statement: any, error: any) => {
      console.log('Custom Message Query', error);
      this.customeMessages = [] as OrderCustomMessage[];
    };

    const setItems = (transaction: String, results: any) => {
      let data = Array.from(results.rows);
      this.customeMessages = data as OrderCustomMessage[];
    };

    DatabaseHandler.executeSqlStatement(sqlString, [], setItems, logError);
  }

  getCustomMessages() {
    return this.customeMessages;
  }

  public showCustomMessageProcess(messageType: string) {
    return new Promise<boolean>(async (resolve: any) => {
      let customMessages = this.getCustomMessages().filter(
        (x) => x.Type == messageType
      );

      if (customMessages.length > 0) {
        for (let i = 0; i < customMessages.length; i++) {
          await this.openCustomMessagePopup(customMessages[i]);
        }
      }

      resolve(true);
    });
  }

  private async openCustomMessagePopup(customMessage: OrderCustomMessage) {
    return new Promise<boolean>(async (resolve: any) => {
      let vwCustomMessage = new vwOrderCustomMessage(
        customMessage.Title,
        customMessage.Image,
        customMessage.Message,
        customMessage.AdditionalText
      );

      let modalReference = this.modalService.open(
        OrderCustomMessagesComponent,
        {
          windowClass: 'oloErrorModel',
          modalDialogClass: 'oloErrorModel',
          backdrop: 'static',
          size: 'lg',
          centered: true,
        }
      );

      modalReference.componentInstance.modalReference = modalReference;
      modalReference.componentInstance.title = vwCustomMessage.title;
      modalReference.componentInstance.beforeImageText =
        vwCustomMessage.beforeImageText;
      modalReference.componentInstance.afterImageText =
        vwCustomMessage.afterImageText;
      modalReference.componentInstance.ImageUrl = vwCustomMessage.image;

      modalReference.componentInstance.modalReference.result.then(
        (result: any) => {
          resolve(true);
        }
      );
    });
  }

  public closeUsConnectScanComponent(event: any) {
    if (this.usConnectScanComponent) {
      this.usConnectScanComponent.componentInstance.close(event);
    }
  }

  public closeUsConnectSuccessModel() {
    if (this.usConnectSuccessModel) {
      this.usConnectSuccessModel.componentInstance.close();
    }
  }

  public closeUsconnectAccountNotFoundModel(event: any) {
    if (this.usConnectSuccessModel) {
      this.usConnectSuccessModel.componentInstance.close(event);
    }
  }

  public openPaymentInsertModal(
    isVerifone: boolean,
    isRefund: boolean = false,
    isClover?: boolean
  ) {
    if (this.paymentInsertModel) {
      this.closePaymentInsertModel();
    }

    this.paymentInsertModel = PaymentInsertComponent.open(
      this.modalService,
      this.language,
      isVerifone,
      isRefund,
      this.olo.validationRes?.data?.total??0,
      this.cartService.subtotal,
      this.olo.validationRes?.data?.tax??0,
      this.cartService.tip,
      this.cartService.shippingFee,
      this.cartService.discount,
      this.cartService.reward,
      this.paymentTypeService.payments.length == 1 &&
        GeneralSetting.getShowPaymentScreen() != 'True',
      isClover
    ).componentInstance;
  }

  public closePaymentInsertModel() {
    if (this.paymentInsertModel) {
      this.paymentInsertModel.close();
    }
  }

  public openPaymentSuccessModel(isVerifone: boolean = false) {
    if (this.paymentSuccessModal) {
      this.closePaymentInsertModel();
    }

    this.paymentSuccessModalRef = PaymentSuccessComponent.open(
      this.modalService,
      isVerifone
    );
    this.paymentSuccessModal = this.paymentSuccessModalRef.componentInstance;
  }

  public closePaymentSuccessModel() {
    if (this.paymentSuccessModal) {
      this.paymentSuccessModal.close();
      this.paymentSuccessModal = null;
      this.paymentSuccessModalRef = null;
    }
  }

  public getPaymentSuccessModelRef() {
    if (this.paymentSuccessModalRef) {
      return this.paymentSuccessModalRef;
    } else return null;
  }

  public openPaymentErrorDialog(isVerifone: boolean, errorMessage: string) {
    return new Promise<void>((resolve, reject) => {
      let pec = PaymentErrorComponent.open(
        this.modalService,
        this.language,
        isVerifone,
        errorMessage
      );
      pec.result.then((data: any) => {
        resolve(data);
      });
    });
  }

}
