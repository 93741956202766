import { Injectable } from '@angular/core';
import { ConcessionaireFilterTypes } from 'src/app/models/concessionaireTypes';
import { ItemTagFilterMasters } from 'src/app/models/ItemTagFilterMaster';
import { DatabaseService } from 'src/app/services/database.service';

@Injectable({
  providedIn: 'root'
})
export class ConcessionaireService {

  concessionaireFilterTypes: ConcessionaireFilterTypes[] = [] as ConcessionaireFilterTypes[];

  selectedConcessionaireTypes: string[] = [] as string[];

  itemTagsAndGroupFilters : ItemTagFilterMasters[] = [] as ItemTagFilterMasters[];

  selectedItemTags: string[] = [] as string[];

  constructor(private readonly db: DatabaseService,) { }

  setConcessionaireFilterTypes(concessionaireFilterTypes: ConcessionaireFilterTypes[]){
      this.concessionaireFilterTypes = concessionaireFilterTypes;
  }

  setItemTagsAndGroupFilters(itemTagsAndGroupFilters: ItemTagFilterMasters[]){
    this.itemTagsAndGroupFilters = itemTagsAndGroupFilters;
}
  
  setSelectedConcessionaireTypes(selectedConcessionaireTypes: string[],selectedItemTags: string[]){
    this.selectedConcessionaireTypes = selectedConcessionaireTypes;
    this.selectedItemTags = selectedItemTags;
  }

  getSelectedConcessionaireTypes(check : number){
    var selConType = "";
    var filters = (check == 1) ? this.selectedConcessionaireTypes : this.selectedItemTags;
    if(filters){
      var len = Object.entries(filters).length;
      for(let i=0 ; i< len; i++){
        if(i != 0 && i != len){
          selConType += ','
        }
        selConType +=`'${filters[i]}'`;
      }
    }
    return selConType;
  }

  getSelectedFilterList(){
    var consFilter = JSON.parse(JSON.stringify(this.concessionaireFilterTypes));
    var selFilter = this.concessionaireFilterTypes;
    if (this.itemTagsAndGroupFilters && this.itemTagsAndGroupFilters.length > 0) {
      this.itemTagsAndGroupFilters.forEach((element) => {
        element.FilterItemTagGroupMapping.forEach((itemtags) => {
          if (itemtags.isSelected){
            let con: ConcessionaireFilterTypes = {
              ConcessionaireTypeID: itemtags.FilterID,
              Name: itemtags.FilterName,
              isSelected : itemtags.isSelected,
              isItemTag : true
            };
            selFilter.push(con); 
          }
        });
      });
    }
    this.concessionaireFilterTypes = consFilter;
    return selFilter;
  }

  onConcessionaireTypeSelection(filterId : string, isSelected : boolean, event : Event, itemTags : boolean){
    if(itemTags){
      if (this.itemTagsAndGroupFilters && this.itemTagsAndGroupFilters.length > 0) {
        this.itemTagsAndGroupFilters.forEach((element) => {
          element.FilterItemTagGroupMapping.forEach((itemtags) => {
            if (itemtags.FilterID == filterId ) {
              if(isSelected){
                this.selectedItemTags.push(filterId);
                this.selectedItemTags = this.selectedItemTags.filter((item, pos, self) => self.findIndex(v => v === item) === pos);
              }else{
                event.stopPropagation();
                event.preventDefault();
                this.deleteSleCon(filterId,itemTags);
              }
              itemtags.isSelected = isSelected;
            }
          });
        });
      }
    }else{
      if (this.concessionaireFilterTypes && this.concessionaireFilterTypes.length > 0) {
        this.concessionaireFilterTypes.forEach((element) => {
          if (element.ConcessionaireTypeID == filterId ) {
            if(isSelected){
              this.selectedConcessionaireTypes.push(element.ConcessionaireTypeID);
              this.selectedConcessionaireTypes = this.selectedConcessionaireTypes.filter((item, pos, self) => self.findIndex(v => v === item) === pos);
            }else{
              event.stopPropagation();
              event.preventDefault();
              this.deleteSleCon(element.ConcessionaireTypeID, itemTags);
            }
            element.isSelected = isSelected;
          }
        });
      }
    }
  }

  deleteSleCon(msg:string, itemTags : boolean) {
    if(itemTags){
      const index: number = this.selectedItemTags.indexOf(msg);
      if (index !== -1) {
          this.selectedItemTags.splice(index, 1);
      }
    }else{
      const index: number = this.selectedConcessionaireTypes.indexOf(msg);
      if (index !== -1) {
          this.selectedConcessionaireTypes.splice(index, 1);
      }      
    }  
  }

  clearFilter(){
    this.concessionaireFilterTypes = [] as ConcessionaireFilterTypes[];
    this.selectedConcessionaireTypes = [] as string[];
    this.itemTagsAndGroupFilters = [] as ItemTagFilterMasters[];
    this.selectedItemTags = [] as string[];
  }
}
