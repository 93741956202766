<div id="custominfo-popup" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="canceled()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="header">
    <h2 class="text-center"><b>{{uhOhText}}</b></h2>
    <h3 class="text-center"><strong>{{ oloErrorMsg }}</strong></h3>
    <h4 class="text-center">{{oloConfirmation}} </h4>
  </div>
  <div class="popup-footer">
    <button type="submit" class="text-center green-btn" (click)="close()">
      {{oloBtnMsg}}
    </button>
  </div>
</div>