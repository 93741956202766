<button class="close-icon" (click)="dismiss()">
  <svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78">
    <g id="x-circle" transform="translate(-0.551 -1)">
      <circle id="Ellipse_3" data-name="Ellipse 3" cx="38" cy="38" r="38" transform="translate(1.551 2)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <line id="Line_12" data-name="Line 12" x1="23" y2="23" transform="translate(28.551 28)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <line id="Line_13" data-name="Line 13" x2="23" y2="23" transform="translate(28.551 28)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </svg>
  
</button>
<h1>{{termsAndConditionsText}}</h1>
<div id="Iframe-Master-CC-and-Rs" *ngIf="value" class="set-margin set-padding set-border set-box-shadow center-block-horiz">
  <div class="responsive-wrapper 
     responsive-wrapper-wxh-572x612"
     style="-webkit-overflow-scrolling: touch; overflow: auto;">
    <iframe [src]="value"></iframe>
  </div>
</div>