import { Component, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Concessionaire, Concessionaire as IConcessionaire } from "../../models/Concessionaire";

@Component({
  selector: 'app-concessionaire-select',
  templateUrl: './concessionaire-select.component.html',
  styleUrls: ['./concessionaire-select.component.css']
})
export class ConcessionaireSelectComponent implements OnInit {

  modalReference: any;

  concessionaireList: Concessionaire[] = [] as IConcessionaire[];

  isDefault:boolean = false;

  constructor(public modalService: NgbModal) {


  }

  ngOnInit(): void {  }

  private deselectAll() {
    for (let con of this.concessionaireList) {
      con.IsSelected = false;
      con.IsDefaultConcessionaire = false;
    }
  }

  onConcessionaireSelect(e:any,conId:string) {
    const checked = e.target.checked;

    if (this.isDefault) {
      this.deselectAll();
    }

    const selectedCon = this.concessionaireList.find(x => x.ConcessionaireID == conId);

    if (selectedCon) {
      if (this.isDefault) {
        selectedCon.IsDefaultConcessionaire = checked;
        selectedCon.IsSelected = checked;
      }
      else
      {
        selectedCon.IsSelected = checked;
      }
    }
  }

  public static open(modalService:NgbModal, conList:Concessionaire[],isDefault:boolean = false) {
    const modalReference = modalService.open(ConcessionaireSelectComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });
   
    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.concessionaireList = conList;
    modalReference.componentInstance.isDefault = isDefault;

    return modalReference;
  }

  close() {
    this.modalReference.close('canceled');
  }

  onSaveClick() {
    this.modalReference.close(this.concessionaireList);
  }

}
