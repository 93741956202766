import { Injectable } from '@angular/core';
import {
  ItemV2,
  ModifierV2,
  ModifierIngredientV2,
  ComboGroupV2,
} from '../models/item';
import { VariationOption } from '../models/Variations';
import { GeneralSetting } from './generalSetting.service';
import { formatDate } from '@angular/common';
import { ConcessionaireDiscontDetail } from '../models/ConcessioanireDiscountDetail';
import { FixedItemDiscount } from '../models/discounts';
import { Item } from '../models/BranchLoginModels';
import { CartComponent } from '../components/cart/cart.component';
import { element } from 'protractor';

@Injectable({
  providedIn: 'root',
})
export class CommonFunctions {
  static isItemSoldOut(data: ItemV2) {
    let flag = false
    if (
      data.CurrentStock != null &&
      Number(data.CurrentStock) <= Number(data.LowThreshold)
    ) {
      flag = true;
    }
    return flag
  }
  static checkVariable(variable: any) {
    if (variable && variable != undefined && variable != null) {
      return true;
    }
    return false;
  }

  static getCurrentTime() {
    // var date = new Date();
    // var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    // var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    // var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    // var strTime = hours + ":" + minutes + ":" + seconds;
    var strTime = formatDate(Date.now(), 'HH:mm:ss', 'en-US');
    return strTime;
  }

  // prettier-ignore
  static getFormatedYearDate(date: any) {
    // this function is transferring format from "M/dd/YYYY, H:mm:ss AM/PM" -> "yyyymmdd"
    var yearDate = date.substring(0, date.indexOf(','));
    var yearDateArray = yearDate.split('/');
    if (yearDateArray) {
      var newMonth = yearDateArray[0];
      var newDate = yearDateArray[1];
      var newYear = yearDateArray[2];
      if (newMonth.length < 2) { newMonth = '0' + newMonth; }
      if (newDate.length < 2) { newDate = '0' + newDate; }

      return newYear + newMonth + newDate;
    }
    else {
      return 0;
    }
  }

  static getCurrentDateTime() {
    var strTime = formatDate(Date.now(), 'YYYY-MM-dd HH:mm:ss', 'en-US');
    return strTime;
  }

  static getCurrentformatedtDate(date: any) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-') + ' 00:00:00';
  }

  static log(...variables: any) {
    for (let variable of variables) {
      console.log(typeof variable, variable);
    }
  }

  static getDateTime(timezone: any) {
    var date = new Date();
    let currentDate = timezone.transform(date, 'MMM M YYYY h:mm');
    const amPm = date.getHours() >= 12 ? 'PM' : 'AM';
    currentDate += amPm;
    return currentDate;
  }

  static getTime(date: any) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const amPm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = hours + ':' + minutes + ' ' + amPm;
    return strTime;
  }

  static getDate() {
    const currentDate = new Date();
    const date = currentDate.toDateString() + ' ' + this.getTime(currentDate);
    return date;
  }

  static getDateV2() {
    const currentDate = new Date();
    let currentDateV2 = formatDate(currentDate, 'MMM dd YYYY hh:mma', 'en-US');
    const date = currentDateV2.toString();
    return date;
  }
  static getUTCDate() {
    const currentDate = new Date();
    let currentDateV2 = formatDate(new Date(), 'yyyy-MM-dd H:mm:ss', 'en', '+0000');
    const date = currentDateV2.toString();
    return date;
  }
  static getDayName() {
    var dateObj = new Date();
    var weekday = dateObj.toLocaleString('default', { weekday: 'long' });
    return weekday;
  }

  static roundDigit(n: number, digits: number) {
    if (digits === undefined) {
      digits = 0;
    }

    const multiplicative = Math.pow(10, digits);
    n = parseFloat((n * multiplicative).toFixed(11));
    return Math.round(n) / multiplicative;
  }

  static getRandomGuid() {
    function s4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-4' +
      s4().substr(0, 3) +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    ).toLowerCase();
  }
  static fixTwoDigit(num: number) {
    if (num === undefined) {
      return 0.00;
    }
    return Number(this.roundDigit(num, 2).toFixed(2));
  }

  static getCommaSepratedStringWithList(ids: string[]) {
    let str_id = '';

    for (let i = 0; i < ids.length; i++) {
      if (i == 0) {
        str_id += "'" + ids[i] + "'";
      } else {
        str_id += ",'" + ids[i] + "'";
      }
    }

    return str_id.trim();
  }

  static getDayOfWeak() {
    let dayId = new Date().getDay();

    if (dayId == 0) {
      dayId = 7;
    }

    return dayId;
  }

  /**
   * @param {any[]} arr an array of items to be filtered asynchronously
   * @param {Function} callback an asynchronous callback of the format `(data:data type)=>{return Promise.resolve(booleanConditonByWhichToFilter)}`
   */
  static async filter(arr: any[], callback: Function) {
    return (
      await Promise.all(
        arr.map(async (item: any) => {
          return (await callback(item)) ? item : undefined;
        })
      )
    ).filter((i) => i !== undefined);
  }

  /**
   * @param {any[]} arr an array of items to perform callback upon asynchronously
   * @param {Function} callback an asynchronous callback
   */
  static async forEach(arr: any[], callback: Function) {
    for (let index = 0; index < arr.length; index++) {
      await callback(arr[index], index);
    }
  }

  /**
   *
   * @param {ItemV2} item Data related to the selected item object
   * @param isEdit default false, determines if app should override item behavior for displaying item detail screen
   * @returns {Promise<string>} Promise containing dynamic URL path of the selected item's desired screen
   */
  static getItemType(item: ItemV2, isEdit: boolean = false): string {
    if (item.EnablePricebyWeight && item.EnablePricebyWeight == 'True') {
      return 'itemDescription';
    } else if (
      (item.Modifiers && item.Modifiers.length) ||
      (item.Variations &&
        item.Variations.length) ||
      item.ModifierCount > 0 ||
      item.AttributeCount > 0 ||
      item.IsCombo
    ) {
      if (item.IsBuilderMode.toLowerCase() == 'true') {
        return 'ItemBuilderMode';
      } else if (
        item.IsBoxBuilderMode &&
        item.IsBoxBuilderMode.toLowerCase() == 'true'
      ) {
        return 'ItemBoxBuilderMode';
      } else {
        return 'ItemNormalMode';
      }
    }
    else {
      if (item.IsItemDetailScreenShowKiosk == 'True' || isEdit) {
        return 'itemDescription';
      } else return 'menu';
    }
  }

  /**
   *
   * @param {ItemV2} item Data related to the selected item object
   * @returns {Promise<string>} Promise containing dynamic URL path of the selected item's desired screen
   */
  static getItemTypeV2(item: ItemV2): string {
    if (
      (item.Modifiers && item.Modifiers.length > 0) ||
      (item.Variations && item.Variations.length > 0) ||
      (item.ComboGroup && item.ComboGroup.length > 0)
    ) {
      if (item.IsBuilderMode.toLowerCase() == 'true') {
        return 'ItemBuilderMode';
      } else if (
        item.IsBoxBuilderMode &&
        item.IsBoxBuilderMode.toLowerCase() == 'true'
      ) {
        if (item.ComboGroup && item.ComboGroup.length > 0) {
          return 'ItemBoxBuilderMode';
        } else {
          return 'ItemBuilderMode';
        }
      } else {
        return 'ItemNormalMode';
      }
    } else {
      if (item.IsItemDetailScreenShowKiosk == 'True') {
        return 'itemDescription';
      } else return 'menu';
    }
  }

  static getVariationPrice(variations: VariationOption[]) {
    let cost = 0;
    for (const variation of variations) {
      cost += Number(variation.Price);
    }
    return cost;
  }

  static getModifierPrice(modifiers: ModifierV2[]) {
    let price = 0;

    for (let mi = 0; mi < modifiers.length; mi++) {
      const modifier = modifiers[mi];

      const ingredients = modifier.Ingredients.filter((x) => x.IsSelected);

      if (ingredients.length > 0) {
        for (let ii = 0; ii < ingredients.length; ii++) {
          const ingredient = ingredients[ii];

          if (ingredient.IsIngredient) {
            const ing = <ModifierIngredientV2>ingredient;
            if (GeneralSetting.getParBrinkIntegrationID() == '') {
              price += Number(ing.ExtraPrice) * Number(ing.Quantity);
            } else {
              if (ing.IsDefault != 'True') {
                price += Number(ing.ExtraPrice) * Number(ing.Quantity);
              }
            }
          } else {
            const mod = <ModifierV2>ingredient;
            let modifierPrice = Number(mod.Price);
            let modifierTotalCost = this.getModifierPrice([
              <ModifierV2>ingredient,
            ]);
            let modifierTotalPrice =
              modifierPrice * Number(mod.Quantity ? mod.Quantity : '1') +
              modifierTotalCost * Number(mod.Quantity ? mod.Quantity : '1');
            price += modifierTotalPrice;

            // price += Number(mod.Price);
            // price += this.getModifierPrice([<ModifierV2>ingredient]);
          }
        }
      }
    }

    return price;
  }

  static getItemPrice(item: ItemV2, isComboItem: boolean = false) {
    if (GeneralSetting.getIsRemovePricingAndPayments()) return 0;

    let itemCost = Number(item.Price); // basePrice

    if (isComboItem) {
      if (item.ExtraPrice && item.ExtraPrice != '') {
        itemCost = Number(item.ExtraPrice);
      }
    }

    const isCombo = item.IsCombo.toString();

    if (
      (isCombo != '0' && isCombo.toLowerCase() == 'true') ||
      item.IsCategory
    ) {
      if (item.ComboGroup && item.ComboGroup.length > 0) {
        for (let ci = 0; ci < item.ComboGroup.length; ci++) {
          const items = item.ComboGroup[ci].Items.filter(
            (x) =>
              x.isSelected ||
              (x.IsInvisibleItem && x.IsInvisibleItem.toLowerCase() == 'true')
          );
          for (let i = 0; i < items.length; i++) {
            let quantity = 1;
            if (items[i].Quantity && items[i].Quantity != 'undefined') {
              quantity = Number(items[i].Quantity);
            }
            itemCost += this.getItemPrice(items[i], true) * quantity;
          }
        }
      }
    } else {
      if (item.Modifiers && item.Modifiers.length > 0) {
        itemCost += this.getModifierPrice(item.Modifiers);
      }

      if (item.VariationOption && item.VariationOption.length > 0) {
        if (
          item.VariationOption != undefined &&
          Object.entries(item.Variations).length > 0
        ) {
          itemCost += this.getVariationPrice(item.VariationOption);
        }
      } else {
        if (item.Variations && item.Variations.length) {
          if (
            item.Variations[0].variationOptions &&
            item.Variations[0].variationOptions.length
          ) {
            for (
              let i = 0;
              i < item.Variations[0].variationOptions.length;
              i++
            ) {
              if (item.Variations[0].variationOptions[i].isSelected) {
                itemCost += this.getVariationPrice([
                  item.Variations[0].variationOptions[i],
                ]);
              }
            }
          }
        }
      }
    }
    return itemCost;
  }

  static getSelectedIngredientCount(modifier: ModifierV2) {
    let count = 0;

    const selectedIngredient = modifier.Ingredients.filter((x) => x.IsSelected);
    for (let i = 0; i < selectedIngredient.length; i++) {
      count += Number(selectedIngredient[i].Quantity);
    }

    return count;
  }

  static getOnlySelectedIngredientCount(modifier: ModifierV2) {
    const selectedIngredient = modifier.Ingredients.filter((x) => x.IsSelected);
    return selectedIngredient.length;
  }

  static getSelectedIngredientPosition(modifier: ModifierV2) {
    let selectedPosition = 0;

    modifier.Ingredients.forEach((element, index) => {
      if (element.IsSelected) {
        selectedPosition = index;
      }
    });


    return selectedPosition;
    
  }

  static getParentCountItem(parent: ComboGroupV2) {
    parent.count = 0;
    const selectedItems = parent.Items.filter((x) => x.isSelected);
    if (selectedItems.length > 0) {
      for (let i = 0; i < selectedItems.length; i++) {
        let quantity = 1;

        if (
          selectedItems[i].Quantity &&
          selectedItems[i].Quantity != 'undefined'
        ) {
          quantity = Number(selectedItems[i].Quantity);
        }

        parent.count += quantity;
      }
    }

    return parent.count;
  }

  static isItemHasModifier(item: ItemV2) {
    if (
      (item.Modifiers && item.Modifiers.length > 0) ||
      (item.Variations &&
        item.Variations.length > 0 &&
        item.Variations[0].variationOptions.length > 0) ||
      (item.ComboGroup && item.ComboGroup.length > 0)
    ) {
      return true;
    }

    return false;
  }

  static getSelectedModifierIngredientList(
    ingredient: (ModifierV2 | ModifierIngredientV2)[],
    withNegativeModifier: boolean = false
  ) {
    let selectedIngredient = [] as (ModifierV2 | ModifierIngredientV2)[];

    if (!ingredient || ingredient.length <= 0) return selectedIngredient;

    for (var i = 0; i < ingredient.length; i++) {
      let objIngredient = ingredient[i];

      if (objIngredient.IsSelected) {
        selectedIngredient.push(objIngredient);
      } else if (withNegativeModifier) {
        if (objIngredient.IsIngredient) {
          const ing = objIngredient as ModifierIngredientV2;
          if (ing.isNegativeModifier) {
            selectedIngredient.push(ing);
          }
        }
      }
    }

    return selectedIngredient;
  }

  static getSelectedItemFromComboGroups(
    comboGroups: ComboGroupV2[],
    withModifers: boolean = false
  ) {
    let selectedItems: ItemV2[] = [];
    let selectedModifiers: ModifierV2[] = [];
    let selectedIngridient: ModifierIngredientV2[] = [];

    if (comboGroups && comboGroups.length > 0) {
      for (let i = 0; i < comboGroups.length; i++) {
        if (comboGroups[i].Items && comboGroups[i].Items.length > 0) {
          for (let j = 0; j < comboGroups[i].Items.length; j++) {
            if (comboGroups[i].Items[j].isSelected) {
              selectedItems.push(comboGroups[i].Items[j]);

              if (withModifers) {
                if (
                  comboGroups[i].Items[j].Modifiers &&
                  comboGroups[i].Items[j].Modifiers.length > 0
                ) {
                  let list_nestedMofier =
                    this.getSelectedModifiersWithIngredient(
                      comboGroups[i].Items[j].Modifiers
                    );

                  if (list_nestedMofier.length > 0) {
                    selectedModifiers.push(
                      ...(list_nestedMofier[0] as ModifierV2[])
                    );
                    selectedIngridient.push(
                      ...(list_nestedMofier[1] as ModifierIngredientV2[])
                    );
                  }
                }
              }
            }
          }
        }
      }
    }

    return [selectedItems, selectedModifiers, selectedIngridient];
  }

  static getSelectedModifiersWithIngredient(
    modifiers: (ModifierV2 | ModifierIngredientV2)[]
  ) {
    let selectedModifiers = [] as ModifierV2[];
    let selectedIngridient = [] as ModifierIngredientV2[];

    if (!modifiers || modifiers.length <= 0) return selectedModifiers;

    for (var i = 0; i < modifiers.length; i++) {
      let objModifier = modifiers[i];

      if (objModifier.IsSelected) {
        if (objModifier.IsModifier) {
          objModifier = objModifier as ModifierV2;

          selectedModifiers.push(objModifier);

          if (objModifier && objModifier.Ingredients.length > 0) {
            let list_nestedMofier = this.getSelectedModifiersWithIngredient(
              objModifier.Ingredients
            );

            if (list_nestedMofier.length > 0) {
              selectedModifiers.push(...(list_nestedMofier[0] as ModifierV2[]));
              selectedIngridient.push(
                ...(list_nestedMofier[1] as ModifierIngredientV2[])
              );
            }
          }
        } else {
          selectedIngridient.push(objModifier as ModifierIngredientV2);
        }
      }
    }

    return [selectedModifiers, selectedIngridient];
  }

  static getSelectedItemList(items: ItemV2[]) {
    const selectedItems = [] as ItemV2[];

    if (!items || items.length <= 0) return selectedItems;

    for (let i = 0; i < items.length; i++) {
      const objItem = items[i];

      if (objItem.isSelected) {
        selectedItems.push(objItem);
      }
    }

    return selectedItems;
  }

  /**
   * @description Disables a button for 1.5 seconds. Used in conjunction with debounce to prevent multiple modals
   * @param {string} id The HTML id of the button to be disabled
   * @param {number} time default 1500, time in milliseconds to disable button
   */
  static disableButton(id: string, time: number = 1500) {
    document.getElementById(`${id}`)?.setAttribute('disabled', 'disabled');
    setTimeout(() => {
      document.getElementById(`${id}`)?.removeAttribute('disabled');
    }, time);
  }

  private static deSelectAllNestedIngredient(modifier: ModifierV2) {
    for (let i = 0; i < modifier.Ingredients.length; i++) {
      const ingredient: ModifierIngredientV2 | ModifierV2 =
        modifier.Ingredients[i];
      ingredient.IsSelected = false;

      if (ingredient.IsModifier) {
        const mod = ingredient as ModifierV2;
        this.deSelectAllNestedIngredient(mod);
      }
    }
  }

  static selectModifierLogic(parent: ModifierV2, IngredientIndex: number) {
    let modifier = parent.Ingredients[IngredientIndex];

    if (!modifier) {
      return parent;
    }

    modifier = <ModifierV2>modifier;

    if (parent.IsModifier86 == 'False' || modifier.IsModifier86 == 'False') {
      //if out of stock, return and set isSelected to false
      modifier.IsSelected = false;

      this.deSelectAllNestedIngredient(modifier);
      return parent;
    } else {
      parent.count = CommonFunctions.getSelectedIngredientCount(parent);

      if (parent.count < parent.max) {
        parent.count++;

        modifier.IsSelected = true;

        parent.IsSelected = true;

        if (
          parent.SelectedIngredients.find(
            (x: any) => x.ModifierID == modifier.ModifierID
          ) == undefined
        ) {
          parent.SelectedIngredients.push(modifier);
        }
      } else if (parent.max === 1) {
        // if only 1 selectable switch selection to newly selected object
        parent.count = 1;
        parent.SelectedIngredients = [];
        for (const item of parent.Ingredients) {
          item.IsSelected = false;

          // deselect sub group Ingredients if another modifier selected
          if (item.IsModifier && item.ModifierID != modifier.ModifierID) {
            const subGroupModifier = item as ModifierV2;

            if (subGroupModifier.IsSubGroup) {
              this.deSelectAllNestedIngredient(subGroupModifier);
            }
          }
        }

        modifier.IsSelected = true;
        parent.IsSelected = true;

        parent.SelectedIngredients.push(modifier);
      }
    }

    parent.count = CommonFunctions.getSelectedIngredientCount(parent);

    if (parent.count <= parent.max && parent.count >= parent.min) {
      parent.isValid = true;
      parent.IsSelected = true;
    }

    return parent;
  }

  static isNonAlcoholicItemAvail(items: ItemV2[]) {
    for (let i = 0; i < items.length; i++) {
      if (
        items[i].ItemCategorySalesTypeID !==
        CategorySalesType.Alcohol.toString()
      ) {
        return true;
      }
    }
    return false;
  }

  // static getFormattedModifier(modifier : ModifierV2[] , item : ItemV2) {

  // function formatIngredient(ingredient : ModifierIngredientV2, item : ItemV2) {
  //   return template;
  // }

  // function formatModifier(modifier: ModifierV2 , item : ItemV2) {
  //     let template = "";
  //     if (modifier.IsSelected) {
  //       if (modifier.Ingredients && modifier.Ingredients.length > 0) {
  //         for (let index = 0; index < modifier.Ingredients.length; index++) {
  //           const ing = modifier.Ingredients[index] as any;
  //           if (ing.IsIngredient && (ing.IsSelected || ing.isNegativeModifier)) {
  //             let ingredient = ing;
  //             template += formatIngredient(ingredient, item);
  //           } else if (ing.IsModifier) {
  //             let mod = ing;
  //             template += formatModifier(mod, item);
  //           }
  //         }
  //       }
  //     }

  //     return template;
  // }

  // let mainTemplate = "";
  // for (let i = 0; i < modifier.length; i++) {
  //   mainTemplate += formatModifier(modifier[i], item);
  // }
  // return mainTemplate;
  // }

  // Set flow as per General kiosk i.e. modifer 86
  static checkForSoldOutV2(item: ItemV2): Promise<ModifierV2[]> {
    const soldOutMod: ModifierV2[] = [];
    const modifierCheck = async (modifier: any) => {
      if (modifier && modifier.IsModifier86 == 'False') {
        soldOutMod.push(modifier);
      }
      return Promise.resolve();
    };
    return new Promise<ModifierV2[]>(async (resolve) => {
      if (item.Modifiers && item.Modifiers.length > 0) {
        await CommonFunctions.forEach(item.Modifiers, modifierCheck).then(
          () => {
            resolve(soldOutMod);
          }
        );
      } else {
        resolve(soldOutMod);
      }
    });
  }

  // Added new method to show category on day and time based
  // static isDaywise(arr: any, currentDayId: string): boolean {
  //   const currentTime = this.getCurrentTime();
  //   for (var i = 0; i < arr.DayIDs.length; i++) {
  //     if (arr.DayIDs[i] == currentDayId) {
  //       return (arr.TimeActives[i] == 'False' ||
  //         (arr.StartTimes[i] <= currentTime &&
  //           arr.EndTimes[i] >= currentTime))
  //     }
  //   }
  //   return false;
  // }

  // Added new method to show category on day and time based
  static getWeightUnit(weightUnit: string): string {
    if (weightUnit == '0') {
      return 'oz';
    } else if (weightUnit == '1') {
      return 'lb';
    } else if (weightUnit == '2') {
      return 'g';
    } else if (weightUnit == '3') {
      return 'kg';
    } else {
      return '';
    }
  }
  static surveyCodeReplacer(data: string) {
    return GeneralSetting.getSurveyLink().replace('[code]', data);
  }
  static surveyCodeScrambler(transactionNumber: string) {
    if (GeneralSetting.getShowSurveyLinkOnReceipt() != 'True') {
      return '';
    }
    if (transactionNumber.length > 4) {
      transactionNumber = transactionNumber.slice(-4);
    }
    let storeID: string = GeneralSetting.getStoreID();
    const dt = new Date();
    let militaryTime = dt.getHours().toString();
    if (militaryTime.length == 1) {
      militaryTime = '0' + militaryTime;
    }
    let month = (dt.getMonth() + 1).toString();
    if (month.length == 1) {
      month = '0' + month;
    }
    let day = dt.getDate().toString();
    if (day.length == 1) {
      day = '0' + day;
    }
    return `${transactionNumber[3]}${militaryTime[1]}${storeID[3]} ${transactionNumber[2]}${storeID[1]}${month[1]} ${transactionNumber[1]}${storeID[0]}${transactionNumber[0]} ${month[0]}${day[1]}${storeID[2]} ${day[0]}${militaryTime[0]}K`;
  }

  static getWeightUnitInWords(weightUnit: string): string {
    if (weightUnit == '0') {
      return 'Ounce';
    } else if (weightUnit == '1') {
      return 'Pound';
    } else if (weightUnit == '2') {
      return 'Gram';
    } else if (weightUnit == '3') {
      return 'Kilogram';
    } else {
      return '';
    }
  }

  static getStringWithDash(first: string, second: string) {
    let res = '';

    if (first != '' && second != '') {
      res = first + '-' + second;
    } else if (first == '' && second != '') {
      res = second;
    } else if (first != '' && second == '') {
      res = first;
    }

    return res;
  }

  static getLatestAlcoholicCount(
    currentParentQty: number,
    quantity: number,
    isIncrease: boolean,
    isCancel: boolean = false
  ): any {
    let currentAlcoholCount = Number(GeneralSetting.getCurrentCountofAlkol());
    if (currentParentQty == 0) {
      if (isIncrease) {
        currentAlcoholCount++;
      } else {
        currentAlcoholCount--;
      }
    } else {
      if (isCancel) {
        if (isIncrease) {
          currentAlcoholCount =
            currentAlcoholCount + currentParentQty * quantity;
        } else {
          currentAlcoholCount =
            currentAlcoholCount - currentParentQty * quantity;
        }
      } else {
        let currentQty: number = isIncrease ? quantity + 1 : quantity - 1;
        currentAlcoholCount =
          currentAlcoholCount > 0
            ? currentAlcoholCount - currentParentQty * quantity
            : 0;
        currentAlcoholCount =
          currentAlcoholCount + currentParentQty * currentQty;
      }
    }
    return currentAlcoholCount;
  }

  static delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static checkEmail(emailId: string) {
    if (!emailId) return false;

    var EMAIL_REGEXP =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!EMAIL_REGEXP.test(emailId)) {
      return false;
    }

    return true;
  }

  static checkPhone(phone: string, isUK: boolean = false) {
    if (!phone) {
      return false;
    }
    let num = 10;
    if (isUK) {
      num = 11;
    }
    if (phone.length < num) {
      return false;
    }
    let regPhone = /^[1-9]\d*|0\d{10}(?!0+$)/;

    if (!regPhone.test(phone)) {
      return false;
    }

    return true;
  }

  static checkName(name: string) {
    if (!name) return false;

    let regName = /^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$/;

    if (!regName.test(name)) {
      return false;
    }

    return true;
  }

  static isComboAlcoholic(comboItem: ItemV2): number {
    const value = Number(comboItem.Quantity);
    let isItemAlcoholic = false;
    const alkolMax = GeneralSetting.getMaxAlcoholicItemsPerOrder();
    let itemQuanity = 0;
    var isCombo = comboItem.IsCombo.toString();

    if (isCombo.toLowerCase() == '0' || isCombo.toLowerCase() == 'true') {
      // loop through all the items if you find any item alcoholic then make isItemAlcoholic as true;
      for (let i = 0; i < comboItem.ComboGroup.length; i++) {
        for (let j = 0; j < comboItem.ComboGroup[i].Items.length; j++) {
          let singleItem = comboItem.ComboGroup[i].Items[j];
          //const alcohol = cartItem.isAlcoholic;
          if (singleItem.isSelected) {
            if (
              singleItem.ItemCategorySalesTypeID ==
              CategorySalesType.Alcohol.toString() &&
              Number(alkolMax) > 0
            ) {
              //this.checkAlkolLimit(singleItem, value);
              itemQuanity += Number(
                singleItem.Quantity ? singleItem.Quantity : 1
              );
            }
          }
        }
      }
    }

    return itemQuanity;
  }

  static isAlcoholLimitRemainig() {
    return (
      Number(GeneralSetting.getMaxAlcoholicItemsPerOrder()) -
      Number(GeneralSetting.getCurrentCountofAlkol()) >
      0
    );
  }

  static increaseAlcoholicCountForCombo(
    item: ItemV2,
    shouldIncrease: boolean
  ): boolean {
    if (this.isComboAlcoholic(item)) {
      let comboAlcoholCount = 0;
      let currentCountofAlkol = GeneralSetting.getCurrentCountofAlkol();
      let maxOrder = GeneralSetting.getMaxAlcoholicItemsPerOrder();

      for (let i = 0; i < item.ComboGroup.length; i++) {
        for (let j = 0; j < item.ComboGroup[i].Items.length; j++) {
          let singleItem = item.ComboGroup[i].Items[j];
          if (singleItem.isSelected) {
            if (
              singleItem.ItemCategorySalesTypeID ==
              CategorySalesType.Alcohol.toString()
            ) {
              comboAlcoholCount +=
                Number(singleItem.Quantity) * Number(item.Quantity);
            }
          }
        }
      }
      if (shouldIncrease) {
        if (
          comboAlcoholCount + Number(currentCountofAlkol) <=
          Number(maxOrder)
        ) {
          GeneralSetting.setCurrentCountofAlkol(
            comboAlcoholCount + Number(currentCountofAlkol)
          );
          return true;
        } else {
          return false;
        }
      } else {
        if (Number(currentCountofAlkol) > 0) {
          sessionStorage.setItem(
            'currentCountofAlkol',
            (Number(currentCountofAlkol) - comboAlcoholCount).toString()
          );
        }
        return true;
      }
    } else {
      return true;
    }
  }

  static getSubTotalByItems(items: ItemV2[]) {
    let subtotal = 0.0;

    items.forEach((element: any) => {
      subtotal +=
        Number(this.getItemPrice(element, true)) * Number(element.Quantity);
    });

    return subtotal;
  }

  static getSubTotalByItemsAfterDiscount(items: ItemV2[]) {
    let subtotal = 0.0;

    items.forEach((element: any) => {
      subtotal +=
        (Number(this.getItemPrice(element, true)) * Number(element.Quantity)) - (element.DiscountAmount && element.DiscountAmount != 0 ? element.DiscountAmount : 0);
    });

    return subtotal;
  }

  static getTotalItemTax(items: ItemV2[]) {
    let itemTax = 0.0;

    items.forEach((element: any) => {
      itemTax += Number(element.TaxAmountWithDiscount);
    });

    return itemTax;
  }

  static increaseVerifonePaymentCounter() {
    let currentCounter = GeneralSetting.getVerifonePaymentCounter() == "" ? 1 : Number(GeneralSetting.getVerifonePaymentCounter());

    var counterList = GeneralSetting.getVerifoneUsedCounterList();

    currentCounter = currentCounter + 1;

    if (counterList != null) {
      while (counterList.data.includes(currentCounter)) {
        currentCounter = currentCounter + 1;
      }

      try {
        let maxCounter = counterList.data.reduce((a: any, b: any) => {
          return Math.max(a, b);
        });

        if (currentCounter < maxCounter) {
          currentCounter = maxCounter + 1;
        }
      }
      catch (e) { }
    }

    GeneralSetting.setVerifonePaymentCounter(currentCounter);

    try {
      if (counterList != null) {
        counterList.data.push(currentCounter);
        if (counterList.data.length > 50) {
          // counterList.data = counterList.data.slice(-1 * 50);
          let minCounter = counterList.data.reduce((a: any, b: any) => {
            return Math.min(a, b);
          });
          // let minCounter = Math.min(counterList.data);

          counterList.data = counterList.data.filter((x: any) => x != minCounter);
        }
      }
      else {
        counterList = {
          data: [currentCounter]
        }
      }

      GeneralSetting.setVerifoneUsedCounterList(counterList);
    } catch (e) {
      console.log("verifone counter list error");
    }

    return currentCounter;
  }

  static capitalizeFirstLetterOfWord(sentence: string): string {
    if (!sentence)
      return "";

    return sentence
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}

export class CommonCalculations {
  public static getItemTax(
    cartItems: ItemV2[],
    reward: number = 0,
    subtotal: number = 0,
    orderDiscount: number = 0,
    concessionaireDiscountDetails: ConcessionaireDiscontDetail[] = []
  ) {
    let totalTax = 0;
    let percentReward: number;
    if (reward > subtotal) {
      reward = subtotal;
    }
    if (reward != 0) {
      percentReward = reward / subtotal;
    } else {
      percentReward = 0;
    }
    let totalTaxTypes = [] as any[]; //Tax getting blank because of this initialization
    for (let i = 0; i < cartItems.length; i++) {
      const item = cartItems[i];
      let totalItemTax = 0;
      let totalItemTaxWithoutDisc = 0;

      let discount = orderDiscount;
      let loaclSubtotal = subtotal;

      if (GeneralSetting.getIsConcessionaire().toLowerCase() == 'true') {
        if (concessionaireDiscountDetails.length > 0) {
          if (
            concessionaireDiscountDetails.filter((x) => Number(x.Discount) > 0)
              .length > 0
          ) {
            let conDetail = concessionaireDiscountDetails.filter(
              (x) => x.concessionaireId == item.ConcessionaireId
            );

            if (conDetail.length > 0) {
              loaclSubtotal = Number(conDetail[0].SubTotal);
              discount = Number(conDetail[0].Discount);
            }
          }
        }
      }

      const itemTotal =
        CommonFunctions.getItemPrice(item) *
        Number(item.Quantity) *
        (1 - percentReward);

      const itemLevelDiscountAmount =
        item.DiscountAmount > 0 ? item.DiscountAmount : 0.0;

      const discountOnItem =
        ((itemTotal - itemLevelDiscountAmount) * discount) / loaclSubtotal;

      const itemTotalWithDiscount = itemTotal - itemLevelDiscountAmount - discountOnItem;

      const itemTotalWithoutDiscount = itemTotal - itemLevelDiscountAmount;

      if (item.taxDetail.length > 0) {
        for (let j = 0; j < item.taxDetail.length; j++) {
          if (item.totalPrice) {
            const itemTax = (Number(item.taxDetail[j].Percentage) * itemTotalWithDiscount) / 100;
            const itemTaxWithoutDiscount = (Number(item.taxDetail[j].Percentage) * itemTotalWithoutDiscount) / 100;

            totalItemTax += itemTax;
            totalTax += itemTax;
            totalItemTaxWithoutDisc += itemTaxWithoutDiscount;

            //for showing tax detail in popup component on check out screen
            const popUpTax: any = totalTaxTypes.find(
              (x: any) => x.TaxId == item.taxDetail[j].TaxID
            );

            if (popUpTax) {
              popUpTax.TaxAmount += itemTax;
            } else {
              totalTaxTypes.push({
                Name: item.taxDetail[j].TaxName,
                TaxId: item.taxDetail[j].TaxID,
                TaxAmount: itemTax,
                TaxPer: Number(item.taxDetail[j].Percentage),
              });
            }
          }
        }
      }

      item.TaxAmount = CommonFunctions.roundDigit(totalItemTaxWithoutDisc, 2);
      item.TaxAmountWithDiscount = CommonFunctions.roundDigit(totalItemTax, 2);
    }
    return {
      totalTax: totalTax,
      totalTaxTypes: totalTaxTypes
    };
  }
}

export class Snackbar {
  /**
   * /
   * @param message
   * @param delay
   * Show snackBar on project
   */
  static show(message: string, delay: number) {
    const snackbar = document.getElementById('snackbarV2');
    if (snackbar) snackbar.innerHTML = message;
    if (snackbar) {
      if (snackbar.className == 'show') return;
      snackbar.className = '';
      snackbar.className = 'show';
      (async () => {
        await CommonFunctions.delay(delay);
        snackbar.className = '';
      })();
    }
  }

  static delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}

export class blankOverlay {
  static show(message: string, withDelay: boolean, delay: number) {
    const overlay = document.getElementById('blank_overlay');
    if (overlay) {
      if (overlay.className == 'show_blank-overlay') return;

      overlay.className = '';
      overlay.className = 'show_blank-overlay';

      if (withDelay) {
        (async () => {
          await CommonFunctions.delay(delay);
          overlay.className = '';
        })();
      }
    }
  }

  static hide() {
    const overlay = document.getElementById('blank_overlay');
    if (overlay) {
      overlay.className = '';
    }
  }
}

export enum CategorySalesType {
  Other = 1,
  Food = 2,
  Beverage = 3,
  Alcohol = 4,
}

export enum WeightUnit {
  Ounce = 0,
  Pound = 1,
  Gram = 2,
  KiloGram = 3,
}
