
<div id="confirmation" >
    <!-- <div class="modal-dialog modal-dialog-centered" role="document"> -->
        <!-- <div class="modal-content"> -->
        <div class="modal-header">
            <h3 class="modal-title text-center" id="confirmationlabel">{{confirmationText}}</h3>
        </div>
        <div class="modal-body">
            <div class="row">
            <div class="col-md-3 col-3"><i class="fa fa-exclamation-triangle warn"></i></div>
            <div class="col-md-9 col-9">{{confirmLogoutText}}<br/>
            </div>
            </div>
        </div>
        <div class="modal-footer w-100-p" style="justify-content: center !important" >
            <button type="button" class="btn btn-primary float-right red-bg" (click)="confirmed()">{{yesText}}</button>
        </div>
        <!-- </div> -->
    <!-- </div> -->
</div>

