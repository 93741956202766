import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OloChainIdProductListReq,
  OloProductListReq,
  OloRewardsToApply,
  OLOChainProduct,
  ChainChoice,
// } from "../models/OLOProductReq";
// import { environment } from "../../environments/environment";
// import { DatabaseHandler } from "../DatabaseHandler";
// import { OloIntegration } from "../models/BranchLoginModels";
// //import { OrderTypeEnum } from "../models/OrderType";
// import { OLOResponse, OLOResponseData, OLOResponseBasketData } from "../models/OLOResponse";
// //import { CloudOrderResponse } from "../models/CloudOrderResponse";
// import { ItemV2, ModifierV2, ModifierIngredientV2 } from "../models/item";
// import { GeneralSetting } from "src/app/services/generalSetting.service";
// import { loggingData, LogService } from "src/app/services/log.service";
// import { Coupon, OLODiscountRequest } from "../models/OLODiscount";
// import { CustomFields } from "../models/BranchLoginModels";
// import { CustomField } from "../models/OLOProductReq";
// import { CloudOrderService } from "./cloud-order.service";
// import { Observable } from "rxjs";
} from '../models/OLOProductReq';
import { environment } from '../../environments/environment';
import { DatabaseHandler } from '../DatabaseHandler';
import { OloIntegration } from '../models/BranchLoginModels';
//import { OrderTypeEnum } from '../models/OrderType';
import { OLOResponse, OLOResponseData, OLOResponseBasketData } from '../models/OLOResponse';
import { ItemV2, ModifierV2, ModifierIngredientV2 } from '../models/item';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { Coupon, OLODiscountRequest } from '../models/OLODiscount';
import { CustomFields } from '../models/BranchLoginModels';
import { CustomField } from '../models/OLOProductReq';
import { LoyaltyType } from '../models/loyaltyModal';
import { CloudOrderService } from "./cloud-order.service";
import { CloudOrderPayment } from '../models/CloudOrderProductReq';
import { DataService } from './data.service';
import { SMBDiscount } from './smb-discount.service'
@Injectable({
  providedIn: 'root',
})
export class OLOService {
  validationRes = {} as OLOResponse; 

  orderRes = {} as OLOResponse;

  oloIntegrationTableData = {} as OloIntegration;

  isOLO: boolean = false;

  useCloudApi: boolean = true;

  isCalculationByOLO: boolean = false;

  apiUrl: string;

  constructor(
    private readonly http: HttpClient,
    private readonly logger: LogService,
    private readonly cloudOrder: CloudOrderService,
    private readonly dataService: DataService
  ) {
    this.apiUrl = environment.apiUrl;

    this.getOloIntegrationData()
      .toPromise()
      .then((data) => {
        if (data == null || data == undefined || data.length <= 0) {
          this.isOLO = false;
          return;
        }

        this.oloIntegrationTableData = data[0];
        this.isOLO = !(
          !this.oloIntegrationTableData.vendorID ||
          this.oloIntegrationTableData.vendorID == null ||
          this.oloIntegrationTableData.vendorID.trim() === ''
        );

        this.useCloudApi = this.oloIntegrationTableData.UseCloudApi;
      });
  }

  public async getOloValidation(
    cartItems: ItemV2[],
    customerName: string,
    customerEmail: string,
    totalTip: number,
    orderInvoiceNo: string,
    loyaltyType: number,
    paytronixRewardApplied: boolean,
    basketId?: string,
    auth: string = "",
    discount?:SMBDiscount
  ) : Promise<OLOResponse> {

    if(this.useCloudApi)
    {
      var customer = this.cloudOrder.GetCustomer(customerName, customerEmail);
      var cart = this.cloudOrder.GetCart(cartItems, totalTip, discount);
      var locationId = GeneralSetting.getBranchId();
      var resp = await this.cloudOrder.ValidateOrder(locationId, cart, customer, orderInvoiceNo);

      return {
        result: resp.result,
        message: resp.message,
        basketData : {
          deliverymode: resp.basketData.deliverymode,
          discount: resp.basketData.discount
        } as OLOResponseBasketData,
        data : {
          basketid : resp.orderId,
          total : resp.totals.total,
          subtotal : resp.totals.subtotal,
          tax : resp.totals.tax
        } as OLOResponseData,
      } as OLOResponse;
    }


    const url = this.apiUrl + "OrderValidateOLOAPI";
    const validationData = await this.getOloValidationData(
      cartItems,
      customerName,
      customerEmail,
      totalTip,
      orderInvoiceNo,
      auth,
      loyaltyType,
      paytronixRewardApplied
    );

    if (basketId && basketId != '') {
      validationData.basketId = basketId;
    }

    this.logger.sendLogToServer(
      new loggingData(
        'Olo Validation Request ',
        `Olo Validation Request OrderValidateOLOAPI
        Invoice No :- ${orderInvoiceNo}
      `,
        'Request Log',
        JSON.stringify(validationData),
        true
      )
    );

    return this.http
      .post<OLOResponse>(url, validationData)
      .toPromise<OLOResponse>();
  }

  public async submitOrderToOLO(
    customerName: string,
    customerEmail: string,
    orderInvoiceNo: string,
    orderToken: string,
    orderTotal: number,
    orderTip: number,
    paymentId: string,
    auth: string
  ) : Promise<OLOResponse> {

    if(this.useCloudApi)
    {
      var payment = new CloudOrderPayment();
      payment.paymentTypeId = Number(GeneralSetting.getPaymentTypeID());
      payment.thirdPartyPaymentTypeId = Number(GeneralSetting.getPaymentMethod());
      if(payment.paymentTypeId === 2) {
        payment.creditCardType = GeneralSetting.getCardType();
      }
      payment.thirdPartyPaymentId = paymentId;
      // Clover payment will create an order Id, we may need it for the Clover POS integration
      if(payment.thirdPartyPaymentTypeId == 13) {
        payment.thirdPartyOrderId = GeneralSetting.getThirdPartyOrderId();
      }


      var customer = this.cloudOrder.GetCustomer(customerName, customerEmail);
      var locationId = GeneralSetting.getBranchId();
      var resp = await this.cloudOrder.SubmitOrder(locationId, orderInvoiceNo, orderToken, payment, customer);

      return {
        result: resp.result,
        message: resp.message,
        oloid : resp.orderId,
        surcharge:resp.creditSurcharge / 100,
        data : {
          basketid : resp.orderId
        } as OLOResponseData,
      } as OLOResponse;
    }
    const url = this.apiUrl + "OrderPlaceOLOAPI";

    const orderPlaceData = await this.getOLOOrderUpData(
      customerName,
      customerEmail,
      orderInvoiceNo,
      auth
    );

    this.logger.sendLogToServer(
      new loggingData(
        'Olo Order Up Call',
        `Olo Submit order api call
        Invoice No :- ${orderInvoiceNo}
      `,
        'Request Log',
        JSON.stringify(orderPlaceData),
        true
      )
    );

    return this.http.post<OLOResponse>(url, orderPlaceData).toPromise();
  }

  getOloIntegrationData() {
    const query = `select * from oloIntegrations where branchId ='${GeneralSetting.getBranchId()}'`;

    return DatabaseHandler.getDataFromQuery<OloIntegration[]>(query);
  }

  private async getOloValidationData(
    cartItems: ItemV2[],
    customerName: string,
    customerEmail: string,
    totalTip: number,
    orderInvoiceNo: string,
    auth: string,
    loyaltyType: number,
    paytronixRewardApplied: boolean
  ) {
    const oloRewardsToApply = {
      membershipid: "0",
      references: [] as string[],
    } as OloRewardsToApply;

    const product = this.getValidationProductData(cartItems);
    const orderType: any =
      GeneralSetting.getOrderTypeId() == ''
        ? '0'
        : GeneralSetting.getOrderTypeId();
//    const orderType = OrderTypeEnum[orderTypeID].toString().toLocaleUpperCase();

    const validationData = {} as OloChainIdProductListReq;
    validationData.tip = totalTip.toString();

    validationData.authtoken = auth;
    validationData.basketId = this.validationRes.data
      ? this.validationRes.data.basketid
        ? this.validationRes.data.basketid
        : ''
      : '';
    validationData.customfields = await this.getCustomFields();
    validationData.deliverymode = orderType;
    validationData.emailaddress = customerEmail;
    validationData.firstname = this.getOloCustomerName(customerName);
    validationData.lastname = this.getCustomerLastName();
    validationData.invoice = orderInvoiceNo;
    validationData.oloRewardsToApply = oloRewardsToApply;
    validationData.products = product;
    validationData.vendorid = this.oloIntegrationTableData.vendorID;
    validationData.IsPaytronix =
      loyaltyType == LoyaltyType.OLOPaytronix && paytronixRewardApplied
        ? '1'
        : undefined;
    return validationData;
  }

  private async getCustomFields() {
    const allFiled = await this.getCustomFieldsFromSQl();
    const customFields: CustomField[] = [];

    for (let i = 0; i < allFiled.length; i++) {
      const field = allFiled[i];

      if (
        field.qualificationcriteria == 'DineInOrdersOnly' &&
        GeneralSetting.getOrderTypeId() == '1' &&
        GeneralSetting.getTableTentNumber() != ''
      ) {
        const customField: CustomField = {
          id: Number(field.id),
          value: GeneralSetting.getTableTentNumber(),
        };
        customFields.push(customField);
      }
    }

    return customFields;
  }

  private getSpecialRequestItem(specialRequest: string) {
    let sr: string;

    if (
      this.oloIntegrationTableData &&
      Object.entries(this.oloIntegrationTableData).length > 0 &&
      this.oloIntegrationTableData.ThirdPartyPrefixForAllItemSpecialRequests.toLowerCase() ==
        'true'
    ) {
      sr =
        (this.oloIntegrationTableData.PrefixText ?? '') +
        (specialRequest ? '_' + specialRequest : '');
    } else {
      sr = specialRequest ?? '';
    }

    return sr ?? '';
  }

  getValidationProductData(cartItems: ItemV2[]) {
    const listOLOProduct = [] as OLOChainProduct[];
    for (let index = 0; index < cartItems.length; index++) {
      const objCartItem = cartItems[index] as ItemV2;
      if (objCartItem.RefItemId == null || objCartItem.RefItemId == '')
        continue;
      const oloProduct = {} as OLOChainProduct;
      oloProduct.chainproductid = objCartItem.RefItemId;
      oloProduct.quantity = isNaN(Number(objCartItem.Quantity))
        ? 1
        : Number(objCartItem.Quantity);

      oloProduct.specialinstructions = this.getSpecialRequestItem(
        objCartItem.specialRequest
      );

      oloProduct.choices = [] as ChainChoice[];

      for (
        let index: number = 0;
        index < objCartItem.Modifiers.length;
        index++
      ) {
        const modCat = objCartItem.Modifiers[index];
        if (modCat.IsSelected) {
          const chainIngredient = this.getOloProductModifier(
            modCat.Ingredients
          );

          for (let CII = 0; CII < chainIngredient.length; CII++) {
            oloProduct.choices.push(chainIngredient[CII]);
          }
        }
      }

      //oloProduct.choices = this.getOloProductModifier(objCartItem.Mods);
      listOLOProduct.push(oloProduct);
    }

    return listOLOProduct;
  }

  private getOloProductModifier(mods: (ModifierV2 | ModifierIngredientV2)[]) {
    const listProjectModifier = [] as ChainChoice[];

    for (let index = 0; index < mods.length; index++) {
      const objMod = mods[index];

      if (objMod.IsSelected) {
        const objProjectModifier = {} as ChainChoice;

        objProjectModifier.quantity = isNaN(Number(objMod.Quantity))
          ? 1
          : Number(objMod.Quantity);
        objProjectModifier.choices = [] as ChainChoice[];

        if (objMod.IsIngredient) {
          const ing = objMod as ModifierIngredientV2;

          if (ing.RefIngredientId == null || ing.RefIngredientId == '')
            continue;
          objProjectModifier.chainchoiceid = ing.RefIngredientId;
          objProjectModifier.choices = [] as ChainChoice[];

          listProjectModifier.push(objProjectModifier);
        } else {
          const modifier = objMod as ModifierV2;

          if (modifier.RefModifierId == null || modifier.RefModifierId == '')
            continue;

          if (modifier.isOloOptionGroup.toLowerCase() != 'true') {
            objProjectModifier.chainchoiceid = modifier.RefModifierId;
            objProjectModifier.choices = this.getOloIngredientOfModifier(
              modifier.Ingredients
            );

            listProjectModifier.push(objProjectModifier);
          } else {
            const ingredientChoices = this.getOloIngredientOfModifier(
              modifier.Ingredients
            );

            for (let i = 0; i < ingredientChoices.length; i++) {
              listProjectModifier.push(ingredientChoices[i]);
            }
          }
        }
      }
    }

    return listProjectModifier;
  }

  private getOloIngredientOfModifier(
    mods: (ModifierV2 | ModifierIngredientV2)[]
  ) {
    const listProjectModifier = [] as ChainChoice[];

    mods = mods.filter((x) => x.IsSelected);

    for (let index = 0; index < mods.length; index++) {
      const objMod = mods[index];

      if (objMod.IsIngredient) {
        const ing = objMod as ModifierIngredientV2;

        const objProjectModifier = {} as ChainChoice;

        objProjectModifier.quantity = isNaN(Number(ing.Quantity))
          ? 1
          : Number(ing.Quantity);
        objProjectModifier.choices = [] as ChainChoice[];

        if (ing.RefIngredientId == null || ing.RefIngredientId == '') continue;
        objProjectModifier.chainchoiceid = ing.RefIngredientId;

        listProjectModifier.push(objProjectModifier);
      } else {
        const modifier = objMod as ModifierV2;

        if (modifier.isOloOptionGroup.toLowerCase() != 'true') {
          const objProjectModifier = {} as ChainChoice;

          if (modifier.RefModifierId == null || modifier.RefModifierId == '')
            continue;
          objProjectModifier.chainchoiceid = modifier.RefModifierId;

          objProjectModifier.quantity = isNaN(Number(modifier.Quantity))
            ? 1
            : Number(modifier.Quantity);
          objProjectModifier.choices = this.getOloIngredientOfModifier(
            modifier.Ingredients
          ) as ChainChoice[];

          listProjectModifier.push(objProjectModifier);
        } else {
          const choices = this.getOloIngredientOfModifier(
            modifier.Ingredients
          ) as ChainChoice[];

          for (let i = 0; i < choices.length; i++) {
            listProjectModifier.push(choices[i]);
          }
        }
      }
    }
    return listProjectModifier;
  }

  private getOloCustomerName(customerName: string) {
    let name: string = '';
    const orderType: any =
      GeneralSetting.getOrderTypeId() == ''
        ? '0'
        : GeneralSetting.getOrderTypeId();
    //const orderType = OrderTypeEnum[orderTypeID].toString().toLocaleUpperCase();
    const orderPrefix = GeneralSetting.getOrderPrefix();

    if (orderPrefix && orderPrefix != '') {
      name += orderPrefix + '-';
    }

    if(customerName != "" && customerName.toLowerCase() == "guest") {
      customerName = "";
    }

    let guestNameType = this.oloIntegrationTableData.CustomerGuestName ? this.oloIntegrationTableData.CustomerGuestName : "GT";

    if (
      !this.oloIntegrationTableData ||
      Object.entries(this.oloIntegrationTableData).length <= 0
    ) {
      if (customerName == "") {
        name += orderType + "-" + guestNameType;
      } else {
        name += orderType + '-' + customerName;
      }
      return name;
    }

    if (customerName == "") {
      if (
        this.oloIntegrationTableData.IsAppendOrderTypeName &&
        this.oloIntegrationTableData.IsAppendOrderTypeName.toLowerCase() ==
          'true'
      ) {
        if (this.oloIntegrationTableData.CustomerNamePrefix == "") {
          name += orderType + "-" + guestNameType;
        } else {
          name += this.oloIntegrationTableData.CustomerNamePrefix + orderType + "-" + guestNameType;
        }
      } else {
        if (this.oloIntegrationTableData.CustomerNamePrefix == "") {
          name += guestNameType;
        } else {
          name += this.oloIntegrationTableData.CustomerNamePrefix + "-" + guestNameType;
        }
      }
    } else {
      if (
        this.oloIntegrationTableData.IsAppendOrderTypeName &&
        this.oloIntegrationTableData.IsAppendOrderTypeName.toLowerCase() ==
          'true'
      ) {
        if (this.oloIntegrationTableData.CustomerNamePrefix == '') {
          name += orderType + '-' + customerName;
        } else {
          name +=
            this.oloIntegrationTableData.CustomerNamePrefix +
            orderType +
            '-' +
            customerName;
        }
      } else {
        if (this.oloIntegrationTableData.CustomerNamePrefix == '') {
          name += customerName;
        } else {
          name +=
            this.oloIntegrationTableData.CustomerNamePrefix +
            '-' +
            customerName;
        }
      }
    }
    //}
    if(name == "") {
      name = "Guest";
    }

    return name;
  }

  private getCustomerLastName() {
    let name = '-';

    if (
      this.oloIntegrationTableData.IsShowTableTentPrefix.toLowerCase() == 'true'
    ) {
      name += GeneralSetting.getTableTentNumber();
    }

    return name;
  }

  private async getOLOOrderUpData(
    customerName: string,
    customerEmail: string,
    orderInvoiceNo: string,
    auth: string = ''
  ) {
    const objOLOOrderData = {} as OloProductListReq;
    objOLOOrderData.authtoken = auth;
    objOLOOrderData.basketId = this.validationRes.data.basketid;
    objOLOOrderData.customfields = await this.getCustomFields();
    objOLOOrderData.emailaddress = customerEmail;
    objOLOOrderData.firstname = this.getOloCustomerName(customerName);
    objOLOOrderData.invoice = orderInvoiceNo;

    objOLOOrderData.lastname = this.getCustomerLastName();

    objOLOOrderData.punchh_authentication_token = '';
    objOLOOrderData.deliverymode =
      this.validationRes.basketData.deliverymode.toUpperCase();
    objOLOOrderData.usertype = auth != '' ? 'user' : 'guest';
    objOLOOrderData.vendorid = this.oloIntegrationTableData.vendorID;

    return objOLOOrderData;
  }

  doApplyDiscount(
    basketId: string,
    code: string,
    isApplied: string,
    orderInvoiceNo: string
  ) {
    const url = this.apiUrl + 'CouponValidateOLOAPI';

    const coupon = {} as Coupon;
    coupon.code = code;
    coupon.secondarycode = '';
    coupon.promotiontype = 'coupon';

    const oloDiscountReq = {} as OLODiscountRequest;
    oloDiscountReq.authtoken = '';
    oloDiscountReq.basketid = basketId;
    oloDiscountReq.deliverymode = 'PICKUP';
    oloDiscountReq.vendorid = '125339';
    oloDiscountReq.IsApplied = isApplied;
    oloDiscountReq.coupons = coupon;

    this.logger.sendLogToServer(
      new loggingData(
        'Olo Discount Request',
        `OLO Discount Request CouponValidateOLOAPI
        Invoice No :- ${orderInvoiceNo ?? ''}
      `,
        'Request Log',
        JSON.stringify(oloDiscountReq),
        true
      )
    );

    return this.http.post<OLOResponse>(url, oloDiscountReq).toPromise();
  }

  private getCustomFieldsFromSQl() {
    return new Promise<CustomFields[]>((resolve: any) => {
      const getModifier = `
        select * from customFields
      `;

      const logError = () => {
        resolve([] as CustomFields[]);
      };

      const setItems = (transaction: String, results: any) => {
        if (results && results.rows.length > 0) {
          resolve(results.rows as CustomFields[]);
        } else {
          resolve([] as CustomFields[]);
        }
      };

      DatabaseHandler.executeSqlStatement(getModifier, [], setItems, logError);
    });
  }
  private validationCheck(validationRes: OLOResponse, orderInvoiceNo: string, isValidation: boolean) {
    validationRes = validationRes as OLOResponse;
    if (validationRes.result.toLowerCase() != 'success') {
      if (
        validationRes.result.toLowerCase() == 'fail' ||
        validationRes.result.toLowerCase() == 'error'
      ) {
        if (!validationRes.message.includes(
          'Thanks for the kind thought'
        ) && !validationRes.message.includes('is currently closed')) {
          this.sendErrorEmail({
            apiFailed: false,
            resData: validationRes,
            Error: validationRes.message,
            orderInvoiceNo: orderInvoiceNo,
            isValidation: isValidation
          });
        }
      }
      else {
        this.sendErrorEmail({
          apiFailed: false,
          resData: validationRes,
          Error: validationRes.message,
          orderInvoiceNo: orderInvoiceNo,
          isValidation: isValidation
        });
      }
    }
    return validationRes;
  }
   public async sendErrorEmail(req: {
    apiFailed: boolean,
    resData: any,
    Error: string,
    orderInvoiceNo: string,
    isValidation: boolean,
  }) {
    let apiName = req.isValidation ? "OrderValidateOLOAPI" : "OrderCreateOLOAPI";
    let subject = req.isValidation ? "OLO Order Validation Failed" : "OLO Order Failed";
    let appVersion = environment.Version;
    let deviceId = GeneralSetting.getSerialNo() == null ? '1' : GeneralSetting.getSerialNo();
    let branchId = GeneralSetting.getBranchId();
    let companyId = GeneralSetting.getCompanyId();
    let emailTemplate = `
      <html> 
        <body>
          <h1> OLO Response Critical Error </h1>
          <h3> Api Name :- ${apiName} </h2>
          <h3> Device ID / Serial No :- ${deviceId} </h2>
          <h3> BranchId :- ${branchId} </h2>
          <h3> Company Id :- ${companyId} </h2>
          <h3> App Version :- ${appVersion} </h2>
          <h3> Envirnment :- ${environment.production ? "Portal " : "Staging"} </h2>
          <h3> Order Numebr :- ${req.orderInvoiceNo} </h2>
          <h3> ${req.apiFailed ? 'Api Failed' : ''} </h3>
          <h3> Error :-  ${req.Error} </h3>
          <h3> Response Data :- ${JSON.stringify(req.resData)} </h3>
        </boby>
      </html>
    `;
    this.dataService.sendEmailError({
      ToEmail: "samsungteam@grubbrr.com",
      Email: "samsungteam@grubbrr.com",
      Message: emailTemplate,
      Subject: "OLO Order Error",
      ToName: "Grubbrr"
    });
    this.dataService.sendEmailError({
      ToEmail: "dotnetteam@grubbrr.com",
      Email: "dotnetteam@grubbrr.com",
      Message: emailTemplate,
      Subject: subject,
      ToName: "Grubbrr"
    });
  }
}
