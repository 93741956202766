import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { LogService } from 'src/app/services/log.service';
import { StoreTime } from 'src/app/models/storeTime';
import { DatabaseHandler } from '../DatabaseHandler';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { CommonFunctions } from './common';

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  storeTime: StoreTime[]=[];
  enterTime: StoreTime = {
    start: '',
    end: '',
    dayId : 0,
  };

  constructor(private logger: LogService) {}

  getStoreTimes(): Observable<StoreTime[]> {
    return from(this.getStoreTimesFromSql());
  }

  getStoreTimesFromSql(): Promise<StoreTime[]> {
    return new Promise((resolve, reject) => {
      const clone = (time: StoreTime): StoreTime => {
        GeneralSetting.setStartTime(time.start.toString());
        GeneralSetting.setEndTime(time.end.toString());

        return {
          start: time.start,
          end: time.end,
          dayId : time.dayId,
        };
      };

      const errorCallback = (tx: any, result: any): void => {
        console.log('Store Time Service, Error getStoreTimesFromSql :- ', result);
        reject();
      };

      const DataCallback = (tx: any, result: any): void => {
        if(result.rows != null && result.rows != undefined && result.rows.length > 0){
          for (var i = 0; i < result.rows.length; i++) {
            //Added to check/manage timing for multi scheduling | 24hrs timing 
            var startTime =  result.rows[i].StartTime;
            var endTime =  result.rows[i].EndTime;
            var dayId = Number(result.rows[i].DayID);
            if(startTime < endTime){
              this.storeTime.push({start: startTime,end: endTime,dayId : dayId});
            }else{
              this.storeTime.push({start: startTime,end: "23:59:59.9990000",dayId : dayId});

              this.storeTime.push({start: "00:00:00.0000000",end: endTime, dayId : (dayId == 7) ? 1 : (dayId + 1)});
            }
          }
        }
        resolve(this.storeTime);
      };

      var sqlString = `Select * FROM GeneralWeekDayMasters WHERE IsActive='True' ORDER BY DayID`;
      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        DataCallback,
        errorCallback
      );
    });
  }

  clearStoreTime(){
    this.storeTime = [];
  }
}
