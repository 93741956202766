<div class="item-bowl">
    <div class="header">
        <h1>Large Bol</h1>
        <p>Build your own Bol with our fresh and bold menu item! </p>
        <span>SMALL BOL $8.49</span>
    </div>
    <div class="bol-size">
        <div class="box">
            <img src="../../../assets/images/bowl-size.svg">
        </div>
        <h2>Bol Size</h2>
    </div>
    <div class="box-size-cat">
        <p class="req">Required. <span>Choose Any 1</span></p>
        <div class="cat-box" (click)="open()">
            <div class="cat-img">
                <img src="../../../assets/images/Image_21.png">
            </div>
            <div class="cat-name">
                Small Bol
            </div>
            <div class="cat-price">
                <h5><span>Start at</span>$8.29</h5>
            </div>
        </div>
        <div class="cat-box">
            <div class="cat-img">
                <img src="../../../assets/images/Image_21.png">
            </div>
            <div class="cat-name">
                Large Bol
            </div>
            <div class="cat-price">
                <h5><span>Start at</span>$10.99</h5>
            </div>
        </div>
        <div class="cat-box">
            <div class="cat-img">
                <img src="../../../assets/images/Image_21.png">
            </div>
            <div class="cat-name">
                Kids Bol
            </div>
            <div class="cat-price">
                <h5><span>Start at</span>$5.99</h5>
            </div>
        </div>
    </div>
    <app-item-footer class="footer">
    </app-item-footer>
</div>