<div class="fiter-modal">
    <div class="header">
        <button class="btn btn-primary close-btn" (click)="cancel()"><svg width="46" height="46" viewBox="0 0 34 34"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.8278 33.6663C26.0325 33.6663 33.4945 26.2044 33.4945 16.9997C33.4945 7.79493 26.0325 0.333008 16.8278 0.333008C7.62305 0.333008 0.161133 7.79493 0.161133 16.9997C0.161133 26.2044 7.62305 33.6663 16.8278 33.6663Z"
                    style="fill:{{secondaryColor}}" />
                <path d="M21.8281 12L11.8281 22" style="stroke:{{positiveButtonText}}" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.8281 12L21.8281 22" style="stroke:{{positiveButtonText}}" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </button>
    </div>
    <div class="filter-body">


        <h1>{{cuisineTypeText}}</h1>
        <div class="filter-blocks">
            <p id={{con.ConcessionaireTypeID}} class="{{con.isSelected ? 'active' : ''}}"
                *ngFor="let con of concessionaireFilterTypes"
                (click)="onConcessionaireTypeSelection(con.ConcessionaireTypeID, !con.isSelected, $event)">
                {{con.Name}}

                <button class="btn btn-primary"
                    (click)="onConcessionaireTypeSelection(con.ConcessionaireTypeID, false, $event)"><svg width="34"
                        height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.8278 33.6663C26.0325 33.6663 33.4945 26.2044 33.4945 16.9997C33.4945 7.79493 26.0325 0.333008 16.8278 0.333008C7.62305 0.333008 0.161133 7.79493 0.161133 16.9997C0.161133 26.2044 7.62305 33.6663 16.8278 33.6663Z"
                            style="fill:{{primaryColor}}" />
                        <path d="M21.8281 12L11.8281 22" style="stroke:{{positiveButtonText}}" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M11.8281 12L21.8281 22" style="stroke:{{positiveButtonText}}" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>
            </p>
        </div>

        <ng-container *ngFor="let itemTagGroup of itemTagsAndGroupFilters">
            <h1>{{itemTagGroup.ItemTagGroupName}}</h1>
            <div class="filter-blocks">
                <ng-container *ngFor="let itemTags of itemTagGroup.FilterItemTagGroupMapping">
                    <p id={{itemTags.FilterID}} class="{{itemTags.isSelected ? 'active' : ''}}"
                        (click)="onConcessionaireTypeSelection(itemTags.FilterID, true, $event, true)">
                        {{itemTags.FilterName}} <button class="btn btn-primary"
                            (click)="onConcessionaireTypeSelection(itemTags.FilterID, false, $event, true)">
                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16.8278 33.6663C26.0325 33.6663 33.4945 26.2044 33.4945 16.9997C33.4945 7.79493 26.0325 0.333008 16.8278 0.333008C7.62305 0.333008 0.161133 7.79493 0.161133 16.9997C0.161133 26.2044 7.62305 33.6663 16.8278 33.6663Z"
                                    style="fill:{{primaryColor}}" />
                                <path d="M21.8281 12L11.8281 22" style="stroke:{{positiveButtonText}}" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.8281 12L21.8281 22" style="stroke:{{positiveButtonText}}" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button></p>
                </ng-container>
            </div>
        </ng-container>
    </div>

    <div class="footer-btn">
        <button class="btn btn-warning" (click)="apply()">
            {{applyText|uppercase}}
        </button>
        <button class="btn btn-default" (click)="cancel()">
            {{cancelText|uppercase}}
        </button>
    </div>
</div>