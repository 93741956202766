import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-error-msg-popup',
  templateUrl: './error-msg-popup.component.html',
  styleUrls: ['./error-msg-popup.component.css'],
})
export class ErrorMsgPopupComponent implements OnInit,OnDestroy {
  languageSub!: Subscription;
  errorMsg: string = '';

  oopsText: string = '';
  okText: string = '';

  modalReference: any;

  constructor(
    public modalService: NgbModal,
    private language: LanguageService
  ) {}
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {
    this.oopsText = this.language.getTextElement('alert_title_opps');
    this.okText = this.language.getTextElement('lbl_ok');
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  open(errorMsg: any) {
    this.modalReference = this.modalService.open(ErrorMsgPopupComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    this.modalReference.componentInstance.modalReference = this.modalReference;

    this.modalReference.componentInstance.errorMsg = errorMsg;
  }

  close() {
    this.modalReference.close();
  }
}
