<div id="upsell-popup">
  <div class="item86-modal">
    <h1 class="text-center">{{uhOhText}}</h1>
    <div class="custom-message">
      {{concessionaireClosedText}}
    </div>
    <div>
      <div class="scroll-item-box">
        <div class="row scroll-item">
          <div class="items" *ngFor="let con of removedConcessionaire">
            <img src="{{ con | functionCall : getConcessionaireLogo : baseImageUrl : concessionaireBaseUrl }}"
                 onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
                 class="img-fluid" />
            <h2 class="item-name">{{ con.Name }}</h2>

          </div>
        </div>
      </div>
    </div>
    <div class="popup-footer text-center">
      <button class="background-primary-color bold" (click)='removeFromCart()'>{{okText}}</button>
    </div>
  </div>
</div>
