import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { loggingData, LogService } from 'src/app/services/log.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { HttpConnection } from 'src/app/services/http.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { IHttpHeaders } from 'src/app/services/http.service';
import { Concessionaire } from '../models/Concessionaire';
import { HealthLogRequest } from './api/Models';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  baseUrl: string = environment.apiUrl;
  branchLoginURL: string = 'BranchLoginV2';
  registerDeviceURL: string = 'DeviceRegisterV2';
  SMSserviceURL: string = 'SendTextMessage';
  ZreportURL: string = 'POSZReportSK';
  EmailURL: string = 'EmailSendSK';
  getCustomerByMobileNoUrl = 'GetCustomerByMobileNo';
  orderhistoryByid = 'OrderByCustomerIDSK';
  getMenuURL: string = 'GetMenu';
  itemDisableURL: string = 'ItemsDisableSK';
  LookupMemberByID: string = 'LookupMemberByIDSK';
  sendAlcoholItemNotificationURL: string = 'SendNotification';
  updateDeviceDetails: string = 'DeviceDetailsUpdate';
  healthLog: string = 'HealthLog';
  installationACK: string = 'installation-ack';

  username: string = '';
  password: string = '';
  dataDate: Date = new Date(0);
  isKiosk: boolean = true;
  placeholderImage: string = '';

  constructor(
    private http: HttpClient,
    private logger: LogService,
    isKioskService: IsKioskService
  ) {
    if (
      GeneralSetting.getPlaceHolderImageFlag() &&
      GeneralSetting.getPlaceholderImage() != ''
    ) {
      this.placeholderImage = GeneralSetting.getPlaceholderImage();
    }
    this.isKiosk = isKioskService.isKiosk();
  }

  getZreport(startDate: string, endDate: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('CompanyID', GeneralSetting.getCompanyId())
      .set('BranchID', GeneralSetting.getBranchId())
      .set('DeviceID', GeneralSetting.getSerialNo());
    const body = new HttpParams()
      .set('StartDate', startDate)
      .set('EndDate', endDate);

    return this.http
      .post(this.baseUrl + this.ZreportURL, body, { headers })
      .pipe(catchError(this.errorHandler));
  }

  /**
   * Post branch log-in info to server
   * including username/password/AppVersion/DeviceType/DeviceID/ModuleID/FCMID
   * @param username
   * @param password
   * @returns POST request response from server
   */
  branchLogin(username: string, password: string, deviceId: string) {
    this.dataDate = new Date();
    localStorage.setItem('syncDate', this.dataDate.toLocaleString());
    this.username = username;
    this.password = password;

    var headers = [] as IHttpHeaders[];
    headers.push({ key: 'Version', value: '1.0' } as IHttpHeaders);
    headers.push({
      key: 'Content-Type',
      value: 'application/json',
    } as IHttpHeaders);

    var body = {
      Username: username,
      Password: password,
      AppVersion: environment.Version,
      DeviceType: '1',
      DeviceID: deviceId,
      ModuleID: '2',
      FCMID: 'Pusher',
      DeviceName: 'Samsung Kiosk',
      AppID: GeneralSetting.getSerialNo(),
    };

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(this.baseUrl + this.branchLoginURL)
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post();
  }

  /**
   * refresh the branch devide info and post to the server
   * @returns POST request response from server
   */
  refreshData() {
    this.dataDate = new Date();
    localStorage.setItem('syncDate', this.dataDate.toLocaleString());
    this.username = GeneralSetting.getBranchLoginUserId();
    this.password = GeneralSetting.getBranchLoginPass();

    var headers = [] as IHttpHeaders[];
    headers.push({ key: 'Version', value: '1.0' } as IHttpHeaders);
    headers.push({
      key: 'Content-Type',
      value: 'application/Json',
    } as IHttpHeaders);

    var body = {
      Username: this.username,
      Password: this.password,
      AppVersion: environment.Version,
      DeviceType: '1',
      DeviceID: GeneralSetting.getSerialNo(),
      ModuleID: '2',
      FCMID: 'Pusher',
      DeviceName: 'Samsung Kiosk',
      AppID: GeneralSetting.getSerialNo(),
    };

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(this.baseUrl + this.branchLoginURL)
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post();
  }

  /**
   * Register the new device and post the info to the server
   * @param username
   * @param password
   * @param deviceId
   * @param moduleId
   * @returns
   */
  registerDevice(
    username: string,
    password: string,
    deviceId: string,
    moduleId: string,
    deviceName: string
  ) {
    var headers = [] as IHttpHeaders[];
    headers.push({
      key: 'Content-Type',
      value: 'application/Json',
    } as IHttpHeaders);

    const body = {
      ModuleID: moduleId,
      DeviceType: '1',
      FCMID: 'Pusher',
      Username: username,
      DeviceID: deviceId,
      Password: password,
      DeviceName: deviceName,
    };

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(this.baseUrl + this.registerDeviceURL)
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post();
  }

  /**
   * Update device info and post the info to the server
   * @returns
   */
  updateDevice() {
    var headers = [] as IHttpHeaders[];
    headers.push({ key: 'Version', value: '1.0' } as IHttpHeaders);
    headers.push({
      key: 'Content-Type',
      value: 'application/Json',
    } as IHttpHeaders);
    headers.push({
      key: 'CompanyID',
      value: GeneralSetting.getCompanyId(),
    } as IHttpHeaders);
    headers.push({
      key: 'BranchID',
      value: GeneralSetting.getBranchId(),
    } as IHttpHeaders);
    headers.push({
      key: 'DeviceID',
      value: GeneralSetting.getSerialNo(),
    } as IHttpHeaders);

    const body = {
      HSN: '',
      ModelName: GeneralSetting.getDeviceModel(),
      DeviceID: GeneralSetting.getSerialNo(),
      ScannerID: '',
      MerchantID: '',
      MacAddress: GeneralSetting.getMacAddress(),
      UseBarcodeScanner:
        GeneralSetting.getUseBarcodeScanner() != ''
          ? GeneralSetting.getUseBarcodeScanner()
          : 'False',
      Name:
        GeneralSetting.getDeviceName() != ''
          ? GeneralSetting.getDeviceName()
          : 'Samsung Kiosk',
      WifiName: GeneralSetting.getWifi(),
      AppVersion: environment.Version,
      BrandName: GeneralSetting.getBrandName(),
      FCMID: '',
      ModuleID: '2',
      EnableScanOrSelectHybridMode: 'False',
      StatusDate: new Date(),
      StaffModuleID: GeneralSetting.getStaffModuleID(),
      OSVersion: GeneralSetting.getOsversion(),
      StatusId: '1',
      IpAddress: GeneralSetting.getIpAddress(),
      Notes: 'Device Connected',
      DeviceTypeID: '1',
    };

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(this.baseUrl + this.updateDeviceDetails)
      .addMethod('POST')
      .addHeader(headers)
      .addBody([body])
      .post();
  }

  sendCustomerText(phoneNumber: string, message: string) {
    var headers = [] as IHttpHeaders[];
    headers.push({ key: 'Version', value: '1.0' } as IHttpHeaders);
    headers.push({
      key: 'Content-Type',
      value: 'application/Json',
    } as IHttpHeaders);
    headers.push({
      key: 'CompanyID',
      value: GeneralSetting.getCompanyId(),
    } as IHttpHeaders);
    headers.push({
      key: 'BranchID',
      value: GeneralSetting.getBranchId(),
    } as IHttpHeaders);
    headers.push({
      key: 'DeviceID',
      value: GeneralSetting.getSerialNo(),
    } as IHttpHeaders);

    const body = {
      PhoneNo: phoneNumber,
      MessageText: message,
    };

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(this.baseUrl + this.SMSserviceURL)
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post();
  }

  /**
   * error handler
   * @param error
   * @returns error message
   */
  errorHandler(error: HttpErrorResponse) {
    var log = new loggingData(
      'Data Error',
      'Encountered Error on Data Fetch',
      `Error`,
      `Encountered Error: ${error}`,
      true
    );
    this.logger.sendLogToServer(log);
    return throwError(error.message);
  }

  /**
   * error handler
   * @param error
   * @returns error
   */
  errorResponse(error: HttpErrorResponse) {
    return throwError(error);
  }

  submitOrder(orderData: any) {
    if (
      orderData &&
      orderData.OrderMaster &&
      orderData.OrderMaster.length > 0
    ) {
      orderData.OrderMaster.forEach(
        (element: { OrderSource: string; TransactionID: any }) => {
          element.OrderSource = 'Kiosk';
          if (
            element.TransactionID == '-undefined' ||
            element.TransactionID == 'undefined' ||
            element.TransactionID == undefined
          ) {
            element.TransactionID = '';
          }
        }
      );
    }

    var log = new loggingData(
      'Order Data',
      'Order Data Sending to Backend',
      `Order to Check TransactionId`,
      `Order Data: ${orderData}`,
      false
    );
    this.logger.sendLogToServer(log);

    const branchId =
      GeneralSetting.getBranchId() == '' ? '0' : GeneralSetting.getBranchId();
    const companyId =
      GeneralSetting.getCompanyId() == '' ? '0' : GeneralSetting.getCompanyId();

    const myHeaders = new Headers();
    myHeaders.append('Version', '1.0');
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('CompanyID', companyId);
    myHeaders.append('BranchID', branchId);
    myHeaders.append('DeviceID', GeneralSetting.getSerialNo());

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(orderData),
      redirect: 'follow' as RequestRedirect,
    };
    return fetch(this.baseUrl + 'OrderUpSK', requestOptions);
  }

  async submitOrderAsync(orderData: any) {
    if (
      orderData &&
      orderData.OrderMaster &&
      orderData.OrderMaster.length > 0
    ) {
      orderData.OrderMaster.forEach(
        (element: { OrderSource: string; TransactionID: any }) => {
          element.OrderSource = 'Kiosk';
          if (
            element.TransactionID == '-undefined' ||
            element.TransactionID == 'undefined' ||
            element.TransactionID == undefined
          ) {
            element.TransactionID = '';
          }
        }
      );
    }

    var log = new loggingData(
      'Order Data',
      'Order Data Sending to Backend',
      `Order to Check TransactionId`,
      `Order Data: ${orderData}`,
      false
    );
    this.logger.sendLogToServer(log);

    const branchId =
      GeneralSetting.getBranchId() == '' ? '0' : GeneralSetting.getBranchId();
    const companyId =
      GeneralSetting.getCompanyId() == '' ? '0' : GeneralSetting.getCompanyId();

    const myHeaders = new Headers();
    myHeaders.append('Version', '1.0');
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('CompanyID', companyId);
    myHeaders.append('BranchID', branchId);
    myHeaders.append('DeviceID', GeneralSetting.getSerialNo());

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(orderData),
      redirect: 'follow' as RequestRedirect,
    };
    return await fetch(this.baseUrl + 'OrderUpSK', requestOptions);
  }
  sendEmail(userEmail: string, userName: string, emailBody: string = 'empty') {
    const branchId =
      GeneralSetting.getBranchId() == '' ? '0' : GeneralSetting.getBranchId();
    const companyId =
      GeneralSetting.getCompanyId() == '' ? '0' : GeneralSetting.getCompanyId();

    const strDeviceId = GeneralSetting.getSerialNo();
    const deviceId = strDeviceId == null ? '1' : strDeviceId;

    const orderInvoiceNo =
      GeneralSetting.getInvoiceNoToDb() == ''
        ? '0'
        : GeneralSetting.getInvoiceNoToDb();

    var headers = [] as IHttpHeaders[];
    headers.push({ key: 'Version', value: '1.0' } as IHttpHeaders);
    headers.push({
      key: 'Content-Type',
      value: 'application/Json',
    } as IHttpHeaders);
    headers.push({ key: 'CompanyID', value: companyId } as IHttpHeaders);
    headers.push({ key: 'BranchID', value: branchId } as IHttpHeaders);
    headers.push({ key: 'DeviceID', value: deviceId } as IHttpHeaders);

    const body = {
      ToEmail: userEmail,
      ToName: userName,
      Subject: 'Your Receipt',
      Message: emailBody,
      Email: userEmail,
      OrderInvoiceNo: orderInvoiceNo,
    };

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(this.baseUrl + this.EmailURL)
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post();
  }

  async sendEmailError(req: {
    ToEmail: string;
    ToName: string;
    Subject: string;
    Message: string;
    Email: string;
  }) {
    const branchId = GeneralSetting.getBranchId();
    const companyId = GeneralSetting.getCompanyId();
    const deviceId =
      GeneralSetting.getSerialNo() == null ? '1' : GeneralSetting.getSerialNo();

    var headers = [] as IHttpHeaders[];
    headers.push({ key: 'Version', value: '1.0' } as IHttpHeaders);
    headers.push({
      key: 'Content-Type',
      value: 'application/Json',
    } as IHttpHeaders);
    headers.push({ key: 'CompanyID', value: companyId } as IHttpHeaders);
    headers.push({ key: 'BranchID', value: branchId } as IHttpHeaders);
    headers.push({ key: 'DeviceID', value: deviceId } as IHttpHeaders);

    const body = {
      ToEmail: req.ToEmail,
      ToName: req.ToName,
      Subject: req.Subject,
      Message: req.Message,
      Email: req.Email,
    };

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(this.baseUrl + this.EmailURL)
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post()
      .toPromise()
      .then();
  }

  getCustomerByMobileNo(mobileNo: string) {
    const branchId =
      GeneralSetting.getBranchId() == '' ? '0' : GeneralSetting.getBranchId();
    const companyId =
      GeneralSetting.getCompanyId() == '' ? '0' : GeneralSetting.getCompanyId();

    var headers = [] as IHttpHeaders[];
    headers.push({ key: 'Version', value: '1.0' } as IHttpHeaders);
    headers.push({
      key: 'Content-Type',
      value: 'application/Json',
    } as IHttpHeaders);
    headers.push({ key: 'CompanyID', value: companyId } as IHttpHeaders);
    headers.push({ key: 'BranchID', value: branchId } as IHttpHeaders);

    const body = {
      MobileNo: mobileNo,
    };

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(this.baseUrl + this.getCustomerByMobileNoUrl)
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post();
  }

  getOrderHistoryByCustomerID(customerID: string) {
    const branchId =
      GeneralSetting.getBranchId() == '' ? '0' : GeneralSetting.getBranchId();
    const companyId =
      GeneralSetting.getCompanyId() == '' ? '0' : GeneralSetting.getCompanyId();

    var headers = [] as IHttpHeaders[];
    headers.push({ key: 'Version', value: '1.0' } as IHttpHeaders);
    headers.push({
      key: 'Content-Type',
      value: 'application/Json',
    } as IHttpHeaders);
    headers.push({ key: 'CompanyID', value: companyId } as IHttpHeaders);
    headers.push({ key: 'BranchID', value: branchId } as IHttpHeaders);
    headers.push({ key: 'PageNo', value: '1' } as IHttpHeaders);

    const body = {
      CustomerID: customerID,
    };

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(this.baseUrl + this.orderhistoryByid)
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post();
  }

  /**
   *
   * @param itemID update status of item for given ID
   * @param status status of item, true for enable and false for disable
   * @returns
   */
  updateItemStatus(itemID: string, status: boolean) {
    var headers = [] as IHttpHeaders[];

    headers.push({
      key: 'Content-Type',
      value: 'application/json',
    } as IHttpHeaders);
    headers.push({
      key: 'CompanyID',
      value: GeneralSetting.getCompanyId(),
    } as IHttpHeaders);
    headers.push({
      key: 'BranchID',
      value: GeneralSetting.getBranchId(),
    } as IHttpHeaders);

    const body = {
      ItemID: itemID,
      Status: status,
    };

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(this.baseUrl + this.itemDisableURL)
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post();
  }

  getMemberFromIDCard(memberId: string) {
    var headers = [] as IHttpHeaders[];

    headers.push({
      key: 'Content-Type',
      value: 'application/json',
    } as IHttpHeaders);
    headers.push({ key: 'Accept', value: 'application/json' } as IHttpHeaders);
    headers.push({
      key: 'CompanyID',
      value: GeneralSetting.getCompanyId(),
    } as IHttpHeaders);
    headers.push({
      key: 'BranchID',
      value: GeneralSetting.getBranchId(),
    } as IHttpHeaders);

    const body = {
      MemberID: memberId,
      CompanyID: GeneralSetting.getCompanyId(),
    };

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(this.baseUrl + this.LookupMemberByID)
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post();
  }
  updateConcessionaireSettings(
    conList: Concessionaire[],
    isDefaultCon: boolean,
    DefaultConId: string
  ) {
    const branchId =
      GeneralSetting.getBranchId() == '' ? '0' : GeneralSetting.getBranchId();
    const companyId =
      GeneralSetting.getCompanyId() == '' ? '0' : GeneralSetting.getCompanyId();
    const strDeviceId = GeneralSetting.getSerialNo();
    const deviceId = strDeviceId == null ? '1' : strDeviceId;

    var headers = [] as IHttpHeaders[];
    headers.push({ key: 'Version', value: '1.0' } as IHttpHeaders);
    headers.push({
      key: 'Content-Type',
      value: 'application/Json',
    } as IHttpHeaders);
    headers.push({ key: 'CompanyID', value: companyId } as IHttpHeaders);
    headers.push({ key: 'BranchID', value: branchId } as IHttpHeaders);
    headers.push({ key: 'DeviceID', value: deviceId } as IHttpHeaders);

    const body = this.createConcessionaireSettingBody(
      conList,
      isDefaultCon,
      DefaultConId
    );

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(this.baseUrl + 'ConcessionaireDeviceSettingUpdate')
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post();
  }

  private createConcessionaireSettingBody(
    conList: Concessionaire[],
    isDefaultCon: boolean,
    DefaultConId: string
  ) {
    let conIds = conList.map((x) => Number(x.ConcessionaireID));

    let staffModuleId = Number(GeneralSetting.getStaffModuleID());
    let specificCon = GeneralSetting.getIsAssignSpecificConcessionaire();

    let d_conId = Number(DefaultConId);

    return {
      StaffModuleID: staffModuleId,
      IsAssignSpecificConcessionaire: specificCon,
      IsDefaultConcessionaire: isDefaultCon,
      DefaultConcessionaireID: d_conId,
      ConcessionaireIDList: conIds,
    };
  }

  IsOnlineCheckSK() {
    // return HttpConnection.Connection(this.http, this.isKiosk)
    //   .addUrl(this.baseUrl + 'IsOnlineCheckSK')
    //   .addMethod("GET")
    //   .addHeader([])
    //   .addBody({})
    //   .post();

    // const body = {};

    return this.http.get(this.baseUrl + 'IsOnlineCheckSK');
  }

  createPrinterConnection() {
    console.log('data.service > createPrinterConnection() is called');

    const body = {};

    return this.http.post(
      environment.NodeUrl + 'createPrinterConnection',
      body,
      { responseType: 'text' as 'json' }
    );
  }
  createConnection() {
    const body = {};
    return this.http.post(environment.NodeUrl + 'createConnection', body, {
      responseType: 'text' as 'json',
    });
  }

  getWeight(unit: string, RaspberryPiIPAddress: string) {
    const body = {
      unit: unit,
      RaspberryPiIPAddress: RaspberryPiIPAddress,
    };
    return this.http.post(environment.NodeUrl + 'getWeight', body, {
      responseType: 'text' as 'json',
    });
  }

  sendAlcoholicItemNotification(deviceName: string) {
    // var headers = [] as IHttpHeaders[];
    // headers.push({ key: "Version", value: "1.0" } as IHttpHeaders);
    // headers.push({ key: "CompanyID", value: GeneralSetting.getCompanyId() } as IHttpHeaders);
    // headers.push({ key: "BranchID", value: GeneralSetting.getBranchId() } as IHttpHeaders);

    const headers = new HttpHeaders()
      .set('Version', '1.0')
      .set('CompanyID', GeneralSetting.getCompanyId())
      .set('BranchID', GeneralSetting.getBranchId());

    const body = new HttpParams()
      .set('OrderID', '0')
      .set('TagName', 'AlcoholicItem')
      .set('IsAlcoholNotification', 'true')
      .set('body', deviceName);
    // const body = {
    //   OrderID: '0',
    //   TagName: 'AlcoholicItem',
    //   IsAlcoholNotification: 'true',
    //   body: deviceName
    // };

    return this.http.get(this.baseUrl + this.sendAlcoholItemNotificationURL, {
      headers: headers,
      params: body,
    });
    // return HttpConnection.Connection(this.http, this.isKiosk)
    //   .addUrl(this.baseUrl + this.sendAlcoholItemNotificationURL)
    //   .addMethod("GET")
    //   .addHeader(headers)
    //   .addBody(body)
    //   .post();
  }

  getPlaceholderImage() {
    if (
      GeneralSetting.getPlaceHolderImageFlag() &&
      GeneralSetting.getPlaceholderImage() != ''
    ) {
      return GeneralSetting.getPlaceholderImage();
    }
    return '';
  }

  postHealthLog(kioskHealthCheck: HealthLogRequest) {
    var headers = [] as IHttpHeaders[];
    headers.push({ key: 'Version', value: '1.0' } as IHttpHeaders);
    headers.push({
      key: 'Content-Type',
      value: 'application/Json',
    } as IHttpHeaders);
    headers.push({
      key: 'CompanyID',
      value: GeneralSetting.getCompanyId(),
    } as IHttpHeaders);
    headers.push({
      key: 'BranchID',
      value: GeneralSetting.getBranchId(),
    } as IHttpHeaders);
    headers.push({
      key: 'DeviceID',
      value: GeneralSetting.getSerialNo(),
    } as IHttpHeaders);

    const body = { data: kioskHealthCheck };

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(this.baseUrl + this.healthLog)
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post();
  }

  postInstallationACK(kioskHealthCheck: any) {
    var headers = [] as IHttpHeaders[];
    headers.push({ key: 'Version', value: '1.0' } as IHttpHeaders);
    headers.push({
      key: 'Content-Type',
      value: 'application/Json',
    } as IHttpHeaders);
    headers.push({
      key: 'CompanyID',
      value: GeneralSetting.getCompanyId(),
    } as IHttpHeaders);
    headers.push({
      key: 'BranchID',
      value: GeneralSetting.getBranchId(),
    } as IHttpHeaders);
    headers.push({
      key: 'DeviceID',
      value: GeneralSetting.getSerialNo(),
    } as IHttpHeaders);

    const body = kioskHealthCheck;

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(this.baseUrl + this.installationACK)
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post();
  }
}
