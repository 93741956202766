import { Injectable } from '@angular/core';
import { DatabaseHandler } from '../DatabaseHandler';
import { CardPaymentProcessor, EPaymentType } from '../models/payment-type';
import { CartService } from './cart.service';
import { DejavooService } from './dejavoo.service';
import { GeneralSetting } from './generalSetting.service';
import { LoaderService } from './loader.service';
import { VerifoneEvoService } from './verifone-evo.service';
import { VerifoneService } from './verifone.service';


interface refundRes { isSuccess: boolean, Error: string, Data: any }

@Injectable({
  providedIn: 'root',
})
export class PaymentGatwayService {
  constructor(
    private readonly verifeonEvo: VerifoneEvoService,
    private cartService: CartService,
    private verifoneService: VerifoneService,
    private loader: LoaderService,
    private dejavoo: DejavooService
  ) { }



  private getCalculationData() {
    return {
      total: this.cartService.total,
      subtotal: this.cartService.subtotal,
      tax: this.cartService.tax,
      tip: this.cartService.tip,
      shippingFee: this.cartService.shippingFee,
      discount: this.cartService.discount,
      reward: this.cartService.reward,
    };
  }

  public async doPayment(
    paymentTypeID: string,
    thirdPartyPaymentTypeId: string,
    totalAmount: number
  ) {
    switch (paymentTypeID) {
      case EPaymentType.Card.toString():
        return await this.doCardPayment(totalAmount, thirdPartyPaymentTypeId);
        break;
      case EPaymentType.Cash.toString():
        break;
      case EPaymentType.USConnect.toString():
        break;
      case EPaymentType.HouseCredit.toString():
        break;
      case EPaymentType.GiftCard.toString():
        break;
      case EPaymentType.LevelUp.toString():
        break;
      case EPaymentType.IDCard.toString():
        break;
      case EPaymentType.RFID.toString():
        break;
      default:
        return false;
    }

    return false;
  }

  private async doCardPayment(amount: number, thirdPartyPaymentTypeId: string) {
    switch (thirdPartyPaymentTypeId) {
      case '7':
        break;
      case '13':
        break;
      case CardPaymentProcessor.GrubbrrPay.toString():
        return await this.doDejavooPayment(amount);
        break;
      case CardPaymentProcessor.Verifone.toString():
        return await this.doVerifonePayment(amount);
        break;
      case CardPaymentProcessor.VerifoneEvo.toString():
        return await this.doVerifoneEvoPayment(amount);
        break;
    }

    return false;
  }

  public async doRefund(amount: number, transactionId: string, thirdPartyPaymentTypeId: string, orderInvoiceNo: string): Promise<refundRes> {


    switch (thirdPartyPaymentTypeId) {
      case '7':
        break;
      case '13':

        break;
      case CardPaymentProcessor.GrubbrrPay.toString():

        break;
      case CardPaymentProcessor.Verifone.toString():
        return await this.doVeifoneRefund(amount, transactionId, orderInvoiceNo);
      case CardPaymentProcessor.VerifoneEvo.toString():
        return await this.doVerifonEvoRefund(amount, transactionId, orderInvoiceNo);
    }

    return {
      isSuccess: false,
      Error: "Refund Failed",
      Data: {}
    } as refundRes;
  }

  public async doConfigurePaymentDevice(isForced: boolean = false) {
    let paymentType = await this.getPaymentTypesFromSql();

    switch (paymentType) {
      case CardPaymentProcessor.Freedompay.toString():
        
        break;
      case '13':

        break;
      case CardPaymentProcessor.GrubbrrPay.toString():

        break;
      case CardPaymentProcessor.Verifone.toString():
        return this.verifoneService.doRegisterDevice(isForced);
        break;  
      case CardPaymentProcessor.VerifoneEvo.toString():

        break;
    }

    return false;
  }

  private async doVerifonEvoRefund(amount: number, transactionId: string, orderInvoiceNo: string) {
    let vRes = await this.verifeonEvo.doRefund(amount, transactionId, orderInvoiceNo);
    let res = {
      isSuccess: vRes,
      Error: "",
      Data: {}
    };

    return res;
  }

  private async doVerifoneEvoPayment(amount: number) {
    let orderInvoiceNo = GeneralSetting.getOrderInvoiceNo();
    let calculationdata = this.getCalculationData();

    let vEres = await this.verifeonEvo.doPayment(
      amount,
      orderInvoiceNo,
      calculationdata
    );

    return vEres;
  }

  private async doVerifonePayment(amount: number) {
    try {
      return await this.verifoneService.startPayment(amount);
    }
    catch (e) {
      return false;
    }
  }

  private async doVeifoneRefund(amount: number, transactionId: string, orderInvoiceNo: string) {
    this.loader.openLoader(false, true, 150000);

    try {
      let refundRes = await this.verifoneService.doRefundAsync(amount, transactionId, orderInvoiceNo);
      this.loader.closeLoader();

      return refundRes;
    }
    catch (e) {
      this.loader.closeLoader();
      return {
        isSuccess: false,
        Error: "Refund Failed",
        Data: {}
      }
    }
  }

  private async doDejavooPayment(amount: number) {
    let orderInvoiceNo = GeneralSetting.getOrderInvoiceNo();
    let calculationdata = this.getCalculationData();

    let res = await this.dejavoo.doPayment(
      amount,
      orderInvoiceNo,
      calculationdata
    );

    return res;
  }

  private async getPaymentTypesFromSql() {

    const getThirdPartyPayments = `
      SELECT DISTINCT TPPBM.TenderId,TPPBM.ThirdPartyPaymentID, PaymentDisplayName, TPPM.PaymentTypeID
      FROM ThirdPartyPaymentBranchMappings AS TPPBM
      INNER JOIN ThirdPartyPaymentMasters AS TPPM
      ON TPPBM.ThirdPartyPaymentID = TPPM.ThirdPartyPaymentID
      WHERE TPPM.PaymentTypeID = '2'
      `;

    let paymentTypeRes = await DatabaseHandler.getDataFromQuery(getThirdPartyPayments).toPromise().then();
    if (paymentTypeRes.length > 0) {
      return paymentTypeRes[0].ThirdPartyPaymentID as string;
    }

    return '0';
  }



}
