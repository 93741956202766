import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ItemV2 } from 'src/app/models/item';
import { CartService } from 'src/app/services/cart.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { environment } from '../../../environments/environment';
import { OutOfStockComponent } from '../out-of-stock/out-of-stock.component';
import { Router } from '@angular/router';
import { LastfiveorderService } from 'src/app/services/lastfiveorder.service';
import { UserService } from '../../services/user.service';
import { AlcoholicOver21DialogComponent } from '../dialogs/alcoholic-over21-dialog/alcoholic-over21-dialog.component';
import { AlcoholicItemsExceedMaxComponent } from '../dialogs/alcoholic-items-exceed-max/alcoholic-items-exceed-max.component';
import { DatabaseHandler } from 'src/app/DatabaseHandler';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { SchedulePricingService } from 'src/app/services/schedule-pricing.service';
import { SetDefaultService } from '../../services/set-default.service';
import {
  CategorySalesType,
  CommonFunctions,
  Snackbar,
} from 'src/app/services/common';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { EatOptionsService } from 'src/app/services/eat-options.service';
import { Guid } from 'src/app/models/Guid';
import { LoadingComponent } from 'src/app/loading/loading.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from 'src/app/services/language.service';
import { TimeoutComponent } from '../timeout/timeout.component';
import { LoyaltyType } from 'src/app/models/loyaltyModal';
import { LoaderService } from 'src/app/services/loader.service';
import { DataService } from 'src/app/services/data.service';
import { DiscountService } from 'src/app/services/discount.service';
import { FixedItemDiscount } from 'src/app/models/discounts';
import { DatabaseService } from 'src/app/services/database.service';

@Component({
  selector: 'app-last-five-order',
  templateUrl: './last-five-order.component.html',
  styleUrls: ['./last-five-order.component.css'],
})
export class LastFiveOrderComponent implements OnInit, OnDestroy {


  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  private allSubsCription: Subscription[] = [];

  @ViewChild(LoadingComponent) loadingModel!: LoadingComponent;

  @ViewChild(OutOfStockComponent) outofstockpopup!: OutOfStockComponent;

  @ViewChild(AlcoholicOver21DialogComponent)
  over21!: AlcoholicOver21DialogComponent;

  @ViewChild(TimeoutComponent)
  timeout!: TimeoutComponent

  customerID!: string;

  schedulePricingService: SchedulePricingService;

  backGround: string = '';

  lastorderPrice!: string;

  customerName: string = '';

  itemlist: any[] = [];

  showSnackBar: boolean = false;

  countStr: string = '';

  intoCombo: boolean = false;

  fromMenuLogin: boolean = false;

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl.substring(0, environment.imageBaseUrl.length - 1)
    : environment.imageBaseUrlRemote
  currAlcoholic: number = 0;

  maxAlcoholic: number = 0;

  hasLoyaltyProgram: boolean = false;

  useLoyaltykiosk: boolean = false;

  welcomeLoyaltyLogoPath!: string;


  showWelcomeLoyaltyLogo: boolean = false;

  showLoyaltyMode: boolean = false;

  showNoHistory: boolean = false;

  headerDescHasScroll: boolean = false;

  TotalPoints!: string;

  LastVisit!: string;

  PointEarnedLastVisit!: string;

  languageSub!: Subscription
  customerWelcomeText: string = ''
  pleaseAddOrderText: string = ''
  seeYouAgainText: string = ''
  yourRewardsAccountText: string = ''
  totalPointsText: string = ''
  lastVisitText: string = ''
  pointsLastVisitText: string = ''
  recentOrderText: string = ''
  noRecentOrderText: string = ''
  addedText: string = ''
  checkoutText: string = ''
  continueToMenuText: string = ''
  signOutText: string = ''
  addToCartText: string = ''
  welcomeFooterText: string = '';
  placeholderImage: string = '';
  fixedItemDisc: FixedItemDiscount[] = [] as FixedItemDiscount[];

  constructor(
    SchedulePricingService: SchedulePricingService,
    private cartservice: CartService,
    private isKioskService: IsKioskService,
    private router: Router,
    public lastfiveservice: LastfiveorderService,
    private userService: UserService,
    private loyaltyService: LoyaltyService,
    private readonly setDefault: SetDefaultService,
    private optionsService: EatOptionsService,
    private modalService: NgbModal,
    private language: LanguageService,
    private loader: LoaderService,
    private readonly dataService: DataService,
    private discountService: DiscountService,
  ) {
    this.schedulePricingService = SchedulePricingService;
  }
  loadText() {
    this.addToCartText = this.language.getTextElement('lbl_add_to_cart')
    this.pleaseAddOrderText = this.language.getTextElement('msg_please_add_order_for_checkout')
    this.seeYouAgainText = this.language.getTextElement('lbl_it_s_so_great_to_see_you_again')
    this.yourRewardsAccountText = this.language.getTextElement('lbl_your_rewards_account')
    this.totalPointsText = this.language.getTextElement('lbl_total_points')
    this.lastVisitText = this.language.getTextElement('lbl_last_visit')
    this.pointsLastVisitText = this.language.getTextElement('lbl_points_earned_last_visit')
    this.recentOrderText = this.language.getTextElement('lbl_your_recent_orders')
    this.noRecentOrderText = this.language.getTextElement('lbl_no_recent_orders')
    this.addedText = this.language.getTextElement('lbl_added')
    this.checkoutText = this.language.getTextElement('lbl_checkout')
    this.continueToMenuText = this.language.getTextElement('lbl_continue_to_menu')
    this.signOutText = this.language.getTextElement('action_sign_out')
    this.welcomeFooterText = this.language.getTextElement('update_account_information')
    this.customerWelcomeText = this.language.getTextElement('hi_guest').replace('%d', this.customerName)
  }
  ngOnInit() {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    let name = GeneralSetting.getCustomerLoginName();
    this.customerName = name ? name : '';
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.loyaltyService.chosenItemList = [];
    this.loyaltyService.itemsWaiting = false;
    this.backGround = this.baseImageUrl + GeneralSetting.getBackGroundImage();
    if (this.loyaltyService.isLoyalty) {
      this.logoSetter();
      if (this.loyaltyService.loyaltyType == LoyaltyType.Referrizer) {
        this.showLoyaltyMode = true;
        this.TotalPoints =
          this.userService.referrizerLoyaltyUser.loyaltyPointsCount.toString();
        this.loyaltyService.getCustomerVisit()!.then((data) => {
          this.PointEarnedLastVisit = data.data.points;
        });
        this.LastVisit = this.userService.referrizerLoyaltyUser.lastVisitDate;
      }
    }
    let curralco = GeneralSetting.getCurrentCountofAlkol();
    if (curralco != null) this.currAlcoholic = Number(curralco);
    this.maxAlcoholic = Number(GeneralSetting.getMaxAlcoholicItemsPerOrder());
    this.setSubscription();
    this.setFixedItemDisc();
  }

  async setFixedItemDisc() {
    this.fixedItemDisc = await this.discountService.getFixedItemDisc();
  }

  private setSubscription() {
    this.allSubsCription.push(
      this.cartservice.sub_subtotal.subscribe((data) => {
        this.updateFixedItemOnSubtotal(data);
      })
    );

    this.allSubsCription.push(
      this.loyaltyService.updateRewardSub.subscribe((points: number) => {
        let availablePoints = this.userService.referrizerLoyaltyUser?.loyaltyPointsCount || 0;;
        availablePoints = Number(availablePoints) + Number(points);
        this.userService.referrizerLoyaltyUser.loyaltyPointsCount = availablePoints;
      })
    );
  }

  ngAfterViewInit() {
    this.getItemList();
    this.isOverflow();
    //this.timeout.stillHere()
  }

  private isOverflow() {
    const element = document.getElementById('arrowDown');
    setTimeout(() => {
      if (element) {
        if (element.scrollHeight > element.clientHeight) {
          this.headerDescHasScroll = true;
        } else {
          this.headerDescHasScroll = false;
        }
      }
    }, 1000);
  }

  async getItemList() {
    this.loader.openLoader(false, true, 10000);

    GeneralSetting.setLastFiveOrderChosen('true');
    let combo = GeneralSetting.getIntoCombo();
    if (combo === null || combo === 'false') {
      this.lastfiveservice.getLastFiveOrders().then(async (data: ItemV2[]) => {
        this.itemlist = data;
        this.showNoHistory = (data.length == 0);
        if (data.length == 0) {
          this.loader.closeLoader()
          return
        }
        await this.cartservice
          .applyPromos(this.itemlist)
          .then(async (data: ItemV2[]) => {
            this.loader.closeLoader()
            for (let i = 0; i < data.length; i++) {
              data[i].totalPrice = CommonFunctions.getItemPrice(data[i]);
            }
            this.itemlist = data;
          });
      });
    } else {
      this.itemlist = this.lastfiveservice.getItemList();
      await this.cartservice.applyPromos(this.itemlist)
        .then(async (data: ItemV2[]) => {
          this.loader.closeLoader()
          for (let i = 0; i < data.length; i++) {
            if (data[i].OrderMinimumQuantity != 0) {
              data[i].Quantity = data[i].OrderMinimumQuantity.toString();
            }
          }
          this.itemlist = data;
        }, () => { });
    }
  }
  addToCart(item: ItemV2) {
    // Checks fixed item quantity limit
    if (this.fixedItemDisc != undefined && this.fixedItemDisc.length > 0) {
      var isFixedItemAvail = this.discountService.isFixedItemAvail(this.fixedItemDisc[0], item);
      if (isFixedItemAvail) {
        return;
      }
    }

    var isCombo = item.IsCombo.toString();
    const value = Number(item.Quantity);
    if (isCombo.toLowerCase() == '0' || isCombo.toLowerCase() == 'false') {
      if (item.SoldOut === true) {
        this.outofstockpopup.open(0);
        return;
      } else if (
        item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString()
      ) {
        this.checkifAlcoholicItem(item);
      } else {
        this.addOrRemoveItem(item);
      }
    } else {
      let totalComboAlcoholCount = CommonFunctions.isComboAlcoholic(item);
      if (totalComboAlcoholCount > 0) {
        this.checkifAlcoholicItem(item);
      } else {
        this.addOrRemoveItem(item);
      }
    }
  }

  checkifAlcoholicItem(item: ItemV2) {
    if (!item.isSelected) {
      AlcoholicOver21DialogComponent.open(this.modalService).result
        .then((data: any) => {
          if (data === 'over') {
            if (!CommonFunctions.isAlcoholLimitRemainig()) {
              AlcoholicItemsExceedMaxComponent.open(this.modalService);
            } else {
              var isCombo = item.IsCombo.toString();
              const value = Number(item.Quantity);
              if (
                isCombo.toLowerCase() == '1' ||
                isCombo.toLowerCase() == 'true'
              ) {
                if (CommonFunctions.increaseAlcoholicCountForCombo(item, true)) {
                  this.addOrRemoveItem(item);
                } else {
                  AlcoholicItemsExceedMaxComponent.open(this.modalService);
                }
              } else {
                this.addOrRemoveItem(item);
              }
            }
          }
        }, () => { });
    } else {
      var isCombo = item.IsCombo.toString();
      const value = Number(item.Quantity);
      if (isCombo.toLowerCase() == '1' || isCombo.toLowerCase() == 'true') {
        if (CommonFunctions.increaseAlcoholicCountForCombo(item, false)) {
          this.addOrRemoveItem(item);
        }
      } else {
        if (item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString()) {
          GeneralSetting.decreaseAlcohol();
        }
        this.addOrRemoveItem(item);
      }
    }
  }

  checkout() {
    this.checkifDirectFromMenu();
    if (this.lastfiveservice.numofItemsAdded === 0) {
      //show snack bar
      Snackbar.show('Please select an item before checking out', 3000);
    } else {
      this.resetData();
      if (this.fromMenuLogin) {
        this.router.navigateByUrl('/orderReview');
      } else if (this.optionsService.length == 1) {
        this.optionsService.getEatOptions().then((data) => {
          GeneralSetting.setOrderTypeId(data[0].OrderTypeID.toString());
          this.optionsService.setEatOption(data[0]);
        }, () => { });
        this.router.navigateByUrl('/orderReview');
      } else {
        GeneralSetting.setCheckOutAtLastFive('true');
        this.setDefault.redirectToEatOptions = true;
        this.router.navigateByUrl('/touchToStart');
      }
    }
  }

  continueToMenu() {
    this.checkifDirectFromMenu();
    if (this.fromMenuLogin || this.optionsService.length == 1) {
      if (this.optionsService.length == 1) {
        this.optionsService.getEatOptions().then((data) => {
          GeneralSetting.setOrderTypeId(data[0].OrderTypeID.toString());
          this.optionsService.setEatOption(data[0]);
        }, () => { });
      }
      this.router.navigateByUrl('/menu');
    } else {
      this.resetData();
      this.loyaltyService.itemsWaiting = true;
      this.setDefault.redirectToEatOptions = true;
      this.router.navigateByUrl('/touchToStart');
    }
  }

  clearInfo() {
    this.resetData();
    this.checkifDirectFromMenu();
    GeneralSetting.removeLastFiveOrderChosen();
    GeneralSetting.removeCustomerLoginMobile();
    GeneralSetting.removeCustomerLoginEmail();
    GeneralSetting.removeCustomerLoginName();
    GeneralSetting.removeCustomerId();
    this.userService.signOut();
    if (this.fromMenuLogin) {
      this.router.navigateByUrl('/menu');
    } else if (this.setDefault.optionsLength == 1) {
      this.cartservice.clearCart();
      this.setDefault.redirectToEatOptions = true;
      this.router.navigateByUrl('/menu');
    } else {
      //this.cartservice.clearCart();
      this.setDefault.redirectToEatOptions = false;
      this.router.navigateByUrl('/touchToStart');
    }
  }
  addOrRemoveItem(item: any) {
    if (!item.isSelected) {
      item.isSelected = true;
      this.lastfiveservice.increaseNumofItems(item);
      if (!item.Modifiers) {
        item.Modifiers = [];
      }
      if (GeneralSetting.getOrderTypeId() != '') {
        this.loyaltyService.chosenItemList = [];
        this.loyaltyService.itemsWaiting = false;
        this.cartservice.createCartItemV2(item).then((data) => {
          this.cartservice.addToCartAsync(data, true).then((res) => {
            this.cartservice.broadcastAll()
          }, () => { });
        }, () => { });
      } else {
        if (this.optionsService.length != 1) {
          if (!item.guid) {
            item.guid = Guid.newGuid();
          }
          this.cartservice.createCartItemV2(item).then((data) => {
            this.loyaltyService.chosenItemList.push(data);
          }, () => { });
          this.loyaltyService.itemsWaiting = true;
        } else {
          this.optionsService.getEatOptions().then((data) => {
            GeneralSetting.setOrderTypeId(data[0].OrderTypeID);
            this.loyaltyService.chosenItemList = [];
            this.loyaltyService.itemsWaiting = false;
            this.cartservice.createCartItemV2(item).then((data) => {
              this.cartservice.addToCartAsync(data, true).then((res) => {
                this.cartservice.broadcastAll()
              }, () => { });
            }, () => { });
          }, () => { });
        }
      }
    } else {
      item.isSelected = false;
      this.lastfiveservice.decreaseNumofItems(item);
      if (this.lastfiveservice.numofItemsAdded !== 0) {
        this.countStr = ' (' + this.lastfiveservice.numofItemsAdded + ')';
      }
      if (GeneralSetting.getOrderTypeId() != '') {
        const toremove = this.cartservice.findDuplicateItemInCartV2(item);
        if (toremove.isSame) {
          let item = this.cartservice.getCartItems().filter((x) => x.guid == toremove.guid)
          if (item && item.length) {
            if (Number(item[0].Quantity) > 1) {
              item[0].Quantity = (Number(item[0].Quantity) - 1).toString()
              item[0].totalPrice = CommonFunctions.getItemPrice(item[0]) * Number(item[0].Quantity);
              this.cartservice.cartItems[
                this.cartservice.cartItems.findIndex((x: any) => x.guid == toremove.guid)
              ] = item[0];
            } else {
              let newCartItems = this.cartservice.getCartItems().filter((x) => x.guid != toremove.guid)
              this.cartservice.cartItems = newCartItems
              this.cartservice.subCartItems.next(newCartItems)
              this.cartservice.removeFromCart(item[0]);
            }
            this.cartservice.broadcastAll();
          }
        }
      } else {
        let cartitems = this.loyaltyService.chosenItemList;
        const toremove = cartitems.find((i) => i.ItemID === item.ItemID);
        if (toremove) {
          this.loyaltyService.removeFromCart(toremove);
        }
      }
    }
  }

  resetData() {
    GeneralSetting.setFromMenuLogin('false');
    GeneralSetting.setLastFiveOrderChosen('true');
    GeneralSetting.setIntoCombo('false');
    this.lastfiveservice.numofItemsAdded = 0;
    for (let item of this.itemlist) {
      item.isSelected = false;
    }
  }

  checkifDirectFromMenu() {
    let check = GeneralSetting.getFromMenuLogin();
    if (check && check === 'true') {
      this.fromMenuLogin = true;
    }
  }

  getLoyaltyProgram() {
    return new Promise<Boolean>((resolve) => {
      let sqlstr =
        'SELECT IsActive FROM LoyaltyPrograms WHERE IsActive = "True"';

      const callback = (tx: string, data: any) => {
        if (data.rows.length > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      const error = (tx: string, error: any) => {
        resolve(false);
      };

      DatabaseHandler.executeSqlStatement(sqlstr, [], callback, error);
    });
  }
  //if loyalty program && useloyatykiosk && showwelcome
  //show the logo pic on html
  logoSetter() {
    const showWelcomeLoyaltyLogo = GeneralSetting.getShowWelcomeLoyaltyLogo();
    if (showWelcomeLoyaltyLogo && showWelcomeLoyaltyLogo === 'True') {
      this.showWelcomeLoyaltyLogo = true;
    }
    let loyaltyLogo = GeneralSetting.getWelcomeLoyaltyLogoPath();
    if (loyaltyLogo && loyaltyLogo !== '') {
      this.welcomeLoyaltyLogoPath = this.baseImageUrl + loyaltyLogo;
    } else {
      this.showWelcomeLoyaltyLogo = false;
    }
  }

  /**
  * Check and alert after every subtotal update if fixed item available in the cart
  * @param subtotal 
  */
  private async updateFixedItemOnSubtotal(subtotal : number){
      var fixedItemDiscount = await this.discountService.checkFreeItemOnSubtotalUpdate(subtotal);
      this.findFixedItemOperation(fixedItemDiscount);
  }

  /**
  * Check fixed item's Quantity need to be updated or removed from the cart
  * @param fixedItemDiscount 
  */
  private findFixedItemOperation(fixedItemDiscount: any) {
    if (fixedItemDiscount && fixedItemDiscount != undefined) {
      const cartItems = this.cartservice.getCartItems().filter(
        (x) => x.ItemID == fixedItemDiscount.ItemID
      );
      if(cartItems && cartItems != undefined && cartItems.length > 0){
        if(fixedItemDiscount.Check == 2){
            this.addOrRemoveItem(cartItems[0]); 
        }
      }
    }
  }
}
