import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Concessionaire } from 'src/app/models/Concessionaire';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-brand-filter',
  templateUrl: './brand-filter.component.html',
  styleUrls: ['./brand-filter.component.css']
})
export class BrandFilterComponent implements OnInit {

  allConcessionaire: Concessionaire[] = [] as Concessionaire[];
  languageSub!:Subscription
  modalReference: any;
  imageBaseUrl : string = '';
  concessionaireBaseUrl : string ='';
  positiveBtnColor: string = '';
  positiveTextColor: string = '';
  checkScrollCheck: boolean = false;
  isBottom: boolean = false;
  secondaryColor: string = '';

  tapToStartOrderText: string = '';

  multipleRestaurantsText: string = '';

  payAllChecksAtOnceText: string = '';

  backText: string = '';

  @ViewChild('itemContainerDiv') itemContainerDiv!: ElementRef;

  constructor(private language:LanguageService) { }
  loadText(){
    this.tapToStartOrderText=this.language.getTextElement('tap_to_start_order')
    this.multipleRestaurantsText=this.language.getTextElement('select_from_multiple_restaurants')
    this.payAllChecksAtOnceText=this.language.getTextElement('pay_all_checks_at_once')
    this.backText=this.language.getTextElement('back')
  }
  ngOnInit(): void { 
    this.languageSub=this.language.localeCommunicator.subscribe((val)=>{
      this.loadText()
    })
    this.positiveBtnColor = GeneralSetting.getPositiveColor();

    this.positiveTextColor = GeneralSetting.getPositiveColorText();

    this.secondaryColor = GeneralSetting.getSecondaryColor();
   }

  public static open(modalService: NgbModal, allConcessionaire: Concessionaire[], imageBaseUrl: string, concessionaireBaseUrl: string) {
    var modalReference = modalService.open(BrandFilterComponent, {
      size: 'lg',
      modalDialogClass: "cons-dialog",
    });

    modalReference.componentInstance.modalReference = modalReference;
    modalReference = modalReference;
    modalReference.componentInstance.allConcessionaire = allConcessionaire;
    modalReference.componentInstance.imageBaseUrl = imageBaseUrl;
    modalReference.componentInstance.concessionaireBaseUrl = concessionaireBaseUrl;
    modalReference.componentInstance.checkScrollCheck = allConcessionaire.length > 28;
    return modalReference;
  }

  /**
   * Sets concessionaire logo
   * @param con 
   * @param imageBaseUrl 
   * @param concessionaireBaseUrl 
   * @returns 
   */
  getConcessionaireLogo(con: Concessionaire, imageBaseUrl: string, concessionaireBaseUrl : string) {
    return  con.Logo && con.Logo != '' ? imageBaseUrl + concessionaireBaseUrl + con.Logo : '../../../assets/images/Image_21.png';
  }

  cancel(){
    this.modalReference.close('');
  }
  
  /**
   * Select concessionaire from View more dialog
   * @param con 
   */
  onConcessionaireClick(con: Concessionaire) {
    this.modalReference.close(con.ConcessionaireID);
  }

  /**
   * Scrolls down the view
   * @returns 
  */
  scrollDown() {
    if (!this.itemContainerDiv) {
      return;
    }

    let element = this.itemContainerDiv.nativeElement;
    const smoothScrollTo = (to: number, duration: number) => {
      const start = (element as HTMLElement).scrollTop,
        change = to - start,
        startDate = +new Date();

      // t = current time
      // b = start value
      // c = change in value
      // d = duration
      const easeInOutQuad = (t: any, b: any, c: any, d: any) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        (element as HTMLElement).scrollTop = parseInt(
          easeInOutQuad(currentTime, start, change, duration)
        );
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          (element as HTMLElement).scrollTop = to;
        }
        this.isBottom = true;
      };
      animateScroll();
    };
    // if (bottom) bottom.scrollIntoView({ behavior: 'smooth' });
    smoothScrollTo(element?.scrollHeight - element?.clientHeight, 300);
  }

   /**
   * 
   * @returns Scrolls up the view
  */
  scrollTop() {
    if (!this.itemContainerDiv) {
      return;
    }

    let element = this.itemContainerDiv.nativeElement;

    const smoothScrollTo = (to: number, duration: number) => {
      const start = (element as HTMLElement).scrollTop,
        change = to - start,
        startDate = +new Date();
      /**
       * @param {any} t current time
       * @param {any} b start value
       * @param {any} c change in value
       * @param {any} d duration
       */
      const easeInOutQuad = (t: any, b: any, c: any, d: any) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        (element as HTMLElement).scrollTop = parseInt(
          easeInOutQuad(currentTime, start, change, duration)
        );
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          (element as HTMLElement).scrollTop = to;
        }
        this.isBottom = false;
      };
      animateScroll();
    };
    // if (bottom) bottom.scrollIntoView({ behavior: 'smooth' });
    smoothScrollTo(0, 300);
  }

  /**
   * Deducts manual scroll position
   * @param event 
   */
  onScroll(event: any) {
    setTimeout(() => {
      this.isBottom = (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight)
    }, 300);
  }

}
