<div [ngStyle]="{ borderLeftColor: comboGroups.isValid ? positiveColor : negativeColor }" class="objects accordion">
  <div class="header-text" (click)="collapse()">
    <span *ngIf="comboGroups.rule != ''">
      {{
      comboGroups.GroupName
      }}
    </span>
    <span *ngIf="comboGroups.rule != ''" class="rule">({{ comboGroups.rule }})</span>
    <span class="required-text" [ngStyle]="{color: comboGroups.isValid?positiveColor:negativeColor}">
      {{
      comboGroups.IsForced === "True" ? requiredText : ""
      }}
    </span>
    <span class="collapse-icon">
      <i *ngIf="collapsed && !showByDefault; else collap" class="fa fa-solid fa-chevron-down"></i>
      <ng-template #collap>
        <i class="fa fa-solid fa-chevron-up"></i>
      </ng-template>
    </span>
  </div>
  <div class="mini-holder flex animated fadeIn">
    <div class="list-text-holder">
      <span *ngFor="let Name of filterData(); let isLast = last" class="normal">
        {{ Name }}{{!isLast ? ', ' : ''}}
      </span>
    </div>
    <div class="flex holder" *ngIf="collapsed">
      <div *ngFor="let item of items">
        <img *ngIf="item.isSelected" class="mini-item" src="{{( item.ImageUrl && item.ImageUrl != '' ) ? baseImageUrl + item.ImageUrl : placeholderImage !='' ? baseImageUrl+placeholderImage : ''}}"
          onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
          [ngStyle]="{'border-radius': bordersTypeItem ? imageRadius : '0%'}">
      </div>
    </div>
  </div>
  <div [ngbCollapse]="collapsed && !showByDefault" (click)="cancelDefault()" class="">
    <div
      [ngClass]="IsCategory? 'object-row object-row-yscroll collapse show animated fadeIn overflow-y-none':'object-row collapse show animated fadeIn overflow-y-none'">
      <div class="object-col" *ngFor="let item of items;index as i">
        <app-items [parent]="comboGroups" [itemIndex]="i" (parentChange)="parentChange($event)" [item]="item"
          [itemType]="ItemType" [isSubCategory]="IsCategory" [parentQuantity]="parentQuantity">
        </app-items>
      </div>
    </div>
  </div>
  <div class="max-holder" *ngIf="comboGroups.max == comboGroups.count">
    <span class="negativeText"> {{maxSelectionsTextA}} <span class="black"> {{maxSelectionsTextB}}</span> </span>
  </div>
</div>