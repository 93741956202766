import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentInsertService } from '../payment-insert/payment-insert.service';
import { PaymentSuccessComponent } from './payment-success.component';

@Injectable({
  providedIn: 'root'
})
export class PaymentSuccessDialogService {

  constructor(private modalService:NgbModal,
      private paymentInsertModal:PaymentInsertService
    ) { }

  private paymentSuccessModal!: PaymentSuccessComponent | null;
  
  private paymentSuccessModalRef: any | null;

  public openPaymentSuccessModel(isVerifone: boolean = false) {
    if (this.paymentSuccessModal) {
      this.paymentInsertModal.closePaymentInsertModel();
    }

    this.paymentSuccessModalRef = PaymentSuccessComponent.open(
      this.modalService,
      isVerifone
    );
    this.paymentSuccessModal = this.paymentSuccessModalRef.componentInstance;
  }

  public closePaymentSuccessModel() {
    if (this.paymentSuccessModal) {
      this.paymentSuccessModal.close();
      this.paymentSuccessModal = null;
      this.paymentSuccessModalRef = null;
    }
  }
  
}
