import { Injectable } from '@angular/core';
import { constants } from 'buffer';
import { Subscription } from 'rxjs';
import { DatabaseHandler } from '../DatabaseHandler';
import { KeyboardService } from './keyboard.service';
export interface SMBDiscount {
  DiscountID: string;
  CouponCode: string;
  Name: string;
  DiscountAmount: string;
  UsePercentage: string;
}
@Injectable({
  providedIn: 'root',
})
export class SMBDiscountService {
  discounts: SMBDiscount[] = []
  
  constructor( private KeyboardService: KeyboardService) {

   }

  async getDiscounts(){
    try{
      let discountsFromSQL=await this.getDiscountsFromSQL()
      this.discounts=discountsFromSQL
    } catch(e){
      console.log(e)
    }
    
  }
  getDiscountsFromSQL(){
      return new Promise<SMBDiscount[]>((resolve: any, reject: any) => {
        var discountQuery = `
        SELECT
         DiscountID, Name, DiscountAmount, UsePercentage, CouponCode
        FROM
          DiscountMasters
          `;
  
        const errorCallback = (statement: any, error: any) => {
          reject(error);
        };
  
        const setItems = (tx: string, results: any) => {
          var data: SMBDiscount[] = Array.from(results.rows);
          resolve(data);
        };
  
        DatabaseHandler.executeSqlStatement(
          discountQuery,
          [],
          setItems,
          errorCallback
        );
      });
  }
  
}
