import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ShippingService {
  shippingDetails: DomesticShippingDetails|InternationalShippingDetails|undefined;
  apiUrl: string;
  constructor(private readonly http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }
  getStates(countryID:string){
    const url = this.apiUrl + `State/${countryID}`;
    let headers = new HttpHeaders()
    .set('Version', '1.0')
    .set('Content-Type', 'application/json');
    return this.http.get(url, { headers }).toPromise();
  }
  getCities(stateID:string){
    const url = this.apiUrl + `City/${stateID}`;
    let headers = new HttpHeaders()
    .set('Version', '1.0')
    .set('Content-Type', 'application/json');
    return this.http.get(url, { headers }).toPromise();
  }
}
export interface ShippingDetails {
  'Name': string;
  'State': string;
}
export class DomesticShippingDetails implements ShippingDetails{
  constructor(name:string,phone:string,email:string,state:any,city:any, addressOne:string, zipCode:string,shippingFee:string,addressTwo:string,) {
    this.Name = name;
    this.Phone = phone;
    this.Email = email;
   this.State=state.StateName;
   this.StateID=state.StateID;
   this.City=city.CityName;
   this.CityID=city.CityID;
   this.AddressOne=addressOne;
   this.ZipCode=zipCode;
   this.ShippingFee=Number(shippingFee);
   this.AddressTwo=addressTwo;
   this.selectedCity=city;
   this.selectedState=state;
  }
  selectedState:any;
  selectedCity:any;
  Name: string;
  Phone:string;
  Email:string;
  State: string;
  StateID:string;
  City: string;
  CityID:string;
  AddressOne: string;
  AddressTwo: string;
  ShippingFee:number;
  ZipCode: string;
}
export class InternationalShippingDetails implements ShippingDetails {
  constructor(name:string,phone:string,email:string,state:any,country:any,address:string,postCode:string,shippingFee:string,code:string){
    this.Name=name;
    this.Phone=phone;
    this.Email=email;
    this.State=state.StateName;
    this.StateID=state.StateID;
    this.Address=address;
    this.Country=country.CountryName;
    this.CountryID=country.CountryID;
    this.ShippingFee=Number(shippingFee);
    this.PostCode=postCode;
    this.MobileCode=code;
    this.selectedCountry=country;
    this.selectedState=state;
  }
  selectedCountry:any;
  selectedState:any;
  Name: string;
  Phone:string;
  Email:string;
  State: string;
  StateID:string;
  Address: string;
  Country: string;
  ShippingFee:number;
  PostCode: string;
  CountryID:string;
  MobileCode:string;
}
