import { Injectable } from "@angular/core";
import Pusher from "pusher-js";
import { PusherMaster } from "../models/PusherMaster";
import { DatabaseService } from "./database.service";
import { Router } from '@angular/router';
// declare const Pusher: any;
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { Subject } from "rxjs/internal/Subject";
import { PusherModel, PusherPriceChangeNotification } from "../models/PusherModel";
import { loggingData,LogService } from "./log.service";
import { HealthLogService } from "./kiosk-health-log.service";
import { KioskUpgradeService } from "./kiosk-upgrade.service";
import { RemoteInstallNotification } from "./api/Models";

@Injectable({
  providedIn: "root"
})
export class PusherService {

  pusher: any;

  channel: any;

  branchId: any;

  pusherMaster: PusherMaster[] = [] as PusherMaster[];

  Item86ModifierDetails:Subject<PusherModel> = new Subject<PusherModel>();
  CommonModifier86:Subject<PusherModel> = new Subject<PusherModel>();
  Ingredient86:Subject<PusherModel> = new Subject<PusherModel>();
  Item86:Subject<PusherModel> = new Subject<PusherModel>();
  ItemPrice:Subject<PusherPriceChangeNotification> = new Subject<PusherPriceChangeNotification>();
  ItemStatus:Subject<PusherModel> = new Subject<PusherModel>();
  ItemIsActive:Subject<PusherModel> = new Subject<PusherModel>();
  ConcessionaireIsActive:Subject<PusherModel> = new Subject<PusherModel>();

  // constructor() {
  //   this.pusher = new Pusher(environment.pusher.key, {
  //     cluster: environment.pusher.cluster,
  //   });
  //   this.channel = this.pusher.subscribe(sessionStorage.getItem('branchId')!);
  // }

  constructor(private readonly db: DatabaseService,
              private router: Router,
              private healthLogService: HealthLogService,
              private logService: LogService, private readonly upgradeService: KioskUpgradeService) {
    const pusherData = localStorage.getItem("pusherData");

    try {
      if (pusherData && pusherData != "") {
        this.pusherMaster = JSON.parse(pusherData);

        if (this.pusherMaster && this.pusherMaster.length > 0) {
          localStorage.setItem("pusherData", JSON.stringify(this.pusherMaster));

          this.pusher = new Pusher(this.pusherMaster[0].AppKey, {
            cluster: this.pusherMaster[0].Cluster,
          });
          let branchId = GeneralSetting.getBranchId();

          if (!branchId) {
            branchId = this.branchId;
          }
          if (!this.channel && (this.channel == null || this.channel == undefined)) { 
            this.channel = this.pusher.subscribe(branchId);
            this.bindPusherFunctions();
          }
          
         
        }
      }
    }
    catch (e) { console.log(e);}

  }


  async getPusherKeys(isLogin: boolean = false) {

    if (isLogin) {
      if (this.pusherMaster && this.pusherMaster.length > 0) {
        localStorage.setItem("pusherData", JSON.stringify(this.pusherMaster));

        this.pusher = new Pusher(this.pusherMaster[0].AppKey, {
          cluster: this.pusherMaster[0].Cluster,
        });

        let branchId = GeneralSetting.getBranchId();

        if (!branchId) {
          branchId = this.branchId;
        }
        if (!this.channel && (this.channel == null || this.channel == undefined)) {
          this.channel = this.pusher.subscribe(branchId);
          this.bindPusherFunctions()
        }
        
        return;
      }
    }

    this.pusherMaster = await this.db.getPusherCredentials();

    if (this.pusherMaster && this.pusherMaster.length > 0) {
      localStorage.setItem("pusherData", JSON.stringify(this.pusherMaster));

      this.pusher = new Pusher(this.pusherMaster[0].AppKey, {
        cluster: this.pusherMaster[0].Cluster,
      });

     
      let branchId = GeneralSetting.getBranchId();

      if (!branchId) {
        branchId = this.branchId;
      }

      this.channel = this.pusher.subscribe(branchId);

      this.bindPusherFunctions()
    }

  }

  private bindPusherFunctions() {
    if (!this.channel) {
      return;
    }

    this.channel.bind('UpgradeKioskApp', async (data: RemoteInstallNotification) =>  {
      let getSerialNo = GeneralSetting.getSerialNo();
      if(data?.deviceIds && data.deviceIds.length > 0 && data.deviceIds.includes(getSerialNo)) {
        setTimeout( () => {
          if(data?.kioskReleaseInformation?.PackageUrl) {
           this.upgradeService.KioskUpgradeEvents.next(data);
          }
        },1000);
      }
    })

    this.channel.bind('UpdateHealthLog', async (data: string) =>  {
      let getSerialNo = GeneralSetting.getSerialNo();
      if(data && (data == getSerialNo) ){
        this.healthLogService.sendHealthLog(true);
      } else if(!data) {
        this.healthLogService.sendHealthLog(false);       
      }
    })
    
    this.channel.bind('notification', async (data: any) =>  {
      if (data.title === 'Update_Menu' || data.title === 'MenuRefresh') {
        this.db.isDoRefresh = true;
        this.db.MenuRefresh.next(this.db.isDoRefresh);
        if(this.router.url =='/settings'){
          this.db.isRefreshedinSettings = true;
        } else {
          this.db.isRefreshedinSettings = false;
        }
        var log = new loggingData(
          'Update Menu',
          'Update Menu success',
          'Menu Sync Successfully',
          'Update Menu',
          true
        );
        this.logService.sendLogToServer(log);
      }
     
      if (data.title == "ConcessionaireIsActive") {
        const obj = JSON.parse(data.body);
        const ConcessionaireId = obj.ItemID;
        const status = obj.Status;
        this.db.updateConcessionaireActiveById(ConcessionaireId, status);
        this.ConcessionaireIsActive.next({  ItemId: obj.ItemID, Status: obj.Status });
      }

      if (data.title === 'Item86' || data.title === 'ItemStatus') {
        const obj = JSON.parse(data.body);
        const itemId = obj.ItemID;
        const status = obj.Status;

        this.db.updateItem86ById(itemId, status);
        setTimeout(() => {
          this.Item86.next({ ItemId: itemId, Status: status });
        },1000);
      }

      if (data.title === 'ItemPrice') {
        const obj = JSON.parse(data.body);
        const itemId = obj.ItemID;
        const price  = obj.Price;

        var success = await this.db.updateItemPriceById(itemId, price);
        if(success)
          this.ItemPrice.next({ ItemId: itemId, Price: price });
      }

      if (data.title === 'IsActive') {
        const obj = JSON.parse(data.body);
        const itemId = obj.ItemID;
        const status = obj.Status;
        this.db.updateItemStatusById(itemId, status);
        setTimeout(() => {
          this.ItemIsActive.next({ ItemId: itemId, Status: status });
        },1000);
      }

      if (data.title === 'CommonModifier86' || data.title === 'Modifier86') {
        const obj = JSON.parse(data.body);
        const itemId = obj.ItemID;
        const status = obj.Status;
        this.db.updateModifierStatusById(itemId, status);

        setTimeout(() => {

          this.CommonModifier86.next({
            ItemId: itemId,
            Status: status
          });
        
        },1000);
      }

      if (data.title === 'Ingredient86') {
        const obj = JSON.parse(data.body);
        const itemId = obj.ItemID;
        const status = obj.Status;
        this.db.updateIngredientStatusById(itemId, status);

        setTimeout(() => {

          this.Ingredient86.next({
            ItemId: itemId,
            Status: status
          });
        
        },1000);
      }

      if (data.title === 'Item86ModifierDetails') {
        let obj = JSON.parse(data.body);
        const itemId = obj.IngredientID;
        const status = obj.Status;

        this.db.updateIngredientStatusById(itemId, status);

        setTimeout(() => {
          this.Item86ModifierDetails.next({
            ItemId: itemId,
            Status: status
          });
        
        },1000);
      }

    });
  }

}
