import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'functionCall'
})
export class FunctionCallPipe implements PipeTransform {

  transform<T,R>(thisArg:T, project: (t: T, ...others:any[]) => R, ...args:any[]):R {
    return project(thisArg,...args);
  }

}
