<div id="parbrink-error-popup" role="dialog">
  <div class="header">
    <p class="text-center caption">{{checkoutText}}</p>
    <p class="text-center"><b class="head">{{uhOhText}}</b></p>
    <p class="text-center">
      <strong class="body">{{ errorMsg }}</strong>
    </p>
    <div *ngIf="arr.length != 0">
      <p>{{affectedItemsText}}</p>
      <div class="scroll">
        <!-- ArrowDown not fully implemented, left in code as it is non-breaking -->
        <div id='arrowDown'>
          <div *ngFor="let item of arr" >
            <p>{{ item }}</p>
          </div>
        </div>
      </div>
      <i
        class="fas fa-caret-down"
        [ngClass]="{
          showArrowDown: headerDescHasScroll,
          hideArrowDown: !headerDescHasScroll
        }"
      ></i>
    </div>
  </div>
  <div *ngIf="retry; else print" class="popup-footer">
    <button
      type="submit"
      class="
        background-positive-button-color
        positive-button-text-color
        text-center
        positive-btn
      "
      (click)="close('retry')"
    >
      {{retryText|uppercase}}
    </button>
    <button type="submit" class="cancel" (click)="close('cancel')">
      {{cancelText|uppercase}} / {{backToCartText|uppercase}}
    </button>
  </div>
  <ng-template #print>
    <div
      style="
        display: flex;
        flex-direction: column;
        margin-top: 8vh;
        align-items: center;
      "
    >
      <button
        type="submit"
        class="
          background-positive-button-color
          positive-button-text-color
          text-center
          positive-btn
        "
        (click)="close('print')"
      >
        {{okText}}
      </button>
    </div>
  </ng-template>
</div>
