import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonFunctions } from 'src/app/services/common';
import { DataService } from 'src/app/services/data.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { ModifierV2, ModifierIngredientV2 } from '../../../models/item';
import { GeneralSetting } from '../../../services/generalSetting.service';

@Component({
  selector: 'app-modifier',
  templateUrl: './modifier.component.html',
  styleUrls: ['./modifier.component.css']
})
export class ModifierComponent implements OnInit, OnDestroy {

  @Input() ingredients: any[] = [] as any[];

  @Input() modifierIndex: number = 0;

  @Input() modifier: ModifierV2 = {} as ModifierV2;

  @Input() ItemType: number = 1;

  @Input() ItemName: string = "";

  @Input() ItemDescription: string = "";

  @Output() modifierChange: EventEmitter<{ modifier: ModifierV2, index: number }> = new EventEmitter<{ modifier: ModifierV2, index: number }>();

  @Output() SelectedItem: EventEmitter<any> = new EventEmitter<any>();

  primaryColor: string;
  positiveColor: string;
  CollapseOptionalModifiersGroups: boolean = false;
  negativeColor: string;

  // used for collapse logic
  collapsed: boolean = false;

  showDropDown: boolean = true;

  baseImageUrl = this.isKioskService.isKiosk() ? environment.imageBaseUrl : environment.imageBaseUrlRemote;

  imageRadius: string = "";
  bordersTypeModifire: boolean = false;
  placeholderImage: string = '';

  showAggregateRule: boolean = false;

  languageSub!: Subscription
  requiredText: string = ''
  maxSelectionsText: string = ''
  deselectText: string = ''
  isAggregate = false;

  constructor(private isKioskService: IsKioskService, private language: LanguageService, private readonly dataService: DataService) {
    this.primaryColor = GeneralSetting.getPrimaryColor();
    this.positiveColor = GeneralSetting.getPositiveColor();
    this.negativeColor = GeneralSetting.getNegativeColor();
  }
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe()
    }
  }
  loadText() {
    this.requiredText = this.language.getTextElement('txt_trequired')
    this.maxSelectionsText = this.language.getTextElement('maximum_number_of_selections_error_msg_a')
    this.deselectText = this.language.getTextElement('maximum_number_of_selections_error_msg_b')
  }
  ngOnInit(): void {
    this.isAggregate = this.modifier.UseAdvancedModifierQuantitySettings == 'True';
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText()
    })
    if (this.modifier) this.ingredients = this.modifier.Ingredients;
    this.imageRadius = GeneralSetting.getBorderPercentage();
    this.bordersTypeModifire = GeneralSetting.getBorderTypeModifiers().toLowerCase() == "true";

    this.collapsed = this.modifier.IsStartinCollapsedMode ?
      this.modifier.IsStartinCollapsedMode.toLowerCase() == 'true' :
      false;
    if (!this.collapsed) {
      this.collapsed = !(GeneralSetting.getCollapseOptionalModifiersGroups() == '2');

      if (GeneralSetting.getCollapseOptionalModifiersGroups() == '1') {
        if (this.modifier.min > 0 || this.modifier.IsForced.toLowerCase() == 'true') {
          this.collapsed = false;
        }
      }
    }
    this.getModifierRules();
  }

  selectedItem() {
    this.SelectedItem.emit();
  }

  parentChange(event: ModifierV2) {
    this.modifier = event;

    if (event.UseAdvancedModifierQuantitySettings == 'True') {
      this.showAggregateRule = CommonFunctions.getOnlySelectedIngredientCount(event) > 0
    }
    this.cancelDefault(); // To collapse the container
    this.getModifierRules();
    this.modifierChange.emit({ modifier: this.modifier, index: this.modifierIndex });
  }

  // by default a modifier should be open, only collapse once a user has clicked
  cancelDefault() {
    if (this.modifier.IsModifier86 == 'False') return;
    if (this.modifier.UseAdvancedModifierQuantitySettings == 'False') {
      this.collapsed = this.modifier.max == this.modifier.count;
    } else {
      this.collapsed = Number(this.modifier.MaxAggregateQty) == this.modifier.countAggregate;
    }
  }

  filterData() {
    let selectedItemNames: any[] = [] as any[];

    let data = this.ingredients.filter(obj => obj.IsSelected) as (ModifierIngredientV2 | ModifierV2)[];
    for (var i = 0; i < data.length; i++) {

      if (data[i].IsIngredient) {
        let ing = <ModifierIngredientV2>data[i];
        selectedItemNames.push({name:ing.Name,price:ing.ExtraPrice});
      }
      else {
        let mod = <ModifierV2>data[i];
        selectedItemNames.push({name:mod.Category,price:mod.Price});
      }
    }
    return selectedItemNames;
  }

  collapse() {
    if (this.modifier.IsModifier86 == 'False') return;
    this.showDropDown = false;
    this.collapsed = !this.collapsed;
  }

  rules = ""
  isRequired = false;
  isCompleted = false;

  /**
   * Get modifier rule based on min max aggregate @nilesh
   */
  getModifierRules() {
    if (!this.isAggregate) {
      if (this.modifier.rule != '') {
        this.rules = this.modifier.rule;
        this.isRequired = false;
        this.isCompleted = false;
      } else {
        this.isRequired = false;
        this.isCompleted = false;
        this.rules = '';
      }
    } else {
      const selectedIngredients = CommonFunctions.getOnlySelectedIngredientCount(this.modifier);
      if (selectedIngredients > 0) {
        if (this.modifier.countAggregate <= this.modifier.MaxAggregateQty
          && this.modifier.countAggregate >= this.modifier.MinAggregateQty) {
          this.rules = 'Complete'
          this.isRequired = false;
          this.isCompleted = true;
        } else {
          this.rules = 'Required';
          this.isCompleted = false;
          this.isRequired = true;
        }

      } else {
        if (this.modifier.rule != '') {
          this.rules = this.modifier.rule;
          this.isRequired = false;
          this.isCompleted = false;
        } else {
          this.rules = '';
          this.isRequired = false;
          this.isCompleted = false;
        }
      }
    }
  }

}