import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ItemV2 as Item, ItemV2, ModifierV2 } from 'src/app/models/item';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatabaseService } from 'src/app/services/database.service';
import { environment } from 'src/environments/environment';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { AlcoholicOver21DialogComponent } from 'src/app/components/dialogs/alcoholic-over21-dialog/alcoholic-over21-dialog.component';
import { AlcoholicItemExceedMaxComponent } from 'src/app/components/dialogs/alcoholic-item-exceed-max/alcoholic-item-exceed-max.component';
import { UpsellComboMessageComponent } from 'src/app/components/dialogs/upsell-combo-message/upsell-combo-message.component';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import {
  CategorySalesType,
  CommonFunctions,
  Snackbar,
} from 'src/app/services/common';
import { ComboItemPopupComponent } from 'src/app/components/combo-item-popup/combo-item-popup.component';
import { OutOfStockComponent } from '../../out-of-stock/out-of-stock.component';
import { SoldOutComponent } from '../sold-out/sold-out.component';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { DataService } from 'src/app/services/data.service';
import { CurrencyService } from 'src/app/services/currency.service';

@Component({
  selector: 'app-up-sell-dialog',
  templateUrl: './up-sell-dialog.component.html',
  styleUrls: ['./up-sell-dialog.component.css'],
})
export class UpSellDialogComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  languageSub!: Subscription;

  upsellHeaderText: string | null = this.language.getCustomMapValues('menu_setting_display__upsell_modal_main_text');

  upSellItems: Item[] = [] as Item[];

  itemId: string = '';

  modalReference: any;

  negativeColor: string = '';

  negativeColorText: string = '';

  positiveColor: string = '';

  positiveColorText: string = '';

  priceColor: string | null = '';

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  itemsForCart = [] as Item[];

  itemType: number = 1;

  currAlcoholAmount: number = 0;

  maxAlcoholAmount: number = 9999;

  parentItem: ItemV2 = new ItemV2();

  imageRadius: string = '';

  bordersTypeItem: boolean = false;

  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();

  parentQuantity: number = 1;

  addOnItemText: string = '';

  addToOrderText: string = '';

  noThanksText: string = '';

  placeholderImage: string = '';

  textColor: string = '';

  maxLimitText: string = 'Maximum limit reached';

  constructor(
    private readonly modalService: NgbModal,
    private readonly databaseService: DatabaseService,
    private isKioskService: IsKioskService,
    private language: LanguageService,
    private readonly dataService: DataService,
    private CurrencyService: CurrencyService
  ) { }

  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.getUpsellItemsV2();
    this.negativeColor = GeneralSetting.getNegativeColor();
    this.negativeColorText = GeneralSetting.getNegativeColorText();
    this.positiveColor = GeneralSetting.getPositiveColor();
    this.positiveColorText = GeneralSetting.getPositiveColorText();
    this.priceColor = GeneralSetting.getPriceColor();
    this.textColor = GeneralSetting.getTextColor();
    this.upsellHeaderText =
      GeneralSetting.getUpSellHeaderText() == ''
        ? this.upsellHeaderText
        : GeneralSetting.getUpSellHeaderText();
    this.imageRadius = GeneralSetting.getBorderPercentage();
    this.bordersTypeItem =
      GeneralSetting.getBorderTypeItems().toLowerCase() == 'true';
  }

  loadText() {
    this.addOnItemText = this.language.getCustomMapValues('menu_setting_display__upsell_modal_header_text');
    this.addToOrderText = this.language.getTextElement('lbl_rec_add_to_order');
    this.noThanksText = this.language.getTextElement('lbl_rec_no_thanks');
    this.maxLimitText = this.language.getTextElement('maximum_limit_reached');
  }

  getDiscountedPrice(item: any): number {

    return item.Price - item.DiscountAmount;

  }

  getItemPrice(item: any): string {
    let currencySymbol = this.CurrencyService.CurrencySymbol;
    return item.DiscountAmount > 0 ? `(${currencySymbol}${item.Price})` : `${currencySymbol}${item.Price}`;
  }

  async getUpsellItemsV2() {
    this.maxAlcoholAmount = Number(
      GeneralSetting.getMaxAlcoholicItemsPerOrder()
    );

    if (
      this.parentItem.ItemCategorySalesTypeID ===
      CategorySalesType.Alcohol.toString()
    ) {
      this.currAlcoholAmount =
        Number(GeneralSetting.getCurrentCountofAlkol()) +
        Number(this.parentQuantity);
    } else {
      this.currAlcoholAmount = Number(GeneralSetting.getCurrentCountofAlkol());
    }

    const upSellItems = await this.databaseService.getUpSellItemsByItemId(
      this.itemId
    );
    var newupSellItems: ItemV2[] = [];
    if (upSellItems && upSellItems.length > 0) {
      // Added because if only we have single item then alcohol count becomes wrong - Rekha
      if (upSellItems.length == 1) {
        const currUpsellQty =
          upSellItems[0].Quantity == undefined ? '1' : upSellItems[0].Quantity;
        this.currAlcoholAmount += Number(currUpsellQty);
      }

      var parentQty =
        this.parentItem.Quantity == undefined ? '1' : this.parentItem.Quantity;
      for (let j = 0; j < upSellItems.length; j++) {
        const item = upSellItems[j];
        // Condition to ignore alcohol item if already satisfies alcohol limit - Rekha
        if (
          !(
            this.currAlcoholAmount > this.maxAlcoholAmount &&
            item.ItemCategorySalesTypeID ===
            CategorySalesType.Alcohol.toString() &&
            this.parentItem.ItemCategorySalesTypeID ===
            CategorySalesType.Alcohol.toString()
          )
        ) {
          if (
            !(
              this.currAlcoholAmount + Number(item.Quantity) >
              this.maxAlcoholAmount &&
              item.ItemCategorySalesTypeID ===
              CategorySalesType.Alcohol.toString()
            )
          ) {
            if (!item.SoldOut) {
              const isCombo = item.IsCombo.toString();

              if (isCombo.toLowerCase() == 'true') {
                const comboItem =
                  await this.databaseService.getPersistentComboGroupItemsAsync(
                    item.ItemID
                  );
                item.ComboGroup = comboItem;
              }

              newupSellItems.push(item);
            }
          }
        }
      }
    }

    this.upSellItems = newupSellItems;
    if (this.upSellItems.length <= 0) this.dismiss();
  }

  private addUpSellOpenItemPopup() {
    if (CommonFunctions.isItemHasModifier(this.upSellItems[0])) {
      const strItemType = CommonFunctions.getItemTypeV2(this.upSellItems[0]);
      let itemType = 1;

      if (strItemType == 'ItemBuilderMode') {
        itemType = 2;
      }

      let cipm = ComboItemPopupComponent.open(
        this.modalService,
        this.upSellItems[0],
        itemType
      );
      cipm.result.then((result: Item) => {
        this.itemsForCart.push(result);
        this.modalReference.close(this.itemsForCart);
      });
    } else {
      this.itemsForCart = this.upSellItems;
      this.modalReference.close(this.itemsForCart);
    }
  }

  private customizePopupOpen(elm: HTMLInputElement, item: Item) {
    let ucmc = UpsellComboMessageComponent.open(this.modalReference);
    ucmc.result.then((data: any) => {
      if (data === 'continue') {
        if (
          item.ItemCategorySalesTypeID == CategorySalesType.Alcohol.toString()
        ) {
          this.onAlcoholicItem(elm, item);
        } else {
          this.openItemPopup(elm, item);
        }
      } else {
        elm.checked = false;
        return;
      }
    });
  }

  flag: boolean = false;
  private openItemPopup(elm: HTMLInputElement, item: Item) {
    if (this.flag) {
      return;
    }
    const inputItem = JSON.parse(JSON.stringify(item));

    const itemTypeStr = CommonFunctions.getItemTypeV2(item);
    let itemType = 1;

    if (itemTypeStr == 'ItemBuilderMode') {
      itemType = 2;
    }
    this.flag = true;

    let cipm = ComboItemPopupComponent.open(
      this.modalService,
      inputItem,
      itemType
    );

    cipm.result
      .then((data: any) => {
        if (data == 'cancel') {
          elm.checked = false;
        } else {
          const upsellItem = data as Item;
          let copy = this.itemsForCart.filter(
            (x) => x.ItemID == upsellItem.ItemID
          );
          if (copy.length) {
            this.itemsForCart = this.itemsForCart.filter(
              (x) => x.ItemID != upsellItem.ItemID
            );
          }
          if (
            upsellItem.ItemCategorySalesTypeID ==
            CategorySalesType.Alcohol.toString()
          ) {
            this.currAlcoholAmount += 1;
          }

          this.itemsForCart.push(upsellItem);
        }
      })
      .finally(() => {
        this.flag = false;
      });
  }

  private onAlcoholicItem(elm: HTMLInputElement, item: Item) {
    let ao21m = AlcoholicOver21DialogComponent.open(this.modalService);
    ao21m.result.then((data: any) => {
      if (data === 'over') {
        if (!this.checkCurrAlcoholMax(elm)) {
          elm.checked = true;
          if (CommonFunctions.isItemHasModifier(item)) {
            this.openItemPopup(elm, item);
          } else {
            if (
              item.ItemCategorySalesTypeID ==
              CategorySalesType.Alcohol.toString()
            ) {
              this.currAlcoholAmount += 1;
            }

            this.itemsForCart.push(item);
          }
        }
      } else {
        elm.checked = false;
      }
    });
  }

  private checkCurrAlcoholMax(elm: any) {
    if (this.maxAlcoholAmount == this.currAlcoholAmount) {
      AlcoholicItemExceedMaxComponent.open(this.modalService);
      elm.checked = false;
      return true;
    }
    return false;
  }

  async onItemSelect(e: Event, item: Item) {
    const elm = e.target as HTMLInputElement;
    const variations = await this.databaseService.getPersistentVariationsAsync(
      item.ItemID
    );
    item.Variations = variations;
    const modifier = await this.databaseService.getPersistentModifiersByIDAsync(
      item.ItemID
    );
    item.Modifiers = modifier;
    if (
      this.maxAlcoholAmount == this.currAlcoholAmount &&
      item.ItemCategorySalesTypeID == CategorySalesType.Alcohol.toString()
    ) {
      AlcoholicItemExceedMaxComponent.open(this.modalService);
      elm.checked = false;
      return;
    }

    if (!CommonFunctions.isItemHasModifier(item)) {
      const newItem = JSON.parse(JSON.stringify(item));

      if (
        item.ItemCategorySalesTypeID == CategorySalesType.Alcohol.toString()
      ) {
        this.onAlcoholicItem(elm, item);
      } else {
        this.addItemsInCart(item);
      }
    } else {
      // this.customizePopupOpen(elm, item);
      if (
        item.ItemCategorySalesTypeID == CategorySalesType.Alcohol.toString()
      ) {
        this.onAlcoholicItem(elm, item);
      } else {
        this.openItemPopup(elm, item);
      }
    }
  }

  private addItemsInCart(item: Item) {
    if (!this.itemsForCart) this.itemsForCart = [] as Item[];

    if (this.itemsForCart.length > 0) {
      let hasPreviousItem = this.itemsForCart.filter(
        (x) => x.ItemID == item.ItemID
      );
      if (hasPreviousItem.length > 0) {
        return;
      }
    }

    this.itemsForCart.push(item);
  }

  private removeItemsFromUpsellCart(item: Item) {
    if (!this.itemsForCart) return;

    if (this.itemsForCart.length > 0) {
      this.itemsForCart = this.itemsForCart.filter(
        (x) => !(x.ItemID == item.ItemID && x.CategoryID == item.CategoryID)
      );
    }
  }

  onItemRemove(e: Event, item: Item) {
    e.preventDefault();
    e.stopPropagation();
    const cartItem = this.itemsForCart.find(
      (x) => x.ItemID == item.ItemID && x.CategoryID == item.CategoryID
    );

    if (!cartItem) return;

    if (cartItem.ItemCategorySalesTypeID === '4') {
      this.currAlcoholAmount -= Number(item.Quantity);
    }
    item.Quantity = '1';

    this.removeItemsFromUpsellCart(item);
  }

  checkItemChecked(itemId: any) {
    for (let i = 0; i < this.itemsForCart.length; i++) {
      if (this.itemsForCart[i].ItemID == itemId) {
        return true;
      }
    }
    return false;
  }

  increaseQuantity(e: Event, item: Item) {
    e.preventDefault();
    e.stopPropagation();

    let cartItem = this.itemsForCart.find(
      (x) => x.ItemID == item.ItemID && x.CategoryID == item.CategoryID
    );

    if (this.upSellItems.length == 1) {
      cartItem = this.upSellItems[0];
    }

    if (!cartItem) {
      //add item to itemsForCart
      this.onItemSelect(e, item)
      cartItem = this.itemsForCart.find(
        (x) => x.ItemID == item.ItemID && x.CategoryID == item.CategoryID
      );
    }
    if (!cartItem) return;

    if (
      cartItem.ItemCategorySalesTypeID == CategorySalesType.Alcohol.toString()
    ) {
      if (this.currAlcoholAmount >= this.maxAlcoholAmount) {
        Snackbar.show('Order Maximum alcohol limit reached', 2500);
        return;
      } else {
        this.currAlcoholAmount += 1;
      }
    }

    if (
      Number(item.OrderMaximumQuantity) != 0 &&
      Number(item.OrderMaximumQuantity) > Number(item.Quantity)
    ) {
      cartItem.Quantity = (Number(cartItem.Quantity) + 1).toString();
      item.Quantity = cartItem.Quantity;
      if (this.upSellItems.length == 1) {
        this.upSellItems[0].Quantity = cartItem.Quantity;
      }
    } else {
      Snackbar.show(this.maxLimitText, 2500);
    }
  }

  decreaseQuantity(e: Event, item: Item) {
    e.preventDefault();
    e.stopPropagation();

    let cartItem = this.itemsForCart.find(
      (x) => x.ItemID == item.ItemID && x.CategoryID == item.CategoryID
    );

    if (this.upSellItems.length == 1) {
      cartItem = this.upSellItems[0];
    }

    if (!cartItem) return;

    if (
      cartItem.ItemCategorySalesTypeID == CategorySalesType.Alcohol.toString()
    ) {
      // if (this.upSellItems.length == 1) {
      if (Number(cartItem.Quantity) > 1) {
        this.currAlcoholAmount -= 1;
        // }
      }
      // else{
      //   if (this.currAlcoholAmount >= 0) this.currAlcoholAmount -= 1;
      // }
    }

    if (Number(cartItem.Quantity) > 1) {
      cartItem.Quantity = (Number(cartItem.Quantity) - 1).toString();
    } else if (Number(cartItem.Quantity) == 1) {
      this.onItemRemove(e, item)
    }

    item.Quantity = cartItem.Quantity;
    if (this.upSellItems.length == 1) {
      this.upSellItems[0].Quantity = cartItem.Quantity;
    }
  }

  public static open(
    modalService: NgbModal,
    language: LanguageService,
    itemId: string,
    itemQty: number,
    itemType: number = 1,
    parentItem: ItemV2
  ) {
    let modalReference = modalService.open(UpSellDialogComponent, {
      backdrop: 'static',
      size: 'xl',
      centered: true,
    });

    let upSellHeaderText =
      GeneralSetting.getUpSellHeaderText() == ''
        ? language.getTextElement('title_recommendation')
        : GeneralSetting.getUpSellHeaderText();

    modalReference.componentInstance.itemId = itemId;
    modalReference.componentInstance.upsellHeaderText = upSellHeaderText;

    modalReference.componentInstance.itemType = itemType;
    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.parentItem = parentItem;
    modalReference.componentInstance.parentQuantity = itemQty;

    return modalReference;
  }

  open(
    itemId: string,
    itemQty: number,
    itemType: number = 1,
    parentItem: ItemV2
  ) {
    let modalReference = this.modalService.open(UpSellDialogComponent, {
      backdrop: 'static',
      size: 'xl',
      centered: true,
    });
    modalReference.componentInstance.itemId = itemId;
    modalReference.componentInstance.upsellHeaderText =
      this.upsellHeaderText == ''
        ? this.language.getTextElement('title_recommendation')
        : this.upsellHeaderText;
    modalReference.componentInstance.itemType = itemType;
    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.parentItem = parentItem;
    modalReference.componentInstance.parentQuantity = itemQty;
    this.modalReference = modalReference;
    return this.modalReference;
  }

  dismiss() {
    if (this.modalReference) this.modalReference.close('cancel');
  }

  async addUpSellItemToOrderV2() {
    if (this.itemsForCart.length === 0) {
      if (this.upSellItems.length == 1) {
        const variations =
          await this.databaseService.getPersistentVariationsAsync(
            this.upSellItems[0].ItemID
          );
        this.upSellItems[0].Variations = variations;
        const modifier =
          await this.databaseService.getPersistentModifiersByIDAsync(
            this.upSellItems[0].ItemID
          );
        this.upSellItems[0].Modifiers = modifier;

        CommonFunctions.checkForSoldOutV2(this.upSellItems[0]).then(
          (data: ModifierV2[]) => {
            if (data.length > 0) {
              if (GeneralSetting.getIsmDoNotAllowAddToCartKiosk() == 'True') {
                OutOfStockComponent.open(this.modalService, this.language, 1);
              } else {
                this.soldOutPath(data, this.upSellItems[0]);
              }
            } else {
              this.checkUpsellItem();
            }
          }
        );
      } else {
        let error = this.language.getTextElement('please_select_item');
        Snackbar.show(error, 3000);
      }
    } else {
      this.modalReference.close(this.itemsForCart);
    }
  }

  private checkUpsellItem() {
    if (
      this.upSellItems[0].ItemCategorySalesTypeID ==
      CategorySalesType.Alcohol.toString()
    ) {
      let ao21m = AlcoholicOver21DialogComponent.open(this.modalService);
      ao21m.result.then((data: any) => {
        if (data === 'over') {
          this.addUpSellOpenItemPopup();
          return true;
        }

        return false;
      });
    } else {
      this.addUpSellOpenItemPopup();
    }
  }

  // Show dialog of 'Allow Add item to cart'
  private soldOutPath(data: ModifierV2[], item: ItemV2) {
    let socm = SoldOutComponent.open(this.modalService, data, item);
    socm.result.then((data: string) => {
      if (data == 'continue') {
        this.checkUpsellItem();
      }
      //else{}
      //no else block
      //if reached, user selected back to menu
    });
  }
}
