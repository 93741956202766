import {
  ComboGroupV2 as ComboGroup,
  ItemV2 as Item,
  ModifierIngredientV2 as ModifierIngredient,
} from './item';

// For single Item
export interface OracleDiscount {
  DiscountId: string;
  DiscountAmountOrPercent: string;
}

export interface OracleMessage {
  GuestCheckID: string;
  GuestCheckNumber: number;
  GuestCheckSequenceNo: number;
  TotalsTotalDue: string;
  TotalsSubTotal: string;
  TotalsAutoSvcChgTotals: string;
  TotalsOtherTotals: string;
  TotalsTaxTotals: string;
  DiscAmountOrPercent: string;
  TotalsSubTotalWithDiscount: string;

  //Micros Integration fields
  CustomerName:string;
  ThirdPartyOrderNumber:number;
  ThirdPartyOrderSequenceNo:number;
}

export interface OracleResponse {
  Code: number;
  DisplayMessage: string;
  OrderInvoiceNo: string;
  Message: OracleMessage;
}


interface CalculateMessage {
  GuestCheckID: string;
  GuestCheckNumber: number;
  GuestCheckSequenceNo: number;
  TotalsTotalDue: string;
  TotalsSubTotal: string;
  TotalsAutoSvcChgTotals: string;
  TotalsOtherTotals: string;
  TotalsTaxTotals: string;
  DiscAmountOrPercent: string;
  TotalsSubTotalWithDiscount: string;
}

export class OracleItem {
  MiObjectNum: string;
  SpecialRequest: string;
  discount?: OracleDiscount;
  modifierslist: OracleModifier[] = [];
  qty: string;

  //Micros Integration Fields 
  extraComment:string ="";

  constructor(item: Item) {
    this.MiObjectNum = item.RefItemId || '0';

    this.SpecialRequest = "";
    if (item.GuestName && item.GuestName != "") {
      this.SpecialRequest = item.GuestName;
    }

    if (item.specialRequest && item.specialRequest != "") {
      if (this.SpecialRequest == "") {
        this.SpecialRequest += item.specialRequest || '';
      }
      else {
        this.SpecialRequest += "-" + (item.specialRequest || '');
      }
    }

    this.discount = {
      DiscountId: item.DiscountID?.toString() || '',
      DiscountAmountOrPercent: item.DiscountAmount?.toString() || '',
    };

    for (let mod of item.Modifiers) {
      for (let ing of mod.Ingredients) {
        if (ing.IsSelected) {
          this.modifierslist.push(
            new OracleModifier(ing as ModifierIngredient)
          );
        }
      }
    }

    this.qty = item.Quantity;
  }
}

export class OracleModifier {
  MiObjectNum: string;
  qty: string;

  constructor(mod: ModifierIngredient) {
    this.MiObjectNum = mod.RefIngredientId || '0';
    this.qty = mod.Quantity;
  }
}

// For single Combo[start]

export class OracleComboitem {
  MiObjectNum: string;
  qty: string;
  mainMenuItem: MainMenuItem[] = [];
  sideMenuitemlist: SideMenuItem[] = [];
  SpecialRequest: string;

  //Micros Integration Fields 
  extraComment:string ="";

  constructor(item: Item) {
    this.MiObjectNum = item.RefItemId || '0';

    this.qty = item.Quantity;

    this.SpecialRequest = "";

    if (item.GuestName && item.GuestName != "") {
      this.SpecialRequest = item.GuestName;
    }

    if (item.specialRequest && item.specialRequest != "") {

      if (this.SpecialRequest == "") {
        this.SpecialRequest += item.specialRequest || '';
      }
      else {
        this.SpecialRequest += "-" + (item.specialRequest || '');
      }
    }

    this.mainMenuItem.push(new MainMenuItem(item.ComboGroup[0]));

    for (let i = 1; i < item.ComboGroup.length; i++) {
      this.sideMenuitemlist.push(new SideMenuItem(item.ComboGroup[i]));
    }

  }
}

class MainMenuItem {
  MiObjectNum: string;
  modifierslist: OracleModifier[] = [];
  SpecialRequest: string;

  constructor(comboGroup: ComboGroup) {
    this.MiObjectNum = '0';
    for (let item of comboGroup.Items) {
      if (item.isSelected) {
        this.MiObjectNum = item.RefItemId || '0';
        for (let mod of item.Modifiers) {
          for (let ing of mod.Ingredients) {
            if (ing.IsSelected) {
              this.modifierslist.push(
                new OracleModifier(ing as ModifierIngredient)
              );
            }
          }
        }
      }
    }

    this.SpecialRequest = '';
  }
}

class SideMenuItem {
  MiObjectNum: string;
  modifierslist: OracleModifier[] = [];
  SpecialRequest: string;

  constructor(comboGroup: ComboGroup) {
    this.MiObjectNum = '0';
    for (let item of comboGroup.Items) {
      if (item.isSelected) {
        this.MiObjectNum = item.RefItemId || '0';
        for (let mod of item.Modifiers) {
          for (let ing of mod.Ingredients) {
            if (ing.IsSelected) {
              this.modifierslist.push(
                new OracleModifier(ing as ModifierIngredient)
              );
            }
          }
        }
      }
    }

    this.SpecialRequest = '';
  }
}

export class OracleOrder {
  OrderInvoiceNo: string = '';
  checkguestcount: string = '';
  checktablenumber: string = '';
  comboitemlist: OracleComboitem[] = [];
  customerName: string = '';
  discount?: OracleDiscount = {} as OracleDiscount;
  extraComment: string = '';
  isautofirecheck: boolean = false;
  menuitemlist: OracleItem[] = [];
  ordertype: string = '';
  paymentamount: string = '';
  tendernumber: string = '';
  tenderrefrence: string = '';
  tipAmountOrPercent: string = '';
  tipComment: string = '';
  tipTenderID: string = '';
  tipamount: string = '';
  
  //Micros Integration Fields 
  MenuLevel: number | null = null;
  EmpObjnumber:number | null = null;
  RevenueObjNum:number | null = null;
  TipID:number | null = null;
  TipsAmt: number |null = null;
  TableTend:number | null = null;
}
