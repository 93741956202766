import { Snackbar } from 'src/app/services/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralSetting } from '../../../services/generalSetting.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-ask-device-name',
  templateUrl: './ask-device-name.component.html',
  styleUrls: ['./ask-device-name.component.css'],
})
export class AskDeviceNameComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubscription.length > 0) {
      for (let i = 0; i < this.allSubscription.length; i++) {
        this.allSubscription[i].unsubscribe();
      }
    }
  }

  ref: any;

  allSubscription: Subscription[] = [];

  deviceName: string = '';

  isKiosk = this.isKioskService.isKiosk();

  nameDeviceText: string = '';

  nextText: string = '';

  skipText: string = '';

  registerDeviceNameText: string = '';

  keySub!:Subscription

  deviceRequiredText: string = '';

  constructor(
    private readonly modalService: NgbModal,
    private KeyboardService: KeyboardService,
    private readonly isKioskService: IsKioskService,
    private language: LanguageService
  ) {}

  ngOnInit(): void {
    this.allSubscription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }

  loadText() {
    this.nameDeviceText = 'Kiosk Name'
    this.skipText = this.language.getTextElement('skip');
    this.nextText = this.language.getTextElement('next');
    this.registerDeviceNameText = this.language.getTextElement(
      'name_your_device'
    );
    this.deviceRequiredText = this.language.getTextElement('device_required');
  }

  public static open(modalService: NgbModal) {
    let ref = modalService.open(AskDeviceNameComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });
    ref.componentInstance.ref = ref;
    return ref;
  }

  open() {
    this.ref = this.modalService.open(AskDeviceNameComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });
    this.ref.componentInstance.ref = this.ref;
  }

  doit2(){
    if(this.keySub){
      this.keySub.unsubscribe()
    }
    this.KeyboardService.newBoardSubject.next()
  }

  
  // used to open keyboard based on dialog required layout, maxlength and assign value to dialog/screen input field @jay
  doit(){  
    this.KeyboardService.manual=true
    this.KeyboardService.maxLength=50
    this.KeyboardService.clearSub.next()
    this.KeyboardService.open("shift");
    this.KeyboardService.stringSub.next()
    this.keySub=this.KeyboardService.stringSub.subscribe((val)=>{
      this.handledeviceNameChange(val);
    })
  }
  // to handle dialog/screen input field value as a first character and character after "." upercase
  handledeviceNameChange(val:string){
    this.ref.componentInstance.deviceName=val
    if(this.ref.componentInstance.deviceName == "" || (this.ref.componentInstance.deviceName).trim().slice(-1) == "."){
      this.KeyboardService.handleupperlowercase.next(true)
    }else{
      this.KeyboardService.handleupperlowercase.next(false)
    }
  }

  onSkip = () => {
    this.KeyboardService.KeyboardStatus = false;
    this.ref.close('');
  };

  onNext = () => {
    this.deviceName = this.deviceName.trim();
    if (!this.deviceName || this.deviceName == '') {
      Snackbar.show(this.deviceRequiredText, 3000);
      return;
    }
    GeneralSetting.setDeviceName(this.deviceName);
    this.KeyboardService.KeyboardStatus = false;
    this.ref.close(this.deviceName);
    this.doit2();
  };
  
  close(){
    this.ref.close("");
  }
}
