<div id="flex-pairing-body" class="top-level">
  <div id="flex-pairing-header" class="flex-pair-header"><p>{{ title }}</p></div>
  <div class="close" (click)="finish()">X</div>
  <div id="flex-pairing-image-container">
    <img
      class="flex-pair-image"
      src={{imageSrc}}
      alt=""
    />
  </div>
  <div>
    <div id="flex-pairing-bottom-text-a" class="flex-pair-body">
      {{ bodya }}
    </div>
    <div id="flex-pairing-bottom-text-b" class="flex-pair-body">
      {{ bodyb }}
    </div>
  </div>
  <div id="flex-pairing-button-bar" class="flex-pair-button-bar">
    <button *ngIf="buttonOneText" (click)="retry()" class="flex-pair-button-one">{{buttonOneText}}</button>
    <button *ngIf="buttonTwoText" (click)="finish()" class="flex-pair-button-two">{{buttonTwoText}}</button>
  </div>
</div>
