<!-- currently dismissed by click on the pop up -->
<div class="container printing">
  <!-- <div *ngIf="printerService.currentPrinter=='kitchen' && printerService.errorOnKitchen == false && printerService.errorOnCustomer == false"> -->
  <div *ngIf="state == 'kitchen'">
    <div class="majorText" text-center>{{sendingOrderText}}</div>
    <div class="middle-image">

    </div>
    <div class="minorText">
      {{pleaseWaitWhileSendingToKitchenText}}
    </div>
  </div>
  <!-- <div *ngIf="printerService.currentPrinter=='customer' && printerService.errorOnKitchen == false && printerService.errorOnCustomer == false"> -->
  <div *ngIf="state == 'customer'">
    <div class="majorText" text-center>{{printingReceiptText}}</div>
    <div class="middle-image">
    </div>
    <div class="minorText">{{pleaseWaitForReceiptText}}</div>
  </div>
  <!-- <div *ngIf="printerService.errorOnKitchen == true">
        <div class="middle-image hundredpercent"><img class="middle-image hundredpercent" src="assets/images/retrieve_connection_image.png"></div>
        <div class="majorText">There was an error sending your order to the kitchen. Please see a manager for help.</div>
    </div>
    <div *ngIf="printerService.errorOnCustomer == true">
        <div class="middle-image hundredpercent"><img class="middle-image hundredpercent" src="assets/images/retrieve_connection_image.png"></div>
        <div class="majorText">There was an error printing your receipt. Please see a manager for help.</div>
    </div> -->
</div>
