import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { CommonFunctions } from 'src/app/services/common';

@Component({
  selector: 'app-refund-success',
  templateUrl: './refund-success.component.html',
  styleUrls: ['./refund-success.component.css'],
})
export class RefundSuccessComponent implements OnInit, OnDestroy {
  ref!: NgbModalRef;
  refundedText: string = '';
  languageSub!: Subscription;
  
  constructor(
    private modalService: NgbModal,
    private language: LanguageService
  ) {}
  
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  
  loadText() {
    this.refundedText = this.language.getTextElement('msg_order_refunded');
  }

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.closeAfterDelay();
  }

  private async closeAfterDelay() {
    await CommonFunctions.delay(5000);
    if (this.ref && this.ref != null) {
      this.ref.close();
    }
  }

  public static open(modalService: NgbModal) {
    let ref = modalService.open(RefundSuccessComponent, {
      centered: true,
      size: 'md',
    });
    ref.componentInstance.ref = ref;
    return ref;
  }

  open() {
    this.ref = this.modalService.open(RefundSuccessComponent, {
      centered: true,
      size: 'md',
    });
    this.ref.componentInstance.ref = this.ref;
    return this.ref;
  }
}
