import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { LogService } from 'src/app/services/log.service';
import { DatabaseHandler } from '../DatabaseHandler';
import { paymentTypeData } from '../models/payment-type';

@Injectable({
  providedIn: 'root',
})
export class PaymentTypesService {
  isCashPromo: boolean = false;
  isCardPromo: boolean = false;
  payments: paymentTypeData[] = [];

  constructor(private logger: LogService) {}

  getPaymentTypes(): Observable<paymentTypeData[]> {
    return from(this.getPaymentTypesFromSql());
  }

  getPaymentTypes2() {
    return new Promise<paymentTypeData[]>((resolve: any, reject: any) => {
      this.getPaymentTypesFromSql().then((PaymentTypes) => {
        this.payments = PaymentTypes;
        resolve(this.payments);
      });
    });
  }

  getPaymentTypesFromSql() {
    return new Promise<paymentTypeData[]>((resolve: any, reject: any) => {
      const getThirdPartyPayments = `
      SELECT DISTINCT TPPBM.TenderId,TPPBM.ThirdPartyPaymentID, PaymentDisplayName, TPPM.PaymentTypeID
      FROM ThirdPartyPaymentBranchMappings AS TPPBM
      INNER JOIN ThirdPartyPaymentMasters AS TPPM
      ON TPPBM.ThirdPartyPaymentID = TPPM.ThirdPartyPaymentID
      `;

      const errorCallback = (statement: any, error: any) => {
        console.log('Payment Service, Error getPaymentTypesFromSql :- ', error);

        reject(error);
      };

      const showPayments = (tx: string, results: any) => {
        var data: paymentTypeData[] = Array.from(results.rows);
        if (this.isCashPromo) {
          resolve([data.find((x) => x.PaymentDisplayName == 'Pay with Cash')]);
        } else if (this.isCardPromo) {
          resolve([data.find((x) => x.PaymentDisplayName == 'Pay with Card')]);
        } else {
          resolve(data);
        }
      };

      DatabaseHandler.executeSqlStatement(
        getThirdPartyPayments,
        [],
        showPayments,
        errorCallback
      );
    });
  }

  getCardPayment() {
    return new Promise<paymentTypeData[]>((resolve: any, reject: any) => {
      const getThirdPartyPayments = `
      SELECT DISTINCT TPPBM.TenderId,TPPBM.ThirdPartyPaymentID, PaymentDisplayName,TPPM.Name as PaymentTypeName, TPPM.PaymentTypeID
      FROM ThirdPartyPaymentBranchMappings AS TPPBM
      INNER JOIN ThirdPartyPaymentMasters AS TPPM
      ON TPPBM.ThirdPartyPaymentID = TPPM.ThirdPartyPaymentID where TPPM.PaymentTypeID='2'
      `;

      const errorCallback = (statement: any, error: any) => {
        console.log('Payment Service, Error getPaymentTypesFromSql :- ', error);

        reject(error);
      };

      const successCallback = (tx: string, results: any) => {
        var data: paymentTypeData[] = Array.from(results.rows);
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getThirdPartyPayments,
        [],
        successCallback,
        errorCallback
      );
    });
  }
}
