import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { Idle, DEFAULT_INTERRUPTSOURCES, AutoResume } from '@ng-idle/core';
import { CartService } from '../../services/cart.service';
import { GeneralSetting } from '../../services/generalSetting.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { ShippingService } from 'src/app/services/shipping.service';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { SetDefaultService } from 'src/app/services/set-default.service';
import { LoyaltyType } from 'src/app/models/loyaltyModal';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.css']
})
export class TimeoutComponent implements OnInit, OnDestroy {

  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];


  closeResult = '';
  orderType: boolean = false;
  modalReference: any;

  time: number = 11;
  tick: number = 1000;
  idleState = 'Not started.';
  lastPing?: Date = undefined;
  title = 'angular-idle-timeout';
  modalOpen: boolean;
  constructor(
    public idle: Idle,
    private cartService: CartService,
    private modalService: NgbModal,
    private router: Router,
    private readonly logger: LogService,
    private shipping: ShippingService,
    private loyalty: LoyaltyService,
    private key: KeyboardService,
    private setDefault: SetDefaultService, private language: LanguageService
  ) {
    this.modalOpen = false;
    idle.setAutoResume(AutoResume.notIdle);

    this.allSubsCription.push(
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          this.idle.stop();
        }
        if (event instanceof NavigationEnd) {
          this.startTimer();
        }
        if (event instanceof NavigationError) {
          //console.log(event.error);
        }
      })
    );
    // this.timerHandler();
    //this.startTimer()
  }

  startTimer(isHidden: boolean = false) {
    if (
      window.location.href.includes('menu') ||
      window.location.href.includes('item') ||
      window.location.href.includes('Builder') ||
      window.location.href.includes('Item') ||
      window.location.href.includes('orderReview') ||
      window.location.href.includes('lastfiveorder') ||
      (window.location.href.includes('touch') && (this.modalService.hasOpenModals() || isHidden)) ||
      window.location.href.includes('SearchScannable') ||
      window.location.href.includes('ConcessionaireGrid')
    ) {
      this.idle.watch();
      if (!(this.idle.onIdleStart.observers.length === 1)) {
        this.allSubsCription.push(
          this.idle.onIdleStart.subscribe(() => {
            this.idleState = "You've gone idle!";
            //check if modal already open

            if (this.modalOpen === false && !this.modalService.hasOpenModals()) {
              //this.modalOpen = true;
              this.open();
            }
            this.idle.setIdle(1);
          })
        );
      }
    }
    if (!(this.idle.onTimeoutWarning.observers.length === 1)) {

      this.allSubsCription.push(
        this.idle.onTimeoutWarning.subscribe((countdown: any) => {
          this.idleState = 'You will time out in ' + countdown + ' seconds!';
        })
      );
    }
    if (!(this.idle.onIdleEnd.observers.length === 1)) {
      this.allSubsCription.push(
        this.idle.onIdleEnd.subscribe(() => {
          this.idleState = 'No longer idle.';
          this.modalOpen = false;
        })
      );
    }
    if (!(this.idle.onTimeout.observers.length === 1)) {
      this.allSubsCription.push(

        this.idle.onTimeout.subscribe(() => {
          this.idleState = 'Timed out!';
          try {
            var log = new loggingData(
              'Order Cancel',
              'Order Cancelled due to timeout ',
              'Order Cancel',
              JSON.stringify(this.cartService.getCartItems()),
              false
            );
            this.logger.sendLogToServer(log);
          } catch (e: any) {
            if (e.message.includes('btoa')) {
              var log = new loggingData(
                'Order Cancel Logging Error',
                'Order Cancelled due to timeout ',
                'Order Cancel Logging Error',
                `Error Logging "JSON.stringify(this.cartService.getCartItemsV2())": ${e.message}`,
                false
              );
              this.logger.sendLogToServer(log);
            } else {
              var log = new loggingData(
                'Order Cancel Unknown Error',
                'Order Cancelled due to timeout ',
                'Order Cancel Unknown Error',
                `Unknown Error Logging "JSON.stringify(this.cartService.getCartItemsV2())": ${e}`,
                false
              );
              this.logger.sendLogToServer(log);
            }
          }
          this.modalOpen = false;
          this.startOver();
        })
      );
    }
  }
  heyText: string = ''
  areYouStillHereText: string = ''
  stillHereText: string = ''
  startOverText: string = ''
  loadText() {
    this.heyText = this.language.getTextElement('txt_hey')
    this.areYouStillHereText = this.language.getTextElement('txt_are_you_still_there')
    this.stillHereText = this.language.getTextElement('i_am_still_here')
    this.startOverText = this.language.getTextElement('start_over')
  }
  ngOnInit(): void {
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText()
      })
    )
    this.allSubsCription.push(
      timer(0, this.tick).subscribe(() => {
        --this.time;
      })
    );
  }

  startOver() {
    if (GeneralSetting.getUserHasPaid() != 'True') {
      this.cartService.clearCart();
    }
    if (this.loyalty.loyaltyType == LoyaltyType.Referrizer) {
      if (this.loyalty.selectedLoyaltyReward) {
        let r = this.loyalty.loyaltyRewards.filter((x: any) => {
          return x.id == this.loyalty.selectedLoyaltyReward
        })
        if (r) {
          this.loyalty.updateLoyaltyPoints(Number(r[0].points))
        }
        this.loyalty.subSelectedLoyaltyReward.next('')
        this.loyalty.selectedLoyaltyReward = ''
      }
    }
    GeneralSetting.removeFromMenuLogin();
    GeneralSetting.removeLastFiveOrderChosen()
    GeneralSetting.removeCheckOutAtLastFive();
    GeneralSetting.removeCustomerLoginEmail();
    GeneralSetting.removeCustomerLoginMobile();
    GeneralSetting.removeCustomerLoginName();
    GeneralSetting.removeCustomerId();
    GeneralSetting.removeOrderTypeId();
    this.setDefault.setDefault()
    this.key.KeyboardStatus = false
    this.shipping.shippingDetails = undefined
    var messageType = 'stopScanner';
    var message = {
      messageType: messageType,
    };
    window.top!.postMessage(message, '*');
    this.modalService.dismissAll('timeout');
    setTimeout(() => {
      if (!window.location.href.includes('touchToStart')) {
        this.router.navigateByUrl('/touchToStart');
      }
      this.cartService.closeOrderType.next(false);
    }, 300);
    // this.idle.stop();
  }

  stillHere(isHidden: boolean = false, orderType: boolean = false) {
    this.orderType = orderType;
    this.timerHandler();
    this.idleState = 'Started.';
    this.modalReference?.close();
    this.modalOpen = false;
    this.startTimer(isHidden);
  }

  timerHandler(): any {
    this.idle.setTimeout(this.orderType ? 30 : 10);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    // this.idle.setIdle(1000000)
    //dynamic time based on client settings
    if (window.location.href.includes('orderReview')) {
      this.idle.setIdle(
        Number(GeneralSetting.getCheckoutScreenTimeoutKIOSK()) > 0
          ? Number(GeneralSetting.getCheckoutScreenTimeoutKIOSK())
          : 1
      );
    } else {
      this.idle.setIdle(
        Number(GeneralSetting.getGeneralTimeoutKIOSK()) > 0
          ? Number(GeneralSetting.getGeneralTimeoutKIOSK())
          : 1
      );
    }
  }

  pad(time: number) {
    var size = 2;
    let s = time + '';
    while (s.length < size) s = '0' + s;
    return s;
  }

  open() {

    let tabButtonDiv: HTMLElement = document.getElementById('tabButtonDiv') as HTMLElement;

    console.log("tabButtonDiv :" + tabButtonDiv);

    if (tabButtonDiv == null) {

      this.modalReference = this.modalService.open(TimeoutComponent, {
        backdrop: 'static',
        centered: true,
        size: 'lg',
      });
      this.modalReference.componentInstance.modalReference = this.modalReference;
      this.modalReference.componentInstance.orderType = this.orderType;
      this.modalReference.componentInstance.time = this.orderType ? 31 : 11;
    }

    this.key.KeyboardStatus = false;

  }

  stopTimer() {
    this.idle.stop();
  }
}
