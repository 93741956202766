<h2>{{generalSettingsText}}</h2>
<div role="tabpanel" class="tab-pane active" id="device-info">
    <div class=" row mb-3rem">
        <div class="col-md-4 col-4">{{branchText}}</div>
        <div class="col-md-8 col-8  text-right" id="branchName">{{settings.branchName}}</div>
    </div>
    <div class="row mb-3rem">
        <div class="col-md-4 col-4 ">{{addressText}}</div>
        <div class="col-md-8 col-8 text-right" id="address">{{settings.address}}</div>
    </div>
    <div class="row mb-3rem">
        <div class="col-md-4 col-4">{{deviceNameText}}</div>
        <div class="col-md-8 col-8 text-right" id="username">{{deviceName}}</div>
    </div>
    <div class="row mb-3rem">
        <div class="col-md-4 col-4">{{currentIPText}}</div>
        <div class="col-md-8 col-8 text-right" id="currentIP">{{IP}}</div>
    </div>
    <div class="row mb-3rem">
        <div class="col-md-4 col-4">{{macIDText}}</div>
        <div class="col-md-8 col-8 text-right" id="MacAddress">{{macID}}</div>
    </div>
    <div class="row mb-3rem">
        <div class="col-md-4 col-4 ">{{appIDText}}</div>
        <div class="col-md-8 col-8 text-right" id="appId">{{appID}}</div>
    </div>
    <div class="row mb-3rem">
        <div class="col-md-4 col-4 ">{{currentWiFiText}}</div>
        <div class="col-md-8 col-8 text-right " id="currentWifi">{{wifi}}</div>
    </div>
    <div class="row mb-3rem">
        <div class="col-md-4 col-4">{{versionText}}</div>
        <div class="col-md-8 col-8 text-right currentVersion">{{version}}</div>
    </div>
    <div class="row mb-3rem">
        <div class="col-md-4 col-4">{{expireDateText}}</div>
        <div class="col-md-8 col-8 text-right " id="expiry-date">{{settings.expireDate}}</div>
    </div>
    <div class="row mb-3rem">
        <div class="col-md-4 col-4 ">{{phoneNoText}}</div>
        <div class="col-md-8 col-8  text-right" id="contact-no">{{settings.phoneNumber}}</div>
    </div>
    <div class="row mb-3rem">
        <div class="col-md-4 col-4 ">{{emailText}}</div>
        <div class="col-md-8 col-8 text-right" id="email-address"><span class="success">{{settings.email}}</span></div>
    </div>
    <div class="row mb-3rem">
        <div class="col-md-4 col-4">{{appVersionText}}</div>
        <div class="col-md-8 col-8 text-right appVersion">{{appVersion}}</div>
    </div>
    <div class="row mb-3rem">
        <div class="col-md-4 col-4">{{appEnvironmentText}}</div>
        <div class="col-md-8 col-8 text-right appVersion">{{appEnvironement}}</div>
    </div>
    <div class="row mb-3rem">
        <div class="col-md-4 col-4">{{io_configText}}</div>
        <div class="col-md-8 col-8 text-right appVersion">{{pusherCluster}} {{pusherAppId}}</div>
    </div>
    <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#customerpin">
    Customer Pin
    </button>-->
</div>
<!-- <button type="button" class="btn btn-primary upgrade-app" (click)="upgradeApp()" >
    <i class="fa fa-refresh" aria-hidden="true"></i> Upgrade Kiosk App 
</button> -->
<div class="row mb-3rem upgrade-app">
    <button style="text-transform: none;" type="button" class="btn btn-primary kiosk-setting-button" (click)="upgradeKioskApp()" >
        <i class="fa fa-upload" aria-hidden="true"></i>{{upgradeKioskAppText}}
    </button>
    <!-- <button (click)="pairFlex()">
      <i class="fa fa-sync"></i> {{pairFlexText}}
    </button> -->
  </div>