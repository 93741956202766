import { Injectable } from '@angular/core';
import {
  CardNetworkMaster,
  OracleGeneralSettings,
  OracleMicrosIntegration,
} from 'src/app/models/BranchLoginModels';
import { DatabaseHandler } from 'src/app/DatabaseHandler';
import { HttpConnection, IHttpHeaders } from 'src/app/services/http.service';
import { HttpClient } from '@angular/common/http';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import {
  OracleMessage,
  OracleComboitem,
  OracleDiscount,
  OracleItem,
  OracleOrder,
  OracleModifier,
} from 'src/app/models/OracleModels';
import {
  ItemV2 as Item,
  ModifierIngredientV2 as Ingredient,
  ModifierV2 as Modifier,
} from 'src/app/models/item';
import { GeneralSetting } from './generalSetting.service';
import { environment } from 'src/environments/environment';
import { loggingData, LogService } from './log.service';
import { CommonFunctions } from './common';
import { ImageService } from './image.service';
import { OrderDetailService } from './order-detail.service';
import { EPaymentType } from '../models/payment-type';
import { IntegrationService } from './integration.service';

@Injectable({
  providedIn: 'root',
})
export class OracleService {
  isOracle: boolean = false;

  isOracleMicross: boolean = false;

  oracleGeneralSetting: OracleGeneralSettings = {} as OracleGeneralSettings;

  oracleMicrosSetting: OracleMicrosIntegration = {} as OracleMicrosIntegration;

  calculationResponse: OracleMessage | null = null;

  cardNetWordDetails: CardNetworkMaster[] = [];

  private isKiosk: boolean = true;

  constructor(
    private http: HttpClient,
    checkKiosk: IsKioskService,
    private eatOption: ImageService,
    private logger: LogService,
    private readonly orderDetail: OrderDetailService,
    private integration: IntegrationService
  ) {
    this.isKiosk = checkKiosk.isKiosk();
    this.getAndSetDetail();
  }

  private getGenralSettingFromSql() {
    return new Promise<OracleGeneralSettings | null>(
      (resolve: any, reject: any) => {
        const getOracleSettings = `
        select ` +
          // APIEmployeeNumber,
          // DailyFullMenuRefresh,
          // FullMenuRefreshTime, 
          `GrubbrrAgentAPIHost, ` +
          // GrubbrrAgentPassword,
          // GrubbrrAgentUsername,
          // IsIgnoreCategoryItemMapping,
          // IsIgnoreCommonModifierItemMapping,
          // IsIgnoreIngredientCommonModifierMapping,
          // MainCategorySource,
          // POSDeviceID,
          // PropertyName,
          // RevenueCenterCodeRVC,
          // SimphonyAPIHost,          
          `TenderID,
          TipTenderID,
          ThirdPartyLoyaltyDiscountID,
          ThirdPartyLoyaltyDiscountName,
          ThirdPartyLoyaltyPaymentTenderID,
          ThirdPartyLoyaltyPaymentTenderName
          from OracleGeneralSetting
      `;

        const logError = (statement: any, error: any) => {
          resolve(null);
        };

        const setDetails = (transaction: String, results: any) => {
          if (results && results.rows.length > 0) {
            let data = Array.from(results.rows);

            resolve(data[0] as OracleGeneralSettings);
          } else {
            resolve(null);
          }
        };

        DatabaseHandler.executeSqlStatement(
          getOracleSettings,
          [],
          setDetails,
          logError
        );
      }
    );
  }

  private getMicrosSettingFromSql() {
    return new Promise<OracleMicrosIntegration | null>(
      (resolve: any, reject: any) => {
        const getOracleSettings = `
        select
          ID,
          TransactionAPIURL,
          RVC_Code,
          EmployeeObjectNumber,
          DBUserName
          DBUserPassword,
          ImportCategorySource,
          TenderID,
          TipTenderID,
          MenuLevelID,
          ThirdPartyLoyaltyDiscountID
        from 
          OracleMicrosIntegration
      `;

        const logError = (statement: any, error: any) => {
          resolve(null);
        };

        const setDetails = (transaction: String, results: any) => {
          if (results && results.rows.length > 0) {
            let data = Array.from(results.rows);

            resolve(data[0] as OracleMicrosIntegration);
          } else {
            resolve(null);
          }
        };

        DatabaseHandler.executeSqlStatement(
          getOracleSettings,
          [],
          setDetails,
          logError
        );
      }
    );
  }

  private async getAndSetMicrosSetting() {
    let oracleDetail = await this.getMicrosSettingFromSql();

    if (!oracleDetail) {
      this.isOracle = false;
      return;
    }

    if (
      oracleDetail.TransactionAPIURL == '' ||
      oracleDetail.MenuLevelID == '' ||
      oracleDetail.TenderID == ''
    ) {
      this.isOracle = false;
      this.isOracleMicross = false;

      this.logger.sendLogToServer(
        new loggingData(
          'Oracle Micros Error',
          'TransactionAPIURL Or MenuLevelID OR TenderID Not found',
          'Data Not found',
          JSON.stringify(oracleDetail),
          true
        )
      );
      return;
    }

    this.oracleMicrosSetting = oracleDetail;
    this.isOracle = true;
    this.isOracleMicross = true;
    this.integration.setMicros3700();
    return this.oracleMicrosSetting;
  }

  private async getAndSetDetail() {
    let oracleDetail = await this.getGenralSettingFromSql();

    if (!oracleDetail) {
      this.isOracle = false;

      await this.getAndSetMicrosSetting();
      return;
    }

    if ( oracleDetail.GrubbrrAgentAPIHost == '' || oracleDetail.SimphonyAPIHost == '' || oracleDetail.TenderID == '' ) {
      this.isOracle = false;

      this.logger.sendLogToServer(
        new loggingData( 'Oracle General Setting Error', 'GrubbrrAgentAPIHost Or SimphonyAPIHost OR TenderID Not found', 'Data Not found', JSON.stringify(oracleDetail), true )
      );
      return;
    }

    this.oracleGeneralSetting = oracleDetail;
    this.isOracle = true;
    this.integration.setSymphony();
    return this.oracleGeneralSetting;
  }

  private getTipOrPersentage(tip: any) {
    let tipAmountOrPercent = tip?.toString() || '0.0000';

    tipAmountOrPercent = Number(tipAmountOrPercent);

    if (tipAmountOrPercent) {
      tipAmountOrPercent = CommonFunctions.roundDigit(
        tipAmountOrPercent,
        4
      ).toFixed(2);
    } else {
      tipAmountOrPercent = '0.0000';
    }

    return tipAmountOrPercent;
  }

  private async getOrderDiscount(discount: any, promo: any) {
    let discountId = '';
    let discountAmount = '';
    if (
      promo &&
      Object.entries(promo).length > 0 &&
      promo.DiscountID &&
      promo.DiscountID != ''
    ) {
      if (discount && Number(discount.toString()) > 0) {
        discountId = await this.getDiscountRefIdFromId(promo.DiscountID);
        if (discountId && discountId != '') {
          discountAmount = discount?.toString();
        }
      }
    }

    return { discountId, discountAmount };
  }

  private getCheckTableTentNumber() {
    let orderType = GeneralSetting.getLocationPreference();

    if (this.isOracleMicross && orderType.includes('Dine')) {
      return '1';
    }

    return '0';
  }

  async getOrderData(
    orderInvoiceNo: string,
    orderTotal: number,
    paymentTypeId: string,
    cardNetworkId: string,
    cartItems: Item[],
    tip: any,
    discount: any,
    promo: any,
    reward: any
  ) {
    let menuItems: OracleItem[] = [];

    let comboItem: OracleComboitem[] = [];

    let tipTenderId = '';

    if (this.isOracleMicross) {
      menuItems = this.getMicrosOrderItems(cartItems);

      tipTenderId = this.oracleMicrosSetting.TipTenderID;
    } else {
      let oracleItems = this.getOracleItems(cartItems);

      menuItems = oracleItems.menuItems;
      comboItem = oracleItems.comboItem;

      tipTenderId = this.oracleGeneralSetting.TipTenderID;
    }

    let tenderDetail = await this.getTenderNumberAndRefrence(
      cardNetworkId,
      paymentTypeId
    );

    let tenderNumber = tenderDetail.tenderNumber;

    let tenderReference = tenderDetail.tenderRefrence;

    let customerName = await this.orderDetail.getCustomerNameForIntegration();

    let tipAmountOrPercent = this.getTipOrPersentage(tip);

    let paymentAmount = 0.0;

    if (this.calculationResponse != null) {
      orderTotal = Number(this.calculationResponse.TotalsTotalDue);

      if (!(Number(this.calculationResponse.TotalsOtherTotals) > 0)) {
        paymentAmount = CommonFunctions.roundDigit(Number(tipAmountOrPercent) + orderTotal,2);
      } else {
        paymentAmount = CommonFunctions.roundDigit(orderTotal,2);
      } 
    }
    else
    {
      paymentAmount = CommonFunctions.roundDigit(Number(tipAmountOrPercent) + orderTotal,2);
    }

    let discountRes = await this.getOrderDiscount(discount, promo);
    if (reward) {
      discountRes.discountAmount = reward.toString();
      discountRes.discountId = this.isOracleMicross
        ? this.oracleMicrosSetting.ThirdPartyLoyaltyDiscountID
        : this.oracleGeneralSetting.ThirdPartyLoyaltyDiscountID;
    }
    let discountId = discountRes.discountId;
    let discountAmount = discountRes.discountAmount;

    let discountObj = null;
    
    if ( discountId && discountId != '' && discountAmount && discountAmount != '' && Number(discountAmount) > 0 ) {
      discountObj = {
        amount: '',
        comment: '',
        tenderID: '',
        DiscountAmountOrPercent: discountAmount, //(Number(discountAmount)*-1).toString(),
        DiscountId: discountId,
      } as OracleDiscount;
    }

    if ( this.isOracleMicross && discountId && discountAmount && Number(discountAmount) > 0 ) {
      discountObj = {
        DiscountAmountOrPercent: discountAmount,
        DiscountId: discountId,//'231',
        DiscReference: '',
      };
    }

    return {
      OrderInvoiceNo: orderInvoiceNo,
      checkguestcount: '1',
      checktablenumber: this.getCheckTableTentNumber(),
      comboitemlist: comboItem,
      customerName: customerName,
      discount: discountObj,
      extraComment: '',
      isautofirecheck: this.isOracleMicross ? false : true,
      menuitemlist: menuItems,
      ordertype: this.getRefOrderTypeID(),
      paymentamount: paymentAmount.toString(),
      tendernumber: tenderNumber,
      tenderrefrence: this.isOracleMicross ? '' : tenderReference,
      tipAmountOrPercent: tipAmountOrPercent,
      tipComment: '',
      tipTenderID:
        Number(tipAmountOrPercent) && Number(tipAmountOrPercent) > 0
          ? this.isOracleMicross
            ? this.oracleMicrosSetting.TipTenderID
            : this.oracleGeneralSetting.TipTenderID
          : '',
      tipamount: this.isOracleMicross ? tipAmountOrPercent : null,
      TipID:
        Number(tipAmountOrPercent) && Number(tipAmountOrPercent) > 0
          ? this.isOracleMicross
            ? this.oracleMicrosSetting.TipTenderID
            : this.oracleGeneralSetting.TipTenderID
          : null,
      //Micros Specific fields
      RevenueObjNum: this.isOracleMicross ? 1 : null,
      MenuLevel: this.isOracleMicross
        ? this.oracleMicrosSetting.MenuLevelID
        : null,
      EmpObjnumber: this.isOracleMicross
        ? this.oracleMicrosSetting.EmployeeObjectNumber
        : null,
      TipsAmt: this.isOracleMicross ? tipAmountOrPercent : null,
      TableTend: this.isOracleMicross ? this.getMicrossTableTend() : null,
    } as OracleOrder;
  }

  private getRefOrderTypeID() {
    let orderTypeID = '';

    if (
      this.eatOption.options &&
      Object.entries(this.eatOption.options).length > 0
    ) {
      let refTypeID = this.eatOption.options.find(
        (x) => x.OrderTypeID == GeneralSetting.getOrderTypeId()
      );

      if (refTypeID) {
        orderTypeID = refTypeID.RefOrderTypeID;
      }
    }

    if (!orderTypeID || orderTypeID == '') {
      orderTypeID = GeneralSetting.getOrderTypeId();
    }

    return orderTypeID;
  }

  private getOracleItems(itemList: Item[]) {
    let comboItemList: OracleComboitem[] = [];
    let menuItemList: OracleItem[] = [];

    for (let item of itemList) {
      if (item.IsCombo) {
        comboItemList.push(new OracleComboitem(item));
      } else {
        menuItemList.push(new OracleItem(item));
      }
    }

    return {
      menuItems: menuItemList,
      comboItem: comboItemList,
    };
  }

  createOrder(orderData: OracleOrder) {
    var headers = [] as IHttpHeaders[];
    headers.push({
      key: 'Content-Type',
      value: 'application/Json',
    } as IHttpHeaders);

    let body = orderData;

    this.logger.sendLogToServer(
      new loggingData(
        `Oracle ${this.isOracleMicross ? 'Micross' : ''} Create Order Request`,
        `Oracle ${this.isOracleMicross ? 'Micross' : ''} Invoice No :- ${
          orderData.OrderInvoiceNo
        } Agent APi Create Order API Call`,
        'Request Log',
        JSON.stringify(body),
        true
      )
    );

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(
        this.isOracleMicross
          ? this.oracleMicrosSetting.TransactionAPIURL + '/api/values'
          : 'http://' +
              this.oracleGeneralSetting.GrubbrrAgentAPIHost +
              ':9000/api/values'
      )
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post();
  }

  calculateOrder(order: OracleOrder) {
    var headers = [] as IHttpHeaders[];
    headers.push({
      key: 'Content-Type',
      value: 'application/Json',
    } as IHttpHeaders);

    let body = order;

    this.logger.sendLogToServer(
      new loggingData(
        `Oracle ${
          this.isOracleMicross ? 'Micross' : ''
        } Calcualate Order Request`,
        `Oracle ${this.isOracleMicross ? 'Micross' : ''} Invoice No :- ${
          order.OrderInvoiceNo
        } APi Calculate Order API Call`,
        'Request Log',
        JSON.stringify(body),
        true
      )
    );

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(
        this.isOracleMicross
          ? this.oracleMicrosSetting.TransactionAPIURL + '/api/OrderCalculate'
          : 'http://' +
              this.oracleGeneralSetting.GrubbrrAgentAPIHost +
              ':9000/api/OrderCalculate'
      )
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post();
  }

  private async getTenderNumberAndRefrence(
    cardNetworkId: string,
    paymentTypeId: string
  ) {
    let tenderRefrence = '';

    let isCardPayment = GeneralSetting.getPaymentTypeID() == '2';
    let isLoyaltyTender = Number(paymentTypeId) == EPaymentType.LevelUp;
    if (!isLoyaltyTender) {
      let tenderNumber = await this.getThridPartyTenderNumber(
        isCardPayment,
        cardNetworkId,
        paymentTypeId
      );

      if (tenderNumber != '' && Number(tenderNumber) > 0) {
        tenderRefrence = await this.getTenderNameByTenderNumber(tenderNumber);
      } else {
        if (this.isOracleMicross) {
          tenderNumber = this.oracleMicrosSetting.TenderID;
        } else {
          tenderNumber = this.oracleGeneralSetting.TenderID;
        }

        if (tenderNumber != '') {
          tenderRefrence = await this.getTenderNameByTenderNumber(tenderNumber);
        }
      }

      return { tenderNumber, tenderRefrence };
    } else {
      if (this.isOracleMicross) {
        let tenderNumber = GeneralSetting.getTPLoyaltyPayment();
        let tenderReference = '';
        return { tenderNumber, tenderReference };
      } else {
        let tenderNumber =
          this.oracleGeneralSetting.ThirdPartyLoyaltyPaymentTenderID;
        let tenderReference =
          this.oracleGeneralSetting.ThirdPartyLoyaltyPaymentTenderName;
        return { tenderNumber, tenderReference };
      }
    }
  }

  private getTenderNameByTenderNumber(tenderNumber: string) {
    return new Promise<string>((res: Function) => {
      let sqlString = ` SELECT TenderName FROM OracleTenderTypes
                          WHERE TenderNumber = '${tenderNumber}'`;

      let errorCallback = (tx: string, result: any) => {
        res('');
      };

      let callback = (tx: string, result: any) => {
        let data = Array.from(result.rows) as any[];

        if (data.length > 0) {
          res(data[0].TenderName as string);
        } else {
          res('');
        }
      };

      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        callback,
        errorCallback
      );
    });
  }

  private getThridPartyTenderNumber(
    isCardPayment: boolean,
    cardNetworkId: string,
    paymentTypeId: string
  ) {
    return new Promise<string>((res: Function) => {
      let sqlString = ` SELECT TenderId FROM ThirdPartyCardNetworkBranchMappings
                          WHERE CardNetworkID = '${cardNetworkId}'`;

      if (!isCardPayment) {
        sqlString = ` SELECT TenderId FROM ThirdPartyCardNetworkBranchMappings
                          WHERE ThirdPartyPaymentID = '${paymentTypeId}'`;
      }

      let errorCallback = (tx: string, result: any) => {
        res('');
      };

      let callback = (tx: string, result: any) => {
        let data = Array.from<any>(result.rows);
        if (data.length > 0) {
          res(data[0].TenderID ?? data[0].TenderId  as string);
        } else {
          res('');
        }
      };

      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        callback,
        errorCallback
      );
    });
  }

  private getDiscountRefIdFromId(discountId: string) {
    return new Promise<string>((res: Function) => {
      let sqlString = ` SELECT RefDiscountID FROM DiscountMasters
                          WHERE DiscountID = '${discountId}' limit 1`;

      let errorCallback = (tx: string, result: any) => {
        res('');
      };

      let callback = (tx: string, result: any) => {
        let data = Array.from(result.rows) as any[];
        if (data.length > 0) {
          res(data[0].RefDiscountID as string);
        } else {
          res('');
        }
      };

      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        callback,
        errorCallback
      );
    });
  }

  SendCalculationResponseToBackend(response: any, invoiceNo: string) {
    var headers = [] as IHttpHeaders[];
    headers.push({
      key: 'Content-Type',
      value: 'application/Json',
    } as IHttpHeaders);
    headers.push({
      key: 'BranchID',
      value: GeneralSetting.getBranchId(),
    } as IHttpHeaders);
    headers.push({
      key: 'CompanyID',
      value: GeneralSetting.getCompanyId(),
    } as IHttpHeaders);

    let body = response;

    this.logger.sendLogToServer(
      new loggingData(
        'Oracle Send to Backend Order Calculate Response',
        `Oracle APi GetOracleOrderCalculateResponse
        Invoice No :- ${invoiceNo}
      `,
        'Request Log',
        JSON.stringify(body),
        true
      )
    );

    HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(environment.apiUrl + 'GetOracleOrderCalculateResponse')
      .addMethod('POST')
      .addHeader(headers)
      .addBody(body)
      .post()
      .toPromise()
      .then(
        (data) => {
          let str_data = '';

          try {
            str_data = JSON.stringify(data);
          } catch (e) {
            str_data = 'Un Known Error';
          }

          this.logger.sendLogToServer(
            new loggingData(
              'Oracle Send to Backend Order Calculate Response Success',
              `Oracle Send to Backend Invoice No :- ${invoiceNo}
          `,
              'Response Success Log',
              str_data,
              true
            )
          );
        },
        (error) => {
          let str_error = '';

          try {
            str_error = JSON.stringify(error);
          } catch (e) {
            str_error = 'Un Known Error';
          }

          this.logger.sendLogToServer(
            new loggingData(
              'Oracle Send to Backend Order Calculate Response Error',
              `Oracle Send to Backend Error Invoice No :- ${invoiceNo}
          `,
              'Response Error Log',
              str_error,
              true
            )
          );
        }
      );
  }

  private getCardNetworkDetailFromSql() {
    let sqlString = "select * from CardNetworkMasters";

    return DatabaseHandler.getDataFromQuery<any[]>(sqlString).toPromise();
  }

  async getCardNetWorkIdFromCardType(cardType: string) {
    cardType = cardType.toLowerCase();
    if(this.cardNetWordDetails.length ==0){
      this.cardNetWordDetails = await this.getCardNetworkDetailFromSql();
    }
    
    if (this.cardNetWordDetails.length > 0) {
      for (let i = 0; i < this.cardNetWordDetails.length; i++) {
        let cardTypes = this.cardNetWordDetails[i].NetworkNameText.toLowerCase().split(' ');

        if (cardTypes.length > 0) {
          if (cardTypes.indexOf(cardType) != -1) {
            return this.cardNetWordDetails[i].CardNetworkID;
          }
        }
      }
    }

    return '';
  }

  //Oracle Micross Methods
  private getMicrossTableTend() {
    let tableTend = -1;

    if (
      this.eatOption.options &&
      Object.entries(this.eatOption.options).length > 0
    ) {
      let refTypeID = this.eatOption.options.find(
        (x) => x.OrderTypeID == GeneralSetting.getOrderTypeId()
      );

      if (refTypeID && refTypeID.KioskTableTent.toLowerCase() == 'true') {
        tableTend = Number(GeneralSetting.getTableTentNumber());
      }
    }

    return tableTend != -1 ? tableTend : null;
  }

  private getMicrosOrderItems(itemList: Item[]) {
    let menuItemList: OracleItem[] = [];
    let isTableTentAdded = false;

    for (let item of itemList) {
      let oracleItem = this.getMicrosOrderItem(item, isTableTentAdded);

      menuItemList = menuItemList.concat(oracleItem.oracleItems);

      isTableTentAdded = oracleItem.isTableTendAdded as boolean;
    }

    return menuItemList;
  }

  private getMicrosOrderItem(item: Item, isTableTendAdded: boolean) {
    let extraCommentRes = this.getMicrosExtraCommentByItem(
      item,
      isTableTendAdded
    );

    let extraComment = extraCommentRes[0] as string;

    let askForName = '';

    if (item.GuestName && item.GuestName != '') {
      askForName = item.GuestName;
    }

    let specialRequest = CommonFunctions.getStringWithDash(
      extraComment,
      askForName
    );
    extraComment = specialRequest;

    let discount = null;

    if (
      item.DiscountID &&
      item.DiscountID > 0 &&
      item.DiscountAmount &&
      item.DiscountAmount > 0
    ) {
      discount = {
        DiscountId: item.DiscountID?.toString() || '',
        DiscountAmountOrPercent: item.DiscountAmount?.toString() || '',
      };
    }

    let qty = item.Quantity;

    let oracleModifier: OracleModifier[] = [];

    let modifierRes = this.getMicrosOrderItemModifier(item.Modifiers);

    oracleModifier = oracleModifier.concat(modifierRes.oracleModifier);

    let oracleItem: OracleItem[] = [];

    let resOracleItem = {
      MiObjectNum: item.RefItemId,
      SpecialRequest: specialRequest,
      discount: discount,
      extraComment: extraComment,
      modifierslist: oracleModifier,
      qty: qty ?? '1',
    } as OracleItem;

    oracleItem.push(resOracleItem);

    oracleItem = oracleItem.concat(modifierRes.oracleItem);

    return {
      oracleItems: oracleItem,
      isTableTendAdded: extraCommentRes[1],
    };
  }

  private getMicrosOrderItemModifier(modifier: (Modifier | Ingredient)[]) {
    let oracleModifier: OracleModifier[] = [];
    let oracleItem: OracleItem[] = [];

    if (!modifier || modifier.length <= 0) {
      return {
        oracleItem: oracleItem,
        oracleModifier: oracleModifier,
      };
    }

    for (let i = 0; i < modifier.length; i++) {
      if (modifier[i].IsSelected) {
        if (modifier[i].IsModifier) {
          let mod = modifier[i] as Modifier;

          let modifierRes = this.getMicrosOrderItemModifier(mod.Ingredients);

          oracleModifier = oracleModifier.concat(modifierRes.oracleModifier);
          oracleItem = oracleItem.concat(modifierRes.oracleItem);
        } else {
          let ing = modifier[i] as Ingredient;
          if (ing.IsItem.trim().toLowerCase() == 'true') {
            oracleItem.push(this.getIngredientAsMicrosItem(ing));
          } else {
            oracleModifier.push({
              MiObjectNum: ing.RefIngredientId,
              qty: ing.Quantity ?? '1',
            } as OracleModifier);
          }
        }
      }
    }

    return {
      oracleItem: oracleItem,
      oracleModifier: oracleModifier,
    };
  }

  private getIngredientAsMicrosItem(ing: Ingredient) {
    return {
      MiObjectNum: ing.RefIngredientId,
      SpecialRequest: '',
      discount: {} as OracleDiscount,
      extraComment: '',
      modifierslist: [] as OracleModifier[],
      qty: ing.Quantity ?? '1',
    } as OracleItem;
  }

  private getMicrosExtraCommentByItem(item: Item, isTableTendAdded: boolean) {
    let extraComment = item.specialRequest ?? '';

    if (
      item.AskForTentNumber &&
      item.AskForTentNumber != undefined &&
      item.AskForTentNumber.toString().toLowerCase() == 'true' &&
      item.TableTentNumber &&
      item.TableTentNumber != undefined &&
      item.TableTentNumber != ''
    ) {
      let prefix = GeneralSetting.getItemTableTentSpecialRequestPrefix();

      let itemTentNumber = '';

      if (GeneralSetting.getOrderTypeId().trim() == '2') {
        itemTentNumber = 'TO-GO';
      }

      if (item.TableTentNumber.trim() != '') {
        itemTentNumber = item.TableTentNumber;
      }

      isTableTendAdded = true;

      let prefieItemTentNumber = CommonFunctions.getStringWithDash(
        prefix,
        itemTentNumber
      );

      extraComment = CommonFunctions.getStringWithDash(
        prefieItemTentNumber,
        extraComment
      );

      // if (prefix != '' && itemTentNumber != '' && extraComment != '') {
      //   extraComment = prefix + '-' + itemTentNumber + '-' + extraComment;
      // } else if (prefix == '' && itemTentNumber != '' && extraComment != '') {
      //   extraComment = itemTentNumber + '-' + extraComment;
      // } else if (prefix != '' && itemTentNumber != '' && extraComment == '') {
      //   extraComment = prefix + '-' + itemTentNumber;
      // } else if (prefix == '' && itemTentNumber != '' && extraComment == '') {
      //   extraComment = itemTentNumber;
      // }
    }

    return [extraComment, isTableTendAdded];
  }
}