import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { ItemV2, ComboGroupV2 } from '../../../models/item';
import { GeneralSetting } from '../../../services/generalSetting.service';

@Component({
  selector: 'app-combogroup',
  templateUrl: './combogroup.component.html',
  styleUrls: ['./combogroup.component.css'],
})
export class CombogroupComponent implements OnInit {
  items = [] as ItemV2[];

  @Input() comboGroupIndex: number = 0;
  @Input() parentQuantity: number = 1;

  @Input() comboGroups: ComboGroupV2 = {} as ComboGroupV2;

  @Input() ItemType: number = 1;

  @Input() IsCategory: boolean = false;

  @Output() comboGroupChange: EventEmitter<{
    comboGroup: ComboGroupV2;
    index: number;
  }> = new EventEmitter<{ comboGroup: ComboGroupV2; index: number }>();

  @Output() SelectedItem: EventEmitter<any> = new EventEmitter<any>();
  languageSub!: Subscription;
  primaryColor: string;
  positiveColor: string;
  negativeColor: string;

  // used for collapse logic
  collapsed: boolean = false;
  showByDefault: boolean = true;
  showDropDown: boolean = true;

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  imageRadius: string = '';
  bordersTypeItem: boolean = false;
  placeholderImage : string = '';

  constructor(
    private readonly isKioskService: IsKioskService,
    private language: LanguageService,
    private readonly dataService : DataService
  ) {
    this.primaryColor = GeneralSetting.getPrimaryColor();
    this.positiveColor = GeneralSetting.getPositiveColor();
    this.negativeColor = GeneralSetting.getNegativeColor();
  }
  requiredText: string = '';
  maxSelectionsTextA: string = '';
  maxSelectionsTextB: string = '';
  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    if (this.comboGroups && Object.keys(this.comboGroups).length > 0)
      this.items = this.comboGroups.Items;
    this.imageRadius = GeneralSetting.getBorderPercentage();
    this.bordersTypeItem =
      GeneralSetting.getBorderTypeItems().toLowerCase() == 'true';
  }
  ngOnDestroy() {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {
    this.requiredText = this.language.getTextElement('txt_required');
    this.maxSelectionsTextA = this.language.getTextElement('maximum_number_of_selections_error_msg_a');
    this.maxSelectionsTextB = this.language.getTextElement('maximum_number_of_selections_error_msg_b');
  }
  selectedItem() {
    this.SelectedItem.emit();
  }

  parentChange(event: ComboGroupV2) {
    this.comboGroups = event;
    this.comboGroupChange.emit({
      comboGroup: this.comboGroups,
      index: this.comboGroupIndex,
    });
    this.collapsed = this.comboGroups.max == this.comboGroups.count;
  }

  // by default a modifier should be open, only collapse once a user has clicked
  cancelDefault() {
    this.showByDefault = false;
  }

  filterData() {
    const Name = [] as string[];

    const data = this.items.filter((obj) => obj.isSelected) as ItemV2[];
    for (let i = 0; i < data.length; i++) {
      Name.push(data[i].Name ?? '');
    }

    return Name;
  }

  collapse() {
    this.showDropDown = false;
    this.collapsed = !this.collapsed;
    this.showByDefault = false;
  }
}
