<div role="tabpanel" class="tab-pane" id="paymentsettings">
  <!--Payment Setting-->
  <div>
    <table class="table table-bordered health-table" >
      <thead>
        <tr>
          <td class="name">{{tableNameTD}}</td>
          <td class="details">{{tableDetailsTD}}</td>
          <td class="status">{{tableStatusTD}}</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let record of kioskHealthRecords">
          <td>
            {{record?.labelText}}
          </td>
          <td *ngIf="record.label !== 'lbl_receipt_printer'">
            {{record.value}}
          </td>
          <td *ngIf="record.label !== 'lbl_receipt_printer'">
            <div class=""> 
              <img src="../../../../assets/images/icon/{{record.severity}}.png" />
            </div>
          </td>
          <td colspan="2" style="padding: 0" *ngIf="record.label === 'lbl_receipt_printer'">
            <table style="width: 100%;">
              <tr *ngFor="let kioskStatus of record.details">
                <td style="width: 77.4%;">
                  {{kioskStatus.value}}
                </td>
                <td>
                  <img src="../../../../assets/images/icon/{{kioskStatus.severity}}.png" />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <p class="kiosk-health-check-date" *ngIf="kioskHealthCheck?.kioskHealthTime" >{{lastUpdatedText}} : {{kioskHealthCheck?.kioskHealthTime | date:'h:mm a, MMM d y'}}</p>
    <p class="kiosk-health-check-date" *ngIf="!kioskHealthCheck?.kioskHealthTime">{{lastUpdatedText}} : -</p>
  </div>
  <div style="margin-top: 20px;">
    <h2 class="kiosk-setting-button kiosk-health-check" (click)="kioskHealthClick()" > 
      <i class="fa fa-refresh" aria-hidden="true"></i>
      &nbsp;{{kioskHealthCheckText}}</h2> 
  </div>
</div>
