import { Injectable } from '@angular/core';
import { DatabaseHandler } from '../DatabaseHandler';
import { OrderSuccessPaymentMasterReq } from '../models/OrderSuccessPaymentMasterReq';
import { VEvoTransactionResData } from '../models/VerifoneEvoModels';
import {
  IVerifoneOrderRefundReq,
  IVerifoneTransactionResData,
} from '../models/VerifoneResponse';
import { CommonFunctions } from './common';
import { GeneralSetting } from './generalSetting.service';

@Injectable({
  providedIn: 'root',
})
export class OrderDetailService {
  constructor() { }

  public getOrderPaymentDetails(
    tx: any,
    total: number,
    orderInvoiceNo: string
  ): OrderSuccessPaymentMasterReq {
    let OrderSuccessPaymentID: number = 0;
    let retref: string = '';
    let maskpan: string = '';
    let PaymentGatewayID: string = GeneralSetting.getPaymentMethod();
    let AuthCode: string = '';
    let respcode: string = '';
    let emvTagData: string = '';
    let respstat: string = '';
    let ctrouid: string = '';
    let commcard: string = '';
    let cvvresp: string = '';
    let respProc: string = '';
    let respText: string = '';
    let avsresp: string = '';
    let authwid: string = '';
    let merchid: string = '';

    let invoiceNo = orderInvoiceNo.length < 6 ? orderInvoiceNo : orderInvoiceNo.substr(orderInvoiceNo.length - 6);

    if (PaymentGatewayID === '17') {
      let transaction = tx as IVerifoneTransactionResData;

      if (transaction.CTROUTD && transaction.CTROUTD != undefined) {
        ctrouid = transaction.CTROUTD;
      }
      else {
        ctrouid = "";
      }

      retref = ctrouid + '-' + transaction.INVOICE;

      AuthCode = transaction.AUTH_CODE;
      respstat = 'true';
      commcard = transaction.PAYMENT_TYPE;
      cvvresp = transaction.TKN_EXPDATE;
      respProc = 'false';
      respcode = transaction.RESULT;
      authwid = transaction.AUTHNWID;
      var reptextObj = {
        c: ctrouid,
        p: commcard,
        r: transaction.RESULT,
        rc: transaction.RESULT_CODE,
        t: transaction.TERMINATION_STATUS,
        ts: transaction.TROUTD && transaction.TROUTD != undefined ? transaction.TROUTD : "",
      } as IVerifoneOrderRefundReq;

      respText = JSON.stringify(reptextObj);
      avsresp = transaction.AUTHNWNAME;
      maskpan = transaction.ACCT_NUM;
      merchid = transaction.MERCHID;
      GeneralSetting.setVerifoneCtrouid(ctrouid);
    } else if (PaymentGatewayID === '14') {
      //grubbrr pay

      let parser = new DOMParser();
      let xmlResponse = parser.parseFromString(tx, 'text/xml');

      OrderSuccessPaymentID = tx
        ? Number(
          xmlResponse
            .getElementsByTagName('RegisterId')[0]
            .childNodes[0].nodeValue!.toString()
        )
        : 0;

      retref = tx
        ? xmlResponse
          .getElementsByTagName('RefId')[0]
          .childNodes[0].nodeValue!.toString()
        : '';

      AuthCode = tx
        ? xmlResponse
          .getElementsByTagName('AuthCode')[0]
          .childNodes[0].nodeValue!.toString()
        : '';
      respcode = tx
        ? xmlResponse
          .getElementsByTagName('Message')[0]
          .childNodes[0].nodeValue!.toString()
        : '';
      maskpan = '';

      respstat = tx
        ? xmlResponse
          .getElementsByTagName('ResultCode')[0]
          .childNodes[0].nodeValue!.toString()
        : '';

      commcard = tx
        ? xmlResponse
          .getElementsByTagName('PaymentType')[0]
          .childNodes[0].nodeValue!.toString()
        : '';

      cvvresp = tx
        ? xmlResponse
          .getElementsByTagName('ExtData')[0]
          .childNodes[0].nodeValue!.toString()
        : '';

      avsresp = '1';

      respProc = tx
        ? xmlResponse
          .getElementsByTagName('TransType')[0]
          .childNodes[0].nodeValue!.toString()
        : '';

      authwid = '{' + cvvresp + '}';
      merchid = '';
      respText = 'false';
    } else if (PaymentGatewayID === '24') {
      let transaction = tx as VEvoTransactionResData;

      if (transaction.TransacionId && transaction.TransacionId != undefined) {
        retref = transaction.TransacionId + '-' + invoiceNo;
        ctrouid = transaction.TransacionId;
      }
      else {
        retref = "";
        ctrouid = "";
      }

      AuthCode = transaction.AuthCode;
      respstat = 'true';
      commcard = transaction.AccountingNature;
      cvvresp = '';
      respProc = 'false';
      respcode = 'Success';
      authwid = transaction.AuthCode;
      var reptextObj = {
        c: ctrouid,
        p: commcard,
        r: respcode,
        rc: transaction.IssuerResponseCode,
        t: respcode,
        ts: ctrouid,
      } as IVerifoneOrderRefundReq;

      GeneralSetting.setVerifoneCtrouid(ctrouid);
    }

    GeneralSetting.setRetRef(retref);

    let order: OrderSuccessPaymentMasterReq = {
      OrderInvoiceNumber: '',
      OrderSuccessPaymentID: OrderSuccessPaymentID,
      OrderID: 0,
      CompanyID: Number(GeneralSetting.getCompanyId()),
      BranchID: Number(GeneralSetting.getBranchId()),
      AppRefIDOrder: '',
      AppRefID: CommonFunctions.getRandomGuid(),
      PaymentGatewayID: Number(PaymentGatewayID),
      amount: total,
      resptext: respText,
      commcard: commcard, // get in payment response
      cvvresp: authwid,
      avsresp: avsresp, //get from payment response
      respcode: respcode,
      merchid: merchid,
      token: '0',
      authcode: AuthCode,
      respproc: respProc,
      emvTagData: '{' + cvvresp + '}', //card type?
      retref: retref,
      respstat: respstat,
      // maskpan: tx.PaymentResult.PaymentInstrumentData.CardData.MaskedPAN.substring(tx.PaymentResult.PaymentInstrumentData.CardData.MaskedPAN.length - 4)
      maskpan: maskpan,
    };

    return order;
  }

  public async getCustomerNameForIntegration() {
    let isTableTent = await this.isTableTent(GeneralSetting.getOrderTypeId());

    let customerNameOrPhone =
      GeneralSetting.getCustomerName() == ''
        ? GeneralSetting.getCustomerPhone()
        : GeneralSetting.getCustomerName();

    if (isTableTent.toLowerCase() == 'true') {
      let customerName = CommonFunctions.getStringWithDash(
        GeneralSetting.getTableTentNumber(),
        customerNameOrPhone
      );
      if (customerName == '') {
        return 'Guest';
      }

      return customerName;
    } else {
      return customerNameOrPhone == '' ? 'Guest' : customerNameOrPhone;
    }
  }

  public isTableTent(ordertype: string) {
    return new Promise<string>((resolve) => {

      var sqltext = `
          SELECT
            KioskTableTent
          FROM
            OrderTypeMappingMasters
          WHERE
            OrderTypeID = '${ordertype}'
          `;

      let errorCallback = (tx: string, result: any) => {
        resolve('');
      };

      let callback = (tx: string, result: any) => {
        let data = Array.from(result.rows) as any[];

        if (data.length > 0) {
          resolve(data[0].KioskTableTent as string);
        } else {
          resolve('');
        }
      };

      DatabaseHandler.executeSqlStatement(sqltext, [], callback, errorCallback);
    });
  }
}
