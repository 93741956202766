<div id="out-of-stock" (click)='close()'>
  <div class="modal-body out-of-stock-modal">
    <div class="content">
      <h2>{{uhOhText}}</h2>
      <h4 class="text-center">
        {{ errorMsg }}
      </h4>
      <button class="plain-btn width60 negative-button-text-color background-negative-button-color border-negative-button-color font-size8 root-round4">{{closeText}}</button>
    </div>
  </div>
</div>
