import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-downloading-popup',
  templateUrl: './downloading-popup.component.html',
  styleUrls: ['./downloading-popup.component.css'],
})
  export class DownloadingPopupComponent implements OnInit {
  modalReference: any;
  ngOnDestroy(){
    if(this.languageSub){
      this.languageSub.unsubscribe()
    }
  }
  languageSub!:Subscription
  constructor(private modalService: NgbModal, private language:LanguageService) {

  }

  currentStateText: string = '';
  indexText: string = '';
  path: string = '';

  ngOnInit(): void {
    this.languageSub=this.language.localeCommunicator.subscribe((val)=>{
      this.loadText()
    })
  }
  loadText(){
    this.currentStateText=this.language.getTextElement('loading_please_wait')
  }

  open() {
    var modalReference = this.modalService.open(DownloadingPopupComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });
    modalReference.componentInstance.modalReference = modalReference;
    this.modalReference = modalReference;
    return modalReference;
  }

  public static open(modalService:NgbModal) {
    var modalReference = modalService.open(DownloadingPopupComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }

  clear() {
    this.modalReference.close();
  }

  public setCurrentStateMessage(message:string){
    this.currentStateText = message;
  }

  public setIndexText(message:string){
    this.indexText = message;
  }

}
