import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ConcessionaireFilterTypes } from 'src/app/models/concessionaireTypes';
import { LoyaltyType } from 'src/app/models/loyaltyModal';
import { CartService } from 'src/app/services/cart.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from '../../../environments/environment';
import { Concessionaire } from '../../models/Concessionaire';
import { DatabaseService } from '../../services/database.service';
import { GeneralSetting } from '../../services/generalSetting.service';
import { IsKioskService } from '../../services/is-kiosk.service';
import { PusherService } from '../../services/pusher.service';
import { CustomerLoginPopupComponent } from '../dialogs/customer-login-popup/customer-login-popup.component';
import { CustomerLoginModalService } from '../dialogs/customer-login-popup/customer-login-popup.service';
import { CustomerWelcomeModalComponent } from '../dialogs/customer-welcome-modal/customer-welcome-modal.component';
import { FilterConcessionaireComponent } from '../dialogs/filter-concessionaire/filter-concessionaire.component';
import { ConcessionaireService } from '../menu/conc-service/concessionaire.service';

@Component({
  selector: 'app-concessionaire-grid',
  templateUrl: './concessionaire-grid.component.html',
  styleUrls: ['./concessionaire-grid.component.css'],
})
export class ConcessionaireGridComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    try {
      if (this.conActiveSub) {
        this.conActiveSub.unsubscribe();
      }
    } catch (e) {}
  }

  conActiveSub!: Subscription;

  constructor(
    private readonly db: DatabaseService,
    private readonly isKioskService: IsKioskService,
    private readonly router: Router,
    private readonly loyaltyService: LoyaltyService,
    private userService: UserService,
    private readonly conService: ConcessionaireService,
    private readonly pusherService: PusherService,
    private language: LanguageService,
    private cartService: CartService,
    private modalService: NgbModal,
    private customerLoginService:CustomerLoginModalService
  ) {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }
  languageSub!: Subscription;
  branchLogo: string = '';

  concessionaire: Concessionaire[] = [] as Concessionaire[];

  concessionaireBaseUrl: string = '/uploads/ConcessionaireLogo/';

  isShowSolidColor: boolean = false;

  solidBackgroundColor: string = '';

  isShowImageBackground: boolean = false;

  backgroundImageUrl: string = '';

  imageBaseUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  @ViewChild(FilterConcessionaireComponent)
  filterConcessionaireComponent!: FilterConcessionaireComponent;

  @ViewChild(CustomerLoginPopupComponent)
  customerLoginPopup!: CustomerLoginPopupComponent;

  //@ViewChild(OrderReviewPromotionsComponent) promo!: OrderReviewPromotionsComponent;

  @ViewChild('itemContainerDiv') itemContainerDiv!: any;

  isLoyalty: boolean = false;

  concessionaireHeaderImage: string = '';

  primaryColor: string = '';

  isShowBranchLogo: boolean = false;

  checkScrollCheck: boolean = false;

  isBottom: boolean = false;

  isNearBottom: boolean = false;
  isFilterApplied: string = '';

  selConcessionaireTypes: ConcessionaireFilterTypes[] =
    [] as ConcessionaireFilterTypes[];

  positiveBtnColor: string = '';

  positiveButtonText: string = '';

  isItemTagsSelected: string = '';

  userName: string = '';

  secondaryColor: string = '';

  useLoyalty: boolean = false;

  categorySideColor: string = '';
  foodFilterText: string = '';
  tapToStartOrderText: string = '';
  multipleRestaurantsText: string = '';
  payAllChecksAtOnceText: string = '';
  noResultsText: string = '';
  differentFilterText: string = '';
  signInText: string = '';
  loadText() {
    this.foodFilterText = this.language.getTextElement('food_filters');
    this.tapToStartOrderText =
      this.language.getTextElement('tap_to_start_order');
    this.multipleRestaurantsText = this.language.getTextElement(
      'select_from_multiple_restaurants'
    );
    this.payAllChecksAtOnceText = this.language.getTextElement(
      'pay_all_checks_at_once'
    );
    this.noResultsText = this.language.getTextElement('no_results');
    this.differentFilterText = this.language.getTextElement(
      'try_different_filter'
    );
    this.signInText = this.language.getTextElement('action_sign_in');
  }
  ngOnInit(): void {
    this.branchLogo =
      GeneralSetting.getBranchLogo() != ''
        ? this.imageBaseUrl + GeneralSetting.getBranchLogo()
        : 'assets/images/Image_21.png';

    this.isShowSolidColor =
      GeneralSetting.getShowSolidColorBGKiosk().toLowerCase() == 'true';

    this.isShowImageBackground =
      GeneralSetting.getShowImageBGKiosk().toLowerCase() == 'true';

    this.backgroundImageUrl = GeneralSetting.getImageBGKiosk();

    this.solidBackgroundColor = GeneralSetting.getColorCodeKiosk();

    this.getConcessionaireList(this.isFilterApplied, this.isItemTagsSelected);

    this.isLoyalty = this.loyaltyService.isLoyalty;

    this.concessionaireHeaderImage =
      GeneralSetting.getConcessionaireHeaderImage();

    this.primaryColor = GeneralSetting.getPrimaryColor();

    this.isShowBranchLogo = GeneralSetting.getHideLogo();

    this.positiveBtnColor = GeneralSetting.getPositiveColor();

    this.positiveButtonText = GeneralSetting.getPositiveColorText();

    this.secondaryColor = GeneralSetting.getSecondaryColor();

    this.getUserName();

    this.useLoyalty = GeneralSetting.getUseLoyaltyKiosk() == 'True';

    this.categorySideColor = GeneralSetting.getCategoryColor();

    this.pusherBind();
  }

  pusherBind() {
    this.conActiveSub = this.pusherService.ConcessionaireIsActive.subscribe(
      (res) => {
        let conId = res.ItemId;

        let con = this.concessionaire.filter(
          (x) => x.ConcessionaireID == conId
        );
        if (con.length > 0) {
          if (res.Status && res.Status != '') {
            if (res.Status.toLowerCase() == 'true') {
              con[0].IsClosed = false;
            } else {
              con[0].IsClosed = true;
            }
          }

          this.concessionaire.sort((a, b) =>
            a.IsClosed > b.IsClosed ? 1 : -1
          );
        }
      }
    );
  }

  onConcessionaireClick(con: Concessionaire) {
    if (con.IsClosed || !con.IsActive) return;

    this.router.navigate([`/menu`], {
      queryParams: { concessionaireId: con.ConcessionaireID },
    });
  }

  getImageOrColor(
    isShowImageBackground: boolean,
    isShowSolidColor: boolean,
    solidColor: string,
    backgroundImageUrl: string,
    imageBaseUrl: string
  ) {
    function getBackgroundImage() {
      if (
        isShowImageBackground &&
        backgroundImageUrl &&
        backgroundImageUrl != ''
      ) {
        return backgroundImageUrl;
      }

      return '';
    }

    let image = getBackgroundImage();

    if (image == '') {
      if (isShowSolidColor) {
        return (
          'background-color: ' + solidColor + ";background-image: url('');"
        );
      } else {
        return '';
      }
    } else {
      return "background-image: url('" + imageBaseUrl + image + "')";
    }
  }

  /**
   * Sets branch logo
   * @param con
   * @param imageBaseUrl
   * @param concessionaireBaseUrl
   * @returns
   */
  getConcessionaireLogo(
    con: Concessionaire,
    imageBaseUrl: string,
    concessionaireBaseUrl: string
  ) {
    return con.Logo && con.Logo != ''
      ? imageBaseUrl + concessionaireBaseUrl + con.Logo
      : '../../../assets/images/Image_21.png';
  }

  /**
   * Sets branch header image
   * @param headerImageUrl
   * @param imageBaseUrl
   * @returns
   */
  setHeaderImage(headerImageUrl: string, imageBaseUrl: string) {
    return headerImageUrl && headerImageUrl != ''
      ? imageBaseUrl + headerImageUrl
      : '';
  }

  /**
   * Opens up the Filter dialog
   */
  clickFilter(event: any) {
    this.filterConcessionaireComponent.open();

    var constConTypes = JSON.parse(
      JSON.stringify(this.conService.concessionaireFilterTypes)
    );
    var constSelConTypes = JSON.parse(
      JSON.stringify(this.conService.selectedConcessionaireTypes)
    );
    var constSelItemTags = JSON.parse(
      JSON.stringify(this.conService.selectedItemTags)
    );
    var consItemTagsAndGroupFilters = JSON.parse(
      JSON.stringify(this.conService.itemTagsAndGroupFilters)
    );
    event.target.disabled = true;
    this.filterConcessionaireComponent.modalReference.result.then(
      (data: string) => {
        event.target.disabled = false;
        if (data == 'apply') {
          this.getSelConcessionaireTypes();
        } else {
          canceFilter(this.conService);
        }
      },
      (reject: any) => {
        event.target.disabled = false;
        setTimeout(() => {
          canceFilter(this.conService);
        }, 500);
      }
    );

    function canceFilter(conService: ConcessionaireService) {
      conService.setConcessionaireFilterTypes(constConTypes);
      conService.setSelectedConcessionaireTypes(
        constSelConTypes,
        constSelItemTags
      );
      conService.setItemTagsAndGroupFilters(consItemTagsAndGroupFilters);
    }
  }

  /**
   * Clicks on user login
   */
  onUserClick() {
    this.customerLoginService.onUserClick(this.loyaltyService.loyaltyType).then((data: number) => {
      if (data) {
        if (data == 1) {
          this.router.navigateByUrl('/lastfiveorder');
        } else if (data == 2) {
          this.getUserName()
        } else {
          //this block should not be reached
        }
      }
      //no else block for top-level if-con
      //where data is falsey, a zero is returned to end execution flow without hanging promise
    }
    //no rejection handler
    //promise resolves 0 on all reject cases
    );
  }

  /**
   * Gets user name
   * @returns
   */
  getUserName() {
    if (this.userService.isUserLoggedIn) {
      this.userName = this.userService.name;
    } else {
      this.userName = this.signInText;
    }
  }

  /**
   * Scrolls down the view
   * @returns
   */
  scrollDown() {
    if (!this.itemContainerDiv) {
      return;
    }

    let element = this.itemContainerDiv.nativeElement;
    const smoothScrollTo = (to: number, duration: number) => {
      const start = (element as HTMLElement).scrollTop,
        change = to - start,
        startDate = +new Date();

      // t = current time
      // b = start value
      // c = change in value
      // d = duration
      const easeInOutQuad = (t: any, b: any, c: any, d: any) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        (element as HTMLElement).scrollTop = parseInt(
          easeInOutQuad(currentTime, start, change, duration)
        );
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          (element as HTMLElement).scrollTop = to;
        }
        this.isBottom = true;
      };
      animateScroll();
    };
    // if (bottom) bottom.scrollIntoView({ behavior: 'smooth' });
    smoothScrollTo(element?.scrollHeight - element?.clientHeight, 300);
  }
  /**
   * @author om kanada
   * @description This method is used to navigate back to the previous page
   * @returns void
   */
  back(){
    this.router.navigateByUrl('/touchToStart');
  }
  /**
   *
   * @returns Scrolls up the view
   */
  scrollTop() {
    if (!this.itemContainerDiv) {
      return;
    }

    let element = this.itemContainerDiv.nativeElement;

    const smoothScrollTo = (to: number, duration: number) => {
      const start = (element as HTMLElement).scrollTop,
        change = to - start,
        startDate = +new Date();
      /**
       * @param {any} t current time
       * @param {any} b start value
       * @param {any} c change in value
       * @param {any} d duration
       */
      const easeInOutQuad = (t: any, b: any, c: any, d: any) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        (element as HTMLElement).scrollTop = parseInt(
          easeInOutQuad(currentTime, start, change, duration)
        );
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          (element as HTMLElement).scrollTop = to;
        }
        this.isBottom = false;
      };
      animateScroll();
    };
    // if (bottom) bottom.scrollIntoView({ behavior: 'smooth' });
    smoothScrollTo(0, 300);
  }

  /**
   * Selects concessionaire as per filter selection
   */
  getSelConcessionaireTypes() {
    this.isFilterApplied = this.conService.getSelectedConcessionaireTypes(1);
    this.isItemTagsSelected = this.conService.getSelectedConcessionaireTypes(2);
    this.selConcessionaireTypes = this.conService.getSelectedFilterList();
    this.getConcessionaireList(this.isFilterApplied, this.isItemTagsSelected);
  }

  /**
   * Gets concessionaire list
   * @param isFilterApplied
   * @param isItemTagsSelected
   */
  getConcessionaireList(
    isFilterApplied: string = '',
    isItemTagsSelected: string = ''
  ) {
    this.db
      .getAllConcessionaireFromSql(isFilterApplied, isItemTagsSelected)
      .then((con) => {
        this.concessionaire = con;
        this.checkScrollCheck = this.concessionaire.length > 28;
      });
  }

  /**
   * Updates concessionaire as per selection and deselection of filter
   * @param concessionaireTypeId
   * @param isSelected
   * @param event
   * @param isItemTag
   */
  onConcessionaireTypeSelection(
    concessionaireTypeId: string,
    isSelected: boolean,
    event: Event,
    isItemTag: boolean = false
  ) {
    this.conService.onConcessionaireTypeSelection(
      concessionaireTypeId,
      isSelected,
      event,
      isItemTag
    );
    this.getSelConcessionaireTypes();
  }

  /**
   * Deducts manual scroll position
   * @param event
   */
  onScroll(event: any) {
    setTimeout(() => {
      this.isBottom =
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight;
    }, 300);
  }
}
