import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-scanned-item-added',
  templateUrl: './scanned-item-added.component.html',
  styleUrls: ['./scanned-item-added.component.css']
})
export class ScannedItemAddedComponent implements OnInit {

  modalReference: any;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  public static open(modalService: NgbModal) {
    var modalReference = modalService.open(ScannedItemAddedComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
      windowClass: "scanDialig",
      modalDialogClass: "scanContent",
    });

    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }

  open() {
    var modalReference = this.modalService.open(ScannedItemAddedComponent, {    
      backdrop: 'static',
      size: 'lg',
      centered: true,
      windowClass: "scanDialig",
      modalDialogClass: "scanContent",
    });

    modalReference.componentInstance.modalReference = modalReference;
    this.modalReference = modalReference;
  }
  close() {
    this.modalReference.close();
  }
}
