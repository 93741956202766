import { Component, OnInit } from '@angular/core';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-scanning-item',
  templateUrl: './scanning-item.component.html',
  styleUrls: ['./scanning-item.component.css'],
})
export class ScanningItemComponent implements OnInit {

  ngOnDestroy() {
    //Clearing interval
    var countInterval = this.intervalID != undefined ? this.intervalID : 0;
    for (var a = 1; a <= countInterval; a++) {
      clearInterval(a);
    }

    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  private clearAllIntervel() {
    var countInterval = this.intervalID != undefined ? this.intervalID : 0;
    for (var a = 1; a <= countInterval; a++) {
      clearInterval(a);
    }
  }

  modalReference: any;

  positiveColor: string | null = '';
  positiveColorText: string | null = '';
  negativeColor: string | null = '';
  negativeColorText: string | null = '';

  scanDialogTitle: string | null = '';
  scanDialogDesc: string | null = '';
  scanDialogGifLink: string | null = '';

  barWidth = 0;
  intervalID: any;
  languageSub!: Subscription;
  itemSelectionHelperText: string = '';
  itemsWithoutBarcodeText: string = '';
  closeText: string = '';
  constructor(
    private modalService: NgbModal,
    private language: LanguageService
  ) {}
  loadText() {
    this.itemSelectionHelperText = this.language.getTextElement('item_selection_helper');
    this.itemsWithoutBarcodeText = this.language.getTextElement('items_without_barcode');
    this.closeText = this.language.getTextElement('close');
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.negativeColorText = GeneralSetting.getNegativeColorText();
    this.negativeColor = GeneralSetting.getNegativeColor();
    this.positiveColor = GeneralSetting.getPositiveColor();
    this.positiveColorText = GeneralSetting.getPositiveColorText();
    this.setScanContent();
    this.intervalID = setInterval(() => {
      if (this.barWidth >= 7) {
        this.barWidth = 0;
      } else if (this.barWidth <= 3) {
        this.barWidth++;
        this.setScanContent();
      } else {
        this.barWidth++;
        this.setSelectContent();
      }
    }, 1000);
  }

  open() {
    var modalReference = this.modalService.open(ScanningItemComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;
    this.modalReference = modalReference;
  }

  close() {
    this.clearAllIntervel();
    this.modalReference.close('continue');
  }

  setScanContent() {
    this.scanDialogTitle = this.language.getTextElement('scanning_an_item');
    this.scanDialogDesc = this.language.getTextElement('scan_item_description');
    this.scanDialogGifLink = "../../../assets/images/product-scan.gif";
  }

  setSelectContent() {
    this.scanDialogTitle = this.language.getTextElement('selecting_an_item');
    this.scanDialogDesc = this.language.getTextElement('select_item_description');
    this.scanDialogGifLink = "../../../assets/images/Select-product.gif";
  }


}
