import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PreloaderService } from 'src/app/services/preloader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-network-error-page',
  templateUrl: './network-error-page.component.html',
  styleUrls: ['./network-error-page.component.css'],
})
export class NetworkErrorPageComponent implements OnInit {
  
  constructor(private router: Router,private preloader: PreloaderService,) {}
  online: boolean = false;
  offline: boolean = false;
  private baseApiUrl = environment.apiUrl;
  ngOnInit(): void {}
  async ping() {
   
    let onlineCheck = await fetch(this.baseApiUrl + 'IsOnlineCheckSK')
      .then(async (res) => {})
      .then((a) => {
        return true;
      })
      .catch((e) => {
        return false;
      });
    return onlineCheck;
  }
  async testNetwork() {
   
    this.online = await this.ping();
    if (this.online) {
      const isLoggedIn = localStorage.getItem('IsDataBasePresent');
      setTimeout(() => {
        this.router.navigateByUrl('/');
      }, 3000);
    } else {
      this.offline = true;
      setTimeout(() => {
        this.offline = false;
      }, 3000);
    }
  }

  ngAfterViewInit() {
    this.preloader.hide();
  }
}
