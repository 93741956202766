export class CommonProperties {
  'IsActive': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'UpdatedBy': string = '';
  'UpdatedDate': string = '';
  'IPAddress': string = '';
}

export class OpenTenderIntegration {
  "BrandID": string = '';
  "OpenTenderPOSIntegrationID": string = '';
  "OpenTenderPOS_ClientID": string = '';
  "OpenTenderPOS_Url": string = '';
  "RevenueCenterID": string = '';
  "WindowsAgentID": string = '';
}

export class ModuleMaster extends CommonProperties {
  'ModuleID': string = '';
  'ModuleName': string = '';
}

export class FeatureMaster extends CommonProperties {
  'FeatureID': string = '';
  'ModuleID': string = '';
  'FeatureName': string = '';
}

export class SubFeatureMaster extends CommonProperties {
  'SubFeatureID': string = '';
  'FeatureID': string = '';
  'ModuleID': string = '';
  'SubFeatureName': string = '';
}

export class RightsMaster extends CommonProperties {
  'RightsID': string = '';
  'RightsName': string = '';
  'DisplayOrder': string = '';
}

export class Rights_Feature_Mapping extends CommonProperties {
  'RFMappingID': string = '';
  'SubFeatureID': string = '';
  'RightsID': string = '';
  'DisplayOrder': string = '';
}

export class FacilitiesMaster extends CommonProperties {
  'FacilitiesID': string = '';
  'FacilitiesName': string = '';
}

export class CuisinesMaster extends CommonProperties {
  'CuisinesID': string = '';
  'CuisineName': string = '';
}

export class UnitTypeMaster extends CommonProperties {
  'UnitTypeID': string = '';
  'UnitTypeName': string = '';
  'SortName': string = '';
  'DisplayOrder': string = '';
  'IsDeleted': string = '';
}

export class UnitTypeConversion {
  'UnitTypeConversionID': string = '';
  'UnitTypeTo': string = '';
  'UnitTypeFrom': string = '';
  'Unitvalues': string = '';
  'DisplayOrder': string = '';
  'IsDeleted': string = '';
}

export class WeekdayMaster {
  'DayID': string = '';
  'DayName': string = '';
  'DisplayOrder': string = '';
}

export class CustomerSignupType {
  'CustomerSignupTypeID': string = '';
  'Name': string = '';
}

export class PaymentTypeMaster extends CommonProperties {
  'PaymentTypeID': string = '';
  'Name': string = '';
}

export class LogicMaster {
  'LogicID': string = '';
  'Name': string = '';
}

export class DineTypeMaster {
  'DineTypeID': string = '';
  'Name': string = '';
  'StartTime': string = '';
  'EndTime': string = '';
  'IsActive': string = '';
}

export class FilterMaster extends CommonProperties {
  'FilterID': string = '';
  'FilterName': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'Description': string = '';
  'DisplayOrder': string = '';
  'IconImage': string = '';
  'IsDeleted': string = '';
  'DisplayName': string = '';
}

export class CardTypeMaster {
  'CardTypeID': string = '';
  'TypeName': string = '';
  'IsActive': string = '';
  'IsDeleted': string = '';
}

export class CardNetworkMaster {
  'CardNetworkID': string = '';
  'NetworkName': string = '';
  'NetworkNameText': string = '';
  'IsActive': string = '';
  'IsDeleted': string = '';
}

export class OrderPlaceMaster {
  'OrderPlaceID': string = '';
  'PlaceName': string = '';
  'IsActive': string = '';
  'IsDeleted': string = '';
}

export class ShapeTypeMaster {
  'ShapeTypeID': string = '';
  'Name': string = '';
  'IsActive': string = '';
  'IsDeleted': string = '';
}

export class ScannerMaster {
  'ScannerID': string = '';
  'ScannerName': string = '';
  'ModelNumber': string = '';
  'IsActive': string = '';
  'IsDeleted': string = '';
}

export class BranchMaster extends CommonProperties {
  'BranchID': string = '';
  'BranchName': string = '';
  'ContactNo': string = '';
  'CustomerSupportNo': string = '';
  'BranchLogo': string = '';
  'customerInfoImage': string = '';
  'BranchEmailID': string = '';
  'OwnerID': string = '';
  'CompanyID': string = '';
  'Address1': string = '';
  'Address2': string = '';
  'CountryCurrency': string = '';
  'CurrencySymbol': string = '';
  'CountryID': string = '';
  'CountryName': string = '';
  'StateID': string = '';
  'StateName': string = '';
  'CityID': string = '';
  'CityName': string = '';
  'ZipCode': string = '';
  'TimeZone': string = '';
  'SquareFeet': string = '';
  'StartDate': string = '';
  'ExpireDate': string = '';
  'TagLine': string = '';
  'Footer': string = '';
  'DeliveryAreaRadius': string = '';
  'DeliveryAreaTitle': string = '';
  'DeliveryCharges': string = '';
  'FreeDeliveryUpto': string = '';
  'DeliveryTime': string = '';
  'PickupTime': string = '';
  'Latitude': string = '';
  'Longtitude': string = '';
  'DistanceType': string = '';
  'WorkingDays': string = '';
  'InvoicePrefix': string = '';
  'InvoiceRefNo': string = '';
  'IsDeleted': string = '';
  'IsPunchRequired': string = '';
  'RefAccessToken': string = '';
  'NoOfVisits': string = '';
  'AmountSpent': string = '';
  'NoOfVisitOrAmount': string = '';
  'NoOfPoints': string = '';
  'RefPin': string = '';
  'HomeScreenLogo': string = '';
  'CustomerSupportEmailID': string = '';
  'KDSRefreshTime': string = '';
  'LevelUpLocationId': string = '';
}

export class BranchColorCodes extends CommonProperties {
  'ColorCodeID': string = '';
  'PrimaryColor': string = '';
  'SecondaryColor': string = '';
  'AccentColor': string = '';
  'PriceColor': string = '';
  'TextColor': string = '';
  'BoldTextColor': string = '';
  'BorderColor': string = '';
  'PositiveButtonColor': string = '';
  'PositiveButtonTextColor': string = '';
  'NegativeButtonColor': string = '';
  'NegativeButtonTextColor': string = '';
  'FooterOrderSummaryBackgroundColor': string = '';
  'CategoryBackgroundColor': string = '';
  'CategorySidebarBackgroundColor': string = '';
  'ImagePath': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class BranchModule extends CommonProperties {
  'BranchModuleID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'ModuleID': string = '';
  'Quantity': string = '';
  'Amount': string = '';
}

export class BranchModuleRight extends CommonProperties {
  'BranchModuleRightsID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'ModuleID': string = '';
  'FeatureID': string = '';
  'SubFeatureID': string = '';
  'RightsID': string = '';
  'DisplayOrder': string = '';
  'IsDeleted': string = '';
}

export class Concessionaire extends CommonProperties {
  'ConcessionaireID': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
  'Name': string = '';
  'ShortName': string = '';
  'Code': string = '';
  'ContactNo': string = '';
  'CustomerSupportNo': string = '';
  'Logo': string = '';
  'EmailID': string = '';
  'Password': string = '';
  'OwnerID': string = '';
  'SquareFeet': string = '';
  'StartDate': string = '';
  'ExpireDate': string = '';
  'SectionID': string = '';
  'KitchenID': string = '';
  'IsDeleted': string = '';
  'ColorCode': string = '';
  'OrderPriority': string = '';
  'ConDescription': string = '';
  'RectangleLogo': string = '';
}

export class ConcessionaireSectionBranchMappingApi extends CommonProperties {
  'ConSecBranMappID': string = '';
  'ConcessionaireID': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
  'SectionID': string = '';
  'KitchenID': string = '';
  'IsDeleted': string = '';
}

export class PayTypeMaster {
  'PayTypeID': string = '';
  'Name': string = '';
}

export class OrderStatusMaster {
  'OrderStatusID': string = '';
  'Name': string = '';
  'IsActive': string = '';
}

export class StaffRoleMaster extends CommonProperties {
  'StaffRoleID': string = '';
  'RoleName': string = '';
  'DayStart': string = '';
  'DayEnd': string = '';
  'PayTypeID': string = '';
  'PayRateSalary': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'RequireEndOfShift': string = '';
}

export class StaffRoleRights_Mapping {
  'SRRMID': string = '';
  'StaffRoleID': string = '';
  'SubFeatureID': string = '';
  'RightsID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class StaffMaster extends CommonProperties {
  'StaffID': string = '';
  'Name': string = '';
  'DisplayName': string = '';
  'Code': string = '';
  'ProfileImage': string = '';
  'MobileNo': string = '';
  'AlternateNo': string = '';
  'EmailID': string = '';
  'DOB': string = '';
  'JoinDate': string = '';
  'ReportingTo': string = '';
  'DisplayInKR': string = '';
  'EmployeeTypeID': string = '';
  'Gender': string = '';
  'Address1': string = '';
  'Address2': string = '';
  'CountryID': string = '';
  'CountryName': string = '';
  'StateID': string = '';
  'StateName': string = '';
  'CityID': string = '';
  'CityName': string = '';
  'ZipCode': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'StaffLocationID': string = '';
  'BranchSectionConcessionaireID': string = '';
  'FingerPrintRefID': string = '';
  'curbsideOnly': string = '';
  'FirstName': string = '';
  'LastName': string = '';
}

export class StaffRole {
  'AssignStaffRoleID': string = '';
  'StaffID': string = '';
  'StaffRoleID': string = '';
  'Salary': string = '';
  'PayTypeID': string = '';
}

export class POSPermissionMaster {
  'POSPermissionID': string = '';
  'Name': string = '';
  'ParentID': string = '';
  'IsActive': string = '';
  'DisplayOrder': string = '';
}

export class POSRolePermissionMaster {
  'POSRolePermissionID': string = '';
  'RoleID': string = '';
  'POSPermissionID': string = '';
  'IsSelected': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'IsActive': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'UpdatedBy': string = '';
  'UpdatedDate': string = '';
  'IPAddress': string = '';
}

export class BreakMaster {
  'BreakID': string = '';
  'Name': string = '';
  'TypeID': string = '';
  'Duration': string = '';
  'IsActive': string = '';
}

export class StaffModuleMaster {
  'StaffModuleID': string = '';
  'Name': string = '';
  'DeviceTypeID': string = '';
  'DeviceID': string = '';
  'MerchantID': string = '';
  'HSN': string = '';
  'ModuleID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'IpAddress': string = '';
  'IsInvoicePrinter': string = '';
  'IsKitchenPrinter': string = '';
  'IsConsolidateItemsByCategories': string = '';
  'IsTokenPrinter': string = '';
  'IsCustomerPrinter': string = '';
  'IsStickyPrinter': string = '';
  'IsKDSPrinter': string = '';
  'IsCashDrawer': string = '';
  'ConnectionStatusDate': string = '';
  'ConnectionStatusId': string = '';
  'PrinterID': string = '';
  'TokenPrinterID': string = '';
  'CustomerPrinterID': string = '';
  'InvoicePrinterID': string = '';
  'KitchenPrinterID': string = '';
  'StickyPrinterID': string = '';
  'DuplicateInvoicePrinterID': string = '';
  'DuplicateKitchenPrinterID': string = '';
  'MacAddress': string = '';
  'WifiName': string = '';
  'AppVersion': string = '';
  'OSVersion': string = '';
  'BrandName': string = '';
  'ModelName': string = '';
  'Notes': string = '';
  'PrinterStatus': string = '';
  'TableID': string = '';
  'UsePOSForOnlineOrder': string = '';
  'CashRecyclerID': string = '';
  'OrderPrefix': string = '';
  'RevenueCenterID': string = '';
  'IsAssignSpecificConcessionaire': string = '';
  'IsDefaultConcessionaire': string = '';
  'DefaultConcessionaireID': string = '';
  'IsPrintByItem': string = '';
  'FreedomPayPinPadConnections': string = '';
  'ScannerID': string = '';
  'CloverFlexIP': string = '';
  'CloverFlexMode': string = '';
  'UseBarcodeScanner': string = '';
  'USConnectDeviceSerialSuffix': string = '';
  'IsCustomScreenSaver': string = '';
  'IsDefaultStartingCash': string = '';
  'StartingCashAmountAtOpen': string = '';
}

export class AttendanceMaster {
  'AttendanceID': string = '';
  'AttendanceInDate': string = '';
  'AttendanceOutDate': string = '';
  'StaffID': string = '';
  'AssignStaffRoleID': string = '';
  'InTime': string = '';
  'OutTime': string = '';
  'InDeviceID': string = '';
  'OutDeviceID': string = '';
  'PunchRecords': string = '';
  'PunchDirections': string = '';
  'ShiftID': string = '';
  'IsStatus': string = '';
  'IsMissedInPunch': string = '';
  'IsMissedOutPunch': string = '';
  'Remarks': string = '';
  'SMSFlag': string = '';
  'TotalDurationMM': string = '';
  'ShiftDurationMM': string = '';
  'AppRefID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'DeclareCash': string = '';
  'CollectTip': string = '';
  'CashTips': number = 0;
  'CreditCardTips': number = 0;
  'OtherTips': number = 0;
  'HouseTips': number = 0;
  'IsShiftEndByManager': string = '';
  'DeclaredCash': number = 0;
  'IsSynced': string = '';
}

export class AttendanceBreakMaster {
  'AttendanceBreakID': string = '';
  'AttendanceID': string = '';
  'StaffID': string = '';
  'AssignStaffRoleID': string = '';
  'BreakType': string = '';
  'BreakInDate': string = '';
  'BreakOutDate': string = '';
  'InTime': string = '';
  'OutTime': string = '';
  'IsStatus': string = '';
  'TotalDurationMM': string = '';
  'AppRefID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'IsSynced': string = '';
  'AttendanceAppRefID': string = '';
}

export class EmployeeShiftSchedule extends CommonProperties {
  'BranchEmployeeShiftID': string = '';
  'BranchEmployeeScheduleID': string = '';
  'DayID': string = '';
  'StartTime': string = '';
  'EndTime': string = '';
  'GiveOT': string = '';
  'ExtraOTPer': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class EmployeeSchedule extends CommonProperties {
  'BranchEmployeeScheduleID': string = '';
  'StaffID': string = '';
  'AssignStaffRoleID': string = '';
  'ShiftLabelID': string = '';
  'FromDate': string = '';
  'ToDate': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class ItemLocation extends CommonProperties {
  'ItemLocationID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'Name': string = '';
  'Description': string = '';
  'IsDeleted': string = '';
}

export class CategoryMaster extends CommonProperties {
  'CategoryID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'Name': string = '';
  'DisplayName': string = '';
  'Description': string = '';
  'ParentCategoryID': string = '';
  'IncludeInTopMenu': string = '';
  'DisplayOrder': string = '';
  'IsDeleted': string = '';
  'OmnivoreID': string = '';
  'IsAlkol': string = '';
  'NameLang': string = '';
  'DescriptionLang': string = '';
  'RefCategoryId': string = '';
  'ThirdPartyPortalID': string = '';
  'RefVersion': string = '';
  'IsShowOnKiosk': string = '';
  'IsShowOnOnlineOrder': string = '';
  'IsShowOnPOS': string = '';
  'ColorCode': string = '';
  'HeaderImage': string = '';
  'CategoryIcon': string = '';
  'DeselectCategoryIcon': string = '';
  'DisplayCatItemRowCount': string = '';
  'HideHeaderText': string = '';
  'IsCatering': string = '';
  'IsShowInOnlineCatering': string = '';
  'IsCateringAddOn': string = '';
  'BackgroundImage': string = '';
  'DisplayCategory': boolean = true;
}

export class CategoryMasterByNameID {
  'CategoryID': number = 0;
  'Name': string = '';
  'Items' = [new TopSellingItemsListByCategoriesGroup()];
}

export class TopSellingItemsListByCategories {
  'ItemID': number = 0;
  'Name': string = '';
  'ItemQuantity': number = 0;
  'ItemTotal': number = 0;
  'Currency': string = '';
  'DisplayName': string = '';
}

export class TopSellingItemsListByCategoriesGroup {
  'CategoryID': number = 0;
  'ItemID': number = 0;
  'Name': string = '';
  'ItemQuantity': number = 0;
  'ItemTotal': number = 0;
  'Currency': string = '';
  'DisplayName': string = '';
}

export class CategoryImage {
  'CImgID': string = '';
  'ImageUrl': string = '';
  'CategoryID': string = '';
  'IsActive': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'IPAddress': string = '';
}

export class CategoryDayWiseMaster {
  'CategoryWeekDayID': string = '';
  'CategoryID': string = '';
  'DayID': string = '';
  'StartTime': string = '';
  'EndTime': string = '';
  'IsActive': string = '';
}

export class Category_Tax_Mapping {
  'CTMID': string = '';
  'TaxGroupID': string = '';
  'CategoryID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class TaxMaster extends CommonProperties {
  'TaxID': string = '';
  'TaxName': string = '';
  'Percentage': string = '';
  'TaxNo': string = '';
  'DisplayOrder': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'TaxNameLang': string = '';
  'RefTaxId': string = '';
  'ThirdPartyPortalID': string = '';
  'RefVersion': string = '';
}

export class TaxGroupMaster extends CommonProperties {
  'TaxGroupID': string = '';
  'TaxGroupName': string = '';
  'DisplayOrder': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'IsDefaultTaxGroup': string = '';
  'TaxGroupNameLang': string = '';
  'RefTaxGroupId': string = '';
  'ThirdPartyPortalID': string = '';
  'RefVersion': string = '';
}

export class TaxGroup_Mapping {
  'TGMappingID': string = '';
  'TaxGroupID': string = '';
  'TaxID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class TaxGroup_OrderType_Mapping {
  'TGOTMappingID': string = '';
  'TaxGroupID': string = '';
  'OrderTypeID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class OtherChargeMaster extends CommonProperties {
  'OtherChargeID': string = '';
  'ChargeName': string = '';
  'UsePercentage': string = '';
  'Amount': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'RefChargeID': string = '';
  'ChargeTaxable': string = '';
  'TaxGroup1': string = '';
  'TaxGroup2': string = '';
}

export class OtherCharges_SubFeature_Mapping {
  'OCSFMappingID': string = '';
  'OtherChargeID': string = '';
  'SubFeatureID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class SectionMaster extends CommonProperties {
  'SectionID': string = '';
  'Name': string = '';
  'Description': string = '';
  'DisplayOrder': string = '';
  'TaxGroupID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'IsDeleted': string = '';
  'NameLang': string = '';
  'DescriptionLang': string = '';
  'IsDefaultSectionPOSTables': string = '';
}

export class Section_Kitchen_Mapping {
  'SKMID': string = '';
  'KitchenID': string = '';
  'SectionID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class TableMaster extends CommonProperties {
  'TableID': string = '';
  'Name': string = '';
  'NoOfSeat': string = '';
  'DisplayOrder': string = '';
  'StaffID': string = '';
  'SectionID': string = '';
  'Location': string = '';
  'XCode': string = '';
  'YCode': string = '';
  'ScaleRatio': string = '';
  'TableStatusID': string = '';
  'ReservationID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'NameLang': string = '';
  'LocationLang': string = '';
  'ShapeTypeID': string = '';
  'TableQRCodePath': string = '';
}

export class DividerMaster {
  'DividerID': string = '';
  'DividerRefNo': string = '';
  'XCode': string = '';
  'YCode': string = '';
  'DividerTypeID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
}

export class Ingredient extends CommonProperties {
  'IngredientID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'Name': string = '';
  'DisplayName': string = '';
  'ImageUrl': string = '';
  'DeselectImageUrl': string = '';
  'UnittypeID': string = '';
  'IsAllergic': string = '';
  'Yield': string = '';
  'Wastage': string = '';
  'LowStockAlertForItem': string = '';
  'StopSalesAlertForItem': string = '';
  'StopSalesAlertForItemAlret': string = '';
  'IsWorkinProgress': string = '';
  'Procedure': string = '';
  'IsDeleted': string = '';
  'NameLang': string = '';
  'OmnivoreID': string = '';
  'RefIngredientId': string = '';
  'ThirdPartyPortalID': string = '';
  'RefVersion': string = '';
  'DisplayOrder': string = '';
  'IsShowOnKiosk': string = '';
  'IsShowOnPOS': string = '';
  'IsShowOnOnlineOrder': string = '';
  'IsIngredient': string = '';
  'Calorie': string = '';
  'IsIngredient86': string = '';
  'RefChainIngredientId': string = '';
  'KOTPrintDisplayName': string = '';
  'IngredientColor': string = '';
}

export class Ingredient_Filter_Mapping {
  'IFMappingID': string = '';
  'IngredientID': string = '';
  'FilterID': string = '';
  'DisplayOrder': string = '';
}

export class CourseMaster {
  'CourseID': string = '';
  'CourseName': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'IsActive': string = '';
}

export class CourseMastersIndex {
  'CourseID': string = '';
  'ItemID': string = '';
  'DisplayOrder': string = '';
}

export class Item extends CommonProperties {
  'ItemID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'Name': string = '';
  'DisplayName': string = '';
  'Sku': string = '';
  'ShortDescription': string = '';
  'FullDescription': string = '';
  'TaxGroupId': string = '';
  'LowStockActivity': string = '';
  'OrderMinimumQuantity': string = '';
  'OrderMaximumQuantity': string = '';
  'Price': string = '';
  'ProductCost': string = '';
  'StartDate': string = '';
  'EndDate': string = '';
  'IsCombo': string = '';
  'NoOfGroup': string = '';
  'Weight': string = '';
  'Length': string = '';
  'Width': string = '';
  'Height': string = '';
  'OmnivoreID': string = '';
  'ApproxCookingHour': string = '';
  'ApproxCookingMinute': string = '';
  'DisplayOrder': string = '';
  'IsDeleted': string = '';
  'Procedure': string = '';
  'ItemCostPer': string = '';
  'ItemPricePer': string = '';
  'CourseID': string = '';
  'IsItemReady': string = '';
  'LowStockAlertForItem': string = '';
  'ItemCategorySalesTypeID': string = '';
  'IsComboItemOnly': string = '';
  'NameLang': string = '';
  'DisplayNameLang': string = '';
  'ShortDescriptionLang': string = '';
  'FullDescriptionLang': string = '';
  'RefItemId': string = '';
  'ThirdPartyPortalID': string = '';
  'RefVersion': string = '';
  'IsShowOnKiosk': string = '';
  'IsShowOnPOS': string = '';
  'IsShowOnOnlineOrder': string = '';
  'ColorCode': string = '';
  'ItemBarcodeID': string = '';
  'KioskDisplayOrder': string = '';
  'OnlineDisplayOrder': string = '';
  'Calorie': string = '';
  'MaxCalories': string = '';
  'Brand': string = '';
  'IsBuilderMode': string = '';
  'IsAskForName': string = '';
  'RefChainItemId': string = '';
  'ItemSKU': string = '';
  'IsVariationItemOnly': string = '';
  'ParentItemID': string = '';
  'IsItemDetailScreenShowKiosk': string = '';
  'IsShowModifiersInSpecialRequest': string = '';
  'KOTorKDSDisplayName': string = '';
  'IsBuilderModeBowl': string = '';
  'BackgroundImage': string = '';
  'IsPrintCompletion': string = '';
  'ItemTypeID': string = '';
}

export class ItemDayWiseMaster {
  'ItemWeekDayID': string = '';
  'ItemID': string = '';
  'DayID': string = '';
  'StartTime': string = '';
  'EndTime': string = '';
  'IsActive': string = '';
  'FullDay': string = '';
  'PriceOverride': string = '';
}

export class Item_Tax_Mapping {
  'ITMID': string = '';
  'TaxGroupID': string = '';
  'ItemID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class ItemImage {
  'IImgID': string = '';
  'ImageUrl': string = '';
  'ItemID': string = '';
  'IsActive': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'IPAddress': string = '';
}

export class ModifierImage {
  'MImgID': string = '';
  'ImageUrl': string = '';
  'ModifierID': string = '';
  'IsActive': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'IPAddress': string = '';
}

export class Item_Suggestive_Mapping {
  'PSMappingID': string = '';
  'ItemID': string = '';
  'SuggestiveItemID': string = '';
  'DisplayOrder': string = '';
}

export class Item_Category_Mapping {
  'PCMappingID': string = '';
  'ItemID': string = '';
  'CategoryID': string = '';
  'DisplayOrder': string = '';
}

export class Item_Filter_Mapping {
  'PFMappingID': string = '';
  'ItemID': string = '';
  'FilterID': string = '';
  'DisplayOrder': string = '';
}

export class ApiItemAttribute {
  'ItemAttributeID': string = '';
  'Name': string = '';
  'ItemID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'IsActive': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'UpdatedBy': string = '';
  'UpdatedDate': string = '';
  'IPAddress': string = '';
  'NameLang': string = '';
  'DisplayName': string = '';
}

export class PriceMaster {
  'PriceID': string = '';
  'ItemID': string = '';
  'ItemQty': string = '';
  'Price': string = '';
  'PriceType': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'OrderPlaceID': string = '';
  'StartDate': string = '';
  'EndDate': string = '';
}

export class ItemAttributeOption {
  'ItemAttributeOptionID': string = '';
  'OptionName': string = '';
  'ImageUrl': string = '';
  'Price': string = '';
  'Calories': string = '';
  'ItemAttributeID': string = '';
  'IsActive': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'UpdatedBy': string = '';
  'UpdatedDate': string = '';
  'IPAddress': string = '';
  'DisplayOrder': string = '';
  'ItemID': string = '';
  'RefItemAttributeOptionID': string = '';
  'DisplayName': string = '';
  'SKU': string = '';
}

export class CommonAttribute_Item_Mappings {
  'CAIMID': string = '';
  'ItemAttributeID': string = '';
  'ItemID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class ItemUpSellMaster {
  'UpSellItemMasterID': string = '';
  'UpSellItemID': string = '';
  'CategoryID': string = '';
  'ItemID': string = '';
  'displayName': string = '';
  'DisplayOrder': string = '';
  'ExtraPrice': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'IPAddress': string = '';
  'AllowQuantitySelection': string = '';
  'CustomMessage': string = '';
  'ItemAttributeOptionID': string = '';
  'OrderMaximumQuantity': string = '';
  'Discount': string = '';
  'UpSellGroupName': string = '';
}

export class ComboGroupMaster extends CommonProperties {
  'ComboGroupID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'GroupName': string = '';
  'Description': string = '';
  'CountOption': string = '';
  'IsDeleted': string = '';
  'GroupNameLang': string = '';
  'DescriptionLang': string = '';
  'RefComboGroupID': string = '';
  'ThirdPartyPortalID': string = '';
  'RefVersion': string = '';
  'DisplayOrder': string = '';
  'MinNoOfSelection': string = '';
}

export class ComboGroupItemMaster extends CommonProperties {
  'ComboGroupItemID': string = '';
  'ComboID': string = '';
  'ComboGroupID': string = '';
  'ItemID': string = '';
  'DisplayOrder': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'IsDefault': string = '';
  'CategoryID': string = '';
  'ExtraPrice': string = '';
  'MaximumNoOfComboItem': string = '';
  'AllowQuantitySelection': string = '';
  'RefComboGroupItemID': string = '';
  'IsInvisibleItem': string = '';
}

export class KitchenMaster extends CommonProperties {
  'KitchenID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'Name': string = '';
  'RoutingIPAddress': string = '';
  'Description': string = '';
  'IsDeleted': string = '';
  'StaffModuleID': string = '';
  'KDSIPAddress': string = '';
  'NameLang': string = '';
  'DescriptionLang': string = '';
  'IsDisplayGrillQueuePanel': string = '';
  'KDSID': string = '';
}

export class KDSTypeMaster {
  'KDSTypeID': string = '';
  'Name': string = '';
}

export class KDSMaster {
  'KDSID': string = '';
  'Name': string = '';
  'KDSIPAddress': string = '';
  'DeviceID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'IsDisplayGrillQueuePanel': string = '';
  'IsExpediter': string = '';
  'KDSTypeID': string = '';
  'IsActive': string = '';
  'StaffModuleID': string = '';
  'IsLightMode': string = '';
  'IsDarkMode': string = '';
  'GrillQueueItems': string = '';
  'GrillQueueModifiers': string = '';
  'SendSMS': string = '';
  'UpdateCFD': string = '';
  'PrintBefore': string = '';
  'IndividualReceipt': string = '';
  'ShowNegativeModifiers': string = '';
  'ShowDefaultModifiers': string = '';
  'IsMapDevice': string = '';
  'IsAutoScrollToNewOrders': string = '';
  'ShowCustomerName': string = '';
  'ShowServerName': string = '';
  'ShowCategoryName': string = '';
  'NoOfRows': string = '';
  'ShowModifierGroupNameForKDS': string = '';
  'UseBumpBar': string = '';
  'MinimizeSidebar': string = '';
  'HideActions': string = '';
  'AllowToCompletePaidOrder': string = '';
  'DeviceSettingsLockdown': string = '';
  'ShowDelayOrderButton': string = '';
  'IsShowCarInfo': string = '';
}

export class ExpediterMaster {
  'ExpediterID': string = '';
  'Name': string = '';
  'ExpediterIPAddress': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class OrderTypeKDSMapping {
  'OTKDSMappingID': string = '';
  'OrderTypeID': string = '';
  'KDSID': string = '';
  'DisplayOrder': string = '';
  'DisplayLabel': string = '';
  'IsEnabled': string = '';
}

export class Kitchen_Item_Mapping {
  'KIMappingID': string = '';
  'KitchenID': string = '';
  'ItemID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class Kitchen_Ingredient_Mapping {
  'KIGMappingID': string = '';
  'KitchenID': string = '';
  'IngredientID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class DiscountTypeMaster {
  'DiscountTypeID': string = '';
  'Name': string = '';
}

export class DiscountLimitationMaster {
  'DiscountLimitationID': string = '';
  'Name': string = '';
}

export class DiscountMaster extends CommonProperties {
  'DiscountID': string = '';
  'Name': string = '';
  'DiscountTypeID': string = '';
  'UsePercentage': string = '';
  'DiscountPercentage': string = '';
  'DiscountAmount': string = '';
  'MaximumDiscountAmount': string = '';
  'StartDate': string = '';
  'EndDate': string = '';
  'RequiresCouponCode': string = '';
  'CouponCode': string = '';
  'IsCumulative': string = '';
  'DiscountLimitationID': string = '';
  'LimitationTimes': string = '';
  'MaximumDiscountedQuantity': string = '';
  'AppliedToSubCategories': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'NameLang': string = '';
  'StaffRoleID': string = '';
  'FreeItem': string = '';
  'CategoryID': string = '';
  'ItemID': string = '';
  'IsShowOnKiosk': string = '';
  'IsShowOnPOS': string = '';
  'IsShowOnOnlineOrder': string = '';
  'RefDiscountID': string = '';
  'RegularOrBogoDiscount': string = '';
  'BogoDiscountType': string = '';
  'BogoPurchaseQuantity': string = '';
  'BogoGetQuantity': string = '';
  'BogoAppliesTo': string = '';
  'BogoDiscountPercentage': string = '';
  'BOGODiscountAmount': string = '';
  'BogoFlatOrPercentage': string = '';
}

export class Discount_Category_Mapping {
  'DCID': string = '';
  'CategoryID': string = '';
  'DiscountID': string = '';
}

export class Discount_Item_Mapping {
  'DIID': string = '';
  'ItemID': string = '';
  'DiscountID': string = '';
  'PurchaseOrGet': string = '';
  'GroupCategoryID': string = '';
}

export class DiscountHappyHours {
  'DiscountHappyHoursID': string = '';
  'DiscountID': string = '';
  'DayID': string = '';
  'StartTime': string = '';
  'EndTime': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'IsActive': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'UpdatedBy': string = '';
  'UpdatedDate': string = '';
  'IPAddress': string = '';
}

export class RecipientMaster {
  'RecipientID': string = '';
  'Name': string = '';
}

export class CouponMaster extends CommonProperties {
  'CouponID': string = '';
  'Name': string = '';
  'RecipientID': string = '';
  'CouponCode': string = '';
  'UsePercentage': string = '';
  'Percentage': string = '';
  'Amount': string = '';
  'MaximumAmount': string = '';
  'StartDate': string = '';
  'EndDate': string = '';
  'Status': string = '';
  'Description': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class Coupon_Category_Mapping {
  'CCID': string = '';
  'CategoryID': string = '';
  'CouponID': string = '';
}

export class Coupon_Item_Mapping {
  'CIID': string = '';
  'ItemID': string = '';
  'CouponID': string = '';
}

export class Coupon_Customer_Mapping {
  'CCID': string = '';
  'CustomerID': string = '';
  'CouponID': string = '';
}

export class TitleMaster {
  'TitleID': string = '';
  'Name': string = '';
}

export class IndustryMaster {
  'IndustryID': string = '';
  'Name': string = '';
}

export class TaxTypeMaster {
  'TaxTypeID': string = '';
  'Name': string = '';
}

export class CustomerMaster extends CommonProperties {
  'CustomerID': string = '';
  'TitleID': string = '';
  'FullName': string = '';
  'CompanyName': string = '';
  'DisplayName': string = '';
  'ImageUrl': string = '';
  'MobileCode': string = '';
  'MobileNo': string = '';
  'EmailID': string = '';
  'Password': string = '';
  'DOB': string = '';
  'DOA': string = '';
  'Gender': string = '';
  'NoOfGuest': string = '';
  'CustomerTypeID': string = '';
  'IndustryID': string = '';
  'TaxTypeID': string = '';
  'TaxNo': string = '';
  'Website': string = '';
  'Facebook': string = '';
  'Twitter': string = '';
  'Linkedin': string = '';
  'Instagram': string = '';
  'Skype': string = '';
  'Notes': string = '';
  'BAddress1': string = '';
  'BAddress2': string = '';
  'BCountryID': string = '';
  'BCountryName': string = '';
  'BStateID': string = '';
  'BStateName': string = '';
  'BCityID': string = '';
  'BCityName': string = '';
  'BZipCode': string = '';
  'SAddress1': string = '';
  'SAddress2': string = '';
  'SCountryID': string = '';
  'SCountryName': string = '';
  'SStateID': string = '';
  'SStateName': string = '';
  'SCityID': string = '';
  'SCityName': string = '';
  'SZipCode': string = '';
  'CustomerSignupTypeID': string = '';
  'AppRefID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'IsGuest': string = '';
  'IsDeleted': string = '';
  'ConsumerPan': string = '';
  'LoyaltyId': string = '';
  'BankedRewards': string = '';
  'MembershipLevel': string = '';
  'MembershipLevelId': string = '';
  'NetBalance': string = '';
  'NetDebits': string = '';
  'PendingPoints': string = '';
  'PointsBalance': string = '';
  'SignupAnniversaryDay': string = '';
  'TotalCredits': string = '';
  'TotalDebits': string = '';
  'TotalPointCredits': string = '';
  'EmailVerified': string = '';
  'RefLoyaltyCustomerID': string = '';
  'AccountNo': string = '';
  'CustomerNo': string = '';
  'CanOrderOnDIYDevices': string = '';
  'RefUserID': string = '';
}

export class ModifierMaster extends CommonProperties {
  'ModifierID': string = '';
  'ItemID': string = '';
  'Category': string = '';
  'IsForced': string = '';
  'CountOption': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'DisplayOrder': string = '';
  'CategoryLang': string = '';
  'MaximumNoOfModifier': string = '';
  'OmnivoreID': string = '';
  'RefModifierID': string = '';
  'MinNoOfSelection': string = '';
  'CommonPrice': string = '';
  'IsModifierOverride': string = '';
  'ParentID': string = '';
  'TopModifierID': string = '';
  'RefChainModifierid': string = '';
  'IsModifier86': string = '';
  'KOTDisplayOrder': string = '';
  'IsShowOnKIOSK': string = '';
  'IsShowOnPOS': string = '';
  'IsShowOnOnlineOrder': string = '';
  'IngredientID': string = '';
  'KOTorKDSDisplayName': string = '';
  'Price': string = '';
  'EnableColorCodedModifiers': string = '';
  'ModifierColor': string = '';
  'isOloOptionGroup': string = '';
  'IsShowInSpecialRequests': string = '';
  'DisplayName': string = '';
  'Description': string = '';
  'SelectedImage': string = '';
  'DeselectedImage': string = '';
  'IsRequireCustomSubgroup': string = '';
  'CustomSubgroupName': string = '';
  'CustomSubGroupModifierID': string = '';
  'IsHideNameOnKDSKOT': string = '';
  'IsStartinCollapsedMode': string = '';
  'MinAggregateQty': string = '';
  'MaxAggregateQty': string = '';
  'MinChoiceQty': string = '';
  'MaxChoiceQty': string = '';
  'ChoiceQtyIncrement': string = '';
  'UseAdvancedModifierQuantitySettings': string = '';
}

export class Modifier_CustomSubgroup_Mapping {
  'MCSCMappingID': string = '';
  'ModifierID': string = '';
  'SubgroupModifierID': string = '';
  'IngredientID': string = '';
  'ModifierDetailID': string = '';
  'DisplayOrder': string = '';
}

export class ModifierDetailMaster {
  'ModifierDetailID': string = '';
  'ModifierID': string = '';
  'IngredientID': string = '';
  'UnitTypeID': string = '';
  'Quantity': string = '';
  'ExtraPrice': string = '';
  'DisplayOrder': string = '';
  'Countable': string = '';
  'IsDefault': string = '';
  'IsActive': string = '';
  'MaxQty': string = '';
  'PortionCount': string = '';
  'IsPremium': string = '';
  'IsShowOnKiosk': string = '';
  'IsShowOnPOS': string = '';
  'IsShowOnOnlineOrder': string = '';
  'KOTKDSDisplayOrder': string = '';
  'ModifierColor': string = '';
}

export class CommonModifier_Item_Mapping {
  'CMIMappingID': string = '';
  'ItemID': string = '';
  'ModifierID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'DisplayOrder': string = '';
  'KOTDisplayOrder': string = '';
  'ParentModifierID': string = '';
  'MaxiNoOfSelection': string = '';
  'MinNoOfSelection': string = '';
  'HasCustomOverride': string = '';
  'IsActive': string = '';
}

export class ScreenSaverMaster extends CommonProperties {
  'ScreenSaverID': string = '';
  'Name': string = '';
  'AnimationDirectionID': string = '';
  'ImagePath': string = '';
  'SliderText': string = '';
  'Description': string = '';
  'DisplayOrder': string = '';
  'ScreenSaverTypeID': string = '';
  'VideoPath': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'ConcessionaireID': string = '';
}

export class SpecialRequestMaster {
  'NoOfRequest': string = '';
  'RequestText': string = '';
}

export class PrinterMaster {
  'PrinterID': string = '';
  'BrandName': string = '';
  'ModelName': string = '';
  'IsActive': string = '';
  'IsDeleted': string = '';
}

export class ThirdPartyPaymentMaster {
  'ThirdPartyPaymentID': string = '';
  'Name': string = '';
  'PaymentTypeID': string = '';
}

export class ThirdPartyPaymentCompanyMappingAPI {
  'TPPCMappingID': string = '';
  'CompanyID': string = '';
  'ThirdPartyPaymentID': string = '';
  'PaymentDisplayName': string = '';
}

export class PaymentCredential {
  'BPCID': string = '';
  'ThirdPartyPaymentID': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
  'Urls': string = '';
  'UserName': string = '';
  'Password': string = '';
  'Notes': string = '';
  'MerchantID': string = '';
  'UserID': string = '';
  'Pin': string = '';
  'VendorID': string = '';
  'VendorAppName': string = '';
  'VendorAppVersion': string = '';
  'ServerType': string = '';
  'EncryptedUrls': string = '';
  'EncryptedUserName': string = '';
  'EncryptedPassword': string = '';
  'EncryptedNotes': string = '';
  'EncryptedMerchantID': string = '';
  'EncryptedUserID': string = '';
  'EncryptedPin': string = '';
  'EncryptedVendorID': string = '';
  'EncryptedVendorAppName': string = '';
  'EncryptedVendorAppVersion': string = '';
  'EncryptedServerType': string = '';
}

export class ModuleGeneralSetting extends CommonProperties {
  'ModuleGeneralSettingID': string = '';
  'DuplicatePrintID': string = '';
  'POSPrefix': string = '';
  'KOTCountID': string = '';
  'KOTFontSizeID': string = '';
  'KOTServerName': string = '';
  'KDSWithoutDisplay': string = '';
  'NoPrinter': string = '';
  'PrintOnPaymentDone': string = '';
  'CustomerNameOnKOT': string = '';
  'ShowNewOrderDialog': string = '';
  'KOTDateTime': string = '';
  'RoundingTotal': string = '';
  'RunningOrderDisplayOnKOT': string = '';
  'DisplayTipSection': string = '';
  'CashRegisterWork': string = '';
  'DisplayTipOnPrintCheck': string = '';
  'KOTOrderType': string = '';
  'DisplayCardNo': string = '';
  'PrintCancelKOT': string = '';
  'SeparateFoodLiquor': string = '';
  'ShowModifier': string = '';
  'MTOSOPD': string = '';
  'TillCurrency1': string = '';
  'TillCurrency2': string = '';
  'TillCurrency3': string = '';
  'TillCurrency4': string = '';
  'TillCurrency5': string = '';
  'TillCurrency6': string = '';
  'FastOrderPreferenceID': string = '';
  'PINOnOrders': string = '';
  'IAAS': string = '';
  'AICCTIAS': string = '';
  'AISIAS': string = '';
  'ThankYouMessage': string = '';
  'IsServingPizza': string = '';
  'OpenItemAsID': string = '';
  'KIOSKRoundingTotal': string = '';
  'ShowTipDialog': string = '';
  'CheckForDiscount': string = '';
  'ShowCouponOption': string = '';
  'IsCashKiosk': string = '';
  'IsCashPOS': string = '';
  'IsCardKiosk': string = '';
  'IsCardPOS': string = '';
  'DayStartTime': string = '';
  'DayEndTime': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'POSCashDiscount': string = '';
  'IsShowPaymentScreen': string = '';
  'IsCurrencyPrefixPOS': string = '';
  'IsCurrencyPrefixKiosk': string = '';
  'IsItemDetailScreenShowKiosk': string = '';
  'UsePercentageCardPOS': string = '';
  'UpchargeCardPOSAmount': string = '';
  'UsePercentageCashPOS': string = '';
  'UpchargeCashPOSAmount': string = '';
  'UsePercentageCardKIOSK': string = '';
  'UpchargeCardKIOSKAmount': string = '';
  'UsePercentageCashKIOSK': string = '';
  'UpchargeCashKIOSKAmount': string = '';
  'IsShowDineInButton': string = '';
  'DineInButtonPosition': string = '';
  'DineInButtonText': string = '';
  'IsShowTakeOutButton': string = '';
  'TakeOutButtonPosition': string = '';
  'TakeOutButtonText': string = '';
  'IsShowHomeScreenLogo': string = '';
  'EnableTableTents': string = '';
  'StartingBalance': string = '';
  'ResetBalanceTypeID': string = '';
  'IsRequireTillClosureBeforeClockout': string = '';
  'IsOfflineTillManagement': string = '';
  'IsPromptToSetTillsUponLogin': string = '';
  'IsRequireSettingOfTills': string = '';
  'IsAllowCashDrawerLockdown': string = '';
  'IsEnableVirtualTills': string = '';
  'IsEnableTillAlert': string = '';
  'TillAlertValue': string = '';
  'IsOverMaxCloseout': string = '';
  'OverMaxCloseoutValue': string = '';
  'IsOverWarningCloseout': string = '';
  'OverWarningCloseoutValue': string = '';
  'Time1': string = '';
  'Time2': string = '';
  'Time3': string = '';
  'Color1': string = '';
  'Color2': string = '';
  'Color3': string = '';
  'IsLogOutTime': string = '';
  'LogOutTime': string = '';
  'IsAutomaticGratuityOnPOS': string = '';
  'AutomaticGratuityPOS': string = '';
  'IsGratuityPercentagePOS': string = '';
  'MinNoOfGuestAGPOS': string = '';
  'IsAutomaticGratuityOnKIOSK': string = '';
  'AutomaticGratuityKIOSK': string = '';
  'IsGratuityPercentageKIOSK': string = '';
  'MinNoOfGuestAGKIOSK': string = '';
  'UseLoyaltyPOS': string = '';
  'UseLoyaltyKiosk': string = '';
  'UseIngredientMapping': string = '';
  'AddTipOnPaymentDone': string = '';
  'IsShowSplashScreenMessage': string = '';
  'SplashScreenMessage': string = '';
  'HomeScreenMessage': string = '';
  'EnableHouseAccounts': string = '';
  'ShowPreAuth': string = '';
  'UseKDSOnline': string = '';
  'OrderSendToKitchen': string = '';
  'ShowTipDialogOnPOS': string = '';
  'TipFromPOS': string = '';
  'TipFromPinpad': string = '';
  'NoOfDaysForClearOrders': string = '';
  'CustomerInfoOnNewOrders': string = '';
  'DineIn': string = '';
  'TakeOut': string = '';
  'Delivery': string = '';
  'PhoneIn': string = '';
  'EnableCFD': string = '';
  'NoTaxOnTakeout': string = '';
  'SendSMS': string = '';
  'UpdateCFD': string = '';
  'ShowModifierSelectionPopup': string = '';
  'ShowPhoneNumber': string = '';
  'ShowEmail': string = '';
  'ShowNegativeModifiers': string = '';
  'ShowDefaultModifiers': string = '';
  'PrintBefore': string = '';
  'IndividualReceipt': string = '';
  'EnableTenderPayment': string = '';
  'EnableADA': string = '';
  'StoreClosedTimeMessage': string = '';
  'EnablePinpadBip': string = '';
  'ForceStoreHours': string = '';
  'RequireEmployeeShift': string = '';
  'SendEmailKiosk': string = '';
  'SendTextMsgKiosk': string = '';
  'ShowImageBGKiosk': string = '';
  'ImageBGKiosk': string = '';
  'ShowSolidColorBGKiosk': string = '';
  'ColorCodeKiosk': string = '';
  'ShowConGridKiosk': string = '';
  'CPrintOrderItemsKiosk': string = '';
  'CPrintConOrderItemsKiosk': string = '';
  'MPrintOrderItemsKiosk': string = '';
  'MPrintConOrderItemsKiosk': string = '';
  'AcceptPaymentsForOOKiosk': string = '';
  'UsePOSForOnlineOrderOnly': string = '';
  'EnableCashRecycler': string = '';
  'WelcomeTextKiosk': string = '';
  'IsBarCodeReaderInstalled': string = '';
  'IsItemAvailableForPOS': string = '';
  'DoNotShowItemOnMenuForPOS': string = '';
  'IsItemAvailableForKiosk': string = '';
  'DoNotShowItemOnMenuForKiosk': string = '';
  'ShowCalorieCounts': string = '';
  'ShowCustomLoyaltyLogo': string = '';
  'CustomLoyaltyLogoPath': string = '';
  'EnableHouseAccountsForKiosk': string = '';
  'IsPrintSeatColumn': string = '';
  'IsPrintCourseColumn': string = '';
  'IsCashManagement': string = '';
  'DontForceEmployeeSchedule': string = '';
  'BackgroundColorForADA': string = '';
  'ContrastColorForADA': string = '';
  'HomeImageLogo': string = '';
  'IsmAllowAddtoCartKiosk': string = '';
  'IsmDoNotAllowAddtoCartKiosk': string = '';
  'IsmAllowAddtoOrderPOS': string = '';
  'IsmDoNotAllowAddtoOrderPOS': string = '';
  'CountCashAtClockIn': string = '';
  'CountCashAtClockOut': string = '';
  'StartingCashAmountAtOpen': string = '';
  'IsAskForCustomerName': string = '';
  'AskCustomerIfTheyWantReceipt': string = '';
  'ReturningCustomersFeatures': string = '';
  'KOTFooterMessage': string = '';
  'ShowModifierGroupName': string = '';
  'CommaSeparatedModifiers': string = '';
  'BackgroundImage': string = '';
  'UseSeparateImageforHomeScreen': string = '';
  'SeparateHomeScreenImagePath': string = '';
  'ShowAvailableInventory': string = '';
  'HeadertextForCustomerInPOS': string = '';
  'FootertextForCustomerInPOS': string = '';
  'HeadertextForMerchantInPOS': string = '';
  'FootertextForMerchantInPOS': string = '';
  'HeadertextForCustomerInKiosk': string = '';
  'FootertextForCustomerInKiosk': string = '';
  'HeadertextForMerchantInKiosk': string = '';
  'FootertextForMerchantInKiosk': string = '';
  'GratuityNotTaxable': string = '';
  'ShowItemTagImages': string = '';
  'WidthForItemPrintSize': string = '';
  'HeightForItemPrintSize': string = '';
  'WidthForCustomerNamePrintSize': string = '';
  'HeightForCustomerNamePrintSize': string = '';
  'WidthForTicketNumberPrintSize': string = '';
  'HeightForTicketNumberPrintSize': string = '';
  'WidthForOrderInformationPrintSize': string = '';
  'HeightForOrderInformationPrintSize': string = '';
  'WidthForOrderTypePrintSize': string = '';
  'HeightForOrderTypePrintSize': string = '';
  'WidthForInstructionsPrintSize': string = '';
  'HeightForInstructionsPrintSize': string = '';
  'WidthForFooterPrintSize': string = '';
  'HeightForFooterPrintSize': string = '';
  'WidthForDateTimePrintSize': string = '';
  'HeightForDateTimePrintSize': string = '';
  'UseCustomTextSize': string = '';
  'CashManagementMode': string = '';
  'IsPocketTill': string = '';
  'ShowWelcomeLoyaltyLogo': string = '';
  'WelcomeLoyaltyLogoPath': string = '';
  'WelcomeFooterText': string = '';
  'POSMode': string = '';
  'ChooseTimeForAutoCapture': string = '';
  'IsDefaultStartingCash': string = '';
  'IsTransparentDineIn': string = '';
  'TransparentButtonColor': string = '';
  'EnableGiftCardForKiosk': string = '';
  'EnableGiftCardForPOS': string = '';
  'IsHouseCashTip': string = '';
  'UpSellHeaderText': string = '';
  'UpSellDescription': string = '';
  'TipHeaderText': string = '';
  'TipDescription': string = '';
  'IsAutoScrollToNewOrders': string = '';
  'MaxAlcoholicItemsPerOrder': string = '';
  'DisableItemSpecialRequestForKiosk': string = '';
  'IsPrintTokenAndCustomerReceiptTogether': string = '';
  'ShowCustomerName': string = '';
  'ShowServerName': string = '';
  'ShowCategoryName': string = '';
  'SMSOrderLinkBefore': string = '';
  'SMSOrderLinkAfter': string = '';
  'DisableScreenSaver': string = '';
  'HideStartsAtForVariablePrice': string = '';
  'EnableTipPooling': string = '';
  'SendOrderKitchenPayment': string = '';
  'TipDialogCashPayment': string = '';
  'TipDialogCreditCardPayment': string = '';
  'EnableConversationalOrdering': string = '';
  'CustomerInfoDialogAtPay': string = '';
  'SendItemToKitchenWhenAdd': string = '';
  'EnableAutoCombo': string = '';
  'SkipCollectTipForEmployee': string = '';
  'ShowModifierGroupNameForKDS': string = '';
  'EnableDeliveryManagement': string = '';
  'EnableMachineLearningUpsell': string = '';
  'EnableCashSafeDropAlert': string = '';
  'CashDrawerLimits': string = '';
  'EnableFireOrder': string = '';
  'EnableConcessionaireMode': string = '';
  'POSMenuLayout': string = '';
  'UseBumpBar': string = '';
  'MinimizeSidebar': string = '';
  'HideActions': string = '';
  'SkipModifierPopUp': string = '';
  'ShowDelayOrderButton': string = '';
  'AllowToCompletePaidOrder': string = '';
  'EnableSignatureLine': string = '';
  'MinimumRequiredAmount': string = '';
  'IsConcessionaire': string = '';
  'IsShowCarInfo': string = '';
  'DriveThru': string = '';
  'WindowHeaderText': string = '';
  'WindowTitle': string = '';
  'WindowText': string = '';
  'TentNumberText': string = '';
  'HideZReport': string = '';
  'HideItemTaxPrice': string = '';
  'CustomerNameLabel': string = '';
  'CustomerNameHeaderText': string = '';
  'CustomerNameHint': string = '';
  'IsMapDevice': string = '';
  'IsMinimizeSpacing': string = '';
  'GeneralTimeoutKIOSK': string = '';
  'CheckoutScreenTimeoutKIOSK': string = '';
  'PrintFreedomPayReceipt': string = '';
  'HideSendButton': string = '';
  'IsRemindEmployeeBreaks': string = '';
  'RemindBreaksPOS': string = '';
  'HideEmailReceiptButton': string = '';
  'ThankYouWindowTimer': string = '';
  'IsShowKOTPaymentType': string = '';
  'StartOrderOption': string = '';
  'CustomerInfo': string = '';
  'IsShowPaidNotPaid': string = '';
  'LogoTypeID': string = '';
  'OrderCustomerNameInfoHeader': string = '';
  'OrderCustomerNameInfoHint': string = '';
  'OrderCustomerPhoneInfoHint': string = '';
  'OrderCustomerEmailInfoHint': string = '';
  'AllowPurchaseOfGiftCardPOS': string = '';
  'AllowPurchaseOfGiftCardKIOSK': string = '';
  'EnableCashManagementPOSLite': string = '';
  'UseHighlightForColor': string = '';
  'EnableOrderThrottling': string = '';
  'StopTakingOrderAtThreshold': string = '';
  'StopOrderTakingThresholdValue': string = '';
}

export class OnlineOrderSetting {
  'OOSID': string = '';
  'OnlineOrderActive': string = '';
  'OnlineOrderPaused': string = '';
  'EstimatedDeliveryTime': string = '';
  'EstimatedReadyForPickUp': string = '';
  'UseTemporarayTimeUntill': string = '';
  'UseTemporaryTimes': string = '';
  'TemporaryEstimatedDeliveryTime': string = '';
  'TemporaryEstimatedReadyForPickupTime': string = '';
  'IsCollapsedCategory': string = '';
  'TableOrdering': string = '';
  'OrderSendToKitchenMode': string = '';
  'IsShowPicturesForModifiersAndVariation': string = '';
  'IsMinOrderForConcessionaire': string = '';
}

export class GeneralWeekDayMaster {
  'GeneralWeekDayID': string = '';
  'DayID': string = '';
  'StartTime': string = '';
  'EndTime': string = '';
  'IsActive': string = '';
}

export class NotificationSetting {
  'NPMID': string = '';
  'Title': string = '';
  'ModuleID': string = '';
  'ModuleName': string = '';
  'BranchIsEmail': string = '';
  'BranchIsSMS': string = '';
  'EmailText': string = '';
  'SMSText': string = '';
}

export class BranchLoyaltyCredential {
  'BLCID': string = '';
  'ThirdPartyLoyaltyID': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
  'Urls': string = '';
  'UserName': string = '';
  'Password': string = '';
  'Notes': string = '';
  'EncryptedUrls': string = '';
  'EncryptedUserName': string = '';
  'EncryptedPassword': string = '';
  'EncryptedNotes': string = '';
  'IsSelected': string = '';
}

export class ThirdPartyLoyalty_Company_Mapping {
  'TPLMID': string = '';
  'ThirdPartyLoyaltyID': string = '';
  'CompanyID': string = '';
}

export class ThirdPartyLoyaltyMaster {
  'ThirdPartyLoyaltyID': string = '';
  'Name': string = '';
  'IsActive': string = '';
}

export class ThirdPartyPOSMaster {
  'ThirdPartyPOSID': string = '';
  'Name': string = '';
  'IsActive': string = '';
}

export class BranchPOSCredential {
  'BPOSCID': string = '';
  'ThirdPartyPOSID': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
  'Urls': string = '';
  'UserName': string = '';
  'Password': string = '';
  'Notes': string = '';
  'EncryptedUrls': string = '';
  'EncryptedUserName': string = '';
  'EncryptedPassword': string = '';
  'EncryptedNotes': string = '';
  'IsSelected': string = '';
}

export class ThirdPartyPOS_Company_Mapping {
  'TPCMID': string = '';
  'ThirdPartyPOSID': string = '';
  'CompanyID': string = '';
}

export class TillStatusMaster {
  'TillStatusID': string = '';
  'Name': string = '';
  'IsActive': string = '';
}

export class TipMaster {
  'TipID': string = '';
  'IsDefault': string = '';
  'IsPercentage': string = '';
  'PercentageOrAmount': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class OrderKitchenStatusMaster {
  'OrderKitchenStatusID': string = '';
  'Name': string = '';
  'ColorCode': string = '';
  'IsActive': string = '';
}

export class TierPricingMaster {
  'TierPricingID': string = '';
  'OrderPlaceID': string = '';
  'AdjustPricesForID': string = '';
  'AdjustPricesByID': string = '';
  'AdjustPricesByValue': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class TierPricingDetailMaster {
  'TierPricingDetailID': string = '';
  'TierPricingID': string = '';
  'OrderPlaceID': string = '';
  'ItemID': string = '';
  'BasePrice': string = '';
  'NewPrice': string = '';
  'IsHideItem': string = '';
  'IsFreezePrice': string = '';
  'ColorClass': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class TierPricingCategoryMapping {
  'TPCM': string = '';
  'TierPricingID': string = '';
  'CategoryID': string = '';
}

export class SchedulePromoPricingMaster {
  'SchedulePromotionId': string = '';
  'Name': string = '';
  'IsByWeek': string = '';
  'IsByDate': string = '';
  'IsRecurringYearly': string = '';
  'StartDate': string = '';
  'EndDate': string = '';
  'AdjustPricesForID': string = '';
  'AdjustPricesByID': string = '';
  'AdjustPricesByValue': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class ScheduleMenuTiersMapping {
  'ScheduleMenuTierMappingId': string = '';
  'SchedulePromotionId': string = '';
  'OrderPlaceId': string = '';
}

export class SchedulePromoPricingDetailMaster {
  'SchedulePromoOrderPlacePricingDetailID': string = '';
  'SchedulePromotionId': string = '';
  'OrderPlaceID': string = '';
  'ItemID': string = '';
  'BasePrice': string = '';
  'NewPrice': string = '';
  'IsHideItem': string = '';
  'IsFreezePrice': string = '';
  'ColorClass': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class SchedulePromoPricingCategoryMapping {
  'SOTCMappingId': string = '';
  'SchedulePromotionId': string = '';
  'OrderPlaceId': string = '';
  'CategoryID': string = '';
}

export class ScheduleItemWeekDayMaster {
  'ItemWeekDayID': string = '';
  'DayID': string = '';
  'StartTime': string = '';
  'EndTime': string = '';
  'SchedulePromotionId': string = '';
}

export class TenderTypeMaster {
  'TenderTypeID': string = '';
  'Name': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class ReadyItemStock {
  'ReadyItemStockID': string = '';
  'ItemID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'CurrentStock': string = '';
  'LowThreshold': string = '';
}

export class PayboticDevjavoo {
  'PayboticDevjavooID': string = '';
  'AuthanticationKey': string = '';
  'RegistrationID': string = '';
  'TPNNumber': string = '';
  'DeviceID': string = '';
  'ServiceCharge': string = '';
  'TPPaymentID': string = '';
}

export class CashRecyclerModel {
  'CashRecyclerModelID': string = '';
  'ModelName': string = '';
  'ThirdPartyPaymentID': string = '';
}

export class CashRecycler {
  'CashRecyclerID': string = '';
  'CashRecycleName': string = '';
  'CashRecyclerModelID': string = '';
  'IPAddress': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
  'Port': string = '';
  'GardaSafeID': string = '';
  'ShowActiveDenomination': string = '';
  'IsActive': string = '';
}

export class Concessionaire_Item_Mapping {
  'CIM': string = '';
  'ConcessionaireID': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
  'ItemID': string = '';
  'CategoryID': string = '';
}

export class OloIntegration {
  'OloIntegrationID': string = '';
  'Url': string = '';
  'key': string = '';
  'vendorID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'CustomerNamePrefix': string = '';
  'CustomerGuestName': string = '';
  'IsShowTableTentPrefix': string = 'false';
  'IsAppendOrderTypeName': string = 'false';
  'ThirdPartyPrefixForAllItemSpecialRequests': string = 'false';
  'PrefixText': string = '';
  'UseCloudApi' : boolean;
}

export class CustomFields {
  'id': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'label': string = '';
  'qualificationcriteria': string = '';
  'required': string = '';
  'validationregex': string = '';
  'OrderTypeID': string = '';
  'IsForTableTent': string = '';
}

export class OloVendorSetting {
  'OVSID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'supportsdinein': string = '';
  'canpickup': string = '';
  'supportscurbside': string = '';
}

export class LoyaltyProgram {
  'LoyaltyProgramID': string = '';
  'ProgramName': string = '';
  'IsActive': string = '';
}

export class OrderTypeMaster {
  'OrderTypeID': string = '';
  'Name': string = '';
  'IsActive': string = '';
}

export class OrderTypeMappingMaster {
  'OrderTypeID': string = '';
  'Name': string = '';
  'DisplayOrder': string = '';
  'DisplayLabel': string = '';
  'PosIsActive': string = '';
  'OnlineOrderIsActive': string = '';
  'KioskIsActive': string = '';
  'PosPath': string = '';
  'KioskPath': string = '';
  'OnlineOrderPath': string = '';
  'KioskTableTent': string = '';
  'KioskAskCustomerName': string = '';
  'KioskAskCustomerPhone': string = '';
  'KioskAskCustomerEmail': string = '';
  'RefOrderTypeID': string = '';
}

export class CloverIntegration {
  'CloverIntegrationId': string = '';
  'MerchantId': string = '';
  'AuthToken': string = '';
  'TenderId': string = '';
}

export class ParBrinkIntegration {
  'ParBrinkIntegrationID': string = '';
  'AccessToken': string = '';
  'LocationToken': string = '';
}

export class DeliverECTIntegration {
  'ClientId': string = '';
  'ClientSecret': string = '';
  'Audience': string = '';
  'PosLocationId': string = '';
  'PosAccountId': string = '';
}

export class OtherCharges_TaxGroup_Mapping {
  'OCTGMappingID': string = '';
  'OtherChargeID': string = '';
  'TaxGroupID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class LockerSetting {
  'LockerID': string = '';
  'IfUsesLocker': string = '';
  'APIUrl': string = '';
  'APIUsername': string = '';
  'APISecret': string = '';
  'APIToken': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'CreatedDate': string = '';
  'CreatedBy': string = '';
  'IPAddress': string = '';
}

export class TwilioAccountInformation {
  'TwilioAccountInfoID': string = '';
  'AccountSid': string = '';
  'AuthToken': string = '';
  'PhoneNumber': string = '';
  'IsActive': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'UpdatedBy': string = '';
  'UpdatedDate': string = '';
  'IPAddress': string = '';
  'IsDeleted': string = '';
}

export class DenominationSettings {
  'DenominationSettingID': string = '';
  'DenominationID': string = '';
  'MinThreshold': string = '';
  'MaxThreshold': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'UpdatedBy': string = '';
  'UpdatedDate': string = '';
  'IPAddress': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
  'CashRecyclerID': string = '';
}

export class CashRecyclerSettings {
  'CRSID': string = '';
  'IsSMS': string = '';
  'MobileNumbers': string = '';
  'IsEmail': string = '';
  'EmailIds': string = '';
  'NotificationMinutes': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'UpdatedBy': string = '';
  'UpdatedDate': string = '';
  'IPAddress': string = '';
  'Isgarda_pickups': string = '';
  'UnsignedURL': string = '';
  'AgentUUID': string = '';
  'AccesskeyID': string = '';
  'Secret': string = '';
}

export class ModuleGeneralTaxMapping {
  'ModuleTaxMappingID': string = '';
  'ModuleGeneralSettingID': string = '';
  'TaxGroupID': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'IPAddress': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
}

export class ModuleGeneralOrderReceipt {
  'OrderReceiptID': string = '';
  'ModuleGeneralSettingID': string = '';
  'HeaderText': string = '';
  'MainText': string = '';
  'SecondaryText': string = '';
  'ShowPointsEarned': string = '';
  'OrderImage': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'IPAddress': string = '';
}

export class OrderConfirmationPrintSettings {
  'OrderConfirmationPrintSettingsID': string = '';
  'ShowModifierGroupName': string = '';
  'ShowModifierSelections': string = '';
  'ShowVariations': string = '';
  'ShowItemSpecialRequest': string = '';
  'ShowOrderSpecialRequest': string = '';
  'ShowOrderDate': string = '';
  'ShowInvoiceNumber': string = '';
  'ShowCustomerPhone': string = '';
  'ShowOrderType': string = '';
  'ShowTokenNumber': string = '';
  'ShowCustomerName': string = '';
  'ShowTopDividerLine': string = '';
  'ShowTotalItems': string = '';
  'ShowItemCounter': string = '';
  'ShowBranchName': string = '';
  'ShowFooterText': string = '';
  'FooterText': string = '';
  'CreatedDate': string = '';
  'CreatedBy': string = '';
  'IPAddress': string = '';
  'ModuleGeneralSettingID': string = '';
  'ItemFontSize': string = '';
}

export class PrepKDSItem {
  'PrepKDSItemID': string = '';
  'PrepKDSItemName': string = '';
  'PrepKDSItemColor': string = '';
  'HoldItemColor': string = '';
  'HoldItemTextColor': string = '';
  'PrepKDSItemDisplayOrder': string = '';
  'IsActive': string = '';
  'CreatedDate': string = '';
  'CreatedBy': string = '';
  'UpdatedDate': string = '';
  'UpdatedBy': string = '';
  'PrepKDSItemTextColor': string = '';
}

export class PrepKDSBoardModifierSelectionMappings {
  'PrepKDSBoard_ModifierSelection_Mapping_ID': string = '';
  'PrepKDSBoardID': string = '';
  'ModifierChoiceID': string = '';
  'IngredientID': string = '';
  'IsActive': string = '';
  'XCode': string = '';
  'YCode': string = '';
  'ShapeTypeID': string = '';
  'Height': string = '';
  'Width': string = '';
}

export class PrepKDSItemBoardMappings {
  'PrepKDSItem_Board_Mapping_ID': string = '';
  'PrepKDSBoardID': string = '';
  'PrepKDSItemID': string = '';
  'DisplayOrder': string = '';
  'IsActive': string = '';
}

export class PrepKDSItemItemMappings {
  'PrepKDSItem_Item_MappingID': string = '';
  'PrepKDSItemID': string = '';
  'ItemID': string = '';
  'IsActive': string = '';
}

export class PrepKDSBoard {
  'PrepKDSBoardID': string = '';
  'PrepKDSBoardName': string = '';
  'PrepKDSBoardDescription': string = '';
  'IsActive': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'UpdatedBy': string = '';
  'UpdatedDate': string = '';
  'IPAddress': string = '';
}

export class KDSDeviceMaster {
  'EPOS_KDS_MID': string = '';
  'ExternalPOSID': string = '';
  'KDSID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'IsDeleted': string = '';
  'IsActive': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'IPAddress': string = '';
}

export class OPBSettings {
  'OPBSettingsID': string = '';
  'GeneralBackgroundColor': string = '';
  'DateTimeTextColor': string = '';
  'HeaderBackgroundColor': string = '';
  'BodyBackgroundColor': string = '';
  'BodyTextColor': string = '';
  'TicketBackgroundColor': string = '';
  'TicketTitleColor': string = '';
  'TicketOrderNumberColor': string = '';
  'TicketCustomerNameColor': string = '';
  'ShowPickedUpOrder': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'UpdatedBy': string = '';
  'UpdatedDate': string = '';
  'IPAddress': string = '';
  'IsActive': string = '';
  'ProgressOrderClearOut': string = '';
  'ReadyOrderClearOut': string = '';
  'PickedUpOrderClearOut': string = '';
  'OrderStatusForKDS': string = '';
  'OrderStatusForExpediter': string = '';
}

export class DenominationMaster {
  'DenominationID': string = '';
  'DenominationName': string = '';
  'IsActive': string = '';
  'IsDeleted': string = '';
  'CompanyID': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'UpdatedBy': string = '';
  'UpdatedDate': string = '';
  'IPAddress': string = '';
}

export class OrderEmailReceiptSetting {
  'EmailReceiptID': string = '';
  'EmailReceiptLogoPath': string = '';
  'EmailReceiptHeaderColor': string = '';
  'EmailReceiptHeaderText': string = '';
  'EmailReceiptPhoneNumber': string = '';
  'EmailReceiptBoldColor': string = '';
  'EmailReceiptButtonColor': string = '';
  'EmailReceiptButtonText': string = '';
  'EmailReceiptWebsite': string = '';
  'EmailReceiptFooterText': string = '';
  'EmailReceiptSMSBody': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'UpdatedBy': string = '';
  'UpdatedDate': string = '';
  'IPAddress': string = '';
}

export class DevicePaymentMapping {
  'DPMappingID': string = '';
  'StaffModuleID': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
  'PaymentTypeID': string = '';
}

export class PaymentTypeGroupMaster {
  'PaymentTypeGroupID': string = '';
  'Name': string = '';
  'IsActive': string = '';
  'DisplayName': string = '';
}

export class ThirdPartyPaymentBranchMapping {
  'TPPBMappingID': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
  'ThirdPartyPaymentID': string = '';
  'PaymentDisplayName': string = '';
  'TenderId': string = '';
}

export class ModuleGeneralEmailReceiptSetting {
  'EmailReceiptID': string = '';
  'ModuleGeneralSettingID': string = '';
  'HeaderText': string = '';
  'MainText': string = '';
  'SecondaryText': string = '';
  'FinishText': string = '';
  'EmailImage': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'IPAddress': string = '';
}

export class IsCheckHelium2Rent {
  'IsHelium2Rent': boolean = false;
}

export class OracleGeneralSettings {
  'APIEmployeeNumber': string = '';
  'DailyFullMenuRefresh': string = '';
  'FullMenuRefreshTime': string = '';
  'GrubbrrAgentAPIHost': string = '';
  'GrubbrrAgentUsername': string = '';
  'GrubbrrAgentPassword': string = '';
  'IsIgnoreCategoryItemMapping': string = '';
  'IsIgnoreCommonModifierItemMapping': string = '';
  'IsIgnoreIngredientCommonModifierMapping': string = '';
  'MainCategorySource': string = '';
  'POSDeviceID': string = '';
  'PropertyName': string = '';
  'RevenueCenterCodeRVC': string = '';
  'SimphonyAPIHost': string = '';
  'TenderID': string = '';
  'TipTenderID': string = '';
  'ThirdPartyLoyaltyDiscountID': string = '';
  'ThirdPartyLoyaltyDiscountName': string = '';
  'ThirdPartyLoyaltyPaymentTenderID': string = '';
  'ThirdPartyLoyaltyPaymentTenderName': string = '';
}

export class OracleMicrosIntegration {
  "DBUserName": string = "";
  "DBUserPassword": string = "";
  "EmployeeObjectNumber": string = "";
  "ID":string = "";
  "ImportCategorySource": string = "";
  "MenuLevelID": string = ""
  "RVC_Code":string= "";
  "TenderID": string = "";
  "ThirdPartyLoyaltyDiscountID":string="";
  "ThirdPartyLoyaltyPaymentTenderID":string="";
  "TransactionAPIURL":string = "";
  "TipTenderID": string = "";

}

export class KioskFeaturedItems {
  'KFIID': string = '';
  'ItemID': string = '';
  'IsMainFeaturedItem': string = '';
  'DisplayOrder': string = '';
  'MainFeatureLabel': string = '';
  'MainFeatureDescription': string = '';
  'DescriptionColor': string = '';
  'ButtonText': string = '';
  'ButtonColor': string = '';
  'HeaderImage': string = '';
  'IsActive': string = '';
  'IsDeleted': string = '';
}

export class KioskFeaturedItemSettings {
  'KFISID': string = '';
  'CategoryName': string = '';
  'CategoryTitle': string = '';
  'HeaderText': string = '';
  'CategorySelectedImage': string = '';
  'CategoryDeselectedImage': string = '';
  'IsEnableFeaturedItem': string = '';
  'IsActive': string = '';
  'IsDeleted': string = '';
}

export class ModifierOverrideDetail {
  'MODID': string = '';
  'ItemID': string = '';
  'ModifierID': string = '';
  'IngredientID': string = '';
  'ModifierDetailID': string = '';
  'isDefault': string = '';  
  "Quantity": string = ''; 
  "DisplayOrder": string = ''; 
  "KOTKDSDisplayOrder": string = ''; 
  "isSendtoPOS": string = ''; 
  'IsSendNegativeModifierstoPOS': string = '';
}

export class OnlineOrderConcessionaire {
  'OOCID': string = '';
  'ConcessionaireID': string = '';
  'MinOrderAmount': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class OAuthMaster {
  'ApplicationID': string = '';
  'AccessToken': string = '';
  'MerchantId': string = '';
  'LocationId': string = '';
  'RefreshToken': string = '';
  'ExpiresAt': string | null = '';
  'RefID': string = '';
  'ApplicationSecret': string = '';
}

export class RevenueCenterMaster {
  'RevenueCenterID': string = '';
  'RevenueCenterName': string = '';
  'RevenueCenterDescription': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class ConcessionaireDeviceMapping {
  'ConcessionaireDeviceID': string = '';
  'StaffModuleID': string = '';
  'ConcessionaireID': string = '';
  'IsDefaultConcessionaire': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
}

export class ConcessionaireScreenSaverMapping {
  'CSMID': string = '';
  'ConcessionaireID': string = '';
  'ScreenSaverID': string = '';
  'Name': string = '';
  'DisplayOrder': string = '';
  'IsActive': string = '';
  'IsDeleted': string = '';
}

export class OPBMaster {
  'OPBID': string = '';
  'Name': string = '';
  'DeviceID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'IsMapDevice': string = '';
  'DontShowLogo': string = '';
  'CustomHeaderText': string = '';
}

export class OPBDeviceMaster {
  'EPOS_OPB_MID': string = '';
  'ExternalPOSID': string = '';
  'OPBID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'IsDeleted': string = '';
  'IsActive': string = '';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'IPAddress': string = '';
}

export class OrderTypeOPBMapping {
  'OTOPBMID': string = '';
  'OrderTypeID': string = '';
  'OPBID': string = '';
  'DisplayOrder': string = '';
  'DisplayLabel': string = '';
  'IsEnabled': string = '';
}

export class CustomZReportSetting {
  'CSID': string = '';
  'CustomSectionId': string = '';
  'CustomSectionName': string = '';
  'CustomSectionSeqNo': string = '';
  'CustomSectionVisible': string = '';
}

export class StaffModuleOrderTypeMapping {
  'StaffModuleOrderID': string = '';
  'StaffModuleID': string = '';
  'ModuleID': string = '';
  'OrderTypeID': string = '';
  'IsActive': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
}

export class PusherSettingMaster {
  'AppID': string = '';
  'AppSecret': string = '';
  'AppKey': string = '';
  'Cluster': string = '';
  'PusherSettingID': string = '';
}

export class ThirdPartyCardNetworkBranchMappings {
  'TPCNMappingID': string = '';
  'ThirdPartyPaymentID': string = '';
  'CardNetworkID': string = '';
  'TenderID': string = '';
  'BranchID': string = '';
  'CompanyID': string = '';
  'MappedSource': string = 'ThirdPartyPayment';
  'CreatedBy': string = '';
  'CreatedDate': string = '';
  'UpdatedBy': string = '';
  'UpdatedDate': string = '';
}

export class ItemTypeMasters {
  'ItemTypeID': string = '';
  'Name': string = '';
}

export class GiftCardSettings {
  'NewGCShowCustomMessage': string = '';
  'NewGCMessageHeader': string = '';
  'NewGCMessageContent': string = '';
  'CustomGCMessageHeader': string = '';
  'CustomGCMessageContent': string = '';
  'RechargeGCShowCustomMessage': string = '';
  'RechargeGCMessageHeader': string = '';
  'RechargeGCMessageContent': string = '';
  'CompletingPaymentHeader': string = '';
  'CompletingPaymentContent': string = '';
  'CustomGCImage': string = '';
  'RechargeGCImage': string = '';
  'ShowCustomAmount': string = '';
}

export class NCRVendorIntegrations {
  'NVID': string = '';
  'CompanyID': string = '';
  'BranchID': string = '';
  'nepOrganization': string = '';
  'nepEnterpriseUnit': string = '';
  'sharedKey': string = '';
  'secretKey': string = '';
}

export class ConcessionaireDayWiseMasters {
  "ConcessionaireWeekDayID": string = '';
  "ConcessionaireID": string = '';
  "DayID": string = '';
  "StartTime": string = '';
  "EndTime": string = '';
  "IsActive": string = '';
}

export class Discount_Concessionaire_Mappings {
  "DCMID": string = '';
  "ConcessionaireID": string = '';
  "DiscountID": string = '';
  "CompanyID": string = '';
  "BranchID": string = '';
}

export class ItemTagGroupMasters {
  "ItemTagGroupID": string = "";
  "ItemTagGroupName": string = "";
  "DisplayOrder": string = "";
}

export class ConcessionaireTypes {
  "ConcessionaireTypeID": string = "";
  "Name": string = "";
}


export class CustomMessages {
  "CMID": string = "";
  "Name": string = "";
  "Title": string = "";
  "Message": string = "";
  "AdditionalText": string = "";
  "Image": string = "";
  "DisplayPriority": string = "";
  "MessageTriggeres": string = "";
  "Type": string = "";
  "IsActive": string = "";
  "BranchID": string = "";
  "CompanyID": string = "";
}

export class CustomMessage_OrderType_Mappings {
  "CMID": string = "";
  "OrderTypeID": string = "";
  "CompanyID": string = "";
  "BranchID": string = "";
}

export class CustomMessage_Category_Mappings {
  "CMID": string = "";
  "CategoryID": string = "";
  "CompanyID": string = "";
  "BranchID": string = "";
}

export class CustomMessage_Item_Mappings {
  "CMID": string = "";
  "ItemID": string = "";
  "CompanyID": string = "";
  "BranchID": string = "";
}

export class Concessionaire_ConcessionaireType_Mappings{
  "CCTMappingID": string = "";
  "ConcessionaireID": string = "";
  "ConcessionaireTypeID": string = "";
}

export class CompanyMasters{
  "CompanyID": string = "";
  "BusinessName": string = "";
}

export class BranchLoginRes {
  ModuleMasters = [new ModuleMaster()];
  FeatureMasters = [new FeatureMaster()];
  SubFeatureMasters = [new SubFeatureMaster()];
  RightsMasters = [new RightsMaster()];
  RightsFeatureMappings = [new Rights_Feature_Mapping()];
  FacilitiesMasters = [new FacilitiesMaster()];
  CuisinesMasters = [new CuisinesMaster()];
  UnitTypeMasters = [new UnitTypeMaster()];
  UnitTypeConversions = [new UnitTypeConversion()];
  WeekdayMasters = [new WeekdayMaster()];
  CustomerSignupTypes = [new CustomerSignupType()];
  PaymentTypeMasters = [new PaymentTypeMaster()];
  LogicMasters = [new LogicMaster()];
  DineTypeMasters = [new DineTypeMaster()];
  FilterMasters = [new FilterMaster()];
  CardTypeMasters = [new CardTypeMaster()];
  CardNetworkMasters = [new CardNetworkMaster()];
  OrderPlaceMasters = [new OrderPlaceMaster()];
  ShapeTypeMasters = [new ShapeTypeMaster()];
  ScannerMasters = [new ScannerMaster()];
  BranchMasters = new BranchMaster();
  BranchColorCodes = new BranchColorCodes();
  BranchModules = [new BranchModule()];
  BranchModuleRights = [new BranchModuleRight()];
  ConcessionaireMasters = [new Concessionaire()];
  ConcessionaireSectionBranchMapping = [
    new ConcessionaireSectionBranchMappingApi(),
  ];
  PayTypeMasters = [new PayTypeMaster()];
  OrderTypeMasters = [new OrderTypeMaster()];
  OrderStatusMasters = [new OrderStatusMaster()];
  StaffRoleMasters = [new StaffRoleMaster()];
  StaffRoleRights_Mappings = [new StaffRoleRights_Mapping()];
  StaffMasters = [new StaffMaster()];
  StaffRoles = [new StaffRole()];
  POSPermissionMasters = [new POSPermissionMaster()];
  POSRolePermissionMasters = [new POSRolePermissionMaster()];
  BreakMasters = [new BreakMaster()];
  StaffModuleMasters = [new StaffModuleMaster()];
  AttendanceMasters = [new AttendanceMaster()];
  AttendanceBreakMasters = [new AttendanceBreakMaster()];
  EmployeeShifts = [new EmployeeShiftSchedule()];
  EmployeeSchedules = [new EmployeeSchedule()];
  ItemLocations = [new ItemLocation()];
  CategoryMasters = [new CategoryMaster()];
  CategoryImages = [new CategoryImage()];
  CategoryDayWiseMasters = [new CategoryDayWiseMaster()];
  Category_Tax_Mappings = [new Category_Tax_Mapping()];
  TaxMasters = [new TaxMaster()];
  TaxGroupMasters = [new TaxGroupMaster()];
  TaxGroup_Mappings = [new TaxGroup_Mapping()];
  TaxGroup_OrderType_Mappings = [new TaxGroup_OrderType_Mapping()];
  OtherChargeMasters = [new OtherChargeMaster()];
  OtherCharges_SubFeature_Mappings = [new OtherCharges_SubFeature_Mapping()];
  SectionMasters = [new SectionMaster()];
  Section_Kitchen_Mappings = [new Section_Kitchen_Mapping()];
  TableMasters = [new TableMaster()];
  DividerMasters = [new DividerMaster()];
  Ingredients = [new Ingredient()];
  Ingredient_Filter_Mappings = [new Ingredient_Filter_Mapping()];
  CourseMasters = [new CourseMaster()];
  CourseMastersIndex = [new CourseMastersIndex()];
  Items = [new Item()];
  ItemDayWiseMasters = [new ItemDayWiseMaster()];
  Item_Tax_Mappings = [new Item_Tax_Mapping()];
  ItemImages = [new ItemImage()];
  ModifierImages = [new ModifierImage()];
  Item_Suggestive_Mappings = [new Item_Suggestive_Mapping()];
  Item_Category_Mappings = [new Item_Category_Mapping()];
  Item_Filter_Mappings = [new Item_Filter_Mapping()];
  ItemAttributes = [new ApiItemAttribute()];
  ItemPriceMasters = [new PriceMaster()];
  ItemAttributeOptions = [new ItemAttributeOption()];
  CommonAttribute_Item_Mappings = [new CommonAttribute_Item_Mappings()];
  UpSellItemMasters = [new ItemUpSellMaster()];
  ComboGroupMasters = [new ComboGroupMaster()];
  ComboGroupItemMasters = [new ComboGroupItemMaster()];
  KitchenMasters = [new KitchenMaster()];
  KDSTypeMasters = [new KDSTypeMaster()];
  KDSMasters = [new KDSMaster()];
  ExpediterMasters = [new ExpediterMaster()];
  OrderTypeKDSMappings = [new OrderTypeKDSMapping()];
  Kitchen_Item_Mapping = [new Kitchen_Item_Mapping()];
  Kitchen_Ingredient_Mapping = [new Kitchen_Ingredient_Mapping()];
  DiscountTypeMasters = [new DiscountTypeMaster()];
  DiscountLimitationMasters = [new DiscountLimitationMaster()];
  DiscountMasters = [new DiscountMaster()];
  Discount_Category_Mappings = [new Discount_Category_Mapping()];
  Discount_Item_Mappings = [new Discount_Item_Mapping()];
  DiscountHappyHours = [new DiscountHappyHours()];
  RecipientMasters = [new RecipientMaster()];
  CouponMasters = [new CouponMaster()];
  Coupon_Category_Mappings = [new Coupon_Category_Mapping()];
  Coupon_Item_Mappings = [new Coupon_Item_Mapping()];
  Coupon_Customer_Mappings = [new Coupon_Customer_Mapping()];
  TitleMasters = [new TitleMaster()];
  IndustryMasters = [new IndustryMaster()];
  TaxTypeMasters = [new TaxTypeMaster()];
  CustomerMasters = [new CustomerMaster()];
  ModifierMasters = [new ModifierMaster()];
  Modifier_CustomSubgroup_Mappings = [new Modifier_CustomSubgroup_Mapping()];
  ModifierDetailMasters = [new ModifierDetailMaster()];
  CommonModifier_Item_Mappings = [new CommonModifier_Item_Mapping()];
  ScreenSaverMasters = [new ScreenSaverMaster()];
  SpecialRequestMasters = [new SpecialRequestMaster()];
  PrinterMasters = [new PrinterMaster()];
  ThirdPartyPaymentMasters = [new ThirdPartyPaymentMaster()];
  ThirdPartyPaymentCompanyMappings = [new ThirdPartyPaymentCompanyMappingAPI()];
  PaymentCredentials = [new PaymentCredential()];
  ModuleGeneralSettings = [new ModuleGeneralSetting()];
  OnlineOrderSettings = [new OnlineOrderSetting()];
  GeneralWeekDayMasters = [new GeneralWeekDayMaster()];
  NotificationSettings = [new NotificationSetting()];
  BranchLoyaltyCredentials = [new BranchLoyaltyCredential()];
  ThirdPartyLoyaltyCompanyMappings = [new ThirdPartyLoyalty_Company_Mapping()];
  ThirdPartyLoyaltyMasters = [new ThirdPartyLoyaltyMaster()];
  ThirdPartyPOSMasters = [new ThirdPartyPOSMaster()];
  BranchPOSCredentials = [new BranchPOSCredential()];
  ThirdPartyPOSCompanyMappings = [new ThirdPartyPOS_Company_Mapping()];
  TillStatusMasters = [new TillStatusMaster()];
  TipMasters = [new TipMaster()];
  OrderKitchenStatusMasters = [new OrderKitchenStatusMaster()];
  TierPricingMasters = [new TierPricingMaster()];
  TierPricingDetailMasters = [new TierPricingDetailMaster()];
  TierPricingCategoryMappings = [new TierPricingCategoryMapping()];
  SchedulePromoPricingMasters = [new SchedulePromoPricingMaster()];
  ScheduleMenuTiersMappingMaster = [new ScheduleMenuTiersMapping()];
  SchedulePromoPricingDetailMasters = [new SchedulePromoPricingDetailMaster()];
  SchedulePromoPricingCategoryMappings = [
    new SchedulePromoPricingCategoryMapping(),
  ];
  ScheduleItemWeekDayMaster = [new ScheduleItemWeekDayMaster()];
  TenderTypeMasters = [new TenderTypeMaster()];
  ReadyItemStock = [new ReadyItemStock()];
  PayboticDevjavoos = [new PayboticDevjavoo()];
  GrubbrrPay = [new PayboticDevjavoo()];
  CashRecyclerModels = [new CashRecyclerModel()];
  CashRecyclers = [new CashRecycler()];
  Concessionaire_Item_Mappings = [new Concessionaire_Item_Mapping()];
  OloIntegrations = [new OloIntegration()];
  CustomFields = [new CustomFields()];
  OloVendorSettings = [new OloVendorSetting()];
  LoyaltyPrograms = [new LoyaltyProgram()];
  OrderTypeMappingMasters = [new OrderTypeMappingMaster()];
  CloverIntegration = new CloverIntegration();
  ParBrinkIntegration = new ParBrinkIntegration();
  DeliverECTIntegration = new DeliverECTIntegration();
  OtherCharges_TaxGroup_Mappings = [new OtherCharges_TaxGroup_Mapping()];
  LockerSettings = [new LockerSetting()];
  TwilioSettings = new TwilioAccountInformation();
  DenominationSettings = [new DenominationSettings()];
  CashRecyclerSettings = new CashRecyclerSettings();
  GratuityTaxGroupMapping = [new ModuleGeneralTaxMapping()];
  ModuleGeneralOrderReceipts = new ModuleGeneralOrderReceipt();
  OrderConfirmationPrintSetting = new OrderConfirmationPrintSettings();
  PrepKDSItem = [new PrepKDSItem()];
  PrepKDSBoardModifierSelectionMappings = [
    new PrepKDSBoardModifierSelectionMappings(),
  ];
  PrepKDSItemBoardMappings = [new PrepKDSItemBoardMappings()];
  PrepKDSItemItemMappings = [new PrepKDSItemItemMappings()];
  PrepKDSBoard = [new PrepKDSBoard()];
  KDSDeviceMasters = [new KDSDeviceMaster()];
  OPBSettings = [new OPBSettings()];
  DenominationMasters = [new DenominationMaster()];
  OrderEmailReceiptSetting = new OrderEmailReceiptSetting();
  DevicePaymentMappings = [new DevicePaymentMapping()];
  PaymentTypeGroupMasters = [new PaymentTypeGroupMaster()];
  ThirdPartyPaymentBranchMappings = [new ThirdPartyPaymentBranchMapping()];
  ModuleGeneralEmailReceiptSettings = new ModuleGeneralEmailReceiptSetting();
  ModelCheckHelium2Rent = new IsCheckHelium2Rent();
  OracleGeneralSetting = new OracleGeneralSettings();
  OracleMicrosIntegration = new OracleMicrosIntegration();
  KioskFeaturedItem = [new KioskFeaturedItems()];
  KioskFeaturedItemSetting = [new KioskFeaturedItemSettings()];
  ModifierOverrideDetail = [new ModifierOverrideDetail()];
  OnlineOrderConcessionaires = [new OnlineOrderConcessionaire()];
  SquareIntegrations = [new OAuthMaster()];
  RevenueCenterMasters = [new RevenueCenterMaster()];
  ConcessionaireDeviceMappings = [new ConcessionaireDeviceMapping()];
  ConcessionaireScreenSaverMappings = [new ConcessionaireScreenSaverMapping()];
  OPBMasters = [new OPBMaster()];
  OPBDeviceMasters = [new OPBDeviceMaster()];
  OrderTypeOPBMappings = [new OrderTypeOPBMapping()];
  CustomZReportSettings = [new CustomZReportSetting()];
  StaffModuleOrderTypeMappings = [new StaffModuleOrderTypeMapping()];
  PusherSettingMasters = [new PusherSettingMaster()];
  ThirdPartyCardNetworkBranchMappings = [
    new ThirdPartyCardNetworkBranchMappings(),
  ];
  GiftCardSettings = [new GiftCardSettings()];
  ItemTypeMasters = [new ItemTypeMasters()];
  NCRVendorIntegrations = [new NCRVendorIntegrations()];
  ConcessionaireDayWiseMasters = [new ConcessionaireDayWiseMasters()];
  Discount_Concessionaire_Mappings = [new Discount_Concessionaire_Mappings()];
  ItemTagGroupMasters = [new ItemTagGroupMasters()];
  ConcessionaireTypes = [new ConcessionaireTypes()];

  CustomMessages = [new CustomMessages()];
  CustomMessage_OrderType_Mappings = [new CustomMessage_OrderType_Mappings()];
  CustomMessage_Category_Mappings = [new CustomMessage_Category_Mappings()];
  CustomMessage_Item_Mappings = [new CustomMessage_Item_Mappings()];
  Concessionaire_ConcessionaireType_Mappings = [new Concessionaire_ConcessionaireType_Mappings()];
  CompanyMasters = [new CompanyMasters()]; 

  //EmployeeShiftRules= [ new EmployeeShiftRules()];
  //CustomMessages= [ new CustomMessage()];
  //CustomMessage_Item_Mappings= [ new CustomMessage_Item_Mapping()];
  //CustomMessage_Category_Mappings= [ new CustomMessage_Category_Mapping()];
  //CustomMessage_OrderType_Mappings= [ new CustomMessage_OrderType_Mapping()];
}
