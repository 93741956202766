import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-terms-conditions-pdf',
  templateUrl: './terms-conditions-pdf.component.html',
  styleUrls: ['./terms-conditions-pdf.component.css']
})
export class TermsConditionsPdfComponent implements OnInit {
  ref: any;
  termsAndConditionsText:string='';
  constructor(private language:LanguageService) { }
  value: string = '';
  ngOnInit(): void {
    this.termsAndConditionsText = this.language.getTextElement('lbl_terms_and_conditions');
  }
  /**
 * @author Om kanada
 * @param url // pass url
 * @description This method is used to open the modal
 * @returns void
 */
  public static open(url: string,modalService:NgbModal,sanitizer:DomSanitizer) {
    let ref = modalService.open(
      TermsConditionsPdfComponent,
      {
        backdrop: 'static', centered: true, size: 'lg', windowClass: 'terms-modal',
        modalDialogClass: 'terms-modal',
      }
    );
    // create url
    let apiUrl = environment.apiUrl;
    let src ='';
    if (apiUrl) {
      apiUrl = apiUrl.replace('/api/', '');
      src = apiUrl + url;
    }
    ref.componentInstance.ref = ref;
    ref.componentInstance.value = sanitizer.bypassSecurityTrustResourceUrl(src + '?page=hsn#toolbar=0');
  }

  dismiss() {
    this.ref.close();
  }

}
