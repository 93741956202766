import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.envName !== 'local') {
  Sentry.init({
    dsn: "https://a45dd724604519b17f045d11bf69076f@o86468.ingest.sentry.io/4505805911293952",
    integrations: [
      new Sentry.Integrations.LinkedErrors(),
      new Sentry.Integrations.GlobalHandlers(),
      new Sentry.Integrations.Breadcrumbs({ console: false }),
      new Sentry.Integrations.Dedupe()
    ],
    // Performance Monitoring
    environment: environment.environment,
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!ou're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
