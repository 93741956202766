import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.css']
})
export class ImagePopupComponent implements OnInit {

  image: string = "";
  ref!: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  open(image: string) {
    this.ref = this.modalService.open(ImagePopupComponent, {
      centered: true
    })
    this.ref.componentInstance.ref = this.ref;
    this.ref.componentInstance.image = image;
    return this.ref;
  }

  close() {
    this.ref.close();
  }

}
