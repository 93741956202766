<ng-container *ngIf="inputComboGroup && inputComboGroup.length > 0">

  <div (click)="checkComboGroupValid()" class="modifier-container">

    <ng-container *ngFor="let comboGroup of inputComboGroup;index as i;">
      <div id="{{ comboGroup.ComboGroupID }}" class="html-objects">
        <div *ngIf="comboGroup.isHidden!=true">
          <app-combogroup (SelectedItem)="checkComboGroupValid()" [comboGroupIndex]="i" [comboGroups]="comboGroup"
            (comboGroupChange)="comboGroupChange($event)" [ItemType]="ItemType" [IsCategory]="IsCategory" [parentQuantity]="parentQuantity">
          </app-combogroup>
        </div>
      </div>
    </ng-container>

  </div>

</ng-container>