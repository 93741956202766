import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GeneralSetting } from "../../../services/generalSetting.service";
import { ItemV2 } from "../../../models/item";
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-builder-mode-header',
  templateUrl: './builder-mode-header.component.html',
  styleUrls: ['./builder-mode-header.component.css']
})
export class BuilderModeHeaderComponent implements OnInit,OnDestroy {

  constructor(private language: LanguageService){}
  @Input() Item: ItemV2 = new ItemV2();

  StartAtStr: string = "";
  hideStartAt: any;
  priceStr: string = '';

  primaryColor: string | null = '';
  priceColor: string | null = "";

  savings = 0;

  itemPrice = 0;

  languageSub!: Subscription
  regularPriceText:string=''
  savingsText:string=''
  startsAtText:string=''
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText(){
    this.regularPriceText = this.language.getTextElement('txt_regular_price');
    this.savingsText = this.language.getTextElement('txt_savings');
    this.startsAtText = this.language.getTextElement('txt_starts_at');
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.StartAtStr = GeneralSetting.getCurrencySymbol();
    this.primaryColor = GeneralSetting.getPrimaryColor();
    this.priceColor = GeneralSetting.getPriceColor();

    this.hideStartAt = GeneralSetting.getHideStartsAtForVariablePrice();

    if (this.Item.Variations && this.Item.Variations.length > 0) {
      const firstVariation = this.Item.Variations[0];

      if (firstVariation && firstVariation.variationOptions.length > 0 && this.hideStartAt === "False") {
        firstVariation.IsSelected = true;

        this.StartAtStr = this.startsAtText;
        this.priceStr = this.StartAtStr;
      }
    }

    this.savings = (this.Item.OldPrice == undefined || isNaN(this.Item.OldPrice as any)) ? Number(this.Item.Price) : Number(this.Item.OldPrice) - Number(this.Item.Price);

    this.itemPrice = Number(this.Item.Price);

  }

}
