import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { loggingData, LogService } from 'src/app/services/log.service';
import { LoyaltyService } from '../../../services/loyalty.service';
import { UserService } from '../../../services/user.service';
import { LastfiveorderService } from 'src/app/services/lastfiveorder.service';
import { Subscription } from 'rxjs';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { CommonFunctions, Snackbar } from 'src/app/services/common';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { OLOTotalLoaderComponent } from '../olototal-loader/olototal-loader.component';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { DataService } from 'src/app/services/data.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoyaltyType } from 'src/app/models/loyaltyModal';
import { CustomerLoginModalService } from './customer-login-popup.service';
import { LoaderService } from 'src/app/services/loader.service';
import {
  OLOPaytronixCustomerAPIResponse,
  OLOPaytronixCustomerResponse,
  ReferrizerCustomerAPIResponse,
} from 'src/app/services/api/Models';
@Component({
  selector: 'app-customer-login-popup',
  templateUrl: './customer-login-popup.component.html',
  styleUrls: ['./customer-login-popup.component.css'],
})
export class CustomerLoginPopupComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    this.removeAllListeners();
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];
  form!: FormGroup;
  @ViewChild(OLOTotalLoaderComponent)
  oloItemTotalLoader!: OLOTotalLoaderComponent;

  @ViewChild('phoneNumberforlType13')
  phoneNumberforlType13Element!: ElementRef;

  @ViewChild('phoneNumberforlType2')
  phoneNumberforlType2Element!: ElementRef;

  @ViewChild('usernameforlType4')
  usernameforlType4Element!: ElementRef;


  allowPhoneLogin: boolean = true;

  //flag showing on the screen
  qr: string = '';
  customerName: string = '';
  customerPhone: string = '';
  customEmail: string = '';
  isShowName: boolean = false;
  isShowCreateAccount: boolean = false;
  isShowCreateAccountToolTip: boolean = false;
  isTickForAccount: boolean = false;
  isShowUniquePhoneError: boolean = false;

  isShown: boolean = false;

  levelUpShowPhone: boolean = false;
  listeners: EventListener[] = [];
  isShowFirstNamePunchh: boolean = false;
  isShowLastNamePunchh: boolean = false;
  isShowEmailPunchh: boolean = false;
  isShowPasswordPunchh: boolean = false;
  isShowForgotPasswordPunchh: boolean = false;
  showForgotPasswordClickedPunchh: boolean = false;
  isShowApiErrorText: boolean = false;

  isUKPhoneFormat: boolean = false;
  mask: string = '000-000-0000';

  isKiosk = this.isKioskService.isKiosk();

  punchhErrorObject = {
    displayError: '',
    externalNotFound: false,
    internalNotFound: false,
    isShowPhoneErrorPunchh: false,
    isShowFirstNameErrorPunchh: false,
    isShowLastNameErrorPunchh: false,
    isShowEmailErrorPunchh: false,
    isShowPasswordErrorPunchh: false,
  };

  levelUpErrorObject = {
    displayError: '',
    externalNotFound: false,
    internalNotFound: false,
    isShowPhoneErrorPunchh: false,
    isShowEmailErrorPunchh: false,
    isShowPasswordErrorPunchh: false,
  };

  customerFirstNamePunchh: string = '';
  customerLastNamePunchh: string = '';
  customerEmailPunchh: string = '';
  customerPasswordPunchh: string = '';
  customerPhonePunchh: string = '';
  phoneErrorMessage: string = '';
  passwordErrorPunchh: string = '';

  paytronixObject = {
    password: '',
    username: '',
    passwordError: '',
    passwordErrorShown: false,
    usernameError: '',
    usernameErrorShown: false,
    generalError: '',
    generalErrorShown: false,
  };

  signinText: string = '';
  apiErrorText: string = '';
  modalReference: any;
  isInvalid: boolean = false;
  directToMenu: boolean = false;
  company: string = GeneralSetting.getBranchName();
  showCompany: boolean = false;
  loyaltyType: number = 0;
  titleText = '';
  middleImageSRC = '../../../../assets/images/customerLoginLoyalty.png';
  description = '';

  altDescriptionOne = '';
  altDescriptionTwo = '';

  isShowAfterAcountCreated = false;
  cssHeight: string = '';
  phoneLength: number = 10;

  altSignInWording: string = '';
  defaultAltSignInWording: string = '';

  title: string = '';
  phoneNumberText: string = '';
  enterEmailText: string = '';
  emailText: string = '';
  passwordText: string = '';
  nameText: string = '';
  validPhoneText: string = '';
  accountNotFoundText: string = '';
  createAccountText: string = '';
  orText: string = '';
  scanQRText: string = '';
  enterPhoneText: string = '';
  enterFirstNameText: string = '';
  firstNameText: string = '';
  enterLastNameText: string = '';
  lastNameText: string = '';
  forgotPasswordText: string = '';
  continueAsGuestText: string = '';
  phoneUniqueText: string = '';
  signInBelowText: string = '';
  userNameText: string = '';
  punchhTitle: string = '';

  constructor(
    private readonly isKioskService: IsKioskService,
    public modalService: NgbModal,
    private loyaltyService: LoyaltyService,
    private userService: UserService,
    private router: Router,
    private logger: LogService,
    private lastfiveorderservice: LastfiveorderService,
    private KeyboardService: KeyboardService,
    private data: DataService,
    private language: LanguageService,
    private customerLoginService:CustomerLoginModalService,
    private loader: LoaderService
  ) {
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }

  loadText() {
    this.title = this.language
      .getTextElement('title_loyalty_program')
      .toLocaleUpperCase();
    this.punchhTitle = this.language
      .getTextElement('join_loyalty_program')
      .toLocaleUpperCase();
    this.description = this.language.getTextElement(
      'msg_get_discounts_and_offers'
    );
    this.altDescriptionOne = this.language.getTextElement(
      'sign_in_or_scan_app'
    );
    this.altDescriptionTwo = this.language.getTextElement('scan_app');
    this.signinText = this.language.getTextElement('lbl_continue');
    this.titleText = this.language.getTextElement('title_earn_rewards');
    this.phoneNumberText = this.language.getTextElement('hint_phone_number');
    this.enterEmailText = this.language.getTextElement(
      'txt_please_enter_email_address'
    );
    this.emailText = this.language.getTextElement('email');
    this.passwordText = this.language.getTextElement('password');
    this.nameText = this.language.getTextElement('hint_name');
    this.validPhoneText = this.language.getTextElement('msg_valid_phone_no');
    this.accountNotFoundText = this.language.getTextElement(
      'msg_loyalt_account_not_fount'
    );
    this.createAccountText = this.language.getTextElement(
      'action_create_account'
    );
    this.scanQRText = this.language.getTextElement('scan_qr_code');
    this.enterPhoneText = this.language.getTextElement('enter_phone_number');
    this.enterFirstNameText = this.language.getTextElement(
      'please_enter_first_name'
    );
    this.firstNameText = this.language.getTextElement('first_name');
    this.enterLastNameText = this.language.getTextElement(
      'please_enter_last_name'
    );
    this.lastNameText = this.language.getTextElement('last_name');
    this.forgotPasswordText = this.language.getTextElement('forgot_password');
    this.orText = this.language.getTextElement('txt_or');
    this.continueAsGuestText = this.language
      .getTextElement('continue_as_guest')
      .toLocaleUpperCase();
    this.phoneUniqueText = this.language.getTextElement(
      'property_phone_should_be_unique'
    );
    this.signInBelowText = this.language.getTextElement('sign_in_below');
    this.userNameText = this.language.getTextElement('username');
    this.passwordErrorPunchh = this.language.getTextElement(
      'please_enter_password'
    );
    this.defaultAltSignInWording = this.language
      .getTextElement('enter_phone_number_below')
      .toLocaleUpperCase();
  }
  /**
   *@description Toggles input to display value as text / password
   */
  revealPassword() {
    this.isShown = !this.isShown;
  }
  ngOnInit(): void {
    this.loyaltyType = this.loyaltyService.loyaltyType;
    
    this.allowPhoneLogin = GeneralSetting.getLoyaltyLoginWithPhone() != 'False';
    this.altSignInWording = this.allowPhoneLogin
      ? this.defaultAltSignInWording
      : '';

    if (this.loyaltyService.loyaltyType == LoyaltyType.OLOPunchh) {
      this.title = this.punchhTitle;
      this.middleImageSRC = 'assets/images/samsung-kiosk-scan.gif';
      if (!this.allowPhoneLogin) {
        this.isShowEmailPunchh = true;
        this.isShowPasswordPunchh = true;
      }
    } else if (this.loyaltyService.loyaltyType == LoyaltyType.LevelUp) {
      this.middleImageSRC = 'assets/images/samsung-kiosk-scan.gif';
      this.description = this.allowPhoneLogin
        ? this.altDescriptionOne
        : this.altDescriptionTwo;
    }
    this.isUKPhoneFormat = GeneralSetting.getCountry() == '230';
    this.phoneLength = this.isUKPhoneFormat ? 11 : 10;
    this.mask = this.isUKPhoneFormat ? '00000-000000' : '000-000-0000';
    this.showCompany = this.loyaltyService.loyaltyType == LoyaltyType.LevelUp;
  }

  ngAfterViewInit() {
    if (this.customerLoginService.keyboardStatus.getValue()) {
      this.KeyboardPopup();
    }
  }
  // add click event on first input field @jay
  public KeyboardPopup() {
    if (this.loyaltyType == 1 || this.loyaltyType == 3) {
      if (this.allowPhoneLogin) {
        let phoneNumberforlType13Ele: HTMLElement = this.phoneNumberforlType13Element.nativeElement as HTMLElement;
        phoneNumberforlType13Ele.click();
      }
    } else if (this.loyaltyType == 2) {
      let phoneNumberforlType2Ele: HTMLElement = this.phoneNumberforlType2Element.nativeElement as HTMLElement;
      phoneNumberforlType2Ele.click();
    } else if (this.loyaltyType == 4) {
      let usernameforlType4Ele: HTMLElement = this.usernameforlType4Element.nativeElement as HTMLElement;
      usernameforlType4Ele.click();
    }
    this.customerLoginService.keyboardStatus.next(false);
  }

  /**
   * @description activates kiosk scanner and sets showQR flag (used to track scanner status programmatically) to true
   */
  startScanner() {
    this.showQR = true;
    var messageType = 'startScanner';
    var message = {
      messageType: messageType,
    };
    window.top!.postMessage(message, '*');
  }
  /**
   * @description deactivates kiosk scanner and sets showQR flag (used to track scanner status programmatically) to false
   */
  stopScanner() {
    this.showQR = false;
    var messageType = 'stopScanner';
    var message = {
      messageType: messageType,
    };
    window.top!.postMessage(message, '*');
  }
  /**
   * @description function to be called after every use of public static open method. All functions that should be performed upon opening the modal belong here
   */
  handleOpenSideEffects() {
    this.handleScannerActivationUponOpen()
  }
  /**
   * @description After calling the static open method, be sure to call the handleOpenSideEffects method on the returned reference.componentInstance
   * @param modalService 
   * @returns NgbModalRef
   */
  public static open(modalService: NgbModal) {
    let modalReference = modalService.open(CustomerLoginPopupComponent, {
      backdrop: 'static',
      size: 'lg',
      modalDialogClass: 'cusLoginModalContent',
      windowClass: 'no-scroll',
      centered: true,
    });
    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }
  /**
   * @description activates the scanner according to loyalty type and sets event listener. Should not be called directly
   */
  handleScannerActivationUponOpen() {
    if (
      this.loyaltyService.loyaltyType == LoyaltyType.LevelUp ||
      this.loyaltyService.loyaltyType == LoyaltyType.OLOPunchh
    ) {
      //commented below lines pending completion of keyboard task -- see HTMl for edited input
      // this.KeyboardService.manual=true
      // this.KeyboardService.open('numeric')
      // this.KeyboardService.stringSub.next()
      // this.KeyboardService.stringSub.subscribe((val)=>{
      //   this.handlePhonePunchhChange(val)
      // })
      this.startScanner();
      this.getLoyaltyCustomerByScan();
    }
  }
  open() {
    var modalRef = this.modalService.open(CustomerLoginPopupComponent, {
      backdrop: 'static',
      size: 'lg',
      modalDialogClass: 'cusLoginModalContent',
      windowClass: 'no-scroll',
      centered: true,
    });
    modalRef.componentInstance.modalReference = modalRef;
    this.modalReference = modalRef;
    // if (window.location.href.includes('touch')) {
    //   this.timeout.startTimer();
    // }
    if (
      this.loyaltyService.loyaltyType == LoyaltyType.LevelUp ||
      this.loyaltyService.loyaltyType == LoyaltyType.OLOPunchh
    ) {
      //commented below lines pending completion of keyboard task -- see HTMl for edited input
      // this.KeyboardService.manual=true
      // this.KeyboardService.open('numeric')
      // this.KeyboardService.stringSub.next()
      // this.KeyboardService.stringSub.subscribe((val)=>{
      //   this.handlePhonePunchhChange(val)
      // })
      this.startScanner();
      this.getLoyaltyCustomerByScan();
    }
  }
  customerPhoneDisplayPunchh: string = '';
  usMask(val: string) {
    let newVal = '';
    if (val.length) {
      if (val.length > 10) {
        val = val.slice(0, 10);
      }
      if (val.length <= 3) {
        newVal = val;
      } else if (val.length <= 6) {
        newVal = val.slice(0, 3) + '-' + val.slice(3);
      } else {
        newVal = val.slice(0, 3) + '-' + val.slice(3, 6) + '-' + val.slice(6);
      }
    } else {
    }
    return newVal;
  }
  ukMask(val: string) {
    let newVal = '';
    if (val.length) {
      if (val.length > 10) {
        val = val.slice(0, 10);
      }
      if (val.length <= 5) {
        newVal = val;
      } else {
        newVal = val.slice(0, 5) + '-' + val.slice(5);
      }
    } else {
    }
    return newVal;
  }
  applyMask(val: string) {
    if (this.isUKPhoneFormat) {
      return this.ukMask(val);
    } else {
      return this.usMask(val);
    }
  }
  handlePhonePunchhChange(val: string) {
    if (val.length <= this.phoneLength) {
      if (val != undefined) {
        this.modalReference.componentInstance.customerPhonePunchh = val;
        this.modalReference.componentInstance.customerPhoneDisplayPunchh =
          this.applyMask(val);
      }
      this.onPhoneChangePunchh();
    }
  }
  removeAllListeners() {
    if (this.listeners.length) {
      this.listeners.forEach((x: EventListener) => {
        window.removeEventListener('message', x);
      });
    }
    this.listeners = [];
  }
  close(msg: any = 'canceled') {
    if (
      this.loyaltyService.loyaltyType == LoyaltyType.LevelUp ||
      this.loyaltyService.loyaltyType == LoyaltyType.OLOPunchh
    ) {
      this.removeAllListeners();
      this.stopScanner();
    }
    this.KeyboardService.KeyboardStatus = false;
    this.modalReference.close(msg);
  }
  activeCustomerRouter: boolean = false;
  getLoyaltyCustomerRouter() {
    if (this.activeCustomerRouter) {
      return;
    }
    this.activeCustomerRouter = true;
    this.stopScanner();
    if (this.loyaltyService.loyaltyType == LoyaltyType.LevelUp) {
      this.getLoyaltyCustomerByPhone();
    } else if (this.loyaltyService.loyaltyType == LoyaltyType.OLOPunchh) {
      if (
        this.isShowFirstNamePunchh &&
        this.isShowLastNamePunchh &&
        this.isShowEmailPunchh &&
        this.isShowPasswordPunchh
      ) {
        //SignUp User
        this.doPunchhSignUp();
      } else {
        if (
          !this.checkPhone(
            this.customerPhonePunchh,
            this.customerEmailPunchh,
            this.customerPasswordPunchh
          ) &&
          this.qr == ''
        ) {
          this.phoneErrorMessage = this.validPhoneText;

          this.flashErrorMessage(
            this.punchhErrorObject,
            'isShowPhoneErrorPunchh'
          );
          this.activeCustomerRouter = false;
          return;
        } else {
          this.punchhErrorObject.isShowPhoneErrorPunchh = false;
        }
        let str = this.customerPhonePunchh;
        if (this.qr != '') {
          str = this.qr;
        }
        this.startCall();
        this.loyaltyService
          .getLoyaltyCustomerByPhone(
            str,
            this.customerEmailPunchh,
            this.customerPasswordPunchh,
            this.qr != ''
          )!
          .then(
            (res: any) => {
              console.log(res)
              let data = res.data;
              let errArr = res.errArr;
              this.endCall();
              if (data.status == 'success' && data.statusCode == '200') {
                this.oloPunchhLoginHandler(data);
              }
              if (errArr.length) {
                var log = new loggingData(
                  'Loyalty Login',
                  'Retry Experienced Fetching Loyalty Account',
                  'Loyalty Retry',
                  `Retry Experienced on Fetching Account With Phone Number ${this.customerPhonePunchh
                  }: ${JSON.stringify(errArr)}`,
                  true
                );
                this.logger.sendLogToServer(log);
              }
            },
            (error: any[]) => {
              console.log(error)
              this.endCall();
              var log = new loggingData(
                'Loyalty Login',
                'Error Fetching Loyalty Account',
                'Loyalty Error',
                `Encountered Error on Fetching Account With Phone Number ${this.customerPhonePunchh}: ${error}`,
                true
              );
              this.logger.sendLogToServer(log, error.length == 5);
              if (error[error.length - 1].res && error[error.length - 1].res.error && error[error.length - 1].res.error.message.toLocaleLowerCase().includes('exist')) {
                this.isShowEmailPunchh = true;
                this.isShowPasswordPunchh = true;
                this.isShowForgotPasswordPunchh = true;
                if (this.customerPhonePunchh.length != this.phoneLength && this.qr == '') {
                  this.punchhErrorObject.isShowPhoneErrorPunchh = true;
                  this.phoneErrorMessage = this.validPhoneText;
                } else {

                  this.punchhErrorObject.internalNotFound = true;
                  if (this.qr != '') {
                this.startScanner();
                    this.getLoyaltyCustomerByScan();
                    let error = this.language.getTextElement(
                      'phone_account_not_found'
                    );
                    Snackbar.show(error, 2500);
                  } else if (
                    this.customerEmailPunchh.length == 0 ||
                    this.customerPasswordPunchh.length == 0
                  ) {
                let error = this.language.getTextElement(
                      'user_pass_not_found'
                    );
                    Snackbar.show(error, 2500);
                  } else {
                this.onSignUpPunchhClick();
                    let error = this.language.getTextElement(
                      'email_pass_not_found'
                    );
                    Snackbar.show(error, 2500);
                  }
                  this.qr = '';
                  let error = this.language.getTextElement(
                    'user_pass_not_found'
                  );
                  this.punchhErrorObject.displayError = error;
                }
              } else if (
                error[error.length - 1].res && error[error.length - 1].res.error && error[error.length - 1].res.error.message ==
                `Incorrect information submitted. Please retry.`
                ) {
                let error = this.language.getTextElement(
                  'email_pass_not_found'
                );
                this.punchhErrorObject.displayError = error;
                this.onSignUpPunchhClick();
              } else {
                let error = this.language.getTextElement(
                  'error_try_again_later'
                );
                Snackbar.show(error, 2500);
                //handle other errors
              }
            }
          )
          .finally(() => {
            this.activeCustomerRouter = false;
          });
      }
    } else if (this.loyaltyService.loyaltyType == LoyaltyType.OLOPaytronix) {
      this.getOLOPaytronixLoyaltyCustomerByPhone();
    }
  }
  onEmailChangePunchh() {
    if (this.punchhErrorObject.internalNotFound) {
      this.punchhErrorObject.displayError = ''
    }
  }
  onPasswordChangePunchh() {
    if (this.punchhErrorObject.internalNotFound) {
      this.punchhErrorObject.displayError = ''
    }
  }
  getOLOPaytronixLoyaltyCustomerByPhone() {
    if (!this.paytronixObject.password.length) {
      let error = this.language.getTextElement('pass_required');
      this.paytronixObject.passwordError = error;
      this.flashErrorMessage(this.paytronixObject, 'passwordErrorShown');
      this.activeCustomerRouter = false;
      return;
    }
    if (!this.paytronixObject.username.length) {
      let error = this.language.getTextElement('user_required');

      this.paytronixObject.usernameError = error;
      this.flashErrorMessage(this.paytronixObject, 'usernameErrorShown');
      this.activeCustomerRouter = false;
      return;
    }
    this.startCall();
    this.loyaltyService
      .getLoyaltyCustomerByPhone(
        '',
        this.paytronixObject.username,
        this.paytronixObject.password
      )!
      .then(
        (res: OLOPaytronixCustomerResponse) => {
          this.endCall();
          let data = res.data;
          let errArr = res.errArr;
          this.activeCustomerRouter = false;
          if (data && data.status == 'success' && data.statusCode == '200') {
            //success!
            this.oloPaytronixLoginHandler(data.data);
          } else if (data && data.status == 'error' && data.statusCode == '0') {
            //message received = `Authentication failed`
            //first error state, cause unknown. Update at future date
          } else if (
            data &&
            data.status == 'error' &&
            data.statusCode == '200'
          ) {
            //message recieved `noMatchingGuests`
            let error = this.language.getTextElement('user_not_found');
            Snackbar.show(error, 2500);
            //second error state, user not found. Update at future date
          } else if (
            data &&
            data.status == 'error' &&
            data.statusCode == '404'
          ) {
            //message received = `Authentication failed`
            //third error state, cause unknown. Update at future date
          } else {
            //unknown error
          }
          if (errArr.length) {
            var log = new loggingData(
              'Loyalty Login',
              'Retry Experienced Fetching Loyalty Account',
              'Loyalty Retry',
              `Retry Experienced on Fetching Account With Phone Number ${this.customerPhone
              }: ${JSON.stringify(errArr)}`,
              true
            );
            this.logger.sendLogToServer(log);
          }
        },
        (error: any[]) => {
          this.endCall();
          this.paytronixObject.generalError = this.language.getTextElement(
            'loyalty_login_network_issue'
          );
          this.flashErrorMessage(this.paytronixObject, 'generalErrorShown');
          var log = new loggingData(
            'Loyalty Login',
            'Error Fetching Loyalty Account',
            'Loyalty Error',
            `Encountered Error on Fetching Account With Phone Number ${this.customerPhone
            }: ${JSON.stringify(error)}`,
            true
          );
          this.logger.sendLogToServer(log, error.length == 5);
          this.activeCustomerRouter = false;
        }
      );
  }
  oloPaytronixLoginHandler(data: OLOPaytronixCustomerAPIResponse) {
    this.customerDataSave(data);
  }
  startCall() {
    this.loader.openLoader();
    this.isShowApiErrorText = true;
  }
  endCall() {
    this.loader.closeLoader();
    this.isShowApiErrorText = false;
  }
  levelUpChoosePhone() {
    this.stopScanner();
    this.levelUpShowPhone = true;
  }

  getLoyaltyCustomerByPhone() {
    this.KeyboardService.KeyboardStatus = false;
    if (!this.isTickForAccount) {
      let str = this.customerPhone;
      if (
        this.loyaltyService.loyaltyType == LoyaltyType.LevelUp ||
        this.loyaltyService.loyaltyType == LoyaltyType.OLOPunchh
      ) {
        str = this.customerPhonePunchh;
      }
      if (this.qr != '') {
        str = this.qr;
      }

      if (this.qr == '' && !CommonFunctions.checkPhone(str)) {
        Snackbar.show(this.validPhoneText, 2500);
        return;
      }

      this.startCall();
      this.loyaltyService
        .getLoyaltyCustomerByPhone(str, '', '', this.qr != '')!
        .then(
          (res: any) => {
            console.log(res)

            let data = res.data;
            let errArr = res.errArr;
            this.activeCustomerRouter = false;
            this.endCall();
            if (this.loyaltyService.loyaltyType == LoyaltyType.OLOPunchh) {
              if (data.status == 'error' && data.statusCode == '404') {
                let error = this.language.getTextElement(
                  'third_party_login_error'
                );
                this.apiErrorText = error;
                this.isShowApiErrorText = true;
              }
            } else if (this.loyaltyService.loyaltyType == LoyaltyType.LevelUp) {
              this.customerPhone = this.customerPhonePunchh;
              try {
                this.oloItemTotalLoader.clear();
              } catch (e) {
              } finally {
                if (data.status == 'success' && data.statusCode == '200') {
                  try {
                    this.oloItemTotalLoader.clear();
                  } catch (e) { }
                  this.customerDataSave(data);
                  this.close('welcome');
                  // this.lastfiveorderservice.clearhistoryData();
                  // var id = GeneralSetting.getCustomerId();
                  // let customerID = id == '' ? '-1' : id;
                  // if (
                  //   customerID == '-1' &&
                  //   this.userService.punchhLoyaltyCustomer &&
                  //   this.userService.punchhLoyaltyCustomer.Phone
                  // ) {
                  //   customerID = this.userService.punchhLoyaltyCustomer.Phone;
                  // }
                  // this.loadingModal.open()
                  // this.data
                  //   .getOrderHistoryByCustomerID(customerID)
                  //   .toPromise()
                  //   .then((data: any) => {
                  //   this.lastfiveorderservice.OrderResponse=data;
                  //     if (this.router.url.indexOf('orderReview') == -1&&this.lastfiveorderservice.OrderResponse.OrderItemMasters.length) {
                  //       this.router.navigateByUrl('/lastfiveorder');
                  //     }
                  //     this.close('');
                  //   }).finally(()=>{
                  //     this.loadingModal.close()
                  //   });
                } else if (data.status == 'error' && data.statusCode == '400') {
                  this.levelUpErrorObject.internalNotFound = true;
                  let error = this.language.getTextElement('account_not_found');
                  this.levelUpErrorObject.displayError = error;
                  if (this.qr != '') {
                    this.qr = '';
                    this.startScanner();
                    this.getLoyaltyCustomerByScan();
                    Snackbar.show(error, 2500);
                  }
                  //LevelUp user not found
                } else {
                  //handle other responses
                }
              }
            } else if (
              this.loyaltyService.loyaltyType == LoyaltyType.Referrizer
            ) {
              if (data.status == 'success') {
                this.customerDataSave(
                  data.data as ReferrizerCustomerAPIResponse
                );
                this.lastfiveorderservice.clearhistoryData();
                var id = GeneralSetting.getCustomerId();
                let customerID = id == '' ? '-1' : id;
                if (
                  customerID == '-1' &&
                  this.userService.punchhLoyaltyCustomer &&
                  this.userService.punchhLoyaltyCustomer.Phone
                ) {
                  customerID = this.userService.punchhLoyaltyCustomer.Phone;
                }
                this.startCall();
                this.data
                  .getOrderHistoryByCustomerID(customerID)
                  .toPromise()
                  .then((data: any) => {
                    this.lastfiveorderservice.OrderResponse = data;
                    if (
                      this.router.url.indexOf('orderReview') == -1 &&
                      this.lastfiveorderservice.OrderResponse.OrderItemMasters
                        .length
                    ) {
                      this.router.navigateByUrl('/lastfiveorder');
                    }
                    this.close('');
                  })
                  .finally(() => {
                    this.endCall();
                  });
              } else {
                this.isShowCreateAccount = true;
                this.isShowCreateAccountToolTip = true;
              }
            }
            if (errArr.length) {
              var log = new loggingData(
                'Loyalty Login',
                'Retry Experienced Fetching Loyalty Account',
                'Loyalty Retry',
                `Retry Experienced on Fetching Account With Phone Number ${this.customerPhone
                }: ${JSON.stringify(errArr)}`,
                true
              );
              this.logger.sendLogToServer(log);
            }
          },
          (error: any[]) => {
            console.log(error)

            this.activeCustomerRouter = false;
            this.endCall();
            var log = new loggingData(
              'Loyalty Login',
              'Error Fetching Loyalty Account',
              'Loyalty Error',
              `Encountered Error on Fetching Account With Phone Number ${this.customerPhone
              }: ${JSON.stringify(error)}`,
              true
            );
            // console.log(error);
            this.logger.sendLogToServer(log, error.length == 5);
            this.close('error');
            this.isShowCreateAccount = true;
            this.isShowCreateAccountToolTip = true;
          }
        )
        .finally(() => {
          this.activeCustomerRouter = false;
        });
    } else if (this.loyaltyService.loyaltyType != LoyaltyType.LevelUp) {
      this.createAccount();
    }
  }

  customerDataSave(data: any) {
    if (this.loyaltyService.loyaltyType == LoyaltyType.Referrizer) {
      this.referrizerDataSave(data as ReferrizerCustomerAPIResponse);
    } else if (this.loyaltyService.loyaltyType == LoyaltyType.OLOPunchh) {
      this.oloPunchhDataSave(data);
      this.close('welcome');
    } else if (this.loyaltyService.loyaltyType == LoyaltyType.LevelUp) {
      this.levelUpDataSave(data);
      this.close('welcome');
    } else if (this.loyaltyService.loyaltyType == LoyaltyType.OLOPaytronix) {
      this.oloPaytronixDataSave(data as OLOPaytronixCustomerAPIResponse);
      this.close('welcome');
    }
  }
  private oloPaytronixDataSave(data: OLOPaytronixCustomerAPIResponse) {
    this.userService.isUserLoggedIn = true;
    this.userService.paytronixCustomer = data;
    this.userService.name = this.userService.paytronixCustomer.firstname ?? '';
    this.userService.userSubject.next(true);
  }
  private levelUpDataSave(data: any) {
    if (this.qr) {
      this.loyaltyService.levelUpObject.qr = this.qr;
      this.loyaltyService.levelUpObject.originalQr = this.qr
    } else {
      this.loyaltyService.levelUpObject.originalQr = this.customerPhone

    }
    this.userService.levelUpCustomer = data.data.customer;
    this.loyaltyService.levelUpObject.visits = data.data.customer.orders_count;
    this.userService.name = data.data.customer.name;
    GeneralSetting.setCustomerId(this.userService.levelUpCustomer.CustomerID);
    GeneralSetting.setCustomerName(this.userService.levelUpCustomer.name);
    GeneralSetting.setCustomerLoginName(this.userService.levelUpCustomer.name);
    GeneralSetting.setCustomerName(this.userService.levelUpCustomer.name);
    GeneralSetting.setCustomerLoginMobile(this.customerPhone);
    this.userService.isUserLoggedIn = true;
    this.userService.userSubject.next(true);
  }

  private oloPunchhDataSave(data: any) {
    this.userService.punchhLoyaltyCustomer = data.data;
    GeneralSetting.setCustomerId(
      this.userService.punchhLoyaltyCustomer.MembershipID
    );
    GeneralSetting.setCustomerLoginMobile(
      this.userService.punchhLoyaltyCustomer.Phone
    );
    GeneralSetting.setCustomerName(
      this.userService.punchhLoyaltyCustomer.Firstname
    );

    GeneralSetting.setCustomerLoginName(
      this.userService.punchhLoyaltyCustomer.Firstname
    );
    GeneralSetting.setCustomerName(
      this.userService.punchhLoyaltyCustomer.Firstname
    );

    GeneralSetting.setCustomerLoginEmail(this.customerEmailPunchh);
    this.userService.name = this.userService.punchhLoyaltyCustomer.Firstname;
    this.userService.isUserLoggedIn = true;
    this.userService.userSubject.next(true);
  }

  private referrizerDataSave(data: ReferrizerCustomerAPIResponse) {
    console.log("data of customer login : ", data);
    this.loyaltyService.setLoyaltyPoints.next(data.loyaltyPointsCount);
    this.userService.referrizerLoyaltyUser = data;
    GeneralSetting.setCustomerId(
      this.userService.referrizerLoyaltyUser.customerID
    );
    GeneralSetting.setCustomerLoginMobile(
      this.userService.referrizerLoyaltyUser.phone
    );
    GeneralSetting.setCustomerName(
      this.userService.referrizerLoyaltyUser.firstName
    );

    GeneralSetting.setCustomerLoginName(
      this.userService.referrizerLoyaltyUser.firstName
    );
    GeneralSetting.setCustomerName(
      this.userService.referrizerLoyaltyUser.firstName
    );
    GeneralSetting.setCustomerLoginEmail(
      this.userService.referrizerLoyaltyUser.email
    );
    this.userService.name = this.userService.referrizerLoyaltyUser.firstName;
    this.userService.isUserLoggedIn = true;
    this.userService.userSubject.next(true);
  }

  private emailValidator(email: string): boolean {
    var EMAIL_REGEXP =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!EMAIL_REGEXP.test(email)) {
      return false;
    }
    return true;
  }

  flashErrorMessage(variable: any, key: string) {
    variable[key] = true;
    setTimeout(() => {
      variable[key] = false;
    }, 5000);
  }

  doPunchhSignUp() {
    this.startCall();

    this.allSubsCription.push(
      this.loyaltyService
        .createLoyaltyCustomer(
          this.customerFirstNamePunchh,
          this.customerLastNamePunchh,
          this.customerPhonePunchh,
          this.customerEmailPunchh,
          this.customerPasswordPunchh
        )!
        .subscribe(
          (data: any) => {
            this.activeCustomerRouter = false;
            this.endCall();
            if (data.data.authtoken != null && data.data.authtoken != '') {
              this.userService.punchhLoyaltyCustomer = data.data;
              this.userService.name =
                this.userService.punchhLoyaltyCustomer.Firstname;
              GeneralSetting.setCustomerId(data.customerID);
              GeneralSetting.setCustomerLoginMobile(
                this.userService.punchhLoyaltyCustomer.Phone
              );
              GeneralSetting.setCustomerLoginName(
                this.userService.punchhLoyaltyCustomer.Firstname
              );
              GeneralSetting.setCustomerLoginEmail(this.customerEmailPunchh);
              this.userService.isUserLoggedIn = true;
              this.userService.userSubject.next(true);
              //this.loyaltyService
              //Change title for success and then close popup
              this.title = this.language.getTextElement(
                'title_account_created'
              );
              this.punchhErrorObject.displayError = '';
              this.description = this.language.getTextElement(
                'msg_account_created'
              );
              this.isShowAfterAcountCreated = true;
              this.middleImageSRC =
                '../../../../assets/images/customerLoginLoyalty.png';
              setTimeout(() => {
                this.close('');
              }, 2000);
            } else {
              this.isShowUniquePhoneError = true;
            }
          },
          (error: any) => {
            this.activeCustomerRouter = false;
            this.endCall();
            var log = new loggingData(
              'Loyalty Login',
              'Error Creating Loyalty Account',
              'Loyalty Error',
              `Encountered Error on Creating Account With Phone Number ${this.customerPhonePunchh}: ${error}`,
              true
            );
            this.logger.sendLogToServer(log);
            if (
              error.error.statusCode == '400' &&
              error.error.message.includes('Password must be at')
            ) {
              Snackbar.show(error.error.message, 5000);
            } else if (
              error.error.statusCode == '400' &&
              error.error.message.includes(
                'Incorrect information submitted. Please retry.'
              )
            ) {
              Snackbar.show(error.error.message, 5000);
            } else if (error.error.statusCode == '500') {
              this.isShowApiErrorText = true;
              let error = this.language.getTextElement(
                'loyalty_login_network_issue'
              );
              this.apiErrorText = error;
            }
          }
        )
    );
  }
  showQR: boolean = true;
  onPhoneChangePunchh() {
    if (this.customerPhonePunchh.length) {
      if (this.showQR) {
        this.stopScanner();
      }
      this.showQR = false;
    } else {
      this.startScanner();
      this.showQR = true;
    }
    this.punchhErrorObject.isShowPhoneErrorPunchh = false;
    this.isShowApiErrorText = false;
    this.isShowFirstNamePunchh = false;
    this.customerFirstNamePunchh = '';
    this.isShowLastNamePunchh = false;
    this.customerLastNamePunchh = '';
    this.isShowEmailPunchh = false;
    this.customerEmailPunchh = '';
    this.isShowPasswordPunchh = false;
    this.customerPasswordPunchh = '';
    this.isShowForgotPasswordPunchh = false;
    this.showForgotPasswordClickedPunchh = false;
    this.punchhErrorObject.internalNotFound = false;
    this.levelUpErrorObject.internalNotFound = false;
  }
  punchhForgotPassword() {
    CommonFunctions.disableButton('punchhForgotPassword');
    this.showForgotPasswordClickedPunchh = true;
    if (this.customerEmailPunchh.length != 0) {
      this.loyaltyService
        .punchhForgotPassword(this.customerEmailPunchh)
        .then((data: any) => {
          if (data) {
            let error = this.language.getTextElement(
              'password_reset_link_sent'
            );
            Snackbar.show(error, 5000);
          } else {
          }
        });
    } else {
      let error = this.language.getTextElement(
        'email_needed_to_reset_passowrd'
      );
      Snackbar.show(error, 5000);
    }
  }
  onSignUpPunchhClick() {
    if (!this.isShowFirstNamePunchh) {
      this.isShowFirstNamePunchh = true;
      this.isShowLastNamePunchh = true;
      this.isShowEmailPunchh = true;
      this.isShowPasswordPunchh = true;
      this.signinText = this.createAccountText.toLocaleUpperCase();
      return;
    }
  }
  createAccount() {
    if (!this.isShowName) {
      this.isShowName = true;
      this.isShowCreateAccountToolTip = false;
      this.isShowCreateAccount = false;
      this.isTickForAccount = true;
      this.activeCustomerRouter = false;
      return;
    }

    if (this.customerName == '') {
      this.activeCustomerRouter = false;
      return;
    }
    if (this.customerPhone == '') {
      this.activeCustomerRouter = false;
      return;
    }
    if (
      !this.checkPhone(
        this.customerPhone,
        this.loyaltyType == LoyaltyType.Referrizer
          ? this.customerName
          : this.customerEmailPunchh,
        this.loyaltyType == LoyaltyType.Referrizer
          ? 'text'
          : this.customerPasswordPunchh
      )
    ) {
      this.activeCustomerRouter = false;
      return;
    }
    if (this.customerPhone.length < this.phoneLength) {
      this.activeCustomerRouter = false;
      return;
    }
    this.startCall();
    this.allSubsCription.push(
      this.loyaltyService
        .createLoyaltyCustomer(this.customerName, this.customerPhone)!
        .subscribe(
          (res: any) => {
            let data = res.data;
            if (data.customerID != null && data.customerID != '') {
              this.userService.referrizerLoyaltyUser = data;
              this.userService.name =
                this.userService.referrizerLoyaltyUser.firstName;
              GeneralSetting.setCustomerId(data.customerID);
              GeneralSetting.setCustomerLoginMobile(
                this.userService.referrizerLoyaltyUser.phone
              );
              GeneralSetting.setCustomerLoginName(
                this.userService.referrizerLoyaltyUser.firstName
              );
              this.userService.isUserLoggedIn = true;
              this.userService.userSubject.next(true);
              //this.loyaltyService
              //Change title for success and then close popup
              this.title = this.language
                .getTextElement('title_account_created')
                .toLocaleUpperCase();
              this.description = this.language.getTextElement(
                'msg_account_created'
              );
              this.isShowAfterAcountCreated = true;
              setTimeout(() => {
                this.close('');
              }, 2000);
            } else {
              this.isShowUniquePhoneError = true;
            }
          },
          (error) => {
            var log = new loggingData(
              'Loyalty Login',
              'Error Creating Loyalty Account',
              'Loyalty Error',
              `Encountered Error on Creating Account With Phone Number ${this.customerPhone}: ${error}`,
              true
            );
            this.logger.sendLogToServer(log);
            this.isShowUniquePhoneError = true;
          },
          () => {
            this.activeCustomerRouter = false;
            this.endCall();
          }
        )
    );
  }
  //get data from database: OrderTypeMappingMasters
  //get the order type info

  checkPhone(phone: any, email: any, password: any) {
    if (this.allowPhoneLogin) {
      let regPhone = /^[1-9]\d*|0\d{11}(?!0+$)/;
      if (!regPhone.test(phone)) {
        return false;
      } else {
        return true;
      }
    } else {
      if (!(email.length && password.length)) {
        return false;
      } else {
        return true;
      }
    }
  }

  onPhoneChange() {
    this.isShowUniquePhoneError = false;
  }

  oloPunchhLoginHandler(data: any) {
    if (data.status == 'success' && data.statusCode == '200') {
      this.customerDataSave(data);
      this.close('welcome');
      // this.lastfiveorderservice.clearhistoryData();
      // var id = GeneralSetting.getCustomerId();
      // let customerID = id == '' ? '-1' : id;
      // if (
      //   customerID == '-1' &&
      //   this.userService.punchhLoyaltyCustomer &&
      //   this.userService.punchhLoyaltyCustomer.Phone
      // ) {
      //   customerID = this.userService.punchhLoyaltyCustomer.Phone;
      // }
      // this.loadingModal.open()
      // this.data
      //   .getOrderHistoryByCustomerID(customerID)
      //   .toPromise()
      //   .then((data: any) => {
      //     this.lastfiveorderservice.OrderResponse=data
      //     if (this.router.url.indexOf('orderReview') == -1&&this.lastfiveorderservice.OrderResponse.OrderItemMasters.length) {
      //       this.router.navigateByUrl('/lastfiveorder');
      //     }
      //     //this.router.navigateByUrl('/lastfiveorder');
      //     this.close('');
      //   }).finally(()=>{
      //     this.loadingModal.close()
      //   });
    } else {
      this.isShowCreateAccount = true;
      this.isShowCreateAccountToolTip = true;
    }
  }
  doit(value: string) {
    this.qr = value;
    this.getLoyaltyCustomerRouter();
  }
  listener: EventListener | undefined;
  getLoyaltyCustomerByScan() {
    this.listener = (event: any) => {
      if (event.data.messageType === 'ScannerResult') {
        this.removeAllListeners();
        if (event.data.result) {
          // if(this.loyaltyType==LoyaltyType.OLOPunchh){
          //   this.customerPhonePunchh = event.data.result;
          //   this.customerPhone = event.data.result;
          // }

          //console.log(NgZone.isInAngularZone()) //-- returns true
          this.doit(event.data.result);
          //this.cd.detectChanges()//does not reflect
        }
      }
    };
    if (this.listeners.length == 0) {
      this.listeners.push(this.listener);
      window.addEventListener('message', this.listener);
    }
  }
}
