import { Component, OnInit, EventEmitter, Output, Input, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { AreYouSureComponent } from '../dialogs/are-you-sure/are-you-sure.component';
import { GeneralSetting } from '../../services/generalSetting.service';
import { LastfiveorderService } from 'src/app/services/lastfiveorder.service';
import { ShippingModalComponent } from '../dialogs/shipping-modal/shipping-modal.component';
import { ShippingService } from 'src/app/services/shipping.service';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';
import { ConcessionaireService } from '../menu/conc-service/concessionaire.service';
import { CommonFunctions } from '../../services/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-checkout-box',
  templateUrl: './checkout-box.component.html',
  styleUrls: ['./checkout-box.component.css'],
})
export class CheckoutBoxComponent implements OnInit {
  ngOnDestroy() {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  AllSubscription: Subscription[] = [];

  languageSub!: Subscription;

  subtotal: number = 0.0;

  @Input() isCartOpen: boolean = true;

  @Output() cleared = new EventEmitter<boolean>();

  positiveButton: string = '';

  negativeButton: string = '';

  positiveButtonText: string = '';

  negativeButtonText: string = '';

  secondaryColor: string = '';

  priceColor: string = '';

  isRemovePricingAndPayments: boolean = false;

  totalText: string = '';

  cancelOrderText: string = '';

  reviewOrderText: string = '';

  checkoutText: string = '';

  constructor(
    private router: Router,
    private cartService: CartService,
    private logger: LogService,
    private lastfiveservice: LastfiveorderService,
    private shipping: ShippingService,
    private language: LanguageService,
    private readonly conService: ConcessionaireService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.AllSubscription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
    this.calculateSubtotal();
    this.positiveButton = GeneralSetting.getPrimaryColor();
    this.negativeButton = GeneralSetting.getNegativeColor();
    this.positiveButtonText = GeneralSetting.getPositiveColorText();
    this.negativeButtonText = GeneralSetting.getNegativeColorText();
    this.secondaryColor = GeneralSetting.getSecondaryColor();
    this.priceColor = GeneralSetting.getPriceColor();
    this.isRemovePricingAndPayments =
      GeneralSetting.getIsRemovePricingAndPayments();
    this.calculationSubscription();
  }

  private calculationSubscription() {
    this.AllSubscription.push(
      this.cartService.sub_subtotal.subscribe((val) => {
        this.subtotal = val;
      })
    );
  }

  loadText() {
    this.totalText = this.language.getTextElement('text_total');
    this.cancelOrderText = this.language.getTextElement('cancel_order');
    this.reviewOrderText = this.language.getTextElement('lbl_review_order');
    this.checkoutText = this.language.getTextElement('lbl_checkout');
  }

  cancelOrder(): void {
    this.lastfiveservice.numofItemsAdded = 0;
    if (this.cartService.getCartItems().length == 0) {
      this.clearCart();
    }
    // if there is an item in the cart
    else {
      // prompt are you sure you want to leave dialog

      let cm = AreYouSureComponent.open(this.modalService);

      cm.result.then(
        (data: string) => {
          if (data === 'cancel') {
            try {
              const log = new loggingData(
                'Order Cancel',
                'Order Cancelled by user',
                'Order Cancel',
                JSON.stringify(this.cartService.getCartItems()),
                false
              );
              this.logger.sendLogToServer(log);
            } catch (e: any) {
              if (e.message.includes('btoa')) {
                const log = new loggingData(
                  'Order Cancel Logging Error',
                  'Order Cancelled by user',
                  'Order Cancel Logging Error',
                  `Error logging JSON.stringify(this.cartService.getCartItemsV2()): ${e.message}`,
                  false
                );
                this.logger.sendLogToServer(log);
              } else {
                const log = new loggingData(
                  'Order Cancel Unknown Error',
                  'Order Cancelled by user',
                  'Order Cancel Unknown Error',
                  `Unknown Error Logging JSON.stringify(this.cartService.getCartItemsV2()): ${e}`,
                  false
                );
                this.logger.sendLogToServer(log);
              }
            }
            this.clearCart();
            GeneralSetting.removeCustomerLoginMobile();
            GeneralSetting.removeCustomerLoginEmail();
            GeneralSetting.removeCustomerLoginName();
            GeneralSetting.removeCustomerId();
          }
        },
        () => {
          //dismissed instead of close
        }
      );
    }
  }

  clearCart() {
    if (GeneralSetting.getEnableOrderShipment() == 'True') {
      this.shipping.shippingDetails = undefined;
    }

    this.cartService.clearCart();
    this.clearFilter();
    setTimeout(() => {
      this.router.navigateByUrl('/touchToStart');
    }, 300);
  }

  toCheckout(): void {
    this.calculateSubtotal();
    // ensure items are in cart before order review
    if (this.cartService.getCartItems().length == 0) {
      const snackbar = document.getElementById('snackbar');
      if (snackbar){
        let err = this.language.getTextElement('empty_cart')
         snackbar.innerHTML = err;
      }

      if (snackbar) {
        snackbar.className = 'show';
        (async () => {
          await CommonFunctions.delay(2500);
          snackbar.className = '';
        })();
      }
    } else {
      var log = new loggingData(
        'CheckoutActivity',
        'Checkout button clicked',
        'General',
        'Checkout clicked',
        false
      );
      this.logger.sendLogToServer(log);

      if (
        GeneralSetting.getEnableOrderShipment() == 'True' &&
        GeneralSetting.getOrderTypeId() == '3'
      ) {
        ShippingModalComponent.open(this.modalService).result.then((data: string) => {
          if (data == 'success') {
            this.clearFilter();
            this.router.navigateByUrl('/orderReview');
          }
        });
      } else {
        this.clearFilter();
        this.router.navigateByUrl('/orderReview');
      }
    }
  }

  calculateSubtotal() {
    this.cartService.calculateSubtotal();
  }

  clearAll() {
    this.cleared.emit(true);
  }

  clearFilter() {
    this.conService.clearFilter();
  }
}
