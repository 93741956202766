import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Modifier } from 'typescript';
import { DatabaseHandler } from '../DatabaseHandler';
import { ModifierIngredientV2, ModifierV2 } from '../models/item';
import { OrderListReq } from '../models/OrderListReq';
import { Customer, CustomerPayments, ExternalIdentifiers, FulfillmentInfo, Items, Modifiers, OrderTotal, OtterOrderReq } from '../models/OtterOrderReq';
import { CartService } from './cart.service';
import { CommonFunctions } from './common';
import { DatabaseService } from './database.service';
import { EatOptionsService } from './eat-options.service';
import { GeneralSetting } from './generalSetting.service';

@Injectable({
  providedIn: 'root'
})
export class OtterService {
  private baseApiUrl = environment.apiUrl;

  constructor(private readonly http: HttpClient,
    private optionsService: EatOptionsService,
    private cartService: CartService,
    private databaseService: DatabaseService,
    private datePipe: DatePipe,) { }


  pingOtter(concessionaireIdList: any[]) {
    const url = this.baseApiUrl + 'PingOtter';
    let headers = new HttpHeaders()
      .set('Version', '1.0')
      .set('Content-Type', 'application/json')
      .set('CompanyID', GeneralSetting.getCompanyId())
      .set('BranchID', GeneralSetting.getBranchId())
      .set('DeviceID', GeneralSetting.getSerialNo());


    // let body = {
    //   data: concessionaireIdList
    // };
    return this.http.post(url, concessionaireIdList, { headers });
  }


  callOrderPlaceOtter(otterOrderReq: OtterOrderReq) {
    const url = this.baseApiUrl + 'OrderPlaceOtter';
    let headers = new HttpHeaders()
      .set('Version', '1.0')
      .set('Content-Type', 'application/json')
      .set('CompanyID', GeneralSetting.getCompanyId())
      .set('BranchID', GeneralSetting.getBranchId())
      .set('DeviceID', GeneralSetting.getSerialNo());

    return this.http.post(url, otterOrderReq, { headers });
  }


  getOrderDataForOtter(orderData: OrderListReq, indexForConcessionaireSplitbill: number): Promise<OtterOrderReq> {
    return new Promise<OtterOrderReq>(async (resolve) => {
      let singleConcessioniareOrderSplitBill = orderData.OrderMaster[0].ConcessioniareOrderSplitBill[indexForConcessionaireSplitbill];

      let currentOption = this.optionsService.getCurrentEatOption();
      let friendlyId = ''
      if (currentOption.KioskTableTent == 'True' && GeneralSetting.getTableTentNumber() != '') {
        friendlyId = GeneralSetting.getTableTentNumber() + "-" + (indexForConcessionaireSplitbill + 1)
      } else {
        friendlyId = GeneralSetting.getOrderInvoiceNo() + "-" + (indexForConcessionaireSplitbill + 1)
      }

      let conRefId = await this.databaseService.getConcessionaireRefIdByConcessionaireIDFromSql(singleConcessioniareOrderSplitBill.BranchSectionConcessionaireID);
      let externalIdentifiers = {
        id: GeneralSetting.getInvoiceNoToDb() + "-" + (indexForConcessionaireSplitbill + 1),
        friendlyId: friendlyId,
        source: conRefId
      } as ExternalIdentifiers

      //Items [start]
      let items: Items[] = [];

      let conItems = this.cartService.getCartItems().filter(
        (x) => x.ConcessionaireId == singleConcessioniareOrderSplitBill.BranchSectionConcessionaireID
      );

      for (let index = 0; index < conItems.length; index++) {
        const item = conItems[index];
        let appRef = item.RefItemId?.toString()

        let modifiers: Modifiers[] = []
        this.getFormattedModifier(modifiers, item.Modifiers)
        // let itemModifiers = CommonFunctions.getSelectedModifiersWithIngredient(item.Modifiers);
        // if (itemModifiers[0] && (itemModifiers[0] as ModifierV2[]).length > 0) {
        //   for (let index = 0; index < (itemModifiers[0] as ModifierV2[]).length; index++) {
        //     const modifier = (itemModifiers[0] as ModifierV2[])[index];
        //     for (let index = 0; index < modifier.Ingredients.length; index++) {
        //       const ingredient = modifier.Ingredients[index] as ModifierIngredientV2;
        //       if (ingredient.IsSelected) {
        //         modifiers.push({
        //           id: ingredient.RefIngredientId,
        //           name: ingredient.Name,
        //           price: ingredient.ExtraPrice.toString(),
        //           groupId: modifier.RefModifierId?.toString(),
        //           quantity: ingredient.Quantity.toString(),
        //           groupName: modifier.Category
        //         } as Modifiers)
        //       }

        //     }
        //   }
        // }

        items.push({
          id: appRef!,
          categoryId: item.CategoryID.toString(),
          categoryName: item.CategoryName,
          name: item.Name,
          note: item.specialRequest ?? item.specialRequest,
          price: item.totalPrice && Number(item.totalPrice) > 0 ? (item.totalPrice / Number(item.Quantity)).toFixed(2) : '0',
          quantity: item.Quantity.toString(),
          modifiers: modifiers

        })
      }
      //Items [End]

      let customer = {
        name: orderData.OrderMaster[0].CustomerMaster.DisplayName,
        email: orderData.OrderMaster[0].CustomerMaster.EmailID,
        phone: orderData.OrderMaster[0].CustomerMaster.MobileNo,
        phoneCode: orderData.OrderMaster[0].CustomerMaster.MobileCode
      } as Customer

      let orderTotal = {
        subtotal: singleConcessioniareOrderSplitBill.SubTotal,
        claimedSubtotal: singleConcessioniareOrderSplitBill.SubTotal,
        discount: singleConcessioniareOrderSplitBill.OrderDiscount,
        tax: singleConcessioniareOrderSplitBill.OrderTax,
        tip: singleConcessioniareOrderSplitBill.TipAmount,
        couponCode: null,
        deliveryFee: null,
        total: singleConcessioniareOrderSplitBill.PaymentAmount
      } as OrderTotal

      let customerPayments: CustomerPayments[] = []
      let paymenttypeId = sessionStorage.getItem('paymentTypeID')
      let paymentMethod = ''
      if (paymenttypeId == '1') {
        paymentMethod = 'CASH';
      } else if (paymenttypeId == '2') {
        paymentMethod = 'CARD';
      } else {
        paymentMethod = 'OTHER';
      }

      let customerPayment = {
        value: singleConcessioniareOrderSplitBill.PaymentAmount,
        processingStatus: 'PROCESSED',
        paymentMethod: paymentMethod
      } as CustomerPayments
      customerPayments.push(customerPayment);


      let fullFillmentMode = '';
      if (currentOption.OrderTypeID == '1') {
        fullFillmentMode = 'DINE_IN'
      } else if (currentOption.OrderTypeID == '2') {
        fullFillmentMode = 'PICKUP'
      } else {
        fullFillmentMode = 'PICKUP'
      }

      let fulfillmentInfo = {
        pickupTime: CommonFunctions.getUTCDate(), //set UTC time @nilesh
        deliveryTime: null,
        fulfillmentMode: fullFillmentMode,
        schedulingType: 'ASAP',
        courierStatus: null
      } as FulfillmentInfo
      const activationDate = new Date();

      let n = new Date(activationDate.getUTCFullYear(),
        activationDate.getUTCMonth(),
        activationDate.getUTCDate(),
        activationDate.getUTCHours(),
        activationDate.getUTCMinutes(),
        activationDate.getUTCSeconds()
      );

      let finalDate = this.datePipe.transform(n, 'MM/dd/YYYY hh:mm:ss a')

      let otterOrderReq = {
        externalIdentifiers: externalIdentifiers,
        items: items,
        orderedAt: CommonFunctions.getUTCDate(), //set UTC time @nilesh
        currencyCode: GeneralSetting.getCountryCurrency(),
        customer: customer,
        customerNote: orderData.OrderMaster[0].ExtraComment,
        status: 'NEW_ORDER',
        orderTotal: orderTotal,
        deliveryInfo: null,
        customerPayments: customerPayments,
        fulfillmentInfo: fulfillmentInfo
      } as OtterOrderReq


      resolve(otterOrderReq);
    });
  }



  getFormattedModifier(otterModifierListMain: Modifiers[], modifier: ModifierV2[]) {

    function formatIngredient(ingredient: ModifierIngredientV2, parentModifier: ModifierV2, modifierTemp: Modifiers) {
      let otterModifierTemp: Modifiers[] = []
      if (ingredient.IsSelected) {
        otterModifierTemp.push({
          id: ingredient.RefIngredientId,
          name: ingredient.Name,
          price: ingredient.ExtraPrice.toString(),
          groupId: parentModifier.RefModifierId?.toString(),
          quantity: ingredient.Quantity.toString(),
          groupName: parentModifier.Category
        } as Modifiers)
      } else if (
        ingredient.isNegativeModifier
      ) {
        otterModifierTemp.push({
          id: ingredient.RefIngredientId,
          name: ingredient.Name,
          price: ingredient.ExtraPrice.toString(),
          groupId: parentModifier.RefModifierId?.toString(),
          quantity: ingredient.Quantity.toString(),
          groupName: parentModifier.Category
        } as Modifiers)
      }


      // modifierTemp.modifiers.push(otterModifierTemp[0]);
      return otterModifierTemp[0];
    }


    function formatModifier(modifier: ModifierV2, otterModifierListMain: Modifiers[], modifierTemp: Modifiers) {

      if (modifier.IsSelected) {

        if (modifier.Ingredients && modifier.Ingredients.length > 0) {
          const selectedIngredient =
            CommonFunctions.getSelectedModifierIngredientList(modifier.Ingredients, true);

          for (let i = 0; i < selectedIngredient.length; i++) {
            const ing = selectedIngredient[i];
            if (ing.IsIngredient) {
              let ingredient = ing as ModifierIngredientV2;

              let ingredientFormated = formatIngredient(ingredient, modifier, modifierTemp);


              if (Object.keys(modifierTemp).length != 0) {
                modifierTemp.modifiers.push(ingredientFormated)

              } else {
                otterModifierListMain.push(ingredientFormated)
                // modifierTemp = ingredientFormated
              }

              // if (modifierTemp.length > 1) {
              //   for (let i = 0; i < modifierTemp.length - 1; i++) {
              //     modifierTemp[i].modifiers.push(modifierTemp[i + 1])
              //   }

              //   otterModifierListMain.push(modifierTemp[0])
              // } else { 
              //   otterModifierListMain.push(modifierTemp[0])
              // }
              // modifierTemp = [];
            } else if (ing.IsModifier) {
              let mod = ing as ModifierV2;


              let tempOtterModifier = {
                id: mod.RefModifierId,
                name: mod.Category,
                price: mod.Price.toString(),
                groupId: modifier.RefModifierId?.toString(),
                quantity: mod.Quantity.toString(),
                groupName: modifier.Category,
                modifiers: []
              } as Modifiers

              if (Object.keys(modifierTemp).length != 0) {
                modifierTemp.modifiers.push(tempOtterModifier)
                formatModifier(mod, otterModifierListMain, tempOtterModifier);
              } else {
                modifierTemp = tempOtterModifier
                formatModifier(mod, otterModifierListMain, modifierTemp);
              }


              // formatModifier(mod, otterModifierListMain, modifierTemp);
            }
          }

          console.log("modifierTemp", modifierTemp);

        }
      }




      return modifierTemp;
    }

    //insert all modifier in otterModifierListMain
    for (let i = 0; i < modifier.length; i++) {
      let modifierTemp: Modifiers = {} as Modifiers;
      let temp = formatModifier(modifier[i], otterModifierListMain, modifierTemp)
      if (Object.keys(temp).length != 0) {
        otterModifierListMain.push(temp)
      }

    }
  }
}
