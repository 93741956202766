<div *ngIf="
    displayedPromos && displayedPromos.length > 0 && !isRemovePricingAndPayments
  " class="discounts">
  <p class="discount-display">{{availableDiscountText}}</p>
  <div class="discount-section">
    <div *ngFor="let promo of displayedPromos">
      <div [ngStyle]="{
          borderColor:
            promo.DiscountID == selectedItemPromo.DiscountID || promo.DiscountID== selectedSubtotalPromo.DiscountID
              ? 'green'
              : ''
        }" (click)="selectDiscount(promo, false)" class="discount-box">
        <img class="discount-image" src="assets/images/ic_discount_offer.png"
          onerror="this.onerror=null; this.src='assets/images/Image_21.png'" alt="" />
        <p class="discount-name">{{ promo.Name }}</p>
        <p class="discount-price" *ngIf="
            promo.DiscountAmount != 0 && promo.UsePercentage == 'False';
            else bogoname
          ">
          {{ promo.DiscountAmount | number: "1.2" | CurrencyPipe }}
        </p>
        <ng-template #bogoname>
          <p *ngIf="promo.RegularOrBogoDiscount == '1'" class="discount-price">
            {{bogoText| replace :'%d' : promo.BogoPurchaseQuantity| replace :'%e' : promo.BogoGetQuantity }}
          </p>
        </ng-template>
        <p class="discount-price" *ngIf="promo.DiscountPercentage && promo.UsePercentage == 'True'">
          {{ promo.DiscountPercentage }}%
        </p>
      </div>
    </div>
  </div>
</div>

<div class="bottom-positions" *ngIf="isLoyalty && !isRemovePricingAndPayments">
  <div *ngIf="loyaltyType == 2">
    <div class="row vertical-center promo-code redeem-rewards-section p-0">
      <div class="col-8 col-md-8 promo-points">
        <div>{{pointsEarnedTextA}}<span class="primary-color bold"> {{' '+availablePoints+' '}}
          </span>{{pointsEarnedTextB}}</div>
        <!-- <strong>
          You have Earned
          <span>{{
            availablePoints | number: "1.0":"en-US"
          }}</span>
          points
        </strong> -->
      </div>
      <div class="col-4 col-md-4 text-right">
        <div class="form-group">
          <button matRipple (click)="openPromo()" class="
            gray-btn
            secondary-button-color
            border-secondary-button-color
            text-uppercase
            bg-white" type="text" placeholder="Use Promo Code" *ngIf="(allPromotions && allPromotions.length > 0)">
            {{usePromoText}}
          </button>
        </div>
      </div>
    </div>

    <div class="row reward-section">
      <div class="row scroll-item">
        <div class="col-md-3 col-3 animated fadeInRight" *ngFor="let reward of loyaltyRewards">
          <input type="radio" id="1" name="radio-group" class="d-none reward-section loyaltyCheckBox JS_LoyaltyCheckBox"
            id="{{ reward.id }}" style="display: none" [checked]="reward.id == selectedLoyaltyReward"
            (click)="onLoyaltyPromotionClick($event, reward)" />
          <label class="redeem-content" for="{{ reward.id }}">
            <a href="Javascript:void(0);" class="remove-combo-item" (click)="onLoyaltyRemoveClick(reward)"
              [style.display]="
                reward.id == selectedLoyaltyReward ? 'block' : 'none'
              ">
              <i class="fas fa-times"></i>
            </a>
            <div class="">
              <h2 class="text-center">{{ reward.title }}</h2>
              <h3 class="text-center">
                {{ getRewardDesc(reward) }}
              </h3>
              <h4 class="text-success text-center">
                {{pointsText|replace :'%d' : reward.points}}
              </h4>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loyaltyType == 1 || loyaltyType == 4">
    <!-- <div *ngIf="loyaltyType == 1" class="row vertical-center promo-code redeem-rewards-section p-0">
      <div class="col-8 col-md-8 promo-points">
        <strong> </strong>
      </div>
    </div> -->

    <div class="row reward-section">
      <div class="row scroll-item">
        <div class="col-md-3 col-3 animated fadeInRight" *ngFor="let reward of loyaltyRewards">
          <input type="radio" id="1" name="radio-group" class="d-none reward-section loyaltyCheckBox JS_LoyaltyCheckBox"
            id="{{ reward.reference }}" style="display: none" [checked]="reward.reference == selectedLoyaltyReward"
            (click)="onLoyaltyPromotionClick($event, reward)" />
          <label class="redeem-content" for="{{ reward.reference }}">
            <a href="Javascript:void(0);" class="remove-combo-item" (click)="onLoyaltyRemoveClick(reward)"
              [style.display]="
                reward.reference == selectedLoyaltyReward ? 'block' : 'none'
              ">
              <i class="fas fa-times"></i>
            </a>
            <div class="">
              <h2 class="text-center">
                {{ loyaltyType == 1 ? reward.fineprint : reward.label }}
              </h2>
              <h3 class="text-center">
                {{ reward.description }}
              </h3>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-md-12 col-xs-12 vertical-center promo-code redeem-rewards-section p-0" *ngIf="(!isLoyalty && ((hasLoyaltyProgram && useLoyalty&&loyaltyType!=3) || (((hasLoyaltyProgram && !useLoyalty)||(!hasLoyaltyProgram)) &&
  allPromotions && allPromotions.length > 0))) 
  && !isRemovePricingAndPayments">
  <div *ngIf="loyaltyType!=3&&loyaltyType!=4" class="col-xs-7 col-md-7 promo-code-text">
    <span *ngIf="hasLoyaltyProgram && useLoyalty">
      <strong>{{rewardsAccountText}} </strong>&nbsp;<strong (click)="signIn()"> {{signUpText}}</strong>

    </span>
    <strong *ngIf="
        !(hasLoyaltyProgram && useLoyalty) &&
        allPromotions && allPromotions.length > 0
      ">{{gotPromoText}}</strong>
  </div>
  <div class="col-xs-5 col-md-5 text-right">
    <div class="form-group" style="margin-left: 10rem" *ngIf="(allPromotions && allPromotions.length > 0)">
      <button matRipple (click)="openPromo()" class="
              gray-btn
              secondary-button-color
              border-secondary-button-color
              text-uppercase
              bg-white
            " type="text" placeholder="Use Promo Code" *ngIf="(allPromotions && allPromotions.length > 0)">
        {{usePromoText}}
      </button>
    </div>
  </div>
</div>

<div id="snackbar">{{ sneakBarMsg }}</div>
<div style="display: none">
  <app-redeem-reward></app-redeem-reward>

  <app-promo-code></app-promo-code>

</div>