<main class="meulist settings-page">
  <div class="row setting-top-row">
    <div class="col-sm-4">
      <a (click)="Login()">
        <img

          src="../../../assets/images/Grubbrr_Logo.jpg"
          onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
          class="img-fluid"
        />
      </a>
    </div>
    <div class="col-sm-4">
      <h2>{{ settingsText }}</h2>
    </div>
    <div class="col-sm-4">
      <button (click)="LaunchKiosk()">
        <i class="fa fa-sign-in-alt"></i> <br />{{ launchKioskText }}
      </button>
    </div>
  </div>
  <div class="row">
    <div role="tabpanel">
      <div class="col-sm-3 side-bar">
        <ul class="nav nav-pills brand-pills nav-stacked" role="tablist">
          <li
            role="presentation"
            class="brand-nav nav-item"
            [ngClass]="{ active: deviceInfo }"
          >
            <a
              (click)="setDeviceInfo()"
              class="nav-link"
              aria-controls="tab2"
              role="tab"
              data-toggle="tab"
              ><i class="fa fa-mobile-alt"></i>{{ deviceInfoText }}</a
            >
          </li>
          <li
            role="presentation"
            class="brand-nav nav-item"
            [ngClass]="{ active: payment }"
          >
            <a
              (click)="setPayment()"
              class="nav-link"
              aria-controls="tab3"
              role="tab"
              data-toggle="tab"
              ><i class="fa fa-credit-card"></i> {{ paymentText }}</a
            >
          </li>
          <li
            role="presentation"
            class="brand-nav nav-item"
            [ngClass]="{ active: generalSettings }"
          >
            <a
              (click)="setGeneralSettings()"
              class="nav-link"
              aria-controls="tab3"
              role="tab"
              data-toggle="tab"
              ><i class="fa fa-print"></i> {{ printerText }}</a
            >
          </li>
          <li
            *ngIf="!isHideZReport"
            role="presentation"
            class="brand-nav nav-item"
            [ngClass]="{ active: zreport }"
          >
            <a
              (click)="setZR()"
              class="av-link"
              aria-controls="tab4"
              role="tab"
              data-toggle="tab"
              ><i class="fa fa-file-alt"></i> {{ zReportText }}</a
            >
          </li>
          <li
            role="presentation"
            class="brand-nav nav-item"
            [ngClass]="{ active: grosssales }"
          >
            <a
              (click)="setGS()"
              class="av-link"
              aria-controls="tab4"
              role="tab"
              data-toggle="tab"
              ><i class="fa fa-file-alt"></i> {{ grossSalesText }}</a
            >
          </li>
          <li
            role="presentation"
            class="brand-nav nav-item"
            [ngClass]="{ active: itemwise }"
          >
            <a
              (click)="setIS()"
              class="av-link"
              aria-controls="tab4"
              role="tab"
              data-toggle="tab"
              ><i class="fa fa-file-alt"></i> {{itemSalesText}}</a
            >
          </li>
          <li
            role="presentation"
            class="brand-nav nav-item"
            [ngClass]="{ active: orderhistory }"
          >
            <a
              (click)="setOH()"
              class="av-link"
              aria-controls="tab4"
              role="tab"
              data-toggle="tab"
              ><i class="fa fa-file-alt"></i> {{ orderHistoryText }}</a
            >
          </li>
          <li
            role="presentation"
            class="brand-nav nav-item"
            [ngClass]="{ active: healthLogs }"
          >
            <a
              (click)="setHL()"
              class="av-link"
              aria-controls="tab4"
              role="tab"
              data-toggle="tab"
              ><i class="fa fa-file-alt"></i> {{healthLogsText}} </a
            >
          </li>
          <!-- <li
            role="presentation"
            class="brand-nav nav-item"
            [ngClass]="{ active: items }"
          >
            <a
              (click)="setItems()"
              class="nav-link"
              aria-controls="tab5"
              role="tab"
              data-toggle="tab"
              ><i class="fa fa-list"></i> {{ itemsText }}</a
            >
          </li>
          <li
            role="presentation"
            class="brand-nav nav-item"
            [ngClass]="{ active: concessionaireShow }"
          >
            <a
              (click)="setConcessionaire()"
              class="nav-link"
              aria-controls="tab5"
              role="tab"
              data-toggle="tab"
              ><i class="fa fa-list"></i>{{ concessionaireText }}</a
            >
          </li>
          <li
            role="presentation"
            class="brand-nav nav-item"
            [ngClass]="{ active: storeTimings }"
          >
            <a
              (click)="setStoreTiming()"
              class="nav-link"
              aria-controls="tab7"
              role="tab"
              data-toggle="tab"
              ><i class="fa fa-clock"></i> {{ storeTimingText }}</a
            >
          </li> -->
          <li role="presentation" class="brand-nav nav-item">
            <a
              (click)="openLogoutConfirmation()"
              data-toggle="modal"
              class="nav-link"
              data-target="#confirmation"
              ><i class="fa fa-power-off"></i>{{ logoutText }}</a
            >
          </li>
        </ul>
      </div>
      <div class="col-sm-9 content">
        <div class="settings-content">
          <app-settings-deviceinfo *ngIf="deviceInfo"></app-settings-deviceinfo>
          <app-settings-generalsettings
            *ngIf="generalSettings"
            (printersRefreshFlag)="setRefreshDBFlag($event)"
          ></app-settings-generalsettings>
          <app-settings-reports *ngIf="reports"></app-settings-reports>
          <!-- <app-settings-items *ngIf="items" (updateMenu)="updateMenu($event)"></app-settings-items> -->
          <app-settings-refresh-data
            *ngIf="refreshData"
          ></app-settings-refresh-data>
          <!-- <app-settings-store-timings *ngIf="storeTimings"></app-settings-store-timings> -->
          <app-settings-payment *ngIf="payment"></app-settings-payment>
          <app-settings-z-report
            [zReport]="zReportData"
            *ngIf="zreport"
          ></app-settings-z-report>
          <app-settings-itemwise-sales-report
            *ngIf="itemwise"
          ></app-settings-itemwise-sales-report>
          <app-settings-gross-sales
            *ngIf="grosssales"
          ></app-settings-gross-sales>
          <app-settings-order-history
            (refershZzReportEvent)="refershZzReport($event)"
            *ngIf="orderhistory"
          >
          </app-settings-order-history>
          <app-health-log *ngIf="healthLogs"></app-health-log>

          <!-- <app-concessionaire-setting *ngIf="concessionaireShow"></app-concessionaire-setting> -->
        </div>
        <div class="row footer-setting-btn">
          <div
            *ngIf="false"
            (click)="exportDatabase()"
            class="col-6 col-md-6 text-center btn1"
          >
            <button>
              <i class="fa fa-external-link-alt"></i>
              {{ exportDBText | uppercase }}
            </button>
          </div>
          <div class="db-refresh">
            <div class="date-db-refresh"
              >{{ lastSyncText }} {{ dataDate | date: "short" }}</div>
            <button
              (click)="doRefresh()"
              [ngStyle]="{
                'pointer-events': doRefreshDB === true ? 'all' : 'none'
              }"
              [disabled]="refreshDisable"
              class="kiosk-setting-button"
            >
              <i class="fa fa-sync"></i> {{ refreshDBText | uppercase }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<script>
  $(".nav-pills li a").click(function () {
    $(".nav-pills li a").removeClass("active");
    $(this).addClass("active");
  });
</script>
<app-logout-confirmation-dialog></app-logout-confirmation-dialog>
<div style="display: none">
  <app-manager-pin-dialog></app-manager-pin-dialog>
</div>
<app-coming-soon></app-coming-soon>
<div class="loading-overlay">
  <div class="loading-div">
    <h2 class="fas fa-spinner fa-3x fa-spin"></h2>
    <br />
    <div class="clearfix"></div>
    <h3>{{ refreshingMenuText }}</h3>
  </div>
</div>

<div id="snackbar">Network error</div>

<app-refresh-data-loading [hidden]="true"></app-refresh-data-loading>
