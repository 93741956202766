import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-connection-loss-popup',
  templateUrl: './connection-loss-popup.component.html',
  styleUrls: ['./connection-loss-popup.component.css'],
})
export class ConnectionLossPopupComponent implements OnInit,OnDestroy {
  allSubscription: Subscription[] = [];
  
  modalReference: any;
  // priceColor: any;

  positiveButton: string | null = '';
  positiveButtonText: string | null = '';

  connectionText:string=''
  uhohText:string=''
  okText:string=''
  tryAgainText:string=''
  constructor(private modalService: NgbModal, private language:LanguageService) {
    this.allSubscription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }
  ngOnDestroy() {
    if (this.allSubscription.length > 0) {
      for (let i = 0; i < this.allSubscription.length; i++) {
        this.allSubscription[i].unsubscribe();
      }
    }
  }
  loadText(){
    this.connectionText=this.language.getTextElement('txt_there_is_problem_connecting_to_network')
    this.okText=this.language.getTextElement('lbl_ok')
    this.uhohText=this.language.getTextElement('titile_uh_oh')
    this.tryAgainText=this.language.getTextElement('please_try_again')
  }
  ngOnInit(): void {
 
    this.positiveButton = GeneralSetting.getPrimaryColor();
    this.positiveButtonText = GeneralSetting.getPositiveColorText();
  }

  public static open(modalService:any) {
    var modalRef = modalService.open(ConnectionLossPopupComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.modalReference = modalRef;

    return modalRef;
  }

  retry() {
    if (!navigator.onLine) {
      this.modalReference.close('offline');
    } else this.modalReference.close('online');
  }
  clear() {
    this.modalReference.close('close');
  }
}
