import { Component, OnDestroy, OnInit } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { interval, Subscription } from 'rxjs'
import { LanguageService } from 'src/app/services/language.service'

@Component({
  selector: 'app-update-menu',
  templateUrl: './update-menu.component.html',
  styleUrls: ['./update-menu.component.css'],
})
export class UpdateMenuComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private modalRef: any;

  private allSubsCription: Subscription[] = [];

  ref: any;
  headerText: string = '';
  descriptions: string[] = [];
  description: string = '';
  index: number = 0;

  currentStateText: string = '...';
  indexText: string = '';
  path: string = '';

  constructor(private language: LanguageService) {
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }

  loadText() {
    this.descriptions = [];
    this.currentStateText = this.language.getTextElement('loading_menu');
    this.descriptions.push(this.language.getTextElement('updating_your_menu'));
    this.descriptions.push(this.language.getTextElement('adding_new_items'));
    this.descriptions.push(
      this.language.getTextElement('checking_for_updates')
    );
    this.descriptions.push(this.language.getTextElement('verifying_prices'));
    this.descriptions.push(
      this.language.getTextElement('getting_really_hungry')
    );
    this.headerText = this.language.getTextElement('txt_please_wait');
  }

  ngOnInit(): void {
    this.description = this.language.getTextElement('loading_menu');
    const source = interval(1000);
    this.allSubsCription.push(
      source.subscribe((val) => {
        this.description = this.descriptions[this.index];
        this.index =
          this.index > this.descriptions.length - 2 ? 0 : this.index + 1;
      })
    );
  }

  public static open(modalService: NgbModal) {
    const ref = modalService.open(UpdateMenuComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    ref.componentInstance.modalRef = ref;

    return ref;
  }

  clear() {
    this.ngOnDestroy();

    if (this.modalRef && this.modalRef != null) {
      this.modalRef.close();
    }
  }

  public setCurrentStateMessage(message: string) {
    this.currentStateText = message;
  }

  public setIndexText(message: string) {
    this.indexText = message;
  }
}
