import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-par-brink-error-pop-up',
  templateUrl: './par-brink-error-pop-up.component.html',
  styleUrls: ['./par-brink-error-pop-up.component.css'],
})
export class ParBrinkErrorPopUpComponent implements OnInit, OnDestroy {
  errorMsg: string = '';
  errorCode: number = 0;
  retry: boolean = true;
  modalReference: any;
  headerDescHasScroll = true;
  languageSub!: Subscription;
  arr: string[] = [];
  constructor(
    public modalService: NgbModal,
    private language: LanguageService
  ) {}
  backToCartText: string = '';
  cancelText: string = '';
  checkoutText: string = '';
  okText: string = '';
  retryText: string = '';
  uhOhText: string = '';
  affectedItemsText: string = '';
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {
    this.backToCartText = this.language.getTextElement('lbl_back_to_cart');
    this.cancelText = this.language.getTextElement('lbl_button_cancel');
    this.checkoutText = this.language.getTextElement('lbl_checkout');
    this.okText = this.language.getTextElement('lbl_ok');
    this.retryText = this.language.getTextElement('lbl_retry');
    this.uhOhText = this.language.getTextElement('lbl_uh_oh');
    this.affectedItemsText = this.language.getTextElement('affected_items');
  }
  ngOnInit(): void {}

  ngAfterViewInit() {
    this.isOverflow();
  }

  public static open(modalService:NgbModal,language:LanguageService, errorCode: number, str = '', arr: string[] = []) {
    let arrayV2 = [];
    let retry: boolean = true;
    let errorMsg: String = '';

    if (errorCode === 3) {
      arrayV2 = [];
      retry = false;
      let error = language.getTextElement('problem_contacting_pos_take_receipt')
      errorMsg =error
    } else if (errorCode === 2) {
      arrayV2 = [];
      retry = true;
      let error=language.getTextElement('problem_contacting_pos')
      errorMsg = error;
    } else if (errorCode === 1 && str != '') {
      arrayV2= arr;
      retry = false;
      let error=language.getTextElement('parbrink_invalid_items_error').replace('%d',str)
      errorMsg = error;
    } else if (errorCode === 101 && str != '') {
      arrayV2= arr;
      retry = false;
      errorMsg = str;
    }

    let modalReference = modalService.open(ParBrinkErrorPopUpComponent, {
      backdrop: 'static',
      size: 'xl',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.retry = retry;
    modalReference.componentInstance.errorMsg = errorMsg;
    modalReference.componentInstance.arr = arr;
    modalReference.componentInstance.errorCode = errorCode;

    return modalReference;
  }

  // open(errorCode: number, str = '', arr: string[] = []) {
  //   if (errorCode === 3) {
  //     this.arr = [];
  //     this.retry = false;
  //     this.errorMsg =
  //       'There is a problem connecting to the POS. Please contact the store manager with your receipt to pick up your order.';
  //   } else if (errorCode === 2) {
  //     this.arr = [];
  //     this.retry = true;
  //     this.errorMsg =
  //       'There is a problem connecting to the POS. Please try again or contact the store manager.';
  //   } else if (errorCode === 1 && str != '') {
  //     this.arr = arr;
  //     this.retry = false;
  //     this.errorMsg = `Your order contains invalid items. Please remove or alter ${str}.`;
  //   } else if (errorCode === 101 && str != '') {
  //     this.arr = arr;
  //     this.retry = false;
  //     this.errorMsg = str;
  //   }

  //   this.modalReference = this.modalService.open(ParBrinkErrorPopUpComponent, {
  //     backdrop: 'static',
  //     size: 'xl',
  //     centered: true,
  //   });

  //   this.modalReference.componentInstance.modalReference = this.modalReference;
  //   this.modalReference.componentInstance.retry = this.retry;
  //   this.modalReference.componentInstance.errorMsg = this.errorMsg;
  //   this.modalReference.componentInstance.arr = arr;
  //   this.modalReference.componentInstance.errorCode = errorCode;
  // }

  close(data: string) {
    this.modalReference.close(data);
  }

  //not functional
  private isOverflow() {
    const element = document.getElementById('arrowDown');
    if (element) {
      if (element.scrollHeight > element.clientHeight) {
        this.headerDescHasScroll = true;
      } else {
        this.headerDescHasScroll = false;
      }
    }
  }
}
