import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ModifierIngredientV2, ModifierV2 } from '../../../../models/item';
import { GeneralSetting } from '../../../../services/generalSetting.service';
import { NestedModifierComponent } from '../../../nested-modifier/nested-modifier.component';

import { CommonFunctions, Snackbar } from '../../../../services/common';

import { DatabaseService } from 'src/app/services/database.service';
import { PusherService } from 'src/app/services/pusher.service';
import { CartService } from 'src/app/services/cart.service';
import { LogService } from 'src/app/services/log.service';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { OLOService } from 'src/app/services/olo.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-ingredient',
  templateUrl: './ingredient.component.html',
  styleUrls: ['./ingredient.component.css'],
})
export class IngredientComponent implements OnInit, OnChanges {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  priceColor: string | null = '';

  @Input() ingredient: any = {} as any;

  @Input() ingredientIndex: number = 0;

  @Input() parent: ModifierV2 = {} as ModifierV2;

  @Input() ItemType: number = 1;

  @Input() ItemName: string = '';

  @Input() ItemDescription: string = "";

  @Input() IsDisableSubgroup: boolean = false;

  @Output() parentChange = new EventEmitter<ModifierV2>();

  @Output() checkValidEmit = new EventEmitter<ModifierV2>();

  @Output() SelectedItem: EventEmitter<any> = new EventEmitter<any>();

  isParbrink: boolean = GeneralSetting.getParBrinkIntegrationID() != '';

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  primaryColor: string = '';

  image: string = '';

  deselectedImage: string = '';

  isSoldOut: boolean = false;

  quantity: number = 1;

  Price: string = '';

  isShowNoCharge = true;

  maxQty = 0;

  itemId: string = "";
  showCalories: boolean = GeneralSetting.getShowCalorieCounts() == "True"
  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();

  bordersTypeModifire: boolean = false;
  imageRadius: string = '';
  soldOutText: string = '';
  calorieText: string = '';
  customizeText: string = ''
  noChargeText: string = ''
  isOlo: boolean = false;
  placeholderImage: string = '';

  constructor(
    private readonly isKioskService: IsKioskService,
    private readonly modalService: NgbModal,
    private readonly pusherService: PusherService,
    private readonly db: DatabaseService,
    private cartservice: CartService,
    private logger: LogService,
    private readonly route: ActivatedRoute,
    private language: LanguageService,
    private readonly olo: OLOService,
    private readonly dataService: DataService
  ) {
    this.itemId = this.route.snapshot.queryParams['id'];
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }
  loadText() {
    this.soldOutText = this.language.getTextElement('sold_out')
    this.calorieText = this.language.getTextElement('calorie_format_text')
    this.customizeText = this.language.getTextElement('txt_customize')
    this.noChargeText = this.language.getTextElement('no_charge')
  }

  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.isOlo = this.olo.isOLO;

    this.image = this.getFormattedURL(this.ingredient);
    if (this.ingredient.IsModifier) {
      this.deselectedImage = this.getDeselectedFormattedURL(
        this.ingredient as ModifierV2
      );
    }

    this.primaryColor = GeneralSetting.getPrimaryColor();
    this.priceColor = GeneralSetting.getPriceColor();
    // this.object.parentObject = this.parent;

    if(this.ingredient.IsSelected && this.ingredient.IsDefault == 'True' && this.ingredient.IsDefault != undefined && this.parent.UseAdvancedModifierQuantitySettings == 'True'){
      this.parent.Ingredients[this.ingredientIndex].IsSelected = false;
      this.itemClicked();
    }else{
      this.quantity = Number(this.ingredient.Quantity);
    }
    
    this.imageRadius = GeneralSetting.getBorderPercentage();

    this.bordersTypeModifire =
      GeneralSetting.getBorderTypeModifiers().toLowerCase() == 'true';

    if (this.ingredient.IsIngredient) {
      this.ingredient = <ModifierIngredientV2>this.ingredient;

      if (this.ingredient.IsIngredient86 == 'False') {
        this.parent.isValid = true;
      }

      this.maxQty = Number(this.ingredient.MaxQty);

      if (this.ingredient.CurrentStock && this.ingredient.LowThreshold) {
        this.isSoldOut =
          this.ingredient.CurrentStock !== ''
            ? this.ingredient.CurrentStock <= this.ingredient.LowThreshold
            : false;
      }
    }

    this.setIngredientPrice();

    this.checkIsShowNoCharge();

    this.checkValidEmit.emit(this.parent);
  }

  ngOnChanges(): void {
    if (this.parent.UseAdvancedModifierQuantitySettings == 'False') {
      this.parent.count = CommonFunctions.getSelectedIngredientCount(this.parent);
    } else {
      this.parent.count = CommonFunctions.getOnlySelectedIngredientCount(this.parent);
    }

  }

  private getFormattedURL(ingredient: any): string {
    if (ingredient.IsSelected) {
      if (ingredient.ImageUrl && ingredient.ImageUrl.startsWith(this.baseImageUrl)) {
        return ingredient.ImageUrl;
      } else {
        if (ingredient.ImageUrl == '') {
          return this.getDeselectedFormattedURL(ingredient, true);
        } else {
          return this.baseImageUrl + ingredient.ImageUrl;
        }
      }
    } else {
      return this.getDeselectedFormattedURL(ingredient, true);
    }
  }

  private getDeselectedFormattedURL(
    modifier: ModifierV2 | ModifierIngredientV2,
    fromGetFormattedURL: boolean = false
  ): string {
    if (modifier.IsModifier) {
      const mod = modifier as ModifierV2;
      if (mod.DeselectedImage) {
        if (mod.DeselectedImage.startsWith(this.baseImageUrl)) {
          return mod.DeselectedImage;
        } else {
          return this.baseImageUrl + mod.DeselectedImage;
        }
      } else {
        return this.setSelectedImage(modifier);
      }
    } else if (modifier.IsIngredient) {
      let ing = modifier as ModifierIngredientV2;
      if (ing.DeselectImageUrl) {
        if (ing.DeselectImageUrl.startsWith(this.baseImageUrl)) {
          return ing.DeselectImageUrl;
        } else {
          return this.baseImageUrl + ing.DeselectImageUrl;
        }
      } else {
        return this.setSelectedImage(modifier);
      }
    } else {
      if (fromGetFormattedURL) {
        return this.setSelectedImage(modifier);
      } else {
        return this.getFormattedURL(modifier);
      }
    }
  }

  itemClicked() {
    if (this.IsDisableSubgroup) return;

    if (this.parent.IsModifier86 == 'False') return;

    if (this.isSoldOut) return;

    if (this.ingredient.IsIngredient) this.selectIngredient();
    else {
      if (this.isStopModiferOpening) return;
      this.stopModifierOpening();

      this.selectModifier();
    }
    return;
  }

  private setSelectedImage(modifier: ModifierV2 | ModifierIngredientV2) {
    if (modifier.ImageUrl && modifier.ImageUrl.startsWith(this.baseImageUrl)) {
      return modifier.ImageUrl;
    } else {
      if (modifier.ImageUrl == '') {
        if (this.placeholderImage != '') {
          return this.baseImageUrl + this.placeholderImage;
        } else {
          return 'assets/images/Image_21.png';
        }
      } else {
        return this.baseImageUrl + modifier.ImageUrl;
      }
    }
  }

  private selectIngredientLogic(
    parent: ModifierV2,
    IngredientIndex: number
  ): any {
    let ingredient = parent.Ingredients[IngredientIndex];

    if (!ingredient) {
      return undefined;
    }

    ingredient = <ModifierIngredientV2>ingredient;

    if (
      parent.IsModifier86 == 'False' ||
      ingredient.IsIngredient86 == 'False'
    ) {
      ingredient.IsSelected = false;
      ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);

      return false;
    } else {
      if (ingredient.IsSelected) {
        parent.count--;
        if (parent.count < 0) parent.count = 0;

        ingredient.IsSelected = false;

        ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);

        ingredient.Quantity = '1'; // To reset the quantity on deselection

        this.quantity = 1; // To reset the quantity on deselection

        return false;
      }

      parent.count = CommonFunctions.getSelectedIngredientCount(parent);

      if (parent.count < parent.max) {
        parent.count++;

        ingredient.IsSelected = true;

        ingredient.Quantity = '1';

        parent.IsSelected = true;
      } else if (parent.max === 1) {
        // if only 1 selectable switch selection to newly selected object
        parent.count = 1;
        //parent.SelectedIngredients = [];
        for (const item of parent.Ingredients) {
          item.IsSelected = false;
          if (item.IsIngredient) {
            const objIng = item as ModifierIngredientV2;
            objIng.isNegativeModifier = this.getNegativeModifier(objIng);
          }
        }

        ingredient.IsSelected = true;
        ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);
        ingredient.Quantity = '1';
        parent.IsSelected = true;
      }

      if (parent.count <= parent.max && parent.count >= parent.min) {
        parent.isValid = true;
      }

      ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);

      parent.SelectedIngredients =
        CommonFunctions.getSelectedModifierIngredientList(parent.Ingredients);
      return true;
    }
  }

  /**
   * Logic based on min max Aggregate and min max choice qty @nilesh 
   * @param parent 
   * @param IngredientIndex 
   * @returns 
   */
  private selectIngredientLogicAggregate(
    parent: ModifierV2,
    IngredientIndex: number
  ): any {
    let ingredient = parent.Ingredients[IngredientIndex];

    if (!ingredient) {
      return undefined;
    }

    ingredient = <ModifierIngredientV2>ingredient;

    if (
      parent.IsModifier86 == 'False' ||
      ingredient.IsIngredient86 == 'False'
    ) {
      //if out of stock, return and set isSelected to false
      ingredient.IsSelected = false;
      ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);

      return false;
    } else {
      if (ingredient.IsSelected) {
        parent.count--;
        if (parent.count < 0) parent.count = 0;

        parent.countAggregate = parent.countAggregate - Number(ingredient.Quantity);
        if (parent.countAggregate < 0) parent.countAggregate = 0;

        ingredient.IsSelected = false;

        ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);

        ingredient.Quantity = '1'; // To reset the quantity on deselection

        this.quantity = 1; // To reset the quantity on deselection

        return false;
      }

      parent.countAggregate = CommonFunctions.getSelectedIngredientCount(parent);
      let selectedIngredient = CommonFunctions.getOnlySelectedIngredientCount(parent);
      if ((selectedIngredient < parent.max) && ((Number(parent.countAggregate) + Number(parent.ChoiceQtyIncrement)) <= parent.MaxAggregateQty)) {

        parent.countAggregate = Number(parent.countAggregate) + Number(parent.ChoiceQtyIncrement);
        parent.count++;
        ingredient.IsSelected = true;

        ingredient.Quantity = parent.ChoiceQtyIncrement?.toString()??'1';

        parent.IsSelected = true;
      } else if (parent.max === 1) {
        // if only 1 selectable switch selection to newly selected object
        parent.countAggregate = parent.ChoiceQtyIncrement;
        parent.count = 1;
        //parent.SelectedIngredients = [];
        for (const item of parent.Ingredients) {
          item.IsSelected = false;
          if (item.IsIngredient) {
            const objIng = item as ModifierIngredientV2;
            objIng.isNegativeModifier = this.getNegativeModifier(objIng);
          }
        }

        ingredient.IsSelected = true;
        ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);
        ingredient.Quantity = parent.ChoiceQtyIncrement.toString();
        parent.IsSelected = true;
      }

      if (parent.countAggregate <= parent.MaxAggregateQty && parent.countAggregate >= parent.MinAggregateQty) {
        parent.isValid = true;
      }

      ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);
      this.quantity = Number(ingredient.Quantity);
      parent.SelectedIngredients =
        CommonFunctions.getSelectedModifierIngredientList(parent.Ingredients);
      return true;
    }
  }

  private getNegativeModifier(ingredient: ModifierIngredientV2) {
    if (!ingredient.IsSelected && ingredient.IsDefault == 'True') {
      return true;
    }

    return false;
  }

  private selectIngredient() {
    if (GeneralSetting.getParBrinkIntegrationID() != '') {
      if (this.ingredient.RefIngredientId == '') {
        let error = this.language.getTextElement('missing_ref_ingredient_id').replace('%d', this.ingredient.Name)
        Snackbar.show(error, 5000);
        return;
      }
    }
    //ADMIN2-FIX
    if ((!this.parent.UseAdvancedModifierQuantitySettings)||this.parent.UseAdvancedModifierQuantitySettings == 'False') {
      this.selectIngredientLogic(this.parent, this.ingredientIndex);
    } else {
      this.selectIngredientLogicAggregate(this.parent, this.ingredientIndex);
    }


    let ingredient = this.parent.Ingredients[this.ingredientIndex];

    this.image = this.getFormattedURL(ingredient);

    this.parentChange.emit(this.parent);
  }

  private isStopModiferOpening: boolean = false;
  private async stopModifierOpening() {
    this.isStopModiferOpening = true;
    await CommonFunctions.delay(500);
    this.isStopModiferOpening = false;
  }

  private selectModifier() {

    let modifier = this.parent.Ingredients[this.ingredientIndex];

    if (!modifier) return;

    modifier = <ModifierV2>modifier;

    if (!modifier.IsSelected) {
      modifier.Quantity = '1';
    }

    if (this.parent.IsModifier86 == 'False' || modifier.IsModifier86 == 'False')
      return;

    if (this.parent.max > 1 && !modifier.IsSelected) {
      if (this.parent.UseAdvancedModifierQuantitySettings == 'False') {
        this.parent.count = CommonFunctions.getSelectedIngredientCount(this.parent);
      } else {
        this.parent.count = CommonFunctions.getOnlySelectedIngredientCount(this.parent);
      }


      if (!(this.parent.count < this.parent.max || this.parent.max == 1))
        return;
    }

    this.openNestedModifier(modifier);
  }

  private async openNestedModifier(modifier: ModifierV2) {

    let parentModifierIngredient = [] as (ModifierIngredientV2 | ModifierV2)[];

    if (!modifier.Ingredients || modifier.Ingredients.length <= 0) {
      let nestedModifiers = await this.db.getChildModifierByItemAndModifierID(
        modifier.ItemID,
        modifier.ModifierID
      );

      let Ingredient = await this.db.getIngredientsByModIDFromSql(
        modifier.ModifierID
      );

      parentModifierIngredient.push(...nestedModifiers);
      parentModifierIngredient.push(...Ingredient);

      parentModifierIngredient = parentModifierIngredient.sort(
        (a: any, b: any) =>
          Number(a.DisplayOrder) > Number(b.DisplayOrder) ? 1 : -1
      );

      modifier.Ingredients = parentModifierIngredient;
    } else {
      parentModifierIngredient = modifier.Ingredients;
    }


    let skipNestedStep = true;

    if (GeneralSetting.getShowNestedModifierInSingleView()) {
      this.ItemType = 1;
    }

    if (parentModifierIngredient && parentModifierIngredient.length > 0) {
      for (let i = 0; i < parentModifierIngredient.length; i++) {
        if (parentModifierIngredient[i].IsIngredient) {
          skipNestedStep = false;
          break;
        }
      }

      if (this.ItemType == 2) {
        if (skipNestedStep && (parentModifierIngredient.length != 1)) {
          skipNestedStep = false;
        }
      }
      else if (this.ItemType == 1) {
        if (GeneralSetting.getShowNestedModifierInSingleView()) {
          if (!(skipNestedStep && modifier.max >= parentModifierIngredient.length && modifier.min == 0)) {
            skipNestedStep = false;
          }
        }
        else {
          skipNestedStep = false;
        }
      }
    }


    var parentModifier = modifier;
    let tempParentModifier = JSON.parse(JSON.stringify(parentModifier));

    const modalReference = NestedModifierComponent.open(this.modalService,
      skipNestedStep
        ? (modifier.Ingredients as ModifierV2[])
        : [modifier],
      this.ingredientIndex,
      parentModifier,
      this.ItemType,
      this.ItemName,
      this.ItemDescription
    );

    modalReference.result.then((result: any) => {
      if (result == 'cancel') {
        // const modifier = this.parent.Ingredients[this.ingredientIndex];

        // if (!modifier.IsSelected) {
        //   modifier.IsSelected = false;
        // }

        // this.quantity = Number(modifier.Quantity);
        // this.parentChange.emit(this.parent);

        this.parent.Ingredients[this.ingredientIndex] = tempParentModifier;
      } else {
        result = <ModifierV2[]>result;

        if (skipNestedStep) {
          //parentModifier.IsSelected = true;

          //parentModifier.Quantity = "1";

          parentModifier.Ingredients = result;

          // for (let i = 0; i < parentModifier.Ingredients.length; i++) {

          //   parentModifier = this.selectModifierLogic(parentModifier, i);

          // }
        } else {
          if (result.length > 0) {
            const modifier = result[0];

            this.quantity = Number(modifier.Quantity);

            this.parent.Ingredients[this.ingredientIndex] = modifier;
          }
        }

        this.parent = CommonFunctions.selectModifierLogic(
          this.parent,
          this.ingredientIndex
        );

        if (this.parent.UseAdvancedModifierQuantitySettings == 'False') {
          this.parent.count = CommonFunctions.getSelectedIngredientCount(this.parent);
        } else {
          this.parent.count = CommonFunctions.getOnlySelectedIngredientCount(this.parent);
          this.parent.countAggregate = CommonFunctions.getSelectedIngredientCount(this.parent);
        }


        this.image = this.getFormattedURL(this.parent.Ingredients[this.ingredientIndex]);

        this.parentChange.emit(this.parent);
      }
    });
  }

  onCloseClick(e: any) {
    let modifier = this.parent.Ingredients[this.ingredientIndex];

    if (!modifier) return false;

    if (modifier.IsIngredient) {
      return false;
    }

    e.preventDefault();
    e.stopPropagation();

    modifier = <ModifierV2>modifier;

    modifier.IsSelected = false;

    modifier.isValid = false;

    this.deSelectAllNestedIngredient(modifier);

    modifier.count = 0;
    modifier.countAggregate = 0;

    if (this.parent.UseAdvancedModifierQuantitySettings == 'False') {
      this.parent.count = CommonFunctions.getSelectedIngredientCount(this.parent);
    } else {
      this.parent.count = CommonFunctions.getOnlySelectedIngredientCount(this.parent);
      this.parent.countAggregate = CommonFunctions.getSelectedIngredientCount(this.parent);
    }

    this.parentChange.emit(this.parent);

    return false;
  }

  private deSelectAllNestedIngredient(modifier: ModifierV2) {
    for (let i = 0; i < modifier.Ingredients.length; i++) {
      const ingredient: ModifierIngredientV2 | ModifierV2 =
        modifier.Ingredients[i];

      if (ingredient.IsModifier) {
        const mod = ingredient as ModifierV2;
        this.deSelectAllNestedIngredient(mod);
      } else if (ingredient.IsIngredient) {
        let ing = ingredient as ModifierIngredientV2;
        ingredient.IsSelected =
          ing.IsDefault == 'True' && ing.IsDefault != undefined;
        ingredient.Quantity = '1';
      }
    }
  }

  private setIngredientPriceHelper() {
    let price = 0;
    if (this.ingredient.ExtraPrice > 0) {
      price = Number(this.ingredient.ExtraPrice) * this.quantity;
    } else if (
      this.ingredient != undefined &&
      this.ingredient.hasOwnProperty('IsModifier86') &&
      this.ingredient.Price > 0
    ) {
      price = Number(this.ingredient.Price) * this.quantity;
    }

    let extraPrice: string;

    if (price > 0)
      extraPrice =
        GeneralSetting.getCurrencySymbol() +
        ' ' +
        CommonFunctions.roundDigit(price, 2).toFixed(2);
    else extraPrice = this.noChargeText;

    this.Price = extraPrice;
  }

  private setIngredientPrice() {
    let price = 0;
    if (this.isParbrink) {
      if (this.ingredient.IsDefault == 'True') {
        this.Price = this.noChargeText;
      } else {
        this.setIngredientPriceHelper();
      }
    } else {
      this.setIngredientPriceHelper();
    }
  }

  onQuantityIncrease(e: any) {
    e.preventDefault();
    e.stopPropagation();

    if (this.parent.UseAdvancedModifierQuantitySettings == 'False') {
      const max = this.parent.max;
      const count = CommonFunctions.getSelectedIngredientCount(this.parent);

      if (count == max) {
        return;
      }

      if (this.parent.Ingredients[this.ingredientIndex].IsIngredient) {
        const maxIngredient = Number(
          (this.parent.Ingredients[this.ingredientIndex] as ModifierIngredientV2)
            .MaxQty
        );
        if (this.quantity >= maxIngredient) return;

        this.quantity += 1;
      }
    } else {
      const maxAggregate = this.parent.MaxAggregateQty;
      const count = this.parent.countAggregate;;

      if (count == maxAggregate) {
        return;
      }

      const maxChoiceQty = Number(this.parent.MaxChoiceQty);
      if (this.quantity >= maxChoiceQty) return;

      if (Number(this.parent.ChoiceQtyIncrement) <= (this.parent.MaxAggregateQty - this.parent.countAggregate))
        this.quantity += Number(this.parent.ChoiceQtyIncrement);
      else
        this.quantity += (this.parent.MaxAggregateQty - this.parent.countAggregate);
    }

    // this.quantity += 1;

    this.parent.Ingredients[this.ingredientIndex].Quantity =
      this.quantity.toString();

    this.setIngredientPrice();

    if (this.parent.UseAdvancedModifierQuantitySettings == 'False') {
      this.parent.count = CommonFunctions.getSelectedIngredientCount(this.parent);
    } else {
      this.parent.count = CommonFunctions.getOnlySelectedIngredientCount(this.parent);
      this.parent.countAggregate = CommonFunctions.getSelectedIngredientCount(this.parent);
    }

    this.parentChange.emit(this.parent);
  }

  onQuantityDecrease(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (this.parent.UseAdvancedModifierQuantitySettings == 'False') {
      if (this.quantity == 1) {
        this.ingredient.IsSelected = false;

        this.ingredient.isNegativeModifier = this.getNegativeModifier(
          this.ingredient
        );

        this.parent.count = CommonFunctions.getSelectedIngredientCount(
          this.parent
        );

        this.parentChange.emit(this.parent);

        return;
      }

      this.quantity -= 1;
    } else {
      if (this.quantity <= Number(this.parent.ChoiceQtyIncrement)) {
        this.ingredient.IsSelected = false;

        this.ingredient.isNegativeModifier = this.getNegativeModifier(
          this.ingredient
        );

        this.parent.count = CommonFunctions.getSelectedIngredientCount(
          this.parent
        );
        this.parent.countAggregate = CommonFunctions.getSelectedIngredientCount(
          this.parent
        );
        this.parentChange.emit(this.parent);
        return;
      }

      if (Number(this.parent.ChoiceQtyIncrement) <= this.parent.countAggregate)
        this.quantity -= Number(this.parent.ChoiceQtyIncrement);
      else
        this.quantity -= this.parent.countAggregate;
    }


    // this.quantity -= 1;

    this.parent.Ingredients[this.ingredientIndex].Quantity =
      this.quantity.toString();

    this.setIngredientPrice();
    this.parent.UseAdvancedModifierQuantitySettings == 'False'
      ? this.parent.count = CommonFunctions.getSelectedIngredientCount(this.parent)
      : this.parent.countAggregate = CommonFunctions.getSelectedIngredientCount(this.parent);

    this.parentChange.emit(this.parent);
  }

  checkIsShowNoCharge() {
    const priceIngredient = (this.parent as any).Ingredients.find(
      (x: any) => Number(x.ExtraPrice) > 0 || Number(x.Price)
    );

    if (priceIngredient) {
      this.isShowNoCharge = true;
    } else {
      this.isShowNoCharge = false;
    }
  }
}
