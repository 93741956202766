import { eatOption } from './../models/eat-option';
import { Injectable } from '@angular/core';
import { PaymentService } from './payment.service';
import { OLOService } from './olo.service';
import { LoyaltyService } from './loyalty.service';
import {
  customerVisitRes,
  LevelUpObject, 
  ReferrizerLoyaltyRewardDetail,
  RefLoyaltyCustomer,
} from '../models/loyaltyModal';
import { UserService } from './user.service';
import { CartService } from './cart.service';
import { CodelessDiscount, FixedItemDiscount } from '../models/discounts';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { formatDate } from '@angular/common';
import { TipService } from 'src/app/services/tip.service';
import { Tip } from '../models/tip';
import { OLOResponse } from '../models/OLOResponse';
import { VerifoneService } from './verifone.service';
import { OracleService } from './oracle.service';
import { ParBrinkService } from './par-brink.service';
import { GeneratedFile } from '@angular/compiler';
import { LastfiveorderService } from './lastfiveorder.service';
import { ConcessionaireService } from '../components/menu/conc-service/concessionaire.service';
import { ConcessionaireFilterTypes } from '../models/concessionaireTypes';
import { ItemTagFilterMasters } from '../models/ItemTagFilterMaster';
import { DiscountService } from './discount.service';
import { CloudOrderService } from './cloud-order.service';
import { PusherService } from './pusher.service';

@Injectable({
  providedIn: 'root',
})
export class SetDefaultService {

  constructor(
    private readonly paymentService: PaymentService,
    private readonly olo: OLOService,
    private readonly loyaltyService: LoyaltyService,
    private readonly userService: UserService,
    private cartService: CartService,
    private tipService: TipService,
    private verifoneService: VerifoneService,
    private oracle: OracleService,
    private parBrink: ParBrinkService,
    private last5: LastfiveorderService,
    private readonly conService: ConcessionaireService,
    private readonly cloudOrderService: CloudOrderService,
    private readonly discountService: DiscountService,
  ) { }


  redirectToEatOptions: boolean = false;

  optionsLength: number = 0;

  setDefault() {
    this.setTouchToStartData();
    this.setPaymentServiceDefaultData();
    this.setCartServiceDefaultData();
    this.setParBrinkDefaults();
    this.setLoyaltyServiceDefault();
    this.setTipServiceDefault();
    this.resetOrderInvoiceNo();
    this.setOracleDefault();
    this.setLastFiveDefault();
    this.setConcessionaireServiceDefault();
    this.setDiscountServiceDefaultData();
    this.cloudOrderService.RestartOrder();

    this.cartService.orderSplitBill = [];
    this.olo.getOloIntegrationData().toPromise().then((data) => {
      if (data == null || data == undefined || data.length <= 0) {
        this.olo.isOLO = false;
        return;
      }
      this.olo.oloIntegrationTableData = data[0];
      this.olo.isOLO = !(
        this.olo.oloIntegrationTableData.vendorID == '' ||
        this.olo.oloIntegrationTableData.vendorID == null
      );
    });

    GeneralSetting.setUserHasPaid('False');

    GeneralSetting.setRedirectToMain('False');

    GeneralSetting.setIsItemTableTentAdded(false);
    GeneralSetting.removePaymentMethod()
    this.olo.validationRes = {} as OLOResponse;
    if (GeneralSetting.getVerifonePosIp() != ""
      && GeneralSetting.getVerifoneMacLabel() != ""
      && GeneralSetting.getVerifoneMacKey() != ""
      && GeneralSetting.getVerifoneDecryptedMessage() != "") {
      this.verifoneService.doConnectVerifoneDevice();
    }
    GeneralSetting.setIsAllowForMarketingCommunication('');
  }

  setLastFiveDefault() {
    this.last5.clearhistoryData()
  }

  resetOrderInvoiceNo() {
    const currentTime = formatDate(Date.now(), 'HH:mm:ss', 'en-US');
    if (currentTime > '23:59:00') {
      GeneralSetting.setOrderInvoiceNo('0');
    }
  }

  private setOracleDefault() {
    this.oracle.calculationResponse = null;
    //this.oracle.cardNetWordDetails = [];
  }

  private setTouchToStartData() {

    GeneralSetting.setCurrentCountofAlkol('0');

    GeneralSetting.setHasUpSold('false');

    GeneralSetting.setCustomerName('');

    GeneralSetting.setCustomerEmail('');

    GeneralSetting.setPaymentResponse('');

    GeneralSetting.setHasUpSold('');

    GeneralSetting.setIsVerifoneOfflineOrder("false");

    GeneralSetting.setAcloholicVerify('false');

    GeneralSetting.setThirdPartyOrderId('');

    GeneralSetting.removeFromMenuLogin();

    GeneralSetting.removeCustomerLoginEmail();

    GeneralSetting.removeCustomerLoginMobile();

    GeneralSetting.removeCustomerLoginName();

    GeneralSetting.removeCustomerId();

    GeneralSetting.removeOrderTypeId();

    GeneralSetting.setMemberId("");
  }

  private setPaymentServiceDefaultData() {
    this.paymentService.orderInvoiceNo = '';
    this.paymentService.CustomerEmail = '';
    this.paymentService.CustomerName = 'Guest';
    this.paymentService.CustomerID = '0';
    this.paymentService.CustomerMobileNo = '0000000000';
    this.paymentService.DiscountAmount = 0;
    this.paymentService.DiscountPer = 0;
    this.paymentService.DiscountTypeId = '0';
    this.paymentService.ExtraCharge = 0;
    this.paymentService.IsDiscount = false;
    this.paymentService.OrderTotal = 0;
    this.paymentService.OrderType = 0;
    this.paymentService.otherCharges = 0
    this.paymentService.SubTotal = 0;
    this.paymentService.TaxPer = 0;
    this.paymentService.TotalTax = 0;
    this.paymentService.TotalTip = 0;
    this.paymentService.PercentageOrAmounCard = 0;
    this.paymentService.UpChargeAmountCard = 0;
    this.paymentService.PercentageOrFlatRate = '0';
    this.paymentService.PercentageOrFlatRateVal = '0';
  }

  private setCartServiceDefaultData() {
    this.cartService.didDiscount = false;
    this.cartService.setPromotion(0, true, false);
    this.cartService.tax = 0;
    this.cartService.tip = 0;
    this.cartService.subtotal = 0;
    this.cartService.discount = 0;
    this.cartService.reward = 0;
    this.cartService.total = 0;
    this.cartService.shippingFee = 0;
    this.cartService.cloverTip = 0;
    this.cartService.cloverCardTotal = 0;
    this.cartService.setPromotion(0, false, false);
    this.cartService.promo = {} as CodelessDiscount;
    this.cartService.ApplyedConcessionaireDiscountIds = [];
    this.cartService.conDetailSub.next([]);
    GeneralSetting.setDidDiscount('false');
    this.cartService.clearCart();
  }

  private setLoyaltyServiceDefault() {
    this.loyaltyService.itemsWaiting = false
    this.loyaltyService.chosenItemList = []
    this.loyaltyService.punchhAppliedRewardID = ''
    this.loyaltyService.oloPaytronixAppliedRewardID = ''
    this.loyaltyService.subSelectedLoyaltyReward.next('');
    this.loyaltyService.selectedLoyaltyReward = '';
    this.loyaltyService.customerVisitRes = {} as customerVisitRes;
    this.loyaltyService.loyaltyRewards = [] as ReferrizerLoyaltyRewardDetail[];
    this.loyaltyService.levelUpObject = {} as LevelUpObject;
    this.userService.signOut();

    try {
      GeneralSetting.removeLastFiveOrderChosen()
      GeneralSetting.removeCustomerId();
      GeneralSetting.removeCustomerLoginEmail();
      GeneralSetting.removeCustomerLoginMobile();
      GeneralSetting.removeCustomerLoginName();
      GeneralSetting.removeCustomerPhone();
    } catch (ex) { }
  }

  private setTipServiceDefault() {
    this.tipService.selectedTip = {} as Tip;
    this.tipService.isNoTipSelected = 'false';
    GeneralSetting.setIsTipSelected('false');
  }

  private setParBrinkDefaults() {
    this.parBrink.edited = false;
    this.parBrink.zeroOrder = false;
    this.parBrink.SubTotal = 0;
    this.parBrink.Total = 0;
    this.parBrink.Tax = 0;
  }

  private setConcessionaireServiceDefault() {
    this.conService.concessionaireFilterTypes = [] as ConcessionaireFilterTypes[];
    this.conService.selectedConcessionaireTypes = [] as string[];
    this.conService.itemTagsAndGroupFilters = [] as ItemTagFilterMasters[];
    this.conService.selectedItemTags = [] as string[];
  }

  private setDiscountServiceDefaultData() {
    this.discountService.fixedItemDisc = [] as FixedItemDiscount[];
  }
}
