<div
  class="optionBox position-relative"
  (click)="Select()"
  [ngClass]="{ selected: isSelected }"
  [ngStyle]="{ 'border-color': isSelected ? primaryColor : 'white' }"
>
  <div *ngIf="isBuilderMode; else classic">
    <div class="optionData">
      <div class="name">
        {{ variationOption?.OptionName }}
      </div>
      <img
        src="{{ variationOption?.ImageUrl && variationOption?.ImageUrl !='' ?baseImageUrl + variationOption?.ImageUrl : placeholderImage !='' ? baseImageUrl+placeholderImage : 'assets/images/Image_21.png'}}"
        onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
        [ngStyle]="{
          'border-radius': bordersTypeVariation ? imageRadius : '0%'
        }"
      />
      <div class="optionText">
        <div *ngIf="showCalories" class="calories medium">
          <em>{{
            variationOption.Calories != ""
              ? variationOption.Calories + calorieText
              : ""
          }}</em>
        </div>
        <div
          [ngStyle]="{ color: priceColor }"
          class="price"
          *ngIf="!isRemovePricingAndPayments"
        >
          {{
            variationOption.Price != "0.00"
              ? "+ " + (variationOption?.Price | CurrencyPipe)
              : ""
          }}
        </div>
      </div>
    </div>
  </div>
  <ng-template #classic>
    <div class="optionData">
      <img
        src="{{ variationOption?.ImageUrl && variationOption?.ImageUrl != '' ? baseImageUrl + variationOption?.ImageUrl : placeholderImage !='' ? baseImageUrl+placeholderImage : 'assets/images/Image_21.png'}}"
        onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
        [ngStyle]="{
          'border-radius': bordersTypeVariation ? imageRadius : '0%'
        }"
      />
      <div class="name">
        {{ variationOption?.OptionName }}
      </div>
      <div class="optionText">
        <div *ngIf="showCalories" class="calories medium">
          <em>{{
            variationOption.Calories != ""
              ? variationOption.Calories + calorieText
              : ""
          }}</em>
        </div>
        <div
          [ngStyle]="{ color: priceColor }"
          class="price"
          *ngIf="!isRemovePricingAndPayments"
        >
          {{
            variationOption.Price != "0.00"
              ? "+ " + (variationOption?.Price | CurrencyPipe)
              : ""
          }}
        </div>
      </div>
    </div>
  </ng-template>
</div>
