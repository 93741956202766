<div id="upsell-message">
    <div class="header">
        {{customItemText}}
    </div>
    <div class="caption">
        {{wantToCustomizeText}}
    </div>

    <div class="btns">
        <button class='no-btn' (click)="close()">{{noThanksText}}</button>
        <button class='yes-btn  background-positive-button-color positive-button-text-color' (click)="continue()">{{yesPleaseText}}</button>
    </div>
</div>
