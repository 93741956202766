import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/services/cart.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { LanguageService } from 'src/app/services/language.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import {
  SMBDiscount,
  SMBDiscountService,
} from 'src/app/services/smb-discount.service';
import { SMBDiscountsAlertComponent } from '../../dialogs/smb-discounts-alert/smb-discounts-alert.component';
import { SMBDiscountsCodeComponent } from '../../dialogs/smb-discounts-code/smb-discounts-code.component';

@Component({
  selector: 'app-smb-discounts',
  templateUrl: './smb-discounts.component.html',
  styleUrls: ['./smb-discounts.component.css'],
})
export class SMBDiscountsComponent implements OnInit, OnDestroy {
  discounts: SMBDiscount[] = [];
  allSubscription: Subscription[] = [];
  subtotal: number = 0;
  discountCode: string = '';
  message: string = '';
  couponCodeText: string = '';
  discountAppliedText: string = '';
  removeDiscountText: string = '';
  languageSub!:Subscription
  constructor(
    private discountService: SMBDiscountService,
    private modal: NgbModal,
    public cart: CartService,
    private KeyboardService: KeyboardService,
    private language:LanguageService,
    private logger: LogService,
  ) {}
  loadText(){
    this.couponCodeText = this.language.getTextElement('txt_coupon_code');
    this.discountAppliedText = this.language.getTextElement('discount_successful');
    this.removeDiscountText = this.language.getTextElement('remove_discount');
  }
  ngOnDestroy(): void {
    this.allSubscription.forEach((x) => x.unsubscribe());
    if(this.languageSub){
      this.languageSub.unsubscribe()
    }
  }
  doit() {
    this.KeyboardService.manual = true;
    this.KeyboardService.maxLength = 50;
    this.KeyboardService.clearSub.next();
    this.KeyboardService.open('shift');
    this.KeyboardService.stringSub.next();
    this.allSubscription.push(
      this.KeyboardService.stringSub.subscribe((val) => {
        this.discountCode = val;
      })
    );
  }
  getDiscount() {
    return this.cart.selectedDiscount
  }
  getDiscountCode(){
    return this.discountCode
  }
  ngOnInit(): void {
    this.discounts = this.discountService.discounts;
    this.languageSub=this.language.localeCommunicator.subscribe((val)=>{
      this.loadText()
    })
    this.allSubscription.push(
      this.cart.sub_subtotal.subscribe((val) => {
        this.subtotal = val;
      })
    );
  }
  public openDiscountAlertDialog(x:string) {
    return new Promise<void>((resolve, reject) => {
      let pec = SMBDiscountsAlertComponent.open(this.modal,x);
      pec.result.then(
        (data: any) => {
          resolve(data,);
        },
        (e: any) => {
          console.log(e);
        }
      );
    });
  }
  public openDiscountCodeDialog(discount: SMBDiscount) {
    return new Promise<void>((resolve, reject) => {
      let pec = SMBDiscountsCodeComponent.open(
        this.modal,
        this.KeyboardService,
        discount,
        this.discountCode
      );
      this.doit();
      pec.result.then(
        (data: any) => {
          if (data) {
            this.openDiscountAlertDialog(this.message);
          } else {
            this.cart.selectedDiscount = discount;
          }
          resolve(data);
        },
        (e: any) => {
          reject(e);
        }
      );
    });
  }
  public openDiscountCodeDialogIter3() {
    return new Promise<void>((resolve, reject) => {
      let pec = SMBDiscountsCodeComponent.openIter3(
        this.modal,
        this.KeyboardService,
      );
      this.doit();
      pec.result.then(
        (code: any) => {
          if (code) {
            for(let i=0;i<this.discounts.length;i++){
              if(this.discounts[i].CouponCode.toLocaleLowerCase() === code.toLocaleLowerCase()){
                if (this.discounts[i].UsePercentage == 'False' && Number(this.discounts[i].DiscountAmount) >= this.subtotal ) {
                  this.message=this.language.getTextElement('txt_coupon_code_not_applied')
                  var log = new loggingData(
                    `Order details: Discounts Applied`,
                    `Order details: Discounts Applied`,
                    `smb-discounts.component.ts`,
                    `Order details: Discounts Applied -  ${code}`,
                    true
                  );
                  this.logger.sendLogToServer(log);
                  this.openDiscountAlertDialog(this.message)
                }else{
                  this.cart.selectedDiscount = this.discounts[i]
                }
                break
              }
              if(i===this.discounts.length-1){
                var log = new loggingData(
                  `Order details: Discounts Invalid`,
                  `Order details: Discounts Invalid`,
                  `smb-discounts.component.ts`,
                  `Order details: Discounts Invalid -  ${code}`,
                  true
                );
                this.logger.sendLogToServer(log);
                this.message=this.language.getTextElement('txt_invalid_coupon_code')
                this.openDiscountAlertDialog(this.message);
              }
            }
          }
          resolve(code);
        },
        (e: any) => {
          reject(e);
        }
      );
    });
  }
  selectDiscount2(event: SMBDiscount): void {
    if (this.cart.selectedDiscount === undefined) {
      //user does not have an applied discount
      if (
        event.UsePercentage == 'False' &&
        Number(event.DiscountAmount) >= this.subtotal
      ) {
        this.openDiscountAlertDialog(this.message)
        //selected discount is a $ discount > subtotal
        // show modal to user alerting the user that the discount cannot be applied due to the subtotal.
        return;
      } else {
        //selected discount is applicable
        this.openDiscountCodeDialog(event).then(
          (data: any) => {
            //console.log(data)
          },
          (err: any) => {
            console.log(err)
          }
        )
        //a modal is opened to accept user input for coupon code
      }
    } else {
      //user does have an applied discount
      if (
        event.UsePercentage == 'False' &&
        Number(event.DiscountAmount) >= this.subtotal
      ) {
        this.openDiscountAlertDialog(this.message);
        //selected discount is a $ discount > subtotal
        // show modal to user alerting the user that the discount cannot be applied due to the subtotal.
        return;
      } else {
        //selected discount is applicable
        //current discount is removed
        if (event === this.cart.selectedDiscount) {
          this.cart.selectedDiscount = undefined;
        } else {
          //a modal is opened to accept user input for coupon code
          this.cart.selectedDiscount = undefined;
          this.openDiscountCodeDialog(event).then(
            (data: any) => {
              //console.log(data)
            },
            (err: any) => {
              console.log(err)
            }
          );
        }
      }
    }
  }
  selectDiscountIter3(){
    if (this.cart.selectedDiscount === undefined) {
      //user does not have an applied discount     
        //selected discount is applicable
        this.openDiscountCodeDialogIter3().then(
          (data: any) => {
            //console.log(data)
            // this.cart.selectedDiscount = this.selectedDiscount;
          },
          (err: any) => {
            console.log(err)
          }
        )
        //a modal is opened to accept user input for coupon code      
    } else{
      this.cart.selectedDiscount = undefined
    }
  }
}
