import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {parse, stringify} from 'flatted';
import { DatabaseHandler } from '../DatabaseHandler';
import { usConnectCreds } from '../models/usconnectCreds';
import { GeneralSetting } from 'src/app/services/generalSetting.service';

@Injectable({
  providedIn: 'root'
})
export class UsconnectService {

  constructor(private http: HttpClient) { }

  getUSConnectCredentials(): Observable<any> {
    return from(this.getUSConnectCredentialsFromSql());
  }

  getUSConnectCredentialsFromSql(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let query = `SELECT * FROM USConnectMasters`;

      function callback(tx: string, data: any) {
        if(data.rows.length > 0) {
          // var results: usConnectCreds = ;
          resolve(Array.from(data.rows));
        } else {
          resolve("");
        }

      }

      function errorCallback(tx: string, data: any) {
        reject(data);
      }
      DatabaseHandler.executeSqlStatement(query, [], callback, errorCallback);
    });
  }

  getUSConnectSerial(deviceId: string): Observable<string> {
    return from(this.getUSConnectSerialFromSql(deviceId));
  }

  getUSConnectSerialFromSql(deviceId: string): Promise<string> {
    return new Promise<any>((resolve, reject) => {
      let query = `SELECT USConnectDeviceSerialSuffix FROM StaffModuleMasters WHERE DeviceID = '${deviceId}'`;

      function callback(tx: string, data: any) {
        if(data.rows.length > 0) {
          resolve(data.rows[0]);
        } else {
          resolve("");
        }

      }

      function errorCallback(tx: string, data: any) {
        reject(data);
      }
      DatabaseHandler.executeSqlStatement(query, [], callback, errorCallback);
    });
  }


  getAccountBalance(creds: any, obj: any) {
    let body = {
      creds: creds,
      cardNo: obj
    };

    //console.log(body)

    var headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post('http://localhost:4200/proxy-usconnect', JSON.stringify(body), {
        headers,
        responseType: 'text',
      })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message);
  }



  doTransaction(creds: any, serial: string, cardNo: string, items: any, tip: number) {

    const replacerFunc = () => {
      const visited = new WeakSet();
      return (key: any, value: any) => {
        if (typeof value === "object" && value !== null) {
          if (visited.has(value)) {
            return;
          }
          visited.add(value);
        }
        return value;
      };
    };
    // let itemsArray = [];

    // for(var i = 0; i < items.length; i++) {
    //   itemsArray.push({
    //         "barcode": items[i].ItemID,
    //         "coil_name": null,
    //         "deposit": 0.00,
    //         "description": items[i].ItemName,
    //         "price": items[i].Price,
    //         "tax": items[i].TotalTax,
    //         "quantity": items[i].Quantity
    //     })
    // }

    const body = {
      cardNo: cardNo,
      items: items,
      creds: creds,
      serial: serial,
      branchName: GeneralSetting.getBranchName(),
      tip: tip
      // itemsArray: itemsArray
    };

    // var newBody = stringify(body);

    var newBody = JSON.stringify(body, replacerFunc());

    //console.log(newBody)

    var headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json');

    return this.http
      .post('http://localhost:4200/payment-usconnect', newBody, {
        headers,
        responseType: 'text',
      })
      .pipe(catchError(this.errorHandler));
  }

}
