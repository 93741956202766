import {
  state,
  trigger,
  style,
  animate,
  transition,
  query,
  stagger,
  group,
  sequence,
  keyframes,
  animateChild,
  AnimationMetadataType,
} from '@angular/animations';
// drop 100px
let categoryAnim = trigger('categoryAnim', [
  state('void', style({ opacity: 0 })),
  transition('void => drop', [animate('1s')]),
]);
let drop = trigger('drop', [
  state('void', style({ transform: 'translateY(-100%)' })),
  transition(':enter', [animate('1s')]),
]);
// cart items sliding animation
let cartSlideIn = trigger('cartSlideIn', [
  transition(':enter', [
    // when each individial item enters
    query(
      ':enter',
      [style({ opacity: 0 }), stagger('200ms', [animate('500ms')])],
      { optional: true }
    ),
  ]),
]);
// simple fade animation
let fade = trigger('fade', [
  state('void', style({ opacity: 0 })),
  transition(':enter', [animate(1000)]),
]);
let pulse = trigger('pulse', [
  state('inactive', style({ opacity: 0 })),
  state('active', style({ opacity: 1 })),
  transition('* <=> *', [animate(2000)]),
]);
// staggering fade animation for order review
let fadeInGrow = trigger('fadeInGrow', [
  transition(':enter', [
    query(
      ':enter',
      [style({ opacity: 0 }), stagger('200ms', [animate('500ms')])],
      { optional: true }
    ),
  ]),
]);

let modifierFade = trigger('modifierFade', [
  state('ready', style({})),
  transition('* => *', [
    query(
      ':enter',
      [style({ opacity: 0 }), stagger('100ms', [animate('300ms')])],
      { optional: true }
    ),
  ]),
]);

let modifierSlideStagger = trigger('modifierSlideStagger', [
  transition('* <=> *', [
    query(':enter', style({ opacity: 0 }), { optional: true }),
    query(
      ':enter',
      stagger('300ms', [
        animate(
          '.5s ease-in',
          keyframes([
            style({ opacity: 0, transform: 'translateX(-50%)', offset: 0 }),
            style({
              opacity: 0.5,
              transform: 'translateX(-10px) scale(1.1)',
              offset: 0.3,
            }),
            style({ opacity: 1, transform: 'translateX(0)', offset: 1 }),
          ])
        ),
      ]),
      { optional: true }
    ),
  ]),
]);


let buttonAnim = trigger('buttonAnim', [
  state(
    'void',
    style({
      background: 'linear-gradient(to right, red 50%, green 50%)',
      transition: 'all 0.3s ease-out',
      backgroundPosition: 'right bottom',
      display: 'block',
      backgroundSize: '200% 100%',
    })
  ),
  state(
    'active',
    style({
      backgroundPosition: 'left bottom',
    })
  ),
  state(
    'active2',
    style({
      backgroundPosition: 'left bottom',
    })
  ),
  transition('* <=> *', [animate('1s')]),
]);

// make order here text bigger and smaller continuosly to grab customers attentions
let order = trigger('order', [
  state('inactive', style({ fontSize: 100 })),
  state('active', style({ fontSize: 150 })),
  state('void', style({ fontSize: 150 })),
  transition('* <=> *', [animate('1s')]),
]);
// slide in for modifier items
let modifierSlide = trigger('modifierSlide', [
  state('void', style({ transform: 'translateX(-100%)' })),
  transition('void <=> *', [
    // use key frames to make a bouncing effect
    animate(
      '1.5s',
      keyframes([
        style({ transform: 'translateX(-100%)', offset: 0.6 }),
        style({ transform: 'translateX(30%)', offset: 0.8 }),
        style({ transform: 'translateX(0)', offset: 1 }),
      ])
    ),
  ]),
]);

let modifiers = trigger('modifiers', [
  transition(':enter', [
    query('@*', [style({ opacity: 0 }), stagger('200ms', [animate('500ms')])], {
      optional: true,
    }),
  ]),
]);

function leftSlide() {
  var data: any[] = [];
  data.push(
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ],
      { optional: true }
    ),
    query(':enter', [style({ left: '100%' })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate('300ms ease-out', style({ left: '-100%' }))], {
        optional: true,
      }),
      query(':enter', [animate('300ms ease-out', style({ left: '0%' }))], {
        optional: true,
      }),
    ]),
    query(':enter', animateChild(), { optional: true })
  );

  return data;
}

function rightSlide() {
  var data: any[] = [];
  data.push(
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ],
      { optional: true }
    ),
    query(':enter', [style({ left: '-100%' })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate('300ms ease-out', style({ left: '100%' }))], {
        optional: true,
      }),
      query(':enter', [animate('300ms ease-out', style({ left: '0%' }))], {
        optional: true,
      }),
    ]),
    query(':enter', animateChild(), { optional: true })
  );

  return data;
}

function routefade() {
  var data: any[] = [];
  data.push(
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ],
      { optional: true }
    ),
    query(':enter', [style({ opacity: 0 })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate('1000ms ease-out', style({ opacity: 0 }))], {
        optional: true,
      }),
      query(':enter', [animate('1000ms ease-out', style({ opacity: 1 }))], {
        optional: true,
      }),
    ]),
    query(':enter', animateChild(), { optional: true })
  );

  return data;
}

let slideInAnimation = trigger('routeAnimations', [
  // transition('managerPasscode => touchToStart', []),
  // transition('* => branchLogin', rightSlide()),
  // transition('settings => managerPasscode', rightSlide()),
  // transition('* => touchToStart', routefade()),
  // transition('touchToStart => menu', []),
  // transition('* => menu', rightSlide()),
  // transition('* <=> *', leftSlide()),
]);

export {
  categoryAnim,
  fade,
  cartSlideIn,
  pulse,
  order,
  fadeInGrow,
  modifierSlide,
  drop,
  // positiveButtonChange,
  // negativeButtonChange,
  modifiers,
  modifierFade,
  buttonAnim,
  modifierSlideStagger,
  slideInAnimation,
};
