import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { ConcessionaireDeviceMapping } from '../../../models/BranchLoginModels';
import { Concessionaire } from '../../../models/Concessionaire';
import { Snackbar } from '../../../services/common';
import { DataService } from '../../../services/data.service';
import { DatabaseService } from '../../../services/database.service';
import { GeneralSetting } from '../../../services/generalSetting.service';
import { SaveSettingsService } from '../../../services/save-settings.service';
import { ConcessionaireSelectComponent } from '../../../shared/concessionaire-select/concessionaire-select.component';

@Component({
  selector: 'app-concessionaire-setting',
  templateUrl: './concessionaire-setting.component.html',
  styleUrls: ['./concessionaire-setting.component.css']
})
export class ConcessionaireSettingComponent implements OnInit,OnDestroy {

  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  showSelectConcessionaire: boolean = false;

  doYouWantDefaultConcessionaire: boolean = false;

  concessionaireList: Concessionaire[] = [] as Concessionaire[];

  concessionaireSetupText:string=''

  concessionaireEmptyText:string=''

  assignSpecificConcessionaireText:string=''
  defaultConcessionaireText:string=''
  selectDefaultConcessionaireText:string=''
  saveText:string=''
  defaultConcessionaire: Concessionaire | null = null;

  defaultConcessionaireList: Concessionaire[] = [] as Concessionaire[];

  constructor(private readonly db: DatabaseService,
    private readonly saveSettingService: SaveSettingsService,
    private readonly dataService: DataService,
    private readonly modalService:NgbModal,
    private language:LanguageService
  ) { }
  loadText(){
    this.concessionaireSetupText=this.language.getTextElement('concessionaire_setup')
    this.concessionaireEmptyText=this.language.getTextElement('concessionaire_empty')
    this.assignSpecificConcessionaireText=this.language.getTextElement('assign_to_specific_concessionaire')
    this.defaultConcessionaireText=this.language.getTextElement('do_you_want_a_default_concessionaire')
    this.selectDefaultConcessionaireText=this.language.getTextElement('select_default_concessionaire')
    this.saveText=this.language.getTextElement('save')
  }
  ngOnInit(): void {
    this.allSubsCription.push(this.language.localeCommunicator.subscribe((val)=>{
      this.loadText();
    }))
    this.baseDataSetup();
    this.doSubScription();
  }

  private baseDataSetup() {
    this.showSelectConcessionaire = GeneralSetting.getIsAssignSpecificConcessionaire();
    this.getAllConcessionaire();
  }

  private doSubScription() {
    this.allSubsCription.push(
      this.saveSettingService.reloadSelfSubject.subscribe(data => {
        setTimeout(() => {
          this.baseDataSetup();
        }, 100)
      })
    );
  }

  private getAllConcessionaire() {
    this.db.getAllConcessionaireForSettingFromSql().then(data => {
      this.concessionaireList = data;

      this.setDefaultConcessionaire(this.concessionaireList);
    });
  }

  private setDefaultConcessionaire(conList: Concessionaire[]) {
    this.defaultConcessionaireList = JSON.parse(JSON.stringify(conList));

    for (let dc of this.defaultConcessionaireList) {
      dc.IsSelected = dc.IsDefaultConcessionaire;
      if (dc.IsDefaultConcessionaire) {
        this.defaultConcessionaire = dc;
        this.doYouWantDefaultConcessionaire = true;
      }
    }
  }

  private openConcessionaireSelectionModel(isDefault: boolean = false) {

    let refConList = null;

    if (isDefault) {
      refConList = JSON.parse(JSON.stringify(this.defaultConcessionaireList));
    }
    else {
      refConList = JSON.parse(JSON.stringify(this.concessionaireList));
    }

    ConcessionaireSelectComponent.open(this.modalService,refConList, isDefault).result.then((data: any) => {
      this.selectConcessionaireModelRes(data, isDefault);
    });
  }

  private selectConcessionaireModelRes(resData: any, isDefault: boolean = false) {
    if (resData != "canceled") {
      const conList = resData as Concessionaire[];

      if (isDefault) {
        this.setDefaultConcessionaire(conList);
      }
      else {
        this.concessionaireList = conList;
      }
    }
  }

  getSelectedConList(concessionaireList: Concessionaire[]) {
    if (concessionaireList.length > 0) {
      return concessionaireList.filter(x => x.IsSelected);
    }

    return [];
  }

  onAllowConcessionaireCheck(e: any) {
    const checked = e.target.checked;

    if (checked) {
      this.showSelectConcessionaire = true;
    } else {
      this.showSelectConcessionaire = false;
    }
  }

  onDefaultCheck(e: any) {
    const checked = e.target.checked;

    if (checked) {
      this.doYouWantDefaultConcessionaire = true;
    } else {
      this.doYouWantDefaultConcessionaire = false;

    }
  }

  onSelectConcessionaireClick() {
    this.openConcessionaireSelectionModel(false);
  }

  onDefaultSelectionClick() {
    this.openConcessionaireSelectionModel(true);
  }

  async onSaveClick() {

    let deviceMappingList = this.convertConListToDeviceMapping();

    if (deviceMappingList.length > 0) {
      await this.saveSettingService.createTableIfNotExists("ConcessionaireDeviceMappings" as any, deviceMappingList[0], deviceMappingList);
    }

    await this.saveSettingService.removeCurrentDetail("ConcessionaireDeviceMappings");

    this.saveSettingService.addTableRowsToDb("ConcessionaireDeviceMappings" as any, deviceMappingList);

    GeneralSetting.setIsAssignSpecificConcessionaire(this.showSelectConcessionaire);

    let slectedConList = this.concessionaireList.filter(x => x.IsSelected);

    if (slectedConList.length > 0) {
      this.dataService.updateConcessionaireSettings(slectedConList, this.doYouWantDefaultConcessionaire, this.defaultConcessionaire?.ConcessionaireID ?? '0').toPromise().then();
    }
    let error=this.language.getTextElement('updated_concessionaire_settings')
    Snackbar.show(error, 2500);
  }

  private convertConListToDeviceMapping() {

    let branchId = GeneralSetting.getBranchId();
    let companyId = GeneralSetting.getCompanyId();
    let staffModuleId = GeneralSetting.getStaffModuleID();


    let deviceMappingList: ConcessionaireDeviceMapping[] = [];

    let index = 0;
    for (let con of this.concessionaireList.filter(x => x.IsSelected)) {
      index++;
      let isDefault = false;

      if (this.defaultConcessionaire) {
        isDefault = con.ConcessionaireID == this.defaultConcessionaire.ConcessionaireID;
      }

      let dm: ConcessionaireDeviceMapping = {
        BranchID: branchId,
        CompanyID: companyId,
        ConcessionaireDeviceID: '' + index,
        ConcessionaireID: con.ConcessionaireID,
        IsDefaultConcessionaire: isDefault.toString(),
        StaffModuleID: staffModuleId
      }

      deviceMappingList.push(dm);
    }

    return deviceMappingList;
  }

}
