import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { OrderListReq } from '../models/OrderListReq';
import { BixolonPrinterStatus, HealthLogModel, HealthLogRequest, LogDetail, SystemHealthStatus } from './api/Models';
import { CloverService } from './clover.service';
import { CommonFunctions } from './common';
import { DataService } from './data.service';
import { DatabaseService } from './database.service';
import { GeneralSetting } from './generalSetting.service';
import { IsKioskService } from './is-kiosk.service';
import { loggingData, LogService } from './log.service';
import { PrinterService } from './printer.service';

@Injectable({
  providedIn: 'root'
})
export class HealthLogService {

  kioskHealthCheck: HealthLogModel = {
    kioskHealthTime: new Date(),
    deviceName: '-',
    serialNo: '-',
    internetConnecationText: '-',
    isInternetConnecation: false,
    grubbrrCloudText: '-',
    isGrubbrrCloud: false,
    grubbrrCloverMerchantId: '-',
    grubbrrCloverDeviceId: '-',
    isGrubbrrClover: false,
    grubbrrCloverApiRes: '',
    kitchenPrinterText: '-',
    isKitchenPrinter: false,
    ReceiptPrinterStatus: [{
      value: '-',
      severity: 'error'
    }],
    KIOSKBuildVersion: '-',
    isKIOSKBuildVersion: false,
    locationId: ''
  }

  constructor(
    private database: DatabaseService,
    private readonly dataService: DataService,
    private clover: CloverService,
    private logService: LogService,
    private readonly isKioskService: IsKioskService,
    private printerService: PrinterService,
  ) { }

  getDeafultHealthLog(): LogDetail[] {
    return  [
      {
        label: "lbl_internet_connection",
        value: "-",
        severity: SystemHealthStatus.Error
      },
      {
        label: "lbl_grubbrr_cloud",
        value: "-",
        severity: SystemHealthStatus.Error
      },
      {
        label: "lbl_clover_cloud",
        value: "-",
        severity: SystemHealthStatus.Error
      },
      {
        label: "text_merchant_id",
        value: "-",
        severity: SystemHealthStatus.Info
      },
      {
        label: "lbl_kitchen_printer",
        value: "-",
        severity: SystemHealthStatus.Error
      },
      {
        label: "lbl_receipt_printer",
        value: "",
        severity: "",
        details: []
      },
      {
        label: "lbl_kiosk_build",
        value: "-",
        severity: SystemHealthStatus.Info,
      }
    ]
  }

  async sendHealthLog(isSpecific: boolean = false): Promise<HealthLogRequest> {
    return new Promise<HealthLogRequest>(async (resolve) => {
      this.kioskHealthCheck.kioskHealthTime = new Date();
      this.kioskHealthCheck.grubbrrCloverMerchantId = GeneralSetting.getMerchantId() || '-';
      this.kioskHealthCheck.locationId = GeneralSetting.getBranchId() || '-';
      this.kioskHealthCheck.deviceName = GeneralSetting.getDeviceName();
      this.kioskHealthCheck.serialNo = GeneralSetting.getSerialNo();
      this.kioskHealthCheck.grubbrrCloverDeviceId = GeneralSetting.getDeviceHSN() || '-';
      this.kioskHealthCheck.KIOSKBuildVersion = GeneralSetting.getAppVersion() || '-';
      this.kioskHealthCheck.isKIOSKBuildVersion = (GeneralSetting.getAppVersion() && (GeneralSetting.getAppVersion().length > 0)) ? true : false;
      this.kioskHealthCheck.kitchenPrinterText = "Clover Default";
      this.database.getKitchenPrinters(true).subscribe(async (printers) => {
        let kitchenPrinterData = printers.find( (b) => b.IsKitchenPrinter == 'True');
        if(kitchenPrinterData) {
          this.kioskHealthCheck.kitchenPrinterText = kitchenPrinterData.IpAddress;
          await this.healthStarPrint(kitchenPrinterData);
        }
      })

      if(this.isKioskService.isKiosk()) {
        let message = {messageType: 'checkBixolonPrintersStatus'};
        window.top!.postMessage(message, '*');

        await this.getBixolonPrinterStatus();
      }

      await this.getOnlineCheckSK();

      let kitchenPrinterStatus = SystemHealthStatus.Info;
      if(this.kioskHealthCheck.kitchenPrinterText !== "Clover Default") {
        kitchenPrinterStatus = this.kioskHealthCheck.isKitchenPrinter ? SystemHealthStatus.Info : SystemHealthStatus.Error
      }

      let kioskHealthCheckRequest: HealthLogRequest = {
        kioskBuildVersion: this.kioskHealthCheck.KIOSKBuildVersion,
        deviceName: GeneralSetting.getDeviceName(),
        serialNo: GeneralSetting.getSerialNo(),
        locationId: this.kioskHealthCheck.locationId,
        kioskHealthTime: this.kioskHealthCheck.kioskHealthTime,
        isGrubbrrCloud: this.kioskHealthCheck.isGrubbrrCloud,
        sendNotification: isSpecific,
        details: [
          {
            label: "lbl_internet_connection",
            value: this.kioskHealthCheck.internetConnecationText,
            severity: this.kioskHealthCheck.isInternetConnecation ? SystemHealthStatus.Success : SystemHealthStatus.Error
          },
          {
            label: "lbl_grubbrr_cloud",
            value: this.kioskHealthCheck.grubbrrCloudText,
            severity: this.kioskHealthCheck.isGrubbrrCloud ? SystemHealthStatus.Success : SystemHealthStatus.Error
          },
          {
            label: "lbl_clover_cloud",
            value: this.kioskHealthCheck.grubbrrCloverDeviceId,
            severity: this.kioskHealthCheck.isGrubbrrClover ? SystemHealthStatus.Success : SystemHealthStatus.Error
          },
          {
            label: "text_merchant_id",
            value: this.kioskHealthCheck.grubbrrCloverMerchantId,
            severity: SystemHealthStatus.Info
          },
          {
            label: "lbl_kitchen_printer",
            value: this.kioskHealthCheck.kitchenPrinterText,
            severity: kitchenPrinterStatus
          },
          {
            label: "lbl_receipt_printer",
            value: "",
            severity: "",
            details: this.kioskHealthCheck.ReceiptPrinterStatus
          },
          {
            label: "lbl_kiosk_build",
            value: this.kioskHealthCheck.KIOSKBuildVersion,
            severity: SystemHealthStatus.Info,
          }
        ]
      }

      GeneralSetting.setHealthLogs(kioskHealthCheckRequest)

      var log = new loggingData(
        'Update HealthLog',
        'Update HealthLog success',
        'HealthLog Sync Successfully',
        `Update HealthLog: ${JSON.stringify(kioskHealthCheckRequest)}`,
        true
      );
      this.logService.sendLogToServer(log);

      if(this.kioskHealthCheck.isGrubbrrCloud) {
        this.dataService.postHealthLog(kioskHealthCheckRequest).pipe(timeout(50000))
        .toPromise()
        .then(
          (data) => {
            resolve(kioskHealthCheckRequest);
        }, (error) => {
          resolve(kioskHealthCheckRequest);
        });
      } else {
        resolve(kioskHealthCheckRequest);
      }
    })

  }

 async healthStarPrint(printer: any) {
     this.printerService
        .doSTARHealthPrint(printer)
        .pipe(timeout(10000))
        .subscribe((starPrinter: any) => {
          this.kioskHealthCheck.isKitchenPrinter = starPrinter?.result || false;
          return starPrinter
        }, (err) =>{
          this.kioskHealthCheck.isKitchenPrinter = false;
          return false
        })
    
  }

  async getBixolonPrinterStatus() {
    return new Promise( (resolve) => {
      this.listener = (event: any) => {
        if (event.data.messageType === 'BixolonPrinterStatusMessage') {
          resolve(true);
          this.kioskHealthCheck.ReceiptPrinterStatus = [];
          let PrinterStatusId = event.data.data.printerStatus;
          if(PrinterStatusId.includes("1")) {
            this.kioskHealthCheck.ReceiptPrinterStatus.push({
              value: BixolonPrinterStatus.Connected,
              severity: SystemHealthStatus.Success
            })
          } else {
            if(PrinterStatusId.includes("3")) {
              this.kioskHealthCheck.ReceiptPrinterStatus.push({
                value: BixolonPrinterStatus.InsufficientPaper,
                severity: SystemHealthStatus.LowWarning
              })
            }

            if(PrinterStatusId.includes("4")) {
              this.kioskHealthCheck.ReceiptPrinterStatus.push({
                value: BixolonPrinterStatus.OutOfPaper,
                severity: SystemHealthStatus.HighWarning
              })
            }

            if(PrinterStatusId.includes("5")) {
              this.kioskHealthCheck.ReceiptPrinterStatus.push({
                value: BixolonPrinterStatus.CoverIsOpen,
                severity: SystemHealthStatus.LowWarning
              })
            } 

            if(PrinterStatusId.includes("6") || PrinterStatusId.includes("7")) {
              this.kioskHealthCheck.ReceiptPrinterStatus.push({
                value: BixolonPrinterStatus.CutterError,
                severity: SystemHealthStatus.Error
              })
            } 

            if(PrinterStatusId.includes("2")) {
              this.kioskHealthCheck.ReceiptPrinterStatus.push({
                value: BixolonPrinterStatus.OtherError,
                severity: SystemHealthStatus.Error
              })
            }

          }

          let connType = event.data.data.connType;
          this.kioskHealthCheck.internetConnecationText = (connType) ? CommonFunctions.capitalizeFirstLetterOfWord(connType) : '';
          this.removeAllListeners(); 
        }
      };
      if (this.listeners.length == 0) {
        this.listeners.push(this.listener);
        window.addEventListener('message', this.listener);
      }
    })
  }

  listeners: EventListener[] = [];
  listener: EventListener | undefined;
  removeAllListeners() {
    if (this.listeners.length) {
      this.listeners.forEach((x: EventListener) => {
        window.removeEventListener('message', x);
      });
    }
    this.listeners = [];
  }

  async getOnlineCheckSK() {
    return new Promise( (resolve) => {
      this.dataService
      .IsOnlineCheckSK()
      .pipe(timeout(50000))
      .toPromise()
      .then(
        (data) => {
          this.kioskHealthCheck.grubbrrCloudText = 'Connected';
          this.kioskHealthCheck.isInternetConnecation = true;
          this.kioskHealthCheck.isGrubbrrCloud = true;
          this.clover.ping().then( (res: any) => {
            if (res.status == 'success') {
              this.kioskHealthCheck.isGrubbrrClover = true;
              this.kioskHealthCheck.grubbrrCloverApiRes = res?.message || '';
            } else {
              this.kioskHealthCheck.isGrubbrrClover = false;
              this.kioskHealthCheck.grubbrrCloverApiRes = res?.message || '';
            }
            resolve(true)
          }, (error) => {
            this.kioskHealthCheck.isGrubbrrClover = false;
            resolve(true)
          });
        },
        (error) => {
          this.kioskHealthCheck.grubbrrCloudText = 'Not Connected';
          this.kioskHealthCheck.isInternetConnecation = false;
          this.kioskHealthCheck.isGrubbrrCloud = false;
          this.kioskHealthCheck.isGrubbrrClover = false;
          resolve(true)
        }
      );
    })
  }

}
