//a container carousel to hold any type of object
import { Component, Input, OnInit, Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { GeneralSetting } from "src/app/services/generalSetting.service";
import { ModifierV2 } from "../../models/item";
import { CommonFunctions } from "../../services/common";

@Component({
  selector: "app-modifier-container",
  templateUrl: "./modifier-container.component.html",
  styleUrls: ["./modifier-container.component.css"]
})
export class ModifierContainerComponent implements OnInit {

  //get modifiers --> get ingredients
  //get item --> modifiers --> get ingredients

  // input from item with modifiers container or anywhere else using this component
  @Input() inputModifier: ModifierV2[] | undefined = [] as ModifierV2[];

  @Input() ItemType: number = 1;

  @Input() ItemName: string = "";

  @Output() isValidEmitter: EventEmitter<boolean> = new EventEmitter();

  @Output() modifierEmitter: EventEmitter<{ inputModifier: ModifierV2[], isValid: boolean }> = new EventEmitter();

  isValid: boolean = true;

  @Input() ItemDescription: string = "";

  ngOnInit(): void {
    this.InitializePage();
  }

  private async InitializePage() {
    await this.checkModifierValid();
  }

  ngAfterViewInit(): void {
    this.InitializePage();
  }

  modifierChange(obj: { modifier: ModifierV2, index: number }) {
    if (this.inputModifier && this.inputModifier.length > 0) {
      let mod = this.inputModifier[obj.index];
      mod = obj.modifier;
    }
    this.checkModifierValid();
  }

  // check if things are valid / selected
  async checkModifierValid() {
    // is valid by default
    this.isValid = true;
    if (this.inputModifier && this.inputModifier.length > 0) {
      for (let i = 0; i < this.inputModifier.length; i++) {
        const modifier = this.inputModifier[i];

        let selectedIngredient = 0
        /**
         * Check modifier validate based on min max aggregate @nilesh
         */
        modifier.UseAdvancedModifierQuantitySettings == 'False'
          ? selectedIngredient = CommonFunctions.getSelectedIngredientCount(modifier)
          : selectedIngredient = CommonFunctions.getOnlySelectedIngredientCount(modifier)

        if (modifier.IsSelected) {
          //if (modifier.Ingredients.length > 0) {

          let aggregateCondition = modifier.MaxAggregateQty && modifier.MinAggregateQty && modifier.MaxAggregateQty != 0 && modifier.MinAggregateQty != 0
            ? modifier.countAggregate <= Number(modifier.MaxAggregateQty) && modifier.countAggregate >= Number(modifier.MinAggregateQty)
            : true;
          let condition = true;
          if (modifier.UseAdvancedModifierQuantitySettings == 'False') {
            condition = selectedIngredient >= modifier.min &&
              selectedIngredient <= modifier.max;
          } else {
            condition = (selectedIngredient >= modifier.min &&
              selectedIngredient <= modifier.max)
              && aggregateCondition;
          }
          if (condition) {
            modifier.isValid = true;
            modifier.IsSelected = true;
          } else {
            modifier.isValid = false;
            this.isValid = false;
          }
          //}
        } else {
          if (selectedIngredient > 0) {
            if (selectedIngredient >= modifier.min && selectedIngredient <= modifier.max) {
              modifier.isValid = true;
              modifier.IsSelected = true;
            } else {
              modifier.isValid = false;
              this.isValid = false;
            }
          }
          else if (modifier.min != 0) {
            modifier.isValid = false;
            this.isValid = false;
          }
        }
        /**
         * https://grubbrr.atlassian.net/browse/SK-1920 - Modifier group isselected flag update @nilesh
         */
        if (selectedIngredient == 0) {
          modifier.IsSelected = false;
        }
      }
      this.modifierEmitter.emit({ inputModifier: this.inputModifier, isValid: this.isValid });
    }
  }

  // scroll to invalid modifier when user tries to press add to cart / update
  modifierScroll() {
    let i: number = 0;
    // find index of invalid modifier or combo
    if (!this.inputModifier) return;
    for (let [index, item] of this.inputModifier.entries()) {
      if (!item.isValid || (item.IsModifier86 == 'False')) {
        i = index;
        break;
      }
    }
    // find the corresponding html element of the invalid modifier set by modifier/combo id
    const htmlMod = document.getElementById(this.inputModifier[i].ModifierID);
    if (htmlMod) {
      // smooth scrolling doesn't actually work on tyzen see menu-item scroll features if you need to make this smooth
      htmlMod.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      if (htmlMod.className.includes(" animated shake")) return;
      htmlMod.className += " animated shake";
      setTimeout(() => {
        if (htmlMod) htmlMod.className = "html-objects";
      }, 1000);
    }
  }
}
