<div class="header">
  <div class="image-box item-image">
    <img src="{{
        menuItem && menuItem.ImageUrl
          ? baseImageUrl + menuItem.ImageUrl
          : placeholderImage !='' 
            ? baseImageUrl+placeholderImage 
            : 'assets/images/Image_21.png'
      }}" alt="" [ngStyle]="{'border-radius': bordersTypeItem ? imageRadius : '0%'}" />
  </div>
  <div class="text tmargin5">
    <div class="text-top">
      <p class="category-name">
        {{ Category ? Category.Name : "" |uppercase}}
      </p>
      <p [ngStyle]="{ color: accentColor}" class="item-name">
        {{ menuItem && menuItem != undefined ? menuItem.Name : "" }}
      </p>
      <div class="arrowDown position-relative">
        <p class="item-description" id="arrowDown">
          {{ menuItem && menuItem != undefined ? menuItem.FullDescription.length > 162 ? (menuItem.FullDescription | slice:0:151): menuItem.FullDescription : ''}} <a class="view-more" (click)="openModal()" *ngIf="lengthy" >...Read More</a>
        </p>
      </div>
      <div [ngStyle]="{ 'align-items': 'horizontal' }" class="row">
        <!-- <i class="fas fa-caret-down" [ngClass]="{
            showArrowDown: headerDescHasScroll,
            hideArrowDown: !headerDescHasScroll
          }"></i> -->
        <div *ngFor="let image of itemTags?.ItemTags"
          [ngStyle]="{ width: '10rem', flex: '0 0 0%', 'padding-top': '1rem' }" class="col">
          <img [ngStyle]="{ width: '3rem', height: '3rem'}" *ngIf="image != null && image != ''"
            src="{{ baseImageUrl + image }}" onerror="this.onerror = null;this.src = 'assets/images/Image_21.png';"
            class="col-md-3" alt="" />
        </div>
      </div>
      <div class="row2" *ngIf="!menuItem.IsCategory">
        <div class="skin-4 col2">
          <span class="num-in">
            <span class="all-span" *ngIf="menuItem?.AllowQuantitySelection != 'False'">
              <p class="quantity-text">{{chooseQuantityText}}</p>
              <div class="break"></div>
              <div id="QtyBoxMinus" class="minus">
                <img (click)="decreaseAmount()" src="../../../assets/images/minus.svg"
                  onerror="this.onerror = null;this.src = 'assets/images/Image_21.png';" alt="" />
              </div>
              <input id="QtyBox" type="input" class="in-num" [value]="quantity" [(ngModel)]="quantity" disabled />
              <div id="QtyBoxPlus" class="plus">
                <img (click)="increaseAmount()" src="../../../assets/images/plus.svg"
                  onerror="this.onerror = null;this.src = 'assets/images/Image_21.png';" alt="" />
              </div>
            </span>
          </span>
        </div>

        <div class="col2 starting-price">
          <div *ngIf="menuItem.Price != '0.00' && !isRemovePricingAndPayments">
            <p class="price" [ngStyle]="{ color: priceColor }">
              {{
              startAtStr +
              ((menuItem != undefined
              ? menuItem.Price == undefined
              ? " 0.00"
              : menuItem.Price
              : "0.00"
              ) | CurrencyPipe)
              }}
            </p>
            <p *ngIf="menuItem.OldPrice != undefined" class="regular-price" [ngStyle]="{ color: priceColor }">
              {{regularPriceText}} {{ menuItem.OldPrice | CurrencyPipe }}
            </p>
            <p *ngIf="menuItem.OldPrice != undefined" class="savings" [ngStyle]="{ color: priceColor }">
              {{savingsText}}
              {{
              convertNumber(menuItem.OldPrice) - convertNumber(menuItem.Price)
              | number: "1.2-2"
              | CurrencyPipe
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>