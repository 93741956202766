

<div class="qr-term" *ngIf="!showTermsAsUrl">
    <h1>{{termsAndConditionsText}}</h1>
    <p>{{scanBarcodeText}} </p>
    <div class="qr-code">
         <ngx-qrcode  [width]="300" [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="value">
        </ngx-qrcode> 
    </div>
    <div class="or">{{orText | uppercase}}</div>
    <button class="green-btn" [ngStyle]="{
        color: textColor,
        'background-color': accentColor
      }" (click)="showTermsAsUrl = true;">{{clickHereText | uppercase}}</button>
    <button class="back-btn" (click)="dismiss()">{{backBtnText | uppercase}}</button>
</div>
<app-terms-conditions-url *ngIf="showTermsAsUrl" [isBoth]="true" (onClose)="onClose($event)" [url]="value"></app-terms-conditions-url>


