<div class="cartFooter menu-cart2 background-footer-color"
  [ngStyle]="{ 'border-top': '5px solid' + positiveBtnColor, backgroundColor: footerOrderSummaryBackgroundColor }"
  [ngClass]="{ open: isCartOpen }">
  <footer class="menu-cart2">
    <div>
      <button *ngIf="isCartOpen" class="pushme2 with-color" (click)="closeCart()">
        <i class="fa fa-chevron-down"></i>
        {{closeCartText}}
      </button>
      <button *ngIf="!isCartOpen" class="pushme2 with-color" (click)="closeCart()">
        <i class="fa fa-chevron-up"></i>
        {{openCartText}}
      </button>
    </div>
    <div class="row background-footer-color">
      <div class="col-md-9 col-xs-9 cart-list width70" id="cart-list">
        <p *ngIf="showCalorie === 'True'; else noShowCalorie">
          {{calorieText}}
        </p>
        <ng-template #noShowCalorie>
          <p>&nbsp;</p>
        </ng-template>
        <app-cart-items (changePrice)="updatePrice()" [isCartOpen]="isCartOpen" [removeAlk]="removeAlk" [cart]="this"
          [isHybrid]="false"></app-cart-items>
      </div>
      <div class="col-md-3 col-xs-3 width30 background-footer-color">
        <app-checkout-box *ngIf="router.url.split('?')[0] === '/menu'" (cleared)="clearCart()"
          [isCartOpen]="isCartOpen">
        </app-checkout-box>
      </div>
    </div>
  </footer>
</div>