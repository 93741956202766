<section>
  <div class="payment-error" role="document">
    <div class="payment-modal">
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      
      <h2 *ngIf="isVerifone" class="text-center">{{ isRefund ? refundText : checkoutText|uppercase }}</h2>
      <h3 *ngIf="isVerifone" class="text-center">{{ isRefund ? refundText : paymentText }}</h3>

      <h2 *ngIf="!isVerifone" class="text-center">{{ isCloverRefund ? refundText : checkoutText|uppercase }}</h2>
      <h3 *ngIf="!isVerifone" class="text-center">{{ isCloverRefund ? notEnoughFundsText : paymentText }}</h3>

      <h4 class="text-center">{{ error }}</h4>

      <div class="col-md-12 col-xs-12 p-0 error-img">
        <img *ngIf="isVerifone"
          [attr.src]="['../../../../assets/images/Payment-Failed.gif']"
          onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
        />
        <img *ngIf="!isVerifone"
          [attr.src]="[
          isClover
            ? '../../../../assets/images/Clover-failed.gif'
            : '../../../../assets/images/Dejavoo-Payment-Failed.gif'
          ]"
          onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
        />
      </div>

      <!-- <div class="col-md-12 col-xs-12 text-center">
                        <button type="button" class="close-btn" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">CLOSE</span>
                        </button>
                    </div>  -->
    </div>
  </div>
</section>
