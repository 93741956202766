import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class HttpConnection implements IAddUrl, IAddHeader, IAddBody, IApiCall, IApiMethod {

  private localUrl: string = environment.NodeUrl;
  private url: string = '';
  private method: ("POST" | "GET") = 'POST';
  private header: IHttpHeaders[];
  private body: any;
  private isKiosk: boolean = true;

  private constructor(private readonly http: HttpClient) {
    this.header = [];
  }

  static Connection(http: HttpClient, isKiosk: boolean): IAddUrl {
    const conn = new HttpConnection(http);
    conn.url = '';
    conn.body = null;
    conn.header = [] as IHttpHeaders[];
    conn.method = 'POST';
    conn.isKiosk = isKiosk;

    return conn;
  }

  addUrl(url: string): IApiMethod {
    this.url = url;
    return this;
  }

  addMethod(method: ("POST" | "GET")): IAddHeader {
    this.method = method;
    return this;
  }

  addHeader(myHeader: IHttpHeaders[]): IAddBody {
    this.header = myHeader;
    return this;
  }

  addBody(body: any): IApiCall {
    this.body = body;
    return this;
  }

  post(): Observable<any> {
    const nodeReqBody = {
      url: this.url,
      body: this.body,
      header: this.header,
      method: this.method
    };

    if (this.isKiosk) {
      return this.http.post(this.localUrl + 'ProxyBackendApiCall', nodeReqBody)
        .pipe(catchError(this.errorHandler));
    }
    else {
      let headers = new HttpHeaders();

      for (let i = 0; i < this.header.length; i++) {
        const header = this.header[i];
        headers = headers.append(header.key, header.value);
      }

      if (this.method == "POST") {
        return this.http.post(this.url, this.body, { headers })
          .pipe(catchError(this.errorHandler));
      }
      else {
        return this.http.get(this.url, { headers: headers })
          .pipe(catchError(this.errorHandler));
      }
    }
  }

  private errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}

export interface IHttpHeaders {
  key: string;
  value: string;
}

interface IAddUrl {
  addUrl(url: string): IApiMethod;
}

interface IApiMethod {
  addMethod(method: ("POST" | "GET")): IAddHeader;
}

interface IAddHeader {
  addHeader(myHeader: IHttpHeaders[]): IAddBody
}

interface IAddBody {
  addBody(body: any): IApiCall
}

interface IApiCall {
  post(): Observable<any>;
}



