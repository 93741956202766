<div id="alcoholicitems">
    <div class="modal-body alcoholicitems-modal">
        <img src="../../../../assets/images/beer.png" onerror="this.onerror=null; this.src='assets/images/Image_21.png'"/>
        <h1 class="text-center">{{alcoholicItemText}}</h1>

        <div class="content">
            <h4 class="text-center">{{ageLimitText}}</h4>



            <div class="modal-footer">
                <div class="col-12 col-md-12 popup-footer text-center">
                    <button matRipple class="green-btn text-uppercase background-positive-button-color positive-button-text-color" type="submit" name="submits"
                        (click)="dismissOver21()" [style.background]="positiveColor" [style.border]="positiveColor" [style.color]="positiveColorText">{{iAmOverText|uppercase}}</button>
                    <br />
                    <button matRipple class="white-btn background-negative-button-color negative-button-text-color warn text-uppercase"
                        (click)="dismissUnder21()" [style.background]="negativeColor" [style.color]="negativeColorText">{{iAmUnderText|uppercase}}</button>
                </div>
            </div>

        </div>
    </div>
</div>