<div id="item-selection">
    <div class="modal-body item-selection-modal">
      <h1 class="text-center">{{itemSelectionText}}</h1>
      <div class="content">
        <h4 class="text-center">{{scanOrSelectText}}<br/>
          {{addToCartText}}</h4>
        <div class="img-bar">
          <div class="col-md-5">
            <img src="../../../assets/images/product-scan.gif" class="scanimg" /><br />
            <h3>{{scanItemsText}}</h3>
          </div>
          <div class="col-md-1">
            {{orText|uppercase}}</div>
          <div class="col-md-5">
            <img src="../../../assets/images/Select-product.gif" class="scanimg" /><br />
            <h3>{{selectItemsText}}</h3>
          </div>
        </div>
        <div class="light-text">
          <div class="col-1 col-md-1 p-0">
            <img src="../../../../assets/images/info.png"/>
          </div>
          <div class="col-10 col-md-10">{{selectFromMenuText}}</div>
        </div>
        <div class="modal-footer">
          <div class="col-12 col-md-12 popup-footer text-center">
            <button matRipple class="green-btn text-uppercase" type="submit" name="submits" (click)="close()"
            [style.background-color]="positiveColor" [style.color]='positiveColorText' >{{continueText}}</button>
          </div>
        </div>
  
      </div>
    </div>
  </div>