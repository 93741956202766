<div class="container">
    <span class="row1"><p class="header1">{{totalText}}</p><p class="header1">{{total|number:'1.2-2'|CurrencyPipe}}</p> <img (click)="swap()" class="icon" src="../../assets/images/information.png" /></span>
    <div *ngIf="!collapsed">
        <span class="row2"><p class="header2">{{subtotalText}}</p><p class="header2">{{subtotal|number:'1.2-2'|CurrencyPipe}}</p></span>
        <span class="row2"><p class="header2">{{tipText}}</p><p class="header2">{{tip|number:'1.2-2'|CurrencyPipe}}</p></span>
        <span class="row2" *ngIf="reward"><p class="header2">{{rewardText}}</p><p class="header2">-{{reward|number:'1.2-2'|CurrencyPipe}}</p> </span>
        <span class="row2" *ngIf="tax"><p class="header2">{{taxesAndFeesText}}</p><p class="header2">{{tax|number:'1.2-2'|CurrencyPipe}}</p> </span>
        <span class="row2" *ngIf="discount"><p class="header2">{{discountText}}</p><p class="header2">{{discount|number:'1.2-2'|CurrencyPipe}}</p> </span>
        <span class="row2" *ngIf="shipping"><p class="header2">{{shippingFeeText}}</p><p class="header2">{{shipping|number:'1.2-2'|CurrencyPipe}}</p> </span>
    </div>
</div>
