<div role="tabpanel" class="tab-pane " id="paymentsettings">
  <!--Payment Setting-->
  <h2>{{concessionaireSetupText}}</h2>

  <div *ngIf="concessionaireList && concessionaireList.length > 0">
  <div class=" row mb-3rem">
    <div class="col-md-6 col-6">{{assignSpecificConcessionaireText}}</div>
    <div class="col-md-6 col-6 text-left">
      <div class="switch_box">
        <input type="checkbox" class="switch_1" [(ngModel)]="showSelectConcessionaire" (click)="onAllowConcessionaireCheck($event)">
      </div>
    </div>
  </div>

  <div class=" row mb-3rem" *ngIf="showSelectConcessionaire && concessionaireList && concessionaireList.length > 0">
    <div class="col-md-12 col-12 concessionaire-names" (click)="onSelectConcessionaireClick()">
      <ng-container *ngFor="let con of concessionaireList | functionCall : getSelectedConList ;let f = first;" >
        <ng-container *ngIf="con.IsSelected">
          <span *ngIf="f">{{ con.Name }}</span>

          <span *ngIf="!f">{{ ","+con.Name }}</span>

        </ng-container>

      </ng-container>

    </div>
  </div>

  <div class=" row mb-3rem">
    <div class="col-md-6 col-6">{{defaultConcessionaireText}}</div>
    <div class="col-md-6 col-6 text-left">
      <div class="switch_box">
        <input type="checkbox" class="switch_1" [(ngModel)]="doYouWantDefaultConcessionaire" (click)="onDefaultCheck($event)">
      </div>
    </div>
  </div>

  <div class=" row mb-3rem" *ngIf="doYouWantDefaultConcessionaire">
    <div class="col-md-12 col-12 concessionaire-names" (click)="onDefaultSelectionClick()">
      <span>{{ defaultConcessionaire ? defaultConcessionaire.Name : selectDefaultConcessionaireText }}</span>
    </div>
  </div>

  <div class="row mb-3rem" style="border: 0">
    <div class="col-md-12 col-12 text-left">
      <div class="btn2 text-center">
        <button (click)="onSaveClick()">
          <i class="fa fa-sync"></i>{{saveText}}
        </button>
      </div>
    </div>
  </div>
  </div>
  <div *ngIf="concessionaireList && concessionaireList.length <= 0">
    <h1>{{concessionaireEmptyText}}</h1>
  </div>
</div>


