import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ItemV2, ModifierIngredientV2, ModifierV2 } from '../models/item';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import {
  ParBrinkItem,
  ParBrinkMod,
  ParBrinkOrderData,
} from '../models/par-brink-order-data';
import { LanguageService } from './language.service';
export interface ParBrinkOverride {
  IngredientID: string;
  ItemID: string;
  ModifierID: string;
  KOTKDSDisplayOrder: string
  isDefault: string;
  DisplayOrder: string;
  isSendtoPOS: string;
  IsSendNegativeModifierstoPOS: string;
}

@Injectable({
  providedIn: 'root',
})
export class ParBrinkService {
  apiUrl: string;
  Tax: number = 0;
  Total: number = 0;
  SubTotal: number = 0;
  OrderId: string = '';
  edited: boolean = false;
  zeroOrder: boolean = false;

  constructor(private readonly http: HttpClient, private language: LanguageService) {
    this.apiUrl = environment.apiUrl;
  }
  isValidConfiguration(isLoyalty: boolean, isLoyaltyPayment: boolean = false): number {
    if (GeneralSetting.getDefaultTenderID() == '' || GeneralSetting.getDefaultTenderID() == '0') {
      return 1;
    }
    if (isLoyalty) {
      if (GeneralSetting.getTPLoyaltyDiscountID() == '' || GeneralSetting.getTPLoyaltyDiscountID() == '0') {
        return 2;
      }
      if (isLoyaltyPayment) {
        if ((GeneralSetting.getTPLoyaltyPayment() == '' || GeneralSetting.getTPLoyaltyPayment() == '0')) {
          return 3;
        }
      }
    }
    return 0;
  }
  resetValues() {
    this.edited = false;
    this.zeroOrder = false;
    this.SubTotal = 0;
    this.OrderId = '';
    this.Total = 0;
    this.Tax = 0;
  }
  private negativeModifierNoteBuilder(
    str: string,
    ingredient: ModifierIngredientV2
  ) {
    if (ingredient.KOTDisplayName != '') {
      str = str + `NO ${ingredient.KOTDisplayName} `;
    } else if (ingredient.DisplayName != '') {
      str = str + `NO ${ingredient.DisplayName} `;
    } else if (ingredient.Name != '') {
      str = str + `NO ${ingredient.Name} `;
    }
    return str;
  }
  private modCreator(id: number, modifier: ModifierV2[]) { }

  private comboCreator(id: number, item: ItemV2) {
    let Item: ParBrinkItem = {
      id: 0,
      ItemId: item.RefItemId!,
      DestinationId: GeneralSetting.getRefOrderTypeID(),
      Modifiers: [],
      Note: '',
    };
    if (item.specialRequest) {
      Item.Note = item.specialRequest;
    }
    let comboItems = [];
    for (let C = 0; C < item.ComboGroup.length; C++) {
      for (let D = 0; D < item.ComboGroup[C].Items.length; D++) {
        if (
          item.ComboGroup[C].Items[D].isSelected ||
          item.ComboGroup[C].Items[D].IsInvisibleItem == 'True'
        ) {
          let res = this.itemCreator(id, item.ComboGroup[C].Items[D]);
          id = res.id;
          let comboItem: ParBrinkItem = res.item;
          comboItem.ComponentId = Number(item.ComboGroup[C].Items[D].RefComboGroupItemID).toString();


          comboItems.push(comboItem);
        }
      }
    }
    Item.ComboItems = comboItems;
    Item.id = id++;
    return { id: id, item: Item };
  }
  private itemCreator(id: number, item: ItemV2) {
    let Item: ParBrinkItem = {
      id: id++,
      ItemId: item.RefItemId!,
      DestinationId: GeneralSetting.getRefOrderTypeID(),
      Modifiers: [],
      Note: '',
    };
    if (item.Modifiers && item.Modifiers.length > 0) {
      for (let i = 0; i < item.Modifiers.length; i++) {
        for (let j = 0; j < item.Modifiers[i].Ingredients.length; j++) {
          if( //@ts-ignore Property 'IsDefault' does not exist on type 'ModifierIngredientV2'
            item.Modifiers[i].Ingredients[j].IsDefault == 'True'){
            if ( //@ts-ignore Property 'IsDefault' does not exist on type 'ModifierV2'
              item.Modifiers[i].Ingredients[j].isSendtoPOS == 'True')
            {
              if (item.Modifiers[i].Ingredients[j].IsSelected == true){
                let mod: ParBrinkMod = {
                  DestinationId: Number(GeneralSetting.getRefOrderTypeID()).toString(),
                  //@ts-ignore Property 'RefIngredientId' does not exist on type 'ModifierV2'
                  ItemId: item.Modifiers[i].Ingredients[j].RefIngredientId,
                  ModifierGroupId: Number(item.Modifiers[i].RefModifierId)!.toString(),
                  id: id++,
                };
                Item.Modifiers.push(mod);
              }
            } else{
              if (item.Modifiers[i].Ingredients[j].IsSelected != true){
                //@ts-ignore Argument of type 'ModifierIngredientV2 | ModifierV2' is not assignable to parameter of type 'ModifierIngredientV2'
                Item.Note = this.negativeModifierNoteBuilder(Item.Note!, item.Modifiers[i].Ingredients[j]);
              }
            }
          }else {
            if (
              item.Modifiers[i].Ingredients[j].IsSelected == true &&
              //@ts-ignore Property 'IsDefault' does not exist on type 'ModifierV2'
              //data structure such that item.modifiers[x].ingredients array may be of type
              //ingredient or of type modifier, allowing nesting of modifiers
              //parbrink POS integration does NOT allow nested modifiers at this time
              //so the error thrown may be safely ignored
              item.Modifiers[i].Ingredients[j].IsDefault != 'True'
            ) {
              let mod: ParBrinkMod = {
                DestinationId: Number(GeneralSetting.getRefOrderTypeID()).toString(),
                //@ts-ignore Property 'RefIngredientId' does not exist on type 'ModifierV2'
                //data structure such that item.modifiers[x].ingredients array may be of type
                //ingredient or of type modifier, allowing nesting of modifiers
                //parbrink POS integration does NOT allow nested modifiers at this time
                //so the error thrown may be safely ignored
                ItemId: item.Modifiers[i].Ingredients[j].RefIngredientId,
                ModifierGroupId: Number(item.Modifiers[i].RefModifierId)!.toString(),
                id: id++,
              };
              Item.Modifiers.push(mod);
            }
          }
        }
      }
    }
    if (item.specialRequest) {
      Item.Note = Item.Note + item.specialRequest;
    }
    return { id: id, item: Item };
  }
  private assembleOrder(
    cartItems: any[],
    payments: any[] = [],
    reward: number = 0
  ) {
    let id = 1;
    let obj: ParBrinkOrderData = {
      DestinationID: Number(GeneralSetting.getRefOrderTypeID()),
      CustomerID: '',
      // 6b0410dc-5761-4e34-a6de-4afc25312a87
      DISCOUNTS: [],
      Items: [],
    };
    for (let i = 0; i < cartItems.length; i++) {
      for (
        let Quantity = 0;
        Quantity < Number(cartItems[i].Quantity);
        Quantity++
      ) {
        if (cartItems[i].IsCombo) {
          let res = this.comboCreator(id, cartItems[i]);
          id = res.id;
          let Item: ParBrinkItem = res.item;

          obj.Items.push(Item);
        } else {
          let res = this.itemCreator(id, cartItems[i]);
          let Item: ParBrinkItem = res.item;
          id = res.id;

          obj.Items.push(Item);
        }
      }
    }
    if (reward != 0) {
      obj.DISCOUNTS.push({
        DISCOUNTID: Number(GeneralSetting.getTPLoyaltyDiscountID()),
        Amount: reward,
        id: id++,
        NAME: GeneralSetting.getTPLoyaltyDiscountName(),
        LoyaltyRewardId: 0,
        OrderItemIds: [],
      });
    }
    if (payments.length == 0) {
      return obj;
    } else {
      obj.Payments = [
        // one entry per METHOD not per payment.
        // total up the amount of each payment method
        {
          Amount: payments[0].Amount,
          id: id++,
          TenderID: payments[0].LoyaltyPayment
            ? Number(GeneralSetting.getTPLoyaltyPayment())
            : GeneralSetting.getTPTenderId() != '' && GeneralSetting.getTPTenderId() != '0'
              ? Number(GeneralSetting.getTPTenderId())
              : Number(GeneralSetting.getDefaultTenderID()),
          TipAmount: payments[0].TipAmount,
        },
      ];
      //do stuff with payments
      return obj;
    }
  }

  getParBrinkOrderCalulation(cartItems: ItemV2[], reward: number = 0) {
    const url = this.apiUrl + 'ParBrinkOrderCalculateAPI';
    let headers = new HttpHeaders()
      .set('Version', '1.0')
      .set('Content-Type', 'application/json')
      .set('CompanyID', GeneralSetting.getCompanyId())
      .set('BranchID', GeneralSetting.getBranchId())
      .set('DeviceID', '2c2ef7a9fbbed8d3');
    const body = this.assembleOrder(cartItems, [], reward);
    body.IsTaxExempt = false;
    return this.http.post(url, body, { headers }).toPromise();
  }

  parBrinkSubmitOrderAPI(cartItems: any, payments: any[], reward: number = 0) {
    const url = this.apiUrl + 'ParBrinkOrderSubmitAPI';
    let headers = new HttpHeaders()
      .set('Version', '1.0')
      .set('Content-Type', 'application/json')
      .set('CompanyID', GeneralSetting.getCompanyId())
      .set('BranchID', GeneralSetting.getBranchId())
      .set('DeviceID', '2c2ef7a9fbbed8d3');
    const body = this.assembleOrder(cartItems, payments, reward);
    body.OrderId = this.OrderId;
    if (GeneralSetting.getCustomerEmail() != '') {
      body.CustomerEmail = GeneralSetting.getCustomerEmail();
    }
    if (GeneralSetting.getCustomerPhone() != '') {
      body.CustomerPhone = GeneralSetting.getCustomerPhone();
    }
    if (GeneralSetting.getCustomerName() != '') {
      body.CustomerName = GeneralSetting.getCustomerName();
    } else {
      body.CustomerName = this.language.getTextElement('txt_guest')
    }
    if (GeneralSetting.getTableTentNumber() != '') {
      body.TableTent = GeneralSetting.getTableTentNumber();
    }
    if (GeneralSetting.getOrderInvoiceNo() != '') {
      body.TokenNumber = GeneralSetting.getOrderInvoiceNo();
    }
    return this.http.post(url, body, { headers }).toPromise();
  }
  parBrinkModifierOverride(
    overrides: ParBrinkOverride[],
    modifiers: ModifierV2[]
  ) {
    if (!(overrides && overrides.length)) {
      return modifiers;
    }
    //commented nestedSearch as current integration for ParBrink does not currently support nested modifiers
    // function nestedSearch(override:ParBrinkOverride, modifier:any){
    // }
    for (let i = 0; i < overrides.length; i++) {
      let modId = overrides[i].ModifierID;
      for (let j = 0; j < modifiers.length; j++) {
        if (modifiers[j].ModifierID == modId) {
          for (let k = 0; k < modifiers[j].Ingredients.length; k++) {
            if (
              modifiers[j].Ingredients[k].IngredientID ==
              overrides[i].IngredientID
            ) {
              let a = modifiers[j].Ingredients[k] as ModifierIngredientV2;
              a.IsDefault = overrides[i].isDefault;
              a.IsSelected = a.IsDefault == 'True';
              a.KOTDisplayOrder = overrides[i].KOTKDSDisplayOrder;
              a.DisplayOrder = overrides[i].DisplayOrder;
              a.isSendtoPOS = overrides[i].isSendtoPOS;
              a.IsSendNegativeModifierstoPOS = overrides[i].IsSendNegativeModifierstoPOS;
              break
            }
          }
          modifiers[j].Ingredients = modifiers[j].Ingredients.sort((a: any, b: any) => Number(a.DisplayOrder) > Number(b.DisplayOrder) ? 1 : -1);
          break
        }
      }
    }


    return modifiers;
  }
}
