<!-- <button (click)="printThis()">print variation</button> -->
<div class="" role="tablist">
  <div class="">
    <div
      *ngIf="!isBuilderMode && variation?.Name && variation?.Name != ''"
      class=""
      role="tab"
    >
      <span class="btn-header-link" role="button">
        <!-- <h2>CHOOSE ANY ONE</h2> -->
        {{ variation?.Name }}</span
      >
    </div>

    <div class="overflowx-scroll">
      <div [ngClass]="isBuilderMode ? 'row' : ''" class="card-body" style="display: flex">
        <app-variation-option
          class="variation animated fadeIn"
          [ngClass]="isBuilderMode ? 'col-md-3 builder' : ''"
          *ngFor="let item of variation.variationOptions; let i = index"
          [variationOption]="item"
          [varitaion]="this"
          [index]="i"
          [isSelected]="item.isSelected"
          [isBuilderMode]= "isBuilderMode"
        >
        </app-variation-option>
      </div>
    </div>
  </div>
</div>
