import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ModifierIngredientV2, ModifierV2 } from '../../../../models/item';
import { GeneralSetting } from '../../../../services/generalSetting.service';
import { NestedModifierComponent } from '../../../nested-modifier/nested-modifier.component';
import { CommonFunctions } from '../../../../services/common';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-box-ingredient',
  templateUrl: './box-ingredient.component.html',
  styleUrls: ['./../../box-builder.component.css']
})
export class BoxIngredientComponent implements OnInit {
  ngOnDestroy() {

  }


  @Input() ingredient: any = {} as any;

  @Input() ingredientIndex: number = 0;

  @Input() parent: ModifierV2 = {} as ModifierV2;

  @Input() ItemType: number = 1;

  @Input() ItemName: string = '';

  @Output() parentChange = new EventEmitter<ModifierV2>();

  @Output() selectedIngredientIndex = new EventEmitter<number>();

  @Output() checkValidEmit = new EventEmitter<ModifierV2>();

  @Output() SelectedItem: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(NestedModifierComponent) nestedModifier!: NestedModifierComponent;

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  primaryColor: string = '';
  accentColor: string = '';
  image: string = '';

  deselectedImage: string = '';

  isSoldOut: boolean = false;

  quantity: number = 1;

  Price: string = '';

  isShowNoCharge = true;

  maxQty = 0;

  placeholderImage : string = '';

  constructor(
    private readonly isKioskService: IsKioskService,
    private readonly modalService: NgbModal,
    private readonly dataService: DataService
  ) { }

  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.image = this.getFormattedURL(this.ingredient);
    if (this.ingredient.IsModifier) {
      this.deselectedImage = this.getDeselectedFormattedURL(this.ingredient as ModifierV2);
    }


    this.primaryColor = GeneralSetting.getPrimaryColor();
    this.accentColor = GeneralSetting.getAccentColor();
    // this.object.parentObject = this.parent;

    this.quantity = Number(this.ingredient.Quantity);

    if (this.ingredient.IsIngredient) {
      this.ingredient = <ModifierIngredientV2>this.ingredient;

      if (this.ingredient.IsIngredient86 == 'False') {
        this.parent.isValid = true;
      }

      this.maxQty = Number(this.ingredient.MaxQty);

      this.isSoldOut =
        this.ingredient.CurrentStock !== ''
          ? this.ingredient.CurrentStock <= this.ingredient.LowThreshold
          : false;
    }

    this.setIngredientPrice();

    this.checkIsShowNoCharge();

    this.checkValidEmit.emit(this.parent);
  }

  ngOnChanges(): void {
    this.parent.count = CommonFunctions.getSelectedIngredientCount(this.parent);
  }

  private getFormattedURL(ingredient: any): string {
    if (ingredient.ImageUrl) {
      if (ingredient.ImageUrl.startsWith(this.baseImageUrl)) {
        return ingredient.ImageUrl;
      }
      else {
        if (ingredient.ImageUrl == "") {
          return this.getDeselectedFormattedURL(ingredient, true);
        }
        else {
          return this.baseImageUrl + ingredient.ImageUrl;
        }
      }
    } else if(this.placeholderImage != '') {
      return this.baseImageUrl+this.placeholderImage;
    } else {
      return "assets/images/Image_21.png";
    }
  }

  private getDeselectedFormattedURL(modifier: ModifierV2, fromGetFormattedURL: boolean = false): string {

    if (modifier.DeselectedImage && modifier.DeselectedImage.startsWith(this.baseImageUrl)) {
      return modifier.DeselectedImage;
    }
    else {
      if (modifier.DeselectedImage == "") {
        if (fromGetFormattedURL) {
          if(this.placeholderImage != ''){
            return this.baseImageUrl+this.placeholderImage;
          } else {
            return "assets/images/Image_21.png";
          }
        }
        else {
          return this.getFormattedURL(modifier);
        }
      }

      return this.baseImageUrl + modifier.DeselectedImage;
    }
  }

  itemClicked() {
    if ((!this.ingredient.IsSelected && this.parent.isValid && !(this.parent.count < this.parent.max) && this.parent.max > 1))
      return
    if (this.parent.IsModifier86 == 'False') return;

    if (this.isSoldOut) return;

    if (this.ingredient.IsIngredient) this.selectIngredient();
    //else this.selectModifier();
  }

  private selectIngredientLogic(
    parent: ModifierV2,
    IngredientIndex: number
  ): any {

    let ingredient = parent.Ingredients[IngredientIndex];

    if (!ingredient) {
      return undefined;
    }

    ingredient = <ModifierIngredientV2>ingredient;

    if (
      parent.IsModifier86 == 'False' ||
      ingredient.IsIngredient86 == 'False'
    ) {
      //if out of stock, return and set isSelected to false
      ingredient.IsSelected = false;
      ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);

      return false;
    } else {
      if (ingredient.IsSelected) {
        parent.count--;
        if (parent.count < 0) parent.count = 0;

        ingredient.IsSelected = false;

        ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);

        return false;
      }

      parent.count = CommonFunctions.getSelectedIngredientCount(parent);

      if (parent.count < parent.max) {
        parent.count++;

        ingredient.IsSelected = true;

        ingredient.Quantity = '1';

        parent.IsSelected = true;
      } else if (parent.max === 1) {
        // if only 1 selectable switch selection to newly selected object
        parent.count = 1;
        //parent.SelectedIngredients = [];
        for (const item of parent.Ingredients) {
          item.IsSelected = false;
          if (item.IsIngredient) {
            const objIng = item as ModifierIngredientV2;
            objIng.isNegativeModifier = this.getNegativeModifier(objIng);
          }
        }

        ingredient.IsSelected = true;
        ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);
        ingredient.Quantity = '1';
        parent.IsSelected = true;

      }

      if (parent.count <= parent.max && parent.count >= parent.min) {
        parent.isValid = true;
      }

      ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);

      parent.SelectedIngredients =
        CommonFunctions.getSelectedModifierIngredientList(parent.Ingredients);
      return true;
    }
  }

  private getNegativeModifier(ingredient: ModifierIngredientV2) {
    if (!ingredient.IsSelected && ingredient.IsDefault == 'True') {
      return true;
    }

    return false;
  }

  private selectIngredient() {
    this.selectIngredientLogic(this.parent, this.ingredientIndex);

    this.selectedIngredientIndex.emit(this.ingredientIndex);

    this.parentChange.emit(this.parent);
  }

  private selectModifierLogic(parent: ModifierV2, IngredientIndex: number) {
    let modifier = parent.Ingredients[IngredientIndex];

    if (!modifier) {
      return parent;
    }

    modifier = <ModifierV2>modifier;

    if (parent.IsModifier86 == 'False' || modifier.IsModifier86 == 'False') {
      //if out of stock, return and set isSelected to false
      modifier.IsSelected = false;

      this.deSelectAllNestedIngredient(modifier);
      return parent;
    } else {
      parent.count = CommonFunctions.getSelectedIngredientCount(parent);

      if (parent.count < parent.max) {
        parent.count++;

        modifier.IsSelected = true;

        parent.IsSelected = true;

        if (
          parent.SelectedIngredients.find(
            (x: any) => x.ModifierID == modifier.ModifierID
          ) == undefined
        ) {
          parent.SelectedIngredients.push(modifier);
        }
      } else if (parent.max === 1) {
        // if only 1 selectable switch selection to newly selected object
        parent.count = 1;
        parent.SelectedIngredients = [];
        for (const item of parent.Ingredients) {
          item.IsSelected = false;
        }

        modifier.IsSelected = true;
        parent.IsSelected = true;

        parent.SelectedIngredients.push(modifier);
      }
    }

    parent.count = CommonFunctions.getSelectedIngredientCount(parent);

    if (parent.count <= parent.max && parent.count >= parent.min) {
      parent.isValid = true;
      parent.IsSelected = true;
    }

    return parent;
  }

  private selectModifier() {
    let modifier = this.parent.Ingredients[this.ingredientIndex];

    if (!modifier) return;

    modifier = <ModifierV2>modifier;


    if (!modifier.IsSelected) {
      modifier.Quantity = "1";
    }

    if (this.parent.IsModifier86 == "False" || modifier.IsModifier86 == "False") return;

    if (this.parent.max > 1 && !modifier.IsSelected) {
      this.parent.count = CommonFunctions.getSelectedIngredientCount(this.parent);

      if (!(this.parent.count < this.parent.max || this.parent.max == 1)) return;
    }
    //this.parent.count = CommonFunctions.getSelectedIngredientCount(this.parent);

    //if (!(this.parent.count < this.parent.max || this.parent.max == 1)) return;

    this.openNestedModifier(modifier);
  }

  private openNestedModifier(modifier: ModifierV2) {
    // if (!this.nestedModifier) {
    //   this.nestedModifier = new NestedModifierComponent(
    //     this.modalService,
    //     this.isKioskService
    //   );
    // }

    let isHasAllModifier = true;

    if (modifier.Ingredients && modifier.Ingredients.length > 0) {

      for (let i = 0; i < modifier.Ingredients.length; i++) {
        if (modifier.Ingredients[i].IsIngredient) {
          isHasAllModifier = false;
          break;
        }
      }

    }

    var parentModifier = modifier;

    const modalReference = this.nestedModifier.open(isHasAllModifier ? modifier.Ingredients as ModifierV2[] : [modifier],
      this.ingredientIndex, parentModifier, this.ItemType, this.ItemName);
    modalReference.result.then((result: any) => {
      if (result == "cancel") {

        const modifier = this.parent.Ingredients[this.ingredientIndex];

        if (!modifier.IsSelected) {
          modifier.IsSelected = false;
        }

        this.quantity = Number(modifier.Quantity);
        this.parentChange.emit(this.parent);
      } else {

        result = <ModifierV2[]>result;

        if (isHasAllModifier) {

          //parentModifier.IsSelected = true;

          //parentModifier.Quantity = "1";

          parentModifier.Ingredients = result;

          // for (let i = 0; i < parentModifier.Ingredients.length; i++) {

          //   parentModifier = this.selectModifierLogic(parentModifier, i);

          // }

        }
        else {
          if (result.length > 0) {
            const modifier = result[0];

            this.quantity = Number(modifier.Quantity);

            this.parent.Ingredients[this.ingredientIndex] = modifier;

          }
        }

        this.parent = this.selectModifierLogic(this.parent, this.ingredientIndex);

        this.parent.count = CommonFunctions.getSelectedIngredientCount(
          this.parent
        );

        this.parentChange.emit(this.parent);
      }
    });
  }

  onCloseClick(e: any) {
    let modifier = this.parent.Ingredients[this.ingredientIndex];

    if (!modifier) return false;

    if (modifier.IsIngredient) {
      return false;
    }

    e.preventDefault();
    e.stopPropagation();

    modifier = <ModifierV2>modifier;

    modifier.IsSelected = false;

    modifier.isValid = false;

    this.deSelectAllNestedIngredient(modifier);

    modifier.count = 0;

    this.parent.count = CommonFunctions.getSelectedIngredientCount(this.parent);

    this.parentChange.emit(this.parent);

    return false;
  }

  private deSelectAllNestedIngredient(modifier: ModifierV2) {
    for (let i = 0; i < modifier.Ingredients.length; i++) {
      const ingredient: ModifierIngredientV2 | ModifierV2 =
        modifier.Ingredients[i];
      ingredient.IsSelected = false;

      if (ingredient.IsModifier) {
        const mod = ingredient as ModifierV2;
        this.deSelectAllNestedIngredient(mod);
      }
    }
  }

  private setIngredientPrice() {
    let price = 0;
    if (
      this.ingredient.ExtraPrice > 0 &&
      this.ingredient.IsPremium &&
      this.ingredient.IsPremium == 'True'
    ) {
      price = Number(this.ingredient.ExtraPrice) * this.quantity;
    } else if (
      this.ingredient != undefined &&
      this.ingredient.hasOwnProperty('IsModifier86') &&
      this.ingredient.Price > 0
    ) {
      price = Number(this.ingredient.Price) * this.quantity;
    }

    let extraPrice: string;

    if (price > 0)
      extraPrice = "$" + CommonFunctions.roundDigit(price, 2).toFixed(2);
    else
      extraPrice = "No Charge";

    this.Price = extraPrice;
  }

  onQuantityIncrease(e: any) {
    e.preventDefault();
    e.stopPropagation();

    const max = this.parent.max;
    const count = CommonFunctions.getSelectedIngredientCount(this.parent);

    if (count == max) {
      return;
    }

    if (this.parent.Ingredients[this.ingredientIndex].IsIngredient) {
      const maxIngredient = Number(
        (this.parent.Ingredients[this.ingredientIndex] as ModifierIngredientV2)
          .MaxQty
      );
      if (this.quantity >= maxIngredient) return;
    }

    this.quantity += 1;

    this.parent.Ingredients[this.ingredientIndex].Quantity =
      this.quantity.toString();

    this.setIngredientPrice();

    this.parent.count = CommonFunctions.getSelectedIngredientCount(this.parent);

    this.parentChange.emit(this.parent);
  }

  onQuantityDecrease(e: any) {
    e.preventDefault();
    e.stopPropagation();

    if (this.quantity == 1) {
      this.ingredient.IsSelected = false;

      this.ingredient.isNegativeModifier = this.getNegativeModifier(
        this.ingredient
      );

      this.parent.count = CommonFunctions.getSelectedIngredientCount(
        this.parent
      );

      this.parentChange.emit(this.parent);

      return;
    }

    this.quantity -= 1;

    this.parent.Ingredients[this.ingredientIndex].Quantity =
      this.quantity.toString();

    this.setIngredientPrice();

    this.parent.count = CommonFunctions.getSelectedIngredientCount(this.parent);

    this.parentChange.emit(this.parent);
  }

  checkIsShowNoCharge() {
    const priceIngredient = (this.parent as any).Ingredients.find(
      (x: any) => Number(x.ExtraPrice) > 0 || Number(x.Price)
    );

    if (priceIngredient) {
      this.isShowNoCharge = true;
    } else {
      this.isShowNoCharge = false;
    }
  }
}
