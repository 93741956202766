import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DatabaseHandler } from '../../DatabaseHandler';
import { LogService, loggingData } from 'src/app/services/log.service';
import { LogoutService } from 'src/app/services/logout.service';
import { Snackbar } from 'src/app/services/common';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-manager-pin-dialog',
  templateUrl: './manager-pin-dialog.component.html',
  styleUrls: ['./manager-pin-dialog.component.css'],
})
export class ManagerPinDialogComponent implements OnInit, OnDestroy {
  languageSub!: Subscription;
  passcode: string = '';
  passcodeCount: number = 1;

  passcode1: string = '';
  passcode2: string = '';
  passcode3: string = '';
  passcode4: string = '';

  confirmPinText: string = '';
  clearText: string = '';
  doneText: string = '';
  invalidCodeText: string = '';
  modalRef: any;
  isClicked: boolean = false;
  constructor(
    public modalService: NgbModal,
    public zone: NgZone,
    public router: Router,
    private logger: LogService,
    private logoutService: LogoutService,
    private language: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }
  ngOnDestroy() {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {
    this.confirmPinText = this.language.getTextElement('txt_confirm_pin');
    this.clearText = this.language.getTextElement('txt_clear');
    this.doneText = this.language.getTextElement('lbl_done');
    this.invalidCodeText = this.language.getTextElement('invalid_code_try_again');
  }

  public static open(modalService: NgbModal) {
    let modalRef = modalService.open(ManagerPinDialogComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalRef.componentInstance.modalRef = modalRef;

    return modalRef;
  }

  open() {
    let modalRef = this.modalService.open(ManagerPinDialogComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalRef.componentInstance.modalRef = modalRef;
    this.modalRef = modalRef;
  }

  close(reason: string) {
    this.passcode1 = '';
    this.passcode2 = '';
    this.passcode3 = '';
    this.passcode4 = '';
    this.passcodeCount = 1;
    if (reason === 'x') {
      this.modalRef.close();
    }
  }

  passcodeCallback = (tx: any, result: any): void => {
    if (result.rows.length > 0) {
      // dismiss modal before navigation

      // if entering pin for logout
      if (this.router.url === '/orderReview') {
        this.modalRef.close('login_success');
      } else if (this.router.url === '/settings/logout') {
        this.modalRef.close();
        this.logger.updateDeviceStatus("2");
        this.logoutService.wipeData();
        this.zone.run(() => this.router.navigateByUrl('/'));
        var log = new loggingData(
          'Branch Logout',
          'Branch Logout Succeeded',
          'BranchLogout Success Confirmation',
          `Branch Logout Successful`,
          true
        );
        this.logger.sendLogToServer(log);
      }
      // if entering pin for manager settings
      else {
        this.isClicked = false;
        this.modalRef.close();
        this.zone.run(() => this.router.navigateByUrl('/settings'));
      }
    } else {
      Snackbar.show(this.invalidCodeText, 2000);
      setTimeout(() => {
      this.close('clear');
        this.isClicked = false;
      }, 100);
    }
  };

  errorCallback(tx: any, result: any): void {
    this.isClicked = false;

    //app log: error in getting staff code
    var log = new loggingData(
      'KIOSK',
      'Manager Login Failed',
      'Log',
      result,
      false
    );
    this.logger.sendLogToServer(log);
  }

  checkPasscode() {
    var sqlString = `SELECT * FROM StaffMasters WHERE Code=?`;

    //add logger for mananger login click
    var log = new loggingData(
      'KIOSK',
      'Manager Login',
      'Log',
      'Manager login clicked.',
      false
    );
    this.logger.sendLogToServer(log);

    DatabaseHandler.executeSqlStatement(
      sqlString,
      [this.passcode],
      this.passcodeCallback,
      this.errorCallback
    );
  }

  onClick(value: any) {
    if (this.passcodeCount < 5) {
      const temp = 'passcode' + this.passcodeCount;
      this.passcodeCount++;
      if (temp == 'passcode1') {
        this.passcode1 = value.currentTarget.innerHTML;
      } else if (temp == 'passcode2') {
        this.passcode2 = value.currentTarget.innerHTML;
      } else if (temp == 'passcode3') {
        this.passcode3 = value.currentTarget.innerHTML;
      } else if (temp == 'passcode4') {
        this.passcode4 = value.currentTarget.innerHTML;
      } else {
      }
  
      if(this.checkForLength()) {
        this.isClicked = true;
        this.checkPasscode();
      }
    }
  }

  checkForLength(): boolean {
    this.passcode = this.passcode1 + this.passcode2 + this.passcode3 + this.passcode4;
    if (this.passcode.length == 4) return true;
    return false;
  }
}
