import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, 
  ViewChild,
} from '@angular/core';
import { ItemTransferService } from 'src/app/services/item-transfer.service';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { Variation, VariationOption } from 'src/app/models/Variations';
import {
  ComboGroupV2,
  ItemV2,
  ModifierIngredientV2,
  ModifierV2,
} from 'src/app/models/item';
import { environment } from 'src/environments/environment';
import { Category } from 'src/app/models/category';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { CommonFunctions } from 'src/app/services/common';
import { ChooseDonutCustomizePopupComponent } from '../../dialogs/choose-donut-customize-popup/choose-donut-customize-popup.component';
// import { BoxBuilderComponent } from '../box-builder.component';
import { Guid } from 'src/app/models/Guid';
import { CartService } from 'src/app/services/cart.service';
import { BoxBuilderFullComponent } from '../../dialogs/box-builder-full/box-builder-full.component';
// import { BoxBuilderReviewComponent } from '../../box-builder/box-builder-review/box-builder-item-review.component';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-box-builder-item-detail',
  templateUrl: './box-builder-item-detail.component.html',
  styleUrls: ['./../box-builder-item.component.css'],
})
export class BoxBuilderItemDetail implements OnInit, OnChanges {
  constructor(
    private readonly itemTransferService: ItemTransferService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly db: DatabaseService,
    private readonly isKioskService: IsKioskService,
    private logger: LogService,
    private readonly modalService: NgbModal,
    private language: LanguageService,
    private readonly dataService: DataService
  ) {
    this.item = null;
    this.modifierGroups = new Array<ModifierV2>();
    this.ItemID = this.route.snapshot.queryParams['id'];
    this.CategoryID = this.route.snapshot.queryParams['itemID'];
    this.guid = this.route.snapshot.queryParams['guid'];
    this.isOrderReviewEdit = this.route.snapshot.queryParams['reviewEdit'];
    this.isLoyalty = this.route.snapshot.queryParams['loyalty'];
    this.parentCategory = this.route.snapshot.queryParams['parentCategory'];
    this.referenceCategory = this.route.snapshot.queryParams['category'];
    this.allSubscription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }

  @ViewChild(ChooseDonutCustomizePopupComponent, { static: true })
  chooseDonutCustomizePopup!: ChooseDonutCustomizePopupComponent;

  @ViewChild('ModifierCategoryBar') modifierCategoryBar!: ElementRef;

  // @ViewChild(BoxBuilderReviewComponent) boxBuilderReviewModel!: BoxBuilderReviewComponent;

  @Input() modifiers: ModifierV2[] = [];

  @Input() comboGroups: ComboGroupV2[] = [] as ComboGroupV2[];

  @Input() isCombo: boolean = false;

  @Input() selectedItem: ItemV2 = new ItemV2();

  @Input() totalComboGroups: number = 0;

  @Input() selectedTotalCount: number = 0;

  @Input() inputItem: ItemV2 = new ItemV2();

  @Input() numInvisibleGroups: number = 0;

  //ComboGroup  Selection Variables
  @Output() isModifierValid: boolean = false;

  @Output() setVariationsValid: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() emitSelectedVariation: EventEmitter<VariationOption[]> =
    new EventEmitter<VariationOption[]>();

  @Output() isComboChanged = new EventEmitter<boolean>();

  @Output() selectedCountEmitter = new EventEmitter<number>();

  @Output() itemImageListEmitter = new EventEmitter<any[]>();

  @Output() modifierEmitter: EventEmitter<{
    inputModifier: ModifierV2[];
    isValid: boolean;
    modifierSelectedIndex: number;
  }> = new EventEmitter();

  @Output() itemModalClose: EventEmitter<ItemV2> = new EventEmitter();

  @Output() modifierSelectedIndexEmitter: EventEmitter<number> =
    new EventEmitter();

  @Output() openReviewScreenEmitter: EventEmitter<boolean> = new EventEmitter();

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  referenceCategory: any;

  modifierGroups: ModifierV2[] = [] as ModifierV2[];

  ItemID: string;

  CategoryID: string;

  isReady: boolean = false;

  ItemName: string = '';

  currentObjPos: number = 0;

  comboGroupSelectedIndex = 0;

  currentComboGroup: ComboGroupV2 | null = null;

  IsComboGroupSelected = false;

  isVariationSelected = false;

  isModifierSelected = false;

  categoryCards!: HTMLCollection;

  IsComboGroupValid: boolean = false;

  modifierSelectedIndex = 0;

  variations: Variation[] = [] as Variation[];

  comboGroup: ComboGroupV2[] = [] as ComboGroupV2[];

  boxBuilder: any;

  modalReference: any;

  selectedVariation: VariationOption = {} as VariationOption;

  isFirstTimeLoad = true;

  modifierStartingIndex: number = 0;

  item: ItemV2 | null;

  selectedCategory: any;

  categories: any;

  currentObj: any;

  // For Edit Item Feature
  guid: string = '';

  isOrderReviewEdit = false;
  // For Edit Item Feature end
  isLoyalty: string;

  isComboItem = false;

  category: Category = {} as Category;

  quantity: number = 1;

  currentCategory: any;

  branchLogo: string | null = '';

  parentCategory: string;

  selectedCount: number = 0;

  comboItemFlags: any[] = [];

  itemImageList: any[] = [];

  refItem!: ItemV2;
  allSubscription: Subscription[] = [];
  nextText: string = '';
  previousText: string = '';
  placeholderImage : string = '';

  @Output() comboItemFlagsEmitter = new EventEmitter<any[]>();
  loadText() {
    this.nextText = this.language.getTextElement('next');
    this.previousText = this.language.getTextElement('previous');
  }
  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.branchLogo = GeneralSetting.getBranchLogo();
    this.setDefault();
  }

  ngOnDestroy() {
    if (this.allSubscription.length > 0) {
      for (let i = 0; i < this.allSubscription.length; i++) {
        this.allSubscription[i].unsubscribe();
      }
    }
  }
  ngOnChanges() { }

  private setDefault() {
    let isCombo = this.isCombo.toString();

    if (isCombo.toLowerCase() == 'true') {
      if (this.comboGroups.length > 0) {
        this.onItemSelect(0);
      }
    } else {
      this.setDefaultModifiers();
    }
    this.isFirstTimeLoad = false;
  }

  private setDefaultModifiers() {
    this.modifierStartingIndex = 0;
    if (this.isFirstTimeLoad) {
      this.onModifierSelect(0);
    }
  }

  setDefaultComboItemFlags() {
    for (let i = 0; i < this.comboGroups[0].Items.length; i++) {
      const item = this.comboGroups[0].Items[i];
      this.comboItemFlags.push({
        ItemID: item.ItemID,
        selected: false,
        item: {} as ItemV2,
      });
    }
  }

  async getModifierGroups(id: string) {
    await this.db
      .getModifierByItemIDFromSql(id)
      .then(async (itemdata: any) => {
        this.modifierGroups = itemdata;
        this.modifiers =
          this.categories =
          this.modifierGroups =
          await this.db.getAllModifierIngredients(this.modifierGroups);
      })
      .then(() => {
        this.setDefaultModifierGroup();
      });
  }

  setDefaultModifierGroup() {
    this.currentObj = this.categories[0];

    // this.currentObj = this.categories[0];
    if (!this.referenceCategory) {
      // this.currentObj = res;
      this.changeCategory(this.currentObj?.CategoryID, this.currentObj);
    }
  }

  changeCategory(id: string, obj: Category) {
    this.changeCategoryID(obj);
    this.changeCategoryObj(obj);
  }

  changeCategoryID(obj: Category) {
    // console.log(obj);
    this.router.navigate([], { queryParams: { category: obj.CategoryID } });
    if (!obj.associatedItems || obj.associatedItems.length == 0) {
      try {
        var log = new loggingData(
          'Empty Category Error',
          'Category With No Items Reached',
          `UI/UX Error in Category ${obj.CategoryID}`,
          `Confirm Category Has Items To Display, or Consider Turning Category Off: ${obj}`,
          true
        );
        this.logger.sendLogToServer(log);
      } catch (e: any) {
        if (e.message.includes('btoa')) {
          var log = new loggingData(
            'Empty Category Logging Error',
            'Category With No Items Reached',
            `UI/UX Error in Category ${obj.CategoryID}`,
            `Error logging \${obj}: ${e.message}`,
            true
          );
          this.logger.sendLogToServer(log);
        } else {
          var log = new loggingData(
            'Empty Category Unknown Error',
            'Category With No Items Reached',
            `UI/UX Error in Category ${obj.CategoryID}`,
            `Unknown error logging \${obj}: ${e}`,
            true
          );
          this.logger.sendLogToServer(log);
        }
      }
    }
    this.selectedCategory = obj.CategoryID;
  }

  changeCategoryObj(id: any) {
    // this.selectedObject = id;
  }

  selectIngredientLogic(parent: ModifierV2, IngredientIndex: number): any {
    let ingredient = parent.Ingredients[IngredientIndex];

    if (!ingredient) {
      return undefined;
    }

    ingredient = <ModifierIngredientV2>ingredient;

    if (
      parent.IsModifier86 == 'False' ||
      ingredient.IsIngredient86 == 'False'
    ) {
      //if out of stock, return and set isSelected to false
      ingredient.IsSelected = false;
      ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);

      return false;
    } else {
      if (ingredient.IsSelected) {
        parent.count--;
        if (parent.count < 0) parent.count = 0;

        ingredient.IsSelected = false;

        ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);

        return false;
      }

      parent.count = CommonFunctions.getSelectedIngredientCount(parent);

      if (parent.count < parent.max) {
        parent.count++;

        ingredient.IsSelected = true;

        ingredient.Quantity = '1';

        parent.IsSelected = true;
      } else if (parent.max === 1) {
        // if only 1 selectable switch selection to newly selected object
        parent.count = 1;
        //parent.SelectedIngredients = [];
        for (const item of parent.Ingredients) {
          item.IsSelected = false;
          if (item.IsIngredient) {
            const objIng = item as ModifierIngredientV2;
            objIng.isNegativeModifier = this.getNegativeModifier(objIng);
          }
        }

        ingredient.IsSelected = true;
        ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);
        ingredient.Quantity = '1';
        parent.IsSelected = true;
      }

      if (parent.count <= parent.max && parent.count >= parent.min) {
        parent.isValid = true;
      }

      ingredient.isNegativeModifier = this.getNegativeModifier(ingredient);

      parent.SelectedIngredients =
        CommonFunctions.getSelectedModifierIngredientList(parent.Ingredients);
      return true;
    }
  }

  private getNegativeModifier(ingredient: ModifierIngredientV2) {
    if (!ingredient.IsSelected && ingredient.IsDefault == 'True') {
      return true;
    }

    return false;
  }

  openCustomBuild(position: any) {
    this.itemTransferService.snapshotItemV2(this.item!);
    this.router.navigate([`buildCustomItem`], {
      queryParams: {
        id: this.item!.ComboGroup[0].Items[position].ItemID,
        itemID: this.item!.ItemID,
        parentCategory: this.CategoryID,
      },
    });
  }
  onComboItemSelect(event: any, item: ItemV2, pos: number) {
    if (this.selectedCount == this.totalComboGroups - this.numInvisibleGroups) {
      if (!this.comboItemFlags[pos].selected && !(item.Modifiers.length > 0)) {
        
        BoxBuilderFullComponent.open(this.modalService,this.selectedCount).result
        .then((res: any) => {
          if (res == 'review') {
            this.openReviewScreenEmitter.emit(true);
          }
        });
        if (event.target) {
          event.target.checked = false;
          return;
        }
      } else if (item.Modifiers.length > 0) {
        BoxBuilderFullComponent.open(this.modalService,this.selectedCount).result
        .then((res: any) => {
          if (res == 'review') {
            this.openReviewScreenEmitter.emit(true);
          }
        });
        if (event.target) {
          event.target.checked = false;
          return;
        }
      }
    }
    let itemNew = JSON.parse(JSON.stringify(item)) as ItemV2;

    if (event.target) {
      if (!this.comboItemFlags[pos].selected) {
        this.chooseDonutCustomizePopup.open(
          false,
          this.baseImageUrl,
          item,
          this.totalComboGroups - this.numInvisibleGroups,
          this.selectedCount
        );
        this.chooseDonutCustomizePopup.ref.result.then((res: any) => {
          if (res.status) {
            item.guid = Guid.newGuid();
            item.BoxBuilderItemGUID = Guid.newGuid();
            this.setItemQuantitySelection(item, res.Quantity, pos);
          }
        });
      } else {
        //DESELECT ITEM
        item.ItemConsolidateQuantity =
          this.comboItemFlags[pos].item.ItemConsolidateQuantity;
        this.chooseDonutCustomizePopup.open(
          true,
          this.baseImageUrl,
          item,
          this.totalComboGroups - this.numInvisibleGroups,
          this.selectedCount
        );
        this.chooseDonutCustomizePopup.ref.result.then((res: any) => {
          if (res == 'delete') {
            this.deselectItem(item, pos);
          } else if (res.status) {
            this.deselectItem(item, pos);
            this.setItemQuantitySelection(item, res.Quantity, pos);
          }
        });
      }
    }
  }

  private setItemQuantitySelection(
    item: ItemV2,
    quantity: number,
    itemPos: number
  ) {
    var count = 0;
    if (this.comboGroups && this.comboGroups.length > 0) {
      for (let i = 0; i < this.comboGroups.length; i++) {
        let items = this.comboGroups[i].Items;
        if (this.hasItemSelected(this.comboGroups[i])) {
          continue;
        } else {
          for (let j = 0; j < items.length; j++) {
            const comboItem = items[j];
            if (comboItem.ItemID == item.ItemID) {
              count++;
              if (count <= quantity) {
                // console.log("combo group", this.comboGroups[i].GroupName);
                comboItem.isSelected = true;
                comboItem.Quantity = '1';
                comboItem.Modifiers = item.Modifiers;
                comboItem.guid = item.guid;
                comboItem.BoxBuilderItemGUID = item.BoxBuilderItemGUID;
                comboItem.ItemConsolidateQuantity = quantity;
                this.comboGroups[i].Items[j] = comboItem;

                // this.comboGroups[0].Items[itemPos] = item;
                if (count == quantity) {
                  item.ItemConsolidateQuantity = quantity;
                  // console.log("After selection combogroups:", this.comboGroups);
                  // this.comboGroups[0].Items[itemPos] = comboItem;
                  this.updateComboItemFlag(item, true);
                }
                break;
              }
            }
          }
        }
      }
    }

    this.selectedItemCount();
  }

  private hasItemSelected(comboGroup: ComboGroupV2): boolean {
    var selectedCount = 0;
    for (let i = 0; i < comboGroup.Items.length; i++) {
      const item = comboGroup.Items[i];
      if (item.isSelected) {
        selectedCount++;
      }
    }
    return selectedCount == Number(comboGroup.CountOption);
  }

  private selectedItemCount() {
    this.selectedCount = 0;
    this.itemImageList = [];
    for (let i = 0; i < this.comboGroups.length; i++) {
      const items = this.comboGroups[i].Items;
      for (let j = 0; j < items.length; j++) {
        if (!this.comboGroups[i].isHidden && items[j].isSelected) {
          this.itemImageList.push({
            image: this.baseImageUrl + items[j].ImageUrl,
            selected: true,
          });
          this.selectedCount++;
        }
      }
    }

    var itemImageLength = this.itemImageList.length;
    for (
      let i = 0;
      i < this.comboGroups.length - this.numInvisibleGroups - itemImageLength;
      i++
    ) {
      this.itemImageList.push({
        image: '../../../assets/images/box-builder/plain.png',
        selected: false,
      });
    }
    this.selectedCountEmitter.emit(this.selectedCount);
    this.itemImageListEmitter.emit(this.itemImageList);
    // console.log("selected item count", this.selectedCount);
  }

  private deselectItem(item: ItemV2, itemPos: number) {
    var count = 0;
    var quantity: number =
      this.comboItemFlags[itemPos].item.ItemConsolidateQuantity;
    if (this.comboGroups && this.comboGroups.length > 0) {
      for (let i = 0; i < this.comboGroups.length; i++) {
        let items = this.comboGroups[i].Items;
        if (this.hasItemSelected(this.comboGroups[i])) {
          for (let j = 0; j < items.length; j++) {
            const comboItem = items[j];
            if (comboItem.ItemID == item.ItemID && comboItem.isSelected) {
              count++;
              if (count <= quantity) {
                // console.log("combo group", this.comboGroups[i].GroupName);
                comboItem.isSelected = false;
                comboItem.Quantity = '1';
                comboItem.ItemConsolidateQuantity = 0;
                this.comboGroups[i].Items[j] = comboItem;
                this.updateComboItemFlag(comboItem, false);
                item.ItemConsolidateQuantity = 1;
                // this.comboGroups[0].Items[itemPos] = item;
                break;
              }
            }
          }
        }
      }
    }
    this.selectedItemCount();
  }

  parentChangeIngredient(modifier: ModifierV2) {
    this.modifiers[this.modifierSelectedIndex] = modifier;

    setTimeout(() => {
      const selectedIngredient =
        CommonFunctions.getSelectedIngredientCount(modifier);
      if (modifier.max == selectedIngredient) {
        this.onNextClick();
        return;
      }
    }, 50);

    this.checkModifierValid();
  }

  onItemSelect(index: number) {
    this.comboGroupSelectedIndex = index;

    this.currentComboGroup = this.comboGroups[index];

    this.IsComboGroupSelected = true;
    this.isVariationSelected = false;
    this.isModifierSelected = false;

    // this.scrollToCard();
    this.checkItemValid();
  }

  async checkItemValid(openNext: boolean = false) {
    // is valid by default
    this.IsComboGroupValid = true;

    if (this.comboGroups && this.comboGroups.length > 0) {
      for (let i = 0; i < this.comboGroups.length; i++) {
        const comboGroup = this.comboGroups[i];

        const groupSelectedItem =
          CommonFunctions.getParentCountItem(comboGroup);

        // min and max data can be not correct to fix them and check
        if (comboGroup.min != 0 && comboGroup.min < 0) {
          comboGroup.min = 0;
        }

        if (comboGroup.max <= 0) {
          comboGroup.max = 99999;
        }

        if (comboGroup.IsSelected) {
          if (comboGroup.Items.length > 0) {
            if (
              groupSelectedItem >= comboGroup.min &&
              groupSelectedItem <= comboGroup.max
            ) {
              comboGroup.isValid = true;
            } else {
              this.IsComboGroupValid = false;
              comboGroup.isValid = false;
            }
          }
        } else {
          if (
            groupSelectedItem >= comboGroup.min &&
            groupSelectedItem <= comboGroup.max
          ) {
            comboGroup.isValid = true;
            comboGroup.isSelected = true;
          } else {
            comboGroup.isSelected = false;
            this.IsComboGroupValid = false;
          }
        }

        //direct to error item page
        if (openNext) {
          if (comboGroup.isValid) {
            if (i == this.comboGroupSelectedIndex) {
              if (this.comboGroupSelectedIndex != this.comboGroups.length - 1) {
                this.onItemSelect(this.comboGroupSelectedIndex + 1);
              } else {
                this.onItemSelect(this.comboGroupSelectedIndex);
              }
            }
          } else {
            this.onItemSelect(i);
            break;
          }
        }
      }

      // this.emitComboGroup.emit({
      //   inputComboGroup: this.comboGroups,
      //   isValid: this.IsComboGroupValid,
      // });
    }
  }

  onModifierSelect(index: number) {
    this.modifierSelectedIndex = index;

    this.isModifierSelected = true;

    this.isVariationSelected = false;

    this.checkModifierValid();

    setTimeout(() => {
      this.scrollModifierInCenter();
    }, 400);
  }

  async checkModifierValid(openNext: boolean = false) {
    // is valid by default
    this.isModifierValid = true;

    if (this.modifiers && this.modifiers.length > 0) {
      for (let i = 0; i < this.modifiers.length; i++) {
        const modifier = this.modifiers[i];

        const selectedIngredient =
          CommonFunctions.getSelectedIngredientCount(modifier);

        if (modifier.IsSelected) {
          //if (modifier.Ingredients.length > 0) {
          if (
            selectedIngredient >= modifier.min &&
            selectedIngredient <= modifier.max
          ) {
            modifier.isValid = true;
            modifier.IsSelected = true;
          } else {
            modifier.isValid = false;
            this.isModifierValid = false;
          }
          //}
        } else {
          if (selectedIngredient > 0 || selectedIngredient == modifier.min) {
            if (
              selectedIngredient >= modifier.min &&
              selectedIngredient <= modifier.max
            ) {
              modifier.isValid = true;
              modifier.IsSelected = true;
            } else {
              modifier.isValid = false;
              this.isModifierValid = false;
            }
          } else if (modifier.min != 0) {
            modifier.isValid = false;
            this.isModifierValid = false;
          }
        }
        /**
         * https://grubbrr.atlassian.net/browse/SK-1920 - Modifier group isselected flag update @nilesh
         */
        if (selectedIngredient == 0) {
          modifier.IsSelected = false;
        }

        if (openNext) {
          if (modifier.isValid) {
            if (i == this.modifierSelectedIndex) {
              if (this.modifierSelectedIndex != this.modifiers.length - 1) {
                this.onModifierSelect(this.modifierSelectedIndex + 1);
              } else {
                this.onModifierSelect(this.modifierSelectedIndex);
              }
            }
          } else {
            this.onModifierSelect(i);
            break;
          }
        }
      }

      this.modifierEmitter.emit({
        inputModifier: this.modifiers,
        isValid: this.isModifierValid,
        modifierSelectedIndex: this.modifierSelectedIndex,
      });
    }

    // this.showFixedItemButton();
  }

  onNextClick() {
    if (this.modifiers.length > 0) {
      if (this.isModifierSelected) {
        if (this.modifierSelectedIndex != this.modifiers.length - 1) {
          this.onModifierSelect(this.modifierSelectedIndex + 1);
          // this.categoryCards[this.modifierSelectedIndex + 1].scrollIntoView({
          //   block: 'center',
          //   inline: 'center',
          // });
        }
      } else {
        this.onModifierSelect(0);
      }
    }

    this.checkModifierValid();
  }

  private scrollModifierInCenter() {
    if (this.modifierCategoryBar) {
      let elm = this.modifierCategoryBar.nativeElement as HTMLElement;

      if (elm) {
        let activeElm = elm.querySelector('.active');

        if (activeElm) {
          activeElm.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }
      }
    }
  }

  onPreviousClick() {
    if (this.isModifierSelected && this.modifiers.length > 0) {
      if (this.modifierSelectedIndex == 0) {
        return;
      } else {
        this.onModifierSelect(this.modifierSelectedIndex - 1);
        // this.categoryCards[this.modifierSelectedIndex - 1].scrollIntoView(
        //   {
        //     block: 'center',
        //     inline: 'center',
        //   }
        // );
      }
    }

    // this.showFixedItemButton();
  }

  updateComboItemFlag(comboItem: ItemV2, flag: boolean) {
    for (let i = 0; i < this.comboItemFlags.length; i++) {
      const element = this.comboItemFlags[i];
      if (element.ItemID == comboItem.ItemID) {
        element.selected = flag;

        if (
          element.selected &&
          element.item.Modifiers &&
          element.item.Modifiers.length > 0
        ) {
          comboItem.ItemConsolidateQuantity = this.getCountForCustomItemQty(
            element.item.ItemID
          );
        } else {
          //comboItem.ItemConsolidateQuantity = comboItem.ItemConsolidateQuantity;
        }
        element.item = comboItem;
        //element.item.isSelected = flag;
        this.comboItemFlags[i] = element;
      }
    }
    this.comboItemFlagsEmitter.emit(this.comboItemFlags);
  }

  getCountForCustomItemQty(itemId: string) {
    let comboItems: any = [];
    this.comboGroups.forEach((comboGrp) => {
      let itm = comboGrp.Items.filter(
        (x) => x.isSelected && x.ItemID == itemId
      );
      comboItems = comboItems.concat(itm);
    });
    // console.log(comboItems);
    return comboItems.length;
  }
  addToCart() {
    const item = this.createCartItem();

    this.closeModel(item);
  }

  private createCartItem(): ItemV2 {
    const cartItem = JSON.parse(JSON.stringify(this.item)) as ItemV2;

    cartItem.guid = Guid.newGuid();
    cartItem.Modifiers = this.modifiers;
    cartItem.ComboGroup = [];
    cartItem.Quantity = this.item!.Quantity;
    cartItem.IsCombo = !(
      cartItem.IsCombo.toString() == '0' ||
      cartItem.IsCombo.toString().toLowerCase() == 'false'
    );
    cartItem.AllowQuantitySelection =
      cartItem.AllowQuantitySelection == ''
        ? 'True'
        : cartItem.AllowQuantitySelection;
    cartItem.totalPrice =
      CommonFunctions.getItemPrice(cartItem) * Number(cartItem.Quantity);

    return cartItem;
  }

  closeModel(item: ItemV2) {
    this.itemModalClose.emit(item);
  }
}
