import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { Printer } from 'src/app/models/Printer';
import { DatabaseService } from 'src/app/services/database.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';
import { loggingData, LogService } from 'src/app/services/log.service';
import { PrinterService } from 'src/app/services/printer.service';
import { SaveSettingsService } from 'src/app/services/save-settings.service';

@Component({
  selector: 'app-settings-generalsettings',
  templateUrl: './settings-generalsettings.component.html',
  styleUrls: ['./settings-generalsettings.component.css'],
})
export class SettingsGeneralsettingsComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();        
      }
    }
  }

  @Output() printersRefreshFlag = new EventEmitter<boolean>();

  private allSubsCription: Subscription[] = [];

  printerThankyouMessage!: string;
  orderPrefix!: string;
  KitchenPrinters: Printer[] = [];
  InvoicePrinters: Printer[] = [];
  hasRefreshed: boolean = false;
  baudRate: boolean = GeneralSetting.getBaudRate() == '115200';
  refreshText: string = '';
  printerNameText: string = '';
  ipAddressText: string = '';
  printerModelText: string = '';
  statusText: string = '';
  kioskText: string = '';
  thankYouText: string = '';
  baudRateText: string = '';
  noPrintersText: string = '';
  readyText: string = '';
  notReadyText: string = '';
  testPrintText: string = '';
  invoiceMerchantText: string = '';
  kitchenPrintersText: string = '';
  printerSettingsText: string = '';

  constructor(
    private printerService: PrinterService,
    private saveSettingsService: SaveSettingsService,
    private db: DatabaseService,
    private language: LanguageService,
    private logger: LogService
  ) {
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }

  loadText() {
    this.refreshText = this.language.getTextElement('lbl_refresh');
    this.printerSettingsText = this.language.getTextElement('printer_settings');
    this.kitchenPrintersText = this.language.getTextElement('kitchen_printers');
    this.invoiceMerchantText = this.language.getTextElement(
      'invoice_merchant_printers'
    );
    this.testPrintText = this.language.getTextElement('test_print');
    this.readyText = this.language.getTextElement('ready');
    this.notReadyText = this.language.getTextElement('not_ready');
    this.printerNameText = this.language.getTextElement('text_printer_name');
    this.ipAddressText = this.language.getTextElement('text_ip_address');
    this.printerModelText = this.language.getTextElement('text_printer_model');
    this.statusText = this.language.getTextElement('title_status');
    this.kioskText = this.language.getTextElement('kiosk_prefix');
    this.thankYouText = this.language.getTextElement('kiosk_thankyou');
    this.baudRateText = this.language.getTextElement('baud_rate');
    this.noPrintersText = this.language.getTextElement('no_printers_found');
  }
  ngOnInit() {
    this.reloadSelf();
    this.refreshKitchenPrinters();
    if (this.language.getCustomMapValues('menu_setting_receipt__thank_you_message_text')) {
      this.printerThankyouMessage = this.language.getCustomMapValues('menu_setting_receipt__thank_you_message_text');        
    } else {
      this.printerThankyouMessage = GeneralSetting.getThankYouMessage();
    }
    this.orderPrefix = GeneralSetting.getOrderPrefix();
  }

  public getKitchenPrinters() {
    this.allSubsCription.push(
      this.db.getKitchenPrinters(false).subscribe((printers) => {
        this.KitchenPrinters = printers.filter(
          (p: Printer) => {
            this.sendPrinterStatus(p);
            return(p.IsKitchenPrinter == 'True')
          }
        );
        this.InvoicePrinters = printers.filter(
          (p: Printer) => {
            this.sendPrinterStatus(p);
            return(p.IsInvoicePrinter == 'True')
          });
        this.hasRefreshed = true;
        this.refreshPrintersFlag(true);
      })
    );
  }

  public async refreshKitchenPrinters() {
    this.allSubsCription.push(
      this.db.getKitchenPrinters(true).subscribe((printers) => {
        this.KitchenPrinters = printers.filter(
          (p: Printer) =>{ 
            this.sendPrinterStatus(p);
            return(p.IsKitchenPrinter == 'True')
          });
        this.InvoicePrinters = printers.filter(
          (p: Printer) => {
            this.sendPrinterStatus(p);
            return(p.IsInvoicePrinter == 'True')
          }
        );
        this.hasRefreshed = true;
        this.refreshPrintersFlag(true);
      })
    );
  }

  refreshPrintersFlag(value: boolean) {
    this.printersRefreshFlag.emit(value);
  }

  public sendPrinterStatus(printerData : Printer){
    var log = new loggingData(
      `Printer Status`,
      `Printer Connectivity Status`,
      `${printerData.BrandName ?? ''} Printer`,
      `Type : ${printerData.IsInvoicePrinter == 'True' ? 'Merchant Copy' : 'KOT'}
       Printer IP : ${printerData.IpAddress ?? ''}
       Printer : ${printerData.BrandName ?? ''}
       Printer Model : ${printerData.ModelName ?? ''}
       reason : ${(printerData.isConnected == 'true') ? 'Printer Connected' : 'Printer Disconnected'}`,
      false
    );
    this.logger.sendLogToServer(log);
  }

  public refreshKitchenPrintersWrapper() {
    this.hasRefreshed = false;
    this.refreshPrintersFlag(false);
    this.refreshKitchenPrinters();
  }

  public testStarPrint(printer: any) {
    this.allSubsCription.push(
      this.printerService
        .doSTARTestPrint(printer)
        .pipe(timeout(10000))
        .subscribe((starPrinter) => {})
    );
  }

  public setBaudRate(event: any) {
    let isChecked = event.target.checked;
    if (isChecked) {
      this.baudRate = true;      
      GeneralSetting.setBaudRate('115200');
    } else {
      this.baudRate = false;
      GeneralSetting.setBaudRate('9600');
    }
  }
  reloadSelf() {
    this.allSubsCription.push(
      this.saveSettingsService.reloadSelfSubject.subscribe((shouldReload) => {
        if (shouldReload) {
          this.hasRefreshed = false;
          this.refreshPrintersFlag(false);
          this.refreshKitchenPrinters();
        }
      })
    );
  }

}
