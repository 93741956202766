import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class NetworkGuardService {
  allSubscriptions: Subscription[] = [];
  private baseApiUrl = environment.apiUrl;
  constructor(
    private router: Router,
    private readonly http: HttpClient,
    private _ngZone: NgZone
  ) {}
  async ping() {
    let onlineCheck = await fetch(this.baseApiUrl + 'IsOnlineCheckSK')
      .then(async (res) => {})
      .then((a) => {
        return true;
      })
      .catch((e) => {
        return false;
      });
    return onlineCheck;
    // return this.http
    //   .post<any>(this.baseApiUrl + 'system/status', { headers })
    //   .toPromise();
  }
  redirect() {
    this.router.navigateByUrl('/networkerror');
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>(async (resolve) => {
      try {
        let onlineCheck = await this.ping();
        resolve(onlineCheck);
        if (!onlineCheck) {
          this._ngZone.run(this.redirect.bind(this));
        }
      } catch (e) {
        this._ngZone.run(this.redirect.bind(this));
        resolve(false);
      }
    });
  }
}
