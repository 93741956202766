import { Injectable } from '@angular/core';
import { ItemV2 as Item } from '../models/item';
import { BehaviorSubject } from 'rxjs';
import { Category } from 'src/app/models/category';

@Injectable({
  providedIn: 'root',
})
export class ItemTransferService {
  public itemSubject = new BehaviorSubject<Item>({} as Item);

  public currentItemCategory!: Category;

  itemV2!: Item;
  constructor() {}

  snapshotItem(item: Item) {
    this.itemV2 = item;
    this.itemSubject.next(item);
  }

  //toDo remove
  snapshotItemV2(item: Item) {
    this.itemV2 = item;
    this.itemSubject.next(item);
  }

  getItem() {
    return this.itemV2;
  }

  //toDo remove
  getItemV2() {
    return this.itemV2;
  }

  snapshotCurrentItemCategory(category:Category) {
    this.currentItemCategory = category;
  }

  getSnapshotItemCategory() {
    return this.currentItemCategory;
  }

  logItem() {
    //console.log(this.item);
  }
}
