import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatService {

  // usege 
  // this.chat.sendMessage(SocketMessageType[SocketMessageType.Message].toString(), data to be sent type any);
  // add unique subscription for node to client access
  // ack = this.socket.fromEvent<string>(message type emitted from node);

  ack = this.socket.fromEvent<string>('ack');

  ackStar = this.socket.fromEvent<string>('starResponse');

  offlineTimeOut = this.socket.fromEvent<boolean>('offlineTimeOut');

  //verifone EVO Subscription


  consoleMessages = this.socket.fromEvent<any>('consoleMessage');

  //verifone Evo Subscription end

  //Device status update on every 2 mmins
  deviceStatus = this.socket.fromEvent<boolean>('deviceStatus');

  starPrinterACK = this.socket.fromEvent<string>('starPrinterACK');
  
  constructor(private socket: Socket) {
     this.consoleLogSub();
  }

  private consoleLogSub(){
    if(environment.IsShowLog){
      this.consoleMessages.subscribe(data => {
        console.log("Node Logs :- ",data);
      });
    }
  }

  // pass unique message type and message name 
  sendMessage(messageType: string, message: any) {
    this.socket.emit(messageType, message);
  }

}

export enum SocketMessageType {
  Ack = 1,
  Message = 2,
  data = 3,
}
