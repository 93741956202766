<div id="configureDevice">
  <div class="modal-body configureDevice-modal">
    <div class="content">
      <p class="text-center bold">{{configureText}}</p>

            <div class="modal-footer">
                <div class="row col-12 col-md-12 popup-footer row text-center">
                    <button matRipple class="white-btn warn text-uppercase" (click)="cancelModal()">{{noText}}</button>
                    
                    <button matRipple class="yes-btn text-uppercase" (click)="configureDevice()">{{yesText}}</button>
                </div>
            </div>

        </div>
    </div>
</div>
