import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-ask-configure-your-device',
  templateUrl: './ask-configure-your-device.component.html',
  styleUrls: ['./ask-configure-your-device.component.css'],
})
export class AskConfigureYourDeviceComponent implements OnInit,OnDestroy {
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  
  ref: any;
  
  languageSub!: Subscription;
  
  configureText: string = '';
  
  yesText: string = '';
  
  noText: string = '';
  
  constructor(
    private modalService: NgbModal,
    private language: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  loadText() {
    this.configureText = this.language.getTextElement(
      'txt_do_you_want_to_configure_your_device'
    );
    this.noText = this.language.getTextElement('txt_no');
    this.yesText = this.language.getTextElement('txt_yes');
  }

  public static open(modalService: NgbModal) {
    let ref = modalService.open(AskConfigureYourDeviceComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    ref.componentInstance.ref = ref;
    return ref;
  }

  open() {
    this.ref = this.modalService.open(AskConfigureYourDeviceComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    this.ref.componentInstance.ref = this.ref;
  }

  cancelModal = () => {
    this.ref.close('no');
  };
  
  configureDevice = () => {
    this.ref.close('yes');
  };

}
