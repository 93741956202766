import { ItemTagsKeyComponent } from './../item-tags-key/item-tags-key.component';
import { ItemBuilderModeSelectionComponent } from './../item-builder-mode/item-builder-mode-selection/item-builder-mode-selection.component';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SpecialRequestDialogComponent } from 'src/app/components/dialogs/special-request-dialog/special-request-dialog.component';
import { Guid } from 'src/app/models/Guid';
import { ItemAskNamePopupComponent } from '../dialogs/item-ask-name-popup/item-ask-name-popup.component';
import { UpSellDialogComponent } from '../dialogs/up-sell-dialog/up-sell-dialog.component';
import { VariationOption } from '../../models/Variations';
import { Subscription } from 'rxjs';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { environment } from '../../../environments/environment';
import { AlcoholicItemsExceedMaxComponent } from '../dialogs/alcoholic-items-exceed-max/alcoholic-items-exceed-max.component';
import { LastfiveorderService } from 'src/app/services/lastfiveorder.service';

import { ItemTransferService } from 'src/app/services/item-transfer.service';
import {
  Snackbar,
  CategorySalesType,
  CommonFunctions,
} from '../../services/common';
import { GeneralSetting } from '../../services/generalSetting.service';
import { ModifierV2, ItemV2, ComboGroupV2 } from '../../models/item';
import { ModifierContainerComponent } from '../modifier-container/modifier-container.component';

// ReSharper disable once UnusedLocalImport
import { DatabaseService } from '../../services/database.service';
import { ComboGroupContainerComponent } from '../combo-group-container/combo-group-container.component';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { ItemIndividualComponent } from '../item-individual/item-individual.component';

import { Concessionaire } from '../../models/Concessionaire';
import { ItemTentPopupComponent } from '../dialogs/item-tent-popup/item-tent-popup.component';
import { LanguageService } from 'src/app/services/language.service';
import { ItemService } from 'src/app/services/ItemService';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { max } from 'lodash';
import { FixedItemDiscount } from 'src/app/models/discounts';
import { DiscountService } from 'src/app/services/discount.service';
import { KeyboardService } from 'src/app/services/keyboard.service';

@Component({
  selector: 'app-item-footer',
  templateUrl: './item-footer.component.html',
  styleUrls: ['./item-footer.component.css'],
})
export class ItemFooterComponent implements OnInit {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  constructor(
    route: ActivatedRoute,
    ItemTransferService: ItemTransferService,
    private readonly router: Router,
    private readonly CartService: CartService,
    private readonly cartService: CartService,
    private readonly lastfiveorderService: LastfiveorderService,
    private readonly loyaltyService: LoyaltyService,
    private readonly isKioskService: IsKioskService,
    private readonly databaseService: DatabaseService,
    private readonly itemService: ItemService,
    private readonly modalService: NgbModal,
    private KeyboardService: KeyboardService,
    private language: LanguageService,
    private readonly discountService: DiscountService,
    private cdr:ChangeDetectorRef
  ) {
    this.itemTransferService = ItemTransferService;
    this.isLoyalty = route.snapshot.queryParams['loyalty'];
    this.isOrderReviewEdit = route.snapshot.queryParams['reviewEdit'];
    this.concessionaireId = route.snapshot.queryParams['concessionaireId'];
    this.isScannedItem = route.snapshot.queryParams['isScannedItem'];
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }

  isLoyalty: string;

  cartItems: ItemV2[] = [];

  @ViewChild(ItemTagsKeyComponent) itemTagsKeyComponent!: ItemTagsKeyComponent;

  @Input() totalCost: number = 0.0;

  @Input() modifiers: ModifierV2[] = [];

  @Input() variations: VariationOption[] = [];

  @Input() comboGroup: ComboGroupV2[] = [];

  @Input() itemID: any;

  @Input() categoryID: any;

  @Input() isModifiersValid: boolean = false;

  @Input() isVariationsValid: boolean = false;

  @Input() isComboGroupValid: boolean = false;

  @Input() isReady: boolean = false;

  @Input() inputItem: ItemV2 = new ItemV2();

  @Input() modifierContainer!: ModifierContainerComponent;

  @Input() comboGroupContainer!: ComboGroupContainerComponent;

  @Input() builderMode!: ItemBuilderModeSelectionComponent;

  @Input() individualMode!: ItemIndividualComponent;

  @Input() cartQuantity: number = 0;

  @Input() quantityWhenAdd: number = 1;

  @Input() isComboItem = false;

  @Input() isComingFromScan = false;

  @Output() quantityChange = new EventEmitter<number>();

  @Output() comboModelClose = new EventEmitter<any>();

  itemTransferService: ItemTransferService;

  loyaltyType: number = 0;

  itemInCart: any;

  category: any;

  disableSpecialRequest: string | null = 'false';

  primaryColor: string | null = '';

  secondaryColor: string | null = '';

  positiveButton: string | null = '';

  positiveButtonText: string | null = '';

  negativeButton: string | null = '';

  negativeButtonText: string | null = '';

  priceColor: string | null = '';

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  itemGuestName: string = '';

  // @Input() quantity: number = 1;
  quantity: number = 1; //to check max and min number

  trueQuantity: number = 0;

  specialRequest: string = '';

  upSellModelRef: any;

  upSellLength: number = 0;

  upSellCartItems = [] as ItemV2[];

  isAlkol: boolean = false;

  canAddIntoCart: boolean = true;

  specialRequestText: string = ''

  isOrderReviewEdit = 'false';

  isItemIndividual: boolean = false;

  concessionaire: Concessionaire = {} as Concessionaire;

  isConcessionaire: boolean = false;

  concessionaireId: string = '';

  isScannedItem: boolean = false;

  itemTagsSet = new Set();
  @Output() itemTags: any = [];
  allTags: any;
  isEdit = false;
  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();

  cancelText: string = '';
  totalText: string = '';
  pricePerText: string = '';
  quantityText: string = '';
  reachMaxQuantityText: string = '';
  itemWeightText: string = '';
  ozText: string = '';
  poundText: string = '';
  gramText: string = '';
  kilogramText: string = '';
  addToCartText: string = '';

  pricePerItem: number = 0.0;
  fixedItemDisc: FixedItemDiscount[] = [] as FixedItemDiscount[];

  loadText() {
    this.cancelText = this.language.getTextElement('lbl_button_cancel');
    this.totalText = this.language.getTextElement('text_total');
    this.pricePerText = this.language.getTextElement('txt_price_per');
    this.quantityText = this.language.getTextElement('txt_quantity');
    this.reachMaxQuantityText = this.language.getTextElement('reached_maximum_quantity');
    this.itemWeightText = this.language.getTextElement('lbl_item_weight');
    this.ozText = this.language.getTextElement('lbl_oz');
    this.poundText = this.language.getTextElement('lbl_pound');
    this.gramText = this.language.getTextElement('lbl_gram');
    this.kilogramText = this.language.getTextElement('lbl_kilogram');
    this.addToCartText = this.language.getTextElement('lbl_add_to_cart');
  }
  ngOnInit(): void {
    this.isItemIndividual = this.router.url.startsWith('/itemDescription');

    this.isConcessionaire =
      GeneralSetting.getIsConcessionaire().toLowerCase() == 'true';

    this.getItems();

    this.calculateTotal();

    this.getUpSellLength();

    this.InitData();

    this.doSubscriptions();

    this.setFixedItemDisc();
  }

  async setFixedItemDisc() {
    this.fixedItemDisc = await this.discountService.getFixedItemDisc();
  }


  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.setItemTags();
  }

  setItemTags() {
    this.itemTagsSet = new Set();
    if (this.builderMode) {
      if (this.builderMode.currentModifier) {
        const currentModifier = this.builderMode.currentModifier as ModifierV2;
        for (const ingredient of this.builderMode.currentModifier
          .Ingredients as any) {
          for (const image of ingredient.ItemTags || []) {
            this.itemTagsSet.add(image);
          }

          this.itemTags = Array.from(this.itemTagsSet.values());
        }
      } else if (this.builderMode.currentComboGroup) {
        const currentComboGroup = this.builderMode
          .currentComboGroup as ComboGroupV2;

        for (const item of currentComboGroup.Items) {
          for (const image of item.IconImages || []) {
            this.itemTagsSet.add(image);
          }
        }
        this.itemTags = Array.from(this.itemTagsSet.values());
      }
    } else if (this.comboGroupContainer) {
      for (const comboGroup of this.comboGroupContainer.inputComboGroup) {
        for (const comboItem of comboGroup.Items) {
          for (const image of comboItem.IconImages || []) {
            this.itemTagsSet.add(image);
          }
        }
      }

      this.itemTags = Array.from(this.itemTagsSet.values());
    } else if (this.modifierContainer) {
      for (const mod of this.modifierContainer.inputModifier as ModifierV2[]) {
        for (const ingredient of mod.Ingredients as any) {
          for (const image of ingredient.ItemTags || []) {
            this.itemTagsSet.add(image);
          }
        }
      }

      this.itemTags = Array.from(this.itemTagsSet.values());
    } else if (this.individualMode) {
      this.itemTags = this.individualMode.item?.IconImages;
    }
    //console.log('individual mode', this.individualMode);
    this.cdr.detectChanges();
  }

  InitData() {
    this.loyaltyType = this.loyaltyService.loyaltyType;
    this.disableSpecialRequest = GeneralSetting.getDisableSpecialRequest();
    this.primaryColor = GeneralSetting.getPrimaryColor();
    this.secondaryColor = GeneralSetting.getSecondaryColor();
    this.positiveButton = GeneralSetting.getPositiveColor();
    this.positiveButtonText = GeneralSetting.getPositiveColorText();
    this.negativeButton = GeneralSetting.getNegativeColor();
    this.negativeButtonText = GeneralSetting.getNegativeColorText();
    this.priceColor = GeneralSetting.getPriceColor();
    if (this.inputItem && this.inputItem.Quantity) {
      this.quantityWhenAdd = Number(this.inputItem.Quantity);
    } else {
      this.quantityWhenAdd = Number(this.inputItem.OrderMinimumQuantity) || 1;
    }

    this.setSpecialReqText();

    if (this.isConcessionaire) {
      this.allSubsCription.push(
        this.databaseService.concessionaireSubject.subscribe((conList) => {
          let con = conList.find((x) => x.ConcessionaireID == this.concessionaireId);
          if (con) {
            this.concessionaire = con;
          }
        })
      );
    }
  }

  setSpecialReqText() {
    if (!this.inputItem) return;
    this.specialRequest = this.inputItem.specialRequest;
    if (this.specialRequest == '' || this.specialRequest == null) {
      this.specialRequestText = this.language.getTextElement('add_special_request').toLocaleUpperCase();
    } else {
      this.specialRequestText = this.language.getTextElement('edit_special_request').toLocaleUpperCase();
    }
  }

  doSubscriptions() {
    this.databaseService.getAllItemTags().then((tags) => {
      this.allTags = tags[0];
    });

    this.allSubsCription.push(
      this.databaseService.categorySubject.subscribe((category) => {
        let cat = category.find((x) => x.CategoryID == this.categoryID);

        if (cat) {
          this.category = cat;
        }
      })
    );

    this.allSubsCription.push(
      this.itemService.setItemTagsSub.subscribe((x) => {
        this.setItemTags();

        if (this.itemTagsKeyComponent) this.itemTagsKeyComponent.setItemTags();
      })
    );
  }

  private isAddtoCartClicked = false;
  async setIsAddToCartClicked(clickd = false) {
    this.isAddtoCartClicked = clickd;
    if (this.isAddtoCartClicked) {
      await CommonFunctions.delay(1000);
      this.isAddtoCartClicked = false;
    }
  }

  async checkAddToCart() {
    if (this.isAddtoCartClicked) return;
    this.setIsAddToCartClicked(true);
    if (this.inputItem.IsCategory) {
      let res = await this.cartService.addToCartAsync(
        this.createCategoryCartItem()
      );
      if (!res.status) {
        if (res.error == 'alcohol') {
          let error = this.language.getTextElement('max_alcohol_limit');
          Snackbar.show(error, 3000);
        } else if (res.error == 'stock') {
          let error = this.language.getTextElement('item_sold_out');
          Snackbar.show(error, 3000);
        }
        return;
      }
      this.redirectToPageAfterAddToCart();
      return;
    }

    const objCartItem = this.createCartItem();

    const isValid = this.checkValidityShowAnimation(objCartItem);

    if (!isValid) return;

    const isEdit = this.checkEditItem();

    this.calculateTotal();

    if (this.inputItem.AskForTentNumber && this.inputItem.AskForTentNumber.toLowerCase() == 'true' && !GeneralSetting.getIsItemTableTentAdded()) {
      let itpm = ItemTentPopupComponent.open(this.modalService);

      let tentNumberRes = await itpm.result;
      if (tentNumberRes.status == 'ok') {
        this.inputItem.TableTentNumber = tentNumberRes.tentNumber;
      }
    }

    if (isEdit && this.inputItem.ItemCategorySalesTypeID == CategorySalesType.Alcohol.toString()) {
      if (this.checkAndShowAlcoholLimitModel()) {
        let res = await this.cartService.addToCartAsync(this.createCartItem());
        if (!res.status) {
          if (res.error == 'alcohol') {
            let error = this.language.getTextElement('max_alcohol_limit');
            Snackbar.show(error, 3000);
          } else if (res.error == 'stock') {
            let error = this.language.getTextElement('item_sold_out');
            Snackbar.show(error, 3000);
          }
          return;
        }
        this.redirectToPageAfterAddToCart();
      }
      return;
    } else if ((this.inputItem.ComboGroup && this.inputItem.ComboGroup.length > 0) || (this.inputItem.Modifiers && this.inputItem.Modifiers.length > 0) || (this.inputItem.Variations && this.inputItem.Variations.length > 0) || this.isVariationsValid || this.isModifiersValid || this.isComboGroupValid) {
      this.checkAndOpenCustomerNamePopup();
    } else if (this.isReady) {
      this.checkAndOpenCustomerNamePopup();
    } else {
      let error = this.language.getTextElement('requirements_not_met')
      Snackbar.show(error, 3000);
    }
  }

  decreaseAmount(item: any) {
    let currentAlcoholCount = Number(GeneralSetting.getCurrentCountofAlkol());
    var currentQty = this.getCurrentQuantity(item);
    let minNo = Number(item.OrderMinimumQuantity);
    let totalComboAlcoholCount = CommonFunctions.isComboAlcoholic(item);
    if (totalComboAlcoholCount > 0) {
      if (!minNo) {
        minNo = 1;
      }
      let newAlcoholCount = CommonFunctions.getLatestAlcoholicCount(totalComboAlcoholCount, this.quantityWhenAdd, false);
      if (this.quantityWhenAdd > minNo) {
        this.quantityWhenAdd = Number(this.quantityWhenAdd) - 1;
        GeneralSetting.setCurrentCountofAlkol(newAlcoholCount.toString());
      } else if (minNo > 1) {
        let error = this.language.getTextElement('min_quantity_not_reached').replace('%d', minNo.toLocaleString())
        Snackbar.show(error, 2500);
      }
    } else {
      if (!minNo) {
        minNo = 1;
      }
      if (this.quantityWhenAdd > minNo) {
        this.quantityWhenAdd = Number(this.quantityWhenAdd) - 1;
      } else if (minNo > 1) {
        let error = this.language.getTextElement('min_quantity_not_reached').replace('%d', minNo.toLocaleString())
        Snackbar.show(error, 2500);
      }
    }
    this.quantityChange.emit(this.quantityWhenAdd);
  }
  getItems(): void {
    this.cartItems = this.cartService.getCartItems();
  }
  increaseAmount(item: any) {
    let currentAlcoholCount = Number(GeneralSetting.getCurrentCountofAlkol());
    var currentQty = this.getCurrentQuantity(item);
    // Checks fixed item quantity limit
    if (this.fixedItemDisc != undefined && this.fixedItemDisc.length > 0) {
      var isFixedItemAvail = this.discountService.isFixedItemAvail(this.fixedItemDisc[0], item, currentQty);
      if (isFixedItemAvail) {
        return;
      }
    }

    let maxNo = Number(item.OrderMaximumQuantity);
    var isCombo = item.IsCombo.toString();
    let alkolMax = Number(GeneralSetting.getMaxAlcoholicItemsPerOrder());
    if (isCombo.toLowerCase() == '0' || isCombo.toLowerCase() == 'true') {
      let totalComboAlcoholCount = CommonFunctions.isComboAlcoholic(item);
      if (totalComboAlcoholCount > 0) {
        let newAlcoholCount = CommonFunctions.getLatestAlcoholicCount(totalComboAlcoholCount, Number(this.quantityWhenAdd), true);
        if (newAlcoholCount <= Number(alkolMax)) {
          GeneralSetting.setCurrentCountofAlkol(newAlcoholCount.toString());
          this.quantityWhenAdd = Number(this.quantityWhenAdd) + 1;
        } else {
          AlcoholicItemsExceedMaxComponent.open(this.modalService);
        }
      } else {
        if (!maxNo) {
          if (item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString() && alkolMax > 0) {
            let currentAlcohol = 1;
            if (this.isEdit) {
              currentAlcohol = currentAlcoholCount - currentQty;
              currentAlcohol += Number(this.quantityWhenAdd);
            } else {
              currentAlcohol =
                currentAlcoholCount + Number(this.quantityWhenAdd);
            }
            if (currentAlcohol + 1 > alkolMax) {
              AlcoholicItemsExceedMaxComponent.open(this.modalService);
            } else {
              if (this.inputItem.CurrentStock != null) {
                let temp = this.databaseService.categorySubject.getValue();
                for (let i = 0; i < temp.length; i++) {
                  if (temp[i].CategoryID == this.inputItem.CategoryID) {
                    for (let j = 0; j < temp[i].associatedItems!.length; j++) {
                      if (temp[i].associatedItems![j].ItemID == this.inputItem.ItemID) {
                        if ((this.isEdit && temp[i].associatedItems![j].CurrentStock > this.quantityWhenAdd - currentQty) || (!this.isEdit && temp[i].associatedItems![j].CurrentStock > this.quantityWhenAdd)) {
                          this.quantityWhenAdd = Number(this.quantityWhenAdd) + 1;
                        } else {
                          let error = this.language.getTextElement('stock_threshold_hit').replace('%d', this.quantityWhenAdd.toLocaleString())
                          Snackbar.show(error, 3000);
                        }
                        break;
                      }
                    }
                    break;
                  }
                }
              } else {
                this.quantityWhenAdd = Number(this.quantityWhenAdd) + 1;
              }
            }
          } else {
            if (this.inputItem.CurrentStock != null) {
              let temp = this.databaseService.categorySubject.getValue();
              for (let i = 0; i < temp.length; i++) {
                if (temp[i].CategoryID == this.inputItem.CategoryID) {
                  for (let j = 0; j < temp[i].associatedItems!.length; j++) {
                    if (temp[i].associatedItems![j].ItemID == this.inputItem.ItemID) {
                      if ((this.isEdit && temp[i].associatedItems![j].CurrentStock > this.quantityWhenAdd - currentQty) || (!this.isEdit && temp[i].associatedItems![j].CurrentStock > this.quantityWhenAdd)) {
                        this.quantityWhenAdd = Number(this.quantityWhenAdd) + 1;
                      } else {
                        let error = this.language.getTextElement('stock_threshold_hit').replace('%d', this.quantityWhenAdd.toLocaleString())
                        Snackbar.show(error, 3000);
                      }
                      break;
                    }
                  }
                  break;
                }
              }
            } else {
              this.quantityWhenAdd = Number(this.quantityWhenAdd) + 1;
            }
          }
        } else if (this.quantityWhenAdd + currentQty < maxNo) {
          if (item.ItemCategorySalesTypeID === '4' && alkolMax > 0) {
            const currentAlcohol: number = currentAlcoholCount + this.quantityWhenAdd;
            if (currentAlcohol + 1 > alkolMax) {
              AlcoholicItemsExceedMaxComponent.open(this.modalService);
            } else {
              this.quantityWhenAdd = Number(this.quantityWhenAdd) + 1;
            }
          } else {
            this.quantityWhenAdd = Number(this.quantityWhenAdd) + 1;
          }
        } else if (this.quantityWhenAdd + currentQty >= maxNo) {
          let error = this.language.getTextElement('item_has_max_quantity').replace('%d', maxNo.toLocaleString())
          Snackbar.show(error, 2500);
        }
      }
    } else {
      if (!maxNo) {
        if (item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString() && alkolMax > 0) {
          let currentAlcohol = 1;
          if (this.isEdit) {
            currentAlcohol = currentAlcoholCount - currentQty;
            currentAlcohol += Number(this.quantityWhenAdd);
          } else {
            currentAlcohol = currentAlcoholCount + Number(this.quantityWhenAdd);
          }
          if (currentAlcohol + 1 > alkolMax) {
            AlcoholicItemsExceedMaxComponent.open(this.modalService);
          } else {
            if (this.inputItem.CurrentStock != null) {
              let temp = this.databaseService.categorySubject.getValue();
              for (let i = 0; i < temp.length; i++) {
                if (temp[i].CategoryID == this.inputItem.CategoryID) {
                  for (let j = 0; j < temp[i].associatedItems!.length; j++) {
                    if (temp[i].associatedItems![j].ItemID == this.inputItem.ItemID) {
                      if ((this.isEdit && temp[i].associatedItems![j].CurrentStock > this.quantityWhenAdd - currentQty) || (!this.isEdit && temp[i].associatedItems![j].CurrentStock > this.quantityWhenAdd)) {
                        this.quantityWhenAdd = Number(this.quantityWhenAdd) + 1;
                      } else {
                        let error = this.language.getTextElement('stock_threshold_hit').replace('%d', this.quantityWhenAdd.toLocaleString())
                        Snackbar.show(error, 3000);
                      }
                      break;
                    }
                  }
                  break;
                }
              }
            } else {
              this.quantityWhenAdd = Number(this.quantityWhenAdd) + 1;
            }
          }
        } else {
          if (this.inputItem.CurrentStock != null) {
            let temp = this.databaseService.categorySubject.getValue();
            for (let i = 0; i < temp.length; i++) {
              if (temp[i].CategoryID == this.inputItem.CategoryID) {
                for (let j = 0; j < temp[i].associatedItems!.length; j++) {
                  if (temp[i].associatedItems![j].ItemID == this.inputItem.ItemID) {
                    if ((this.isEdit && temp[i].associatedItems![j].CurrentStock > this.quantityWhenAdd - currentQty) || (!this.isEdit && temp[i].associatedItems![j].CurrentStock > this.quantityWhenAdd)) {
                      this.quantityWhenAdd = Number(this.quantityWhenAdd) + 1;
                    } else {
                      let error = this.language.getTextElement('stock_threshold_hit').replace('%d', this.quantityWhenAdd.toLocaleString())
                      Snackbar.show(error, 3000);
                    }
                    break;
                  }
                }
                break;
              }
            }
          } else {
            this.quantityWhenAdd = Number(this.quantityWhenAdd) + 1;
          }
        }
      } else if (this.quantityWhenAdd + currentQty < maxNo) {
        if (item.ItemCategorySalesTypeID === '4' && alkolMax > 0) {
          let currentAlcohol = 1;
          if (this.isEdit) {
            currentAlcohol = currentAlcoholCount - currentQty;
            currentAlcohol += Number(this.quantityWhenAdd);
          } else {
            currentAlcohol = currentAlcoholCount + Number(this.quantityWhenAdd);
          }
          if (currentAlcohol + 1 > alkolMax) {
            AlcoholicItemsExceedMaxComponent.open(this.modalService);
          } else {
            this.quantityWhenAdd = Number(this.quantityWhenAdd) + 1;
          }
        } else {
          this.quantityWhenAdd = Number(this.quantityWhenAdd) + 1;
        }
      } else if (this.quantityWhenAdd + currentQty >= maxNo) {
        let error = this.language.getTextElement('item_has_max_quantity').replace('%d', maxNo.toLocaleString())
        Snackbar.show(error, 2500);
      }
    }
    this.quantityChange.emit(this.quantityWhenAdd);
  }

  getCurrentQuantity(item: any) {
    let qty = 0;

    for (let cartItem of this.cartItems) {
      if (cartItem.ItemID === item.ItemID) {
        qty += Number(cartItem.Quantity);
      }
    }
    if (this.inputItem.guid) {
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }

    return qty;
  }

  /**
   * change price when choosing modifiers/variations
   */
  ngOnChanges(changes: SimpleChanges) {
    this.calculateTotal();
  }

  getUpSellLength() {
    this.databaseService
      .getUpSellItemsByItemId(this.inputItem.ItemID)
      .then((data) => {
        this.upSellLength = data.length;
      });
  }

  checkAndOpenCustomerNamePopup() {
    if (
      this.inputItem.IsAskForName === 'True' &&
      (this.inputItem.guid == '' ||
        this.inputItem.guid == null ||
        this.inputItem.guid == undefined)
    ) {
      //open pop up if there is no name for this item
      this.askCustomerInfo();
    } else {
      this.checkAndOpenUpSellModel();
    }
  }

  openItemAskForNameModal() {
    if (this.inputItem.IsAskForName === 'True' && this.upSellLength > 0) {
      //open pop up if there is no name for this item
      this.askCustomerInfo();
    } else {
      this.openUpSellModel();
    }
  }

  private async checkAndOpenUpSellModel() {
    if (this.isComboItem) {
      this.addToCart();
      return;
    }

    if (this.checkEditItem()) {
      this.addToCart();
      return;
    }

    //Add this check again because when we open detail screen and do item86 for upsell item then upsell popup shows blank
    let data = await this.databaseService.getUpSellItemsByItemId(
      this.inputItem.ItemID,
      this.isConcessionaire,
      this.concessionaire?.ConcessionaireID
    );

    this.upSellLength = data.length;

    if (this.upSellLength == 0) {
      this.addToCart();
    } else {
      const currentCountofAlkol = Number(
        GeneralSetting.getCurrentCountofAlkol()
      );
      const maxOrder = Number(GeneralSetting.getMaxAlcoholicItemsPerOrder());
      if (
        currentCountofAlkol == maxOrder &&
        !CommonFunctions.isNonAlcoholicItemAvail(data)
      ) {
        this.addToCart();
      } else {
        var currentItemQty =
          this.inputItem.Quantity == undefined ? 1 : this.inputItem.Quantity;
        if (
          Number(currentItemQty) + currentCountofAlkol === maxOrder &&
          this.inputItem.ItemCategorySalesTypeID ===
          CategorySalesType.Alcohol.toString() &&
          !CommonFunctions.isNonAlcoholicItemAvail(data)
        ) {
          this.addToCart();
        } else {
          let itemUpsell = this.cartService.cartItems.find((cartItem) => cartItem.IsUpSellItem && data.find((upSellItem) => upSellItem.ItemID == cartItem.ItemID));
          if (!itemUpsell) {
            this.openUpSellModel();
          } else {
            this.addToCart();
          }
        }
      }
    }
  }

  private async addToCart(upSellItems: ItemV2[] = []) {
    const objItem = this.createCartItem();
    objItem.totalPrice =
      CommonFunctions.getItemPrice(objItem) * Number(objItem.Quantity);

    if (this.isComboItem) {
      this.comboModelClose.emit(objItem);
      return;
    }

    const resAddToCart = await this.CartService.addToCartAsync(objItem);
    if (!resAddToCart.status) {
      if (resAddToCart.error == 'alcohol') {
        let error = this.language.getTextElement('max_alcohol_limit');
        Snackbar.show(error, 3000)
      }

      return;
    }
    if (upSellItems.length > 0) {
      for (let i = 0; i < upSellItems.length; i++) {
        upSellItems[i].UpSellItemGuid = objItem.guid;
        this.cartService.addToCartAsync(upSellItems[i]);
      }
    }

    this.redirectToPageAfterAddToCart();

    return;
  }

  private redirectToPageAfterAddToCart() {
    if (this.checkIfFromLastFivePageForCombo()) {
      this.router.navigateByUrl('/lastfiveorder').then(() => {
        this.lastfiveorderService.updateitemList(
          this.itemID,

          this.variations,

          this.modifiers
        );
      });

      GeneralSetting.setIntoCombo('false');
    } else {
      if (this.getParameterByName('isFromReview') === 'true') {
        this.router.navigateByUrl('/orderReview');
      } else {
        if (this.isConcessionaire) {
          this.router.navigate(['/menu'], {
            queryParams: {
              category: this.inputItem.CategoryID,
              id: this.inputItem.ItemID,
              concessionaireId: this.concessionaire?.ConcessionaireID,
            },
          });
        } else if (this.isScannedItem || this.isComingFromScan) {
          this.router.navigate(['/SearchScannable'], {
            queryParams: {
              category: this.inputItem.CategoryID,
              id: this.inputItem.ItemID,
              concessionaireId: this.concessionaire?.ConcessionaireID,
            },
          });
        } else {
          this.router.navigate(['/menu'], {
            queryParams: {
              category: this.inputItem.CategoryID,
              id: this.inputItem.ItemID,
            },
          });
        }
      }
    }
  }

  private openUpSellModel() {
    const itemTypeStr = CommonFunctions.getItemTypeV2(this.inputItem);
    let itemType: number = 1;

    if (itemTypeStr == 'ItemBuilderMode') {
      itemType = 2;
    }

    let usdc = UpSellDialogComponent.open(
      this.modalService,
      this.language,
      this.inputItem.ItemID,
      this.quantityWhenAdd,
      itemType,
      this.inputItem
    );

    usdc.result.then((result: any) => {
      const objItem = this.createCartItem();

      if (result != 'cancel') {
        this.upSellCartItems = result;
        const upSellItems: ItemV2[] = [];

        for (let upSellItem of this.upSellCartItems) {
          upSellItem.IsUpSellItem = true;
          upSellItem.UpSellItemID = objItem.ItemID;
          upSellItem.UpSellItemGuid = objItem.guid;
          upSellItem.UpSellItemPrice = CommonFunctions.getItemPrice(objItem);
          upSellItem.totalPrice = CommonFunctions.getItemPrice(upSellItem);

          if (this.isConcessionaire) {
            if (
              this.concessionaire &&
              Object.entries(this.concessionaire).length > 0
            ) {
              upSellItem.ConcessionaireId =
                this.concessionaire.ConcessionaireID;
              upSellItem.ConcessionaireName = this.concessionaire.Name;
              upSellItem.ConcessionaireColorCode =
                this.concessionaire.ColorCode;
            }
          }

          upSellItems.push(upSellItem);
        }

        this.addToCart(upSellItems);
      } else {
        this.addToCart();
      }
    });
  }

  private checkIfFromLastFivePageForCombo() {
    const checkIfLastfiveorder = GeneralSetting.getLastFiveOrderChosen();
    const intocombo = GeneralSetting.getIntoCombo();

    if (
      checkIfLastfiveorder &&
      checkIfLastfiveorder === 'true' &&
      intocombo &&
      intocombo === 'true'
    )
      return true;

    return false;
  }

  private askCustomerInfo() {
    let ianp = ItemAskNamePopupComponent.open(this.modalService);
    ianp.result.then(
      (data: any) => {
        this.itemGuestName = data;
        this.checkAndOpenUpSellModel();
      },
      () => { }
    );
  }

  openSpecialRequest() {
    let spm = SpecialRequestDialogComponent.open(
      this.modalService,
      this.specialRequest
    );
    //commented out as the code below has severe side effects that are not intended
    //the below function triggers a manual override of the automated keyboard logic and is not used correctly
    // spm.componentInstance.doit();
    spm.result.then(
      (result: string) => {
        this.specialRequest = result;
        if (this.specialRequest == '' || this.specialRequest == null) {
          this.specialRequestText = this.language
            .getTextElement('add_special_request')
            .toLocaleUpperCase();
        } else {
          this.specialRequestText = this.language
            .getTextElement('edit_special_request')
            .toLocaleUpperCase();
        }
      },
      () => { }
    ).finally(() => {
      this.KeyboardService.manual = false
      // spm.componentInstance.doit2()
      this.KeyboardService.KeyboardStatus = false;
    });
  }

  calculateTotalRecursive(options: any) {
    let total = 0;
    for (let i = 0; i < options?.length; i++) {
      if (options[i].isSelected) {
        if (
          options[i].associatedVariations &&
          options[i].associatedVariations.length > 0
        ) {
          for (let option of options[i].associatedVariations[0]
            .variationOptions) {
            if (option.isSelected) {
              total += Number(option.Price);
            }
          }
        }
        if (
          options[i].ComboGroupID ||
          (options[i].IsPremium && options[i].IsPremium == 'True')
        ) {
          total += Number(options[i].ExtraPrice || options[i].Price || 0);
        }

        if (options[i].hasOwnProperty('IsModifier86')) {
          if (
            options[i].Price != null &&
            options[i].Price != undefined &&
            !isNaN(options[i].Price)
          ) {
            total += Number(options[i].Price);
          }
        }

        total += this.calculateTotalRecursive(options[i].associatedOptions);
      }
    }

    return total;
  }

  calculateTotal() {
    //console.log(this.inputItem.Modifiers);

    if (this.inputItem == undefined || this.inputItem.Price == undefined) {
      return;
    }

    this.inputItem.VariationOption = this.variations;

    const total = CommonFunctions.getItemPrice(this.inputItem);
    this.totalCost = total;
    this.pricePerItem = Number(this.inputItem.Price);
  }

  getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  cancel() {
    if (this.isComboItem) {
      this.comboModelClose.emit('cancel');
      return;
    }
    if (this.isLoyalty == 'true') {
      this.loyaltyService.selectedLoyaltyReward = '';
      this.loyaltyService.subSelectedLoyaltyReward.next('');
    }
    // const checkIfLastfiveorder = GeneralSetting.getLastFiveOrderChosen();
    // const intocombo = GeneralSetting.getIntoCombo();
    let cancelCartItems =
      this.cartItems.length > 0
        ? this.cartItems[
        this.cartItems.findIndex((el) => el.guid === this.inputItem.guid)
        ]
        : this.inputItem;
    cancelCartItems = cancelCartItems ? cancelCartItems : this.inputItem;
    var isCombo = cancelCartItems.IsCombo.toString();
    if (isCombo.toLowerCase() == '0' || isCombo.toLowerCase() == 'true') {
      try {
        let totalComboAlcoholCount =
          CommonFunctions.isComboAlcoholic(cancelCartItems);
        if (totalComboAlcoholCount > 0) {
          if (this.getParameterByName('isFromReview') === 'true') {
            this.router.navigateByUrl('/orderReview');
          } else {
            if (this.checkEditItem()) {
              this.router.navigate(['/menu'], {
                queryParams: {
                  category: this.inputItem.CategoryID,
                  id: this.inputItem.ItemID,
                },
              });

              if (cancelCartItems) {
                if (
                  Number(cancelCartItems.Quantity) >=
                  Number(this.inputItem.Quantity)
                ) {
                  let newAlcoholCount = CommonFunctions.getLatestAlcoholicCount(
                    totalComboAlcoholCount,
                    Number(this.inputItem.Quantity),
                    false,
                    true
                  );
                  GeneralSetting.setCurrentCountofAlkol(
                    newAlcoholCount.toString()
                  );

                  newAlcoholCount = CommonFunctions.getLatestAlcoholicCount(
                    totalComboAlcoholCount,
                    Number(cancelCartItems.Quantity),
                    true,
                    true
                  );
                  GeneralSetting.setCurrentCountofAlkol(
                    newAlcoholCount.toString()
                  );
                } else if (Number(this.inputItem.Quantity) > 1) {
                  let newAlcoholCount = CommonFunctions.getLatestAlcoholicCount(
                    totalComboAlcoholCount,
                    Number(this.inputItem.Quantity) -
                    Number(cancelCartItems.Quantity),
                    false,
                    true
                  );
                  GeneralSetting.setCurrentCountofAlkol(
                    newAlcoholCount.toString()
                  );
                }
              }
            } else {
              this.router.navigate(['/menu'], {
                queryParams: {
                  category: this.inputItem.CategoryID,
                  id: this.inputItem.ItemID,
                },
              });
              let newAlcoholCount = CommonFunctions.getLatestAlcoholicCount(
                totalComboAlcoholCount,
                Number(this.quantityWhenAdd),
                false,
                true
              );
              GeneralSetting.setCurrentCountofAlkol(newAlcoholCount.toString());
            }
          }
        }
      } catch (e) { }
    }
    this.redirectToPageAfterAddToCart();
  }

  private createCategoryCartItem(): ItemV2 {
    let cartItem = {} as ItemV2;

    if (this.inputItem.ComboGroup && this.inputItem.ComboGroup.length > 0) {
      let tempItem = this.inputItem.ComboGroup[0].Items.filter(
        (item) => item.isSelected
      );
      if (tempItem && tempItem.length > 0) {
        cartItem = JSON.parse(JSON.stringify(tempItem[0])) as ItemV2;
      }
    }
    cartItem.guid = this.inputItem.guid ? this.inputItem.guid : Guid.newGuid();
    cartItem.GuestName = this.itemGuestName;
    cartItem.specialRequest = this.specialRequest;
    cartItem.totalPrice =
      CommonFunctions.getItemPrice(cartItem) * Number(cartItem.Quantity);
    cartItem.Variations = this.inputItem.Variations
      ? this.inputItem.Variations
      : [];

    if (this.isConcessionaire) {
      if (
        this.concessionaire &&
        Object.entries(this.concessionaire).length > 0
      ) {
        cartItem.ConcessionaireId = this.concessionaire.ConcessionaireID;
        cartItem.ConcessionaireName = this.concessionaire.Name;
        cartItem.ConcessionaireColorCode = this.concessionaire.ColorCode;
      }
    }
    return cartItem;
  }
  private createCartItem(): ItemV2 {
    const cartItem = JSON.parse(JSON.stringify(this.inputItem)) as ItemV2;
    cartItem.guid = this.inputItem.guid ? this.inputItem.guid : Guid.newGuid();

    cartItem.Modifiers = this.modifiers;
    cartItem.VariationOption = this.variations;
    cartItem.ComboGroup = this.comboGroup;

    cartItem.Quantity = this.quantityWhenAdd.toString();
    cartItem.AllowQuantitySelection =
      cartItem.AllowQuantitySelection == ''
        ? 'True'
        : cartItem.AllowQuantitySelection;
    cartItem.GuestName = this.itemGuestName;
    cartItem.specialRequest = this.specialRequest;
    //Total price based on qty or weight
    if (
      !(
        cartItem.EnablePricebyWeight &&
        cartItem.EnablePricebyWeight.toString().toLocaleLowerCase() == 'true'
      )
    ) {
      cartItem.totalPrice =
        CommonFunctions.getItemPrice(cartItem) * Number(cartItem.Quantity);
    } else {
      cartItem.Quantity = cartItem.Weight.toString();
      cartItem.totalPrice =
        CommonFunctions.getItemPrice(cartItem) * Number(cartItem.Weight);
    }

    cartItem.Variations = this.inputItem.Variations
      ? this.inputItem.Variations
      : [];

    if (this.isConcessionaire) {
      if (
        this.concessionaire &&
        Object.entries(this.concessionaire).length > 0
      ) {
        cartItem.ConcessionaireId = this.concessionaire.ConcessionaireID;
        cartItem.ConcessionaireName = this.concessionaire.Name;
        cartItem.ConcessionaireColorCode = this.concessionaire.ColorCode;
      }
    }

    cartItem.TableTentNumber = this.inputItem.TableTentNumber;

    return cartItem;
  }

  /**
   * @description Determines if the current alcoholic item will exceed the maximum allowed amount
   * @returns {boolean} true: the current item will NOT exceed the set maximum alcoholic items per order;
   * @returns {boolean} false: the current item WILL exceed the set maximum alcoholic items per order
   */
  private checkAndShowAlcoholLimitModel(): boolean {
    const currentCountofAlkol = GeneralSetting.getCurrentCountofAlkol();
    const maxOrder = GeneralSetting.getMaxAlcoholicItemsPerOrder();

    if (maxOrder == null) {
      return true;
    }

    const tempQuantity = Number(this.inputItem.Quantity) + 1;

    if (
      currentCountofAlkol != null &&
      Number(maxOrder) > 0 &&
      tempQuantity + 1 > Number(maxOrder)
    ) {
      return false;
    }

    // const tempQuantity = this.inputItem.guid
    //   ? this.quantityWhenAdd - Number(this.inputItem.Quantity)
    //   : this.quantityWhenAdd;

    // if (
    //   currentCountofAlkol != null &&
    //   Number(maxOrder) > 0 &&
    //   tempQuantity + Number(currentCountofAlkol) > Number(maxOrder)
    // ) {
    //   // this.alcoholicExceedMaxPopup.open();
    //   return false;
    // }

    return true;
  }

  /**
   * @description Appropriately routes to correct screen if edit item, and calls alcohol checks if alcoholic before editing cart
   */
  private checkEditItem(): boolean {
    if (this.inputItem.guid) {
      if (this.inputItem.GuestName && this.inputItem.GuestName != '') {
        this.itemGuestName = this.inputItem.GuestName;
      }
      if (
        !(
          this.inputItem.ItemCategorySalesTypeID ===
          CategorySalesType.Alcohol.toString()
        )
      ) {
        return true;
      } else if (this.checkAndShowAlcoholLimitModel()) {
        return true;
      }
    }

    return false;
  }

  /**
   * @description checks the current selections against the requirements of the item and alerts user if item is invalid
   */
  private checkValidityShowAnimation(item: ItemV2) {
    if (item.Modifiers != undefined) {
      for (let i = 0; i < item.Modifiers.length; i++) {
        const count = CommonFunctions.getSelectedIngredientCount(
          item.Modifiers[i]
        );

        if (item.Modifiers[i].UseAdvancedModifierQuantitySettings == 'True') {
          if (!this.checkValidModifierSelection(this.inputItem.Modifiers[i].MinAggregateQty, this.inputItem.Modifiers[i].MaxAggregateQty, count) ||
            (item.Modifiers[i].IsModifier86 == 'False' && this.inputItem.Modifiers[i].MinAggregateQty >= 1)) {
            this.modifierAnimation();
            return false;
          }
        }
        else {
          if (!this.checkValidModifierSelection(this.inputItem.Modifiers[i].min, this.inputItem.Modifiers[i].max, count) ||
            (item.Modifiers[i].IsModifier86 == 'False' && this.inputItem.Modifiers[i].min >= 1)) {
            this.modifierAnimation();
            return false;
          }
        }

      }
    }

    return true;
  }

  checkValidModifierSelection(min: number, max: number, count: number) {
    // Required
    if (min == max) {
      if (count == min) {
        return true;
      } else {
        return false;
      }
    }
    //Choose Up to max
    if (min == 0 && max >= 1) {
      if (count <= max) {
        return true;
      } else {
        return false;
      }
    }
    //Select at least one
    if (min == 1 && max > 1) {
      if (count >= 1 && count <= max) {
        return true;
      } else {
        return false;
      }
    }
    //select at least more then one
    if (min > 1 && max > 1) {
      if (count > min || count <= max) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  modifierAnimation() {
    if (this.modifierContainer) this.modifierContainer.modifierScroll();

    if (this.comboGroupContainer) this.comboGroupContainer.comboGroupScroll();

    if (this.builderMode) this.builderMode.onFixItemsClick();
  }
}
