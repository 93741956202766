<div class="checkoutbox">
  <ul>
    <li class="bmargin2" *ngIf="isCartOpen && !isRemovePricingAndPayments">
      <strong [ngStyle]="{ color: secondaryColor }"> {{subtotalText}} </strong>
      <strong id="subTotal" [ngStyle]="{ color: priceColor }"> {{ subtotal.toFixed(2) | CurrencyPipe }} </strong>
    </li>
    <li id="checkout-button" class="bmargin2">
      <a matRipple (click)="toCheckout()" [ngStyle]="{
            color: positiveButtonText,
            'background-color': positiveButton
          }">{{ isRemovePricingAndPayments ? reviewOrderText : checkoutText }}</a>
    </li>
    <li id="cancel-button">
      <a matRipple (click)="cancelOrder()" [ngStyle]="{
            color: negativeButtonText,
            'background-color': negativeButton
          }">{{cancelOrderText}}</a>
    </li>
    <!--<li *ngIf="isCartOpen">
              <span (click)="clearAll()">Clear All</span>
              <i class="fas fa-wheelchair"></i>
          </li>-->
  </ul>
</div>