<div class="refresh">

  <div class="spinner-border spinner"
       style="width: 15rem; height: 15rem;border-width: 10px;margin-top: 7rem;margin-bottom: 7rem;" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <!--<div style="display: flex; justify-content: center">
    <img src="/assets/images/loading/refresh_loading.gif" style="height:15rem;width:15rem" />
  </div>
  <div class="text-center">
    <h1></h1>
  </div>-->
</div>
