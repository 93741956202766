<div id="connection-loss" role="dialog">
    <div class="header">
        <h2 class="text-center"><b>{{uhohText}}</b></h2>
        <h4 class="text-center">{{connectionText}}
            <br>
            {{tryAgainText}}
        </h4>
    </div>
    <div class="popup-footer">
        <button type="submit" class="text-center green-btn" (click)="clear()" [ngStyle]="{
            color: positiveButtonText,
            'background-color': positiveButton
          }">
            {{okText}}
        </button>
    </div>
</div>
