import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Snackbar } from 'src/app/services/common';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { LanguageService } from 'src/app/services/language.service';
import { PaymentTotalRowComponent } from '../../payment-total-row/payment-total-row.component';

@Component({
  selector: 'app-pay-with-idcard-login',
  templateUrl: './pay-with-idcard-login.component.html',
  styleUrls: ['./pay-with-idcard-login.component.css']
})
export class PayWithIdcardLoginComponent implements OnInit, OnDestroy {
  @ViewChild(PaymentTotalRowComponent) paymentRow!: PaymentTotalRowComponent
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  private allSubsCription: Subscription[] = [];
  total: number = 0
  subtotal: number = 0
  tax: number = 0
  discount: number = 0
  reward: number = 0
  shipping: number = 0

  showPayment: boolean = false
  modalReference: any;
  paymentType: string = '';
  title: string = '';
  subTitle: string = '';
  label_submit: string = '';
  languageSub!: Subscription;
  checkoutText: string = '';
  holdBarcodeText: string = '';
  memberid: string = '';
  keySub!:Subscription
  isKiosk = this.isKioskService.isKiosk();
  constructor(
    private modalService: NgbModal,
    private language: LanguageService,
    private isKioskService: IsKioskService,
    private KeyboardService: KeyboardService,
  ) { }

  loadText() {
    this.checkoutText = this.language.getTextElement('lbl_checkout').toLocaleUpperCase();
    this.holdBarcodeText = this.language.getTextElement('hold_barcode_to_scanner')
  }

  ngOnInit(): void {
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
    this.loadData();
    this.memberid = '';
    //  this.subscribeToValueChange();
  }

  public static open(modelService: NgbModal, paymentType: string, total: number = 0, subtotal: number = 0, tax: number = 0, shipping: number = 0, discount: number = 0, reward: number = 0, showPayment: boolean = false) {
    var modalReference = modelService.open(PayWithIdcardLoginComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    })
    modalReference.componentInstance.modalReference = modalReference
    modalReference.componentInstance.showPayment = showPayment
    modalReference.componentInstance.paymentType = paymentType
    modalReference.componentInstance.total = total
    modalReference.componentInstance.subtotal = subtotal
    modalReference.componentInstance.tax = tax
    modalReference.componentInstance.shipping = shipping
    modalReference.componentInstance.discount = discount
    modalReference.componentInstance.reward = reward
    return modalReference
  }

  doit2(){
    if(this.keySub){
      this.keySub.unsubscribe()
    }
    this.KeyboardService.newBoardSubject.next()
  }

  
  // used to open keyboard based on dialog required layout, maxlength and assign value to dialog/screen input field @jay
  doit(){
    this.KeyboardService.manual=true
    this.KeyboardService.maxLength=50
    this.KeyboardService.clearSub.next()
    this.KeyboardService.open("alphaNumeric");
    this.KeyboardService.stringSub.next()
    this.keySub=this.KeyboardService.stringSub.subscribe((val)=>{
      this.modalReference.componentInstance.memberid=val
    })
  }

  loadData() {
    /**
     * Payment Types 1=Us Connect , 2 = ID Card
     */
    if (this.paymentType == '1') {
      //us connect
      this.title = this.checkoutText;
      this.subTitle = this.language.getTextElement('pay_with_us_connect');
    } else if (this.paymentType == '2') {
      //id card payment
      this.title = this.language.getTextElement('pay_with_id_card').toLocaleUpperCase();
      this.subTitle = this.language.getTextElement('please_enter_your_member_id');
      this.label_submit = this.language.getTextElement('lbl_submit');
    }
  }

  close(event: string) {
    this.KeyboardService.KeyboardStatus = false;
    this.modalReference.close(event);
  }

  // subscribeToValueChange() {
  //   this.barcodeInput = new FormControl('');

  //   this.allSubsCription.push(
  //     this.barcodeInput.valueChanges
  //       .pipe(debounceTime(400), distinctUntilChanged())
  //       .subscribe((inputText) => {
  //         this.modalReference.close({ eventtype: 'result', data: inputText });
  //         //console.log(inputText);
  //       })
  //   );
  // }

  onSubmit(memberid: string) {

    if (memberid) {
      this.KeyboardService.KeyboardStatus = false;
      this.modalReference.close({ eventtype: 'result', data: memberid });
    }
    else {
      Snackbar.show("Please enter member id", 2000);
    }
  }
}
