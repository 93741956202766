<div id="discountSuccess">
    <div class="modal-body configureDevice-modal">
        <div class="content">
            <h2>{{titleText}}</h2>
            <p>{{messageText}}</p>
        </div>
    </div>
    <div class="modal-footer">


        <button matRipple class="no-btn text-uppercase" (click)="onNoClick()" *ngIf="negativeBtnText.length > 0">{{negativeBtnText}}</button>
        <button matRipple class="yes-btn text-uppercase" (click)="onYesClick()">{{positiveBtnText}}</button>
        <!-- <button matRipple class="white-btn warn text-uppercase" (click)="cancelModal()">No</button> -->
    </div>
</div>