import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { itemwise } from 'src/app/models/itemwise';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { ItemwiseSalesService } from 'src/app/services/itemwise-sales.service';
import { LanguageService } from 'src/app/services/language.service';
import { PrinterService } from 'src/app/services/printer.service';
@Component({
  selector: 'app-settings-itemwise-sales-report',
  templateUrl: './settings-itemwise-sales-report.component.html',
  styleUrls: ['./settings-itemwise-sales-report.component.css'],
})
export class SettingsItemwiseSalesReportComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  itemwiseService: ItemwiseSalesService;
  itemwiseOrders: itemwise[] = [];
  dt: Date = new Date();
  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();
  languageSub!: Subscription;
itemNameText:string=''
itemSalesReportText:string=''
eachText:string=''
quantityText:string=''
totalText:string=''
printText:string=''

  constructor(
    ItemwiseSalesService: ItemwiseSalesService,
    private datePipe: DatePipe,
    private printService: PrinterService,
    private language: LanguageService

  ) {
    this.itemwiseService = ItemwiseSalesService;
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    )
  }
  loadText(){
    this.itemNameText=this.language.getTextElement('lbl_item_name')
    this.eachText=this.language.getTextElement('lbl_each')
    this.quantityText=this.language.getTextElement('lbl_quantity')
    this.totalText=this.language.getTextElement('reports_lbl_total')
    this.itemSalesReportText=this.language.getTextElement('item_sales_report')
    this.printText=this.language.getTextElement('lbl_print')
  }
  ngOnInit(): void {

    this.itemwiseReport();
  }

  itemwiseReport() {
    const date = this.datePipe.transform(new Date(), 'MMM dd YYYY');
    // this.itemwiseService.getTotalItems(date!.toString()).subscribe((item) => {

    //   count = Number(item);
    // })
    this.allSubsCription.push(
      this.itemwiseService
        .getItemReport(date!.toString())
        .subscribe((item) => {
          this.itemwiseOrders = item;
          // console.log('itemwise item', item);
        })
    );
  }
  computePrice(str: string, no: number): number {
    return Number(str) * no;
  }

  setItemwiseDate(event: any) {
    this.dt = event;
    const date = this.datePipe.transform(this.dt, 'MMM dd YYYY');

    this.allSubsCription.push(
      this.itemwiseService
        .getItemReport(date!.toString())
        .subscribe((item) => {
          this.itemwiseOrders = item;
        })
    );
  }
  printItemwise() {
    this.printService.KioskDataPrint({
      type: 'Itemwise',
      data: this.itemwiseOrders,
    });
  }
}
