import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { CheckoutBoxComponent } from '../checkout-box/checkout-box.component';
import { Subscription } from 'rxjs';
import { GeneralSetting } from '../../services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
})
export class CartComponent implements OnInit, OnDestroy {
  // subtotal: number = 0.0;
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  @Input() @Output() removeAlk!: boolean;

  @Output() isCartOpenChange = new EventEmitter<boolean>();

  @ViewChild(CheckoutBoxComponent) checkoutBox!: CheckoutBoxComponent;

  primaryColor: string = '';

  positiveBtnColor: string = '';

  footerOrderSummaryBackgroundColor: string = '';

  isCartOpen: boolean = false;

  footerBackground: string = '';

  showCalorie: any;

  closeCartText: string = '';

  openCartText: string = '';

  calorieText: string = '';

  constructor(
    public router: Router,
    private language: LanguageService,
    private cartService: CartService
  ) {}

  loadText() {
    this.calorieText = this.language.getTextElement( 'txt_calorie_counts_are_only_an_estimate' );
    this.openCartText = this.language.getTextElement('open_cart');
    this.closeCartText = this.language.getTextElement('close_cart');
  }

  ngOnInit(): void {
    this.allSubsCription.push(
      this.cartService.cartCurrentlyOpen.subscribe((isOpenOrNot) => {
        this.isCartOpen = isOpenOrNot;
      })
    );
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );

    this.primaryColor = GeneralSetting.getPrimaryColor();
    this.positiveBtnColor = GeneralSetting.getPositiveColor();

    this.footerBackground = GeneralSetting.getFooterBackground();
    //this.footerOrderSummaryBackgroundColor = GeneralSetting.getFooterOrderSummaryBackgroundColor();

    this.showCalorie = GeneralSetting.getShowCalorieCounts();

    this.isCartOpen = !this.cartService.isEmpty();

    this.isCartOpenChange.emit(this.isCartOpen);

    this.footerOrderSummaryBackgroundColor =
      GeneralSetting.getFooterOrderSummaryBackgroundColor();
  }

  clearCart() {
    this.cartService.clearCart();
  }

  updatePrice() {
    if (this.checkoutBox) this.cartService.calculateSubtotal();
  }

  closeCart() {
    this.isCartOpen = !this.isCartOpen;
    this.isCartOpenChange.emit(this.isCartOpen);
  }
}
