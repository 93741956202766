import { Injectable } from '@angular/core';
import { DatabaseHandler } from '../DatabaseHandler';
import { eatOption } from 'src/app/models/eat-option';
import { LogService } from 'src/app/services/log.service';

@Injectable({
  providedIn: 'root',
})
export class EatOptionsService {
  selectedOption!: eatOption;
length:number=0;

constructor(private logger: LogService) {}

 
  getEatOptions(): Promise<eatOption[]> {
    return (this.getEatOptionsFromSql());
  }

  getCurrentEatOption(): eatOption {
    return this.selectedOption;
  }

  setEatOption(option: eatOption): void {
    this.selectedOption = option;
  }

  getEatOptionsFromSql() {
    return new Promise<eatOption[]>((resolve: any, reject: any) => {
      const getEatOptionsStatement = `SELECT DISTINCT * FROM OrderTypeMappingMasters WHERE KioskIsActive = 'True' ORDER BY CAST(DisplayOrder AS INT)`;

      const errorCallback = (statement: any, error: any) => {
        resolve([]);
      };

      const setEatOptions = (tx: string, results: any) => {
        var data: eatOption[] = Array.from(results.rows);
        this.length = data.length;
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getEatOptionsStatement,
        [],
        setEatOptions,
        errorCallback
      );
    });
  }

  getEatOptionsForOrderTypeIdFromSql(orderTypeId : string) {
    return new Promise<eatOption[]>((resolve: any, reject: any) => {
      const getEatOptionsStatement = `SELECT DISTINCT * FROM OrderTypeMappingMasters WHERE OrderTypeID = '${orderTypeId}'`;

      const errorCallback = (statement: any, error: any) => {
        console.log('Eat options Service , Error getEatOptionsFromSql :- ', error);

        resolve([]);
      };

      const setEatOptions = (tx: string, results: any) => {
        var data: eatOption[] = Array.from(results.rows);
        resolve(data);
      };

      DatabaseHandler.executeSqlStatement(
        getEatOptionsStatement,
        [],
        setEatOptions,
        errorCallback
      );
    });
  }
}
