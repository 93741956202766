import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ItemV2 } from 'src/app/models/item';
import { DataService } from 'src/app/services/data.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-box-builder-header',
  templateUrl: './box-builder-header.component.html',
  styleUrls: ['./../box-builder.component.css'],
})
export class BoxBuilderHeaderComponent implements OnInit, OnDestroy {
  allSubscription: Subscription[] = [];
  pickDonutText: string = '';
  constructor(
    private readonly router: Router,
    private readonly isKioskService: IsKioskService,
    route: ActivatedRoute,
    private language: LanguageService,
    private readonly dataService: DataService
  ) {
    this.concessionaireId = route.snapshot.queryParams['concessionaireId'];
    this.allSubscription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }
  @Input() Item: ItemV2 = new ItemV2();

  @Input() numInvisibleGroups: number = 0;

  @Input() isCombo: boolean = false;

  @Input() selectedTotalCount: number = 0;

  @Input() itemImageList: any[] = [];

  @Input() totalComboGroups: number = 0;

  @Output() itemModalClose = new EventEmitter<any>();

  concessionaireId: string = '';

  boxBuilder!: any;
  modalReference: any;
  // totalComboGroups!: number;

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  branchLogo: string | null = '';
  placeholderImage : string = '';

  ngOnDestroy() {
    if (this.allSubscription.length > 0) {
      for (let i = 0; i < this.allSubscription.length; i++) {
        this.allSubscription[i].unsubscribe();
      }
    }
  }
  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.branchLogo = GeneralSetting.getBranchLogo();
  }
  loadText() {
    this.pickDonutText = this.language.getTextElement('pick_a_donut');
  }
  cancel() {
    if (this.isCombo) {
      this.router.navigate(['/menu'], {
        queryParams: {
          category: this.Item.CategoryID,
          concessionaireId: this.concessionaireId,
        },
      });
    } else {
      this.itemModalClose.emit(null);
    }
  }

  getPrimaryColor() {
    return GeneralSetting.getPrimaryColor();
  }
  getAccentColor() {
    return GeneralSetting.getAccentColor();
  }
}
