import { EventEmitter, Input, OnDestroy } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { Variation } from '../../../models/Variations';
import { VariationOption } from '../../../models/Variations';
import { CommonFunctions } from '../../../services/common';
import { DatabaseService } from '../../../services/database.service';

@Component({
  selector: 'app-variation',
  templateUrl: './variation.component.html',
  styleUrls: ['./variation.component.css'],
})
export class VariationComponent implements OnInit, OnDestroy {

  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  @Input() isBuilderMode = false;

  @Input() variation!: Variation;

  @Input() variationIndex!: Number;

  @Output() totalAdditionlPrice: string = '';

  @Output() SelectedVariation: VariationOption | undefined;

  @Output() SelectedVariationEmiter: EventEmitter<VariationOption> = new EventEmitter<VariationOption>();

  @Input() collapsed!: boolean;

  positiveColor: string | null = '';

  negativeColor: string | null = '';

  variationOptions: VariationOptionExtended[];

  constructor(private db: DatabaseService) {
    this.variationOptions = [];
  }

  ngOnInit(): void {
    this.getvariationOptions();
    this.positiveColor = GeneralSetting.getPositiveColor();
    this.negativeColor = GeneralSetting.getNegativeColor();
  }

  // collapse() {
  //   this.collapsed = !this.collapsed;
  // }

  getvariationOptions() {
    this.allSubsCription.push(
      this.db.getVariationOptionByID(String(this.variation.ItemAttributeID))
        .subscribe((variation: VariationOption[]) => {
          this.variationOptions = variation as VariationOptionExtended[];
        })
    );
  }

  setSelectedVariation(variation: VariationOption, index: number) {
    for (const option of this.variation.variationOptions) {
      option.isSelected = false;
    }
    this.variation.variationOptions[index].isSelected = true;
    this.SelectedVariation = variation;
    this.SelectedVariationEmiter.emit(this.SelectedVariation);
    (async () => {
      await CommonFunctions.delay(100);
      this.collapsed = true;
    })();
    //TODO visually select/deselect
  }

  printThis() { }
}

interface VariationOptionExtended extends VariationOption {
  isSelected: boolean;
}
