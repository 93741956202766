import { Injectable } from '@angular/core';
import {
  RefLoyaltyCustomer,
  PunchhLoyaltyCustomer,
  ReturningCustomer,
  LevelUpCustomer,
  PaytronixCustomer,
} from '../models/loyaltyModal';
import { BehaviorSubject } from 'rxjs';
import { ReferrizerCustomerAPIResponse } from './api/Models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userSubject = new BehaviorSubject<boolean>(false);

  isUserLoggedIn = false;
  name: string = '';

  returningCustomer = {} as ReturningCustomer;

  referrizerLoyaltyUser = {} as ReferrizerCustomerAPIResponse;
  punchhLoyaltyCustomer = {} as PunchhLoyaltyCustomer;
  levelUpCustomer = {} as LevelUpCustomer;
  paytronixCustomer = {} as PaytronixCustomer
  signOut() {
    this.returningCustomer = {} as ReturningCustomer;
    this.referrizerLoyaltyUser = {} as ReferrizerCustomerAPIResponse;
    this.punchhLoyaltyCustomer = {} as PunchhLoyaltyCustomer;
    this.levelUpCustomer = {} as LevelUpCustomer;
    this.paytronixCustomer = {} as PaytronixCustomer;
    this.name = '';
    this.isUserLoggedIn = false;
    this.userSubject.next(false);
  }
}
