import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { HealthLogRequest, LogDetail } from 'src/app/services/api/Models';
import { HealthLogService } from 'src/app/services/kiosk-health-log.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoaderService } from 'src/app/services/loader.service';
import { GeneralSetting } from '../../../services/generalSetting.service';
import { GeneralAlertComponent } from '../../dialogs/general-alert/general-alert.component';

@Component({
  selector: 'app-health-log',
  templateUrl: './settings-health-log.component.html',
  styleUrls: ['./settings-health-log.component.css'],
})
export class SettingsHealthLogComponent implements OnInit,OnDestroy {
  
  kioskHealthRecords: LogDetail[] = [];
  kioskHealthCheck: HealthLogRequest | null = null;
  kioskHealthCheckText:  string = '';
  lastUpdatedText:  string = '';
  tableNameTD:  string = '';
  tableStatusTD:  string = '';
  tableDetailsTD:  string = '';
  languageSub!: Subscription;

  constructor(
    private language: LanguageService,
    private loader:LoaderService,
    private healthLogService: HealthLogService,
    private readonly modalService: NgbModal,
  ) {}

  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  
  loadText() {
     this.kioskHealthCheckText = this.language.getTextElement('lbl_kiosk_health_check');
     this.lastUpdatedText = this.language.getTextElement('lbl_last_updated');
     this.tableNameTD = this.language.getTextElement('lbl_table_name_td');
     this.tableStatusTD = this.language.getTextElement('lbl_table_status_td');
     this.tableDetailsTD = this.language.getTextElement('lbl_table_details_td');
  }

  ngOnInit(): void {
    this.kioskHealthRecords = this.healthLogService.getDeafultHealthLog();
    this.kioskHealthRecords.map( (a: any) => {
      a.labelText = this.language.getTextElement(a.label)
      return a
    })
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });

    let getHealthLogs = GeneralSetting.getHealthLogs()
    if(getHealthLogs) {
      this.kioskHealthCheck =  getHealthLogs;
      this.kioskHealthRecords = this.kioskHealthCheck?.details || this.kioskHealthRecords;
      this.kioskHealthRecords.map( (a: any) => {
        a.labelText = this.language.getTextElement(a.label)
        return a
      })
    }
    this.kioskHealthClick()
  }

  async kioskHealthClick() {
    this.loader.openLoader();
    this.kioskHealthCheck = await this.healthLogService.sendHealthLog();
    this.kioskHealthRecords = this.kioskHealthCheck.details;
    this.kioskHealthRecords.map( (a: any) => {
      a.labelText = this.language.getTextElement(a.label)
      return a
    })
    if(!this.kioskHealthCheck.isGrubbrrCloud){
      let err = this.language.getTextElement('device_offline_error');
      let title = this.language.getTextElement('titile_uh_oh');
      GeneralAlertComponent.open(this.modalService, title, err);
    }
    this.loader.closeLoader();
  }
}
