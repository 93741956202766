<div id="comboItemModifier">
  <div class="modal-body comboItemModifier-modal">
    <div class="content">
      <div class="flexbox">
        <div class="top-header">
          <h3 [style.color]="positiveColor">{{ itemName }}</h3>
          <p>{{itemDescription}}</p>
        </div>
        <div class="header row text-center">
          <div class="col-md-12 col-12 vertical-center">
            <strong><h1 class="toppings">{{ parentModifier && parentModifier.Category ? parentModifier.Category : '' }}</h1></strong>
          </div>
        </div>
        <div class="h-83vh">
          <ng-container *ngIf="itemType == 2">
            <app-item-builder-mode-selection class="w-100 selection"
                                             [modifiers]="modifiers"
                                             [isCombo]="false"
                                             (emitSelectedModifier)="setSelectedModifiers($event)"
                                             [ItemName]="itemName"
                                             [ItemDescription]="itemDescription">
            </app-item-builder-mode-selection>
          </ng-container>
          <ng-container *ngIf="itemType == 1">
            <app-modifier-container [inputModifier]="modifiers"
                                    (modifierEmitter)="setSelectedModifiers($event)"
                                    (isValidEmitter)="setModifiersValid($event)"
                                    [ItemName]="itemName"
                                    [ItemType]="itemType"
                                    [ItemDescription]="itemDescription">
            </app-modifier-container>
          </ng-container>
   
        </div>
        <div class="item-footer vertical-center">
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
              <button class="plain-btn negative-button-text-color background-negative-button-color border-negative-button-color" (click)="cancelModal()">{{cancelText}}</button>
            </div>
            <div class="col-4">
              <button *ngIf="isModifiersValid; else prompt"
                      [style.backgroundColor]="positiveColor"
                      [style.borderColor]="positiveColor"
                      class="green-btn positive-button-text-color"
                      (click)="addToCart()">
                {{selectText}}
              </button>
              <ng-template #prompt>
                <button style="background-color: gray; border-color: gray"
                        class="green-btn"
                        (click)="modifierAnimation()">
                  {{selectText}}
                </button>
              </ng-template>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

