import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConcessionaireSelectComponent } from 'src/app/shared/concessionaire-select/concessionaire-select.component';
import { SharedRoutingModule } from 'src/app/shared/shared-routing.module';
import { FormsModule } from '@angular/forms';
import { FunctionCallPipe } from 'src/app/CustomTools/function-call.pipe';
import { OrderCustomMessagesComponent } from './order-custom-messages/order-custom-messages.component';

@NgModule({
  declarations: [
    ConcessionaireSelectComponent,
    FunctionCallPipe,
    OrderCustomMessagesComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule
  ],
  exports: [
    ConcessionaireSelectComponent,
    FunctionCallPipe,
    OrderCustomMessagesComponent
  ]
})
export class SharedModule { }
