import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { GeneralSetting } from '../../../services/generalSetting.service';

@Component({
  selector: 'app-alcoholic-items-exceed-max',
  templateUrl: './alcoholic-items-exceed-max.component.html',
  styleUrls: ['./alcoholic-items-exceed-max.component.css'],
})
export class AlcoholicItemsExceedMaxComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  modalReference: any;

  languageSub!: Subscription;

  limit: any;

  negativeColor: string | null = '';

  negativeColorText: string | null = '';

  uhOhText: string = '';

  closeText: string = '';

  alcoholicLimitText: string = '';

  constructor(
    private readonly modalService: NgbModal,
    private language: LanguageService
  ) { }


  loadText() {
    this.closeText = this.language.getTextElement('close');
    this.uhOhText = this.language.getTextElement('titile_uh_oh');
    this.alcoholicLimitText = this.language.getTextElement(
      'you_have_reached_the_maximum_number_of_alcoholic_items_allowed_for_this_order_limit_is'
    ).replace('%d', this.limit)
  }

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.limit = GeneralSetting.getMaxAlcoholicItemsPerOrder()
      this.loadText();;
    });

    this.negativeColor = GeneralSetting.getNegativeColor();
    this.negativeColorText = GeneralSetting.getNegativeColorText();
  }

  public static open(modalService: NgbModal) {
    let modalReference = modalService.open(AlcoholicItemsExceedMaxComponent, {
      backdrop: 'static',
      size: 'xl',
      centered: true,

    });

    modalReference.componentInstance.modalReference = modalReference;

    return modalReference;
  }

  dismiss() {
    this.modalReference.close();
  }
}
