import { ItemFooterComponent } from './../item-footer/item-footer.component';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { drop } from 'src/app/animations';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { ItemTransferService } from 'src/app/services/item-transfer.service';
import { AlcoholicItemsExceedMaxComponent } from '../dialogs/alcoholic-items-exceed-max/alcoholic-items-exceed-max.component';
import { PusherService } from 'src/app/services/pusher.service';
import { DatabaseService } from '../../services/database.service';
import { GeneralSetting } from '../../services/generalSetting.service';
import { ItemV2 } from 'src/app/models/item';
import { Category } from 'src/app/models/category';
import {
  CategorySalesType,
  CommonFunctions,
  Snackbar,
} from '../../services/common';
import { DataService } from 'src/app/services/data.service';
import { ErrorMsgPopupComponent } from '../dialogs/error-msg-popup/error-msg-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { timeout } from 'rxjs/operators';
import { GeneralAlertComponent } from '../dialogs/general-alert/general-alert.component';
import { LanguageService } from 'src/app/services/language.service';
import { DiscountService } from 'src/app/services/discount.service';
import { FixedItemDiscount } from 'src/app/models/discounts';

@Component({
  selector: 'app-item-individual',
  templateUrl: './item-individual.component.html',
  styleUrls: ['./item-individual.component.css'],
  animations: [drop],
})
export class ItemIndividualComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }
  private allSubsCription: Subscription[] = [];
  @ViewChild(ItemFooterComponent) itemFooter!: ItemFooterComponent;
  bkgdColor: string = '';
  isLoyalty: string;
  branchLogo: string = '';
  priceColor: string | null = '';
  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;
  ItemID: string = '';
  CategoryID: string = '';
  totalCost: number = 0.0;
  quantity: number = 1;
  guid: string = '';
  item: ItemV2 | null;
  category: Category = {} as Category;
  cartItem?: ItemV2;
  isOrderReviewEdit = false;
  cartQuantity: number = 0;
  @ViewChild(AlcoholicItemsExceedMaxComponent)
  alcoholicExceedMaxPopup!: AlcoholicItemsExceedMaxComponent;
  isEdit = false;
  positiveButton: string | null = '';
  positiveButtonText: string | null = '';
  imageRadius: string = '';
  bordersTypeItem: boolean = false;
  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();
  isgettingData = false;
  productDescriptionText: string = '';
  quantityText: string = '';
  loadingText: string = '';
  recalculateText: string = '';
  weightText: string = '';
  itemWeightText: string = '';
  totalPriceText: string = '';
  perOzText: string = '';
  perPoundText: string = '';
  perGramText: string = '';
  perKilogramText: string = '';
  ozText: string = '';
  poundText: string = '';
  gramText: string = '';
  kilogramText: string = '';
  isConcessionaire: boolean = false;
  concessionaireHeaderImage: string = '';
  isShowBranchLogo: boolean = false;
  placeholderImage : string = '';
  fixedItemDisc : FixedItemDiscount[] = [] as FixedItemDiscount[];

  constructor(
    route: ActivatedRoute,
    private readonly itemTransferService: ItemTransferService,
    private readonly cartService: CartService,
    private readonly isKioskService: IsKioskService,
    private databaseService: DatabaseService,
    private dataService: DataService,
    public modalService: NgbModal,
    private language: LanguageService,
    private discountService: DiscountService
  ) {
    this.item = null;
    this.ItemID = route.snapshot.queryParams['id'];
    this.CategoryID = route.snapshot.queryParams['category'];
    this.guid = route.snapshot.queryParams['guid'];
    this.isOrderReviewEdit = route.snapshot.queryParams['reviewEdit'];
    this.isLoyalty = route.snapshot.queryParams['loyalty'];
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }
  loadText() {
    this.productDescriptionText = this.language.getTextElement( 'lbl_product_description' );
    this.quantityText = this.language.getTextElement('quantity');
    this.loadingText = this.language.getTextElement('txt_loading');
    this.recalculateText = this.language.getTextElement('re-calculate_weight');
    this.weightText = this.language.getTextElement('weight');
    this.itemWeightText = this.language.getTextElement('lbl_item_weight');
    this.totalPriceText = this.language.getTextElement('lbl_total_price');
    this.perOzText= this.language.getTextElement('per_oz');
    this.perPoundText= this.language.getTextElement('per_pound');
    this.perGramText= this.language.getTextElement('per_gram');
    this.perKilogramText= this.language.getTextElement('per_kilogram');
    this.ozText= this.language.getTextElement('lbl_oz');
    this.poundText= this.language.getTextElement('lbl_pound');
    this.gramText= this.language.getTextElement('lbl_gram');
    this.kilogramText= this.language.getTextElement('lbl_kilogram');
  }
  ngOnInit(): void {
    this.Init();
    this.placeholderImage = this.dataService.getPlaceholderImage();

    this.setItemTags();
    this.bordersTypeItem = GeneralSetting.getBorderTypeItems().toLowerCase() == 'true';
    this.setFixedItemDisc();
  }

  async setFixedItemDisc(){
    this.fixedItemDisc = await this.discountService.getFixedItemDisc();
  }

    
  setItemTags() {
    setTimeout(() => {
      if (this.itemFooter) {
        this.itemFooter.setItemTags();
        this.itemFooter.itemTagsKeyComponent.setItemTags();
      }
    }, 1);
  }
  private Init(){
    this.positiveButton = GeneralSetting.getPositiveColor();
    this.positiveButtonText = GeneralSetting.getPositiveColorText();
    if (!this.databaseService.hasCategory()) {
      this.databaseService.getPersistentCategoriesAsync(true);
    }
    this.allSubsCription.push(
      this.databaseService.categorySubject.subscribe((categories) => {
        const category = categories.find((x) => x.CategoryID == this.CategoryID);
        this.category = category ?? ({} as Category);
        if (this.guid) {
          this.isEdit = true;
          this.setEditItemDetail();
          return;
        }
        this.isEdit = false;
        const item = this.itemTransferService.getItemV2();
        if (item) {
          this.setDetailByItem(item);
        }
      })
    );
    this.bkgdColor = GeneralSetting.getPrimaryColor();
    this.branchLogo = GeneralSetting.getBranchLogo();
    this.priceColor = GeneralSetting.getPriceColor();
    this.imageRadius = GeneralSetting.getBorderPercentage();
    this.isConcessionaire = GeneralSetting.getIsConcessionaire().toLowerCase() == 'true';
    if (this.isConcessionaire){
      this.concessionaireHeaderImage = GeneralSetting.getConcessionaireHeaderImage();
      this.isShowBranchLogo = GeneralSetting.getHideLogo();
    }
  }
  private setEditItemDetail() {
    const cartItem = this.cartService.getCartItems().find((x: any) => x.guid == this.guid)
    if (cartItem && cartItem != null) {
      const item = JSON.parse(JSON.stringify(cartItem)) as ItemV2
      this.item = item
      if (!this.item || this.item == null) return
      this.itemTransferService.snapshotItemV2(this.item)
      this.cartQuantity = parseInt( (this.item ? Number(this.item.Quantity) : 1).toString() )
      this.quantity = this.item ? Number(this.item.Quantity) : 1
      const cartItems = this.cartService.getCartItems().filter((x) => x.ItemID == item.ItemID)
      if (cartItems && cartItems.length > 0) {
        const cartItemsQuantity = Number(cartItems.map((x) => Number(x.Quantity)).reduce((a, b) => a + b, 0))
        this.cartQuantity = cartItemsQuantity
      }
      this.cartQuantity = Number(this.quantity) - this.cartQuantity
    }
    return
  }
  private setDetailByItem(categoryItem: ItemV2) {
    if(!categoryItem) return
    // categoryItem =  await this.databaseService.getItemDetails(categoryItem);
    const item = JSON.parse(JSON.stringify(categoryItem)) as ItemV2
    if(!item) return
    if(this.itemTransferService.itemV2?.OldPrice && this.itemTransferService.itemV2?.OldPrice != undefined) {
      item.Price = this.itemTransferService.itemV2?.Price;
      item.OldPrice = this.itemTransferService.itemV2?.OldPrice;
    }
    this.quantity = Number(item?.Quantity) || 1;
    this.itemTransferService.snapshotItemV2(item);
    this.item = item;
    // console.log(this.item);
  }
  private checkQtyOfAlcohol() {
    const alcoholMaxQuantity =
      GeneralSetting.getMaxAlcoholicItemsPerOrder() == null ||
      GeneralSetting.getMaxAlcoholicItemsPerOrder() == ''
        ? 9999
        : Number(GeneralSetting.getMaxAlcoholicItemsPerOrder())
    if (!this.item) return;
    const itemMax = this.item.OrderMaximumQuantity
    const currentCountofAlkol: number = Number( GeneralSetting.getCurrentCountofAlkol() );
    if (alcoholMaxQuantity <= 0 || currentCountofAlkol >= alcoholMaxQuantity) {
      AlcoholicItemsExceedMaxComponent.open(this.modalService)
      return;
    }
    const remaining = Number(alcoholMaxQuantity) - Number(this.quantity)
    const currentValue = this.quantity
    if (Number(itemMax) > 0) {
      if (Number(currentValue) < Number(itemMax) && remaining > 0) {
        this.quantity = Number(this.quantity) + 1
      } else if (remaining <= 0) {
        AlcoholicItemsExceedMaxComponent.open(this.modalService)
      }
    } else {
      if (remaining > 0) {
        this.quantity = Number(this.quantity) + 1
      } else {
        AlcoholicItemsExceedMaxComponent.open(this.modalService)
      }
    }
  }
  increaseQuantity(): void {
    if (!this.item) return;
    this.increaseAmount(this.item);
  }
  decreaseQuantity(): void {
    if (!this.item) return;
    if (Number(this.quantity) > 1) {
      if (this.item.OrderMinimumQuantity != Number('')) {
        if (Number(this.quantity) > Number(this.item.OrderMinimumQuantity)) {
          this.quantity = Number(this.quantity) - 1
        }
      } else {
        this.quantity = Number(this.quantity) - 1
      }
    }
  }
  // Added to resolve Alcohol quantity issue in Increment as well as in item edit mode - Rekha
  increaseAmount(item: any) {
    // Checks fixed item quantity limit
    if(this.fixedItemDisc != undefined && this.fixedItemDisc.length > 0){
      var isFixedItemAvail = this.discountService.isFixedItemAvail(this.fixedItemDisc[0],item, this.quantity);
      if(isFixedItemAvail){
        return;
      }
    }

    let currentAlcoholCount = Number(GeneralSetting.getCurrentCountofAlkol());
    let currentQty = Number(item.Quantity == undefined ? '1' : item.Quantity);
    let maxNo = Number(item.OrderMaximumQuantity);
    let alkolMax = Number(GeneralSetting.getMaxAlcoholicItemsPerOrder());
    if (!maxNo) {
      if (item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString() && alkolMax > 0) {
        let currentAlcohol = 1
        if (this.isEdit) {
          currentAlcohol = currentAlcoholCount - currentQty
          currentAlcohol += this.quantity;
        } else {
          currentAlcohol = currentAlcoholCount + this.quantity
        }
        if (currentAlcohol + 1 > alkolMax) {
          AlcoholicItemsExceedMaxComponent.open(this.modalService)
        } else {
          // GeneralSetting.increaseAlcohol(1);
          this.quantity = Number(this.quantity) + 1;
        }
      } else {
        this.quantity = Number(this.quantity) + 1;
      }
    } else {
      if (item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString() && alkolMax > 0) {
        let currItemQty = this.isEdit
          ? this.quantity - Number(item.Quantity)
          : this.quantity;
        const currentAlcohol: number = currentAlcoholCount + currItemQty;
        if (currentAlcohol + 1 > alkolMax) {
          AlcoholicItemsExceedMaxComponent.open(this.modalService);
        } else {
          this.quantity = Number(this.quantity) + 1;
        }
      } else {
        this.quantity = Number(this.quantity) + 1;
      }
    }
  }
  onRecalculate() {
    this.isgettingData = true;
    this.allSubsCription.push(
      this.dataService
        .getWeight('W', GeneralSetting.getRaspberryPiIPAddress())
        .pipe(timeout(10000))
        .subscribe(
          (response: any) => {
            //console.log(response);
            this.isgettingData = false;
            if (response && response != '') {
              if (response == 'error') {
                let errorModel = new GeneralAlertComponent( this.modalService, this.language );
                let title=this.language.getTextElement('titile_uh_oh')
                let subTitle=this.language.getTextElement('server_communiation_error')
                errorModel.open(title, subTitle);
                return;
              }
              if (this.item) {
                // response = response.slice(1);
                let weightArray = response.replace(/ /g, '').match(/[a-z]+|[^a-z]+/gi) ?? ''; /** will seperate number and weight unit like g,kg */
                if (weightArray.length > 1) {
                  let weightUnitFromServer = weightArray[weightArray.length - 1]
                  let weightCountFromServer = weightArray[0];
                  // weightCountFromServer = weightCountFromServer.slice(1);
                  if (weightUnitFromServer.toLowerCase() == CommonFunctions.getWeightUnit(this.item.WeightUnitID)) {
                    this.item.Weight = Number(weightCountFromServer.trim())
                    this.item.Quantity = weightCountFromServer.trim()
                  } else {
                    let errorModel = new GeneralAlertComponent( this.modalService, this.language );
                    let title=this.language.getTextElement('titile_uh_oh')
                    let subTitle=this.language.getTextElement('change_weight_unit').replace('%d',CommonFunctions.getWeightUnitInWords( this.item.WeightUnitID ))
                    errorModel.open(title, subTitle);
                  }
                } else {
                  if (weightArray.length > 0) {
                    let errorModel = new GeneralAlertComponent( this.modalService, this.language );
                    let title=this.language.getTextElement('titile_uh_oh')
                    let subTitle=this.language.getTextElement('exceeded_weight_limit') 
                    errorModel.open( title, subTitle ); 
                  }
                }
              }
            } else {
              let errorModel = new GeneralAlertComponent( this.modalService, this.language );
              let title=this.language.getTextElement('titile_uh_oh')
              let subTitle=this.language.getTextElement('server_communiation_error')
              errorModel.open(title, subTitle);
            }
          },
          (error) => {
            //console.log(error);
            this.isgettingData = false;
            let errorModel = new GeneralAlertComponent( this.modalService, this.language );
            let title=this.language.getTextElement('titile_uh_oh')
            let subTitle=this.language.getTextElement('server_communiation_error')
            errorModel.open(title, subTitle);
          }
        )
    );
  }
  /**
   * Sets branch header image
   * @param headerImageUrl
   * @param imageBaseUrl
   * @returns
   */
  setHeaderImage(headerImageUrl: string, imageBaseUrl: string) {
    return headerImageUrl && headerImageUrl != ''
      ? imageBaseUrl + headerImageUrl
      : '';
  }

}
