<div class='text-center' >
    <p class="tax-title">{{taxDetailText|uppercase}}</p>
    <div class="tax-info-container">
        <div *ngFor="let taxes of taxDetail" class="tax-item">
          <p> {{taxes.Name }} ({{ taxes.TaxPer | number: '1.0-3'}}%) </p>
            <p> {{ taxes.TaxAmount | number: '1.2-2' | CurrencyPipe}}</p>
         </div>
         <button (click)="close()" class="close-tax-button">
             {{okText|uppercase}}
         </button>
    </div>
</div>
