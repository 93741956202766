import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/language.service';
import { PaymentTotalRowComponent } from '../../payment-total-row/payment-total-row.component'; 
@Component({
  selector: 'app-usconnect-scan',
  templateUrl: './usconnect-scan.component.html',
  styleUrls: ['./usconnect-scan.component.css'],
})
export class UsconnectScanComponent implements OnInit, OnDestroy {
  @ViewChild(PaymentTotalRowComponent) paymentRow!:PaymentTotalRowComponent
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  private allSubsCription: Subscription[] = [];
  total: number=0
  subtotal: number=0
  tax: number=0
  discount: number=0
  reward: number=0
  shipping: number=0

  showPayment:boolean = false
  modalReference: any;
  paymentType: string = '';
  title: string = '';
  subTitle: string = '';
  barcodeInput!: FormControl;
  languageSub!: Subscription;
  checkoutText: string = '';
  holdBarcodeText: string = '';
  constructor(
    private modalService: NgbModal,
    private language: LanguageService
  ) {}

  loadText() {
    this.checkoutText=this.language.getTextElement('lbl_checkout').toLocaleUpperCase();
    this.holdBarcodeText=this.language.getTextElement('hold_barcode_to_scanner')
  }

  ngOnInit(): void {
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
    this.loadData();
    this.subscribeToValueChange();
  }

  public static open(modelService: NgbModal, paymentType: string, total:number=0,subtotal:number=0,tax:number=0,shipping:number=0,discount:number=0,reward:number=0,showPayment:boolean=false) {
    var modalReference = modelService.open(UsconnectScanComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    })
    modalReference.componentInstance.modalReference = modalReference
    modalReference.componentInstance.showPayment = showPayment
    modalReference.componentInstance.paymentType = paymentType
    modalReference.componentInstance.total=total
    modalReference.componentInstance.subtotal=subtotal
    modalReference.componentInstance.tax=tax
    modalReference.componentInstance.shipping=shipping
    modalReference.componentInstance.discount=discount
    modalReference.componentInstance.reward=reward
    return modalReference
  }

  loadData() {
    /**
     * Payment Types 1=Us Connect , 2 = ID Card
     */
    if (this.paymentType == '1') {
      //us connect
      this.title = this.checkoutText;
      this.subTitle = this.language.getTextElement('pay_with_us_connect');
    } else if (this.paymentType == '2') {
      //id card payment
      this.title = this.language.getTextElement('tap_your_id_card_to_pay').toLocaleUpperCase();
      this.subTitle = this.language.getTextElement('tap_your_card_on_the_reader');
    }
  }

  close(event: string) {
    this.modalReference.close(event);
  }

  subscribeToValueChange() {
    this.barcodeInput = new FormControl('');

    this.allSubsCription.push(
      this.barcodeInput.valueChanges
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((inputText) => {
          this.modalReference.close({ eventtype: 'result', data: inputText });
          //console.log(inputText);
        })
    );
  }
}
