import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-discount-confirmation',
  templateUrl: './discount-confirmation.component.html',
  styleUrls: ['./discount-confirmation.component.css']
})
export class DiscountConfirmationComponent implements OnInit,OnDestroy {

  modalReference: any;
languageSub!:Subscription
  constructor(private modalService: NgbModal,private language:LanguageService) { }

  messageText: string = '';
  positiveBtnText: string = '';
  negativeBtnText: string = '';
  discountText:string=''
  alertFor: number = 0;
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText(){
    this.discountText=this.language.getTextElement('txt_discount')
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  public static open(modalService:NgbModal, msg: string, positiveButtonText: string, negativeButtonText: string, alertFor: number) {
    let modalReference = modalService.open(DiscountConfirmationComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.messageText = msg;
    modalReference.componentInstance.positiveBtnText = positiveButtonText;
    modalReference.componentInstance.negativeBtnText = negativeButtonText;
    modalReference.componentInstance.alertFor = alertFor
    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }

  open(msg: string, positiveButtonText: string, negativeButtonText: string, alertFor: number) {
    this.modalReference = this.modalService.open(DiscountConfirmationComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    this.modalReference.componentInstance.messageText = msg;
    this.modalReference.componentInstance.positiveBtnText = positiveButtonText;
    this.modalReference.componentInstance.negativeBtnText = negativeButtonText;
    this.modalReference.componentInstance.alertFor = alertFor;

    this.modalReference.componentInstance.modalReference = this.modalReference;
  }

  onYesClick() {
    this.modalReference.close('Yes');
  }
  onNoClick() {
    this.modalReference.close('No');
  }
}
