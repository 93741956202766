<div id="custominfo-popup" role="dialog">
  <div class="header">
    <span
      *ngIf="!isShowAfterAcountCreated"
      (click)="close()"
      class="close"
      aria-disabled="true"
      >&times;</span
    >
    <p class="text-center font-size6 bold" *ngIf="showCompany">{{ company }}</p>
    <p class="text-center font-size5" innerHtml="{{ title }}"></p>
    <p class="text-center font-size12 bold hmargin6 vcentered">
      {{ titleText }}
    </p>
    <p class="text-center" innerHtml="{{ description }}"></p>
  </div>
  <div class="middle-image" *ngIf="!punchhErrorObject.internalNotFound">
    <img
      src="{{ middleImageSRC }}"
      onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
      class="img-responsive"
      alt=""
    />
  </div>
  <div *ngIf="loyaltyType == 1 || loyaltyType == 3">
    <!-- For Punchh or LevelUp -->
    <div class="two-lines-container" *ngIf="!isShowAfterAcountCreated&&!punchhErrorObject.internalNotFound">
      <span>{{ scanQRText }}</span>
      <span *ngIf="allowPhoneLogin" class="two-lines"
        ><div class="line1"></div>
        <span class="static">{{orText}}</span>
        <div class="line2"></div
      ></span>
      <span *ngIf="allowPhoneLogin">{{ enterPhoneText }}</span>
    </div>
    <div
      class="info-input form-group align-items-center"
      *ngIf="!isShowAfterAcountCreated"
    >
      <div class="">
        <div *ngIf="allowPhoneLogin" class="input-group inputbox phone">
          <div
            class="tool-tip"
            *ngIf="punchhErrorObject.isShowPhoneErrorPunchh"
          >
            <p class="text-center" innerHtml="{{ phoneErrorMessage }}"></p>
          </div>

          <label for="phoneNumber">{{ phoneNumberText }}</label>
          <input
            #phoneNumberforlType13
            KeyboardCustom
            [readonly]="isKiosk"
            layout="numeric"
            customLength="{{ phoneLength }}"
            type="tel"
            class="form-control"
            name="phone"
            id="phoneNumber"
            mask="{{ mask }}"
            [showMaskTyped]="false"
            [validation]="false"
            [(ngModel)]="customerPhonePunchh"
            (ngModelChange)="onPhoneChangePunchh()"
            onfocus="this.value = this.value;"
          />
          <!-- Commented input pending completion of required keyboard tasks -- see TS -->
          <!-- <input
            readonly
            type="tel"
            class="form-control"
            name="phone"
            id="phoneNumber"
            mask="{{ mask }}"
            [showMaskTyped]="false"
            [validation]="false"
            [value]="customerPhoneDisplayPunchh"
            (change)="onPhoneChangePunchh()"            
          /> -->
        </div>
        
        <div class="middle-image" *ngIf="levelUpErrorObject.internalNotFound">
          <p class="error">
            {{ levelUpErrorObject.displayError }}
          </p>
        </div>
        <div
          [style.display]="isShowFirstNamePunchh ? 'block' : 'none'"
          class="inputbox name"
        >
          <div
            class="tool-tip2"
            *ngIf="punchhErrorObject.isShowFirstNameErrorPunchh"
          >
            {{ enterFirstNameText }}
          </div>
          <label for="firstname">{{ firstNameText }}</label>
          <input
            KeyboardCustom
            [readonly]="isKiosk"
            layout="alpha"
            id="firstName"
            customLength="350"
            class="form-control"
            name="name"
            [(ngModel)]="customerFirstNamePunchh"
          />
        </div>
        <div
          [style.display]="isShowLastNamePunchh ? 'block' : 'none'"
          class="inputbox name"
        >
          <div
            class="tool-tip2"
            *ngIf="punchhErrorObject.isShowLastNameErrorPunchh"
          >
            {{ enterLastNameText }}
          </div>
          <label for="name">{{ lastNameText }}</label>
          <input
            KeyboardCustom
            [readonly]="isKiosk"
            layout="alpha"
            id="lastName"
            customLength="350"
            class="form-control"
            name="name"
            [(ngModel)]="customerLastNamePunchh"

          />
        </div>
        <div
          [style.display]="isShowEmailPunchh ? 'block' : 'none'"
          class="inputbox name"
        >
          <div
            class="tool-tip2"
            *ngIf="punchhErrorObject.isShowEmailErrorPunchh"
          >
            {{ enterEmailText }}
          </div>
          <label for="name">{{ emailText }}</label>
          <input
            KeyboardCustom
            [readonly]="isKiosk"
            id="email"
            customLength="350"
            class="form-control"
            name="name"

            [(ngModel)]="customerEmailPunchh"
            (ngModelChange)="onEmailChangePunchh()"
          />
        </div>

        <div
          [style.display]="isShowPasswordPunchh ? 'block' : 'none'"
          class="inputbox name"
        >
          <div
            class="tool-tip2"
            *ngIf="punchhErrorObject.isShowPasswordErrorPunchh"
          >
            {{ passwordErrorPunchh }}
          </div>
          <label for="name">{{ passwordText }}</label>
          <div class="input-group">
            <input
              KeyboardCustom
              [readonly]="isKiosk"
              id="password"
              [type]="!isShown ? 'password' : 'text'"
              customLength="350"
              class="form-control"
              name="name"
              [(ngModel)]="customerPasswordPunchh"
              (ngModelChange)="onPasswordChangePunchh()"
              
            />
            <span
              id="showPassword"
              class="input-group-btn password-button bg-none b-0 b-shadow-0"
            >
              <button
                class="btn btn-default reveal bg-none b-0 b-shadow-0"
                type="button"
              >
                <span
                  [ngClass]="!isShown ? 'far fa-eye-slash' : 'far fa-eye'"
                  (click)="revealPassword()"
                ></span>
              </button>
            </span>
          </div>
          <div class="link">
            <a
              [ngStyle]="{
                color: showForgotPasswordClickedPunchh ? 'purple' : 'black'
              }"
              id="punchhForgotPassword"
              (click)="punchhForgotPassword()"
              >{{ forgotPasswordText }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="middle-image" *ngIf="punchhErrorObject.internalNotFound">
        <p>
          {{ punchhErrorObject.displayError }}
        </p>
      </div>
      <button
        *ngIf="
          !this.isShowAfterAcountCreated &&
          ((allowPhoneLogin && customerPhonePunchh.length == phoneLength) ||
            (!allowPhoneLogin &&
              customerEmailPunchh.length &&
              customerPasswordPunchh.length))
        "
        class="text-center"
        (click)="getLoyaltyCustomerRouter()"
        class="green-btn"
      >
        {{ signinText|uppercase }}
      </button>
      <p
        class="text-center"
        *ngIf="isShowApiErrorText"
        innerHtml="{{ apiErrorText }}"
      ></p>
      <!-- <button
        *ngIf="!isShowFirstNamePunchh"
        class="text-center"
        (click)="onSignUpPunchhClick()"
        class="green-btn"
      >
        SIGN UP
      </button> -->
      <button
        *ngIf="!this.isShowAfterAcountCreated"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close(''); $event.stopPropagation()"
        class="plain-btn"
      >
        {{ continueAsGuestText }}
      </button>
    </div>
  </div>

  <div *ngIf="loyaltyType == 2">
    <!-- For Refferizer -->
    <div
      class="info-input form-group align-items-center"
      *ngIf="!isShowAfterAcountCreated"
    >
      <div class="">
        <div
          [style.display]="isShowName ? 'block' : 'none'"
          class="inputbox name"
        >
          <label for="name">{{ nameText }}</label>
          <input
            KeyboardCustom
            layout="alpha"
            [readonly]="isKiosk"
            id="name"
            customLength="350"
            style="height: 13rem !important; font-size: 4.5rem !important"
            class="form-control"
            name="name"
            [(ngModel)]="customerName"
          />
        </div>

        <div class="input-group inputbox phone">
          <div class="tool-tip" style="display: none">
            {{ validPhoneText }}
          </div>
          <div class="tool-tip" *ngIf="isShowCreateAccountToolTip">
            {{ accountNotFoundText }}
          </div>
          <div class="tool-tip" *ngIf="isShowUniquePhoneError">
            {{ phoneUniqueText }}
          </div>
          <div class="check-container">
            <label for="phoneNumber">{{ phoneNumberText }}</label>
            <input
              #phoneNumberforlType2
              KeyboardCustom
              [readonly]="isKiosk"
              layout="numeric"
              customLength="{{ phoneLength }}"
              type="tel"
              class="form-control"
              name="phone"
              id="phoneNumber"
              mask="{{ mask }}"
              style="height: 13rem !important; font-size: 4.5rem !important"
              [showMaskTyped]="false"
              [validation]="false"
              (change)="onPhoneChange()"
              [(ngModel)]="customerPhone"
              onfocus="this.value = this.value;"
            />
            <div class="input-group-append">
              <button
                appDebounceClick
                (click)="getLoyaltyCustomerByPhone()"
                class="btn green-btn"
                type="button"
                *ngIf="customerPhone.length == phoneLength"
              >
                <i class="fa fa-check"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer" *ngIf="!isShowAfterAcountCreated">
      <button
        class="text-center"
        (click)="createAccount()"
        class="green-btn"
        *ngIf="isShowCreateAccount"
      >
        {{ createAccountText }}
      </button>
      <!--Error Msg-->
      <!--<p class="text-center warn">Error : Internal Server Error</p>-->
      <button
        data-dismiss="modal"
        aria-label="Close"
        (click)="close(''); $event.stopPropagation()"
        class="plain-btn"
      >
        CONTINUE AS GUEST
      </button>
    </div>
  </div>
  <div *ngIf="loyaltyType == 4">
    <!-- For Paytronix -->
    <p class="signInText text-center font-size8 semi-bold">{{signInBelowText}}</p>
    <div class="info-input form-group align-items-center">
      <div [style.display]="'block'" class="inputbox name">
        <div class="tool-tip2" *ngIf="paytronixObject.passwordErrorShown">
          {{ paytronixObject.usernameError }}
        </div>
        <label for="name">{{userNameText}}</label>
        <div class="input-group">
          <input
            #usernameforlType4
            KeyboardCustom
            [readonly]="isKiosk"
            id="password"
            customLength="350"
            class="form-control"
            name="name"
            [(ngModel)]="paytronixObject.username"
          />
        </div>
      </div>
      <div [style.display]="'block'" class="inputbox name">
        <div class="tool-tip2" *ngIf="paytronixObject.passwordErrorShown">
          {{ paytronixObject.passwordError }}
        </div>
        <label for="name">{{passwordText}}</label>
        <div class="input-group">
          <input
            KeyboardCustom
            [readonly]="isKiosk"
            id="password"
            [type]="!isShown ? 'password' : 'text'"
            customLength="350"
            class="form-control"
            name="name"
            [(ngModel)]="paytronixObject.password"
          />
          <span
            id="showPassword"
            class="input-group-btn password-button bg-none b-0 b-shadow-0"
          >
            <button
              class="btn btn-default reveal bg-none b-0 b-shadow-0"
              type="button"
            >
              <span
                [ngClass]="!isShown ? 'far fa-eye-slash' : 'far fa-eye'"
                (click)="revealPassword()"
              ></span>
            </button>
          </span>
        </div>
      </div>
      <div class="footer" *ngIf="!isShowAfterAcountCreated">
        <div class="tool-tip3" *ngIf="paytronixObject.generalErrorShown">
          {{ paytronixObject.generalError }}
        </div>
        <button
          *ngIf="paytronixObject.password.length && paytronixObject.username"
          class="text-center"
          (click)="getLoyaltyCustomerRouter()"
          class="green-btn"
        >
          {{ signinText }}
        </button>
        <button
          data-dismiss="modal"
          aria-label="Close"
          (click)="close(''); $event.stopPropagation()"
          class="plain-btn"
        >
        {{continueAsGuestText}}
        </button>
      </div>
    </div>
  </div>
</div>
<app-loading [hidden]="true"></app-loading>
<app-olototal-loader [hidden]="true"></app-olototal-loader>
