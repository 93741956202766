import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/services/cart.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-level-up-modal',
  templateUrl: './level-up-modal.component.html',
  styleUrls: ['./level-up-modal.component.css']
})
export class LevelUpModalComponent implements OnInit,OnDestroy {
  modalReference!: NgbModalRef;
  languageSub!:Subscription
  branchName:string=GeneralSetting.getBranchName()
  total: number=0
  subtotal: number=0
  tax: number=0
  discount: number=0
  reward: number=0
  shipping: number=0
  case:number=0
  showPayment:boolean=false
  checkoutText:string=''
  totalDueText:string=''
  transactionDeclinedText:string=''
  paymentDeclinedText:string=''
  tryAgainOrUseAnotherMethodText:string=''
  scanQRCodeText:string=''
  tryAgainText:string=''
  tryAnotherMethodText:string=''
  constructor(private readonly modalService: NgbModal, private readonly cart:CartService, private language:LanguageService) {
    
  }
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText(){
    this.checkoutText=this.language.getTextElement('lbl_checkout')
    this.totalDueText=this.language.getTextElement('is_the_total_due').replace('%d',GeneralSetting.getCurrencySymbol()+this.cart.total.toFixed(2).toString() )
    this.transactionDeclinedText=this.language.getTextElement('transaction_declined')
    this.paymentDeclinedText=this.language.getTextElement('your_payment_is_declined')
    this.tryAgainOrUseAnotherMethodText=this.language.getTextElement('please_try_again_or_try_another_payment_method')
    this.scanQRCodeText=this.language.getTextElement('scan_your_qr_code_in_the_loyalty_app_to_use_your_card_on_file_to_complete_payment').replace('%d',this.branchName)
    this.tryAgainText=this.language.getTextElement('try_again')
    this.tryAnotherMethodText=this.language.getTextElement('try_another_method')
  }
  ngOnInit(): void {
    this.total=this.cart.total
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }
  open(total:number,subtotal:number,tax:number,shipping:number,discount:number,reward:number,c:number=1,showPayment:boolean=false) {
    const modalReference=this.modalService.open(LevelUpModalComponent, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });
    modalReference.componentInstance.total=total;
    modalReference.componentInstance.subtotal=subtotal;
    modalReference.componentInstance.tax=tax;
    modalReference.componentInstance.shipping=shipping;
    modalReference.componentInstance.discount=discount;
    modalReference.componentInstance.reward=reward;
    modalReference.componentInstance.case=c;
    modalReference.componentInstance.modalReference=modalReference;
    this.modalReference=modalReference;
  }
  clear(c:number=0) {
    var messageType = 'stopScanner';
    var message = {
      messageType: messageType,
    };
    window.top!.postMessage(message, '*');
    this.modalReference.close(c);
  }
}
