import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-refund-confirmation',
  templateUrl: './refund-confirmation.component.html',
  styleUrls: ['./refund-confirmation.component.css'],
})
export class RefundConfirmationComponent implements OnInit,OnDestroy {
  
  ref!: NgbModalRef;
  
  languageSub!:Subscription
  
  confirmText:string=''
  
  cancelText:string=''
  
  yesText:string=''
  
  refundAlertText:string=''
  
  constructor(private modalService: NgbModal,private language:LanguageService) {}
  
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {    
    this.confirmText = this.language.getTextElement('do_you_want_refund_order');
    this.cancelText = this.language.getTextElement('lbl_button_cancel');
    this.yesText = this.language.getTextElement('yes');
    this.refundAlertText = this.language.getTextElement('refund_alert');
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }
  public static open(modalService: NgbModal) {
    let ref = modalService.open(RefundConfirmationComponent, {
      centered: true,
      size: 'lg',
    });
    ref.componentInstance.ref = ref;
    return ref;
  }
  open() {
    this.ref = this.modalService.open(RefundConfirmationComponent, {
      centered: true,
      size: 'lg',
    });
    this.ref.componentInstance.ref = this.ref;
    return this.ref;
  }
  back() {
    this.ref.close('cancel');
  }
  refund() {
    this.ref.close('refund');
  }
}
