<div role="tabpanel" class="tab-pane" id="items">
  <div class="row mb-3rem">
    <div class="col-md-6 col-6">{{manageText}}</div>
    <div class="col-md-6 col-6 text-right">
      <input [(ngModel)]="isChecked" type="checkbox" class="switch_1" id="selectall" name="selectall" value="Select All"
        (change)="selectAll(isChecked)" />
      <label for="selectall">{{selectAllText}}</label>
    </div>
  </div>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col" class="text-center">{{categoryText}}</th>
        <th scope="col" class="text-center">{{itemText}}</th>
        <th scope="col" class="text-center">{{displayItemMenuText}}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let category of categories; let i = index">
        <tr>
          <td class="text-left">{{ category.Name }}</td>
          <td></td>
          <td class="text-center">
            <input type="checkbox" class="switch_1" id="check{{ category.CategoryID }}" name="check1"
              [checked]="(category.DisplayCategory)" (change)="checkCategory(category, $event)" />
            <label for="check{{ category.CategoryID }}"></label>
          </td>
        </tr>
        <ng-container *ngFor="let item of category.items; let j = index">
          <tr>
            <td class="text-center">{{ item.category }}</td>
            <td>{{ item.name }}</td>
            <td class="text-center">
              <input type="checkbox" class="switch_1" id="check{{ item.name }}" name="check{{ item.name }}"
                [checked]="item.isActive" (change)="checkValue(item, category, $event, false,false)" />
              <label for="check{{ item.name  }}"></label>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
<!-- Remove print button, hide button from UI
https://grubbrr.atlassian.net/browse/SK-1787
-->
<!-- <button>{{printText}}</button> -->
<br />
<br />