import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';

import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { loggingData, LogService } from 'src/app/services/log.service';
import { SaveSettingsService } from 'src/app/services/save-settings.service';
import { DataService } from '../../../services/data.service';
import { DatabaseHandler } from '../../../DatabaseHandler';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-settings-refresh-data',
  templateUrl: './settings-refresh-data.component.html',
  styleUrls: ['./settings-refresh-data.component.css'],
})
export class SettingsRefreshDataComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  dataService: DataService;
  dataDate: string = '';
  saveSettingsService: SaveSettingsService;

  languageSub!: Subscription;
  refreshingText:string=''
  refreshText:string=''
  lastSyncText:string=''
  constructor(
    DataService: DataService,
    private zone: NgZone,
    private router: Router,
    private logger: LogService,
    SaveSettingsService: SaveSettingsService,
    private language:LanguageService
  ) {
    this.dataService = DataService;
    this.dataDate = localStorage.getItem('syncDate') || '';
    this.saveSettingsService = SaveSettingsService;
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    )
  }
  loadText(){
    this.refreshingText=this.language.getTextElement('refreshing_menu')
    this.refreshText=this.language.getTextElement('lbl_refresh')
    this.lastSyncText=this.language.getTextElement('text_last_sync_on')
  }
  ngOnInit(): void {

  }

  doRefresh() {
    Array.from(
      document.getElementsByClassName(
        'loading-overlay'
      ) as HTMLCollectionOf<HTMLElement>
    )[0].style.visibility = 'visible';

    this.allSubsCription.push(
      this.dataService.refreshData().subscribe(
        (val: any) => {
          this.parseBranchData(JSON.parse(JSON.stringify(val)));
        },
        (response: any) => {

          console.log("Refresh Api Failed", response);
          var log = new loggingData(
            'Refresh Data',
            'Refresh data Api failed',
            'Refresh Data',
            response,
            true
          );
          this.logger.sendLogToServer(log);

          const snackbar = document.getElementById('snackbar');

          Array.from(
            document.getElementsByClassName(
              'loading-overlay'
            ) as HTMLCollectionOf<HTMLElement>
          )[0].style.visibility = 'hidden';

          if (snackbar) {
            snackbar.className = 'show';
            snackbar.innerHTML = 'Network Error';
          }

          setTimeout(() => {
            if (snackbar) snackbar.className = '';
          }, 3000);
        },
        () => {
          this.dataDate =
            this.dataService.dataDate.toDateString() !==
              new Date(0).toDateString()
              ? this.dataService.dataDate.toLocaleString()
              : '';
          Array.from(
            document.getElementsByClassName(
              'loading-overlay'
            ) as HTMLCollectionOf<HTMLElement>
          )[0].style.visibility = 'hidden';
        }
      )
    );
  }

  parseBranchData(data: { [key: string]: object }): void {
    let keys = Object.keys(data);
    keys.forEach((table) => {
      let test = data;

      try {
        if (
          table in test &&
          test[table] != null &&
          (test[table] as Array<object>).length != 0
        ) {
          var tableData = test[table];

          if (!Array.isArray(tableData)) {
            tableData = [tableData];
          }

          var statements = [];
          var variables = [];
          var callbacks = [];
          var callbacksFail = [];

          var LogError = (error: string): void => {

            console.log("Setting Refresh , Error Parse Branch Data :- " + table, error);

            const log = new loggingData(
              'Parse Branch Data',
              error,
              table + ' Parse Branch Data Error',
              'Error Parsing table data',
              false
            );
            this.logger.sendLogToServer(log);
          };
          var doNothing = (): void => {
            this.saveSettingsService.theme();
          };

          var firstRow = Object.values(tableData)[0];

          var drop = `DROP TABLE IF EXISTS ${table}`;
          statements.push(drop as any);
          variables.push([] as any);
          callbacks.push(doNothing as any);
          callbacksFail.push(LogError as any);

          var create = `CREATE TABLE IF NOT EXISTS ${table} (`;
          Object.keys(firstRow).forEach((key) => (create += `${key}, `));
          create = create.slice(0, -2); //remove last ", "
          create += `)`;
          statements.push(create as any);
          variables.push([] as any);
          callbacks.push(doNothing as any);
          callbacksFail.push(LogError as any);

          Object.values(tableData).forEach(function (row) {
            let rowKeys = Object.keys(row);
            let statement = `INSERT INTO ${table} (`;
            let vars: String[] = [];
            rowKeys.forEach((key) => {
              statement += `${key}, `;
            });

            statement = statement.slice(0, -2); //remove last ", "
            statement += `) VALUES (`;

            let values: String[] = Object.values(row);

            statement += `?, `.repeat(values.length);
            statement = statement.slice(0, -2); //remove last ", "
            statement += `)`;

            vars = vars.concat(values);

            statements.push(statement as any);
            variables.push(vars as any);
            callbacks.push(doNothing as any);
            callbacksFail.push(LogError as any);
          });

          DatabaseHandler.executeSqlTransation(
            statements as any,
            variables as any,
            callbacks as any,
            callbacksFail as any
          );
        }
      } catch (error) { }
    });
  }
}
