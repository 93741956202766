import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-general-alert',
  templateUrl: './general-alert.component.html',
  styleUrls: ['./general-alert.component.css']
})
export class GeneralAlertComponent implements OnInit {
  languageSub!: Subscription;

  ref: any;
  title: string = '';
  titleText:string=''
  subTitle: string = '';
  subTitleText: string = '';
  okText:string=''
  
  constructor(private modalService: NgbModal, private language:LanguageService) { }

  loadText(){
    this.okText=this.language.getTextElement('txt_ok')
    this.titleText=this.language.getTextElement('lbl_uh_oh')
    this.subTitleText=this.language.getTextElement('try_again')
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  public static open(modalService:NgbModal,title: string = "", subTitle: string = "") {
    let ref = modalService.open(GeneralAlertComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    ref.componentInstance.title = title;
    ref.componentInstance.subTitle = subTitle;
    ref.componentInstance.ref = ref;
    return ref
  }

  open(title: string = this.titleText, subTitle: string = this.subTitleText) {
    this.ref = this.modalService.open(GeneralAlertComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    this.ref.componentInstance.title = title;
    this.ref.componentInstance.subTitle = subTitle;
    this.ref.componentInstance.ref = this.ref;
    return this.ref
  }

  onOkClick() {
    this.ref.close('Ok');
  }
}
