<section>
    <div class="pay-id" role="document">
        <div class="payment-modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close('cancel')">
                <span aria-hidden="true">&times;</span>
            </button>
            <h2 class="text-center">{{title}}</h2>
            <h4>{{subTitle}}</h4>
            <input KeyboardCustom layout="alphaNumeric" [readonly]="isKiosk" id="barcode" [(ngModel)]="memberid"  type="text" />

            <!-- <span [ngClass]="paymentType == '1' ? 'title' : 'subTitle'">{{
                subTitle
                }}</span>
            <div *ngIf="showPayment">
                <app-payment-total-row [total]="total" [subtotal]="subtotal" [discount]="discount" [tax]="tax"
                    [shipping]="shipping" [reward]="reward"></app-payment-total-row>
            </div> -->


            <h4 *ngIf="paymentType == '1'" class="text-center">
                {{holdBarcodeText}}
            </h4>
            <!-- <div class="col-md-12 col-xs-12 p-0 insert-img">
                <span *ngIf="paymentType == '1'">
                    <img src="../../../../assets/images/PayWithUSConnect.gif"
                        onerror="this.onerror=null; this.src='assets/images/Image_21.png'" />
                </span>
                <span *ngIf="paymentType == '2'">
                    <img src="../../../../assets/images/RFID_tap.gif"
                        onerror="this.onerror=null; this.src='assets/images/Image_21.png'" />
                </span>
            </div> -->
            <button class="background-primary-color positive-button-text-color bold green-btn border-primary-color"
                (click)="onSubmit(memberid)">{{label_submit}}</button>
        </div>
    </div>
</section>