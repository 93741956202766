<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close();">
  <span aria-hidden="true">&times;</span>
</button>
<div>

  <div class="row mb-3rem selectheader">
    <span class="">Select Concessionaire</span>

  </div>
  <br/>
  <ng-container *ngIf="concessionaireList && concessionaireList.length > 0">
    <ng-container *ngFor="let con of concessionaireList">
      <div class="row conlist">
        <div class="col-md-9 col-9 concessionaire-names">{{ con.Name }}</div>
        <div class="col-md-3 col-3">
          <input type="checkbox" class="switch" [(ngModel)]="con.IsSelected" (click)="onConcessionaireSelect($event,con.ConcessionaireID)">
        </div>
      </div>
    </ng-container>
  </ng-container>

  <br/>
  <div class="row mb-3rem" style="border: 0">
    <div class="col-md-12 col-12 text-left">
      <div class="savebtn text-center">
        <button (click)="onSaveClick()">Save</button>
      </div>
    </div>
  </div>

</div>
