import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatabaseHandler } from 'src/app/DatabaseHandler';
import { Router } from '@angular/router';
import { loggingData, LogService } from 'src/app/services/log.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { ShippingService } from 'src/app/services/shipping.service';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-email-receipt-thankyou-dialog',
  templateUrl: './email-receipt-thankyou-dialog.component.html',
  styleUrls: ['./email-receipt-thankyou-dialog.component.css'],
})
export class EmailReceiptThankyouDialogComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }

    if (this.timeOutSub) {
      window.clearTimeout(this.timeOutSub);
    }
  }

  languageSub!: Subscription;

  timeOutSub: any;


  finishText: string = this.language.getCustomMapValues('menu_setting_display__confirm_email_modal_finish_text');
  finishLocalizedText: string = ''


  headerText: string = this.language.getCustomMapValues('menu_setting_display__confirm_email_modal_header_text');;
  headerLocalizedText: string =this.language.getCustomMapValues('menu_setting_display__confirm_email_modal_header_text');;;

  mainText: string = this.language.getCustomMapValues('menu_setting_display__confirm_order_modal_secondary_text');;;
  mainLocalizedText: string = this.language.getCustomMapValues('menu_setting_display__confirm_order_modal_secondary_text');;;

  secondaryText: string = this.language.getCustomMapValues('menu_setting_display__confirm_email_modal_secondary_text');;;;
  secondaryLocalizedText: string = this.language.getCustomMapValues('menu_setting_display__confirm_email_modal_secondary_text');;;;

  ref: any;

  constructor(public modalService: NgbModal,
    private router: Router,
    private logger: LogService,
    private shipping: ShippingService,
    private language: LanguageService)
  { }

  loadText() {
    this.finishLocalizedText = this.language.getTextElement('finish')
    this.headerLocalizedText = this.language.getTextElement('email_receipt')
    this.mainLocalizedText = this.language.getTextElement('thank_you')
    this.secondaryLocalizedText= this.language.getTextElement('txt_we_will_be_emailing_you_your_receipt')
  }

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.getText();
  }

  ngAfterViewInit() {
    this.timeOutSub = setTimeout(() => {
      this.shipping.shippingDetails = undefined
      this.ref.close();
      if (GeneralSetting.getRedirectToMain() == 'True') {
        this.router.navigateByUrl('/touchToStart');
      }
    }, 5000);
  }

  public static open(modalService: NgbModal) {
    var ref = modalService.open(EmailReceiptThankyouDialogComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });

    GeneralSetting.setRedirectToMain("True");
   
    ref.componentInstance.ref = ref;
    return ref;
  }

  /**
   * get data from db ModuleGeneralEmailReceiptSettings
   * EmailImage
   * FinishText
   * HeaderText
   * MainText
   * SecondaryText
   */
  getText() {
    var sqlstring = `SELECT
                       FinishText,
                       HeaderText,
                       MainText,
                       SecondaryText
                     FROM
                       ModuleGeneralEmailReceiptSettings`;

    const callback = (tx: string, results: any) => {
      if (results.rows && results.rows.length > 0) {
        var data = results.rows[0];
        this.finishText = data.FinishText ? data.FinishText : this.finishLocalizedText;
        this.headerText = data.HeaderText ? data.HeaderText : this.headerLocalizedText;
        this.mainText = data.MainText ? data.MainText : this.mainLocalizedText;
        this.secondaryText = data.SecondaryText ? data.SecondaryText : this.secondaryLocalizedText;
      }
    };

    const errorcallback = (tx: string, error: any) => {
      console.log("Email Receipt Component , Error Getting ModuleGeneralEmailReceiptSettings ", error.message);
    };

    DatabaseHandler.executeSqlStatement(sqlstring, [], callback, errorcallback);
  }

  dismissModal() {
    this.ref.close();
    //back to touch to start
    GeneralSetting.setRedirectToMain("True");
    setTimeout(() => {
      this.shipping.shippingDetails = undefined
      if (GeneralSetting.getRedirectToMain() == 'True') {
        this.router.navigateByUrl('/touchToStart');
      }
    }, 300);
  }

}
