import {
  Component, ElementRef, OnDestroy, OnInit, ViewChild
} from '@angular/core'
import {
  FormBuilder, FormControl, FormGroup, Validators
} from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Subscription } from 'rxjs'
import { IsKioskService } from 'src/app/services/is-kiosk.service'
import { KeyboardService } from 'src/app/services/keyboard.service'
import { LanguageService } from 'src/app/services/language.service'
import { loggingData, LogService } from 'src/app/services/log.service'
import { environment } from 'src/environments/environment'
import { CommonFunctions } from '../../../services/common'
import { GeneralSetting } from '../../../services/generalSetting.service'

@Component({
  selector: 'app-custominfo-popup',
  templateUrl: './custominfo-popup.component.html',
  styleUrls: ['./custominfo-popup.component.css'],
})
export class CustominfoPopupComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  @ViewChild('nameforkeyboard')
  nameElement!: ElementRef;

  @ViewChild('mobileNumberforkeyboard')
  mobileNumberElement!: ElementRef;

  @ViewChild('emailforkeyboard')
  emailElement!: ElementRef;

  form!: FormGroup;

  isUKPhoneFormat: boolean = false;
  //flag showing on the screen
  customName: string | null = '';

  customPhone: string | null = '';

  customEmail: string | null = '';

  header: string | null = '';

  languageSub!: Subscription;

  mask: string = '000-000-0000';

  mobNumberPattern = '[1-9]d*|0d{11}(?!0+$)'; // '[1-9]\d*|0\d+'

  modalReference: any;

  submitText: string = '';

  nameRequiredText: string = '';

  emailRequiredText: string = '';

  invalidNameText: string = '';

  phoneNumberRequiredText: string = '';

  invalidPhoneText: string = '';

  invalidEmailText: string = '';

  isInvalid: boolean = false;

  showSubmit: boolean = false;

  // Custom Fields
  orderCustomerNameInfoHeader!: string;

  orderCustomerEmailInfoHint!: string;

  orderCustomerNameInfoHint!: string;

  orderCustomerPhoneInfoHint!: string;

  placeHolderEmailInfoHint!: string;

  placeHolderNameInfoHint!: string;

  placeHolderPhoneInfoHint!: string;

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  isKiosk = this.isKioskService.isKiosk();

  isAllowForMarketingCommunication: boolean = false;

  isOpen: boolean = false;

  constructor(
    public modalService: NgbModal,
    private formBuilder: FormBuilder,
    private logger: LogService,
    private isKioskService: IsKioskService,
    private KeyboardService: KeyboardService,
    private language: LanguageService
  ) {}

  ngOnInit(): void {
    this.isUKPhoneFormat = GeneralSetting.getCountry() == '230';

    if (this.isUKPhoneFormat) {
      this.mobNumberPattern = '[1-9]d*|0d{11}(?!0+$)';
      this.mask = '00000-000000';
    }
    this.setFormDetailes();
    this.setAllTexts();
    this.setAllDetailes();
    //this.showSubmit = false;
  }

  ngAfterViewInit() {
    this.setEmailValidations();
    this.setNameValidations();
    this.setPhoneValidations();
    this.KeyboardPopup();
  }
  // add click event on first input field @jay
  KeyboardPopup() {
    if (this.customName == 'True') {
      let nameEle: HTMLElement = this.nameElement.nativeElement as HTMLElement;
      nameEle.click();
    } else if (this.customPhone == 'True') {
      let mobileNumberEle: HTMLElement = this.mobileNumberElement
        .nativeElement as HTMLElement;
      mobileNumberEle.click();
    } else if (this.customEmail == 'True') {
      let emailEle: HTMLElement = this.emailElement
        .nativeElement as HTMLElement;
      emailEle.click();
    }
  }

  private setAllDetailes() {
    //await this.getinfo();

    this.showSubmit = true;
  }

  private setAllTexts() {
    if (GeneralSetting.getCustomerLoginEmail() != '') {
      this.email?.setValue(GeneralSetting.getCustomerLoginEmail());
      this.email?.updateValueAndValidity({ onlySelf: true });
    }

    this.name?.setValue(
      GeneralSetting.getCustomerLoginEmail() === 'Guest'
        ? GeneralSetting.getCustomerLoginEmail()
        : GeneralSetting.getCustomerName()
    );

    this.name?.updateValueAndValidity({ onlySelf: true });
    if (GeneralSetting.getCustomerPhone() != '') {
      this.mobileNumber?.setValue(GeneralSetting.getCustomerPhone());
      this.mobileNumber?.updateValueAndValidity({ onlySelf: true });
    }
    this.orderCustomerNameInfoHeader =
      GeneralSetting.getOrderCustomerNameInfoHeader() != ''
        ? GeneralSetting.getOrderCustomerNameInfoHeader()
        : this.language.getCustomMapValues(
            'menu_setting_display__customer_name_info_header'
          );
    this.orderCustomerNameInfoHint =
      GeneralSetting.getOrderCustomerNameInfoHint() != ''
        ? GeneralSetting.getOrderCustomerNameInfoHint()
        : this.language.getCustomMapValues(
            'menu_setting_display__customer_name_info_hint'
          );

    this.orderCustomerEmailInfoHint =
      GeneralSetting.getOrderCustomerEmailInfoHint() != ''
        ? GeneralSetting.getOrderCustomerEmailInfoHint()
        : this.language.getCustomMapValues(
            'menu_setting_display__customer_email_info_hint'
          );
    this.orderCustomerPhoneInfoHint =
      GeneralSetting.getOrderCustomerPhoneInfoHint() != ''
        ? GeneralSetting.getOrderCustomerPhoneInfoHint()
        : this.language.getCustomMapValues(
            'menu_setting_display__customer_phone_info_hint'
          );
    this.placeHolderEmailInfoHint =
      GeneralSetting.getOrderCustomerEmailInfoHint() != ''
        ? GeneralSetting.getOrderCustomerEmailInfoHint()
        : this.language.getCustomMapValues(
            'menu_setting_display__customer_email_info_hint'
          );
    this.placeHolderNameInfoHint =
      GeneralSetting.getOrderCustomerNameInfoHint() != ''
        ? GeneralSetting.getOrderCustomerNameInfoHint()
        : this.language.getCustomMapValues(
            'menu_setting_display__customer_name_info_hint'
          );
    this.placeHolderPhoneInfoHint =
      GeneralSetting.getOrderCustomerPhoneInfoHint() != ''
        ? GeneralSetting.getOrderCustomerPhoneInfoHint()
        : this.language.getCustomMapValues(
            'menu_setting_display__customer_phone_info_hint'
          );

    this.submitText = this.language.getTextElement('lbl_submit');
    this.nameRequiredText = this.language.getTextElement('name_is_required');
    this.invalidNameText = this.language.getTextElement('invalid_name');
    this.phoneNumberRequiredText = this.language.getTextElement(
      'phone_number_is_required'
    );
    this.invalidPhoneText = this.language.getTextElement(
      'invalid_phone_number'
    );
    this.invalidEmailText = this.language.getTextElement('invalid_email');
    this.emailRequiredText = this.language.getTextElement('email_is_required');
    // to check whether popup is open or not and check whether user allow to optin for marketing communication or not
    if (this.isOpen) {
      if (GeneralSetting.getAllowCustomerOptInForMarketing()) {
        if (
          GeneralSetting.getIsAllowForMarketingCommunication() != '' &&
          GeneralSetting.getIsAllowForMarketingCommunication() == 'True'
        ) {
          this.isAllowForMarketingCommunication = false;
        } else {
          GeneralSetting.setIsAllowForMarketingCommunication('True');
          this.isAllowForMarketingCommunication = true;
        }
      } else {
        this.isAllowForMarketingCommunication = false;
      }
    }
  }

  private setFormDetailes() {
    if (!this.form) {
      this.form = this.formBuilder.group({
        name: new FormControl(''),
        mobileNumber: new FormControl(''),
        email: new FormControl(''),
      });

      if (this.customName == 'True') {
        this.form.controls['name'].setValidators([
          Validators.pattern('^[a-zA-Z0-9 ]*$'),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        ]);
      }
    }
  }

  getHeader() {
    if (
      this.customEmail === 'True' &&
      this.customName === 'False' &&
      !this.customPhone
    ) {
      this.header = this.language.getTextElement(
        'we_will_email_you_when_ready'
      );
    }
  }

  // getter for each of these form variables
  get mobileNumber() {
    return this.form.get('mobileNumber');
  }

  get name() {
    return this.form.get('name');
  }

  get email() {
    return this.form.get('email');
  }

  public static open(
    modalService: NgbModal,
    name: boolean,
    phone: boolean,
    email: boolean
  ) {
    const modalReference = modalService.open(CustominfoPopupComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.customName = name ? 'True' : 'False';
    modalReference.componentInstance.customPhone = phone ? 'True' : 'False';
    modalReference.componentInstance.customEmail = email ? 'True' : 'False';
    modalReference.componentInstance.showSubmit = true;

    return modalReference;
  }

  open() {
    const modalReference = this.modalService.open(CustominfoPopupComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });
    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.isOpen = true;
    this.modalReference = modalReference;
  }

  canceled() {
    this.KeyboardService.KeyboardStatus = false;
    this.modalReference.close('canceled');
  }

  onSubmit() {
    if (this.form.status === 'VALID') {
      if (this.name && this.name.value.length > 28) {
        this.isInvalid = true;
        return;
      }
      this.KeyboardService.KeyboardStatus = false;

      this.isInvalid = false;

      // if (this.name?.value) {
      //   if (!CommonFunctions.checkName(this.name?.value)) {
      //     this.isInvalid = false;
      //     return;
      //   }
      // }

      if (this.mobileNumber?.value) {
        if (
          !CommonFunctions.checkPhone(
            this.mobileNumber?.value,
            this.isUKPhoneFormat
          )
        ) {
          this.isInvalid = false;
          return;
        }
      }

      if (this.email?.value) {
        if (!CommonFunctions.checkEmail(this.email?.value)) {
          this.isInvalid = false;
          return;
        }
      }
      GeneralSetting.setCustomerName(this.name?.value.trim());
      GeneralSetting.setCustomerPhone(this.mobileNumber?.value);
      GeneralSetting.setCustomerLoginEmail(this.email?.value);
      GeneralSetting.setCustomerEmail(this.email?.value);

      let customerinfo = [];
      if(this.name?.value.trim()) {
        customerinfo.push('Name :' + this.name?.value.trim())
      }

      if(this.mobileNumber?.value) {
        customerinfo.push('Mobile Number :' + this.mobileNumber?.value)
      }

      if(this.email?.value) {
        customerinfo.push('Email :' + this.email?.value)
      }

      var log = new loggingData(
        `Order details: Customer info`,
        `Order details: Customer info -  ${customerinfo.join(', ')}`,
        `custominfo-popup.component.ts`,
        `Order details: Customer info -  ${customerinfo.join(', ')}`,
        true
      );
      this.logger.sendLogToServer(log);

      this.modalReference.close();
    } else {
      this.isInvalid = true;
    }
  }

  //get data from database: OrderTypeMappingMasters
  //get the order type info

  // getinfo(): Promise<boolean> {
  //   return new Promise<boolean>((resolve, reject) => {
  //     if (this.customName != '') {
  //       resolve(true);
  //     }
  //     //get eating option from session storage location-preference -- dine in/take out
  //     var orderTypeId = GeneralSetting.getOrderTypeId();

  //     //query
  //     var sqltext = `
  //     SELECT
  //       KioskAskCustomerName,
  //       KioskAskCustomerPhone,
  //       KioskAskCustomerEmail

  //     FROM
  //       OrderTypeMappingMasters

  //     WHERE
  //       OrderTypeId = '${orderTypeId}'
  //     `;

  //     //callback handler
  //     const setInfo = (tx: string, results: any) => {

  //       var infoObj = results.rows[0];

  //       this.customName = infoObj && infoObj.KioskAskCustomerName;
  //       this.customPhone = infoObj && infoObj.KioskAskCustomerPhone;
  //       this.customEmail = infoObj && infoObj.KioskAskCustomerEmail;

  //       // if (this.customName === 'True' || this.customPhone === 'True' || this.customEmail === 'True') {
  //       //   this.setEmailValidations();
  //       //   this.setPhoneValidations();
  //       //   this.setNameValidations();
  //       // }

  //       resolve(true);
  //     };

  //     //error
  //     const errorCallback = (statement: any, error: any) => {
  //       console.log('Error Getting OrderTypeMappingMasters from sql');
  //       resolve(true);
  //     };

  //     DatabaseHandler.executeSqlStatement(sqltext, [], setInfo, errorCallback);
  //   });
  // }

  private async setEmailValidations() {
    if (this.customEmail === 'True') {
      this.form.controls['email'].setValidators([
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ]);
      if (GeneralSetting.getCustomerLoginEmail() != '') {
        this.form.controls['email'].setValue(
          GeneralSetting.getCustomerLoginEmail()
        );
      }
      this.form.controls['email'].updateValueAndValidity({ onlySelf: true });
    }
  }

  private async setPhoneValidations() {
    if (this.customPhone === 'True') {
      this.form.controls['mobileNumber'].setValidators([
        Validators.required,
        Validators.pattern(this.mobNumberPattern),
        Validators.minLength(10),
      ]);
      if (GeneralSetting.getCustomerLoginMobile() != '') {
        this.form.controls['mobileNumber'].setValue(
          GeneralSetting.getCustomerLoginMobile()
        );
      }
      this.form.controls['mobileNumber'].updateValueAndValidity({
        onlySelf: true,
      });
    }
  }

  private async setNameValidations() {
    if (this.customName === 'True') {
      // this.form.controls['name'].setValidators([Validators.required,
      // Validators.pattern(/^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$/),
      // ]);
      if (GeneralSetting.getCustomerLoginName() != '') {
        this.form.controls['name'].setValue(
          GeneralSetting.getCustomerLoginName()
        );
      }
      this.form.controls['name'].updateValueAndValidity({ onlySelf: true });
    }
  }
}
