import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-usconnect-account-found',
  templateUrl: './usconnect-account-found.component.html',
  styleUrls: ['./usconnect-account-found.component.css'],
})
export class UsconnectAccountFoundComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  languageSub!: Subscription;

  modalReference: any;

  status: number = 0;

  title: string = '';

  subTitle: string = '';

  accountInfo: any;
  availableBalance: string = '0';
  label_available_balance: string = '';
  @Input() subtotal: any;

  @Input() tax: number = 0;

  @Input() total!: number;

  checkoutText: string = '';

  accountFoundText: string = '';

  loadingText: string = '';

  cancelText: string = '';
  yourTotalText: string = '';
  proceedWithPaymentText: string = '';

  constructor(
    private modalService: NgbModal,
    private language: LanguageService
  ) { }


  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    })
  }

  loadText() {
    this.checkoutText = this.language.getTextElement('lbl_checkout')
    this.accountFoundText = this.language.getTextElement('account_found')
    this.loadingText = this.language.getTextElement('txt_loading')
    this.cancelText = this.language.getTextElement('lbl_button_cancel')
    this.yourTotalText = this.language.getTextElement('your_total')
    this.proceedWithPaymentText = this.language.getTextElement('proceed_payment')
    this.label_available_balance = this.language.getTextElement('lbl_available_balance')
  }

  public static open(modelService: NgbModal, total: number, title: string, subTitle: string, status: string) {
    var modalReference = modelService.open(
      UsconnectAccountFoundComponent,
      {
        backdrop: 'static',
        size: 'lg',
        centered: true,
      }
    );

    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.title = title;
    modalReference.componentInstance.subTitle = subTitle;
    modalReference.componentInstance.status = status;
    modalReference.componentInstance.total = total;

    return modalReference;
  }

  open(total: number) {
    var modalReference = this.modalService.open(
      UsconnectAccountFoundComponent,
      {
        backdrop: 'static',
        size: 'lg',
        centered: true,
      }
    );

    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.title = this.title;
    modalReference.componentInstance.subTitle = this.subTitle;
    modalReference.componentInstance.status = this.status;
    this.modalReference = modalReference;

    modalReference.componentInstance.total = total;
  }

  startLoading() {
    this.status = 1;
    this.title = this.language.getTextElement('account_lookup')
    this.subTitle = this.language.getTextElement('please_wait_while_fetching_your_account_detail');
    this.modalReference.componentInstance.title = this.title;
    this.modalReference.componentInstance.subTitle = this.subTitle;
    this.modalReference.componentInstance.status = this.status;
  }

  stopLoading() {
    this.status = 2;
    this.title = this.accountFoundText;
    this.subTitle = this.language.getTextElement('would_you_like_to_proceed_with_payment');
    this.modalReference.componentInstance.title = this.title;
    this.modalReference.componentInstance.subTitle = this.subTitle;
    this.modalReference.componentInstance.status = this.status;
  }

  setData(data: any) {
    this.modalReference.componentInstance.accountInfo = data;
    if (data.data.MonthlyLimit && Number(data.data.MonthlyLimit) > 0) {
      this.availableBalance = (Number(data.data.MonthlyLimit) - Number(data.data.MonthlyOrderTotal)).toFixed(2);
    }
    this.modalReference.componentInstance.availableBalance = this.availableBalance;
  }

  close(event: string) {
    this.modalReference.close(event);
  }

}
