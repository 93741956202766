<ng-container *ngIf="item != null">
  <div class="flexbox">
    <app-item-normal-mode-header class="header" [menuItem]="item" [Category]="category"
      (quantityChange)="headerQuantityChange($event)">
    </app-item-normal-mode-header>

    <div class="container" *ngIf="comboGroup.length <= 0">
      <ng-container *ngIf="variations.length > 0">
        <app-variation-container (variationOptionSelectedEmiter)="setSelectedVariations($event)" [ItemID]="ItemID"
          [inputVariations]="variations" (isValidEmitter)="setVariationsValid($event)">
        </app-variation-container>
      </ng-container>

      <ng-container *ngIf="modifiers.length > 0">
        <app-modifier-container [inputModifier]="modifiers" (modifierEmitter)="setSelectedModifiers($event)"
          (isValidEmitter)="setModifiersValid($event)" [ItemType]="1"
          [ItemName]="item.Name == undefined ? '' : item.Name"
          [ItemDescription]="(item.FullDescription && item.FullDescription != '') ? item.FullDescription : 
          (item.ShortDescription && item.ShortDescription != '') ? item.ShortDescription : ''">
        </app-modifier-container>
      </ng-container>
    </div>

    <div class="container" *ngIf="comboGroup.length > 0">
      <app-combo-group-container [inputComboGroup]="comboGroup" (comboGroupEmitter)="setSelectedComboGroup($event)"
        (isValidEmitter)="setComboGroupValid($event)" [ItemType]="1" [IsCategory]='item.IsCategory'>
      </app-combo-group-container>
    </div>

    <!-- <div class="container" *ngIf="subCatItems.length > 0">
      <app-combogroup>
      </app-combogroup>

    </div> -->


    <app-item-footer class="footer" [isReady]="isReady" [isComboItem]="isComboItem" [modifiers]="_selectedModifiers"
      [variations]="_selectedVariations" [comboGroup]="_selectedComboGroup" [itemID]="item.ItemID"
      [categoryID]="category.CategoryID" [isModifiersValid]="isModifiersValid" [isVariationsValid]="isVariationsValid"
      [isComboGroupValid]="isComboGroupValid" [inputItem]="item" [modifierContainer]="modifierContainer"
      [comboGroupContainer]="comboGroupContainer" [quantityWhenAdd]="quantity" [cartQuantity]="cartQuantity"
      (comboModelClose)="closeModel($event)">
    </app-item-footer>
  </div>
</ng-container>

<app-timeout style="display: none" [hidden]="true"></app-timeout>