import { Component, OnDestroy, OnInit } from '@angular/core';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LogService } from 'src/app/services/log.service';
import { Settings } from '../../../models/settings';
import { DatabaseHandler } from '../../../DatabaseHandler';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { PusherMaster } from 'src/app/models/PusherMaster'
import { LoaderService } from 'src/app/services/loader.service';
import { KioskUpgradeService } from 'src/app/services/kiosk-upgrade.service';

@Component({
  selector: 'app-settings-deviceinfo',
  templateUrl: './settings-deviceinfo.component.html',
  styleUrls: ['./settings-deviceinfo.component.css'],
})
export class SettingsDeviceinfoComponent implements OnInit, OnDestroy {
  IP: string | null = '';
  expireDate: string = '';
  branchName: string = '';
  user: string = '';
  macID: string | null = '';
  appID: string | null = '';
  wifi: string | null = '';
  version: string = environment.Version;
  phoneNumber: string = '';
  email: string = '';
  appVersion: string = environment.Version;
  address: string = '';
  generalSettingsText = '';

  settings: Settings = {
    IP: '',
    expireDate: '',
    branchName: '',
    phoneNumber: '',
    email: '',
    address: '',
  };
  languageSub!: Subscription
  branchText: string = ''
  addressText: string = ''
  deviceNameText: string = ''
  currentIPText: string = ''
  macIDText: string = ''
  appIDText: string = ''
  currentWiFiText: string = ''
  versionText: string = ''
  expireDateText: string = ''
  phoneNoText: string = ''
  emailText: string = ''
  appVersionText: string = ''
  appEnvironmentText: string = ''
  appEnvironement: string = ''
  samsungTizenText: string = ''
  defaultText: string = ''
  upgradeKioskAppText: string = ''
  io_configText: string = ''
  pusherCluster = ''
  pusherAppId = ''
  deviceName:string=GeneralSetting.getDeviceName()
  
  constructor(private logger: LogService, private language: LanguageService, private readonly loader: LoaderService, private readonly upgradeService: KioskUpgradeService) { }
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {
    this.branchText = this.language.getTextElement('txt_branch')
    this.addressText = this.language.getTextElement('address')
    this.deviceNameText = this.language.getTextElement('device_name')
    this.currentIPText = this.language.getTextElement('txt_current_ip')
    this.macIDText = this.language.getTextElement('txt_mac_id')
    this.appIDText = this.language.getTextElement('txt_app_id')
    this.currentWiFiText = this.language.getTextElement('txt_current_wifi')
    this.versionText = this.language.getTextElement('version')
    this.expireDateText = this.language.getTextElement('expire_date')
    this.phoneNoText = this.language.getTextElement('phone_no')
    this.emailText = this.language.getTextElement('txt_email_s')
    this.appVersionText = this.language.getTextElement('txt_app_version')
    this.samsungTizenText = this.language.getTextElement('samsung_tizen')
    this.defaultText = this.language.getTextElement('lbl_none')
    this.generalSettingsText = this.language.getTextElement('General_Settings')
    this.appEnvironmentText = this.language.getTextElement('environment')
    this.upgradeKioskAppText = this.language.getTextElement('lbl_upgrade_kiosk_app')
    this.io_configText = this.language.getTextElement('lbl_io_config')
    this.appEnvironement = environment.environment;

    const pusherData = localStorage.getItem("pusherData")
    if (pusherData) {
      let pusherMaster: PusherMaster[] = [] as PusherMaster[];
      pusherMaster = JSON.parse(pusherData)
      if(pusherMaster){
        this.pusherCluster = `Cluster:${pusherMaster[0].Cluster}`
        this.pusherAppId = `AppId:${pusherMaster[0].AppID}`
      }
    } 

  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText()
    })

    this.getData().then((element: any) => {
      this.settings = element;
    });

    this.appID = GeneralSetting.getSerialNo() !== "" && GeneralSetting.getWifi() !== "" ? GeneralSetting.getSerialNo() : this.defaultText;
    this.IP = GeneralSetting.getIpAddress() !== "" && GeneralSetting.getWifi() !== "" ? GeneralSetting.getIpAddress() : this.defaultText;
    this.macID = GeneralSetting.getMacAddress() !== "" && GeneralSetting.getWifi() !== "" ? GeneralSetting.getMacAddress() : this.defaultText;
    this.wifi = GeneralSetting.getWifi() !== "" && GeneralSetting.getWifi() !== "" ? GeneralSetting.getWifi() : this.defaultText;
  }

  getData(): Promise<any> {
    return new Promise((resolve, reject) => {
      var branchSQLstring = 'Select * FROM BranchMasters';

      const errorCallback = (tx: any, result: any): void => {
        console.log("Error getting BranchMaster Data");
        reject();
      };

      const BranchCallBack = (tx: any, result: any): void => {
        // this.settings.IP = result.rows[0].IPAddress;
        this.settings.expireDate = result.rows[0].ExpireDate;
        this.settings.branchName = result.rows[0].BranchName;
        this.settings.phoneNumber = result.rows[0].ContactNo;
        this.settings.email = result.rows[0].BranchEmailID;
        this.settings.address =
          result.rows[0].Address1 + ' ' + result.rows[0].Address2;

        resolve(this.settings);
      };
      DatabaseHandler.executeSqlStatement(
        branchSQLstring,
        [],
        BranchCallBack,
        errorCallback
      );
    });
  }

  tizenURL: string = 'https://builds.grubbrr.net/kiosk/v1/latest/kiosk.zip';
  upgradeKioskApp() {
    if(this.tizenURL) {
      this.upgradeService.kioskAppUpgrade(this.tizenURL);
    }
  }

}


