<div id="ask-for-receipt">
    <div class="header">
        {{askReceiptText}}
    </div>
    <div class='row'>
        <button matRipple class="bold background-positive-button-color positive-button-text-color "
            (click)="close('yes')">{{yesText|uppercase}}</button>
        <button matRipple class="bold background-negative-button-color negative-button-text-color "
            (click)="close('no')">{{noText|uppercase}}</button>
    </div>
</div>