<div id="tabletent-popup" role="dialog">
    <div class="tabletent-popup-modal">

      <div class="content">
        <h2><b> {{ headerText }}</b></h2>
        <h4 class="text-center">{{ bodyText }}</h4>
        <div class="input-group mb-3">
          <input KeyboardCustom customLength="3" id="itemTent" layout="numeric" [readonly]="isKiosk" mask="000" 
          required [(ngModel)]="tentNumber" placeholder="Table Tent Number" />

          <div class="input-group-append">
            <button (click)="confirmTent()" class="
                btn
                green-btn
                border-positive-button-color
                positive-button-text-color
                background-positive-button-color
              " type="button">
              <i class="fa fa-check"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>