import { Injectable } from '@angular/core';
import { ItemV2 as Item, ModifierV2 as Modifier } from 'src/app/models/item';
import { tax } from 'src/app/models/tax';
import { DatabaseHandler } from 'src/app/DatabaseHandler';
import __ from 'circular-json';
import { CodelessDiscount } from 'src/app/models/discounts';
import { OLOService } from 'src/app/services/olo.service';
import { PaymentService } from 'src/app/services/payment.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Guid } from 'src/app/models/Guid';
import { TaxPojo } from 'src/app/models/tax-pojo';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import {
  CategorySalesType,
  CommonCalculations,
  CommonFunctions,
} from 'src/app/services/common';
import { DatabaseService } from 'src/app/services/database.service';
import { ShippingService } from 'src/app/services/shipping.service';
import { CartItemsComponent } from 'src/app/components/cart-items/cart-items.component';
import { Integration, IntegrationService } from './integration.service';
import { ParBrinkService } from './par-brink.service';
import { UserService } from './user.service';
import { ConcessionaireDiscontDetail } from '../models/ConcessioanireDiscountDetail';
import { ConcessioniareOrderSplitBill } from '../models/ConcessioniareOrderSplitBill';
import { CartComponent } from '../components/cart/cart.component';
import { SMBDiscount } from './smb-discount.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  
  cartItems: Item[] = [];

  subtotal: number = 0.0;

  tax: number = 0.0;

  total: number = 0.0;

  shippingFee: number = 0.0;

  //parTotal variable used to avoid conflicts and minimize code logic for parbrinkIntegrations.
  parTotal: number = 0.0;

  tip: number = 0.0;

  cloverTip: number = 0.0;

  cloverCardTotal: number = 0.0;

  discount: number = 0.0;

  promo: CodelessDiscount = {} as CodelessDiscount;

  didDiscount: boolean = false;
  reward: number = 0; //used in levelUp
  itemKitchenMapping = new Map<string, string[]>();

  promotionPercent: number = 0.0;

  promotionAmount: number = 0.0;

  promotionIsTotal: boolean = false;

  taxDetails: any;

  taxes: tax[] = [];

  cartItemsComponent?: CartItemsComponent;

  public sub_subtotal = new BehaviorSubject<number>(0);

  public sub_total = new BehaviorSubject<number>(0);

  public sub_shipping = new BehaviorSubject<number>(0);

  public sub_reward = new BehaviorSubject<number>(0);

  public sub_discount = new BehaviorSubject<number>(0);

  public sub_tax = new BehaviorSubject<number>(0);

  public cartCurrentlyOpen = new Subject<boolean>();

  public subCartItems = new BehaviorSubject<Item[]>(this.cartItems);

  public removeItemSub = new BehaviorSubject<Item>({} as Item);

  public conDetailSub = new BehaviorSubject<ConcessionaireDiscontDetail[]>([]);

  public doScrollCart = new Subject<boolean>();

  public removeOrderLevelDiscount = new Subject<boolean>();

  totalTaxTypes = [] as any[];

  isItemSelectionOrScan: boolean = false;

  public closeOrderType = new Subject<boolean>();

  public ApplyedConcessionaireDiscountIds: string[] = [];

  public orderSplitBill: ConcessioniareOrderSplitBill[] = [];

  selectedItemPromo: CodelessDiscount = {} as CodelessDiscount;

  selectedSubtotalPromo: CodelessDiscount = {} as CodelessDiscount;

  public closeRetryConfirmationDialog = new Subject<boolean>();

  closeCartDelay: any;
  
  public selectedDiscount: SMBDiscount | undefined;

  constructor(
    private readonly paymentService: PaymentService,
    private readonly olo: OLOService,
    private readonly databaseService: DatabaseService,
    private readonly shipping: ShippingService,
    private readonly db: DatabaseService,
    private integration: IntegrationService,
    private parbrink: ParBrinkService,
    private user: UserService
  ) { }

  createCartItemV2(item: Item) {
    return new Promise<Item>((resolve, reject) => {
      if (item.openItem) {
        item.taxDetail = [];
        item.TaxPercentage = 0;
        item.TaxGroupId = 0;
        resolve(item);
      }

      let taxCallAsync = async () => {
        const taxList: TaxPojo[] = await this.getOrderTaxDetails(
          '',
          item.CategoryID,
          item.ItemID,
          GeneralSetting.getOrderTypeId()
        );
        let taxPercentage = 0.0;
        let TaxGroupId = '0';

        if (taxList.length > 0) {
          for (let i = 0; i < taxList.length; i++) {
            taxPercentage += Number(taxList[i].Percentage);
            TaxGroupId = taxList[i].TaxGroupID;
          }
        }

        item.taxDetail = taxList;
        item.TaxPercentage = taxPercentage;
        item.TaxGroupId = Number(TaxGroupId);

        if (!item.guid) {
          item.guid = Guid.newGuid();
        }

        resolve(item);
      };

      taxCallAsync();
    });
  }
  closeRetryConfirmation(retryClosed: boolean) {
    this.closeRetryConfirmationDialog.next(retryClosed);
  }

  getOrderTaxDetails(
    sectionID: string,
    categoryId: string,
    itemId: string,
    orderTypeId: string | null
  ) {
    return new Promise<TaxPojo[]>((resolve, reject) => {
      let branchId = GeneralSetting.getBranchId();
      let companyId = GeneralSetting.getCompanyId();
      let taxList: TaxPojo[];
      let taxQuery = `select
                    a.TaxGroupID,
                    a.TaxID,
                    b.TaxGroupName,
                    b.IsDefaultTaxGroup,
                    c.TaxName,
                    C.Percentage,
                    C.IsActive,
                    B.IsActive
      from TaxGroup_Mappings as a
      inner join TaxGroupMasters as b ON a.TaxGroupId = b.TaxGroupid
      inner join  TaxMasters as c ON a.taxid = c.taxid
      where C.IsActive='True' AND B.IsActive='True' AND
      a.TaxGroupId in  (SELECT CASE WHEN EXISTS(SELECT ITM.TaxGroupID from item_tax_mappings as ITM
        inner join TaxGroup_OrderType_Mappings as TOM on ITM.TaxGroupID = TOM.TaxGroupID
        inner join TaxGroupMasters as TGM on ITM.TaxGroupID = TGM.TaxGroupID where ITM.CompanyID = '${companyId}'
        and ITM.BranchID = '${branchId}' and ITM.ItemID IN ('${itemId}') and ITM.TaxGroupID !=''
        and ITM.TaxGroupID !='0' AND TOM.OrderTypeID = '${orderTypeId}')
        THEN (SELECT ITM.TaxGroupID from item_tax_mappings as ITM
          inner join TaxGroup_OrderType_Mappings as TOM on ITM.TaxGroupID = TOM.TaxGroupID
          inner join TaxGroupMasters as TGM on ITM.TaxGroupID = TGM.TaxGroupID where ITM.CompanyID = '${companyId}'
          and ITM.BranchID = '${branchId}' and ITM.ItemID IN ('${itemId}') AND TOM.OrderTypeID = '${orderTypeId}')
          ELSE CASE WHEN EXISTS(SELECT CTM.TaxGroupID from Category_Tax_Mappings as CTM
            inner join TaxGroup_OrderType_Mappings as TOM on CTM.TaxGroupID = TOM.TaxGroupID
            inner join TaxGroupMasters as TGM on CTM.TaxGroupID = TGM.TaxGroupID where CTM.CompanyID = '${companyId}'
            and CTM.BranchID = '${branchId}' and CTM.CategoryID IN ('${categoryId}') and CTM.TaxGroupID !='' and CTM.TaxGroupID !='0'
            AND TOM.OrderTypeID = '${orderTypeId}')  THEN (SELECT CTM.TaxGroupID from Category_Tax_Mappings as CTM
              inner join TaxGroup_OrderType_Mappings as TOM on CTM.TaxGroupID = TOM.TaxGroupID
              inner join TaxGroupMasters as TGM on CTM.TaxGroupID = TGM.TaxGroupID where CTM.CompanyID = '${companyId}'
              and CTM.BranchID = '${branchId}' and CTM.CategoryID IN ('${categoryId}') and TOM.OrderTypeID = '${orderTypeId}')
              ELSE CASE WHEN EXISTS(SELECT SM.TaxGroupID from SectionMasters as SM
                inner join TaxGroup_OrderType_Mappings as TOM on SM.TaxGroupID = TOM.TaxGroupID
                inner join TaxGroupMasters as TGM on SM.TaxGroupID = TGM.TaxGroupID where
                SM.CompanyID = '${companyId}' and SM.BranchID = '${branchId}' and SM.SectionID = '${sectionID}' and SM.TaxGroupID !=''
                and SM.TaxGroupID !='0' AND TOM.OrderTypeID = '${orderTypeId}')
                THEN (SELECT SM.TaxGroupID from SectionMasters as SM
                  inner join TaxGroup_OrderType_Mappings as TOM on SM.TaxGroupID = TOM.TaxGroupID
                  inner join TaxGroupMasters as TGM on SM.TaxGroupID = TGM.TaxGroupID where SM.CompanyID = '${companyId}'
                  and SM.BranchID = '${branchId}' and SM.SectionID = '${sectionID}' and  TOM.OrderTypeID = '${orderTypeId}')
                  ELSE CASE WHEN EXISTS(SELECT TM.TaxGroupID from TaxGroupMasters as TM
                    inner join TaxGroup_OrderType_Mappings as TOM on TM.TaxGroupID = TOM.TaxGroupID
                    where TM.CompanyID = '${companyId}' and TM.BranchID = '${branchId}' and TM.IsDefaultTaxGroup = 'True'
                    and TM.TaxGroupID !='' and TM.TaxGroupID !='0' AND TOM.OrderTypeID = '${orderTypeId}')
                    THEN (SELECT TM.TaxGroupID from TaxGroupMasters as TM
                      inner join TaxGroup_OrderType_Mappings as TOM on TM.TaxGroupID = TOM.TaxGroupID
                      where TM.CompanyID = '${companyId}' and TM.BranchID = '${branchId}' and TM.IsDefaultTaxGroup = 'True' AND TOM.OrderTypeID = '${orderTypeId}')
                      ELSE '0' END END END END );`;

      const callback = (tx: string, result: any) => {
        var data: TaxPojo[] = Array.from(result.rows);
        resolve(data);
      };

      const errorCallback = (tx: string, result: any) => {
        console.log(
          'Cart Service , Error Getting tax info from Sql',
          result.message
        );

        reject(result);
      };

      DatabaseHandler.executeSqlStatement(
        taxQuery,
        [],
        callback,
        errorCallback
      );
    });
  }

  async modCheck(item: any, cart: Modifier) {
    if (item.SelectedIngredients.length === cart.SelectedIngredients.length) {
      let flag = true;
      for (let i = 0; i < item.SelectedIngredients.length; i++) {
        if (flag) {
          let a = await CommonFunctions.filter(
            cart.SelectedIngredients,
            (el: any) => {
              return Promise.resolve(
                el.Name == item.SelectedIngredients[i].Name &&
                el.Quantity === item.SelectedIngredients[i].Quantity
              );
            }
          );
          if (a.length === 0) {
            flag = false;
            return flag;
          } else {
            if (a[0].hasOwnProperty('SelectedIngredients')) {
              await this.modCheck(item.SelectedIngredients[i], a[0]).then(
                (data: boolean) => {
                  if (data == false) {
                    flag = data;
                  }
                }
              );
            }
          }
        } else return flag; //else return Promise.resolve(false);
      }
      return flag;
    } else return false; //else return Promise.resolve(false);
  }

  checkAlcohol(
    edit: boolean,
    item: Item,
    isIncreaseQty: boolean = true,
    parenetQty: number = 0
  ) {
    return new Promise<boolean>((resolve, reject) => {
      const currentCountofAlkol: number = Number(
        GeneralSetting.getCurrentCountofAlkol()
      );
      const maxOrder = GeneralSetting.getMaxAlcoholicItemsPerOrder();
      if (
        item.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString()
      ) {
        if (maxOrder == null) {
          resolve(true);
        } else {
          let temp: number = edit
            ? Number(item.Quantity) -
            Number(
              this.cartItems[
                this.cartItems.findIndex((el) => el.guid === item.guid)
              ].Quantity
            )
            : Number(item.Quantity);
          if (temp + currentCountofAlkol <= Number(maxOrder)) {
            if (isIncreaseQty) {
              GeneralSetting.setCurrentCountofAlkol(temp + currentCountofAlkol); // Added as not updating current alcohol count of upsell
            }
            resolve(true);
          } else {
            resolve(false);
          }
        }
      } else {
        resolve(true);
      }
    });
  }

  checkQuantity(item: Item) {
    return new Promise<boolean>((resolve, reject) => {
      let quantity: number = 0;
      const Summer = (el: Item) => {
        if (
          (item.guid == undefined && el.ItemID === item.ItemID) ||
          (item.guid != undefined &&
            item.guid != el.guid &&
            item.ItemID == el.ItemID)
        ) {
          quantity += Number(el.Quantity);
        }
      };
      if (Number(item.OrderMaximumQuantity) === 0) {
        resolve(true);
      } else {
        CommonFunctions.forEach(this.cartItems, Summer).then(() => {
          if (
            quantity + Number(item.Quantity) <=
            Number(item.OrderMaximumQuantity)
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      }
    });
  }

  /**
   * @TODO checks for combo && LastFiveOrders
   * @description performs all calculations required to add new item to cart or update existing item
   * @param {Item} item the item to add to the cart, or the item in cart to update
   * @returns {Promise<string>}  promise containing string with details related to resolve/reject status
   */
  private async createItemAsync(item: Item) {
    const taxList: TaxPojo[] = await this.getOrderTaxDetails(
      '',
      item.CategoryID,
      item.ItemID,
      GeneralSetting.getOrderTypeId()
    );

    let taxPercentage = 0.0;
    let TaxGroupId = '0';

    if (taxList.length > 0) {
      for (let i = 0; i < taxList.length; i++) {
        taxPercentage += Number(taxList[i].Percentage);
        TaxGroupId = taxList[i].TaxGroupID;
      }
    }

    item.taxDetail = taxList;
    item.TaxPercentage = taxPercentage;
    item.TaxGroupId = Number(TaxGroupId);

    if (!item.guid) {
      item.guid = Guid.newGuid();
    }

    return item;
  }

  async addToCartAsync(
    item: Item,
    last5: boolean = false,
    shouldCheckAlcoholic: boolean = true
  ) {

    //-------------------------------------------------------
    //This for Item Level Upsell
    let quantity = item.Quantity ? Number(item.Quantity) : 1;
    item.DiscountAmount = item.DiscountAmountForCalulation * quantity;
    //--------------------------------------------------------

    const kitchens = await this.db.getItemKitchensFromSql(item);
    item.Kitchens = kitchens;
    var isCombo = item.IsCombo.toString();
    if (isCombo.toLowerCase() != '0' && isCombo.toLowerCase() == 'true') {
      for (let index = 0; index < item.ComboGroup.length; index++) {
        const comboGroup = item.ComboGroup[index];
        for (let index = 0; index < comboGroup.Items.length; index++) {
          if (comboGroup.Items[index].isSelected) {
            const item = comboGroup.Items[index];
            const kitchens = await this.db.getItemKitchensFromSql(item);
            item.Kitchens = kitchens;
          }
        }
      }
    }

    const isEditItem = this.cartItems.some((y: any) => y.guid == item.guid);
    if (shouldCheckAlcoholic) {
      const resCheckAlcohol = await this.checkAlcohol(isEditItem, item);

      if (!resCheckAlcohol) return { status: false, error: 'alcohol' };
    }

    const resCheckQuantity = await this.checkQuantity(item);
    if (resCheckQuantity && isEditItem && !last5) {
      let diff =
        Number(
          this.cartItems[
            this.cartItems.findIndex((x: any) => x.guid == item.guid)
          ].Quantity
        ) - Number(item.Quantity);

      let newOne = this.databaseService.categorySubject.getValue();

      for (let i = 0; i < newOne.length; i++) {
        if (newOne[i].CategoryID == item.CategoryID) {
          for (let j = 0; j < newOne[i].associatedItems!.length; j++) {
            if (newOne[i].associatedItems![j].ItemID == item.ItemID) {
              if (
                newOne[i].associatedItems![j].CurrentStock != null &&
                diff > 0
              ) {
                newOne[i].associatedItems![j].CurrentStock = (
                  Number(newOne[i].associatedItems![j].CurrentStock) + diff
                ).toString();
                if (
                  newOne[i].associatedItems![j].CurrentStock ==
                  newOne[i].associatedItems![j].LowThreshold
                ) {
                  newOne[i].associatedItems![j].SoldOut = true;
                } else if (
                  Number(newOne[i].associatedItems![j].CurrentStock) >
                  Number(newOne[i].associatedItems![j].LowThreshold)
                ) {
                  newOne[i].associatedItems![j].SoldOut = false;
                }
              }
              this.databaseService.categorySubject.next(newOne);
              break;
            }
          }
          break;
        }
      }

      this.cartItems[
        this.cartItems.findIndex((x: any) => x.guid == item.guid)
      ] = item;
      if (GeneralSetting.getParBrinkIntegrationID() != '') {
        this.parbrink.edited = true
      }
      return { status: true, error: '0' };
    }

    const resDuplicateItem = this.checkIsDuplicateItem(item);

    if (resDuplicateItem.isSame) {
      this.cartItemsComponent?.scrollID(resDuplicateItem.guid);
      const duplicateItem = JSON.parse(
        JSON.stringify(
          this.cartItems[
          this.cartItems.findIndex(
            (x: any) => x.guid == resDuplicateItem.guid
          )
          ]
        )
      );

      duplicateItem.Quantity = (
        Number(duplicateItem.Quantity) + 1
      ).toString();

      // As it inceases the alcohol limit twice if we add same item again - Done
      // const resCheckAlcohol = await this.checkAlcohol(true, duplicateItem);

      // if (!resCheckAlcohol) return { status: false, error: 'alcohol' };

      this.cartItems[
        this.cartItems.findIndex((x: any) => x.guid == resDuplicateItem.guid)
      ] = duplicateItem;
      if (GeneralSetting.getParBrinkIntegrationID() != '') {
        this.parbrink.edited = true
      }
      duplicateItem.totalPrice =
        this.getItemPrice(item, false) * Number(duplicateItem.Quantity);

      this.broadcastAll();

      return { status: true, error: '0' };
    } else {
      this.doScrollCart.next(true);
    }

    item.totalPrice = this.getItemPrice(item, false) * Number(item.Quantity);
    const itemToAdd: Item = await this.createItemAsync(item);

    this.cartItems.push(itemToAdd);
    if (GeneralSetting.getParBrinkIntegrationID() != '') {
      this.parbrink.edited = true
    }
    this.broadcastAll();

    this.subCartItems.next(this.cartItems);

    return { status: true, error: '0' };
  }

  findDuplicateItemInCartV2(item: Item) {
    let {
      Modifiers,
      Variations,
      ComboGroup,
      specialRequest,
      ItemID,
      IsLoyaltyDiscountItem,
      Price,
      ...rest
    } = item;
    let itemCopy = Object.assign(
      {},
      {
        Modifiers,
        Variations,
        ComboGroup,
        specialRequest,
        ItemID,
        IsLoyaltyDiscountItem,
        Price,
      }
    );
    for (let cartItem of this.getCartItems()) {
      let {
        Modifiers,
        Variations,
        ComboGroup,
        specialRequest,
        ItemID,
        IsLoyaltyDiscountItem,
        Price,
        ...rest
      } = cartItem;
      let cartCopy = Object.assign(
        {},
        {
          Modifiers,
          Variations,
          ComboGroup,
          specialRequest,
          ItemID,
          IsLoyaltyDiscountItem,
          Price,
        }
      );
      if (JSON.stringify(itemCopy) == JSON.stringify(cartCopy)) {
        return { isSame: true, guid: cartItem.guid };
      }
    }
    return { isSame: false, guid: '' };
  }

  getItemById(itemID: string): Item {
    let cartItem: Item = new Item();
    for (let index = 0; index < this.cartItems.length; index++) {
      if (
        !this.cartItems[index].IsUpSellItem &&
        this.cartItems[index].ItemID == itemID &&
        !this.cartItems[index].IsLoyaltyDiscountItem &&
        cartItem.specialRequest == this.cartItems[index].specialRequest
      ) {
        cartItem = this.cartItems[index];
        break;
      }
    }
    return cartItem;
  }

  getCartItems() {
    if (this.cartItems) return this.cartItems;
    else return [] as Item[];
  }

  clearCart() {
    this.cartItems = [] as Item[];

    this.databaseService.categorySubject.next([]);

    this.subCartItems.next(this.cartItems);

    this.isItemSelectionOrScan = false;

    return this.cartItems;
  }

  removeFromCart(item: Item) {
    const cartItems = [] as Item[];

    for (let i = 0; i < this.cartItems.length; i++) {
      const cartItem = this.cartItems[i];

      if (JSON.stringify(cartItem) != JSON.stringify(item)) {
        //TODO come back and test this change
        //if (cartItem.UpSellItemGuid != item.guid) {
        if (cartItem.guid != item.guid) {
          if (
            !(
              cartItem.UpSellItemGuid &&
              cartItem.UpSellItemGuid.toString() == item.guid
            )
          ) {
            cartItems.push(cartItem);
          }
        } else {
          if (cartItem.UpSellItemGuid === item.guid && cartItem.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString()) {
            // const curr = Number(GeneralSetting.getCurrentCountofAlkol());
            // GeneralSetting.setCurrentCountofAlkol(
            //   curr - Number(cartItem.Quantity)
            // );
            this.checkAndUpdateAlcoholCountInRemove(cartItem);
          }
        }
      } else {
        // if (cartItem.ItemCategorySalesTypeID === CategorySalesType.Alcohol.toString()) {
        //   const curr = Number(GeneralSetting.getCurrentCountofAlkol());
        //   GeneralSetting.setCurrentCountofAlkol(
        //     curr - Number(cartItem.Quantity)
        //   );
        // }
        this.checkAndUpdateAlcoholCountInRemove(cartItem);
      }
    }

    this.cartItems = cartItems;
    if (GeneralSetting.getParBrinkIntegrationID() != '') {
      this.parbrink.edited = true
    }
    this.calculateSubtotal();
    // this.subCartItems.next(this.cartItems);
  }

  checkAndUpdateAlcoholCountInRemove(item: Item) {
    var isCombo = item.IsCombo.toString();
    if (isCombo.toLowerCase() != '0' && isCombo.toLowerCase() == 'true') {
      let itemQuanity = 0;
      item.ComboGroup.forEach((comboGroup) => {
        comboGroup.Items.forEach((items) => {
          if (items.isSelected) {
            if (
              items.ItemCategorySalesTypeID ==
              CategorySalesType.Alcohol.toString()
            ) {
              itemQuanity += Number(items.Quantity ? items.Quantity : 1);
            }
          }
        });
      });

      if (itemQuanity > 0) {
        GeneralSetting.setCurrentCountofAlkol(
          (
            Number(GeneralSetting.getCurrentCountofAlkol()) -
            (item.Quantity
              ? Number(item.Quantity) * itemQuanity
              : 1 * itemQuanity)
          ).toString()
        );
      }
    } else {
      if (
        item.ItemCategorySalesTypeID == CategorySalesType.Alcohol.toString()
      ) {
        GeneralSetting.setCurrentCountofAlkol(
          (
            Number(Number(GeneralSetting.getCurrentCountofAlkol())) -
            Number(item.Quantity)
          ).toString()
        );
      }
    }
  }

  isEmpty(): boolean {
    return typeof this.cartItems !== 'undefined' && this.cartItems.length === 0;
  }

  private getShippingFee() {
    let numItems: number = 0;

    for (let i = 0; i < this.cartItems.length; i++) {
      if (
        !(
          (this.cartItems[i].CategoryID == '18402' &&
            this.cartItems[i].CategoryName == 'Charities') ||
          (this.cartItems[i].CategoryID == '18402' &&
            this.cartItems[i].IsUpSellItem)
        )
      ) {
        numItems = numItems + Number(this.cartItems[i].Quantity);
      }
    }

    return this.shipping.shippingDetails
      ? this.shipping.shippingDetails.ShippingFee * numItems
      : 0.0;
  }

  private async defaultCalulator() {
    /**
     * @Nilesh
     */
    // New calculation with reverse tax calculation
    const subtotal = this.calculateSubtotal();

    let discount = 0;

    if (GeneralSetting.getIsConcessionaire().toLowerCase() == 'true') {
      discount = this.getDiscountByConcessionaire(
        this.ApplyedConcessionaireDiscountIds,
        this.promotionPercent > 0,
        this.promotionPercent > 0 ? this.promotionPercent : this.promotionAmount
      );

      if (this.ApplyedConcessionaireDiscountIds.length == 0) {
        discount = this.getDiscount();
      }
    } else {
      //Setting Concesisonaire Discount to 0 if subtotal or item discount
      let conDetail = this.conDetailSub.value;

      if (conDetail.length > 0) {
        for (var i = 0; i < conDetail.length; i++) {
          conDetail[i].Discount = '0';
        }
      }

      discount = this.getDiscount();
    }

    this.subtotal = subtotal;

    this.discount = discount;

    const reward = this.reward;
    //reward and subtotal sent to calculate tax on post-reward value
    //levelUp rewards are applied pre tax

    const resTaxDetail = CommonCalculations.getItemTax(
      this.getCartItems(),
      reward,
      subtotal,
      this.discount,
      this.conDetailSub.value
    );
    this.totalTaxTypes = resTaxDetail.totalTaxTypes;
    let totalTax = resTaxDetail.totalTax;

    const shippingFee: number = this.getShippingFee();

    const total = subtotal + totalTax - discount + Number(this.tip) + shippingFee - reward;
    this.tax = totalTax;

    this.paymentService.TotalTax = totalTax;

    this.total = total;

    this.shippingFee = shippingFee;

    this.updateTaxWithConDiscount();

    return [subtotal, totalTax, discount, total, shippingFee, reward];
  }

  private async parbrinkCalculator() {
    const subtotal =
      (this.parbrink.SubTotal != 0 || this.parbrink.zeroOrder) &&
        !this.parbrink.edited
        ? this.parbrink.SubTotal
        : this.calculateSubtotal();
    const discount = this.getDiscount();
    this.subtotal = subtotal;
    this.discount = discount;
    const taxV2 = this.parbrink.Tax;

    let numItems: number = 0;
    for (let i = 0; i < this.cartItems.length; i++) {
      numItems = numItems + Number(this.cartItems[i].Quantity);
    }
    const shippingFee: number = this.shipping.shippingDetails
      ? this.shipping.shippingDetails.ShippingFee * numItems
      : 0.0;
    const total =
      this.parbrink.Total != 0
        ? this.parbrink.Total
        : subtotal + taxV2 - discount + Number(this.tip) + shippingFee;
    this.tax = taxV2;
    this.paymentService.TotalTax = taxV2;
    this.total = total;
    this.shippingFee = shippingFee;
    let reward: number = 0;
    if (this.user.isUserLoggedIn && this.user.levelUpCustomer.CustomerID) {
      if (this.reward) {
        reward = this.reward;
      }
    }
    return [subtotal, taxV2, discount, total, shippingFee, reward];
  }

  /**
   * Updates tax after applying discount
   * @returns 
   */
  private updateTaxWithConDiscount() {
    return new Promise<void>((resolve) => {
      if (GeneralSetting.getIsConcessionaire().toLowerCase() == 'true') {
        this.getDiscountByConcessionaire(
          this.ApplyedConcessionaireDiscountIds,
          this.promotionPercent > 0,
          this.promotionPercent > 0 ? this.promotionPercent : this.promotionAmount
        );
      }
    });
  }

  broadcastAll() {
    return new Promise<void>((resolve) => {
      this.calculateAll().then((allCalc) => {
        this.sub_subtotal.next(allCalc[0]);
        this.sub_tax.next(allCalc[1]);
        this.sub_discount.next(allCalc[2]);
        this.sub_total.next(allCalc[3]);
        this.sub_shipping.next(allCalc[4]);
        this.sub_reward.next(allCalc[5]);
        resolve();
      });
    });
  }
  async calculateAll() {
    if (this.integration.integration == Integration.Clover) {
      return this.defaultCalulator();
    } else if (this.integration.integration == Integration.Parbrink) {
      return this.parbrinkCalculator();
    } else if (this.integration.integration == Integration.OLO) {
      return this.defaultCalulator();
    } else if (this.integration.integration == Integration.Symphony) {
      return this.defaultCalulator();
    } else if (this.integration.integration == Integration.Micros3700) {
      return this.defaultCalulator();
    } else {
      return this.defaultCalulator();
    }
  }

  removeLoyaltyItem(): void {
    let a = this.getCartItems();
    for (let i = 0; i < a.length; i++) {
      if (a[i].IsLoyaltyDiscountItem) {
        this.removeFromCart(a[i]);
        break;
      }
    }
  }

  getUpchargeAmount(): number {
    return this.paymentService.UpChargeAmountCard;
  }

  calculateSubtotal(withTaxCheck: boolean = true, isIncludeDiscount: boolean = true): number {
    this.subtotal = 0.0;

    let isTaxIncludedInSubtotal =
      GeneralSetting.getTaxesIncludedInPrice() && withTaxCheck;
    if ((GeneralSetting.getParBrinkIntegrationID() != '') &&
      (this.parbrink.SubTotal != 0 || this.parbrink.zeroOrder) &&
      !this.parbrink.edited) {
      this.subtotal = this.parbrink.SubTotal
    } else {
      for (let i = 0; i < this.cartItems.length; i++) {
        const item = this.cartItems[i];
        let quantity = item.Quantity ? Number(item.Quantity) : 1;

        let itemTotal = CommonFunctions.getItemPrice(item) * quantity;

        let itemDiscount = item.DiscountAmount > 0 ? item.DiscountAmount : 0.0;

        if (isTaxIncludedInSubtotal) {
          itemTotal = itemTotal - itemDiscount;
          let itemTax = (item.TaxPercentage * itemTotal) / 100;
          let totalWithTax = itemTotal + itemTax;

          item.totalPrice = totalWithTax;
          itemTotal = totalWithTax;
        }

        if (isIncludeDiscount) {
          this.subtotal += item.DiscountAmount > itemTotal ? 0.0 : itemTotal - itemDiscount;
        } else {
          this.subtotal += itemTotal;
        }
      }
    }

    this.subCartItems.next(this.cartItems);
    this.paymentService.SubTotal = this.subtotal;
    this.sub_subtotal.next(this.subtotal);
    return this.subtotal;
  }

  getAndSetItemTotalWithTax(item: Item) {
    let quantity = item.Quantity ? Number(item.Quantity) : 1;
    let itemTotal = CommonFunctions.getItemPrice(item) * quantity;

    let itemDiscount = item.DiscountAmount > 0 ? item.DiscountAmount : 0.0;
    itemTotal = itemTotal - itemDiscount;

    let itemTax = (item.TaxPercentage * itemTotal) / 100;

    let totalWithTax = itemTotal + itemTax;

    item.totalPrice = totalWithTax;
  }

  getDiscount() {
    let discountAmount = 0;
    if (this.promotionIsTotal) {
      if (this.promotionPercent > 0) {
        discountAmount = (this.subtotal * this.promotionPercent) / 100;
      } else {
        discountAmount = this.promotionAmount;
      }
    }

    return discountAmount;
  }

  getItemPrice(item: Item, withDiscount: boolean): number {
    let itemCost = CommonFunctions.getItemPrice(item as Item); // basePrice

    if ((!this.promotionIsTotal || item.DiscountAmount > 0) && withDiscount) {
      if (this.promotionPercent != 0.0) {
        itemCost =
          itemCost * (1 - this.promotionPercent / Number(item.Quantity) / 100);
      } else {
        // itemCost =
        //   itemCost - (item.DiscountAmount / Number(item.Quantity) || 0);
      }
      this.didDiscount = true;
    }

    item.totalPrice = itemCost;

    return itemCost;
  }

  getItemTotalTax(reward: number = 0, subtotal: number = 0) {
    let totalTax = 0;
    let percentReward: number;
    if (reward > subtotal) {
      reward = subtotal;
    }
    if (reward != 0) {
      percentReward = reward / subtotal;
    } else {
      percentReward = 0;
    }
    this.totalTaxTypes = []; //Tax getting blank because of this initialization
    for (let i = 0; i < this.getCartItems().length; i++) {
      const item = this.getCartItems()[i];
      let totalItemTax = 0;

      const itemTotal =
        CommonFunctions.getItemPrice(item) *
        Number(item.Quantity) *
        (1 - percentReward);

      const itemLevelDiscountAmount =
        item.DiscountAmount > 0 ? item.DiscountAmount : 0.0;

      //  @Vasant: Added subtotal gretter then 0 condition due to GBRL-184 - (Ticket The tax should not shown minus infinity shows.)
      const discountOnItem =
        (this.subtotal > 0) ? ((itemTotal - itemLevelDiscountAmount) * this.discount) / this.subtotal : 0.00;
      const itemTotalWithDiscount =
        itemTotal - itemLevelDiscountAmount - discountOnItem;
      if (item.taxDetail.length > 0) {
        for (let j = 0; j < item.taxDetail.length; j++) {
          if (item.totalPrice) {
            const itemTax =
              (Number(item.taxDetail[j].Percentage) * itemTotalWithDiscount) /
              100;

            totalItemTax += CommonFunctions.roundDigit(itemTax, 2);
            totalTax += itemTax;

            //for showing tax detail in popup component on check out screen
            const popUpTax: any = this.totalTaxTypes.find(
              (x: any) => x.TaxId == item.taxDetail[j].TaxID
            );

            if (popUpTax) {
              popUpTax.TaxAmount += itemTax;
            } else {
              this.totalTaxTypes.push({
                Name: item.taxDetail[j].TaxName,
                TaxId: item.taxDetail[j].TaxID,
                TaxAmount: itemTax,
                TaxPer: Number(item.taxDetail[j].Percentage),
              });
            }
          }
        }
      }

      item.TaxAmount = CommonFunctions.roundDigit(totalItemTax, 2);
    }
    return totalTax;
  }

  setPromotion(
    percentage: number,
    isAmount: boolean,
    isTotal: boolean,
    concessionaireIds: string[] = []
  ) {
    this.promotionIsTotal = isTotal;
    if (isAmount) {
      this.promotionAmount = percentage;
      this.promotionPercent = 0.0;
    } else {
      this.promotionPercent = percentage;
      this.promotionAmount = 0.0;
    }
    if (percentage != 0) {
      GeneralSetting.setDidDiscount('true');
    }

    this.ApplyedConcessionaireDiscountIds = concessionaireIds;
  }

  calculateDiscountByAmount(
    amount: number,
    concessionaireIds: string[] = []
  ): number[] {
    const subTotal = this.subtotal;

    if (
      GeneralSetting.getIsConcessionaire().toLowerCase() == 'true' &&
      concessionaireIds.length > 0
    ) {
      let discountAmount = this.getDiscountByConcessionaire(
        concessionaireIds,
        false,
        amount
      );

      this.subtotal = subTotal - discountAmount;
    } else {
      this.subtotal = subTotal - amount;
    }

    return [this.subtotal, this.tax, this.subtotal + this.tax, amount];
  }

  calculateDiscountByPercent(
    percentage: number,
    concessionaireIds: string[] = []
  ): number[] {
    const subTotal = this.subtotal;

    let discountAmount = 0;

    if (
      GeneralSetting.getIsConcessionaire().toLowerCase() == 'true' &&
      concessionaireIds.length > 0
    ) {
      discountAmount = this.getDiscountByConcessionaire(
        concessionaireIds,
        true,
        percentage
      );

      this.subtotal = subTotal - discountAmount;
    } else {
      discountAmount = percentage == 0 ? 0 : (subTotal * percentage) / 100;

      this.subtotal = subTotal * (1 - percentage / 100);
    }

    return [this.subtotal, this.tax, this.subtotal + this.tax, discountAmount];
  }

  private getDiscountByConcessionaire(
    concessionaireIds: string[],
    isPercentage: boolean,
    value: number
  ) {
    let items = this.getCartItems();

    let conDetailList = [] as any[];

    var allConcessionaire: any[] = [];

    items.forEach(function (x) {
      allConcessionaire.push({
        ConcessionaireId: x.ConcessionaireId,
        ConcessionaireName: x.ConcessionaireName,
        ConcessionaireColorCode: x.ConcessionaireColorCode,
      });
    });

    let uniqueCon = [
      ...new Map(
        allConcessionaire.map((item) => [item['ConcessionaireId'], item])
      ).values(),
    ];

    let discountAmount = 0;

    let concessionareItems = items.filter((x) =>
      concessionaireIds.includes(x.ConcessionaireId)
    );
    let discountItemsSubTotal = 0;

    if (concessionareItems.length > 0) {
      discountItemsSubTotal =
        CommonFunctions.getSubTotalByItemsAfterDiscount(concessionareItems);

      if (isPercentage) {
        discountAmount = value == 0 ? 0 : (discountItemsSubTotal * value) / 100;
      } else {
        if (value <= discountItemsSubTotal) {
          discountAmount = value;
        } else {
          discountAmount = 0;
        }
      }
    }

    let orderDiscountAmount = 0;
    if (concessionaireIds.length == 0 && value > 0) {
      if (this.promotionIsTotal) {
        if (isPercentage) {
          orderDiscountAmount = (this.subtotal * value) / 100;
        } else {
          orderDiscountAmount = value;
        }
      }
    }

    for (var i = 0; i < uniqueCon.length; i++) {
      let conSubtotal = CommonFunctions.getSubTotalByItemsAfterDiscount(
        items.filter((x) => x.ConcessionaireId == uniqueCon[i].ConcessionaireId)
      );

      let conDisAmount = 0;

      if (concessionaireIds.includes(uniqueCon[i].ConcessionaireId)) {
        if (isPercentage) {
          conDisAmount = value == 0 ? 0 : (conSubtotal * value) / 100;
        } else {
          if (value <= discountItemsSubTotal) {
            let conDiscountPer = (conSubtotal / discountItemsSubTotal) * 100;

            conDisAmount = value == 0 ? 0 : (value * conDiscountPer) / 100;
          }
        }
      } else if (concessionaireIds.length == 0 && orderDiscountAmount > 0) {
        let conSubTotalPer = (conSubtotal / this.subtotal) * 100;
        conDisAmount = (orderDiscountAmount * conSubTotalPer) / 100;
      }

      let conTotalTax = CommonFunctions.getTotalItemTax(
        items.filter((x) => x.ConcessionaireId == uniqueCon[i].ConcessionaireId)
      );

      let Condiscount = {
        concessionaireId: uniqueCon[i].ConcessionaireId,
        Discount: CommonFunctions.roundDigit(conDisAmount, 2).toFixed(2),
        SubTotal: CommonFunctions.roundDigit(conSubtotal, 2).toFixed(2),
        TotalTax: CommonFunctions.roundDigit(conTotalTax, 2).toFixed(2)
      } as ConcessionaireDiscontDetail;

      conDetailList.push(Condiscount);
    }

    this.conDetailSub.next(conDetailList);
    this.ApplyedConcessionaireDiscountIds = concessionaireIds;

    return discountAmount;
  }

  calculateTotal(): number {
    if (!this.olo.isOLO) {
      if (this.promotionIsTotal) {
        if (this.promotionPercent != 0.0) {
          this.subtotal = this.subtotal * (1 - this.promotionPercent / 100);
        } else {
        }
      }

      this.tax = this.getItemTotalTax();

      this.total =
        Number(this.calculateSubtotal()) + this.tax + Number(this.tip);

      return this.total;
    } else {
      if (
        this.olo.validationRes != null &&
        this.olo.validationRes != undefined
      ) {
        if (this.olo.validationRes.data && this.olo.validationRes.data.total)
          this.total = this.olo.validationRes.data.total;
        else this.total = 0;
      }
    }
    return this.total;
  }

  setTip(tip: number) {
    this.tip = tip;
  }

  /**
   * @description replaces Item.Price property  with lowest applicable promotion pricing, assigning original Item.Price property to Item.OldPrice
   * @param {Item[]} arr array of Item objects to apply promotions to
   */
  applyPromos(arr: Item[]) {
    let tempPromo: any;

    const itemCheck = async (item: Item) => {
      if (
        tempPromo.ItemID == item.ItemID &&
        item.OldPrice == undefined &&
        Number(item.Price) > Number(tempPromo.NewPrice) &&
        Number(tempPromo.NewPrice) > 0
      ) {
        item.OldPrice = item.Price;
        item.Price = tempPromo.NewPrice;
        item.AppliedPromoName = tempPromo.Name;
      } else if (
        tempPromo.ItemID == item.ItemID &&
        Number(item.Price) > Number(tempPromo.NewPrice) &&
        Number(tempPromo.NewPrice) > 0
      ) {
        item.Price = tempPromo.NewPrice;
        item.AppliedPromoName = tempPromo.Name;
      }
    };
    const promoCheck = async (promo: any) => {
      tempPromo = promo;
      CommonFunctions.forEach(arr, itemCheck);

      return Promise.resolve();
    };
    return new Promise<Item[]>(async (resolve) => {
      await this.databaseService
        .getPromoPricingFromSql()
        .then((promos: any) => {
          CommonFunctions.forEach(promos, promoCheck);
          resolve(arr);
        });
    });
  }

  private checkIsDuplicateItem(item: Item) {
    let cartItems = this.getCartItems();

    let sameCartItems = cartItems.filter((x) => x.ItemID == item.ItemID);

    if (sameCartItems.length > 0) {
      for (let i = 0; i < sameCartItems.length; i++) {
        let mainItem = sameCartItems[i];

        let res = this.checkAllItemCondition(item, mainItem);

        if (res) {
          return { isSame: true, guid: mainItem.guid };
        }
      }
    }

    return { isSame: false, guid: '' };
  }

  private checkAllItemCondition(item: Item, item2: Item) {
    if (
      item.IsLoyaltyDiscountItem == true ||
      (item.EnablePricebyWeight && item.EnablePricebyWeight == 'True') ||
      item.IsUpSellItem
    ) {
      return false;
    }

    if (
      item.ConcessionaireId &&
      item2.ConcessionaireId &&
      item.ConcessionaireId != item2.ConcessionaireId
    ) {
      return false;
    }

    if (item2.UpSellItemID || item2.IsLoyaltyDiscountItem) {
      return false;
    }

    if (item2.specialRequest != item.specialRequest) {
      return false;
    }

    if (
      (!item.Modifiers || (item.Modifiers && item.Modifiers.length == 0)) &&
      (!item.Variations || (item.Variations && item.Variations.length == 0)) &&
      (!item.ComboGroup || (item.ComboGroup && item.ComboGroup.length == 0))
    ) {
      return true;
    }

    if (item.IsCombo) {
      //let res = this.isComboEquivalent(item, item2);

      //return res;

      return false;
    } else {
      if (item.Variations && item.Variations.length > 0) {
        if (
          JSON.stringify(item.VariationOption) !=
          JSON.stringify(item2.VariationOption)
        ) {
          return false;
        }
      }

      if (item.Modifiers && item.Modifiers.length > 0) {
        if (item2.Modifiers && item2.Modifiers.length > 0) {
          let res = this.isNormalItemEquivalent(item, item2);

          return res;
        }

        return false;
      }

      return true;
    }
  }

  private getModifierJson(Modifiers: any[]) {
    let ingArray = [] as any[];

    Modifiers.forEach((item: any) => {
      if (item.IsSelected) {
        const modifierData = {
          ItemID: item.ItemID,
          CategoryID: item.ModifierID,
          IngredientID: item.IngredientID,
          ModifierID: item.ModifierID,
          Quantity: item.Quantity,
          IsSelected: item.IsSelected,
          ModifierDetailID: [] as any[],
          IsUpSellItem: item.IsUpSellItem,
          Ingredients: '',
        };

        if (item.Ingredients && item.Ingredients.length > 0) {
          modifierData.Ingredients = JSON.stringify(
            this.getModifierJson(item.Ingredients)
          );

          item.Ingredients.forEach((ing: any) => {
            modifierData.ModifierDetailID.push(ing.ModifierDetailID);
          });
        }

        ingArray.push(modifierData);
      }
    });

    return ingArray;
  }

  private isNormalItemEquivalent(a: any, b: any) {
    var sourceArray = [] as any[];
    var destinationArray = [] as any[];

    a.Modifiers.forEach((item: any) => {
      const modifierData = {
        ItemID: item.ItemID,
        CategoryID: item.ModifierID,
        IngredientID: item.IngredientID,
        ModifierID: item.ModifierID,
        Quantity: item.Quantity,
        IsSelected: item.IsSelected,
        ModifierDetailID: [] as any[],
        IsUpSellItem: item.IsUpSellItem,
        Ingredients: '',
      };

      if (item.Ingredients && item.Ingredients.length > 0) {
        modifierData.Ingredients = JSON.stringify(
          this.getModifierJson(item.Ingredients)
        );

        item.Ingredients.forEach((ing: any) => {
          modifierData.ModifierDetailID.push(ing.ModifierDetailID);
        });
      }

      sourceArray.push(modifierData);
    });

    b.Modifiers.forEach((item: any) => {
      const modifierData = {
        ItemID: item.ItemID,
        CategoryID: item.ModifierID,
        IngredientID: item.IngredientID,
        ModifierID: item.ModifierID,
        Quantity: item.Quantity,
        IsSelected: item.IsSelected,
        ModifierDetailID: [] as any[],
        IsUpSellItem: item.IsUpSellItem,
        Ingredients: '',
      };

      if (item.Ingredients && item.Ingredients.length > 0) {
        modifierData.Ingredients = JSON.stringify(
          this.getModifierJson(item.Ingredients)
        );

        item.Ingredients.forEach((ing: any) => {
          modifierData.ModifierDetailID.push(ing.ModifierDetailID);
        });
      }

      destinationArray.push(modifierData);
    });

    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(sourceArray);
    const bProps = Object.getOwnPropertyNames(destinationArray);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i] as string;

      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    if (sourceArray.length != destinationArray.length) {
      return false;
    }

    for (let i = 0; i < sourceArray.length; i++) {
      let sObj = sourceArray[i];
      let dObj = destinationArray[i];

      if (JSON.stringify(sObj) != JSON.stringify(dObj)) {
        return false;
      }
    }

    let match = true;
    if (sourceArray.length === 0 && destinationArray.length === 0) {
      match = true;
    } else {
      if (sourceArray.length === destinationArray.length) {
        for (let x = 0; x < sourceArray.length; x++) {
          if (destinationArray[x].IsSelected !== sourceArray[x].IsSelected) {
            match = false;
          } else if (
            JSON.stringify(destinationArray[x].ModifierDetailID) !==
            JSON.stringify(sourceArray[x].ModifierDetailID)
          ) {
            match = false;
          } else if (
            JSON.stringify(destinationArray[x].IsUpSellItem) !==
            JSON.stringify(sourceArray[x].IsUpSellItem)
          ) {
            match = false;
          } else {
            if (destinationArray[x].Quantity !== sourceArray[x].Quantity) {
              match = false;
            }
          }
        }
      } else {
        match = false;
        return false;
      }
    }

    if (!match) {
      return false;
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
  }

  //do not remove thease code working on that
  private isComboEquivalent(a: any, b: any) {
    var sourceArray = [] as any;
    var destinationArray = [] as any;

    a.ComboGroup.forEach((cg: any) => {
      cg.Items.forEach((item: any) => {
        const itemData = {
          ItemId: item.ItemID,
          quantity: item.Quantity,
          CategoryID: item.CategoryID,
          ComboID: item.ComboGroupID,
          ModifierArr: item.Modifiers,
          IsSelected: item.isSelected,
        };

        sourceArray.push(itemData);
      });
    });

    b.ComboGroup.forEach((cg: any) => {
      cg.Items.forEach((item: any) => {
        const itemData = {
          ItemId: item.ItemID,
          quantity: item.Quantity,
          CategoryID: item.CategoryID,
          ComboID: item.ComboGroupID,
          ModifierArr: item.Modifiers,
          IsSelected: item.isSelected,
        };

        destinationArray.push(itemData);
      });
    });

    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(sourceArray);
    const bProps = Object.getOwnPropertyNames(destinationArray);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    destinationArray.sort();
    sourceArray.sort();

    for (let k = 0; k < aProps.length; k++) {
      const propName = aProps[k];

      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    let match = true;
    for (let i = 0; i < sourceArray.length; i++) {
      const dest = destinationArray[i];

      if (sourceArray[i].ItemId === dest.ItemId) {
        if (sourceArray[i].quantity === dest.quantity) {
          if (
            sourceArray[i].ModifierArr.length === 0 &&
            dest.ModifierArr.length === 0
          ) {
            match = true;
          } else {
            if (sourceArray[i].ModifierArr.length === dest.ModifierArr.length) {
              for (let x = 0; x < sourceArray[i].ModifierArr.length; x++) {
                const sourceModifier = sourceArray[i].ModifierArr[x];

                if (
                  JSON.stringify(dest.ModifierArr[x].Ingredients) !==
                  JSON.stringify(sourceModifier.Ingredients)
                ) {
                  match = false;
                } else {
                  if (
                    dest.ModifierArr[x].quantity !== sourceModifier.quantity
                  ) {
                    match = false;
                  }
                }
              }
            } else {
              match = false;
              return false;
            }
          }
        } else {
          match = false;
          return false;
        }
      } else {
        match = false;
        return false;
      }
    }

    if (!match) {
      return false;
    }

    return true;
  }

  getAllCartItemCategoryIds() {
    return Array.from(new Set(this.getCartItems().map((x) => x.CategoryID)));
  }

  getAllCartItemIds() {
    return Array.from(new Set(this.getCartItems().map((x) => x.ItemID)));
  }

  removeItemDiscounts() {
    for (let item of this.getCartItems()) {
      item.DiscountAmount = 0;
      item.DiscountID = 0;
      item.DiscountTypeID = 0;
      item.DiscountName = '';
      item.IsDiscount = false;
    }
  }

  /**
  * Method to open cart while adding item into the cart
  */
  async openCartOnAddItem(cart: CartComponent) {
    if (!cart?.isCartOpen) {
      cart.closeCart();
    }

    // if (!cart.isCartOpen) {
    //   cart.closeCart();
    // }else{
    //   clearTimeout(this.closeCartDelay);
    // }

    // await new Promise((resolve) =>{
    //   this.closeCartDelay = setTimeout(resolve, 5000);
    // });

    // cart.closeCart();
  }
  /**
* @author Om Kanada
* @param item // object of itemid and item86 status
* @description This method is used to update item86 status when item added to cart and at that time item will sold out.
*/
  updateItem86(item: any) {
    const index = this.cartItems.findIndex(x => x.ItemID === item.ItemId);
    if (index > -1) {
      this.cartItems[index].IsItem86 = item.Status;
    }
  }

  updateItemPrice(itemId: string, price: number) {
    const index = this.cartItems.findIndex(x => x.ItemID === itemId);
    if (index > -1) {
      this.cartItems[index].Price = price.toString();
    }
  }

}
