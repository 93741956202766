import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AskConfigureYourDeviceComponent } from './ask-configure-your-device.component';

@Injectable({
  providedIn: 'root'
})
export class AskConfigureDevicePopupServiceService {

  constructor(private modalService:NgbModal) 
  { }

  private AskConfigureYourDeviceRef:AskConfigureYourDeviceComponent | null = null;

  public open() {
    this.close();

    var modalRef= AskConfigureYourDeviceComponent.open(this.modalService);

    this.AskConfigureYourDeviceRef = modalRef.componentInstance;
    return modalRef;
  }

  public close(){
    if(this.AskConfigureYourDeviceRef && this.AskConfigureYourDeviceRef != null){
      this.AskConfigureYourDeviceRef.cancelModal();
    }
  }
}
