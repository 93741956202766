import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-usconnect-account-not-found',
  templateUrl: './usconnect-account-not-found.component.html',
  styleUrls: ['./usconnect-account-not-found.component.css']
})
export class UsconnectAccountNotFoundComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  languageSub!: Subscription

  modalReference: any;

  isIDCardPayment: boolean = false;

  checkoutText: string = ''

  tryAgainText: string = ''
  accountNotFoundText: string = ''
  cannotFindAccountText: string = ''
  useAnotherMethodText: string = ''

  constructor(private language: LanguageService) { }

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
  }

  loadText() {
    this.checkoutText = this.language.getTextElement('lbl_checkout');
    this.tryAgainText = this.language.getTextElement('try_again');
    this.accountNotFoundText = this.language.getTextElement('account_not_found');
    this.cannotFindAccountText = this.language.getTextElement('cannot_find_your_account');
    this.useAnotherMethodText = this.language.getTextElement('use_another_method');
  }

  public static open(modelService: NgbModal, isIDCardPayment: boolean) {
    var modalReference = modelService.open(UsconnectAccountNotFoundComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.isIDCardPayment = isIDCardPayment;
    return modalReference;
  }

  close(event: string) {
    this.modalReference.close(event);
  }

}
