<div id="upsell-popup">
  <div class="upsell-modal">
    <h5 class="modal-title text-center"></h5>
    <h1 class="text-center">{{ upsellHeaderText }}</h1>
    <img src="{{ (item.ImageUrl && item.ImageUrl != '') ? baseImageUrl + item.ImageUrl : placeholderImage !='' ? baseImageUrl+placeholderImage : 'assets/images/Image_21.png' }}" onerror="this.onerror = null;this.src = 'assets/images/Image_21.png';"
      alt="" [ngStyle]="{'border-radius': bordersTypeItem ? imageRadius : '0%'}"/>
    <h2 class="text-center">{{ item.Name }}</h2>
    <h2 *ngIf="((item.Price | numberConvert) > 0) && !isRemovePricingAndPayments" class="text-center black-text" [ngStyle]="{ color: priceColor }">{{ item.Price | CurrencyPipe }}</h2>
    <div class="col-12 col-md-12 popup-footer text-center">
      <button class="green-btn text-uppercase w-100-p bold" [style.backgroundColor]="positiveColor"
        [style.color]="positiveColorText" (click)="checkItem86()" matRipple>
        {{addToOrderText|uppercase}}
      </button>
      <div class="clearfix"></div>
      <button matRipple class="green-btn w-100-p bold" (click)="dismiss()" [style.backgroundColor]="negativeColor"
        [style.color]="negativeColorText">
        {{goToCheckoutText|uppercase}}
      </button>
      <div id="snackbar">{{requirementsNotMetText}}</div>
    </div>
  </div>
</div>

<div style="display: none">
  <app-combo-item-popup></app-combo-item-popup>
  <app-alcoholic-over21-dialog></app-alcoholic-over21-dialog>
  <app-alcoholic-item-exceed-max style="display: none"></app-alcoholic-item-exceed-max>
  <app-upsell-combo-message style="display: none"></app-upsell-combo-message>
  <app-out-of-stock [hidden]='true'></app-out-of-stock>
  <app-sold-out [hidden]="true"></app-sold-out>
</div>