<div *ngIf="none" role="tabpanel" class="tab-pane" id="reports">
    <h1 class="text-center mb-3rem">{{reportsText}}</h1>

    <div class="reports-container">
        <div class=" row mb-3rem">
            <div class="col-md-6 col-6"> <button class="col-md-12 col-12" (click)="zReport()">{{zReportsText}}</button></div>
            <div class="col-md-6 col-6"> <button class="col-md-12 col-12" (click)="grossSalesReport()">{{grossSalesText}}</button></div>
        </div>
        <br/>
        <div class=" row mb-3rem">
            <div class="col-md-6 col-6">   <button class="col-md-12 col-12" (click)="itemwiseReport()">{{itemWiseSalesText}}</button></div>
            <div class="col-md-6 col-6">    <button class="col-md-12 col-12" (click)="orderHistoryReport()">{{orderHistoryText}}</button></div>
        </div>                                
    </div>
</div>

<div class="z-report"  *ngIf="zreport">
 <app-settings-z-report></app-settings-z-report>
</div>

<div *ngIf="itemwise">
   <app-settings-itemwise-sales-report></app-settings-itemwise-sales-report>
</div>

<div *ngIf="grossSales">
<app-settings-gross-sales></app-settings-gross-sales>
</div>

<div *ngIf="orderhistory">
   <app-settings-order-history></app-settings-order-history>
</div>
