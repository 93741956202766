<div class="">
  <div id="snackbar">{{reachMaxQuantityText}}</div>
  <div class="cartBar">
    <div class="cartLeft pr-5">
      <app-item-tags-key
        [itemTagArray]="itemTags"
        class="keys"
      ></app-item-tags-key>
      <button
        id="specialRequestButton"
        *ngIf="disableSpecialRequest === 'False'"
        type="button"
        matRipple
        class="specialRequest secondary-color col-md-12 col-xs-12 mtb-1rem text-center"
        data-toggle="upSellModelRef"
        data-target="#specialrequest"
        (click)="openSpecialRequest()"
      >
        {{ specialRequestText }}
      </button>

      <div
        matRipple
        class="cancel"
        (click)="cancel()"
        [ngStyle]="{
          'background-color': negativeButton,
          border: negativeButton,
          color: negativeButtonText,
          height: disableSpecialRequest === 'True' ? '15rem' : '',
          marginTop: disableSpecialRequest === 'True' ? '5rem' : ''
        }"
      >
        <div class="centeredText">{{cancelText}}</div>
      </div>
    </div>
    <div class="cartCenter px-5" *ngIf="!isRemovePricingAndPayments">
      <div
        *ngIf="
          !(
            inputItem.EnablePricebyWeight &&
            inputItem.EnablePricebyWeight == 'True'
          );
          else weightLayout
        "
      >
        <div class="total row">
          <div class="col-md-6 col-6" [ngStyle]="{ color: secondaryColor }">
            {{totalText}}
          </div>
          <div
            class="col-md-6 col-6 right-text"
            [ngStyle]="{ color: priceColor }"
          >
            <div>
              {{ (totalCost * quantityWhenAdd).toFixed(2) | CurrencyPipe }}
            </div>
          </div>
        </div>
        <div class="pricePer row">
          <div class="col-md-6 col-6" [ngStyle]="{ color: secondaryColor }">
            {{pricePerText}}
          </div>
          <strong
            class="col-md-6 col-6 right-text"
            [ngStyle]="{ color: priceColor }"
          >
            {{ pricePerItem | number: "1.2-2" | CurrencyPipe }}
          </strong>
        </div>
        <div class="quantity row">
          <div class="col-md-6 col-6" [ngStyle]="{ color: secondaryColor }">
            {{quantityText}}
          </div>
          <div class="col-md-6 col-6 text-right">
            <div class="num-block skin-4">
              <span class="num-in">
                <div
                  *ngIf="
                    inputItem?.IsBuilderMode == 'True' && !isItemIndividual;
                    else normal
                  "
                >
                  <span
                    class="all-span flex just-contents-flexend align-center"
                  >
                    <span
                      class="input-number-decrement JS_ItemDecrement rmargin4"
                      data-itemId="1"
                      (click)="decreaseAmount(inputItem)"
                      *ngIf="inputItem?.AllowQuantitySelection != 'False'"
                    >
                      <img
                        src="../../../assets/images/minus.svg"
                        onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
                      />
                    </span>
                    <p id="QtyBox" class="font-size6 bmargin0 right-text">
                      {{ quantityWhenAdd }}
                    </p>
                    <span
                      class="input-number-increment JS_ItemIncrement lmargin4"
                      data-itemId="1"
                      (click)="increaseAmount(inputItem)"
                      *ngIf="inputItem?.AllowQuantitySelection != 'False'"
                    >
                      <img
                        src="../../../assets/images/plus.svg"
                        onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
                      />
                    </span>
                  </span>
                </div>
                <ng-template #normal>
                  <span class="all-span">
                    <p id="QtyBox" class="font-size6 bmargin0 right-text">
                      {{ quantityWhenAdd }}
                    </p>
                  </span>
                </ng-template>
              </span>
            </div>
          </div>
        </div>
      </div>

      <ng-template #weightLayout>
        <div class="total row">
          <div class="col-md-6 col-6" [ngStyle]="{ color: secondaryColor }">
            {{totalText}}
          </div>
          <div
            class="col-md-6 col-6 right-text"
            [ngStyle]="{ color: priceColor }"
          >
            <div>
              {{ (totalCost * inputItem.Weight)|number :"1.2-2" | CurrencyPipe }}
            </div>
          </div>
        </div>
        <div class="pricePer row">
          <div class="col-md-6 col-6" [ngStyle]="{ color: negativeButtonText }">
            {{pricePerText}}
          </div>
          <strong
            class="col-md-6 col-6 right-text"
            [ngStyle]="{ color: priceColor }"
          >
            {{ totalCost|number :"1.2-2" | CurrencyPipe }}
          </strong>
        </div>
        <div class="pricePer row">
          <div class="col-md-6 col-6" [ngStyle]="{ color: negativeButtonText }">
            {{itemWeightText}}
          </div>
          <strong
            class="col-md-6 col-6 right-text"
            [ngStyle]="{ color: priceColor }"
          >
            <span [ngStyle]="{ color: priceColor }"
              >{{ inputItem.Weight }}
              <span [ngSwitch]="inputItem.WeightUnitID">
                <span *ngSwitchCase="0">{{ozText}}</span>
                <span *ngSwitchCase="1">{{poundText}}</span>
                <span *ngSwitchCase="2">{{gramText}}</span>
                <span *ngSwitchCase="3">{{kilogramText}}</span>
                <span *ngSwitchDefault></span>
              </span>
            </span>
          </strong>
        </div>
      </ng-template>
    </div>
    <div class="cartRight">
      <div
        appDebounceClick
        matRipple
        *ngIf="isReady; else other_text"
        [ngClass]="isReady ? 'animated infinite pulse' : ''"
        class="addToCart"
        (click)="checkAddToCart()"
        [ngStyle]="{
          'background-color': positiveButton,
          border: positiveButton,
          color: positiveButtonText
        }"
        [debounceTime]="1000"
      >
        <div
          class="centeredText background-positive-button-color border-positive-button-color positive-button-text-color"
        >
          {{addToCartText}}
        </div>
      </div>
      <ng-template class="cart-not-ready" #other_text>
        <div
          matRipple
          class="addToCart"
          (click)="modifierAnimation()"
          [ngStyle]="{
            'background-color': 'gray',
            borderColor: 'gray',
            color: 'white'
          }"
        >
          <div class="centeredText"> {{addToCartText}}</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

