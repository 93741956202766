import { Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { CurrencyService as CurrencyService } from '../services/currency.service';

@Pipe({
  name: 'CurrencyPipe',
  pure: false
})
export class CurrencyPipe implements PipeTransform {

  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  prefix!: boolean;

  constructor(private CurrencyService: CurrencyService) {
    this.prefix = GeneralSetting.getCurrencyPrefix() == "True";
    this.allSubsCription.push(
      this.CurrencyService.currencyEmitter.subscribe((value) => {
        this.prefix = value;
      })
    );
  }

  transform(num: any): unknown {
    if (num && num != null) {
      num = num.toString()
      if (num[0] == '-') {
        return this.prefix && (num[0] + this.CurrencyService.CurrencySymbol + num.slice(1)) || num + this.CurrencyService.CurrencySymbol;
      }
    }
    return this.prefix && (this.CurrencyService.CurrencySymbol + num) || num + this.CurrencyService.CurrencySymbol;
  }

}
