<div class="container">
    <span (click)="close()" class="close" aria-disabled="true">&times;</span>
    <div class='init-container '*ngIf="initial">
      <p class="name primary-color">{{welcomeText}}</p>
      <p class="subname">{{startEarningText}}</p>
      <button
        class="button sign-out background-positive-button-color positive-button-text-color"
        (click)="close()"
      >
      {{continueText}}
      </button>
    </div>
    <div *ngIf="!initial" class="container">
      <p class="name primary-color">{{hiGuestText}}</p>
      <p class="subtext">{{thankYouLoyaltyText}}</p>
      <p class="subtext">{{wantToSignOutText}}</p>
      <div class="button-bar">
      <button
        class="button sign-out background-positive-button-color positive-button-text-color"
        (click)="close()"
      >
      {{cancelText}}
      </button>
      <button
        class="button sign-out background-negative-button-color negative-button-text-color"
        (click)="signOut()"
      >
        {{signOutText}}
      </button>
    </div>
    </div>
  </div>
