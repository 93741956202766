import { Component, OnDestroy, OnInit } from '@angular/core';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-item-selection',
  templateUrl: './item-selection.component.html',
  styleUrls: ['./item-selection.component.css'],
})
export class ItemSelectionComponent implements OnInit, OnDestroy {
  languageSub!: Subscription;
  modalReference: any;

  positiveColor: string = '';
  positiveColorText: string = '';

  orText: string = '';
  continueText: string = '';
  itemSelectionText: string = '';
  scanOrSelectText: string = '';
  addToCartText: string = '';
  scanItemsText: string = '';
  selectItemsText: string = '';
  selectFromMenuText: string = '';
  constructor(
    private modalService: NgbModal,
    private readonly router: Router,
    private language: LanguageService
  ) {}
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {
    this.orText = this.language.getTextElement('txt_or');
    this.continueText = this.language.getTextElement('lbl_continue');
    this.itemSelectionText = this.language.getTextElement('item_selection');
    this.scanOrSelectText = this.language.getTextElement('scan_or_select_an_item')
    this.addToCartText = this.language.getTextElement('to_add_it_to_your_cart')
    this.scanItemsText = this.language.getTextElement('scan_items')
    this.selectItemsText = this.language.getTextElement('select_items')
    this.selectFromMenuText = this.language.getTextElement('items_without_a_barcode_should_be_selected_from_the_menu')
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.positiveColor = GeneralSetting.getPositiveColor();
    this.positiveColorText = GeneralSetting.getPositiveColorText();
  }

  public static open(modalService: NgbModal) {
    var modalReference = modalService.open(ItemSelectionComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }

  open() {
    var modalReference = this.modalService.open(ItemSelectionComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;
    this.modalReference = modalReference;
  }
  close() {
    this.modalReference.close('continue');
  }
}
