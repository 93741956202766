import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterContentInit,
  OnDestroy,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from 'src/app/services/cart.service';
import { Tip } from '../../../models/tip';
import { TipService } from 'src/app/services/tip.service';
import { PaymentService } from '../../../services/payment.service';
import { GeneralSetting } from '../../../services/generalSetting.service';
import { CommonFunctions } from '../../../services/common';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { loggingData, LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-tip-dialog',
  templateUrl: './tip-dialog.component.html',
  styleUrls: ['./tip-dialog.component.css'],
})
export class TipDialogComponent implements OnInit, AfterContentInit, OnDestroy {
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  languageSub!: Subscription;

  @Input() total: number = 0.0;

  @Output() totalChange = new EventEmitter<number>();

  bkgdColor: string | null = '';

  priceColor: string | null = '';

  tipHeader: any = GeneralSetting.getTipHeaderText();

  defaultTipHeader: string = '';

  okText: string = '';

  checkoutText: string = '';

  noTipText: string = '';

  totalText: string = '';

  tipAmountText: string = '';

  addTipText: string = '';

  tipDescription: string = GeneralSetting.getTipDescription();

  defaultTipDescription: string = '';

  tipPrice: number = 0;

  tipPercentage: number = 0;

  tipsPercent!: Tip[];

  tipsFixed!: Tip[];

  shippingFee: any;

  selectedTip: any;

  noTip: string = 'false';

  currentPercent: any;

  modalReference: any;

  closeResult: any;

  matSliderPercent: any;

  calculatedTotal: number = 0;

  constructor(
    private modalService: NgbModal,
    private readonly cartService: CartService,
    private readonly tipService: TipService,
    private readonly paymentService: PaymentService,
    private language: LanguageService,
    private logger: LogService,
  ) { }

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.cartService.setTip(0);
    this.paymentService.TotalTip = 0;
    this.bkgdColor = GeneralSetting.getPrimaryColor();
    this.priceColor = GeneralSetting.getPriceColor();
    this.tipsFixed = this.tipService.tipsFixed;
    this.tipsPercent = this.tipService.tipsPercent;
  }

  ngAfterContentInit() {
    if (
      this.tipService.selectedTip == null ||
      this.tipService.selectedTip == undefined
    ) {
      this.noTip = 'true';
    } else {
      this.selectedTip = this.tipService.selectedTip;
    }
    if (this.selectedTip && !isNaN(this.selectedTip) && this.noTip == 'false') {
      this.currentPercent = Number(this.selectedTip);
      this.matSliderPercent = Number(this.selectedTip);
    }

    this.calculatedTotal = this.calculateTotal();
  }

  private loadText() {
    this.defaultTipDescription = this.language.getCustomMapValues('menu_setting_display__tip_modal_main_text');
    if (this.defaultTipDescription) {
      this.tipDescription = this.defaultTipDescription;
    }
    this.defaultTipHeader = this.language.getCustomMapValues('menu_setting_display__tip_modal_header_text');
    if (this.defaultTipHeader) {
      this.tipHeader = this.defaultTipHeader;
    }

    this.okText = this.language.getTextElement('lbl_ok');
    this.checkoutText = this.language.getTextElement('lbl_checkout');
    this.noTipText = this.language.getTextElement('lbl_no_tip');
    this.totalText = this.language.getTextElement('text_total');
    this.tipAmountText = this.language.getTextElement('txt_tip_amount');
    this.addTipText = this.language.getTextElement('lbl_add_tip');
  }

  labelFn(value: number): string {
    return `${Math.floor(value)}%`;
  }

  public static open(
    modalService: NgbModal,
    originalTotal: number,
    shippingFee: number
  ) {
    if (modalService.hasOpenModals()) {
      modalService.dismissAll();
    }

    let modalReference = modalService.open(TipDialogComponent, {
      backdrop: 'static',
      size: 'xl',
      centered: true,
    });

    modalReference.componentInstance.cartService.setTip(0);

    modalReference.componentInstance.shippingFee = shippingFee;

    modalReference.componentInstance.total = CommonFunctions.roundDigit(
      Number(originalTotal.toString()),
      2
    ).toFixed(2);

    modalReference.componentInstance.calculatedTotal = modalReference.componentInstance.total;

    modalReference.componentInstance.modalReference = modalReference;

    return modalReference;
  }

  open(originalTotal: number, shippingFee: number) {
    this.cartService.setTip(0);

    this.total = Number(originalTotal.toString());
    this.calculatedTotal = this.total;
    if (!this.modalService.hasOpenModals()) {
      this.modalReference = this.modalService.open(TipDialogComponent, {
        backdrop: 'static',
        size: 'lg',
        centered: true,
      });
      this.modalReference.componentInstance.shippingFee = shippingFee;
      this.modalReference.componentInstance.total = CommonFunctions.roundDigit(
        this.total,
        2
      ).toFixed(2);
      this.modalReference.componentInstance.calculatedTotal =
        this.modalReference.componentInstance.total;
      this.modalReference.componentInstance.modalReference =
        this.modalReference;
    }
  }

  canceled() {
    this.modalReference.close('canceled');
  }

  isOpen(): boolean {
    return this.modalService.hasOpenModals();
  }

  calculatePercent(str: string): number {
    return parseFloat(str);
  }

  calculatePrice(str: any): any {
    if (str == null || (isNaN(str) && Object.entries(str).length <= 0)) {
      this.currentPercent = 0;
      this.matSliderPercent = this.currentPercent;
      return (0.0).toFixed(2);
    }
    if (typeof str == 'string') {
      return CommonFunctions.roundDigit(
        (parseFloat(str) / 100) * this.total,
        2
      ).toFixed(2);
    } else {
      if (str.IsPercentage == 'True') {
        this.currentPercent = Math.floor(
          parseFloat(str.PercentageOrAmount)
        ).toFixed(2);
        this.matSliderPercent = this.currentPercent;
        return (
          (parseFloat(str.PercentageOrAmount) / 100) *
          this.total
        ).toFixed(2);
      } else {
        this.currentPercent = Math.floor(
          (parseFloat(str.PercentageOrAmount) / this.total) * 100
        );
        this.matSliderPercent = 0;
        return parseFloat(str.PercentageOrAmount).toFixed(2);
      }
    }
  }

  calculateTotal() {
    return (
      Number(this.total) +
      Number(this.calculatePrice(this.selectedTip)) +
      this.shippingFee
    ).toFixed(2);
  }

  onClick(event: any) {
    var id = event.currentTarget.querySelector('span').id;

    if (id == 0 || id == '') {
      this.selectedTip = null;
      this.tipService.isNoTipSelected = 'true';
      this.noTip = 'true';
      this.cartService.setTip(0);
      this.paymentService.TotalTip = 0;
      const total: number = this.cartService.calculateTotal();
      this.totalChange.emit(total);
      GeneralSetting.setIsTipSelected('true');
      this.tipService.selectedTip = this.selectedTip;
      this.calculatedTotal = this.calculateTotal();

      this.modalReference.close(`${total}`);
    } else {
      if (this.tipsPercent.length > 0) {
        this.tipsPercent.forEach((element) => {
          if (element.TipID == id) {
            this.selectedTip = element;
            this.noTip = 'false';
            this.tipService.isNoTipSelected = 'false';
            this.matSliderPercent = element.PercentageOrAmount;
          }
        });
      }

      if (this.tipsFixed.length > 0) {
        this.tipsFixed.forEach((element) => {
          if (element.TipID == id) {
            this.selectedTip = element;
            this.noTip = 'false';
            this.tipService.isNoTipSelected = 'false';
          }
        });
      }

      this.tipPrice = this.calculatePrice(this.selectedTip);
      this.tipPercentage = this.calculatePercent(
        this.selectedTip.PercentageOrAmount
      );
      this.calculatedTotal = this.calculateTotal();
    }
  }

  setTipVal(str: any) {
    str = str.toString();
    this.currentPercent = str;
    this.selectedTip = str;
    this.calculatedTotal = this.calculateTotal();
  }

  async addTip(tip: number) {
    this.cartService.setTip(tip);

    this.paymentService.TotalTip = tip;

    const total = await this.cartService.calculateAll();

    this.totalChange.emit(total[3]);

    GeneralSetting.setIsTipSelected('true');

    this.tipService.selectedTip = this.selectedTip;

    var log = new loggingData(
      `Order details: Tips Added - ${tip}`,
      `Order details: Tips Added - ${tip}`,
      `tip-dialog.component.ts`,
      `Order details: Tips Added - ${tip}`,
      true
    );
    this.logger.sendLogToServer(log);

    this.modalReference.close(`${total[3]}`);
  }
}
