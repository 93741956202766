<div
  [ngStyle]="{
    position: 'relative'
  }"
  [style.border]="item.isSelected ? 'solid 3px ' + primaryColor : ''"
  style="animation: fadeInSide {{ itemIndex / 9 }}s ease-in-out 0s forwards;"
  class="object item-ingredient-animation"
  [ngStyle]="{ width: isBuilderMode ? '26rem' : '' }"
  [ngClass]="{ doDisabled: !item.isSelected && !(parent.count < parent.max) }"
  (click)="itemClicked()"
>
  <div class="close" *ngIf="item.isSelected">
    <i
      class="fa fa-close"
      style="font-size: 32px"
      (click)="onCloseClick($event)"
    ></i>
  </div>
  <div class="name-div">
    <div class="name">{{ item.Name }}</div>
  </div>
  <span class="row itemTagContainer" style="height: 2.25rem">
    <div *ngFor="let image of item.IconImages" class="col itemTagDiv">
      <img
        *ngIf="image != null && image != ''"
        [ngStyle]="{ width: '2.25rem', height: '2.25rem'}"
        src="{{ (image && image !='') ? baseImageUrl + image : placeholderImage !='' ? baseImageUrl+placeholderImage : ''}}"
        onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
        class="col-md-3"
      />
    </div>
  </span>

  <div class="image-div" style="height: 47%">
    <img
      class=""
      src="{{ image != '' ? image : 'assets/images/Image_21.png' }}"
      onerror="this.onerror=null; this.src='assets/images/Image_21.png';"
      alt=""
      [ngClass]="{ soldOut: isSoldOut }"
      [ngStyle]="{'border-radius': bordersTypeItem ? imageRadius : '0%'}"
    />
  </div>

  <div class="sold-out" *ngIf="isSoldOut">
    <em>{{soldOutText}}</em>
  </div>

  <div
    *ngIf="showCalories"
    class="calorie-div price w-100 font-size3 medium"
    [ngClass]="{ soldOut: isSoldOut }"

  >
    <em>{{ item.Calorie ? item.MaxCalories !=='' ? item.Calorie + ' - ' + item.MaxCalories + calorieText : item.Calorie + calorieText : "" }}</em>
  </div>
  <div class="number-counter">
    <input
      class="input-number-2 JS_ItemQuantity"
      disabled="disabled"
      data-itemId="1"
      id="1"
      type="text"
      value="{{ quantity }}"
      min="0"
      max=""
      [(ngModel)]="quantity"
      *ngIf="parent.max > 1 && item.isSelected"
    />
    <div class="number-content">
      <span
        class="input-number-decrement JS_ItemDecrement"
        data-itemId="1"
        *ngIf="parent.max > 1 && item.isSelected"
        (click)="onQuantityDecrease($event)"
      >
        <img
          src="../../../../../assets/images/minus.svg"
          onerror="this.onerror=null; this.src='assets/images/Image_21.png';"
        />
      </span>

      <div
        [ngStyle]="{ color: priceColor }"
        [ngClass]="{ soldOut: isSoldOut }"
        [style.marginBottom]="item.isSelected ? '0rem' : ''"
        *ngIf="!isRemovePricingAndPayments"
      >
        {{ itemPrice != "" ? "+ " + itemPrice : "" }}
      </div>

      <span
        class="input-number-increment JS_ItemIncrement"
        data-itemId="1"
        *ngIf="parent.max > 1 && item.isSelected"
        (click)="onQuantityIncrease($event)"
      >
        <img
          src="../../../../../assets/images/plus.svg"
          onerror="this.onerror=null; this.src='assets/images/Image_21.png';"
        />
      </span>
    </div>
  </div>
  <!--<div class="price w-100 green" [ngClass]="{ soldOut: isSoldOut }"
       [style.marginBottom]="item.isSelected ? '3rem' : '' ">
    {{ (itemPrice > 0 ? "+ " + (itemPrice | CurrencyPipe) : '') }}
  </div>-->
  <button
    *ngIf="item.isSelected && hasModifier"
    class="custom-footer custom font-size4 medium"
    [ngClass]="{ soldOut: isSoldOut }"
  >
    {{customizeText|uppercase}}
  </button>
</div>
