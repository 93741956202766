import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';
import { TermsConditionsUrlComponent } from '../terms-conditions-url/terms-conditions-url.component';

@Component({
  selector: 'app-terms-condition-qr-url-both',
  templateUrl: './terms-condition-qr-url-both.component.html',
  styleUrls: ['./terms-condition-qr-url-both.component.css']
})
export class TermsConditionQrUrlBothComponent implements OnInit {

  ref: any;


  title = 'angular10qrcodegeneration';

  // We can have Canvas/Img/Url as elementType
  elementType = NgxQrcodeElementTypes.IMG;

  // We can have High/Low/Medium/Quartile
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  // Need to specify the valid website address
  value = '';
  urlLink: string = '';
  @ViewChild(TermsConditionsUrlComponent)
  termsConditionsUrlComponent!: TermsConditionsUrlComponent;
  showTermsAsUrl: boolean = false;
  textColor: string | null = '';
  accentColor: string | null = '';
  termsAndConditionsText:string='';
  scanBarcodeText: string = '';
  orText: string = '';
  backBtnText: string = '';
  clickHereText: string = '';

  constructor(private language: LanguageService) { }
  //Intialize the component
  ngOnInit(): void {
    this.textColor = GeneralSetting.getPositiveColorText();
    this.accentColor = GeneralSetting.getAccentColor();
    this.termsAndConditionsText = this.language.getTextElement('lbl_terms_and_conditions');
    this.scanBarcodeText = this.language.getTextElement('lbl_scan_barcode');
    this.orText = this.language.getTextElement('txt_or');
    this.backBtnText = this.language.getTextElement('back');
    this.clickHereText = this.language.getTextElement('lbl_click_here');
  }
  /**
   * @author Om kanada
   * @param url // pass url 
   * @description This method is used to open the modal
   * @returns void
   */
 public static open(url: string,modalService:NgbModal) {
    var ref = modalService.open(TermsConditionQrUrlBothComponent, { backdrop: 'static', centered: true, size: 'lg' });
    ref.componentInstance.ref = ref;
    ref.componentInstance.value = url;
    return ref;
  }
  /**
   * @author Om kanada
   * @param event // pass event to get the url
   * @description This method is used emit close modal event
   */
  onClose(event: string) {
    if (event === 'done') {
      this.dismiss();
    } else {
      this.showTermsAsUrl = false;
    }
  }
  /**
 * @author Om kanada
 * @description This method is used to dismiss the modal
 * @returns void
 */
  dismiss() {
    this.ref.close();
  }

}
