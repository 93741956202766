<div class="return-customer">
  <div class="header text-center">
    <h1>{{myAccountText}}</h1>
  </div>
  <div class="caption text-center">
    <h3>{{signInTopText}}</h3>
    <h4>{{signInBottomText}}</h4>
  </div>
  <Form [formGroup]="infoform" (ngSubmit)="onContinue()" novalidate>
    <div class="info-input align-items-center">
      <div class="col-sm-6 col-sm-offset-3 phone-box">
        <label class="phone-label">{{phoneNumberText}}</label>
        <input
          #phoneforreturningcus         
          KeyboardCustom
          [readonly]="isKiosk"
          id="phone"
          maxlength="phoneLength"
          mask={{mask}}
          layout="numeric"
          [ngStyle]="{
            borderColor: submitted && f.phoneNumber.errors ? 'red' : ''
          }"
          class="form-control"
          placeholder="Phone Number"
          formControlName="phoneNumber"
          [(ngModel)]="customerPhoneNo"
          (ngModelChange)="onchange()"
        />
        
        <div *ngIf="submitted && f.phoneNumber.errors">
          <div *ngIf="f.phoneNumber.errors.required" class="error">
            <p class="errors">{{enterPhoneToBeginText}}</p>
          </div>
          <div class="errors" *ngIf="f.phoneNumber.errors.pattern">
            <p>{{invalidPhoneText}}</p>
          </div>
        </div>
      </div>

      <div *ngIf="showName" class="col-sm-6 col-sm-offset-3 name-box">
        <label class="email-label">{{nameText}}</label>
        <input
          KeyboardCustom
          [readonly]="isKiosk"
          maxlength="350"
          id="name"
          class="form-control"
          placeholder="Name"
          formControlName="cusotmername"
          [(ngModel)]="customerName"
        />
      <div *ngIf="submitted && f.cusotmername.errors">
        <div *ngIf="f.cusotmername.errors.required" class="error">
          <p class="errors">{{addNameToCompleteText}}</p>
        </div>
        <div *ngIf="!(f.cusotmername.errors.required)" class="error">
          <p class="errors">{{addValidNameToCompleteText}}</p>
        </div>
      </div>
    </div>
      <div *ngIf="false" class="col-sm-6 col-sm-offset-3 email-box">
        <label class="email-label">{{emailText}}</label>
        <input
          KeyboardCustom
          [readonly]="isKiosk"
          maxlength="350"
          id="email"
          [ngStyle]="{ borderColor: submitted && f.email.errors ? 'red' : '' }"
          class="form-control"
          placeholder="Email"
          formControlName="email"
          [(ngModel)]="customerEmail"
          (ngModelChange)="onchange()"
        />
        <div>
          <!-- *ngIf="submitted && f.email.errors" -->
          <!-- <div *ngIf="f.email.errors.required" class="error">
                        <p>{{emailRequiredText}}</p>
                    </div> -->
          <div>
            <!-- *ngIf="f.email.errors.email" -->
            <p>{{invalidEmailText}}</p>
          </div>
        </div>
      </div>
    </div>
  <app-optin-marketing *ngIf="isAllowForMarketingCommunication" (onTermsAndConditionsChange)="checkedTermsAndConstions = $event"></app-optin-marketing>

    <div class="submitbtn">
      <button
        class="text-center green-btn"
        [style.background-color]="positiveColor"
        [style.border]="positiveColor"
        [style.color]="positiveColorText"
      >
        {{continueText}}
      </button>
      <br />
    </div>
  </Form>
  <div class="submitbtn" (click)="guestLogin()">
    <button
      class="text-center red-btn"
      [style.background-color]="negativeColor"
      [style.border]="negativeColor"
      [style.color]="negativeColorText"
    >
      {{continueAsGuestText}}
    </button>
  </div>
</div>
