<div class="warning-body">
  <p class="attention">{{attentionText}}</p>
  <p class="customized">{{alreadyCustomizedText}}</p>
  <p class="cleared">{{confirmationText}}</p>
  <div class="buttons">
    <button matRipple (click)="cancel()" class="cancel">{{cancelText}}</button
    ><button
    matRipple
      class="
        start
        background-positive-button-color
        positive-button-text-color
        border-positive-button-color
      "
      (click)="start()"
    >
      {{startNewItemText}}
    </button>
  </div>
</div>
