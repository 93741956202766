import { Injectable } from '@angular/core';
import { Tip } from '../models/tip';
import { DatabaseHandler } from 'src/app/DatabaseHandler';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class TipService {
  tipsPercent!: Tip[];
  tipsFixed!: Tip[];
  selectedTip!: Tip;
  isNoTipSelected: string = 'false';

  constructor(private logger: LogService) { }

  getTipMaster() {
    //Remove this condition because we didnt get updated value after sync in tippopup
    // if (this.tipsPercent === undefined) {
    var sqlString = `SELECT DISTINCT * FROM TipMasters WHERE IsPercentage = 'True'`;
    const callback = (tx: string, result: any) => {
      this.tipsPercent = Array.from(result.rows);

      this.tipsPercent.forEach((element) => {
        if (element.IsDefault == 'True') {
          this.selectedTip = element;
        }
      });
    };

    const errorCallback = (tx: string, result: any) => {

      console.log('Tip Service, Error getTipMaster :- ', result);

    };

    DatabaseHandler.executeSqlStatement(
      sqlString,
      [],
      callback,
      errorCallback
    );
    // }
  }

  getTipFixed() {
    // if (this.tipsFixed === undefined){
    const sqlString = `SELECT DISTINCT * FROM TipMasters WHERE IsPercentage = 'False'`;

    const callback = (tx: string, result: any) => {
      this.tipsFixed = Array.from(result.rows);

      this.tipsFixed.forEach((element) => {
        if (element.IsDefault == 'True') {
          this.selectedTip = element;
        }
      });
    };

    const errorCallback = (tx: string, result: any) => {
      console.log('Tip Service, Error getTipFixed :- ', result);
    };

    DatabaseHandler.executeSqlStatement(sqlString, [], callback, errorCallback);
    // }
  }
}
