import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-item-full-description',
  templateUrl: './item-full-description.component.html',
  styleUrls: ['./item-full-description.component.css'],
})
export class ItemFullDescriptionComponent implements OnInit, OnDestroy {
  languageSub!: Subscription;
  modalReference: any;
  positiveColor: string = '';
  positiveColorText: string = '';

  closeText: string = '';

  @Input() description: any = '';
  constructor(
    private modalService: NgbModal,
    private language: LanguageService
  ) {}
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {
    this.closeText = this.language.getTextElement('close');
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.positiveColor = GeneralSetting.getPositiveColor();
    this.positiveColorText = GeneralSetting.getPositiveColorText();
  }

  public static open(modalService: NgbModal) {
    let modalReference = modalService.open(ItemFullDescriptionComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;

    return modalReference;
  }
  close() {
    this.modalReference.close();
  }
}
