<div id="custominfo-popup" role="dialog">
  <a
    href="Javascript:void(0);"
    class="popupClose"
    (click)="onPopupClose()"
    style="display: block"
  >
    <i class="fas fa-times"></i>
  </a>
  <div class="header" *ngIf="reward != undefined">
    <div *ngIf="loyaltyType == 2">
      <div *ngIf="reward.Items && reward.Items.length > 1; else single">
        <div [innerHTML]="rewardString"></div>
        <h2 class="text-center">{{ getRewardDesc() }}</h2>
        <h4 class="text-center">
          {{ reward.points | number: "1.0":"en-US" }} {{pointsText}}
        </h4>
        <div
          *ngFor="let item of reward.Items"
          style="
            height: 13rem;
            border: 1px solid #000;
            margin: 1rem;
            border-radius: 2rem;
          "
        >
          <div class="row item-row no-gutters" (click)="selectItem(item)">
            <div class="col-md-2 imgdiv">
              <img
                src="{{
                  item.ImageUrl && item.ImageUrl != ''
                    ? baseImageUrl + item.ImageUrl
                    : 
                    placeholderImage !='' 
                      ? baseImageUrl+placeholderImage :
                      'assets/images/Image_21.png'
                }}"
                style="margin: 1rem"
                class="card-img"
                alt="itemImage"
              />
            </div>
            <div class="col-md-6" style="width: 35.6vw">
              <div class="card-body">
                <h3 class="card-title">
                  {{ item.Name }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #single>
        <h3 class="text-center">
          <div [innerHTML]="rewardString"></div>
        </h3>
        <h2 class="text-center">{{ getRewardDesc() }}</h2>
        <h4 class="text-center">
          {{ reward.points | number: "1.0":"en-US" }} {{pointsText}}
        </h4>
      </ng-template>
      <div class="popup-footer">
        <button
          type="submit"
          class="text-center green-btn background-positive-button-color white"
          (click)="onRedeemRewardClick()"
        >
          {{redeemRewardText}}
        </button>
      </div>
    </div>
    <div *ngIf="loyaltyType == 3">
      <div class="flex-down just-contents-center">
        <div class="text-center top-text">{{helloText}}</div>
        <img src="assets/images/rewards.PNG" />
        <p class="text-center bottom-text">
          {{redeemedRewardText}}
        </p>
        <div class="popup-footer">
          <button
            type="submit"
            class="text-center green-btn background-positive-button-color white"
            (click)="close('apply')"
          >
            {{okText}}
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="!displayBanked && loyaltyType == 1">
      <h2 class="text-center">{{ reward.label }}</h2>
      <h4 class="text-center">{{ reward.fineprint }}</h4>
      <h5 class="text-center font-size5 even-pad4">{{ reward.description }}</h5>
      <div class="text-center">
        <img class="popupImage" src="{{ reward.imageurl }}" />
        `
      </div>
      <div class="popup-footer">
        <button
          type="submit"
          class="text-center green-btn background-positive-button-color white"
          (click)="onRedeemRewardClick()"
        >
          {{redeemRewardText}}
        </button>
      </div>
    </div>
    <div *ngIf="displayBanked && loyaltyType == 1">
      <div class="header">
        <h2 class="text-center">{{ reward.label }}</h2>
        <h4 class="text-center">{{ reward.fineprint }}</h4>
      </div>
      <p class="text-center">
        {{ maxUsable | number: "1.2-2" | CurrencyPipe }}
      </p>
      <div class="body">
      </div>
      <div class="footer">
        <button
          type="submit"
          class="text-center green-btn background-positive-button-color white"
          (click)="onRedeemRewardClick(1)"
        >
          {{redeemRewardText}}
        </button>
        <p class="text-center cancel-button" (click)="close('error')">{{closeText|uppercase}}</p>
      </div>
    </div>
    <div *ngIf="loyaltyType == 4">
      <h2 class="text-center">{{ reward.label }}</h2>
      <h4 class="text-center">{{ reward.fineprint }}</h4>
      <h5 class="text-center font-size5 even-pad4">{{ reward.description }}</h5>
      <div class="text-center">
        <img class="popupImage" src="{{ reward.imageurl }}" />        
      </div>
      <div class="popup-footer">
        <button
          type="submit"
          class="text-center green-btn background-positive-button-color white"
          (click)="onRedeemRewardClick()"
        >
        {{applyText}}    
        </button>
      </div>
    </div>
  </div>
</div>
<app-loading [hidden]="true"></app-loading>
