<div id="sold-out" class="sold-out">
  <div class="modal-body sold-out-modal">
    <h1 class="text-center">SOLD OUT</h1>
    <div class="content">
      <div>
        <h2>{{topText}} out of stock.</h2>
      </div>
      
      <h4 class="text-center">
        We're sorry! We are out of {{ bottomText }}. Would you still
        like to add {{ parent.Name }} to our cart?
      </h4>

      <img src="{{ imageUrl + parent.ImageUrl }}" onerror="this.onerror=null; this.src='assets/images/Image_21.png'"/>
      <div class="modal-footer">
        <div class="col-12 col-md-12 popup-footer text-center">
          <button
            class="positive-button-text-color background-positive-button-color green-btn text-uppercase"

            data-dismiss="modal"
            aria-label="Close"
            type="submit"
            name="submits"
          (click)="continue()"
          >
           CONTINUE
          </button>
          <br />
          <button
          matRipple
            class="white-btn warn text-uppercase negative-button-text-color background-negative-button-color"

            data-dismiss="modal"
            aria-label="Close"
            (click)="backToMenu()"
          >
            BACK TO MENU
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
