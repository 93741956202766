<div id="specialrequest">
  <div class="modal-body specialrequest-modal">
    <h1 class="text-center">{{ specialRequestText }}</h1>

    <div class="content">
      <h4 class="text-center">{{ perfectOrderText }}</h4>

      <form (ngSubmit)="addSpecialRequest()">
        <div class="form">
          <div class="form-group">
            <label for="specialrequest-text">{{ yourRequestText }}</label>
            <textarea
              KeyboardCustom
              layout="default"
              maxlength="255"
              customLength="350"
              [readonly]="isKiosk"
              name="specialRequest"
              class="form-control"
              [(ngModel)]="specialRequest"
              id="specialrequest-text"
              rows="10"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 col-md-12 popup-footer text-center">
            <button
              matRipple
              class="green-btn text-uppercase"
              type="submit"
              name="submits"
              [style.background-color]="positiveColor"
              [style.color]="positiveColorText"
            >
              {{addSpecialRequestText|uppercase}}</button>
            <br />
            <button
              matRipple
              class="white-btn warn text-uppercase"
              (click)="cancelModal()"
              [style.background-color]="negativeColor"
              [style.color]="negativeColorText"
            >
              {{cancelText}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!--
</div>
</div>
</div>--Special Request UI Popup-->
