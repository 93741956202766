
  <div class="modal-body timer-modal">
    <h2 class="center-text">{{heyText}}</h2>
    <h4 class="center-text">{{areYouStillHereText}}</h4>
    <h3 class="center-text"> <img src="../../../assets/images/timer.svg" onerror="this.onerror=null; this.src='assets/images/Image_21.png'" class="timer-icon"/>
      <!---<i class="fa fa-clock"></i>--></h3>
    <h2 class="negative-button-color center-text">00:{{ pad(time) }}</h2>
    <br />
    <div class="col-12 col-md-12 popup-footer center-text">
      <a class="green-btn border-positive-button-color background-positive-button-color upper w-100-p" (click)="stillHere()"
        >{{stillHereText}}</a
      >
      <br />
      <div class="clearfix"></div>
      <a class="background-negative-button-color plain-btn negative-button-text-color w-100-p" (click)="startOver()">{{startOverText|uppercase}}</a>
    </div>
  </div>

