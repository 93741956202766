<div class="container" [ngStyle]="{ 'background-image': 'url(' + backGround + ')' }">
  <div class="lastfiveorder">
    <div id="bannerimage"
         [ngStyle]="{ 'background-image': 'url(' + welcomeLoyaltyLogoPath + ')' }"></div>
    <div id="snackbar" *ngIf="showSnackBar">{{pleaseAddOrderText}}</div>
    <div class="header text-color text-center">
      <h1>{{customerWelcomeText}}</h1>
      <h2>{{seeYouAgainText}}</h2>
    </div>
    <div class="reward-info text-center" *ngIf="showLoyaltyMode">
      <div class="row table-name">
        <div class="">{{yourRewardsAccountText|uppercase}}</div>
      </div>
      <div class="row total-point">
        <div class="col-md-6 col-xs-6 content-header left">{{totalPointsText}}</div>
        <div class="col-md-6 col-xs-6 text-right right" style="color: #3aae9b">
          {{ TotalPoints | number: "1.0":"en-US" }}
        </div>
      </div>
      <div class="row other-point">
        <div class="col-md-6 col-xs-6 content-header left">{{lastVisitText}}</div>
        <div class="col-md-6 col-xs-6 text-right right">
          {{ LastVisit }}
        </div>
      </div>
      <div class="row other-point">
        <div class="col-md-6 col-xs-6 content-header left">
          {{pointsLastVisitText}}
        </div>
        <div class="col-md-6 col-xs-6 text-right right" style="color: #3aae9b">
          {{ PointEarnedLastVisit | number: "1.0":"en-US" }}
        </div>
      </div>
    </div>
    <div class="header text-center">
      <h3>{{recentOrderText|uppercase}}</h3>
    </div>
    <div class="no-recent-history text-center" *ngIf="showNoHistory">
      <img src="assets/images/ic_no_recent_order_1.png"
           onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
           alt="no recent history" />
      <p>{{noRecentOrderText}}</p>
    </div>
    <div [ngStyle]="{'height': showLoyaltyMode ? '46vh' :'62vh'  }">
      <div *ngIf="!showNoHistory"
           class="order-history"
           id="arrowDown"
           [ngStyle]="{
          height: showLoyaltyMode ? '46vh' :'62vh',
          overflow: 'auto',
          width: showLoyaltyMode ? '80%' : '',
          margin: showLoyaltyMode ? '0rem auto 0' : '0 4rem 0'
        }">
        <div *ngFor="let item of itemlist"
             class="card mb-3 mx-auto"
             style="min-width: 100%"
             [ngStyle]="{ 'min-height': showLoyaltyMode ? '12rem' : '15rem' }">
          <div class="row no-gutters">
            <div class="col-md-2 imgdiv">
              <img src="{{
                  item.ImageUrl && item.ImageUrl != ''
                    ? baseImageUrl + item.ImageUrl
                    : placeholderImage !='' 
                      ? baseImageUrl+placeholderImage 
                      :'assets/images/Image_21.png'
                }}"
                   class="card-img"
                   alt="itemImage"
                   [ngStyle]="{ width: showLoyaltyMode ? '12rem' : '15rem' }" />
            </div>
            <div class="col-md-6">
              <div class="card-body">
                <h3 class="card-title"
                    [ngStyle]="{
                    'font-size': showLoyaltyMode ? '2.5rem' : '3rem'
                  }">
                  {{ item.Name }}
                  <span style="display: inline-block">
                    </span>
                </h3>
                <p class="card-text"
                   [ngStyle]="{
                    'font-size': showLoyaltyMode ? '2rem' : '2.5rem'
                  }">
                  {{ item.last5modsName }}
                </p>
              </div>
            </div>
            <div class="col-md-4 addtocart">
              <button *ngIf="!item.isSelected"
                      id="{{ item.ItemID }}"
                      (click)="addToCart(item)"
                      [ngStyle]="{ 'font-size': showLoyaltyMode ? '2.5rem' : '3rem' }">
                {{addToCartText}}
                    <!-- Commented below block as order minimum quantity is not currently supported -->
                <!-- Add
                {{
                  item.OrderMinimumQuantity == "0"
                    ? ""
                    : item.OrderMinimumQuantity === ""
                    ? ""
                    : (item.OrderMinimumQuantity
                      | numberConvert
                      | number: "1.0":"en-US")
                }}
                to cart -->
              </button>
              <button *ngIf="item.isSelected"
                      id="{{ item.ItemID }}"
                      (click)="addToCart(item)"
                      [ngStyle]="{ 'font-size': showLoyaltyMode ? '2.5rem' : '3rem' }">
                {{addedText}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <i class="fas fa-caret-down"
         [ngClass]="{
          showArrowDown: headerDescHasScroll,
          hideArrowDown: !headerDescHasScroll
        }"></i>
    </div>

    <div class="row footer">
      <button class="background-primary-color bold"
              *ngIf="!showNoHistory && itemlist.length"
              (click)="checkout()">
        {{checkoutText|uppercase}}
        {{
          lastfiveservice.numofItemsAdded > 0
            ? " (" + lastfiveservice.numofItemsAdded + ")"
            : ""
        }}
      </button>
      <button class="background-primary-color bold" (click)="continueToMenu()">
        {{continueToMenuText|uppercase}}
      </button>
      <button class="negativebtn background-negative-button-color negative-button-text-color bold"
              (click)="clearInfo()">
        {{signOutText|uppercase}}
      </button>
      <p class="text-center">{{ welcomeFooterText }}</p>
    </div>
  </div>
</div>



<app-loading style="display: none" [hidden]="true"></app-loading>
<app-out-of-stock [hidden]="true"></app-out-of-stock>
<app-alcoholic-items-exceed-max [hidden]="true"></app-alcoholic-items-exceed-max>
<app-alcoholic-over21-dialog [hidden]="true"></app-alcoholic-over21-dialog>
<app-timeout style="display: none" [hidden]="true"></app-timeout>


