import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { EmailReceiptDialogComponent } from '../dialogs/email-receipt-dialog/email-receipt-dialog.component';
import { LoyaltyService } from '../../services/loyalty.service';
import { UserService } from '../../services/user.service';
import { PaymentService } from '../../services/payment.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { OLOService } from '../../services/olo.service';
import { TipService } from 'src/app/services/tip.service';
import { ParBrinkService } from '../../services/par-brink.service';
import { ShippingService } from 'src/app/services/shipping.service';
import { EatOptionsService } from 'src/app/services/eat-options.service';
import { OracleService } from '../../services/oracle.service';
import { Subscription } from 'rxjs';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-thankyou-popup',
  templateUrl: './thankyou-popup.component.html',
  styleUrls: ['./thankyou-popup.component.css'],
})
export class ThankyouPopupComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    GeneralSetting.setIsTipSelected('false');
    clearTimeout(this.timeoutTimer);
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  languageSub!: Subscription;
  positiveColor: string = '';
  positiveColorText: string = '';
  negativeColor: string = '';
  negativeColorText: string = '';
  priceColor: string = '';
  dataService: DataService;
  hideReceipt: string = '';
  isLoyalty: boolean = false;
  pointsEarned: number = 0;
  visitCount: number = 0;
  totalLoyaltyPoints: number = 0;
  loyaltyType: number = 0;

  constructor(
    private router: Router,
    public modalService: NgbModal,
    DataService: DataService,
    private loyaltyService: LoyaltyService,
    private userService: UserService,
    private olo: OLOService,
    private shipping: ShippingService,
    private readonly optionsService: EatOptionsService,
    private KeyboardService: KeyboardService,
    private language:LanguageService
  ) {
    this.dataService = DataService;
  }

  @Input() total: any;
  orderNo: any;

  modalReference: any;
  timeoutPopup: any;
  timeoutTimer: any;
  enableTableTents: string | null = '';
  tableTentText: string = '';
  orderNoText: string = ''
  isRemovePricingAndPayments = GeneralSetting.getIsRemovePricingAndPayments();
  isShowPoints: boolean = true;
orderReceiptText:string=''
thankYouText:string=''
orderOnTheWayText:string=''
totalText:string=''
pointsEarnedText:string=''
visitCountText:string=''
rewardsPointsText:string=''
emailReceiptText:string=''
finishOrderText:string=''
  private loyaltyVisitRes: any = undefined;
  loadText(){
    this.orderReceiptText=this.language.getCustomMapValues('menu_setting_display__confirm_order_modal_header_text')
    this.thankYouText=this.language.getCustomMapValues('menu_setting_display__confirm_order_modal_secondary_text')
    this.orderOnTheWayText=this.language.getCustomMapValues('menu_setting_display__confirm_order_modal_main_text')
    this.totalText=this.language.getTextElement('text_total')
    this.pointsEarnedText=this.language.getTextElement('txt_points_earned').replace('%d',this.pointsEarned.toString())
    this.visitCountText=this.language.getTextElement('txt_your_total_visit_count').replace('%d',this.visitCount.toString())
    this.rewardsPointsText=this.language.getTextElement('txt_you_now_have_rewards_points').replace('%d',this.totalLoyaltyPoints.toString())
    this.emailReceiptText=this.language.getTextElement('email_receipt')
    this.finishOrderText=this.language.getTextElement('finish_order')
    this.tableTentText=this.language.getCustomMapValues('menu_setting_display__order_table_tent_number_text')
    this.orderNoText=this.language.getTextElement('lbl_order_no')
  }
  ngOnInit(): void {
    this.setLoyaltyPointsDetail();
    this.languageSub=this.language.localeCommunicator.subscribe((val)=>{
      this.loadText()
    })
    this.positiveColor = GeneralSetting.getPositiveColor();
    this.positiveColorText = GeneralSetting.getPositiveColorText();
    this.negativeColor = GeneralSetting.getNegativeColor();
    this.negativeColorText = GeneralSetting.getNegativeColorText();

    var currentOption = this.optionsService.getCurrentEatOption();
    if (
      currentOption.KioskTableTent == 'True' &&
      !this.isRemovePricingAndPayments
    ) {
      this.enableTableTents = this.tableTentText.trim() !== '' ? this.tableTentText : GeneralSetting.getTentNumberText();
    } else {
      this.enableTableTents = this.orderNoText;
    }
    this.hideReceipt = GeneralSetting.getHideEmailReceiptButton();

    
  }

  ngAfterViewInit() {
    GeneralSetting.setRedirectToMain('True');
    this.timeoutTimer = setTimeout(() => {
      if (GeneralSetting.getRedirectToMain() == 'True') {
        this.finishOrder();
        this.priceColor = GeneralSetting.getPriceColor();
      }
    }, 5000);
  }

  private setLoyaltyPointsDetail() {
    this.loyaltyType = this.loyaltyService.loyaltyType;
    if (this.userService.isUserLoggedIn) {
      this.isLoyalty = this.loyaltyService.isLoyalty;
      if (this.isLoyalty) {
        if (
          this.loyaltyVisitRes &&
          this.loyaltyVisitRes != undefined &&
          Object.entries(this.loyaltyVisitRes > 0)
        ) {
          this.isShowPoints = this.loyaltyVisitRes.isShowPoints;
          this.pointsEarned = this.loyaltyVisitRes.pointsEarned;
          this.totalLoyaltyPoints = this.loyaltyVisitRes.totalLoyaltyPoints;
          this.visitCount = this.loyaltyVisitRes.visitCount;
        }
      }
      this.visitCount = this.userService.referrizerLoyaltyUser.visitsCount + 1;
    } else {
      this.isLoyalty = false;
      this.isShowPoints = false;
    }
  }

  public static open(
    modelService: any,
    orderNo: string | null,
    loyaltyObject: any = undefined,
    total: number
  ) {
    let modalReference = modelService.open(ThankyouPopupComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.total = total.toFixed(2);
    modalReference.componentInstance.orderNo = orderNo;
    modalReference.componentInstance.loyaltyVisitRes = loyaltyObject;
    modalReference.componentInstance.modalReference = modalReference;

    return modalReference;
  }

  finishOrder() {
    this.modalReference.close('');
    GeneralSetting.setRedirectToMain('True');
    setTimeout(() => {
      if (GeneralSetting.getRedirectToMain() == 'True') {
        this.router.navigateByUrl('/touchToStart');
      }
      this.shipping.shippingDetails = undefined;
    }, 300);
  }

  emailReceipt() {
    GeneralSetting.setRedirectToMain('False');

    window.clearTimeout(this.timeoutTimer);

    this.modalService.dismissAll();

    EmailReceiptDialogComponent.open(this.modalService, this.orderNo);
  }
}
