<ng-container *ngIf="isCombo">
  <section class="bottom-section">

    <div class="left-section">

      <!-- <img src="../../../assets/images/box-builder/warm-delicious.png" class="warm-delicious"
          alt="warm-delicious" /> -->
      <div class="row1">
        <img src="{{baseImageUrl + footerImage}}" class="duck-logo"
          onerror="this.onerror=null; this.src='assets/images/Image_21.png';" alt="duck-logo" />
        <!-- <img src="../../../assets/images/box-builder/duck.png" class="duck-logo" alt="duck-logo" /> -->
        <!-- <img src="../../../assets/images/box-builder/warm-delicious.png" class="warm-delicious" alt="warm-delicious"/>-->
        <div class="tag-line">
          <h3>{{inputItem.ShortDescription}}</h3>
        </div>
      </div>
    </div>
    <div class="right-section">

      <button class="btn btn-add active" type="button" data-bs-toggle="modal" data-bs-target="#customize"
        (click)="reviewYourBox()">
        {{reviewBoxText}}
      </button>
      <button class="btn btn-cancel" (click)="cancel()">{{cancelText}}</button>
    </div>

  </section>
</ng-container>
<div style="display: none">
  <app-box-builder-review></app-box-builder-review>
  <app-general-alert></app-general-alert>
</div>