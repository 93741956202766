import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { loggingData, LogService } from 'src/app/services/log.service';
import {
  getLoyaltyCustomerResponse,
  ReferrizerLoyaltyRewardDetail,
  customerVisitRes,
  LevelUpProposal,
  LevelUpItem,
  RefLoyaltyCustomer,
  PaytronixCustomerResponse,
  LoyaltyType,
  LevelUpObject,
  LoyaltyInfo,
} from '../models/loyaltyModal';
import { UserService } from './user.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { DatabaseHandler } from '../DatabaseHandler';
import { ItemV2, ModifierIngredientV2 } from '../models/item';
import { CartService } from './cart.service';
import { OLOService } from './olo.service';
import { OLOChainProduct } from '../models/OLOProductReq';
import { CategorySalesType, CommonFunctions } from './common';
import { PaymentService } from './payment.service';
import { resolve } from 'dns';
import { ApiService } from './api/api.service';
import { LanguageService } from './language.service';
import { CustomerWelcomeModalComponent } from '../components/dialogs/customer-welcome-modal/customer-welcome-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerLoginPopupComponent } from '../components/dialogs/customer-login-popup/customer-login-popup.component';

@Injectable({
  providedIn: 'root',
})
export class LoyaltyService {
  private baseApiUrl = environment.apiUrl;

  private companyId = '';

  private branchId = '';

  loyaltyRewards = [] as any[];

  selectedLoyaltyReward = '';

  punchhAppliedRewardID: string = '';
  oloPaytronixAppliedRewardID: string = '';

  loyaltyInfo: LoyaltyInfo[] = [];

  isLoyalty = false;

  loyaltyType!: number;
  // isGetLoyaltyVisitRes = false;

  levelUpObject: LevelUpObject = {} as LevelUpObject;

  customerVisitRes: customerVisitRes = {} as customerVisitRes;

  subSelectedLoyaltyReward = new BehaviorSubject<string>(
    this.selectedLoyaltyReward
  );

  redeemedRewardSub = new Subject<number>();

  updateRewardSub = new Subject<number>();

  setLoyaltyPoints = new Subject<number>();

  subLoyaltyRewards = new BehaviorSubject<any[]>(this.loyaltyRewards);

  apiUrl: string;

  constructor(
    private readonly http: HttpClient,
    private userService: UserService,
    private logger: LogService,
    private readonly cartService: CartService,
    private readonly olo: OLOService,
    private payment: PaymentService,
    private api: ApiService,
    private language: LanguageService,
    private modalService: NgbModal
  ) {
    this.apiUrl = environment.apiUrl;
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();

    this.setLoyaltyType();
  }
  /**
   * @description performs a three-part check to determine if loyalty is active
   * @description sets loyaltyService.isLoyalty to and stores values in localStorage
   * @checkOne company has mapped loyalty type
   * @checkTwo location has mapped loyalty program
   * @checkThree use loyalty kiosk is selected under general settings in Grubbrr backend
   * @returns Promise containing boolean value such that `true` if all three checks, else `false`. This value is loyaltyService.isLoyalty
   *
   */
  setLoyaltyType() {
    return new Promise<boolean>((resolve) => {
      this.getLoyaltyType().then((loyaltyType) => {
        this.getLoyalty().then((data: any) => {
          if (GeneralSetting.getUseLoyaltyKiosk() == 'True') {
            this.isLoyalty = data;
          }
          if (data) {
            this.loyaltyType = loyaltyType;
            GeneralSetting.setShowLoyaltyLogin('True');
          } else {
            GeneralSetting.setShowLoyaltyLogin('False');
            this.loyaltyType = LoyaltyType.None;
          }
          resolve(data);
        });
      });
    });
  }
  //use in console for testing levelup QR scan in staging levelUp sandbox
  // for successful payment:
  // window.postMessage({'messageType':'ScannerResult','result':"LU03009VHH9103JJZOR1V0Q0030000LU"})
  // for earning points only / unsuccessful payment:
  // window.postMessage({'messageType':'ScannerResult','result':"LU03009VHH4PJST65RNGCV00030000LU"})
  /**
   * @description handles levelUp calls with both proposal and completion.
   * @description levelUp order complete API requires that the proposal and the completion have the same value for discount_only parameter
   * @returns {-{Promise} containing response from 'RedeemLoyaltyRewardV3'} or a boolean false
   * @todo refactor POS integrations (ParBrink, Oracle) to be called after proposal, use this function in all places for levelUp order propose and levelUp order complete
   * @todo POS integrations should have an order validate call made after the levelUp order propose, and the levelUp order complete should occur before the POS order complete. this function should handle levelUp propose, call POS validate and handle levelUp complete
   */
  levelUpPayment() {
    return new Promise<any>(async (resolve, reject) => {
      if (this.levelUpObject.order!.proposed_order.discount_only) {
        await this.proposeLevelUpOrder(
          this.cartService.getCartItems(),
          true
        ).then((data: any) => {
          if (data.statusCode == '200' && data.status == 'success') {
            this.levelUpObject.proposal = data.data.proposed_order;
            this.completeLevelUpOrder(true).then(
              (data: any) => {
                resolve(data);
              },
              (err: any) => {
                reject(err);
              }
            );
          } else {
            resolve(false);
          }
        });
      } else {
        this.completeLevelUpOrder(true).then(
          (data: any) => {
            resolve(data);
          },
          (err: any) => {
            reject(err);
          }
        );
      }
    });
  }
  /**
   * @description used to complete a levelUp proposed order. If user is paying with loyalty app payment and the levelUp proposed order was made with discount_only as true, a new proposal must be made with discount_only as false
   * @param payment whether user is paying with the Loyalty-App-saved payment method, defaults false if not passed
   * @returns {-{Promise} containing response from 'RedeemLoyaltyRewardV3'}
   * @todo move to API service and implement retries
   */
  completeLevelUpOrder(payment: boolean = false) {
    const url = this.apiUrl + 'RedeemLoyaltyRewardV3';
    let headers = new HttpHeaders()
      .set('Version', '1.0')
      .set('Content-Type', 'application/json')
      .set('CompanyID', GeneralSetting.getCompanyId())
      .set('BranchID', GeneralSetting.getBranchId())
      .set('DeviceID', 'sdfsd15145151515');
    let body = {
      levelUpCompletedOrder: {
        completed_order: {
          applied_discount_amount: this.levelUpObject.proposal!.discount_amount,
          discount_only: !payment,
          identifier_from_merchant: this.payment.getRandomOrderNo(),
          location_id: Number(GeneralSetting.getLevelUpLocationId()),
          partial_authorization_allowed: !payment ? true : false,
          payment_token_data: this.levelUpObject.qr
            ? this.levelUpObject.qr
            : GeneralSetting.getCustomerLoginMobile(),
          proposed_order_uuid: this.levelUpObject.proposal!.uuid,
          spend_amount:
            this.cartService.total +
            this.levelUpObject.proposal!.discount_amount -
            this.cartService.tip,
          tax_amount: CommonFunctions.roundDigit(this.cartService.tax, 2),
          tip_amount: Number(this.cartService.tip),
          items: [] as LevelUpItem[],
        },
      },
    };
    let data = this.levelUpItemFormatter(this.cartService.getCartItems());
    body.levelUpCompletedOrder.completed_order.items = data.items;
    return this.http.post(url, body, { headers }).toPromise();
  }
  /**
   * @description calls levelUp proposal if possible or resolves an empty object in the event that levelUp has failed in the current user session
   * @param cart current cart items, used to generate payload for levelUp proposal
   * @param payment boolean value corresponding to whether the user is paying with their Loyalty-App-saved payment method
   * @returns {-{Promise} containing response from 'LevelUpProposedOrderSK' or an empty object
   */
  proposeLevelUpOrder(cart: ItemV2[], payment: boolean = false) {
    // if (!this.levelUpObject.fail) {
    const url = this.apiUrl + 'LevelUpProposedOrderSK';
    let headers = new HttpHeaders()
      .set('Version', '1.0')
      .set('Content-Type', 'application/json')
      .set('CompanyID', GeneralSetting.getCompanyId())
      .set('BranchID', GeneralSetting.getBranchId())
      .set('DeviceID', 'sdfsd15145151515');
    const body = this.assembleLevelUpOrder(cart, payment);
    this.levelUpObject.order = body;
    return this.http.post(url, body, { headers }).toPromise();
    // } else {
    //   return new Promise<any>((resolve) => {
    //     resolve({});
    //   });
    // }
  }
  /**
   * @description sends log to Grubbrr backend with specific error details
   */
  logBadLevelUpProposal() {
    let log = new loggingData(
      'LevelUp Error',
      'Error Proposing LevelUp Order',
      'Bad Data Error',
      'Encountered Error While Proposing Order:' +
      JSON.stringify(this.levelUpObject.order),
      true
    );
    this.logger.sendLogToServer(log);
  }
  /**
   *
   * @param cart current cart items, used to generate payload for levelUp proposal
   * @param payment boolean value corresponding to whether the user is paying with their Loyalty-App-saved payment method
   * @returns {-{LevelUpProposal} object structured for levelUp API payload
   */
  assembleLevelUpOrder(cart: ItemV2[], payment: boolean = false) {
    let body: LevelUpProposal = {
      proposed_order: {
        payment_token_data: this.levelUpObject.qr
          ? this.levelUpObject.qr
          : this.levelUpObject.originalQr ? this.levelUpObject.originalQr : GeneralSetting.getCustomerLoginMobile(),
        discount_only: !payment,
        receipt_message_html: null,
        tax_amount: 0,
        tip_amount: 0,
        identifier_from_merchant: this.payment.getRandomOrderNo(),
        partial_authorization_allowed: false,
        location_id: Number(GeneralSetting.getLevelUpLocationId()),
        spend_amount: 0,
        exemption_amount: 0,
        items: [],
      },
    };
    let data = this.levelUpItemFormatter(cart);
    body.proposed_order.spend_amount = data.amount;
    body.proposed_order.items = data.items;
    body.proposed_order.exemption_amount = data.exemption
    return body;
  }
  /**
   * @description should not be called directly, helper for assembleLevelUpOrder
   * @param cart current cart items, used to generate payload for levelUp proposal
   * @returns object containing charged amount and array of items, used as part of levelUp payloads
   */
  private levelUpItemFormatter(cart: ItemV2[]) {
    let arr = [];
    let amount: number = 0;
    let exemption: number = 0
    for (let i = 0; i < cart.length; i++) {
      let item: LevelUpItem = {
        item: {
          charged_price_amount: cart[i].totalPrice!,
          description: cart[i].FullDescription,
          name:
            cart[i].ItemName && cart[i].ItemName != ''
              ? cart[i].ItemName
              : cart[i].Name && cart[i].Name != ''
                ? cart[i].Name
                : cart[i].KOTDisplayName,
          quantity: Number(cart[i].Quantity),
          sku: cart[i].RefItemId!,
          category: cart[i].CategoryName,
          standard_price_amount: cart[i].totalPrice!,
          children: [],
        },        
      };
      if (!cart[i].IsCombo) {
        if (cart[i].totalPrice) {
          amount += cart[i].totalPrice!;
        }
        if (cart[i].Modifiers && cart[i].Modifiers.length > 0) {
          for (let j = 0; j < cart[i].Modifiers.length; j++) {
            let temp: ModifierIngredientV2[] = this.nestedModCheck(
              cart[i].Modifiers[j]
            );

            for (let k = 0; k < temp.length; k++) {
              item.item.children.push({
                item: {
                  charged_price_amount: Number(temp[k].ExtraPrice),
                  description: '',
                  name: temp[k].Name,
                  quantity: Number(temp[k].Quantity),
                },
              });
            }
          }
        }
      } else {
        if (cart[i].totalPrice) {
          amount += cart[i].totalPrice!;
        }
        if (cart[i].ComboGroup && cart[i].ComboGroup.length > 0) {
          for (let j = 0; j < cart[i].ComboGroup.length; j++) {
            if (cart[i].ComboGroup[j].Items) {
              for (let k = 0; k < cart[i].ComboGroup[j].Items.length; k++) {
                if (cart[i].ComboGroup[j].Items[k].isSelected) {
                  let tempItem: any = {
                    item: {
                      charged_price_amount: Number(
                        cart[i].ComboGroup[j].Items[k].Price
                      ),
                      quantity: Number(cart[i].ComboGroup[j].Items[k].Quantity),
                      description: '',
                      name: cart[i].ComboGroup[j].Items[k].Name,
                      children: [],
                    },
                  };
                  if (
                    cart[i].ComboGroup[j].Items[k].Modifiers &&
                    cart[i].ComboGroup[j].Items[k].Modifiers.length
                  ) {
                    for (
                      let l = 0;
                      l < cart[i].ComboGroup[j].Items[k].Modifiers.length;
                      l++
                    ) {
                      let temp: ModifierIngredientV2[] = this.nestedModCheck(
                        cart[i].ComboGroup[j].Items[k].Modifiers[l]
                      );
                      for (let m = 0; m < temp.length; m++) {
                        tempItem.item.children.push({
                          charged_price_amount: Number(temp[m].ExtraPrice),
                          description: '',
                          name: temp[m].Name,
                          quantity: Number(temp[m].Quantity),
                        });
                      }
                    }
                  }
                  item.item.children.push(tempItem);
                }
              }
            }
          }
        }
      }
      if (cart[i].ItemCategorySalesTypeID == CategorySalesType.Alcohol.toString()) {
        exemption += cart[i].totalPrice!
      }
      arr.push(item);
    }
    return { amount: amount, items: arr, exemption: exemption };
  }
  /**
   * @description checks if object is a mofiifer or an ingredient
   * @ifModifier recursively checks for ingredients and modifiers if selected
   * @ifIngredient pushes to arr if selected
   * @param modifier object to check
   * @param arr array of selected ingredients
   * @returns array of selected ingredients
   */
  private nestedModCheck(modifier: any, arr: any[] = []) {
    if (modifier.IsModifier) {
      //received nested
      if (modifier.IsSelected) {
        for (let i = 0; i < modifier.Ingredients.length; i++) {
          if (modifier.Ingredients[i].IsIngredient) {
            if (modifier.Ingredients[i].IsSelected) {
              arr.push(modifier.Ingredients[i]);
            }
          } else {
            arr = this.nestedModCheck(modifier.Ingredients[i], arr);
          }
        }
      }
      return arr;
    } else {
      for (let i = 0; i < modifier.Ingredients.length; i++) {
        if (modifier.Ingredients[i].IsIngredient) {
          if (modifier.Ingredients[i].IsSelected) {
            arr.push(modifier.Ingredients[i]);
          }
        } else {
          //found nested
          arr = this.nestedModCheck(modifier.Ingredients[i], arr);
        }
      }
      return arr;
    }
  }
  /**
   * @param qr the value obtained from the QR code scanner
   * @returns {-{Promise} containing response from OLO_SigninwithQRCodeV2SK}
   * @todo create data model for API response, move to API service and implement retries
   */
  oloPunchhQRLogin(qr: string) {
    let basket = {
      QRCode: qr,
    };
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();
    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2');
    return this.http
      .post<any>(this.baseApiUrl + 'OLO_SigninwithQRCodeV2SK', basket, {
        headers,
      })
      .toPromise();
  }

  /**
   * @description Controller function that creates a user account in Referrizer or Punchh
   * @implements {createReferrizerCustomer(firstName, phone)}
   * @implements {createPunchhCustomer(firstName,phone,lastName,email, password)}
   * @returns  data stream containing information in the form of getLoyaltyCustomerResponse
   * @param  firstName used in Referrizer and Punchh
   * @param  phone used in Referrizer and Punchh
   * @param  lastName required in Punchh
   * @param  email required in Punchh
   * @param  password required in Punchh
   */
  createLoyaltyCustomer(
    firstName: string,
    phone: string,
    lastName?: string,
    email?: string,
    password?: string
  ) {
    if (this.loyaltyType == LoyaltyType.Referrizer) {
      return this.createReferrizerCustomer(firstName, phone);
    } else if (this.loyaltyType == LoyaltyType.OLOPunchh) {
      return this.createOLOPunchhCustomer(
        firstName,
        phone,
        lastName!,
        email!,
        password!
      );
    } else {
      return null;
    }
  }

  /**
   * @description removes applied rewards to ensure no rewards are lost if order is canceled/abandoned.
   * @description Used only after OLO order validation, and before payment is received
   * @returns {-{Promise} containing response from 'OLO_RemoveAppliedRewardV2SK'}
   * @todo move to API service and implement retries, create data model for API response
   */
  removeOLOPunchhReward(): Promise<any> {
    let basket = {
      rewardid: this.punchhAppliedRewardID,
      basketid: this.userService.punchhLoyaltyCustomer.basketID,
    };

    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();
    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2');
    return this.http
      .post<any>(this.baseApiUrl + 'OLO_RemoveAppliedRewardV2SK', basket, {
        headers,
      })
      .toPromise();
  }

  /**
   * @description Applies the selected reward to the OLO basket
   * @description This should only be done immediately before OLO order validation
   * @returns {-{Promise} containing response from OLO_ApplyRewardsToBasketV2SK}
   * @todo move to API service and implement retries, create data model for API response
   */
  applyOLOPunchhReward() {
    let basket = {
      references: [this.selectedLoyaltyReward],
      basketid: this.userService.punchhLoyaltyCustomer.basketID,
      membershipid: this.userService.punchhLoyaltyCustomer.MembershipID,
    };
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();
    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2');

    return this.http
      .post<any>(this.baseApiUrl + 'OLO_ApplyRewardsToBasketV2SK', basket, {
        headers,
      })
      .toPromise();
  }
  /**
   * @description removes applied rewards to ensure no rewards are lost if order is canceled/abandoned.
   * @description Used only after OLO order validation, and before payment is received
   * @returns {-{Promise} containing response from 'OLO_RemoveAppliedRewardV2SK'}
   * @todo move to API service and implement retries, create data model for API response
   */
  removeOLOPaytronixReward(): Promise<any> {
    let basket = {
      rewardid: this.oloPaytronixAppliedRewardID,
      basketid: this.userService.paytronixCustomer.basketid,
    };

    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();
    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2');
    return this.http
      .post<any>(this.baseApiUrl + 'OLO_RemoveAppliedRewardV2SK', basket, {
        headers,
      })
      .toPromise();
  }
  /**
   * @description Applies the selected reward to the OLO basket
   * @description This should only be done immediately before OLO order validation
   * @returns {-{Promise} containing response from OLO_ApplyRewardsToBasketV2SK}
   * @todo move to API service and implement retries, create data model for API response
   */
  applyOLOPaytronixReward() {
    let basket = {
      references: [this.selectedLoyaltyReward],
      basketid: this.userService.paytronixCustomer.basketid,
      membershipid: this.userService.paytronixCustomer.membershipid,
    };
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();
    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2');

    return this.http
      .post<any>(this.baseApiUrl + 'OLO_ApplyRewardsToBasketV2SK', basket, {
        headers,
      })
      .toPromise();
  }
  /**
   * @description sends an email containing reset instructions to the passed parameter
   * @param {-{string} email used to identify the account to send password reset instructions to}
   * @returns {-{Promise} containing response from SSO_ForgoPasswordV2SK}
   * @response response.statusCode='200' && response.message='' is a success response. In failure, message will not be empty
   * @todo regex validator on either end of function call to validate passed parameter
   * @todo move to API service and implement retries, create data model for API response
   */
  punchhForgotPassword(email: string) {
    let payload = {
      email: email,
      password: null,
      phone: null,
    };
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();
    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2');
    return this.http
      .post<any>(this.baseApiUrl + 'SSO_ForgoPasswordV2SK', payload, {
        headers,
      })
      .toPromise();
  }

  /**
   * @description checks which rewards are available to a user based on their current basket
   * @description should not be called directly.
   * @returns {-Observable data stream containing response from OLO_BasketQualifyingRewardsV2SK
   * @todo move to API service and implement retries, create data model for API response, convert to promise
   */
  oloPunchhBasketRewardCheck() {
    let basket = {
      authtoken: this.userService.punchhLoyaltyCustomer.authtoken,
      basketid: this.userService.punchhLoyaltyCustomer.basketID,
      membershipid: this.userService.punchhLoyaltyCustomer.MembershipID,
    };
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();
    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2');
    return this.http.post<any>(
      this.baseApiUrl + 'OLO_BasketQualifyingRewardsV2SK',
      basket,
      { headers }
    );
  }
  /**
   * @description creates OLO basket and associates with paytronix authtoken
   * @param productList array of pre-formatted items to be sent
   * @returns {-{Promise} containing response from OLO_CreateBasketV2SK
   * @todo move to API service and implement retries, create data model for API response
   */
  oloPaytronixCreateBasket(productList: any[]) {
    let basket = {
      authtoken: this.userService.paytronixCustomer.authtoken,
      Products: productList,
    };
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();
    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2.0');
    return this.http
      .post<any>(this.baseApiUrl + 'OLO_CreateBasketV2SK', basket, { headers })
      .toPromise();
  }
  /**
   * @description creates OLO basket
   * @returns {-{Observable} data stream containing response from OLO_CreateBasketV2SK
   * @param {-{OLOChainProduct[ ]} param passed in by parent function used to create basket
   * @todo move to API service and implement retries, create data model for API response, convert to promise
   */
  oloPunchhCreateBasket(productList: any[]) {
    let basket = {
      authtoken: this.userService.punchhLoyaltyCustomer.authtoken,
      Products: productList,
    };
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();

    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2.0');

    return this.http.post<any>(
      this.baseApiUrl + 'OLO_CreateBasketV2SK',
      basket,
      { headers }
    );
  }

  /**
   * @description creates new Punchh loyalty user
   * @returns data stream containing response from SSO_SignupV2SK in the form of getLoyaltyCustomerResponse
   * @param first_name passed as payload parameter
   * @param last_name passed as payload parameter
   * @param phone passed as payload parameter
   * @param email passed as payload parameter
   * @param password passed as payload parameter
   * @todo move to API service and implement retries, convert to promise
   */
  private createOLOPunchhCustomer(
    first_name: string,
    last_name: string,
    phone: string,
    email: string,
    password: string
  ): Observable<getLoyaltyCustomerResponse> {
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();

    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2.0');

    const modal = {
      email: email,
      password: password,
      phone: phone,
      first_name: first_name,
      last_name: last_name,
    };

    return this.http.post<getLoyaltyCustomerResponse>(
      this.baseApiUrl + 'SSO_SignupV2SK',
      modal,
      { headers }
    );
  }

  /**
   * @description used to create a customer visit for Referrizer users
   * @returns {-{Promise-boolean} resolves true if successful customer visit is created}
   * @implements {createReferrizerVisit(orderTotal)}
   * @param {-{string} orderTotal passed to helper functions}
   * @todo add handling for levelUp
   */
  createCustomerVisit(orderTotal: string): Promise<boolean> {
    if (this.loyaltyType == LoyaltyType.Referrizer) {
      return new Promise<boolean>((resolve) => {
        this.createReferrizerVisit(orderTotal).then((data: boolean) => {
          resolve(data);
        });
      });
    } else if (this.loyaltyType == LoyaltyType.LevelUp) {
      //need levelUp implementation
      return new Promise<boolean>((resolve) => {
        resolve(false);
      });
    } else {
      return new Promise<boolean>((resolve) => {
        resolve(false);
      });
    }
  }

  /**
   * @description returns customer visit history for Referrizer users
   * @implements {getReferrizerVisit()}
   * @returns {-{Observable} if Referrizer user}
   * @returns {-{null} if loyalty type is not Referrizer}
   */
  getCustomerVisit() {
    if (this.loyaltyType == LoyaltyType.Referrizer) {
      return this.getReferrizerVisit();
    } else if (this.loyaltyType == LoyaltyType.LevelUp) {
      return Promise.resolve(); //need levelUp implementation
    } else {
      return Promise.resolve(); //need error handling
    }
  }

  /**
   * @description returns customer information from grubbrr backend related to the phone number passed after validating with Punchh
   * @description should not be called directly
   * @returns {-{Observable} data stream containing response from OLO_SigninwithPhoneV2SK in the form of getLoyaltyCustomerResponse}
   * @param {-{string} phone passed as payload parameter}
   * @todo move to API service and implement retries, create data model for API response, convert to promise
   */
  getPunchhCustomer(phone: string) {
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();

    const headers = new HttpHeaders()

      .set('Version', '2')
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId);

    let modal = {
      Phonenumber: phone,
    };
    return this.http.post<getLoyaltyCustomerResponse>(
      this.baseApiUrl + 'OLO_SigninwithPhoneV2SK',
      modal,
      { headers }
    );
  }

  /**
   * @description returns customer information directly from Punchh
   * @description should not be called directly
   * @returns {-{Observable} data stream containing response from SSO_SigninV2SK in the form of getLoyaltyCustomerResponse}
   * @param {-{string} email passed as payload parameter}
   * @param {-{string} password passed as payload parameter}
   * @todo move to API service and implement retries, create data model for API response, convert to promise
   */
  getPunchhCustomerExternal(email: string, password: string, phone: string) {
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();
    const headers = new HttpHeaders()
      .set('Version', '2')
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId);
    let modal = {
      email: email,
      password: password,
      phone: phone,
    };

    return this.http.post<getLoyaltyCustomerResponse>(
      this.baseApiUrl + 'SSO_SigninV2SK',
      modal,
      { headers }
    );
  }

  /**
   * @description controller function
   * @returns {-{Observable} data stream containing customer information in the form of getLoyaltyCustomerResponse
   * @param {-{string} phone passed as parameter to child functions
   * @param {-{string} email required for Punchh customers if phone number isn't found in grubbrr system. Used for first-time grubbrr visits from existing punchh customers
   * @param {-{string} password required for Punchh customers if phone number isn't found in grubbrr system. Used for first-time grubbrr visits from existing punchh customers
   * @todo convert subscriptions to promises and implement error handling for all child functions as a child function here
   */
  getLoyaltyCustomerByPhone(
    phone: string,
    email: string = '',
    password: string = '',
    isQr: boolean = false
  ) {
    if (this.loyaltyType == LoyaltyType.Referrizer) {
      return this.api.getReferrizerCustomerByPhone(phone);
    } else if (this.loyaltyType == LoyaltyType.LevelUp) {
      return this.api.getLevelUpCustomerByPhone(phone, isQr);
    } else if (this.loyaltyType == LoyaltyType.OLOPunchh) {
      if (isQr) {
        return this.api.oloPunchhQRLogin(phone);
      } else if (email == '' && password == '') {
        return this.api.getPunchhCustomer(phone);
      } else {
        return this.api.getPunchhCustomerExternal(email!, password!, phone);
      }
    } else if (this.loyaltyType == LoyaltyType.OLOPaytronix) {
      return this.api.getOLOPaytronixCustomer(email, password);
    } else {
      return; //need error handling
    }
  }

  /**
   * @description Controller function for retrieving loyalty rewards suitable for use with any loyaltyType
   * @implements {getReferrizerRewards( ) for referrizer users
   * @implements {getPunchhRewards( ), getValidationProductData( ), cartService.getItemsV2( ) for OLOPunchh users
   * @returns {-Promise containing data related to available rewards
   * @todo additional error handling
   */
  getLoyaltyRewards() {
    return new Promise<any>((resolve, reject) => {
      if (this.loyaltyType == LoyaltyType.Referrizer) {
        this.getReferrizerRewards().then(
          (data: ReferrizerLoyaltyRewardDetail[]) => {
            resolve(data);
          }
        );
      } else if (this.loyaltyType == LoyaltyType.LevelUp) {
        resolve(true); //need levelUp implementation
      } else if (this.loyaltyType == LoyaltyType.OLOPunchh) {
        let items = this.olo.getValidationProductData(
          this.cartService.getCartItems()
        );
        this.getPunchhRewards(items).then(
          (data: any) => {
            if (data) {
              resolve(data.data.rewards);
            } else {
              //user not signed in
              resolve(null);
            }
          },
          (err: any) => {
            // console.log('getPunchhRewards error: ', err);
            //need error handling
            resolve(null);
          }
        );
      } else if (this.loyaltyType == LoyaltyType.OLOPaytronix) {
        let items = this.olo.getValidationProductData(
          this.cartService.getCartItems()
        );
        this.getOLOPaytronixRewards(items).then((data: any) => {
          if (data) {
            resolve(data.data.rewards);
          } else {
            //user not signed in
            resolve(null);
          }
        });
      } else {
        //should never reach this block
        resolve(true); //need error handling
      }
    });
  }
  /**
   * @description checks for and returns user rewards from Paytronix
   * @returns response from OLO_BasketQualifyingRewardsV2S
   * @todo move to API service and implement retries, create data model for API response
   */
  oloPaytronixBasketRewardCheck() {
    let basket = {
      authtoken: this.userService.paytronixCustomer.authtoken,
      basketid: this.userService.paytronixCustomer.basketid,
      IsPaytronix: 1,
    };
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();
    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2');
    return this.http
      .post<any>(this.baseApiUrl + 'OLO_BasketQualifyingRewardsV2SK', basket, {
        headers,
      })
      .toPromise();
  }
  /**
   * @description generates basket and associates baskt with paytronix user if logged in, fetches rewards if applicable
   * @param items array of pre-formmatted items passed to oloPaytronixCreateBasket function
   * @returns response from OLO_BasketQualifyingRewardsV2S if user logged in or false otherwise
   * @todo create data model for API response
   */
  getOLOPaytronixRewards(items: OLOChainProduct[]) {
    return new Promise<any>((resolve, reject) => {
      this.oloPaytronixCreateBasket(items).then(
        (data: any) => {
          if (data.statusCode === '200' && data.data) {
            if (data.data.basket) {
              this.userService.paytronixCustomer.basketid = data.data.basket.id;
            }
            if (this.userService.paytronixCustomer.authtoken != '') {
              this.oloPaytronixBasketRewardCheck().then(
                (data) => {
                  if (data.status == 'success' && data.statusCode == '200') {
                    this.loyaltyRewards = data.data.rewards;
                    this.subLoyaltyRewards.next(this.loyaltyRewards);
                    resolve(data);
                  } else {
                    reject(data);
                  }
                },
                (err) => {
                  // console.log(err);
                  reject(err);
                }
              );
            } else {
              resolve(false);
            }
          }
        },
        (err) => {
          // console.log(err);
          if (err.status == '500') {
            reject(err);
          }
        }
      );
    });
  }
  /**
   * @description creates basket and checks basket for qualifying rewards
   * @implements {punchhBasketRewardCheck( ), oloPunchhCreateBasket(items)}
   * @returns {-{Promise} containing rewards if found, boolean false otherwise}
   * @param {-OLOChainProduct[ ] items used to create basket data }
   */
  getPunchhRewards(items: OLOChainProduct[]) {
    return new Promise<any>((resolve, reject) => {
      this.oloPunchhCreateBasket(items)
        .toPromise()
        .then(
          (data: any) => {
            if (data.statusCode === '200' && data.data) {
              if (data.data.basket) {
                this.userService.punchhLoyaltyCustomer.basketID =
                  data.data.basket.id;
              }
              if (this.userService.punchhLoyaltyCustomer.MembershipID != '') {
                this.oloPunchhBasketRewardCheck()
                  .toPromise()
                  .then(
                    (data) => {
                      if (
                        data.status == 'success' &&
                        data.statusCode == '200'
                      ) {
                        this.loyaltyRewards = data.data.rewards;
                        this.subLoyaltyRewards.next(this.loyaltyRewards);
                        resolve(data);
                      }
                    },
                    (err) => {
                      // console.log(err);
                    }
                  );
              } else {
                resolve(false);
              }
            }
          },
          (err) => {
            // console.log(err);
            if (err.status == '500') {
              reject(err);
            }
          }
        );
    });
  }
  /**
   * @description controller function to call points-based redemption for all loyalties, currently supports referrizer only as no other supported loyalty integrations are points-based
   * @param rewardId passed to redeemReferrizerPoints
   * @param itemId passed to redeemReferrizerPoints
   * @returns response from appropriate loyalty point redemption API
   * @todo convert parameters to object for ease of access with future loyalty integrations
   */
  redeemLoyaltyPoints(rewardId: string, itemId: string) {
    if (this.loyaltyType == LoyaltyType.Referrizer) {
      return this.redeemReferrizerPoints(rewardId, itemId);
    } else if (this.loyaltyType == LoyaltyType.LevelUp) {
      return; //need levelUp implementation
    } else {
      return; //need error handling
    }
  }
  /**
   * @description controller function to update points for all loyalties, currently supports referrizer only as no other supported loyalty integrations are points-based
   * @param points passed to updateReferrizerPoints
   * @returns response from appropriate loyalty point update API
   * @todo convert parameters to object for ease of access with future loyalty integrations
   */
  updateLoyaltyPoints(points: number) {
    if (this.loyaltyType == LoyaltyType.Referrizer) {
      return this.updateReferrizerPoints(points);
    } else if (this.loyaltyType == LoyaltyType.LevelUp) {
      return; //need levelUp implementation
    } else {
      return; //need error handling
    }
  }
  /**
   * @description controller function to get specific reward redemption details for all loyalties, currently supports referrizer only as no other supported loyalty integrations require specific reward checks
   * @description not used to fetch customer available rewards, used to confirm the customer selected reward is still available
   * @param rewardId passed to getCustomerReferrizerReward
   * @param phone passed to getCustomerReferrizerReward
   * @returns response from appropriate loyalty reward check API
   * @todo convert parameters to object for ease of access with future loyalty integrations
   */
  getCustomerLoyaltyReward(rewardId: string, phone: string) {
    if (this.loyaltyType == LoyaltyType.Referrizer) {
      return this.getCustomerReferrizerReward(rewardId, phone);
    } else if (this.loyaltyType == LoyaltyType.LevelUp) {
      return; //need levelUp implementation
    } else {
      return; //need error handling
    }
  }
  /**
   * @description creates a visit in the referrizer database for the customer and handles errors
   * @param orderTotal
   * @returns {-{Promise} containing boolean value corresponding to a successful visit creation
   * @todo move to API service and implement retries, add handling for error block
   */
  createReferrizerVisit(orderTotal: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!Object.keys(this.userService.referrizerLoyaltyUser).length) {
        resolve(true);
      } else {
        this.branchId = GeneralSetting.getBranchId();
        this.companyId = GeneralSetting.getCompanyId();
        const headers = new HttpHeaders()
          .set('CompanyID', this.companyId)
          .set('BranchID', this.branchId)
          .set('Version', '2.0');
        const visit = {
          contactId: this.userService.referrizerLoyaltyUser.id,
          id: this.userService.referrizerLoyaltyUser.id,
          amount: orderTotal,
        };
        this.http
          .post<customerVisitRes>(
            this.baseApiUrl + 'CreateCustomerVisitV3',
            visit,
            { headers }
          )
          .toPromise()
          .then(
            (res: any) => {
              if (res.statusCode == '200' && res.status == 'success') {
                this.customerVisitRes = res.data;
                resolve(true);
              } else {
                //error
              }
            },
            (err) => {
              var log = new loggingData(
                'Loyalty Visit Error',
                'Encountered Error on CreateCustomerVisitV3',
                `Error Loyalty`,
                `Encountered Error Creating Visit ${visit}: ${err}`,
                true
              );
              this.logger.sendLogToServer(log);
              resolve(true);
            }
          );
      }
    });
  }
  /**
   * @description fetches last visit date and customer details, used to confirm visit was successfully created
   * @returns response from GetLastVisitByCustomerV2SK
   * @todo move to API service and implement retries, create data model for API response
   */
  getReferrizerVisit() {
    if (
      this.userService.referrizerLoyaltyUser == null ||
      this.userService.referrizerLoyaltyUser.id == ''
    ) {
      return Promise.resolve({} as any);
    }

    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();

    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2.0');

    const visit = {
      contactId: this.userService.referrizerLoyaltyUser.id,
      id: this.userService.referrizerLoyaltyUser.id,
    };

    return this.http
      .post<any>(this.baseApiUrl + 'GetLastVisitByCustomerV2SK', visit, {
        headers,
      })
      .toPromise();
  }
  // /**
  //  * @description should not be called directly
  //  * @description fetches Paytronix customer with entered data. No other sign in calls used, paytronix does not use phone number
  //  * @param username username of the user to be fetched
  //  * @param password password of the user to be fetched
  //  * @todo convert to promise, move to API service and implement retries
  //  */
  // getOLOPaytronixCustomer(username: string, password: string) {
  //   this.branchId = GeneralSetting.getBranchId();
  //   this.companyId = GeneralSetting.getCompanyId();
  //   const headers = new HttpHeaders()
  //     .set('CompanyID', this.companyId)
  //     .set('BranchID', this.branchId);
  //   let body = {
  //     username: username,
  //     password: password,
  //   };

  //   return this.http.post<PaytronixCustomerResponse>(
  //     this.baseApiUrl + 'OLOPaytronixAuthenticateGuest',
  //     body,
  //     { headers }
  //   );
  // }
  /**
   * @description fetches Referrizer loyalty rewards
   * @returns {-{Promise} containing response from GetAllLoyaltyRewardsV2SK
   * @todo move to API service and implement retries
   */
  getReferrizerRewards() {
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();

    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2.0')
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http
      .post<ReferrizerLoyaltyRewardDetail[]>(
        this.baseApiUrl + 'GetAllLoyaltyRewardsV2SK',
        '',
        { headers }
      )
      .toPromise();
  }
  /**
   *
   * @param rewardId the ID of the reward being redeemed
   * @param itemId the ID of the item being redeemed
   * @returns {{-Promise} containing response from RedeemLoyaltyRewardV3
   * @todo move to API service and implement retries, create data model for API response
   */
  redeemReferrizerPoints(rewardId: string, itemId: string) {
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();

    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2.0');

    const lrp = {
      contactId: this.userService.referrizerLoyaltyUser.id,
      RewardID: rewardId,
      ItemID: itemId,
    };

    return this.http
      .post<any>(this.baseApiUrl + 'RedeemLoyaltyRewardV3', lrp, { headers })
      .toPromise();
  }
  /**
   * @description to refund points to a referrizer user
   * @param points the number of points to manually add to a referrizer user
   * @todo move to API service and implement retries
   */
  updateReferrizerPoints(points: number) {
    if (points <= 0) return;

    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();

    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId);

    const req = {
      contactId: this.userService.referrizerLoyaltyUser.id,
      pointsValue: points,
    };

    this.http
      .post<any>(this.baseApiUrl + 'UpdateLoyaltyPointsV3', req, { headers })
      .toPromise()
      .then((data) => {
        this.updateRewardSub.next(points);
      });

  }
  /**
   * @description confirms that user is able to redeem the rewrad. Some referrizer rewards can be used only `x` times per guest
   * @param rewardId ID of redeemed reward
   * @param phone phone number of guest redeeming the reward
   * @returns response from GetCustomerLoyaltyRewardV3
   * @todo convert to promise, move to API service and implement retries
   */
  getCustomerReferrizerReward(rewardId: string, phone: string) {
    if (
      this.userService.referrizerLoyaltyUser == null ||
      this.userService.referrizerLoyaltyUser.id == ''
    ) {
      return null;
    }
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();

    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2.0');

    const modal = {
      id: rewardId,
      contactID: this.userService.referrizerLoyaltyUser.id,
      email: '',
      phone: phone,
    };

    return this.http.post<ReferrizerLoyaltyRewardDetail>(
      this.baseApiUrl + 'GetCustomerLoyaltyRewardV3',
      modal,
      { headers }
    );
  }
  /**
   * @description should not be called directly
   * @description checks to see if current location has an active loyalty program
   * @returns Promise containing boolean value such that `true` if active loyalty program, else `false`
   */
  getLoyalty() {
    return new Promise<boolean>((resolve) => {
      var sqlStr =
        'SELECT IsActive FROM LoyaltyPrograms WHERE IsActive = "True"';

      const callback = (tx: string, data: any) => {
        if (data.rows.length > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      const error = (err: any) => {
        var log = new loggingData(
          'Loyalty Program Error',
          'Loyalty Program Encountered Error Fetching data',
          'Loyalty Error',
          `Loyalty Program Encountered Error: ${err}`,
          true
        );
        this.logger.sendLogToServer(log);
        resolve(false);
      };

      DatabaseHandler.executeSqlStatement(sqlStr, [], callback, error);
    });
  }
  /**
   * @description should not be called directly
   * @description checks company-mapped loyalty programs, sets loyaltyService.loyaltyInfo and
   * @returns Promise containing number corresponding to LoyaltyType
   */
  getLoyaltyType() {
    return new Promise<number>((resolve) => {
      var sqlStr = `SELECT TPLM.ThirdPartyLoyaltyID
      ,TPLCM.ThirdPartyLoyaltyID
      , TPLM.Name
      , TPLM.IsActive 
      FROM ThirdPartyLoyaltyMasters 
      AS TPLM 
      JOIN ThirdPartyLoyaltyCompanyMappings as TPLCM ON 
      TPLCM.ThirdPartyLoyaltyID=TPLM.ThirdPartyLoyaltyID
      WHERE TPLM.IsActive = 'True'
      `;
      const callback = (tx: string, data: any) => {
        if (data.rows.length > 0) {
          this.loyaltyInfo = data.rows;
          resolve(Number(this.loyaltyInfo[0].ThirdPartyLoyaltyID));
        }
      };
      const error = (err: any) => {
        var log = new loggingData(
          'Loyalty Program Error',
          'Loyalty Program Encountered Error Fetching data from local DB',
          'Loyalty Error',
          `Loyalty Program Encountered Error: ${err}`,
          true
        );
        this.logger.sendLogToServer(log);
        resolve(0);
      };
      DatabaseHandler.executeSqlStatement(sqlStr, [], callback, error);
    });
  }
  /**
   *
   * @param name name of the guest. This will be used to refer to the guest in frontend and to identify guest in backend
   * @param phone phoe number associated with guest. this will be used as primary key in Referrizer's databse, and will be used to lookup guest in Grubbrr's database
   * @returns response from CreateLoyaltyCustomerV3
   * @todo convert to promise, move to API service and implement retries
   */
  createReferrizerCustomer(name: string, phone: string) {
    this.branchId = GeneralSetting.getBranchId();
    this.companyId = GeneralSetting.getCompanyId();

    const headers = new HttpHeaders()
      .set('CompanyID', this.companyId)
      .set('BranchID', this.branchId)
      .set('Version', '2.0');

    const modal = {
      firstName: name,
      lastName: '',
      phone,
      email: '',
    };

    return this.http.post<getLoyaltyCustomerResponse>(
      this.baseApiUrl + 'CreateLoyaltyCustomerV3',
      modal,
      { headers }
    );
  }

  chosenItemList: ItemV2[] = [];

  itemsWaiting: boolean = false;
  /**
   * @description in Last5Orders flow, users can add items to the cart without selecting an order type. This leads to missing tax details
   * @description chosenItemList is used as a temporary holding area for items from Last5Orders orders added before order type is selected
   * @description removes items in chosenItemList screen before user selects an order type
   * @param item the item to be removed
   */
  removeFromCart(item: any) {
    const chosenItemList = [] as ItemV2[];

    for (let i = 0; i < this.chosenItemList.length; i++) {
      const cartItem = this.chosenItemList[i];

      if (JSON.stringify(cartItem) != JSON.stringify(item)) {
        if (cartItem.guid && cartItem.guid != item.guid) {
          chosenItemList.push(cartItem);
        } else {
          if (
            cartItem.ItemCategorySalesTypeID ===
            CategorySalesType.Alcohol.toString()
          ) {
            const curr = Number(GeneralSetting.getCurrentCountofAlkol());
            GeneralSetting.setCurrentCountofAlkol(
              curr - Number(cartItem.Quantity)
            );
          }
        }
      } else {
        if (
          cartItem.ItemCategorySalesTypeID ===
          CategorySalesType.Alcohol.toString()
        ) {
          const curr = Number(GeneralSetting.getCurrentCountofAlkol());
          GeneralSetting.setCurrentCountofAlkol(
            curr - Number(cartItem.Quantity)
          );
        }
      }
    }

    this.chosenItemList = chosenItemList;
  }
  /**
   * @description in Last5Orders flow, users can add items to the cart without selecting an order type. This leads to missing tax details
   * @description chosenItemList is used as a temporary holding area for items from Last5Orders orders added before order type is selected
   * @description adds all items from chosenItemList to the cart
   */
  async addToCart() {
    for (let i = 0; i < this.chosenItemList.length; i++) {
      let a = await this.cartService.createCartItemV2(this.chosenItemList[i]);
      await this.cartService.addToCartAsync(a, true, true);
    }
    this.chosenItemList = [];
    this.itemsWaiting = false;
  }
}
