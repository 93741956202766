import { ImagePopupComponent } from './../image-popup/image-popup.component';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-failed-images',
  templateUrl: './failed-images.component.html',
  styleUrls: ['./failed-images.component.css']
})
export class FailedImagesComponent implements OnInit,OnDestroy {
  languageSub!: Subscription;

  ref!: NgbModalRef;
  // get from component instance
  failedImages: string[] = [];
  onlineImages: string[] = [];

  retryText:string=''
  errorText:string=''
  continueText:string=''
  downloadFailureText:string=''

  @ViewChild(ImagePopupComponent) imagePopupComponent!: ImagePopupComponent;

  constructor(private modalService: NgbModal, private language:LanguageService) { }
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText(){
    this.continueText=this.language.getTextElement('lbl_continue')
    this.retryText=this.language.getTextElement('lbl_retry')
    this.errorText=this.language.getTextElement('error')
    let image = this.failedImages.length==1?this.language.getTextElement('image_singular'):this.language.getTextElement('image_plural')
    this.downloadFailureText=this.language.getTextElement('failed_to_download').replace('%d', this.failedImages.length.toString()).replace('%e',image )
  }
  ngOnInit(): void {
    this.languageSub=this.language.localeCommunicator.subscribe((val)=>{
      this.loadText()
    })
  }
  open(failedImages: string[], onlineImages: string[]) {
    this.ref = this.modalService.open(FailedImagesComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl'
    });
    this.ref.componentInstance.failedImages = failedImages;
    this.ref.componentInstance.onlineImages = onlineImages;
    this.loadText()
    return this.ref;
  }
  retry() {
    this.ref.close("retry");
  }
  continue() {
    this.ref.close("continue");
  }
  clickLink(i: number) {
    this.imagePopupComponent.open(this.onlineImages[i]);
  }
}
