<ng-container *ngIf="item">
    <app-box-builder-header [Item]="item" [isCombo]="item.IsCombo" [selectedTotalCount]="selectedCount"
      [itemImageList]="itemImageList" (itemModalClose)="closeModel($event)" [totalComboGroups]="totalComboGroups" [numInvisibleGroups]="numInvisibleGroups">
    </app-box-builder-header>
    <ng-container *ngIf="!item.IsCombo && modifiers && modifiers.length > 0">
      <app-box-builder-item-detail [comboGroups]="comboGroup" [modifiers]="modifiers" [isCombo]="item.IsCombo"
        [totalComboGroups]="totalComboGroups" [selectedTotalCount]="selectedCount" [numInvisibleGroups]="numInvisibleGroups"
        (isComboChanged)="isComboStateChanged($event)" (selectedCountEmitter)="getSelectedCount($event)"
        (itemImageListEmitter)="getItemImageList($event)" (modifierEmitter)="setSelectedModifiers($event)" [inputItem]="item" (openReviewScreenEmitter)="reviewScreenOpen($event)"
        (itemModalClose)="closeModel($event)" (comboItemFlagsEmitter)="getComboItemFlags($event)">
      </app-box-builder-item-detail>
    </ng-container>
  
    <ng-container *ngIf="!item.IsCombo && modifiers && modifiers.length > 0">
      <app-box-builder-item-footer [categoryID]="category.CategoryID" [isCombo]="item.IsCombo" class="footer" [isReady]="isReady" [isEdit]= "isFromEdit"
      [itemImageList]="itemImageList" [isComboItem]="isComboItem" [modifiers]="_selectedModifiers"
      [variations]="_selectedVariations" [comboGroup]="_selectedComboGroup" [itemID]="item.ItemID"
      [numInvisibleGroups]="numInvisibleGroups"

      [categoryID]="category.CategoryID" [isModifiersValid]="isModifiersValid" [isVariationsValid]="isVariationsValid"
      [isComboGroupValid]="isComboGroupValid" [inputItem]="item" [boxBuilderItemDetailModal]="boxBuilderItem"
      [quantityWhenAdd]="quantity" [cartQuantity]="cartQuantity" (itemModalClose)="closeModel($event)"
      [currentSelectedModifierIndex]="currentSelectedModifierIndex" [selectedTotalCount]="selectedCount"
      [totalComboGroups]="totalComboGroups" [comboItemFlags]="comboItemFlags"
      >
      </app-box-builder-item-footer>
    </ng-container>
  
  
  </ng-container>
  
  
  