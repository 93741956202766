import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { environment } from "../../environments/environment.prod";
import { IHttpHeaders } from "../services/http.service";
import { IsKioskService } from "../services/is-kiosk.service";


@Injectable()
export class postInterceptor implements HttpInterceptor {

  constructor(private isKiosk: IsKioskService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //if (!this.isKiosk.isKiosk()) {
      return next.handle(req);
    //}

    //let reqBody = null;

    //if (req.body) {
    //  let serializeBody = req.serializeBody();
    //  if (serializeBody != null && typeof (serializeBody) == 'string') {
    //    reqBody = JSON.parse(serializeBody);
    //  }
    //}

    //let httpHeader = req.headers as HttpHeaders;

    //var headers = this.getHeaderFromHttpHeader(httpHeader);

    //let url = environment.NodeUrl + 'ProxyBackendApiCall';

    //let method = req.method;

    //const nodeReqBody = {
    //  url: req.urlWithParams,
    //  body: reqBody,
    //  header: headers,
    //  method: method
    //};

    //const reqCopy = req.clone({
    //  body: nodeReqBody,
    //  url: url,
    //  headers:new HttpHeaders()
    //}) as HttpRequest<any>;
    
    //return next.handle(reqCopy);
  }


  private getHeaderFromHttpHeader(httpHeader: HttpHeaders) {
    let allHeader = httpHeader.keys();
    var headers = [] as IHttpHeaders[];

    if (allHeader.length > 0) {
      let key = "";
      let value = "";

      for (let i = 0; i < allHeader.length; i++) {

        key = allHeader[i];
        let values = httpHeader.getAll(allHeader[i]);

        if (values != null && values.length > 0) {
          value = values[0];

          headers.push({ key: key, value: value } as IHttpHeaders);
        }
      }
    }

    return headers;
  }
  
}
