<!-- --------------------------------------------------------- Secondary Page With Modifier Groups and Ingredients   ------------------------------------------------------>

<ng-container *ngIf="!isCombo && modifiers && modifiers.length > 0">
  <section class="zig-zag-bottom zig-zag-top" *ngIf="!isCombo">
    <div class="wizard-container wizard-container-initial">
      <div class="wizard-card" id="wizard">
        <form action="" method="">
          <div class="wizard-header">
          </div>
          <!----------------------------------     Modifier Groups     ---------------------------------------->
          <div class="duk-tab" #ModifierCategoryBar>
            <ul class="nav nav-tabs" role="tablist">

              <li class="nav-item" role="presentation" *ngFor="let modGrp of modifiers; index as i ">
                <a (click)="onModifierSelect(i)" aria-controls="coating" data-toggle="tab" role="tab" class="nav-link"
                  [ngClass]="{'active': i == modifierSelectedIndex}">
                  <img class="tab-img" src="{{ (modGrp.DeselectedImage && modGrp.DeselectedImage !='') ? baseImageUrl+modGrp.DeselectedImage: placeholderImage != '' ? baseImageUrl+placeholderImage :'/assets/images/Image_21.png'}}"
                    onerror="this.onerror=null; this.src='assets/images/box-builder/plain.png';" />
                  {{modGrp.Category}}
                </a>
              </li>

            </ul>
          </div>
          <ng-container *ngIf="modifiers[modifierSelectedIndex]">
            <div class="tab-content duk-tab-content">
              <div class="tab-pane active" id="coating" role="tabpanel">
                <div class="row">
                  <div class="col-sm-12">
                    <h4 class="info-text">{{ modifiers[modifierSelectedIndex].rule }}</h4>
                  </div>
                </div>
                <div class="choices h-27vh mul-choice">
                  <ng-container *ngFor="
                                let ingredient of modifiers[modifierSelectedIndex].Ingredients;
                                let i = index;
                            ">
                    <app-box-ingredient [parent]="modifiers[modifierSelectedIndex]" [ingredientIndex]="i"
                      (parentChange)="parentChangeIngredient($event)" [ingredient]="ingredient" [ItemName]="ItemName">
                    </app-box-ingredient>

                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="wizard-footer px-5">
            <div class="float-end"
              *ngIf="modifierSelectedIndex != modifiers.length-1">
              <button class='btn btn-next btn-fill btn-danger btn-wd' (click)="onNextClick()">
                {{nextText}} <i class="fas fa-arrow-right"></i>
              </button>

            </div>
            <div class="float-start" *ngIf="modifiers[modifierSelectedIndex].Category != modifiers[0].Category">
              <button class='btn btn-previous btn-fill btn-danger btn-wd' (click)="onPreviousClick()">
                <i class="fas fa-arrow-left"></i> {{previousText}}
              </button>
            </div>
            <div class="clearfix"></div>
          </div>
        </form>
      </div>
    </div>
  </section>
</ng-container>


<!-- Modals -->
<div style="display: none">
  <app-choose-donut-customize-popup>

  </app-choose-donut-customize-popup>
</div>
