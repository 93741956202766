import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../services/user.service';
import { LoyaltyService } from '../../../services/loyalty.service';
import {
  LoyaltyType,
  OLOPaytronixLoyaltyRewardDetail,
  PunchhLoyaltyRewardDetail,
  ReferrizerLoyaltyRewardDetail,
} from '../../../models/loyaltyModal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '../../../services/cart.service';
import { PaymentService } from '../../../services/payment.service';
import { ErrorMsgPopupComponent } from '../../../components/dialogs/error-msg-popup/error-msg-popup.component';
import { Snackbar } from '../../../services/common';
import { Guid } from '../../../models/Guid';
import { ItemV2 } from '../../../models/item';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DatabaseService } from 'src/app/services/database.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoadingComponent } from 'src/app/loading/loading.component';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LoaderService } from 'src/app/services/loader.service';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-redeem-reward',
  templateUrl: './redeem-reward.component.html',
  styleUrls: ['./redeem-reward.component.css'],
})
export class RedeemRewardComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  isKiosk = this.isKioskService.isKiosk();

  rewardId: any;
  Items!: ItemV2[];
  reward: any;
  user: any;
  displayBanked: boolean = false;
  availablePoints = 0;
  quantityDesired: string = '';
  maxUsable: string = '';
  loyaltyType: number = 0;
  modalReference: any;
  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;
  rewardString: string = ''
  pointsText: string = ''
  redeemRewardText: string = ''
  okText: string = ''
  closeText: string = ''
  redeemedRewardText: string = ''
  helloText: string = ''
  applyText: string = ''
  orderTotalTooLowText: string = ''
  private rewardBack = false;
  languageSub!: Subscription;
  placeholderImage: string = '';

  constructor(
    private isKioskService: IsKioskService,
    private readonly userService: UserService,
    private readonly loyaltyService: LoyaltyService,
    public modalService: NgbModal,
    public cartService: CartService,
    public paymentService: PaymentService,
    private KeyboardService: KeyboardService,
    private databaseService: DatabaseService,
    private language: LanguageService,
    private loader: LoaderService,
    private readonly dataService: DataService
  ) {

  }
  loadText() {
    this.pointsText = this.language.getTextElement('lbl_points')
    this.redeemRewardText = this.language.getTextElement('action_redeem_reward')
    this.okText = this.language.getTextElement('lbl_ok')
    this.closeText = this.language.getTextElement('close')
    this.applyText = this.language.getTextElement('apply')
    let texta = this.language.getTextElement('txt_you_have_b')
    let textb = this.language.getTextElement('txt_points_available_b')
    this.rewardString = '<h3 class="text-center">' +
      texta + this.availablePoints + textb +
      '</h3>'
    this.redeemedRewardText = this.language.getTextElement('you_have_received_a_reward_on_your_order').replace('%d', GeneralSetting.getCurrencySymbol() + this.reward)
    this.helloText = this.language.getTextElement('hello_user').replace('%d', this.user ?? '')
    this.user = this.userService.levelUpCustomer.name ?? '';
    this.orderTotalTooLowText = this.language.getTextElement('msg_small_order_total')
  }
  ngOnInit(): void {
    this.placeholderImage = this.dataService.getPlaceholderImage();
    this.availablePoints = this.userService.referrizerLoyaltyUser?.loyaltyPointsCount;
    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe(() => {
        this.loadText();
      })
    )
    this.loyaltyType = this.loyaltyService.loyaltyType;
    let tempReward;
    if (this.loyaltyType == LoyaltyType.Referrizer) {
      tempReward = this.loyaltyService.loyaltyRewards.find(
        (x) => x.id == this.rewardId
      );
      const reward = tempReward;
      this.reward = reward as ReferrizerLoyaltyRewardDetail;
      this.availablePoints =
        this.userService.referrizerLoyaltyUser.loyaltyPointsCount;
      if (this.reward) {
        this.loyaltyService
          .getCustomerLoyaltyReward(
            this.reward.id,
            this.userService.referrizerLoyaltyUser.phone
          )
          ?.toPromise()
          .then((res) => {
            this.rewardBack = true;
            if (res != null) {
            }
          });
      }
    } else if (this.loyaltyType == LoyaltyType.OLOPunchh) {
      tempReward = this.loyaltyService.loyaltyRewards.find(
        (x) => x.reference == this.rewardId
      );
      const reward = tempReward;
      if (reward && reward.reference == 'banked') {
        this.maxUsable = this.cartService.total.toString();
        if (Number(this.maxUsable) > reward.quantityavailable) {
          this.maxUsable = reward.quantityavailable.toString();
        }
      }
      this.reward = reward as PunchhLoyaltyRewardDetail;
    } else if (this.loyaltyType == LoyaltyType.OLOPaytronix) {
      tempReward = this.loyaltyService.loyaltyRewards.find(
        (x) => x.reference == this.rewardId
      );
      const reward = tempReward;
      this.reward = reward as OLOPaytronixLoyaltyRewardDetail;

    } else if (this.loyaltyType == LoyaltyType.LevelUp) {
      this.user = this.userService.levelUpCustomer.name;
      this.loadText()
    }

  }

  async getItemByReward() {
    let itemId = '';
    if (
      this.reward &&
      !this.reward.Item &&
      this.reward.MultiItemIDs &&
      this.reward.MultiItemIDs.length > 0
    ) {
      itemId = this.reward.MultiItemIDs[0];
    }

    if (itemId != null) {
      let itemList = await this.databaseService.getItemByItemIdFromSql(itemId);

      if (itemList && itemList.length > 0) {
        this.reward.item = itemList[0];
      }
    }

    return this.reward.item;
  }

  async selectItem(item: ItemV2) {
    if (!item) {
      item = await this.getItemByReward();
    }
    let discountItem = JSON.parse(JSON.stringify(item)) as ItemV2;
    discountItem.Quantity = '1';
    discountItem.DiscountPer = 100;
    discountItem.IsLoyaltyDiscountItem = true;
    discountItem.OrderMinimumQuantity = 1;
    discountItem.AllowQuantitySelection = 'False';
    if (this.loyaltyType == LoyaltyType.Referrizer) {
      this.close({ reward: this.reward?.id, item: discountItem });
    } else if (this.loyaltyType == LoyaltyType.OLOPunchh) {
      this.close({ reward: this.reward?.reference, item: discountItem });
    } else if (this.loyaltyType == LoyaltyType.OLOPaytronix) {
      this.close({ reward: this.reward?.reference, item: discountItem });
    }
  }

  open(reward: any, loyaltyType: number) {
    this.modalReference = this.modalService.open(RedeemRewardComponent, {
      backdrop: loyaltyType != 3 ? 'static' : undefined,
      size: 'lg',
      centered: true,
    });
    if (this.loyaltyType == LoyaltyType.Referrizer) {
      if (reward.Items && reward.Items.length > 0) {
        this.Items = JSON.parse(JSON.stringify(reward.Items));
        this.modalReference.componentInstance.Items = this.Items;
      }
      if (reward.id != undefined) {
        this.modalReference.componentInstance.rewardId = reward.id;
      }
    } else if (this.loyaltyType == LoyaltyType.OLOPunchh) {
      this.modalReference.componentInstance.rewardId = reward.reference;
      if (reward.reference == 'banked') {
        this.modalReference.componentInstance.displayBanked = true;
      }
    } else if (this.loyaltyType == LoyaltyType.OLOPaytronix) {
      this.modalReference.componentInstance.rewardId = reward.reference;
    } else if (this.loyaltyType == LoyaltyType.LevelUp) {
      this.modalReference.componentInstance.reward = reward;
    }

    this.modalReference.componentInstance.loyaltyType = Number(loyaltyType);
    this.modalReference.componentInstance.modalReference = this.modalReference;
  }
  close(msg: any = '') {
    this.modalReference.close(msg);
    this.removeKeyboard();
  }
  createOpenItem(reward: any, c: number = 0): ItemV2 {
    let price;
    if (this.loyaltyService.loyaltyType == LoyaltyType.OLOPunchh) {
      if (reward.reference == 'banked') {
        if (!(c > 0) && Number(this.quantityDesired) > Number(this.maxUsable)) {
          let err = this.language.getTextElement('not_enough_points')
          Snackbar.show(err, 4500);
          throw '';
        } else if (
          Number(reward.quantityavailable) > this.cartService.subtotal
        ) {
          if (!this.quantityDesired) {
            this.quantityDesired = String(this.cartService.subtotal);
          } else {
            if (this.quantityDesired[0] == GeneralSetting.getCurrencySymbol()) {
              this.quantityDesired = this.quantityDesired.slice(1);
            }
            if (Number(this.quantityDesired) > this.cartService.subtotal) {
              this.quantityDesired = this.cartService.subtotal.toString();
            }
          }
          price =
            c > 0
              ? -1 * this.cartService.subtotal
              : -1 * Number(this.quantityDesired);
        } else {
          price = -1 * Number(reward.quantityavailable);
        }
      } else {
        price = '0.00';
      }
    } else {
      price = '0.00';
    }
    let item = new ItemV2();
    item.AllowQuantitySelection = 'False';
    item.AttributeCount = 0;
    item.cartId = '';
    item.CategoryID = '';
    item.CategoryName = '';
    item.ComboGroupID = '';
    item.CommonModifierCount = 0;
    item.DiscountAmount = 0;
    item.DiscountID = 0;
    item.DiscountPer = 0;
    item.DiscountTypeID = 0;
    item.flattenedMods = '';
    item.FullDescription = '';
    item.GuestName = '';
    item.guid = '';
    item.IconImages = [];
    item.ImageUrl =
      reward.imageurl != undefined &&
        reward.imageurl != null &&
        reward.imageurl != ''
        ? reward.imageurl
        : '/assets/images/default-reward-icon.png';
    item.IsBuilderMode = '';
    item.IsCombo = false;
    item.IsDiscount = false;
    item.IsItemDetailScreenShowKiosk = 'False';
    item.IsLoyaltyDiscountItem = true;
    item.isSelected = true;
    item.IsUpSellItem = false;
    item.ItemCategorySalesTypeID = '';
    item.ItemID = '';
    item.ItemImage = '';
    item.ItemName = reward.label;
    item.ModifierCount = 0;
    item.Name = reward.label;
    item.openItem = true;
    item.OrderMaximumQuantity = 0;
    item.OrderMinimumQuantity = 0;
    item.Price = price.toString();
    item.Quantity = '1';
    item.specialRequest = '';
    item.TaxGroupId = 0;
    item.TaxPer = 0;
    item.TaxPercentage = 0;
    item.TotalTax = 0;
    item.totalPrice = Number(item.Price);
    item.UpSellItemGuid = '';
    item.UpSellItemID = '0';
    item.UpSellItemPrice = 0;
    item.Modifiers = [];
    item.Variations = [];
    item.ComboGroup = [];
    item.taxDetail = [];
    item.CurrentStock = '';
    item.LowThreshold = '';
    item.SoldOut = false;
    item.ExtraPrice = '0.00';
    item.KOTDisplayName;
    item.BackgroundImage = '';
    return item;
  }
  async onRedeemRewardClick(c: number = 0) {
    if (this.loyaltyType == LoyaltyType.OLOPunchh) {
      let item: ItemV2 = this.createOpenItem(this.reward, c);
      await this.selectItem(item);
    } else if (this.loyaltyType == LoyaltyType.OLOPaytronix) {
      this.userService.paytronixCustomer.membershipid = this.reward.membershipid
      let item: ItemV2 = this.createOpenItem(this.reward, c);
      await this.selectItem(item);
    } else {
      if (!this.rewardBack) {
        setTimeout(() => {
          this.onRedeemRewardClick();
        }, 3000);
        return;
      }
      if (this.reward != undefined) {
        let discountAmount = 0;
        let discountPer = 0;
        const subtotal = this.cartService.calculateSubtotal();

        if (this.reward?.type == 'CASH_DISCOUNT') {
          let amount = Number(this.reward?.value);
          if (amount > subtotal) {
            this.showErrorModel(
              this.orderTotalTooLowText
            );
            return;
          }
        }

        if (
          this.reward?.totalQuantity == '' ||
          this.reward?.totalQuantity == '0'
        ) {
          let err = this.language.getTextElement('reward_over_used')
          Snackbar.show(err, 2500);
          return;
        }

        if (
          this.reward?.perContactQuantity == '' ||
          this.reward?.perContactQuantity == '0'
        ) {
          let err = this.language.getTextElement('offer_unavailable')
          Snackbar.show(err, 2500);
          return;
        }

        if (
          this.reward?.expires &&
          this.reward?.startDate != '' &&
          this.reward?.expiredDate != '' &&
          !this.checkOfferIsActive(
            this.reward?.startDate,
            this.reward?.expiredDate
          )
        ) {
          let err = this.language.getTextElement('reward_over_used')
          Snackbar.show(err, 2500);
          return;
        }

        if (this.reward.type == 'ITEM') {
          await this.selectItem(this.reward.Items![0]);
        } else if (
          this.reward.type.toLowerCase() == 'PERCENT_DISCOUNT'.toLowerCase()
        ) {
          discountPer = Number(this.reward.value);
          discountAmount = (discountPer * subtotal) / 100;
        } else {
          discountAmount = Number(this.reward.value);
        }

        if (discountAmount <= subtotal) {
          this.loader.openLoader();

          this.loyaltyService
            .redeemLoyaltyPoints(this.reward?.id, this.reward?.ItemID)!
            .then(
              (data) => {
                this.loader.closeLoader();
                if (data.statusCode == '400') {
                  //error
                  this.close(data);
                } else if (data.statusCode == '200') {
                  this.cartService.promotionPercent = discountPer;
                  this.paymentService.DiscountAmount = discountAmount;
                  this.paymentService.DiscountPer = discountPer;
                  this.cartService.promotionIsTotal = true;
                  this.paymentService.DiscountId = this.reward.id;
                  this.close({ reward: this.reward?.id });
                  this.userService.referrizerLoyaltyUser.loyaltyPointsCount =
                    Number(this.userService.referrizerLoyaltyUser.loyaltyPointsCount) - Number(this.reward.points);
                  this.loyaltyService.redeemedRewardSub.next(this.reward?.points);
                }
              },
              () => {
                this.loader.closeLoader();
              }
            );
        } else {
          this.showErrorModel(
            this.orderTotalTooLowText
          );
        }
      } else {
        let err = this.language.getTextElement('reward_over_used')
        Snackbar.show(err, 2500);
        this.close('error');
      }
    }
  }

  // currencySymbol: string = '$';
  // checkPrefix() {
  //   if (this.quantityDesired != '') {
  //     if (this.quantityDesired[0] != this.currencySymbol) {
  //       this.quantityDesired = this.currencySymbol + this.quantityDesired;
  //     }
  //   }
  // }
  private showErrorModel(msg: string) {
    let errorModel = new ErrorMsgPopupComponent(this.modalService, this.language);
    errorModel.open(msg);
    this.close('error');
  }
  private checkOfferIsActive(startTime: string, endTime: string) {
    const currentDate = new Date();
    const st = new Date(startTime);
    const et = new Date(endTime);
    return currentDate > st && currentDate < et;
  }
  getRewardDesc() {
    if (this.reward != undefined && this.reward != null) {
      return this.reward?.type.toLowerCase() == 'item'
        ? this.reward.Items!.length > 1
          ? this.language.getTextElement('reward_one_free_from').replace('%d', this.reward.Items.length)
          : this.language.getTextElement('reward_one_free').replace('%d', this.reward.Items![0].DisplayName ?? this.reward.Items![0].Name)
        : this.reward?.type.toLowerCase() == 'PERCENT_DISCOUNT'.toLowerCase()
          ? this.language.getTextElement('reward_off').replace('%d', this.reward?.value + '%')
          : this.reward?.type.toLowerCase() == 'CASH_DISCOUNT'.toLowerCase()
            ? this.language.getTextElement('reward_off').replace('%d', GeneralSetting.getCurrencySymbol() + this.reward?.value)
            : '';
    }
    return '';
  }
  onPopupClose() {
    this.close('error');
  }
  createCartItem(item: any): any {
    return {
      guid: item.guid ? item.guid : Guid.newGuid(),
      ItemID: item.ItemID,
      ItemName: item.Name || item.ItemName,
      Name: item.Name || item.ItemName,
      FullDescription: item.FullDescription,
      Quantity: '1',
      ItemImage: item.ImageUrl ? item.ImageUrl : item.ItemImage,
      Price: String(Number(item.Price)),
      CategoryID: item.CategoryID || item.CategoryId,
      CategoryName: item.CategoryName,
      Mods: item.Mods ? item.Mods : item.associatedOptions,
      flattenedMods: item ? item.flattenedMods : [],
      Variations: item.variations,
      VariationOptions: item.VariationOptions || item.associatedVariations,
      cartId: 'test',
      OrderMinimumQuantity: item.OrderMinimumQuantity,
      OrderMaximumQuantity: item.OrderMaximumQuantity,
      specialRequest: '',
      IsAskForName: '',
      TotalTax: 0,
      TaxPer: 0,
      TaxPercentage: 0,
      DiscountAmount: item.DiscountAmount,
      DiscountPer: item.DiscountPer,
      Items: [] as ItemV2[],
      TaxGroupId: 0,
      ComboGroupID: '',
      DiscountID: 0,
      IsDiscount: false,
      DiscountTypeID: 0,
      IsUpSellItem: false,
      UpSellItemId: 0,
      UpSellItemGuid: '',
      UpSellItemPrice: 0,
      GuestName: '',
      Calorie: item.Calorie,
      MaxCalories: item.MaxCalories,
      ItemCategorySalesTypeID: item ? item.ItemCategorySalesTypeID : 0,
      isCombo: false,
      IsCombo: item.IsCombo,
      AttributeCount: item.AttributeCount,
      ModifierCount: item.ModifierCount,
      IsBuilderMode: item.IsBuilderMode,
      CommonModifierCount: item.CommonModifierCount,
      RefItemId: item.RefItemId,
      AllowQuantitySelection: 'True',
      IsLoyaltyDiscountItem: true,
      IsItemDetailScreenShowKiosk: item.IsItemDetailScreenShowKiosk,
    };
  }
  removeKeyboard() {
    this.KeyboardService.KeyboardStatus = false;
  }
}
