export interface paymentTypeData {
  PaymentTypeID: string,
  PaymentDisplayName: string,
  ThirdPartyPaymentID: string,
  PaymentTypeName: string,
  TenderId: string
}


export enum EPaymentType {
  notPaid = 0,
  Cash = 1,
  Card = 2,
  Partial = 3,
  Coupon = 4,
  BharatQR = 5,
  Discount = 6,
  Swiggy = 7,
  Zomato = 8,
  BankTransfer = 9,
  Cheque = 10,
  UPI = 11,
  Bitcoin = 12,
  HouseCredit = 13,
  SplitBill = 14,
  ThirdParty = 15,
  Tender = 16,
  GiftCard = 17,
  Merged = 18,
  USConnect = 19,
  EndofDayAutoClose = 20,
  IDCard = 21,
  LevelUp = 22,
  RFID = 23,
  VerifoneMaxicoEvo = 25,


}

export enum CardPaymentProcessor {
  Mosambee = 3,
  Freedompay = 7,
  GrubbrrPay = 14,
  Verifone = 17,
  VerifoneEvo = 24,
}
