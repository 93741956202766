import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { Snackbar } from 'src/app/services/common';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { loggingData, LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-table-tent-popup',
  templateUrl: './table-tent-popup.component.html',
  styleUrls: ['./table-tent-popup.component.css'],
})
export class TableTentPopupComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  windowHeaderText: string = GeneralSetting.getWindowHeaderText();
  defaultWindowHeaderText: string = '';
  tentNumberText: string = GeneralSetting.getTentNumberText() || 'Table #';
  defaultTentNumberText: string = '';
  windowText: string = GeneralSetting.getWindowText();
  defaultWindowText: string = '';
  windowTitle: string = GeneralSetting.getWindowTitle();
  defaultWindowTitle: string = '';
  tentImage: string = '';
  defaultTentImage: string = '';
  modalReference: any;
  showTipDialog = GeneralSetting.getShowTipDialog();
  tentControl!: FormControl;
  tentNumber: any = null;
  isInvalid: boolean = false;
  isKiosk = this.isKioskService.isKiosk();
  languageSub!: Subscription;
  keySub!:Subscription
  isCustomImage = false;
  baseImageUrl = this.isKiosk
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;
  constructor(
    public modalService: NgbModal,
    private formBuilder: FormBuilder,
    private KeyboardService: KeyboardService,
    private readonly isKioskService: IsKioskService,
    private language: LanguageService,
    private logger: LogService,
  ) {
    this.defaultTentImage = 'assets/images/ic_table_tent.png';
  }
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {
    this.defaultWindowText = this.language.getCustomMapValues(
      'menu_setting_display__order_table_tent_body_text'
    );
    if (this.defaultWindowText) {
      this.windowText = this.defaultWindowText;
    }
    this.defaultWindowHeaderText =
      this.language.getCustomMapValues('menu_setting_display__order_table_tent_header_text');
    if (this.defaultWindowHeaderText) {
      this.windowHeaderText = this.defaultWindowHeaderText;
    }
    this.defaultWindowTitle = this.language.getCustomMapValues('menu_setting_display__order_table_tent_title');
    if (this.defaultWindowTitle) {
      this.windowTitle = this.defaultWindowTitle;
    }
    this.defaultTentNumberText = this.language.getCustomMapValues('menu_setting_display__order_table_tent_number_text');
    if(this.defaultTentNumberText) {
      this.tentNumberText = this.defaultTentNumberText;
    }
  }
  ngOnInit(): void {
    if (GeneralSetting.getTentImage() == '') {
      this.tentImage = this.defaultTentImage;
    } else {
      this.tentImage = this.baseImageUrl + GeneralSetting.getTentImage();
      this.isCustomImage = true;
    }
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    if (GeneralSetting.getTableTentNumber() != '') {
      this.tentNumber = GeneralSetting.getTableTentNumber();
    }
  }
  doit2(){
    if(this.keySub){
      this.keySub.unsubscribe()
    }
    this.KeyboardService.newBoardSubject.next()
  }
  // to open keyboard based on dialog required layout, maxlength and assign value to dialog/screen input field @jay
  doit(){
    this.KeyboardService.manual=true
    this.KeyboardService.maxLength=3
    this.KeyboardService.clearSub.next()
    this.KeyboardService.open('numeric')
    this.KeyboardService.stringSub.next()
    this.keySub=this.KeyboardService.stringSub.subscribe((val)=>{
      this.handleTentChange(val)
    })
  }
  // to handle dialog/screen input field value as a first character and character after "." upercase
  handleTentChange(val:string){
    if(val.length>3){
      val=val.slice(0,3)
    }    
    if(val!=undefined){        
      this.modalReference.componentInstance.tentNumber=val
    }
    
  }
  public static open(modalService: NgbModal) {
    let modalReference = modalService.open(TableTentPopupComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.tentNumber =
      GeneralSetting.getTableTentNumber() != ''
        ? GeneralSetting.getTableTentNumber()
        : null;
    return modalReference;
  }

  open() {
    let modalReference = this.modalService.open(TableTentPopupComponent, {
      //backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.tentNumber =
      GeneralSetting.getTableTentNumber() != ''
        ? GeneralSetting.getTableTentNumber()
        : null;
    this.modalReference = modalReference;
  }
  canceled() {
    this.modalReference.close('canceled');
  }
  confirmTent() {
    if (
      this.tentNumber &&
      this.tentNumber.length &&
      this.tentNumber.length <= 3 &&
      this.tentNumber != 0
    ) {

      var log = new loggingData(
        `Order details: Table tent number`,
        `Order details: Table tent number`,
        `table-tent-popup.component.ts`,
        `Order details: Table tent number -  ${this.tentNumber}`,
        true
      );
      this.logger.sendLogToServer(log);

      GeneralSetting.setTableTentNumber(this.tentNumber);
      this.KeyboardService.KeyboardStatus = false;
      this.modalReference.close('confirmed');
    } else {
      let error=this.language.getTextElement('enter_valid_table_tent')
      Snackbar.show(error, 2000);
    }
  }
  returnToTip() {
    GeneralSetting.setIsTipSelected('false');
    this.KeyboardService.KeyboardStatus = false;
    this.modalReference.close('edit tip');
  }
}
