<div class="container">
  <span class="checkout">{{checkoutText|uppercase}}</span>
  <div *ngIf="!isContactForm">
    <span class="header"> {{contactDetailsText}} </span>
    <div class="horizontal">
      <div class="shipping-container" (click)="setDomestic()">
        <div class="ship-option" style="margin: 0 3.4rem 1.7rem 0" [ngClass]="isDomestic ? 'selected' : 'unselected'">
          <span class="option-text"> {{domesticText}} </span>
          <span class="option-text">
            +&nbsp;{{ domesticFee | number: "1.2-2" | CurrencyPipe }}
          </span>
        </div>
        <div
          *ngIf="isDomestic"
          style="margin-right: 3rem"
          class="pointy-thing"
        ></div>
      </div>
      <div class="shipping-container" (click)="setInternational()" >
        <div class="ship-option" style="margin: 0 0 1.7rem 3.4rem" [ngClass]="isDomestic ? 'unselected' : 'selected'">
          <span class="option-text"> {{internationalText}} </span>
          <span class="option-text">
            +&nbsp;{{ internationalFee | number: "1.2-2" | CurrencyPipe }}
          </span>
        </div>
        <div
          *ngIf="!isDomestic"
          style="margin-left: 3rem"
          class="pointy-thing"
        ></div>
      </div>
    </div>
    <div class="form">
      <form [formGroup]="formContact" (ngSubmit)="onContactSubmit()" novalidate>
        <div class="name">
          <label for="name">{{nameText}}</label>
          <input
            #nameforshipping
            KeyboardCustom
            [readonly]="isKiosk"
            (focus)="onOutsideFocus()"
            id="name"
            [ngStyle]="{ borderColor: isInvalid && !name?.valid ? 'red' : '' }"
            required
            class="form-control"
            placeholder="{{ placeHolderNameInfoHint }}"
            name="name"
            formControlName="name"
            [formControl]="$any(name)"
            maxlength="28"
          />
          <div class="error" *ngIf="!name?.valid && isInvalid">
            <div [hidden]="!name?.errors?.required">
              <p>{{nameRequiredText}}</p>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="code" *ngIf="!isDomestic">
            <label for="code">{{countryCodeText}}</label>
            <input
              KeyboardCustom
              [readonly]="isKiosk"
              layout="numeric" type="tel"
              (focus)="onOutsideFocus()"
              id="code"
              style="width: 15vw"
              [ngStyle]="{
                borderColor: isInvalid && !code?.valid ? 'red' : ''
              }"
              required
              [(ngModel)]="Code"
              (input)="onCodeChange()"
              mask="0||00||000"
              [showMaskTyped]="false"
              class="form-control"
              placeholder="{{ placeHolderCodeInfoHint }}"
              name="code"
              formControlName="code"
              [formControl]="$any(code)"
              maxlength="3"
            />
            <div class="error" *ngIf="!code?.valid && isInvalid">
              <div [hidden]="!code?.errors">
                <p>{{codeRequiredText}}</p>
              </div>
            </div>
          </div>
          <div class="phone">
            <label for="phone">{{phoneNumberText}}</label>
            <input
              KeyboardCustom
              [readonly]="isKiosk"
              layout="numeric" type="tel"
              id="phone"
              [ngStyle]="{
                borderColor: isInvalid && !phone?.valid ? 'red' : '',
                width: isDomestic ? '55vw' : '35vw'
              }"
              required
              (focus)="onOutsideFocus()"
              [(ngModel)]="Phone"
              (input)="onPhoneChange()"
              mask="000-000-0000"
              [showMaskTyped]="false"
              class="form-control"
              placeholder="{{ placeHolderPhoneInfoHint }}"
              name="phone"
              formControlName="phone"
              [formControl]="$any(phone)"
              maxlength="28"
            />
            <div class="error" *ngIf="!phone?.valid && isInvalid">
              <div [hidden]="!phone?.errors">
                <p>{{phoneRequiredText}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="email">
          <label for="email">{{emailText}}</label>
          <input
            KeyboardCustom
            [readonly]="isKiosk"
            (focus)="onOutsideFocus()"
            id="email"
            [ngStyle]="{ borderColor: isInvalid && !email?.valid ? 'red' : '' }"
            required
            class="form-control"
            placeholder="{{ placeHolderEmailInfoHint }}"
            name="email"
            formControlName="email"
            [formControl]="$any(email)"
            maxlength="28"
          />
          <div class="error" *ngIf="!email?.valid && isInvalid">
            <div [hidden]="!email?.errors?.required">
              <p>{{emailRequiredText}}</p>
            </div>
            <div [hidden]="!email?.errors?.pattern">
              <p>{{emailInvalidText}}</p>
            </div>
          </div>
        </div>
        <button class="continue" type="submit">{{nextText}}</button>
        <button class="back" (click)='cancel()'>{{backText}}</button>

      </form>
    </div>
</div>
    <div *ngIf="isContactForm">
      <span class="header"> {{addShippingAddressText}} </span>
      <form [formGroup]="formAddress" (ngSubmit)="onSubmit()" novalidate>
        <div class="address1" *ngIf="isDomestic">
          <label for="address1">{{addressLineOneText}}</label>
          <input
            KeyboardCustom
            [readonly]="isKiosk"
            id="address1"
            [ngStyle]="{
              borderColor: isInvalid && !addressOne?.valid ? 'red' : ''
            }"
            required
            (focus)="onOutsideFocus()"
            class="form-control"
            placeholder="{{ placeHolderAddressOneInfoHint }}"
            name="address1"
            formControlName="addressOne"
            [formControl]="$any(addressOne)"
          />
          <div class="error" *ngIf="!name?.valid && isInvalid">
            <div [hidden]="!name?.errors?.required">
              <p>{{addressRequiredText}}</p>
            </div>
          </div>
        </div>
        <div class="address2" *ngIf="isDomestic">
          <label for="address2">{{addressLineTwoText}}</label>
          <input
            KeyboardCustom
            [readonly]="isKiosk"
            (focus)="onOutsideFocus()"
            id="address2"
            class="form-control"
            placeholder="{{ placeHolderAddressTwoInfoHint }}"
            name="address2"
            formControlName="addressTwo"
            [formControl]="$any(addressTwo)"
          />
        </div>
        <div class="country" *ngIf="!isDomestic">
          <label for="country">{{countryText}}</label>
          <input
            KeyboardCustom
            [readonly]="isKiosk"
            id="country"
            [ngStyle]="{
              borderColor: isInvalid && !country?.valid ? 'red' : ''
            }"
            required
            (focus)="onCountryFocus()"
            class="form-control"
            name="country"
            (input)="onCountryChange()"
            [(ngModel)]="Country"
            formControlName="country"
            [formControl]="$any(country)"
          />
          <div *ngIf="focusCountry" class="dropdown">
            <p *ngIf="focusCountry && Country == ''" class="dropdown-element">
              {{typeYourCountryText}}
            </p>
            <div
              *ngFor="let item of filteredCountryList; let i = index"
              class="dropdown-element"
            >
              <p (click)="chooseCountry(item)">{{ item.CountryName }}</p>
            </div>
          </div>
          <div class="error" *ngIf="!country?.valid && isInvalid">
            <div [hidden]="!country?.errors?.required">
              <p>{{countryRequiredText}}</p>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="state">
            <label for="state">{{stateText}}</label>
            <input
              KeyboardCustom
              [readonly]="isKiosk"
              id="state"
              [ngStyle]="{
                borderColor: isInvalid && !state?.valid ? 'red' : ''
              }"
              required
              (focus)="onStateFocus()"
              style="width: 25vw"
              class="form-control"
              (input)="onStateChange()"
              [(ngModel)]="State"
              placeholder="{{ placeHolderStateInfoHint }}"
              name="state"
              formControlName="state"
              [formControl]="$any(state)"
            />
            <div *ngIf="focusState" class="dropdown">
              <p *ngIf="focusState && State == ''" class="dropdown-element">
                {{typeStateText}}
              </p>
              <div
                *ngFor="let item of filteredStateList; let i = index"
                class="dropdown-element"
              >
                <p (click)="chooseState(item)">{{ item.StateName }}</p>
              </div>
            </div>
            <div class="error" *ngIf="!state?.valid && isInvalid">
              <div [hidden]="!state?.errors?.required">
                <p>{{stateRequiredText}}</p>
              </div>
            </div>
          </div>

          <div class="zipCode" *ngIf="isDomestic">
            <label for="zipCode">{{zipCodeText}}</label>
            <input
              KeyboardCustom
              [readonly]="isKiosk"
              id="zipCode"
              [ngStyle]="{
                borderColor: isInvalid && !zipCode?.valid ? 'red' : ''
              }"
              mask="00000"
              [showMaskTyped]="false"
              required
              (focus)="onOutsideFocus()"
              style="width: 18vw"
              class="form-control"
              placeholder="{{ placeHolderZipCodeInfoHint }}"
              name="zipCode"
              formControlName="zipCode"
              [formControl]="$any(zipCode)"
            />
            <div class="error" *ngIf="!zipCode?.valid && isInvalid">
              <div [hidden]="!zipCode?.errors?.required">
                <p>{{zipRequiredText}}</p>
              </div>
            </div>
          </div>
          <div class="postCode" *ngIf="!isDomestic">
            <label for="postCode">{{postCodeText}}</label>
            <input
              KeyboardCustom
              [readonly]="isKiosk"
              id="postCode"
              [ngStyle]="{
                borderColor: isInvalid && !postCode?.valid ? 'red' : ''
              }"
              (focus)="onOutsideFocus()"
              required
              style="width: 18vw"
              class="form-control"
              name="postCode"
              formControlName="postCode"
              [formControl]="$any(postCode)"
            />
            <div class="error" *ngIf="!postCode?.valid && isInvalid">
              <div [hidden]="!postCode?.errors?.required">
                <p>{{postCodeRequiredText}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="city" *ngIf="isDomestic">
          <label for="city">{{cityText}}</label>
          <input
            KeyboardCustom
            [readonly]="isKiosk"
            id="city"
            [ngStyle]="{ borderColor: isInvalid && !city?.valid ? 'red' : '' }"
            required
            class="form-control"
            (focus)="onCityFocus()"
            (input)="onCityChange()"
            [(ngModel)]="City"
            placeholder="{{ placeHolderCityInfoHint }}"
            name="city"
            formControlName="city"
            [formControl]="$any(city)"
          />
          <div *ngIf="focusCity" class="dropdown">
            <p *ngIf="focusCity && City == ''" class="dropdown-element">
              {{typeCityText}}
            </p>
            <div
              *ngFor="let item of filteredCityList; let i = index"
              class="dropdown-element"
            >
              <p (click)="chooseCity(item)">{{ item.CityName }}</p>
            </div>
          </div>
          <div class="error" *ngIf="!city?.valid && isInvalid">
            <div [hidden]="!city?.errors?.required">
              <p>{{cityRequiredText}}</p>
            </div>
          </div>
        </div>

        <div class="address" *ngIf="!isDomestic">
          <label for="address">{{addressText}}</label>
          <textarea
            KeyboardCustom
            [readonly]="isKiosk"
            (focus)="onOutsideFocus()"
            id="address"
            [ngStyle]="{
              borderColor: isInvalid && !address?.valid ? 'red' : ''
            }"
            style="height: 22rem"
            required
            class="form-control"
            name="address"
            placeholder="{{ placeHolderAddressInfoHint }}"
            formControlName="address"
            [formControl]="$any(address)"
          >
          </textarea>
          <div class="error" *ngIf="!address?.valid && isInvalid">
            <div [hidden]="!address?.errors?.required">
              <p>{{addressRequiredText}}</p>
            </div>
          </div>
        </div>

        <div class="footer">
          <p class="fee" *ngIf="isDomestic && domesticFee != '0.00'">
            {{domesticFeeText}}
          </p>
          <p class="fee" *ngIf="!isDomestic && internationalFee != '0.00'">
            {{internationalFeeText}}
          </p>
          <button class="continue2" type="submit">{{submitText}}</button>
        </div>
      </form>
      <button class="back" (click)="toContact()">{{backText}}</button>
    </div>
</div>
