import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { element } from 'protractor';
import { loggingData, LogService } from 'src/app/services/log.service';
import { SettingsItem } from '../../../models/settingsItem';
import { Category } from '../../../models/category';
import { DatabaseHandler } from '../../../DatabaseHandler';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { DatabaseService } from 'src/app/services/database.service';
import { CommonFunctions } from 'src/app/services/common';
import { DataService } from 'src/app/services/data.service';
import { Console, log } from 'console';
import { LoadingComponent } from 'src/app/loading/loading.component';
import { reject } from 'lodash';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-settings-items',
  templateUrl: './settings-items.component.html',
  styleUrls: ['./settings-items.component.css'],
})
export class SettingsItemsComponent implements OnInit,OnDestroy {


  combinedItems: SettingsItem[] = [];
  settingsItems: SettingsItem[] = [];
  categories: Category[] = [];
  isChecked = false;
  categoryChecked: boolean = false;
  languageSub!: Subscription;
  manageText: string = '';
  selectAllText: string = '';
  categoryText: string = '';
  itemText: string = '';
  displayItemMenuText: string = '';
  printText: string = '';
  @Output() updateMenu = new EventEmitter<boolean>();

  constructor(
    private logger: LogService,
    private db: DatabaseService,
    private dataservice: DataService,
    private language: LanguageService,
    private loader:LoaderService
  ) {}
  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }
  loadText() {
    this.manageText=this.language.getTextElement('txt_manage_category_amp_items')
    this.selectAllText=this.language.getTextElement('txt_select_all')
    this.categoryText=this.language.getTextElement('category')
    this.itemText=this.language.getTextElement('item')
    this.displayItemMenuText=this.language.getTextElement('txt_display_item_menu')
    this.printText=this.language.getTextElement('lbl_print')
  }
  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });
    this.loadData();
    this.isChecked =
      GeneralSetting.getIsSelectAllCategory() === 'False' ? false : true;
  }

  loadData() {
    this.settingsItems = [];
    this.categories = [];
    Promise.all([this.getData(), this.getCategoryData()]).then((values) => {
      this.settingsItems = values[0];
      this.categories = values[1];
      this.combineData(this.settingsItems, this.categories);
    });
    this.updateMenu.emit(true);
  }
  getCategoryData(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const DataCallBack = (tx: any, result: any): void => {
        for (var i = 0; i < result.rows.length; i++) {
          // use name for display and ID to identify category
          var category: Category = {
            Name: result.rows[i].Name,
            ImageUrl: '',
            CategoryID: result.rows[i].CategoryID,
            items: [],
            CategoryIcon: '',
            DeselectCategoryIcon: '',
            isActive: this.toBool(result.rows[i].IsActive),
            HideHeaderText: result.rows[i].HideHeaderText,
            DisplayCategory: this.toBool(result.rows[i].DisplayCategory),
            CurrentStock: '',
            LowThreshold: '',
            SoldOut: false,
            IconImages: [],
            ItemTags: [],
            ShowasClassicModifierGroup: '',
            LabelforItemSelection: '',
          };
          this.categories.push(category);
        }

        resolve(this.categories);
      };

      const ErrorCallBack = (tx: any, result: any): void => {
        console.log(
          'setting item Component Error getting Category data from sql ',
          result.message
        );
      };

      const dayId = await this.db.getDayId(CommonFunctions.getDayName());
      var sqlString = `
        SELECT CM.Name , CM.CategoryID , CM.IsActive, CM.HideHeaderText, CM.DisplayCategory FROM CategoryMasters CM CROSS JOIN CategoryDayWiseMasters CD ON
        CM.CategoryID = CD.CategoryID AND CD.DayID = '${dayId}' WHERE CM.IsActive = "True" 
        AND CD.StartTime <= time('${CommonFunctions.getCurrentTime()}') 
        AND CD.EndTime >= time('${CommonFunctions.getCurrentTime()}') 
        AND CD.IsActive = 'True'
        AND CM.IsShowOnKiosk = 'True' ORDER BY CM.Name
        `;

      DatabaseHandler.executeSqlStatement(
        sqlString,
        [],
        DataCallBack,
        ErrorCallBack
      );
    });
  }

  isActive(category: Category): boolean {
    for (var i = 0; i < category.items.length; i++) {
      if (category.items[i].isActive) {
        return true;
      }
    }
    return false;
  }

  // combine the list of categories with each individual item and place individual item under its respective category in list
  combineData(settingsItems: SettingsItem[], categories: Category[]) {
    var k = 0;

    // for each category
    for (var i = 0; i < this.categories.length; i++) {
      for (var j = k; j < settingsItems.length; j++) {
        if (
          Number(categories[i].CategoryID) ===
          Number(settingsItems[j].categoryID)
        ) {
          this.categories[i].items.push(this.settingsItems[j]);
          k++;
        }
      }
    }
  }

  toBool(string: string) {
    if (string === 'True') {
      return true;
    } else {
      return false;
    }
  }

  // get data for item
  getData(): Promise<any> {
    return new Promise((resolve, reject) => {
      const DataCallBack = (tx: any, result: any): void => {
        // for each item from DB
        for (var i = 0; i < result.rows.length; i++) {
          // the category name will be blank so we don't display category name for each item, but we use ID to identify what category it belongs to
          var item: SettingsItem = {
            categoryID: result.rows[i].CategoryID,
            category: '',
            name: result.rows[i].ItemName,
            hideHeaderText: result.rows[i].HideHeaderText,
            // covert string boolean from DB to regular boolean
            isActive: this.toBool(result.rows[i].IsActive),
            ItemID: result.rows[i].ItemID,
          };
          this.settingsItems.push(item);
        }
        resolve(this.settingsItems);
      };

      const ErrorCallBack = (tx: any, result: any): void => {
        console.log(
          'Setting Item , Error Getting Item Data from sql',
          result.message
        );

        reject();
      };

      // Join item table with category to find which item belongs to respective category
      var itemSQLstring = `
                          SELECT X.Name as ItemName , X.IsActive , Z.Name as CategoryName, Z.CategoryID, Z.DisplayCategory, X.ItemID
                          FROM Items X
                          INNER JOIN Item_Category_Mappings Y
                          ON X.ItemID = Y.ItemID
                          INNER JOIN CategoryMasters Z
                          ON Y.CategoryID = Z.CategoryID
                          ORDER BY CategoryName
                          `;

      DatabaseHandler.executeSqlStatement(
        itemSQLstring,
        [],
        DataCallBack,
        ErrorCallBack
      );
    });
  }

  // capatalize first letter in a string
  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  private setItemStatusInBackend(itemID: string, isActive: boolean) {
    return new Promise<boolean>((resolve, reject) => {
      this.loader.openLoader();

      this.dataservice
        .updateItemStatus(itemID, isActive)
        .toPromise()
        .then(
          (data: any) => {
            /***
             * Success on api
             */
            this.loader.closeLoader();
            resolve(true);
            //console.log("Response:", data);
          },
          (response: any) => {
            this.loader.closeLoader();
            var log = new loggingData(
              'Update Item Status Error',
              'Encountered Error on Updating Item Status',
              `Error`,
              `Encountered Error With Item ID ${itemID}:${response}`,
              true
            );
            this.logger.sendLogToServer(log);
            resolve(true);
          }
        );
    });
  }

  // when check input is pressed for individual items
  async checkValue(
    item: SettingsItem,
    category: Category,
    event: any,
    isCategorySelected: boolean,
    isActive: boolean
  ) {
    /**
     * Update item status to backend
     */
    if (event.currentTarget) {
      isActive = event.currentTarget.checked ?? false;
    }

    await this.setItemStatusInBackend(item.ItemID, isActive);

    /***************************** */
    var sqlString = '';
    const DataCallBack = (tx: any, results: any) => {};

    const ErrorCallBack = (tx: any, results: any) => {
      console.log(
        'Setting Item , Error updating item data in sql ',
        results.message
      );
    };

    // update active status in database to user selection
    if (isCategorySelected) {
      // To enable or disable all items on parent selections
      sqlString =
        "UPDATE Items SET IsActive = '" +
        this.capitalizeFirstLetter(String(isActive)) +
        "' WHERE ItemID = '" +
        item.ItemID +
        "'";
      item.isActive = isActive;
    } else {
      if (!category.DisplayCategory) {
        // To enable parent if select atleast single item
        var event: any;
        event.currentTarget.checked = true;
        this.checkCategory(category, event, item);
      } else {
        // To enable single item on its selection
        var tmpIsActive = !item.isActive;
        item.isActive = tmpIsActive;
        sqlString =
          "UPDATE Items SET IsActive = '" +
          this.capitalizeFirstLetter(String(tmpIsActive)) +
          "' WHERE ItemID = '" +
          item.ItemID +
          "'";
      }
    }

    DatabaseHandler.executeSqlStatement(
      sqlString,
      [],
      DataCallBack,
      ErrorCallBack
    );
  }

  // when user checks a full category
  async checkCategory(
    category: Category,
    event: any,
    item?: SettingsItem | null
  ) {
    const DataCallBack = (tx: any, results: any) => {};

    const ErrorCallBack = (tx: any, results: any) => {
      console.log(
        'Setting Item , Error updating Category data to sql',
        results.message
      );
    };

    category.DisplayCategory = event.currentTarget.checked;
    let isActive = event.currentTarget.checked;

    if (item == null) {
      for (var i = 0; i < category.items.length; i++) {
        await this.checkValue(
          category.items[i],
          category,
          event,
          true,
          isActive
        );
      }
    } else {
      await this.checkValue(item, category, event, false, isActive);
    }

    let currentCategory = this.categories.find(
      (x) => x.CategoryID == category.CategoryID
    );
    if (currentCategory) {
      currentCategory.DisplayCategory = isActive;
    }

    var sqlString = `UPDATE CategoryMasters SET DisplayCategory = '${this.capitalizeFirstLetter(
      String(isActive)
    )}' WHERE CategoryID = '${category.CategoryID}'`;
    DatabaseHandler.executeSqlStatement(
      sqlString,
      [],
      DataCallBack,
      ErrorCallBack
    );
  }

  // select all is checked
  selectAll(isChecked: boolean) {
    this.isChecked = isChecked;
    GeneralSetting.setIsSelectAllCategory(isChecked ? 'True' : 'False');
    const DataCallBack = (tx: any, results: any) => {};
    const ErrorCallBack = (tx: any, results: any) => {
      console.log(
        'Setting Item ,Error selecting data when select all is checked',
        results.message
      );
    };

    //update every item in DB
    var sqlString =
      "UPDATE Items SET IsActive = '" +
      this.capitalizeFirstLetter(String(isChecked)) +
      "'";
    DatabaseHandler.executeSqlStatement(
      sqlString,
      [],
      DataCallBack,
      ErrorCallBack
    );
    sqlString =
      "UPDATE CategoryMasters SET DisplayCategory = '" +
      this.capitalizeFirstLetter(String(isChecked)) +
      "'";
    DatabaseHandler.executeSqlStatement(
      sqlString,
      [],
      DataCallBack,
      ErrorCallBack
    );
    // for each category
    for (var i = 0; i < this.categories.length; i++) {
      // set active status of category to check mark active status
      this.categories[i].DisplayCategory = isChecked;

      // for each item in category
      for (var j = 0; j < this.categories[i].items.length; j++) {
        this.categories[i].items[j].isActive = isChecked;
      }
    }
  }
}
