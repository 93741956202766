import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from 'src/app/services/payment.service';
import { Subscription, timer } from 'rxjs';
import { PaymentTypesService } from 'src/app/services/payment-types.service';
import { EPaymentType, paymentTypeData } from 'src/app/models/payment-type';
import { CartService } from 'src/app/services/cart.service';
import { OLOService } from '../../services/olo.service';
import { OLOResponse } from '../../models/OLOResponse';
import { GeneralSetting } from '../../services/generalSetting.service';
import { CurrencyPipe } from '@angular/common';
import { request } from 'http';
import { HttpClient } from '@angular/common/http';
import { resolve } from 'dns';
import { CommonFunctions, Snackbar } from '../../services/common';
import { loggingData, LogService } from 'src/app/services/log.service';
import { OloApplyRemoveDiscountPopupComponent } from '../dialogs/olo-apply-remove-discount-popup/olo-apply-remove-discount-popup.component';
import { OloErrorPopupComponent } from '../dialogs/olo-error-popup/olo-error-popup.component';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { ParBrinkService } from '../../services/par-brink.service';
import { OracleService } from 'src/app/services/oracle.service';
import { OracleMessage } from 'src/app/models/OracleModels';
import {
  Integration,
  IntegrationService,
} from 'src/app/services/integration.service';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { LanguageChoice, LanguageService } from 'src/app/services/language.service';
import { LoyaltyType } from 'src/app/models/loyaltyModal';

@Component({
  selector: 'app-payment-popup',
  templateUrl: './payment-popup.component.html',
  styleUrls: ['./payment-popup.component.css'],
  // animations: []
})
export class PaymentPopupComponent implements OnInit, OnDestroy {
  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  languageSub!: Subscription;
  keySub!:Subscription

  private allSubsCription: Subscription[] = [];

  @Input() subtotal: any;

  @Input() tax: number = 0;

  @Input() total!: number;

  @Input() tip: number = 0;
  @Input() reward: number = 0;
  //took second variable to calculate total based on card up charge amount
  discount: number = 0;

  cardTotal: number = 0.0;
  hasLoyaltyReward: boolean = false;
  oloFee: number = 0;

  showTipDialog: string = '';

  //for bottom text
  nonCashFeeText: string = '';

  //calculate upcharge amount
  upChargeAmountCard: number = 0.0;

  //value from backend settings[start]
  upChargeCardKioskAmount: string = '';

  usePercentageCardKIOSK: string = '';

  //value from backend settings[end]

  paymentTypes: paymentTypeData[] = [];

  modalReference: any;

  isOLO: boolean = false;

  oloValidationRes: OLOResponse | null = null;

  priceColor: string | null = '';

  promoInput: string = '';

  oloDiscountLoader!: OloApplyRemoveDiscountPopupComponent;

  positiveButton: string = '';

  positiveButtonText: string = '';

  isDiscountApplied = false;

  discountErrorMessage = '';

  isParBrink: boolean = false;

  isOracle: boolean = false;

  isOracleMicross: boolean = false;

  oracleCalcualtion: OracleMessage | null = null;

  integrationID: number = 0;

  taxType: string = '';

  checkoutText: string = '';

  paymentMethodText: string = '';
  paymentMethodTypeText: string = '';

  subTotalText: string = '';

  discountText: string = '';

  normalTaxText: string = '';

  tipText: string = '';

  totalText: string = '';

  vatText: string = '';

  serviceFeeText: string = '';

  taxesAndFees: string = ''

  totalDueText: string = '';

  removeText: string = '';

  applyText: string = '';

  editTipText: string = '';

  applyDiscountText: string = '';

  cancelText: string = '';

  backToCartText: string = '';

  rewardText: string = '';

  paymentChosen: boolean = false;

  constructor(
    private readonly modalService: NgbModal, // payment popup
    private readonly paymentTypeService: PaymentTypesService,
    private readonly paymentService: PaymentService,
    private readonly cartService: CartService,
    private readonly olo: OLOService,
    private readonly logger: LogService,
    private KeyboardService: KeyboardService,
    private parbrink: ParBrinkService,
    private oracle: OracleService,
    private integration: IntegrationService,
    private loyalty: LoyaltyService,
    private language: LanguageService
  ) {}

  ngOnInit(): void {
    this.basicDataSet();

    this.allSubsCription.push(
      this.paymentTypeService.getPaymentTypes().subscribe((data) => {
        data.forEach((x) => {
          if (x.PaymentTypeID == EPaymentType.LevelUp.toString()) {
            x.PaymentDisplayName = 'Pay with Loyalty App';
          }

          if(x.PaymentTypeID == EPaymentType.Card.toString() && this.language.languageChoice != LanguageChoice.English) {
            x.PaymentDisplayName = this.language.getTextElement('txt_pay_with_card');
          }
        });
        this.paymentTypes = data;
      })
    );

    this.calculationSubscriber();

    this.calculateCardTotalForUpCharge();

    this.calculateOloServiceFee();

    this.allSubsCription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }

  private basicDataSet() {
    this.integrationID = this.integration.integration;

    this.isParBrink = this.integrationID == Integration.Parbrink;

    this.isOLO = this.olo.isOLO;

    this.hasLoyaltyReward = this.loyalty.selectedLoyaltyReward != '';

    this.isOracle = this.oracle.isOracle;

    this.isOracleMicross = this.oracle.isOracleMicross;

    this.oracleCalcualtion = this.oracle.calculationResponse;

    this.showTipDialog = GeneralSetting.getShowTipDialog();

    this.upChargeCardKioskAmount = GeneralSetting.getUpChargeCardKIOSKAmount();

    this.usePercentageCardKIOSK = GeneralSetting.getUsePercentageCardKIOSK();

    this.taxType = GeneralSetting.getTaxType().toLowerCase();

    this.oloValidationRes = this.olo.validationRes;

    this.priceColor = GeneralSetting.getPriceColor();

    this.positiveButton = GeneralSetting.getPositiveColor();

    this.positiveButtonText = GeneralSetting.getPositiveColorText();

    this.tip = this.paymentService.TotalTip;
  }

  loadText() {
    this.checkoutText = this.language.getTextElement('lbl_checkout');
    this.paymentMethodText = this.language.getTextElement(
      'select_your_payment_method_to_complete_your_order'
    );

    this.subTotalText = this.language.getTextElement('lbl_sub_total');
    this.discountText = this.language.getTextElement('text_discount');
    this.normalTaxText = this.language.getTextElement('txt_tax');
    this.tipText = this.language.getTextElement('txt_tip');
    this.totalText = this.language.getTextElement('txtt_total');
    this.totalDueText = this.language
      .getTextElement('is_the_total_due')
      .replace('%d', '');
    this.removeText = this.language.getTextElement('txt_remove');
    this.applyText = this.language.getTextElement('apply');
    this.editTipText = this.language.getTextElement('edit_tip');
    this.cancelText = this.language.getTextElement('lbl_button_cancel');
    this.backToCartText = this.language.getTextElement('lbl_back_to_cart');
    this.rewardText = this.language.getTextElement('reward');
    this.serviceFeeText = this.language.getTextElement('lbl_service_fee');
    this.taxesAndFees = this.language.getTextElement('lbl_taxes_and_fees');
    this.vatText = this.language.getTextElement('lbl_vat');
    this.applyDiscountText = this.language.getTextElement(
      'apply_a_discount_code'
    );
  }

  public static open(
    modalService: NgbModal,
    total: number,
    tax: number,
    subTotal: string,
    discount: string,
    reward: number,
    paymentTypes: any
  ): any {
    const modalReference = modalService.open(PaymentPopupComponent, {
      backdrop: 'static',
      size: 'xl',
      centered: true,
      modalDialogClass: 'even-pad10',
    });
    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.subtotal = CommonFunctions.roundDigit(
      Number(subTotal),
      2
    ).toFixed(2);

    modalReference.componentInstance.discount = CommonFunctions.roundDigit(
      Number(discount),
      2
    ).toFixed(2);
    modalReference.componentInstance.reward = CommonFunctions.roundDigit(
      Number(reward),
      2
    ).toFixed(2);
    modalReference.componentInstance.tax = CommonFunctions.roundDigit(
      Number(tax),
      2
    ).toFixed(2);

    modalReference.componentInstance.total = CommonFunctions.roundDigit(
      Number(total),
      2
    ).toFixed(2);

    modalReference.componentInstance.cardTotal = CommonFunctions.roundDigit(
      Number(total),
      2
    ).toFixed(2);
    modalReference.componentInstance.paymentTypes = paymentTypes;

    return modalReference;
  }

  doit2(){
    if(this.isOLO && !(this.discount > 0) && !this.hasLoyaltyReward){
      this.KeyboardService.manual = false;
      if(this.keySub){
        this.keySub.unsubscribe()
      }
      this.KeyboardService.newBoardSubject.next()
      this.KeyboardService.KeyboardStatus = false;
    }
  }

  
  // used to open keyboard based on dialog required layout, maxlength and assign value to dialog/screen input field @jay
  doit(){
    if(this.isOLO && !(this.discount > 0) && !this.hasLoyaltyReward){
      this.KeyboardService.manual=true
      this.KeyboardService.maxLength=350
      this.KeyboardService.clearSub.next()
      this.KeyboardService.open("shift");
      this.KeyboardService.stringSub.next()
      this.keySub=this.KeyboardService.stringSub.subscribe((val)=>{
        this.handlepromoInputChange(val);
      })
    }
  }
  // to handle dialog/screen input field value as a first character and character after "." upercase
  handlepromoInputChange(val:string){
    this.modalReference.componentInstance.promoInput=val
    if(this.modalReference.componentInstance.promoInput == "" || (this.modalReference.componentInstance.promoInput).trim().slice(-1) == "."){
      this.KeyboardService.handleupperlowercase.next(true)
    }else{
      this.KeyboardService.handleupperlowercase.next(false)
    }
  }
  
  private calculationSubscriber() {
    this.allSubsCription.push(
      this.cartService.sub_reward.subscribe((data) => {
        this.reward = CommonFunctions.roundDigit(data, 2);
      })
    );
    this.allSubsCription.push(
      this.cartService.sub_subtotal.subscribe((data) => {
        this.subtotal = CommonFunctions.roundDigit(data, 2);
      })
    );

    this.allSubsCription.push(
      this.cartService.sub_total.subscribe((data) => {
        if (this.integrationID != Integration.OLO) {
          this.total = CommonFunctions.roundDigit(data, 2);
        }
      })
    );

    this.allSubsCription.push(
      this.cartService.sub_tax.subscribe((data) => {
        this.tax = CommonFunctions.roundDigit(data, 2);
      })
    );

    this.allSubsCription.push(
      this.cartService.sub_discount.subscribe((data) => {
        if (this.integrationID != Integration.OLO) {
          this.discount = CommonFunctions.roundDigit(data, 2);
        } else if (this.loyalty.loyaltyType == LoyaltyType.OLOPunchh) {
          this.discount = this.olo.validationRes.basketData.discount;
        } else if (this.loyalty.loyaltyType == LoyaltyType.OLOPaytronix) {
          this.discount = this.olo.validationRes.basketData.discount;
        }
      })
    );
  }

  calculateOloServiceFee() {
    if (this.integrationID == Integration.OLO) {
      this.oloFee = this.olo.validationRes?.basketData?.totalfees;
    }
  }

  editTip() {
    this.modalReference.close('goBack');
  }

  selectedPayment(event: any): void {
    if (this.paymentChosen) {
      return;
    }
    this.paymentChosen = true;
    this.KeyboardService.KeyboardStatus = false;
    if (
      this.loyalty.isLoyalty &&
      this.integration.integration == Integration.Micros3700 &&
      event.PaymentTypeID == EPaymentType.LevelUp &&
      GeneralSetting.getTPLoyaltyPayment() == ''
    ) {
      this.modalReference.close('Error: 1');
    } else {
      sessionStorage.setItem('PaymentDisplayName', event.PaymentDisplayName);
      if (GeneralSetting.getParBrinkIntegrationID() != '') {
        GeneralSetting.setTPTenderId(event.TenderId);
      }
      if (event.PaymentTypeID == '2') {
        this.paymentService.setUpchargeCardData(
          this.upChargeCardKioskAmount != null &&
            Number(this.upChargeCardKioskAmount) > 0.0,
          this.usePercentageCardKIOSK == 'True',
          Number(this.upChargeCardKioskAmount),
          this.upChargeAmountCard
        );
      } else {
        this.paymentService.setUpchargeCardData();
      }
      this.modalReference.close(event);
    }
  }

  canceled() {
    if (this.isDiscountApplied) {
      this.removeOloDiscount(true);
    } else {
      this.dismissPopup();
    }
  }

  dismissPopup() {
    this.cartService.setTip(0);
    GeneralSetting.setIsTipSelected('false');
    this.modalReference.close('canceled');
  }

  calculateCardTotalForUpCharge() {
    let total = this.getTotal(
      this.isOLO,
      this.oloValidationRes,
      this.total,
      this.isOracle,
      this.oracleCalcualtion
    );
    if (
      this.upChargeCardKioskAmount != null &&
      Number(this.upChargeCardKioskAmount) > 0.0
    ) {
      if (this.usePercentageCardKIOSK == 'True') {
        this.upChargeAmountCard = Number(
          ((Number(this.upChargeCardKioskAmount) / 100) * total).toFixed(2)
        );
        this.cardTotal = Number(
          CommonFunctions.roundDigit(
            Number(total) + Number(this.upChargeAmountCard),
            2
          ).toFixed(2)
        );
        this.nonCashFeeText = this.language
          .getTextElement('upcharge_fee_message')
          .replace('%d', this.upChargeCardKioskAmount);
      } else {
        this.upChargeAmountCard = Number(this.upChargeCardKioskAmount);
        this.cardTotal = Number(
          CommonFunctions.roundDigit(
            Number(total) + Number(this.upChargeAmountCard),
            2
          ).toFixed(2)
        );
        this.nonCashFeeText = this.language
          .getTextElement('upcharge_non_cash_fee_message')
          .replace(
            '%d',
            GeneralSetting.getCurrencySymbol() +
              ' ' +
              this.upChargeCardKioskAmount
          );
      }
    } else {
      this.cardTotal = Number(
        CommonFunctions.roundDigit(Number(total), 2).toFixed(2)
      );
    }
  }

  applyOrRemoveOloDiscount() {
    if (this.promoInput && this.promoInput != '') {
      if (this.isDiscountApplied) {
        this.removeOloDiscount();
      } else {
        this.applyOloDiscount();
      }
    } else {
      this.discountErrorMessage = this.language.getTextElement(
        'please_enter_discount_code'
      );
    }
    this.KeyboardService.KeyboardStatus = false;
  }

  applyOloDiscount() {
    this.oloDiscountLoader = OloApplyRemoveDiscountPopupComponent.open(
      this.modalService,
      true
    ).componentInstance;

    const basketId = this.olo.validationRes.data
      ? this.olo.validationRes.data.basketid
        ? this.olo.validationRes.data.basketid
        : ''
      : '';

    const invoiceNo = this.paymentService.getRandomOrderNo();

    this.olo.doApplyDiscount(basketId, this.promoInput, '0', invoiceNo).then(
      (data: OLOResponse) => {
        try {
          const oloOrderUpError = new loggingData(
            'Olo Apply Discount Res ',
            'OLO Apply Discount Res',
            'Response Log',
            JSON.stringify(data),
            false
          );

          this.logger.sendLogToServer(oloOrderUpError);
        } catch (e) {}

        if (data && data.result && data.result.toLowerCase() == 'success') {
          this.isDiscountApplied = true;
          this.discountErrorMessage = '';
          //Need to again validate order to fetch proper tax and totals
          this.validateOLOOrder();
        } else if (
          (data && data.result && data.result.toLowerCase() == 'fail') ||
          (data && data.result && data.result.toLowerCase() == 'error')
        ) {
          this.oloDiscountLoader?.clear();
          this.isDiscountApplied = false;
          // Snackbar.show(data.message, 2500);
          this.discountErrorMessage = this.language.getTextElement(
            'invalid_code_try_again'
          );
        } else {
          this.oloDiscountLoader?.clear();
          this.isDiscountApplied = false;
          // Snackbar.show('Some Error Occur. Please contact store manager ', 2500);
          this.discountErrorMessage =
            this.language.getTextElement('error_see_manager');
        }
      },
      (err: any) => {
        this.oloDiscountLoader?.clear();
        let error = this.language.getTextElement('invalid_code_try_again');
        Snackbar.show(error, 2500);
      }
    );
  }

  async removeOloDiscount(shouldClosePaymentPopup: boolean = false) {
    this.oloDiscountLoader = OloApplyRemoveDiscountPopupComponent.open(
      this.modalService,
      false
    ).componentInstance;
    const basketId = this.olo.validationRes.data
      ? this.olo.validationRes.data.basketid
        ? this.olo.validationRes.data.basketid
        : ''
      : '';

    const invoiceNo = this.paymentService.getRandomOrderNo();

    this.olo.doApplyDiscount(basketId, this.promoInput, '1', invoiceNo).then(
      (data: OLOResponse) => {
        try {
          const oloOrderUpError = new loggingData(
            'Olo Remove Discount Res ',
            'OLO Remove Discount Res',
            'Response Log',
            JSON.stringify(data),
            false
          );

          this.logger.sendLogToServer(oloOrderUpError);
        } catch (e) {}

        if (this.olo.validationRes.result.toLowerCase() == 'success') {
          //Need to again validate order to fetch proper tax and totals
          this.isDiscountApplied = false;
          this.promoInput = '';
          this.cartService.discount = 0;

          this.validateOLOOrder(shouldClosePaymentPopup);
        } else if (
          this.olo.validationRes.result.toLowerCase() == 'fail' ||
          this.olo.validationRes.result.toLowerCase() == 'error'
        ) {
          this.oloDiscountLoader?.clear();
          Snackbar.show(this.olo.validationRes.message, 2500);
        } else {
          this.oloDiscountLoader?.clear();
          let error = this.language.getTextElement('error_see_manager');
          Snackbar.show(error, 2500);
        }
      },
      (err: any) => {
        this.oloDiscountLoader?.clear();
        let error = this.language.getTextElement('invalid_code_try_again');
        Snackbar.show(error, 2500);
      }
    );
  }

  private async validateOLOOrder(shouldClosePaymentPopup: boolean = false) {
    const cartItems = this.cartService.getCartItems();

    const customerName =
      this.paymentService.CustomerName == null ||
      this.paymentService.CustomerName == ''
        ? 'Guest'
        : this.paymentService.CustomerName;

    let defaultGuestEmail = this.language.getTextElement('txt_guest_email');
    const customerEmail =
      this.paymentService.CustomerEmail == null ||
      this.paymentService.CustomerEmail == ''
        ? defaultGuestEmail
        : this.paymentService.CustomerEmail;

    this.olo
      .getOloValidation(
        cartItems,
        customerName,
        customerEmail,
        this.paymentService.TotalTip,
        this.paymentService.getRandomOrderNo(),
        this.loyalty.loyaltyType,
        this.loyalty.oloPaytronixAppliedRewardID != ''
      )
      .then((data: OLOResponse) => {
        // this.oloDiscountLoader.clear();
        this.olo.validationRes = data;
        this.oloValidationRes = this.olo.validationRes;
        this.calculateOloServiceFee();
        try {
          const oloOrderUpError = new loggingData(
            'Olo Validation Res ',
            'OLO Validation Res',
            'Response Log',
            JSON.stringify(data),
            false
          );

          this.logger.sendLogToServer(oloOrderUpError);
        } catch (e) {}

        if (this.olo.validationRes.result.toLowerCase() == 'success') {
          this.oloDiscountLoader?.clear();
          this.total = this.olo.validationRes.data.total;
          this.tax = this.olo.validationRes.data.tax;

          this.discount = this.olo.validationRes.basketData.discount;
          this.cartService.sub_discount.next(this.discount);
          if (this.discount > 0) {
            this.cartService.promotionIsTotal = true;
            this.cartService.promotionAmount = this.discount;
            if (
              this.olo.validationRes.basketData.discounts &&
              this.olo.validationRes.basketData.discounts.length > 0
            ) {
              this.cartService.promo.Name =
                this.olo.validationRes.basketData.discounts[0].description;
            }
            this.cartService.discount = this.discount;
          } else {
            this.resetDiscountValues();
          }
          this.modalReference.componentInstance.total =
            CommonFunctions.roundDigit(Number(this.total), 2).toFixed(2);
          this.cardTotal = CommonFunctions.roundDigit(Number(this.total), 2);
          this.calculateCardTotalForUpCharge();

          if (shouldClosePaymentPopup) {
            this.dismissPopup();
          }
        } else if (
          this.olo.validationRes.result.toLowerCase() == 'fail' ||
          this.olo.validationRes.result.toLowerCase() == 'error'
        ) {
          this.oloDiscountLoader?.clear();
          OloErrorPopupComponent.open(
            this.modalService,
            this.olo.validationRes.message
          );
        } else {
          this.oloDiscountLoader?.clear();
          let error = this.language.getTextElement('error_see_manager');
          OloErrorPopupComponent.open(this.modalService, error);
        }
      })
      .catch((x) => {
        this.oloDiscountLoader?.clear();
      });
  }

  resetDiscountValues() {
    this.cartService.promotionIsTotal = false;
    this.cartService.promotionAmount = 0;
    this.cartService.discount = 0;
    this.cartService.sub_discount.next(this.cartService.discount);
    this.cartService.promo.Name = '';
  }

  getTotal(
    isOlo: boolean,
    oloValidation: OLOResponse | null,
    baseTotal: number,
    isOracle: boolean = false,
    calculationResponse: OracleMessage | null = null
  ) {
    if (isOlo) {
      if (
        oloValidation != null &&
        oloValidation.data != undefined &&
        oloValidation.data
      ) {
        return oloValidation.data.total;
      } else {
        return 0.0;
      }
    } else if (isOracle && calculationResponse != null) {
      return Number(calculationResponse.TotalsTotalDue);
    } else {
      return baseTotal;
    }
  }

  getSubTotal(
    isOlo: any,
    oloValidation: any,
    baseSubtotal: any,
    isOracle: any = false,
    calculationResponse: OracleMessage | null,
    isOracleMicross: any,
    reward: any
  ) {
    if (isOlo) {
      if (
        oloValidation != null &&
        oloValidation.data != undefined &&
        oloValidation.data
      ) {
        return oloValidation.data.subtotal;
      } else {
        return 0.0;
      }
    } else if (isOracle && calculationResponse != null) {
      if (isOracleMicross) {
        return Number(calculationResponse.TotalsSubTotal) + reward;
      }
      if (Number(calculationResponse.DiscAmountOrPercent) > 0) {
        return Number(calculationResponse.TotalsSubTotalWithDiscount);
      }

      return Number(calculationResponse.TotalsSubTotal);
    } else {
      return baseSubtotal;
    }
  }

  getTax(
    isOlo: boolean,
    oloValidation: OLOResponse | null,
    baseTax: number,
    isOracle: boolean = false,
    calculationResponse: OracleMessage | null = null
  ) {
    if (isOlo) {
      if (
        oloValidation != null &&
        oloValidation.data != undefined &&
        oloValidation.data
      ) {
        return oloValidation.data.tax;
      } else {
        return 0.0;
      }
    } else if (isOracle && calculationResponse != null) {
      return Number(calculationResponse.TotalsTaxTotals);
    } else {
      return baseTax;
    }
  }
}
