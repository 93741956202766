import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { DatabaseHandler } from '../DatabaseHandler';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { environment } from 'src/environments/environment';
import { PaymentCredential } from '../models/BranchLoginModels';
import { StaffModuleMaster } from '../models/BranchLoginModels';
import { HttpConnection, IHttpHeaders } from './http.service';
import { IsKioskService } from './is-kiosk.service';
import { IVerifoneGeneralRes, IVerifoneTransactionRes } from '../models/VerifoneResponse';
import { PaymentErrorDialogService } from '../components/dialogs/payment-error/payment-error-dialog.service';
import { LanguageService } from './language.service';
import { PaymentInsertService } from '../components/dialogs/payment-insert/payment-insert.service';
import { loggingData, LogService } from './log.service';
import { PaymentService } from './payment.service';
import { CommonFunctions } from './common';
import { PaymentSuccessDialogService } from '../components/dialogs/payment-success/payment-success-dialog.service';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root',
})
export class VerifoneService {
  authTokenURL: string = 'https://cst1.test-vam.vfims.com/oauth2/realms/root/realms/VerifoneServices/access_token';
  verifoneURL: string = 'https://cst2.test-gsc.vfims.com/oidc/poscloud/nexo';
  verifoneRefundPaymentApi: string = 'RefundVerifonePayment';

  isKiosk: boolean = true;

  private lastTransactionCounter = 0;

  private isPaymentConfigured: boolean = false;

  verifoneErrorResponseCodes: any[] = [
    '43',
    '44',
    '55',
    '61',
    '68',
    '30001',
    '30002',
    '30003',
    '30004',
    '30005',
    '23',
    '24',
    '74',
    '59024',
    '59025',
    '59026',
  ];

  constructor(private http: HttpClient,
    private kioskService: IsKioskService,
    private paymentErrorDialog: PaymentErrorDialogService,
    private paymentInsertModal: PaymentInsertService,
    private readonly paymentSuccessModel: PaymentSuccessDialogService,
    private language: LanguageService,
    private readonly logger: LogService,
    private paymentService: PaymentService,
    private loader: LoaderService
  ) {
    this.isKiosk = kioskService.isKiosk();
  }

  public increasePaymentCounter() {
    return CommonFunctions.increaseVerifonePaymentCounter();
  }

  getAuthToken(obj: any) {
    let body = {
      url: obj.url,
      username: obj.username,
      secret: obj.secret,
    };

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post(environment.NodeUrl + 'proxy', JSON.stringify(body), {
        headers,
        responseType: 'text',
      })
      .pipe(catchError(this.errorHandler));
  }

  getStatus(authToken: string, url: string) {
    const body = { authToken: authToken, url: url };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(environment.NodeUrl + 'status', JSON.stringify(body), {
        headers,
        responseType: 'text',
      })
      .pipe(catchError(this.errorHandler));
  }

  getStatusV2(authToken: string, url: string) {
    const body = { authToken: authToken, url: url };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(environment.NodeUrl + 'verifoneStatus', JSON.stringify(body), {
        headers,
        responseType: 'text',
      })
      .pipe(catchError(this.errorHandler));
  }

  doPayment(authToken: string, amount: string, paymentId: string, url: string) {
    const body = { authToken: authToken, amount: amount, paymentId: paymentId, url: url };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(environment.NodeUrl + 'payment', JSON.stringify(body), {
        headers,
        responseType: 'text',
      })
      .pipe(catchError(this.errorHandler));
  }

  doPaymentV2(authToken: string, amount: string, paymentId: string, url: string) {
    const body = { authToken: authToken, amount: amount, paymentId: paymentId, url: url };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(environment.NodeUrl + 'paymentV2', JSON.stringify(body), {
        headers,
        responseType: 'text',
      })
      .pipe(catchError(this.errorHandler));
  }

  getPaymentCredentialsFromSql(): Promise<PaymentCredential> {
    return new Promise((resolve, reject) => {
      const query = 'SELECT * FROM PaymentCredentials';

      function callback(tx: string, data: any) {
        if (data.rows && data.rows.length > 0) {
          resolve(data.rows[0]);
        }
        else {
          resolve({} as PaymentCredential);
        }
      }

      function errorCallback(tx: string, data: any) {
        resolve({} as PaymentCredential);
      }

      DatabaseHandler.executeSqlStatement(query, [], callback, errorCallback);
    });
  }

  //getPaymentDevice(): Observable<string> {
  //  return from(this.getPaymentDeviceFromSql());
  //}

  getPaymentDeviceFromSql(): Promise<StaffModuleMaster> {
    return new Promise((resolve, reject) => {
      const query = `SELECT hsn FROM StaffModuleMasters WHERE DeviceID = '${GeneralSetting.getSerialNo()}'`;

      function callback(tx: string, data: any) {
        if (data.rows && data.rows.length > 0) {
          resolve(data.rows[0]);
        }
        else {
          resolve({} as StaffModuleMaster);
        }
      }

      function errorCallback(tx: string, data: any) {
        resolve({} as StaffModuleMaster);
      }
      DatabaseHandler.executeSqlStatement(query, [], callback, errorCallback);
    });
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message);
  }

  // Verifone Offline 
  getKeyPairFromServer() {
    // let url = "https://localhost:44367/Home/GenerateKeysV2";
    const url = environment.apiUrl + "CryptoGenerateKeysRSA";

    const headers = new HttpHeaders()
      .set('CompanyID', GeneralSetting.getCompanyId())
      .set('BranchID', GeneralSetting.getBranchId())
      .set('Version', '2.0');

    return this.http.post(url, '', { headers })
      .pipe(catchError(this.errorHandler));
  }

  getDecryptWithPrivateKey() {

    return this.http.post<any>(environment.NodeUrl + 'doDecryptVerifoneMacKey', {
      macKey: GeneralSetting.getVerifoneMacKey(),
    }).pipe(catchError(this.errorHandler));
  }

  connectAndRegisterVerifoneClient(body: any) {
    return this.http
      .post(environment.NodeUrl + 'verifoneRegisterDevice', body)
      .pipe(catchError(this.errorHandler));
  }

  testMac() {

    const currentCounter = this.increasePaymentCounter();

    const body = {
      message: GeneralSetting.getVerifoneDecryptedMessage(),
      counter: currentCounter,
      macLable: GeneralSetting.getVerifoneMacLabel()
    };

    return this.http.post<any>(environment.NodeUrl + 'testMacVerifone', body)
      .pipe(catchError(this.errorHandler));
  }

  startSession(invoiceNo: string) {
    const currentCounter = this.increasePaymentCounter();

    const body = {
      message: GeneralSetting.getVerifoneDecryptedMessage(),
      counter: currentCounter,
      macLable: GeneralSetting.getVerifoneMacLabel(),
      Invoice: invoiceNo.substr(invoiceNo.length - 6),
      posIp: GeneralSetting.getVerifonePosIp()
    };

    return this.http
      .post<any>(environment.NodeUrl + 'doStartVerifoneSession', body)
      .pipe(catchError(this.errorHandler));
  }

  doOfflineTransaction(amount: string) {
    const currentCounter = this.increasePaymentCounter();

    const body = {
      message: GeneralSetting.getVerifoneDecryptedMessage(),
      counter: currentCounter,
      macLable: GeneralSetting.getVerifoneMacLabel(),
      amount: amount
    };

    return this.http
      .post<any>(environment.NodeUrl + 'doVerifoneTransaction', body)
      .pipe(catchError(this.errorHandler));
  }

  finishSession() {
    const currentCounter = this.increasePaymentCounter();

    const body = {
      message: GeneralSetting.getVerifoneDecryptedMessage(),
      counter: currentCounter,
      macLable: GeneralSetting.getVerifoneMacLabel()
    };

    return this.http
      .post<any>(environment.NodeUrl + 'doEndVerifoneSession', body)
      .pipe(catchError(this.errorHandler));
  }

  getCounter() {
    const currentCounter = this.increasePaymentCounter();

    const body = {
      message: GeneralSetting.getVerifoneDecryptedMessage(),
      counter: currentCounter,
      macLable: GeneralSetting.getVerifoneMacLabel()
    };

    return this.http
      .post<any>(environment.NodeUrl + 'doVerifoneCounter', body)
      .pipe(catchError(this.errorHandler));
  }

  getQueryVerifoneOfflineTransaction() {
    const currentCounter = this.increasePaymentCounter();

    const body = {
      message: GeneralSetting.getVerifoneDecryptedMessage(),
      counter: currentCounter,
      macLable: GeneralSetting.getVerifoneMacLabel()
    };

    return this.http
      .post<any>(environment.NodeUrl + 'doQueryVerifoneOfflineTransaction', body)
      .pipe(catchError(this.errorHandler));
  }

  refundTransaction(amount: string, ctrouid: string) {
    const currentCounter = this.increasePaymentCounter();

    const body = {
      message: GeneralSetting.getVerifoneDecryptedMessage(),
      counter: currentCounter,
      macLable: GeneralSetting.getVerifoneMacLabel(),
      transactionId: ctrouid,
      amount: amount.trim()
    };

    return this.http
      .post<any>(environment.NodeUrl + 'doRefundVerifoneTransaction', body)
      .pipe(catchError(this.errorHandler));
  }

  unRegisterDevice() {
    const currentCounter = this.increasePaymentCounter();

    const body = {
      counter: currentCounter,
      macLable: GeneralSetting.getVerifoneMacLabel(),
    };

    return this.http
      .post<any>(environment.NodeUrl + 'doUnRegisterDevice', body)
      .pipe(catchError(this.errorHandler));
  }

  getLastTransaction() {
    const currentCounter = this.increasePaymentCounter();

    const body = {
      message: GeneralSetting.getVerifoneDecryptedMessage(),
      counter: currentCounter,
      macLable: GeneralSetting.getVerifoneMacLabel()
    };

    return this.http
      .post<any>(environment.NodeUrl + 'getLastTransaction', body)
      .pipe(catchError(this.errorHandler));
  }

  doVoidTransaction(CTROUTD: string) {
    const currentCounter = this.increasePaymentCounter();

    const body = {
      message: GeneralSetting.getVerifoneDecryptedMessage(),
      counter: currentCounter,
      macLable: GeneralSetting.getVerifoneMacLabel(),
      ctroutd: CTROUTD
    };

    return this.http
      .post<any>(environment.NodeUrl + 'doVoidTransaction', body)
      .pipe(catchError(this.errorHandler));
  }

  getDeviceVersion() {
    const currentCounter = this.increasePaymentCounter();

    const body = {
      message: GeneralSetting.getVerifoneDecryptedMessage(),
      counter: currentCounter,
      macLable: GeneralSetting.getVerifoneMacLabel(),
    };

    return this.http
      .post<any>(environment.NodeUrl + 'getDeviceVersion', body)
      .pipe(catchError(this.errorHandler));
  }

  doCancelOrder() {

    const currentCounter = this.increasePaymentCounter();

    const body = {
      message: GeneralSetting.getVerifoneDecryptedMessage(),
      counter: currentCounter,
      macLable: GeneralSetting.getVerifoneMacLabel(),
    };

    return this.http
      .post<any>(environment.NodeUrl + 'doCancelOrder', body)
      .pipe(catchError(this.errorHandler));
  }

  doConnectVerifoneDevice() {
    const body = {
      posPort: '5015',
      posIp: GeneralSetting.getVerifonePosIp()
    };

    return this.http
      .post<any>(environment.NodeUrl + 'verifoneConnectDevice', body)
      .pipe(catchError(this.errorHandler));
  }

  //Verifone Offline End

  onlineCheck() {
    // let url = "https://localhost:44367/Home/GenerateKeysV2";
    const url = environment.apiUrl + "CryptoGenerateKeysRSA";

    const headers = new HttpHeaders()
      .set('CompanyID', "")
      .set('BranchID', "")
      .set('Version', '2.0');

    return this.http.post(url, '', { headers })
      .pipe(catchError(this.errorHandler));
  }

  /**
   * Verifone refund payment
   */
  doVerifonePaymentRefund(orderId: string, refundedAmount: string) {
    var headers = [] as IHttpHeaders[];
    headers.push({ key: "Content-Type", value: "application/json" } as IHttpHeaders);
    headers.push({ key: "Accept", value: "application/json" } as IHttpHeaders);
    const body = {
      OrderID: Number(orderId),
      RefundAmount: Number(refundedAmount)
    };

    return HttpConnection.Connection(this.http, this.isKiosk)
      .addUrl(environment.apiUrl + this.verifoneRefundPaymentApi)
      .addMethod("POST")
      .addHeader(headers)
      .addBody(body)
      .post();
  }


  doConnectAndTryLastTransactionOneTime() {
    return new Promise<IVerifoneTransactionRes | null>(async (resolve) => {

      this.doConnectVerifoneDevice()
        .pipe(timeout(20000))
        .toPromise()
        .then(
          (data: any) => {
            if (!data.isError) {
              this.getLastTransaction()
                .pipe(timeout(50000))
                .toPromise()
                .then(
                  (lastTranRes: IVerifoneTransactionRes) => {
                    if (lastTranRes.RESPONSE.RESULT_CODE == '59003') {
                      resolve(null);
                    } else {
                      resolve(lastTranRes);
                    }
                  },
                  (error: any) => {
                    resolve(null);
                  }
                );
            } else {
              resolve(null);
            }
          },
          (error: any) => {
            resolve(null);
          }
        );


    });
  }

  public async startPayment(amount: number) {
    console.log("verifone startPayment Called ");

    let orderInvoiceNo = this.paymentService.getRandomOrderNo();

    let errorTxt = this.language.getTextElement('txt_kiosk_payment_device_not_set_up');
    this.lastTransactionCounter = 0;

    if (!GeneralSetting.getIsHasVerifoneDetailes()) {
      console.log("Verifone has no detailes");

      await this.paymentErrorDialog.openPaymentErrorDialog(true, errorTxt).then();

      return false;
    }

    this.paymentInsertModal.openPaymentInsetModel(true, false, false, "Connecting To Payment Device");

    console.log("doVerifonePosConnection called");

    if (!(await this.doVerifonePosConnection())) {
      console.log("doVerifonePosConnection failed");
      this.paymentInsertModal.closePaymentInsertModel();
      await this.paymentErrorDialog.openPaymentErrorDialog(true, errorTxt).then();
      return false;
    }

    console.log("getTransactionCounter Called");

    if (!(await this.getTransactionCounter())) {
      console.log("getTransactionCounter failed");
      this.paymentInsertModal.closePaymentInsertModel();
      await this.paymentErrorDialog.openPaymentErrorDialog(true, errorTxt).then();
      return false;
    }

    console.log("doTestMac Called");
    if (!(await this.doTestMac())) {
      console.log("doTestMac failed");
      this.paymentInsertModal.closePaymentInsertModel();
      await this.paymentErrorDialog.openPaymentErrorDialog(true, errorTxt).then();
      return false;
    }

    console.log("doStartSession Called");
    if (!(await this.doStartSession(this.paymentService.getRandomOrderNo()))) {
      console.log("doStartSession failed");
      this.paymentInsertModal.closePaymentInsertModel();
      await this.paymentErrorDialog.openPaymentErrorDialog(true, errorTxt).then();
      return false;
    }

    console.log("doVerifonePayment Called");
    let paymentRes = await this.doVerifonePayment(amount, orderInvoiceNo);

    await this.doFinishSession();

    this.paymentInsertModal.closePaymentInsertModel();

    if (!paymentRes.IsSuccess) {
      console.log("doVerifonePayment failed");
      await this.paymentErrorDialog.openPaymentErrorDialog(true, paymentRes.Message).then();
      return false;
    }

    console.log("doVerifonePayment Success");
    this.paymentSuccessModel.openPaymentSuccessModel(true);

    await CommonFunctions.delay(1000);

    this.paymentSuccessModel.closePaymentSuccessModel();

    return true;
  }

  private async doVerifonePosConnection() {
    return new Promise<boolean>(async (resolve) => {
      const body = {
        posPort: '5015',
        posIp: GeneralSetting.getVerifonePosIp()
      };

      this.logger.sendLogToServer(new loggingData(
        'Verifone do Pos Conncectin Called',
        'Verifone do Pos Conncectin Called orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
        'Verifone do Pos Conncectin Called',
        JSON.stringify(body),
        true
      ));


      this.http
        .post<any>(environment.NodeUrl + 'verifoneConnectDevice', body)
        .pipe(catchError(this.errorHandler))
        .toPromise().then(data => {
          if (!data.isError) {
            resolve(true);
          } else {
            this.closePaymentModelAndFinishVerifoneSession();
            resolve(false);
          }
        }, error => {
          resolve(false);
        });
    });
  }

  private async getTransactionCounter() {
    return new Promise<boolean>(async (resolve) => {
      const currentCounter = this.increasePaymentCounter();

      const body = {
        message: GeneralSetting.getVerifoneDecryptedMessage(),
        counter: currentCounter,
        macLable: GeneralSetting.getVerifoneMacLabel()
      };

      this.logger.sendLogToServer(new loggingData(
        'Verifone get Transaction Counter Called',
        'Verifone get Transaction Counter Called orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
        'Verifone get Transaction Counter Called',
        JSON.stringify(body),
        true
      ));

      this.http
        .post<any>(environment.NodeUrl + 'doVerifoneCounter', body)
        .pipe(catchError(this.errorHandler))
        .toPromise().then(counterRes => {

          this.logger.sendLogToServer(new loggingData(
            'Verifone Counter Res',
            'Verifone get Counter Res orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
            'Error Payment Verifone',
            JSON.stringify(counterRes),
            true
          ));

          if (
            !counterRes.RESPONSE.RESULT_CODE ||
            counterRes.RESPONSE.RESULT_CODE != '1'
          ) {
            if (counterRes.RESPONSE.COUNTER && counterRes.RESPONSE.COUNTER != "") {
              GeneralSetting.setVerifonePaymentCounter(
                counterRes.RESPONSE.COUNTER
              );
            }

            this.increasePaymentCounter();
          } else {
            this.increasePaymentCounter();
          }

          this.paymentInsertModal.setHeaderText("");

          resolve(true);
        }, error => {
          this.closePaymentModelAndFinishVerifoneSession();

          this.logger.sendLogToServer(new loggingData(
            'Verifone Counter Res Error',
            'Verifone get Counter Res Error  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
            'Error Payment Verifone',
            JSON.stringify(error),
            true
          ));

          resolve(false);
        });
    });
  }

  private async doTestMac() {
    return new Promise<boolean>(async (resolve) => {

      const currentCounter = this.increasePaymentCounter();

      const body = {
        message: GeneralSetting.getVerifoneDecryptedMessage(),
        counter: currentCounter,
        macLable: GeneralSetting.getVerifoneMacLabel()
      };

      this.logger.sendLogToServer(new loggingData(
        'Verifone do Test Mac Called',
        'Verifone do Test Mac Called orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
        'Verifone do Test Mac Called',
        JSON.stringify(body),
        true
      ));

      this.http.post<any>(environment.NodeUrl + 'testMacVerifone', body)
        .pipe(catchError(this.errorHandler))
        .toPromise().then((testMacRes: IVerifoneGeneralRes) => {

          if (
            !testMacRes.RESPONSE.RESULT_CODE ||
            testMacRes.RESPONSE.RESULT_CODE != '-1'
          ) {
            this.logger.sendLogToServer(new loggingData(
              'Verifone Test Mac Error',
              'Verifone Encountered Error Doing TestMac  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
              'Error Payment Verifone',
              JSON.stringify(testMacRes) + "  " + JSON.stringify(body),
              true
            ));

            this.closePaymentModelAndFinishVerifoneSession();
            resolve(false);
          }
          else {
            this.logger.sendLogToServer(new loggingData(
              'Verifone Test Mac Succeess',
              'Verifone Test Mac Succeess orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
              'Verifone Test Mac',
              JSON.stringify(testMacRes) + "  " + JSON.stringify(body),
              true
            ));

            resolve(true);
          }
        }, error => {
          this.logger.sendLogToServer(new loggingData(
            'Verifone Test Mac Error',
            'Verifone Encountered Test Mac Error  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
            JSON.stringify(body),
            'Test Mac Error',
            true
          ));

          this.closePaymentModelAndFinishVerifoneSession();

          resolve(false);
        });

    });
  }

  private async doStartSession(invoiceNo: string) {
    return new Promise<boolean>(async (resolve) => {
      const currentCounter = this.increasePaymentCounter();

      const body = {
        message: GeneralSetting.getVerifoneDecryptedMessage(),
        counter: currentCounter,
        macLable: GeneralSetting.getVerifoneMacLabel(),
        Invoice: invoiceNo.substr(invoiceNo.length - 6),
        posIp: GeneralSetting.getVerifonePosIp()
      };

      this.logger.sendLogToServer(new loggingData(
        'Verifone Start Session Called',
        'Verifone Start Session Called orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
        'Verifone Start Session Called',
        JSON.stringify(body),
        true
      ));

      this.http
        .post<any>(environment.NodeUrl + 'doStartVerifoneSession', body)
        .pipe(catchError(this.errorHandler)).toPromise().then(
          (sessionStartRes: IVerifoneGeneralRes) => {
            if (
              !sessionStartRes.RESPONSE.RESULT_CODE ||
              sessionStartRes.RESPONSE.RESULT_CODE != '-1'
            ) {
              this.closePaymentModelAndFinishVerifoneSession();

              this.logger.sendLogToServer(new loggingData(
                'Verifone Start Session Error',
                'Verifone Encountered Error Doing Start Session  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
                'Error Payment Verifone',
                JSON.stringify(sessionStartRes) + " Req Data " + JSON.stringify(body),
                true
              ));

              resolve(false);
            }
            else {
              this.logger.sendLogToServer(new loggingData(
                'Verifone Start Session Res ',
                'Verifone Start Session Res Success orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
                'Verifone Start Session Res Success',
                JSON.stringify(sessionStartRes),
                true
              ));

              resolve(true);
            }
          }, error => {
            this.closePaymentModelAndFinishVerifoneSession();

            const log = new loggingData(
              'Verifone Session Start Error',
              'Verifone Session Start Error   orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
              "Error Verifone Session Start",
              JSON.stringify(body),
              true
            );

            resolve(false);
          });
    });
  }

  private async doVerifonePayment(amount: number, orderInvoiceNo: string) {
    return new Promise<{
      IsSuccess: boolean,
      Message: string
    }>(async (resolve) => {
      const currentCounter = this.increasePaymentCounter();

      const body = {
        message: GeneralSetting.getVerifoneDecryptedMessage(),
        counter: currentCounter,
        macLable: GeneralSetting.getVerifoneMacLabel(),
        amount: CommonFunctions.roundDigit(amount, 2).toFixed(2)
      };

      this.logger.sendLogToServer(new loggingData(
        'Verifone do Payment Called ',
        'Verifone do Payment Called  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
        'Verifone do Payment Called',
        JSON.stringify(body),
        true
      ));

      this.http
        .post<any>(environment.NodeUrl + 'doVerifoneTransaction', body)
        .pipe(catchError(this.errorHandler))
        .toPromise().then(async (transactionRes: IVerifoneTransactionRes) => {
          this.logger.sendLogToServer(new loggingData(
            'Verifone do Payment Res  ',
            'Verifone do Payment Res   orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
            'Verifone do Payment Res ',
            JSON.stringify(transactionRes),
            true
          ));

          resolve(await this.onVerifoneTransactionRes(transactionRes, orderInvoiceNo));
        }, async (error: any) => {
          let stringError = '';

          try {
            stringError = JSON.stringify(error);
          } catch (e) { }

          this.logger.sendLogToServer(new loggingData(
            'Verifone Transaction Time Out Error',
            'Verifone Encountered Transaction Error  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
            'Error Payment Verifone',
            stringError,
            true
          ));

          await this.doFinishSession();

          resolve(await this.doLastTransactionLoop(orderInvoiceNo));
        });
    });
  }

  private async onVerifoneTransactionRes(
    transactionRes: IVerifoneTransactionRes,
    orderInvoiceNo: string,
    isLastTransaction: boolean = false
  ) {

    let resData = {
      IsSuccess: false,
      Message: ""
    }

    if (
      transactionRes &&
      transactionRes.RESPONSE &&
      (transactionRes.RESPONSE.RESULT_CODE == '2' ||
        transactionRes.RESPONSE.RESULT_CODE == '4' ||
        transactionRes.RESPONSE.RESULT_CODE == '5')
    ) {
      this.logger.sendLogToServer(new loggingData(
        'Verifone Transaction Approved',
        'Verifone Encountered Transaction Approved  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
        'Approve Payment Verifone',
        JSON.stringify(transactionRes),
        true
      ));

      if (!transactionRes.RESPONSE.TROUTD
        || transactionRes.RESPONSE.TROUTD == undefined
        || transactionRes.RESPONSE.TROUTD == null
        || transactionRes.RESPONSE.TROUTD == ''
      ) {
        GeneralSetting.setIsVerifoneOfflineOrder('true');
      } else {
        GeneralSetting.setIsVerifoneOfflineOrder('false');
      }

      GeneralSetting.setPaymentResponse(
        JSON.stringify(transactionRes.RESPONSE)
      );

      GeneralSetting.setCardType(transactionRes.RESPONSE.PAYMENT_MEDIA);

      resData.IsSuccess = true;
      resData.Message = "Payment Successful";

      return resData;
    } else if (
      transactionRes &&
      transactionRes.RESPONSE &&
      transactionRes.RESPONSE.RESULT_CODE &&
      this.verifoneErrorResponseCodes.find(
        (x) => x == transactionRes.RESPONSE.RESULT_CODE
      ) &&
      !isLastTransaction
    ) {

      this.logger.sendLogToServer(new loggingData(
        'Verifone Transaction Error',
        'Verifone Encountered Transaction Error  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
        'Error Payment Verifone',
        JSON.stringify(transactionRes),
        true
      ));

      await this.doFinishSession();
      if (isLastTransaction) {
        resData =
        {
          IsSuccess: false,
          Message: "Transaction Failed"
        }
        return resData;
      }
      return await this.doLastTransactionLoop(orderInvoiceNo);
    } else {
      var tranResMessage = await this.getTransactionResMessage(transactionRes.RESPONSE.RESULT_CODE, transactionRes);

      if (!tranResMessage.isKnownResCode) {
        const log = new loggingData(
          'Verifone Transaction Declined',
          'Verifone Encountered Transaction Declined  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
          'Decline Payment Verifone',
          JSON.stringify(transactionRes),
          true
        );

        this.logger.sendLogToServer(log);

        await this.doFinishSession();
        if (isLastTransaction) {
          resData =
          {
            IsSuccess: false,
            Message: "Transaction Failed"
          }
          return resData;
        }

        return await this.doLastTransactionLoop(orderInvoiceNo, true);
      } else {
        resData =
        {
          IsSuccess: false,
          Message: "Transaction Failed"
        }
        return resData;
      }
    }
  }

  private async getTransactionResMessage(ResultCode: string, transactionRes: IVerifoneTransactionRes) {
    let transactionMessage = 'Transaction Declined';

    let isKnownResCode = false;

    switch (ResultCode) {
      case '59001':
        isKnownResCode = true;
        this.logger.sendLogToServer(
          new loggingData(
            'Verifone Transaction Canceled By Customer',
            'Verifone Encountered Transaction Cancel  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
            'Decline Payment Verifone',
            JSON.stringify(transactionRes),
            true
          )
        );

        transactionMessage = 'Canceled By Customer';

        break;
      case '56783':
        isKnownResCode = true;
        this.logger.sendLogToServer(
          new loggingData(
            'Verifone DUPLICATE TRANSACTION ',
            'Verifone Encountered DUPLICATE TRANSACTION  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
            'Decline Payment Verifone',
            JSON.stringify(transactionRes),
            true
          )
        );

        transactionMessage = 'Duplicate Transaction';

        break;
      case '999998':
        isKnownResCode = true;
        this.logger.sendLogToServer(
          new loggingData(
            'Verifone Decline TRANSACTION ',
            'Verifone Encountered Decline TRANSACTION orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
            'Decline Payment Verifone',
            JSON.stringify(transactionRes),
            true
          )
        );

        transactionMessage = 'Transaction Decline';

        break;
    }

    return {
      isKnownResCode: isKnownResCode,
      transactionMessage: transactionMessage
    };
  }

  private doConnectDevice() {
    return new Promise<boolean>((resolve, reject) => {
      const body = {
        posPort: '5015',
        posIp: GeneralSetting.getVerifonePosIp()
      };

      this.logger.sendLogToServer(new loggingData(
        'Verifone do Connect Device Called',
        'Verifone do Connect Device Called  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
        'Verifone do Connect Device Called ',
        JSON.stringify(body),
        true
      ));

      this.http
        .post<any>(environment.NodeUrl + 'verifoneConnectDevice', body)
        .pipe(catchError(this.errorHandler)).toPromise()
        .then(data => {
          this.logger.sendLogToServer(new loggingData(
            'Verifone do Connect Device Res',
            'Verifone do Connect Device Res  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
            'Verifone do Connect Device Res ',
            JSON.stringify(data),
            true
          ));
          if (!data.isError) {
            resolve(true);
          } else {
            resolve(false);
          }
        }, error => {
          resolve(false);
        });
    });
  }

  private async doLastTransactionLoop(orderInvoiceNo: string, IsOneTime: boolean = false) {
    return new Promise<{
      IsSuccess: boolean,
      Message: string
    }>(async (resolve, reject) => {

      this.logger.sendLogToServer(
        new loggingData(
          'Verifone Last Transaction Inititated',
          'Verifone Refund Order Invoice no :- ' + orderInvoiceNo,
          'Verifon Last Transaction Inititated',
          "",
          true
        )
      );

      await this.doFinishSession();

      await this.doConnectDevice();

      await CommonFunctions.delay(10000);

      let lastTransactionRes = await this.doVerifoneLastTransaction(orderInvoiceNo, IsOneTime);

      if (lastTransactionRes.IsSuccess) {
        resolve({
          IsSuccess: true,
          Message: "Payment Successful"
        });
      }
      else if (lastTransactionRes.DoRetry) {
        if (this.lastTransactionCounter > 0) {
          resolve({
            IsSuccess: false,
            Message: "Payment Failed"
          });
        }
        else {
          await this.doFinishSession();

          this.lastTransactionCounter++;

          if (!IsOneTime) {
            await CommonFunctions.delay(10000 * this.lastTransactionCounter * 2);

            resolve(await this.doLastTransactionLoop(orderInvoiceNo, false));
          }
        }
      }

      resolve({
        IsSuccess: false,
        Message: "Payment Failed"
      });
    });
  }

  private doVerifoneLastTransaction(orderInvoiceNo: string, IsOneTime: boolean) {
    return new Promise<{
      IsSuccess: boolean,
      Message: string,
      DoRetry: boolean
    }>((resolve) => {

      let resData =
      {
        IsSuccess: false,
        Message: "Transaction Failed",
        DoRetry: false
      }

      const currentCounter = this.increasePaymentCounter();

      const body = {
        message: GeneralSetting.getVerifoneDecryptedMessage(),
        counter: currentCounter,
        macLable: GeneralSetting.getVerifoneMacLabel()
      };

      this.logger.sendLogToServer(new loggingData(
        'Verifone do Verifone Last Transaction Called',
        'Verifone do Verifone Last Transaction Called  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
        'Verifone do Verifone Last Transaction Called ',
        JSON.stringify(body),
        true
      ));

      this.http
        .post<any>(environment.NodeUrl + 'getLastTransaction', body)
        .pipe(catchError(this.errorHandler)).toPromise()
        .then(async (lastTranRes: IVerifoneTransactionRes) => {

          this.logger.sendLogToServer(
            new loggingData(
              'Verifone Last Transaction Response',
              'Verifone Refund Order Invoice no :- ' + orderInvoiceNo,
              'Verifone Last Transaction Response',
              JSON.stringify(lastTranRes),
              true
            )
          );

          await this.doFinishSession();

          if (lastTranRes.RESPONSE.RESULT_CODE == '59003') {
            resData.IsSuccess = false;
            resData.Message = "Transaction Failed";
            resData.DoRetry = true;
            resolve(resData);
          }
          else {
            let transactionInvoicNo = orderInvoiceNo;

            if (lastTranRes.RESPONSE.INVOICE == transactionInvoicNo.substr(transactionInvoicNo.length - 6)
            ) {
              let res = await this.onVerifoneTransactionRes(lastTranRes, orderInvoiceNo, true)
              resData.IsSuccess = res?.IsSuccess ?? false;
              resData.Message = res?.Message ?? "Transaction Failed";
              resData.DoRetry = false;
              resolve(resData);
            } else {
              resData.IsSuccess = false;
              resData.Message = "Transaction Failed";
              resData.DoRetry = false;
              resolve(resData);
            }
          }
        }, error => {
          resData.IsSuccess = false;
          resData.Message = "Transaction Failed";
          resData.DoRetry = true;
          resolve(resData);
        });

    });
  }

  private async closePaymentModelAndFinishVerifoneSession() {
    await this.doFinishSession();

    this.paymentInsertModal.closePaymentInsertModel();
  }

  private async doFinishSession() {
    return new Promise<boolean>((resolve, reject) => {
      const currentCounter = this.increasePaymentCounter();

      const body = {
        message: GeneralSetting.getVerifoneDecryptedMessage(),
        counter: currentCounter,
        macLable: GeneralSetting.getVerifoneMacLabel()
      };

      
      this.logger.sendLogToServer(new loggingData(
        'Verifone do Finish Session Called',
        'Verifone do Finish Session Called  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
        'Verifone do Finish Session Called',
        JSON.stringify(body),
        true
      ));

      return this.http
        .post<any>(environment.NodeUrl + 'doEndVerifoneSession', body)
        .pipe(catchError(this.errorHandler)).toPromise().then(data => {
          this.logger.sendLogToServer(new loggingData(
            'Verifone do Finish Session Res',
            'Verifone do Finish Session Res  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
            'Verifone do Finish Session Res',
            JSON.stringify(data),
            true
          ));
          resolve(true);
        }, error => {
          this.logger.sendLogToServer(new loggingData(
            'Verifone do Finish Session Res Error',
            'Verifone do Finish Session Res Error  orderInvoiceNo :- ' + this.paymentService.getRandomOrderNo(),
            'Verifone do Finish Session Res Error',
            JSON.stringify(error),
            true
          ));
          resolve(false);
        });
    })
  }

  private onlineCheckAsync() {
    return new Promise<boolean>((resolve, reject) => {

      const url = environment.apiUrl + "IsOnlineCheckSK";

      this.http.get(url)
        .toPromise()
        .then(() => {
          resolve(true);
        }, () => {
          resolve(false);
        });
    });
  }

  public async doRefundAsync(amount: number, transactionId: string, orderInvoiceNo: string) {
    this.lastTransactionCounter = 0;

    this.logger.sendLogToServer(
      new loggingData(
        'Verifone Refund Started',
        'Verifone Refund Order Invoice no :- ' + orderInvoiceNo,
        'Verifon Refund Started',
        JSON.stringify({
          orderInvoiceNo: orderInvoiceNo,
          transactionId: transactionId,
          amount: amount
        }),
        true
      )
    );

    let res = {
      isSuccess: false,
      Error: "",
      Data: {}
    };

    if (!GeneralSetting.getIsHasVerifoneDetailes()) {
      res.isSuccess = false;
      res.Error = this.language.getTextElement('refund_declined_network_issue');
      return res;
    }

    if (!(await this.doVerifonePosConnection())) {
      this.paymentInsertModal.closePaymentInsertModel();
      res.isSuccess = false;
      res.Error = this.language.getTextElement('refund_declined_network_issue');
      return res;
    }


    if (!await this.onlineCheckAsync()) {
      res.isSuccess = false;
      res.Error = this.language.getTextElement('refund_declined_network_issue');
      return res;
    }

    if (!await this.doStartSession(orderInvoiceNo)) {
      res.isSuccess = false;
      res.Error = this.language.getTextElement('refund_declined_device_issue');
      return res;
    }

    let refundRes = await this.doRefundTransaction(CommonFunctions.roundDigit(amount, 2).toFixed(2), transactionId, orderInvoiceNo);

    if (!refundRes) {
      let lastTranRes = await this.doLastTransactionLoop(orderInvoiceNo, true);

      if (lastTranRes.IsSuccess) {
        refundRes = true;
      }
      else {
        refundRes = false;
      }
    }

    this.doFinishSession().then();

    res.isSuccess = refundRes;

    return res;
  }

  private doRefundTransaction(amount: string, transactionId: string, orderInvoiceNo: string) {
    return new Promise<boolean>((resolve) => {
      const currentCounter = this.increasePaymentCounter();

      const body = {
        message: GeneralSetting.getVerifoneDecryptedMessage(),
        counter: currentCounter,
        macLable: GeneralSetting.getVerifoneMacLabel(),
        transactionId: transactionId,
        amount: amount.trim()
      };

      this.http
        .post<any>(environment.NodeUrl + 'doRefundVerifoneTransaction', body)
        .pipe(catchError(this.errorHandler))
        .toPromise()
        .then(refundRes => {


          if (
            !refundRes.RESPONSE ||
            !refundRes.RESPONSE.RESULT_CODE ||
            (refundRes.RESPONSE &&
              refundRes.RESPONSE.RESULT_CODE &&
              !(
                refundRes.RESPONSE.RESULT_CODE == '4' ||
                refundRes.RESPONSE.RESULT_CODE == '5'
              ))
          ) {
            this.logger.sendLogToServer(
              new loggingData(
                'Verifone Refund Response Error',
                'Verifone Refund Order Invoice no :- ' + orderInvoiceNo,
                'Verifon Refund Response Error',
                JSON.stringify(refundRes),
                true
              )
            );

            resolve(false);
            return;
          }

          this.logger.sendLogToServer(
            new loggingData(
              'Verifone Refund Response Success',
              'Verifone Refund Order Invoice no :- ' + orderInvoiceNo,
              'Verifon Refund Response Success',
              JSON.stringify(refundRes),
              true
            )
          );

          resolve(true);

          return;
        }, error => {
          resolve(false);
        });
    });
  }

  private doConnectAndRegisterVerifoneClient(posIp: string) {
    return new Promise<{
      IsError: boolean,
      data: any
    }>((resolve) => {

      var registerClientReq = {
        posPort: "5015",
        posIp: posIp,
        code: 1111,
        publicKey: GeneralSetting.getVerifonePublicKey(),
      };

      this.http
        .post(environment.NodeUrl + 'verifoneRegisterDevice', registerClientReq)
        .pipe(catchError(this.errorHandler))
        .toPromise()
        .then(data => {
          resolve({
            IsError: false,
            data: data
          });
        }, error => {
          resolve({
            IsError: true,
            data: error
          });
        })
    });
  }

  private doDecryptWithPrivateKey() {
    return new Promise<{
      IsError: boolean,
      data: any
    }>((resolve) => {

      this.http.post<any>(environment.NodeUrl + 'doDecryptVerifoneMacKey', {
        macKey: GeneralSetting.getVerifoneMacKey(),
      }).pipe(catchError(this.errorHandler))
        .toPromise().then(data => {
          resolve({
            IsError: false,
            data: data
          });
        }, error => {
          resolve({
            IsError: true,
            data: error
          });
        });
    });
  }

  public async doRegisterDevice(isForced: boolean = false) {

    const paymentCred = await this.getPaymentDeviceFromSql();

    if (!isForced) {
      if (paymentCred.HSN == GeneralSetting.getVerifonePosIp() && this.isPaymentConfigured && GeneralSetting.getIsHasVerifoneDetailes()) {
        return true;
      }
    }

    GeneralSetting.setVerifonePrivateKey('');
    GeneralSetting.setVerifonePublicKey('');
    GeneralSetting.setVerifoneDecryptedMessage('');

    //paymentCred.HSN = "10.10.90.239";

    GeneralSetting.setVerifonePosIp(paymentCred.HSN);

    if (Object.entries(paymentCred).length < 0 || paymentCred.HSN == "") {
      return false;
    }

    let connetAndRegisterRes = await this.doConnectAndRegisterVerifoneClient(paymentCred.HSN);

    if (connetAndRegisterRes.IsError ||
      !(connetAndRegisterRes.data &&
        connetAndRegisterRes.data != '' &&
        connetAndRegisterRes.data.RESPONSE &&
        connetAndRegisterRes.data.RESPONSE.RESULT_CODE == '-1'
      )) {
      return false;
    }

    const macKey = connetAndRegisterRes.data.RESPONSE.MAC_KEY;
    const macLabel = connetAndRegisterRes.data.RESPONSE.MAC_LABEL;

    GeneralSetting.setVerifoneMacLabel(macLabel);
    GeneralSetting.setVerifoneMacKey(macKey);

    let decriptionRes = await this.doDecryptWithPrivateKey();

    if (decriptionRes.IsError) {
      return false;
    }

    GeneralSetting.setVerifoneDecryptedMessage(decriptionRes.data.decryptedMessage);
    this.isPaymentConfigured = true;
    return true;
  }

}


