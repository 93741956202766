import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-alcoholic-item-exceed-max',
  templateUrl: './alcoholic-item-exceed-max.component.html',
  styleUrls: ['./alcoholic-item-exceed-max.component.css'],
})
export class AlcoholicItemExceedMaxComponent implements OnInit,OnDestroy {
  
  ngOnDestroy() {
    if (this.allSubscription.length > 0) {
      for (let i = 0; i < this.allSubscription.length; i++) {
        this.allSubscription[i].unsubscribe();
      }
    }
  }

  allSubscription: Subscription[] = [];

  modalReference: any;

  limit: any;

  uhOhText: string = '';

  alcoholicLimitText: string = '';

  closedText: string = '';

  constructor(private language: LanguageService) {}

  ngOnInit(): void {
    this.allSubscription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.limit = GeneralSetting.getMaxAlcoholicItemsPerOrder();
        this.loadText();
      })
    );

  }

  loadText() {
    this.uhOhText = this.language.getTextElement('titile_uh_oh');
    this.alcoholicLimitText = this.language.getTextElement(
      'you_have_reached_the_maximum_number_of_alcoholic_items_allowed_for_this_order_limit_is'
    ).replace('%d',this.limit )
    this.closedText = this.language.getTextElement('closed');
  }

  public static open(modalService: NgbModal) {
    let modalReference = modalService.open(AlcoholicItemExceedMaxComponent, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });
    modalReference.componentInstance.modalReference = modalReference;
    return modalReference;
  }

  dismiss() {
    this.modalReference.close();
  }

}
