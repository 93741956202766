<div [ngStyle]="{
    borderLeftColor:
    modifier.isValid
      ? positiveColor
      : modifier.count<=modifier.max && modifier.count>=modifier.min
        ? 'gray'
        : 'red'
  }" class="objects accordion">
  <div class="header-text" [ngClass]="modifier.IsModifier86 == 'False' ? 'sold' : ''" (click)="collapse()">
    <span *ngIf="modifier.rule != ''" style="font-weight: bold;">
      {{
      (modifier.DisplayName || modifier.Name || modifier.Category)|uppercase
      }}
    </span>
    
    <span *ngIf="isRequired" class="material-icons text-danger">error</span>
    <span *ngIf="isCompleted" class="material-icons text-success">done</span>

    <span [ngStyle]="{color: isCompleted ? 'green': isRequired ? 'red' : null}"> {{rules}}</span>
    
    <!-- <span *ngIf="modifier.rule != '' && !isAggregate" class="rule">({{ modifier.rule }})</span> -->
    <span *ngIf="!isAggregate"class="required-text" [ngStyle]="{color: modifier.count<=modifier.max && modifier.count>=modifier.min?'green':'red'}">
      {{ modifier.min > 0 ? requiredText : '' }}
    </span>
    <span class="collapse-icon">

      <i *ngIf="collapsed; else collap" class="fa fa-solid fa-chevron-down"></i>

      <ng-template #collap>
        <i class="fa fa-solid fa-chevron-up">
        </i>
      </ng-template>
    </span>
  </div>
  <div class="mini-holder flex animated fadeIn">
    <div class="list-text-holder">
      <span *ngFor="let item of filterData(); let isLast = last" class="normal">
        <span>{{ item.name }} </span> <span style="color:green" *ngIf="item.price != '0.00'"> +{{ item.price }}</span>{{!isLast ? ', ' : ''}}
      </span>
    </div>
  <span *ngIf="showAggregateRule">
   {{modifier.ruleAggregate}}
  </span>
    <div class="flex holder" *ngIf="collapsed">
      <div *ngFor="let item of ingredients">
        <img *ngIf="item.IsSelected" class="mini-item" src="{{item.ImageUrl && item.ImageUrl !='' ? baseImageUrl + item.ImageUrl : placeholderImage !='' ? baseImageUrl+placeholderImage : 'assets/images/Image_21.png'}}"
          onerror="this.onerror=null; this.src='assets/images/Image_21.png'" [ngStyle]="{'border-radius': bordersTypeModifire ? imageRadius : '0%' }">
      </div>
    </div>
  </div>
  <div [ngbCollapse]="collapsed" (click)="cancelDefault()" class="">
    <div class="object-row collapse show animated fadeIn overflow-y-none">
      <div class="object-col" *ngFor="let ingredient of ingredients;index as i">
        <app-ingredient (SelectedItem)="selectedItem()" [parent]="modifier" [ingredientIndex]="i"
          (parentChange)="parentChange($event)" [ingredient]="ingredient" [ItemType]="ItemType"
          [ItemName]="ItemName" [ItemDescription]="ItemDescription">
        </app-ingredient>
      </div>
    </div>
  </div>
  <div class="max-holder" *ngIf="modifier.max == modifier.count">
    <span class="negativeText"> {{maxSelectionsText}} <span class="black"> {{deselectText}}</span> </span>
  </div>
</div>