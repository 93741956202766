import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Category } from 'src/app/models/category';
import { ComboGroupV2 as ComboGroup, ItemV2, ModifierV2 } from 'src/app/models/item';
import { Variation, VariationOption } from 'src/app/models/Variations';
import { DatabaseService } from 'src/app/services/database.service';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { IsKioskService } from 'src/app/services/is-kiosk.service';
import { ItemTransferService } from 'src/app/services/item-transfer.service';
import { environment } from 'src/environments/environment';
import { BoxBuilderFooterComponent } from './box-builder-footer/box-builder-footer.component';
import { ChooseDonutCustomizePopupComponent } from 'src/app/components/dialogs/choose-donut-customize-popup/choose-donut-customize-popup.component';
import { CartService } from 'src/app/services/cart.service';
import { BoxBuilderComboComponent } from 'src/app/components/box-builder/box-builder-combo/box-builder-combo.component';
import { LoadingComponent } from 'src/app/loading/loading.component';
import { ItemService } from 'src/app/services/ItemService';
import { Concessionaire } from '../../models/Concessionaire';
import { LoaderService } from 'src/app/services/loader.service';
import { ParBrinkService } from 'src/app/services/par-brink.service';
import { Integration, IntegrationService } from 'src/app/services/integration.service';

@Component({
  selector: 'app-box-builder',
  templateUrl: './box-builder.component.html',
  styleUrls: ['./box-builder.component.css'],
})
export class BoxBuilderComponent implements OnInit, OnDestroy {

  ngOnDestroy() {
    if (this.allSubsCription.length > 0) {
      for (let i = 0; i < this.allSubsCription.length; i++) {
        this.allSubsCription[i].unsubscribe();
      }
    }
  }

  private allSubsCription: Subscription[] = [];

  constructor(
    private readonly itemTransferService: ItemTransferService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly db: DatabaseService,
    private readonly modalService: NgbModal,
    private readonly isKioskService: IsKioskService,
    private readonly cartService: CartService,
    private readonly cdr: ChangeDetectorRef,
    private readonly itemService: ItemService,
    private readonly loader:LoaderService,
    private parBrink:ParBrinkService,
    private integration:IntegrationService
  ) {
    this.item = null;
    this.variations = [] as Variation[];
    this.modifiers = new Array<ModifierV2>();
    this.ItemID = this.route.snapshot.queryParams['id'];
    this.CategoryID = this.route.snapshot.queryParams['category'];
    this.guid = this.route.snapshot.queryParams['guid'];
    this.isOrderReviewEdit = this.route.snapshot.queryParams['reviewEdit'];
    this.isLoyalty = this.route.snapshot.queryParams['loyalty'];
    this.concessionaireId = this.route.snapshot.queryParams['concessionaireId'];
  }

  baseImageUrl = this.isKioskService.isKiosk()
    ? environment.imageBaseUrl
    : environment.imageBaseUrlRemote;

  modifiers: ModifierV2[] = [] as ModifierV2[];

  variations: Variation[] = [] as Variation[];

  comboGroup: ComboGroup[] = [] as ComboGroup[];

  modalReference: any;

  ItemID: string;

  CategoryID: string;

  isReady: boolean = false;

  item: ItemV2 | null;

  // For Edit Item Feature
  guid: string = '';

  isOrderReviewEdit = false;
  // For Edit Item Feature end
  isLoyalty: string;

  isComboItem = false;

  category: Category = {} as Category;

  quantity: number = 1;

  cartQuantity: number = 0;

  branchLogo: string | null = '';

  selectedCount: number = 0;
  itemImageList: any[] = [];

  // For selected item with modifiers
  _selectedModifiers: ModifierV2[] = [];

  _selectedVariations: VariationOption[] = [];

  _selectedComboGroup: ComboGroup[] = [];

  isModifiersValid!: boolean;
  isVariationsValid!: boolean;
  isComboGroupValid: boolean = false;
  numInvisibleGroups: number = 0;
  totalComboGroups: number = 0;

  isFromEdit: boolean = false;

  // @ViewChild(BoxBuilderItemDetail) boxBuilderItem!: BoxBuilderItemDetail;
  boxBuilderItem: any

  @ViewChild(BoxBuilderFooterComponent)
  boxBuilderItemFooter!: BoxBuilderFooterComponent;

  @ViewChild(ChooseDonutCustomizePopupComponent, { static: true })
  chooseDonutCustomizePopup!: ChooseDonutCustomizePopupComponent;

  @ViewChild(BoxBuilderComboComponent) boxBuilderCombo!: BoxBuilderComboComponent;

  @ViewChild(BoxBuilderFooterComponent) boxBuilderFooter!: BoxBuilderFooterComponent;

  currentSelectedModifierIndex: number = 0;

  isConcessionaire: boolean = false;

  concessionaireId: string = "";

  comboItemFlags: any[] = [];
  ngOnInit(): void {

    this.isConcessionaire = GeneralSetting.getIsConcessionaire().toLowerCase() == "true";

    this.branchLogo = GeneralSetting.getBranchLogo();
    this.initializePage();
  }

  private initializePage() {
    if (!this.db.hasCategory()) {
      this.db.getPersistentCategoriesAsync(true);
    }


    if (this.isComboItem) {
      if (this.item) {
        this.setDetailByItem(this.item);
        if (this.item.ComboGroup) {
          this.numInvisibleGroups = this.item.ComboGroup.filter(
            (x) => x.isHidden
          ).length;
        }
      }
      return;
    }

    const item = this.itemTransferService.getItem();

    if (this.guid) {
      this.setEditItemDetail();
      if (this.item) {
        if (this.item.ComboGroup) {
          this.numInvisibleGroups = this.item.ComboGroup.filter(
            (x) => x.isHidden
          ).length;
        }
      }
      return;
    }

    this.allSubsCription.push(
      this.db.categorySubject.subscribe((categories) => {
      if (!(categories && categories.length > 0)) return;

      const category = categories.find(
        (x) => Number(x.CategoryID) == Number(this.CategoryID)
      );

      this.category = category ?? ({} as Category);
      })
    );

    if (item) {
      this.setDetailByItem(item);

      //if (this.item) {
      //  this.numInvisibleGroups = this.item!.ComboGroup.filter(x => x.isHidden).length
      //}

      this.checkIsReady();
    }
    else {

      this.db.getItemsByCategoryIdFromSql(this.CategoryID, this.isConcessionaire, this.concessionaireId, true, this.ItemID).then(items => {
        if (items.length > 0) {
          this.itemTransferService.snapshotItem(items[0]);
          this.initializePage();
        }
      });
    }

  }

  private setEditItemDetail() {
    const cartItem = this.cartService.getCartItems().find(
      (x: any) => x.guid == this.guid
    );

    if (cartItem && cartItem != null) {

      const item = JSON.parse(JSON.stringify(cartItem)) as ItemV2;

      if (item && item != undefined) {

        if (item.Modifiers && item.Modifiers.length > 0) {
          this.modifiers = item.Modifiers;
        }
        else {
          this.getAndSetModifier(item.ItemID);
        }

        if (item.Variations && item.Variations.length > 0) {
          this.variations = item.Variations;
        }
        else {
          this.db.getPersistentVariationsAsync(item.ItemID).then(variations => {
            this.variations = variations;

            if (this.item) {
              this.item.Variations = JSON.parse(JSON.stringify(variations));
            }
          });
        }

        if (item.IsCombo) {
          if (item.ComboGroup && item.ComboGroup.length > 0) {
            this.comboGroup = item.ComboGroup

            this.totalComboGroups = item.ComboGroup.length;
          }
          else {
            this.getAndSetComboDetail(item.ItemID);
          }
        }
      }

      this.item = item;

      if (!this.item || this.item == null) return;

      this.itemTransferService.snapshotItemV2(this.item);

      this.cartQuantity = parseInt(
        (this.item ? Number(this.item.Quantity) : 1).toString()
      );

      this.quantity = this.item ? Number(this.item.Quantity) : 1;

      const cartItems = this.cartService.getCartItems().filter(
        (x) => x.ItemID == item.ItemID
      );

      if (cartItems && cartItems.length > 0) {
        const cartItemsQuantity = Number(
          cartItems.map((x) => Number(x.Quantity)).reduce((a, b) => a + b, 0)
        );
        this.cartQuantity = cartItemsQuantity;
      }

      this.cartQuantity = this.quantity - this.cartQuantity;
    }
    this.checkIsReady();
  }

  // on getting item from category subscription set all detail in component
  private setDetailByItem(categoryItem: ItemV2) {

    if (!categoryItem) return;

    const item = JSON.parse(JSON.stringify(categoryItem)) as ItemV2;

    if (!item) return;

    if (item.Modifiers && item.Modifiers.length > 0) {
      this.modifiers = item.Modifiers;
    }
    else {
      this.getAndSetModifier(item.ItemID);
    }

    if (item.Variations && item.Variations.length > 0) {
      this.variations = item.Variations;
    }
    else {
      this.db.getPersistentVariationsAsync(item.ItemID).then(variations => {
        this.variations = variations;

        if (this.item) {
          this.item.Variations = JSON.parse(JSON.stringify(variations));

          if (this.boxBuilderCombo) this.boxBuilderCombo.ngOnInit();
        }
      });
    }

    if (item.IsCombo) {
      if (item.ComboGroup && item.ComboGroup.length > 0) {
        this.comboGroup = item.ComboGroup;
      }
      else {
        this.getAndSetComboDetail(item.ItemID);
      }
    }

    if (
      this.itemTransferService.itemV2?.OldPrice &&
      this.itemTransferService.itemV2?.OldPrice != undefined
    ) {
      item.Price = this.itemTransferService.itemV2?.Price;
      item.OldPrice = this.itemTransferService.itemV2?.OldPrice;
    }
    this.quantity = item?.OrderMinimumQuantity || 1;
    this.itemTransferService.snapshotItemV2(item);
    this.item = item;
  }

  private getAndSetModifier(itemId: string) {
    //const modPer = performance.now();

    //console.log("modifier Performance :- " + modPer)

    this.db.getModifierByItemIDFromSql(itemId).then(async (allModifier: ModifierV2[]) => {

      this.loader.openLoader();

      this.modifiers = await this.itemService.getAllModifierIngredients(allModifier);
      if(this.integration.integration==Integration.Parbrink){
        let parBrinkMods:any =await this.db.parBrinkOverride(this.item,this.modifiers)
        this.modifiers = this.parBrink.parBrinkModifierOverride(parBrinkMods, this.modifiers)
      }
      //const modPerEnd = performance.now();

      //console.log("modifier Performance End :- " + modPerEnd);

      //console.log("Category Per End :- " + ((modPerEnd - modPer) / 1000));

      if (this.item) {
        this.item.Modifiers = JSON.parse(JSON.stringify(this.modifiers));
      }

      this.loader.closeLoader();
      if (this.boxBuilderCombo) {
        this.cdr.detectChanges();
        this.boxBuilderCombo.ngOnInit();
      }
    });
  }

  private getAndSetComboDetail(itemId: string) {

    let comboItemPer = performance.now();

    console.log("Combo Item Performance :- " + comboItemPer);

    this.db.getAllComboGroupsFromSQl(itemId).then(async (comboGroups: ComboGroup[]) => {

      this.loader.openLoader();

      if (comboGroups.length > 0) {
        let firstComboGroup = comboGroups[0];

        let comboGroupItems = await this.db.getComboItemsFromSql(firstComboGroup.ComboGroupID, itemId);

        let isHidden = comboGroupItems.find(x => x.IsInvisibleItem.toLowerCase() == "true");
        if (isHidden) {
          firstComboGroup.isHidden = true;
        }
        else {
          firstComboGroup.isHidden = false;
          firstComboGroup.isSelected = true;
        }

        firstComboGroup.Items = comboGroupItems;

        let copyComboGroupItems = JSON.parse(JSON.stringify(comboGroupItems));

        for (let i = 1; i < comboGroups.length; i++) {

          let comboGroup = comboGroups[i];

          if (comboGroup.HasInvisibleItem) {

            let IcomboGroupItems = await this.db.getComboItemsFromSql(comboGroup.ComboGroupID, itemId);

            let isHidden = IcomboGroupItems.find(x => x.IsInvisibleItem.toLowerCase() == "true");
            if (isHidden) {
              comboGroup.isHidden = true;
            }
            else {
              comboGroup.isHidden = false;
              comboGroup.isSelected = true;
            }

            comboGroup.Items = IcomboGroupItems;
          }
          else {

            for (let j = 0; j < copyComboGroupItems.length; j++) {
              comboGroupItems[j].ComboID = comboGroup.ComboGroupID;
            }

            comboGroup.Items = JSON.parse(JSON.stringify(copyComboGroupItems));

            comboGroup.isHidden = false;
            comboGroup.isSelected = true;
          }
        }
      }
      this.comboGroup = comboGroups;
      this.loader.closeLoader();

      if (this.item) {
        this.item.ComboGroup = this.comboGroup;
        this.numInvisibleGroups = this.item.ComboGroup.filter(x => x.isHidden).length;
      }

      if (this.boxBuilderCombo) {
        if (this.item) {
          this.boxBuilderCombo.comboGroups = this.item.ComboGroup;
          this.totalComboGroups = this.item.ComboGroup.length;
        }

        this.boxBuilderCombo.numInvisibleGroups = this.numInvisibleGroups;
        this.boxBuilderCombo.ngOnInit();
      }
      else {
        this.cdr.detectChanges();
      }

      if (this.boxBuilderFooter) {
        this.boxBuilderFooter.numInvisibleGroups = this.numInvisibleGroups;
        this.boxBuilderFooter.totalQuantity = Array.from(Array(this.totalComboGroups - this.boxBuilderFooter.numInvisibleGroups).keys());
      }

    });
  }

  open(
    item: any,
    isCombo: boolean,
    isComboItem: boolean,
    itemImageList: any[],
    totalComboGroups: number,
    selectedCount: number,
    numInvisibleGroups: number,
    isEdit: boolean = false
  ) {

    this.modalReference = this.modalService.open(BoxBuilderComponent, {
      centered: true,
      size: 'xl',
      windowClass: 'BoxBuilderPopup',
      modalDialogClass: 'BoxBuilderPopup_modelDialog',
    });
    this.modalReference.componentInstance.item = item;
    this.modalReference.componentInstance.item.IsCombo = isCombo; // sending to builder item
    // this.modalReference.componentInstance.modifiers = item.Modifiers; // Sending to builder item
    this.modalReference.componentInstance.isComboItem = isComboItem;
    this.modalReference.componentInstance.itemImageList = itemImageList;
    this.modalReference.componentInstance.totalComboGroups = totalComboGroups;
    this.modalReference.componentInstance.selectedCount = selectedCount;
    this.modalReference.componentInstance.modalReference = this.modalReference;
    this.modalReference.componentInstance.numInvisibleGroups =
      numInvisibleGroups;
    if (isEdit) {
      this.modalReference.componentInstance.isFromEdit = isEdit;
    }
    return this.modalReference;
  }

  close() {
    this.modalReference.close();
  }

  getSelectedCount(selectedCount: number) {
    this.selectedCount = selectedCount;
  }

  getItemImageList(itemImageList: any[]) {
    this.itemImageList = itemImageList;
  }

  isComboStateChanged(value: boolean) {
    this.item!.IsCombo = value;
  }

  closeModel(data: ItemV2 | null) {
    this.modalReference.close(data);
  }

  setSelectedModifiers(values: {
    inputModifier: ModifierV2[];
    isValid: boolean;
    modifierSelectedIndex: number;
  }) {
    this._selectedModifiers = Array.from(values.inputModifier);
    this.isModifiersValid = values.isValid;

    this.currentSelectedModifierIndex = values.modifierSelectedIndex;
    this.checkIsReady();
  }

  private checkIsReady() {
    let isValid = false;

    if (
      this.item &&
      (!this.item.ComboGroup || this.item.ComboGroup.length <= 0) &&
      (!this.item.Modifiers || this.item.Modifiers.length <= 0) &&
      (!this.item.Variations || this.item.Variations.length <= 0)
    ) {
      isValid = true;
    } else {
      if (this.comboGroup && this.comboGroup.length > 0) {
        isValid = this.isComboGroupValid;
      } else {
        if (this.modifiers) {
          if (this.isModifiersValid == undefined) {
            isValid = true;
          } else {
            isValid = this.isModifiersValid;
          }
        }
      }
    }

    // just to avoid having expressing change error in log
    // setTimeout(() => {
      this.isReady = isValid;
    // }, 500);
  }

  getComboItemFlags(comboItemFlags: any[]) {
    this.comboItemFlags = comboItemFlags;
  }

  cancel() {
    this.router.navigate(['/menu'], {
      queryParams: { category: this.CategoryID, concessionaireId: this.concessionaireId },
    });
  }

  updateComboGroup(comboGroupList: ComboGroup[]) {
    this.comboGroup = comboGroupList;
    this.boxBuilderCombo.comboGroup = comboGroupList;
    if (this.boxBuilderCombo) {
      this.boxBuilderCombo.setComboItems(true);
    }
  }

  reviewScreenOpen(result: boolean) {
    this.boxBuilderItemFooter.reviewYourBox();
  }
}
