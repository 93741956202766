import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-terms-conditions-url',
  templateUrl: './terms-conditions-url.component.html',
  styleUrls: ['./terms-conditions-url.component.css']
})
export class TermsConditionsUrlComponent implements OnInit {
  ref: any;

  constructor(public sanitizer: DomSanitizer, private language: LanguageService) { }
  value: any;
  @Input() url: string = '';
  @Input() isBoth: boolean = false;
  @Output() onClose = new EventEmitter<any>();
  termsAndConditionsText: string = '';
  ngOnInit(): void {
    // get url as input when using in both mode
    if (this.isBoth && this.url) {
      this.value = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + '?page=hsn#toolbar=0');
    }
    this.termsAndConditionsText = this.language.getTextElement('lbl_terms_and_conditions');
  }
  /**
   * @author Om kanada
   * @param url // pass url 
   * @description This method is used to open the modal
   * @returns void
   */
  public static open(url: string, modalService: NgbModal, sanitizer: DomSanitizer) {
    var ref = modalService.open(TermsConditionsUrlComponent, {
      backdrop: 'static', centered: true, size: 'lg', windowClass: 'terms-modal',
      modalDialogClass: 'terms-modal'
    });
    ref.componentInstance.ref = ref;
    const value = sanitizer.bypassSecurityTrustResourceUrl(url + '?page=hsn#toolbar=0');
    ref.componentInstance.value = value;
    return ref;
  }

  dismiss() {
    this.ref.close();
  }

}
