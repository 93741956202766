<main class="fullPageDiv" ng-init="init()">
    <div class="container-fluid" id="main-container">
        <div class="row">
            <div class="col-sm-12 text-center">

                <div class="login-content  text-left">
                    <div class="logo-box mb-0 mt-0">
                        <img src="assets/images/logo.svg"
                            onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
                            class="logo img-responsive" loading="lazy" />
                    </div>
                    <div id="pinpad">
                        <h2> {{accessCodeText}}</h2>
                        <ul class="passcode">
                            <li><input type="password" name="passcode1"
                                    class="bg-none b-0 b-shadow-0 b-bottom-1-light-gray" id="passcode1" maxlength="1"
                                    readonly />{{passcode1}}</li>
                            <li><input type="password" name="passcode2"
                                    class="bg-none b-0 b-shadow-0 b-bottom-1-light-gray" id="passcode2" maxlength="1"
                                    readonly /></li>
                            <li><input type="password" name="passcode3"
                                    class="bg-none b-0 b-shadow-0 b-bottom-1-light-gray" id="passcode3" maxlength="1"
                                    readonly /></li>
                            <li><input type="password" name="passcode4"
                                    class="bg-none b-0 b-shadow-0 b-bottom-1-light-gray" id="passcode4" maxlength="1"
                                    readonly /></li>
                        </ul>
                        <div class="pinpad">
                            <div class="wrapper">
                                <div class="row">
                                    <button><span (click)='onClick($event)'>1</span></button><button><span
                                            (click)='onClick($event)'>2</span></button><button><span
                                            (click)='onClick($event)'>3</span></button>
                                </div>
                                <div class="row">
                                    <button><span (click)='onClick($event)'>4</span></button><button><span
                                            (click)='onClick($event)'>5</span></button><button><span
                                            (click)='onClick($event)'>6</span></button>
                                </div>
                                <div class="row">
                                    <button><span (click)='onClick($event)'>7</span></button><button><span
                                            (click)='onClick($event)'>8</span></button><button><span
                                            (click)='onClick($event)'>9</span></button>
                                </div>
                                <div class="row">
                                    <button [attr.disabled]="isChecking ? true: null"><span (click)="onXClick()"
                                            class="fas fa-times negative-button-color"></span></button><button><span
                                            (click)='onClick($event)'>0</span>
                                    </button>
                                    <button [attr.disabled]="isChecking ? true: null"><span
                                            (click)="onCheckClick()"
                                            class="fas fa-check positive-button-color"></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <footer>
        <p>
            <strong>IP Address: </strong><span id="IpAddress">IP Address Not Loaded</span>,
            <strong>Version Code: </strong>1,
            <strong>Version Name: </strong>KIOSK V1.0.32(T),
            <strong>Application ID: </strong>ABCUniq
        </p>
        <p>
            <strong>Network Type: </strong><span id="NetworkInfo"></span>,
            <strong>Serial Number: </strong><span id="SerialNumber"></span>,
            <strong>MAC address: </strong><span id="MacAddress"></span>,
        </p>
    </footer> -->
    <div id="snackbar">{{invalidPinText}}</div>

</main>