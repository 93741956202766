<div class="customize-donut">
    <div class="modal-body">
        <button class="close" (click)="close()">
            &times;
            <!-- <img src="../../../../assets/images/box-builder/close-icon.svg" class="img-fluid" /> -->
        </button>
        <h2>{{comboItem.Name}}</h2>
        <div class="content">
            {{comboItem.FullDescription}}
        </div>
        <div class="product-img">
            <img src="{{(comboItem.ImageUrl && comboItem.ImageUrl !='') ? imageBaseUrl+comboItem.ImageUrl : placeholderImage !='' ? imageBaseUrl+placeholderImage : 'assets/images/Image_21.png'}}"
                onerror="this.onerror=null; this.src='assets/images/Image_21.png'" class="img-fluid" />
        </div>
        <div class="product-qty">
            <h3>{{howManyText}}
                <svg *ngIf="isEdit" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"
                    (click)="deleteItem()">
                    <path d="M8 9.33342H6.66667V26.6667C6.66667 27.374 6.94762 28.0523 7.44772 28.5524C7.94781 
                        29.0525 8.62609 29.3334 9.33333 29.3334H22.6667C23.3739 29.3334 24.0522 29.0525 
                        24.5523 28.5524C25.0524 28.0523 25.3333 27.374 25.3333 26.6667V9.33342H8ZM13.3333 
                        25.3334H10.6667V13.3334H13.3333V25.3334ZM21.3333 25.3334H18.6667V13.3334H21.3333V25.3334ZM22.1573 
                        5.33341L20 2.66675H12L9.84267 5.33341H4V8.00008H28V5.33341H22.1573Z"
                        [ngStyle]="{fill:getAccentColor()}"></path>
                </svg>
                <!-- <img src="../../../../assets/images/box-builder/delete.svg" (click)="deleteItem()" /> -->
            </h3>
            <div class="d-flex flex-row flex-wrap quantity">
                <button class="btn donut-qty" *ngFor="let item of totalQuantity; let i = index"
                    [ngClass]="{active: i==selectedQuantity}" (click)="onClick($event,i)" [disabled]="!getIsActive(i)">
                    {{i+1}}
                    <!-- <input type="checkbox" name="qty" id="qty{{i}}" (click)="onClick($event,i)" /> -->

                    <!-- <label for="qty{{i}}" [ngClass]="{active: i==checkBoxSelected}">{{i+1}}</label> -->
                </button>
            </div>

        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-add active" (click)="onAddToBox()" id="btn-box">{{addToBoxText}}</button>
    </div>
    <!-- <img src="../../../../assets/images/box-builder/quack.png" id="quack-img" /> -->
</div>