<div
  *ngIf="ShowItemTagImages == 'True' && itemTags"
  class="itemTags full-width"
>
  <div *ngIf="itemTags && itemTags.size > 0" class="itemTagsKeyContainer">
    <div
      *ngFor="let tag of itemTags | keyvalue; let i = index"
      class="itemTagsKeyContainer"
    >
      <img
        class="itemTagKeys"
        src="{{ baseImageUrl + tag.value }}"
        onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
      />
      <div class="font-size4 semi-bold key">{{ tag.key }}</div>
    </div>
  </div>
</div>
