import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingComponent } from '../loading/loading.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private LoadingModal!: LoadingComponent | null;

  constructor(private readonly modalService:NgbModal) { }

  public openLoader(isDialogOpen:boolean = false, isTimeOut:boolean = false,timeOutMiliSec:number = 5000) {
    if (!(this.LoadingModal && this.LoadingModal.isDialogOpen)) {
      this.closeLoader();
   
      this.LoadingModal = LoadingComponent.open(
        this.modalService,
        isDialogOpen,
        isTimeOut,
        timeOutMiliSec
      ).componentInstance;
    }
  }

  public closeLoader() {
    if (this.LoadingModal) {
      this.LoadingModal.close();
    }
  }
  
}
