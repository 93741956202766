<section>
    <div class="scan" role="document">
    <div class="payment-modal">          
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()"  >
            <span aria-hidden="true">&times;</span>
        </button> -->
            <h2 class="text-center">{{checkoutText}}</h2>                
            <h3 class="text-center">{{notEnoughBalanceText}}</h3>
            <h4 class="text-center">{{tryDifferentMethodText}}</h4>
            
            <div class="col-md-12 col-xs-12 p-0 insert-img">
                <img src="../../../../assets/images/ic_not_enough_balance.png" onerror="this.onerror=null; this.src='assets/images/Image_21.png'"/>
            </div>

            <!-- <div class="modal-footer"> -->
                <div class="col-12 col-md-12 popup-footer text-center">
                    <button
                        class="btn text-uppercase negative-button-text-color background-negative-button-color border-negative-button-color "
            
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="close('payment')"
                    >
                    {{useAnotherMethodText|uppercase}}
                    </button>
                </div>
            <!-- </div> -->
        </div>
    </div>
</section>


    