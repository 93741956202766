import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralSetting } from 'src/app/services/generalSetting.service';
import { Router } from '@angular/router';
import { loggingData, LogService } from 'src/app/services/log.service';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-ask-customer-receipt-dialog',
  templateUrl: './ask-customer-receipt-dialog.component.html',
  styleUrls: ['./ask-customer-receipt-dialog.component.css'],
})
export class AskCustomerReceiptDialogComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }

    if (this.timeOut) {
      window.clearTimeout(this.timeOut);
    }
  }
 
  languageSub!: Subscription;
 
  modalReference: any;

  private timeOut: any;

  askReceiptText: string = '';
 
  yesText: string = '';
 
  noText: string = '';
  
  constructor(private logger: LogService,
    private language: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageSub = this.language.localeCommunicator.subscribe((val) => {
      this.loadText();
    });

    if (Number(GeneralSetting.getCheckoutScreenTimeoutKIOSK()) > 30) {
      this.timeOut = setTimeout(() => {
        this.close('no');
      }, 30000);
    }
  }
    
  loadText() {
    this.askReceiptText = this.language.getTextElement('do_you_want_receipt');
    this.yesText = this.language.getTextElement('txt_yes');
    this.noText = this.language.getTextElement('txt_no');
  }

  public static open(modalService: NgbModal) {
    let modalReference = modalService.open(AskCustomerReceiptDialogComponent, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });

    modalReference.componentInstance.modalReference = modalReference;

    return modalReference;
  }

  close(result: string) {
    try {
      this.logger.sendLogToServer(
        new loggingData(
          'Choice of Receipt',
          'Customer Receipt Printing Choice',
          'Printing',
          `Customer Chose ${result} to Printing ${GeneralSetting.getInvoiceNoToDb()}`,
          true
        )
      );

      if (this.timeOut) {
        window.clearTimeout(this.timeOut);
      }
    } catch (e) {}

    this.modalReference.close(result);
  }
  
}
