<section>
    <div class="scan" role="document">
    <div class="payment-modal">          
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()"  >
            <span aria-hidden="true">&times;</span>
        </button>
            <h2 class="text-center">{{checkoutText}}</h2>                
            <h3 class="text-center">{{successText}}</h3>
            <h4 class="text-center">{{paymentCompleteText}} </h4>
            <div class="col-md-12 col-xs-12 p-0 insert-img">
                <img src="../../../../assets/images/ic_success_payment.png" onerror="this.onerror=null; this.src='assets/images/Image_21.png'"/>
            </div>
    
        </div>
    </div>
</section>