<div class="body">
  <p class="header">{{errorText}}</p>
  <p class="message">{{downloadFailureText}}</p>
  <!-- <div class="images" *ngFor="let image of failedImages; let i = index">
    <p (click)="clickLink(i)" >{{image}}</p>
  </div> -->
  <div class="buttons">
    <button (click)="retry()">{{retryText|uppercase}}</button><button (click)="continue()">{{continueText|uppercase}}</button>
  </div>
</div>

<div style="display: none;">
  <app-image-popup></app-image-popup>
</div>
