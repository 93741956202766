import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { GeneralSetting } from '../../../services/generalSetting.service';

@Component({
  selector: 'app-alcoholic-over21-dialog',
  templateUrl: './alcoholic-over21-dialog.component.html',
  styleUrls: ['./alcoholic-over21-dialog.component.css'],
})
export class AlcoholicOver21DialogComponent implements OnInit {
  ngOnDestroy() {
    if (this.allSubscription.length > 0) {
      for (let i = 0; i < this.allSubscription.length; i++) {
        this.allSubscription[i].unsubscribe();
      }
    }
  }

  modalReference: any;

  allSubscription: Subscription[] = [];

  limit: any;

  primaryColor: string = '';

  secondaryColor: string = '';

  positiveColor: string = '';

  positiveColorText: string = '';

  negativeColor: string = '';

  negativeColorText: string = '';

  alcoholicItemText: string = '';

  ageLimitText: string = '';
  iAmOverText: string = '';
  iAmUnderText: string = '';
  constructor(private language: LanguageService) { }

  ngOnInit(): void {
    this.limit = GeneralSetting.getMaxAlcoholicItemsPerOrder();
    this.primaryColor = GeneralSetting.getPrimaryColor();
    this.secondaryColor = GeneralSetting.getSecondaryColor();
    this.positiveColor = GeneralSetting.getPositiveColor();
    this.positiveColorText = GeneralSetting.getPositiveColorText();
    this.negativeColor = GeneralSetting.getNegativeColor();
    this.negativeColorText = GeneralSetting.getNegativeColorText();

    this.allSubscription.push(
      this.language.localeCommunicator.subscribe((val) => {
        this.loadText();
      })
    );
  }

  loadText() {
    this.alcoholicItemText = this.language.getTextElement('alcoholic_item');
    this.ageLimitText = this.language.getTextElement('you_need_to_be_over_age_to_purchase_this_item').replace('%d', GeneralSetting.getAgeOfMajority())
    this.iAmOverText = this.language.getTextElement('i_am_over').replace('%d', GeneralSetting.getAgeOfMajority());
    this.iAmUnderText = this.language.getTextElement('i_am_under').replace('%d', GeneralSetting.getAgeOfMajority());
  }

  public static open(modalService: NgbModal) {
    let modalReference = modalService.open(AlcoholicOver21DialogComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
      windowClass: 'alc-over',
      modalDialogClass: 'alc-over-modal',
    });

    modalReference.componentInstance.modalReference = modalReference;

    if (GeneralSetting.getIsAlcoholicAgeLimitKnown() == 'True') {
      modalReference.componentInstance.dismissOver21();
    }

    return modalReference;
  }

  dismissUnder21() {
    this.modalReference.close('under');
  }

  dismissOver21() {
    GeneralSetting.setIsAlcoholicAgeLimitKnown('True');
    this.modalReference.close('over');
  }
}
