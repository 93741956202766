import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-customer-welcome-modal',
  templateUrl: './customer-welcome-modal.component.html',
  styleUrls: ['./customer-welcome-modal.component.css']
})
export class CustomerWelcomeModalComponent implements OnInit {
  languageSub!:Subscription
  modalReference: NgbModalRef | undefined;

  name: string = ''

  initial: boolean = false;
  welcomeText:String=''
  startEarningText:String=''
  continueText:String=''
  hiGuestText:String=''
  thankYouLoyaltyText:String=''
  wantToSignOutText:String=''
  signOutText:String=''
  cancelText:String=''
  
  constructor(public modalService: NgbModal, private userService: UserService, private language:LanguageService ) { }
  loadText(){
  this.welcomeText=this.language.getTextElement('welcome_user').replace('%d',this.name)
  this.startEarningText=this.language.getTextElement('start_earning_now')
  this.continueText=this.language.getTextElement('lbl_continue')
  this.thankYouLoyaltyText=this.language.getTextElement('thank_you_for_your_loyalty')
  this.wantToSignOutText=this.language.getTextElement('want_to_sign_out')
  this.signOutText=this.language.getTextElement('action_sign_out')
  this.cancelText=this.language.getTextElement('lbl_button_cancel')
  this.hiGuestText=this.language.getTextElement('hi_guest').replace('%d',this.name)
  }
  ngOnInit(): void {
    this.languageSub=this.language.localeCommunicator.subscribe((val)=>{
      this.name = this.userService.name??this.language.getTextElement('txt_guest')
      this.loadText()
    })
  }

  public static open(modalService: NgbModal, initial: boolean = false,name:string) {
    let modalReference = modalService.open(CustomerWelcomeModalComponent, { size: "lg", centered: true })
    modalReference.componentInstance.modalReference = modalReference;
    modalReference.componentInstance.name = name;
    modalReference.componentInstance.initial = initial;

    setTimeout(() => {
      if (initial) {
        modalReference.componentInstance.close('')
      }
    }, 2000);
    return modalReference;
  }

  open(initial: boolean = false) {
    this.initial = initial
    this.modalReference = this.modalService.open(CustomerWelcomeModalComponent, { size: "lg", centered: true })
    this.modalReference.componentInstance.modalReference = this.modalReference;
    this.modalReference.componentInstance.name = this.userService.name
    this.modalReference.componentInstance.initial = initial
    setTimeout(() => {
      if (initial) {
        this.modalReference?.close('')
      }
    }, 2000);
    return this.modalReference
  }

  signOut() {
    this.userService.signOut()
    this.modalReference?.close('signout')
  }

  close() {
    this.modalReference?.close('')
  }

}
