<div [ngStyle]="{
    border: ingredient.IsSelected ? 'solid 3px' : '',
    borderColor: ingredient.IsSelected ? accentColor : '',
    color:ingredient.IsSelected? accentColor: '#000000',
    position: 'relative'
  }" class="object item-ingredient-animation" [ngClass]="{
    sold:
      parent.IsModifier86 == 'False' || ingredient.IsIngredient86 == 'False'
  }" (click)="itemClicked()">
  <div class="ct-hidden"
    [ngStyle]="{display : (!ingredient.IsSelected && parent.isValid && !(parent.count < parent.max) && parent.max > 1)? 'block':'none' }">
  </div>
  <div class="choice-type">


    <div class="image-div">
      <img class="" [src]="
        ingredient.IsModifier
          ? ingredient.IsSelected
            ? image != ''
              ? image
              : placeholderImage !=''
                ? baseImageUrl+placeholderImage 
                :'assets/images/Image_21.png'
            : deselectedImage != ''
              ? deselectedImage
              : placeholderImage !=''
                ? baseImageUrl+placeholderImage 
                :'assets/images/Image_21.png'
          : image != ''
            ? image
            : placeholderImage !=''
              ? baseImageUrl+placeholderImage 
              :'assets/images/Image_21.png'
      " onerror="this.onerror=null; this.src='assets/images/Image_21.png';" style="aspect-ratio: 1"
        alt="ingredient-img" [ngClass]="{ soldOut: isSoldOut }" />
    </div>
    <span>{{ ingredient.Name || ingredient.Category }}</span>
  </div>
</div>