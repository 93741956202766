<!-- <div  class="modal-body"> -->
<div class="pin-modal">
  <div class="modal-header">
    <h3 class="modal-title text-center">{{confirmPinText}}</h3>
    <button class="close" (click)="close('x')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <ul class="passcode">
    <li><input [(ngModel)]="passcode1" type="password" name="passcode1"
        class="bg-none b-0 b-shadow-0 b-bottom-1-light-gray" id="passcode1" maxlength="1" disabled /></li>
    <li><input [(ngModel)]="passcode2" type="password" name="passcode2"
        class="bg-none b-0 b-shadow-0 b-bottom-1-light-gray" id="passcode2" maxlength="1" disabled /></li>
    <li><input [(ngModel)]="passcode3" type="password" name="passcode3"
        class="bg-none b-0 b-shadow-0 b-bottom-1-light-gray" id="passcode3" maxlength="1" disabled /></li>
    <li><input [(ngModel)]="passcode4" type="password" name="passcode-none 4"
        class="bg-none b-0 b-shadow-0 b-bottom-1-light-gray" id="passcode4" maxlength="1" disabled /></li>
  </ul>
  <div class="pinpad">
    <div class="wrapper">
      <div class="row">
        <button (click)="onClick($event)">1</button><button (click)="onClick($event)">2</button><button
          (click)="onClick($event)">3</button>
      </div>
      <div class="row">
        <button (click)="onClick($event)">4</button><button (click)="onClick($event)">5</button><button
          (click)="onClick($event)">6</button>
      </div>
      <div class="row">
        <button (click)="onClick($event)">7</button><button (click)="onClick($event)">8</button><button
          (click)="onClick($event)">9</button>
      </div>
      <div class="row">
        <button (click)="close('clear')" [attr.disabled]="isClicked ? true: null" class="font-size-4rem">
            {{clearText | uppercase}}</button>
        <button (click)="onClick($event)">0</button>
        <button class="positive-button-text-color font-size-4rem background-positive-button-color"
          (click)='checkPasscode()' [attr.disabled]="isClicked ? true: null">
            {{doneText | uppercase}}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- </div> -->