<section>
    <div class="payment-error" role="document">
        <div class="payment-modal">          
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()"  >
                <span aria-hidden="true">&times;</span>
            </button>
                <h2 class="text-center">{{checkoutText|uppercase}}</h2>                
                <h3 class="text-center">{{paymentErrorText}}</h3>
                <h4 class="text-center">{{error}}</h4>
        </div>
    </div>
</section>

        