<div class="search-scannable-items">

    <div class="scan-selection-bar">
        <button class="btn back-btn" (click)="backtomenu()">
            <span class="material-icons">
                arrow_back_ios
            </span>
            {{backText}}
        </button>
        <div class="search-bar">
            <span class="material-icons first">
                qr_code_scanner
            </span>
            <input KeyboardCustom readonly id="searchitem" type="search" class="form-control"
                [style.font-size]="isSelectedLangFrench ? '2.7rem' : '3rem'"
                placeholder="{{searchPlaceholder}}" [(ngModel)]="searchitem" name="search"
                (input)="searchThis()" />
            <span class="material-icons last" (click)="clearSearch()">
                close
            </span>
        </div>
    </div>

    <div class="search-body">
        <div class="row">
            <div class="col-md-8 search-items">
                <h4>{{searchResultsText}}</h4>

                <div *ngIf="searchableItems.length <=0" class="empty-list-overlay">
                    <div class="empty-list-text">
                        <p>{{noItemFound}}</p>
                    </div>
                </div>

                <div *ngIf="searchableItems.length >0" class="search-result" [ngStyle]="setSearchListHeight()">
                    <!-- <ng-container *ngIf="isConcessionaire ? conSize > 0 : 1"> -->
                    <div class="menuContainer" [ngClass]="{ open: openCart }" style="position: relative; height:{{
                    openCart
                      ? isConcessionaire
                        ? '69vh'
                        : '80vh'
                      : isConcessionaire
                      ? '71vh'
                      : '85vh'
                  }}">

                        <div class="rightMenucolumn" [ngClass]="{ open: openCart }">
                            <!-- Menu Items Start  -->

                            <div class="itemContainer menu_Items_Container" [ngClass]="{ open: openCart }" [ngStyle]="{
                        'background-image': 'url(' + backgroundImageCategory + ')'}" style=" height:{{itemContainerHeight}}">
                                <div class="item-list" (scroll)="onScroll($event)" id="itemContainer"
                                    [ngClass]="{ open: openCart }" #itemContainerDiv>
                                    <div id="topDiv"></div>
                                    <div #itemsgrid [ngClass]="{
                            'mini-three-item-container':
                              selectedCategory &&
                              selectedCategory.DisplayCatItemRowCount == '3'
                          }" [ngStyle]="{
                            'grid-auto-rows': (minimizeSpace && 'min-content') || 'auto',
                            'grid-template-columns':
                              'repeat(' +
                              selectedCategory?.DisplayCatItemRowCount +
                              ', minmax(0, 1fr))',
                            display: 'grid'
                          }" class="item-grid">
                                        <div *ngFor="let item of searchableItems" [ngClass]="{
                              'item-container': currentItems.length > 0,
                              'item-container-one': currentItems.length == 0,
                              'mini-item-container': minimizeSpace
                            }">
                                            <!-- begin applied schedule pricing block -->
                                            <div style="width: 100%" *ngIf="item.OldPrice; else normal">
                                                <div appDebounceClick class="item" style="position: relative"
                                                    id="{{ item.ItemID }}" (debounceClick)="onItemClick(item, $event)">
                                                    <div class="image-container" [ngStyle]="getContainerStyle(item)">
                                                        <img src="{{
                                      item.ImageUrl
                                        | functionCall: getCommonImage:imageBaseUrl:placeholderImage
                                    }}" [ngStyle]="getStyle(item)" [ngClass]="{ ghost: item.SoldOut }"
                                                            onerror="this.src='assets/images/Image_21.png'"
                                                            loading="lazy" />

                                                        <!-- <img src="{{ item.ImageUrl && item.ImageUrl != '' ? imageBaseUrl + item.ImageUrl : 'assets/images/Image_21.png' }}"
                                       [ngStyle]="getStyle(item)" [ngClass]="{ ghost: item.SoldOut }"
                                       onerror="this.src='assets/images/Image_21.png'" loading="lazy" /> -->
                                                    </div>

                                                    <div [ngStyle]="{
                                    'align-items': 'horizontal',
                                    height: '4rem'
                                  }" class="itemTagsContainer">
                                                        <div *ngFor="let image of item.IconImages"
                                                            [ngClass]="{ ghost: item.SoldOut }" class="col itemTagsDiv">
                                                            <img [ngStyle]="{ width: '3rem', height: '3rem' }"
                                                                *ngIf="image != null && image != ''" src="{{
                                        image | functionCall: getCommonImage:imageBaseUrl:placeholderImage
                                      }}" onerror="this.src='assets/images/Image_21.png'" />
                                                        </div>
                                                    </div>
                                                    <div class="sold-out red" *ngIf="item.SoldOut">
                                                        <em>{{ soldOutText }}</em>
                                                    </div>
                                                    <p class="calorie-text" style="
                                    font-style: italic;
                                    color: #9e9e9e;
                                    font-size: smaller;
                                    font-weight: normal;
                                  " *ngIf="showCalorie === 'True' && item.Calorie !== ''"></p>
                                                    <div style="
                                    font-size: 3rem;
                                    padding-left: 1rem;
                                    padding-right: 1rem;
                                    position: absolute;
                                    right: 50%;
                                    transform: translateX(50%);
                                    top: 2%;
                                    max-width: 80%;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: word;
                                    color: white;
                                    background-color: green;
                                  " class="root-round2">
                                                        {{ item.AppliedPromoName }}
                                                    </div>

                                                    <div class="sold-out red" *ngIf="item.SoldOut">
                                                        <em>{{ soldOutText }}</em>
                                                    </div>
                                                    <strong [ngStyle]="{
                                    color: accentColor
                                  }" [ngClass]="{ ghost: item.SoldOut }">{{ item.Name | uppercase }}</strong>

                                                    <p *ngIf="!item.IsCategory && !isRemovePricingAndPayments"
                                                        [ngStyle]="{ color: 'green' }"
                                                        [ngClass]="{ ghost: item.SoldOut }">
                                                        {{
                                                        ((item.ModifierCount > 0 || item.AttributeCount > 0) &&
                                                        hideStartAt === "False"
                                                        ? startsAtText
                                                        : "") + (item.Price | CurrencyPipe)
                                                        }}
                                                    </p>
                                                    <p *ngIf="!item.IsCategory && !isRemovePricingAndPayments"
                                                        style="font-size: 2rem" [ngClass]="{ ghost: item.SoldOut }"
                                                        [ngStyle]="{ color: 'black' }">
                                                        {{regularPriceText}} {{ item.OldPrice | CurrencyPipe }}
                                                    </p>
                                                    <p class="calorie-text" style="
                                    font-style: italic;
                                    color: #9e9e9e;
                                    font-size: smaller;
                                    font-weight: normal;
                                  " *ngIf="showCalorie === 'True' && item.Calorie !== ''">
                                                        {{ item.MaxCalories !=='' ? item.Calorie + ' - ' +
                                                        item.MaxCalories + calorieText : item.Calorie + calorieText}}
                                                    </p>
                                                </div>
                                            </div>

                                            <ng-template #normal>
                                                <div appDebounceClick class="item" style="position: relative"
                                                    id="{{ item.ItemID }}" (debounceClick)="onItemClick(item, $event)">
                                                    <div class="image-container" [ngStyle]="getContainerStyle(item)">
                                                        <img id="{{ item.ImageUrl }}" [ngStyle]="getStyle(item)" src="{{
                                      item.ImageUrl
                                        | functionCall: getCommonImage:imageBaseUrl:placeholderImage
                                    }}" [ngClass]="{ ghost: item.SoldOut }" [ngStyle]="getStyle(item)"
                                                            onerror="this.onerror=null; this.src='assets/images/Image_21.png'"
                                                            loading="lazy" />
                                                    </div>

                                                    <div [ngStyle]="{
                                    'align-items': 'horizontal',
                                    height: '4rem'
                                  }" class="itemTagsContainer">
                                                        <div *ngFor="let image of item.IconImages"
                                                            [ngClass]="{ ghost: item.SoldOut }" class="col itemTagsDiv">
                                                            <img [ngStyle]="{ width: '3rem', height: '3rem' }"
                                                                *ngIf="image != null && image != ''" src="{{
                                        image | functionCall: getCommonImage:imageBaseUrl:placeholderImage
                                      }}" onerror="this.onerror=null; this.src='assets/images/Image_21.png'" />
                                                        </div>
                                                    </div>
                                                    <div class="sold-out" *ngIf="item.SoldOut">
                                                        <em>{{ soldOutText }}</em>
                                                    </div>
                                                    <strong [ngClass]="{ ghost: item.SoldOut }"
                                                        [ngStyle]="{ color: accentColor }">
                                                        {{ item.Name | uppercase }}
                                                    </strong>
                                                    <p *ngIf="
                                    isRemovePricingAndPayments
                                      | functionCall: isPriceShow:isHideZeroPrice:item.Price
                                  " [ngStyle]="{
                                    color: priceColor,
                                    opacity: item.SoldOut ? 0.3 : 1
                                  }">
                                                        {{
                                                        ((item.ModifierCount > 0 || item.AttributeCount > 0) &&
                                                        hideStartAt === "False"
                                                        ? startsAtText
                                                        : "") + (item.Price | CurrencyPipe)
                                                        }}
                                                    </p>
                                                    <p class="calorie-text" style="
                                    font-style: italic;
                                    color: #9e9e9e;
                                    font-size: smaller;
                                    font-weight: normal;
                                  " *ngIf="showCalorie === 'True' && item.Calorie !== ''">
                                                        {{ item.MaxCalories !=='' ? item.Calorie + ' - ' +
                                                        item.MaxCalories + calorieText : item.Calorie + calorieText }}
                                                    </p>
                                                </div>

                                            </ng-template>

                                        </div>

                                        <div class="menu-navigation" *ngIf="checkScrollCheck">
                                            <div *ngIf="!isTop" (click)="scrollTop()">
                                                <i class="scroll-up fas fa-angle-double-up"></i>
                                            </div>
                                            <div *ngIf="!isBottom" (click)="scrollDown()">
                                                <i class="scroll-down fas fa-angle-double-down"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Menu Items End  -->
                        </div>
                    </div>


                    <!-- </ng-container> -->
                </div>
            </div>
            <div class="col-md-4 v-cart">
                <h4>{{yourOrder}}</h4>
                <app-scan-cart class="cart background-footer-color ver-cart" (isCartOpenChange)="changeOpenCart($event)"
                    [removeAlk]="removeAlcohol"></app-scan-cart>

            </div>
        </div>
    </div>



</div>

<div style="display: none">
    <app-timeout style="display: none" [hidden]="true"></app-timeout>
</div>
