<div role="dialog" id="thankyou-popup">
  <div class="popup">
    <h2 class="text-center">{{orderReceiptText|uppercase}}</h2>

    <div class="content">
      <h1 class="text-center">{{thankYouText}}</h1>
      <h3 class="text-center">{{orderOnTheWayText}}</h3>
      <div class="row">
        <div class="col-sm-8 col-sm-offset-2">
          <div class="tab"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-sm-offset-3 container-print">
          <div class="panel panel-dark panel-default pseudo">
            <div class="panel-body">
              <h3>{{enableTableTents}}</h3>
              <h2 id="order-token">{{ orderNo }}</h2>
              <h4 id="TYP_TOTAL" *ngIf="!isRemovePricingAndPayments">
                {{totalText}} <span [ngStyle]="{ color: priceColor }">{{ total | number: "1.2-2" | CurrencyPipe }}</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 popup-footer text-center">
        <div class="loyaltyDetail" *ngIf="isLoyalty && isShowPoints">
          <span class="poinsEarned"
            ><b>{{ pointsEarnedText }}</b></span
          >
          <br />
          <span *ngIf="loyaltyType==2" class="rewardPoints"
          > {{ visitCountText }}</span
        >
          <br />
          <span class="rewardPoints"
          >{{rewardsPointsText }}</span
        >
        </div>
        <div *ngIf="(hideReceipt === 'False') && !isRemovePricingAndPayments">
        <a appDebounceClick
           matRipple
           class="
            border-positive-button-color
            green-btn
            background-positive-button-color
            positive-button-text-color
            text-uppercase
            w-100-p
          "
           (debounceClick)="emailReceipt()">
          {{emailReceiptText|uppercase}} &nbsp;
          <i class="fas fa-envelope-open-text"></i>
        </a>
      </div>
        <div class="clearfix"></div>
        <a matRipple
           class="
            plain-btn
            w-100-p
            negative-button-text-color
            background-negative-button-color
            border-negative-button-color
          "
           (click)="finishOrder()">
          {{finishOrderText|uppercase}} &nbsp;
          <i class="fas fa-sign-out-alt"></i>
        </a>
      </div>
    </div>
  </div>
</div>

