

export class OrderCustomMessage {
  Name: string = "";
  Title: string = "";
  Message: string = "";
  AdditionalText: string = "";
  Image: string = "";
  Type: string = "";
}

export class vwOrderCustomMessage {

  constructor(title:string,image:string,bit:string,ait:string) {
    this.title = title;
    this.image = image;
    this.beforeImageText = bit;
    this.afterImageText = ait;

    this.setBeforeAndAfterImageText();
  }

  title: string = "";
  image: string = "";
  beforeImageText: string = "";
  afterImageText: string = "";

  setBeforeAndAfterImageText() {
    if (!this.image || this.image.trim() == "") {
      this.beforeImageText = "";
      this.afterImageText = "";
    }
  }

}
