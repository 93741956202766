<div>
    <h1 class="loading-text">
        {{headerText}}
    </h1>
    <h2 class="scrolling-text">
        {{ currentStateText }}
    </h2>
    <p class="index">
        {{ indexText }}
    </p>
    <p class="path">
        {{ path }}
    </p>
    <div class="loading-gif">
        <img src="assets/images/loading/grubbrr_menu_refresh_animation.gif">
    </div>

</div>
